import {
  ButtonAnalyticParams,
  EventAnalyticParams,
  PageAnalyticParams
} from '../models/analytics.model';

export class AutomaticPaymentsAnalytics {
  public static readonly pageMultipleAutomaticPayments: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Multiple Automatic Payments',
    subCategory3: ''
  };

  public static readonly buttonSkipAutoPaySetup: ButtonAnalyticParams = {
    link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:SkipAutoPaySetup'
  };

  public static readonly pageMultipleAutomaticPaymentsAccountInfoNotFound: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:MultipleAutomaticPayments:AccountInfoNotFound',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Multiple Automatic Payments',
    subCategory3: ''
  };

  public static readonly pageMultipleAutomaticPaymentsCancelConfirmation: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:Cancel',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Multiple Automatic Payments',
    subCategory3: ''
  };

  public static readonly eventMultipleAutomaticPaymentsStart: EventAnalyticParams = {
    eventName: 'Multiple Automatic Payments',
    eventStep: 'start'
  };

  public static readonly buttonStartSkipAutoPaySetup: ButtonAnalyticParams = {
    link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:Start:SkipAutoPaySetup'
  };

  public static readonly pageMultipleAutomaticPaymentsConfirm: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:Confirm',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Multiple Automatic Payments',
    subCategory3: ''
  };

  public static readonly buttonConfirmSkipAutoPaySetup: ButtonAnalyticParams = {
    link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:Confirm:SkipAutoPaySetup'
  };

  public static readonly pageMultipleAutomaticPaymentsComplete: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Multiple Automatic Payments',
    subCategory3: ''
  };

  public static readonly eventMultipleAutomaticPaymentsComplete: EventAnalyticParams = {
    eventName: 'Multiple Automatic Payments',
    eventStep: 'complete'
  };

  public static readonly pageMultipleAutomaticPaymentsPartialError: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:PartialError',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Multiple Automatic Payments',
    subCategory3: ''
  };

  public static readonly buttonPartialErrorTryAgain: ButtonAnalyticParams = {
    link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:PartialError:TryAgain'
  };

  public static readonly buttonErrorTryAgain: ButtonAnalyticParams = {
    link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:Error:TryAgain'
  };

  public static readonly pageMultipleAutomaticPaymentsError: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Multiple Automatic Payments',
    subCategory3: ''
  };

  public static readonly pageMultipleAutomaticPaymentsNewPaymentMethodSaving: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:NewPaymentMethod',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Add Payment Method: Saving',
    subCategory3: ''
  };

  public static readonly buttonMultipleAutomaticPaymentsNewPaymentMethodSaving: ButtonAnalyticParams =
    {
      link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:PaymentMethodAdded:Saving'
    };

  public static readonly pageMultipleAutomaticPaymentsNewPaymentMethodChecking: PageAnalyticParams =
    {
      pageName: 'MyAccount:BillingPayments:AutomaticPayments:NewPaymentMethod',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Billing Payments',
      subCategory2: 'Add Payment Method: Checking',
      subCategory3: ''
    };

  public static readonly buttonMultipleAutomaticPaymentsNewPaymentMethodChecking: ButtonAnalyticParams =
    {
      link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:PaymentMethodAdded:Checking'
    };

  public static readonly pageMultipleAutomaticPaymentsNewPaymentMethodDebitCredit: PageAnalyticParams =
    {
      pageName: 'MyAccount:BillingPayments:AutomaticPayments:NewPaymentMethod',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Billing Payments',
      subCategory2: 'Add Payment Method: DebitCredit',
      subCategory3: ''
    };

  public static readonly buttonMultipleAutomaticPaymentsNewPaymentMethodDebitCredit: ButtonAnalyticParams =
    {
      link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:PaymentMethodAdded:DebitCredit'
    };

  public static readonly pageMultipleAutomaticPaymentsEditPaymentMethod: PageAnalyticParams = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:EditPaymentMethod',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Edit Payment Method',
    subCategory3: ''
  };

  public static readonly buttonMultipleAutomaticPaymentsPaymentMethodEdited: ButtonAnalyticParams =
    {
      link: 'MyAccount:BillingPayments:MultipleAutomaticPayments:PaymentMethodEdited'
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditComplete: PageAnalyticParams =
    {
      pageName: 'MyAccount:BillingPayments:AutomaticPayments:Edit:Complete',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Billing Payments',
      subCategory2: 'Multiple Automatic Payments',
      subCategory3: ''
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditStart: PageAnalyticParams =
    {
      pageName: 'MyAccount:BillingPayments:AutomaticPayments:Edit:Start',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Billing Payments',
      subCategory2: 'Multiple Automatic Payments',
      subCategory3: ''
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditDeleteStart: PageAnalyticParams =
    {
      pageName: 'MyAccount:BillingPayments:AutomaticPayments:Edit:Delete:Start',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Billing Payments',
      subCategory2: 'Multiple Automatic Payments',
      subCategory3: ''
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditDeleteComplete: PageAnalyticParams =
    {
      pageName: 'MyAccount:BillingPayments:AutomaticPayments:Edit:Delete:Complete',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Billing Payments',
      subCategory2: 'Multiple Automatic Payments',
      subCategory3: ''
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditError: PageAnalyticParams =
    {
      pageName: 'MyAccount:BillingPayments:AutomaticPayments:Edit:Error',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Billing Payments',
      subCategory2: 'Multiple Automatic Payments',
      subCategory3: ''
    };
}

export class AutomaticPaymentsAnalyticsAdmin {
  public static readonly pageMultipleAutomaticPayments: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Multiple Automatic Payments'
  };
  public static readonly buttonSkipAutoPaySetup: ButtonAnalyticParams = {
    link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:SkipAutoPaySetup'
  };

  public static readonly pageMultipleAutomaticPaymentsAccountInfoNotFound: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:AccountInfoNotFound',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Multiple Automatic Payments'
  };

  public static readonly pageMultipleAutomaticPaymentsCancelConfirmation: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:CancelConfirmation',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Multiple Automatic Payments'
  };

  public static readonly pageMultipleAutomaticPaymentsStart: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Multiple Automatic Payments'
  };
  public static readonly pageSingleAutomaticPayments: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:SingleAutomaticPayment',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Single Automatic Payment'
  };

  public static readonly eventMultipleAutomaticPaymentsStart: EventAnalyticParams = {
    eventName: 'Multiple Automatic Payments',
    eventStep: 'start'
  };

  public static readonly buttonStartSkipAutoPaySetup: ButtonAnalyticParams = {
    link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Start:SkipAutoPaySetup'
  };

  public static readonly pageMultipleAutomaticPaymentsConfirm: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Confirm',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Multiple Automatic Payments'
  };

  public static readonly buttonConfirmSkipAutoPaySetup: ButtonAnalyticParams = {
    link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Confirm:SkipAutoPaySetup'
  };

  public static readonly pageMultipleAutomaticPaymentsComplete: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Multiple Automatic Payments'
  };

  public static readonly eventMultipleAutomaticPaymentsComplete: EventAnalyticParams = {
    eventName: 'Multiple Automatic Payments',
    eventStep: 'complete'
  };

  public static readonly pageMultipleAutomaticPaymentsPartialError: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:PartialError',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Multiple Automatic Payments'
  };

  public static readonly buttonPartialErrorTryAgain: ButtonAnalyticParams = {
    link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:PartialError:TryAgain'
  };

  public static readonly buttonErrorTryAgain: ButtonAnalyticParams = {
    link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Error:TryAgain'
  };

  public static readonly pageMultipleAutomaticPaymentsError: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Multiple Automatic Payments'
  };

  public static readonly pageMultipleAutomaticPaymentsNewPaymentMethodSaving: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:NewPaymentMethod',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Add Payment Method: Saving'
  };

  public static readonly buttonMultipleAutomaticPaymentsNewPaymentMethodSaving: ButtonAnalyticParams =
    {
      link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:PaymentMethodAdded:Saving'
    };

  public static readonly pageMultipleAutomaticPaymentsNewPaymentMethodChecking: PageAnalyticParams =
    {
      pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:NewPaymentMethod',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Admin',
      subCategory2: 'Billing Payments',
      subCategory3: 'Add Payment Method: Checking'
    };

  public static readonly buttonMultipleAutomaticPaymentsNewPaymentMethodChecking: ButtonAnalyticParams =
    {
      link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:PaymentMethodAdded:Checking'
    };

  public static readonly pageMultipleAutomaticPaymentsNewPaymentMethodDebitCredit: PageAnalyticParams =
    {
      pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:NewPaymentMethod',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Admin',
      subCategory2: 'Billing Payments',
      subCategory3: 'Add Payment Method: DebitCredit'
    };

  public static readonly buttonMultipleAutomaticPaymentsNewPaymentMethodDebitCredit: ButtonAnalyticParams =
    {
      link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:PaymentMethodAdded:DebitCredit'
    };

  public static readonly pageMultipleAutomaticPaymentsEditPaymentMethod: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:EditPaymentMethod',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Edit Payment Method'
  };

  public static readonly buttonMultipleAutomaticPaymentsPaymentMethodEdited: ButtonAnalyticParams =
    {
      link: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:PaymentMethodEdited'
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditComplete: PageAnalyticParams =
    {
      pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Edit:Complete',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Admin',
      subCategory2: 'Billing Payments',
      subCategory3: 'Multiple Automatic Payments'
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditStart: PageAnalyticParams =
    {
      pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Edit:Start',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Admin',
      subCategory2: 'Billing Payments',
      subCategory3: 'Multiple Automatic Payments'
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditDeleteStart: PageAnalyticParams =
    {
      pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Edit:Delete:Start',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Admin',
      subCategory2: 'Billing Payments',
      subCategory3: 'Multiple Automatic Payments'
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditDeleteComplete: PageAnalyticParams =
    {
      pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Edit:Delete:Complete',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Admin',
      subCategory2: 'Billing Payments',
      subCategory3: 'Multiple Automatic Payments'
    };

  public static readonly MyAccountBillingPaymentsMultipleAutomaticPaymentsEditError: PageAnalyticParams =
    {
      pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Edit:Error',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Admin',
      subCategory2: 'Billing Payments',
      subCategory3: 'Multiple Automatic Payments'
    };
}

<form [formGroup]="singleSelectionGroup">
  <ds-radio-group
    [control]="'option'"
    [selectedAnswer]="selectedAnswer"
    [parentFormGroup]="singleSelectionGroup"
    [options]="selectOptions"
    data-cy="radioOption"
  >
  </ds-radio-group>
  <call-to-action
    [disabled]="singleSelectionGroup.invalid"
    [primaryButtonName]="'Continue'"
    [displayBackButton]="displayBackButton"
    [tertiaryButtonName]="'Cancel'"
    (primaryEvent)="onSingleSelectSubmit(singleSelectionGroup.controls['option'].value)"
    (tertiaryEvent)="cancel()"
    (backEvent)="goBack()"
  >
  </call-to-action>
</form>

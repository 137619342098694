<h3 [ngClass]="{ 'null-phone': !phones.length }" class="pad-top-30 margin-bottom-10">
  <span *dsPartnerAFI content [contentPath]="'profile.managePhoneNumbers.phoneNumbersTitle'"></span>
  <span
    *dsPartnerConnect
    content
    [contentPath]="'profile.managePhoneNumbers.phoneNumbersTitlePartners'"
  ></span>
</h3>
<p
  *ngIf="!phones.length"
  content
  [contentPath]="'profile.managePhoneNumbers.emptyPhoneNumberState'"
  class="pad-bottom-10"
  data-cy="noContactPhonesText"
></p>
<phone-list-item
  *ngFor="let phone of phones"
  data-cy="contactPhoneListItem"
  [phone]="phone"
  (makePrimaryEvent)="onMakePrimary($event)"
  (deleteEvent)="onDelete($event)"
>
</phone-list-item>
<p
  class="pad-top-20"
  *ngIf="phones.length && allPrimaryCheck"
  content
  [contentPath]="'profile.managePhoneNumbers.primaryPhoneNumberChange'"
></p>

<div *ngIf="isAddingPhone" class="edit-form pad-bottom-10">
  <form [formGroup]="phoneListForm" (ngSubmit)="addPhoneNumber()">
    <div class="row flex wrap">
      <div class="column twelve sm-five sm-pad-right-10 pad-bottom-10 sm-pad-bottom-0 float-left">
        <ds-form-control-container
          [labelText]="'Phone Number'"
          [control]="phoneListForm.get('phoneNumber')"
        >
          <input
            [identifier]="'phoneNumber'"
            id="phoneNumber"
            data-cy="phoneNumberInput"
            [preValue]="phoneListForm.value.phoneNumber"
            [phoneControl]="phoneListForm.controls['phoneNumber']"
            formControlName="phoneNumber"
            maxlength="12"
            phoneMask
          />
          <ds-form-control-message
            [control]="phoneListForm.get('phoneNumber')"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
      <div class="column twelve sm-three sm-pad-right-10 pad-bottom-10 sm-pad-bottom-0">
        <ds-form-control-container
          [labelText]="'Phone Type'"
          [control]="phoneListForm.get('typeOfUsageCode')"
        >
          <select
            id="phoneType"
            class="icon fill"
            data-cy="phoneNumberType"
            formControlName="typeOfUsageCode"
          >
            <option *ngFor="let type of usageCodes" value="{{ type }}">
              <span *ngIf="type !== 'TDD_TTY'"
                >{{ type | slice: 0:1 }}{{ type | slice: 1 | lowercase }}</span
              >
              <span *ngIf="type === 'TDD_TTY'">TDD/TTY</span>
            </option></select
          ><span class="icon-chevron-down"></span>
          <ds-form-control-message
            [control]="phoneListForm.get('typeOfUsageCode')"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
      <div
        *ngIf="phoneListForm.get('typeOfUsageCode').value === 'WORK'"
        class="column twelve sm-four pad-bottom-10 sm-pad-bottom-0"
      >
        <ds-form-control-container
          [labelText]="'Ext (Optional)'"
          [control]="phoneListForm.get('extension')"
        >
          <input
            type="tel"
            data-cy="workPhoneExtensionInput"
            id="extension"
            formControlName="extension"
            class="fill"
            maxlength="10"
          />
          <ds-form-control-message
            [control]="phoneListForm.get('extension')"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
      <div
        *ngIf="phoneListForm.get('typeOfUsageCode').value === 'OTHER'"
        class="column twelve sm-four pad-bottom-10 sm-pad-bottom-0"
      >
        <ds-form-control-container
          [labelText]="'Description'"
          [control]="phoneListForm.get('descriptionForOtherType')"
        >
          <input
            type="text"
            data-cy="otherPhoneDescriptionInput"
            id="descriptionForOtherType"
            formControlName="descriptionForOtherType"
            class="fill"
            maxlength="35"
          />
          <ds-form-control-message
            *ngIf="phoneListForm.hasError('required')"
            [control]="phoneListForm"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
    </div>
    <div class="row flex wrap">
      <div class="column twelve pad-top-10">
        <input
          type="checkbox"
          class="checkbox"
          data-cy="makePrimaryCheckbox"
          [disabled]="!(roleService.isAuthorized('make_primary_phone') | async)"
          id="makePrimaryPhoneOnSave"
          [checked]="this.makePrimaryOnSave"
          formControlName="primaryChecked"
        />

        <label
          for="makePrimaryPhoneOnSave"
          class="checkbox-label"
          content
          [contentPath]="'shared.makePrimaryPhoneText'"
        ></label>
        <p
          class="subText"
          content
          [contentPath]="'profile.managePhoneNumbers.primaryPhoneNumberUsageDescription'"
        ></p>
      </div>
    </div>
    <div class="row flex wrap">
      <div class="column twelve pad-top-10 null-phone">
        <button
          ds-button
          data-cy="submitPhoneNumberButton"
          type="submit"
          [disabled]="
            !phoneListForm.valid || !(roleService.isAuthorized('addphonenumber_submit') | async)
          "
          class="-outline float-left"
          content
          [contentPath]="'shared.saveBtn'"
        ></button>
        <button
          ds-button
          type="button"
          (click)="cancel()"
          content
          [contentPath]="'shared.cancel'"
          class="-white float-left bg-transparent cancel a margin-left-10"
        ></button>
      </div>
    </div>
  </form>
</div>

<div
  [ngClass]="{
    'margin-top-20': phones.length,
    'pad-top-10': !phones.length,
    'pad-bottom-10': !phones.length
  }"
>
  <button
    ds-button
    id="addPhoneNumBtn"
    [disabled]="isAddingPhone || !(roleService.isAuthorized('addphonenumber_view') | async)"
    class="-outline float-left"
    (click)="open()"
    data-cy="addPhoneNumberButton"
    content
    [contentPath]="'shared.addBtn'"
  ></button>
</div>

<!-- DP: New billing card component as of 02/14/2023 -->
<div class="tile">
  <div class="tile-heading-wrapper">
    <!-- Risk icons and status pill -->
    <div class="tile-heading">
      <p>Risk Icons</p>
      <span class="pill-border">
        <ds-billing-status-pill [statusText]="'Due Soon'"></ds-billing-status-pill>
      </span>
    </div>
    <!-- Bill account information -->
    <div>
      <p>Billing account end 9984</p>
      <p>$55.00 due</p>
      <p>
        2010 Subaru Legacy, 2016 Nissan Sentra, 2016 Hyundai Santa Fe XE, 2019 Hyundai Santa Fe XE
      </p>
      <p>Last Payment: 09/29/2022</p>
    </div>
  </div>
  <!-- Status message to customer -->
  <div class="tile-content-wrapper">
    <p>Message Text</p>
  </div>
  <div class="tile-cta-wrapper">
    <!-- Action button -->
    <button ds-button class="column md-six margin-right-8 -outline">Make Payment</button>
    <!-- View Account button -->
    <button ds-button class="column md-six -outline-white">View Account</button>
  </div>
</div>

// Angular
import { Injectable } from '@angular/core';
// Store
import { Actions, createEffect, ofType } from '@ngrx/effects';
// rxjs
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
// Models
// import { CreateDraftClaimResponse, DraftClaim } from '../models';
// Services
import { ClaimEnterpriseService } from '../services/claim-enterprise.service';
import {
  ClaimEnterpriseActionTypes,
  CreateEnterpriseClaim,
  fromClaimEnterpriseActions
} from './claim-enterprise.actions';

@Injectable()
export class ClaimEnterpriseEffects {
  claimCreate$ = createEffect(() =>
    // eslint-disable-next-line ngrx/prefer-effect-callback-in-block-statement
    this.action$.pipe(
      ofType(ClaimEnterpriseActionTypes.CreateClaim),
      map((action: CreateEnterpriseClaim) => action),
      switchMap(action =>
        this.claimEnterpriseService.createClaim(action.payload, action.isProperty).pipe(
          map((res: any) => new fromClaimEnterpriseActions.CreateEnterpriseClaimSuccess(res.data)),
          catchError(error => of(new fromClaimEnterpriseActions.CreateEnterpriseClaimFail(error)))
        )
      )
    )
  );
  constructor(private action$: Actions, private claimEnterpriseService: ClaimEnterpriseService) {}
}

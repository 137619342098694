import { PaymentMethodFacade } from '@amfam/billing/payment-method/data-access';
import {
  PaymentMethodAccountType,
  PaymentMethodAddEditConfig,
  PaymentMethodAddEditOperationType
} from '@amfam/billing/payment-method/util';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ds-add-payment-method-quicklinks-wrapper',
  templateUrl: './add-payment-method-quicklinks-wrapper.component.html',
  styleUrls: ['./add-payment-method-quicklinks-wrapper.component.scss']
})
export class AddPaymentMethodQuickLinksWrapper implements OnInit {
  @Output() addPaymentMethodEvent: EventEmitter<PaymentMethodAddEditConfig> =
    new EventEmitter<PaymentMethodAddEditConfig>();

  authorizedToAddPaymentMethod$: Observable<boolean>;
  authorizedToEditPaymentMethod$: Observable<boolean>;
  constructor(
    private roleService: ImpersonateRolesService,
    private paymentMethodFacade: PaymentMethodFacade
  ) {}

  ngOnInit(): void {
    this.authorizedToAddPaymentMethod$ = this.roleService.isAuthorized(
      'makepaymentacct_walkthrough'
    );
    this.authorizedToEditPaymentMethod$ = this.roleService.isAuthorized(
      'editpaymentacct_walkthrough'
    );
  }

  addCheckingAccount(): void {
    this.paymentMethodFacade.sendPaymentMethodAnalytics({
      buttonAnalyticName: 'AddPaymentMethod:CheckingAccount'
    });
    this.addPaymentMethodEvent.emit({
      accountType: PaymentMethodAccountType.CHECKING,
      operation: PaymentMethodAddEditOperationType.ADD,
      paymentMethod: null
    });
  }
  addSavingsAccount(): void {
    this.paymentMethodFacade.sendPaymentMethodAnalytics({
      buttonAnalyticName: 'AddPaymentMethod:SavingsAccount'
    });
    this.addPaymentMethodEvent.emit({
      accountType: PaymentMethodAccountType.SAVINGS,
      operation: PaymentMethodAddEditOperationType.ADD,
      paymentMethod: null
    });
  }
  addCreditDebit(): void {
    this.paymentMethodFacade.sendPaymentMethodAnalytics({
      buttonAnalyticName: 'AddPaymentMethod:CreditDebitCard'
    });
    this.addPaymentMethodEvent.emit({
      accountType: PaymentMethodAccountType.CREDIT_DEBIT,
      operation: PaymentMethodAddEditOperationType.ADD,
      paymentMethod: null
    });
  }
}

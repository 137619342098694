import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdjusterListComponent } from './component/adjuster-list.component';
import { DsRouterLinkModule } from '@amfam/shared/ui/ds-router-link';
import { PipesModule } from '@amfam/shared/ui/pipes';
import { BrandDirectivesModule } from '@amfam/shared/utility/brand';

@NgModule({
  imports: [CommonModule, DsRouterLinkModule, PipesModule, BrandDirectivesModule],
  declarations: [AdjusterListComponent],
  providers: [],
  exports: [AdjusterListComponent]
})
export class AdjusterListModule {}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'overview-section',
  templateUrl: './overview-section.component.html',
  styleUrls: ['./overview-section.component.scss']
})
export class OverviewSectionComponent {
  @Input() sectionHeading = '';
  @Input() loading = false;
  @Input() spinnerText = '';
}

// the below are all child components specific to `OverviewSectionComponent`

// header-link
@Component({
  selector: 'overview-section-header-link',
  template: ` <ng-content></ng-content> `
})
export class OverviewSectionHeaderLinkComponent {}

// content
@Component({
  selector: 'overview-section-content',
  template: ` <div class="flex wrap"><ng-content></ng-content></div> `
})
export class OverviewSectionContentComponent {}

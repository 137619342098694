import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'invalid-token',
  templateUrl: './invalid-token.component.html',
  styleUrls: ['./invalid-token.component.scss']
})
export class InvalidTokenComponent implements OnInit {
  public amfamChatUrl;
  public scheduleACallbackUrl;

  constructor(private activatedRoute: ActivatedRoute, private config: ConfigService) {}

  ngOnInit() {
    this.amfamChatUrl = this.config.get('links.amfamChatUrl');
    this.scheduleACallbackUrl = this.config.get('links.scheduleACallbackUrl');
  }
}

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { LoadingSpinnerService } from './loading-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerResolverService  {
  constructor(private loadingSpinnerService: LoadingSpinnerService) {}

  resolve(): Observable<boolean> {
    if (this.loadingSpinnerService.active) {
      this.loadingSpinnerService.stop();
    }
    document.getElementById('global-spinner').innerHTML = '';
    return of(true);
  }
}

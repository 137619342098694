import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get as _get } from 'lodash';
import {
  PendingRegistrationsActionTypes,
  PendingRegistrationsActions
} from './registration.actions';
import {
  PendingRegistration,
  initialPendingRegistration
} from '../models/pending-registration.model';

export const PENDING_REGISTRATION_FEATURE_KEY = 'prending registration';

export interface PendingRegistrationPartialState {
  readonly [PENDING_REGISTRATION_FEATURE_KEY]: PendingRegistrationEntityState;
}

export interface PendingRegistrationEntityState extends EntityState<PendingRegistration> {
  selectedBillAccountNumber: string;
}

export const adapter: EntityAdapter<PendingRegistration> = createEntityAdapter<PendingRegistration>(
  {
    selectId: (billingObjModel: PendingRegistration) => billingObjModel.billAccountNumber,
    sortComparer: false
  }
);

export const initialState: PendingRegistrationEntityState = adapter.getInitialState({
  selectedBillAccountNumber: ''
});

export function reducer(
  state: PendingRegistrationEntityState = initialState,
  action: PendingRegistrationsActions
): PendingRegistrationEntityState {
  switch (action.type) {
    case PendingRegistrationsActionTypes.PendingRegistrationsAddOne: {
      return adapter.upsertOne(_get(action, 'payload', initialPendingRegistration), state);
    }

    case PendingRegistrationsActionTypes.PendingRegistrationsAddMany: {
      return adapter.upsertMany(_get(action, 'payload', []), state);
    }

    case PendingRegistrationsActionTypes.PendingRegistrationsRemoveAll: {
      return adapter.removeAll(state);
    }

    case PendingRegistrationsActionTypes.PendingRegistrationsRemoveOne: {
      return adapter.removeOne(action.payload.billAccountNumber, state);
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

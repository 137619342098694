// date-fns
import { getYear, getMonth, getDate, getHours, getMinutes, format } from 'date-fns';

export class IncidentDate {
  year: string;
  hour: string;
  ampm: string;
  minute: string;
  month: string;
  day: string;
  value: string;

  constructor(value: string) {
    this.value = value;
    this.year = getYear(value).toString();
    this.hour = getHours(value).toString();
    this.ampm = format(value, 'A');
    this.minute = getMinutes(value).toString();
    this.month = (getMonth(value) + 1).toString();
    this.day = getDate(value).toString();
  }
}

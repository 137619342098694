<div class="flex footer-container pad-top-30 md-pad-right-30 md-pad-left-30 neutral-600-bg">
  <div class="column twelve pad-bottom-20 md-four text-center" id="left">
    <div>
      <a
        dsPartnerContent
        [brandHref]="'getLandingPageUrl'"
        target="amfam"
        [attr.title]="footerLogo?.altText ? footerLogo.altText : 'logo'"
      >
        <div
          class="logo-container column text-center fixed-200"
          [style.backgroundImage]="'url(' + footerLogo?.logoURL + ')'"
          [style.height.px]="footerLogo?.height"
        ></div>
      </a>
    </div>
  </div>
  <div class="vertical-rule none md-block" *ngIf="showContactInfo"></div>
  <div class="contact-info column twelve md-four" id="center" *ngIf="showContactInfo">
    <div class="row flex wrap justify-center items-center">
      <div>
        <span class="icon-call"></span>
        <a
          dsPartnerContent
          [brandContent]="'getPrettyCusCareNumber'"
          [brandHref]="'getCusCareNumberFullHref'"
          class="inline-block"
        ></a>
        <p dsPartnerContent [brandContent]="'getCusCareHoursWeekday'" class="neutral-200-text"></p>
        <p dsPartnerContent [brandContent]="'getCusCareHoursFriday'" class="neutral-200-text"></p>
        <p dsPartnerContent [brandContent]="'getCusCareHoursWeekend'" class="neutral-200-text"></p>
      </div>
    </div>
  </div>
  <div class="vertical-rule none lg-block" *ngIf="showContactInfo"></div>
  <hr
    aria-hidden="true"
    class="column twelve none md-margin-bottom lg-margin-bottom-20 md-block lg-none"
  />
  <div class="column twelve lg-four" id="right" *ngIf="showContactInfo">
    <div class="row flex wrap pad-top-30 md-pad-top-0 justify-center">
      <a dsPartnerContent [brandHref]="'getCusCareEmailHref'" class="inline-block">
        <span class="icon-email pad-right-5"></span>
        <span dsPartnerContent [brandContent]="'getCusCareEmail'"></span>
      </a>
    </div>
  </div>
</div>

<div
  class="
    row
    pad-top-10 pad-bottom-30
    md-pad-top-5 md-pad-bottom-0 md-pad-right-30 md-pad-left-30
    neutral-600-bg
  "
>
  <hr aria-hidden="true" class="md-margin-top-10 lg-margin-top-20" />
  <ul class="column twelve pad-top-10 md-pad-top-0 md-flex wrap justify-space-between text-center">
    <li class="pad-bottom md-pad-right">
      <a [dsRouterLink]="['/company-information']">Company Information </a>
    </li>
    <li class="pad-bottom md-pad-right">
      <a [dsRouterLink]="['/privacy-security-policy']">Privacy &amp; Security Policy</a>
    </li>
    <li class="pad-bottom md-pad-right"><a [dsRouterLink]="['/legal-notice']">Legal Notice</a></li>
    <li *ngIf="showSiteMap" class="pad-bottom md-pad-right">
      <a [dsRouterLink]="['/sitemap']">Sitemap </a>
    </li>
    <li class="md-none pad-bottom-0"><hr aria-hidden="true" /></li>
    <li class="md-text-left">
      <small>
        &copy;{{ currentYear }} American Family Mutual Insurance Company, S.I. All rights
        reserved.</small
      >
    </li>
  </ul>
</div>

import { Enrollment } from './enrollment.model';
import { UserState } from '@amfam/shared/user';

export class EnrollConfirmationModel {
  public anyErrors: boolean;

  constructor(public enrollments: Array<Enrollment>, public user: UserState) {
    this.anyErrors =
      enrollments.length > 0 ? enrollments.some(enrollment => !enrollment.success) : true;
  }
}

import { Action } from '@ngrx/store';

export enum ClaimGlassOnlyActionTypes {
  LoadGlassOnlyClaims = '[ClaimGlassOnly] Load Glass Only Claims',
  LoadGlassOnlyClaimsSuccess = '[ClaimGlassOnly] Load Glass Only Claims Success',
  LoadGlassOnlyClaimsFail = '[ClaimGlassOnly] Load Glass Only Claims Fail'
}

export class LoadGlassOnlyClaims implements Action {
  readonly type = ClaimGlassOnlyActionTypes.LoadGlassOnlyClaims;
  constructor(public payload?: any) {}
}

export class LoadGlassOnlyClaimsSuccess implements Action {
  readonly type = ClaimGlassOnlyActionTypes.LoadGlassOnlyClaimsSuccess;
  constructor(public payload?: any) {}
}

export class LoadGlassOnlyClaimsFail implements Action {
  readonly type = ClaimGlassOnlyActionTypes.LoadGlassOnlyClaimsFail;
  constructor(public payload?: any) {}
}

export type ClaimGlassOnlyAction =
  | LoadGlassOnlyClaims
  | LoadGlassOnlyClaimsSuccess
  | LoadGlassOnlyClaimsFail;

export const fromClaimGlassOnlyActions = {
  LoadGlassOnlyClaims,
  LoadGlassOnlyClaimsSuccess,
  LoadGlassOnlyClaimsFail
};

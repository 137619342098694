import { Injectable } from '@angular/core';
import { CreateDraftClaimModel } from '../models/create-draft-claim.model';
import { LossLocationAddress } from '../+state/fnol.model';
import {
  get as _get,
  upperFirst as _upperFirst,
  has as _has,
  startCase as _startCase,
  keys as _keys,
  pickBy as _pickBy
} from 'lodash';
import { format, endOfDay, subDays, isAfter } from 'date-fns';
import { FnolModel } from '../+state/fnol.model';
import { SubmitQuestionApiModel } from '../models/submit-question-api.model';
import { SubmitQuestionStoreModel } from '../models/submit-question-store.model';
import { FnolQuestionTypes } from '../models/question-types';
import { GenericProductType } from '@amfam/policy/models';
import {
  VehicleDetails,
  VehicleDamagePayloadModel,
  ThirdPartyContactPayloadModel,
  ClaimantContactPayloadModel,
  VehicleDamage,
  InsuredContactPayloadModel,
  ThirdPartyContactForm,
  ClaimantContactForm,
  VehicleDamageConstants,
  ClaimContact
} from '@amfam/shared/models';
import { DriverDefaults } from '../models/driver-types';
import {
  Applications,
  ApplicationService,
  ConfigService
} from '@amfam/shared/utility/shared-services';

@Injectable({
  providedIn: 'root'
})
export class FnolTransmuteService {
  constructor(private applicationService: ApplicationService, private config: ConfigService) {}

  public createDraftClaimPayload(
    payload: FnolModel,
    options?: {
      lossLocationFormData?: any;
      addReportedBy?: boolean;
      reportedBy?: number;
      addVehicleDetails?: boolean;
      isPartner?: boolean;
    }
  ): CreateDraftClaimModel {
    const createDraftClaimPayload: CreateDraftClaimModel = {
      lobCode:
        _get(payload, 'risk.generalizedProductType') === GenericProductType.Home
          ? 'HomeownersLine_HOE'
          : 'PersonalAutoLine',
      lossDate: payload.lossDate,
      openDate: format(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
      lossLocationAddress: this.buildLossLocationAddress(
        payload,
        _get(options, 'lossLocationFormData')
      ),
      policyNumber: _get(payload, 'risk.policyNumber'),
      reportedOn: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      reportedTime: '',
      sourceSystem: options.isPartner ? 'MidvaleWebApplication' : this.config.get('afiAppName')
    };

    // add claim contacts and reportedBy
    if (!this.applicationService.isApp(Applications.THIRD_PARTY_FNOL)) {
      createDraftClaimPayload.claimContacts = [
        {
          contact: {
            contactId: 1,
            contactType: 'Person',
            firstName: payload.firstName,
            sourceSystemIdentifier: payload.cdhId,
            isThirdParty: 'NO',
            lastName: payload.lastName,
            newPrimaryPhoneEmail: {
              country: 'United States',
              area: _get(payload, 'phoneNumber', '').slice(0, 3),
              number: _get(payload, 'phoneNumber', '').slice(3, 10),
              type: 'Cell',
              emailAddress: payload.emailAddress,
              emailType: 'Primary'
            }
          }
        }
      ];
      createDraftClaimPayload.reportedBy = {
        contactId: 1
      };
      createDraftClaimPayload.insuredContactInfo = {
        contactId: 1
      };
      createDraftClaimPayload.reportedByType = 'Self';
    }

    // add reportedBy
    if (
      this.applicationService.isApp(Applications.THIRD_PARTY_FNOL) &&
      _get(options, 'addReportedBy', false)
    ) {
      createDraftClaimPayload.reportedBy = {
        contactId: _get(options, 'reportedBy', 1)
      };
      createDraftClaimPayload.reportedByType = 'Claimant';
    }

    // add vehicle details
    const vehicleDetailsPayload: VehicleDetails[] = [];
    if (
      !this.applicationService.isApp(Applications.THIRD_PARTY_FNOL) ||
      _get(options, 'addVehicleDetails', false)
    ) {
      if (_get(payload, 'risk.vehicleTypeName') === DriverDefaults.VEHICLE_NOT_ON_POLICY) {
        vehicleDetailsPayload.push({
          vehLossIsThirdParty: 'NO',
          vehTypeVin: '',
          vehTypeYear: '1000',
          vehTypeMake: 'UNK',
          vehTypeModel: 'UNK'
        });
        createDraftClaimPayload.vehicleNotOnPolicy = true;
      } else {
        vehicleDetailsPayload.push({
          vehLossIsThirdParty: 'NO',
          vehTypeVin: _get(payload, 'risk.vin'),
          vehTypeYear: _get(payload, 'risk.year'),
          vehTypeMake: _get(payload, 'risk.make'),
          vehTypeModel: _get(payload, 'risk.model')
        });
      }
    }

    // Only add a contactId if we have a driver
    if (_get(payload, 'driver.firstName') && _get(payload, 'driver.lastName')) {
      // Check if driver and insured aren't the same person. If they aren't, make a new
      // contact for driver, else make sure insured is contact (contactId: 1)
      if (
        !_get(payload, 'driver.firstName', '')
          .toUpperCase()
          .split(' ')
          .includes(_get(payload, 'firstName', '').toUpperCase()) ||
        !_get(payload, 'driver.lastName', '')
          .toUpperCase()
          .split(' ')
          .includes(_get(payload, 'lastName', '').toUpperCase())
      ) {
        createDraftClaimPayload.claimContacts.push({
          contact: {
            contactId: 2,
            contactType: 'Person',
            firstName: _startCase(_get(payload, 'driver.firstName', '').toLowerCase()),
            lastName: _startCase(_get(payload, 'driver.lastName', '').toLowerCase()),
            isThirdParty: 'NO'
          }
        });

        vehicleDetailsPayload[0].vehLossDriverContact = {
          contactId: 2
        };
      } else {
        vehicleDetailsPayload[0].vehLossDriverContact = {
          contactId: 1
        };
      }
    }

    // Append vehicleDetails to create draftclaim payload for Auto.
    if (_get(payload, 'risk.generalizedProductType') === GenericProductType.Auto) {
      const payloadWithVehicleDetails = {
        ...createDraftClaimPayload,
        vehicleDetails: vehicleDetailsPayload
      };
      return payloadWithVehicleDetails;
    } else {
      // Property payload
      return createDraftClaimPayload;
    }
  }

  private buildLossLocationAddress(
    payload: FnolModel,
    lossLocationFormData: any
  ): LossLocationAddress[] {
    if (_get(payload, 'risk.generalizedProductType') === GenericProductType.Home) {
      return [
        {
          addressLine1: _get(payload, 'risk.address.addressLine1'),
          addressLine2: _get(payload, 'risk.address.addressLine2'),
          city: _get(payload, 'risk.address.city'),
          state: _get(payload, 'risk.address.state'),
          zip: _get(payload, 'risk.address.zipCode')
        }
      ];
    } else {
      return [
        {
          city: _get(
            lossLocationFormData,
            'incidentCity',
            _get(payload, 'lossLocationAddress[0].city', '')
          ),
          state: _get(
            lossLocationFormData,
            'incidentState',
            _get(payload, 'lossLocationAddress[0].state', '')
          )
        }
      ];
    }
  }

  createInitiateDynamicQuestionPayload(
    draftClaimNumber: string,
    createDraftClaimPayload: CreateDraftClaimModel
  ) {
    return {
      draftClaimNumber: draftClaimNumber.replace(/-/g, ''),
      payload: {
        answer: null,
        draftClaimInfo: createDraftClaimPayload
      }
    };
  }

  createSubmitQuestionPayload(
    selectedAnswer: string,
    payload: SubmitQuestionStoreModel
  ): SubmitQuestionApiModel {
    if (payload.questionType === FnolQuestionTypes.SINGLE_SELECT) {
      return {
        answer: {
          questionId: payload.questionId,
          singleSelect: selectedAnswer
        },
        draftClaimInfo: null
      };
    } else if (payload.questionType === FnolQuestionTypes.MULTI_SELECT) {
      return {
        answer: {
          questionId: payload.questionId,
          multipleSelect: selectedAnswer
        },
        draftClaimInfo: null
      };
    } else if (payload.questionType === FnolQuestionTypes.FREE_FORM) {
      return {
        answer: {
          questionId: payload.questionId,
          freeForm: selectedAnswer
        },
        draftClaimInfo: null
      };
    }
  }

  createVehicleDamageQuestionPayload(
    payload: VehicleDamagePayloadModel,
    questionInfo: SubmitQuestionStoreModel
  ) {
    return {
      answer: {
        questionId: questionInfo.questionId,
        vehicleDamagePicker: payload
      },
      draftClaimInfo: null
    };
  }

  createThirdPartyContactFormQuestionPayload(
    payload: ThirdPartyContactPayloadModel[],
    questionInfo: SubmitQuestionStoreModel
  ) {
    return {
      answer: {
        questionId: questionInfo.questionId,
        thirdPartyContactForm: payload
      },
      draftClaimInfo: null
    };
  }

  // DM: need to submit the draftclaimpayload again so that we
  // can set the claimant contact ID in the reportedBy field
  createClaimantContactFormQuestionPayload(
    payload: ClaimantContactPayloadModel,
    questionInfo: SubmitQuestionStoreModel,
    createDraftClaimPayload: CreateDraftClaimModel
  ) {
    return {
      answer: {
        questionId: questionInfo.questionId,
        claimantContactForm: payload
      },
      draftClaimInfo: createDraftClaimPayload
    };
  }

  createInsuredQuestionPayload(
    payload: { emailAddress: string; phoneNumber: string },
    questionInfo: SubmitQuestionStoreModel
  ): SubmitQuestionApiModel {
    return {
      answer: {
        questionId: questionInfo.questionId,
        insuredContactType: {
          email: payload.emailAddress,
          areaCode: payload.phoneNumber.slice(0, 3),
          phoneNumber: payload.phoneNumber.slice(3, 10)
        }
      },
      draftClaimInfo: null
    };
  }

  filterFnols(fnols: FnolModel[]): FnolModel[] {
    // Filter any FNOLs that are older than 30 days ago
    return fnols.filter(fnol => {
      let include = false;
      if (_has(fnol, 'openDate')) {
        // Determine if the openDate is within the last 30 days.
        // Subtract 31 days from today, set Date obj to the end of that day. Make sure the openDate is after that
        const lowerBounds = endOfDay(subDays(new Date(), 31));
        if (isAfter(_get(fnol, 'openDate'), lowerBounds)) {
          include = true;
        }
      }
      return include;
    });
  }

  public getThirdPartyPayload(
    contactForm: ThirdPartyContactForm[],
    existingContactsLength: number
  ): ThirdPartyContactPayloadModel[] {
    const thirdPartyApiPayload: ThirdPartyContactPayloadModel[] = [];
    contactForm.forEach((contact, index) => {
      thirdPartyApiPayload.push(
        this.getThirdpartyContactAndVehiclePayload(contact, existingContactsLength + index)
      );
    });

    return thirdPartyApiPayload;
  }

  private getThirdpartyContactAndVehiclePayload(
    contactForm: ThirdPartyContactForm,
    index: number
  ): ThirdPartyContactPayloadModel {
    return {
      claimContact: {
        contact: {
          contactId: index + 1,
          firstName: _get(contactForm, 'firstName', ''),
          lastName: _get(contactForm, 'lastName', ''),
          newAddress: [
            {
              addressLine1: _get(contactForm, 'addressLine1', ''),
              addressLine2: _get(contactForm, 'addressLine2', ''),
              city: _get(contactForm, 'city', ''),
              state: _get(contactForm, 'state', ''),
              zip: _get(contactForm, 'zipCode', '')
            }
          ],
          newPrimaryPhoneEmail: {
            number: _get(contactForm, 'phoneNumber', '').replace(/-/g, ''), // Remove hyphens
            type: 'Cell',
            emailAddress: _get(contactForm, 'emailAddress', ''),
            emailType: 'Primary'
          },
          contactType: 'Person',
          isThirdParty: 'YES'
        }
      },
      vehicleDetail: {
        vehLossDriverContact: {
          contactId: index + 1
        },
        vehLossIsThirdParty: 'YES',
        vehTypeYear: _get(contactForm, 'vehicleYear', ''),
        vehTypeMake: _get(contactForm, 'vehicleMake', ''),
        vehTypeModel: _get(contactForm, 'vehicleModel', '')
      },
      isThirdPartyInjured: _get(contactForm, 'injury') === true ? 'YES' : ''
    };
  }

  getClaimantContactPayload(
    contactForm: ClaimantContactForm,
    index: number
  ): ClaimantContactPayloadModel {
    return {
      claimContact: {
        contact: {
          contactId: index + 1,
          firstName: _get(contactForm, 'firstName', ''),
          lastName: _get(contactForm, 'lastName', ''),
          newAddress: [
            {
              addressLine1: _get(contactForm, 'addressLine1', ''),
              addressLine2: _get(contactForm, 'addressLine2', ''),
              city: _get(contactForm, 'city', ''),
              state: _get(contactForm, 'state', ''),
              zip: _get(contactForm, 'zipCode', '')
            }
          ],
          newPrimaryPhoneEmail: {
            country: 'United States',
            area: _get(contactForm, 'phoneNumber', '').replace(/-/g, '').slice(0, 3),
            number: _get(contactForm, 'phoneNumber', '').replace(/-/g, '').slice(3, 10),
            type: 'Cell',
            emailAddress: _get(contactForm, 'emailAddress', ''),
            emailType: 'Primary'
          },
          contactType: 'Person',
          isThirdParty: 'YES'
        }
      },
      vehicleDetail: {
        vehLossDriverContact: {
          contactId: index + 1
        },
        vehLossIsThirdParty: 'YES',
        vehTypeYear: _get(contactForm, 'vehicleYear', ''),
        vehTypeMake: _get(contactForm, 'vehicleMake', ''),
        vehTypeModel: _get(contactForm, 'vehicleModel', '')
      },
      isDriver: _get(contactForm, 'isDriver') === true ? 'YES' : ''
    };
  }

  public getVehicleDamagePayload(vehicleDamageFormValue: any): VehicleDamagePayloadModel {
    let vehicleDamages: VehicleDamage[] = [];

    const otherDamageValue = _get(vehicleDamageFormValue, 'other-damage');

    if (otherDamageValue !== 'some-damage') {
      vehicleDamages = [
        {
          vehicleDamage: otherDamageValue === 'no-damage' ? 'ND' : 'VA'
        }
      ];
    } else {
      // Gather selected damage parts
      const selectedDamageParts = _keys(_pickBy(vehicleDamageFormValue));

      // Gather correct ids associated to damaged parts.
      selectedDamageParts.forEach(partName => {
        const selectedDamagedPart = VehicleDamageConstants.find(part => part.name === partName);
        if (selectedDamagedPart) {
          vehicleDamages.push({ vehicleDamage: _get(selectedDamagedPart, 'id') });
        }
      });
    }

    return {
      vehicleDamages: vehicleDamages,
      vehDamageDamageDescription: _get(vehicleDamageFormValue, 'additional-damage'),
      damageType: this.getVehicleDamageType(vehicleDamageFormValue)
    };
  }

  public getVehicleDamageType(vehicleDamageFormValue: any): string {
    switch (_get(vehicleDamageFormValue, 'other-damage')) {
      case 'some-damage':
        return 'partialDamage';
      case 'no-damage':
        return 'noDamage';
      default:
        return 'totalLoss';
    }
  }

  public getPrimaryInsuredContactPayload(
    phoneNumber: string,
    emailAddress: string
  ): InsuredContactPayloadModel {
    return {
      country: 'United States',
      area: phoneNumber.slice(0, 3),
      number: phoneNumber.slice(3, 10),
      type: 'Cell',
      emailAddress: emailAddress,
      emailType: 'Primary'
    };
  }

  public checkForExistingDraftClaims(
    lossDate: string,
    vin: string,
    riskAddressLine1: string,
    draftClaimsList: FnolModel[]
  ) {
    return draftClaimsList.filter(draftClaim => {
      return (
        _get(draftClaim, 'lossDate', '').split(' ')[0] === lossDate.split(' ')[0] &&
        !!_get(draftClaim, 'claimNumber') && // Don't return the draft we're currently making
        ((riskAddressLine1 &&
          riskAddressLine1.toLowerCase() === this.getRiskAddressLine1(draftClaim).toLowerCase()) || // Property claim with matching address
          (!!vin && // Or auto claim with matching vin
            this.getVin(draftClaim) === String(vin)))
      );
    });
  }

  /**
   *
   * @param draftClaim - VIN retunrd by API is different format than VIN saved in store to create draft claim.
   * this method will extract vin accordingly
   */
  private getVin(draftClaim: FnolModel): string {
    let vin = '';
    if (_get(draftClaim, 'autoClaimDetail')) {
      vin = _get(draftClaim, 'autoClaimDetail.vehicles[0].vin');
    } else {
      const vehicleDetail = _get(draftClaim, 'vehicleDetails', []).find(
        vehicle => _get(vehicle, 'vehLossIsThirdParty', '').toUpperCase() === 'NO'
      );
      vin = _get(vehicleDetail, 'vehTypeVin');
    }

    return vin;
  }

  private getRiskAddressLine1(fnol: FnolModel): string {
    return (
      _get(fnol, 'lossLocationAddress.addr1', '') ||
      _get(fnol, 'lossLocationAddress[0].addressLine1', '')
    );
  }
}

import { ActionReducerMap } from '@ngrx/store';

import * as fromDigitalAccount from './digital-account/digital-account.reducer';
import * as fromSecurityQuestions from './security-questions/security-questions.reducer';

/** REDUCERS */

import { createFeatureSelector } from '@ngrx/store';

export const digitalAccountFeatureKey = 'digitalAccount';

export interface DigitalAccountFeatureState {
  digitalAccount: fromDigitalAccount.DigitalAccountState;
  securityQuestions: fromSecurityQuestions.SecurityQuestionsState;
}

export const reducers: ActionReducerMap<DigitalAccountFeatureState> = {
  digitalAccount: fromDigitalAccount.reducer,
  securityQuestions: fromSecurityQuestions.reducer
};

export const getDigitalAccountFeatureState = createFeatureSelector<DigitalAccountFeatureState>(
  digitalAccountFeatureKey
);

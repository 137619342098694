<form [formGroup]="form" (ngSubmit)="submitPolicyNum(form.controls['policyNumber'].value)">
  <div class="row">
    <div class="column twelve md-eight">
      <ds-form-control-container
        [labelText]="'Policy Number (Dashes not required)'"
        [control]="form.controls['policyNumber']"
      >
        <input
          data-cy="verifyPolicyNumInput"
          formControlName="policyNumber"
          type="text"
          class="fill"
        />
        <ds-form-control-message
          [control]="form.controls['policyNumber']"
        ></ds-form-control-message>
      </ds-form-control-container>
    </div>
    <div class="column twelve md-four">
      <div class="md-pad-left-10 pad-top-10 pad-bottom-10">
        <button
          data-cy="verifyPolicyNumContinueButton"
          ds-button
          content
          [contentPath]="'shared.nextBtn'"
          type="submit"
          [disabled]="!form.valid"
        ></button>
      </div>
    </div>
  </div>
</form>

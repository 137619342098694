export interface BillingStatusPill {
  statusText: string;
  pillColor: string;
  amountColor: string;
}

export enum BillingStatusEnums {
  SCHEDULED = 'Scheduled',
  DUE_SOON = 'Due Soon',
  PROCESSING = 'Processing',
  PAID = 'Paid',
  PAST_DUE = 'Past Due',
  DECLINED = 'Declined',
  FAILED = 'Failed',
  RETURNED = 'Returned',
  AUTO_PAY = 'AutoPay',
  AFT = 'AFT',
  ESCROW = 'Escrow',
  CANCELED = 'Canceled'
}

import { Action } from '@ngrx/store';
import { PolicyDocument, PolicySummary } from '@amfam/policy/models';

export enum DocumentsActionTypes {
  LoadDocuments = '[Documents] Load Documents',
  LoadDocumentsSuccess = '[Documents] Load Success',
  LoadDocumentsComplete = '[Documents] Documents Load Complete',
  LoadDocumentsError = '[Documents] Documents Load Error',
  DocumentView = '[Documents] View',
  DocumentDismissNotification = '[Documents] Dismiss Notification'
}

export class LoadDocuments implements Action {
  readonly type = DocumentsActionTypes.LoadDocuments;
  constructor(public payload: PolicySummary[]) {}
}

export class LoadDocumentsSuccess implements Action {
  readonly type = DocumentsActionTypes.LoadDocumentsSuccess;
  constructor(public payload: PolicyDocument[]) {}
}

export class LoadDocumentsError implements Action {
  readonly type = DocumentsActionTypes.LoadDocumentsError;
  constructor(public payload?: any) {}
}

export class LoadDocumentsComplete implements Action {
  readonly type = DocumentsActionTypes.LoadDocumentsComplete;
  constructor(public payload?: any) {}
}

export class ViewAction implements Action {
  readonly type = DocumentsActionTypes.DocumentView;
  constructor(public payload: string) {}
}

export class DismissNotificationAction implements Action {
  readonly type = DocumentsActionTypes.DocumentDismissNotification;
  constructor(public payload: string) {}
}

export type DocumentsAction =
  | LoadDocuments
  | LoadDocumentsComplete
  | LoadDocumentsError
  | LoadDocumentsSuccess
  | ViewAction
  | DismissNotificationAction;

export const fromDocumentsActions = {
  LoadDocuments,
  LoadDocumentsComplete,
  LoadDocumentsError,
  LoadDocumentsSuccess,
  ViewAction,
  DismissNotificationAction
};

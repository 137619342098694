import { PaymentMethod } from '@amfam/billing/payment-method/util';
import { CurrencyUSDPipe } from '@amfam/shared/ui/pipes';
import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ds-scheduled-payments-detail',
  templateUrl: './scheduled-payments-detail.component.html',
  styleUrls: ['./scheduled-payments-detail.component.scss']
})
export class ScheduledPaymentsDetailComponent implements OnInit {
  @Input() payment: any;
  @Input() paymentAccount: PaymentMethod;
  paymentAmount: string;
  paymentDate: string;
  constructor() {}

  ngOnInit(): void {
    // get correct payment amount value
    this.paymentAmount = this.payment.billAcctPaymentAmount
      ? new CurrencyUSDPipe().transform(this.payment.billAcctPaymentAmount)
      : this.payment.paymentAmount === 'FullPay'
      ? 'All at once'
      : this.payment.paymentAmount === 'MinDue'
      ? 'Monthly'
      : null;

    // get correct payment date value
    this.paymentDate = this.payment.date
      ? formatDate(this.payment.date, 'EEE, MMM d', 'en-US')
      : parseInt(this.payment.daysBeforeDueDate, 10) === 0
      ? 'On the due Date'
      : parseInt(this.payment.daysBeforeDueDate, 10) === 1
      ? '1 day before due date'
      : this.payment.daysBeforeDueDate > 1
      ? `${this.payment.daysBeforeDueDate} days before due date`
      : null;
  }
}

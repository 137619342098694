import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditCardMaskDirective } from './creditCard-mask.directive';
import { DigitMaskDirective } from './digit-mask.directive';
import { DateOfBirthMaskDirective } from './dob-mask.directive';
import { PhoneMaskDirective } from './phone-mask.directive';
import { SsnMaskDirective } from './ssn-mask.directive';
import { PhoneMaskWithoutFormDirective } from './phone-mask-without-form.directive';
import { ExpirationDateMaskDirective } from './expiration-date-mask.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CreditCardMaskDirective,
    DigitMaskDirective,
    DateOfBirthMaskDirective,
    PhoneMaskDirective,
    PhoneMaskWithoutFormDirective,
    SsnMaskDirective,
    ExpirationDateMaskDirective
  ],
  exports: [
    CreditCardMaskDirective,
    DigitMaskDirective,
    DateOfBirthMaskDirective,
    PhoneMaskDirective,
    PhoneMaskWithoutFormDirective,
    SsnMaskDirective,
    ExpirationDateMaskDirective
  ]
})
export class MasksModule {}

import { from as observableFrom, Observable, of } from 'rxjs';

import { tap, withLatestFrom, map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ConfigService } from '@amfam/shared/utility/shared-services/src/lib/config';
import { CookiesService } from '@amfam/shared/utility/shared-services/src/lib/cookies/cookies.service';
import { AgentState } from './agent.reducer';
import { Agent, initialAgent } from '../models/agent.model';
import {
  fromAgentActions,
  AgentActionTypes,
  LoadAgentAction,
  LoadAgentsSuccessAction
} from './agent.actions';
import { get as _get } from 'lodash';
import { agentQuery } from './agent.selectors';

@Injectable()
export class AgentEffects {
  
  loadAgent$ = createEffect(() => this.action$.pipe(
    ofType(AgentActionTypes.LoadAgents),
    map((action: LoadAgentAction) => action.payload),
    mergeMap(payload => {
      const options = this.getOptions();
      const url: string =
        this.config.get('producerApi') + `producers/${payload.id}?levelsofdetail=EXTENDED`;
      return this.http.get(url, options).pipe(
        map((data: any) =>
          Object.assign({}, initialAgent, {
            id: _get(data, 'producer.producerId'),
            agentId: _get(data, 'producer.producerId'),
            globalNickname: _get(data, 'producer.alternateId.globalNickname'),
            fullName: _get(data, 'producer.fullName'),
            firstName: _get(data, 'producer.firstName'),
            middleName: _get(data, 'producer.middleName'),
            lastName: _get(data, 'producer.lastName'),
            nickName: _get(data, 'producer.nickName'),
            offices: _get(data, 'producer.offices'),
            websiteUrl: _get(data, 'producer.producerDetail.webSiteUrl'),
            emailFormUrl: data?.producer?.producerDetail?.emailFormUrl,
            photoUrl: _get(data, 'producer.producerDetail.photoOfAgentUrl'),
            isAmericanStarCertified: _get(data, 'producer.producerDetail.americanStarIndicator'),
            // Underlying data stucture for getting the emails is not reliable at this time
            emails: _get(data, 'producer.contactDetail.emails', []),
            agencyEmails: _get(data, 'producer.contactDetail.agencyEmails', []),
            phones: _get(data, 'producer.contactDetail.phones', [])
          })
        ),
        map((agent: Agent) => new fromAgentActions.LoadAgentsSuccessAction(agent)),
        catchError(err => of(new fromAgentActions.LoadAgentsFailAction(err)))
      );
    })
  ));

  
  updateAgentCookie$ = createEffect(() => this.action$.pipe(
    ofType(AgentActionTypes.LoadAgentsSuccess),
    map((action: LoadAgentsSuccessAction) => action.payload),
    withLatestFrom(this.store.select(agentQuery.getAgents)),
    map(([payload, agents]) => {
      agents = agents === undefined ? [] : agents;
      // get unique nicknames from new payload plus what is already in store
      return [
        ...Array.from(
          new Set([
            // global nickname from new payload
            payload.globalNickname,
            // global nicknames from store
            ...agents
              // get global nickname for each agent in store
              .map(agent => agent.globalNickname)
          ])
        )
      ].filter(globalNickname => globalNickname !== undefined);
    }),
    tap(globalNicknames => {
      const expirationDate = new Date();
      expirationDate.setUTCFullYear(expirationDate.getUTCFullYear() + 2);

      // remove agentID cookie if there are no agents, or update if there are agents
      globalNicknames.length === 0
        ? this.cookies.removeItem('agentID', '/', '.amfam.com')
        : this.cookies.setItem(
            'agentID',
            globalNicknames.join('~'),
            expirationDate,
            '/',
            '.amfam.com'
          );
    })
  ), { dispatch: false });

  private getOptions() {
    const headers = new HttpHeaders({ authId: 'cdhtp1', userId: 'cdhtp1' });
    return { headers: headers };
  }

  constructor(
    private store: Store<AgentState>,
    private action$: Actions,
    private config: ConfigService,
    private cookies: CookiesService,
    private http: HttpClient
  ) {}
}

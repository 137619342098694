export interface EnrollStepsModel {
  identificationStep?: boolean;
  verificationStep?: boolean;
  personalSignUpStep?: boolean;
  personalSecurityQuestionsStep?: boolean;
  businessEnrollStep?: boolean;
  businessSignUpStep?: boolean;
  businessSecurityQuestionsStep?: boolean;
}

export const initialState: EnrollStepsModel = {
  identificationStep: false,
  verificationStep: false,
  personalSignUpStep: false,
  personalSecurityQuestionsStep: false,
  businessEnrollStep: false,
  businessSignUpStep: false,
  businessSecurityQuestionsStep: false
};

<mat-form-field [formGroup]="formGroup" appearance="outline" style="width: 100%">
  <input
    data-cy="datePickerInput"
    matInput
    [min]="matDatePickerOptions.minDate"
    [max]="matDatePickerOptions.maxDate"
    [matDatepicker]="picker"
    formControlName="paymentDatePicker"
    [disabled]="matDatePickerOptions.isDisabled"
    (dateChange)="datePickerChangeEvent.emit($event)"
    readonly
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
</mat-form-field>

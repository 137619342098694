<ds-table-body-row-wrapper [rowDetail]="rowDetail">
  <ds-table-body-row
    *ngFor="let row of rows"
    [columns]="columns"
    [tableClasses]="tableClasses"
    [row]="row"
    [rowDetail]="rowDetail"
  >
  </ds-table-body-row>
</ds-table-body-row-wrapper>

import { PaymentAccount } from '@amfam/shared/models';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { Component, Input, OnInit } from '@angular/core';
import { has as _has } from 'lodash';

@Component({
  selector: 'ds-payment-method-block',
  templateUrl: './payment-method-block.component.html',
  styleUrls: ['./payment-method-block.component.scss']
})
export class PaymentMethodBlockComponent implements OnInit {
  // Drive styling when detail rows are expanded. Values are 0 || 1
  @Input() rowExpanded = false;

  // Optional parameter, when passed in the template will be driven off this object
  @Input() paymentAccount: PaymentAccount;

  // Determine if we should use the nickname or the paymentType for the title value
  @Input() useNickNameAsTitle = true;

  /*
    When using the Payment Type as an inputs (returned from /registeredpayments)
    (i.e. on Payment History)

    Valid Payment Type values:

    Checking/Savings Account
    Check
    Money Order
    Automated Funds Withdrawal
    OCR
    Third Party Interest
    Bill Payer Service
    Debit/CreditCard
    Cash

  */
  @Input() paymentType: any;

  // When initializing with paymentType, the nickname may be passed in
  @Input() nickName: string;

  public icon: string;
  public title: string;
  public accountNumber: string;
  public subLine1: string;

  public paymentMethodsText: any[] = [];

  constructor(private copyService: CopyService) {
    this.paymentMethodsText = this.copyService.getCopy('billing.paymentMethodsCondensed');
  }

  ngOnInit() {
    if (this.paymentAccount) {
      this.setupWithPaymentAccount();
    } else {
      this.setupWithPaymentType();
    }
  }

  private setupWithPaymentAccount() {
    // Derive the title
    if (this.useNickNameAsTitle === true && _has(this.paymentAccount, 'nickName')) {
      /**
       * AS: If the nick name is being passed and is different than the payment account nickname
       * then the passed nick name is the latest one.
       */
      if (this.nickName && this.nickName !== this.paymentAccount?.nickName) {
        this.title = this.nickName;
      } else {
        this.title = this.paymentAccount?.nickName;
      }
    } else {
      if (_has(this.paymentAccount, 'creditCard.cardType')) {
        this.title = this.paymentMethodsText[0];
      } else {
        this.title = this.paymentMethodsText[1];
      }
    }

    // Derive the icon, account number and financial institution name
    if (_has(this.paymentAccount, 'creditCard.cardType')) {
      this.icon = this.paymentAccount.creditCard.cardType;
      this.accountNumber = this.paymentAccount.creditCard.cardNumber;
    } else if (_has(this.paymentAccount, 'achWithdrawal')) {
      this.icon = 'bank';
      this.accountNumber = this.paymentAccount.achWithdrawal.accountNumber;
      this.subLine1 = this.paymentAccount.achWithdrawal.financialInstitution;
    }
  }

  private setupWithPaymentType() {
    if (!this.paymentType) {
      return;
    }

    // Default to the payment type for the title and icon
    this.title = this.paymentType.replace('Debit/CreditCard', 'Debit/Credit Card');
    this.icon = this.paymentType;

    if (this.nickName) {
      this.subLine1 = this.nickName;
    }
  }
}

import { PaymentAccount } from './payment-account.model';

export interface AutomaticPaymentRowModel {
  paymentAccount: PaymentAccount;
  amount: {
    amount: string;
    daysBefore: string;
    predictedAmt: string;
    nextPaymentDate: string;
  };
  billAccountNumber: string;
}

export interface PredictedPaymentRowModel {
  paymentAccount: PaymentAccount;
  amount: {
    amount: string;
    daysBefore: string;
    predictedAmt: string;
    nextPaymentDate: string;
  };
  billAccountNumber: string;
}

export interface PaymentRowsModel {
  predictedPayments: PredictedPaymentRowModel[];
  automaticPayments: AutomaticPaymentRowModel[];
}

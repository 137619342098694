import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FinancialInstitutionModel } from '../models/financial-institution.models';

const getFinancialInstitutionState = createFeatureSelector<FinancialInstitutionModel>(
  'financialInstitution'
);

export const getFinancialInstitutionLoading = createSelector(
  getFinancialInstitutionState,
  (state: FinancialInstitutionModel) => state.loading
);

export const getLoaded = createSelector(
  getFinancialInstitutionState,
  (state: FinancialInstitutionModel) => state.loaded
);

export const financialInstitutionQuery = {
  getFinancialInstitutionState,
  getFinancialInstitutionLoading,
  getLoaded
};

<div id="verify-options">
  <h1
    data-cy="resetPasswordText"
    *ngIf="!verifyMethodLocked"
    content
    [contentPath]="'auth.resetOptionsHeader'"
  ></h1>
  <h1
    *ngIf="verifyMethodLocked"
    content
    [contentPath]="'auth.resetOptionsHeaderFailedAttempts'"
  ></h1>
  <h4 class="margin-top-20">Email/User ID: {{ userIdentifier }}</h4>
  <div class="row verification-list">
    <div class="column twelve">
      <ul data-cy="resetPasswordVerifyOptionsList" class="margin-bottom-10">
        <hr aria-hidden="true" />
        <li *ngIf="isDuplicateEmailError">
          <a href="javascript:void(0)">
            <span content [contentPath]="'auth.resetOptionsEmailLink'"></span>
            <em class="margin-top-5 float-right icon-chevron-right"></em
          ></a>
        </li>
        <li *ngIf="emailEnabled && !isDuplicateEmailError" [dsRouterLink]="[currentRoute, 'email']">
          <a href="javascript:void(0)">
            <span
              data-cy="resetPasswordEmailLink"
              content
              [contentPath]="'auth.resetOptionsEmailLink'"
            ></span>
            <em class="margin-top-5 float-right icon-chevron-right"></em
          ></a>
        </li>
        <hr *ngIf="textEnabled && emailEnabled" aria-hidden="true" class="dash" />
        <li *ngIf="textEnabled" [dsRouterLink]="[currentRoute, 'text']">
          <a href="javascript:void(0)">
            <span
              data-cy="resetPasswordTextLink"
              content
              [contentPath]="'auth.resetOptionsTextLink'"
            ></span>
            <em class="margin-top-5 float-right icon-chevron-right"></em
          ></a>
        </li>
        <hr *ngIf="callEnabled && (textEnabled || emailEnabled)" aria-hidden="true" class="dash" />
        <li *ngIf="callEnabled" [dsRouterLink]="[currentRoute, 'call']">
          <a href="javascript:void(0)">
            <span
              data-cy="forgotPasswordCallLink"
              content
              [contentPath]="'auth.resetOptionsCallLink'"
            ></span>
            <em class="margin-top-5 float-right icon-chevron-right"></em
          ></a>
        </li>
        <hr
          *ngIf="securityQuestionsEnabled && (callEnabled || textEnabled || emailEnabled)"
          aria-hidden="true"
          class="dash"
        />
        <li *ngIf="securityQuestionsEnabled" [dsRouterLink]="[currentRoute, 'security-questions']">
          <a href="javascript:void(0)">
            <span
              data-cy="forgotPasswordAnswerSecurityQuestions"
              content
              [contentPath]="'auth.resetOptionsSecurityQuestionsLink'"
            ></span>
            <em class="margin-top-5 float-right icon-chevron-right"></em
          ></a>
        </li>
        <hr aria-hidden="true" />
      </ul>
    </div>
    <p class="duplicateEmailError" *ngIf="isDuplicateEmailError">
      <span content contentPath="auth.duplicateEmailForgotPasswordError"></span>
      <span content contentPath="shared.contactNumber"></span>
      <span>.</span>
    </p>
  </div>
  <div class="row">
    <div id="back" class="caption margin-top-10 margin-bottom-10">
      <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block"
        ><em class="icon-chevron-left"></em> Back</a
      >
    </div>
  </div>
</div>

import { CustomerEnrollment } from '@amfam/mae/data-access/src';
import { createAction, props } from '@ngrx/store';

export const createAccount = createAction('[MaeFeature] createAccount');

export const initiateEnrollment = createAction(
  '[MaeFeature] Initiate Enrollment',
  props<{ email: string }>()
);
export const initiateEnrollmentSuccess = createAction('[MaeFeature] Initiate Enrolment Success');
export const initiateEnrollmentFailure = createAction(
  '[MaeFeature] Initiate Enrollment Failure',
  props<{ message: string }>()
);

export const enrollmentSuccessANSNotification = createAction(
  '[MaeFeature] Enrollment Success ANS Notification',
  props<{ customerEnrollment: CustomerEnrollment }>()
);
export const enrollmentSuccessANSNotificationSuccess = createAction(
  '[MaeFeature] Enrollment Success ANS Notification Success'
);
export const enrollmentSuccessANSNotificationFailure = createAction(
  '[MaeFeature] Enrollment Success ANS Notification Failure'
);

export const validateEmail = createAction(
  '[MaeFeature] Validate Email',
  props<{ email: string; correlationId: string }>()
);
export const launchOverview = createAction('[MaeFeature] launch Overview');
export const setPreferences = createAction('[MaeFeature] Preferences');
export const setPreferencesSuccess = createAction('[MaeFeature] Preferences Success');
export const skipAutoPay = createAction('[MaeFeature] Skip Autopay');

import { Policy } from './policy';
import { Address } from './address';
import { Risk } from './risk';

import { get as _get, has as _has, head as _head } from 'lodash';
import { Life } from './life.model';

import { CapitalToPascal } from '@amfam/shared/ui/pipes';
import { PolicyTypeIconConstants } from './policy-type-icon-constants';

export class LifePolicy extends Policy {
  public coverageAmount: number;
  public riskLocationAddress: Address;
  public namedInsured: Risk;
  public term: string;

  static fromJson(json: Life): LifePolicy {
    const policy = new LifePolicy();
    policy.assignProperties(json);

    return policy;
  }

  protected assignProperties(json: Life) {
    json.periodStartDate = json.effectiveDate;
    super.assignProperties(json);
    this.iconType = PolicyTypeIconConstants.LIFE;
    this.policyType = 'life';
    this.coverageAmount = json.coverageAmount;
    this.term = json.term;

    if (_has(json, 'insured')) {
      json.insured.forEach(insured => {
        const name =
          _get(insured, 'firstName', '') +
          ' ' +
          _get(insured, 'middleInitial', '') +
          ' ' +
          _get(insured, 'lastName', '');
        this.policyRisks.push(name);
        insured.addressInfo.forEach(address => {
          const zipCode = _get(address, 'zipCode', '').replace(/-/g, '');
          address.zipCode = !!zipCode ? zipCode.substring(0, 5) + '-' + zipCode.substring(5) : '';
          this.riskLocationAddress = Address.fromJson(address);
        });
      });
    }

    if (this.policyRisks.length > 0) {
      this.namedInsured = _head(this.policyRisks);
    }

    this.riskDescriptionList = [new CapitalToPascal().transform(this.policyType)];
  }
}

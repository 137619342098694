export interface PaymentTermsAndConditionsPartnerContent {
  companyName: string;
  phoneNumber: string;
  landingPage: string;
  billingEmail: string;
}

export enum PaymentTermsAndConditionsType {
  AUTOPAY_BANK = 'autoPayBank',
  AUTOPAY_CARD = 'autoPayCard',
  AUTOPAY_BOTH = 'autoPayBoth',
  SCHEDULED_PAYMENT_BANK = 'scheduledPaymentBank',
  SCHEDULED_PAYMENT_CARD = 'scheduledPaymentCard',
  SCHEDULED_PAYMENT_BOTH = 'scheduledPaymentBoth'
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-preferences-message',
  templateUrl: './preferences-message.component.html',
  styleUrls: ['./preferences-message.component.scss']
})
export class PreferencesMessageComponent {
  @Input() message: string;

  constructor() {}
}

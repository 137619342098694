export interface Environment{
  production: boolean;
  devMode: boolean;
  suppressErrorPage: boolean;
  billingCareCentreEmail: string;
  billingPersonalLinesEmail: string;
  billingCommercialLinesEmail: string;
  billingInternalFromEmail: string; 
  policyChangeRequestInternal: string;
  policyGracePeriodNotificatonInternal: string;
  policyGracePeriodNotificatonInternalAgencyMailbox: string;
  claimContantAdjusterInternal: string;
  claimReportClaimInternal: string;
  partnerBillingEmail: string;
  partnerClaimEmail: string;
  partnerAdjusterEmail:string;
  partnerLicensedAdvisorEmail: string; 
  gaProperty: string;
  currentDate: Date
}

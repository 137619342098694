export class MaeStatus {
  public enrollmentStatus: EnrollmentStatus;

  constructor(
    public customerId: string = '',
    public emailAddress: string = '',
    status: string = ''
  ) {
    this.enrollmentStatus = this.getEnrollmentStatus(status);
  }

  private getEnrollmentStatus(status: string): EnrollmentStatus {
    switch (status) {
      case 'A': // User already has active MyAccount
        return 'Active';
      case 'E': // Email correlation id is expired
        return 'Expired';
      case 'P': // User enrollment is pending
        return 'Pending';
      case 'N': // User does not have an account staged or enrolled
        return 'Not Enrolled';
    }

    return 'Unknown';
  }
}

export type EnrollmentStatus = 'Active' | 'Expired' | 'Pending' | 'Not Enrolled' | 'Unknown';

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import { NavigationService } from '../services/navigation.service';
import { RouterActionTypes, Go, Back, Forward } from './router-actions';

@Injectable()
export class RouterEffects {
  
  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.GO),
    map((action: Go) => action.payload),
    tap(({ path, query: queryParams, extras }) =>
      this.router.navigate(path, { queryParams, ...extras })
    )
  ), { dispatch: false });

  
  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.BACK),
    tap(() => this.location.back())
  ), { dispatch: false });

  
  navigateForward$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.FORWARD),
    tap(() => this.location.forward())
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: NavigationService,
    private location: Location
  ) {}
}

import { of as observableOf } from 'rxjs';

import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EnrollNotificationService } from '../../../shared/enroll-notification/shared/enroll-notification.service';

import * as maeProgram from './mae-program.actions';
import * as maeEnrollmentActions from './mae-enrollment.actions';
import { BillAccountActions } from '@amfam/billing/billaccount/data-access';
import * as kydEligibilityActions from '../../store/programs/kyd/kyd-eligibility.actions';
import * as kydActions from '../../store/programs/kyd/kyd.actions';
import { PaymentAccountActions } from '@amfam/billing/paymentaccount/data-access';

import * as fromRoot from '../../store';
import { PolicySelectors, PolicyActions } from '@amfam/policy/data-access';
import { PolicySummarySelectors, PolicySummaryActions } from '@amfam/policy/data-access';
import { PolicyDocumentsSelectors } from '@amfam/policy/data-access';
import { ProfileActions } from '@amfam/profile/data-access';
@Injectable()
export class MaeEnrollmentEffects {
  
  enroll$ = createEffect(() => this.action$.pipe(
    ofType(maeEnrollmentActions.ENROLL),
    map((action: maeEnrollmentActions.EnrollAction) => action.payload),
    switchMap(request => this.enrollNotificationService.enroll(request)),
    map(status => this.enrollNotificationService.getMaeEnrollments(status)),
    mergeMap(enrollments => [
      // Dispatch LoadCompleteAction as starting point to call other API's.s
      new maeEnrollmentActions.LoadSuccessAction(enrollments),
      new maeEnrollmentActions.LoadCompleteAction()
    ]),
    catchError(error => observableOf(new maeEnrollmentActions.LoadFailAction(error)))
  ));

  
  unenroll$ = createEffect(() => this.action$.pipe(
    ofType(maeEnrollmentActions.UNENROLL),
    map((action: maeEnrollmentActions.UnenrollAction) => action.payload),
    switchMap(request => this.enrollNotificationService.decline(request)),
    map(status => this.enrollNotificationService.getMaeEnrollments(status)),
    map(enrollments => new maeEnrollmentActions.LoadSuccessAction(enrollments)),
    catchError(error => observableOf(new maeEnrollmentActions.LoadFailAction(error)))
  ));

  /**
   * Programs can be enrolled through MAE -
   * goPaperless(policy documents), Online Billing, Autopay, Billing Preferences, Kyd Automatic.
   *
   * Dispatch below actions to know status of programs.
   */
  
  loadComplete$ = createEffect(() => this.action$.pipe(
    ofType(maeEnrollmentActions.LOAD_COMPLETE),
    switchMap(() => this.store.select(PolicySelectors.getAdvanceAutoPoliciesAndRisks)),
    mergeMap(kydActionsData => [
      new maeProgram.InitPrograms(),
      new ProfileActions.RefreshPartyDataAction(),
      new BillAccountActions.BillAccountsLoad(),
      new PaymentAccountActions.PaymentAccountsLoad(),
      new kydEligibilityActions.LoadAction(kydActionsData.advanceAutoPolicyrisks),
      new kydActions.LoadAction(kydActionsData.advanceAutpolicies)
    ])
  ));

  constructor(
    private enrollNotificationService: EnrollNotificationService,
    private action$: Actions,
    private store: Store<fromRoot.RootState>
  ) {}
}

import { Action } from '@ngrx/store';

export enum ClaimEnterpriseActionTypes {
  CreateClaim = '[ClaimEnterprise] Create  Claim',
  CreateClaimSuccess = '[ClaimEnterprise] Create Claim Success',
  CreateClaimFail = '[ClaimEnterprise] Create Claim Fail'
}

export class CreateEnterpriseClaim implements Action {
  readonly type = ClaimEnterpriseActionTypes.CreateClaim;
  constructor(public payload?: any, public isProperty?: boolean) {}
}

export class CreateEnterpriseClaimSuccess implements Action {
  readonly type = ClaimEnterpriseActionTypes.CreateClaimSuccess;
  constructor(public payload?: any) {}
}

export class CreateEnterpriseClaimFail implements Action {
  readonly type = ClaimEnterpriseActionTypes.CreateClaimFail;
  constructor(public payload?: any) {}
}

export type ClaimEnterpriseAction =
  | CreateEnterpriseClaim
  | CreateEnterpriseClaimSuccess
  | CreateEnterpriseClaimFail;

export const fromClaimEnterpriseActions = {
  CreateEnterpriseClaim,
  CreateEnterpriseClaimSuccess,
  CreateEnterpriseClaimFail
};

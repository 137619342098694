<section id="edit-security-questions-component" class="item">
  <div class="row">
    <div class="column twelve">
      <div
        *ngIf="!showForm"
        class="pad-left-40 pad-top-10 float-left"
        content
        [contentPath]="'profile.securityQuestionsTitle'"
      ></div>
      <button
        ds-button
        *ngIf="!showForm"
        class="-outline-white float-right margin-left-10"
        id="openSecurityQuestionsForm"
        (click)="openSecurityQuestionsForm()"
        data-cy="openSecurityQuestionsButton"
        content
        [contentPath]="'shared.changeBtn'"
        authCheck
        [permissionName]="'securityquestions_view'"
        [checkAction]="'disable'"
      ></button>
      <div class="row pad-left-40" *ngIf="showForm">
        <p *ngIf="errorLoadingQuestions" class="color-red">
          There was an error loading the security questions.
        </p>
        <h3 content [contentPath]="'profile.securityQuestionsTitle'"></h3>
        <p class="margin-bottom-20" content [contentPath]="'profile.securityQuestionsText'"></p>
        <manage-security-questions
          *ngIf="!errorLoadingQuestions"
          [securityQuestions]="securityQuestions"
          (complete)="onComplete($event)"
          (getAllQuestionsServiceError)="onGetAllQuestionsServiceError($event)"
        >
        </manage-security-questions>
        <button
          ds-button
          class="-white -offset-top bg-transparent cancel a float-right margin-right-5"
          (click)="openSecurityQuestionsForm()"
          data-cy="cancelSecurityQuestionsButton"
          content
          [contentPath]="'shared.cancel'"
        ></button>
      </div>
    </div>
  </div>
</section>
<hr aria-hidden="true" class="dashed" />

// TODO: Make sure that resize trigger away from mobileMenu resets open state to closed.

import { LogoModel } from '@amfam/shared/models';
import { BrandLinkDataModel } from '@amfam/shared/utility/brand';
import { WindowRef } from '@amfam/shared/utility/shared-services';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { get as _get, sortBy as _sortBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderNavRect, NavLinkTreeObj, NotificationObj } from '../../models';
import { HeaderWrapperService } from '../../services/header-wrapper.service';
import { DsHeaderMobileNavigationItemComponent } from './ds-header-mobile-navigation-item/ds-header-mobile-navigation-item.component';

@Component({
  selector: 'ds-header-mobile-navigation',
  templateUrl: './ds-header-mobile-navigation.component.html',
  styleUrls: [
    './ds-header-mobile-navigation.component.scss',
    './shared/ds-header-mobile-navigation.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggleMenu', [
      state('true', style({ transform: 'translateX(0)', visibility: 'visible' })),
      state('false', style({ transform: 'translateX(-100%)', visibility: 'hidden' })),
      transition('* => *', animate('0.25s'))
    ])
  ]
})
export class DsHeaderMobileNavigationComponent implements OnInit, OnDestroy {
  // TODO: Fix inputs upstream to use async as soon as possible and remove Observables here
  @Input() loaded$: Observable<boolean>;
  @Input() headerLogo: LogoModel;
  @Input() brandInfo$: Observable<BrandLinkDataModel>;
  @Input() loggedIn$: Observable<boolean>;
  @Input() homeUrl: '';
  @Input() linkArray$: Observable<NavLinkTreeObj[]>;
  @Input() notifications$: Observable<NotificationObj[] | false>;

  @Output() linkClickEvent = new EventEmitter();
  @Output() sublinkClickEvent = new EventEmitter();

  @ViewChildren('navItem') navItems: QueryList<DsHeaderMobileNavigationItemComponent>;
  @ViewChild('menuLink') menuLink: ElementRef;

  mobileMenuOpen = false;
  showNotificationMenuSub = false;
  linkArray: NavLinkTreeObj[] = [];

  headerNavRect$: Observable<HeaderNavRect>;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private headerWrapperService: HeaderWrapperService,
    private ref: ChangeDetectorRef,
    private win: WindowRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.headerNavRect$ = this.headerWrapperService.headerNavRect$;

    this.linkArray$.pipe(takeUntil(this.stop$)).subscribe(linkArray => {
      this.linkArray = _sortBy(linkArray, 'mobileMenuItemOrder');
      // Trigger lifecycle
      this.ref.detectChanges();
    });

    // Needs to listen on the entire body opposed to @HostListener
    this.renderer.listen(document.body, 'keyup', event => {
      this.keyupEventHandler(event);
    });

    this.headerWrapperService.mobileMenuOpen$
      .pipe(takeUntil(this.stop$))
      .subscribe(mobileMenuOpen => {
        this.mobileMenuOpen = mobileMenuOpen;
        this.ref.detectChanges();
        if (mobileMenuOpen) {
          this.win.nativeWindow.scrollTo(0, 0);
        }
      });
    this.headerWrapperService.mobileMenuReset$
      .pipe(takeUntil(this.stop$))
      .subscribe(mobileMenuReset => {
        if (mobileMenuReset) {
          this.menuLink.nativeElement.focus();
          this.headerWrapperService.setMobileMenuReset(false);
        }
      });

    this.headerWrapperService.mobileNotificationMenuOpen$
      .pipe(takeUntil(this.stop$))
      .subscribe(showNotificationMenuSub => {
        this.showNotificationMenuSub = showNotificationMenuSub;
        this.ref.detectChanges();
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  resetOnBlur(target) {
    if (target === this.navItems.last) {
      this.headerWrapperService.setMobileMenuReset(true);
    }
  }

  hideAllNavItems() {
    if (this.navItems) {
      this.navItems.forEach(mobileNavItemInstance => {
        mobileNavItemInstance.showNavItem = 'hide';
      });
    }
  }

  showAllNavItems() {
    if (this.navItems) {
      this.navItems.forEach(mobileNavItemInstance => {
        mobileNavItemInstance.showNavItem = 'show';
      });
    }
  }

  hideOtherSubMenus(mobileNavItem?: DsHeaderMobileNavigationItemComponent) {
    if (this.navItems) {
      this.navItems.forEach(mobileNavItemInstance => {
        if (mobileNavItemInstance !== mobileNavItem && mobileNavItemInstance.showSub !== 'hide') {
          mobileNavItemInstance.showSub = 'hide';
        }
      });
    }
  }

  // Tell Angular to track these items by ID so that they are not recreated in the DOM
  trackByFn(index) {
    return index;
  }

  linkClicked({ link, event }) {
    const linkItem = _get(link, 'link', null);
    if (linkItem) {
      this.linkClickEvent.emit({ link: linkItem, event });
    }

    if (!!_get(linkItem, 'subLinkArray.length')) {
      this.hideAllNavItems();
    } else if (!link.textOnly) {
      this.headerWrapperService.setMobileMenuOpen(false);
    }
  }

  sublinkClicked({ sublink, event }) {
    if (sublink) {
      this.sublinkClickEvent.emit({ sublink, event });
    }

    // Reset to navItems unless specified otherwise with the textOnly property
    if (!_get(sublink, 'textOnly')) {
      this.showAllNavItems();
    }
  }

  sublinkTitleClicked() {
    this.showAllNavItems();
  }

  // ESC has to close menu for WCAG 2.0
  keyupEventHandler(event: any) {
    if (this.mobileMenuOpen) {
      const keyCode = event.keyCode || event.charCode;

      if (keyCode === 27) {
        this.headerWrapperService.setMobileMenuOpen(false);
      }
    }
  }

  toggleNav() {
    this.headerWrapperService.setMobileMenuOpen(!this.mobileMenuOpen);
    if (this.showNotificationMenuSub) {
      this.headerWrapperService.setMobileNotificationMenuOpen(false);
    }
  }
}

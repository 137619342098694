import { ErrorLevels, ErrorObj } from '@amfam/ui-kit';
import { Injectable } from '@angular/core';
import { get as _get, has as _has } from 'lodash';

// date-fns
import {
  addDays,
  differenceInDays,
  format,
  getHours,
  getMinutes,
  isAfter,
  isSameDay
} from 'date-fns';

export enum ClaimProgressEnum {
  'SUBMISSION',
  'EVALUATION',
  'RESOLUTION',
  'CLOSED'
}

@Injectable({
  providedIn: 'root'
})
export class ClaimUtilService {
  constructor() {}

  public buildIndicatorStatus(claim) {
    let activeStep = '';
    if (_has(claim, 'claimStatus') && _get(claim, 'claimStatus') !== 'IN PROGRESS') {
      activeStep = _get(ClaimProgressEnum, _get(claim, 'claimProgress', '').toUpperCase(), '');
    }
    return this.determineClaimStatus(activeStep);
  }

  private determineClaimStatus(claimProgress) {
    // The default values here matter, all visible by default, completed false and active false
    const claimStatus = [
      {
        name: 'SUBMISSION',
        title: 'Submit',
        complete: false,
        active: false,
        show: true
      },
      {
        name: 'EVALUATION',
        title: 'Evaluate',
        complete: false,
        active: false,
        show: true
      },
      {
        name: 'RESOLUTION',
        title: 'Resolve',
        complete: false,
        active: false,
        show: true
      },
      {
        name: 'CLOSED',
        title: 'Close',
        complete: false,
        active: false,
        show: true
      }
    ];

    switch (claimProgress) {
      // Submission
      case ClaimProgressEnum.SUBMISSION:
      case ClaimProgressEnum.EVALUATION:
        claimStatus[0].complete = true;
        claimStatus[1].active = true;
        break;
      case ClaimProgressEnum.RESOLUTION:
        claimStatus[0].complete = true;
        claimStatus[1].complete = true;
        claimStatus[2].active = true;
        break;
      case ClaimProgressEnum.CLOSED:
        claimStatus[0].complete = true;
        claimStatus[1].complete = true;
        claimStatus[2].complete = true;
        claimStatus[3].complete = true;
        claimStatus[3].active = true;
        break;
      default:
        claimStatus[0].complete = false;
        claimStatus[1].complete = false;
        claimStatus[2].complete = false;
        claimStatus[3].complete = false;
        claimStatus[0].active = true;
        break;
    }

    return claimStatus;
  }

  public formatDate(date) {
    if (date) {
      return format(date, 'MM/DD/YYYY');
    }
    return '';
  }

  public formatTime(time) {
    if (time) {
      return format(time, 'h:mm a');
    }
    return '';
  }

  public isDateTodayOrAfter(date) {
    if (date) {
      if (isSameDay(date, new Date()) || isAfter(date, new Date())) {
        return true;
      } else {
        return false;
      }
    }
  }

  public addDays(startDate: string, numberOfDays: number) {
    if (startDate && numberOfDays) {
      return format(addDays(startDate, numberOfDays), 'MM/DD/YYYY');
    }
  }

  public daysRemainingFromToday(date) {
    if (date) {
      return differenceInDays(format(date, 'MM/DD/YYYY'), format(new Date(), 'MM/DD/YYYY'));
    }
  }

  public checkObjPropsHasValue(obj) {
    const result = Object.keys(obj).some(function (key) {
      return obj[key] !== undefined && obj[key] !== null && obj[key] !== '';
    });
    return result;
  }

  public showErrorMessage(
    errorMessageList: Array<String>,
    errorLevel?: ErrorLevels
  ): Array<ErrorObj> {
    let errorList: Array<ErrorObj> = [];
    errorMessageList.forEach((msg: string) => {
      if (!errorList.find(error => error.errorMessage === msg)) {
        errorList = [
          ...errorList,
          { errorMessage: msg, errorLevel: errorLevel || ErrorLevels.HIGH }
        ];
      }
    });
    return errorList;
  }

  /**
   * @param: hours as 01 - 23, meridian as  AM/PM
   */
  public convertTime12To24(hours: string = '', meridian: string = ''): string {
    if (hours === '12') {
      hours = '00';
    }
    if (meridian === 'PM') {
      hours = String(parseInt(hours, 10) + 12);
    }
    return hours;
  }

  /** Inconsistency in date format lead to validate for particular element position.
   * @param time24h should be in YYYY-MM-DDTHH:mm:ss pattern.
   */
  public convertTime24To12(time24h: string): string {
    let derivedTime = '';
    derivedTime = this.derive24HrTime(getHours(time24h), getMinutes(time24h));
    return derivedTime;
  }

  public derive24HrTime(hourInput: number, minutes: number): string {
    let minutesString: string;
    let hour = hourInput % 12;
    if (hour === 0) {
      hour = 12;
    }
    if (minutes < 10) {
      minutesString = '0' + minutes;
    }
    return (
      (hour < 10 ? '0' : '') + hour + ':' + minutesString + ' ' + (hourInput < 12 ? 'AM' : 'PM')
    );
  }

  // Cleans and re-adds dashes to claim number for display purposes
  public formatClaimNumber(claimNumber: string): string {
    const num = claimNumber.replace(/-/g, '');
    return num.slice(0, 2) + '-' + num.slice(2, 5) + '-' + num.slice(5);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CLAIMFAQ_FEATURE_KEY,
  initialState as claimFaqInitialState,
  claimFaqReducer
} from './+state/claim-faq.reducer';
import { ClaimFaqEffects } from './+state/claim-faq.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIMFAQ_FEATURE_KEY, claimFaqReducer, {
      initialState: claimFaqInitialState
    }),
    EffectsModule.forFeature([ClaimFaqEffects])
  ]
})
export class ClaimFaqDataAccessModule {}

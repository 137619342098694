<div class="risk-list">
  <div
    *ngIf="!!otherLabel"
    [formGroup]="riskSelectionForm"
    class="row risk"
    [class.pad-0]="!showPolicyNumber"
    [ngClass]="{ active: currentRiskId && currentRiskId === 'other' }"
    (click)="toggleRisk('other')"
  >
    <div class="flex self-center pad-10">
      <div class="self-center pad-right-30">
        <input
          type="radio"
          id="other"
          formControlName="riskSelection"
          value="other"
          (change)="toggleRisk('other')"
        />
        <label class="none checkbox-label" for="other" [attr.aria-label]="otherLabel">{{
          otherLabel
        }}</label>
      </div>
      <div *ngIf="otherLabel" class="self-center">
        <div class="risk-info">
          <div class="heading" data-cy="otherRiskLabel">{{ otherLabel }}</div>
        </div>
      </div>
    </div>
  </div>

  <div
    [formGroup]="riskSelectionForm"
    class="row risk"
    [class.pad-0]="!showPolicyNumber"
    *ngFor="let risk of risks"
    [ngClass]="{ active: currentRisk?.id && currentRisk?.id === risk?.id }"
    (click)="toggleRisk(risk.id)"
    [attr.data-cy]="'riskItem'"
  >
    <div class="flex self-center pad-10">
      <div class="self-center pad-right-30">
        <input
          type="radio"
          formControlName="riskSelection"
          [attr.id]="'checkbox-risk-' + risk.id"
          [value]="risk.id"
          (change)="toggleRisk(risk.id)"
          [attr.data-cy]="'checkbox-risk-' + risk.id"
        />
        <label
          class="none checkbox-label"
          [attr.for]="'checkbox-risk-' + risk.id"
          [attr.aria-label]="
            (risk.vehicleTypeDisplayName || risk.policyTypeDisplayName) +
            ' Policy: ' +
            risk.description
          "
        ></label>
      </div>
      <div *ngIf="showIcon" class="self-center pad-right-30" aria-hidden="true">
        <span
          class="policyIcon"
          [ngClass]="
            risk.vehicleTypeName === driverDefaults.VEHICLE_NOT_ON_POLICY
              ? riskIcons.NOT_ON_POLICY
              : risk.iconType
          "
        ></span>
      </div>
      <div class="self-center">
        <div class="risk-info">
          <div class="heading">
            {{ vehicleLabel
            }}{{
              risk.vehicleTypeName === driverDefaults.VEHICLE_NOT_ON_POLICY
                ? driverDefaults.VEHICLE_NOT_ON_POLICY
                : risk.description
            }}
          </div>
          <div
            *ngIf="
              risk.vehicleTypeName !== driverDefaults.VEHICLE_NOT_ON_POLICY && showPolicyNumber
            "
          >
            Policy #{{ risk.policyNumber | prettyPolicyNum }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <p *ngIf="!!otherContent" class="pad-top-20"><span [innerHTML]="otherContent"></span></p>
</div>

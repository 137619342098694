<ds-modal
  [id]="modalId"
  [closeOthers]="true"
  [maxWidth]="959"
  (onCloseModalEvent)="close()"
  (initialized)="modalInitialized($event)"
>
  <div class="padded">
    <enroll-terms
      *ngIf="hasPrograms && !enrolled"
      [model]="model"
      (onSubmit)="onSubmitTerms($event)"
    ></enroll-terms>
    <enroll-confirmation *ngIf="enrolled" (onClose)="close(false)"></enroll-confirmation>
  </div>
</ds-modal>

import { GenericProductType } from '@amfam/policy/models';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import {
  AnalyticsService,
  ApplicationService,
  Applications,
  ClaimsAnalytics,
  FirstPartyFnolAnalytics,
  PageAnalytic
} from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';
import { get as _get } from 'lodash';
import { FnolAnalyticsModel } from '../+state/fnol.model';
import { CtaTypes } from '../models/cta-types';
import { FnolQuestionTypes } from '../models/question-types';
@Injectable({
  providedIn: 'root'
})
export class DynamicFnolAnalyticsService {
  private sendDynamicQuestionAnalytic(
    questionId: string,
    questionCategory: string,
    claimNumber: string,
    lineOfBusiness: GenericProductType
  ): void {
    let pageName: string;
    if (this.appService.isApp(Applications.FIRST_PARTY_FNOL)) {
      pageName =
        lineOfBusiness === GenericProductType.Home
          ? 'Claims:ReportClaim:Guest:Property:' + questionCategory + ':' + questionId
          : 'Claims:ReportClaim:Guest:Auto:' + questionCategory + ':' + questionId;
    } else if (this.appService.isApp(Applications.THIRD_PARTY_FNOL)) {
      pageName =
        lineOfBusiness === GenericProductType.Home
          ? 'Claims:ReportClaim:ThirdParty:Property:' + questionCategory + ':' + questionId
          : 'Claims:ReportClaim:ThirdParty:Auto:' + questionCategory + ':' + questionId;
    } else {
      pageName =
        lineOfBusiness === GenericProductType.Home
          ? 'MyAccount:ReportClaim:Property:' + questionCategory + ':' + questionId
          : 'MyAccount:ReportClaim:Auto:' + questionCategory + ':' + questionId;
    }
    const category =
      this.appService.isApp(Applications.FIRST_PARTY_FNOL) ||
      this.appService.isApp(Applications.THIRD_PARTY_FNOL)
        ? 'Claims'
        : 'My Account';
    const dynamicAnalytic = {
      pageName: pageName,
      experience: '',
      primaryCategory: category,
      subCategory1: questionCategory,
      subCategory2: questionId,
      subCategory3: '',
      claimNumber: claimNumber
    };

    this.analyticsFacade.trackPage(dynamicAnalytic);
  }

  sendAnalyticWithClaimNumber(baseAnalytic: PageAnalytic, claimNumber: string): void {
    baseAnalytic.claimNumber = claimNumber;
    this.analyticsFacade.trackPage(baseAnalytic);
  }

  sendDynamicClaimsAnalytic(response: FnolAnalyticsModel): void {
    let analytic;
    // If analytic is error, send error with claim number
    if (_get(response, 'isError')) {
      analytic =
        response.lineOfBusiness === GenericProductType.Home
          ? ClaimsAnalytics.pageReportClaimPropertyError
          : ClaimsAnalytics.pageReportClaimAutoError;
      analytic.claimNumber = _get(response, 'claimNumber');
      this.analyticsFacade.trackPage(analytic);
    } else {
      // If analytic isn't error, figure out what type of analytic needs to be sent
      switch (_get(response, 'apiResponse.questionType')) {
        case FnolQuestionTypes.END:
          if (this.appService.isApp(Applications.FIRST_PARTY_FNOL)) {
            analytic = FirstPartyFnolAnalytics.pagePropertyReportClaimSummary;
          } else {
            analytic =
              response.lineOfBusiness === GenericProductType.Home
                ? ClaimsAnalytics.pageReportClaimPropertySummary
                : ClaimsAnalytics.pageReportClaimAutoSummary;
          }
          analytic.claimNumber = _get(response, 'claimNumber');
          this.analyticsFacade.trackPage(analytic);
          break;
        case FnolQuestionTypes.INSURED_CONTACT_TYPE:
          analytic =
            response.lineOfBusiness === GenericProductType.Home
              ? ClaimsAnalytics.pageReportClaimPropertyContactInfo
              : ClaimsAnalytics.pageReportClaimAutoContactInfo;
          analytic.claimNumber = _get(response, 'claimNumber');
          this.analyticsFacade.trackPage(analytic);
          break;
        case FnolQuestionTypes.FREE_FORM:
        case FnolQuestionTypes.SINGLE_SELECT:
        case FnolQuestionTypes.MULTI_SELECT:
          this.sendDynamicQuestionAnalytic(
            _get(response, 'apiResponse.questionId'),
            _get(response, 'apiResponse.questionCategory'),
            _get(response, 'claimNumber'),
            _get(response, 'lineOfBusiness')
          );
          break;
      }
    }
  }

  sendTidAnalytic(ctaType: string, lineOfBusiness: GenericProductType): void {
    switch (ctaType) {
      case CtaTypes.CANCEL:
        lineOfBusiness === GenericProductType.Home
          ? this.analyticsService.trackButtonClick(ClaimsAnalytics.clickPropertyFnolCancel)
          : this.analyticsService.trackButtonClick(ClaimsAnalytics.clickAutoFnolCancel);
        break;
      case CtaTypes.REPORT_NEW_CLAIM:
        lineOfBusiness === GenericProductType.Home
          ? this.analyticsService.trackButtonClick(
              ClaimsAnalytics.clickPropertyFnolDuplicateReportNewClaim
            )
          : this.analyticsService.trackButtonClick(
              ClaimsAnalytics.clickAutoFnolDuplicateReportNewClaim
            );
        break;
      case CtaTypes.RESUME_CLAIM:
        lineOfBusiness === GenericProductType.Home
          ? this.analyticsService.trackButtonClick(
              ClaimsAnalytics.clickPropertyFnolDuplicateResumeDraftClaim
            )
          : this.analyticsService.trackButtonClick(
              ClaimsAnalytics.clickAutoFnolDuplicateResumeDraftClaim
            );
        break;
      default:
        break;
    }
  }

  constructor(
    private analyticsService: AnalyticsService,
    private analyticsFacade: AnalyticsFacade,
    private appService: ApplicationService
  ) {}
}

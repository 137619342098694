// TODO delete this file
export class CCTypes {
  public static NONE = 'none';
  public AMERICAN_EXPRESS = 'AX';
  public DISCOVER = 'DS';
  public VISA = 'VI';
  public MASTERCARD = 'MC';

  constructor() {
    // empty
  }
  public isAccepted(cardName: string): boolean {
    return (
      cardName === this.AMERICAN_EXPRESS ||
      cardName === this.DISCOVER ||
      cardName === this.VISA ||
      cardName === this.MASTERCARD
    );
  }
}

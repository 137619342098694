<p class="subheading">Payment Method</p>
<div *ngIf="paymentMethodOptions?.length > 0" class="pad-top-20">
  <p>Saved payment methods</p>
  <ds-radio-group
    data-cy="paymentMethodOptionsRadioButtons"
    class="radio-group-container"
    [control]="'paymentMethod'"
    [parentFormGroup]="formGroup"
    [options]="paymentMethodOptions"
  >
  </ds-radio-group>
</div>

<div *ngIf="displayPaymentExpiredError" class="row">
  <ds-error-box [messageText]="paymentExpiredErrorMessage"> </ds-error-box>
</div>
<div *ngIf="!!formGroup.get('paymentMethod').value" class="row">
  <a (click)="editPaymentMethod()" [ngClass]="{ disabled: !authorizedToEditPaymentMethod }"
    >Edit payment method</a
  >
</div>

<ds-warning-box
  *ngIf="displayDiscountWarning"
  [headingText]="autoPayWarningHeading"
  [messageText]="autoPayWarningBody"
>
</ds-warning-box>
<ds-add-payment-method-quicklinks
  [authorizedToEditPaymentMethod]="authorizedToEditPaymentMethod"
  [authorizedToAddPaymentMethod]="authorizedToAddPaymentMethod"
  [displayAutoPayDiscountMessage]="true"
  [displayDiscountWarning]="displayDiscountWarning"
  [displayPaymentExpiredError]="displayPaymentExpiredError"
  [displayAutoPayDiscountMessage]="displayAutoPayDiscountMessage"
  [formGroup]="formGroup"
  [paymentMethodError]="paymentMethodError"
  [paymentMethodOptions]="paymentMethodOptions"
  (addCheckingAccountEvent)="addCheckingAccount()"
  (addCreditDebitEvent)="addCreditDebit()"
  (addSavingsAccountEvent)="addSavingsAccount()"
>
</ds-add-payment-method-quicklinks>
<div *ngIf="paymentMethodError" class="row">
  <ds-error-box [messageText]="choosePaymentMethod"> </ds-error-box>
</div>
<div *ngIf="paymentError" class="row">
  <ds-error-box data-cy="paymentErrorMessage" [messageText]="paymentError"> </ds-error-box>
</div>

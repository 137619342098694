<div class="row flex pad-top-20">
  <div class="column twelve md-ten md-push-one">
    <div class="row lg-flex">
      <div class="column twelve pad-bottom-20">
        <div class="column twelve flex justify-space-between flex-direction">
          <div class="row pad-bottom-10">
            <div class="column twelve pad-bottom-10 pad-left pad-right md-pad-0">
              <div class="rich-text">
                <h2 class="heading-main error-600-text">HTTP 404 - Page not found</h2>
                <p>
                  <span class="error-600-text"
                    ><strong
                      >We apologize but the page you requested could not be found.</strong
                    ></span
                  >
                </p>
                <p>
                  The page you are looking for may have been removed or had its name changed, or it
                  might be temporarily unavailable.
                </p>
                <p>Please try the following options:</p>
                <ul>
                  <li>
                    If you typed the page address in the address bar, make sure it is spelled
                    correctly.
                  </li>
                  <li *dsPartnerAFI>
                    Check our&nbsp;<a [href]="amfamSitemap" target="_self"
                      ><strong><span>sitemap</span></strong></a
                    >&nbsp;for your desired destination.
                  </li>
                  <li>
                    Return to the&nbsp;
                    <a *dsPartnerAFI [href]="amfamDotCom" target="_self"
                      ><strong><span>www.amfam.com</span></strong></a
                    >
                    <a
                      *dsPartnerConnect
                      dsPartnerContent
                      [brandHref]="'getLandingPageUrl'"
                      target="_self"
                      ><strong><span>www.midvaleinsurance.com</span></strong></a
                    >
                    &nbsp;home page.
                    <p></p>
                  </li>
                  <li *dsPartnerAFI>
                    If you are still experiencing problems after trying these steps, please&nbsp;<a
                      [href]="amfamWebsiteIssue"
                      ><strong><span>contact the web experience team.</span></strong></a
                    >
                    <p></p>
                  </li>
                </ul>
                <p>We are sorry for the inconvenience that this issue has caused you.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

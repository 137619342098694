import { take } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../core/store';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { find as _find, forEach as _forEach } from 'lodash';

import { Agent, agentQuery } from '@amfam/shared/utility/agent';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.scss']
})
export class AgentsListComponent {
  areAllAgentsLoaded: Observable<boolean>;
  agents: Observable<Agent[]>;
  isConnectPartner: boolean;

  constructor(private store: Store<fromRoot.RootState>) {
    this.areAllAgentsLoaded = this.store.select(agentQuery.getAgentsLoaded);
    this.agents = this.store.select(fromRoot.getAllActiveAgents);

    // Check if user is non-AFI partner
    this.store
      .select(BrandSelectors.getIsPartner)
      .pipe(take(1))
      .subscribe(isPartner => {
        if (isPartner) {
          this.isConnectPartner = true;
        }
      });
  }
}

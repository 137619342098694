import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['../../containers/ds-table/ds-table.component.scss']
})
export class DsTableHeaderComponent implements OnInit {
  @Input() columns: any;

  constructor() {}

  ngOnInit() {}
}

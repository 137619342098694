import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CLAIM_FEATURE_KEY,
  initialState as claimInitialState,
  claimReducer
} from './+state/claim.reducer';
import { ClaimEffects } from './+state/claim.effects';
import { ClaimAdapter } from './services/claim.adapter';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIM_FEATURE_KEY, claimReducer),
    EffectsModule.forFeature([ClaimEffects])
  ],
  providers: [ClaimAdapter]
})
export class ClaimDataAccessModule {}

/* eslint-disable no-case-declarations */
import { Entities, initialEntities } from '../../entity/entity.model';
import * as kydEligibilityActions from './kyd-eligibility.actions';

import { get as _get, groupBy as _groupBy, keyBy as _keyBy } from 'lodash';
import { KydEligibilityResponse } from './models/kyd-eligibility-response.model';
import { KydPolicyEligibility } from './models/kyd-policy-eligibility.modal';
import { KydVehicleEligibility } from './models/kyd-vehicle-eligibility.model';

export function reducer(
  state = initialEntities<KydPolicyEligibility>(),
  action: kydEligibilityActions.Actions
): Entities<KydPolicyEligibility> {
  let entities: {};

  switch (action.type) {
    case kydEligibilityActions.LOAD:
    case kydEligibilityActions.LOAD_SMARTPHONE_KYD_POLICIES:
      return Object.assign({}, state, {
        loading: true
      });

    case kydEligibilityActions.LOAD_COMPLETE:
      return Object.assign({}, state, {
        loading: false
      });

    case kydEligibilityActions.LOAD_SUCCESS:
      const eligibilityByPolicyNumber = _groupBy(
        action.payload,
        (riskEligibility: KydEligibilityResponse) => riskEligibility.policyNumber
      );
      const policyEligibilities = Object.keys(eligibilityByPolicyNumber).map(policyNumber => {
        const policyEligibility = new KydPolicyEligibility();
        const entity = eligibilityByPolicyNumber[policyNumber];
        policyEligibility.policyNumber = policyNumber;
        policyEligibility.isCustomerEligible = entity.every(
          eligibility => eligibility.customerEligible
        );
        policyEligibility.isPolicyEligible = entity.every(
          eligibility => eligibility.policyEligible
        );
        policyEligibility.isPhhEligible = entity.every(eligibility => eligibility.phhEligible);
        policyEligibility.isStateEligible = entity.every(eligibility => eligibility.stateEligible);
        policyEligibility.programCategory = _get(entity[0], 'programCategory', '');
        policyEligibility.vehicleEligibilities = entity.map(eligibility => {
          const vehicleEligibility = new KydVehicleEligibility();
          vehicleEligibility.isVehicleEligible = eligibility.vehicleEligible;
          vehicleEligibility.canEnroll =
            eligibility.policyEligible &&
            eligibility.stateEligible &&
            eligibility.vehicleEligible &&
            eligibility.customerEligible;
          vehicleEligibility.vin = eligibility.vinNumber;
          return vehicleEligibility;
        });

        policyEligibility.reasonCode = _get(entity[0], 'reasonCode', '');

        return policyEligibility;
      });

      const groupedEntities = _keyBy(
        policyEligibilities,
        policyEligibility => policyEligibility.policyNumber
      );

      entities = Object.assign({}, state.entities || {}, groupedEntities);

      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities,
        loaded: true,
        loading: false
      });

    case kydEligibilityActions.LOAD_FAIL:
      return Object.assign({}, state, {
        hasError: true,
        error: action.payload,
        loading: false
      });

    case kydEligibilityActions.LOAD_SMARTPHONE_KYD_POLICIES_SUCCESS: {
      const smartPhoneEntities = [];
      for (const smartPhonePolicy of action.payload) {
        smartPhoneEntities[smartPhonePolicy.policyNumber] = Object.assign(
          {},
          state.entities[smartPhonePolicy.policyNumber],
          { kydSmartPhoneData: smartPhonePolicy }
        );
      }
      return Object.assign({}, state, {
        ids: Object.keys(Object.assign({}, state.entities || {}, smartPhoneEntities)),
        entities: Object.assign({}, state.entities || {}, smartPhoneEntities),
        loaded: true,
        loading: false
      });
    }

    case kydEligibilityActions.LOAD_SMARTPHONE_KYD_POLICIES_FAIL:
      return Object.assign({}, state, {
        hasError: true,
        error: action.payload,
        loading: false
      });

    default:
      return state;
  }
}

export const getEntities = (state: Entities<KydPolicyEligibility>) => _get(state, 'entities');

export const getIds = (state: Entities<KydPolicyEligibility>) => _get(state, 'ids');

export const getError = (state: Entities<KydPolicyEligibility>) => _get(state, 'error');

export const getLoaded = (state: Entities<KydPolicyEligibility>) => _get(state, 'loaded');

export const getLoading = (state: Entities<KydPolicyEligibility>) => _get(state, 'loading');

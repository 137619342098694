import { ClaimEnterpriseAction, ClaimEnterpriseActionTypes } from './claim-enterprise.actions';

export const CLAIM_ENTERPRISE_FEATURE_KEY = 'claimEnterprise';

export interface ClaimEnterpriseState {
  url: string;
  loading: boolean;
  hasError: boolean;
  loaded: boolean;
}

export const initialState: ClaimEnterpriseState = {
  url: null,
  loading: false,
  hasError: false,
  loaded: false
};

export function claimEnterpriseReducer(
  state: ClaimEnterpriseState = initialState,
  action: ClaimEnterpriseAction
): ClaimEnterpriseState {
  switch (action.type) {
    case ClaimEnterpriseActionTypes.CreateClaim:
      return Object.assign({}, state, action.payload, {
        loading: true,
        loaded: false,
        hasError: false
      });

    case ClaimEnterpriseActionTypes.CreateClaimSuccess:
      return Object.assign({}, state, action.payload, {
        loading: false,
        loaded: true,
        hasError: false
      });

    case ClaimEnterpriseActionTypes.CreateClaimFail:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        hasError: true
      });

    default:
      return state;
  }
}

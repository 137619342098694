import { ApiStatus } from '@amfam/shared/models';
import { TypedAction } from '@ngrx/store/src/models';
export interface GetAutoPayPredictionPayload {
  billAccountNumber: string;
  autopayRule: AutoPayRule;
  predictionOnly: boolean;
  transactionId: string
}

export interface GetAutoPayPredictionResponse {
  status: ApiStatus;
  autoPayRules: AutomaticPayment[];
}

export interface AddEditAutoPayPayload {
  autopayRule: AutoPayRule;
  transactionId: string;
}

export interface AutoPayRule {
  paymentAccountNickName: string;
  daysBeforeDueDate: string;
  paymentAmount: AutoPayAmounts;
}

export interface AddEditAutoPayResponse {
  status: ApiStatus;
  autoPayRules: AutomaticPayment[];
}

export interface AddMultipleAutoPayPayload {
  accounts: {
    billingNumber: string;
    paymentAccountNickName: string;
    daysBeforeDueDate: string;
    paymentAmount: string;
  }[];
}

export interface AddMultipleAutoPayResponse {
  autoPayRules: AutomaticPayment[];
  partialStatuses: {
    payloadEntityId: string; // This is the bill account number
    status: ApiStatus;
  }[];
  status: ApiStatus;
}

export interface DeleteAutoPayPayload {
  action?: {
    payload: DeleteAutoPayPayload;
    correlationId: string;
} & TypedAction<"[AutoPay] Delete AutoPay">;
  billAccountNumber: string;
}

export interface DeleteAutoPayResponse {
  status: ApiStatus;
}

export interface GetAutomaticPaymentsPayload {
  billAccountNumber: string;
}

export interface GetAutomaticPaymentsResponse {
  status: ApiStatus;
  autoPayRules: AutomaticPayment[];
}

export interface AutomaticPayment {
  billAccountNumber: string;
  daysBeforeDueDate: string;
  lastUpdateTimestamp: string;
  paymentAccount: {
    nickName: string;
    creditCard?: {
      cardNumber: string;
      cardType: string;
    };
    achWithdrawal?: {
      routingNumber: string;
      accountNumber: string;
      accountType: string;
      accountUse: string;
      financialInstitution: string;
    };
    lastUpdateTimestamp?: string;
    paymentAccountId?: string;
    modeOfAuthorization?: string;
  };
  paymentAmount: 'MinDue' | 'FullPay';
  nextPaymentDate: string;
  predictedDollarAmount: string;
}

export interface ApplyAutoPayDiscountPayload {
  FormInstanceTypeAutoPay: {
    policies: {
      policyNumber: string;
      producerId: string;
    }[];
    billAccount: string;
    paymentType: string;
    autoPayIndicator: boolean;
    effectiveDate: string;
    customerId: string;
    partnerId: string;
    templateCode: 'AUTO_PAY_CHANGE';
  };
}

export interface ApplyAutoPayDiscountResponse {
  status: ApiStatus;
}

export enum AutoPayAmounts {
  FULLPAY = 'FULLPAY',
  MINDUE = 'MINDUE'
}

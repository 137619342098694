import { Observable } from 'rxjs';

import * as appBootstrapActions from './app-bootstrap.actions';
import { AppBootstrap, initialAppBootstrap } from './app-bootstrap.model';
import { isValid, isAfter, isWithinRange } from 'date-fns';
import { has as _has } from 'lodash';

export function reducer(
  state = initialAppBootstrap,
  action: appBootstrapActions.SystemStatusActions
): AppBootstrap {
  switch (action.type) {
    case appBootstrapActions.START_APPLICATION:
      return Object.assign({}, state, {
        bannerContent: '',
        showBanner: false,
        appStarted: true
      });

    // This case is in a block to isolate variables
    case appBootstrapActions.LOAD_SYSTEM_BANNER_SUCCESS: {
      let shouldShowBanner = false;
      // Split string into message and time range in which the banner is to be shown
      const [message, timeRange]: string[] = _has(action, 'payload.richContent')
        ? action.payload.richContent.split('^')
        : ['', ''];
      if (message && message !== '') {
        shouldShowBanner = true;
      }
      if (timeRange) {
        const [start, end]: string[] = timeRange.split('|');
        if (isValid(new Date(start)) && isValid(new Date(end)) && isAfter(end, start)) {
          // Show banner if current time is inside the given range
          shouldShowBanner = isWithinRange(new Date(), start, end);
        }
      }

      return Object.assign({}, state, {
        bannerContent: message,
        showBanner: shouldShowBanner,
        appStarted: true
      });
    }

    case appBootstrapActions.LOAD_SYSTEM_BANNER_FAIL:
      return Object.assign({}, state, {
        bannerContent: '',
        showBanner: false,
        appStarted: true
      });

    default:
      return state;
  }
}

export const bannerContent = (state: AppBootstrap) => state.bannerContent;

export const showBanner = (state: AppBootstrap) => state.showBanner;

export const appStarted = (state: AppBootstrap) => state.appStarted;

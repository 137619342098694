export enum Partners {
  CAH = '534',
  ONSTAR = '536',
  GENERAL = '635',
  COSTCO_CHOICE = '526',
  AFBA = '524',
  CONNECT_COSTCO = '149',
  CONNECT_NON_COSTCO = '101',
  COMPARE = '521',
  INSURIFY = '520',
  COVER_MY_STUFF = '151',
  MATIC = '514',
  ELECTRIC_BOOKROLL = '515',
  INSURITAS = '513',
  HOMEGAUGE = '512',
  METLIFE_BOOKROLL = '138',
  WELLS_FARGO = '500',
  UW_MADISON = '510',
  DIRECT_32 = '511',
  AFI_MSA_FARM = 'AFI',
  AFI = '100',
  AMFAM = '124'
}

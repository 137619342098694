import { Injectable } from '@angular/core';

import { ConfigService } from '../config/config.service';
import { Applications } from './application.enum';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  constructor(private config: ConfigService) {}

  isApp(appName: Applications): boolean {
    const configAppName: string = this.config.get('applicationTitle');
    if (configAppName === appName) {
      return true;
    }
    return false;
  }

  getAppName(): string {
    return this.config.get('applicationTitle');
  }
}

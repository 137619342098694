//TODO: This will move to claims folder once Post-FNOL is merged
import { ButtonAnalytic, EventAnalytic, PageAnalytic } from '../analytics.model';

export class ClaimsAnalytics {
  public static readonly pageClaimHistory: PageAnalytic = {
    pageName: 'MyAccount:Claims:History',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReportClaimRiskSelection: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:RiskSelection',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReportClaimDriverSelection: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:VehicleDriver',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageReportClaimStart: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Start',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly pageReportClaimPropertyStart: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Property:ClaimStart',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };
  public static readonly eventReportClaimStart: EventAnalytic = {
    eventName: 'Report A Claim',
    eventStep: 'start'
  };

  public static readonly pageReportClaimAutoDuplicate: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:DuplicateClaim',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageReportClaimPropertyDuplicate: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Property:DuplicateClaim',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };

  public static readonly pageReportClaimPropertyContactInfo: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Property:YourContactInfo',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };

  public static readonly pageReportClaimAutoContactInfo: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:YourContactInfo',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageReportClaimPropertySummary: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Property:Summary',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };

  public static readonly pageReportClaimAutoSummary: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:Summary',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageReportClaimPropertyError: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Property:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };
  public static readonly pageReportClaimAutoError: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageReportClaimPropertyTimeout: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Property:ClaimSubmittedTimeout',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };

  public static readonly pageReportClaimAutoTimeout: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:ClaimSubmittedTimeout',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageReportClaimPropertyConfirmation: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Property:ClaimSubmitted',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };
  public static readonly pageClaimAutoConfirmation: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:ClaimSubmitted',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };
  public static readonly eventReportClaimPropertyConfirmation: EventAnalytic = {
    eventName: 'Report A Claim',
    eventStep: 'complete'
  };

  public static readonly pageNonVehicleClaim: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:NonVehicleClaim',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageAutoClaimIncidentInfo: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:ClaimStart',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageAutoClaimIncidentLocation: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Auto:Where',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageAutoClaimLossCause: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:How',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageAutoClaimDetails: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:DamageDetails',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageAutoClaimSummary: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Summary',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageGlassClaim: PageAnalytic = {
    pageName: 'MyAccount:Claims:GlassClaim',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageFAQClaim: PageAnalytic = {
    pageName: 'MyAccount:Claims:FAQ',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimStatus: PageAnalytic = {
    pageName: 'MyAccount:Claims:AllActivity',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimDetail: PageAnalytic = {
    pageName: 'MyAccount:Claims:ViewDetails',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimDetailError: PageAnalytic = {
    pageName: 'MyAccount:Claims:ViewDetails:ClaimLoadError',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimConfirmation: PageAnalytic = {
    pageName: 'MyAccount:ReportClaim:Complete',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly eventClaimConfirmation: EventAnalytic = {
    eventName: 'Report A Claim',
    eventStep: 'complete'
  };

  public static readonly pageClaimPhotoUploadIntro: PageAnalytic = {
    pageName: 'MyAccount:Claims:AddPhotos:Intro',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimPhotoUploadStart: PageAnalytic = {
    pageName: 'MyAccount:Claims:AddPhotos:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Add Photos',
    subCategory3: ''
  };

  public static readonly pageClaimPhotoUploadSuccess: PageAnalytic = {
    pageName: 'MyAccount:Claims:AddPhotos:Success',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Add Photos',
    subCategory3: ''
  };

  public static readonly pageClaimPhotoUploadFailed: PageAnalytic = {
    pageName: 'MyAccount:Claims:AddPhotos:Failed',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Add Photos',
    subCategory3: ''
  };

  public static readonly pageClaimPhotoUploadTooLarge: PageAnalytic = {
    pageName: 'MyAccount:Claims:AddPhotos:Select:TooLargeError',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Add Photos',
    subCategory3: ''
  };

  public static readonly pageClaimPhotoUploadComplete: PageAnalytic = {
    pageName: 'MyAccount:Claims:AddPhotos:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Add Photos',
    subCategory3: ''
  };

  public static readonly eventClaimPhotoUploadStart: EventAnalytic = {
    eventName: 'Claims Photo Upload',
    eventStep: 'start'
  };

  public static readonly eventClaimPhotoUploadComplete: EventAnalytic = {
    eventName: 'Claims Photo Upload',
    eventStep: 'complete'
  };

  public static readonly eventClaimDrpStart: EventAnalytic = {
    eventName: 'Find Repair Shop',
    eventStep: 'start'
  };

  public static readonly eventClaimDrpComplete: EventAnalytic = {
    eventName: 'Find Repair Shop',
    eventStep: 'complete'
  };

  public static readonly pageClaimPhotoUploadVIN: PageAnalytic = {
    pageName: 'MyAccount:Claims:AddPhotos:WhereIsVIN',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'Add Photos',
    subCategory3: ''
  };

  public static readonly pageDrpIntro: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:Intro',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageDrpStart: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageDrpDisclosureModal: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:Disclosure',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageDrpConfirmationComplete: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageDrpConfirmationFailed: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageDrpLocationYes: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:LocationPrompt:Yes',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly eventDrpLocationYes: EventAnalytic = {
    eventName: 'Find a Repair Shop',
    eventStep: 'Start'
  };

  public static readonly pageDrpLocationNo: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:LocationPrompt:No',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageDrpResultsYes: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:Results',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageDrpResultsNo: PageAnalytic = {
    pageName: 'MyAccount:Claims:FindRepairShop:NoResults',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  // Rental Page Analytics
  public static readonly pageClaimRentalReservationStart: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationPartialEligible: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:PartialEligible',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationNotEligible: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:NotEligible',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationYesLocation: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:LocationPrompt:Yes',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationNoLocation: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:LocationPrompt:No',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationYesResults: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:LocationResults',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationNoResults: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:NoResults',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationSelect: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:Select',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationFinalize: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:Finalize',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationError: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageClaimRentalReservationComplete: PageAnalytic = {
    pageName: 'MyAccount:Claims:RentalCarReservation:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly eventClaimRentalReservationStart: EventAnalytic = {
    eventName: 'Rental Car Reservation',
    eventStep: 'start'
  };

  public static readonly eventClaimRentalReservationComplete: EventAnalytic = {
    eventName: 'Rental Car Reservation',
    eventStep: 'complete'
  };

  public static readonly clickFindDrpLater: ButtonAnalytic = {
    link: 'FindRepairShopSelectLater'
  };

  public static readonly clickCallUsDrp: ButtonAnalytic = {
    link: 'FindRepairShopErrorCallUs'
  };

  public static readonly clickFindRentalLater: ButtonAnalytic = {
    link: 'RentalCarReservationSelectLater'
  };

  public static readonly callUsRentalPartialEligible: ButtonAnalytic = {
    link: 'RentalCarPartialEligibleContactUs'
  };

  public static readonly callUsRentalNotEligible: ButtonAnalytic = {
    link: 'RentalCarNotEligibleContactUs'
  };

  public static readonly clickCallUsRental: ButtonAnalytic = {
    link: 'RentalCarReservationErrorCallUs'
  };

  public static readonly clickClaimCompleteAddPhotos: ButtonAnalytic = {
    link: 'ReportClaimCompleteAddPhotos'
  };

  public static readonly clickClaimCompleteFindRepairShop: ButtonAnalytic = {
    link: 'ReportClaimCompleteFindRepairShop'
  };

  public static readonly clickClaimStatusAddPhotos: ButtonAnalytic = {
    link: 'ReportClaimAllActivityAddPhotos'
  };

  public static readonly clickClaimPhotoUploadBrowseFiles: ButtonAnalytic = {
    link: 'ReportClaimBrowseFiles'
  };

  public static readonly clickClaimResumeDraft: ButtonAnalytic = {
    link: 'ClaimsOverviewResumeDraft'
  };

  public static readonly clickClaimOverviewAccidentChecklist: ButtonAnalytic = {
    link: 'ClaimsOverviewAccidentChecklist'
  };

  public static readonly clickPropertyFnolCancel: ButtonAnalytic = {
    link: 'MyAccount:ReportClaim:Property:CancelSubmission'
  };

  public static readonly clickAutoFnolCancel: ButtonAnalytic = {
    link: 'MyAccount:ReportClaim:Auto:CancelSubmission'
  };

  public static readonly clickPropertyFnolDuplicateResumeDraftClaim: ButtonAnalytic = {
    link: 'MyAccount:ReportClaim:Property:DuplicateClaim:ResumeClaim'
  };

  public static readonly clickAutoFnolDuplicateResumeDraftClaim: ButtonAnalytic = {
    link: 'MyAccount:ReportClaim:Auto:DuplicateClaim:ResumeClaim'
  };

  public static readonly clickPropertyFnolDuplicateReportNewClaim: ButtonAnalytic = {
    link: 'MyAccount:ReportClaim:Property:ReportNewClaim'
  };

  public static readonly clickAutoFnolDuplicateReportNewClaim: ButtonAnalytic = {
    link: 'MyAccount:ReportClaim:Auto:ReportNewClaim'
  };

  public static readonly pageClaimFAQ: PageAnalytic = {
    pageName: 'MyAccount:Claims:FAQ',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Claims',
    subCategory2: 'FAQ',
    subCategory3: ''
  };
}

export interface KydCopy {
  kyd: {
    termsTitle: string;
    enrollTitleSuccess: string;
    enrollTitleError: string;
    enrollTitleWarning: string;
    nextStepsSuccess: string;
    nextStepsWarning: string;
    nextStepsError: string;
    disclaimer: string;
    optOutMessage: string;
  };
}

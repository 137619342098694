<div class="main row">
  <div class="column twelve sm-ten sm-push-one md-eight md-push-two lg-six lg-push-three">
    <div *ngIf="!securityQuestionsSuccess">
      <h1 content [contentPath]="'auth.addSecurityQuestionsHeader'"></h1>
      <section class="margin-bottom-20">
        <p class="margin-bottom-20" content [contentPath]="'auth.addSecurityQuestionsText'"></p>
        <manage-security-questions [buttonTextInput]="'Save'" (complete)="onComplete($event)">
        </manage-security-questions>
        <div *ngIf="securityQuestionsFailed">
          <div class="flex margin-top-20 margin-bottom-20">
            <span class="icon icon-alert margin-right-10"></span>
            <p
              class="color-red margin-right-20"
              content
              [contentPath]="'auth.addSecurityQuestionsError'"
            ></p>
          </div>
          <div class="row flex items-center margin-left-40">
            <button ds-button class="pad-left-30 pad-right-30" dsRouterLink="/overview">
              Skip for now
            </button>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="securityQuestionsSuccess">
      <div class="flex margin-bottom-30">
        <span class="icon icon-complete-circle margin-right-10"></span>
        <p content [contentPath]="'auth.addSecurityQuestionsSuccess'"></p>
      </div>
      <a class="margin-top-60" dsRouterLink="/overview">Go to Overview</a>
    </div>
  </div>
</div>

import { DigitalAccount } from '@amfam/profile/data-access';

export interface Magic3SearchRequest {
  lastName: string;
  dob: string;
  zipcode: string;
  partnerId: string;
}

export interface SendPinRequest {
  customerId?: string;
  typeOfVerificationMethodCode: string;
  partnerId: string;
  maskedPhoneNumber: string;
}

export interface VerifyPinRequest {
  pinCode: string;
  partnerId: string;
}

export interface ValidateTokenRequest {
  correlationId: string;
}

export interface UpdateEmailData {
  waid: string;
  digitalAccount: DigitalAccount;
}

export interface UpdateEmailRequest {
  partnerId: string;
  experienceId: string;
  digitalAccount: DigitalAccount;
}

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { PageAnalytic } from '@amfam/shared/utility/shared-services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['../../enrollment.component.scss']
})
export class VerificationComponent implements OnInit {
  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic;
  // **** End of Analytics data for this component
  constructor(private analyticsFacade: AnalyticsFacade, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.pageAnalytic = {
      pageName: `MyAccount:Enroll:ConfirmIdentity`,
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity'
    };

    this.analyticsFacade.trackPage(this.pageAnalytic);
  }
}

<ul class="criteria-list">
  <li
    *ngFor="let item of criteriaItems"
    [ngClass]="{
      isValid: item.valid,
      hasError: item.error
    }"
    class="pseudo-icon flex items-center"
  >
    <span class="margin-top-5 margin-left-5">{{ item.text }}</span>
  </li>
</ul>

import { filter, switchMap, take } from 'rxjs/operators';
import { BrandModel, BrandSelectors } from '@amfam/shared/utility/brand';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import * as fromRoot from '../../core/store';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { NavigationService } from '@amfam/shared/utility/navigation';

@Injectable()
export class NavigationResolver  {
  constructor(
    private store: Store<fromRoot.RootState>,
    private router: NavigationService,
    private featureFlagService: FeatureFlagService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.featureFlagService.isEnabled('partner')) {
      return of(true);
    }
    const routeExperienceId = route.paramMap.get('expid');
    return this.waitForUserBrandToLoad().pipe(
      switchMap(() => {
        return this.getBrand().pipe(
          switchMap(brand => {
            if (!brand.isPartner && !routeExperienceId) {
              // no redirect for AFI
              return of(true);
            }
            let pathIndex = 2;
            if (!routeExperienceId) {
              pathIndex = 1;
            }
            if (brand.experienceId !== routeExperienceId) {
              const url = state.url.split('/');
              const routeArray = [];
              for (let i = pathIndex; i < url.length; i++) {
                routeArray.push(url[i].split('?')[0]);
              }
              if (state.url !== '/' + routeArray.join('/') || brand.isPartner) {
                this.router.navigate(routeArray);
              }
            }
            return of(true);
          })
        );
      })
    );
  }

  private getBrand(): Observable<BrandModel> {
    return this.store.select(BrandSelectors.getBrandState).pipe(take(1));
  }

  private waitForUserBrandToLoad(): Observable<boolean> {
    return this.store.select(BrandSelectors.getUserBrandLoaded).pipe(
      filter(loaded => loaded),
      take(1)
    );
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { userQuery, UserState } from '@amfam/shared/user';
import { Store } from '@ngrx/store';

import { GetEligibleServicesPayload, EligibleServicesResponse } from './eligible-services.models';
import { ClaimServicesService } from '../../services/claim-services.service';

import {
  GetEligibleServices,
  GetEligibleServicesSuccess,
  GetEligibleServicesFail,
  EligibleServicesActionTypes
} from './eligible-services.actions';

@Injectable()
export class EligibleServicesEffects {
  
  getEligibleServices$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GetEligibleServices>(EligibleServicesActionTypes.GetEligibleServices),
    map(action => action.payload),
    withLatestFrom(this.userStore.select(userQuery.getCustomerId)),
    switchMap(([payload, customerId]) => {
      return this.claimServicesService
        .getEligibleServices(payload.claimNumber, payload.vin, customerId)
        .pipe(
          map((response: EligibleServicesResponse) => new GetEligibleServicesSuccess(response)),
          catchError(error => of(new GetEligibleServicesFail(error)))
        );
    })
  ));

  constructor(
    private actions$: Actions,
    private claimServicesService: ClaimServicesService,
    private userStore: Store<UserState>
  ) {}
}

<div class="row">
  <div class="column relative twelve emailListInfo" data-cy="contactEmailInfoWrapper">
    <div
      class="pad-top-10 pad-bottom-10 items-center justify-space-between sm-flex"
      data-cy="contactEmailInfo"
    >
      <div *ngIf="isShellAccount" class="sm-margin-right-10 flex-auto">
        <b data-cy="contactEmail">{{ email.emailAddress }}</b>
        <span *ngIf="email.emailAddress == shellEmail">
          <span class="pad-left-20 success-400-text icon icon-complete-circle"></span>
          <span
            class="pad-left-5 subTextSize"
            content
            [contentPath]="'shared.enrolledEmail'"
          ></span>
        </span>
        <div class="flex" *ngIf="email.emailAddress == shellEmail && isShellAccount">
          <span class="error-600-text icon-alert icon-error"></span>
          <p
            class="margin-left error-600-text"
            content
            [contentPath]="'shared.resendEmailBanner'"
          ></p>
        </div>
      </div>
      <div *ngIf="!isShellAccount" class="sm-margin-right-10 flex-auto">
        <b data-cy="contactEmail">{{ email.emailAddress }}</b>
        <span *ngIf="isPrimaryEmail">
          <span class="pad-left-20 success-400-text icon icon-complete-circle icon-size"></span>
          <span
            class="pad-left-5 subTextSize"
            content
            [contentPath]="'shared.primary'"
            data-cy="primaryEmailValidation"
          ></span>
        </span>
        <span
          *ngIf="isPendingEmail && !tokenExpiry"
          class="pad-left-20 subTextSize"
          data-cy="pendingVerification"
          content
          [contentPath]="'profile.manageEmails.pendingVerificationText'"
        ></span>
        <p class="subTextSize" data-cy="contactEmailType" *ngIf="!isPrimaryEmail">
          {{ email.contactMethodUsages[0].typeOfUsageCode | titlecase }}
          <span *ngIf="email.contactMethodUsages[0].descriptionForOtherType"> - </span>
          {{ email.contactMethodUsages[0].descriptionForOtherType }}
        </p>
        <p class="subTextSize" *ngIf="isPrimaryEmail">
          {{ primaryEmailContactMethodUsage.typeOfUsageCode | titlecase }}
          <span *ngIf="primaryEmailContactMethodUsage.descriptionForOtherType"> - </span>
          {{ primaryEmailContactMethodUsage.descriptionForOtherType }}
        </p>
        <div *ngIf="isPrimaryEmail && isDuplicateEmail">
          <span class="icon-alert duplicateEmailIcon"></span>
          <span
            class="duplicateEmailContent column twelve"
            content
            [contentPath]="'overview.duplicateEmailProfileContent'"
            [options]="{ contactNumber: contactNumber }"
          ></span>
        </div>
        <div
          *ngIf="isPendingEmail && primaryEmail && primaryEmail.emailAddress && !tokenExpiry"
          class="pad-top-20"
        >
          <span content [contentPath]="'profile.manageEmails.primaryEmailVerificationText1'"></span>
          <span
            ><b>{{ primaryEmail.emailAddress }}</b></span
          >
          <span content [contentPath]="'profile.manageEmails.primaryEmailVerificationText2'"></span>
        </div>
        <div
          *ngIf="isPendingEmail && primaryEmail && primaryEmail.emailAddress && tokenExpiry"
          class="pad-top-20"
        >
          <span
            content
            [contentPath]="'profile.manageEmails.primaryEmailVerificationExpired'"
          ></span>
        </div>

        <div *ngIf="isPendingEmail" class="pad-top-20">
          <a
            *ngIf="roleService.isAuthorized('send_verification_link') | async"
            (click)="sendAnotherVerificationEmail()"
            content
            [contentPath]="'profile.manageEmails.sendAnotherVerificationEmailText'"
          ></a>
          <span
            *ngIf="!(roleService.isAuthorized('send_verification_link') | async)"
            class="color-blue"
            content
            [contentPath]="'profile.manageEmails.sendAnotherVerificationEmailText'"
          >
          </span>
        </div>
      </div>
      <a
        [hidden]="
          makePrimaryEmailAddDeleteBtnToggleDisplay ||
          isPrimaryEmail ||
          isPendingEmail ||
          !(roleService.isAuthorized('deleteemailaddr_view') | async)
        "
        (click)="deleteEmail()"
        data-cy="deleteEmailLink"
        class="caption error-600-text font-weight-bold"
        content
        [contentPath]="'shared.deleteBtn'"
      >
      </a>
      <button
        ds-button
        *ngIf="!(isPrimaryEmail || isPendingEmail) && !isShellAccount"
        [disabled]="!(roleService.isAuthorized('make_primary_email') | async)"
        class="-outline-white flex-constrained margin-top-10 sm-margin-top-0 margin-left-20"
        (click)="makePrimary()"
        data-cy="makePrimaryCheckbox"
        [attr.aria-label]="makePrimaryButtonText"
      >
        {{ makePrimaryButtonText }}
      </button>
      <button
        ds-button
        *ngIf="isShellAccount && email.emailAddress == shellEmail"
        class="-outline-white flex-constrained margin-top-10 sm-margin-top-0 margin-left-20"
        (click)="resendEmail()"
        data-cy="makePrimaryCheckbox"
        [attr.aria-label]="resendEmailButtonText"
      >
        {{ resendEmailButtonText }}
      </button>
      <button
        ds-button
        *ngIf="isShellAccount && email.emailAddress != shellEmail"
        class="-outline-white flex-constrained margin-top-10 sm-margin-top-0 margin-left-20"
        (click)="changeEmail(email.emailAddress)"
        data-cy="makePrimaryCheckbox"
        [attr.aria-label]="changeEmailButtonText"
      >
        {{ changeEmailButtonText }}
      </button>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';

// application modules
import { SharedModule } from '../shared/shared.module';

// components/directives/pipes
import { ProfileComponent } from './profile.component';
import { ProfileFeatureModule } from '@amfam/profile/feature';
import { UseridAvailabilityComponent } from './userid-availability/userid-availability.component';
import { ValidateEmailTokenComponent } from './validate-email-token';
import { EditUseridComponent } from './edit-userid/edit-userid.component';
// resolve handlers

@NgModule({
  imports: [SharedModule, ProfileFeatureModule],
  declarations: [
    ProfileComponent,
    UseridAvailabilityComponent,
    ValidateEmailTokenComponent,
    EditUseridComponent
  ],
  exports: [
    ProfileComponent,
    UseridAvailabilityComponent,
    ValidateEmailTokenComponent,
    EditUseridComponent
  ],
  providers: []
})
export class ProfileModule {}

<form [formGroup]="billingPreferencesForm" class="fill">
  <div formArrayName="billingPreferences">
    <div class="preferences-row" *ngFor="let preference of preferenceData; index as i">
      <div class="row">
        <div class="column twelve flex flex-direction-column md-flex-direction-row">
          <div class="row flex grow">
            <div class="flex wrap">
              <div class="fill">Bills for {{ preference.billAccountName }}</div>
              <div class="fill description-text">{{ preference.riskNames?.join(', ') }}</div>
            </div>
          </div>
          <div class="row billing preference-options">
            <!--
              Below implementation to disable radio button is workaround implementation from https://github.com/angular/angular/issues/11763#issuecomment-268622631
            -->
            <div class="column preference-options-item">
              <fieldset
                [disabled]="
                  preference.canSetPreferences === false ||
                  !(roleService.isAuthorized('edit_communication_prefrences') | async) ||
                  adminPartnerDisabled
                "
              >
                <input type="radio" id="paper-{{ i }}" value="paper" formControlName="{{ i }}" />
              </fieldset>
              <label class="block md-none" for="paper-{{ i }}">Paper</label>
            </div>
            <div class="column preference-options-item">
              <fieldset
                [disabled]="
                  preference.canSetPreferences === false ||
                  !(roleService.isAuthorized('edit_communication_prefrences') | async) ||
                  adminPartnerDisabled
                "
              >
                <input type="radio" id="email-{{ i }}" value="email" formControlName="{{ i }}" />
              </fieldset>
              <label class="block md-none" for="email-{{ i }}">Email</label>
            </div>
            <div class="column preference-options-item">
              <fieldset
                [disabled]="
                  preference.canSetPreferences === false ||
                  !(roleService.isAuthorized('edit_communication_prefrences') | async) ||
                  adminPartnerDisabled
                "
              >
                <input type="radio" id="both-{{ i }}" value="both" formControlName="{{ i }}" />
              </fieldset>
              <label class="block md-none" for="both-{{ i }}">Both</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="canWarn[i] && billingPreferences.controls[i].value !== 'email'" class="row">
        <div class="column twelve">
          <ds-preferences-warning
            [billAccountType]="preference.billAccountType"
            [deliveryPreference]="billingPreferences.controls[i].value"
          >
          </ds-preferences-warning>
        </div>
      </div>
    </div>
  </div>
</form>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAnalytics from './analytics.reducer';

export const selectAnalyticsState = createFeatureSelector<fromAnalytics.State>(
  fromAnalytics.analyticsFeatureKey
);

export const getAdobeAnalyticsLoaded = createSelector(
  selectAnalyticsState,
  (state: fromAnalytics.State) => {
    return state.adobeAnalyticsLoaded;
  }
);

export const getGoogleAnalyticsLoaded = createSelector(
  selectAnalyticsState,
  (state: fromAnalytics.State) => {
    return state.googleAnalyticsLoaded;
  }
);

export const getGtm = createSelector(selectAnalyticsState, (state: fromAnalytics.State) => {
  return state.gtm;
});

export const getAnalyticsLoaded = createSelector(
  selectAnalyticsState,
  (state: fromAnalytics.State) => {
    return (
      (state.adobeAnalyticsLoaded || state.adobeAnalyticsError) &&
      (state.adobeAnalyticsLoaded || state.adobeAnalyticsError)
    );
  }
);

export const getUser = createSelector(selectAnalyticsState, (state: fromAnalytics.State) => {
  return state.user;
});

import { Vehicle } from './vehicle';

export class Motorcycle extends Vehicle {
  public engineDisplacement: string;

  static fromJson(json: any): Motorcycle {
    const motorcycle = new Motorcycle();
    motorcycle.assignProperties(json);

    return motorcycle;
  }

  protected assignProperties(json: any) {
    super.assignProperties(json);

    this.engineDisplacement = json.engineDisplacement;
  }
}

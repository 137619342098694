export * from './lib/opportunities.module';
export * from './lib/opportunities-routing.module';
export * from './lib/containers/opportunities-overview/opportunities-overview.component';
export * from './lib/containers/opportunities-detail-page/opportunities-detail-page.component';
export * from './lib/containers/opportunities-detail-selection-page/opportunities-detail-selection-page.component';
export * from './lib/components';
export * from './lib/models';
export * from './lib/services/adapter.service';
export * from './lib/services/opportunity.service';
export * from './lib/services/core.service';
export * from './lib/+state';
export * from './lib/+state/opportunity.action';

<div *ngIf="!showAll" class="invalid-feedback" aria-live="assertive">
  <span class="error" *ngIf="errorMessages"> {{ errorMessages[0] }} </span>
</div>
<div *ngIf="showAll && groupMessages" aria-live="assertive">
  <ul class="error-list">
    <li *ngFor="let message of errorMessages">{{ message }}</li>
  </ul>
</div>
<div *ngIf="showAll && groupMessages" class="pad-top-10 row" aria-live="assertive">
  <div *ngFor="let messages of groupedAllErrorMessages" class="column twelve md-six">
    <ul class="error-list row">
      <li *ngFor="let message of messages">{{ message }}</li>
    </ul>
  </div>
</div>

import { Action } from '@ngrx/store';
import {
  FinancialAccountResponseModel,
  FetchPciTokenModel
} from '../models/financial-institution.models';

export enum FinancialInstitutionActionTypes {
  GetFinancialInstitution = '[FinancialInstitution] Get FinancialInstitution',
  GetFinancialInstitutionSuccess = '[FinancialInstitution] Get FinancialInstitution Success',
  GetFinancialInstitutionFail = '[FinancialInstitution] Get FinancialInstitution Fail',
  FetchAuthToken = '[FinancialInstitution] Fetch Auth Token',
  ResetFinancialInstitution = '[FinancialInstitution] Get FinancialInstitution Reset'
}

export class GetFinancialInstitution implements Action {
  readonly type = FinancialInstitutionActionTypes.GetFinancialInstitution;
  constructor(public payload: string) {}
}

export class GetFinancialInstitutionSuccess implements Action {
  readonly type = FinancialInstitutionActionTypes.GetFinancialInstitutionSuccess;
  constructor(public payload: FinancialAccountResponseModel) {}
}

export class GetFinancialInstitutionFail implements Action {
  readonly type = FinancialInstitutionActionTypes.GetFinancialInstitutionFail;
  constructor(public payload?: any) {}
}

export class ResetFinancialInstitution implements Action {
  readonly type = FinancialInstitutionActionTypes.ResetFinancialInstitution;
  constructor(public payload?: any) {}
}

export class FetchPciTokenAction implements Action {
  readonly type = FinancialInstitutionActionTypes.FetchAuthToken;
  constructor(public payload: FetchPciTokenModel) {}
}

export type FinancialInstitutionAction =
  | GetFinancialInstitution
  | GetFinancialInstitutionSuccess
  | GetFinancialInstitutionFail
  | ResetFinancialInstitution
  | FetchPciTokenAction;

export const fromFinancialInstitutionActions = {
  GetFinancialInstitution,
  GetFinancialInstitutionSuccess,
  GetFinancialInstitutionFail,
  ResetFinancialInstitution,
  FetchPciTokenAction
};

import { takeUntil } from 'rxjs/operators';
import { Directive, Input, Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';

// Store
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as BrandSelectors from '../+state/brand.selectors';
import { BrandModel as BrandState } from '../models/brand.model';

@Directive({
  selector: '[dsPartnerContent]'
})
export class PartnerContentDirective implements OnDestroy, OnInit {
  @Input() brandContent: string;
  @Input() brandHref: string;

  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private store: Store<BrandState>
  ) {}

  /**
   * PartnerContentDirective takes in a key from the template and searches Store for the matching Brand Service
   * content. The matching content is then rendered in the template instead of the default content.
   */
  ngOnInit() {
    const brandContent = this.brandContent;
    const brandHref = this.brandHref;

    if (brandContent && BrandSelectors[brandContent]) {
      this.store
        .select(BrandSelectors[brandContent])
        .pipe(takeUntil(this.stop$))
        .subscribe((content: string) => {
          if (content) {
            this.renderer.setProperty(this.el.nativeElement, 'innerHTML', content);
          }
        });
    }

    if (brandHref && BrandSelectors[brandHref]) {
      this.store
        .select(BrandSelectors[brandHref])
        .pipe(takeUntil(this.stop$))
        .subscribe((href: string) => {
          if (href) {
            this.renderer.setAttribute(this.el.nativeElement, 'href', href);
          }
        });
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

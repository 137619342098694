<div class="borderless-tiles">
  <div
    class="opportunity-tile"
    *ngFor="
      let opportunityContent of opportunitiesContentList
        | slice
          : 0
          : (opportunitiesSlicedLength > displayedOpportunitiesLength
              ? opportunitiesSlicedLength
              : displayedOpportunitiesLength)
    "
  >
    <ng-container *ngFor="let opportunityInfo of opportunityContent?.slides">
      <ds-opportunity-card
        *ngIf="opportunityInfo"
        [opportunityCardInformation]="opportunityInfo"
        [recommendationId]="opportunityContent.recommendationId"
        [successMessage]="opportunityContent.successNotification"
        [nothanksSuccessMessage]="opportunityContent.nothanksSuccessMessage"
        [isAuthorized]="isAuthorized"
        [failureMessage]="opportunityContent.failureNotification"
        (learnMoreEvent)="learnMoreEvent.emit(opportunityContent.recommendationId)">
      </ds-opportunity-card>
    </ng-container>
  </div>
</div>

<div
  class="column twelve pad-10 text-center"
  *ngIf="
    opportunitiesContentList?.length &&
    opportunitiesContentList?.length > displayedOpportunitiesLength
  "
>
  <p class="primary-800-text text-center">
    <a
      id="toggleLink"
      class="no-underline"
      href="javascript:void(0);"
      (click)="toggleContentList(
          opportunitiesSlicedLength,
          opportunitiesContentList?.length,
          displayedOpportunitiesLength
        )
      "
    >
      {{
        (opportunitiesSlicedLength > displayedOpportunitiesLength
          ? opportunitiesSlicedLength
          : displayedOpportunitiesLength) < opportunitiesContentList?.length
          ? 'Show More'
          : 'Show less'
      }}
      <span
        [ngClass]="
          (opportunitiesSlicedLength > displayedOpportunitiesLength
            ? opportunitiesSlicedLength
            : displayedOpportunitiesLength) < opportunitiesContentList?.length
            ? 'icon-chevron-down'
            : 'icon-chevron-up'
        "
      ></span>
    </a>
  </p>
</div>

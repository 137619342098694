import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFinancialAccount from './financial-account/financial-account.reducer';

import * as fromPaymentMethod from './payment-method/payment-method.reducer';

export const paymentMethodFeatureKey = 'paymentMethod';

// Reducer Map
export interface State {
  paymentMethodState: fromPaymentMethod.State;
  financialAccountState: fromFinancialAccount.State;
}

export const reducers: ActionReducerMap<State> = {
  paymentMethodState: fromPaymentMethod.reducer,
  financialAccountState: fromFinancialAccount.reducer
};

// State Selectors
export const getState = createFeatureSelector<State>(paymentMethodFeatureKey);

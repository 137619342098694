<div class="box">
  <div class="payment-detail-card">
    <!-- first column for account info -->
    <div class="payment-detail-card-left">
      <div class="payment-detail-card-item-container">
        <div class="payment-detail-card-item-block">
          <span class="pseudo-icon bill-account-icon" [ngClass]="payment.icon"></span>
        </div>
        <div class="payment-detail-card-item-block">
          <p>{{ payment.riskDescription }}</p>
          <p class="payment-detail-card-caption">Acct {{ payment.billAccountNumber }}</p>
        </div>
      </div>
    </div>

    <!-- second column for payment details -->
    <div class="payment-detail-card-right">
      <div class="payment-detail-card-right-item-container top-border padding">
        <p>{{ paymentAmount }}</p>
        <p>{{ paymentDate }}</p>
      </div>
      <div class="payment-detail-card-right-item-container">
        <ds-payment-method-card
          [paymentAccount]="paymentAccount"
          [showIcon]="false"
        ></ds-payment-method-card>
      </div>
    </div>
  </div>
</div>

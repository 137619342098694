import { Action } from '@ngrx/store';
import { KydConfirmation, KydSignUp, KydUnenrollRequest } from './models';
import { Policy } from '@amfam/policy/models';

export const ENROLL = '[KydEnroll] Enroll';
export const ENROLL_SUCCESS = '[KydEnroll] Enroll Success';
export const ENROLL_COMPLETE = '[KydEnroll] Enroll Complete';
export const ENROLL_FAIL = '[KydEnroll] Enroll Fail';
export const UNENROLL = '[KydEnroll] Unenroll';
export const UNENROLL_SUCCESS = '[KydEnroll] Unenroll Success';
export const UNENROLL_COMPLETE = '[KydEnroll] Unenroll Complete';
export const UNENROLL_FAIL = '[KydEnroll] Unenroll Fail';

export class EnrollAction implements Action {
  readonly type = ENROLL;

  constructor(public payload: KydSignUp[]) {}
}

export class EnrollCompleteAction implements Action {
  readonly type = ENROLL_COMPLETE;

  constructor(public payload?: any) {}
}

export class EnrollSuccessAction implements Action {
  readonly type = ENROLL_SUCCESS;

  constructor(public payload: KydConfirmation[]) {}
}

export class EnrollFailAction implements Action {
  readonly type = ENROLL_FAIL;

  constructor(public payload?: any) {}
}

export class UnenrollAction implements Action {
  readonly type = UNENROLL;

  constructor(public payload: KydUnenrollRequest[]) {}
}

export class UnenrollCompleteAction implements Action {
  readonly type = UNENROLL_COMPLETE;

  constructor(public payload?: any) {}
}

export class UnenrollSuccessAction implements Action {
  readonly type = UNENROLL_SUCCESS;

  constructor(public payload: string[]) {}
}

export class UnenrollFailAction implements Action {
  readonly type = UNENROLL_FAIL;

  constructor(public payload?: any) {}
}

export type Actions =
  | EnrollAction
  | EnrollSuccessAction
  | EnrollFailAction
  | EnrollCompleteAction
  | UnenrollAction
  | UnenrollSuccessAction
  | UnenrollFailAction
  | UnenrollCompleteAction;

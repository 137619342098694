<div class="main row">
  <div class="column twelve sm-ten sm-push-one md-eight md-push-two lg-six lg-push-three">
    <div class="margin-bottom-10">
      <div id="verify-method"><router-outlet></router-outlet></div>
    </div>
    <div class="row">
      <small content [contentPath]="'auth.loginTrouble'"></small>
      <a
        *dsPartnerAFI
        href="javascript:void(0)"
        (click)="openContactUs()"
        class="caption"
        content
        [contentPath]="'auth.loginTroubleNumber'"
      ></a>
      <a
        *dsPartnerConnect
        class="caption"
        dsPartnerContent
        [brandContent]="'getPrettyCusCareNumber'"
        [brandHref]="'getCusCareNumberFullHref'"
      ></a>
    </div>
  </div>
</div>

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Swiper, SwiperOptions } from 'swiper/types';
import { defaultSwiperOptions } from './default-swiper-options';

@Component({
  selector: 'ds-swiper',
  templateUrl: './swiper.component.html'
})
export class DsSwiperComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('swiperElement', { static: true }) swiperElementRef: ElementRef;
  @Input() options: SwiperOptions;
  @Input() activeIndex = 0;
  @Output() initialized = new EventEmitter();
  @Output() slideChanged = new EventEmitter<number>();

  defaultOptions: SwiperOptions = defaultSwiperOptions;
  removeInitListener: () => void;
  slideChangedHandler: () => void;

  constructor(private renderer: Renderer2) {}

  public get swiperElement() {
    return this.swiperElementRef.nativeElement;
  }

  public get swiper(): Swiper {
    return this.swiperElement?.swiper;
  }

  ngAfterViewInit() {
    Object.assign(this.swiperElement, { ...this.defaultOptions, ...this.options });
    this.removeInitListener = this.renderer.listen(this.swiperElement, 'init', event => {
      this.initialized.emit(event);
      if (this.swiper.activeIndex !== this.activeIndex) this.slideTo(this.activeIndex);
    });
    this.slideChangedHandler = this.renderer.listen(this.swiperElement, 'slidechange', event => {
      const [swiper] = event.detail;
      this.slideChanged.emit(swiper.activeIndex);
    });
    this.swiperElement.initialize();
  }

  slideTo(index: number) {
    if (index === null || index === undefined) index = 0;
    this.swiper?.slideTo(index);
    this.slideChanged.emit(index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes["activeIndex"].currentValue !== null &&
      changes["activeIndex"].currentValue !== undefined &&
      changes["activeIndex"].currentValue !== changes["activeIndex"].previousValue
    )
      this.slideTo(changes["activeIndex"].currentValue);
  }

  ngOnDestroy() {
    if (this.removeInitListener) this.removeInitListener();
    if (this.slideChangedHandler) this.slideChangedHandler();
  }
}

@Component({
  selector: 'ds-swiper-slide',
  template: ` <swiper-slide>
    <ng-content></ng-content>
  </swiper-slide>`
})
export class DsSwiperSlideComponent {}

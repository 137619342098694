import { AnalyticsFacade } from '@amfam/shared/analytics';
import { featureFlagQuery } from '@amfam/shared/utility/feature-flag/data-access';
import {
  AnalyticsService,
  ConfigService,
  CookiesService,
} from '@amfam/shared/utility/shared-services';
import { BannerConfig } from '@amfam/ui-kit';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromStore from '../../core/store';
import * as sessionActions from '../../core/store/session/session.actions';
import { LoginAnalytics } from '../shared/login-constants';

@Component({
  selector: 'ds-login-base',
  templateUrl: './login-base.component.html',
  styleUrls: ['../login-form/login-form.component.scss']
})
export class LoginBaseComponent implements OnInit {
  cookiesEnabled = true;
  cookieCheckFeatureEnabled$: Observable<boolean>;
  responseCode = 0;
  emailRedirect = false;

  private validateEmailPath: string;
  duplicateEmailBannerConfig: BannerConfig;

  constructor(
    private store: Store<fromStore.RootState>,
    private cookiesService: CookiesService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private analyticsFacade: AnalyticsFacade
  ) {
    // did user try to login from amfam.com
    route.data.subscribe(data => {
      if (data['invalid']) {
        this.store.dispatch(
          new sessionActions.LoginUserFailAction({
            status: { code: 401, reason: 'invalid credentials' }
          })
        );
      }
    });
    this.validateEmailPath = this.config.get('profileValidateEmailPath');
  }

  ngOnInit() {
    this.cookiesEnabled = this.cookiesService.cookiesEnabled();
    if (!this.cookiesEnabled) {
      this.analyticsService.sendEvent('exception', LoginAnalytics.loginCookiesDisabledAnalytic);
    }
    this.cookieCheckFeatureEnabled$ = this.store.pipe(
      select(featureFlagQuery.getFeatureFlag('cookie_check')),
      map(cookieCheck => !!cookieCheck && cookieCheck.enabled)
    );
    this.analyticsFacade.trackPage(LoginAnalytics.loginAnalytic);
  }

  responseReceived(responseCode: number) {
    if (responseCode === 409) {
      this.analyticsFacade.trackPage(LoginAnalytics.loginMultipleEmailErrorAnalytic);
      this.duplicateEmailBannerConfig = {
        headingTextPath: 'overview.duplicateEmailError'
      };
    }
    this.responseCode = responseCode;
  }
}

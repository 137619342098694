import { ApiStatus } from  '@amfam/shared/models';

export interface LifeDetail {
  policy: Life;
  status: ApiStatus;
}

export interface Life {
  policyNumber: string;
  effectiveDate: string;
  coverageAmount: number;
  insured: Insured[];
  periodStartDate?: string;
  term?: string
}

export interface Insured {
  firstName: string;
  lastName: string;
  middleInitial: string;
  dateOfBirth: string;
  gender: string;
  age: string;
  addressInfo: [
    {
      addr1: string;
      addr2: string;
      city: string;
      state: string;
      zipCode: string;
    }
  ];
}

import { File } from './file';

export class BufferedFile extends File {
  buffer: ArrayBuffer;

  constructor(file: File, buffer: ArrayBuffer) {
    super(file.absolutePath, file.contentType, file.friendlyName);
    this.buffer = buffer;
  }
}

<div class="table-body-cell-label-content expandable-cell">
  <span class="overflow-hidden overflow-ellipsis payment-method-wrapper">
    <div class="flex items-center">
      <ds-credit-card-icon
        class="pad-right-10"
        [ccType]="icon"
        [showDefault]="'icon'"
        [ccIconHeight]="24"
      ></ds-credit-card-icon>
      <span
        data-cy="scheduledPaymentName"
        class="overflow-hidden overflow-ellipsis payment-method-name"
        *ngIf="title"
        title="{{ title }}"
      >
        {{ title }}</span
      >
    </div>
    <p *ngIf="paymentAccount?.creditCard?.cardType" class="cell-secondary whitespace-nowrap">
      {{ accountNumber | returnLast: 4 | redact: paymentAccount }}
    </p>
    <p *ngIf="paymentAccount?.achWithdrawal" class="cell-secondary whitespace-nowrap">
      {{ accountNumber | returnLast: 3 | redact: paymentAccount }}
    </p>
    <p class="cell-secondary whitespace-nowrap" *ngIf="subLine1" title="{{ subLine1 }}">
      {{ subLine1 }}
    </p>
  </span>
</div>

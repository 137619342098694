import { AutopayPredictActionTypes, AutopayPredictActions } from './autopay-predict.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get as _get, flatMap as _flatMap } from 'lodash';
import { AutopayPredict } from '../models/autopay-predict.models';

export const AUTOPAY_FEATURE_KEY = 'autopaypredict';

export interface AutopayPartialState {
  readonly [AUTOPAY_FEATURE_KEY]: AutopayEntityState;
}

export interface AutopayEntityState extends EntityState<AutopayPredict> {
  selectedBillAccountNumber: string;
}

export const adapter: EntityAdapter<AutopayPredict> = createEntityAdapter<AutopayPredict>({
  selectId: (autopayObjModel: AutopayPredict) => autopayObjModel.billAccountNumber,
  sortComparer: false
});

export const initialState: AutopayEntityState = adapter.getInitialState({
  selectedBillAccountNumber: ''
});

export function reducer(
  state: AutopayEntityState = initialState,
  action: AutopayPredictActions
): AutopayEntityState {
  switch (action.type) {
    case AutopayPredictActionTypes.ClearAutopayPredict: {
      return initialState;
    }

    case AutopayPredictActionTypes.GetAutopayPredictSuccess: {
      return adapter.addOne(_get(action, 'payload.autoPayRules[0]'), state);
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { EventAnalytic, PageAnalytic } from '@amfam/shared/utility/shared-services';

export class LoginAnalytics {
  public static readonly loginAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Login',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Customer',
    subCategory3: ''
  };

  public static readonly loginCookiesDisabledAnalytic: EventAnalytic = {
    eventName: 'Cookies Disabled',
    eventStep: 'login form'
  };

  public static readonly emailVerifiedAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:MakePrimaryEmail:Confirmation',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Updated Email Address',
    subCategory2: 'Email Validation',
    subCategory3: ''
  };

  public static readonly emailVerificationLogin: PageAnalytic = {
    pageName: 'MyAccount:Profile:UpdatedEmailAddress:Login',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly emailVerificationMagic3: PageAnalytic = {
    pageName: 'MyAccount:Profile:UpdatedEmailAddress:VerifyWithPersonalInfo',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Updated Email Address',
    subCategory2: 'Email Validation',
    subCategory3: ''
  };

  public static readonly emailVerificationSendCode: PageAnalytic = {
    pageName: 'MyAccount:Profile:UpdatedEmailAddress:SendCode',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Updated Email Address',
    subCategory2: 'Email Validation',
    subCategory3: ''
  };

  public static readonly emailVerificationConfirmCode: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:ResetMethod:EnterCode',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly loginMultipleEmailErrorAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Login:MultipleEmailError',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: '',
    subCategory3: ''
  };
}

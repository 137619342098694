import { ApiStatus } from '../api/api-status.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetRegisteredPaymentsResponse {
  status: ApiStatus;
  payments: Payment[];
}
export interface Payment {
  lastUpdateTimestamp: string;
  paymentId: string;
  autoPayIndicator: string;
  paymentMethod: string;
  totalPaymentAmount: string;
  billAccounts: PaymentBillAccount[];
  paymentAccount: {
    nickName: string;
  };
  receivedDate: string;
  paymentStatus: string;
  paymentType: string;
}

export interface PaymentBillAccount {
  billAcctPaymentAmount: string;
  billAccountNumber: string;
  nickName?: string;
}
export interface GetPaymentHistoryPayload {
  monthRange: MonthRange;
}

export interface MonthRange {
  startMonth: number;
  endMonth: number;
}

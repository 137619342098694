import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  OnInit
} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Option } from '@amfam/shared/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'single-select-question',
  templateUrl: './single-select-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleSelectQuestionComponent implements OnInit {
  @Input() selectOptions: Option[];
  @Input() selectedAnswer?: string;
  @Input() displayBackButton = false;
  @Output() submitQuestion = new EventEmitter<string>();
  @Output() cancelEvent = new EventEmitter();
  @Output() goBackEvent = new EventEmitter();

  singleSelectionGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.singleSelectionGroup = this.formBuilder.group({
      option: ['', Validators.compose([Validators.required])]
    });

    if (this.selectedAnswer) {
      const selectedOption = this.selectOptions.find(option => option.id === this.selectedAnswer);
      this.singleSelectionGroup.controls['option'].setValue(selectedOption);
    }
  }

  onSingleSelectSubmit(selectedOption: Option) {
    this.submitQuestion.emit(<string>selectedOption.id);
    this.singleSelectionGroup.reset();
  }

  cancel() {
    this.cancelEvent.emit();
  }

  goBack() {
    this.goBackEvent.emit();
    this.singleSelectionGroup.reset();
  }
}

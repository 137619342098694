import { Injectable } from '@angular/core';

import { parse } from 'date-fns';
import { CookiesService } from '@amfam/shared/utility/shared-services';
import { Store } from '@ngrx/store';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import * as fromRoot from '../../core/store';
@Injectable({
  providedIn: 'root'
})
export class UpdateContactService {
  constructor(private cookieService: CookiesService, private store: Store<fromRoot.RootState>) {}

  /**
   * Takes the trackId and lastLogin strings from the session
   * effect and determines whether or not we want to route the
   * user to the update contact page.
   * @param trackId
   * @param lastLogin
   */
  serveMandate(trackId: string, lastLogin: String) {
    const monthFromTrackId = this.calculateMonthFromTrackId(trackId);
    const lastLoggedInMonth = this.getParsedLastLoginMonth(lastLogin);
    const currentMonth = this.getCurrentMonth();
    if (
      !this.maProfileCookieExists() &&
      !this.lastLoggedInMonthIsTrackMonth(lastLoggedInMonth, monthFromTrackId) &&
      this.currentMonthIsTrackMonth(currentMonth, monthFromTrackId)
    ) {
      this.setMaProfileCheckCookie();
      this.routeToUpdateContactComponent();
    }
  }

  /**
   * Calculates the month from the track id's remainder
   * when divided by 12
   * @param trackId
   */
  calculateMonthFromTrackId(trackId: string): number {
    if (trackId && trackId !== '') {
      return Number(trackId) % 12;
    }
  }

  /**
   * Gets the date and returns the month number
   */
  getCurrentMonth(): number {
    return parse(new Date().toString()).getMonth();
  }

  /**
   * Parses the last login and returns the
   * month number
   * @param lastLogin
   */
  getParsedLastLoginMonth(lastLogin: String): number {
    return parse(lastLogin.toString()).getMonth();
  }

  /**
   * Sets a session cookie to keep track of if the
   * user has seen the update contact page.
   */
  setMaProfileCheckCookie() {
    this.cookieService.setItem('ma_profile_check', '1');
  }

  /**
   * Gets the session cookie verifying if the user has seen
   * the update contact component.
   */
  getMaProfileCheckCookie(): String {
    return this.cookieService.getItem('ma_profile_check');
  }

  /**
   * If the session cookie for the update contact component
   * exists, is the value '1'
   */
  maProfileCookieExists(): boolean {
    return this.getMaProfileCheckCookie() === '1' ? true : false;
  }

  /**
   * Determines whether or not the last logged in month is
   * equal to the month calculated from the track id.
   * @param lastLoggedInMonth
   * @param monthFromTrackId
   */
  lastLoggedInMonthIsTrackMonth(lastLoggedInMonth: number, monthFromTrackId: number): boolean {
    return lastLoggedInMonth === monthFromTrackId ? true : false;
  }

  /**
   * Determines whether or not the current month is equal to the month
   * calculated from the track id.
   * @param currentMonth
   * @param monthFromTrackId
   */
  currentMonthIsTrackMonth(currentMonth: number, monthFromTrackId: number): boolean {
    return currentMonth === monthFromTrackId ? true : false;
  }

  /**
   * Dispactches the fromRouterActions to the store
   * and routes to contact-info-mandate
   */
  routeToUpdateContactComponent() {
    this.store.dispatch(
      new fromRouterActions.Go({
        path: ['verify-contact-info']
      })
    );
  }
}

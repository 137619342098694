import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  AccountEnrollment,
  AnalyticOptions,
  ButtonAnalyticParams,
  EventAnalyticParams,
  PageAnalyticParams,
  ReplaceVehicleAnalyticParams
} from '../models/analytics.model';
import * as AnalyticsActions from './analytics.actions';
import { getAdobeAnalyticsLoaded } from './analytics.selectors';

@Injectable({ providedIn: 'root' })
export class AnalyticsFacade {
  constructor(private store: Store) {}

  private sendAnalytics(options: AnalyticOptions) {
    this.store.dispatch(AnalyticsActions.sendAnalytic({ options }));
  }

  /**
   * send button clicked link to Adobe
   *
   * @param {ButtonAnalytic} buttonAnalytic
   * @memberof AnalyticsFacade
   */
  trackButtonClick(link: ButtonAnalyticParams): void {
    const options: AnalyticOptions = {
      button: link
    };
    this.sendAnalytics(options);
  }

  /**
   * @param  {PageAnalyticParams} page
   * @memberof AnalyticsFacade
   */
  trackPage(page: PageAnalyticParams): void {
    const options: AnalyticOptions = {
      page
    };
    this.sendAnalytics(options);
  }

  /**
   * @param  {EventAnalyticParams} event
   * @memberof AnalyticsFacade
   */
  trackEvent(event: EventAnalyticParams): void {
    const options: AnalyticOptions = {
      event
    };
    this.sendAnalytics(options);
  }
  /**
   * Send page and events to Adobe
   *
   * @param {PageAnalytic} pageAnalytic
   * @param {EventAnalytic} eventAnalytic
   * @memberof AnalyticsFacade
   */

  trackPageAndEvent(
    page: PageAnalyticParams,
    event?: EventAnalyticParams,
    button?: ButtonAnalyticParams,
    replaceVehicle?: ReplaceVehicleAnalyticParams
  ): void {
    const options: AnalyticOptions = {
      page,
      event,
      button,
      replaceVehicle
    };
    this.sendAnalytics(options);
  }

  trackAutoPayEnrollmentCountAndPageAndEvent(
    page: PageAnalyticParams,
    event: EventAnalyticParams,
    accountEnrollment: AccountEnrollment
  ): void {
    const options: AnalyticOptions = {
      page,
      event,
      accountEnrollment
    };
    this.sendAnalytics(options);
  }

  isAdobeAnalyticsLoaded(): Observable<boolean> {
    return this.store.select(getAdobeAnalyticsLoaded);
  }
}

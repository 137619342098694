<div class="row">
  <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
    <div class="column twelve md-push-two md-eight">
      <confirmation
        [config]="confirmationConfig$ | async"
        (primaryEvent)="primaryButtonClick()"
        (tertiaryEvent)="tertiaryButtonClick()"
      >
        <div content *ngIf="overallSubmissionStatus$ | async as overallStatus">
          <!-- Failed Case Start -->
          <p
            *ngIf="
              overallStatus === submitStatuses.FAILURE ||
              overallStatus === submitStatuses.PARTIAL_FAILURE
            "
            class="margin-bottom-20"
          >
            {{ failurePaperlessSubheading }}
          </p>
          <p
            *ngIf="
              overallStatus === submitStatuses.FAILURE ||
              overallStatus === submitStatuses.PARTIAL_FAILURE
            "
            class="margin-bottom-20"
          >
            Failed

            <ds-paperless-confirmation-review-item
              *ngFor="let setup of failedReviewItems$ | async"
              [preference]="setup"
            >
            </ds-paperless-confirmation-review-item>
          </p>
          <!-- Failed Case End -->

          <!-- Success Case Start -->
          <p *ngIf="overallStatus === submitStatuses.PARTIAL_FAILURE" class="margin-bottom-20">
            Successful
          </p>
          <div
            *ngIf="
              overallStatus === submitStatuses.SUCCESS ||
              overallStatus === submitStatuses.PARTIAL_FAILURE
            "
          >
            <p
              *ngIf="overallStatus === submitStatuses.SUCCESS"
              class="subtitle"
              data-cy="successPaperlessSubheading"
            >
              {{ successPaperlessSubheading }}
            </p>
            <ds-paperless-confirmation-review-item
              *ngFor="let setup of successfulReviewItems$ | async"
              [preference]="setup"
            >
            </ds-paperless-confirmation-review-item>
          </div>
          <!-- Success Case End -->
        </div>
      </confirmation>
    </div>
  </div>
</div>

import { PaymentMethodFacade } from '@amfam/billing/payment-method/data-access';
import {
  PaymentMethodAddEditConfig,
  PaymentMethodAddEditOperationType
} from '@amfam/billing/payment-method/util';
import { CriteriaItem } from '@amfam/ui-kit';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'ds-bank-account-add-edit',
  templateUrl: './bank-account-add-edit.component.html',
  styleUrls: ['./bank-account-add-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankAccountAddEditComponent implements OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() financialInstitutionName: string;
  @Input() config: PaymentMethodAddEditConfig;

  criteria: Observable<CriteriaItem[]>;

  constructor(private paymentMethodFacade: PaymentMethodFacade) {}

  ngOnChanges(): void {
    this.criteria = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      map(() => {
        const nickNameControl: AbstractControl = this.formGroup.get('nickName');
        return [
          {
            text: 'Maximum 4 numbers',
            valid: !nickNameControl.hasError('noMoreThanFourNumbers') && nickNameControl.value,
            error: nickNameControl.hasError('noMoreThanFourNumbers')
          },
          {
            text: 'No special characters',
            valid: !nickNameControl.hasError('invalidNickName') && nickNameControl.value,
            error: nickNameControl.hasError('invalidNickName')
          },
          {
            text: 'Unique account name',
            valid: !this.formGroup.hasError('duplicateNickName') && nickNameControl.value,
            error: this.formGroup.hasError('duplicateNickName')
          }
        ];
      })
    );
  }

  businessAccountCheck(value: boolean) {
    // send analytics
    if (value) {
      let buttonAnalyticName =
        this.config.operation === PaymentMethodAddEditOperationType.ADD
          ? 'AddPaymentMethod:SetupSavingsChecking:BusinessAccount'
          : 'EditPaymentMethod:SetupSavingsChecking:BusinessAccount';
      this.paymentMethodFacade.sendPaymentMethodAnalytics({ buttonAnalyticName });
    }
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { initiateEnrollment, launchOverview, validateEmail } from './mae-feature.actions';
export const maeFeatureFeatureKey = 'maeFeature';

//TODO - State is yet to define
export interface MaeFeateureState {
  enrollmentFlow: boolean;
  email: string;
  correlationId: string;
}

export const initialState: MaeFeateureState = {
  enrollmentFlow: false,
  email: '',
  correlationId: ''
};

export function reducer(state: MaeFeateureState | undefined, action: Action): any {
  return mapReducer(state, action);
}

const mapReducer = createReducer(
  initialState,

  on(initiateEnrollment, state => ({
    ...state,
    enrollmentFlow: true
  })),
  on(validateEmail, (state, { email, correlationId }) => ({
    ...state,
    email: email,
    correlationId: correlationId
  })),
  on(launchOverview, state => ({
    ...state,
    enrollmentFlow: false
  }))
);

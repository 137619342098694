export class ClaimsNavigationPaths {
  public static readonly CLAIM_CALLUS = '/claims/report-claim-fnol/call-us';
  public static readonly CLAIM_CALLUS_INJURY_PATH = '/claims/report-claim-fnol/call-us/injury';
  public static readonly CLAIM_CALLUS_CONTACT_PATH = '/claims/report-claim-fnol/call-us/contact';
  public static readonly CLAIM_INCIDENT_TIME_PATH = '/claims/report-claim-fnol/incident-time';
  public static readonly CLAIM_INCIDENT_LOCATION_PATH =
    '/claims/report-claim-fnol/incident-location';
  public static readonly CLAIM_NONAUTO_PATH = '/claims/report-claim-fnol/non-auto';
  public static readonly CLAIM_HOME_PATH = '/claims';
  public static readonly CLAIM_REPORTFNOL_HOME_PATH = '/claims/report-claim-fnol';
  public static readonly CLAIM_REPORT_HOME_PATH = '/claims/report-claim';
  public static readonly CLAIM_FNOL_FILE_CLAIM_PATH =
    'claims/dynamic-fnol/claim-risk-selection/fileaclaim';
  public static readonly CLAIM_OVERVIEW_FNOL_FILE_CLAIM_PATH =
    '/claims/dynamic-fnol-overview/claim-risk-selection/claim';
  public static readonly CLAIM_CAUSE_PATH = '/claims/report-claim-fnol/cause';
  public static readonly CLAIM_CONTACT = '/claims/report-claim-fnol/contact';
  public static readonly CLAIM_SUMMARY = '/claims/report-claim-fnol/summary';
  public static readonly REPORT_CLAIM = '/claims/report-claim';

  // Dynamic Fnol library routes
  public static readonly DYNAMIC_FNOL_RISK_SELECTION = '/claims/dynamic-fnol/risk-selection';
  public static readonly DYNAMIC_FNOL_NEWCLAIM_RISK_SELECTION =
    '/claims/dynamic-fnol/claim-risk-selection';
  public static readonly DYNAMIC_FNOL_DRIVER_SELECTION = '/claims/dynamic-fnol/driver-selection';
  public static readonly CLAIM_CREATE_INCIDENT_INFO = '/claims/dynamic-fnol/incident-info';
  public static readonly CLAIM_CREATE_INCIDENT_LOCATION = '/claims/dynamic-fnol/incident-location';
  public static readonly CLAIM_CREATE_DYNAMIC_QUESTION = '/claims/dynamic-fnol/dynamic-question';
  public static readonly CLAIM_CREATE_THIRD_PARTY = '/claims/dynamic-fnol/third-party';
  public static readonly CLAIM_CREATE_VEHICLE_DAMAGE_MAP =
    '/claims/dynamic-fnol/vehicle-damage-map';
  public static readonly CLAIM_CREATE_CONTACT_INFO = '/claims/dynamic-fnol/contact-info';
  public static readonly CLAIM_CREATE_CLAIMANT_INFO = '/claims/dynamic-fnol/claimant-info';
  public static readonly CLAIM_CREATE_SUMMARY = '/claims/dynamic-fnol/summary';
  public static readonly CLAIM_CREATE_REVIEW = '/claims/dynamic-fnol/review';
  public static readonly CLAIM_CREATE_CONTACT_ERS = '/claims/dynamic-fnol/contact-ers';
  public static readonly CLAIM_CREATE_CONTACT_GLASS = '/claims/dynamic-fnol/contact-glass';
  public static readonly CLAIM_CREATE_EXISTING_CLAIMS = '/claims/dynamic-fnol/existing-claims';
  public static readonly CLAIM_CREATE_CONFIRMATION = '/claims/dynamic-fnol/confirmation';
  public static readonly CLAIM_CREATE_PROPERTY_CONFIRMATION =
    'claims/dynamic-fnol/property-confirmation';
  public static readonly CLAIM_CREATE_GUEST_CONFIRMATION =
    '/claims/dynamic-fnol/guest-confirmation';
  public static readonly CLAIM_CREATE_GUEST_PROPERTY_CONFIRMATION =
    '/claims/dynamic-fnol/guest-property-confirmation';
  public static readonly CLAIM_CREATE_THIRD_PARTY_EXISTING_CLAIMS =
    '/claims/dynamic-fnol/similar-claim';
  public static readonly CLAIM_CREATE_THIRD_PARTY_CONFIRMAITON =
    '/claims/dynamic-fnol/third-party-confirmation';
}

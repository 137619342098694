<div *ngIf="active" class="loading-container" data-cy="appLoadingSpinnerContent">
  <div *ngIf="!isProgressBar">
    <div class="box" [ngStyle]="{ 'max-width.px': maxWidth }">
      <div class="spinner" *ngIf="!isNewLoader">
        <div class="spin-accent"></div>
        <div class="center"></div>
      </div>
      <ng-container *ngIf="isNewLoader">
        <img class="car-icon" src="/assets/images/auto-icon-loader.svg" alt="loader car icon" />
        <div class="spinner-afi">
          <div class="spin-accent"></div>
          <div class="center"></div>
        </div>
      </ng-container>
      <div class="heading-main" [innerHTML]="loadingMessage">Loading</div>
      <div *ngIf="secondaryMessage && secondaryMessage.length > 0">
        <hr aria-hidden="true" class="fill" />
        <span [innerHTML]="secondaryMessage"></span>
      </div>
    </div>
  </div>
  <div class="box" *ngIf="isProgressBar && !isPartner" style="padding: 0px 0px" border-radius="3px">
    <section class="card-progress">
      <div class="card-progress--title">
        <div aria-hidden="true" class="card-progress__icons">
          <i class="afi-icon">
            <img src="/assets/images/Icon_puzzle.svg" />
          </i>
          <i class="afi-icon">
            <img src="/assets/images/Icon_Car.svg" />
          </i>
          <i class="afi-icon">
            <img src="/assets/images/Icon_Keys.svg" />
          </i>
          <i class="afi-icon">
            <img src="/assets/images/Icon_Discounts.svg" />
          </i>
        </div>
        <div class="card-progress--title__headline">
          <div>We’re calculating your auto quote</div>
          <div>Please wait, this may take a few minutes</div>
        </div>
      </div>
      <div style="border: none">
        <progress max="100" [value]="commentProgress.progress" class="progress"></progress>
        <p>{{ commentProgress.progress }}%</p>
      </div>
    </section>
  </div>
</div>

import { FnolRiskModel } from './claim-risk.model';
import { GenericProductType } from './generic-product-type';
import { PolicySummary } from './policy-summary';
import { RiskModel } from './risk.model';

export class RiskCompare {
  // order if strings in this array determines the sort order for risks
  public static sortKey = [
    'Family car',
    'Car/Light Truck',
    'Medium/Heavy Duty Truck',
    'Trailer',
    'Golf Cart',
    'Cycle',
    'ATV',
    'UTC',
    'Dirt Bike',
    'Moped/Scooter',
    'Snowmobile',
    'Watercraft',
    'Motor Home',
    'Camper/Trailer',
    'Tractor & Farm Machinery',
    'Vehicle is not on policy'
  ];

  private static getGenericProductTypeOrder(productType: GenericProductType) {
    let sort: number;
    switch (productType) {
      case GenericProductType.Auto:
        sort = 0;
        break;
      case GenericProductType.Home:
        sort = 1;
        break;
      case GenericProductType.Umbrella:
        sort = 2;
        break;
      default:
        sort = 100;
        break;
    }

    return sort;
  }

  public static compare(a: RiskModel | FnolRiskModel, b: RiskModel | FnolRiskModel): number {
    if (a == null || b == null) {
      return -1;
    }

    if (
      a.generalizedProductType === GenericProductType.Auto &&
      b.generalizedProductType === GenericProductType.Auto
    ) {
      const typeA = RiskCompare.sortKey.indexOf(a.vehicleTypeName);
      const typeB = RiskCompare.sortKey.indexOf(b.vehicleTypeName);
      if (typeA > typeB) {
        return 1;
      }
      if (typeA < typeB) {
        return -1;
      }
      return 0;
    }

    const aProductType = RiskCompare.getGenericProductTypeOrder(a.generalizedProductType);
    const bProductType = RiskCompare.getGenericProductTypeOrder(b.generalizedProductType);

    if (aProductType < bProductType) {
      return -1;
    }
    if (aProductType > bProductType) {
      return 1;
    }

    if (a.periodStartDate < b.periodStartDate) {
      return -1;
    }
    if (a.periodStartDate > b.periodStartDate) {
      return 1;
    }
    return 0;
  }
}

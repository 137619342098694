import { Injectable } from '@angular/core';
import {
  InitiateDynamicQuestionApiResponseModel,
  Answer,
  ReviewSheet,
  Question
} from '../models/initiate-dynamic-question-api-response.model';
import { get as _get, flatMap as _flatmap, upperFirst as _upperFirst } from 'lodash';
import { ClaimsNavigationPaths, Option, ClaimContact, VehicleDetails } from '@amfam/shared/models';
import {
  InitiateDynamicQuestionAdapterModel,
  FnolSummary,
  IncidentSummary,
  ThirdPartySummary
} from '../models/initiate-dynamic-question-adapter.model';
import { FnolQuestionTypes } from '../models/question-types';
import {
  ApplicationService,
  Applications,
  CopyService
} from '@amfam/shared/utility/shared-services';
import { ErrorLevels, ErrorObj } from '@amfam/ui-kit';
import { ClaimAndRiskSummaryModel } from '../models/summary.model';
import { DateFormatPipe } from '@amfam/shared/ui/pipes';
import { ClaimUtilService } from '@amfam/claim/shared/utility';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class FnolAdapter {
  constructor(
    private dateFormatPipe: DateFormatPipe = new DateFormatPipe(),
    private claimUtilSerive: ClaimUtilService,
    private copyService: CopyService,
    private appService: ApplicationService
  ) {}

  adapt(payload: InitiateDynamicQuestionApiResponseModel): InitiateDynamicQuestionAdapterModel {
    const phone =
      _get(payload, 'response.question.answers[0].answerData.areaCode', '') +
      _get(payload, 'response.question.answers[0].answerData.phoneNumber', '');

    const email = _get(payload, 'response.question.answers[0].answerData.email', '');
    const contacts = _get(payload, 'response.draftClaimInfo.claimContacts');

    const response: InitiateDynamicQuestionAdapterModel = {
      question: _get(payload, 'response.question.displayText', ''),
      questionId: _get(payload, 'response.question.id', ''),
      questionCategory: _get(payload, 'response.question.dialogTreeId', ''),
      options: this.buildAnswers(_get(payload, 'response.question.answers', [])),
      questionType: _get(payload, 'response.question.questionType', ''),
      instructionText: _get(payload, 'response.question.instructionText', ''),
      path: this.deriveRoutingPath(_get(payload, 'response.question.questionType', '')),
      selectedAnswer: this.determineSelectedAnswer(_get(payload, 'response.question')),
      summary: this.buildFnolSummary(payload),
      enableBackButton: _get(payload, 'response.question.enableBackButton', true),
      maxLength: _get(payload, 'response.question.answers[0].maximumLength', 500),
      vehicleDetails: _get(payload, 'response.draftClaimInfo.vehicleDetails')
    };

    // Only override claimContacts if we have something returned
    if (!!contacts) {
      response.claimContacts = contacts;
    }

    // If we have email/phone to override what's on policy, add it
    if (!!email) {
      response.emailAddress = email;
    } else {
      response.emailAddress = _get(
        payload,
        'response.draftClaimInfo.claimContacts[0].contact.newPrimaryPhoneEmail.emailAddress',
        ''
      );
    }
    if (!!phone) {
      response.phoneNumber = phone;
    } else {
      response.phoneNumber =
        _get(
          payload,
          'response.draftClaimInfo.claimContacts[0].contact.newPrimaryPhoneEmail.area',
          ''
        ) +
        _get(
          payload,
          'response.draftClaimInfo.claimContacts[0].contact.newPrimaryPhoneEmail.number',
          ''
        );
    }

    // If question is vehicle damage picker, grab answer data to prefill
    if (response.questionType === FnolQuestionTypes.VEHICLE_DAMAGE_PICKER) {
      response.vehicleDamage = _get(payload, 'response.question.answers[0].answerData');
    }

    if (response.questionType === FnolQuestionTypes.THIRD_PARTY_CONTACT_FORM) {
      response.thirdPartyContacts = _get(
        payload,
        'response.question.answers[0].answerData.thirdPartyContactForm'
      );
    }

    // keep claimant contact info in store to display on review screen in place of insured's info
    if (
      _get(payload, 'response.question.reviewSheet', []).some(
        question => question.questionType === FnolQuestionTypes.CLAIMANT_CONTACT_TYPE
      )
    ) {
      const claimantContactAnswer = _get(payload, 'response.question.reviewSheet', []).find(
        question => question.questionType === FnolQuestionTypes.CLAIMANT_CONTACT_TYPE
      );
      response.claimantContact = Object.assign(
        {},
        _get(claimantContactAnswer, 'answerData.claimantContactForm'),
        { questionId: _get(claimantContactAnswer, 'questionId') }
      );
    }
    return response;
  }

  private buildAnswers(answers: Answer[]): Option[] {
    const options: Option[] = [];
    answers.forEach((answer: Answer) => {
      options.push({
        id: answer.answerId,
        text: answer.displayText
      });
    });
    return this.sortOptionsById(options);
  }

  private sortOptionsById(options: Option[]): Option[] {
    return options.sort((a, b) => {
      return (<string>a.id).localeCompare(<string>b.id);
    });
  }

  private determineSelectedAnswer(question: Question): string {
    switch (_get(question, 'questionType')) {
      case FnolQuestionTypes.SINGLE_SELECT:
      case FnolQuestionTypes.MULTI_SELECT:
        return this.selectAnswer(_get(question, 'answers', []));
      case FnolQuestionTypes.FREE_FORM:
        return _get(question, 'answers[0].response', '');
      default:
        return '';
    }
  }

  // Creates string of selected answer(s) for single/multi select
  private selectAnswer(answers: Answer[]) {
    let selectedAnswers = '';
    answers.forEach((answer: Answer) => {
      if (answer.selected) {
        // If we already have a selection, append answer to string for multi select
        if (selectedAnswers !== '') {
          selectedAnswers += ',' + answer.answerId;
        } else {
          // Otherwise just set answer for single select/first multi select answer
          selectedAnswers = answer.answerId;
        }
      }
    });
    return selectedAnswers;
  }

  private deriveRoutingPath(questionType: string): string {
    switch (questionType) {
      case FnolQuestionTypes.THIRD_PARTY_CONTACT_FORM:
        return ClaimsNavigationPaths.CLAIM_CREATE_THIRD_PARTY;
      case FnolQuestionTypes.CLAIMANT_CONTACT_TYPE:
        return ClaimsNavigationPaths.CLAIM_CREATE_CLAIMANT_INFO;
      case FnolQuestionTypes.INSURED_CONTACT_TYPE:
        return ClaimsNavigationPaths.CLAIM_CREATE_CONTACT_INFO;
      case FnolQuestionTypes.VEHICLE_DAMAGE_PICKER:
        return ClaimsNavigationPaths.CLAIM_CREATE_VEHICLE_DAMAGE_MAP;
      case FnolQuestionTypes.SINGLE_SELECT:
      case FnolQuestionTypes.MULTI_SELECT:
      case FnolQuestionTypes.FREE_FORM:
        return ClaimsNavigationPaths.CLAIM_CREATE_DYNAMIC_QUESTION;
      case FnolQuestionTypes.END:
        return ClaimsNavigationPaths.CLAIM_CREATE_REVIEW;
      default:
        return '';
    }
  }

  private buildFnolSummary(payload: InitiateDynamicQuestionApiResponseModel): FnolSummary {
    const reviewSheet: ReviewSheet[] = _get(payload, 'response.question.reviewSheet');
    let incidentSummary: IncidentSummary[] = [];

    if (_get(reviewSheet, 'length', 0) > 0) {
      incidentSummary = _flatmap(
        reviewSheet.filter(
          review =>
            review.questionType !== FnolQuestionTypes.INSURED_CONTACT_TYPE &&
            review.questionType !== FnolQuestionTypes.THIRD_PARTY_CONTACT_FORM &&
            (review.reviewAnswerDisplay !== 'N/A' ||
              review.questionType === FnolQuestionTypes.VEHICLE_DAMAGE_PICKER)
        ),
        review => {
          if (review.questionType === FnolQuestionTypes.VEHICLE_DAMAGE_PICKER) {
            return [
              {
                questionId: review.questionId,
                answerId: review.answerId,
                questionType: review.questionType,
                nextQuestion: review.nextQuestion,
                reviewQuestionDisplay: review.reviewQuestionDisplay,
                reviewAnswerDisplay: this.getVehicleDamageAnswer(
                  _get(review, 'answerData.vehicleDamages[0].vehicleDamage', '')
                )
              }
            ];
          } else if (review.questionType === FnolQuestionTypes.MULTI_SELECT) {
            const multiSelectArray = [];
            const multiSelectAnswers = review.answerId.split(',');
            const multiSelectDisplay = review.reviewAnswerDisplay.split(',');
            multiSelectAnswers.forEach((answer, i) => {
              multiSelectArray.push({
                questionId: review.questionId,
                answerId: answer,
                questionType: review.questionType,
                nextQuestion: review.nextQuestion,
                reviewQuestionDisplay: review.reviewQuestionDisplay,
                reviewAnswerDisplay: multiSelectDisplay[i]
              });
            });
            return multiSelectArray;
          } else {
            return [
              {
                questionId: review.questionId,
                answerId: review.answerId,
                questionType: review.questionType,
                nextQuestion: review.nextQuestion,
                reviewQuestionDisplay: review.reviewQuestionDisplay,
                reviewAnswerDisplay: review.reviewAnswerDisplay
              }
            ];
          }
        }
      );
    }

    return {
      incidentSummary: incidentSummary,
      claimAndRiskInfo: this.buildClaimAndRiskInfo(payload),
      thirdPartyContactSummary: this.buildThirdPartyContacts(payload)
    };
  }

  // Dynamic FNOL has one custom error message to display if the draft claim has already become a full claim
  buildErrorMessage(apiError): Array<ErrorObj> {
    let errorMessage = this.copyService.get('claims', 'defaultErrorMessage');
    // A 400008 code corresponds to claim already submitted
    if (_get(apiError, 'status.errorCode', '').includes('400008')) {
      errorMessage =
        this.copyService.get('claims', 'alreadySubmittedErrorMessage1') +
        _get(apiError, 'response.claimNumber', '') +
        this.copyService.get('claims', 'alreadySubmittedErrorMessage2');
    }

    return [
      {
        errorMessage: errorMessage,
        errorLevel: ErrorLevels.HIGH
      }
    ];
  }
  private buildClaimAndRiskInfo(
    payload: InitiateDynamicQuestionApiResponseModel
  ): ClaimAndRiskSummaryModel {
    const lossDate = _get(payload, 'response.draftClaimInfo.lossDate');

    return {
      lossDate:
        format(lossDate, 'MM/DD/YYYY') + ' at ' + this.claimUtilSerive.convertTime24To12(lossDate),
      lossLocation:
        _upperFirst(_get(payload, 'response.draftClaimInfo.lossLocationAddress[0].city')) +
        ', ' +
        _get(payload, 'response.draftClaimInfo.lossLocationAddress[0].state'),
      phoneNumber: this.getPhoneNumber(_get(payload, 'response.question.reviewSheet')),
      emailAddress: this.getEmailAddress(_get(payload, 'response.question.reviewSheet')),
      questionId: this.getInsuredQuestionId(_get(payload, 'response.question.reviewSheet'))
    };
  }

  private buildThirdPartyContacts(response: InitiateDynamicQuestionApiResponseModel) {
    const ccPayload = _get(response, 'response.question.reviewSheet', []).find(
      question => _get(question, 'questionType') === FnolQuestionTypes.THIRD_PARTY_CONTACT_FORM
    );
    const thirdPartyContacts: ThirdPartySummary[] = [];

    const claimContacts = _get(ccPayload, 'answerData.thirdPartyContactForm', []).map(
      contact => contact.claimContact
    );

    const vehicleDetails = _get(ccPayload, 'answerData.thirdPartyContactForm', []).map(
      contact => contact.vehicleDetail
    );

    claimContacts.map(claimContact => {
      const matchedVehicleDetail = vehicleDetails.find(
        vehicleDetail =>
          _get(vehicleDetail, 'vehLossDriverContact.contactId') ===
          _get(claimContact, 'contact.contactId')
      );
      thirdPartyContacts.push({
        claimContact: claimContact,
        vehicleDetail: matchedVehicleDetail,
        questionId: _get(ccPayload, 'questionId', '')
      });
    });

    return thirdPartyContacts;
  }

  private getInsuredContactInfo(reviewSheet: ReviewSheet[]): ReviewSheet {
    return (
      _get(reviewSheet, 'length') > 0 &&
      reviewSheet.find(
        review => _get(review, 'questionType') === FnolQuestionTypes.INSURED_CONTACT_TYPE
      )
    );
  }

  private getClaimantContactInfo(reviewSheet: ReviewSheet[]): ReviewSheet {
    return (
      _get(reviewSheet, 'length') > 0 &&
      reviewSheet.find(
        review => _get(review, 'questionType') === FnolQuestionTypes.CLAIMANT_CONTACT_TYPE
      )
    );
  }

  private getPhoneNumber(reviewSheet: ReviewSheet[]): string {
    if (this.appService.isApp(Applications.THIRD_PARTY_FNOL)) {
      const claimantContact: ReviewSheet = this.getClaimantContactInfo(reviewSheet);
      return (
        _get(
          claimantContact,
          'answerData.claimantContactForm.claimContact.contact.newPrimaryPhoneEmail.area'
        ) +
        _get(
          claimantContact,
          'answerData.claimantContactForm.claimContact.contact.newPrimaryPhoneEmail.number'
        )
      );
    } else {
      const insuredContact: ReviewSheet = this.getInsuredContactInfo(reviewSheet);
      return (
        _get(insuredContact, 'answerData.areaCode') + _get(insuredContact, 'answerData.phoneNumber')
      );
    }
  }

  private getEmailAddress(reviewSheet: ReviewSheet[]): string {
    if (this.appService.isApp(Applications.THIRD_PARTY_FNOL)) {
      const claimantContact: ReviewSheet = this.getClaimantContactInfo(reviewSheet);
      return _get(
        claimantContact,
        'answerData.claimantContactForm.claimContact.contact.newPrimaryPhoneEmail.emailAddress'
      );
    } else {
      const insuredContact: ReviewSheet = this.getInsuredContactInfo(reviewSheet);
      return _get(insuredContact, 'answerData.email');
    }
  }

  private getInsuredQuestionId(reviewSheet: ReviewSheet[]): string {
    const insuredContact: ReviewSheet = this.getInsuredContactInfo(reviewSheet);
    return _get(insuredContact, 'questionId');
  }

  private getVehicleDamageAnswer(answer: string): string {
    switch (answer.toUpperCase()) {
      case 'ND':
        return 'No Damage';
      case 'VA':
        return 'Damage All Over';
      default:
        return 'Some Damage';
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthCheckDirective } from './directives/auth-check.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AuthCheckDirective],
  exports: [AuthCheckDirective]
})
export class ImpersonationModule {}

import { createAction, props } from '@ngrx/store';
import {
  ApplyPaperlessDiscountPayload,
  ApplyPaperlessDiscountResponse,
  BillAccountDeliveryPreference,
  SetBillAccountDeliveryPreferencePayload,
  SetPolicyDeliveryPreferencePayload,
  SetPolicyDeliveryPreferenceResponse
} from '../models/communication-preferences.models';

// Set Bill Account Delivery Preference
export const setBillAccountDeliveryPreference = createAction(
  '[CommunicationPreferences] Set Bill Account Delivery Preference',
  props<{
    billAccountNumber: string;
    payload: SetBillAccountDeliveryPreferencePayload;
    correlationId: string;
  }>()
);

export const setBillAccountDeliveryPreferenceSuccess = createAction(
  '[CommunicationPreferences] Set Bill Account Delivery Preference Success',
  props<{
    billAccountNumber: string;
    billingPreferences: BillAccountDeliveryPreference;
    correlationId: string;
  }>()
);

export const setBillAccountDeliveryPreferenceFailure = createAction(
  '[CommunicationPreferences] Set Bill Account Delivery Preference Failure',
  props<{ billAccountNumber: string; error: any; correlationId: string }>()
);

// Set Policy Delivery Preference
export const setPolicyDeliveryPreference = createAction(
  '[CommunicationPreferences] Set Policy Delivery Preference',
  props<{
    customerId: string;
    payload: SetPolicyDeliveryPreferencePayload;
    correlationId: string;
  }>()
);

export const setPolicyDeliveryPreferenceSuccess = createAction(
  '[CommunicationPreferences] Set Policy Delivery Preference Success',
  props<{ response: SetPolicyDeliveryPreferenceResponse; correlationId: string }>()
);

export const setPolicyDeliveryPreferenceFailure = createAction(
  '[CommunicationPreferences] Set Policy Delivery Preference Failure',
  props<{ error: any; correlationId: string }>()
);

// Apply Paperless Discount
export const applyPaperlessDiscount = createAction(
  '[CommunicationPreferences] Apply Paperless Discount',
  props<{
    payload: ApplyPaperlessDiscountPayload;
    correlationId: string;
  }>()
);

export const applyPaperlessDiscountSuccess = createAction(
  '[CommunicationPreferences] Apply Paperless Discount Success',
  props<{ response: ApplyPaperlessDiscountResponse; correlationId: string }>()
);

export const applyPaperlessDiscountFailure = createAction(
  '[CommunicationPreferences] Apply Paperless Discount Failure',
  props<{ error: any; correlationId: string }>()
);

import { ApiStatus } from '@amfam/shared/models';

export class FeedbackRequest {
  feedback: FeedBack;
  offerKeys: string[];
}

export interface FeedBack {
  recommendationId: string;
  feedbackActionCode: string;
  feedbackReason: string;
  servicingProducerId: number;
  feedbackDate: string;
  createdBy: string;
  createdBySystemName: string;
}

export class OpportunitiesFeedback {
  feedbackActionCode: string;
  producerId?: string;
  agentId?: string;
  recommendationId: string;
  isMultiAgent: boolean;
  type?: string;
}

export class OpportunitiesFeedbackFailure {
  status: ApiStatus;
  correlationId?: string;
  customerFeedbackCode: string;
  recommendationId: string;
  error?: any;
  type?: string;
}

export class OpportunitiesFeedbackSuccess {
  feedbacks: FeedBack[];
  status: ApiStatus;
  customerFeedbackCode: string;
  recommendationId: string;
  type?: string;
}

export class CustomerFeedbackEnum {
  public static CustomerLearnMore = 'CUSTOMER_LEARNED_MORE';
  public static CustomerDismissed = 'CUSTOMER_DISMISSED';
  public static CustomerRequestedQuote = 'CUSTOMER_REQUESTED_QUOTE';
  public static CustomerAccepted = 'ACCEPTED';
  public static CustomerDeclined = 'DECLINED';
  public static AgentQuoteInitiated = 'QUOTE_INITIATED';
}
export class OpportunityButtonAction {
  public static contactMe = 'contact me';
}

import { get as _get } from 'lodash';
import { Injectable } from '@angular/core';
import { SubmitDraftClaimResponse, DraftClaim, FnolConfirmation } from '../models';

@Injectable()
export class SubmitDraftClaimAdapter {
  constructor() {}

  adapt(draftClaim: DraftClaim, draftClaimResponse: SubmitDraftClaimResponse): FnolConfirmation {
    return {
      draftClaim: {
        incidentDate: draftClaim.fnol.lossDate
      },
      submittedClaim: {
        vin: _get(draftClaim, 'fnol.autoClaimDetail.vehicles[0].vin', ''),
        claimNumber: draftClaimResponse.claimNumber,
        status: draftClaimResponse.status,
        eligibleServices: draftClaimResponse.eligibleServices
      }
    };
  }
}

import {
  GetMakesPayload,
  GetModelsPayload,
  GetStylesPayload,
  GetVehicleMakesApiResponse,
  GetVehicleModelsApiResponse,
  GetVehicleSeriesApiResponse,
  GetVehicleVinYearApiResponse,
  VechiclePolicyChangeEntryPoint,
  VehicleSelectCriteria
} from '../models/vehicle.models';

import { Action } from '@ngrx/store';
import { TPIIndicator } from '@amfam/policy/vehicle/data-access';

export enum VehicleActionTypes {
  Reset = '[Vehicle] Reset',
  LookupVinYear = '[Vehicle] Lookup Vehicle By Vin and Year',
  LookupVinYearSuccess = '[Vehicle] Lookup Vehicle By Vin and Year Success',
  LookupVinYearFail = '[Vehicle] Lookup Vehicle By Vin and Year Failure',
  GetMakes = '[Vehicle] Get Vehicle Makes',
  GetMakesSuccess = '[Vehicle] Get Vehicle Makes Success',
  GetMakesFail = '[Vehicle] Get Vehicle Makes Failure',
  GetModels = '[Vehicle] Get Vehicle Models',
  GetModelsSuccess = '[Vehicle] Get Vehicle Models Success',
  GetModelsFail = '[Vehicle] Get Vehicle Models Failure',
  GetStyles = '[Vehicle] Get Vehicle Styles',
  GetStylesSuccess = '[Vehicle] Get Vehicle Styles Success',
  GetStylesFail = '[Vehicle] Get Vehicle Styles Failure',
  SetCriteria = '[Vehicle] Set Vehicle Lookup Criterion',
  SetTpi = '[Vehicle] Set Tpi',
  SetVehiclePolicyChangeEntryPoint = '[Vehicle] Set vehicle policy change entry point'
}

export class Reset implements Action {
  readonly type = VehicleActionTypes.Reset;
  constructor() {}
}

export class LookupVinYear implements Action {
  readonly type = VehicleActionTypes.LookupVinYear;
  constructor(public payload: VehicleSelectCriteria) {}
}

// TODO - determine response payload dimensions and create model
export class LookupVinYearSuccess implements Action {
  readonly type = VehicleActionTypes.LookupVinYearSuccess;
  constructor(public payload: GetVehicleVinYearApiResponse) {} // has success status
}

// TODO - determine response payload dimensions and create model
export class LookupVinYearFail implements Action {
  readonly type = VehicleActionTypes.LookupVinYearFail;
  constructor(public payload: any) {} // has error status. This might be a string or an ApiStatus object. Any is the best type we can get
}

export class GetMakes implements Action {
  readonly type = VehicleActionTypes.GetMakes;
  constructor(public payload: GetMakesPayload) {}
}

export class GetMakesSuccess implements Action {
  readonly type = VehicleActionTypes.GetMakesSuccess;
  constructor(public payload: GetVehicleMakesApiResponse) {} // has makes array
}

export class GetMakesFail implements Action {
  readonly type = VehicleActionTypes.GetMakesFail;
  constructor(public payload: GetVehicleMakesApiResponse) {} // has error status, empty makes array
}

export class GetModels implements Action {
  readonly type = VehicleActionTypes.GetModels;
  constructor(public payload: GetModelsPayload) {}
}

export class GetModelsSuccess implements Action {
  readonly type = VehicleActionTypes.GetModelsSuccess;
  constructor(public payload: GetVehicleModelsApiResponse) {} // has models array
}

export class GetModelsFail implements Action {
  readonly type = VehicleActionTypes.GetModelsFail;
  constructor(public payload: GetVehicleModelsApiResponse) {} // has error status & empty models array
}

export class GetStyles implements Action {
  readonly type = VehicleActionTypes.GetStyles;
  constructor(public payload: GetStylesPayload) {}
}

export class GetStylesSuccess implements Action {
  readonly type = VehicleActionTypes.GetStylesSuccess;
  constructor(public payload: GetVehicleSeriesApiResponse) {} // has styles array, may be empty
}

export class GetStylesFail implements Action {
  readonly type = VehicleActionTypes.GetStylesFail;
  constructor(public payload: GetVehicleSeriesApiResponse) {} // has error status & empty styles array
}

export class SetCriteria implements Action {
  readonly type = VehicleActionTypes.SetCriteria;
  constructor(public payload: VehicleSelectCriteria) {}
}

export class SetTpi implements Action {
  readonly type = VehicleActionTypes.SetTpi;
  constructor(public payload: { tpiName: string; copyTpiIndicator: TPIIndicator }) {}
}

export class SetVehiclePolicyChangeEntryPoint implements Action {
  readonly type = VehicleActionTypes.SetVehiclePolicyChangeEntryPoint;
  constructor(public payload: { entryPoint: VechiclePolicyChangeEntryPoint }) {}
}

export type VehicleAction =
  | Reset
  | LookupVinYear
  | LookupVinYearSuccess
  | LookupVinYearFail
  | GetMakes
  | GetMakesSuccess
  | GetMakesFail
  | GetModels
  | GetModelsSuccess
  | GetModelsFail
  | GetStyles
  | GetStylesSuccess
  | GetStylesFail
  | SetCriteria
  | SetTpi
  | SetVehiclePolicyChangeEntryPoint;

export const fromVehicleActions = {
  Reset,
  LookupVinYear,
  LookupVinYearSuccess,
  LookupVinYearFail,
  GetMakes,
  GetMakesSuccess,
  GetMakesFail,
  GetModels,
  GetModelsSuccess,
  GetModelsFail,
  GetStyles,
  GetStylesSuccess,
  GetStylesFail,
  SetCriteria,
  SetTpi,
  SetVehiclePolicyChangeEntryPoint
};

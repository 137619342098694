import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CurrencyFilterPipe } from './pipes/currency-filter.pipe';
import { DocumentPipe } from './pipes/document.pipe';
import {
  AdjusterPhoneNumberFormatterPipe,
  ArrayToList,
  CamelCase,
  CapitalizePipe,
  CapitalToPascal,
  CoverageCurrencyUSDPipe,
  CurrencyUSDPipe,
  DateFormatOutPipe,
  DateFormatPipe,
  DecimalDigits,
  KydOperatorOptionStatusDisplayPipe,
  KydOperatorOptionStatusValuePipe,
  KydStatusPipe,
  OrderBy,
  PhoneNumberFormatterPipe,
  PhoneNumberSingleStringFormatterPipe,
  PhoneNumberTelFormatterPipe,
  PrettyBillingAcctNum,
  PrettyBillingMethod,
  PrettyClaimNum,
  PrettyPaymentMethod,
  PrettyPhoneNumberPipe,
  Redact,
  RemoveSpecialChars,
  ReturnFirst,
  ReturnLast,
  SplitAndGetPipe,
  StateAbbreviationPipe,
  StrToFloat,
  Time24to12Pipe,
  TitleCaseDashNoSpacesPipe,
  TitleCaseDashPipe,
  TitleCasePipe,
  ToArrayPipe
} from './pipes/format.pipe';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [
    CurrencyUSDPipe,
    CoverageCurrencyUSDPipe,
    DateFormatPipe,
    DateFormatOutPipe,
    OrderBy,
    PhoneNumberSingleStringFormatterPipe,
    PhoneNumberFormatterPipe,
    PhoneNumberTelFormatterPipe,
    PrettyBillingMethod,
    AdjusterPhoneNumberFormatterPipe,
    ArrayToList,
    CapitalizePipe,
    CapitalToPascal,
    PrettyPhoneNumberPipe,
    Redact,
    RemoveSpecialChars,
    ReturnFirst,
    ReturnLast,
    TitleCasePipe,
    TitleCaseDashPipe,
    TitleCaseDashNoSpacesPipe,
    SplitAndGetPipe,
    StrToFloat,
    ToArrayPipe,
    Time24to12Pipe,
    KydStatusPipe,
    KydOperatorOptionStatusValuePipe,
    KydOperatorOptionStatusDisplayPipe,
    PrettyBillingAcctNum,
    PrettyPaymentMethod,
    DocumentPipe,
    PrettyClaimNum,
    CamelCase,
    DecimalDigits,
    CurrencyFilterPipe,
    StateAbbreviationPipe
  ],
  exports: [
    AdjusterPhoneNumberFormatterPipe,
    ArrayToList,
    CapitalizePipe,
    CapitalToPascal,
    CurrencyUSDPipe,
    DateFormatOutPipe,
    OrderBy,
    PhoneNumberFormatterPipe,
    PhoneNumberSingleStringFormatterPipe,
    PhoneNumberTelFormatterPipe,
    PrettyBillingMethod,
    PrettyPhoneNumberPipe,
    Redact,
    RemoveSpecialChars,
    ReturnFirst,
    ReturnLast,
    SplitAndGetPipe,
    StrToFloat,
    TitleCasePipe,
    TitleCaseDashPipe,
    TitleCaseDashNoSpacesPipe,
    ToArrayPipe,
    CoverageCurrencyUSDPipe,
    CapitalToPascal,
    DateFormatPipe,
    Time24to12Pipe,
    KydStatusPipe,
    KydOperatorOptionStatusValuePipe,
    KydOperatorOptionStatusDisplayPipe,
    PrettyBillingAcctNum,
    PrettyPaymentMethod,
    DocumentPipe,
    PrettyClaimNum,
    CamelCase,
    DecimalDigits,
    CurrencyFilterPipe,
    StateAbbreviationPipe
  ],
  providers: [DateFormatPipe, CurrencyUSDPipe, CapitalToPascal, CurrencyUSDPipe]
})
export class PipesModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromBillAccountCanRegister from './+state/bill-account-can-register.reducer';
import { BillAccountCanRegisterEffects } from './+state/bill-account-can-register.effects';
import { BILL_ACCOUNT_CAN_REGISTER_FEATURE_KEY } from './+state/bill-account-can-register.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      BILL_ACCOUNT_CAN_REGISTER_FEATURE_KEY,
      fromBillAccountCanRegister.billAccountCanRegisterReducer
    ),
    EffectsModule.forFeature([BillAccountCanRegisterEffects])
  ]
})
export class BillingBillAccountCanRegisterDataAccessModule {}

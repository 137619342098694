<div class="row" [formGroup]="form" *ngIf="form">
  <div class="column twelve">
    <ds-form-control-container [labelText]="'User ID'" [control]="form.get('userId')">
      <input
        type="text"
        id="userId"
        data-cy="userIdTextInput"
        formControlName="userId"
        class="fill"
        autocomplete="off"
      />
      <div class="row pad-top-10">
        <div class="column twelve md-six">
          <ul class="criteria-list">
            <li
              [ngClass]="{
                isValid:
                  !form.get('userId').hasError('notAlphanumeric') && (form.get('userId').dirty || form.get('userId').untouched),
                hasError: form.get('userId').hasError('notAlphanumeric') && (form.get('userId').dirty || form.get('userId').untouched)
              }"
              class="pseudo-icon"
              data-cy="userIdAlphanumericValidator"
              content
              [contentPath]="'enrollment.signUpUserIdSuggText'"
            ></li>
            <li
              [ngClass]="{
                isValid: !form.get('userId').hasError('invalidLength') && (form.get('userId').dirty || form.get('userId').untouched),
                hasError: form.get('userId').hasError('invalidLength') && (form.get('userId').dirty || form.get('userId').untouched)
              }"
              class="pseudo-icon"
              data-cy="userIDInputLengthValidator"
              content
              [contentPath]="'enrollment.signUpUserIdLengthText'"
            ></li>
          </ul>
        </div>
        <div class="column twelve md-six">
          <ul class="criteria-list">
            <li
              data-cy="availability"
              [ngClass]="{
                isValid: userIdAvailable,
                notAvailable: userIdUnavailable
              }"
              class="pseudo-icon"
              [innerHTML]="message"
            ></li>
          </ul>
        </div>
      </div>
    </ds-form-control-container>
  </div>
</div>

<ds-header-wrapper></ds-header-wrapper>
<div toastContainer></div>
<div
  [style.height]="(mobileMenuOpen$ | async) && (mobileMenuActive$ | async) ? '100%' : '0'"
  class="overlay"
></div>
<div id="body-wrapper" class="flex row" [class.noDock]="noDock$ | async">
  <div class="column twelve">
    <router-outlet></router-outlet>
  </div>
</div>

import { ApiStatus } from '@amfam/shared/models';

export interface ExposureIdResponse {
  status: ApiStatus;
  vehicleExposures?: Array<ExposureData>;
}

export class ExposureData {
  claimNumber: string;
  exposureId: string;
}

export class ExposureIdReqData {
  claimNumber: string;
  vin: string;
}

import { Action } from '@ngrx/store';
// eslint-disable-next-line
import {
  PaymentAccount,
  AddEditPaymentAccountDTO,
  AddEditPaymentAccountSuccessModel,
  AddEditPaymentAccountFailureModel,
  UpdateModeOfAuthModel,
  UpdateModeOfAuthSuccessModel,
  UpdateModeOfAuthFailureModel,
  DeletePaymentAccountRequestModel,
  DeletePaymentAccountFailureModel,
  DeletePaymentAccountSuccessModel
} from '@amfam/shared/models';
import { PaymentRecordRequest } from '../models/payment-capture.model';

export enum PaymentAccountActionTypes {
  PaymentAccountsLoad = '[PaymentAccounts] Load',
  PaymentAccountsLoadSuccess = '[PaymentAccounts] Load Success',
  PaymentAccountsLoadFail = '[PaymentAccounts] Load Fail',
  PaymentAccountsLoadComplete = '[PaymentAccounts] Load Complete',

  PaymentAccountsLoadDetail = '[PaymentAccounts] Load Detail',
  PaymentAccountsLoadDetailSuccess = '[PaymentAccounts] Load Detail Success',
  PaymentAccountsLoadDetailFail = '[PaymentAccounts] Load Detail Fail',

  PaymentAccountsRefresh = '[PaymentAccounts] Refresh',

  PaymentAccountsSave = '[PaymentAccounts] Save',
  PaymentAccountsSaveSuccess = '[PaymentAccounts] Save Success',
  PaymentAccountsSaveFail = '[PaymentAccounts] Save Fail',

  PaymentAccountsAdd = '[PaymentAccounts] Add',
  PaymentAccountsAddSuccess = '[PaymentAccounts] Add Success',
  PaymentAccountsAddFail = '[PaymentAccounts] Add Fail',

  PaymentAccountsEdit = '[PaymentAccounts] Edit',
  PaymentAccountsEditSuccess = '[PaymentAccounts] Edit Success',
  PaymentAccountsEditFail = '[PaymentAccounts] Edit Fail',

  PaymentAccountsUpdateModeAuth = '[PaymentAccounts] Update Mode of Auth',
  PaymentAccountsUpdateModeAuthSuccess = '[PaymentAccounts] Update Mode of Auth Success',
  PaymentAccountsUpdateModeAuthFail = '[PaymentAccounts] Update Mode of Auth Fail',

  PaymentAccountsDelete = '[PaymentAccounts] Delete',
  PaymentAccountsDeleteSuccess = '[PaymentAccounts] Delete Success',
  PaymentAccountsDeleteFail = '[PaymentAccounts] Delete Fail',

  PaymentAccountScreenRecordingUpdate = '[PaymentAccounts] Screen Recording Update',

  PaymentAccountsCleanup = '[PaymentAccounts] Cleanup',
  PaymentAccountsTruncate = '[PaymentAccounts] Truncate'
}

export class PaymentAccountsLoad implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsLoad;
  constructor(public payload?: any) {}
}

export class PaymentAccountsLoadSuccess implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsLoadSuccess;
  constructor(public payload: PaymentAccount) {}
}

export class PaymentAccountsLoadFail implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsLoadFail;
  constructor(public payload?: any) {}
}

export class PaymentAccountsLoadComplete implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsLoadComplete;
  constructor(public payload?: any) {}
}

export class PaymentAccountsLoadDetail implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsLoadDetail;
  constructor(public payload: any) {}
}

export class PaymentAccountsLoadDetailSuccess implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsLoadDetailSuccess;
  constructor(public payload?: any) {}
}

export class PaymentAccountsLoadDetailFail implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsLoadDetailFail;
  constructor(public payload?: any) {}
}

export class PaymentAccountsRefresh implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsRefresh;
  constructor(public payload?: any) {}
}

export class PaymentAccountsSave implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsSave;
  constructor(public payload: AddEditPaymentAccountDTO) {}
}

export class PaymentAccountsSaveSuccess implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsSaveSuccess;
  constructor(public payload: PaymentAccount) {}
}

export class PaymentAccountsSaveFail implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsSaveFail;
  constructor(public payload?: AddEditPaymentAccountFailureModel) {}
}

export class PaymentAccountsAdd implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsAdd;
  constructor(public payload: AddEditPaymentAccountDTO) {}
}

export class PaymentAccountsAddSuccess implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsAddSuccess;
  constructor(public payload: AddEditPaymentAccountSuccessModel) {}
}

export class PaymentAccountsAddFail implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsAddFail;
  constructor(public payload: AddEditPaymentAccountFailureModel) {}
}

export class PaymentAccountsEdit implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsEdit;
  constructor(public payload: AddEditPaymentAccountDTO) {}
}

export class PaymentAccountsEditSuccess implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsEditSuccess;
  constructor(public payload: AddEditPaymentAccountSuccessModel) {}
}

export class PaymentAccountsEditFail implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsEditFail;
  constructor(public payload: AddEditPaymentAccountFailureModel) {}
}

export class PaymentAccountsUpdateModeAuth implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsUpdateModeAuth;
  constructor(public payload: UpdateModeOfAuthModel) {}
}

export class PaymentAccountsUpdateModeAuthSuccess implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthSuccess;
  constructor(public payload: UpdateModeOfAuthSuccessModel) {}
}

export class PaymentAccountsUpdateModeAuthFail implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthFail;
  constructor(public payload: UpdateModeOfAuthFailureModel) {}
}

export class PaymentAccountsDelete implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsDelete;
  constructor(public payload: DeletePaymentAccountRequestModel) {}
}

export class PaymentAccountsDeleteSuccess implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsDeleteSuccess;
  constructor(public payload: DeletePaymentAccountSuccessModel) {}
}

export class PaymentAccountsDeleteFail implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsDeleteFail;
  constructor(public payload: DeletePaymentAccountFailureModel) {}
}

export class PaymentAccountScreenRecordingUpdate implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountScreenRecordingUpdate;
  constructor(public payload: PaymentRecordRequest) {}
}

export class PaymentAccountsCleanup implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsCleanup;
  constructor(public payload: string) {} // paymentAccountNickName
}

export class PaymentAccountsTruncate implements Action {
  readonly type = PaymentAccountActionTypes.PaymentAccountsTruncate;
  constructor(public payload: string) {} // paymentAccountNickName
}

export type PaymentAccountActions =
  | PaymentAccountsLoad
  | PaymentAccountsLoadSuccess
  | PaymentAccountsLoadFail
  | PaymentAccountsLoadComplete
  | PaymentAccountsLoadDetail
  | PaymentAccountsLoadDetailSuccess
  | PaymentAccountsLoadDetailFail
  | PaymentAccountsRefresh
  | PaymentAccountsSave
  | PaymentAccountsSaveFail
  | PaymentAccountsSaveSuccess
  | PaymentAccountsAdd
  | PaymentAccountsAddSuccess
  | PaymentAccountsAddFail
  | PaymentAccountsEdit
  | PaymentAccountsEditSuccess
  | PaymentAccountsEditFail
  | PaymentAccountsUpdateModeAuth
  | PaymentAccountsUpdateModeAuthSuccess
  | PaymentAccountsUpdateModeAuthFail
  | PaymentAccountsDelete
  | PaymentAccountsDeleteSuccess
  | PaymentAccountsDeleteFail
  | PaymentAccountScreenRecordingUpdate
  | PaymentAccountsCleanup
  | PaymentAccountsTruncate;

export const fromPaymentAccountActions = {
  PaymentAccountsLoad,
  PaymentAccountsLoadSuccess,
  PaymentAccountsLoadFail,
  PaymentAccountsLoadComplete,
  PaymentAccountsLoadDetail,
  PaymentAccountsLoadDetailSuccess,
  PaymentAccountsLoadDetailFail,
  PaymentAccountsRefresh,
  PaymentAccountsSave,
  PaymentAccountsSaveFail,
  PaymentAccountsSaveSuccess,
  PaymentAccountsAdd,
  PaymentAccountsAddSuccess,
  PaymentAccountsAddFail,
  PaymentAccountsEdit,
  PaymentAccountsEditSuccess,
  PaymentAccountsEditFail,
  PaymentAccountsUpdateModeAuth,
  PaymentAccountsUpdateModeAuthSuccess,
  PaymentAccountsUpdateModeAuthFail,
  PaymentAccountsDelete,
  PaymentAccountsDeleteSuccess,
  PaymentAccountsDeleteFail,
  PaymentAccountScreenRecordingUpdate,
  PaymentAccountsCleanup,
  PaymentAccountsTruncate
};

<div class="tile-pill" *ngIf="showPill && isRomePillEnabled">
  <span class="tile-pill-text" [ngClass]="{
    'tile-pill-color-success': variant==='success',
    'tile-pill-color-alert': variant==='alert'
  }">
    <span *ngIf="icon" class="tile-pill-icon {{icon}}"></span>
    <ng-content select="[tile-pill]"></ng-content>
  </span>
</div>

<div class="tile" [ngClass]="tileClasses">
  <div class="tile-banner-wrapper" *ngIf="showBanner">
    <ng-content select="[tile-banner]"></ng-content>
  </div>
  <div class="tile-heading-wrapper"><ng-content select="[tile-heading]"></ng-content></div>
  <div class="tile-content-wrapper fill">
    <ng-content select="[tile-content]"></ng-content>
  </div>
  <span class="grow"></span>
  <div class="tile-cta-wrapper"><ng-content select="[tile-cta]"></ng-content></div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  constructor() {}

  loadScript(scriptUrl: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = () => {
        resolve();
      };
      scriptElement.onerror = error => {
        reject({ error: 'Script load error', details: error });
      };

      document.head.appendChild(scriptElement);
    });
  }
}

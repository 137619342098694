import { Action } from '@ngrx/store';

export const ENROLL_POLICY_INIT = '[KydPolicy] Enroll Policy Init';
export const ENROLL_POLICY = '[KydPolicy] Enroll Policy';
export const ENROLL_POLICY_SUCCESS = '[KydPolicy] Enroll Policy Success';
export const ENROLL_POLICY_FAIL = '[KydPolicy] Enroll Policy Fail';
export const ENROLL_POLICY_RESET = '[KydPolicies] Enroll Policies Reset State';

export class EnrollPolicyInitAction implements Action {
  readonly type = ENROLL_POLICY_INIT;

  constructor(public payload: any) {}
}

export class EnrollPolicyAction implements Action {
  readonly type = ENROLL_POLICY;

  constructor(public payload: any) {}
}

export class EnrollPolicySuccessAction implements Action {
  readonly type = ENROLL_POLICY_SUCCESS;

  constructor(public payload: any) {}
}

export class EnrollPolicyFailAction implements Action {
  readonly type = ENROLL_POLICY_FAIL;

  constructor(public payload: any) {}
}

export class EnrollPolicyResetAction implements Action {
  readonly type = ENROLL_POLICY_RESET;

  constructor() {}
}

export type Actions =
  | EnrollPolicyInitAction
  | EnrollPolicyAction
  | EnrollPolicySuccessAction
  | EnrollPolicyFailAction
  | EnrollPolicyResetAction;

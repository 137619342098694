import { FEATURES } from '@amfam/policy/vehicle-coverages/data-access';

export interface Status {
  code: number;
  reason: string;
  transactionId: string;
  maxMessageLevel?: any;
  errors: any[];
}

export interface DiscountSupportDTO {
  courseCompletionValidPeriodInMonths: number;
}

export interface SelectableDiscount {
  discountCode: string;
  selected: boolean;
  discountType: string;
  discountSupportDTOs: DiscountSupportDTO[];
  name: string;
  description: string;
}

export interface LossesAndViolations {
  incidents: any[];
}

export interface DateOfBirth {
  year: number;
  month: number;
  day: number;
}

export interface AddressDetails {
  city: string;
  addressLine1: string;
  postalCode: string;
  country: string;
  state: string;
}

export interface Person {
  employerName: string;
  homeNumber: string;
  workNumber: string;
  primaryPhoneType: string;
  cellNumber: string;
  preferredContactMethod: string;
  employmentStatus: string;
  publicID: string;
  addressBookUID: string;
  dateOfBirth: DateOfBirth;
  maritalStatus: string;
  middleName: string;
  gender: string;
  licenseState: string;
  lastName: string;
  firstName: string;
  licenseNumber: string;
  occupationType: string;
  emailAdress1: string;
  emailAdress2: string;
  addressDetails: AddressDetails;
  primaryEmailType: string;
  displayName: string;
}

export interface ReportDetail {
  sdipstatus: string;
  fromLossPredictor: boolean;
  mvrdate: string;
  mvrstatus: string;
  acldate: string;
  aclstatus: string;
  dhrmonitoringStatus: string;
  frfiling: string;
  aclurlReportId: string;
  mvrurlReportId: string;
  sdipurlReportId: string;
  rvpurlReportId: string;
}

export interface Driver {
  selectableDiscounts?: SelectableDiscount[];
  lossesAndViolations?: LossesAndViolations;
  fixedId?: number;
  affiliations: any[];
  person?: Person;
  publicId: string | number;
  militaryDeployedDates?: any[];
  dateOfBirth?: DateOfBirth;
  gender?: string;
  licenseState?: string;
  licenseNumber?: string;
  driverStatus?: string;
  relationshipToPNI?: string;
  isPolicyHolder?: boolean;
  driverID?: number;
  filingNeeded?: boolean;
  ageLicensed?: number;
  reportDetails?: ReportDetail[];
  displayName?: string;
  name?: string;
  primary: boolean;
}

export interface PreviousTotalCost {
  amount: number;
  currency: string;
}

export interface TransactionCost {
  amount: number;
  currency: string;
}

export interface FromPc {
  previousTotalCost: PreviousTotalCost;
  transactionCost: TransactionCost;
}

export interface Taxes {
  amount: number;
  currency: string;
}

export interface Rate {
  fromPc: FromPc;
  taxes: Taxes;
  totalCost: number;
  priorCost: number;
  transactionCost: number;
  totalDiscountAmount: number;
  termMonths: number;
  monthlyPremium: string;
  priorMonthly: string;
}

export interface VehiclesInPolicy {
  vin: string;
  year: number;
  description: string;
  fixedId: number;
}

export interface Option {
  code: string;
  optionValue: number;
  maxValue: number;
  name: string;
}

export interface Term {
  updated: boolean;
  publicID: string;
  required: boolean;
  options: Option[];
  patternCode: string;
  type: string;
  chosenTermValue: string | boolean;
  isAscendingBetter: boolean;
  coveragePublicID: string;
  chosenTerm: string;
  name: string;
}

export interface ICoverage {
  coverageCategoryDisplayName: string;
  isValid: boolean;
  hasTerms: boolean;
  coverageCategoryCode: string;
  selected: boolean;
  terms: Term[];
  updated: boolean;
  publicID: string;
  required: boolean;
  isRejected: boolean;
  name: string;
  description: string;
  hide?: boolean;
}

export interface VehicleCoverage {
  vehicleName: string;
  fixedId: number;
  coverages: ICoverage[];
  appliedDiscounts: AppliedDiscount[];
  publicID: string;
  fees: {
    code: string;
    amount: { amount: number; currency: string };
    name: string;
  }[];
}

export interface Amount {
  amount: number;
  currency: string;
}

export interface EffectiveDate {
  day: number;
  year: number;
  month: number;
}

export interface ExpirationDate {
  day: number;
  year: number;
  month: number;
}

export interface AppliedDiscount {
  discountCode: string;
  amount: string;
  effectiveDate: EffectiveDate;
  expirationDate: ExpirationDate;
  name: string;
}

export interface PolicyCoverage {
  coverageCategoryDisplayName: string;
  isValid: boolean;
  hasTerms: boolean;
  coverageCategoryCode: string;
  selected: boolean;
  terms: Term[];
  updated: boolean;
  publicID: string;
  required: boolean;
  amount: Amount;
  isRejected: boolean;
  name: string;
  description: string;
}

export interface DriverAssignment {
  primary: boolean;
  vin: string;
  vehicleId: number;
  driverId: number;
  vehicleDisplayName: string;
  driverName: string;
}

export interface Result {
  id?: string;
  vin: string;
  year: string;
  coveragesModelledAfter: string;
  coveragesModelledAfterVin: string;
  policyNumber: string;
  submissionId: string;
  drivers: Driver[];
  rate: Rate;
  vehiclesInPolicy: VehiclesInPolicy[];
  vehicleCoverages: VehicleCoverage[];
  policyCoverages: PolicyCoverage[];
  tpis: any[];
  driverAssignments: DriverAssignment[];
}
export interface TransactionResponse {
  output: any;
  status: Status;
  result: Result;
}
export interface UpdateCoverageParams {
  coverageCode: string;
  termName: string;
  value: string | boolean;
}

export interface UpdatePrimaryDriverParams {
  driverId: number;
  vehicleId: number;
}

//Transaction Request Objects
export interface GarageAddress {
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface Option {
  optionValue: number;
  code: string;
  maxValue: number;
  name: string;
}

export interface Term {
  updated: boolean;
  required: boolean;
  options: Option[];
  publicID: string;
  patternCode: string;
  chosenTerm: string;
  chosenTermValue: string | boolean;
  isAscendingBetter: boolean;
  coveragePublicID: string;
  type: string;
  name: string;
}

export interface PartnerVehicle {
  vin?: string;
  year?: string;
  currentMileage?: number;
  annualMileage?: number;
  msrp?: string;
  partnerPrimaryUse?: string;
  garageAddress?: GarageAddress;
  airBagsIndicator?: string;
  antiLockBrakes?: boolean;
  antiTheftDeviceIndicator?: string;
  dayTimeRunningLights?: boolean;
  electronicStability?: boolean;
  hasOtherOrCoowner?: boolean;
  tpis?: any[];
  coverages: VehicleCoverage[];
}
export interface TransactionRequest {
  policyNumber: string;
  callProductModelApi: boolean;
  policyType: string;
  partnerCode: string;
  contractState?: string;
  vehicle: PartnerVehicle;
  drivers: DriverAssignment[];
}
export interface SaveAndRateRequest {
  policyNumber: string;
  vehicle: PartnerVehicle;
  drivers: DriverAssignment[];
}
//Transaction Request Objects

export interface CoverageControlModel {
  coverageCode: string;
  coverageName: string;
  coverageDescription: string;
  options: Option[];
  selectedValue: string | boolean;
  coverageHelpTextList?: string[];
  isCheckbox: boolean;
  controls: Control[];
  isDisabled: boolean;
}
export interface Control {
  labelText: string;
  selectedOption?: string | boolean;
  options: Option[];
}

export interface PartnerChangeCoverageAgentEmail {
  customerName: string;
  policyNumber: string;
  vehicleDescription: string;
  coverageChanges?: string;
  emailType: string;
  workOrderNumber?: string;
}

export class ChangeCoverageMessageModel {
  public firstName: string;
  public policyNumber: string;
  public riskInformation: string;
  public totalPremium: number; // expected to be a number by Salesforce
  public monthlyPremium: number; // expected to be a number by Salesforce
  public termDescription: string;
  public newRiskInformation: string;
  public newTotalPremium: number; // expected to be a number by Salesforce
  public newMonthlyPremium: number; // expected to be a number by Salesforce
  public newTermDescription: string;
  public quoteExpirationDate: string;
  public drivers: string;
  public garagedAddress: string;
  public coverages: string;
  public discounts: string;
  public fees: string;
  public orderNumber: string;
  public producerId: string;

  constructor(request) {
    this.firstName = request.firstName;
    this.policyNumber = request.policyNumber;
    this.drivers = request.drivers;
    this.coverages = request.coverages;
    this.orderNumber = request.orderNumber;
  }
}

export interface VehicleCoverageAnalyticPayLoad {
  event: FEATURES;
  buttonAnalyticName?: string;
  pageAnalyticName?: string;
}

export enum ChangeCoverageEmailType {
  BIND = 'BIND',
  SEND_TO_AGENT = 'SEND_TO_AGENT'
}

export enum ChangeCoverageEmailTemplate {
  BIND = 'CovChangeBindConf',
  SEND_TO_AGENT = 'CovChangeToAgent'
}

export enum ChangeCoverageAFIEmailTemplate {
  BIND = 'CovChangeBindConf',
  SEND_TO_AGENT = 'CovChangeToAgent'
}

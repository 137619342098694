export const SortTypes: { [key: string]: string } = {
  HOME: 'property',
  HOMEOWNERS: 'property',
  PROPERTY: 'property',
  PERSONALAUTO: 'auto',
  VEHICLE: 'auto',
  FAMCARPOLICY: 'auto',
  MOTORHOME: 'motorhome',
  RECREATIONALVEHICLE: 'motorhome',
  WATERCRAFT: 'boat',
  COMMERCIAL: 'umbrella',
  CYCLE: 'motorcycle',
  OPERATOR: 'operator'
};

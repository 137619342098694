import { ApiStatus, GetQuoteABTestVariations } from '@amfam/shared/models';
import { CurrencyUSDPipe } from '@amfam/shared/ui/pipes';
import { QuickLink } from '@amfam/shared/ui/quick-links';
import { Agent } from '@amfam/shared/utility/agent';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import {
  AnalyticsService,
  ButtonAnalytic,
  CopyService,
  OpportunityContent,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, DsModalService, LoadingSpinnerService } from '@amfam/ui-kit';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// Store
import { select, Store } from '@ngrx/store';
import { get as _get, template as _template, template } from 'lodash';
// Common Plugings
import { FeedbackStatus } from '@amfam/shared/models';
import { CapitalizePipe } from '@amfam/shared/ui/pipes';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, map, mergeMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import * as fromOpportunity from '../../+state';
import * as fromNotification from '../../+state/notification.reducer';
import * as OpportunityActions from '../../+state/opportunity.action';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { fromRouterActions } from '@amfam/shared/utility/navigation/src';
import { getAllActiveAgents } from '../../+state';
import {
  OpportunitiesCtaButton,
  OpportunitiesCtaInterface
} from '../../components/opportunities-cta/opportunities-cta.model';

import { ABExperience } from '../../models';
import { CustomerFeedbackEnum } from '../../models/feedback';
import { OpportunityAnalytics } from '../../models/opportunity-analytic-contants';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'ds-opportunities-detail',
  templateUrl: './opportunities-detail-page.component.html',
  styleUrls: ['./opportunities-detail-page.component.scss']
})
export class OpportunitiesDetailComponent implements OnInit, OnDestroy {
  // Public variables
  opportunityContent$: Observable<OpportunityContent>;
  recommendationId$: Observable<string>;

  quickLinksList = new Array<QuickLink>();
  agentNotificationForm: UntypedFormGroup;
  propertyIcons = [
    { icon: 'icon-home', name: 'Homeowners', active: true, analyticsCode: 'Home' },
    { icon: 'icon-condo', name: 'Condo', active: false, analyticsCode: 'Condo' },
    { icon: 'icon-renters', name: 'Renters', active: false, analyticsCode: 'Renters' },
    {
      icon: 'icon-manufactured-home',
      name: 'Manufactured Home',
      active: false,
      analyticsCode: 'Manufactured'
    }
  ];
  agentSelectionModal = 'Opportunity-Agent-Selection-Modal';
  modalWidth = '596';

  pending$: Observable<boolean>;
  authorized$: Observable<boolean>;
  notificationStatus$: Observable<FeedbackStatus>;
  notificationSuccess$: Observable<ApiStatus>;
  opportunitiesCTAData: Observable<OpportunitiesCtaInterface>;
  agents$: Observable<Agent[]>;
  propItemIndex = 0;
  notificationState$: Observable<fromNotification.NotificationState>;
  customerFeedbackEnum: CustomerFeedbackEnum;
  type: string;
  showQuote = false;
  primaryButton: string;
  secondaryButton: string;
  tertiaryButton: string;
  public showNewTemplate = false;
  // Private variables
  private stop$: Subject<void> = new Subject<void>();
  private opportunityType$: Observable<string>;
  constructor(
    private copyService: CopyService,
    private dockingService: DockingBarService,
    private store: Store,
    private modalService: DsModalService,
    private formBuilder: UntypedFormBuilder,
    private analyticsService: AnalyticsService,
    private utilService: UtilService,
    public roleService: ImpersonateRolesService,
    public spinner: LoadingSpinnerService,
    private opportunityService: OpportunityService,
    private route: ActivatedRoute,
    private currencyUSDPipe: CurrencyUSDPipe,
    private featureFlagService: FeatureFlagService,
    private analyticsFacade: AnalyticsFacade
  ) {
    this.currencyUSDPipe = new CurrencyUSDPipe();
    this.quickLinksList = [
      new QuickLink('Request Contact from Agent').withOnClick((event: MouseEvent) => {
        this.contactAgent();
        event.preventDefault();
      })
    ];
    this.dockingService.registerHeading('My Opportunity');
  }

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('type');
    // Setup the observable to get the value from the params
    this.opportunityType$ = this.route.params.pipe(
      take(1),
      map(param => <string>param['id'])
    );

    this.recommendationId$ = this.opportunityType$.pipe(
      mergeMap(opportunityType =>
        this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
      )
    );
    this.notificationState$ = this.store.pipe(select(fromOpportunity.selectOpportunityCardState));

    this.agents$ = this.store.pipe(select(getAllActiveAgents));

    // Setup Impersonaton access
    this.authorized$ = this.roleService.isAuthorized('myopportunities_cta');

    // Setup the error observable to be used in async pipe in the template
    this.notificationStatus$ = this.opportunityType$.pipe(
      mergeMap(opportunityType =>
        this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
      ),
      mergeMap(recommendationId =>
        this.store.pipe(select(fromOpportunity.getOpportunityFeedback, recommendationId))
      )
    );

    // Setup the success observable to be used in the async pipe in the template
    this.notificationSuccess$ = this.opportunityType$.pipe(
      mergeMap(opportunityType =>
        this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
      ),
      mergeMap(recommendationId =>
        this.store.pipe(select(fromOpportunity.getOpportunityCardSuccess, recommendationId))
      )
    );

    // Setup loading indicator
    this.pending$ = this.opportunityType$.pipe(
      mergeMap(opportunityType =>
        this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
      ),
      mergeMap(recommendationId =>
        this.store.pipe(select(fromOpportunity.getOpportunityCardLoading, recommendationId))
      )
    );

    // Get the opportunity content
    this.opportunityContent$ = this.opportunityType$.pipe(
      mergeMap(opportunityType =>
        this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
      ),
      mergeMap(recommendationId =>
        this.store.pipe(
          select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
          withLatestFrom(this.opportunityType$),
          map(([opportunity, opportunityType]) => {
            if (!opportunity) {
              return (<OpportunityContent[]>this.copyService.getCopy('opportunity')).find(
                opportunityFrmCopy => opportunityFrmCopy.opportunityProductType === opportunityType
              );
            }
            return opportunity;
          })
        )
      ),
      filter(opportunity => !!opportunity),
      map(opportunity => _get(opportunity, 'content', opportunity)),
      map(content => {
        let result = this.opportunityService.mapContent([content])[0];
        this.showNewTemplate = result.newTemplate;
        if (this.showNewTemplate) {
          result = this.opportunityService.mapSelectionTypeObjects(result, this.type);
        }
        this.setActionButtons(result);
        this.showQuote = this.showQuoteButton(result);
        return result;
      })
    );

    // Build the page cta by fetching the information from the content
    this.opportunitiesCTAData = this.opportunityContent$.pipe(
      withLatestFrom(this.opportunityService.filterStateAverages(), this.authorized$),

      mergeMap(([opportunityContent, opportunitiesStateAverages, authorized]) => {
        return this.buildCtaData(
          _get(opportunityContent, 'detailComponentFooterInfoTitle'),
          [
            _template(_get(opportunityContent, 'detailComponentFooterInfoBody'))({
              state: this.getStateName(_get(opportunitiesStateAverages, 'stateCode')),
              monthlyAmount: this.currencyUSDPipe.transform(
                _get(opportunitiesStateAverages, 'monthly')
              )
            })
          ],
          [],
          authorized,
          _get(opportunityContent, 'ctaIconPath', '')
        );
      })
    );

    // Setup the page spinner indicator
    this.pending$.pipe(takeUntil(this.stop$)).subscribe(pending => {
      if (pending) {
        this.spinner.start({ blockActions: true });
      } else {
        this.spinner.stop();
      }
    });

    // Send information to the analytics about the landing on the detail page of opportunity
    this.opportunityType$
      .pipe(
        mergeMap(opportunityType =>
          this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
        ),
        mergeMap(recommendationId =>
          this.opportunityService.buildPageAnalyticData(
            this.type
              ? OpportunityAnalytics.pageOpportunityDetailWithType
              : OpportunityAnalytics.pageOpportunityDetail,
            recommendationId,
            this.type
          )
        ),
        take(1)
      )
      .subscribe(pageAnalytic => this.analyticsFacade.trackPage(pageAnalytic));

    this.buildForm();
  }

  private showQuoteButton(opportunitiesContent: OpportunityContent) {
    if (
      opportunitiesContent.quote &&
      opportunitiesContent.quote.abTestingKey &&
      opportunitiesContent.quote.featureFlag
    ) {
      const experience: ABExperience = this.featureFlagService.getVisitorExperience(
        opportunitiesContent.quote.featureFlag,
        opportunitiesContent.quote.abTestingKey
      );
      return (
        experience &&
        experience.isEnabled &&
        experience.text === GetQuoteABTestVariations.SHOW_QUOTE
      );
    }
    return false;
  }

  setActionButtons(opportunityContent: OpportunityContent) {
    this.primaryButton = opportunityContent.detailContactBtnText;
    this.secondaryButton = opportunityContent.overviewComponentIntroNoThanksBtn;
    this.tertiaryButton = null;

    if (this.showQuote && opportunityContent.quote) {
      this.primaryButton = opportunityContent.quote.buttonText;
      this.secondaryButton = opportunityContent.quote.askAgentText;
      this.tertiaryButton = opportunityContent.overviewComponentIntroNoThanksBtn;
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  public switchPropertyTile(i: number) {
    this.propertyIcons.map((obj, index) => {
      if (index === i) {
        obj.active = true;
      } else {
        obj.active = false;
      }
    });
    this.propItemIndex = i;

    this.opportunityType$
      .pipe(
        mergeMap(opportunityType =>
          this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
        ),
        mergeMap(recommendationId =>
          this.opportunityService.buildIconClickAnalyticData(
            this.propertyIcons[this.propItemIndex].analyticsCode,
            recommendationId
          )
        ),
        take(1)
      )
      .subscribe(buttonAnalytic => this.analyticsService.trackButtonClick(buttonAnalytic));
  }
  isContactMeDisabled(state: fromNotification.NotificationState, id: number) {
    if (
      (id && state?.entities?.[id]?.success?.code === 200) ||
      state?.entities?.[id]?.success?.customerFeedbackCode ===
        CustomerFeedbackEnum.CustomerDismissed
    ) {
      return true;
    }
    return false;
  }

  /**
   * @param: None
   * @returns: Void
   * @description: This function is called when the user clicks on the contact agent button from the opportunity card
   * and based on the number of active agents we either show them the option to choose their agent or send the email to
   * the single agent present.
   */
  contactAgent(buttonClicked?: string): void {
    const type = new CapitalizePipe().transform(this.type);
    this.opportunityContent$
      .pipe(
        withLatestFrom(
          this.agents$,
          this.opportunityType$.pipe(
            mergeMap(opportunityType =>
              this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
            ),
            mergeMap(recommendationId =>
              this.buildButtonAnalyticData(
                type
                  ? OpportunityAnalytics.clickOpportunityDismissWithType
                  : OpportunityAnalytics.clickOpportunityDismiss,
                recommendationId,
                type
              )
            )
          ),
          this.opportunityType$.pipe(
            mergeMap(opportunityType =>
              this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
            )
          )
        ),
        take(1)
      )
      .subscribe(([content, agents, buttonAnalyticData, recommendationId]) => {
        // If the user clicked on the no thanks button we send the feedback to the recommender service about the user selection
        // Since this is an operation which doesnt pester the user with the modal pop to select the agent, we go ahead with
        // the first agent id in case of multiple agents.
        if (buttonClicked === content.overviewComponentIntroNoThanksBtn) {
          // Send back the analytic data
          this.analyticsFacade.trackButtonClick(buttonAnalyticData);
          this.sendFeedback(_get(agents[0], 'id'), recommendationId, type);
        } else if (content.quote && buttonClicked === content.quote.buttonText) {
          this.buildButtonAnalyticData(
            OpportunityAnalytics.clickOpportunityGetAQuote,
            recommendationId
          )
            .pipe(
              withLatestFrom(this.opportunityType$),
              first(),
              tap(([analytics, opportunityType]) => {
                this.analyticsFacade.trackButtonClick(analytics);
                const qualifierRoute = ['opportunities/learnmore', opportunityType, 'eligibility'];
                this.store.dispatch(new fromRouterActions.Go({ path: qualifierRoute }));
              })
            )
            .subscribe();
        } else {
          // If there is a single agent , we use the agent's information to send the email.
          // If there are more than one agent show the modal and let the customer choose the agent to who they want to send the
          // opportunity selection information.
          const numberOfAgents = _get(agents, 'length', 0);
          if (numberOfAgents && numberOfAgents > 1) {
            this.openModal();
            this.opportunityService
              .buildPageAnalyticData(
                type
                  ? OpportunityAnalytics.whichAgentPageOpporutunityWithType
                  : OpportunityAnalytics.whichAgentPageOpporutunity,
                recommendationId,
                type
              )
              .pipe(take(1))
              .subscribe(resp => this.analyticsFacade.trackPage(resp));
          } else {
            this.notifyAgent(_get(agents[0], 'agentId'), recommendationId, false);
          }
        }
      });
  }

  /**
   * @param: None
   * @returns: void
   * @description: Opens the modal with the dropdown to choose the agents from.
   */
  openModal(): void {
    this.modalService.open(this.agentSelectionModal);
  }

  /**
   * @param: None
   * @returns: void
   * @description: Closes the modal with the dropdown to choose the agents from.
   */
  closeModal(): void {
    this.modalService.close(this.agentSelectionModal);
  }

  /**
   * @param: None
   * @returns: void
   * @description: Function gets called when the user confirms their selection of an agent from the agent modal dropdown.
   * This would call the notifyAgent function and close the agent selection modal.
   */
  confirmAgentSelection(): void {
    const selectedAgentId = _get(this, 'agentNotificationForm.controls["selectAgent"].value', '');
    this.opportunityType$
      .pipe(
        mergeMap(opportunityType =>
          this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
        )
      )
      .subscribe(recommendationId => this.notifyAgent(selectedAgentId, recommendationId, true));
    this.closeModal();
  }

  /**
   * @param: AgentId
   * @returns: void
   * @description: This functions gets called when the user selects one of the agents from the dropdown selection in the modal
   * and dispatches an action to send the notification to the agent.
   */
  notifyAgent(selectedAgentId: string, recommendationId: string, isMultiAgent = false): void {
    const type = new CapitalizePipe().transform(this.type);
    // Send back the analytic data
    this.buildButtonAnalyticData(
      isMultiAgent
        ? this.type
          ? OpportunityAnalytics.clickOpportunityWhichAgentSendToAgentWithType
          : OpportunityAnalytics.clickOpportunityWhichAgentSendToAgent
        : this.type
        ? OpportunityAnalytics.clickOpportunityContactAgentWithType
        : OpportunityAnalytics.clickOpportunityContactAgent,
      recommendationId,
      type
    )
      .pipe(take(1))
      .subscribe(analytticsRes => this.analyticsService.trackButtonClick(analytticsRes));

    this.store.dispatch(
      new OpportunityActions.OpportunitiesNotificationAction({
        correlationId: this.utilService.generateId(),
        agentId: selectedAgentId,
        recommendationId: recommendationId,
        isMultiAgent,
        type
      })
    );
  }

  /**
   * @param: StateCode - String
   * @returns: String
   * @description: This function takes in the state code and returns the state name by checking the mappings from the copy service
   */
  getStateName(stateCode: string): string {
    let stateName: string;
    this.copyService.get('shared', 'usStates').forEach((stateItem: any) => {
      if (stateItem.value === stateCode) {
        stateName = stateItem.name;
      }
    });
    return stateName;
  }

  /**
   * @param: AgentId
   * @returns: void
   * @description: This functions semds the feedback to the recommender service that user clicked on No Thanks
   * and they do not wish to see this opportunity anymore. (For 6 months)
   */
  sendFeedback(selectedAgentId: string, recommendationId: string, type?: string): void {
    this.store.dispatch(
      new OpportunityActions.OpportunitiesFeedbackAction({
        feedbackActionCode: CustomerFeedbackEnum.CustomerDismissed,
        producerId: selectedAgentId,
        recommendationId: recommendationId,
        isMultiAgent: false,
        type
      })
    );
  }

  /**
   *
   * @param analyticData Incoming analytic model containing a link string based on the specific criterion
   * @param productType Product type of the selected opportunity
   * @param subProductType Sub Producttype of the selected opportunity/
   * @returns ButtonAnalytic : returns a ButtonAnalytic model containing a link string with the concatenated product type
   * and sub product type.
   */
  private buildButtonAnalyticData(
    analyticData: ButtonAnalytic,
    recommendationId: string,
    type?: string
  ): Observable<ButtonAnalytic> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
      take(1),
      mergeMap(opportunity =>
        of({
          link:
            'MyAccount:' +
            opportunity.content.analyticsInfo.pageInfo +
            ':' +
            template(analyticData.link)({ type })
        })
      )
    );
  }
  /**
   * @param: None
   * @returns: void
   * @description: Builds the form and adds the agent select along with the required validation.
   */
  private buildForm(): void {
    this.agentNotificationForm = this.formBuilder.group({
      selectAgent: ['', Validators.compose([Validators.required])]
    });
  }

  /**
   * @author Abhishek Singh
   * @param title - string which denotes what is the title of the cta component
   * @param body - string array which describes the body of the cta component
   * @param buttonText - text which needs to be displayed on the cta commponent button
   * @param buttonType - This is an optional param which decides the styling on the cta component button
   * @returns OpportunitiesCtaInterface
   * @description This function would take the params and return the object as per the OpportunitiesCtaInterface
   */
  private buildCtaData(
    title: string,
    body: string[],
    buttons: OpportunitiesCtaButton[],
    enabled?: boolean,
    ctaIconPath?: string
  ): Observable<OpportunitiesCtaInterface> {
    return of({
      title: title,
      body: body,
      buttons: buttons,
      enabled: enabled,
      ctaIconPath: ctaIconPath
    });
  }
}

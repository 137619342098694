import { ApiStatus } from '@amfam/shared/models';
import { SecurityQuestionsAnswerModel } from './security-questions-answer.model';

export interface ResetPasswordModel {
  loading: boolean;
  hasError: boolean;
  status?: ApiStatus;
  statusCode: number;
  userIdentifier?: string;
  lockedUserId?: string;
  userVerified: boolean;
  verifyMethodLocked: boolean;
  correlationId?: string;
  pinCode?: string;
  answeredSecurityQuestions?: SecurityQuestionsAnswerModel[];
  securityQuestions?: any[];
  userDetails: UserDetailsModel;
  shellAccountStatus: boolean;
}

export const initialState: ResetPasswordModel = {
  loading: false,
  hasError: false,
  status: null,
  statusCode: null,
  userIdentifier: '',
  lockedUserId: '',
  userVerified: false,
  verifyMethodLocked: false,
  correlationId: '',
  pinCode: '',
  answeredSecurityQuestions: null,
  securityQuestions: null,
  userDetails: null,
  shellAccountStatus: false
};

export interface UserDetailsModel {
  maskedEmailAddress: string;
  maskedPhoneNumbers: string[];
  shellAccountIndicator: boolean;
  enrollmentRequestIndicator: boolean;
  verificationInProgressIndicator: boolean;
  status: ApiStatus;
}

export interface LoadUserDataSuccessModel {
  securityQuestionsData: GetSecurityQuestionsModel;
  apiData: UserDetailsModel;
}

export interface GetSecurityQuestionsModel {
  userIdentifier: string;
  partnerId?: string;
}

export interface ResetOptionModel {
  userIdentifier: string;
  maskedPhoneNumber?: string;
  typeOfVerificationMethodCode?: string;
}

export interface ResetOptionRequest {
  userId?: string;
  email?: string;
  partnerId: string;
  typeOfVerificationMethodCode: string;
  maskedPhoneNumber?: string;
}

export interface VerifyPinRequest {
  userId?: string;
  partnerId?: string;
  pinCode?: string;
  correlationId?: string;
}

export interface VerifySecurityQuestionsModel {
  userIdentifier: string;
  securityQuestions: SecurityQuestionsAnswerModel[];
}

export interface VerifySecurityQuestionsRequest {
  userId?: string;
  email?: string;
  partnerId: string;
  securityQuestions: SecurityQuestionsAnswerModel[];
}

export interface ResetPasswordSubmitModel {
  userIdentifier?: string;
  newPassword: string;
  pinCode?: string;
  correlationId?: string;
  securityQuestions?: SecurityQuestionsAnswerModel[];
}

export interface ResetPasswordSubmitRequest {
  userId?: string;
  email?: string;
  partnerId: string;
  newPassword: string;
  pinCode?: string;
  correlationId?: string;
  securityQuestions?: SecurityQuestionsAnswerModel[];
}

export interface ResetPasswordSuccessModel {
  status: ApiStatus;
  userIdentifier: string;
  password: string;
}

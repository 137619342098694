export interface ClaimsCopy {
  claimsSubHeadingPre: string;
  claimsSubHeadingPost: string;
  noClaimsText: string;
  claimTechnicalError: string;
  claimErrorHit: string;
  hiddenClaimsText: string;
  reportClaimBtn: string;
  reportGlassClaimBtn: string;
  accidentChecklistButton: string;
  whenDidIncidentHappen: string;
  whereDidIncidentHappen: string;
  cityStateDetails: string;
  evaluateProgressBarHeaderText: string;
  resolveProgressBarHeaderText: string;
  evaluateProgressBarListItemText1: string;
  evaluateProgressBarListItemText2: string;
  evaluateProgressBarListItemText3: string;
  evaluateProgressBarListItemText4: string;
  resolveProgressBarListItemText1Auto: string;
  resolveProgressBarListItemText1Property: string;
  resolveProgressBarListItemText2Auto: string;
  resolveProgressBarListItemText2Property: string;
  resolveProgressBarListItemText3Auto: string;
  resolveProgressBarListItemText3Property: string;
  completeHeaderText1: string;
  completeHeaderText2: string;
  checkBackSoonText: string;
  noRentalCarListedText: string;
  rentalCoverageNotEligible: string;
  partnerRentalCoverageNotEligible1: string;
  partnerRentalCoverageNotEligible2: string;
  noRentalCoverage: string;
  partnerNoRentalCoverage1: string;
  partnerNoRentalCoverage2: string;
  noPaymentsDeterminedText: string;
  noTowingOrRepairShopsListedText: string;
  findDrpPartnerText: string;
  dateFiledText: string;
  dateAndTimeIncidentText: string;
  vehicleText: string;
  propertyText: string;
  policyText: string;
  statusText: string;
  incidentDescriptionText: string;
  claimContactText: string;
  typeOfIncidentText: string;
  locationText: string;
  adjusterText: string;
  emailText: string;
  phoneText: string;
  carReturnLocationText: string;
  rentalLimitsText: string;
  claimActivityText: string;
  claimDetailsText: string;
  claimSummaryText: string;
  lossLocations: LossLocationCopy[];
  lossCauses: LossLocationCopy[];
  collisionDetailCauses: LossLocationCopy[];
  weatherDetailCauses: LossLocationCopy[];
  theftDetailCauses: TheftDetailCauseCopy[];
  partnerClaimsText1: string;
  partnerClaimsBullet1: string;
  partnerClaimsBullet2: string;
  partnerClaimsBullet3: string;
  claimsRetrieveError: string;
  defaultErrorMessage: string;
  alreadySubmittedErrorMessage1: string;
  alreadySubmittedErrorMessage2: string;
  fnol: FnolCopy;
  postFnol: PostFnolCopy;
  photoUpload: PhotoUploadCopy;
  dynamicQuestion: DynamicQuestion;
}

interface FnolCopy {
  riskSelectionCompSubHeading: string;
  riskSelectionCompText: string;
  privacyAndSecurityPre: string;
  privacyAndSecurityURL: string;
  privacyAndSecurityPost: string;
  riskSelectionHeading: string;
  riskNotFoundContact1: string;
  riskNotFoundContact2: string;
  riskNotFoundContact: string;
  incidentDriverDescription: string;
  incidentSectionHeading: string;
  confirmationHeading: string;
  anyOneHurtSelection: string;
  yesInjuries: string;
  noInjuries: string;
  thirdPartyInjury: string;
  claimantIsDriver: string;
  thirdPartyInjuryHtml: string;
  notSure: string;
  saveUpdate: string;
  continue: string;
  saveAndContinue: string;
  lossCauseSectionHeading: string;
  collisionMovingObjectOrVehicle: string;
  yesMovingObjectOrVehicle: string;
  noMovingObjectOrVehicle: string;
  vehicleDamageTypeHeading: string;
  hailDamageTypeHeading: string;
  theftDamageTypeHeading: string;
  aboutTheIncident: string;
  noDamage: string;
  someDamage: string;
  allDamage: string;
  howCanWeReachYou: string;
  claimSummaryHeading: string;
  claimSummaryDescription: string;
  vehicle: string;
  vehicleNotOnPolicy: string;
  when: string;
  date: string;
  time: string;
  whereAndInjuries: string;
  cityOrState: string;
  location: string;
  injuries: string;
  incidentDetails: string;
  cause: string;
  otherVehicleOrObject: string;
  description: string;
  hailDamage: string;
  theftVandalismDetails: string;
  descriptionAndContactInfo: string;
  detailedDescription: string;
  phone: string;
  thankYou: string;
  whatToExpectBtn: string;
  nextSteps: string;
  nextStepsInstructions: string;
  nextStepsInstructionsAlt: string;
  nextStepsInstructionsPartner: string;
  nextStepsInstructionsAutoConfirmation: string;
  nextStepsInstructionsAutoNextStepsHtml: string;
  nextStepsInstructionsAutoBulletsHtml: string;
  nextStepsInstructionsPropertyConfirmation: string;
  nextStepsInstructionsPropertyNextSteps: string;
  nextStepsInstructionsPropertyBulletsHtml: string;
  nextStepsInstructionsPropertyClaimDetails: string;
  nextStepsInstructionsPropertyTimeoutConfirmation: string;
  nextStepsInstructionsTimeoutEmail: string;
  nextStepsInstructionsPropertyTimeoutNextSteps: string;
  nextStepsInstructionsAutoTimemoutConfirmation: string;
  nextStepsInstructionsAutoTimeoutNextStepsHtml: string;
  claimSummary: string;
  giveUsACall: string;
  gotoSafelite: string;
  duplicateFound: string;
  duplicateFoundText: string;
  reportNewClaimBtn: string;
  contactText1: string;
  contactText2: string;
  injuryContactText: string;
  contactNumber: string;
  rentalRoute: string;
  repairRoute: string;
  confirmReservation: string;
  claimSubmitted: string;
}

interface PostFnolCopy {
  nextStepsInstructionsFlow: string;
  nextStepsInstructionsDrpEligible: string;
  nextStepsInstructionsDrpSuccess: string;
  nextStepsInstructionsDrpFail: string;
  nextStepsInstructionsPostFnolDrpFail: string;
  nextStepsInstructionsPostFnolDrpSuccess: string;
  nextStepsInstructionsPhotoUpload: string;
  nextStepsInstructionsRentalSuccess: string;
  nextStepsInstructionsRentalFail: string;
  partnerNextStepsInstructionsRentalFail1: string;
  partnerNextStepsInstructionsRentalFail2: string;
  postFnolDrpInfoHeader: string;
  partnerPostFnolDrpInfoHeader: string;
  postFnolDrpInfo: string;
  partnerPostFnolDrpInfo: string;
  postFnolDrpInfoNextButton: string;
  postFnolDrpInfoSkipButton: string;
  postFnolDRPCompletePhotoUploadInfoHeader: string;
  postFnolPhotoUploadInfoHeader: string;
  postFnolDRPCompletePhotoUploadInfo: string;
  postFnolPhotoUploadInfo: string;
  postFnolPhotoUploadInfoNextButton: string;
  postFnolPhotoUploadInfoSkipButton: string;
  postFnolDRPCompletePhotoUploadInfoSkipButton: string;
  postFnolDRPCompletePhotoUploadInfoNextButton: string;
  postFnolRentalEligibleInfoHeader: string;
  postFnolRentalNotEligibleInfoHeader: string;
  postFnolNoRentalCoverageInfoHeader: string;
  partnerPostFnolNoRentalCoverageInfoHeader: string;
  postFnolRentalInfo: string;
  partnerPostFnolRentalInfo: string;
  postFnolRentalNotEligibleInfo: string;
  partnerPostFnolRentalNotEligibleInfo: string;
  postFnolNoRentalCoverageInfo: string;
  partnerPostFnolNoRentalCoverageInfo: string;
  postFnolRentalInfoButton: string;
  postFnolContinueBulletsNoDrp: string;
  postFnolContinueBulletsDrp: string;
  speedUpYourClaim: string;
  importantThings: string;
  importantThingsBullets: string;
  partnerImportantThingsBullets: string;
  confirmationHeadingRentalSuccess: string;
  confirmationHeadingRentalFail: string;
  confirmationSubHeadingRentalSuccess: string;
  confirmationHeadingDrpSuccess: string;
  confirmationHeadingDrpFail: string;
  confirmationHeadingPhotosSuccess: string;
  finalizeHeadingRental: string;
  finalizeDetailsHeadingRental: string;
  postFnolCtaHeading: string;
  postFnolCtaPrompt: string;
  postFnolCtaBullet1: string;
  postFnolCtaBullet2: string;
  postFnolCtaBullet3: string;
  postFnolCtaButton: string;
  goToClaimsButton: string;
  goToClaimButton: string;
}

interface PhotoUploadCopy {
  addPhotosBtn: string;
  claimConfirmationSubHeading: string;
  photoUploadDescription: string;
  claimConfirmationBullet1: string;
  claimConfirmationBullet2: string;
  claimStatusDescription: string;
  claiStatusNoPhotosText: string;
  photoUploadHeader: string;
  photoUploadCriteriaHeader: string;
  photoUploadBullet1: string;
  photoUploadBullet1SubText: string;
  photoUploadBullet2: string;
  photoUploadBullet2LinkText: string;
  photoUploadBullet3: string;
  photoUploadBullet4: string;
  photoUploadBullet5: string;
  photoUploadBullet6: string;
  photoUploadDragText: string;
  photoUploadBrowseBtn: string;
  photoUploadFileTypes: string;
  photoUploadFileSize: string;
  photoUploadFileError: string;
  photoUploadErrorToaster: string;
  photoListHeader: string;
  photoListDescription: string;
  photoListRemoveBtn: string;
  photoListAddDescriptionText: string;
  photoListRemoveDescriptionText: string;
  photoListCategories: string[];
  photoListReadyText: string;
  photoUploadVINModalQuestion: string;
  photoUploadVINModalDescription: string;
  photoUploadConfirmationHeader: string;
  photoUploadConfirmationNextSteps: string;
  photoUploadConfirmationViewClaimLink: string;
}

interface TheftDetailCauseCopy {
  name: string;
  value: string;
  isEligibleForVehicleTypeOfBoat?: boolean;
}

interface LossLocationCopy {
  name: string;
  value: string;
}

interface DynamicQuestion {
  incidentInfoHeading: string;
  incidentLocationHeading: string;
  noDamage: string;
  damageAllOver: string;
  someDamage: string;
  callRoadSideAssistanceHeading: string;
  callGlassRepairHeading: string;
  contactErsHtml: string;
  contactGlassHtml: string;
  draftClaimSessionTimeoutHeading: string;
  draftClaimSessionTimeoutMessage: string;
}

<div class="docking-bar primary-800-bg" data-cy="dockingBar" [class.empty]="empty" #dockingBar>
  <div class="side" [ngClass]="{ 'with-icon': isShellAccount && routerUrl === '/profile' }">
    <h1
      #mainHeading
      class="heading-banner flex overflow-hidden overflow-ellipsis"
      data-cy="dockbarTitle"
      [attr.aria-label]="heading"
    >
      <span
        *ngIf="isShellAccount && routerUrl === '/profile'"
        dsTabFocus
        aria-label="Shell Account"
        title="Shell Account"
        class="icon-shield-user"
      ></span>
      {{ heading }}
    </h1>
  </div>

  <div class="docking-actions">
    <h1
      *ngIf="!!secondaryHeading"
      class="heading-banner block md-inline-block overflow-hidden overflow-ellipsis"
      data-cy="dockbarTitle"
      [attr.aria-label]="secondaryHeading"
    >
      {{ secondaryHeading }}
    </h1>
    <button
      ds-button
      *ngFor="let action of actions"
      (click)="action.fn()"
      class="outline dock-action"
      data-cy="dockBarButton"
      [attr.aria-label]="action.title"
    >
      {{ action.title }}
    </button>
  </div>
</div>

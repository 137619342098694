<last-login *ngIf="lastLogin" [lastLogin]="lastLogin" id="lastLogin"></last-login>
<footer-afi
  *dsPartnerAFI
  [chatEnabled]="chatEnabled"
  [feedbackEnabled]="feedbackEnabled"
  [showContactInfo]="showContactInfo"
></footer-afi>
<footer-partner
  *dsPartnerConnect
  [showContactInfo]="showContactInfo"
  [footerLogo]="partnerFooterLogo"
></footer-partner>

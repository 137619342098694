import { PaymentMethodActions } from '@amfam/billing/payment-method/data-access';
import { ScheduledPaymentSelectors } from '@amfam/billing/schedulepayment/data-access';
import { PaymentService } from '@amfam/billing/shared/util/src';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { GetAutomaticPaymentsResponse } from '../models/auto-pay.models';
import { AutoPayService } from '../services/auto-pay.service';
import * as AutoPayActions from './auto-pay.actions';
@Injectable()
export class AutoPayEffects {
  getAutomaticPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPayActions.getAutomaticPayment),
      switchMap(action =>
        this.autoPayService.getAutomaticPayments(action.billAccountNumber).pipe(
          map(res =>
            AutoPayActions.getAutomaticPaymentSuccess({
              response: res,
              correlationId: action.correlationId
            })
          ),
          catchError(error =>
            of(
              AutoPayActions.getAutomaticPaymentFailure({
                error,
                billAccountNumber: action.billAccountNumber,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    )
  );

  getAllAutomaticPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPayActions.getAllAutomaticPayments),
      switchMap(action => {
        const responses: Observable<GetAutomaticPaymentsResponse>[] = action.billAccountNumbers.map(
          billAccountNumber => this.autoPayService.getAutomaticPayments(billAccountNumber)
        );
        return forkJoin(responses).pipe(
          map((res: GetAutomaticPaymentsResponse[]) =>
            AutoPayActions.getAllAutomaticPaymentsSuccess({
              response: res,
              correlationId: action.correlationId
            })
          ),
          catchError(error =>
            of(
              AutoPayActions.getAllAutomaticPaymentsFailure({
                error,
                billAccountNumbers: action.billAccountNumbers,
                correlationId: action.correlationId
              })
            )
          )
        );
      })
    )
  );

  getAutoPayPrediction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPayActions.getAutoPayPrediction),
      switchMap(action =>
        this.autoPayService.getAutoPayPrediction(action.payload, action.context).pipe(
          map(res =>
            AutoPayActions.getAutoPayPredictionSuccess({
              response: res,
              correlationId: action.correlationId
            })
          ),
          catchError(error =>
            of(
              AutoPayActions.getAutoPayPredictionFailure({
                error,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    )
  );

  editAutoPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPayActions.editAutoPay),
      switchMap(action =>
        this.autoPayService.editAutoPay(action.payload, action.billAccountNumber).pipe(
          map(res =>
            AutoPayActions.editAutoPaySuccess({
              response: res,
              correlationId: action.correlationId,
              confirmation: this.paymentService.buildEditAutomaticPaymentConfirmationData(
                action.payload,
                res.autoPayRules
              )
            })
          ),
          catchError(error =>
            of(
              AutoPayActions.editAutoPayFailure({
                error,
                billAccountNumber: action.billAccountNumber,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    )
  );

  deleteAutoPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPayActions.deleteAutoPay),

      switchMap(action =>
        this.autoPayService.deleteAutoPay(action.payload).pipe(
          withLatestFrom(
            this.rootStore.select(
              ScheduledPaymentSelectors.hasScheduledPaymentForBillaccount(
                action.payload.billAccountNumber
              )
            )
          ),
          map(([res, hasScheduledPayment]) =>
            AutoPayActions.deleteAutoPaySuccess({
              response: res,
              billAccountNumber: action.payload.billAccountNumber,
              correlationId: action.correlationId,
              confirmation: this.paymentService.buildDeleteAutomaticPaymentConfirmationData(
                action.payload,
                res,
                hasScheduledPayment
              )
            })
          ),
          catchError(error =>
            of(
              AutoPayActions.deleteAutoPayFailure({
                error,
                billAccountNumber: action.payload.billAccountNumber,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    )
  );

  // When payment method is edited we need to make sure any payment references are updated

  editPaymentMethodSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentMethodActions.editPaymentMethodSuccess),
      map(action => {
        const payload = {
          newNickName: action.newNickName,
          oldNickName: action.oldNickName
        };
        return AutoPayActions.updateAutoPay({ payload });
      })
    )
  );

  addMultipleAutoPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPayActions.addMultipleAutoPay),
      switchMap(action =>
        this.autoPayService.addMultipleAutoPay(action.payload).pipe(
          map(response => {
            const success: boolean = _get(response, 'autoPayRules', []).length > 0;
            const failure: boolean = _get(response, 'partialStatuses', []).length > 0;

            return success && !failure
              ? AutoPayActions.addMultipleAutoPaySuccess({
                  response,
                  correlationId: action.correlationId,
                  req: action.payload,
                  confirmation:
                    this.paymentService.buildMultipleAddAutomaticPaymentConfirmationData(
                      action.payload,
                      response,
                      response.autoPayRules,
                      response.partialStatuses
                    )
                })
              : success && failure
              ? AutoPayActions.addMultipleAutoPayPartialSuccess({
                  response,
                  correlationId: action.correlationId
                })
              : AutoPayActions.addMultipleAutoPayFailure({
                  error: response.partialStatuses[0].status,
                  billAccountNumbers: action.payload.accounts.map(account => account.billingNumber),
                  correlationId: action.correlationId
                });
          }),
          catchError(error =>
            of(
              AutoPayActions.addMultipleAutoPayFailure({
                error: error,
                billAccountNumbers: action.payload.accounts.map(account => account.billingNumber),
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    )
  );

  applyAutoPayDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPayActions.applyAutoPayDiscount),
      mergeMap(action =>
        this.autoPayService.applyAutoPayDiscount(action.payload).pipe(
          map(res =>
            AutoPayActions.applyAutoPayDiscountSuccess({
              response: res
            })
          ),
          catchError(error =>
            of(
              AutoPayActions.applyAutoPayDiscountFailure({
                error
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private autoPayService: AutoPayService,
    private paymentService: PaymentService,
    private rootStore: Store<any>
  ) {}
}

import { PoliciesAction, PoliciesActionTypes } from './policies.actions';
import { ApiStatus, initialApiStatus } from '@amfam/shared/models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get as _get } from 'lodash';

export interface PoliciesNotificationsState {
  policyNumber: string;
  error: ApiStatus | null;
  success: ApiStatus | null;
  loading: boolean;
}

export interface NotificationState extends EntityState<PoliciesNotificationsState> {
  loading: boolean;
  error: ApiStatus | null;
}

export const adapter: EntityAdapter<PoliciesNotificationsState> = createEntityAdapter<
  PoliciesNotificationsState
>({
  selectId: (policiesNotificationsState: PoliciesNotificationsState) =>
    policiesNotificationsState.policyNumber,
  sortComparer: false
});

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: PoliciesAction
): NotificationState {
  switch (action.type) {
    case PoliciesActionTypes.LoadPolicies: {
      return { ...state, loading: true };
    }

    case PoliciesActionTypes.PoliciesLoadError: {
      return { ...state, loading: false };
    }

    case PoliciesActionTypes.PoliciesLoadComplete:
    case PoliciesActionTypes.PoliciesLoadSuccess: {
      return { ...state, loading: false };
    }

    case PoliciesActionTypes.LoadOnePolicy: {
      return adapter.upsertOne(
        {
          policyNumber: _get(action, 'payload.policyNumber'),
          loading: true,
          error: state.entities[_get(action, 'payload.policyNumber')]
            ? state.entities[_get(action, 'payload.policyNumber')].error
            : null,
          success: state.entities[_get(action, 'payload.policyNumber')]
            ? state.entities[_get(action, 'payload.policyNumber')].success
            : null
        },
        state
      );
    }

    case PoliciesActionTypes.PolicyLoadSuccess: {
      return adapter.upsertOne(
        {
          policyNumber: _get(action, 'payload.policyNumber'),
          success: _get(action, 'payload.status', initialApiStatus),
          error: null,
          loading: false
        },
        state
      );
    }

    case PoliciesActionTypes.PolicyLoadError: {
      return adapter.upsertOne(
        {
          policyNumber: _get(action, 'payload.policyNumber'),
          success: null,
          error: _get(action, 'payload.status', initialApiStatus),
          loading: false
        },

        { ...state, loading: false }
      );
    }

    default: {
      return state;
    }
  }
}

<div class="container">
  <div class="delete-payment-method">
    <!-- icon and heading -->
    <div class="row flex items-center">
      <span class="pseudo-icon heading-icon warning"></span>
      <h1 content [contentPath]="'billing.paymentMethodStandAlone.deleteHeading'"></h1>
    </div>
    <!-- payment method to be deleted -->
    <div class="payment-card">
      <ds-payment-method-card
        class="cell-component"
        [paymentAccount]="paymentMethod"
      ></ds-payment-method-card>
    </div>
    <!-- affected payments -->
    <div *ngIf="scheduledPayments.length > 0 || automaticPayments.length > 0">
      <h2 class="pad-bottom-20">The following will also be deleted:</h2>
      <!-- affected scheduled payments -->
      <div *ngIf="scheduledPayments.length > 0" class="payments">
        <h3 class="pad-bottom-10">One-time payments</h3>
        <ds-scheduled-payments-detail
          *ngFor="let payment of scheduledPayments"
          [payment]="payment"
          [paymentAccount]="paymentMethod"
        ></ds-scheduled-payments-detail>
      </div>
      <!-- affected automatic payments -->
      <div *ngIf="automaticPayments.length > 0" class="payments">
        <h3 class="pad-bottom-10">AutoPay</h3>
        <div class="pad-bottom-20">
          <ds-warning-box
            *ngIf="autoPayDiscountWarningHeading"
            [headingText]="autoPayDiscountWarningHeading"
            [messageText]="autoPayDiscountWarningBody"
          ></ds-warning-box>
        </div>
        <ds-scheduled-payments-detail
          *ngFor="let payment of automaticPayments"
          [payment]="payment"
          [paymentAccount]="paymentMethod"
        ></ds-scheduled-payments-detail>
      </div>
    </div>
  </div>

  <call-to-action
    [primaryButtonName]="'Delete'"
    [primaryButtonDestructive]="true"
    [tertiaryButtonName]="'Cancel'"
    (primaryEvent)="delete()"
    (tertiaryEvent)="cancel()"
  >
  </call-to-action>
</div>

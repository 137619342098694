import { ClaimDashboardAction, ClaimDashboardActionTypes } from './claim-dashboard.actions';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

export const CLAIMDASHBOARD_FEATURE_KEY = 'claimDashboard';
import { DashboardClaim } from './claim-dashboard.models';

export interface ClaimDashboardState extends EntityState<DashboardClaim> {
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  error: any;
}

export const adapter: EntityAdapter<DashboardClaim> = createEntityAdapter<DashboardClaim>({
  selectId: (claim: DashboardClaim) => claim.claimNumber,
  sortComparer: sortByClaimNumber
});

export function sortByClaimNumber(a: DashboardClaim, b: DashboardClaim): number {
  return parseInt(b.claimNumber, 10) - parseInt(a.claimNumber, 10);
}

export const initialState: ClaimDashboardState = adapter.getInitialState({
  loading: false,
  loaded: false,
  hasError: false,
  error: null
});

export function claimDashboardReducer(
  state = initialState,
  action: ClaimDashboardAction
): ClaimDashboardState {
  let newState = null;
  switch (action.type) {
    case ClaimDashboardActionTypes.LoadClaimDashboard: {
      newState = Object.assign({}, state, {
        loading: true,
        loaded: false,
        hasError: false
      });
      return newState;
    }

    case ClaimDashboardActionTypes.LoadClaimDashboardSuccess: {
      newState = Object.assign({}, state, {
        loading: false,
        loaded: true
      });
      return adapter.setAll(action.payload, newState);
    }

    case ClaimDashboardActionTypes.LoadClaimDashboardFail: {
      newState = Object.assign({}, state, {
        error: action.payload,
        loading: false,
        loaded: true,
        hasError: true
      });
      return newState;
    }

    default:
      return state;
  }
}

// get the selectors
const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

// select the array of claim ids (claim number)
export const selectEntityIds = selectIds;

// select the dictionary of claim entities
export const selectDashboardClaimEntities = selectEntities;

// select the array of claims
export const selectAllDashboardClaims = selectAll;

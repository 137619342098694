import { Action } from '@ngrx/store';
import { BrandIDModel } from '../models/brandId';

export const LOAD_BRAND_DATA = '[Brand] Load Brand Data';
export const LOAD_BRAND_DATA_SUCCESS = '[Brand] Load Brand Data Success';
export const LOAD_BRAND_DATA_FAILURE = '[Brand] Load Brand Data Failure';
export const LOAD_USER_BRAND_DATA_SUCCESS = '[Brand] Load Brand Data for User Success';
export const LOAD_USER_BRAND_DATA_FAILURE = '[Brand] Load Brand Data for User Failure';
export const VERIFY_BRAND_EXPERIENCE_SUCCESS = '[Brand] Verify Brand Experience Success';
export const VERIFY_BRAND_EXPERIENCE_FAILURE = '[Brand] Verify Brand Experience Failure';
export const UPDATE_PROPERTY_SERVICE_URL = '[Brand] Update Property Service Url';

export class LoadBrandDataAction implements Action {
  readonly type = LOAD_BRAND_DATA;

  constructor(public payload: BrandIDModel) {}
}

export class LoadBrandDataSuccess implements Action {
  readonly type = LOAD_BRAND_DATA_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadBrandDataFailure implements Action {
  readonly type = LOAD_BRAND_DATA_FAILURE;

  constructor(public payload: any) {}
}

export class LoadUserBrandDataSuccess implements Action {
  readonly type = LOAD_USER_BRAND_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadUserBrandDataFailure implements Action {
  readonly type = LOAD_USER_BRAND_DATA_FAILURE;
  constructor(public payload: any) {}
}

export class UpdatePropertyServiceUrl implements Action {
  readonly type = UPDATE_PROPERTY_SERVICE_URL;
  constructor(public payload: string) {}
}

export type BrandActions =
  | LoadBrandDataAction
  | LoadBrandDataSuccess
  | LoadBrandDataFailure
  | LoadUserBrandDataSuccess
  | LoadUserBrandDataFailure
  | UpdatePropertyServiceUrl;

import { AutopayPredictActionTypes, AutopayPredictActions } from './autopay-predict.actions';
import { get as _get, flatMap as _flatMap } from 'lodash';
import { ApiStatus } from '@amfam/shared/models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface AutopayPredictNotificationsState {
  billAccountNumber: string;
  error?: ApiStatus | null;
  success?: ApiStatus | null;
  loading: boolean;
  hasError?: boolean;
}

export interface NotificationState extends EntityState<AutopayPredictNotificationsState> {
  loading: boolean;
  error: ApiStatus | null;
}

export const adapter: EntityAdapter<AutopayPredictNotificationsState> = createEntityAdapter<
  AutopayPredictNotificationsState
>({
  selectId: (autopayPredictNotificationsState: AutopayPredictNotificationsState) =>
    autopayPredictNotificationsState.billAccountNumber,
  sortComparer: false
});

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: AutopayPredictActions
): NotificationState {
  switch (action.type) {
    case AutopayPredictActionTypes.GetAutopayPredict:
    case AutopayPredictActionTypes.EditAutopayPredict: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber', action.payload),
          loading: true,
          error: null,
          success: null
        },
        state
      );
    }

    case AutopayPredictActionTypes.ClearAutopayPredict: {
      return initialState;
    }

    case AutopayPredictActionTypes.GetAutopayPredictSuccess: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.autoPayRules[0].billAccountNumber'),
          loading: false,
          error: null,
          success: action.payload.status
        },
        state
      );
    }

    case AutopayPredictActionTypes.GetAutopayPredictFail: {
      return adapter.upsertOne(
        {
          billAccountNumber: action.payload.billAccountNumber,
          loading: false,
          error: _get(action, 'payload.error.status'),
          success: null
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

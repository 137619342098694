import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';

import {
  PendingEmailVerification,
  ProfileActions,
  profileQuery,
  ProfileUtilService
} from '@amfam/profile/data-access';
import { PartyEmail, UsageCode } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { DsModalService } from '@amfam/ui-kit';
import { get as _get } from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'manage-email-address',
  templateUrl: './manage-email-address.component.html',
  styleUrls: ['./manage-email-address.component.scss']
})
export class ManageEmailAddressComponent implements OnInit, OnDestroy {
  emails: PartyEmail[];
  primaryEmail: PartyEmail;
  primaryEmailContactMethodUsage: UsageCode;
  pendingEmailId: string;
  otherEditActive = false;
  representative: 'primaryIndicator' = 'primaryIndicator';
  emailToDelete: PartyEmail;
  modalId = 'deleteEmail';
  emailVerificationPending: PendingEmailVerification;
  @Input() makePrimaryEmailAddDeleteBtnToggleDisplay: boolean;
  @Output() changeEmailEmitEvent: EventEmitter<string> = new EventEmitter();
  @Input() isShellAccount: boolean;
  @Input() shellEmail: string;
  @Output() resendEmailEmitEvent: EventEmitter<any> = new EventEmitter();
  @Input() isDuplicateEmail: boolean;
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<any>,
    private modalService: DsModalService,
    public roleService: ImpersonateRolesService,
    private profileUtilService: ProfileUtilService
  ) {}

  ngOnInit() {
    combineLatest(
      this.store.select(profileQuery.getEmailVerificationPending),
      this.store.select(profileQuery.getUpdatedMyAccountEmailAddress),
      this.store.select(userQuery.getMyAccountEmail),
      this.store.select(userQuery.getEmails),
      this.store.select(profileQuery.getProfileLoading),
      (emailVerificationPending, updatedMyAccountEmailAddress, myAccountEmail, emails, loading) => {
        return {
          emailVerificationPending: emailVerificationPending,
          updatedMyAccountEmailAddress: updatedMyAccountEmailAddress,
          myAccountEmail: myAccountEmail,
          emails: emails,
          loading: loading
        };
      }
    )
      .pipe(
        takeUntil(this.stop$),
        filter(state => !state.loading)
      )
      .subscribe(state => {
        this.emailVerificationPending = state.emailVerificationPending;
        this.emails = state.emails;
        // Show myAccount email as primary in the list
        if (state.updatedMyAccountEmailAddress) {
          /* This updated myAccount email address is obtained when DA api call is success.
           We use this to show user that this is primary on the go as this email's usage
           type is not updated to myAccount until some time.
        */
          this.primaryEmail = this.emails.find(email => {
            return email.emailAddress === state.updatedMyAccountEmailAddress;
          });
        }
        if (!this.primaryEmail && state.myAccountEmail) {
          this.primaryEmail = state.myAccountEmail;
        }
        /* Here we choose which usageCode to display other than myaccount/edelivery and if there is no
            alternate usageCode, we display 'myaccount'.
         */
        if (_get(this.primaryEmail, 'contactMethodUsages')) {
          this.primaryEmailContactMethodUsage = this.primaryEmail.contactMethodUsages.find(
            usageCode => {
              return usageCode.typeOfUsageCode.toLowerCase() === 'myaccount';
            }
          );
        }

        // Put primary email on the top
        if (this.primaryEmail && this.emails.length) {
          this.emails = this.emails.filter(email => {
            return _get(email, 'emailId') !== _get(this.primaryEmail, 'emailId');
          });
          this.emails.unshift(this.primaryEmail);
        }

        this.pendingEmailId = '';
        let pendingEmail: PartyEmail = null;
        if (this.emailVerificationPending.pending) {
          if (this.emailVerificationPending.newEmail.emailId !== undefined) {
            //  this.pendingEmailId = this.emailVerificationPending.newEmail.emailId;
            pendingEmail = this.emailVerificationPending.newEmail;
          } else {
            pendingEmail = this.emails.find(
              email =>
                JSON.stringify(email.emailAddress) ===
                JSON.stringify(this.emailVerificationPending.newEmail)
            );
          }
          this.pendingEmailId = pendingEmail.emailId;
          // Put pending verification email next to primary
          if (this.emails.length) {
            this.emails = this.emails.filter(
              email => _get(email, 'emailId') !== this.pendingEmailId
            );
            this.emails.splice(1, 0, pendingEmail);
          }
        }
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  onDeleteConfirm(email: PartyEmail) {
    this.emailToDelete = email;
    this.modalService.open(this.modalId);
  }

  onDelete(emailId: string): void {
    // start the spinner
    this.store.dispatch(new ProfileActions.MakeRequestAction());
    // send the request to delete the email
    this.store.dispatch(
      new ProfileActions.DeleteContactEntryAction({
        data: emailId,
        methodType: 'emails'
      })
    );
    this.closeModal();
  }

  onMakePrimary(selectedEmail: PartyEmail) {
    const data = this.profileUtilService.getOldAndNewPrimaries(this.primaryEmail, selectedEmail);
    this.store.dispatch(new ProfileActions.GetValidationStatusForEmailAction(data));
  }

  sendAnotherVerificationEmail(email: PartyEmail) {
    // start the spinner
    this.store.dispatch(new ProfileActions.MakeRequestAction());
    // Send request to verify email
    this.store.dispatch(
      new ProfileActions.ValidateEmailAction({
        newEmail: email,
        oldEmail: this.primaryEmail
      })
    );
  }

  resendEmail() {
    this.resendEmailEmitEvent.emit();
  }
  changeEmail(email) {
    this.changeEmailEmitEvent.emit(email);
  }

  onEdit(val): void {
    this.otherEditActive = val;
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }

  onSave({ addr, type, typeDesc, makePrimary }): void {
    const newEmail = new PartyEmail(addr, type, typeDesc, makePrimary);
    // start the spinner
    this.store.dispatch(new ProfileActions.MakeRequestAction());
    // send the request to add the new email
    this.store.dispatch(
      new ProfileActions.AddContactEntryAction({
        data: newEmail,
        methodType: 'emails'
      })
    );
  }
}

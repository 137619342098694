import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from as observableFrom, of as observableOf, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { PolicyDocumentService } from '../../services/policy-documents.service';
import { DocumentsActionTypes, LoadDocuments, LoadDocumentsSuccess, fromDocumentsActions } from './documents.actions';

import { PolicyDocument, PolicySummary } from '@amfam/policy/models';
import { UtilService } from '@amfam/shared/utility/shared-services/src';
import { PolicyService } from '../../services/policy.service';
import { SummariesAction, SummariesActionTypes } from '../summaries/summaries.actions';

@Injectable()
export class DocumentsEffects {
  // TODO: AS - This pattern with datapersistence is not working and i do not have time currently to fix it
  // @Effect() loadPolicyDocuments$ = this.dataPersistence.fetch(DocumentsActionTypes.LoadDocuments, {
  //   run: (action: LoadDocuments, state: PolicyDocumentsPartialState) => {
  //     return from(action.payload).pipe(
  //       filter(policySummary =>
  //         this.policyDocumentService.isPolicyTypeSupported(policySummary.generalizedProductType)
  //       ),
  //       mergeMap(policySummary => {
  //         return this.policyDocumentService.getDocumentList(
  //           policySummary.policyNumber,
  //           policySummary.sourceSystem,
  //           policySummary.generalizedProductType
  //         );
  //       }),
  //       mergeMap(policyDocuments => policyDocuments),
  //       toArray(),
  //       map(
  //         (policyDocumentList: PolicyDocument[]) =>
  //           new fromDocumentsActions.LoadDocumentsSuccess(policyDocumentList)
  //       )
  //     );
  //   },

  //   onError: (action: LoadDocuments, error) => {
  //     return new fromDocumentsActions.LoadDocumentsError(error);
  //   }
  // });

  // constructor(
  //   private actions$: Actions,
  //   private dataPersistence: DataPersistence<PolicyDocumentsPartialState>,
  //   private policyDocumentService: PolicyDocumentService
  // ) {}

  
  policySummariesLoaded$ = createEffect(() => this.action$.pipe(
    ofType(SummariesActionTypes.SummariesLoadSuccess),
    map((action: SummariesAction) => action.payload),
    map(payload => new fromDocumentsActions.LoadDocuments(payload))
  ));

  
  loadPolicyDocuments$ = createEffect(() => this.action$.pipe(
    ofType(DocumentsActionTypes.LoadDocuments),
    map((action: LoadDocuments) => action.payload),
    switchMap((payload: PolicySummary[]) =>
      observableFrom(
        payload.filter(policySummary =>
          this.policyservice.isPolicyTypeSupported(policySummary.generalizedProductType)
        )
      ).pipe(
        mergeMap(policySummary => {
          return this.policyDocumentService.getDocumentList(
            policySummary.policyNumber,
            policySummary.sourceSystem,
            policySummary.generalizedProductType
          ).pipe(catchError(() => of([])));
        }),
        map(policyDocuments => (policyDocuments as PolicyDocument[]).map(doc => ({...doc,policyNumber: this.utilService.onlyAlphanumeric(doc.policyNumber) }))),
        catchError(() => of([]))
      ),
    ),
    map(policyDocuments => new fromDocumentsActions.LoadDocumentsSuccess(policyDocuments)),
    catchError(error => observableOf(new fromDocumentsActions.LoadDocumentsError(error)))
  ));

  
  policyDocumentsLoaded$ = createEffect(() => this.action$.pipe(
    ofType(DocumentsActionTypes.LoadDocumentsSuccess, DocumentsActionTypes.LoadDocumentsError),
    map((action: LoadDocumentsSuccess) => action.payload),
    map(policyDocuments => new fromDocumentsActions.LoadDocumentsComplete())
  ));

  constructor(
    private policyDocumentService: PolicyDocumentService,
    private policyservice: PolicyService,
    private action$: Actions,
    private utilService: UtilService
  ) {}
}

import { of as observableOf, Observable, combineLatest, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

import { Agent } from '@amfam/shared/utility/agent';

import {
  CommunicationService,
  InternalEmail,
  TemplatedEmail,
  Addressee,
  MessageDetail
} from '@amfam/shared/utility/shared-services';

import { ReportClaimInternalEmail, ReportClaimCustomerEmail } from '../models';
import { PrettyPolicyNum } from '@amfam/policy/pipes';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { get as _get } from 'lodash';

import { ClaimConfirmation } from '../models/claim-confirmation';

@Injectable({ providedIn: 'root' })
export class ClaimEmailService {
  constructor(private communicationService: CommunicationService, private config: ConfigService) {}

  public generateInternalEmails(confirmation: ClaimConfirmation, isPartner): Observable<any> {
    const model: ReportClaimInternalEmail = this.buildInternalEmail(confirmation);
    const email: InternalEmail = new InternalEmail();
    const emailAddress = this.getProducerEmailAddress(model.producer, isPartner);
    if (!emailAddress) {
      return observableOf(null);
    }
    const name = this.getProducerName(model.producer);
    email.messageFrom = new Addressee('report_claim@amfam.com', 'File a Claim');
    email.messageToList.push(new Addressee(emailAddress, name));
    email.messageSubject = 'American Family Insurance File a Claim';
    email.messageContent = this.generateInternalEmailContent(model);

    return this.communicationService.sendInternalEmail(email);
  }

  private generateInternalEmailContent(model: ReportClaimInternalEmail): string {
    const name = this.getProducerName(model.producer);
    let content = '';
    if (name) {
      content += `Dear ${name},<br><br>`;
    }
    content += `Your customer below has submitted a claim to his/her policy(ies) from My Account.<br><br>`;
    content += `Please contact the customer within two business days to discuss their claim.<br><br>`;
    content += `Thank you,<br>The amfam.com Team`;
    content += `<br><br><hr/><br><br>Report Claim<br>`;
    content += `The following is the claim detail from customer.<br><br>`;
    content += `<table>`;
    content += `<tr><td>Date of Incident</td><td>${model.dateOfIncident}</td></tr>`;
    content += `<tr><td>Time of Incident</td><td>${model.timeOfIncident}</td></tr>`;
    content += `<tr><td>Location of Incident</td><td>${model.locationOfIncident}</td></tr>`;
    content += `<tr><td>Description of Incident</td><td>${model.descriptionOfIncident}</td></tr>`;
    content += `<tr><td>Email</td><td>${model.email}</td></tr>`;
    content += `<tr><td>Phone</td><td>${model.phone}</td></tr>`;
    content += `<tr><td>Policy</td><td>${new PrettyPolicyNum().transform(model.policy)}</td></tr>`;
    content += `</table>`;
    content += `<br><br><hr/>`;

    return content;
  }

  private buildInternalEmail(confirmation: ClaimConfirmation): ReportClaimInternalEmail {
    const claimsIntakeRequest = _get(confirmation, 'request.claimRequest.ClaimsIntakeRequest');
    const policy = _get(confirmation, 'request.policy');
    const date = _get(confirmation, 'request.date');
    const time = _get(confirmation, 'request.time');
    let producer: Agent;
    if (_get(confirmation, 'request.producer') && _get(confirmation, 'request.producer.id')) {
      producer = _get(confirmation, 'request.producer');
    }
    return new ReportClaimInternalEmail(
      policy,
      producer,
      _get(claimsIntakeRequest, 'customer.emails[0].emailAddress'),
      _get(claimsIntakeRequest, 'customer.phones[0].number'),
      date,
      time,
      _get(claimsIntakeRequest, 'incidentDetails.incidentLoc'),
      _get(claimsIntakeRequest, 'incidentDetails.incidentDesc')
    );
  }

  public generateCustomerEmail(
    confirmation: ClaimConfirmation,
    partnerId: string
  ): Observable<any> {
    const model: ReportClaimCustomerEmail = this.buildCustomerEmail(confirmation);
    const messageDetails: MessageDetail[] = this.buildMessageDetails(confirmation);
    const email = new TemplatedEmail(
      'ReportAClaim',
      partnerId,
      'MyAccount',
      'EN',
      model.customerId,
      model.email,
      messageDetails
    );

    return this.communicationService.sendTemplatedEmail(email);
  }

  private buildCustomerEmail(confirmation: ClaimConfirmation): ReportClaimCustomerEmail {
    const email = new ReportClaimCustomerEmail();
    email.customerId = _get(confirmation, 'request.customerId');
    email.email = _get(
      confirmation,
      'request.claimRequest.ClaimsIntakeRequest.customer.emails[0].emailAddress'
    );
    return email;
  }

  private buildMessageDetails(confirmation: ClaimConfirmation): MessageDetail[] {
    const messageDetails: MessageDetail[] = new Array<MessageDetail>();
    const claimsIntakeRequest = _get(confirmation, 'request.claimRequest.ClaimsIntakeRequest');
    messageDetails.push(
      new MessageDetail('FirstName', _get(claimsIntakeRequest, 'customer.firstName'))
    );
    messageDetails.push(
      new MessageDetail(
        'EmailAddress',
        _get(claimsIntakeRequest, 'customer.emails[0].emailAddress')
      )
    );
    if (_get(confirmation, 'request.producer') && _get(confirmation, 'request.producer.id')) {
      messageDetails.push(
        new MessageDetail('AgentID', _get(confirmation, 'request.producer.id').toString())
      );
    }
    messageDetails.push(new MessageDetail('ClaimRefNum', _get(confirmation, 'referenceNumber')));
    messageDetails.push(
      new MessageDetail('ClaimRiskName', _get(claimsIntakeRequest, 'description'))
    );
    messageDetails.push(new MessageDetail('ClaimEventDate', _get(confirmation, 'request.date')));
    messageDetails.push(
      new MessageDetail('ClaimDesc', _get(claimsIntakeRequest, 'incidentDetails.incidentData'))
    );
    return messageDetails;
  }

  private getProducerEmailAddress(producer: Agent, isConnectPartner: boolean) {
    // If Connect Partner, send to Connect mailbox
    if (isConnectPartner) {
      return this.config.get('partnerClaimEmail');
    }
    return _get(producer, 'emails[0]', '');
  }

  private getProducerName(producer: Agent) {
    if (!producer || (!producer.firstName && !producer.lastName)) {
      return '';
    }

    if (!producer.lastName) {
      return producer.firstName;
    }
    return `${producer.firstName} ${producer.lastName}`;
  }
}

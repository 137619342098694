import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ds-billing-card',
  templateUrl: './billing-card.component.html',
  styleUrls: ['./billing-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingCardComponent {
  // DP: New billing card component as of 02/14/2023
  constructor() {}
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ValidationService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-verify-ssn',
  templateUrl: './verify-ssn.component.html'
})
export class VerifySsnComponent implements OnInit {
  @Output() submittedSSNEvent = new EventEmitter();

  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.loadSsnForm();
  }

  // Emits input ssn to parent component
  submitSSN(ssn: string) {
    this.submittedSSNEvent.emit(ssn);
  }

  private loadSsnForm() {
    this.form = this.formBuilder.group({
      ssn: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          ValidationService.ssnLastFourDigitsValidator,
          ValidationService.noAlphabetsAndSpecialChar
        ])
      ]
    });
  }
}

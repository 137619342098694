<div *ngIf="displayBanner" class="container">
  <div class="row">
    <div class="column three app-link">
      <a (click)="dismiss()" class="no-decoration neutral-600-text"
        ><span class="icon icon-times-thick"></span
      ></a>
      <img
        src="//webassets.amfam.com/images/footer/icon_MyAmfam.png"
        class="app-logo margin-left-10"
        alt="AmFam App Logo"
      />
    </div>
    <div class="column six">
      <h4
        class="app-heading margin-top-10"
        content
        [contentPath]="'shared.mobileAppBannerHeading'"
      ></h4>
      <p
        class="text-small neutral-600-text"
        content
        [contentPath]="'shared.mobileAppBannerText'"
      ></p>
    </div>
    <div class="column three text-center app-link">
      <a
        (click)="sendAnalytics()"
        [href]="appLink"
        target="_blank"
        class="margin-left-20"
        content
        [contentPath]="'shared.mobileAppBannerLink'"
      ></a>
    </div>
  </div>
</div>

<div class="ds-table-header-cell-content">
  <span *ngIf="!column.headerTemplate" class="ds-table-header-cell-wrapper">
    <span class="ds-table-header-cell-label" [innerHTML]="column.name"> </span>
  </span>
  <ng-template
    *ngIf="column.headerTemplate"
    [ngTemplateOutlet]="column.headerTemplate"
    [ngTemplateOutletContext]="{
      column: column
    }"
  >
  </ng-template>
</div>

import { Injectable } from '@angular/core';
import { timer as observableTimer, Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AuthService } from '../../core/auth/auth.service';
import { NavigationService } from '@amfam/shared/utility/navigation';
import { SessionEventsService } from '@amfam/shared/utility/shared-services';

@Injectable()
export class SessionActivityService {
  private isUserActive = false; // user has registered activity since the last keep-alive
  private timersStarted = false; // activity polling and warning timers started

  // Keep a handle on the user which is updated at login, refresh and any API activity
  private userSub: Subscription;

  // A handle on the interval that tracks when to send a keep alive
  private activityPollSub: Subscription; // timer for checking user activity
  private popupSub: Subscription; // timer for warning popup
  private timeoutSub: Subscription; // timer for expire session

  private sessionWarningDelay = 720000; // 12 minutes
  private activityPollingDelay = 60000; // 1 minute
  private expireSessionDelay = 120000; // 2 minutes

  // Subjects used by session-timeout component to manage visibility of warning
  private _showSessionWarning$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * The currentUser$ auth subscription is responsible for starting the
   *
   * session tracking timers and polling timers when the user logs in as
   * well as responsible for tearing them down when the user logs out manually
   * or by the system. As the currentUser is updated by API activity or the
   */
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private sessionEventsService: SessionEventsService
  ) {
    this.userSub = authService.currentUser$.subscribe(currentUser => {
      if (currentUser && currentUser.trackId && currentUser.trackId !== '') {
        this.startTimers();
      }
    });
    this.sessionEventsService.userActivity$.subscribe(() => this.registerUserActivity());
  }

  /**
   * User activity handlers are wired in the app root to detect
   * when the user is interacting with the application.
   */
  registerUserActivity() {
    this.isUserActive = true;
  }

  /**
   * Observable for communicating when the session timeout warning should be shown / hidden
   */
  get showSessionWarning$() {
    return this._showSessionWarning$.asObservable();
  }

  /**
   * Happens if user clicks 'I'm done' during session expiration warning. Logs the user out and
   * cancels the sessionExpirationWarning and activity polling timers. Routes to /login page.
   */
  userDone() {
    this._showSessionWarning$.next(false); // closes the warning
    this.logout('/login');
  }

  /**
   * Accept user input from the session expiration warning dialogue to continue with the user's
   * session. This is seperate from the private sendKeepAlive() method in that the private method
   * also handles the starting of the primary sessionExpirationWarning & userActivityPolling timers
   */
  keepAlive() {
    this._showSessionWarning$.next(false); // closes the warning
    this.registerUserActivity();
    this.sendKeepAlive();
  }

  /**
   * pop the session warning notification if the user is inactive
   */
  private handleSessionTimeoutWarning() {
    if (this.isUserActive) {
      this.sendKeepAlive();
    } else {
      this.cancelTimers();
      const popupTimer = observableTimer(this.expireSessionDelay);
      this.popupSub = popupTimer.subscribe(f => this.expireSession());
      this._showSessionWarning$.next(true); // shows the warning
    }
  }

  /**
   * called when the user doesn't click I'm still here.
   * This will logout the user after routing to /session-expired
   */
  private expireSession() {
    this._showSessionWarning$.next(false); // closes the warning
    this.logout('/session-expired');
  }

  /**
   * Call refresh to update the token. Actual implementation details
   * currently unknown. The session expiration warning needs to be turned back on in
   * addition to the periodic polling of user activity.
   */
  private sendKeepAlive() {
    if (this.isUserActive) {
      this.cancelTimers();
      this.authService.refresh().subscribe(
        res => {
          this.isUserActive = false;
        },
        err => {
          this.isUserActive = false;
        }
      );
    }
  }

  /**
   * logout and reload the app
   */
  private logout(path: string) {
    this.cancelTimers();
    const redirect = this.navigationService.getCommands(path);
    this.authService
      .logout()
      .pipe(take(1))
      .subscribe(
        res => {
          window.location.href = redirect.join('/');
        },
        err => {
          window.location.href = redirect.join('/');
        }
      );
  }

  /**
   * Start the timers to show the session expiration warning and check user activity.
   *
   * THIS SHOULD ONLY be called from within the currentUser$ Subscription.
   */
  private startTimers() {
    if (!this.timersStarted) {
      this.isUserActive = false;
      this.timersStarted = true;
      // start timer forsession expiration warning.
      const timeoutTimer = observableTimer(this.sessionWarningDelay);
      this.timeoutSub = timeoutTimer.subscribe(() => this.handleSessionTimeoutWarning());
      // start timer for checking user activity
      const activityPollTimer = observableTimer(
        this.activityPollingDelay,
        this.activityPollingDelay
      );
      this.activityPollSub = activityPollTimer.subscribe(() => this.sendKeepAlive());
    }
  }

  /**
   * Cancel all the timers
   */
  private cancelTimers() {
    this.timersStarted = false;
    // Cancel the timer that checks for user activity
    if (this.activityPollSub !== undefined) {
      this.activityPollSub.unsubscribe();
    }
    // Cancel the timer for the session expiration warning
    if (this.popupSub !== undefined) {
      this.popupSub.unsubscribe();
    }
    // Cancel the timer for session expiration
    if (this.timeoutSub !== undefined) {
      this.timeoutSub.unsubscribe();
    }
  }
}

import { ApiStatus, PartyEmail } from '@amfam/shared/models';

export interface PendingEmailVerification {
  pending: boolean;
  oldEmail: PartyEmail;
  newEmail: PartyEmail;
  tokenExpiry?: boolean;
}

export interface UpdateContactMethodPayload {
  data: any;
  methodType: string;
}

export interface EmailProfile {
  // For type 'any' I was getting null values. I don't know exactly what they are.
  confirmed: boolean;
  createDate: string;
  createProgramName: string;
  createUserId: string;
  displayName: any;
  emailAddress: string;
  id: number;
  profileLastConfirmed: string;
  profileLastUpdated: string;
  updateDate: any;
  updatedProgramName: any;
  updatedUserId: any;
  waid: string;
}

export interface EmailValidationResponse {
  emailProfile: EmailProfile;
  status: ApiStatus;
}

export interface EmailPayloadToGetValidationStatus {
  newEntry: PartyEmail;
  oldEntry: PartyEmail | undefined;
  type: string;
}

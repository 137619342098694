import { ApiStatus } from '@amfam/shared/models';

import { ClaimFnolAction, ClaimFnolActionTypes } from './claim-fnol.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { omit as _omit, get as _get, keyBy as _keyBy, has as _has } from 'lodash';
import { initialCreateDraftClaim, DraftClaim, UNAVAILABLE_CLAIM } from '../models';

import { subDays, isAfter, endOfDay } from 'date-fns';

import { Update } from '@ngrx/entity/src/models';

export const CLAIMFNOL_FEATURE_KEY = 'claimFnol';

export interface ClaimFnolState extends EntityState<DraftClaim> {
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
  error: string;
  selectedEntityId: string;
  status: ApiStatus;
}

export const adapter: EntityAdapter<DraftClaim> = createEntityAdapter<DraftClaim>({
  selectId: (claimPayload: DraftClaim) => {
    return claimPayload.claimNumber === '' ? UNAVAILABLE_CLAIM : claimPayload.claimNumber;
  },
  sortComparer: false
});

export const initialState: ClaimFnolState = adapter.getInitialState({
  loaded: false,
  loading: false,
  hasError: false,
  error: null,
  selectedEntityId: null,
  status: null
});

export function claimFnolReducer(state = initialState, action: ClaimFnolAction): ClaimFnolState {
  let newState = null;
  let draftClaimNumber = null;
  let newDraft: DraftClaim;
  let oldDraft: DraftClaim = null; // claim with old data
  let updatedDraft: DraftClaim = null; // claim with new data attached
  let draftUpdate: Update<DraftClaim> = null; // ngrx type Update<Claim> used with entity adapter

  switch (action.type) {
    // SUMMARY CALLS
    case ClaimFnolActionTypes.LoadDraftClaims:
      return Object.assign({}, state, {
        loading: true,
        hasError: false
      });

    case ClaimFnolActionTypes.LoadDraftClaimsSuccess:
      const fnols = getFnols(_get(action, 'payload', []));
      newState = Object.assign({}, state, {
        hasError: false,
        loading: false
      });
      return adapter.setAll(fnols, newState);

    case ClaimFnolActionTypes.LoadDraftClaimsFail:
      return Object.assign({}, state, {
        hasError: true,
        loading: false,
        status: _get(action, 'payload.status', {})
      });

    // DETAIL CALLS
    case ClaimFnolActionTypes.LoadDraftClaimDetail:
      draftClaimNumber = action.payload.replace(/-/g, '');
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: true,
        detailsLoaded: false
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case ClaimFnolActionTypes.LoadDraftClaimDetailSuccess:
      draftClaimNumber = action.payload.claimNumber.replace(/-/g, '');
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: false,
        detailsLoaded: true
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      newState = adapter.removeOne(UNAVAILABLE_CLAIM, state);
      return adapter.updateOne(draftUpdate, newState);

    case ClaimFnolActionTypes.LoadDraftClaimDetailFail:
      draftClaimNumber = action.payload.claimNumber.replace(/-/g, '');
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: false,
        detailsLoaded: false,
        hasError: true,
        status: _get(action, 'payload.status', {})
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    // Sets the draft claim number to the root claimNumber element
    case ClaimFnolActionTypes.ResumeDraftClaim:
      return Object.assign({}, state, {
        selectedEntityId: action.payload
      });

    // Store the selected risk for a new, uncreated draft claim (no api interaction)
    case ClaimFnolActionTypes.SaveDraftClaim:
      newDraft = Object.assign({}, initialCreateDraftClaim, action.payload, {
        claimNumber: ''
      });
      newState = Object.assign({}, state, {
        selectedEntityId: UNAVAILABLE_CLAIM
      });
      return adapter.upsertOne(newDraft, newState);

    /**
     * Assigning the current claim object to entities[UNAVAILABLE_CLAIM] to persist the data in store collected from
     * report-claim-loss-cause.component & report-claim-contact-info.component when editing date of loss from repot-claim-summary.component.
     * This temporarly stores the data collected until a new entity key is returned by the CREATE_DRAFT API call.
     * CREATE_DRAFT_SUCCESS will then take this data and remap it to whatever claim number comes back from the create API call.
     *
     * TODO: action.payload needs to have the full claim object.
     */
    case ClaimFnolActionTypes.CreateDraftClaim:
      updatedDraft = Object.assign({}, action.payload, {
        loading: true,
        hasError: false,
        status: {}
      });
      newState = Object.assign({}, state, {
        selectedEntityId: UNAVAILABLE_CLAIM
      });
      draftUpdate = createDraftUpdate(UNAVAILABLE_CLAIM, updatedDraft);
      return adapter.updateOne(draftUpdate, newState);

    /**
     * Map unavailable claimNumber data to claimNumber and delete unavailable claimNumber.
     */
    case ClaimFnolActionTypes.CreateDraftClaimSuccess:
      draftClaimNumber = action.payload.claimNumber.replace(/-/g, '');
      oldDraft = state.entities[UNAVAILABLE_CLAIM];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        claimNumber: draftClaimNumber,
        loading: false
      });
      newState = Object.assign({}, state, {
        selectedEntityId: draftClaimNumber
      });
      newState = adapter.removeOne(UNAVAILABLE_CLAIM, newState);
      return adapter.addOne(updatedDraft, newState);

    case ClaimFnolActionTypes.CreateDraftClaimFail:
      oldDraft = state.entities[UNAVAILABLE_CLAIM];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: false,
        hasError: true,
        status: _get(action, 'payload', {})
      });
      draftUpdate = createDraftUpdate(UNAVAILABLE_CLAIM, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    // Update an existing draft claim (will always have a claim number)
    // TODO - determine if the update API call will ever return a different claim number.
    // If it does, we need to park the data in UNAVAILABLE like we do for CREATE_DRAFT
    case ClaimFnolActionTypes.UpdateDraftClaim:
      draftClaimNumber = action.payload.fnol.claimNumber.replace(/-/g, '');
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, action.payload.fnol, {
        loading: true,
        hasError: false,
        status: {}
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case ClaimFnolActionTypes.UpdateDraftClaimSuccess:
      draftClaimNumber = action.payload.claimNumber.replace(/-/g, '');
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: false
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case ClaimFnolActionTypes.UpdateDraftClaimFail:
      draftClaimNumber = action.payload.claimNumber.replace(/-/g, '');
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: false,
        hasError: true,
        status: _get(action, 'payload.status', {})
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case ClaimFnolActionTypes.SubmitDraftClaim:
      draftClaimNumber = action.payload.fnol.claimNumber.replace(/-/g, '');
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: true,
        hasError: false,
        status: {}
      });

      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    // The submit draft success effect will handle routing on success, so the reducer just cleans up the element.
    case ClaimFnolActionTypes.SubmitDraftClaimSuccess:
      draftClaimNumber = action.payload.fnolClaimNumber;
      newState = Object.assign({}, state, {
        selectedEntityId: ''
      });
      return adapter.removeOne(draftClaimNumber, newState);

    case ClaimFnolActionTypes.SubmitDraftClaimFail:
      draftClaimNumber = action.payload.claimNumber.replace(/-/g, '');
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case ClaimFnolActionTypes.DeleteDraftClaim:
      draftClaimNumber = action.payload.claimNumber;
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: true,
        hasError: false,
        status: {}
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    // The delete effect will handle routing on success, so the reducer just cleans up the element
    case ClaimFnolActionTypes.DeleteDraftClaimSuccess:
      draftClaimNumber = action.payload.claimNumber;
      newState = Object.assign({}, state, {
        selectedEntityId:
          action.payload.claimNumber === state.selectedEntityId ? '' : state.selectedEntityId
      });
      return adapter.removeOne(draftClaimNumber, newState);

    case ClaimFnolActionTypes.DeleteDraftClaimFail:
      draftClaimNumber = action.payload.claimNumber;
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case ClaimFnolActionTypes.ClearSelectedEntity:
      newState = Object.assign({}, state, {
        selectedEntityId: ''
      });
      return adapter.removeOne(UNAVAILABLE_CLAIM, newState);

    default:
      return state;
  }
}

function createDraftUpdate(claimNumber: string, updatedEntity: DraftClaim): Update<DraftClaim> {
  return {
    id: claimNumber,
    changes: updatedEntity
  };
}

// TODO move adapter
function getFnols(payload) {
  // Filter any FNOLs that are older than 30 days ago
  return payload.filter(fnol => {
    let include = false;
    if (_has(fnol, 'openDate')) {
      // Determine if the openDate is within the last 30 days.
      // Subtract 31 days from today, set Date obj to the end of that day. Make sure the openDate is after that
      const lowerBounds = endOfDay(subDays(new Date(), 31));
      if (isAfter(fnol.openDate, lowerBounds)) {
        include = true;
      }
    }
    return include;
  });
}

// get the selectors
const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

// select the array of claim ids (claim number)
export const selectEntityIds = selectIds;

// select the dictionary of claim entities
export const selectClaimFnolEntities = selectEntities;

// select the array of claims
export const selectAllClaimFnols = selectAll;

<h3 class="margin-bottom-20 pad-top-30" content [contentPath]="'profile.headerCopy'"></h3>

<div class="pad-left-40">
  <div class="border-dashed">
    <div class="row">
      <div class="pad-top-10 pad-bottom-10 items-center justify-space-between sm-flex">
        <div *ngIf="!isEditing" class="sm-margin-right-10 flex-auto">
          <b>{{ nameFormat }}</b>
        </div>
        <button
          ds-button
          *ngIf="!isEditing"
          class="-outline-white margin-top-10 sm-margin-top-0 flex-constrained"
          type="button"
          (click)="editing()"
          [disabled]="(roleService.isAuthorized('edit_business_name') | async) === false"
        >
          <span content [contentPath]="'shared.changeBtn'"></span>
        </button>
      </div>
    </div>
    <form
      *ngIf="isEditing"
      [formGroup]="changeNamesForm"
      class="row pad-top-10 pad-bottom-10"
      (ngSubmit)="save()"
    >
      <div class="column twelve md-four sm-pad-right-10 pad-bottom-10 sm-pad-bottom-0">
        <ds-form-control-container
          [labelText]="'First Name'"
          [control]="changeNamesForm.controls['firstName']"
        >
          <input
            type="text"
            id="firstName"
            class="fill"
            formControlName="firstName"
            maxlength="30"
          />
          <ds-form-control-message
            [control]="changeNamesForm.controls['firstName']"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
      <div class="column twelve md-four">
        <ds-form-control-container
          [labelText]="'Last Name'"
          [control]="changeNamesForm.controls['lastName']"
        >
          <input type="text" id="lastName" class="fill" formControlName="lastName" maxlength="30" />
          <ds-form-control-message
            [control]="changeNamesForm.controls['lastName']"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
      <div class="text-right">
        <button
          ds-button
          class="-white bg-transparent cancel a"
          content
          [contentPath]="'shared.cancel'"
          [disabled]=""
          (click)="cancel()"
        ></button>
        <button
          ds-button
          type="submit"
          content
          [contentPath]="'shared.confirmBtn'"
          [disabled]="!changeNamesForm.valid || !changeNamesForm.dirty"
        ></button>
      </div>
    </form>
  </div>
</div>

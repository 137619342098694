export const VehicleDamageConstants = [
  {
    name: 'driver-side-front-wheel',
    id: '10'
  },
  {
    name: 'driver-side-fender',
    id: 'LF'
  },
  {
    name: 'driver-side-front-door',
    id: '20'
  },
  {
    name: 'driver-side-rear-door',
    id: '30'
  },
  {
    name: 'driver-side-quarter-panel',
    id: 'LQ'
  },
  {
    name: 'driver-side-rear-wheel',
    id: '40'
  }, {
    name: 'passenger-side-front-wheel',
    id: '12'
  },
  {
    name: 'passenger-side-fender',
    id: 'RF'
  },
  {
    name: 'passenger-side-front-door',
    id: '22'
  },
  {
    name: 'passenger-side-rear-door',
    id: '32'
  },
  {
    name: 'passenger-side-quarter-panel',
    id: 'RQ'
  },
  {
    name: 'passenger-side-rear-wheel',
    id: '42'
  },
  {
    name: 'front-bumper',
    id: '01'
  },
  {
    name: 'hood',
    id: '11'
  },
  {
    name: 'roof',
    id: '21'
  },
  {
    name: 'trunk',
    id: '41'
  },
  {
    name: 'rear-bumper',
    id: '51'
  },
  {
    name: 'front-windshield',
    id: 'FW'
  },
  {
    name: 'rear-windshield',
    id: 'BW'
  }
]

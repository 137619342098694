export enum FnolQuestionTypes {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  FREE_FORM = 'FREE_FORM',
  VEHICLE_DAMAGE_PICKER = 'VEHICLE_DAMAGE_PICKER',
  INSURED_CONTACT_TYPE = 'INSURED_CONTACT_TYPE',
  THIRD_PARTY_CONTACT_FORM = 'THIRD_PARTY_CONTACT_FORM',
  CLAIMANT_CONTACT_TYPE = 'CLAIMANT_CONTACT_TYPE',
  SUMMARY = 'summary',
  GLASS_END = 'redirect_to_glass_view',
  ERS_END = 'redirect_to_roadside_view',
  END = 'end'
}

<ds-add-payment-method-quicklinks
  [authorizedToAddPaymentMethod]="authorizedToAddPaymentMethod$ | async"
  [authorizedToEditPaymentMethod]="authorizedToEditPaymentMethod$ | async"
  [displayAutoPayDiscountMessage]="false"
  [displayDiscountWarning]="false"
  [displayPaymentExpiredError]="false"
  (addCheckingAccountEvent)="addCheckingAccount()"
  (addCreditDebitEvent)="addCreditDebit()"
  (addSavingsAccountEvent)="addSavingsAccount()"
>
</ds-add-payment-method-quicklinks>

// @todo - jjc: change this to use options, see: DatepickerOptionsModel for an example

import { Params } from '@angular/router';

export class QuickLink {
  onClick: (event: MouseEvent) => EventReturnType = () => {};

  constructor(
    public text: string,
    public href?: string,
    public target?: string,
    public queryParams?: Params,
    public dataCy?: string
  ) {}

  // @todo - jjc: a11y: Add keyboard event binding to this so it's accessible via keyboard interaction.
  withOnClick(onClick: (event: MouseEvent) => EventReturnType): QuickLink {
    this.onClick = onClick;
    return this;
  }
}

type EventReturnType = boolean | void;

<form [formGroup]="loginForm" (ngSubmit)="loginUser()" autocomplete="off" data-cy="loginFormForm">
  <div class="row">
    <div class="column twelve margin-top-10">
      <if-feature-enabled flag="save_user_id" hideIfEnabled="true">
        <ds-form-control-container
          [labelText]="'Email or User ID'"
          [labelClass]="'no-float'"
          [control]="username"
          inputWrapperClass="input a11y"
        >
          <input
            type="text"
            id="username"
            class="fill"
            formControlName="username"
            autocomplete="off"
            data-cy="loginFormUsername"
            [focus]="true"
          />
          <ds-form-control-message [control]="username"></ds-form-control-message>
        </ds-form-control-container>
      </if-feature-enabled>
      <if-feature-enabled flag="save_user_id">
        <ds-form-control-container
          [labelText]="'Email or User ID'"
          [labelClass]="'no-float'"
          [control]="username"
          inputWrapperClass="input a11y"
        >
          <input
            type="text"
            id="username"
            class="fill"
            formControlName="username"
            autocomplete="off"
            data-cy="loginFormUsername"
            [focus]="true"
          />
          <ds-form-control-message [control]="username"></ds-form-control-message>
        </ds-form-control-container>
      </if-feature-enabled>
    </div>
  </div>

  <div class="row">
    <div class="column twelve margin-top-10">
      <show-hide>
        <ds-form-control-container
          [labelText]="'Password'"
          [labelClass]="'no-float'"
          [control]="password"
          inputWrapperClass="input a11y"
        >
          <input
            show-hide-input
            type="password"
            id="password"
            class="icon fill"
            formControlName="password"
            autocomplete="off"
            data-cy="loginFormPassword"
          />
          <!-- TODO - Investigate usage of autocomplete="new-password" for accessibility -->
          <ds-form-control-message [control]="password"></ds-form-control-message>
        </ds-form-control-container>
      </show-hide>
    </div>
  </div>
  <!-- Use the feature flag wrapper in favour of feature directive as it is testable -->
  <if-feature-enabled flag="save_user_id">
    <div *ngIf="!isEmailValidation" class="row">
      <div class="column twelve margin-top-10">
        <input
          type="checkbox"
          class="checkbox"
          id="rememberMe"
          formControlName="rememberMe"
          (change)="rememberMeToggle()"
          data-cy="loginFormRememberMeCheckbox"
        />
        <label for="rememberMe" class="checkbox-label" data-cy="loginFormRememberMeCheckboxLabel"
          >Remember Me</label
        >
      </div>
    </div>
  </if-feature-enabled>

  <div class="row caption error-600-text">
    <div class="column twelve">
      <p
        class="margin-vertical-10"
        *ngIf="responseSubcode === authErrors.INVALID_CREDENTIALS && loginAttempts <= 2"
      >
        <span
          *ngIf="loginType === 'email'"
          content
          [contentPath]="'auth.loginWithEmailIncorrectText'"
        ></span>
        <span
          *ngIf="loginType === 'userid'"
          content
          [contentPath]="'auth.loginWithUserIdIncorrectText'"
        ></span>
        <span
          *ngIf="loginType === 'remember me'"
          content
          [contentPath]="'auth.loginIncorrectGenericText'"
        ></span>
      </p>
      <p
        class="margin-vertical-10"
        *ngIf="responseSubcode !== authErrors.ACCOUNT_LOCKED && loginAttempts > 2"
        content
        [contentPath]="'auth.loginWithEmailLockWarning'"
      ></p>
      <p class="margin-vertical-10" *ngIf="responseSubcode === authErrors.ACCOUNT_LOCKED">
        <span content [contentPath]="'auth.loginAccountLocked1'"></span>
      </p>
      <p class="margin-vertical-10" *ngIf="responseSubcode === 4230002">
        <span content [contentPath]="'auth.loginAccountLockedAlt'"></span>
        <span dsPartnerContent [brandContent]="'getPrettyCusCareNumber'"></span> <span>.</span>
      </p>
    </div>
  </div>

  <div *ngIf="responseSubcode !== authErrors.ACCOUNT_LOCKED">
    <div class="row pad-top-20">
      <ng-container *dsPartnerGenericHide>
        <div *ngIf="!isEmailValidation" class="column five pad-left-4">
          <a
            class="caption"
            [dsRouterLink]="['/forgot-userid']"
            id="forgotUserId"
            content
            [contentPath]="'auth.loginForgotUserText'"
            data-cy="loginFormForgotUserIdLink"
          >
          </a
          ><br />
          <a
            class="caption"
            [dsRouterLink]="['/forgot-password']"
            id="forgotPassword"
            content
            [contentPath]="'auth.loginForgotPasswordText'"
            data-cy="loginFormForgotPasswordLink"
          ></a>
        </div>
      </ng-container>

      <div class="twelve caption">
        <button
          ds-button
          type="submit"
          id="signInBtn"
          class="-outline column five float-right"
          [disabled]="!loginForm?.valid || !responseReceived || cookieError"
          content
          [contentPath]="'shared.logInText'"
          data-cy="loginFormSubmitButton"
        ></button>
      </div>
    </div>
  </div>
</form>

<ng-container *dsPartnerGenericHide>
  <div *ngIf="responseSubcode === authErrors.ACCOUNT_LOCKED" class="row pad-top-20">
    <button
      ds-button
      [dsRouterLink]="['/forgot-password']"
      class="btnOutline column twelve unlock-margin margin-bottom-10"
      content
      [contentPath]="'auth.loginUnlock'"
      data-cy="loginFormUnlockButton"
    ></button>
  </div>
</ng-container>

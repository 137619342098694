// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { ConfigService } from '@amfam/shared/utility/shared-services';

import { ClaimAdapter } from './claim.adapter';
// Misc
import { has as _has, get as _get } from 'lodash';

@Injectable({ providedIn: 'root' })
export class ClaimService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private claimAdapter: ClaimAdapter
  ) {}

  // TODO: re-look for constructing repsonse model.
  public getClaimsByCustomerId(customerId: string): Observable<any> {
    return this.http.get(this.config.get('claimApi') + 'claims?partyIdentifier=' + customerId).pipe(
      map(response => {
        return this.claimAdapter.adapt(response);
      })
    );
  }

  public getClaimById(claimNumber: string): Observable<any> {
    return this.http.get(this.config.get('claimApi') + 'claims/' + claimNumber);
  }

  public getExposureId(claimNumber: string, vin: string) {
    const body = {
      claimNumber: claimNumber,
      vehicleVin: vin
    };
    const url = this.config.get('claimExposureApi') + 'vehicle';
    return this.http.post(url, body);
  }
}

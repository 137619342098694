// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// Models
import { ClaimGlassOnly } from '../models/claim-glass-only.model';

// Services
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { Partners } from '@amfam/shared/utility/brand';

@Injectable({ providedIn: 'root' })
export class ClaimGlassOnlyService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  /**
   * AS: A function to call the safelite api.
   */
  public createGlassOnlyClaim(reqObj: ClaimGlassOnly, partnerId: string): Observable<string> {
    const endpoint =
      this.config.get('reportGlassClaimApi') +
      '?redirect=true&postURL=' +
      encodeURIComponent(this.config.get('links.reportGlassClaimVendorUrl')) +
      '&clientTag=' +
      this.getClientTag(partnerId);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'text/html', observe: 'body' }),
      responseType: 'text' as 'text'
    };
    return this.http.post(endpoint, reqObj, httpOptions);
  }

  public getClientTag(parnterId: string): string {
    switch (parnterId) {
      case Partners.ONSTAR:
        return this.config.get('osiGlassKey');
      case 'AFI':
        return this.config.get('amfamGlassKey');
      default:
        // Midvale tag as default
        return this.config.get('midvaleGlassKey');
    }
  }

  public routeToSafelite(payload) {
    const newWindow = window.open('', '_self');
    // body is an HTML document with a form
    // write the form to this document and then submitting it
    const parser = new DOMParser();
    const doc = parser.parseFromString(payload, 'text/html');
    newWindow.document.write(doc.body.innerHTML);
    newWindow.document.forms[0].submit();
    newWindow.document.close();
  }
}

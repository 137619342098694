export class ClaimGlassOnly {
  policyNumber: string;
  lossDate: string;
  returnURL?: string;
  returnURL2?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  email?: string;
  phoneNumber?: string;
  htmlBody?: any;
  loading?: boolean;
  loaded?: boolean;
  status?: any;
  defaultPartnerId?: string;
}

export const initialClaimGlassOnly: ClaimGlassOnly = {
  policyNumber: null,
  lossDate: null,
  returnURL: null,
  returnURL2: null,
  firstName: null,
  lastName: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  zip: null,
  email: null,
  phoneNumber: null,
  loading: false,
  loaded: false,
  status: null
};

import { Phone } from '@amfam/shared/models';

export class NamedInsured {
  public customerId: string;
  public name: string;
  public firstName: string;
  public lastName: string;
  public roleType: string;
  public thirdPartyInterestType: string;
  public phoneNumbers: Array<Phone>;

  static fromJson(json: any): NamedInsured {
    const namedInsured = new NamedInsured();
    namedInsured.assignProperties(json);

    return namedInsured;
  }

  protected assignProperties(json: any) {
    this.customerId = json.sourceIdentifier || '';
    this.name = json.fullName
      ? String(json.fullName).split(', ').reverse().join(' ')
      : null || json.name || json.firstName + ' ' + json.lastName;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.roleType = json.roleType;
    this.thirdPartyInterestType = json.thirdPartyInterestType;
    this.phoneNumbers = json.phoneNumbers || [];
  }
}

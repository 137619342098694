export interface ConfirmationModel {
  confirmation?: {
    status?: 'success' | 'warning' | 'error' | 'none' | 'alert' | 'claims';
    heading?: string;
    subHeading?: string;
    infoText?: string;
    errorHeading?: string;
  };
  details?: {
    heading?: string;
    tableRows?: any;
    tableColumns?: any;
  }[];
  nextSteps?: {
    heading?: string;
    text?: string;
    isNote?: boolean;
  };
  ctaArea?: {
    heading?: string;
    text?: string;
    benefits?: Array<string>;
    button?: {
      title: string;
      callback: Function;
    };
  };
  disclaimer?: string;
  isPartner?: boolean;
  policyNumberText?: string;
  vehicleAddedText?: string;
  alertMessageConfirmation?: string;
  titleConfirmation?: string;
}

export const initialConfirmationModel: ConfirmationModel = {
  confirmation: {
    status: 'success'
  },
  details: [
    {
      heading: 'Details'
    }
  ],
  nextSteps: {
    heading: 'Next Steps'
  }
};

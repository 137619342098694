export class LimitTerm {
  public name: string;
  public description: string;
  public value: string;
  public code: string;
  public coverageTermType: string;
  public valueType: string;

  static fromJson(json: any): LimitTerm {
    const limitTerm = new LimitTerm();
    if (json) {
      limitTerm.name = json.name;
      limitTerm.description = json.description;
      limitTerm.value = json.value;
      limitTerm.code = json.code;
      limitTerm.coverageTermType = json.coverageTermType;
      limitTerm.valueType = json.valueType;
    }

    return limitTerm;
  }
}

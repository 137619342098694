import { Component, OnInit, Input } from '@angular/core';
import { ConfirmationModel, initialConfirmationModel } from '../../ds-confirmation.model';
import { has as _has } from 'lodash';

@Component({
  selector: 'ds-confirmation-next-steps',
  templateUrl: './ds-confirmation-next-steps.component.html',
  styleUrls: ['./ds-confirmation-next-steps.component.scss']
})
export class DsConfirmationNextStepsComponent implements OnInit {
  @Input() nextSteps: ConfirmationModel['nextSteps'];
  private confirmationData: ConfirmationModel;
  public heading: ConfirmationModel['nextSteps']['heading'];

  constructor() {
    // Set up initial string value
    this.confirmationData = initialConfirmationModel;
    this.heading = this.confirmationData['nextSteps']['heading'];
  }

  ngOnInit() {
    // Override initial string value if it was send in the @Input data
    if (_has(this.nextSteps, 'heading')) {
      this.heading = this.nextSteps.heading;
    }
  }
}

import { PageAnalytic } from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalOutageAnalyticConstants {
  public static readonly pageGlobalOutage: PageAnalytic = {
    pageName: 'MyAccount:GlobalOutage',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };
}

export class CustomerEnrollmentStatus {
  autoPayOptInStatus = new ProgramEnrollmentStatus();
  eDeliveryOptInStatus = new ProgramEnrollmentStatus();
  onineBillingOptInStatus = new ProgramEnrollmentStatus();
  ubiOptInStatus = new ProgramEnrollmentStatus();
  ubiDeviceOptInStatus = new ProgramEnrollmentStatus();
  managedPreferenceChangeStatus = new ProgramEnrollmentStatus();
}

export class ProgramEnrollmentStatus {
  requested: boolean;
  success: boolean;
  message: string;
}

<layout-sidebar [sidebarVisible]="isCustomer">
  <div class="main">
    <section id="profile-information" class="margin-bottom-60">
      <div class="row">
        <div class="column twelve">
          <profile-card
            *ngIf="isCustomer"
            [profileCard]="profileCard$ | async"
            [isCustomer]="isCustomer"
          ></profile-card>
          <profile-card-business *ngIf="!isCustomer"></profile-card-business>
        </div>
      </div>
    </section>
    <section id="profile-details">
      <div class="row">
        <div class="column twelve padding-bottom-20">
          <h2 data-cy="profileDetails" content [contentPath]="'profile.profileHeader'"></h2>
          <p *ngIf="isCustomer">
            <span content [contentPath]="'profile.profileText1'"></span>
            <span *dsPartnerAFI>
              <span content [contentPath]="'profile.profileText2'"></span>
              <a
                [dsRouterLink]="requestChangeRoute"
                content
                [contentPath]="'profile.requestChangeLinkText'"
              >
              </a>
              <span content [contentPath]="'profile.profileText3'"></span>
            </span>
          </p>
          <p *ngIf="!isCustomer"><span content [contentPath]="'profile.profileText1'"></span></p>
        </div>
      </div>
      <div class="row">
        <div class="column twelve">
          <ds-accordion [allowMultipleOpenCategories]="true" [allowToggleAccordion]="true">
            <ds-accordion-category [title]="securityInformationTitle" [startOpen]="openSecurity">
              <div class="accordion-content-wrapper">
                <p
                  class="accordion-text pad-bottom-20"
                  content
                  [contentPath]="
                    hasSecurityQuestions
                      ? 'profile.securityInformationText'
                      : 'profile.noSecurityQuestionsSecurityInformationText'
                  "
                ></p>
                <ds-edit-userid class="block"></ds-edit-userid>
                <edit-security-questions
                  *ngIf="hasSecurityQuestions"
                  class="block"
                  [securityQuestions]="securityQuestions$ | async"
                ></edit-security-questions>
                <edit-password class="block pad-bottom-20"></edit-password>
              </div>
            </ds-accordion-category>
            <ds-accordion-category
              data-cy="contactInformation"
              [title]="contactInformationTitle"
              [startOpen]="openContact"
            >
              <div class="accordion-content-wrapper">
                <p
                  *ngIf="isCustomer"
                  class="accordion-text"
                  content
                  [contentPath]="'profile.contactInformationText'"
                ></p>
                <manage-email-address
                  [isDuplicateEmail]="isDuplicateEmail"
                  *ngIf="isCustomer"
                ></manage-email-address>
                <p
                  *ngIf="!isCustomer"
                  class="accordion-text"
                  content
                  [contentPath]="'profile.contactBusinessInformationText'"
                ></p>
                <manage-business-email-address *ngIf="!isCustomer"></manage-business-email-address>
                <hr *ngIf="isCustomer" aria-hidden="true" />
                <manage-phone-numbers *ngIf="isCustomer"></manage-phone-numbers>
              </div>
            </ds-accordion-category>
            <ng-container>
              <ng-container *ngIf="preferenceData?.length">
                <ds-accordion-category
                  [title]="communicationPreferencesTitle"
                  [startOpen]="openCommunication"
                >
                  <ds-error-message
                    *ngIf="!(isEligibleToViewPreferences | async)"
                    [errorList]="errorList"
                  ></ds-error-message>
                  <div
                    class="accordion-content-wrapper"
                    *ngIf="isEligibleToViewPreferences | async"
                  >
                    <ds-communication-preferences
                      [preferenceData]="preferenceData"
                      [eSignatureStatus]="eSignatureStatus"
                    ></ds-communication-preferences>
                  </div>
                </ds-accordion-category>
              </ng-container>
            </ng-container>
          </ds-accordion>
        </div>
      </div>
    </section>
  </div>
  <aside class="main-aside">
    <links-agents-sidebar [quickLinksList]="quickLinksOverride"></links-agents-sidebar>
  </aside>
</layout-sidebar>

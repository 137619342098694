import { Directive, HostListener, ElementRef } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Directive({
  selector: '[ccMask]'
})
export class CreditCardMaskDirective {
  private model: FormControlName;
  protected separator = '-';
  protected digitPattern = /\d{1,4}\-{0,1}\d{0,4}\-{0,1}\d{0,4}-{0,1}\d{0,4}-{0,1}\d{0,4}/;
  protected amexDigitPattern = /\d{1,4}\-{0,1}\d{0,6}\-{0,1}\d{0,5}/;
  constructor(private el: ElementRef, model: FormControlName) {
    this.model = model;
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    this.updateValue(this.model.value, event.data);
  }

  @HostListener('ngModelChange', ['$event'])
  onNgModelChange(currentValue: string) {
    this.updateValue(currentValue, '');
  }

  // updates the actual and displayed values
  private updateValue(currentValue: string, data: string) {
    const actualValue = this.getActualValue(currentValue);
    let maskedValue = null;
    if (this.isAmex(currentValue)) {
      maskedValue = this.getMaskedAmexValue(actualValue);
    } else {
      maskedValue = this.getMaskedValue(actualValue);
    }
    if (currentValue !== actualValue) {
      // This is the actual binding (unmasked) value
      this.model.control.setValue(actualValue, { emitModelToViewChange: false });
    }

    /**
     * Need to move the caret position at the end of the masked value.
     * We add the set time out to give the browser time to render the text and then set
     * the position, this might cause little flickering.
     */
    setTimeout(() => {
      //Getting the current cursor positionfor start & end
      let start = this.el.nativeElement.selectionStart;
      let end = this.el.nativeElement.selectionEnd;
      //Getting count of no. of hiphens in masked value
      const count = (maskedValue.match(/-/g) || []).length;
      // This is the displaying (masked) value
      this.model.valueAccessor.writeValue(maskedValue);
      // Based on the no. of hiphens position cursor position is placed to avoid flickering.
      if (count > 0 && count <= 4 && end + count >= maskedValue.length) {
        start = start + count;
        end = end + count;
      }
      // Moving cursor to next point on input
      else if (start % 5 === 0 && data) {
        start = start + 1;
        end = end + 1;
      }
      // Placing the cursor at right position
      this.el.nativeElement.setSelectionRange(start, end);
    });
  }

  // Returns the masked value for non-amex cards
  private getMaskedValue(actualValue: string): string {
    let maskedValue = '';

    const unmaskedValue = actualValue.replace(/\D+/g, '');
    for (let i = 0; i < unmaskedValue.length && i < 23; i++) {
      if (!(i % 4) && i > 0) {
        maskedValue += this.separator;
      }
      maskedValue += unmaskedValue[i];
    }

    return maskedValue;
  }

  // returns the masked value for Amex cards
  private getMaskedAmexValue(actualValue: string): string {
    let maskedValue = '';

    const unmaskedValue = actualValue.replace(/\D+/g, '');
    for (let i = 0; i < unmaskedValue.length && i < 23; i++) {
      switch (i) {
        case 4:
        case 10:
          maskedValue += this.separator;
          break;
      }
      maskedValue += unmaskedValue[i];
    }

    return maskedValue;
  }

  // Returns the actual (unmasked) value
  private getActualValue(currentValue: string): string {
    let result = '';

    // Check if something is available to mask
    if (currentValue && currentValue.length > 0) {
      let digits = null;

      if (this.isAmex(currentValue)) {
        digits = currentValue.match(this.amexDigitPattern);
      } else {
        digits = currentValue.match(this.digitPattern);
      }

      if (digits) {
        for (const value of digits) {
          result += value;
        }
      }
    }
    return result;
  }

  private isAmex(inputString: string) {
    if (inputString && inputString.length > 0 && inputString.charAt(0) === '3') {
      return true;
    }
    return false;
  }
}

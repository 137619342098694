<!-- Alert Bar Start -->
<section
  [ngClass]="alert.alertBg"
  aria-label="info dialog"
  aria-labelledby="alertMessage"
  class="ds-alert"
  role="alert"
  *ngIf="alert"
>
  <!-- Alert Icon Start -->
  <div class="ds-alert__icon" aria-hidden="true" *ngIf="icon">
    <span [ngClass]="alert.alertIcon"></span>
  </div>
  <!-- Alert Icon End -->

  <!-- Alert Text Start -->
  <div class="ds-alert__text" id="alertMessage">
    <strong class="title" *ngIf="title">{{ title }}</strong>
    <p [innerHTML]="message" class="message" *ngIf="message"></p>
  </div>
  <!-- Alert Text End -->

  <!-- Alert Dismiss Start -->
  <div class="ds-alert__dismiss" *ngIf="dismiss">
    <a (click)="dismissEvent.emit()" title="Close"></a>
  </div>
  <!-- Alert Close End -->

  <!-- Alert Action Start -->
  <div class="ds-alert__action" *ngIf="action">
    <!--Primary Button-->
    <button
      ds-button
      *ngIf="!!primaryButtonName"
      (click)="primaryButtonEvent.emit()"
      [disabled]="disabled"
      data-cy="alertPrimaryButton"
    >
      {{ primaryButtonName }}
    </button>
    <!--Secondary Button-->
    <button
      ds-button
      [ngClass]="'-outline-white'"
      *ngIf="!!secondaryButtonName"
      (click)="secondaryEvent.emit()"
      [disabled]="disabled"
      data-cy="secondaryButton"
    >
      {{ secondaryButtonName }}
    </button>
  </div>
  <!-- Alert Action End -->
</section>
<!-- Alert Bar End -->

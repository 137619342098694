import { ClaimRequest } from './claim-request';
import { Policy } from '@amfam/policy/models';
import { Agent } from '@amfam/shared/utility/agent';

export class ClaimRequestWrapper {
  claimRequest: ClaimRequest;
  policy: Policy;
  producer: Agent;
  date: string;
  time: string;
  customerId: string;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ds-bill-account-policy-risk-icon',
  templateUrl: './bill-account-policy-risk-icon.component.html',
  styleUrls: ['./bill-account-policy-risk-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillAccountPolicyRiskIconComponent {
  @Input() policyRiskIcons: string[];
  constructor() {}
}

import { BillAccountsSelectors as fromBillAccounts } from '@amfam/billing/billaccount/data-access';
import { PolicySelectors as fromPolicies } from '@amfam/policy/data-access';
import { EdeliverySelectors as fromEdeliverySelectors } from '@amfam/policy/e-delivery/data-access';
import { GoPaperLessSelectors as fromGoPaperless } from '@amfam/policy/go-paperless/data-access';
import { DeliveryPreferences } from '@amfam/shared/models';
import { createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

export const billingEligibleToGoPaperless = createSelector(
  fromBillAccounts.getBillAccounts,
  billAccounts => {
    const isBillingPaper =
      !!billAccounts &&
      !!billAccounts.find(billAccount =>
        _get(billAccount, 'billingPreferences.preferences', []).some(
          preference =>
            preference.isModifiable &&
            preference.preferenceDeliveryCode.toLowerCase() !== DeliveryPreferences.EMAIL
        )
      );
    return isBillingPaper;
  }
);

export const isEligibleToGoPaperless = createSelector(
  fromBillAccounts.getBillAccounts,
  fromGoPaperless.isEnrolledGoPaperless,
  fromPolicies.getPolicyRisks,
  fromEdeliverySelectors.getEdeliveryTermsAndConditionsHasError,
  fromPolicies.isEligibleForPaperless,
  fromBillAccounts.isEligibleToViewPreferences,
  (
    billAccounts,
    isPolicyPaperless,
    risks,
    eDeliveryTermsAndConditionsError,
    nonLifePolicies,
    noPreferencesError
  ) => {
    const isPolicyPaper = !isPolicyPaperless;
    const isBillingPaper = !!billAccounts.find(billAccount =>
      _get(billAccount, 'billingPreferences.preferences', []).some(
        preference =>
          preference.isModifiable &&
          preference.preferenceDeliveryCode.toLowerCase() !== DeliveryPreferences.EMAIL
      )
    );
    return (
      risks.length > 0 &&
      !eDeliveryTermsAndConditionsError &&
      (isBillingPaper || isPolicyPaper) &&
      nonLifePolicies &&
      noPreferencesError
    );
  }
);

import { createFeatureSelector, createSelector, ActionReducerMap } from '@ngrx/store';
import { POLICIES_EDELIVERY_FEATURE_KEY } from './e-delivery-terms-and-conditions.reducer';
import * as fromEdeliveryState from './e-delivery-terms-and-conditions.reducer';
import * as fromEdeliveryNotifications from './notifications.reducer';

import { get as _get, flatten as _flatten } from 'lodash';

export interface EdeliveryState {
  eDeliveryState: fromEdeliveryState.EdeliveryEntityState;
  eDeliveryNotifications: fromEdeliveryNotifications.NotificationState;
}

export const eDeliveryReducers: ActionReducerMap<EdeliveryState> = {
  eDeliveryState: fromEdeliveryState.reducer,
  eDeliveryNotifications: fromEdeliveryNotifications.reducer
};

export const getEdeliveryState = createFeatureSelector<EdeliveryState>(
  POLICIES_EDELIVERY_FEATURE_KEY
);

export const getEdeliveryTermsAndConditionsLoading = createSelector(
  getEdeliveryState,
  (state: EdeliveryState) => _get(state, 'eDeliveryNotifications.loading', false)
);

export const getEdeliveryTermsAndConditionsHasError = createSelector(
  getEdeliveryState,
  (state: EdeliveryState) => !!_get(state, 'eDeliveryNotifications.error')
);

export const getEdeliveryTermsAndConditionsContent = createSelector(
  getEdeliveryState,
  (state: EdeliveryState, brandId) =>
    _get(state.eDeliveryState.entities[brandId], 'richContent.richContent')
);

export const eDeliveryQuery = {
  getEdeliveryTermsAndConditionsLoading,
  getEdeliveryTermsAndConditionsHasError,
  getEdeliveryTermsAndConditionsContent,
  getEdeliveryState
};

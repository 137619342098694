<label
  #labelEl
  *ngIf="inputValue"
  [attr.for]="'checkbox-' + inputRow.billAccountNumber"
  [attr.id]="'checkbox-label-' + inputRow.billAccountNumber"
  aria-label="Pay"
  class="check-cell checkbox-toggle-button primary-800-bg none md-inline-block"
  [ngClass]="{
    disabled: inputRow.disable || inputDisabled
  }"
>
  <input
    [attr.checked]="inputRow.checked"
    [attr.aria-labelledby]="'checkbox-label-' + inputRow.billAccountNumber"
    type="checkbox"
    class="checkbox-toggle"
    data-cy="payCheckbox"
    [attr.id]="'checkbox-' + inputRow.billAccountNumber"
    [disabled]="inputRow.disable || inputDisabled"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
  <span aria-hidden="true" class="checkbox-toggle-label">
    <p class="checkbox-toggle-label-text common-white-text">{{ labelText }}</p>
  </span>
</label>

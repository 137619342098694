export interface ConfirmationConfig {
  heading: string;
  status: ConfirmationStatus;
  subheading?: string;
  subheading2?: string;
  bodyText?: string;
  ctaConfig?: {
    primaryButtonName?: string;
    secondaryButtonName?: string;
    tertiaryButtonName?: string;
    disabled?: boolean;
    disablePrimaryButton?: boolean;
    disableSecondaryButton?: boolean;
    displayBackButton?: boolean;
  };
}

export enum ConfirmationStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  ALERT = 'alert',
  CLAIMS = 'claims'
}

import * as error from './error.actions';
import { ErrorModel, initialErrorModel } from './error.model';

// This reduces errors
export function reducer(state: ErrorModel = initialErrorModel, action: error.Actions): ErrorModel {
  switch (action.type) {
    case error.LOAD_SUCCESS:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}

export const getError = (state: ErrorModel) => state;

import { ClaimFaqAction, ClaimFaqActionTypes } from './claim-faq.actions';

export const CLAIMFAQ_FEATURE_KEY = 'claimFaq';

export interface ClaimFaqState {
  richContent: string;
  loading: boolean;
  status: any;
  loaded: boolean;
}

export const initialState: ClaimFaqState = {
  richContent: null,
  loading: false,
  status: null,
  loaded: false
};

export function claimFaqReducer(
  state: ClaimFaqState = initialState,
  action: ClaimFaqAction
): ClaimFaqState {
  switch (action.type) {
    case ClaimFaqActionTypes.LoadClaimFaq:
      return Object.assign({}, state, initialState, {
        loading: true,
        loaded: false
      });

    case ClaimFaqActionTypes.LoadClaimFaqSuccess:
      return Object.assign({}, state, action.payload, {
        loading: false,
        loaded: true
      });

    case ClaimFaqActionTypes.LoadClaimFaqFail:
      return Object.assign({}, state, {
        status: action.payload.error.status,
        loading: false,
        loaded: true,
        hasError: true
      });

    default:
      return state;
  }
}

import { BillingBillAccountDataAccessModule } from '@amfam/billing/billaccount/data-access';
import { MaeDataAccessModule } from '@amfam/mae/data-access';
import { MaeUiModule } from '@amfam/mae/ui/src/lib/mae-ui.module';
import { PolicyDataAccessPolicyModule } from '@amfam/policy/data-access';
import { PolicyGoPaperlessDataAccessModule } from '@amfam/policy/go-paperless/data-access';
import { ProfileFeatureModule } from '@amfam/profile/feature/src/lib/profile-feature.module';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MaeFeatureEffects } from './+state/mae-feature.effects';
import * as fromMaeFeature from './+state/mae-feature.reducer';
import { AutopayComponent } from './autopay/autopay.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { InitiationComponent } from './initiation/initiation.component';
import { MaeErrorComponent } from './mae-error/mae-error.component';
import { MaeFeatureRoutingModule } from './routing/mea-feature.routing.module';
import { SkipautopayComponent } from './skipautopay/skipautopay.component';

@NgModule({
  declarations: [
    InitiationComponent,
    ConfirmationComponent,
    MaeErrorComponent,
    AutopayComponent,
    SkipautopayComponent
  ],
  imports: [
    CommonModule,
    MaeFeatureRoutingModule,
    MaeUiModule,
    UiKitModule,
    FormsModule,
    ReactiveFormsModule,
    SharedServicesModule,
    PolicyDataAccessPolicyModule,
    PolicyGoPaperlessDataAccessModule,
    ProfileFeatureModule,
    MaeDataAccessModule,
    BillingBillAccountDataAccessModule,
    EffectsModule.forFeature([MaeFeatureEffects]),
    StoreModule.forFeature(fromMaeFeature.maeFeatureFeatureKey, fromMaeFeature.reducer)
  ]
})
export class MaeFeatureModule {}

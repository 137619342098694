<div class="row flex items-center footer-container" data-cy="footer">
  <div class="column twelve md-four pad-top-10 text-center md-fixed-350 lg-fixed-300" id="left">
    <div class="row flex wrap justify-center items-center flex-constrained">
      <a
        *ngIf="feedbackEnabled"
        href="#"
        onClick="OOo.inlineFeedbackShow(event)"
        id="web-feedback"
        data-cy="webFeedback"
        class="column none pad-10 md-block md-fixed-150 no-underline"
      >
        <span class="pseudo-icon"></span> <span class="inline-block">Provide<br />Feedback</span>
      </a>
      <a
        [href]="amfamDotCom + 'privacy-security'"
        target="amfam"
        class="column none pad-10 md-block md-fixed-150 no-underline"
        id="safe-info"
        data-cy="safeInfo"
      >
        <span class="icon-safe-info"></span>
        <span class="inline-block">
          Your Info<br />
          <span class="is-safe">Is Safe</span>
        </span>
      </a>
      <a
        [href]="nortonSecurity"
        target="_blank"
        id="norton-secured"
        data-cy="nortonSecured"
        class="column none text-center md-block md-fixed-150"
      >
        <img src="assets/images/norton.png" alt="Norton Secured" title="Norton Secured" />
      </a>
    </div>
  </div>
  <div class="vertical-rule none md-block"></div>
  <div class="contact-info column twelve md-six lg-fixed-300" id="center">
    <div class="row flex wrap justify-center items-center">
      <div class="column fixed-200 pad-10 pad-bottom-5 text-center">
        <a [href]="amfamDotCom" target="amfam">
          <img
            src="/assets/images/american-family-insurance-logo-tagline.png"
            class="logo"
            title="American Family Insurance"
            alt="American Family Insurance"
            data-cy="americanFamilyLogoTagline"
          />
        </a>
      </div>
      <div class="column fixed-300 pad-10 flex items-center justify-center" data-cy="amFamAddress">
        <span class="icon-location pad-right-10"></span>
        <span class="color-copy">
          6000 American Parkway<br />
          Madison, WI 53783
        </span>
      </div>
      <div *ngIf="showContactInfo" class="column fixed-300 pad-10 flex wrap items-center justify-center" data-cy="contactAmFam">
        <div class="phone-number flex items-center" data-cy="callAmFam">
          <span class="icon-call"></span>
          <div class="phone-link">
            <a href="tel:{{ amfamHeaderFooterPhone }}">
              <span>{{ amfamHeaderFooterPhone }}</span>
            </a>
          </div>
        </div>

        <div class="contact-us">
          <div>
            <span class="icon-contact-us"></span>
            <a [href]="amfamDotCom + 'contact?UTM_Source=MyAccount'" target="amfam" data-cy="contactUs"> Contact Us </a>
          </div>
          <div *ngIf="chatEnabled" class="pad-left-10">
            <a [href]="amfamChatUrl" target="_blank"
              ><span class="icon-feedback pad-right-5" data-cy="chatOnline"></span>Chat Online</a
            >
          </div>
        </div>
      </div>
    </div>
    <hr aria-hidden="true" class="md-none fill" />
  </div>
  <div class="vertical-rule none lg-block"></div>
  <hr aria-hidden="true" class="column twelve none md-block lg-none" />
  <div class="column twelve lg-fixed-250 xl-fixed-300" id="right">
    <div class="row flex wrap justify-center">
      <div class="column fixed-250 pad">
        <div class="row flex items-center justify-space-between pad-bottom">
          <img src="//webassets.amfam.com/images/footer/icon_MyAmfam.png" class="app-icon" alt="" />
          <span class="my-amfam-app heading-banner" data-cy="myAmFamAppLogo">MyAmFam App</span>
        </div>
        <div class="row flex items-center justify-space-between">
          <a href="//play.google.com/store/apps/details?id=com.amfam.myamfam" target="_blank">
            <img
              src="/assets/images/google-play-badge.png"
              class="app-link"
              alt="Download MyAmFam App from Google Play"
              data-cy="googlePlayBadge"
            />
          </a>
          <a href="//itunes.apple.com/us/app/my-amfam/id329763835?mt=8" target="_blank">
            <img
              src="//webassets.amfam.com/images/footer/apple-app-store.svg"
              class="app-link"
              alt="Download MyAmFam App from the App Store"
              data-cy="appleAppStore"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row pad-top pad-bottom md-pad">
  <hr aria-hidden="true" class="pad-bottom-10" />
  <ul class="column twelve md-flex wrap justify-space-between text-center">
    <li class="pad-bottom md-pad-right">
      <a [href]="amfamDotCom + 'companyid'" target="amfam" data-cy="companyIdentification">Company Identification </a>
    </li>
    <li class="pad-bottom md-pad-right" data-cy="privacySecurity">
      <a [href]="amfamDotCom + 'privacy-security'" target="amfam">Privacy &amp; Security</a>
    </li>
    <li class="pad-bottom md-pad-right">
      <a [href]="amfamDotCom + 'legal-notice'" target="amfam" data-cy="legalNotice">Legal Notice</a>
    </li>
    <li *ngIf="showSiteMap" class="pad-bottom md-pad-right">
      <a dsRouterLink="/sitemap" dsRouterLinkActive="/sitemap" data-cy="siteMap">Site Map</a>
    </li>
    <li class="md-none pad-bottom-0"><hr aria-hidden="true" /></li>
    <li class="md-text-left">
      <small data-cy="copyRightText">
        &copy;{{ currentYear }} American Family Mutual Insurance Company, S.I. All rights
        reserved.</small
      >
    </li>
  </ul>
</div>

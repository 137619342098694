import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { CardEnums } from '../amfam-dsn-card/amfam-dsn-card.models';

@Component({
  selector: 'ds-amfam-dsn-card',
  templateUrl: './amfam-dsn-card.component.html',
  styleUrls: ['./amfam-dsn-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmfamDsnCardComponent implements OnInit, OnChanges {
  @Input() type: CardEnums;
  @Input() img = false;
  @Input() imgSrc: string;
  @Input() icon = true;
  @Input() iconName: string;
  @Input() title: string;
  @Input() message: string;
  @Input() primaryButtonName: string;
  @Input() secondaryButtonName: string;
  @Input() disablePrimaryButton = false;
  @Input() disableSecondaryButton = false;
  @Input() disabled = false;

  @Output() primaryButtonEvent: EventEmitter<void> = new EventEmitter();
  @Output() secondaryButtonEvent: EventEmitter<void> = new EventEmitter();

  // card: Card;
  cardBg = '';

  constructor() {}

  ngOnInit(): void {
    this.cardUI(this.type);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isChangeValid(changes)) this.cardUI(this.type);
  }

  cardUI(type: CardEnums) {
    switch (type) {
      // INFO
      case CardEnums.INFO:
        this.cardBg = 'ds-card--info';
        break;
      // WARNING
      case CardEnums.WARNING:
        this.cardBg = 'ds-card--warning';
        break;
      // SUCCESS
      case CardEnums.SUCCESS:
        this.cardBg = 'ds-card--success';
        break;
      // ERROR
      case CardEnums.ERROR:
        this.cardBg = 'ds-card--error';
        break;
      // DEFAULT
      default:
        this.cardBg = '';
    }
  }

  private isChangeValid(changes: SimpleChanges): boolean {
    return (
      changes &&
      changes.type &&
      changes.type.currentValue &&
      changes.type.currentValue !== changes.type.previousValue
    );
  }
}

import { BillAccountActions } from '@amfam/billing/billaccount/data-access';
import { fromMaeActions } from '@amfam/mae/data-access';
import { PolicySummarySelectors } from '@amfam/policy/data-access';
import { PrettyPolicyNum } from '@amfam/policy/pipes';
import {
  DigitalAccountActions,
  DigitalAccountActionTypes,
  fromDigitalAccountActions
} from '@amfam/shared/digital-account/data-access';
import { ArrayToList } from '@amfam/shared/ui/pipes';
import { fromUserActions, userQuery } from '@amfam/shared/user';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService, UtilService } from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addDays } from 'date-fns';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ANSRequest } from '../model/mae-feature.model';
import { MaeFeatureService } from '../services/mae-feature.service';
import {
  enrollmentSuccessANSNotificationFailure,
  enrollmentSuccessANSNotificationSuccess,
  initiateEnrollment,
  launchOverview,
  setPreferences,
  skipAutoPay,
  validateEmail
} from './mae-feature.actions';
import { maeFeatureQuery } from './mae-feature.selectors';

@Injectable()
export class MaeFeatureEffects {
  initiateEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateEnrollment),
      map(action => action.email),
      withLatestFrom(
        this.store.select(userQuery.getUserState),
        this.store.select(PolicySummarySelectors.getProducerId)
      ),
      switchMap(([email, user, produerId]) => {
        const enrollmentRequest = this.maeFeatureService.getPayload(user, produerId, email);
        return [fromMaeActions.enrollment({ payload: enrollmentRequest })];
      })
    )
  );
  validateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateEmail),
      map(action => action),
      withLatestFrom(this.store.select(userQuery.getPartnerId)),
      map(([action, partnerID]) => {
        const checkEmailAddressPayload = this.maeFeatureService.getCheckEmailAddressPayload(
          action.email,
          partnerID,
          action.correlationId
        );
        return new fromDigitalAccountActions.CheckEmailAddress(checkEmailAddressPayload);
      })
    )
  );
  updateUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaeActions.enrollmentSuccess),
      map(action => action.payload),
      withLatestFrom(this.store.select(maeFeatureQuery.getEnrollmentEmail)),
      switchMap(([customerEnrollment, email]) => [
        new fromUserActions.EditUserId('SHELLACCOUNT'),
        new fromUserActions.UpdateUserEmail(email),
        new fromUserActions.EditWAID(customerEnrollment?.userProfile?.waid),
        new fromRouterActions.Go({ path: ['/enroll/confirmation'] })
      ])
    )
  );

  errorpage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaeActions.enrollmentFailure, DigitalAccountActionTypes.CheckEmailAddressFailure),
      map(() => new fromRouterActions.Go({ path: ['/enroll/error'] }))
    )
  );

  lauchOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(launchOverview),
      map(() => {
        const refreshSuccessResponse = this.maeFeatureService.getRefreshResponse();
        return new DigitalAccountActions.RefreshSuccess(refreshSuccessResponse);
      })
    )
  );
  setPreferences = createEffect(() =>
    this.actions$.pipe(
      ofType(setPreferences),
      switchMap(() => [
        new BillAccountActions.BillAccountsLoad(),
        this.feature.isEnabled('go_paperless_new_enrollment_page')
          ? new fromRouterActions.Go({ path: ['/enroll/paperless/sign-up'] })
          : new fromRouterActions.Go({ path: ['/enroll/paperless/signup'] })
      ])
    )
  );

  skipAutoPay = createEffect(() =>
    this.actions$.pipe(
      ofType(skipAutoPay),
      map(() => new fromRouterActions.Go({ path: ['/enroll/skip-autopay'] }))
    )
  );

  sendAgentANSNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaeActions.enrollmentSuccess),
      map(action => action.payload),
      withLatestFrom(
        this.store.select(PolicySummarySelectors.getProducerId),
        this.store.select(PolicySummarySelectors.getPolicyList),
        this.store.select(userQuery.getPreEnrollementPhoneNumber)
      ),
      switchMap(([customerEnrollmentPayload, producerId, policyList, phoneNumber]) =>
        this.maeFeatureService
          .ansNotification(
            this.getAgentNotificationPayload(
              producerId,
              policyList,
              `${customerEnrollmentPayload?.userProfile?.firstName} ${customerEnrollmentPayload?.userProfile?.lastName}`,
              phoneNumber,
              customerEnrollmentPayload?.userProfile?.emailAddress
            )
          )
          .pipe(
            map(() => enrollmentSuccessANSNotificationSuccess()),
            catchError(() => of(enrollmentSuccessANSNotificationFailure()))
          )
      )
    )
  );

  private getAgentNotificationPayload(
    producerId,
    policyNumbers,
    customerName,
    phoneNumber,
    emailAddress
  ) {
    const notificationText = this.copyService.getCopy('enrollment.maePostEnrollmentANSText', {
      customerName,
      policyNumbers: new ArrayToList().transform(policyNumbers),
      phoneNumber: phoneNumber ? (phoneNumber === 'None' ? 'N/A' : phoneNumber) : 'N/A',
      emailAddress: emailAddress || 'N/A'
    });

    const ansPayload: ANSRequest = {
      notificationsRequest: {
        customerName,
        notificationText,
        notificationType: 'ANSMYAERMT',
        createdBy: 'DMSI',
        policyNumber: new ArrayToList().transform(policyNumbers),
        referenceNumber: policyNumbers[0],
        formattedReferenceNumber: new PrettyPolicyNum().transform(policyNumbers[0]),
        from: 'DMSI',
        dueDate: addDays(new Date(), 2).toISOString(),
        sourceSystem: 'MYACCOUNT',
        uniqueId: this.utilService.generateId()
      },
      producerId: producerId
    };
    return ansPayload;
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private maeFeatureService: MaeFeatureService,
    private copyService: CopyService,
    private utilService: UtilService,
    private feature: FeatureFlagService
  ) {}
}

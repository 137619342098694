import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureFlagsState } from './feature-flag.reducer';

const getFeatureFlagState = createFeatureSelector<FeatureFlagsState>('featureFlag');

export const getFeatureFlagEntitiesState = createSelector(
  getFeatureFlagState,
  state => state?.entities
);

export const hasFeatureFlag = createSelector(getFeatureFlagState, state => !!state && state.ids?.length > 0);

export const getFeatureFlag = feature =>
  createSelector(getFeatureFlagEntitiesState, entities => entities && entities[feature]);

export const featureFlagQuery = {
  hasFeatureFlag,
  getFeatureFlagState,
  getFeatureFlagEntitiesState,
  getFeatureFlag
};

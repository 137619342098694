
export interface FnolClaimModel {
  authToken:string;
  callingContext:string;
  distributionChannel:DistributionChannel;
  callingSubContext:string;
  claimsInfo: ClaimsInfo;
  customerInfo:ClaimCustomerInfo;
}

export interface DistributionChannel{
  partnerId:string;
  opco:string;
  partnerName:string;
  accountId:string;
  sourceSystem:string;
}

export interface ClaimsInfo {
  draftClaimNumbers: string[];
  fullClaimNumber:string;
  policies: Policies[];
  detailedPoliciesInfo: DetailedPoliciesInfo[];
}

export interface Policies {
  policyNumber:string;
  sourceSystem:string;
  productCode:string;
  inquiryDate:string;
  isE1P:boolean;
}

export interface DetailedPoliciesInfo{
  generalizedProductType:string;
  periodStartDate: string;
  policyNumber: string;
  policyAddress: PolicyAddress[];
  policyRoles: PolicyRoles[];
  risks: Risks[];
  policySource:string;
  partnerCode:string;
  policyStatus:string;
}

export interface PolicyAddress {
  addressLine1:string;
  city: string;
  state:string;
  zipCode5: string;
  zipCode4: string;
}

export interface PolicyRoles{
  contactReferenceKey:string;
  roleType:string;
  firstName:string;
  lastName:string;
}

export interface Risks {
  address: RiskAddress[];
  vin:string;
  year:string;
  make:string;
  model:string;
  vehicleTypeName:string;
}

export interface RiskAddress{
  addressLine1: string;
  city:string;
  state:string;
  zipCode5:string;
  zipCode4:string;
}

export interface ClaimCustomerInfo {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    userId: string;
    roleType: string;
}

import { AnalyticsFacade } from '@amfam/shared/analytics/src/lib/+state/analytics.facade';
import { OverviewAnalytics } from '@amfam/shared/models/src';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { has as _has } from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LoadingSpinnerService } from '../services/loading-spinner.service';
@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnDestroy {
  @Input() show: boolean;
  public active: boolean;
  public isNewLoader: boolean;
  public isProgressBar: boolean;
  public isRecalculate: boolean;
  public loadingMessage: string;
  public secondaryMessage: string;
  public blockActions: boolean;
  public maxWidth: string;
  public icons: any;
  public interval: any;
  public timeout: any;
  public commentProgress = {
    progress: 0
  };
  public isPartner = false;
  private loading = true;
  private el: any;
  private stop$ = new Subject<void>();

  constructor(
    spinner: LoadingSpinnerService,
    private elRef: ElementRef,
    private analyticsFacade: AnalyticsFacade,
    private store: Store
  ) {
    spinner.status.pipe(takeUntil(this.stop$)).subscribe((status: boolean) => {
      this.active = status;
    });

    this.store
      .select(BrandSelectors.getIsPartner)
      .pipe(take(1))
      .subscribe(ele => {
        this.isPartner = ele;
      });
    spinner.blockActionsStatus.pipe(takeUntil(this.stop$)).subscribe((status: boolean) => {
      this.blockActions = status;
      this.el = this.elRef; // This element

      if (_has(this.el, 'nativeElement')) {
        if (this.blockActions) {
          this.el.nativeElement.classList.add('actionBlock');
        } else {
          this.el.nativeElement.classList.remove('actionBlock');
        }
      }
    });

    spinner.loadingMessageSub
      .pipe(takeUntil(this.stop$))
      .subscribe((loadingMessageContent: string) => {
        if (loadingMessageContent !== null && loadingMessageContent !== undefined) {
          this.loadingMessage = loadingMessageContent;
        }
      });

    spinner.secondaryMessageSub
      .pipe(takeUntil(this.stop$))
      .subscribe((secondaryMessageContent: string) => {
        if (secondaryMessageContent !== null && secondaryMessageContent !== undefined) {
          this.secondaryMessage = secondaryMessageContent;
        }
      });

    spinner.maxWidthSub.pipe(takeUntil(this.stop$)).subscribe((maxWidthContent: string) => {
      if (maxWidthContent) {
        this.maxWidth = maxWidthContent;
      }
    });

    spinner.isNewLoaderSub.pipe(takeUntil(this.stop$)).subscribe((status: boolean) => {
      this.isNewLoader = status;
    });

    spinner.isRecalculateSub.pipe(takeUntil(this.stop$)).subscribe((status: boolean) => {
      this.isRecalculate = status;
    });

    spinner.isProgressBarSub.pipe(takeUntil(this.stop$)).subscribe((status: boolean) => {
      this.isProgressBar = status;
      clearInterval(this.interval);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.isProgressBar && !this.isPartner) {
          this.icons = document.querySelectorAll('.afi-icon');
          this.commentProgress.progress = 0;
          this.addAddition();
          let index = 0;

          this.interval = setInterval(() => {
            this.icons[index].style.display = 'none';
            index++;
            if (index >= this.icons.length) {
              index = 0;
            }
            this.icons[index].style.display = 'block';
          }, 1000);
        } else {
          this.isProgressBar = false;
        }
      }, 10);
    });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  }

  public addAddition() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.commentProgress.progress += this.randomIntFromInterval(this.commentProgress.progress);
      if (this.commentProgress.progress === 100) {
        if (this.isRecalculate) {
          this.analyticsFacade.trackPage(OverviewAnalytics.SpinnerRecalculateCompleteAnalytics);
        } else {
          this.analyticsFacade.trackPage(OverviewAnalytics.SpinnerCompleteAnalytics);
        }
      }
      if (this.commentProgress.progress < 100) {
        if (this.loading) {
          this.addAddition();
        } else {
          this.commentProgress.progress = 0;
        }
      }
    }, 1000);
  }

  private randomIntFromInterval(progressPer: number) {
    if (progressPer >= 0 && progressPer <= 80) {
      return 2;
    } else if (progressPer >= 81 && progressPer < 100) {
      return 1;
    } else if (progressPer >= 100) {
      return 0;
    }
  }
}

<div class="row autopay-container">
  <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
    <ds-step-counter
      data-cy="autoPayWrapperStepCounter"
      *ngIf="stepNumber$ | async"
      [currentStep]="stepNumber$ | async"
      [totalSteps]="3"
    ></ds-step-counter>
    <router-outlet></router-outlet>
  </div>
</div>

<ds-alert-modal
  [config]="cancelModalConfig"
  (primaryEvent)="leave()"
  (tertiaryEvent)="stay()"
></ds-alert-modal>

import { throwError as observableThrowError, timer, Observable, combineLatest, of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  catchError,
  retryWhen,
  withLatestFrom
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import {
  has as _has,
  some as _some,
  get as _get,
  cloneDeep as _cloneDeep,
  forEach as _forEach,
  first as _first
} from 'lodash';
import {
  fromClaimActions,
  ClaimActionTypes,
  LoadClaims,
  LoadClaimDetail,
  GetExposureId
} from './claim.actions';

import { LoadClaimDetailPayload, LoadClaimDetailSuccessPayload } from '../models';

import { ClaimService } from '../services/claim.service';
import { ExposureIdResponse, ExposureIdReqData } from '../models/exposure-id';

@Injectable()
export class ClaimEffects {
  
  loadClaims$ = createEffect(() => this.action$.pipe(
    ofType(ClaimActionTypes.LoadClaims),
    map((action: LoadClaims) => action.payload),
    filter(payload => {
      // Make sure we have a customerId before calling getClaimsByCustomerId
      return payload && payload.customerId && payload.customerId !== '';
    }),
    switchMap(user =>
      this.claimService.getClaimsByCustomerId(user.customerId).pipe(
        switchMap(response => {
          return of(new fromClaimActions.LoadClaimsSuccess(response));
        }),
        catchError(error => {
          return of(new fromClaimActions.LoadClaimsFail(error));
        })
      )
    )
  ));

  
  loadClaimDetail$ = createEffect(() => this.action$.pipe(
    ofType(ClaimActionTypes.LoadClaimDetail),
    map((action: LoadClaimDetail) => action.payload),
    switchMap((payload: LoadClaimDetailPayload) =>
      this.claimService.getClaimById(payload.claimNumber).pipe(
        mergeMap(response => {
          if (_some(response, 'claimNumber')) {
            return of(
              new fromClaimActions.LoadClaimDetailSuccess({ response }),
              new fromClaimActions.SetSelectedClaim(payload.claimNumber)
            );
          }
          // AS: If we do not have a claim number in the response of load detail we need to treat this as
          // an error.
          return observableThrowError({
            claimNumber: payload.claimNumber,
            status: response.status ? response.status : response
          });
        }),
        retryWhen(genericRetryStrategy()),
        catchError(error => {
          // DM: we need to set the selected claim here so that the report claim summary component
          // can retreive the claim and determine if we gave up trying to fetch the detail
          const failureAction =
            error.status.code === '200'
              ? new fromClaimActions.DetailUnavailibleIgnoreErrors(error.claimNumber)
              : new fromClaimActions.LoadClaimDetailFail({
                  claimNumber: error.claimNumber ? error.claimNumber : payload.claimNumber,
                  status: error.status ? error.status : error
                });
          return of(new fromClaimActions.SetSelectedClaim(payload.claimNumber), failureAction);
        })
      )
    )
  ));

  
  getExposureId$ = createEffect(() => this.action$.pipe(
    ofType(ClaimActionTypes.GetExposureId),
    map((action: GetExposureId) => action.payload),
    switchMap((payload: ExposureIdReqData) =>
      this.claimService.getExposureId(payload.claimNumber, payload.vin).pipe(
        map((response: ExposureIdResponse) => {
          const exposureId = _get(response, 'vehicleExposures[0].exposureId', '');
          return new fromClaimActions.GetExposureIdSuccess({
            claimNumber: payload.claimNumber,
            exposureId: exposureId
          });
        }),
        catchError(() =>
          of(
            new fromClaimActions.GetExposureIdFail({
              claimNumber: payload.claimNumber,
              exposureId: null
            })
          )
        )
      )
    )
  ));

  constructor(private claimService: ClaimService, private action$: Actions) {}
}

/**
 * Retry Observables from a retryWhen block
 * TODO: move to a resiliancy service
 *
 * @param maxRetryAttempts: how many times to retry
 * @param scalingDuration: each retry will delay the previous delay plus scalingDuration
 * @param excludedStatusCodes: status codes to exclude from retry
 */
export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = []
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === error.status)) {
        return observableThrowError(error);
      }
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    })
  );
};

import { Action } from '@ngrx/store';

export const START_APPLICATION = '[App Bootstrap] Start Application';
export const LOAD_SYSTEM_BANNER_SUCCESS = '[App Bootstrap] Load System Banner Success';
export const LOAD_SYSTEM_BANNER_FAIL = '[App Bootstrap] Load System Banner Fail';

export class StartApplicationAction implements Action {
  readonly type = START_APPLICATION;

  constructor() {}
}

export class LoadSystemBannerSuccessAction implements Action {
  readonly type = LOAD_SYSTEM_BANNER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadSystemBannerFailAction implements Action {
  readonly type = LOAD_SYSTEM_BANNER_FAIL;

  constructor(public payload: any) {}
}

export type SystemStatusActions =
  | StartApplicationAction
  | LoadSystemBannerSuccessAction
  | LoadSystemBannerFailAction;

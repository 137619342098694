<div class="row">
  <div class="content">
    <h1 data-cy="resetPasswordHeaderText" content [contentPath]="'auth.resetPasswordHeader'"></h1>
    <p content [contentPath]="'auth.resetPasswordCreate'"></p>
    <accept-password-input
      *ngIf="userIdentifier"
      (onComplete)="onComplete($event)"
      [componentUsernameInput]="userIdentifier"
      [errorText]="resetPasswordErrorText"
    ></accept-password-input>
    <accept-password-input
      *ngIf="correlationId"
      (onComplete)="onComplete($event)"
      [errorText]="resetPasswordErrorText"
    ></accept-password-input>
  </div>
</div>

import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';
import { GoPaperlessModel } from '../models/go-paperless.model';
import * as fromGoPaperlessState from './go-paperless.reducer';
export const GO_PAPERLESS_FEATURE_KEY = 'goPaperless';
export interface GoPaperLessState {
  goPaperLessState: GoPaperlessModel;
}

export const goPaperLessReducers: ActionReducerMap<GoPaperLessState> = {
  goPaperLessState: fromGoPaperlessState.reducer
};

export const getGoPaperlessState =
  createFeatureSelector<GoPaperLessState>(GO_PAPERLESS_FEATURE_KEY);

export const getGoPaperlessLoading = createSelector(
  getGoPaperlessState,
  (state: GoPaperLessState) => _get(state, 'goPaperLessState.loading', true)
);

export const getGoPaperlessLoaded = createSelector(
  getGoPaperlessState,
  (state: GoPaperLessState) => !_get(state, 'goPaperLessState.loading', true)
);

export const getGoPaperlessError = createSelector(
  getGoPaperlessState,
  (state: GoPaperLessState) => !!_get(state, 'goPaperLessState.hasError')
);

export const isEnrolledGoPaperless = createSelector(
  getGoPaperlessState,
  (state: GoPaperLessState) => !!_get(state, 'goPaperLessState.isEnrolled')
);

export const getCorrelationId = createSelector(getGoPaperlessState, (state: GoPaperLessState) =>
  _get(state, 'goPaperLessState.correlationId')
);

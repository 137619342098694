import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ds-premium-card',
  templateUrl: './premium-card.component.html',
  styleUrls: ['./premium-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PremiumCardComponent {
  @Input() amount: number | string;
  @Input() term: string;
}
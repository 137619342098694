import { Component, OnInit, Input } from '@angular/core';
import { ErrorObj, ErrorLevels } from '../../models/error-model';

@Component({
  selector: 'ds-error-item',
  templateUrl: './error-item.component.html',
  styleUrls: ['./error-item.component.scss']
})
export class ErrorItemComponent implements OnInit {
  @Input() errorItem: ErrorObj;

  public currentClasses: any;

  constructor() { }

  ngOnInit() {
    this.currentClasses = {
      'high-alert': this.errorItem.errorLevel === ErrorLevels.HIGH,
      'medium-alert': this.errorItem.errorLevel === ErrorLevels.MEDIUM,
      'low-alert': this.errorItem.errorLevel === ErrorLevels.LOW
    };
  }
}

import { ProfileFeatureModule } from '@amfam/profile/feature';
import { ImpersonationModule } from '@amfam/shared/utility/impersonation';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OverviewPaperlessEnrollmentFeatureEffects } from './+state/overview-paperless-enrollment-feature-effects';
import * as OverviewPaperlessEnrollmentFeature from './+state/overview-paperless-enrollment-feature-reducer';
import { PreferencesInitComponent } from './set-billing-and-policy-preferences/preferences-init/preferences-init.component';
import { SetBillingAndPolicyPreferencesComponent } from './set-billing-and-policy-preferences/set-billing-and-policy-preferences.component';

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    FormsModule,
    SharedServicesModule,
    ImpersonationModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([OverviewPaperlessEnrollmentFeatureEffects]),
    StoreModule.forFeature(
      OverviewPaperlessEnrollmentFeature.paperlessEnrollmentFeatureKey,
      OverviewPaperlessEnrollmentFeature.reducer
    ),
    ProfileFeatureModule
  ],
  declarations: [SetBillingAndPolicyPreferencesComponent, PreferencesInitComponent],
  exports: [SetBillingAndPolicyPreferencesComponent, PreferencesInitComponent]
})
export class OverviewPaperlessEnrollmentFeatureModule {}

/* eslint-disable ngrx/prefer-action-creator-in-dispatch */
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { userQuery } from '@amfam/shared/user';
import {
  ButtonAnalytic,
  CopyService,
  HttpStatusCode,
  OpportunityContent,
  OpportunityQualifierOptions,
  WindowRef
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, LoadingSpinnerService } from '@amfam/ui-kit/src';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { getNotifiedOpportunities } from '../../+state';
import { OpportunitiesFeedbackAction } from '../../+state/opportunity.action';
import { DSP_DOMAIN, StampsApiResponse } from '../../models';
import { CustomerFeedbackEnum } from '../../models/feedback';
import { OpportunityAnalytics } from '../../models/opportunity-analytic-contants';
import { OpportunityService } from '../../services/opportunity.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'amfam-opportunities-qualifiers',
  templateUrl: './opportunities-qualifiers.component.html',
  styleUrls: ['./opportunities-qualifiers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunitiesQualifiersComponent implements OnInit, OnDestroy {
  qualifersFormGroup: FormGroup;
  opportunityContent$: Observable<OpportunityContent>;
  qualifierOptions: OpportunityQualifierOptions[];
  productType: string;
  recommendationId$: Observable<string>;
  openedWindow: Window;
  isOpportunityQuoted$: Observable<boolean>;
  private stop$ = new Subject<void>();
  dspUrl: string;

  constructor(
    private copyService: CopyService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router,
    private opportunityService: OpportunityService,
    private store: Store,
    private windowRef: WindowRef,
    private analyticsFacade: AnalyticsFacade,
    private spinner: LoadingSpinnerService,
    private dockingBarService: DockingBarService,
    private coreService: CoreService
  ) {}

  get qualifiersFormArray() {
    return this.qualifersFormGroup.controls['qualifiers'] as FormArray;
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.dockingBarService.registerHeading('My Opportunity');
    this.productType = this.route.snapshot.params['id'];
    this.recommendationId$ = this.opportunityService.getRecommendationIdByOpportunityType(
      this.productType
    );
    this.sendPageAnalytics();
    this.buildForm();
    this.isOpportunityQuoted$ = combineLatest([
      this.recommendationId$,
      this.store.select(getNotifiedOpportunities)
    ]).pipe(
      takeUntil(this.stop$),
      map(([recommendationId, quotedOpportunities]) =>
        quotedOpportunities.some(opp => opp.recommendationId === recommendationId)
      )
    );
  }

  buildForm() {
    this.qualifersFormGroup = this.formBuilder.group({
      qualifiers: new FormArray([])
    });

    this.qualifierOptions = this.copyService
      .getCopy('opportunity')
      .find(
        opportunityFromCopy => opportunityFromCopy.opportunityProductType === this.productType
      ).qualifiers;

    this.addControls();
  }

  sendPageAnalytics() {
    this.recommendationId$
      .pipe(
        filter(recommendationId => !!recommendationId),
        first(),
        switchMap(recommendationId =>
          this.opportunityService.buildPageAnalyticData(
            OpportunityAnalytics.pageOpportunitiesQualifiers,
            recommendationId
          )
        ),
        tap(analytics => this.analyticsFacade.trackPage(analytics))
      )
      .subscribe();
  }

  sendCancelButtonAnalytics() {
    return this.recommendationId$.pipe(
      filter(recommendationId => !!recommendationId),
      first(),
      switchMap(recommendationId =>
        this.opportunityService.buildButtonAnalyticData(
          OpportunityAnalytics.clickOpportunityCancelQuote,
          recommendationId
        )
      ),
      tap(analytics => this.analyticsFacade.trackButtonClick(analytics))
    );
  }

  addControls() {
    this.qualifierOptions.forEach(() => this.qualifiersFormArray.push(new FormControl(false)));
  }

  continue() {
    const qualifiers: Array<boolean> = this.qualifersFormGroup.getRawValue().qualifiers;
    if (qualifiers.some(q => q)) {
      this.router.navigate(['opportunities', 'learnmore', this.productType, 'agent']);
    } else {
      this.spinner.start({ blockActions: true });
      this.openedWindow = this.windowRef.nativeWindow.open('', '_blank');
      this.openedWindow.document.write('Loading. Please Wait !');
      this.store
        .select(userQuery.getZipCode)
        .pipe(
          withLatestFrom(this.store.select(userQuery.getUserStateCode), this.recommendationId$),
          switchMap(([zipCode, stateCode, recommendationId]) =>
            this.opportunityService.stamps(zipCode).pipe(
              tap((response: StampsApiResponse) => {
                if (response.status.code === HttpStatusCode.OK) {
                  const url = response.url;
                  if (url.startsWith(DSP_DOMAIN)) {
                    this.sendNavigateToDSPAnalytics();
                    this.dspUrl = `${url}?state=${stateCode}&zipCode=${zipCode}&servLine=Auto&servLineCat=Car&tid=CrossSellAutoDSPWeb&CrossSellValue=true`;
                    this.coreService.recommendationId = recommendationId;
                    this.coreService.dspUrl = this.dspUrl;
                    this.openedWindow.location = this.dspUrl;
                  } else {
                    this.openedWindow.close();
                    this.router.navigate(['opportunities', 'learnmore', this.productType, 'agent']);
                  }
                } else {
                  this.openedWindow.close();
                  this.router.navigate(['opportunities', 'learnmore', this.productType, 'agent']);
                }
              })
            )
          ),
          first(),
          catchError(() => {
            this.openedWindow.close();
            this.router.navigate(['opportunities', 'learnmore', this.productType, 'agent']);
            return of(null);
          }),
          finalize(() => this.spinner.stop())
        )
        .subscribe();
    }
  }

  sendNavigateToDSPAnalytics() {
    const analyticData: ButtonAnalytic = OpportunityAnalytics.clickOpportunitySendToDSP;
    this.analyticsFacade.trackButtonClick(analyticData);
  }

  sendFeedback(recommendationId: string): void {
    this.store.dispatch(
      new OpportunitiesFeedbackAction(
        {
          feedbackActionCode: CustomerFeedbackEnum.CustomerRequestedQuote,
          producerId: null,
          recommendationId: recommendationId,
          isMultiAgent: false
        },
        true
      )
    );
  }
  cancel() {
    this.sendCancelButtonAnalytics()
      .pipe(tap(() => this.location.back()))
      .subscribe();
  }

  onEnterKeyPressed(formControlName: string) {
    const value = this.qualifiersFormArray.controls[formControlName].value;
    const formControl: AbstractControl = this.qualifiersFormArray.controls[formControlName];
    if (formControl) formControl.setValue(!value);
  }

  ngOnDestroy(): void {
    this.spinner.stop();
    this.stop$.next();
    this.stop$.complete();
  }
}

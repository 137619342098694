<div
  #modal
  [attr.id]="id"
  [ngClass]="fullScreen ? 'full-screen' : 'normal'"
  class="modal ds-modal overlay-black"
>
  <!-- The service depends on the #content child being here, don't remove it. -->
  <div #content class="content ds-modal-wrapper" [ngStyle]="{ 'max-width.px': maxWidth }">
    <button ds-button class="close icon-exit" aria-label="Close" (click)="close()"></button>
    <div class="ds-modal-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers, autoPayFeatureKey } from './+state/auto-pay.selectors';
import { EffectsModule } from '@ngrx/effects';
import { AutoPayEffects } from './+state/auto-pay.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(autoPayFeatureKey, reducers),
    EffectsModule.forFeature([AutoPayEffects])
  ]
})
export class BillingAutoPayDataAccessModule {}

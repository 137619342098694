import { Directive, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

/*
  This directive addresses an issue detailed here: https://github.com/swimlane/ngx-datatable/issues/193

  Basically at certain breakpoints, the header and table width are being calculated before the data is loaded
  and after loading the data is not being recalculated. The result is that the table header and body have a
  width of zero and as such is not painted to the screen until the user manually changes the windw size.

  This solution detects the incorrect size and forces a recalculation

*/
@Directive({ selector: '[dsTableResizeWatcher]' })
export class DsTableResizeWatcherDirective implements AfterContentChecked {
  constructor(private table: DatatableComponent, public ref: ChangeDetectorRef) {}

  private latestWidth: number;

  ngAfterContentChecked() {
    if (this.table && this.table.element.clientWidth !== this.latestWidth) {
      this.latestWidth = this.table.element.clientWidth;
      this.table.recalculate();
      this.ref.markForCheck();
    }
  }
}

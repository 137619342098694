<div class="agents" *ngIf="agents$ | async as agents">
  <div class="agents__header">
    <img class="icon" src="/assets/images/collar@2x.png" alt="Agent icon" />
    <div class="title">Your agent will help</div>
  </div>

  <div class="agents__sub-header">
      <p>Your agent will contact you after reviewing your information.</p>
      <p>They can answer your questions and help find the right policy for you.</p>
  </div>

  <ng-container *ngIf="agents && agents.length > 0">
    <amfam-profile-card
      *ngFor="let agent of agents; let i = index"
      [index]="i"
      [id]="agent.agentId"
      [url]="agent.url"
      [name]="agent.name"
      [office]="agent.office"
      [recommended]="agent.recommended"
      [address]="agent.address"
      [selected]="agent.agentId === agentId && agents.length > 1"
      [showRadio]="agents.length > 1"
      (profileSelected)="onAgentSelected($event)"
    >
    </amfam-profile-card>
  </ng-container>


  <div class="agents__actions">
    <call-to-action
    primaryButtonName="Send to My Agent"
    secondaryButtonName="Back to My Account"
    [equalWidthButtons]="true"
    [capitalize]="true"
    (primaryEvent)="sendToAgent()"
    (secondaryEvent)="backToMyAccount()"
    [disablePrimaryButton]="!agentId"
    >

    </call-to-action>
  </div>

</div>

import { AbstractControl } from '@angular/forms';
import { PaymentAccount } from './payment-account.model';

export interface SelectedPaymentAmountForAutopay {
  billAccountNumber: string;
  billAccountNickName: string;
  billAccountDueDate: string;
  selectedAmount: 'MINDUE' | 'FULLPAY';
}

export interface SelectedPaymentMethodForAutopay {
  selectedPaymentMethod: string;
}

export interface SelectedDueDateForAutopay {
  selectedDueDate: string;
}

export interface AddEditPaymentMethodSelected {
  selectedPaymentMethod: PaymentAccount | null;
  action: 'add-payment-account' | 'edit-payment-account';
}

export interface AddEditSuccessEvent {
  status: boolean;
  paymentMethodNickName: AbstractControl;
}

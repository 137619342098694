import { Action } from '@ngrx/store';
import { Enrollment, EnrollmentRequest } from './models';

export const LOAD = '[MaeEnrollment] Load';
export const LOAD_COMPLETE = '[MaeEnrollment] Load Complete';
export const LOAD_SUCCESS = '[MaeEnrollment] Load Success';
export const ENROLL = '[MaeEnrollment] Enroll';
export const UNENROLL = '[MaeEnrollment] Unenroll';
export const LOAD_FAIL = '[MaeEnrollment] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor(public payload?: any) {}
}

export class LoadCompleteAction implements Action {
  readonly type = LOAD_COMPLETE;
  constructor() {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: Enrollment[]) {}
}

export class EnrollAction implements Action {
  readonly type = ENROLL;
  constructor(public payload: EnrollmentRequest) {}
}

export class UnenrollAction implements Action {
  readonly type = UNENROLL;
  constructor(public payload: EnrollmentRequest) {}
}
export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
  constructor(public payload?: any) {}
}

export type Actions =
  | LoadAction
  | LoadCompleteAction
  | LoadSuccessAction
  | EnrollAction
  | UnenrollAction
  | LoadFailAction;

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { get as _get } from 'lodash';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { ResetOptionModel, VerifyPinRequest } from '@amfam/shared/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { LoadingSpinnerService, ToasterService } from '@amfam/ui-kit';
import { ForgotService } from '../../../shared/forgot.service';
import { ForgotPasswordAnalytics } from '../../shared/forgot-password-analytic-constants';

import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../core/store';

@Component({
  selector: 'ds-reset-password-call',
  templateUrl: './reset-password-call.component.html',
  styleUrls: ['../../forgot-password.component.scss']
})
export class ResetPasswordCallComponent implements OnInit, OnDestroy {
  pinConfirmData: VerifyPinRequest;
  phone: string;
  userIdentifier: string;
  partnerId: string;
  phoneList: string[];
  showCodeEntry = false;
  codeNotFound = false;
  gotError = false;
  phoneLocked = false;

  constructor(
    private forgotService: ForgotService,
    private spinner: LoadingSpinnerService,
    private toaster: ToasterService,
    private analyticsFacade: AnalyticsFacade,
    private store: Store<fromRoot.RootState>
  ) {}

  ngOnInit() {
    combineLatest(
      this.store.select(fromRoot.getResetPasswordState),
      this.store.select(BrandSelectors.getPartnerId),
      (resetPasswordState, partnerId) => {
        return {
          resetPasswordState: resetPasswordState,
          partnerId: partnerId
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        this.userIdentifier = _get(state, 'resetPasswordState.userIdentifier', '');
        this.phoneList = _get(state, 'resetPasswordState.userDetails.maskedPhoneNumbers');
        this.partnerId = state.partnerId;
        if (this.phoneList.length === 1) {
          this.sendCode(this.phoneList[0]);
        }
        this.pinConfirmData = {
          userId: this.userIdentifier,
          partnerId: this.partnerId
        };
      });

    this.analyticsFacade.trackPage(ForgotPasswordAnalytics.pageForgotPasswordCall);
  }

  sendCode(phone: string) {
    this.spinner.start();
    this.phoneLocked = false;
    this.gotError = false;
    this.phone = phone;

    const requestObj: ResetOptionModel = {
      userIdentifier: this.userIdentifier,
      typeOfVerificationMethodCode: 'PHONE CALL',
      maskedPhoneNumber: phone
    };

    this.forgotService
      .sendVerificationRequest(requestObj)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.stop();
          const callSuccess = 'We will be calling you shortly with your code';
          this.toaster.pop('success', callSuccess);
          this.showCodeEntry = true;
        },
        err => {
          this.spinner.stop();
          this.checkIfPhoneLocked(err);
          const callError = 'We had trouble scheduling your call';
          this.gotError = true;
          this.toaster.pop('error', callError);
          this.showCodeEntry = false;
        }
      );
  }

  checkIfPhoneLocked(err: any) {
    const apiCode = _get(err, 'status.messages[0].code');
    if (apiCode === 400010) {
      this.phoneLocked = true;
    }
  }

  goBackOneStep() {
    this.store.dispatch(
      new fromRouterActions.Go({
        path: ['/forgot-password/reset-options']
      })
    );
  }

  ngOnDestroy() {
    this.spinner.stop();
  }
}

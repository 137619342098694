<div *dsPartnerAFI>
  <div class="agents-list" *ngIf="(areAllAgentsLoaded | async) && (agents | async).length > 0">
    <h4 *ngIf="(agents | async).length === 1 && !isConnectPartner" data-cy="myAgentHeader">My Agent</h4>
    <h4 *ngIf="(agents | async).length > 1 && !isConnectPartner">My Agents</h4>
    <ul class="agent-list-item-wrapper">
      <li *ngFor="let agent of (agents | async)">
        <agent-item  [agent]="agent"></agent-item>
      </li>
    </ul>
  </div>
</div>
<div *dsPartnerConnect class="agents-list">
  <h4>Contact Us</h4>
  <ul class="agent-list-item-wrapper">
    <li><agent-item></agent-item></li>
  </ul>
</div>

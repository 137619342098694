// Angular
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// rxjs
import { skip, map, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

// Store
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import { fromRouterActions } from '@amfam/shared/utility/navigation';

// Models
import { Step } from '../../shared/models/step';

// Services
import { NavigationService } from '@amfam/shared/utility/navigation';

// Misc
import { has as _has, forEach as _forEach, cloneDeep as _cloneDeep, get as _get } from 'lodash';

@Injectable()
export class WorkflowService {
  private activeStepSub: Subscription;
  private eventsSub: Subscription;
  private workflowSub: Subscription;

  constructor(private store: Store<fromRoot.RootState>, private navigator: NavigationService) {}

  /**
   * This method subscribes to changes to the activeStep and navigates to route for that step
   */
  workflowSubscribe(activatedRoute: ActivatedRoute) {
    this.activeStepSub = this.store
      .select(fromRoot.getWorkflowActiveStep)
      .pipe(skip(1), distinctUntilChanged())
      .subscribe(step => {
        if (step && step.route && step.route.length > 0) {
          // TODO: don't navigate if already at that
          this.navigator.navigate(step.route, {
            relativeTo: activatedRoute,
            queryParams: _get(activatedRoute, 'snapshot.queryParams')
          });
        }
      });
  }

  /**
   * This method unsubscribes for any active step subscriptions
   */
  workflowUnsubscribe() {
    if (this.activeStepSub) {
      this.activeStepSub.unsubscribe();
    }
  }

  workflowError() {
    this.store.dispatch(
      new fromRouterActions.Go({
        path: ['/enroll/contact-us']
      })
    );
  }

  /**
   * This method data for the progress bar from the workflow
   */
  getProgressBarData(): Observable<any> {
    return this.store.select(fromRoot.getWorkflowState).pipe(
      map(data => {
        const progressBarData: Step[] = [];
        const steps = _cloneDeep(data.steps);
        _forEach(steps, step => {
          if (_has(step, 'show') && step.show === true) {
            progressBarData.push({
              name: Symbol(step.name),
              title: step.title,
              complete: step.complete,
              skipped: step.skipped,
              nextStep: step.nextStep,
              active: step.title === _get(data.steps[data.activeStep], 'title', '') ? true : false,
              routes: []
            });
          }
        });
        return progressBarData;
      })
    );
  }
}

import { Routes } from '@angular/router';

import { ErrorPageComponent } from './error-page/error-page.component';
import { ErrorGuard } from './error.guard';

export const ErrorRoutes: Routes = [
  {
    path: 'error',
    component: ErrorPageComponent,
    canDeactivate: [ErrorGuard]
  }
];

export const ErrorGuards = [ErrorGuard];

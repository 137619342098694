import { ApiStatus, PartyEmail } from '@amfam/shared/models';
import { PageAnalytic, ButtonAnalytic } from '@amfam/shared/utility/shared-services';

export interface GoPaperlessModel {
  loading?: boolean;
  hasError?: boolean;
  status?: ApiStatus;
  isEnrolled?: boolean;
  correlationId?: any;
}

export enum GenerateEmailConstants {
  GoPaperlessOptOut = 'GoPaperlessOptOut',
  GoPaperlessOptIn = 'GoPaperlessOptIn',
  MAA_CommPrefChange_OptIn = 'MAA_CommPrefChange_OptIn',
  MAA_CommPrefChange_OptOut = 'MAA_CommPrefChange_OptOut',
  MAA_StatementPrefChange_OptBoth = 'MAA_StatementPrefChange_OptBoth'
}

export const initialState: GoPaperlessModel = {
  loading: false,
  hasError: false,
  status: null,
  isEnrolled: false,
  correlationId: ''
};

export interface GenerateEmailModel {
  loading?: boolean;
  hasError?: boolean;
  status?: ApiStatus;
  operation: string;
}

export interface EDeliveryOptingsModel {
  authId: string;
  party: {
    edeliveryIndicator: string;
    partyVersion: string;
    updatedOn: Date;
    typeOfPartyCode: string;
    sourceSystemInformation: {
      sourceSystemName: SourceSystemType;
      sourceSystemKey: string;
    };
    contactMethodDetail: {
      emails: PartyEmail[];
    };
  };
}

export interface GoPaperlessSuccessModel {
  status?: ApiStatus;
  isEnrolled: boolean;
  correlationId?: any;
}

export interface GoPaperLessAnalyticsModel {
  pageAnalytic?: PageAnalytic;
  buttonAnalytic?: ButtonAnalytic;
}

export enum SourceSystemType {
  MYACCOUNT = 'MYACCOUNT',
  ADMIN = 'MYACCTADMN'
}

// eslint-disable-next-line
import {
  BillAccount,
  BillAccountData,
  DeliveryPreferences,
  ONLINE_BILLING,
  PAPER
} from '@amfam/shared/models';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get, some as _some } from 'lodash';
import * as fromBillingState from './billaccount.reducer';
import { BILLING_FEATURE_KEY } from './billaccount.reducer';
import * as fromBillingNotifications from './notifications.reducer';

export interface BillingState {
  billingState: fromBillingState.BillingEntityState;
  billingNotifications: fromBillingNotifications.NotificationState;
}

export const billingReducers: ActionReducerMap<BillingState> = {
  billingState: fromBillingState.reducer,
  billingNotifications: fromBillingNotifications.reducer
};

// Lookup the 'Billing' feature state managed by NgRx
export const getBillAccountsState = createFeatureSelector<BillingState>(BILLING_FEATURE_KEY);

/**
 * BillAccounts State Selectors
 */

export const getBillAccountsLoaded = createSelector(
  getBillAccountsState,
  (state: BillingState) => !_get(state, 'billingNotifications.loading', true)
);

export const getAllBillAccounts = createSelector(
  getBillAccountsState,
  getBillAccountsLoaded,
  (state: BillingState, isLoaded) =>
    isLoaded ? fromBillingState.selectAll(state.billingState) : []
);

export const getBillAccountEntities = createSelector(
  getBillAccountsState,
  (state: BillingState) => state.billingState.entities
);

export const getBillAccountIds = createSelector(
  getBillAccountsState,
  getBillAccountsLoaded,
  (state: BillingState, isLoaded) =>
    isLoaded ? <string[]>fromBillingState.selectIds(state.billingState) : []
);

const getSelectedBillAccountId = createSelector(
  getBillAccountsState,
  (state: BillingState) => state.billingState.selectedBillAccountNumber
);

const getSelectedBillAccount = createSelector(
  getAllBillAccounts,
  getSelectedBillAccountId,
  (billAccounts = [], id) => {
    const result = billAccounts.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : [];
  }
);

export const getBillAccounts = createSelector(
  getBillAccountEntities,
  getBillAccountIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const getBillAccount = (billAccountNumber: string) =>
  createSelector(getBillAccountEntities, entities => entities[billAccountNumber]);

export const getUserRegisteredBillAccounts = createSelector(getBillAccounts, accounts =>
  accounts.filter(
    billAcc =>
      _get(billAcc, 'billingMethod') === ONLINE_BILLING &&
      !_get(billAcc, 'registeredElsewhere', false) &&
      !_get(billAcc, 'enabledAFT', false)
  )
);

export const getAllRegisteredBillAccounts = createSelector(getBillAccounts, accounts =>
  accounts.filter(billAccs => _get(billAccs, 'billingMethod') === ONLINE_BILLING)
);

export const getUnregisteredBillAccounts = createSelector(getBillAccounts, billAccounts =>
  billAccounts.filter(billAcc => _get(billAcc, 'billingMethod') === PAPER)
);

/**
 * Returns billingPreferences object for the bill account.
 */
export const getBillAccountDataToUpdatePreferences = billAccountNumber =>
  createSelector(getBillAccounts, billAccounts => {
    const matchingBillAccount = billAccounts.find(
      billAccount => billAccount.billAccountNumber === billAccountNumber
    );
    const billAccountData: BillAccountData = {
      billingPreferences: _get(matchingBillAccount, 'billingPreferences'),
      associated: _get(matchingBillAccount, 'associated'),
      billingMethod: _get(matchingBillAccount, 'billingMethod')
    };
    return billAccountData;
  });

export const getMatchedBillAccount = createSelector(
  getBillAccountEntities,
  (billAccounts: { [id: string]: BillAccount }, billAccountNumber: string | number) => [
    billAccounts[billAccountNumber]
  ]
);

/**
 * Returns true if all billaccounts are paperless
 */
export const getBillAccountsArePaperless = createSelector(getBillAccounts, billAccounts => {
  const allPaperless = billAccounts.every(billAccount => {
    if (_get(billAccount, 'billingPreferences.preferences')) {
      const accountIsPaperless = !billAccount.billingPreferences.preferences.some(
        pref =>
          pref.preferenceCode.toLowerCase() === 'documents' &&
          pref.preferenceDeliveryCode.toLowerCase() === DeliveryPreferences.PAPER
      );
      return accountIsPaperless;
    }
  });
  return allPaperless;
});

/**
 * BillAccounts Notification Selectors
 */

export const getBillAccountsLoading = createSelector(getBillAccountsState, (state: BillingState) =>
  _get(state, 'billingNotifications.loading', true)
);

export const getBillAccountsError = createSelector(getBillAccountsState, (state: BillingState) =>
  _get(state, 'billingNotifications.error')
);

export const getBillAccountsHasError = createSelector(
  getBillAccountsState,
  (state: BillingState) => !!_get(state, 'billingNotifications.error')
);

export const getAllBillAccountsNotifications = createSelector(
  getBillAccountsState,
  getBillAccountsLoaded,
  (state: BillingState, isLoaded) =>
    isLoaded ? fromBillingNotifications.selectAll(state.billingNotifications) : []
);

export const getBillAccountNotificationsEntities = createSelector(
  getBillAccountsState,
  (state: BillingState) => state.billingNotifications.entities
);

/**
 * added this selector to get bill account statement history document status if it's loaded or not
 *
 * @param billAccountNumber
 * @returns boolean
 */
export const getBillAccountDocumentLoaded = (billAccountNumber: string) =>
  createSelector(getBillAccountNotificationsEntities, entities => {
    if (billAccountNumber) {
      return entities[billAccountNumber]?.documentsLoaded;
    } else {
      return Object.values(entities).every(entity => entity.documentsLoaded);
    }
  });

const getBillAccountNotificationsIds = createSelector(
  getBillAccountsState,
  getBillAccountsLoaded,
  (state: BillingState, isLoaded) =>
    isLoaded ? <string[]>fromBillingNotifications.selectIds(state.billingNotifications) : []
);

export const getBillAccountsNotifications = createSelector(
  getBillAccountNotificationsEntities,
  getBillAccountNotificationsIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const getMatchedBillAccountNotification = createSelector(
  getBillAccountNotificationsEntities,
  (
    billAccounts: { [id: string]: fromBillingNotifications.BillingNotificationsState },
    billAccountNumber: string | number
  ) => billAccounts[billAccountNumber]
);

// AS: Get the list of bill accounts which failed during the registration process in the
// current session.
export const getBillAccountsWithRegistrationFailure = createSelector(
  getBillAccountsNotifications,
  billAccounts =>
    billAccounts.filter(billAcc => !!_get(billAcc, 'updateRegistrationError') === true)
);

// AS: Get the list of billaccounts which failed during the updation of the preference in the
// current session.
export const getBillAccountsWithUpdatePreferenceFailure = createSelector(
  getBillAccountsNotifications,
  billAccounts => billAccounts.filter(billAcc => !!_get(billAcc, 'updatePreferencesError') === true)
);

export const getBillAccountsAnyLoading = createSelector(
  getBillAccountsNotifications,
  getBillAccountsLoading,
  (billAccounts, billAccountsLoading) =>
    billAccountsLoading || _some(billAccounts, { loading: true })
);

export const getBillAccountsAnyPrefsLoading = createSelector(
  getBillAccountsNotifications,
  getBillAccountsLoading,
  (billAccounts, billAccountsLoading) =>
    billAccountsLoading ||
    _some(billAccounts, { prefsFinishedLoading: false }) ||
    _some(billAccounts, { loading: true })
);

export const getBillAccountsUpdatingPreferences = createSelector(
  getBillAccountsNotifications,
  billAccounts => _some(billAccounts, { updatingPreferences: true })
);

export const getBillAccountsUpdatingRegistration = createSelector(
  getBillAccountsNotifications,
  billAccounts => _some(billAccounts, { updatingRegistration: true })
);

export const getBillAccountsUpdatingPolicyList = createSelector(
  getBillAccountsNotifications,
  billAccounts => _some(billAccounts, { riskDetailsLoaded: false })
);

// To hide biling preferences section if there is partial/retrieve preferences error.
export const isEligibleToViewPreferences = createSelector(
  getBillAccountsNotifications,
  billAccounts => !_some(billAccounts, { loadPrefsError: true })
);

export const getBillAccountCancelPath = createSelector(
  getBillAccountsState,
  (state: BillingState) => _get(state, 'billingNotifications.cancelNavigationPath')
);

export const getAllBillingPreferencesLoaded = createSelector(
  getBillAccountsNotifications,
  billAccounts => !_some(billAccounts, { prefsFinishedLoading: false })
);

// bill account which are not readonly.
// DM: need here so we can use notifications entities
export const getModifiableBillAccounts = createSelector(
  getBillAccounts,
  getBillAccountNotificationsEntities,
  (billAccounts, notifications) =>
    billAccounts.filter(
      billAccount =>
        !billAccount.readOnly &&
        _get(notifications[billAccount.billAccountNumber], 'prefsFinishedLoading', true)
    )
);

export const hasPastDue = createSelector(getBillAccounts, billAccounts =>
  _some(billAccounts, { pastDue: true })
);

export const billaccountsQuery = {
  getBillAccountCancelPath,
  getBillAccountsArePaperless,
  getMatchedBillAccount,
  isEligibleToViewPreferences,
  getBillAccountsUpdatingPolicyList,
  getBillAccountsUpdatingRegistration,
  getBillAccountsUpdatingPreferences,
  getBillAccountsAnyLoading,
  getBillAccountsAnyPrefsLoading,
  getBillAccountDataToUpdatePreferences,
  getBillAccountsWithUpdatePreferenceFailure,
  getBillAccountsWithRegistrationFailure,
  getUnregisteredBillAccounts,
  getAllRegisteredBillAccounts,
  getUserRegisteredBillAccounts,
  getModifiableBillAccounts,
  getBillAccounts,
  getSelectedBillAccount,
  getBillAccountIds,
  getBillAccountEntities,
  getAllBillAccounts,
  getAllBillAccountsNotifications,
  getBillAccountsHasError,
  getBillAccountsError,
  getBillAccountsLoading,
  getBillAccountsLoaded,
  getBillAccountsState,
  getAllBillingPreferencesLoaded,
  hasPastDue
};

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-table-body-row-wrapper',
  templateUrl: './table-body-row-wrapper.component.html'
})
export class DsTableBodyRowWrapperComponent implements OnInit {
  @Input() rowDetail: any;
  @Input() expanded = false;
  @Input() row: any;

  constructor() {}

  ngOnInit() {}
}

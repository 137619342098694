import { Enrollment } from './models';
import * as maeEnrollment from './mae-enrollment.actions';
import { Entities, initialEntities } from '../entity/entity.model';

import { keyBy as _keyBy, values as _values } from 'lodash';

export function reducer(
  state = initialEntities<Enrollment>(),
  action: maeEnrollment.Actions
): Entities<Enrollment> {
  let entities = {};

  switch (action.type) {
    case maeEnrollment.LOAD_SUCCESS:
      entities = _keyBy(action.payload, (enrollment: Enrollment) => enrollment.title);
      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities,
        loaded: true,
        loading: false
      });
    case maeEnrollment.LOAD_FAIL:
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        error: action.payload
      });
    default:
      return state;
  }
}

export const getEntities = (state: Entities<Enrollment>) => state.entities;

export const getIds = (state: Entities<Enrollment>) => state.ids;

export const getError = (state: Entities<Enrollment>) => state.error;

export const getLoaded = (state: Entities<Enrollment>) => state.loaded;

export const getLoading = (state: Entities<Enrollment>) => state.loading;

import { of as observableOf, from as observableFrom, Observable } from 'rxjs';

import { withLatestFrom, map, mergeMap, catchError, toArray } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Policy } from '@amfam/policy/models';
import { GenericProductType, SourceSystemType } from '@amfam/policy/models';
import * as kydActions from './kyd.actions';
import { PolicySelectors, PolicyActions } from '@amfam/policy/data-access';
import { PolicySummarySelectors, PolicySummaryActions } from '@amfam/policy/data-access';
import { PolicyDocumentsSelectors } from '@amfam/policy/data-access';
import * as fromRoot from '../../';

import { KydService } from '../../../../policy/know-your-drive/shared/kyd.service';

@Injectable()
export class KydEffects {
  
  load$ = createEffect(() => this.action$.pipe(
    ofType(kydActions.LOAD),
    map((action: kydActions.LoadAction) => action.payload),
    mergeMap(autoPolicies => {
      return observableFrom(autoPolicies).pipe(
        mergeMap(autoPolicy => this.kydService.getPolicyEnrollments(autoPolicy.policyNumber)),
        toArray()
      );
    }),
    map(enrollmentResponses => {
      this.store.dispatch(new kydActions.LoadSuccessAction(enrollmentResponses));
      return new kydActions.LoadCompleteAction();
    }),
    catchError(error => observableOf(new kydActions.LoadFailAction(error)))
  ));

  
  refresh$ = createEffect(() => this.action$.pipe(
    ofType(kydActions.REFRESH),
    map((action: kydActions.RefreshAction) => action.payload),
    withLatestFrom(this.store.select(PolicySelectors.getAdvanceAutoPolicies)),
    mergeMap(([policyNumbers, autoPolicies]) =>
      observableOf(
        autoPolicies.filter(policy =>
          policyNumbers.some(policyNumber => policy.policyNumber === policyNumber)
        )
      )
    ),
    map(policies => new kydActions.LoadAction(policies))
  ));

  constructor(
    private store: Store<fromRoot.RootState>,
    private kydService: KydService,
    private action$: Actions
  ) {}
}

import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params, PRIMARY_OUTLET } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { NavigationService } from '@amfam/shared/utility/navigation';
import { fromRouterActions } from '@amfam/shared/utility/navigation';

import { Store } from '@ngrx/store';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: any[];
  suppressBreadcrumbs = false;

  constructor(
    private router: Router,
    private store: Store<any>,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {
    this.breadcrumbs = [];
  }

  ngOnInit() {
    this.buildBreadcrumbs();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.suppressBreadcrumbs = false;
      this.buildBreadcrumbs();
    });
  }

  private buildBreadcrumbs() {
    // skip the AppComponent route, go straight to the Account route.
    let account: ActivatedRoute = this.route.root.firstChild.firstChild;
    // skip the :expid route if found
    if (this.navigationService.getExperienceId() !== '') {
      account = account.firstChild;
    }
    this.breadcrumbs = this.getBreadcrumbs(account);
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const USE_RESOLVED = 'useResolvedData';
    const RESOLVED_DATA = 'resolvedData';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      // skip over routes that aren't active
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // skip over data-less routes
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        continue;
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      const labelReference = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
      if (typeof labelReference === 'boolean' && labelReference === false) {
        return breadcrumbs;
      }

      let label;
      if (typeof labelReference === 'string') {
        // Remove breadcrumbs for "action" pages as requested by UXD
        if (labelReference === 'none') {
          this.suppressBreadcrumbs = true;
          return;
        }

        if (labelReference !== USE_RESOLVED) {
          label = new BehaviorSubject(labelReference);
        } else {
          label = new BehaviorSubject(child.snapshot.data[RESOLVED_DATA]);
        }
      }

      if (typeof labelReference === 'function') {
        label = (function () {
          const params = Object.keys(child.snapshot.params)
            .map(key => {
              return child.snapshot.params[key];
            })
            .join(' - ');

          // initialize our BS with the param value as a placeholder.
          const subject = new BehaviorSubject(params);

          // call the provided function, subscribe to async response and overwrite the placeholder.
          labelReference(child.snapshot.params).subscribe(response => {
            subject.next(response);
          });

          return subject;
        })();
      }

      // add breadcrumb
      const breadcrumb = {
        label: label,
        params: child.snapshot.params,
        url: url
      };

      if (labelReference !== 'lazy_loaded_module') {
        breadcrumbs.push(breadcrumb);
      }

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  navigateTo(href: string) {
    this.store.dispatch(
      new fromRouterActions.Go({
        path: [href]
      })
    );
  }
}

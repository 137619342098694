import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberSingleStringFormatterPipe } from '@amfam/shared/ui/pipes';
import { get as _get } from 'lodash';

@Pipe({
  name: 'phoneDescription'
})
export class PhoneDescriptionPipe extends PhoneNumberSingleStringFormatterPipe
  implements PipeTransform {
  transform(value: any, args?: any): string {
    if (_get(value, 'tenDigitPhoneNumber')) {
      const phoneDescription = super.transform(value.tenDigitPhoneNumber, value.extension);
      return phoneDescription;
    }
  }
}

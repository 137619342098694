import { AnalyticsFacade } from '@amfam/shared/analytics';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { delay, take } from 'rxjs/operators';
import { CustomerFeedbackEnum } from '../../models/feedback';
import { OpportunityAnalytics } from '../../models/opportunity-analytic-contants';
import { OpportunitiesCardInterface } from '../../models/slide';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'ds-opportunity-card',
  templateUrl: './opportunity-card.component.html',
  styleUrls: ['./opportunity-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityCardComponent implements OnInit {
  @Input() opportunityCardInformation: OpportunitiesCardInterface;
  @Input() recommendationId: string;
  @Input() successMessage: string;
  @Input() nothanksSuccessMessage: string;
  @Input() failureMessage: string;
  @Input() isAuthorized: boolean;

  @Output() learnMoreEvent = new EventEmitter<string>();
  customerFeedbackEnum = CustomerFeedbackEnum;

  constructor(
    private analyticsFacade: AnalyticsFacade,
    private opportunityService: OpportunityService
  ) {}

  ngOnInit() {
    this.opportunityService
      .buildPageAnalyticData(OpportunityAnalytics.overviewPageOpporutunity, this.recommendationId)
      .pipe(delay(0), take(1))
      .subscribe(pageAnalytic => this.analyticsFacade.trackPage(pageAnalytic));
  }
}

import { ApiStatus } from '@amfam/shared/models';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AuthenticatePayload,
  AuthenticateSuccessResponse,
  CheckEmailAddressPayload,
  CheckEmailAddressResponse,
  CreateDigitalAccountPayload,
  DigitalAccount,
  GetDigitalAccountByCdhIdResponse,
  GetDigitalAccountByWaidResponse,
  RefreshSuccessResponse
} from '../+state/digital-account/digital-account.model';
import { DigitalAccountAdapter } from './adapters/digital-account.adapter';

@Injectable({
  providedIn: 'root'
})
export class DigitalAccountService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private digitalAccountAdapter: DigitalAccountAdapter
  ) {}

  createDigitalAccount(digitalAccountPayload: CreateDigitalAccountPayload): Observable<ApiStatus> {
    const body = JSON.stringify(digitalAccountPayload);
    const endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts';
    return this.http.post<ApiStatus>(endpoint, body);
  }

  getDigitalAccountByWaid(waid: string): Observable<DigitalAccount> {
    const endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts/' + waid;
    return this.http
      .get<GetDigitalAccountByWaidResponse>(endpoint)
      .pipe(
        map((response: GetDigitalAccountByWaidResponse) =>
          this.digitalAccountAdapter.adaptGetDigitalAccountByWaid(response)
        )
      );
  }

  /**
   * @param customerId - Customer ID
   * https://intssg.amfam.com/digitalaccount/v1/digitalaccount?customerId=<partyId || cdhid>
   */
  getDigitalAccountByCdhId(cdhId: string): Observable<DigitalAccount> {
    const endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts';
    const params = new HttpParams().set('customerId', cdhId);
    return this.http
      .get<GetDigitalAccountByCdhIdResponse>(endpoint, { params })
      .pipe(
        map((response: GetDigitalAccountByCdhIdResponse) =>
          this.digitalAccountAdapter.adaptGetDigitalAccountByCdhId(response, cdhId)
        )
      );
  }

  updateDigitalAccount(
    digitalAccount: DigitalAccount,
    waid: string,
    partnerId: string,
    experienceId: string
  ): Observable<any> {
    const endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts/' + waid;
    const body = JSON.stringify({
      partnerId: partnerId,
      experienceId: experienceId,
      digitalAccount: digitalAccount
    });
    return this.http.put(endpoint, body);
  }

  authenticate(authPayload: AuthenticatePayload): Observable<AuthenticateSuccessResponse> {
    const endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts/authenticate';
    const body = {
      rememberMe: authPayload.rememberMe,
      partnerId: authPayload.partnerId
    };
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa(authPayload.userId + ':' + authPayload.password)
      })
    };
    return this.http.post<AuthenticateSuccessResponse>(endpoint, body, options);
  }

  checkUserIdAvailability(userId: string): Observable<any> {
    const endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts?userId=' + userId;
    return this.http.get(endpoint);
  }

  checkEmailAddressAvailability(email: string, partnerId: string): Observable<any> {
    let endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts';
    // DM: Cannot use HttpParams since it unencodes certain special characters
    endpoint += '?emailAddress=' + encodeURIComponent(email);
    endpoint += partnerId ? '&partnerId=' + encodeURIComponent(partnerId) : '';
    return this.http.get(endpoint);
  }
  /**
   *  https://intssg.amfam.com/digitalaccount/v1/digitalaccounts/refresh
   */
  refresh(): Observable<RefreshSuccessResponse> {
    const refreshUrl = this.config.get('digitalAccountApi') + '/digitalaccounts/refresh';
    return this.http.post<RefreshSuccessResponse>(refreshUrl, '');
  }
  /**
   * This function is built for myaccount-admin enrollment flow, it may replace the checkEmailAddressAvailability() in future
   * @param email
   * @param partnerId
   * @returns
   */
  checkEmailAddress(payload: CheckEmailAddressPayload): Observable<CheckEmailAddressResponse> {
    let endpoint = this.config.get('digitalAccountApi') + '/emails';
    // DM: Cannot use HttpParams since it unencodes certain special characters
    endpoint += '?emailAddress=' + encodeURIComponent(payload.emailAddress);
    endpoint += payload.partnerId ? '&partnerId=' + encodeURIComponent(payload.partnerId) : '';
    return this.http
      .get<CheckEmailAddressResponse>(endpoint)
      .pipe(
        map(response =>
          this.digitalAccountAdapter.adaptCheckEmailAddress(response, payload.correlationId)
        )
      );
  }
}

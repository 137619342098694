import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InsuranceCardComponent } from './insurance-card/insurance-card.component';
import {
  OverviewCardComponent,
  OverviewCardContentComponent,
  OverviewCardCtaComponent,
  OverviewCardHeadingComponent,
  OverviewCardSubheadingComponent
} from './overview-card';
import {
  OverviewSectionComponent,
  OverviewSectionContentComponent,
  OverviewSectionHeaderLinkComponent
} from './overview-section/overview-section.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from "../../../../shared/ui/pipes/src/lib/pipes.module";

@NgModule({
    declarations: [
        OverviewCardComponent,
        OverviewCardContentComponent,
        OverviewCardCtaComponent,
        OverviewCardHeadingComponent,
        OverviewCardSubheadingComponent,
        OverviewSectionComponent,
        OverviewSectionContentComponent,
        OverviewSectionHeaderLinkComponent,
        InsuranceCardComponent
    ],
    exports: [
        OverviewCardComponent,
        OverviewCardContentComponent,
        OverviewCardCtaComponent,
        OverviewCardHeadingComponent,
        OverviewCardSubheadingComponent,
        OverviewSectionComponent,
        OverviewSectionContentComponent,
        OverviewSectionHeaderLinkComponent,
        InsuranceCardComponent
    ],
    imports: [CommonModule, UiKitModule, RouterModule, PipesModule]
})
export class OverviewUiModule {}

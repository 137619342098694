<div>
  <ds-preferences-init
    *ngIf="!setPreferencesSuccess"
    [billingEligibleToGoPaperless]="billingEligibleToGoPaperless$ | async"
    [isPolicyGoPaperless]="isPolicyGoPaperless$ | async"
    [routeToConfirmation]="routeToConfirmation"
    (goPaperlessClickEvent)="goPaperlessEvent($event)"
  >
  </ds-preferences-init>
</div>

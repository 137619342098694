import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { EnrollNotificationModel } from '../../../core/store/mae';

import { ValidationService } from '@amfam/ui-kit';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { EnrollAnalytics } from '../shared/enroll-constants';

@Component({
  selector: 'enroll-terms',
  templateUrl: './enroll-terms.component.html',
  styleUrls: ['./enroll-terms.component.scss']
})
export class EnrollTermsComponent implements OnInit {
  @Input() propertyServiceUrl: string;
  @Input() model: EnrollNotificationModel;

  @Output()
  onSubmit = new EventEmitter<boolean>();

  public termsForm: UntypedFormGroup;

  private _agreeToTerms: boolean;
  public hasSelectedAgreeToTermsOption: boolean;

  get agreeToTerms(): boolean {
    return this._agreeToTerms;
  }

  set agreeToTerms(agreeToTerms: boolean) {
    this._agreeToTerms = agreeToTerms;
    this.hasSelectedAgreeToTermsOption = agreeToTerms !== undefined && agreeToTerms !== null;
  }

  constructor(private analyticsFacade: AnalyticsFacade, private formBuilder: UntypedFormBuilder) {}

  submitForm(): void {
    this.onSubmit.emit(this.agreeToTerms);
  }

  ngOnInit() {
    this.termsForm = this.formBuilder.group(
      { agreeToTerms: [null] },
      { validator: ValidationService.requiresOneOrMore }
    );

    this.analyticsFacade.trackPage(EnrollAnalytics.enrollTermsPageAnalytic);
  }
}

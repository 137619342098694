import { Component, OnInit } from '@angular/core';
import { CopyService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-email-validation',
  templateUrl: './email-validation.component.html'
})
export class EmailValidationComponent implements OnInit {
  contactNumber: string;

  constructor(private copyService: CopyService) {}

  ngOnInit() {
    this.contactNumber = this.copyService.getCopy('shared.amfamPhoneNumber');
  }
}

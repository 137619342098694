export class Address {
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public state: string;
  public zipCode: string;
  public zipCode5: string;
  public zipCode4: string;
  public addressType: string;

  static fromJson(json: any): Address {
    const address = new Address();
    if (json) {
      address.addressLine1 = this.trim(json.addressLine1 || json.addr1 || json.line1);
      address.addressLine2 = this.trim(json.addressLine2 || json.addr2 || json.line2);
      address.city = this.trim(json.city);
      address.state = this.trim(json.state);
      address.zipCode = this.trim(json.zipCode);
      address.zipCode5 = this.trim(json.zipCode5 || json.zip5);
      address.zipCode4 = this.trim(json.zipCode4);
      address.addressType = this.trim(json.addressType);
    }

    return address;
  }

  private static trim(value: any) {
    if (typeof value === 'string') {
      return value.trim();
    } else {
      return value;
    }
  }

  public getStringifiedAddress() {
    let stringifiedAddress = this.addressLine1;
    if (this.addressLine2) stringifiedAddress += ' ' + this.addressLine2;
    stringifiedAddress += ' ' + this.city + ' ' + this.state;
    if (this.zipCode) {
      stringifiedAddress += ' ' + this.zipCode;
    } else {
      stringifiedAddress += ' ' + this.zipCode5;
      if (this.zipCode4) stringifiedAddress += '-' + this.zipCode4;
    }
    return stringifiedAddress;
  }
}

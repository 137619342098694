// Angular Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

// Lodash Imports
import { chain as _chain, differenceBy as _differenceBy } from 'lodash';

// My Account Imports
import {
  AutoPolicy,
  Coverage,
  PolicySummary,
  PolicyTypeIconConstants,
  RiskTypeIconConstants,
  SourceSystemType,
  Vehicle
} from '@amfam/policy/models';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { PolicyDocumentService } from './policy-documents.service';
import { TypedWorker } from './typed-worker';

@Injectable({
  providedIn: 'root'
})
export class PlautoService implements TypedWorker {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private policyDocumentService: PolicyDocumentService,
    private feature: FeatureFlagService
  ) {}

  /**
   * @description: Returns the productApi endpoint for autopolicies.
   */
  public getEndpoint() {
    return this.config.get('productApi') + 'autopolicies/';
  }

  public getPolicy(url: string, policySummary: PolicySummary): Observable<AutoPolicy> {
    return this.http.get(url).pipe(
      map((data: any) => this.loadModel(data)),
      map((policy: AutoPolicy) => {
        policy.productDescription = policySummary.productDescription;
        switch (policy.productDescription.toLowerCase()) {
          case 'cycle':
            for (const risk of policy.vehicles) {
              risk.iconType = RiskTypeIconConstants.MOTORCYCLE;
            }
            break;
          case 'recreational vehicle':
            for (const risk of policy.vehicles) {
              risk.iconType = RiskTypeIconConstants.RV;
            }
            break;
          case 'watercraft':
            for (const risk of policy.vehicles) {
              risk.iconType = RiskTypeIconConstants.WATERCRAFT;
            }
            break;
          default:
            for (const risk of policy.vehicles) {
              risk.iconType = PolicyTypeIconConstants.AUTO;
            }
            break;
        }
        if (policy.vehicles.length > 0) {
          policy.iconType = policy.vehicles[0].iconType;
        }
        return policy;
      }),
      mergeMap(policy => this.policyDocumentService.getPOILinks(policy))
    );
  }

  public loadModel(json: any): AutoPolicy {
    let policy: any = json;
    if (json.policy) {
      policy = json.policy;
    }
    return AutoPolicy.fromJson(policy);
  }

  public getPolicyLevelCoverages(policy: AutoPolicy, vehicleId?: string): Array<Coverage> {
    if (policy.sourceSystem === SourceSystemType.Advance) {
      return _chain(policy.vehicles.filter(v => v.vehicleId === (vehicleId ? vehicleId : v.vehicleId)))
        .flatMap(x => x.vehicleCoverages)
        .filter(x => x.appliesToMultipleRisks)
        .filter((x, index, collection) => {
          if (vehicleId) return true;
          const count = policy.vehicles.filter(y =>
            y.vehicleCoverages.some(z => z.code === x.code)
          ).length;
          return count === policy.vehicles.length;
        })
        .unionBy(policy.policyCoverages, x => x.code)
        .sortBy(x => x.code)
        .value();
    } else {
      return policy.policyCoverages;
    }
  }

  public getRiskLevelCoverages(
    policy: AutoPolicy,
    risk: Vehicle,
    onlyVehicle = false
  ): Array<Coverage> {
    if (policy.sourceSystem === SourceSystemType.Advance) {
      /** Only return coverages that DO NOT EXIST in ALL of the other risks */
      const riskLevelCoverage = _differenceBy(
        risk.vehicleCoverages,
        this.getPolicyLevelCoverages(policy, onlyVehicle ? risk.vehicleId : undefined),
        'code'
      );
      return riskLevelCoverage.sort(Coverage.compare);
    } else {
      return risk.vehicleCoverages;
    }
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CLAIM_FEATURE_KEY,
  ClaimState,
  selectClaimEntities,
  selectEntityIds,
  selectAllClaims
} from './claim.reducer';

import { get as _get, some as _some } from 'lodash';
import { isAfter, subYears } from 'date-fns';

const getClaimsState = createFeatureSelector<ClaimState>(CLAIM_FEATURE_KEY);

const getClaimEntities = createSelector(getClaimsState, selectClaimEntities);

const getClaimIds = createSelector(getClaimsState, selectEntityIds);

const getClaimsLoading = createSelector(getClaimsState, (state: ClaimState) => state.loading);

const getClaimsLoaded = createSelector(getClaimsState, (state: ClaimState) => state.loaded);

const getSelectedId = createSelector(getClaimsState, (state: ClaimState) => state.selectedEntityId);

const getClaimsStatus = createSelector(getClaimsState, (state: ClaimState) => state.status);

const getLatestClaimError = createSelector(getClaimsState, (state: ClaimState) => state.hasError);

const getSelectedClaim = createSelector(getClaimEntities, getSelectedId, (entities, selectedId) => {
  return entities[selectedId];
});

const getAllClaims = createSelector(getClaimsState, selectAllClaims);

const getHasHiddenClaims = createSelector(getClaimsState, state =>
  _get(state, 'hasHiddenClaims', false)
);

const getOpenClaims = createSelector(getAllClaims, claims => {
  return claims.filter(claim => {
    return (
      claim.claimStatus === 'OPEN' ||
      claim.claimStatus === 'IN_PROGRESS' ||
      claim.claimStatus === 'OPEN_REPORTED' ||
      claim.claimStatus === 'AUTOPAY_PENDING' ||
      claim.claimStatus === 'AUTOPAY_REVIEW' ||
      claim.claimStatus === 'AWAITING_SETUP'
    );
  });
});

const getClosedClaims = createSelector(getAllClaims, claims => {
  return claims.filter(claim => {
    return (
      claim.claimStatus === 'ARCHIVED' ||
      claim.claimStatus === 'CLOSED' ||
      claim.claimStatus === 'VOID' ||
      claim.claimStatus === 'CLOSED_AUTO_PAY' ||
      claim.claimStatus === 'CLOSED_ERROR' ||
      claim.claimStatus === 'REOPEN'
    );
  });
});

const getRecentClaims = createSelector(getAllClaims, claims => {
  const minDate = subYears(new Date(), 5);
  return claims.filter(claim => claim.claimStatus === 'OPEN' || isAfter(claim.closedDate, minDate));
});

const getClaimsAnyLoading = createSelector(
  getClaimsLoading,
  getAllClaims,
  (claimsLoading, claims) => {
    return claimsLoading || _some(claims, { loading: true });
  }
);

const getClaimsAnyError = createSelector(
  getLatestClaimError,
  getAllClaims,
  getClaimsStatus,
  (claimsError, claims, status) => {
    /**
     * We are only bothered by the status which do not have the code in the 400 range, We ignore the
     * 400's since that would be returned even if query has no data.
     */
    return (
      (!!claimsError || _some(claims, { hasError: true })) &&
      !_get(status, 'code', '')
        .toString()
        .match(/\b4\d{2}\b/)
    );
  }
);

const getClaimsNeedRefresh = createSelector(getClaimsState, claimState => {
  return claimState.needRefresh;
});

export const claimQuery = {
  getClaimsState,
  getClaimEntities,
  getClaimIds,
  getClaimsLoading,
  getClaimsLoaded,
  getSelectedId,
  getClaimsStatus,
  getLatestClaimError,
  getSelectedClaim,
  getAllClaims,
  getHasHiddenClaims,
  getOpenClaims,
  getClosedClaims,
  getRecentClaims,
  getClaimsAnyLoading,
  getClaimsAnyError,
  getClaimsNeedRefresh
};

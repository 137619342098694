export class Fee {
  public code: string;
  public name: string;
  public description: string;
  public amount: string;
  public value: string;
  public valueType: string;

  static fromJson(json: any): Fee {
    const fee = new Fee();
    fee.code = json.code;
    fee.name = json.name;
    fee.description = json.description;
    fee.amount = json.amount;
    fee.value = json.value;
    fee.valueType = json.valueType;

    return fee;
  }
}

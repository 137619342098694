export const policyOrder = [
  'auto',
  'property',
  'umbrella',
  'life',
  'motorcycle',
  'boat',
  'motorhome',
  'atv',
  'camper',
  'dirtbike',
  'farmmachinery',
  'golfcart',
  'mobilitydevice',
  'moped',
  'scooter',
  'snowmobile',
  'trailer',
  'utv',
  'operator'
];

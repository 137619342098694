<div class="main row">
  <div class="column twelve sm-ten sm-push-one md-eight md-push-two lg-six lg-push-three">
    <router-outlet></router-outlet>

    <div class="column twelve caption pad-top-10">
      <span content contentPath="auth.duplicateEmailloginTrouble"></span>
      <a dsPartnerContent content contentPath="shared.amfamPhoneNumber"></a>
    </div>
  </div>
</div>

import { PaymentMethodRadioOption } from '@amfam/shared/models';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup } from '@angular/forms';

@Component({
  selector: 'ds-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentMethodComponent implements ControlValueAccessor {
  @Input() paymentMethodOptions: PaymentMethodRadioOption[];
  @Input() formGroup: FormGroup;
  @Input() control: string;
  @Input() authorizedToAddPaymentMethod: boolean;
  @Input() authorizedToEditPaymentMethod: boolean;
  @Input() paymentMethodError: boolean;

  @Output() editPaymentMethodEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() addCheckingAccountEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() addSavingsAccountEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() addCreditDebitEvent: EventEmitter<void> = new EventEmitter<void>();

  choosePaymentMethod = this.copyService.getCopy('billing.oneTimePayRefactor.choosePaymentMethod');

  constructor(private copyService: CopyService) {}

  set value(val) {
    this.onChange(val);
    this.onTouched(val);
  }
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  radioButtonClick(option: PaymentMethodRadioOption) {
    this.formGroup.controls[this.control].setValue(option);
  }

  edit(id: string) {
    if (this.authorizedToEditPaymentMethod) {
      this.editPaymentMethodEvent.emit(id);
    }
  }

  addCheckingAccount(): void {
    if (this.authorizedToAddPaymentMethod) {
      this.addCheckingAccountEvent.emit();
    }
  }

  addSavingsAccount(): void {
    if (this.authorizedToAddPaymentMethod) {
      this.addSavingsAccountEvent.emit();
    }
  }

  addCreditDebit(): void {
    if (this.authorizedToAddPaymentMethod) {
      this.addCreditDebitEvent.emit();
    }
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
  selector: 'ds-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyComponent {
  @Input() currency: number;
}

import { Action } from '@ngrx/store';

// Registration check actions
export const CAN_REGISTER = '[BillAccountRegistration] Can Register';
export const CAN_REGISTER_SUCCESS = '[BillAccountRegistration] Can Register Success';
export const CAN_REGISTER_FAIL = '[BillAccountRegistration] Can Register Fail';
export const CAN_REGISTER_CLEAR = '[BillAccountRegistration] Can Register Clear';

export class CanRegisterAction implements Action {
  readonly type = CAN_REGISTER;
  constructor(public payload?: any) {}
}

export class CanRegisterSuccessAction implements Action {
  readonly type = CAN_REGISTER_SUCCESS;
  constructor(public payload?: any) {}
}

export class CanRegisterFailAction implements Action {
  readonly type = CAN_REGISTER_FAIL;
  constructor(public payload?: any) {}
}

export class CanRegisterClearAction implements Action {
  readonly type = CAN_REGISTER_CLEAR;
  constructor(public payload?: any) {}
}

export type BillAccountCanRegisterAction =
  | CanRegisterAction
  | CanRegisterSuccessAction
  | CanRegisterFailAction
  | CanRegisterClearAction;

export const fromBillAccountCanRegisterActions = {
  CanRegisterAction,
  CanRegisterSuccessAction,
  CanRegisterFailAction,
  CanRegisterClearAction
};

import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '@amfam/shared/utility/shared-services';
import { MaeStatus } from '../../core/store/mae/models';

@Injectable()
export class MaeService {
  private maeBaseUrl: string;

  constructor(private configService: ConfigService, private http: HttpClient) {
    this.maeBaseUrl = this.configService.get('maeApi');
  }

  getMaeStatus(enrollmentId: string): Observable<MaeStatus> {
    if (!enrollmentId) {
      return observableOf(new MaeStatus());
    }

    const url = this.maeBaseUrl + 'customerenrollments/verifyuser';
    const data = {
      verifyUserRequest: {
        correlationid: enrollmentId
      }
    };
    return this.http.post(url, data).pipe(
      map((json: any) => {
        if (json.CustomerEnrollment && json.CustomerEnrollment.length) {
          const enrollment = json.CustomerEnrollment[0];
          return new MaeStatus(
            enrollment.cdhId,
            enrollment.emailAddressSelected,
            enrollment.enrollmentStatus
          );
        }
        return new MaeStatus();
      })
    );
  }
}

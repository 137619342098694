import { Action } from '@ngrx/store';
import { EnrollStepsModel } from './enroll-steps.model';

export const PERSONAL_ID_COMPLETE = '[PERSONAL] Identification Step Complete';
export const VERIFY_COMPLETE = '[PERSONAL] Verification Step Complete';
export const PERSONAL_SIGNUP_COMPLETE = '[PERSONAL] Signup Step Complete';
export const PERSONAL_SECURITYQUESTIONS_COMPLETE = '[PERSONAL] Security Questions Step Complete';
export const BUSINESS_ENROLL_COMPLETE = '[BUSINESS] Enroll ID Step Complete';
export const BUSINESS_SIGNUP_COMPLETE = '[BUSINESS] Signup Step Complete';
export const BUSINESS_SECURITYQUESTIONS_COMPLETE = '[BUSINESS] Security Questions Step Complete';
export const RESET_STEPS = '[ENROLL] Reset Steps';

export class PersonalIDStepAction implements Action {
  type = PERSONAL_ID_COMPLETE;
  constructor(public payload: any) {}
}

export class VerifyStepAction implements Action {
  type = VERIFY_COMPLETE;
  constructor(public payload: any) {}
}

export class PersonalSignUpStepAction implements Action {
  type = PERSONAL_SIGNUP_COMPLETE;
  constructor(public payload: any) {}
}

export class PersonalSecurityQuestionsStepAction implements Action {
  type = PERSONAL_SECURITYQUESTIONS_COMPLETE;
  constructor(public payload: any) {}
}

export class BusinessEnrollStepAction implements Action {
  type = BUSINESS_ENROLL_COMPLETE;
  constructor(public payload: any) {}
}

export class BusinessSignUpStepAction implements Action {
  type = BUSINESS_SIGNUP_COMPLETE;
  constructor(public payload: any) {}
}

export class BusinessSecurityQuestionsStepAction implements Action {
  type = BUSINESS_SECURITYQUESTIONS_COMPLETE;
  constructor(public payload: any) {}
}

export class ResetEnrollStepsAction implements Action {
  type = RESET_STEPS;
  constructor(public payload?: any) {}
}

export type Actions =
  | PersonalIDStepAction
  | VerifyStepAction
  | PersonalSignUpStepAction
  | PersonalSecurityQuestionsStepAction
  | BusinessEnrollStepAction
  | BusinessSignUpStepAction
  | BusinessSecurityQuestionsStepAction
  | ResetEnrollStepsAction;

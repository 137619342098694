import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';

import { VehicleService } from '../services/vehicle.service';

import {
  VehicleActionTypes,
  LookupVinYear,
  GetMakes,
  GetModels,
  GetStyles,
  fromVehicleActions
} from './vehicle.actions';

import {
  GetVehicleModelsApiResponse,
  GetMakesPayload,
  GetModelsPayload,
  GetStylesPayload,
  LookupVinYearPayload
} from '../models/vehicle.models';

@Injectable()
export class VehicleEffects {
  
  getByVinAndYear$ = createEffect(() => this.actions$.pipe(
    ofType(VehicleActionTypes.LookupVinYear),
    map((action: LookupVinYear) => action.payload),
    switchMap((payload: LookupVinYearPayload) =>
      this.vehicleService.getVehicleByVinAndYear(payload).pipe(
        map(response => {
          return new fromVehicleActions.LookupVinYearSuccess(response);
        }),
        catchError(error => of(new fromVehicleActions.LookupVinYearFail(error)))
      )
    )
  ));

  
  getMakes$ = createEffect(() => this.actions$.pipe(
    ofType(VehicleActionTypes.GetMakes),
    map((action: GetMakes) => action.payload),
    switchMap((payload: GetMakesPayload) =>
      this.vehicleService.getVehicleMakes(payload).pipe(
        map(response => {
          return new fromVehicleActions.GetMakesSuccess(response);
        }),
        catchError(error => of(new fromVehicleActions.GetMakesFail(error)))
      )
    )
  ));

  
  getModels$ = createEffect(() => this.actions$.pipe(
    ofType(VehicleActionTypes.GetModels),
    map((action: GetModels) => action.payload),
    switchMap((payload: GetModelsPayload) =>
      this.vehicleService.getVehicleModels(payload).pipe(
        map((response: GetVehicleModelsApiResponse) => {
          return new fromVehicleActions.GetModelsSuccess(response);
        }),
        catchError(error => of(new fromVehicleActions.GetModelsFail(error)))
      )
    )
  ));

  
  getStyles$ = createEffect(() => this.actions$.pipe(
    ofType(VehicleActionTypes.GetStyles),
    map((action: GetStyles) => action.payload),
    switchMap((payload: GetStylesPayload) =>
      this.vehicleService.getVehicleStyles(payload).pipe(
        map((response: any) => {
          return new fromVehicleActions.GetStylesSuccess(response);
        }),
        catchError(error => of(new fromVehicleActions.GetStylesFail(error)))
      )
    )
  ));

  constructor(private actions$: Actions, private vehicleService: VehicleService) {}
}

export interface PaperlessDiscountPayload {
  FormInstanceTypePaperless: PaperlessDiscountModel;
}

export interface AutoPayDiscountPayload {
  FormInstanceTypeAutoPay: AutoPayDiscountModel;
}

export interface PaperlessDiscountModel {
  policies: DiscountPolicyModel[];
  paperlessIndicator: boolean;
  effectiveDate: string;
  customerId: string;
  partnerId: string;
  templateCode: string;
}

export interface AutoPayDiscountModel {
  policies: DiscountPolicyModel[];
  billAccount: string;
  paymentType?: string;
  autoPayIndicator: boolean;
  effectiveDate: string;
  customerId: string;
  partnerId: string;
  templateCode: string;
}

export interface DiscountPolicyModel {
  policyNumber: string;
  producerId: string;
}

export interface RpaAutoPayData {
  autoPayIndicator: boolean;
  billAccount: string;
  paymentAccountNickname: string;
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VehicleState } from './vehicle.reducer';

const getVehicleState = createFeatureSelector<VehicleState>('vehicle');

export const getStatus = createSelector(getVehicleState, (state: VehicleState) => state.status);

export const hasError = createSelector(getVehicleState, (state: VehicleState) => state.hasError);

export const getLoading = createSelector(getVehicleState, (state: VehicleState) => state.loading);

export const getCriteria = createSelector(getVehicleState, (state: VehicleState) => state.criteria);

export const getYears = createSelector(getVehicleState, (state: VehicleState) => state.years);

export const getMakes = createSelector(getVehicleState, (state: VehicleState) => state.makes);

export const getModels = createSelector(getVehicleState, (state: VehicleState) => state.models);

export const getStyles = createSelector(getVehicleState, (state: VehicleState) => state.styles);

export const getVehiclePolicyChangeEntryPoint = createSelector(
  getVehicleState,
  (state: VehicleState) => state.vehiclePolicyChangeEntryPoint
);

export const getNewVehicle = createSelector(
  getVehicleState,
  (state: VehicleState) => state.newVehicle
);

export const getVehicleFeatures = createSelector(
  getVehicleState,
  (state: VehicleState) => state.features
);

export const getNewRiskDescription = createSelector(getNewVehicle, newVehicle =>
  newVehicle
    ? `${newVehicle.year} ${newVehicle.make} ${newVehicle.model} ${
        newVehicle.series ? newVehicle.series : ''
      }`
    : ''
);

export const getNewRiskDescriptionWithoutSeries = createSelector(getNewVehicle, newVehicle =>
  newVehicle ? `${newVehicle.year} ${newVehicle.make} ${newVehicle.model}` : ''
);

export const vehicleQuery = {
  getStatus,
  hasError,
  getLoading,
  getCriteria,
  getYears,
  getMakes,
  getModels,
  getStyles,
  getNewVehicle,
  getNewRiskDescription,
  getVehiclePolicyChangeEntryPoint,
  getNewRiskDescriptionWithoutSeries,
  getVehicleFeatures
};

import { KydConfirmationRisk } from './kyd-confirmation-risk.model';

export class KydConfirmation {
  success: boolean;
  risk: KydConfirmationRisk;

  static compare(a: KydConfirmation, b: KydConfirmation) {
    if (a.success && b.success) {
      return 0;
    }
    if (a.success && !b.success) {
      return 1;
    }

    return -1;
  }
}

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationModel } from '../../ds-confirmation.model';

@Component({
  selector: 'ds-confirmation-details',
  templateUrl: './ds-confirmation-details.component.html',
  styleUrls: ['./ds-confirmation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsConfirmationDetailsComponent {
  @Input() details: ConfirmationModel['details'];
}

import { Injectable } from "@angular/core";
@Injectable()
export class ImpersonationRoles {
  public static readonly REPORT_CLAIM_VIEW = 'fnolform_view';
  public static readonly REPORT_CLAIM_SUBMIT = 'rptclmfrm_submit';
  public static readonly CLAIM_DETAIL_VIEW = 'clmdetails_view';

  // KYD & smartphone roles.
  public static readonly SIGNUP_KYD = 'signup_kyd_automatic';
  public static readonly SIGNOUT_KYD = 'signout_kyd_automatic';
  public static readonly SIGNUP_KYD_SMARTPHONE = 'signup_kyd_smartphone';
  public static readonly SIGNOUT_KYD_SMARTPHONE = 'signout_kyd_smartphone';

  // Claim Status
  public static readonly CLAIMSUMMARY_VIEW = 'claimsummary_veiw';
  public static readonly FNOL_CLAIMSTATUS_SECURED = 'fnolclaimstatus_secured';
  public static readonly FNOL_CLAIMSTATUS_NONSECURED = 'fnolclaimstatus_nonsecured';
}

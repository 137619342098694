export interface Alert {
  type: AlertEnums;
  alertBg: string;
  alertIcon: string;
}

export enum AlertEnums {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error'
}

<div class="main row container">
  <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
    <confirmation
      [config]="confirmationData$ | async"
      (primaryEvent)="submit()"
      (tertiaryEvent)="done()"
      ><div class="bottom-border">
        <div content>
          <p class="margin-bottom-20">{{ messageBodyText }}</p>
        </div>
        <div class="margin-bottom-20" *ngIf="subHeading">
          <h2 class="subHeading">{{ subHeading }}</h2>
          <div class="payment-card">
            <ds-payment-method-card
              class="cell-component"
              [paymentAccount]="paymentMethod"
            ></ds-payment-method-card>
          </div>
        </div>
        <!-- affected payments -->
        <div
          *ngIf="
            deletePaymentMethodInfo &&
            (scheduledPayments.length > 0 || automaticPayments.length > 0)
          "
        >
          <!-- affected scheduled payments -->
          <div *ngIf="scheduledPayments.length > 0" class="payments">
            <h2 class="pad-bottom-10 subHeading">One-time payments</h2>
            <ds-scheduled-payments-detail
              *ngFor="let payment of scheduledPayments"
              [payment]="payment"
              [paymentAccount]="paymentMethod"
            ></ds-scheduled-payments-detail>
          </div>
          <!-- affected automatic payments -->
          <div *ngIf="automaticPayments.length > 0" class="payments">
            <h2 class="pad-bottom-10 subHeading">AutoPay</h2>
            <ds-scheduled-payments-detail
              *ngFor="let payment of automaticPayments"
              [payment]="payment"
              [paymentAccount]="paymentMethod"
            ></ds-scheduled-payments-detail>
          </div>
        </div>
      </div>
    </confirmation>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../../core';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/store';

@Injectable()
export class ForgotPasswordGuard  {
  constructor(private authService: AuthService, private store: Store<fromRoot.RootState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      this.store.dispatch(
        new fromRouterActions.Go({
          path: ['/overview']
        })
      );
      return false;
    }

    return true;
  }
}

<div class="confirmation-details" *ngFor="let tableDetailObj of details">
  <h2
    *ngIf="tableDetailObj.tableRows?.length > 0"
    class="confirmation-details-title margin-bottom-0"
    [innerHTML]="tableDetailObj.heading"
  ></h2>

  <ds-table
    *ngIf="tableDetailObj.tableRows?.length > 0"
    class="block md-none"
    [rows]="tableDetailObj.tableRows"
    [columns]="tableDetailObj.tableColumns"
    [headerHeight]="60"
    [tableClasses]="'action-row'"
  >
  </ds-table>

  <ngx-datatable
    *ngIf="tableDetailObj.tableRows?.length > 0"
    class="none md-block"
    [ngClass]="'material expandable noshadow'"
    [cssClasses]="{
      pagerLeftArrow: 'icon-chevron-left',
      pagerRightArrow: 'icon-chevron-right',
      pagerPrevious: 'icon-arrow-left-double',
      pagerNext: 'icon-arrow-right-double'
    }"
    [rows]="tableDetailObj.tableRows"
    [columns]="tableDetailObj.tableColumns"
    [columnMode]="'force'"
    [headerHeight]="60"
    [rowHeight]="'auto'"
    [selectionType]="''"
    [limit]="6"
    [footerHeight]="''"
  >
  </ngx-datatable>
</div>

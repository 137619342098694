<div
  role="banner"
  class="container row"
  [ngClass]="experienceId ? 'expid-' + experienceId : 'expid-afi'"
>
  <ds-header-navigation
    class="column none lg-block lg-twelve"
    [ngClass]="{ 'logged-in': loggedIn$ | async }"
    [loaded$]="loaded$"
    [headerLogo]="headerLogo"
    [isPartner$]="isPartner$"
    [brandInfo$]="brandInfo$"
    [linkArray$]="linkArray$"
    [notifications$]="notifications$"
    [homeUrl]="homeUrl"
    [chatEnabled$]="chatEnabled$"
    [loggedIn$]="loggedIn$"
    (linkClickEvent)="linkClicked($event)"
    (sublinkClickEvent)="sublinkClicked($event)"
  ></ds-header-navigation>
  <ds-header-mobile-navigation
    class="column twelve block lg-none"
    [ngClass]="{ 'logged-in': loggedIn$ | async }"
    [loaded$]="loaded$"
    [headerLogo]="headerLogo"
    [brandInfo$]="brandInfo$"
    [linkArray$]="linkArray$"
    [notifications$]="notifications$"
    [homeUrl]="homeUrl"
    [loggedIn$]="loggedIn$"
    (linkClickEvent)="linkClicked($event)"
    (sublinkClickEvent)="sublinkClicked($event)"
  ></ds-header-mobile-navigation>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-table-body',
  templateUrl: './table-body.component.html',
  styleUrls: ['../../containers/ds-table/ds-table.component.scss']
})
export class DsTableBodyComponent implements OnInit {
  @Input() public rows: any;
  @Input() public columns: any;
  @Input() public rowDetail: any;
  @Input() public tableClasses: any;

  constructor() {}

  ngOnInit() {}
}

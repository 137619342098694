import { CCTypes } from './CCTypes';
// TODO delete this file

// @dynamic
export class CCValidator {
  public static MIN_LENGTH = 14;

  // information for various credit card types as a string map
  public static _types: { [key: string]: Object } = {
    AX: { pattern: /^3[47]/, length: 15 },
    DS: {
      pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
      length: 16
    },
    VI: { pattern: /^4/, length: 16, alternateLength: 19 },
    MC: { pattern: /^5[1-5]/, length: 16 }
  };

  /**
   * Is the supplied credit-card number valid
   *
   * @param cardNumber : string - Credit card number, which may include spaces or dashes, i.e. XXXX-YYYY-ZZZZ-ABCD
   *
   * @return boolean - true if the card number is recognized as a supported card type and the card-number properties are correct
   * for that card type.  Note that this does not mean the card is valid to be charged against, only that the number is theoretically
   * correct for the card type.
   */
  public static isValid(cardNumber: string): boolean {
    const testNumber: string = this.__preprocess(cardNumber);
    const cardType: string = this.getCardType(testNumber);

    if (cardType !== CCTypes.NONE) {
      return this.__lengthValid(testNumber, cardType) && this.__luhnValid(testNumber);
    }
    return false;
  }

  /**
   * Return the credit card type based on the card number (provided the card is in the accepted list of cards)
   *
   * @param cardNumber : string - Credit card number, which may include spaces or dashes, i.e. XXXX-YYYY-ZZZZ-ABCD
   *
   * @return string - Credit card (CCTypes member) type, which may be 'none' if the card number is not recognized
   */
  public static getCardType(cardNumber: string): string {
    let cardProps: Object;
    const theCard: string = this.__preprocess(cardNumber);

    for (const key in this._types) {
      if (this._types[key]) {
        cardProps = this._types[key];
        if (theCard.match(cardProps['pattern'])) {
          if (key) {
            return key;
          }
        }
      }
    }
    return 'none';
  }

  // remove spaces and dashes that may be present in the credit card number
  public static __preprocess(cardNumber: string): string {
    return cardNumber.replace(/[ -]/g, '');
  }

  // check the length of the credit card number based on its type
  public static __lengthValid(cardNumber: string, cardType: string): boolean {
    const cardProps: Object = this._types[cardType];
    return cardProps
      ? cardNumber.length === cardProps['length'] ||
          cardNumber.length === cardProps['alternateLength']
      : false;
  }

  // check the credit card number with the Luhn algorithm
  public static __luhnValid(cardNumber: string): boolean {
    let digit: number;
    let n: number;
    let sum: number;
    let j: number;

    sum = 0;
    const numbers: Array<number> = cardNumber
      .split('')
      .reverse()
      .map(val => parseFloat(val));
    const len: number = numbers.length;
    n = 0;
    j = 0;

    // there's really nothing new under the sun ...
    while (j < len) {
      digit = numbers[n];
      digit = +digit;

      if (n % 2) {
        digit *= 2;
        if (digit < 10) {
          sum += digit;
        } else {
          sum += digit - 9;
        }
      } else {
        sum += digit;
      }
      n = ++j;
    }

    return sum % 10 === 0;
  }
}

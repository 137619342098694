<div class="row flex warning-box">
  <div class="column icon-column margin-right-10">
    <span class="icon-alert"></span>
  </div>
  <div class="column">
    <p tabindex="0" [attr.aria-label]="headingText" class="subheading" data-cy="warningBoxHeading">
      {{ headingText }}
    </p>

    <div>
      <p class="caption" data-cy="warningBoxBody">{{ messageText }}</p>
      <a *ngIf="linkText" class="caption" (click)="linkEvent.emit(linkText)">{{ linkText }}</a>
      <p *ngIf="postLinkText" class="caption">{{ postLinkText }}</p>
    </div>
  </div>
</div>

import { FnolConfirmation } from '@amfam/claim/fnol/data-access';
import { ClaimConfirmation } from '../models/claim-confirmation';
import { ClaimSubmitAction, ClaimSubmitActionTypes } from './claim-submit.actions';
export const CLAIMSUBMIT_FEATURE_KEY = 'claimSubmit';

type ClaimSubmitConfirmation = ClaimConfirmation & FnolConfirmation;

export interface ClaimSubmitState {
  confirmation: ClaimSubmitConfirmation;
  error: boolean;
}

export const initialState: ClaimSubmitState = {
  confirmation: null,
  error: false
};

export function claimSubmitReducer(
  state: ClaimSubmitState = initialState,
  action: ClaimSubmitAction
): ClaimSubmitState {
  switch (action.type) {
    case ClaimSubmitActionTypes.SubmitClaimSuccess:
    case ClaimSubmitActionTypes.FnolConfirmation:
    case ClaimSubmitActionTypes.ReportClaimSuccess:
      return Object.assign({}, state, {
        confirmation: action.payload
      });

    case ClaimSubmitActionTypes.SubmitClaimFailed:
    case ClaimSubmitActionTypes.ReportClaimFailed:
      return { ...state, error: true, confirmation: null };

    case ClaimSubmitActionTypes.ResetConfirmation:
      return initialState;

    default:
      return state;
  }
}

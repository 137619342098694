import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EligibleServicesEffects } from './+state/eligible-services/eligible-services.effects';
import { RentalServiceEffects } from './+state/rental-service/rental-service.effects';
import { RepairServiceEffects } from './+state/repair-service/repair-service.effects';
import { CLAIMSERVICES_FEATURE_KEY, reducers } from './+state';

const claimServicesEffects = [EligibleServicesEffects, RentalServiceEffects, RepairServiceEffects];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIMSERVICES_FEATURE_KEY, reducers),
    EffectsModule.forFeature(claimServicesEffects)
  ]
})
export class ClaimServicesDataAccessModule {}

import { createFeatureSelector, createSelector, ActionReducerMap } from '@ngrx/store';
import * as fromEsignatureState from './e-signature.reducer';
import { get as _get } from 'lodash';
import { Policy } from '@amfam/policy/models';

export interface EsignatureState {
  eSignatureState: fromEsignatureState.EsignatureEntityState;
}

export const esignatureReducers: ActionReducerMap<EsignatureState> = {
  eSignatureState: fromEsignatureState.reducer
};

const getESignatureState = createFeatureSelector<EsignatureState>(
  fromEsignatureState.ESIGNATURE_FEATURE_KEY
);

export const getLoading = createSelector(getESignatureState, (state: EsignatureState) =>
  _get(state, 'eSignatureState.loading', true)
);

export const getLoaded = createSelector(
  getESignatureState,
  (state: EsignatureState) => !_get(state, 'eSignatureState.loading', true)
);

export const getESignatureEntities = createSelector(
  getESignatureState,
  getLoaded,
  (state: EsignatureState, isLoaded) => {
    return isLoaded ? fromEsignatureState.selectEntities(state.eSignatureState) : [];
  }
);

export const getESignatureIds = createSelector(
  getESignatureState,
  getLoaded,
  (state: EsignatureState, isLoaded) => {
    return isLoaded ? <string[]>fromEsignatureState.selectIds(state.eSignatureState) : [];
  }
);

export const getESignatureRequiredPolicies = createSelector(
  getESignatureEntities,
  getESignatureIds,
  (entities, ids) => {
    return <Policy[]>ids.map(id => entities[id]);
  }
);

export const getESignatureRequired = createSelector(
  getESignatureRequiredPolicies,
  policies => policies.length > 0
);

export const eSignatureQuery = {
  getESignatureState,
  getLoading,
  getLoaded,
  getESignatureRequired,
  getESignatureRequiredPolicies,
  getESignatureIds,
  getESignatureEntities
};

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

import { ConfigService } from '@amfam/shared/utility/shared-services';
import { DigitalAccount } from '../models/digital-account.model';

// Store
import { Store } from '@ngrx/store';
import { userQuery, UserState } from '@amfam/shared/user';
import { distinctUntilKeyChanged, switchMap, map, take } from 'rxjs/operators';
import { BrandModel, BrandSelectors } from '@amfam/shared/utility/brand';

@Injectable()
export class DigitalAccountService {
  private store$: ReplaySubject<DigitalAccount> = new ReplaySubject<any>();
  private endpoint: string;
  private waid: string;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private store: Store<UserState>
  ) {
    // this.store
    //   .select(userQuery.getUserState)
    //   .pipe(
    //     distinctUntilKeyChanged('waid', (prev, next) => prev.toLowerCase() === next.toLowerCase())
    //   )
    //   .subscribe(user => {
    //     if (user !== null && user.waid) {
    //       this.waid = user.waid;
    //       this.fetchDigitalAccount(user.waid).subscribe(
    //         data => {
    //           this.store$.next(data.digitalAccount);
    //         },
    //         // error
    //         err => {},
    //         // complete
    //         () => {
    //           this.store$.complete();
    //         }
    //       );
    //     }
    //   });
  }

  get digitalAccount$() {
    return this.store$.asObservable();
  }

  // update any user info that is part of the `digitalAccount` property
  public updateAccount(digitalAccount: DigitalAccount): Observable<any> {
    this.endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts/';
    return this.getBrand().pipe(
      switchMap(brand => {
        const data = {
          partnerId: brand.partnerId,
          experienceId: brand.experienceId,
          digitalAccount: digitalAccount
        };
        return this.http.put(this.endpoint + this.waid, JSON.stringify(data));
      })
    );
  }

  // private fetchDigitalAccount(waid: string): Observable<any> {
  //   this.endpoint = this.config.get('digitalAccountApi') + '/digitalaccounts/' + waid;
  //   return this.http.get(this.endpoint).pipe(map((data: any) => data));
  // }

  private getBrand(): Observable<BrandModel> {
    return this.store.select(BrandSelectors.getBrandState).pipe(take(1));
  }
}

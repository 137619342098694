import { takeUntil } from 'rxjs/operators';
import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BrandModel as BrandState } from '../models/brand.model';
import * as BrandSelectors from '../+state/brand.selectors';

@Directive({
  selector: '[dsPartnerShow]'
})
export class PartnerShowDirective implements OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<BrandState>
  ) {}

  /**
   * PartnerShowDirective takes in the partnerID from the template and checks to see if it matches the
   * partnerID in Store. If it matches, the content is shown. If not, it is hidden.
   * This is to be used for toggling specific partner content.
   */

  @Input()
  set partnerShow(partner: string) {
    this.store
      .select(BrandSelectors.getPartnerId)
      .pipe(takeUntil(this.stop$))
      .subscribe(partnerId => {
        if (partnerId === partner) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

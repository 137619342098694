<div>
  <h3 content [contentPath]="'enrollment.verifyPolicyConfirm'"></h3>
  <p>
    <span
      *ngIf="multipleMatch"
      content
      [contentPath]="'enrollment.verifyPolicyNumConfirmText'"
    ></span>
    <span content [contentPath]="'enrollment.verifyPolicyNumContinueText'"></span>
  </p>
  <ds-verify-policy-num (policyNumSubmittedEvent)="preparePolicyNum($event)">
  </ds-verify-policy-num>
  <small
    data-cy="enrollmentVerifyPolicyNumMismatchText"
    class="block caption error margin-top-5"
    *ngIf="verifyResponse && !verifyResponse.success && policyNumNotFoundMessage"
    content
    [contentPath]="'enrollment.verifyPolicyNumMismatchText'"
  ></small>
  <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
    ><span class="icon-chevron-left"></span> Back</a
  >
</div>

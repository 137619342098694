import { of as observableOf, Observable } from 'rxjs';

import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { some as _some, has as _has } from 'lodash';
import { Store } from '@ngrx/store';

import { ConfigService } from '@amfam/shared/utility/shared-services';
import { ImpersonationState, initialImpersonationState } from '../+state/impersonation.model';
import { impersonationQuery } from '../+state/impersonation.selectors';
import { UserState } from '@amfam/shared/user';

@Injectable({
  providedIn: 'root'
})
export class ImpersonateRolesService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private store: Store<ImpersonationState>
  ) {}

  /*
    If we are not impersonating or the impersonator has the named role returns true.
    Not impersonating or lacking the named role returns false.
  */
  public isAuthorized(permissionName: string): Observable<boolean> {
    return this.store.select(impersonationQuery.isImpersonating).pipe(
      mergeMap(isImpersonating => {
        // Not impersonating, return true
        if (isImpersonating === false) {
          return observableOf(true);
        }

        return this.store.select(impersonationQuery.permissions).pipe(
          map(permissions => {
            return permissions.find(permission => permission === permissionName) != null;
          })
        );
      })
    );
  }

  public populatePermissionSet(user: UserState): Observable<ImpersonationState> {
    if (!_has(user, 'smImpersonatorUserID') || user.smImpersonatorUserID === '') {
      return observableOf(initialImpersonationState);
    }
    const baseUrl =
      this.config.get('impersonationConfig.apiEndpoint') +
      this.config.get('impersonationConfig.applicationName');
    const uri = `${baseUrl}/roles?userid=${user.smImpersonatorUserID}`;
    return this.http.get<{ status: any; roles: any; user: any }>(uri).pipe(
      map(responseObj => {
        const i: ImpersonationState = {
          isImpersonating: false,
          permissions: []
        };
        if (_has(user, 'smImpersonatorUserID') && user.smImpersonatorUserID !== '') {
          i.isImpersonating = true;
        }

        if (
          _has(responseObj, 'status.code') &&
          responseObj.status.code === 200 &&
          _has(responseObj, 'roles')
        ) {
          // This is the get app permissions call, populate the list:
          responseObj.roles.forEach(role => {
            i.permissions.push(role);
          });
        }
        return i;
      }),
      catchError(err => {
        return observableOf(initialImpersonationState);
      })
    );
  }
}

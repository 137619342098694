<form [formGroup]="multipleSelectionGroup">
  <ds-checkbox-group
    [controlName]="'option'"
    [parentFormGroup]="multipleSelectionGroup"
    [options]="dynamicView?.options"
    [displayClass]="'flex'"
  >
  </ds-checkbox-group>

  <call-to-action
    [disabled]="multipleSelectionGroup.invalid"
    [primaryButtonName]="'Continue'"
    [displayBackButton]="displayBackButton"
    [tertiaryButtonName]="'Cancel'"
    (primaryEvent)="onMultipleSelectSubmit()"
    (tertiaryEvent)="cancelEvent.emit()"
    (backEvent)="goBackEvent.emit()"
  >
  </call-to-action>
</form>

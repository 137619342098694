<div class="row">
  <email-list
    [emailAddresses]="emails"
    [primaryEmail]="primaryEmail"
    [primaryEmailContactMethodUsage]="primaryEmailContactMethodUsage"
    [pendingEmailId]="pendingEmailId"
    [otherEditActive]="otherEditActive"
    (save)="onSave($event)"
    (deleteEmailEvent)="onDeleteConfirm($event)"
    (edit)="onEdit($event)"
    (makePrimaryEvent)="onMakePrimary($event)"
    (sendAnotherVerificationEmailEvent)="sendAnotherVerificationEmail($event)"
    (resendEmailEmitEvent)="resendEmail()"
    (changeEmailEmitEvent)="changeEmail($event)"
    [makePrimaryEmailAddDeleteBtnToggleDisplay]="makePrimaryEmailAddDeleteBtnToggleDisplay"
    [isShellAccount]="isShellAccount"
    [shellEmail]="shellEmail"
    [isDuplicateEmail]="isDuplicateEmail"
  >
  </email-list>
</div>

<ds-modal [id]="modalId" [closeOthers]="true" [maxWidth]="800">
  <div class="row">
    <div class="column twelve">
      <h2
        class="pad-bottom-20 pad-left-50 pad-right-50 text-center font-weight-semibold"
        content
        [contentPath]="'profile.manageEmails.confirmDeletePrompt'"
      ></h2>
      <p class="pad-bottom-20 font-weight-semibold text-center">
        {{ emailToDelete | emailDescription }} will be deleted.
      </p>
      <div class="flex items-center justify-center">
        <button
          ds-button
          data-cy="confirmDeleteEmailButton"
          (click)="onDelete(emailToDelete.emailId)"
          [disabled]="!(roleService.isAuthorized('deleteemailaddr_submit') | async)"
          class="-error"
          type="button"
          content
          [contentPath]="'shared.deleteBtn'"
        ></button>
        <a
          (click)="closeModal()"
          class="pad-left-16 caption primary-800-text font-weight-bold"
          content
          [contentPath]="'shared.cancel'"
        ></a>
      </div>
    </div>
  </div>
</ds-modal>

import { ActivatedRoute } from '@angular/router';

export const stepNames = {
  identification: 'identification',
  verificationOptions: 'verification-options',
  verification: 'verification',
  signup: 'signup',
  chooseSecurityQuestions: 'security-questions',
  personalInfo: 'personal-info',
  complete: 'complete'
};
export interface WorkflowModel {
  loaded: boolean;
  workflowType: string;
  ids: string[];
  steps: { [id: string]: StepModel };
  activeStep: string;
  complete?: string;
}

export function initialWorkflow(vals: any = {}): WorkflowModel {
  return Object.assign(
    {
      loaded: false,
      workflowType: '',
      ids: [],
      steps: {},
      activeStep: '',
      complete: false
    },
    vals
  );
}

export interface StepModel {
  name: string; // identifies this step
  title: string; // title for progress bar
  complete: boolean; // is the step complete?
  active?: boolean; // is the step active? - Use WorkflowModel.activeStep if possible
  show?: boolean; // show in progress bar?
  skipped?: boolean; // did we skip this step? (post-fnol flow only)
  route?: string[]; // route for this step, can be modified by complete action
  nextStep?: string; // next step in workflow
  backStep?: string; // previous step in workflow
}

export const initialStep: StepModel = {
  name: '',
  title: '',
  complete: false,
  active: false,
  show: false,
  skipped: false,
  route: [],
  nextStep: '',
  backStep: ''
};

export interface StepCompleteModel {
  step: string;
  nextRoute?: string[];
  nextStep?: string;
}

export interface ActiveStepModel {
  activeStep?: string;
}

export interface LoadWorkflowModel {
  workflowType: string;
  steps: StepModel[];
  activeStep?: string;
  activatedRoute?: ActivatedRoute;
}

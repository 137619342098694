<div *ngIf="(loading | async) === false">
  <amfam-enrollment-header
    [firstLine]="emailSelectionHeader"
    [secondLine]="emailSelectionConditionHeader"
  ></amfam-enrollment-header>
  <form [formGroup]="emailSelectionForm">
    <div
      class="column twelve sm-three sm-pad-right-10 pad-bottom-10 sm-pad-bottom-0"
      *ngIf="!isEmailError"
    >
      <ds-form-control-container [control]="emailSelectionForm.get('emailInput')">
        <div class="input-group">
          <div class="input">
            <select
              class="icon fill"
              formControlName="emailInput"
              id="emailInput"
              data-cy="maeInitiationEmailSelect"
            >
              <option value=""></option>
              <option
                data-cy="maeInitiationEmailSelectOptions"
                *ngFor="let email of emails"
                [value]="email.emailAddress"
              >
                {{ email.emailAddress }}
              </option>
            </select>
            <span class="icon-chevron-down"></span>
            <div>
              <ds-form-control-message [control]="emailSelectionForm.get('emailInput')">
              </ds-form-control-message>
            </div>
          </div>
        </div>
      </ds-form-control-container>
    </div>
    <div class="row caption error-600-text" *ngIf="isEmailError">
      <p
        class="margin-vertical-10"
        data-cy="emptyEmailErroText"
        content
        [contentPath]="'enrollment.emptyEmailErrorMessage'"
      ></p>
    </div>

    <div class="row caption error-600-text" *ngIf="duplicateEmail">
      <p class="margin-vertical-10" data-cy="duplicateEmailText">
        This email address is already in use. Please select a different email address.
      </p>
    </div>

    <div
      class="column twelve lg-push-two md-eight md-push-two"
      *ngIf="!isEmailError && emailInput != null"
    >
      <call-to-action
        [primaryButtonName]="'CREATE ACCOUNT'"
        (primaryEvent)="createAccount()"
        [disablePrimaryButton]="!emailSelectionForm.valid"
      ></call-to-action>
    </div>
  </form>
</div>

import { ApiStatus } from '../api/api-status.model';
import { PaymentConfirmationModel } from './payment-confirmation.model';

export interface ScheduledPayment {
  paymentId: string;
  [propName: string]: any;
  autoPayIndicator: boolean | string;
  billAccounts: Array<BillAccountModel>;
  confirmationNumber?: string;
  entryDate?: string;
  financialAccountId?: Number;
  lastUpdateTimestamp: string;
  paymentAccount: PaymentAccountModel;
  paymentMethod: string;
  paymentStatus: string;
  paymentType: string;
  receivedDate: string;
  totalPaymentAmount: string;
  userIdentityId?: Number;
}

export const UNAVAILABLE_PAYMENT_ID = 'unavailable';

export class SubmitScheduledPaymentPayloadModel {
  requestJson: SchedulePaymentPayloadModel;
  autoPayIndicator?: string | undefined;
  paymentTerm?: Array<string>;
  paymentMethodType: string;
  correlationId: string;
}

export interface SchedulePaymentPayloadModel {
  paymentId?: string;
  lastUpdateTimestamp?: string;
  payment: {
    billAccounts: SchedulePaymentBillAccount[];
    paymentAccount: {
      nickName: string;
    };
    paymentMethod?: string;
    receivedDate: string;
    totalPaymentAmount: string;
  };
  paymentMethodType?: string;
  correlationId?: string;
  paymentTerm?: string[];
}

export interface SchedulePaymentBillAccount {
  billAccountNumber: string;
  billAccountNickName: string;
  billAcctPaymentAmount: string;
  pastDueInfo?: {
    currentAmountDue?: number;
    currentAmountDueDate?: string;
  };
}

export interface SubmitScheduledPaymentSuccessModel {
  request: SchedulePaymentPayloadModel;
  response: SubmitScheduledPaymentResponseModel;
  confirmation: PaymentConfirmationModel;
}

export interface SubmitScheduledPaymentFailureModel {
  request: SubmitScheduledPaymentPayloadModel;
  status: ApiStatus;
}
export interface SubmitScheduledPaymentResponseModel {
  confirmationId: string;
  paymentId: string;
  status: {
    code: string;
    metrics: {
      acceptedOn: string;
      returnedOn: string;
    };
    reason: string;
    transactionId: string;
  };
}

export interface EditScheduledPaymentSuccessModel {
  request: SchedulePaymentPayloadModel;
  response: EditScheduledPaymentResponseModel;
  confirmation: PaymentConfirmationModel;
}

export interface EditScheduledPaymentResponseModel {
  status: {
    code: string;
    metrics: {
      acceptedOn: string;
      returnedOn: string;
    };
    reason: string;
    transactionId: string;
  };
}

export interface DeleteScheduledPaymentSuccessModel {
  request?: SchedulePaymentPayloadModel;
  response?: EditScheduledPaymentResponseModel;
  confirmation: PaymentConfirmationModel;
}

export interface DeleteScheduledPaymentFailureModel {
  request: SchedulePaymentPayloadModel;
  status: ApiStatus;
}

export interface BillAccountModel {
  billAccountNumber: string;
  billAcctPaymentAmount: string;
  billAccountNickName?: string;
}

export interface PaymentAccountModel {
  nickName: string;
}

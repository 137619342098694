<div data-cy="pastDueAlert" style="flex-grow: 1">
  <ng-container *ngFor="let billAccount of billAccounts; index as i; last as isLast">
    <a
      data-cy="multiplePastDueAccountNumber"
      (click)="routeToBillAccount.emit(billAccount.billAccountNumber)"
      >{{
        billAccount?.billingPreferences?.accountNickName
          ? billAccount?.billingPreferences?.accountNickName
          : (billAccount?.billAccountNumber | prettyBillingAcctNum)
      }}</a
    >
    <ng-container *ngIf="!isLast && billAccounts.length > 2 && i !== billAccounts.length - 2"
      >,
    </ng-container>
    <ng-container *ngIf="i === billAccounts.length - 2"> and </ng-container>
  </ng-container>
  <ng-container *ngIf="billAccounts.length === 1; else elseCondition">
    is past due. Please make a payment to avoid losing your coverage.
  </ng-container>
  <ng-template #elseCondition>
    are past due. Please make a payment to avoid losing your coverage.</ng-template
  >
</div>

import { Component, OnInit, Input } from '@angular/core';
import { ConfirmationModel, initialConfirmationModel } from '../../ds-confirmation.model';

@Component({
  selector: 'ds-confirmation-disclaimer',
  templateUrl: './ds-confirmation-disclaimer.component.html',
  styleUrls: ['./ds-confirmation-disclaimer.component.scss']
})
export class DsConfirmationDisclaimerComponent implements OnInit {
  @Input() disclaimer: ConfirmationModel['disclaimer'];

  constructor() {}

  ngOnInit() {}
}

import { PartyEmail } from '@amfam/shared/models';

export class KydSmartphoneRole {
  public customerId: string;
  public operatorId: string;
  public firstName: string;
  public lastName: string;
  public email: PartyEmail;
  public phones: string[];
  public enrollStatus: string;
  public alreadyEnrolled: boolean;
  public fullName: string;
  public originalPhoneNumber: string;
  public selectedPhoneNumber: string;
  public _selectedPhoneNumber: string;

  public isNamedInsured: boolean;

  constructor(
    customerId = '',
    firstName = '',
    lastName = '',
    email = null,
    phones = new Array<string>(),
    enrollStatus = 'N',
    enrolledPhone = ''
  ) {
    this.customerId = customerId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phones = phones;
    this.enrollStatus = enrollStatus;

    this.originalPhoneNumber = '';
    this.selectedPhoneNumber = '';
    this._selectedPhoneNumber = '';

    this.alreadyEnrolled = false;

    this.fullName = this.firstName + ' ' + this.lastName;
  }
}

import { Routes } from '@angular/router';
import { Observable } from 'rxjs';

import { ProfileComponent } from './profile.component';

export const ProfileRoutes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      breadcrumb: 'Profile'
    }
  }
];

import { ApiStatus } from '@amfam/shared/models';

export interface GetAllSecurityQuestionsSuccessPayload {
  questions: string[];
}

export interface GetAllSecurityQuestionsResponse {
  questions: string[];
  status: ApiStatus;
}

export interface GetUserSecurityQuestionsPayload {
  userIdentifier: string;
  partnerId?: string;
}

export interface SetSecurityQuestionsAnswersPayload {
  customerId: string;
  partnerId: string;
  experienceId: string;
  waid: string;
  securityQuestions: SecurityQuestion[];
}

export interface SecurityQuestion {
  question: string;
  answer: string;
  oldQuestion?: string;
}

import { Component } from '@angular/core';

@Component({
  selector: 'ds-enrollment-next',
  templateUrl: './enrollment-next.component.html',
  styleUrls: ['./enrollment-next.component.scss']
})
export class EnrollmentNextComponent {
  constructor() {}
}

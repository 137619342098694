import { Action } from '@ngrx/store';
import { InitiateDynamicQuestionAdapterModel } from '../models/initiate-dynamic-question-adapter.model';
import { InitiateDynamicQuestionModel } from '../models/initiate-dynamic-question.model';
import { CreateDraftClaimModel } from '../models/create-draft-claim.model';
import { FnolModel, FnolAnalyticsModel, Driver } from './fnol.model';
import { ErrorObj } from '@amfam/ui-kit';
import {
  CustomerPreFnolModel,
  ResumeDraftClaimModel,
  VehicleDamagePayloadModel,
  ThirdPartyContactPayloadModel,
  ClaimantContactPayloadModel
} from '@amfam/shared/models';

export enum FnolActionTypes {
  LOAD_DRAFT_CLAIMS = '[Fnol] Load Draft Claims',

  RESUME_DRAFT_CLAIM = '[Fnol] Resume draft claim',
  RESUME_DRAFT_CLAIM_SUCCESS = '[Fnol] Resume draft claim success',
  RESUME_DRAFT_CLAIM_FAIL = '[Fnol] Resume draft claim fail',

  SAVE_INFO_FOR_DRAFT_CLAIM = '[Fnol] Save info for draft claim',
  SAVE_DRIVER_INFO = '[Fnol] Save driver info for draft claim',
  SAVE_LOSS_DATE_INFO = '[Fnol] Save loss date',

  INITIATE_DRAFT_CLAIM = '[Fnol] Initiate Draft Claim',

  CREATE_DRAFT_CLAIM = '[Fnol] Create draft claim',
  CREATE_DRAFT_CLAIM_SUCCESS = '[Fnol] Create draft claim success',
  CREATE_DRAFT_CLAIM_FAIL = '[Fnol] Create draft claim fail',

  INITIATE_DYNAMIC_QUESTIONS_FLOW = '[Fnol] Initiate dynamic questions',
  INITIATE_DYNAMIC_QUESTIONS_FLOW_SUCCESS = '[Fnol] Initiate dynamic questions success',
  INITIATE_DYNAMIC_QUESTIONS_FLOW_FAIL = '[Fnol] Initiate dynamic questions fail',

  SUBMIT_QUESTION = '[Fnol] Get next question',
  SUBMIT_QUESTION_SUCCESS = '[Fnol] Get next question success',
  SUBMIT_QUESTION_FAIL = '[Fnol] Get next question fail',

  GO_BACK = '[Fnol] Go back one question',
  GO_BACK_SUCCESS = '[Fnol] Go back one question success',
  GO_BACK_FAIL = '[Fnol] Go back one question fail',

  EDIT_QUESTION = '[Fnol] Edit specified question',
  EDIT_QUESTION_SUCCESS = '[Fnol] Edit specified question success',
  EDIT_QUESTION_FAIL = '[Fnol] Edit specified question fail',

  SUBMIT_VEHICLE_DAMAGE_QUESTION = '[Fnol] Submit vehicle damage picker question',

  SUBMIT_THIRD_PARTY_CONTACT_FORM_QUESTION = '[Fnol] Submit third party contact form question',
  SUBMIT_CLAIMANT_CONTACT_FORM_QUESTION = '[Fnol] Submit claimant contact form question',
  SUBMIT_INSURED_CONTACT_QUESTION = '[Fnol] Submit insured contact question',

  SUBMIT_DRAFT_CLAIM = '[Fnol] Submit draft claim',
  SUBMIT_DRAFT_CLAIM_SUCCESS = '[Fnol] Submit draft claim success',
  SUBMIT_DRAFT_CLAIM_FAIL = '[Fnol] Submit draft claim fail',

  // Delete
  DELETE_DRAFT_CLAIM = '[Fnol] Delete Draft Claim',
  DELETE_DRAFT_CLAIM_SUCCESS = '[Fnol] Delete Draft Claim Success',
  DELETE_DRAFT_CLAIM_FAIL = '[Fnol] Delete Draft Claim Fail',

  SEND_ANALYTICS = '[Fnol] Send Fnol Analtyics',

  CLEAR_DRAFT_CLAIM = '[Fnol] Clear draft claim',

  CLEAR_ERROR_STATE = '[Fnol] Clear error state',

  RESET_SELECTED_ENTITY = '[Fnol] Reset selected entity'
}

export class LoadDraftClaims implements Action {
  readonly type = FnolActionTypes.LOAD_DRAFT_CLAIMS;
  constructor(public payload: FnolModel[]) {}
}

export class ResumeDraftClaim implements Action {
  readonly type = FnolActionTypes.RESUME_DRAFT_CLAIM;
  constructor(public payload: ResumeDraftClaimModel) {}
}

export class ResumeDraftClaimSuccess implements Action {
  readonly type = FnolActionTypes.RESUME_DRAFT_CLAIM_SUCCESS;
  constructor(public payload: InitiateDynamicQuestionAdapterModel) {}
}

export class ResumeDraftClaimFail implements Action {
  readonly type = FnolActionTypes.RESUME_DRAFT_CLAIM_FAIL;
  constructor(public payload: Array<ErrorObj>) {}
}

export class SaveInfoForDraftClaim implements Action {
  readonly type = FnolActionTypes.SAVE_INFO_FOR_DRAFT_CLAIM;
  constructor(public payload: CustomerPreFnolModel) {}
}

export class SaveLossDateInfo implements Action {
  readonly type = FnolActionTypes.SAVE_LOSS_DATE_INFO;
  constructor(public payload: { lossDate: string }) {}
}

export class SaveDriverInfo implements Action {
  readonly type = FnolActionTypes.SAVE_DRIVER_INFO;
  constructor(public payload: { driver: Driver }) {}
}

export class InitiateDraftClaim implements Action {
  readonly type = FnolActionTypes.INITIATE_DRAFT_CLAIM;
  constructor(public payload: { draftInfo: FnolModel; locationInfo?: any }) {}
}

export class CreateDraftClaim implements Action {
  readonly type = FnolActionTypes.CREATE_DRAFT_CLAIM;
  constructor(public payload: CreateDraftClaimModel) {}
}
export class CreateDraftClaimSuccess implements Action {
  readonly type = FnolActionTypes.CREATE_DRAFT_CLAIM_SUCCESS;
  constructor(public payload: string) {}
}

export class CreateDraftClaimFail implements Action {
  readonly type = FnolActionTypes.CREATE_DRAFT_CLAIM_FAIL;
  constructor() {}
}

export class InitiateDynamicQuestionsFlow implements Action {
  readonly type = FnolActionTypes.INITIATE_DYNAMIC_QUESTIONS_FLOW;
  constructor(public payload: InitiateDynamicQuestionModel) {}
}

export class InitiateDynamicQuestionsFlowSuccess implements Action {
  readonly type = FnolActionTypes.INITIATE_DYNAMIC_QUESTIONS_FLOW_SUCCESS;
  constructor(public payload: InitiateDynamicQuestionAdapterModel) {}
}

export class InitiateDynamicQuestionsFlowFail implements Action {
  readonly type = FnolActionTypes.INITIATE_DYNAMIC_QUESTIONS_FLOW_FAIL;
  constructor(public payload: Array<ErrorObj>) {}
}

export class SubmitQuestion implements Action {
  readonly type = FnolActionTypes.SUBMIT_QUESTION;
  constructor(public payload: string) {}
}

export class SubmitQuestionSuccess implements Action {
  readonly type = FnolActionTypes.SUBMIT_QUESTION_SUCCESS;
  constructor(public payload: InitiateDynamicQuestionAdapterModel) {}
}

export class SubmitQuestionFail implements Action {
  readonly type = FnolActionTypes.SUBMIT_QUESTION_FAIL;
  constructor(public payload: Array<ErrorObj>) {}
}

export class GoBack implements Action {
  readonly type = FnolActionTypes.GO_BACK;
  constructor() {}
}

export class GoBackSuccess implements Action {
  readonly type = FnolActionTypes.GO_BACK_SUCCESS;
  constructor(public payload: InitiateDynamicQuestionAdapterModel) {}
}

export class GoBackFail implements Action {
  readonly type = FnolActionTypes.GO_BACK_FAIL;
  constructor(public payload: Array<ErrorObj>) {}
}

export class EditQuestion implements Action {
  readonly type = FnolActionTypes.EDIT_QUESTION;
  constructor(public payload: string) {}
}

export class EditQuestionSuccess implements Action {
  readonly type = FnolActionTypes.EDIT_QUESTION_SUCCESS;
  constructor(public payload: InitiateDynamicQuestionAdapterModel) {}
}

export class EditQuestionFail implements Action {
  readonly type = FnolActionTypes.EDIT_QUESTION_FAIL;
  constructor(public payload: Array<ErrorObj>) {}
}

export class SubmitVehicleDamageQuestion implements Action {
  readonly type = FnolActionTypes.SUBMIT_VEHICLE_DAMAGE_QUESTION;
  constructor(public payload: VehicleDamagePayloadModel) {}
}

export class SubmitThirdPartyContactFormQuestion implements Action {
  readonly type = FnolActionTypes.SUBMIT_THIRD_PARTY_CONTACT_FORM_QUESTION;
  constructor(public payload: ThirdPartyContactPayloadModel[]) {}
}

export class SubmitClaimantContactFormQuestion implements Action {
  readonly type = FnolActionTypes.SUBMIT_CLAIMANT_CONTACT_FORM_QUESTION;
  constructor(public payload: ClaimantContactPayloadModel) {}
}

export class SubmitInsuredContactQuestion implements Action {
  readonly type = FnolActionTypes.SUBMIT_INSURED_CONTACT_QUESTION;
  constructor(public payload: { phoneNumber: string; emailAddress: string }) {}
}

export class SubmitDraftClaim implements Action {
  readonly type = FnolActionTypes.SUBMIT_DRAFT_CLAIM;
  constructor() {}
}
export class SubmitDraftClaimSuccess implements Action {
  readonly type = FnolActionTypes.SUBMIT_DRAFT_CLAIM_SUCCESS;
  constructor(public payload: string) {}
}

export class SubmitDraftClaimFail implements Action {
  readonly type = FnolActionTypes.SUBMIT_DRAFT_CLAIM_FAIL;
  constructor(public payload: Array<ErrorObj>) {}
}

export class ClearDraftClaim implements Action {
  readonly type = FnolActionTypes.CLEAR_DRAFT_CLAIM;
  constructor(public payload?: string) {}
}

export class ClearErrorState implements Action {
  readonly type = FnolActionTypes.CLEAR_ERROR_STATE;
  constructor() {}
}

export class ResetSelectedEntity implements Action {
  readonly type = FnolActionTypes.RESET_SELECTED_ENTITY;
  constructor() {}
}

export class DeleteDraftClaim implements Action {
  readonly type = FnolActionTypes.DELETE_DRAFT_CLAIM;
  constructor(public payload: string) {}
}

export class DeleteDraftClaimSuccess implements Action {
  readonly type = FnolActionTypes.DELETE_DRAFT_CLAIM_SUCCESS;
  constructor(public payload: string) {}
}

export class DeleteDraftClaimFail implements Action {
  readonly type = FnolActionTypes.DELETE_DRAFT_CLAIM_FAIL;
  constructor(public payload: string) {}
}

export class SendAnalytics implements Action {
  readonly type = FnolActionTypes.SEND_ANALYTICS;
  constructor(public payload: FnolAnalyticsModel) {}
}

export type FnolActions =
  | LoadDraftClaims
  | ResumeDraftClaim
  | ResumeDraftClaimSuccess
  | ResumeDraftClaimFail
  | SaveInfoForDraftClaim
  | SaveDriverInfo
  | SaveLossDateInfo
  | InitiateDraftClaim
  | CreateDraftClaim
  | CreateDraftClaimSuccess
  | CreateDraftClaimFail
  | InitiateDynamicQuestionsFlow
  | InitiateDynamicQuestionsFlowSuccess
  | InitiateDynamicQuestionsFlowFail
  | SubmitQuestion
  | SubmitQuestionSuccess
  | SubmitQuestionFail
  | SubmitInsuredContactQuestion
  | SubmitVehicleDamageQuestion
  | SubmitThirdPartyContactFormQuestion
  | SubmitClaimantContactFormQuestion
  | SubmitDraftClaim
  | SubmitDraftClaimSuccess
  | SubmitDraftClaimFail
  | GoBack
  | GoBackSuccess
  | GoBackFail
  | ResetSelectedEntity
  | EditQuestion
  | EditQuestionSuccess
  | EditQuestionFail
  | ClearDraftClaim
  | ClearErrorState
  | DeleteDraftClaim
  | DeleteDraftClaimSuccess
  | DeleteDraftClaimFail
  | SendAnalytics;

export const fromFnolActions = {
  LoadDraftClaims,
  ResumeDraftClaim,
  ResumeDraftClaimSuccess,
  ResumeDraftClaimFail,
  SaveInfoForDraftClaim,
  SaveDriverInfo,
  SaveLossDateInfo,
  InitiateDraftClaim,
  CreateDraftClaim,
  CreateDraftClaimSuccess,
  CreateDraftClaimFail,
  InitiateDynamicQuestionsFlow,
  InitiateDynamicQuestionsFlowSuccess,
  InitiateDynamicQuestionsFlowFail,
  SubmitQuestion,
  SubmitQuestionSuccess,
  SubmitQuestionFail,
  SubmitVehicleDamageQuestion,
  SubmitThirdPartyContactFormQuestion,
  SubmitClaimantContactFormQuestion,
  SubmitInsuredContactQuestion,
  SubmitDraftClaim,
  SubmitDraftClaimSuccess,
  SubmitDraftClaimFail,
  GoBack,
  GoBackSuccess,
  GoBackFail,
  ResetSelectedEntity,
  EditQuestion,
  EditQuestionSuccess,
  EditQuestionFail,
  ClearDraftClaim,
  ClearErrorState,
  DeleteDraftClaim,
  DeleteDraftClaimSuccess,
  DeleteDraftClaimFail,
  SendAnalytics
};

<ds-modal [id]="modalId" [closeOthers]="true" [maxWidth]="400" (onCloseModalEvent)="close()">
  <div class="text-center">
    <span class="icon-alert"></span>
    <div *ngIf="impersonationError" class="pad-top-20">
      <div content [contentPath]="'billing.errorMsgsModified[0].202304'"></div>
    </div>
    <div *ngIf="!impersonationError" class="pad-top-20">
      <div>Something went wrong on our end. No payment has been made. Please try again.</div>
    </div>
    <div *ngIf="!impersonationError" class="row pad-top-20">
      <div><button ds-button type="button" (click)="tryAgain()">Try Again</button></div>
      <div>
        Or
        <a dsPartnerContent [brandHref]="'getCusCareNumberFullHref'" class="accent">
          call us at <span dsPartnerContent [brandContent]="'getPrettyCusCareNumber'"></span>
        </a>
      </div>
    </div>
  </div>
</ds-modal>

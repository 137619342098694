<h4>{{ title }}</h4>
<ul data-cy="quickLinksList">
  <li *ngFor="let link of links">
    <a
      [ngClass]="{ 'disable-link': isQuickLinkDisable }"
      *ngIf="link.href && !link.target && !link.queryParams"
      [dsRouterLink]="link.href"
      (click)="link.onClick($event)"
      [attr.data-cy]="link?.dataCy"
      >{{ link.text }}</a
    >
    <a
      [ngClass]="{ 'disable-link': isQuickLinkDisable }"
      *ngIf="link.href && !link.target && link.queryParams"
      [dsRouterLink]="link.href"
      [queryParams]="link.queryParams"
      (click)="link.onClick($event)"
      [attr.data-cy]="link?.dataCy"
      >{{ link.text }}</a
    >
    <a
      [ngClass]="{ 'disable-link': isQuickLinkDisable }"
      *ngIf="link.href && link.target"
      [href]="link.href"
      [attr.target]="link.target"
      (click)="link.onClick($event)"
      [attr.data-cy]="link?.dataCy"
      >{{ link.text }}</a
    >
    <a
      *ngIf="!link.href"
      [ngClass]="{ 'disable-link': isQuickLinkDisable }"
      href="#"
      [attr.data-cy]="link?.dataCy"
      (click)="link.onClick($event)"
      >{{ link.text }}</a
    >
  </li>
</ul>

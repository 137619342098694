import { AnalyticsFacade } from '@amfam/shared/analytics';
import { PartyPhone } from '@amfam/shared/models';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { ButtonAnalytic } from '@amfam/shared/utility/shared-services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'phone-list-item',
  templateUrl: './phone-list-item.component.html',
  styleUrls: ['./phone-list-item.component.scss']
})
export class PhoneListItemComponent implements OnInit {
  static readonly makePrimaryPhoneButton: ButtonAnalytic = {
    link: 'MyAccount:Profile:MakePrimaryPhone'
  };
  @Input() phone: PartyPhone;

  // passes up id of phone entry to delete
  @Output() deleteEvent: EventEmitter<PartyPhone> = new EventEmitter();
  // Make the phone number primary
  @Output() makePrimaryEvent: EventEmitter<PartyPhone> = new EventEmitter();

  constructor(
    public roleService: ImpersonateRolesService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {}

  makePrimary() {
    // MakePrimary Analytics
    this.analyticsFacade.trackButtonClick(PhoneListItemComponent.makePrimaryPhoneButton);

    this.makePrimaryEvent.emit(this.phone);
  }

  deletePhone() {
    this.deleteEvent.emit(this.phone);
  }
}

/* eslint-disable @typescript-eslint/member-ordering */
import { fromBillingActions } from '@amfam/billing/billaccount/data-access/src/lib/+state/billaccount.actions';
import { billaccountsQuery } from '@amfam/billing/billaccount/data-access/src/lib/+state/billaccount.selectors';
import { RegisterBillAccModel } from '@amfam/billing/billaccount/data-access/src/lib/models/billingAccount.model';
import {
  AutoPolicy,
  GenericProductType,
  Policy,
  PolicyTypeDisplayNameConstants,
  PolicyTypeIconConstants,
  RiskModel,
  RiskSummary
} from '@amfam/policy/models';
import {
  AutoPayPayloadModel,
  AutopayPredictPayloadModel,
  BillAccount,
  ONLINE_BILLING,
  PolicyList,
  PolicyListItem,
  SilentRegistrationPayload
} from '@amfam/shared/models';
import { CopyService } from '@amfam/shared/utility/shared-services/src/lib/copy/copy.service';
import { UtilService } from '@amfam/shared/utility/shared-services/src/lib/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
// date-fns
import { endOfDay, format, isAfter, isBefore, isValid, subDays } from 'date-fns';
import {
  Dictionary,
  cloneDeep as _cloneDeep,
  get as _get,
  has as _has,
  reduce as _reduce,
  some as _some,
  uniqBy as _uniqBy
} from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BillAccountTransmuteService {
  public correlationId: string;
  constructor(
    private copyService: CopyService,
    private store: Store<any>,
    private utilService: UtilService
  ) {}

  public decorateAccountsArray(responseArray: any[]) {
    // Associated from the CDH Call
    let associatedAccts: any[] = [];
    if (_has(responseArray[0], 'status.code') && responseArray[0].status.code === 200) {
      associatedAccts = responseArray[0].billAccounts;
    }

    // Registered from the WAID call
    let registeredAccts: any[] = [];
    if (_has(responseArray[1], 'status.code') && responseArray[1].status.code === 200) {
      registeredAccts = responseArray[1].billAccounts ? responseArray[1].billAccounts : [];
    }

    // Loop through associated accounts and add associated true flag
    for (let i = 0; i < associatedAccts.length; i++) {
      associatedAccts[i].associated = true;
    }

    // If there are Bill accounts in the "by waid" list that aren't in the "by CdhId" list,
    // those are Un-associated.
    const unAssociatedAccts = registeredAccts.filter(
      rObj =>
        !associatedAccts.some(aObj => {
          if (aObj.billAccountNumber && rObj.billAccountNumber) {
            return rObj.billAccountNumber === aObj.billAccountNumber;
          }
        })
    );

    // Billing preference object for associated accounts..
    associatedAccts.forEach(aObj => {
      registeredAccts.forEach(rObj => {
        if (aObj.billAccountNumber && rObj.billAccountNumber) {
          if (aObj.billAccountNumber === rObj.billAccountNumber) {
            aObj.billingPreference = rObj.billingPreference;
            aObj.registrationDate = rObj.registrationDate;
          }
        }
      });
    });

    // Filter out inactive accounts from the by Cdh Call. By Waid only has active accounts.
    associatedAccts = associatedAccts.filter(acct => {
      if (_has(acct, 'accountStatus')) {
        return acct.accountStatus === 'BILLING_ACTIVE' || acct.accountStatus === 'Active';
      }
      return false;
    });

    // Adding custom indicators
    for (let i = 0; i < unAssociatedAccts.length; i++) {
      unAssociatedAccts[i].associated = false; // Make sure that the flag is correct for unassociated accounts.
      unAssociatedAccts[i] = this.transmuteSummary(unAssociatedAccts[i]);
    }

    for (let j = 0; j < associatedAccts.length; j++) {
      associatedAccts[j] = this.transmuteSummary(associatedAccts[j]);

      // Determine if this account is registered to someone else.

      // Bill Account should not be in the WAID call(registered list)
      const noBillAccountinWaid = !_some(registeredAccts, {
        billAccountNumber: associatedAccts[j].billAccountNumber
      });

      // If the billing method is ONLINE_BILLING and not in the waid call(registered list)
      if (associatedAccts[j].billingMethod === ONLINE_BILLING && noBillAccountinWaid) {
        associatedAccts[j].registeredElsewhere = true;
      }

      // SK: Determine if this account is scheduled AFT, if the billing method is AFT and it's not in the registered list.
      if (
        associatedAccts[j].billingMethod === 'AFT' &&
        !_some(
          registeredAccts,
          acct => acct.billAccountNumber === associatedAccts[j].billAccountNumber
        )
      ) {
        associatedAccts[j].enabledAFT = true;
      }
    }

    // Return both arrays
    return associatedAccts.concat(unAssociatedAccts);
  }

  /*
    Deep clone the summary object. Rename elements before
    persisting in cache to align with the element names that will
    ultimately be pulled from the bill account detail call.

    1. Rename the billingPreference element to billingPreferences if the singular is found
    2. Rename the accountNickname element to accountNickName to match the /preferences call
    3. Fix bad dates returned by the GET /billaccounts?cdhid=foo & GET /billaccounts?waid=bar calls
    4. Cast the string minimumAmountDue values as a number
  */
  private transmuteSummary(billAccount) {
    if (!billAccount) {
      return;
    }

    let ba = _cloneDeep(billAccount);

    // 1. Rename the billingPreference node to billingPreferences
    if (_has(ba, 'billingPreference')) {
      ba.billingPreferences = ba.billingPreference;
      delete ba.billingPreference;
    }

    // 2. Rename the nickname element to have the same case as the other calls
    if (_has(ba, 'billingPreferences.accountNickname')) {
      ba.billingPreferences.accountNickName = ba.billingPreferences.accountNickname;
      delete ba.billingPreferences.accountNickname;
    }

    // 3. Fix bad dates returned by the GET /billaccounts?cdhid=foo & GET /billaccounts?waid=bar calls
    if (_has(ba, 'paymentDueDate')) {
      const date = ba.paymentDueDate.match(/([0-9]{4}-[0-9]{2}-[0-9]{2})/g);
      const formatted = format(date[0], 'YYYY-MM-DD');
      ba.paymentDueDate = formatted;
    }

    // 4. Determine if the bill account summary object shows a past-due bill account:
    ba = this.determineIsPastDue(ba);

    // 5. Cast the string minimumAmountDue values as a number
    ba.minimumAmountDue = Number(billAccount.minimumAmountDue);

    // 6. Set the ONLINE_BILLING billingMethod for unassociated bill accounts since we don't get it from the API
    if (_get(ba, 'associated') !== true) {
      ba.billingMethod = ONLINE_BILLING;
    }

    return ba;
  }

  // Add empty documents list if not returned from API.
  public decorateDocumentsCall(documentsObject: any) {
    const billAccount: any = {
      billAccountNumber: documentsObject.billAccountNumber,
      billingDocumentsList: _get(documentsObject, 'billingDocuments', [])
    };

    return billAccount;
  }

  public transmuteDetailCall(detailedBillAccount: any) {
    let normalizedBillAccount = _cloneDeep(detailedBillAccount);
    normalizedBillAccount.minimumAmountDue = Number(detailedBillAccount.minimumAmountDue);
    normalizedBillAccount.fullPayBalance = Number(detailedBillAccount.fullPayBalance);

    // Cast any possible year-01 dates to a known constant value
    if (
      _get(normalizedBillAccount, 'paymentDueDate', '').startsWith('0001-01-03T') ||
      _get(normalizedBillAccount, 'paymentDueDate', '').startsWith('0001-01-01')
    ) {
      normalizedBillAccount.paymentDueDate = '0001-01-01T00:00:00.0-0600';
    }

    if (_has(normalizedBillAccount, 'paymentDueDate')) {
      // we need the actual date with the timezone offset so we can format it correctly
      const date = normalizedBillAccount.paymentDueDate.match(/([0-9]{4}-[0-9]{2}-[0-9]{2})/g);
      const formatted = format(date[0], 'YYYY-MM-DD');
      normalizedBillAccount.paymentDueDate = formatted;
    }

    // Set the past due flag
    normalizedBillAccount = this.determineIsPastDue(normalizedBillAccount);

    if (_has(normalizedBillAccount, 'policyRisks')) {
      normalizedBillAccount.icon = this.getItemIconClass(
        normalizedBillAccount.policyRisks,
        normalizedBillAccount
      );
      normalizedBillAccount.policyTypeDisplayName = this.getPolicyType(
        normalizedBillAccount.policyRisks
      );
    } else {
      normalizedBillAccount.icon = this.getItemIconClass(null, normalizedBillAccount);
      normalizedBillAccount.policyTypeDisplayName = this.getPolicyType(); //default
    }

    // Determine if classic or advance
    normalizedBillAccount.isAdvance =
      detailedBillAccount.billingSystem.toLowerCase() === 'billingcenter' ? true : false;

    return normalizedBillAccount;
  }

  /*
    A bill account is past due if the bill accounnt has a minimumAmountDue > 0 AND
    has either a valid (non-year-01) due date before today OR the billingStatementType flag of PAST_DUE
  */
  private determineIsPastDue(billAccount: BillAccount): BillAccount {
    // Default past due to false unless calculated to be true
    const decoratedBillAccount = _cloneDeep(billAccount);
    decoratedBillAccount.pastDue = false;

    // If we dont' have a minimumAmountDue or a due date, the bill account shouldn't be flagged as past due
    if (!_has(billAccount, 'minimumAmountDue') || !_has(billAccount, 'paymentDueDate')) {
      return decoratedBillAccount;
    }

    // A bill account has to have a minimum amount due to be past due
    if (Number(billAccount.minimumAmountDue) > 0) {
      // If there are billingStatements that have the value of PAST_DUE, the bill account is past due
      /*if (
        _has(billAccount, 'billingStatements') &&
        _some(billAccount.billingStatements, { billingStatementType: 'PAST_DUE' })
      ) {
        let calculatedCurrentMinDue: any;
        if (_get(billAccount, 'billingStatement.policiesBilled', []).length > 0) {
          // if the minimum amount due is more than 0 and doesn't have the object policiesBilled
          calculatedCurrentMinDue = _get(billAccount, 'billingStatement.policiesBilled', [])
            .map(billedPolicy => {
              return Object.values(billedPolicy.currentMinimumDue)
                .filter((val: number) => !isNaN(val))
                .reduce((acc: number, x: number) => acc + x);
            })
            .reduce((minDue: number, agg: number) => minDue + agg);
        }
        if (billAccount.minimumAmountDue <= calculatedCurrentMinDue) {
          decoratedBillAccount.paymentDueDate = _get(
            billAccount,
            'billingStatement.currentAmtDueDate',
            billAccount.paymentDueDate
          );
          decoratedBillAccount.isPartialPaymentPastDue = true; // we want to treat these as not past due
        } else {
          decoratedBillAccount.pastDue = true;
        }

        return decoratedBillAccount;
      }*/

      /* DM: this may be necessary in the future
      if (
        _has(billAccount, 'billingStatements') &&
        _some(billAccount.billingStatements, { billingStatementType: 'PAST_DUE' }) &&
        _get(billAccount, 'minimumAmountDueBreakdown.previousAmountDue', 0) > 0
      ) {
        decoratedBillAccount.pastDue = true;
        return decoratedBillAccount;
      }*/

      const defaultDate = '0001-01-03T00:00:00.000-0600';
      const ISODateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
      const initialDate = format(defaultDate, ISODateFormat);

      // if the paymentDueDate is after the year-01 constant / and before today it is past due
      if (
        isValid(new Date(initialDate)) &&
        isAfter(billAccount.paymentDueDate, new Date(initialDate)) &&
        isBefore(billAccount.paymentDueDate, endOfDay(subDays(new Date(), 1)))
      ) {
        decoratedBillAccount.pastDue = true;
        return decoratedBillAccount;
      }
    }

    return decoratedBillAccount;
  }

  private transmutePolicyRisks(
    policyRisks: RiskModel[]
  ): Array<{ id: string; description: string }> {
    let risks: Array<{ id: string; description: string }>;
    const policyGeneralizedProductType: number = policyRisks[0].generalizedProductType;
    switch (policyGeneralizedProductType) {
      case GenericProductType.Auto:
        risks = policyRisks.map(pr => ({ id: pr.id, description: pr.description }));
        break;
      case GenericProductType.Umbrella:
        risks = policyRisks.map(pr => ({ id: null, description: 'Umbrella' }));
        break;
      default:
        risks = policyRisks.map(pr => ({ id: null, description: pr.description }));
        break;
    }
    return risks;
  }

  private transmutePolicyRiskDesc(policyRisks: RiskModel[]): string[] {
    let riskDesc: string[];
    const policyGeneralizedProductType: number = policyRisks[0].generalizedProductType;
    switch (policyGeneralizedProductType) {
      case GenericProductType.Auto:
        riskDesc = _reduce(
          policyRisks,
          (res: string[], aRisk: RiskSummary | RiskModel) => {
            aRisk = aRisk as RiskModel;
            if (aRisk.description) {
              res.push(aRisk.description);
            }
            return res;
          },
          []
        );
        break;
      case GenericProductType.Home:
        riskDesc = _reduce(
          policyRisks,
          (res: string[], aRisk: RiskModel) => {
            /**
             * AS: Policy Summaries now gives us riskDescription so use it if we have one.
             */
            if (aRisk.description) {
              res.push(aRisk.description);
            }
            return res;
          },
          []
        );
        break;
      case GenericProductType.Umbrella:
        riskDesc = ['Umbrella'];
        break;
      default:
        riskDesc = _reduce(
          policyRisks,
          (res: string[], aRisk: RiskModel) => {
            /**
             * AS: Policy Summaries now gives us riskDescription so use it if we have one.
             */
            if (aRisk.description) {
              res.push(aRisk.description);
            }
            return res;
          },
          []
        );
        break;
    }
    return riskDesc;
  }

  private transmutePolicyRiskIcons(policyRisks: RiskModel[]): string[] {
    return policyRisks.map(policyRisk => policyRisk.iconType);
  }

  /*
      Determine the date picker options given a list of objects that have
      1. Bill Account Number
      2. Payment Due Date
      Disable when:
      * paymenetDueDate is today or before
      Enable when:
      * paymentDueDate is after today
    */

  determineFullPayDiscountText(selectedValue: string, billAccount: BillAccount): string {
    let discountText = '';

    // If full pay is available, determine the correct messaging.
    if (billAccount && selectedValue && billAccount.fullPayBalance > 0) {
      if (
        selectedValue === 'unpaidBalance' ||
        selectedValue === 'accountBalance' ||
        selectedValue === 'FullPay'
      ) {
        discountText = this.copyService.get('billing', 'fullPayDiscountConfirmation');
      } else {
        // Show the default prompt without a date
        discountText = this.copyService.get('billing', 'fullPayDiscountPrompt');

        // Embed the due date if it is later than today to tell them when they must pay by
        if (isAfter(billAccount.paymentDueDate, format(new Date(), 'YYYY-MM-DD'))) {
          discountText =
            this.copyService.get('billing', 'fullPayDiscountDatePromptPre') +
            format(billAccount.paymentDueDate, 'MM/DD/YYYY') +
            this.copyService.get('billing', 'fullPayDiscountDatePromptPost');
        }
      }
    }

    return discountText;
  }

  public buildRegistrationPayload(
    billAccount: BillAccount,
    correlationId: string,
    policyList: Policy[] = []
  ): RegisterBillAccModel {
    const billAccNumber: string = _get(billAccount, 'billAccountNumber', '');
    let policyNumberForRegistration: string;
    for (const billAccPolicyNumber of billAccount.policyList) {
      if (policyList.find(policy => policy.policyNumber === billAccPolicyNumber.policyNumber)) {
        policyNumberForRegistration = billAccPolicyNumber.policyNumber;
        break;
      }
    }
    const payload: RegisterBillAccModel = {
      billAccountNumber: billAccount.billAccountNumber,
      correlationId: correlationId,
      isUnregister: false,
      registrationData: {
        policyNumber: policyNumberForRegistration,
        accountName:
          'Billing Account End ' +
          billAccNumber.substring(billAccNumber.length - 4, billAccNumber.length),
        reminder: '7',
        associated: billAccount.associated ? true : false
      }
    };
    return payload;
  }

  /**
   * @author Abhishek Singh
   * @returns Policy List,
   * @param : List of policies to be transformed
   * @description  Takes in the list of policies asssociated with the bill account and returns the policy list with
   * the description. Policy list from the party call gives the policy description we need.
   */
  public transformBillingPolicyList(
    billingPolicyList: PolicyList = [],
    policyRiskList: RiskModel[] = []
  ): Observable<PolicyList> {
    // Get the policies associated to this bill account
    let filteredBillingPolicyList: PolicyList = [];

    billingPolicyList.forEach((policyListItem, index) => {
      // Find the matching policy summary
      const matchingPolicyRiskList = policyRiskList.filter(
        policy =>
          /**
           * Compare only the first 10 digits of the policy number. There is discrepancy in the policy
           * being returned as part of party call vs the billing detail call. Billing detail has an additional
           * 2 digits as part of checksum appended at the end of the policy numbers.
           * NOTE: This is happening for classic policies.
           */
          policy &&
          policy.policyNumber.slice(0, 10) === _get(policyListItem, 'policyNumber', '').slice(0, 10)
      );

      if (_get(matchingPolicyRiskList, 'length', 0) > 0) {
        // AS: Since all the risk are of the same policy, which we have ensured above, we can safely go ahead and use
        // the first risk information from the array to get the information about the policy.
        const policyGeneralizedProductType: number =
          matchingPolicyRiskList[0].generalizedProductType;
        const policyStartDate: Date = matchingPolicyRiskList[0].periodStartDate;
        const policyEndDate: Date = matchingPolicyRiskList[0].periodEndDate;
        const policyNumber: string = matchingPolicyRiskList[0].policyNumber;

        // Build out the risk description
        // DP: Building policy risk icons list for each policy risk under a bill account
        const summary: PolicyListItem = Object.assign({}, policyListItem, {
          risks: this.transmutePolicyRisks(matchingPolicyRiskList),
          riskDescriptionList: this.transmutePolicyRiskDesc(matchingPolicyRiskList),
          policyTypeForRouting: this.getPolicyTypeFromCode(policyGeneralizedProductType),
          effectiveDate: policyStartDate,
          endEffectiveDate: policyEndDate,
          policyNumber: policyNumber,
          riskIconList: this.transmutePolicyRiskIcons(matchingPolicyRiskList)
        });

        // Add to the list
        filteredBillingPolicyList = [...filteredBillingPolicyList, summary];
      } else {
        // If there were no match, then return the same policyList object and continue on.
        filteredBillingPolicyList = [...filteredBillingPolicyList, billingPolicyList[index]];
      }
    });

    return of(filteredBillingPolicyList);
  }

  public getIcon(
    billAccount: BillAccount,
    policyRisks: RiskModel[],
    policyEntities: Dictionary<Policy>
  ): BillAccount {
    const filteredPolicyRisks: RiskModel[] = [];
    const filteredPolicyEntities: Policy[] = [];
    billAccount.policyList.forEach(policyListItem => {
      policyRisks.map(policyRisk => {
        if (policyRisk.policyNumber.slice(0, 10) === policyListItem.policyNumber.slice(0, 10)) {
          filteredPolicyRisks.push(policyRisk);
        }
      });
      for (const entity in policyEntities) {
        if (policyEntities[entity]) {
          if (
            policyEntities[entity].policyNumber.slice(0, 10) ===
            policyListItem.policyNumber.slice(0, 10)
          ) {
            filteredPolicyEntities.push(policyEntities[entity]);
          }
        }
      }
    });

    const iconClass = this.getItemIconClass(
      filteredPolicyRisks.length ? filteredPolicyRisks : filteredPolicyEntities,
      billAccount
    );
    const policyTypeDisplayName = this.getPolicyType(filteredPolicyRisks);
    return Object.assign({}, billAccount, {
      icon: iconClass,
      policyTypeDisplayName: policyTypeDisplayName
    });
  }

  private getItemIconClass(policyEntities?: any[], billAccount?: BillAccount): string {
    if (policyEntities && policyEntities?.length) {
      // Check for unique icon type within policyRisks map
      const uniquePolicyRiskByIconType = _uniqBy(policyEntities, 'iconType');

      // if: there's only one policy risk
      return policyEntities.length === 1
        ? // then: use it's icon
          policyEntities[0].iconType
        : // if: icon is the same for all risks
        uniquePolicyRiskByIconType.length === 1
        ? // then: use the shared icon type
          uniquePolicyRiskByIconType[0].iconType
        : // else use combined bill icon
          PolicyTypeIconConstants.MIXED;
    } else {
      return !billAccount?.associated
        ? PolicyTypeIconConstants.UNASSOCIATED
        : PolicyTypeIconConstants.OTHER;
    }
  }

  public getPolicyType(policyRisks?: RiskModel[]): string {
    // Check for unique policy type name within policyRisks map
    const uniquePolicyRiskByPolicyTypeName = _uniqBy(policyRisks, 'policyTypeName');

    if (policyRisks) {
      // if: there's only one policy risk
      if (policyRisks.length === 1) {
        // then: use it's policy type
        if (policyRisks[0].policyTypeName === 'Home') {
          return PolicyTypeDisplayNameConstants.HOME;
        } else {
          return policyRisks[0].vehicleTypeDisplayName || policyRisks[0].policyTypeName;
        }

        // else: more than one risk
      } else {
        // if: all risks share the same policy type
        if (uniquePolicyRiskByPolicyTypeName.length === 1) {
          // if: the shared policy type is Auto
          if (uniquePolicyRiskByPolicyTypeName[0].policyTypeName === 'Auto') {
            // return the shared auto policy type
            return this.getAutoPolicyType(policyRisks);
            // else: shared policy type is not Auto
          } else {
            // return the shared policy type name
            if (uniquePolicyRiskByPolicyTypeName[0].policyTypeName === 'Home') {
              return PolicyTypeDisplayNameConstants.HOME;
            } else {
              return uniquePolicyRiskByPolicyTypeName[0].policyTypeName;
            }
          }
        }

        // else: risks don't share the same policy type, return mixed
        return PolicyTypeDisplayNameConstants.MIXED;
      }
    }
    return PolicyTypeDisplayNameConstants.OTHER;
  }

  private getAutoPolicyType(policyRisks: RiskModel[]): string {
    // Check for unique risk vehicle specific type within policyRisks map
    const uniquePolicyRiskByVehicleDisplayName = _uniqBy(policyRisks, 'vehicleTypeDisplayName');

    // Check for unique risk vehicle type name within policyRisks map
    const uniquePolicyRiskByVehicleTypeName = _uniqBy(policyRisks, 'vehicleTypeName');

    // Check for unique risk vehicle type (code) within policyRisks map
    const uniquePolicyRiskByVehicleType = _uniqBy(policyRisks, 'vehicleType');

    // if: there is a unique vehicle type name, use that
    if (uniquePolicyRiskByVehicleDisplayName.length === 1) {
      return uniquePolicyRiskByVehicleTypeName[0].vehicleTypeDisplayName;

      // else if: there is a unique vehicle type name, use that
    } else if (uniquePolicyRiskByVehicleTypeName.length === 1) {
      return uniquePolicyRiskByVehicleTypeName[0].vehicleTypeName;

      // else if: check for a unique vehicle type and use that if found
    } else if (uniquePolicyRiskByVehicleType.length === 1) {
      return AutoPolicy.getStatus(uniquePolicyRiskByVehicleType[0]);
    } else {
      // else: it's a mixed bag of autos
      return PolicyTypeDisplayNameConstants.MIXED;
    }
  }

  public initiateSilentRegistration(
    billAccountNumbersToBeRegistered: string[]
  ): Observable<BillAccount[]> {
    const correlationId = this.utilService.generateId();
    const silentRegistrationPayload = this.buildSilentRegistrationPayload(
      billAccountNumbersToBeRegistered,
      correlationId
    );
    this.store.dispatch(
      new fromBillingActions.BillAccountSilentRegistration(silentRegistrationPayload)
    );

    return combineLatest([
      // get list of billaccounts we want to register
      this.store
        .select(billaccountsQuery.getBillAccounts)
        .pipe(
          map(billAccounts =>
            billAccounts.filter(billAccount =>
              billAccountNumbersToBeRegistered.includes(billAccount.billAccountNumber)
            )
          )
        ),
      // get notifications for these accounts
      this.store
        .select(billaccountsQuery.getAllBillAccountsNotifications)
        .pipe(
          map(notifications =>
            notifications.filter(notification =>
              billAccountNumbersToBeRegistered.includes(notification.billAccountNumber)
            )
          )
        )
    ]).pipe(
      // wait for all api calls to finish
      filter(([billAccounts, billAccountsNotifications]) =>
        billAccountsNotifications.every(
          notification => notification.correlationId === correlationId
        )
      ),
      // return bill accounts that have been sucessfully registered
      map(([billAccounts, billAccountsNotifications]) =>
        billAccounts.filter(billAccount => billAccount.billingMethod === ONLINE_BILLING)
      )
    );
  }

  public buildSilentRegistrationPayload(
    billAccNumberList: string[],
    correlationId: string
  ): SilentRegistrationPayload[] {
    const silentRegistrationPayload: SilentRegistrationPayload[] = [];
    for (const billAccNumber of billAccNumberList) {
      const payload = {
        billAccountNumber: billAccNumber,
        correlationId: correlationId
      };
      silentRegistrationPayload.push(payload);
    }

    return silentRegistrationPayload;
  }

  /**
   * @param : Takes the policy generalized code set in policy base
   * @return : Policy type string which is used to route to the type of policy
   */
  private getPolicyTypeFromCode(policyCode: number): string {
    switch (policyCode) {
      case GenericProductType.Auto:
        return 'auto';
      case GenericProductType.Home:
        return 'property';
      case GenericProductType.Umbrella:
        return 'umbrella';
      case GenericProductType.Life:
        return 'life';
      default:
        return '';
    }
  }

  /**
   * @param : Take in the AutopayModel as the input
   * @return : Returns the transformed
   */
  public transformPayloadForAutopayPredict(
    autopayPayload: AutoPayPayloadModel
  ): AutopayPredictPayloadModel {
    return {
      autopayRule: autopayPayload.payload.autopayRule,
      predictionOnly: true,
      billAccountNumber: autopayPayload.billAccountNumber
    };
  }
}

import { AddEditAutoPayPayload, DeleteAutoPayPayload } from '@amfam/billing/auto-pay/data-access/src';
import {
  AddEditPaymentAccountDTO,
  AddEditPaymentAccountRequestModel,
  AddEditPaymentAccountResponseModel,
  AutomaticPayment,
  AutoPayPayloadModel,
  AutopayPredictPayloadModel,
  DeletePaymentAccountRequestModel,
  DeletePaymentAccountResponseModel,
  EditScheduledPaymentResponseModel,
  FinAcctServiceRequestModel,
  FinAcctServiceResponseModel,
  GetRegisteredPaymentsResponse,
  INVALID_IMPERSONATION_STATUS_CODE,
  MultipleAutoPayPayloadModel,
  MultipleAutoPayResponse,
  PaymentConfirmationModel,
  SchedulePaymentBillAccount,
  SchedulePaymentPayloadModel,
  SubmitScheduledPaymentPayloadModel,
  SubmitScheduledPaymentResponseModel,
  UpdateModeOfAuthRequestModel
} from '@amfam/shared/models';
import { ConfigService, CopyService, UtilService } from '@amfam/shared/utility/shared-services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// date-fns
import { format } from 'date-fns';
import {
  find as _find,
  flatMap as _flatMap,
  get as _get,
  has as _has,
  omit as _omit
} from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// TODO externalize this:
import { FinancialAccountService } from './financial-account.service';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private finAcctService: FinancialAccountService,
    private copyService: CopyService,
    private utilService: UtilService
  ) {}

  get paymentApi() {
    return this.config.get('paymentApi');
  }

  // ngrx-store Implementation
  public getPaymentMethods() {
    const endpoint = this.paymentApi + 'paymentaccounts';
    return this.http.get(endpoint);
  }

  // ngrx-store Implementation
  public getPaymentMethodDetail(payloadObj: any) {
    const endpoint = this.paymentApi + 'paymentaccounts/' + payloadObj;
    return this.http.get(endpoint);
  }

  /*
    Get a list of scheduled & authorized payments.

    Required parameters:
    startDate: Today minus 31 days. YYYY-MM-DD
    endDate: Today + 31 days, YYYY-MM-DD
    registeredOnly: false - We want all payment methods (OLB, PayNow, AFT, etc.)
    includeFinancials: true - returns masked payment account details
  */
  // TODO: Calculate an appropriate date range for query using gateway time
  getRegisteredPayments(
    startDate: string,
    endDate: string,
    registeredOnly: boolean,
    includeFinancials: boolean
  ): Observable<GetRegisteredPaymentsResponse> {
    const endpoint = this.config.get('paymentApi') + 'registeredpayments';
    const params: HttpParams = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('registeredOnly', String(registeredOnly))
      .set('includeFinancials', String(includeFinancials));

    return this.http.get<GetRegisteredPaymentsResponse>(endpoint, { params });
  }

  // ngrx-store Implementation
  public getAutomaticPaymentRule(billAccountId: string) {
    const uri: string = this.paymentApi + 'billaccounts/' + billAccountId + '/autopay';
    return this.http.get(uri);
  }

  // ngrx-store Implementation
  public postAutopayPredict(apiPayload: AutopayPredictPayloadModel) {
    const uri: string =
      this.paymentApi + 'billaccounts/' + apiPayload.billAccountNumber + '/autopay';
    return this.http.post(uri, apiPayload);
  }

  // ngrx-store Implementation
  public putAutopayPredict(apiPayload: AutopayPredictPayloadModel) {
    const updatedPayload = Object.assign({}, apiPayload);
    updatedPayload.transactionId = this.utilService.generateId();
    const uri: string =
      this.paymentApi + 'billaccounts/' + apiPayload.billAccountNumber + '/autopay';
    return this.http.put(uri, updatedPayload);
  }

  // ngrx-store Implementation
  public savePaymentMethod(finAcctPayload: FinAcctServiceRequestModel): Observable<any> {
    const fasPayload = Object.assign({}, _omit(finAcctPayload, ['expiring', 'expired']), {
      consumerKey: this.config.get('finAcctConsumerKey')
    });
    return this.finAcctService.saveFinancialAccount(fasPayload);
  }

  // ngrx-store Implementation
  public addPaymentMethod(payloadObj: AddEditPaymentAccountRequestModel): Observable<any> {
    const uri: string = this.paymentApi + 'paymentaccounts';
    return this.http.post(uri, payloadObj);
  }

  // ngrx-store Implementation
  public editPaymentMethod(
    payloadObj: AddEditPaymentAccountRequestModel,
    paymentId: string
  ): Observable<any> {
    const uri: string = this.paymentApi + 'paymentaccounts/' + paymentId;
    return this.http.put(uri, payloadObj);
  }

  public updateModeOfAuth(
    payloadObj: UpdateModeOfAuthRequestModel,
    paymentId: string
  ): Observable<any> {
    const uri: string = this.paymentApi + 'paymentaccounts/' + paymentId;
    return this.http.put(uri, payloadObj);
  }

  // ngrx-store Implementation
  public deletePaymentMethod(payloadObj: DeletePaymentAccountRequestModel): Observable<any> {
    const uri = this.paymentApi + 'paymentaccounts/' + payloadObj.paymentAccountId;
    return this.http.delete(uri);
  }

  // ngrx-store Implementation
  public addScheduledPayment(payloadObj: SubmitScheduledPaymentPayloadModel): Observable<any> {
    const uri = this.paymentApi + 'registeredpayments';
    return this.http.post(uri, payloadObj.requestJson);
  }

  // ngrx-store Implementation
  // Failures come back as 500s inside a 200 ?!
  public editScheduledPayment(payloadObj: SchedulePaymentPayloadModel): Observable<any> {
    // Strip off the unwanted elements from within the bill accounts array
    const updatedBillAccounts = [];
    _get(payloadObj, 'payment.billAccounts', []).forEach(billAccount => {
      const ba = _omit(billAccount, 'billAccountNickName');
      updatedBillAccounts.push(ba);
    });

    // Build a payment object with the updated bill accounts and add the OLB payment method
    const updatedPaymentObject = Object.assign({}, payloadObj.payment, {
      billAccounts: updatedBillAccounts,
      paymentMethod: 'OLB'
    });

    // Reconstruct the payload grabbing the last update timestamp, the payment object and add a transactionId
    const updatedPayload = Object.assign(
      {},
      {
        lastUpdateTimestamp: payloadObj.lastUpdateTimestamp,
        transactionId: this.utilService.generateId(),
        payment: updatedPaymentObject
      }
    );

    const url = this.paymentApi + 'registeredpayments/' + payloadObj.paymentId;
    return this.http.put(url, updatedPayload).pipe(
      map((response: any) => {
        if (String(_get(response, 'status.code', '')) !== '200') {
          throw response;
        }
        return response;
      })
    );
  }

  // ngrx-store Implementation
  public deleteScheduledPayment(payloadObj: any): Observable<any> {
    const uri = this.paymentApi + 'registeredpayments/' + payloadObj.paymentId;
    return this.http.delete(uri);
  }

  // ngrx-store Implementation
  public submitMultipleAutomaticPaymentRule(
    payloadObj: MultipleAutoPayPayloadModel
  ): Observable<MultipleAutoPayResponse> {
    const uri = this.paymentApi + 'automaticpayments';
    return this.http.post<MultipleAutoPayResponse>(uri, payloadObj).pipe(
      map(response => {
        /**
         * Status code of '202304' will be returned if the user is impersonating and is
         * trying to add a payment rule without sufficient permission to do so.
         * In this case treat the response code of '202304' as a failure code and stop them from
         * moving forward.
         */
        if (_get(response, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
          throw response;
        }
        return response;
      })
    );
  }

  // ngrx-store Implementation
  public submitAutomaticPaymentRule(payloadObj: AutoPayPayloadModel): Observable<any> {
    const uri = this.paymentApi + 'billaccounts/' + payloadObj.billAccountNumber + '/autopay';
    return this.http.post(uri, payloadObj.payload).pipe(
      map(response => {
        /**
         * Status code of '202304' will be returned if the user is impersonating and is
         * trying to add a payment rule without sufficient permission to do so.
         * In this case treat the response code of '202304' as a failure code and stop them from
         * moving forward.
         */
        if (_get(response, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
          throw response;
        }
        return response;
      })
    );
  }

  // ngrx-store Implementation
  public editAutomaticPaymentRule(payloadObj: any): Observable<any> {
    const updatedPayload = Object.assign({}, payloadObj.payload);
    updatedPayload.transactionId = this.utilService.generateId();
    const uri = this.paymentApi + 'billaccounts/' + payloadObj.billAccountNumber + '/autopay';
    return this.http.put(uri, updatedPayload);
  }

  // ngrx-store Implementation
  public deleteAutomaticPaymentRule(payloadObj: any): Observable<any> {
    const uri = this.paymentApi + 'billaccounts/' + payloadObj.billAccountNumber + '/autopay';
    return this.http.delete(uri);
  }

  // The response from the fin accout service gets appended to the add/edit payment DTO
  public attachFinAcctServiceResponse(
    payload: AddEditPaymentAccountDTO,
    finAccResObj: FinAcctServiceResponseModel
  ) {
    const response = Object.assign({}, payload, {
      customerPaymentPayload: {
        paymentAccount: {
          nickName: _get(payload, 'finAcctPayload.nickName', ''),
          modeOfAuthorization: _get(payload, 'modeOfAuthorization'),
          token: _get(finAccResObj, 'finAcctServiceResponse.tokenId', ''),
          consumerKey: _get(finAccResObj, 'finAcctServiceResponse.consumerKey', '')
        }
      }
    });
    if (_has(payload, 'lastUpdateTimestamp')) {
      response.customerPaymentPayload.lastUpdateTimestamp = payload.lastUpdateTimestamp;
    }
    return response;
  }

  public buildAddEditPaymentMethodConfirmation(
    payloadObj: AddEditPaymentAccountDTO,
    cpResponse: AddEditPaymentAccountResponseModel
  ): PaymentConfirmationModel {
    const paymentConfirmationObj: PaymentConfirmationModel = {
      confirmationId: _get(cpResponse, 'status.transactionId'),
      category: 'paymentMethod',
      subCategory: '',
      paymentMethod: {
        nickName: payloadObj.finAcctPayload.nickName,
        date: format(new Date(), 'YYYY-MM-DD'),
        creditCard: payloadObj.finAcctPayload.creditCard,
        achWithdrawal: payloadObj.finAcctPayload.bankAccount
      }
    };
    const action = String(_get(payloadObj, 'action', ''));
    switch (action) {
      case 'edit':
        paymentConfirmationObj.subCategory = 'editPaymentMethod';
        break;
      case 'add':
        paymentConfirmationObj.subCategory = 'addPaymentMethod';
        break;
      case 'delete':
        paymentConfirmationObj.subCategory = 'deletePaymentMethod';
        break;
    }

    return paymentConfirmationObj;
  }

  public buildDeletePaymentMethodConfirmationData(
    payloadObj: DeletePaymentAccountRequestModel,
    response: DeletePaymentAccountResponseModel
  ): PaymentConfirmationModel {
    const paymentConfirmationObj: PaymentConfirmationModel = {
      confirmationId: _get(response, 'status.transactionId'),
      category: 'paymentMethod',
      subCategory: 'deletePaymentMethod',
      paymentMethod: {
        nickName: payloadObj.nickName,
        date: format(new Date(), 'YYYY-MM-DD'),
        creditCard: payloadObj.creditCard,
        achWithdrawal: payloadObj.bankAccount
      }
    };
    return paymentConfirmationObj;
  }

  public buildAddAutomaticPaymentConfirmationData(
    payloadObj: AutoPayPayloadModel,
    response: any
  ): PaymentConfirmationModel {
    const confirmationObj: PaymentConfirmationModel = Object.assign({}, payloadObj, {
      payload: {
        autopayRule: [payloadObj.payload.autopayRule],
        autopayPredictRule: _flatMap(response.autoPayRules, (autoPayRule: AutomaticPayment) => {
          if (!isNaN(Number.parseFloat(_get(autoPayRule, 'predictedDollarAmount', 'UNKNOWN')))) {
            return autoPayRule;
          }
        })
      },
      category: 'automaticPaymentRule',
      subCategory: 'add',
      transactionId: response.status.transactionId
    });
    return confirmationObj;
  }

  public buildMultipleAddAutomaticPaymentConfirmationData(
    payloadObj: MultipleAutoPayPayloadModel,
    response: MultipleAutoPayResponse,
    autopayRulesWithSuccessCode: MultipleAutoPayResponse['autoPayRules'] = [],
    autopayRulesWithFailureCode: MultipleAutoPayResponse['partialStatuses'] = []
  ): PaymentConfirmationModel {
    const confirmationObj: PaymentConfirmationModel = Object.assign({}, payloadObj, {
      payload: {
        autopayRule: payloadObj.accounts.filter(
          autopayItem =>
            !!autopayRulesWithSuccessCode.find(
              autoPayRule => autoPayRule.billAccountNumber === autopayItem.billingNumber
            )
        ),
        autopayPredictRule: _flatMap(response.autoPayRules, (autoPayRule: AutomaticPayment) => {
          if (!isNaN(Number.parseFloat(_get(autoPayRule, 'predictedDollarAmount', 'UNKNOWN')))) {
            return autoPayRule;
          }
        }),
        failedAutoPayRules: payloadObj.accounts.filter(
          autopayItem =>
            !!autopayRulesWithFailureCode.find(
              autoPayRule => autoPayRule.payloadEntityId === autopayItem.billingNumber
            )
        )
      },
      category: 'multipleAutomaticPaymentRule',
      subCategory: 'add',
      transactionId: ''
    });
    return confirmationObj;
  }

  public buildEditAutomaticPaymentConfirmationData(
    payloadObj: AddEditAutoPayPayload | AutoPayPayloadModel,
    response: any
  ): PaymentConfirmationModel {
    // Store the confirmation object
    const confirmationObj: any = Object.assign({}, payloadObj, {
      payload: {
        autopayRule: response[0]
      },
      category: 'automaticPaymentRule',
      subCategory: 'edit',
      transactionId: _get(response, 'status.transactionId', '')
    });
    return confirmationObj;
  }

  public buildDeleteAutomaticPaymentConfirmationData(
    payloadObj: DeleteAutoPayPayload | AutoPayPayloadModel,
    autoPayResponse: any,
    pendingScheduledPayment?: boolean
  ): PaymentConfirmationModel {
    let confirmationObj: any = payloadObj;
    confirmationObj = Object.assign({}, confirmationObj, {
      payload: {
        autopayRule: autoPayResponse[0]
      },
      category: 'automaticPaymentRule',
      subCategory: pendingScheduledPayment === true ? 'delete' : 'deleteWithoutScheduledPayment',
      action: payloadObj.action
    });

    if (!(_has(autoPayResponse, 'status.code') && String(autoPayResponse.status.code) === '200')) {
      confirmationObj = {};
    }

    return confirmationObj;
  }

  public buildAddScheduledPaymentConfirmationData(
    payloadObj: SubmitScheduledPaymentPayloadModel,
    response: SubmitScheduledPaymentResponseModel
  ): PaymentConfirmationModel {
    const billAccsLength = _get(payloadObj, 'requestJson.payment.billAccounts.length');
    const confirmationObj: PaymentConfirmationModel = {
      amount: Number(_get(payloadObj, 'requestJson.payment.totalPaymentAmount')),
      date: String(_get(payloadObj, 'requestJson.payment.receivedDate')),
      transactionId: String(_get(response, 'status.transactionId')),
      moduleName: 'Billing',
      category: 'scheduledPayments',
      subCategory: this.determineSubcategory(
        _get(payloadObj, 'requestJson.payment.billAccounts', [])
      ),
      confirmationMsgHeading: '',
      billAccounts: _get(payloadObj, 'requestJson.payment.billAccounts'),
      paymentAccount: String(_get(payloadObj, 'requestJson.payment.paymentAccount.nickName')),
      autoPay: _get(payloadObj, 'autoPayIndicator'),
      paymentTerm: _get(payloadObj, 'paymentTerm'),
      paymentConfirmationId: _get(response, 'confirmationId'),
      paymentId: response.paymentId
    };
    return confirmationObj;
  }

  private determineSubcategory(billAccounts: SchedulePaymentBillAccount[]) {
    if (billAccounts.length > 0 && _get(billAccounts[0], 'pastDueInfo')) {
      let subCategory = 'pastAndCurrentDue';
      billAccounts.forEach(ba => {
        if (ba.pastDueInfo.currentAmountDue > 0) {
          subCategory = 'pastDue';
        }
      });
      return subCategory;
    } else {
      return billAccounts.length === 1
        ? 'scheduled'
        : billAccounts.length > 0
        ? 'multipleScheduled'
        : 'noAccounts';
    }
  }

  public buildEditScheduledPaymentConfirmationData(
    payloadObj: SchedulePaymentPayloadModel,
    response: EditScheduledPaymentResponseModel
  ): PaymentConfirmationModel {
    const confirmationObj: PaymentConfirmationModel = {
      amount: Number(payloadObj.payment.totalPaymentAmount),
      date: String(payloadObj.payment.receivedDate),
      transactionId: String(response.status.transactionId),
      moduleName: 'Billing',
      category: 'scheduledPayments',
      subCategory: 'editScheduled',
      confirmationMsgHeading: '',
      billAccounts: payloadObj.payment.billAccounts,
      paymentAccount: String(payloadObj.payment.paymentAccount.nickName),
      paymentTerm: _get(payloadObj, 'paymentTerm')
    };
    return confirmationObj;
  }

  public buildDeleteScheduledPaymentConfirmationData(
    payloadObj: SchedulePaymentPayloadModel,
    response: any
  ): PaymentConfirmationModel {
    const confirmationObj: any = {
      amount: Number(_get(payloadObj, 'payment.totalPaymentAmount')),
      date: String(_get(payloadObj, 'payment.receivedDate')),
      transactionId: String(_get(response, 'status.transactionId')),
      moduleName: 'Billing',
      category: 'scheduledPayments',
      subCategory: 'deleteScheduled',
      confirmationMsgHeading: '',
      billAccounts: _get(payloadObj, 'payment.billAccounts'),
      paymentAccount: String(_get(payloadObj, 'payment.paymentAccount.nickName')),
      paymentId: _get(payloadObj, 'paymentId')
    };
    return confirmationObj;
  }

  // Convenience wrapper for unpacking error messages
  public buildErrorList(payload: { messages?: Array<{}>; [propName: string]: any }) {
    const errorMsgArray = new Array<String>();
    if (_get(payload, 'messages')) {
      payload.messages.forEach(
        (errorMsgObj: { details?: Array<{}>; code?: string; [propName: string]: any }) => {
          let errMsg = '';
          if (errorMsgObj.hasOwnProperty('code')) {
            errMsg = this.copyService.get('billing', 'errorMsgsModified')[0][
              errorMsgObj.code.toString()
            ];
            if (errMsg) {
              errorMsgArray.push(errMsg);
            }
          }
          if (errorMsgObj.hasOwnProperty('details') && !errMsg) {
            if (!Array.isArray(errorMsgObj.details)) {
              errorMsgObj.details = [errorMsgObj.details];
            }
            errorMsgObj.details.forEach(
              (detailObj: { description?: string; [propName: string]: any }) => {
                if (detailObj) {
                  errMsg = this.copyService.get('billing', 'errorMsgsModified')[0][
                    detailObj.description
                  ];
                  if (errMsg) {
                    errorMsgArray.push(errMsg);
                  }
                }
              }
            );
          }
        }
      );
    } else if (_get(payload, 'status.messages')) {
      payload.status.messages.forEach(errorMsgObj => {
        if (errorMsgObj.hasOwnProperty('code')) {
          const errorMsg = this.copyService.get('billing', 'errorMsgsModified')[0][
            errorMsgObj.code.toString()
          ];
          if (errorMsg) {
            errorMsgArray.push(errorMsg);
          }
        }
      });
      // We will not get json response body with this request, hence we use '304' instead of '304001'
    } else if (_get(payload, 'code') && payload.code.toString() === '304') {
      const errorMsg = this.copyService.get('billing', 'errorMsgsModified')[0][
        payload.code.toString()
      ];
      if (errorMsg) {
        errorMsgArray.push(errorMsg);
      }
    }
    /**
     * AS: If an error occured an we don't know what message to throw, we then show a generic message.
     */
    if (errorMsgArray.length === 0) {
      errorMsgArray.push(this.copyService.get('billing', 'defaultBillingErrorMessage'));
    }
    return errorMsgArray.length ? errorMsgArray : null;
  }

  /**
   *
   * @param errorMessages: Message object which comes as a part of error response of the finaccount service call.
   * @function finAccountValidationErrorHandler: Takes in the the message object and returns a specific error message based
   * on a code.
   */
  private finAccountValidationErrorHandler(errorMessages) {
    let tempMsg;
    errorMessages.forEach(msg => {
      if (msg.code === '400003') {
        tempMsg = Object.assign({}, msg, {
          details: [
            {
              description: _find(
                this.copyService.get('billing', 'errorMsgs'),
                (copyErrorMsg: any) => copyErrorMsg.key === msg.code
              ).key
            }
          ]
        });
      }
    });
    if (tempMsg) {
      errorMessages = [...errorMessages, tempMsg];
    }
    return errorMessages;
  }
}

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { PageAnalytic } from '@amfam/shared/utility/shared-services';
import { Component, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionActivityService } from '../../login/session-activity/session-activity.service';

@Component({
  selector: 'session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit, OnDestroy, OnChanges {
  private showHideSub: Subscription;
  private originalFocusElem: Element;

  public show: boolean;
  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:SessionTimeOutWarning',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'MyAccount:SessionTimeoutEnroll',
    subCategory2: '',
    subCategory3: ''
  };
  // **** End of Analytics data for this component

  constructor(
    private activityService: SessionActivityService,
    private analyticsFacade: AnalyticsFacade,
    private elem: ElementRef
  ) {}

  ngOnInit() {
    // AS : Subscribe to the SessionActivityService function showSessionWarning$
    // which would tell us if its time to say bye - bye.
    this.showHideSub = this.activityService.showSessionWarning$.subscribe((status: boolean) => {
      this.show = status;
    });

    this.originalFocusElem = document.activeElement;
    this.elem.nativeElement.setAttribute('tabindex', '-1');
    this.elem.nativeElement.focus();
  }

  ngOnChanges() {
    if (this.show) {
      this.analyticsFacade.trackPage(this.pageAnalytic);
    }
  }

  ngOnDestroy() {
    if (this.showHideSub) {
      this.showHideSub.unsubscribe();
    }
  }

  // AS : If the user wants to stay logged in call the service to extent his
  // session and reset all the timers and variables.
  continueSession() {
    this.activityService.keepAlive();
    this.show = false;
    this.restoreFocus();
  }

  // AS : If the user is done and wants to exit we call the service to
  // clean up after his mess and redirect to the login page.
  exitSession() {
    this.activityService.userDone();
  }

  // AS : User did not click on any of the option on the modal screen and clicks
  // on the close button.
  closeActivityModal() {
    this.show = false;
    this.restoreFocus();
  }

  private restoreFocus() {
    /*
      Reset the focus to the element that fired the modal

      The instanceof is a trick to add .focus to the element.
      this.originalFocusElemEl is from document.activeElement and is of type Element, not HTMLElement
      Element doesn't have .focus(), but HTMLElement does. Source: https://github.com/Microsoft/TypeScript/issues/5901#issuecomment-431649653
    */
    if (this.originalFocusElem instanceof HTMLElement) {
      this.originalFocusElem.focus(); // reset focus
      this.originalFocusElem = null; // clear variable
    }

    this.elem.nativeElement.removeAttribute('tabindex');
  }
}

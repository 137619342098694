import { AnalyticsFacade } from '@amfam/shared/analytics';
import { userQuery } from '@amfam/shared/user';
import {
  ApplicationService,
  Applications,
  ConfigService
} from '@amfam/shared/utility/shared-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from '@optimizely/optimizely-sdk';
import { firstValueFrom } from 'rxjs';
import { delay, filter, first, map, take, tap } from 'rxjs/operators';
import { fromFeatureFlagActions } from '../+state/feature-flag.actions';
import { FeatureFlag } from '../models/feature-flag.model';
import { FeatureFlagAnalyticConstants } from './feature-flag-analytic.constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  public optimizelyClient: Client;
  private trackId$ = this.store.select(userQuery.getTrackId);
  private overrideList: { [key: string]: boolean };
  private optimizelyKey: string;
  private optimizelyContextUserId;
  private variationKey: string | null;
  private localRevision: number;
  private remoteRevision: number;

  constructor(
    private configService: ConfigService,
    private store: Store<any>,
    private httpClient: HttpClient,
    private anayticsFacade: AnalyticsFacade,
    private applicationService: ApplicationService
  ) {}

  /**
   *
   * @param key : This key is configured in the config file and should match the value
   * assigned in the optimizely application.
   */

  // TODO: AS: Handle the error page on otimizely faiture
  // TODO: AS: Handle the seamless deployment from preview to prod
  // TODO: AS: Look at the audience feature
  // TODO: AS: CORS error on optimizely when accessing the datafile from CDN

  public async initiateOptimizely(key: string) {
    if (!key) return;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const optimizelySDK = await import('@optimizely/optimizely-sdk');
    optimizelySDK.setLogger(null);

    // Load the local datafile
    const localDataFile = this.configService.get('featureflagsFile');
    console.log('LOCALDATAFILE', localDataFile);
    let datafile = await firstValueFrom(
      this.httpClient.get<any>(
        `/assets/featureflags/${
          localDataFile || 'featureflags-prod.json'
        }?t=${new Date().getTime()}`
      )
    ).catch(err => {
      console.error('ERROR', err);
    });
    if (datafile) {
      datafile = { ...datafile, revision: +datafile.revision - 1 };
      this.localRevision = +datafile?.revision;
    }

    // Initialize the client with local datafile to start with, incaase we cannot
    // fetch the latest optimizly flag it will use the flags from datafile.
    this.optimizelyClient = optimizelySDK.createInstance({
      sdkKey: key,
      datafile: datafile
    });
    this.optimizelyKey = key;
    this.overrideList = this.configService.get('featureOverride');

    this.optimizelyClient
      .onReady()
      .catch(err => {
        console.error('Error in optimizely', err);
      })
      .then(() => {
        // optimizelyClientInstance is ready to use, with datafile downloaded from the
        // Optimizely CDN
        this.store.dispatch(
          fromFeatureFlagActions.loadAllFeatureFlags({
            payload: this.getAllFeatureFlags('', this.optimizelyKey)
          })
        );
      });
  }

  public isEnabled(featureName: string) {
    let trackId = 'anonymous';
    this.trackId$.pipe(take(1)).subscribe(userId => {
      trackId = userId || 'anonymous';
    });
    /**
     * @description: This logic would let us override featureflags set in optimizely.
     */
    if (this.overrideList && this.hasFeature(featureName)) {
      return this.overrideList[featureName];
    }
    return this.optimizelyClient?.isFeatureEnabled(featureName, trackId.toString());
  }

  public getAllFeatureFlags(trackId: string, optimizelyKey: string): FeatureFlag[] {
    //this.trackId = trackId;
    if (!this.optimizelyClient) {
      this.initiateOptimizely(optimizelyKey);
    }
    const optimizelyConfig = this.optimizelyClient.getOptimizelyConfig();
    this.remoteRevision = +optimizelyConfig?.revision;
    if (this.remoteRevision === this.localRevision) {
      this.anayticsFacade
        .isAdobeAnalyticsLoaded()
        .pipe(
          filter(loaded => loaded),
          first(),
          tap(() => {
            this.anayticsFacade.trackPage(
              this.applicationService.isApp(Applications.MYACCOUNT_ADMIN)
                ? FeatureFlagAnalyticConstants.pageDefaultDatafileLoadedMyaccountAdmin
                : FeatureFlagAnalyticConstants.pageDefaultDatafileLoadedMyaccount
            );
          })
        )
        .subscribe();
    }

    const featuresArray: string[] = Object.keys(optimizelyConfig?.featuresMap);
    const enabledFeatures: FeatureFlag[] = [];
    featuresArray.forEach(feature => {
      enabledFeatures.push({
        id: feature,
        enabled: this.hasFeature(feature)
          ? this.overrideList[feature]
          : this.optimizelyClient?.isFeatureEnabled(feature, trackId)
      });
    });
    return enabledFeatures;
  }

  hasFeature(featureName: string): boolean {
    return (
      this.overrideList &&
      this.overrideList[featureName] !== undefined &&
      this.overrideList[featureName] !== null
    );
  }

  // AS: TODO AB Testing
  public getVisitorExperience(
    featureFlagName: string,
    experimentName: string,
    optimizely = this.optimizelyClient,
    userId$ = this.trackId$
  ) {
    let experience = null;
    userId$
      .pipe(
        take(1),
        map(userId => {
          // Create a user and decide a flag rule (such as an A/B test) for them
          this.optimizelyContextUserId = optimizely.createUserContext(userId);
          const decision = this.optimizelyContextUserId.decide(featureFlagName);

          const isEnabled = decision.enabled;
          this.variationKey = this.optimizelyClient.activate(experimentName, userId);

          experience = {
            text: this.variationKey,
            isEnabled: isEnabled
          };
        })
      )
      .subscribe();
    return experience;
  }

  public trackOptimizelyEvent(eventKey: string, ABTestingKey: string, variationName: string) {
    try {
      const decision = this.optimizelyContextUserId.decide(ABTestingKey);
      const isEnabled = decision.enabled;
      if (isEnabled && this.variationKey === variationName) {
        this.optimizelyContextUserId.trackEvent(eventKey);
        this.trackId$
          .pipe(take(1))
          .subscribe(userId => this.optimizelyClient.track(eventKey, userId));
      }
    } catch (e) {
      // NO Key/Variation Error
    }
  }
}

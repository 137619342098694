import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import * as eDelivery from './e-delivery-terms-and-conditions.actions';
import {
  Applications,
  ApplicationService,
  ContentService,
  PartialContentPaths
} from '@amfam/shared/utility/shared-services';

import { get as _get } from 'lodash';
import { EdeliveryTermsAndConditionsResponse } from '../models/e-delivery-terms-and-conditions.model';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { LoadEDeliveryTermsAndConditions } from './e-delivery-terms-and-conditions.actions';
import { BrandService, BrandActions } from '@amfam/shared/utility/brand';

@Injectable()
export class EDeliveryTermsAndConditionsEffects {
  constructor(
    private store: Store<any>,
    private action$: Actions,
    private contentService: ContentService,
    private appService: ApplicationService
  ) {}

  
  //  ** MR: Disable EDelivery effects until removal is approved
  // loading$: Observable<eDelivery.EDeliveryTermsAndConditionsActions> = this.action$.pipe(
  //   ofType(BrandActions.LOAD_BRAND_DATA_SUCCESS),
  //   map((action: BrandActions.LoadBrandDataSuccess) => action.payload),
  //   map(payload => new eDelivery.LoadEDeliveryTermsAndConditions(payload))
  // );
  
  load$: Observable<eDelivery.EDeliveryTermsAndConditionsActions> = createEffect(() => this.action$.pipe(
    ofType(eDelivery.EDeliveryTermsAndConditionsActionTypes.LoadEDeliveryTermsAndConditions),
    map((action: LoadEDeliveryTermsAndConditions) => action.payload),
    // withLatestFrom(this.store.select(BrandSelectors.getBrandId)),
    switchMap(id => {
      const brandId = id.brandId;
      const isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);

      let partialPath: PartialContentPaths = '-edelivery-paperlesspolicytermsandcondition';
      if (isAdminApp) {
        partialPath = '-edelivery-paperlesstermsandcondition-admin';
      }
      const fullPath = brandId + partialPath;
      return <Observable<eDelivery.EDeliveryTermsAndConditionsActions>>(
        this.contentService.getContent(fullPath).pipe(
          map((res: EdeliveryTermsAndConditionsResponse) => {
            if (_get(res, 'richContent')) {
              return new eDelivery.LoadEDeliveryTermsAndConditionssSuccess({
                richContent: res,
                brandId: brandId
              });
            }
            return new eDelivery.LoadEDeliveryTermsAndConditionssFailure({
              error: res,
              brandId: brandId
            });
          }),
          catchError(error => {
            return of(
              new eDelivery.LoadEDeliveryTermsAndConditionssFailure({
                error: error,
                brandId: brandId
              })
            );
          })
        )
      );
    }),
    catchError(error => {
      return of(
        new eDelivery.LoadEDeliveryTermsAndConditionssFailure({ error: error, brandId: '' })
      );
    })
  ));
}

import { PaymentMethod } from '@amfam/billing/payment-method/util';
import { Pipe, PipeTransform } from '@angular/core';
import { format, getHours, getMinutes, isValid } from 'date-fns';
import { camelCase as _camelCase, cloneDeep as _cloneDeep, get as _get, has as _has } from 'lodash';

@Pipe({ name: 'camelCase' })
export class CamelCase implements PipeTransform {
  transform(value: string): string {
    if (value && typeof value === 'string') {
      value = value.replace(/[^a-zA-Z0-9]/g, '');
      return _camelCase(value);
    }
  }
}

@Pipe({ name: 'removeSpecialChars' })
export class RemoveSpecialChars implements PipeTransform {
  transform(value: string): string {
    if (value && typeof value === 'string') {
      value = value.replace(/[^a-zA-Z0-9]/g, '');
      return value;
    }
  }
}
@Pipe({ name: 'titleCaseDashes' })
export class TitleCaseDashPipe implements PipeTransform {
  transform(value: string): string {
    if (value && typeof value === 'string') {
      value = value.replace(/-/g, ' ');
      value = value
        .split(' ')
        .map(i => i.toLowerCase().charAt(0).toUpperCase() + i.toLowerCase().slice(1))
        .join(' ');
      return value;
    }
  }
}

@Pipe({ name: 'titleCaseDashesNoSpaces' })
export class TitleCaseDashNoSpacesPipe implements PipeTransform {
  transform(value: string): string {
    if (value && typeof value === 'string') {
      value = value.replace(/-/g, ' ');
      value = value
        .split(' ')
        .map(i => i.toLowerCase().charAt(0).toUpperCase() + i.toLowerCase().slice(1))
        .join('');
      return value;
    }
  }
}

@Pipe({ name: 'titleCase' })
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      // See my plunkr at: http://plnkr.co/edit/6uaHPb22AYCeeVMO9vix?p=preview

      // Replace any spaces at the beginning or end
      value = value.trim();

      /*
      Replace any non-alphanumeric except ' , _ , & and -
      This takes care of any crazy unicode characters and such as well
      WELLS-FARGO needs to stay intact as does Bob's Bank */
      value = value.replace(/[^a-zA-Z'\-_&\d\s:]/g, '');

      // Replace underscores with spaces
      value = value.replace(/_/g, ' ');

      /*
      \W matches any non-word character (equal to [^a-zA-Z0-9_]), but we've eliminated underscore above
      \s matches any whitespace character and - matches.. -
      With the ^ reversing things, you end up with a-zA-Z0-9 followed by anything other than a space or a -
      when anything other than that is encountered, we break on it and uppercase the first part of.
      e.g., 10th(0) American(1) Bank(2) or Wells(1)-Fargo(2)
      */
      value = value.replace(
        /([^\W]+[^\s-]*) */g,
        i => i.charAt(0).toUpperCase() + i.substring(1).toLowerCase()
      );

      return value;
    }
  }
}

@Pipe({ name: 'currencyUSD' })
export class CurrencyUSDPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    // AS: Return if we cannot transform to a number.
    if (args[0] && isNaN(Number(args[0]))) {
      return args[0];
    }
    let prefix = '';
    if (!value) {
      value = 0;
    } else if (value < 0) {
      // if number is negative...
      value = Math.abs(value); // make it positive and set a prefix so, we can have -$9.99 vs. $-9.99
      prefix = '-';
    }
    if (args[0]) {
      // Drop the decimals if the pipe is passing an argument. Use the first argument (e.g., | currencyUSD:true...)
      // see: https://stackoverflow.com/a/2901298/1317884 for an explaination of this regex.
      return (
        prefix +
        '$' +
        Number(value)
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      );
    }
    return (
      prefix +
      '$' +
      Number(value)
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    );
  }
}

@Pipe({ name: 'coverageCurrencyUSD' })
export class CoverageCurrencyUSDPipe implements PipeTransform {
  transform(value: any, arg: string, stateCode?: string): string {
    // AS: first check if there is a divider to the value eg 25/200
    const valuesArr = String(value).split('/');
    const updatedArr = [];
    for (let valueInArr of valuesArr) {
      valueInArr = valueInArr.replace(/,/g, '');
      // AS: Return if we cannot transform to a number.
      if (valueInArr && isNaN(Number(valueInArr))) {
        updatedArr.push(valueInArr);
        continue;
      }
      let prefix = '';
      if (!valueInArr) {
        updatedArr.push(valueInArr);
        continue;
      } else if (Number(valueInArr) < 0) {
        // if number is negative...
        valueInArr = String(Math.abs(value)); // make it positive and set a prefix so, we can have -$9.99 vs. $-9.99
        prefix = '-';
      }
      if (valueInArr) {
        // Drop the decimals if the pipe is passing an argument. Use the first argument (e.g., | currencyUSD:true...)
        // see: https://stackoverflow.com/a/2901298/1317884 for an explaination of this regex.
        updatedArr.push(
          prefix +
            '$' +
            Number(valueInArr)
              .toFixed(0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        );
      }
    }
    if (this.isMaPARentalCovNotSelected(arg, value, stateCode)) {
      return value;
    }
    if (arg === 'PARentalCov') {
      return updatedArr[0] && updatedArr[1]
        ? `${updatedArr[0]}/day, ${updatedArr[1]} maximum`
        : updatedArr[0].toLowerCase() === 'not selected'
        ? updatedArr[0]
        : `${updatedArr[0]}/day`;
    } else if (arg === 'PAUmpdCov_af' || arg === 'PAUIMPDCov_af') {
      return updatedArr[0] === 'None' || updatedArr[0] === 'Rejected'
        ? updatedArr[0]
        : updatedArr[0].toLowerCase() === 'not selected'
        ? updatedArr[0]
        : `${updatedArr[0]}${stateCode && arg === 'PAUmpdCov_af' ? ' deductible' : '/accident'}`;
    }
    return updatedArr.length > 0 ? updatedArr.join('/') : updatedArr[0];
  }

  private isMaPARentalCovNotSelected(cov: string, value: string, stateCode: string): boolean {
    return (
      stateCode &&
      stateCode.toLocaleLowerCase() === 'ma' &&
      cov === 'PARentalCov' &&
      value.toLowerCase() === 'not selected'
    );
  }
}

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }
}

// takes capital letters words and split them to an array
//  and transforms it to pascal case

@Pipe({ name: 'capitalToPascal' })
export class CapitalToPascal implements PipeTransform {
  transform(value: any) {
    if (value) {
      return value
        .split(' ')
        .map(i => i.toLowerCase().charAt(0).toUpperCase() + i.toLowerCase().slice(1))
        .join(' ');
    }
    return value;
  }
}

// Returns the last [returnChars] of a string
//
// @param {String} value [] - The value to add symbols to
// @param {Number} returnChars [] - The number of characters to add
// @example XXX-XXX-9999 | returnLast:4
// @return 9999
// TODO - enforce that value is a string with value.toString()

@Pipe({ name: 'returnLast' })
export class ReturnLast implements PipeTransform {
  transform(value: any, returnChars: any): any {
    if (value.length > returnChars) {
      return value.slice(-returnChars);
    }
    return value;
  }
}

// Returns the first [returnChars] of a string
//
// @param {String} value [] - The value to add symbols to
// @param {Number} returnChars [] - The number of characters to add
// @example 999-XXX-XXXX | returnFirst:3
// @return 999
// TODO - enforce that value is a string with value.toString()

@Pipe({ name: 'returnFirst' })
export class ReturnFirst implements PipeTransform {
  transform(value: any, returnChars: any): any {
    if (value.length > returnChars) {
      return value.slice(0, returnChars);
    }
    return value;
  }
}

// Take value and append or prepend symbols
// Add Payment Account Requirement BR-06 specifies that we're to use asterisks
//
// @param {String} value [] - The value to add symbols to
// @param {Number} chars [3] - The number of characters to add
// @param {String} location [before] - Location of the symbols. Options are before [default] and after
// @param {String} symbol [\u002a] - Symbol to use. Default uses the unicode value for asterisk
// @example 9999 | redact:4:'after':'X'
// @return 9999 X X X X

@Pipe({ name: 'redact' })
export class Redact implements PipeTransform {
  transform(value: any, input: any, location?: any, symbol?: any): any {
    const smallSpace = '\u0020';
    const largeSpace = '\u2006';
    const symbolArray = [];
    let chars = 0;
    switch (symbol) {
      case 'heavy':
        symbol = '\u2731';
        break;
      case 'dot':
        symbol = '\u2022';
        break;

      default:
        symbol = '\u002a';
        break;
    }
    // SK: Determine CC or A/C number length.
    if (_has(input, 'creditCard.cardType')) {
      chars = input.creditCard.cardNumber.replace(/-+/g, '').length - 4;
    } else if (_has(input, 'achWithdrawal')) {
      chars = input.achWithdrawal.accountNumber.length - 3;
    }

    if (chars) {
      for (let i = 0; i < chars - 1; i++) {
        symbolArray.push(largeSpace + symbol);
      }
    }

    if (location === 'after') {
      return value + smallSpace + symbol + symbolArray.join('');
    } else {
      return symbol + symbolArray.join('') + smallSpace + value;
    }
  }
}

@Pipe({ name: 'prettyBillingMethod' })
export class PrettyBillingMethod implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const noUnderScore = value.replace(/_/g, ' ');
      return noUnderScore
        .split(' ')
        .map(i => i[0].toUpperCase() + i.substring(1).toLowerCase())
        .join(' ');
    }
  }
}

@Pipe({ name: 'arrayToList' })
export class ArrayToList implements PipeTransform {
  transform(value: any, args?: any): string {
    if (value) {
      if (value instanceof Array) {
        return value.join(args || ', ');
      }

      return value;
    }

    return '';
  }
}

@Pipe({ name: 'strToFloat' })
export class StrToFloat implements PipeTransform {
  transform(value: any): any {
    return parseFloat(value);
  }
}

@Pipe({ name: 'dateFormat', pure: false })
export class DateFormatPipe implements PipeTransform {
  transform(value: Date | string, outputDateFormat: string): string {
    if (value && isValid(new Date(value))) {
      // Type : valid Date object
      const formatOut = outputDateFormat ? outputDateFormat : 'MM/DD/YYYY'; // Default the formatOut
      // Ignore the timezone offset, otherwise date-fns format functions evaluates that to the previous day.
      // splitting would give us the value without timezone
      const valueToFormat = new Date(value).toISOString().split('T')[0];
      return format(valueToFormat, formatOut);
    }
    // If there is no passed input or invalid date return empty
    return '';
  }
}

/** Inconsistency in date format lead to validate for particular element position.
 * @param value should be in YYYY-MM-DDTHH:mm:ss pattern.
 */
@Pipe({ name: 'time24To12' })
export class Time24to12Pipe implements PipeTransform {
  transform(value: string): string {
    if (value && isValid(new Date(value))) {
      let derivedTime = '';
      const hourInput: number = getHours(value);
      let minInput: string | number = getMinutes(value);

      let hour = hourInput % 12;
      if (hour === 0) {
        hour = 12;
      }
      if (minInput < 10) {
        minInput = '0' + minInput;
      }
      derivedTime =
        (hour < 10 ? '0' : '') + hour + ':' + minInput + ' ' + (hourInput < 12 ? 'AM' : 'PM');
      return derivedTime;
    }
    // If there is no passed input or invalid date return empty
    return '';
  }
}

@Pipe({ name: 'dateOutFormat', pure: false })
export class DateFormatOutPipe implements PipeTransform {
  transform(value: Date, ...args: any[]): string {
    const formatOut = args && args.length === 1 ? args[0] : 'MM/DD/YYYY';
    return format(value, formatOut);
  }
}

// Transform an object into and array
@Pipe({ name: 'toArray' })
export class ToArrayPipe implements PipeTransform {
  transform(value: any): any {
    return Object.keys(value).map(key => value[key]);
  }
}

@Pipe({ name: 'prettyPhoneNumber' })
export class PrettyPhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: string, nobrackets?: boolean): string {
    if (phoneNumber) {
      const numbersOnly = phoneNumber.replace(/[^0-9]/g, '');
      if (numbersOnly && numbersOnly.length === 10) {
        const areaCode = numbersOnly.substr(0, 3);
        const prefix = numbersOnly.substr(3, 3);
        const lineNumber = numbersOnly.substr(6, 10);
        if (nobrackets) {
          return areaCode + '-' + prefix + '-' + lineNumber;
        }
        return '(' + areaCode + ') ' + prefix + '-' + lineNumber;
      }
    }

    return phoneNumber;
  }
}

@Pipe({ name: 'letterToDigitPhoneNumber' })
export class LetterToDigitPhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (phoneNumber.length > 14) {
      phoneNumber = phoneNumber.slice(0, 14);
    }
    let formattedNumber = '';
    for (let i = 0; i < phoneNumber.length; i++) {
      const character = phoneNumber.toLowerCase().charAt(i);

      if (/[0-9]/.test(character)) {
        formattedNumber += character;
      } else if (/[abc]/.test(character)) {
        formattedNumber += '2';
      } else if (/[def]/.test(character)) {
        formattedNumber += '3';
      } else if (/[ghi]/.test(character)) {
        formattedNumber += '4';
      } else if (/[jkl]/.test(character)) {
        formattedNumber += '5';
      } else if (/[mno]/.test(character)) {
        formattedNumber += '6';
      } else if (/[pqrs]/.test(character)) {
        formattedNumber += '7';
      } else if (/[tuv]/.test(character)) {
        formattedNumber += '8';
      } else if (/[wxyz]/.test(character)) {
        formattedNumber += '9';
      } else {
        formattedNumber += character;
      }
    }

    return formattedNumber;
  }
}

@Pipe({ name: 'prettyLetterAndDigitPhoneNumber' })
export class PrettyLetterAndDigitPhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (phoneNumber.length > 14) {
      phoneNumber = phoneNumber.slice(0, 14);
    }
    let formattedNumber = phoneNumber;
    const alphaLength = phoneNumber.match(/[a-zA-Z]+/g);

    if (alphaLength && alphaLength[0]) {
      // Decide if we need to present last 4 digits or last 7 (4OSI -> 4674, MYAMFAM -> 692-6326)
      const startIndex =
        alphaLength[0].length <= 4 ? phoneNumber.length - 4 : phoneNumber.length - 7;
      formattedNumber += ' (';
      for (let i = startIndex; i < phoneNumber.length; i++) {
        const character = phoneNumber.toLowerCase().charAt(i);

        // Add a '-' for longer alpha phone numbers (1-800-MYAMFAM)
        if (i === startIndex + 3 && startIndex === phoneNumber.length - 7) {
          formattedNumber += '-';
        }

        if (/[abc]/.test(character)) {
          formattedNumber += '2';
        } else if (/[def]/.test(character)) {
          formattedNumber += '3';
        } else if (/[ghi]/.test(character)) {
          formattedNumber += '4';
        } else if (/[jkl]/.test(character)) {
          formattedNumber += '5';
        } else if (/[mno]/.test(character)) {
          formattedNumber += '6';
        } else if (/[pqrs]/.test(character)) {
          formattedNumber += '7';
        } else if (/[tuv]/.test(character)) {
          formattedNumber += '8';
        } else if (/[wxyz]/.test(character)) {
          formattedNumber += '9';
        } else {
          formattedNumber += character;
        }
      }
      formattedNumber += ')';
    }
    return formattedNumber;
  }
}

@Pipe({ name: 'phoneNumberSingleStringFormatter' })
export class PhoneNumberSingleStringFormatterPipe implements PipeTransform {
  transform(phoneNumber: string, extension?: string): string {
    if (!phoneNumber) {
      return '';
    }
    // take a 11 digit phone number, assumes area code and country code is included.
    // if the number doesn't pass the regex, number is returned as-is.
    // +12223334444 -> 1-(222)-333-4444
    const phoneRegex = /^(?:\+?1[-. ]?)?(?:\(?([0-9]{3})\)?[-. ]?)?([0-9]{3})[-. ]?([0-9]{4})$/;
    const numberRegexTest = phoneRegex.test(phoneNumber);
    const numberBreakOut = phoneNumber.match(phoneRegex);
    let formattedNumber = '';

    if (numberRegexTest) {
      for (let i = 1; i < numberBreakOut.length; ++i) {
        if (numberBreakOut[i]) {
          if (numberBreakOut[i].length === 3) {
            numberBreakOut[1] = '(' + numberBreakOut[1] + ') ';
            formattedNumber += numberBreakOut[i];
          } else {
            formattedNumber += '-' + numberBreakOut[i];
          }
        }
      }

      formattedNumber = !extension ? formattedNumber : formattedNumber + ` ext. ${extension}`;
      return formattedNumber;
    } else {
      return formattedNumber;
    }
  }
}

/*
 * Example use
 *		Basic Array of single type: *ngFor="let todo of todoService.todos | orderBy : '-'"
 *		Multidimensional Array Sort on single column: *ngFor="let todo of todoService.todos | orderBy : ['-status']"
 *		Multidimensional Array Sort on multiple columns: *ngFor="let todo of todoService.todos | orderBy : ['status', '-title']"
 */

@Pipe({ name: 'orderBy', pure: false })
export class OrderBy implements PipeTransform {
  value: string[] = [];

  static _orderByComparator(a: any, b: any): number {
    if (a === null || typeof a === 'undefined') {
      a = 0;
    }
    if (b === null || typeof b === 'undefined') {
      b = 0;
    }

    if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
      // Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }

    return 0; // equal each other
  }

  transform(input: any, config = '+'): any {
    // make a copy of the input's reference
    this.value = _cloneDeep(input);
    const value = this.value;

    if (!Array.isArray(value)) {
      return value;
    }

    if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
      const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
      const desc = propertyToCheck.substr(0, 1) === '-';

      // Basic array
      if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
        return !desc ? value.sort() : value.sort().reverse();
      } else {
        const property: string =
          propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-'
            ? propertyToCheck.substr(1)
            : propertyToCheck;

        return value.sort(function (a: any, b: any) {
          return !desc
            ? OrderBy._orderByComparator(a[property], b[property])
            : -OrderBy._orderByComparator(a[property], b[property]);
        });
      }
    } else {
      // Loop over property of the array in order and sort
      return value.sort(function (a: any, b: any) {
        for (let i = 0; i < config.length; i++) {
          const desc = config[i].substr(0, 1) === '-';
          const property =
            config[i].substr(0, 1) === '+' || config[i].substr(0, 1) === '-'
              ? config[i].substr(1)
              : config[i];

          const comparison = !desc
            ? OrderBy._orderByComparator(a[property], b[property])
            : -OrderBy._orderByComparator(a[property], b[property]);

          // Don't return 0 yet in case of needing to sort by next property
          if (comparison !== 0) {
            return comparison;
          }
        }

        return 0; // equal each other
      });
    }
  }
}

/**
 * Takes in an input, delimiter and an index of array to be returned.
 * Use: abc^dsf^efsdf | splitAndGet:"^":0
 * It would return 'abc'
 */
@Pipe({ name: 'splitAndGet' })
export class SplitAndGetPipe implements PipeTransform {
  transform(input: string, separator: string, index: number): string {
    if (typeof input === 'string') {
      return input.split(separator)[index];
    }
  }
}

@Pipe({ name: 'phoneNumberFormatter' })
export class PhoneNumberFormatterPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (phoneNumber) {
      const areaCode = phoneNumber.substr(0, 3);
      const phoneNumberPart1 = phoneNumber.substr(3, 3);
      const phoneNumberPart2 = phoneNumber.substr(6, 10);
      return '(' + areaCode + ') ' + phoneNumberPart1 + '-' + phoneNumberPart2;
    }
    return '';
  }
}

@Pipe({ name: 'adjusterPhoneNumberFormatter' })
export class AdjusterPhoneNumberFormatterPipe implements PipeTransform {
  transform(fullPhoneNumber: string): string {
    let extension: string;
    let phoneNumber: string;
    if (fullPhoneNumber) {
      [phoneNumber, extension] = fullPhoneNumber.split('x');
    }
    if (phoneNumber && phoneNumber.length === 10) {
      // No Country Code
      const areaCode = phoneNumber.substr(0, 3);
      const phoneNumberPart1 = phoneNumber.substr(3, 3);
      const phoneNumberPart2 = phoneNumber.substr(6, 4);
      if (extension) {
        return (
          '(' + areaCode + ') ' + phoneNumberPart1 + '-' + phoneNumberPart2 + ', ext.' + extension
        );
      } else {
        return '(' + areaCode + ') ' + phoneNumberPart1 + '-' + phoneNumberPart2;
      }
    }
    return '';
  }
}

@Pipe({ name: 'phoneNumberTelFormatter' })
export class PhoneNumberTelFormatterPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (phoneNumber) {
      const tel = 'tel:+1';
      let extension: string;
      [phoneNumber, extension] = phoneNumber.split('x');
      if (extension) {
        return tel + phoneNumber + ',' + extension;
      } else {
        return tel + phoneNumber;
      }
    }
    return '';
  }
}

@Pipe({ name: 'prettyBillingAcctNum' })
export class PrettyBillingAcctNum implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }
    if (value !== undefined && value.length === 11) {
      let prettyString = '';
      for (let i = 0; i < value.length; i++) {
        prettyString += value[i];
        if (i === 2 || i === 5 || i === 8) {
          prettyString += '-';
        }
      }
      return prettyString;
    } else if (value !== undefined && value.length === 6) {
      let prettyString = '000-';
      for (let i = 0; i < value.length; i++) {
        prettyString += value[i];
        if (i === 2) {
          prettyString += '-';
        }
      }
      return prettyString;
    }
    return value;
  }
}

@Pipe({ name: 'kydStatusPipe' })
export class KydStatusPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    switch (value) {
      case 'Opted-Out':
      case 'Not-Enrolled':
        return 'Eligible';
      default:
        return value;
    }
  }
}

@Pipe({ name: 'kydOperatorOptionStatusDisplayPipe' })
export class KydOperatorOptionStatusDisplayPipe implements PipeTransform {
  transform(value: string): string[] {
    if (!value) return [''];
    switch (value) {
      case 'Opted-Out':
      case 'Not-Enrolled':
        return ['Not Enrolled', 'Enroll Now'];
      case 'Enrolled':
        return ['Enrolled', 'End Enrollment'];
      default:
        return [value];
    }
  }
}

@Pipe({ name: 'kydOperatorOptionStatusValuePipe' })
export class KydOperatorOptionStatusValuePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    switch (value) {
      case 'End Enrollment':
      case 'Not Enrolled':
      case 'Opted-Out':
        return 'Not-Enrolled';
      case 'Enroll Now':
      case 'Enrolled':
        return 'Enrolled';
      default:
        return value;
    }
  }
}

@Pipe({ name: 'prettyPaymentMethod' })
export class PrettyPaymentMethod implements PipeTransform {
  transform(paymentMethod: PaymentMethod): string {
    // Credit Card Help Text
    if (_has(paymentMethod, 'creditCard')) {
      const cardType: string = _get(paymentMethod, 'creditCard.cardType', '');
      const cardNumber: string = _get(paymentMethod, 'creditCard.cardNumber', '');
      const cardText: string = cardType + ' ****' + cardNumber.substring(cardNumber.length - 4);
      return cardText;
    }

    // Bank Account Help Text
    else if (_has(paymentMethod, 'achWithdrawal')) {
      const accountType: string = _get(paymentMethod, 'achWithdrawal.accountType', '');
      const accountNumber: string = _get(paymentMethod, 'achWithdrawal.accountNumber', '');
      const accountText: string =
        accountType + ' ****' + accountNumber.substring(accountNumber.length - 3);
      return accountText;
    }
    return '';
  }
}

@Pipe({ name: 'prettyClaimNum' })
export class PrettyClaimNum implements PipeTransform {
  transform(value: string | number): string {
    if (!value || (typeof value !== 'number' && typeof value !== 'string')) {
      return '';
    }
    const claimNumber = (value + '').trim();
    if (claimNumber.length === 11) {
      return claimNumber.replace(/^(\d{2})(\d{3})(\d{6})$/g, '$1-$2-$3');
    } else {
      return claimNumber;
    }
  }
}

@Pipe({ name: 'stateAbbreviation' })
export class StateAbbreviationPipe implements PipeTransform {
  states = [
    { state: 'Alabama', abbr: 'AL' },
    { state: 'Alaska', abbr: 'AK' },
    { state: 'Arizona', abbr: 'AZ' },
    { state: 'Arkansas', abbr: 'AR' },
    { state: 'California', abbr: 'CA' },
    { state: 'Colorado', abbr: 'CO' },
    { state: 'Connecticut', abbr: 'CT' },
    { state: 'Delaware', abbr: 'DE' },
    { state: 'Florida', abbr: 'FL' },
    { state: 'Georgia', abbr: 'GA' },
    { state: 'Hawaii', abbr: 'HI' },
    { state: 'Idaho', abbr: 'ID' },
    { state: 'Illinois', abbr: 'IL' },
    { state: 'Indiana', abbr: 'IN' },
    { state: 'Iowa', abbr: 'IA' },
    { state: 'Kansas', abbr: 'KS' },
    { state: 'Kentucky', abbr: 'KY' },
    { state: 'Louisiana', abbr: 'LA' },
    { state: 'Maine', abbr: 'ME' },
    { state: 'Maryland', abbr: 'MD' },
    { state: 'Massachusetts', abbr: 'MA' },
    { state: 'Michigan', abbr: 'MI' },
    { state: 'Minnesota', abbr: 'MN' },
    { state: 'Mississippi', abbr: 'MS' },
    { state: 'Missouri', abbr: 'MO' },
    { state: 'Montana', abbr: 'MT' },
    { state: 'Nebraska', abbr: 'NE' },
    { state: 'Nevada', abbr: 'NV' },
    { state: 'New Hampshire', abbr: 'NH' },
    { state: 'New Jersey', abbr: 'NJ' },
    { state: 'New Mexico', abbr: 'NM' },
    { state: 'New York', abbr: 'NY' },
    { state: 'North Carolina', abbr: 'NC' },
    { state: 'North Dakota', abbr: 'ND' },
    { state: 'Ohio', abbr: 'OH' },
    { state: 'Oklahoma', abbr: 'OK' },
    { state: 'Oregon', abbr: 'OR' },
    { state: 'Pennsylvania', abbr: 'PA' },
    { state: 'Rhode Island', abbr: 'RI' },
    { state: 'South Carolina', abbr: 'SC' },
    { state: 'South Dakota', abbr: 'SD' },
    { state: 'Tennessee', abbr: 'TN' },
    { state: 'Texas', abbr: 'TX' },
    { state: 'Utah', abbr: 'UT' },
    { state: 'Vermont', abbr: 'VT' },
    { state: 'Virginia', abbr: 'VA' },
    { state: 'Washington', abbr: 'WA' },
    { state: 'West Virginia', abbr: 'WV' },
    { state: 'Wisconsin', abbr: 'WI' },
    { state: 'Wyoming', abbr: 'WY' }
  ];

  transform(state: string): string {
    const fullState = this.states.find(st => st.abbr === state);
    return fullState.state;
  }
}

@Pipe({ name: 'decimalDigits' })
export class DecimalDigits implements PipeTransform {
  transform(value: any, ...args): any {
    try {
      value = parseFloat(value);
    } catch (e) {
      value = '';
    }
    if (!value) {
      return '';
    }
    return Number(value.toFixed(2).split('.')[1]);
  }
}

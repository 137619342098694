import { ApiStatus } from '@amfam/shared/models';
export interface CustomerEnrollmentRequest {
  autoPayOptInIndicator: boolean;
  eDeliveryOptInIndicator: boolean;
  emailAddressSelected: string;
  firstName: string;
  lastName: string;
  onlineBillingOptInIndicator: boolean;
  producerId: string;
  handlerUserId: string;
  handlerType: string;
  expId: string;
  partnerId: string;
}
export interface CustomerEnrollment {
  status: ApiStatus;
  eDeliveryOptInIndicator: boolean;
  onlineBillingOptInIndicator: boolean;
  autoPayOptInIndicator: boolean;
  ubiOptInIndicator: boolean;
  loyaltyOptInIndicator: boolean;
  userProfile: UserProfile;
}

export interface CustomerEnrollentResponse {
  CustomerEnrollment: CustomerEnrollment;
}

export interface UserProfile {
  accountStatus: string;
  cdhPartyId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  userType: string;
  userTypeId: string;
  waid: string;
}

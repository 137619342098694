import { GenericProductType } from './generic-product-type';
import { SourceSystemType } from './source-system-type';
import { PolicyTypeDisplayNameConstants } from './policy-type-display-name-constants';

export abstract class PolicyBase {
  public policyNumber: string;
  public productType: string;
  public productDescription: string;
  public generalizedProductType: GenericProductType;
  public policyTypeDisplayName: string;
  public sourceSystem: SourceSystemType;
  public sourcePath: string;

  private static getPolicyTypeDisplayName(productType: string): string {
    if (productType) {
      return PolicyTypeDisplayNameConstants[this.productSwitch(productType)];
    }
  }

  public static getGenericProductType(productType: string): GenericProductType {
    if (productType) {
      switch (this.productSwitch(productType)) {
        case 'AUTO':
          return GenericProductType.Auto;
        case 'HOME':
          return GenericProductType.Home;
        case 'UMBRELLA':
          return GenericProductType.Umbrella;
        case 'LIFE':
          return GenericProductType.Life;
        case 'MIXED':
          return GenericProductType.Mixed;
        default:
          return GenericProductType.Other;
      }
    }
  }

  private static productSwitch(productType: string) {
    if (productType) {
      switch (productType.toUpperCase()) {
        case 'PLAUTO':
        case 'PERSONALAUTO':
          return 'AUTO';
        case 'PLPROPERTY':
        case 'HOMEOWNERS':
        case 'DWELLINGFIRE':
        case 'PERSONALPROPERTY':
          return 'HOME';
        case 'COMMERCIALUMBRELLA':
        case 'PLUMBRELLA':
        case 'PERSONALUMBRELLA':
          return 'UMBRELLA';
        case 'T1':
        case 'U1':
          return 'LIFE';
        case 'MIXED':
          return 'MIXED';
        default:
          return 'OTHER';
      }
    }
  }

  protected assignProperties(json: any) {
    this.policyNumber = json.policyNumber;
    this.productType = json.productType;
    this.generalizedProductType = PolicyBase.getGenericProductType(json.productType);
    this.policyTypeDisplayName = PolicyBase.getPolicyTypeDisplayName(json.productType);
    this.sourceSystem = this.getSourceSystemType(json.sourceSystem);
    this.sourcePath = json.sourceSystem;
  }

  private getSourceSystemType(sourceSystem: string): SourceSystemType {
    if (sourceSystem === 'PolicyCenter') {
      return SourceSystemType.Advance;
    }

    return SourceSystemType.Legacy;
  }
}

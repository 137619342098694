import { Component, OnInit, Input } from '@angular/core';
import { DsClicktipModel } from '../../clicktip.model';

@Component({
  selector: 'ds-clicktip',
  templateUrl: './ds-clicktip.component.html',
  styleUrls: ['./ds-clicktip.component.scss']
})
export class DsClicktipComponent implements OnInit {
  @Input() content?: DsClicktipModel;
  @Input() title: string;
  @Input() text: string;
  @Input() label: string;
  public display = false;
  constructor() {}

  ngOnInit() {}

  public toggleClicktip() {
    if (this.display === true) {
      this.display = false;
    } else {
      this.display = true;
    }
  }

  public contentVisible() {
    return this.display;
  }
}

import * as replaceVehicleActions from './replace-vehicle.actions';

import { ReplaceVehicle, initialReplaceVehicle } from '@amfam/policy/models';

// This reduces a set of bill accounts
export function reducer(
  state = initialReplaceVehicle,
  action: replaceVehicleActions.Actions
): ReplaceVehicle {
  switch (action.type) {
    case replaceVehicleActions.REPLACE_VEHICLE_INIT:
      return Object.assign({}, initialReplaceVehicle);

    case replaceVehicleActions.REPLACE_VEHICLE_GET_PREMIUM:
      return Object.assign({}, initialReplaceVehicle, {
        loading: true,
        hasError: false,
        policyNumber: action.payload.policyNumber,
        effectiveDate: action.payload.effectiveDate,
        replaceVin: action.payload.replaceVIN,
        newVehicle: action.payload.newVehicle
      });

    case replaceVehicleActions.REPLACE_VEHICLE_GET_PREMIUM_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        premium: action.payload.premium,
        status: action.payload.status
      });

    case replaceVehicleActions.REPLACE_VEHICLE_GET_PREMIUM_FAIL:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    case replaceVehicleActions.REPLACE_VEHICLE_SUBMIT_ONLINE_BIND:
      return Object.assign({}, state, {
        loading: true,
        hasError: false
      });

    case replaceVehicleActions.REPLACE_VEHICLE_SUBMIT_ONLINE_BIND_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: action.payload,
        confirmationType: 'Online Bind Success'
      });

    case replaceVehicleActions.REPLACE_VEHICLE_SUBMIT_ONLINE_BIND_FAIL:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload
      });

    case replaceVehicleActions.REPLACE_VEHICLE_NOTIFY_AGENT:
      return Object.assign({}, state, {
        notifyAgentStatus: {
          notifyingAgent: true,
          hasError: false,
          status: null
        },
        producerId: action.payload.producer,
        vehicleId: action.payload.vehicleId,
        orderNumber: action.payload.orderNumber,
        email: action.payload.email
      });

    case replaceVehicleActions.REPLACE_VEHICLE_NOTIFY_AGENT_SUCCESS:
      return Object.assign({}, state, {
        notifyAgentStatus: {
          notifyingAgent: false,
          hasError: false
        },
        confirmationType:
          action.payload.type === 'No Quote' ? 'No Quote Send to Agent' : 'Send Quote to Agent'
      });

    case replaceVehicleActions.REPLACE_VEHICLE_NOTIFY_AGENT_FAIL:
      return Object.assign({}, state, {
        notifyAgentStatus: {
          notifyingAgent: false,
          hasError: true,
          status: action.payload.status
        }
      });

    case replaceVehicleActions.REPLACE_VEHICLE_ANS_NOTIFICATION:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        status: null
      });

    case replaceVehicleActions.REPLACE_VEHICLE_ANS_NOTIFICATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        hasError: false
      });

    case replaceVehicleActions.REPLACE_VEHICLE_ANS_NOTIFICATION_FAIL:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    case replaceVehicleActions.REPLACE_VEHICLE_RISK_SELECTION_CONTEXT:
      return Object.assign({}, state, {
        riskSelectionContext: action.payload
      });

    default:
      return state;
  }
}

export const getStatus = (state: ReplaceVehicle) => state.status;
export const hasError = (state: ReplaceVehicle) => state.hasError;
export const getLoading = (state: ReplaceVehicle) => state.loading;
export const getNotifyAgentStatus = (state: ReplaceVehicle) => state.notifyAgentStatus;
export const getProducerId = (state: ReplaceVehicle) => state.producerId;
export const getPolicyNumber = (state: ReplaceVehicle) => state.policyNumber;
export const getEffectiveDate = (state: ReplaceVehicle) => state.effectiveDate;
export const getReplaceVin = (state: ReplaceVehicle) => state.replaceVin;
export const getNewVehicle = (state: ReplaceVehicle) => state.newVehicle;
export const getPaymentRequestId = (state: ReplaceVehicle) => state.paymentRequestId;
export const getPremium = (state: ReplaceVehicle) => state.premium;
export const getConfirmationType = (state: ReplaceVehicle) => state.confirmationType;
export const getVehicleRiskSelectionContext = (state: ReplaceVehicle) => state.riskSelectionContext;

import { MessageDetail } from './messagedetail.model';

export class TemplatedEmail {
  constructor(
    public templateCode: string,
    public partnerId: string,
    public brandId: string,
    public language: string,
    public customerIdentifier: string,
    public recipientAddress: string,
    public messageDetails: MessageDetail[] = new Array<MessageDetail>(),
    public accountId?: string
  ) {}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ds-warning-box',
  templateUrl: './ds-warning-box.component.html',
  styleUrls: ['./ds-warning-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsWarningBoxComponent {
  @Input() headingText: string;
  @Input() messageText: string;
  @Input() linkText: string;
  @Output() linkEvent = new EventEmitter<any>();
  @Input() postLinkText: string;
  constructor() {}

  ngOnInit(): void {}
}

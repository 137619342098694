import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BillAccountDeliveryPreferenceNotification } from '../models/communication-preferences.models';
import * as CommunicationPreferencesActions from './communication-preferences.actions';

export const communicationPreferencesFeatureKey = 'communicationPreferences';

export type State = EntityState<BillAccountDeliveryPreferenceNotification>;
export const adapter: EntityAdapter<BillAccountDeliveryPreferenceNotification> =
  createEntityAdapter<BillAccountDeliveryPreferenceNotification>({
    selectId: (deliveryPreference: BillAccountDeliveryPreferenceNotification) =>
      deliveryPreference.billAccountNumber,
    sortComparer: false
  });
export const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,

  on(CommunicationPreferencesActions.setBillAccountDeliveryPreferenceSuccess, (state, action) =>
    adapter.upsertOne(action, state)
  )
);

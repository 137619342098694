import { KydSmartphonePolicy } from '../../kyd-smartphone';
import { KydVehicleEligibility } from './kyd-vehicle-eligibility.model';

export class KydPolicyEligibility {
  policyNumber: string;
  isCustomerEligible: boolean;
  isPolicyEligible: boolean;
  isStateEligible: boolean;
  programCategory: string;
  isPhhEligible: boolean;
  vehicleEligibilities = new Array<KydVehicleEligibility>();
  kydSmartPhoneData?: KydSmartphonePolicy[];
  allOperatorsEnrolled?: boolean;
  edit?: boolean;
  enrollStatus?: string;
  reasonCode?: string;
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { userQuery } from '@amfam/shared/user';
import { LogoModel } from '@amfam/shared/models';

@Component({
  selector: 'ds-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public showBanner: Observable<boolean>;
  chatEnabled$: Observable<boolean>;
  isPartner$: Observable<boolean>;
  partnerFooterLogo$: Observable<LogoModel>;
  lastLogin$: Observable<string>;

  constructor(private store: Store<fromRoot.RootState>) {}

  ngOnInit() {
    this.showBanner = this.store.select(fromRoot.getShowBanner);
    this.chatEnabled$ = this.store.select(fromRoot.chatEnabled);
    this.partnerFooterLogo$ = this.store.select(BrandSelectors.getFooterLogo);
    this.lastLogin$ = this.store.select(userQuery.getLastLoggedInOn);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Thirdy party
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// shared/ui
import { AcceptPasswordInputComponent } from './accept-password-input/accept-password-input.component';
import { DisableCopyPasteDirective } from './disable-paste/disable-paste.directive';

import { FocusDirective } from './focus/focus.directive';
import { ModalComponent } from './modal';

import { ShowHideInputComponent } from './show-hide-input/show-hide-input.component';
import { ShowHideInputMyAccountDirective } from './show-hide-input/show-hide-input.directive';
import { StepProgressBarComponent } from './step-progress-bar';

import { TabComponent } from './tabs/tab.component';
import { TabsComponent } from './tabs/tabs.component';

import { MasksModule } from '@amfam/shared/ui/masks';
import { PipesModule } from '@amfam/shared/ui/pipes';
import { UiKitModule } from '@amfam/ui-kit';

import { PrettyBillingAcctNum } from './format-pipes/format.pipe';
import { SortColFormatPipe } from './format-pipes/sort-col-format.pipe';

import { ClaimSharedUtilityModule } from '@amfam/claim/shared/utility';
import { SharedUiBreadcrumbModule } from '@amfam/shared/ui/breadcrumb';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    UiKitModule,
    MasksModule,
    PipesModule,
    ClaimSharedUtilityModule,
    SharedUiBreadcrumbModule
  ],
  declarations: [
    AcceptPasswordInputComponent,
    DisableCopyPasteDirective,
    FocusDirective,
    ModalComponent,
    StepProgressBarComponent,
    ShowHideInputMyAccountDirective,
    ShowHideInputComponent,
    TabComponent,
    TabsComponent,
    PrettyBillingAcctNum,
    SortColFormatPipe
  ],
  exports: [
    AcceptPasswordInputComponent,
    DisableCopyPasteDirective,
    FocusDirective,
    ModalComponent,
    NgxDatatableModule,
    StepProgressBarComponent,
    ShowHideInputMyAccountDirective,
    ShowHideInputComponent,
    TabComponent,
    TabsComponent,
    PrettyBillingAcctNum,
    UiKitModule,
    MasksModule,
    PipesModule,
    SharedUiBreadcrumbModule
  ]
})
export class UiModule {}

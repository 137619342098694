import { Phone } from '@amfam/shared/models';

export interface Agent {
  id: string;
  loading?: boolean;
  loaded?: boolean;
  agentId?: string;
  globalNickname?: string;
  fullName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nickName?: string;
  offices?: any[];
  websiteUrl?: string;
  emailFormUrl?: string;
  photoUrl?: string;
  isAmericanStarCertified?: boolean;
  emails?: string[];
  agencyEmails?: string[];
  phones?: Phone[];
}

export const initialAgent: Agent = {
  id: null
};

import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import {
  ClaimGlassOnlyActionTypes,
  LoadGlassOnlyClaims,
  fromClaimGlassOnlyActions,
  LoadGlassOnlyClaimsSuccess
} from './claim-glass-only.actions';
import { ClaimGlassOnlyService } from '../services/claim-glass-only.service';
import { Store } from '@ngrx/store';
import { BrandSelectors } from '@amfam/shared/utility/brand';

@Injectable()
export class ClaimGlassOnlyEffects {

  loadGlassOnly$ = createEffect(() => this.action$.pipe(
    ofType(ClaimGlassOnlyActionTypes.LoadGlassOnlyClaims),
    map((action: LoadGlassOnlyClaims) => action.payload),
    withLatestFrom(this.store.select(BrandSelectors.getPartnerId)),
    switchMap(([payload, partnerId]) =>
       this.claimGlassOnlyService.createGlassOnlyClaim(payload, partnerId || payload.defaultPartnerId).pipe(
        map((res: any) => new fromClaimGlassOnlyActions.LoadGlassOnlyClaimsSuccess(res)),
        catchError(error => of(new fromClaimGlassOnlyActions.LoadGlassOnlyClaimsFail(error)))
      )
    )
  ));

  // Route to safelite.
   routeToSafelite$ = createEffect(() => this.action$.pipe(
    ofType(ClaimGlassOnlyActionTypes.LoadGlassOnlyClaimsSuccess),
    map((action: LoadGlassOnlyClaimsSuccess) => action.payload),
    map(payload => this.claimGlassOnlyService.routeToSafelite(payload))
  ), { dispatch: false });

  constructor(
    private action$: Actions,
    private claimGlassOnlyService: ClaimGlassOnlyService,
    private store: Store
  ) {}
}

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { isEqual as _isEqual, includes as _includes } from 'lodash';

@Component({
  selector: 'ds-bank-account-check-image',
  templateUrl: './bank-account-check-image.component.html',
  styleUrls: ['./bank-account-check-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankAccountCheckImageComponent {
  @Input() isBusiness: boolean;

  constructor() {}
}

import { Component, OnInit, Input } from '@angular/core';
import { has as _has } from 'lodash';
import { format, parse, isValid } from 'date-fns';

@Component({
  selector: 'ds-date-circle',
  templateUrl: './ds-date-circle.component.html',
  styleUrls: ['./ds-date-circle.component.scss']
})
export class DsDateCircleComponent implements OnInit {
  @Input() dateString?: string;
  private dateParsed: Date;
  public dateMonth: string;
  public dateDay: string;

  constructor() {}

  ngOnInit() {
    this.dateMonth = format(new Date(), 'MMM');
    this.dateDay = format(new Date(), 'D');
    if (this.dateString && this.dateString.length && this.dateString.length > 0) {
      this.dateParsed = parse(this.dateString);
      if (isValid(this.dateParsed)) {
        this.dateMonth = format(this.dateParsed, 'MMM');
        this.dateDay = format(this.dateParsed, 'D');
      } else {
        console.error('dateString is invalid - using current date');
      }
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromESignature from './+state/e-signature.reducer';
import { ESignatureEffects } from './+state/e-signature.effects';
import { esignatureReducers } from './+state/e-signature.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromESignature.ESIGNATURE_FEATURE_KEY, esignatureReducers),
    EffectsModule.forFeature([ESignatureEffects])
  ]
})
export class PolicyESignatureDataAccessModule {}

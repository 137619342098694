import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// @dynamic
@Injectable({
  providedIn: 'root'
})
/*
 * Validations specific to the security
 * questions and answers.
 */
export class SecurityQuestionsValidationService {
  private static questionOneTokens: any[];
  private static answerOneTokens: any[];
  private static questionTwoTokens: any[];
  private static answerTwoTokens: any[];
  /*
   * Due to the nature of the form builder in
   * the security question component we need to
   * handle all 4 controls in the group.  This will
   * check the security questions and answers dynamically
   * and set the error to the control as needed.
   */
  static questionsDoNotContainAnswerInOrder(q1: string, q2: string, a1: string, a2: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      // Lets check question 1 and answer 1 and only do stuff if they are populated
      if (
        group.controls[q1].value &&
        group.controls[a1].value &&
        !group.controls[a1].getError('answerInQuestion')
      ) {
        // Makes pretty arrays to check tokens
        this.questionOneTokens = this.tokenizeValue(group.controls[q1].value);
        this.answerOneTokens = this.tokenizeValue(group.controls[a1].value);
        if (
          this.tokenListsAreEqual(this.questionOneTokens, this.answerOneTokens) &&
          this.tokenLocationsAreEqual(this.questionOneTokens, this.answerOneTokens)
        ) {
          // Naughty answer in question is naughty, throw validation
          group.controls[a1].setErrors({ answerInQuestion: true });
          return null;
        }
      }
      // Lets check question 2 and answer 2 and only do stuff if they are populated
      if (
        group.controls[q2].value &&
        group.controls[a2].value &&
        !group.controls[a2].getError('answerInQuestion')
      ) {
        // Makes pretty arrays to check tokens
        this.questionTwoTokens = this.tokenizeValue(group.controls[q2].value);
        this.answerTwoTokens = this.tokenizeValue(group.controls[a2].value);
        if (
          this.tokenListsAreEqual(this.questionTwoTokens, this.answerTwoTokens) &&
          this.tokenLocationsAreEqual(this.questionTwoTokens, this.answerTwoTokens)
        ) {
          // Naughty answer in question is naughty, throw validation
          group.controls[a2].setErrors({ answerInQuestion: true });
          return null; // Nothing needs to go to the error map
        }
      }
    };
  }

  /*
   * Helper to tokenize an input value
   */
  private static tokenizeValue(s: any): any[] {
    if (s !== undefined) {
      return s.toLowerCase().split(' ');
    }
    return [];
  }

  /*
   * Checks the location in the string of
   * each token.  We allow the word in a different
   * position that of the question.
   */
  private static tokenLocationsAreEqual(tl1: any[], tl2: any[]): boolean {
    if (tl1.length > 0 && tl2.length > 0) {
      for (let i = 0; i < tl2.length; i++) {
        if (tl2[i] === tl1[i]) {
          return true;
        }
      }
    }
    return false;
  }

  /*
   * Checks the equality of two lists of
   * tokens
   */
  private static tokenListsAreEqual(tl1: any[], tl2: any[]): boolean {
    if (tl1.length > 0 && tl2.length > 0) {
      for (const token of tl2) {
        // Checks if the token is in the answer
        if (tl1.includes(token)) {
          return true;
        }
      }
    }
    return false;
  }

  static securityAnswers_areEqual(answer1: string, answer2: string, defaultVal = '') {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const ans1 = (group.controls[answer1].value || '').trim();
      const ans2 = (group.controls[answer2].value || '').trim();
      // if we have values AND they are the same value AND NOT the default value
      if (ans1 && ans2 && ans1.toUpperCase() === ans2.toUpperCase() && ans1 !== defaultVal) {
        return { answersMatch: true };
      }
    };
  }

  // if the answers haven't changed, don't allow the form to submit
  static securityAnswersUnchanged(answer1: string, answer2: string, defaultVal: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const ans1 = group.controls[answer1].value;
      const ans2 = group.controls[answer2].value;
      return ans1 === ans2 && ans1 === defaultVal ? { unchanged: true } : null;
    };
  }
}

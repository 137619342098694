<div class="box">
  <span class="flex">
    <div *ngIf="showIcon" class="pad-right-10">
      <ds-credit-card-icon
        *ngIf="creditCardIcon"
        [ccType]="icon"
        [ccIconHeight]="24"
      ></ds-credit-card-icon>
      <span *ngIf="!creditCardIcon" class="icon-bank bank-icon white" role="img"></span>
    </div>
    <div class="flex card-right">
      <div>
        <span *ngIf="!showIcon">Payment method:</span>
        <span
          class="overflow-hidden overflow-ellipsis payment-method-name"
          *ngIf="title"
          title="{{ title }}"
        >
          {{ title }}
        </span>
      </div>
      <div class="whitespace-nowrap account-type">
        <span>{{ accountType }}</span>
        <span *ngIf="paymentAccount?.creditCard">{{
          accountNumber | returnLast: 4 | redact: paymentAccount
        }}</span>
        <span *ngIf="paymentAccount?.achWithdrawal">{{
          accountNumber | returnLast: 3 | redact: paymentAccount
        }}</span>
      </div>
    </div>
  </span>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkActiveDirective } from './router-link-active.directive';
import { RouterLinkDirective, RouterLinkWithHrefDirective } from './router-link.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [RouterLinkActiveDirective, RouterLinkDirective, RouterLinkWithHrefDirective],
  exports: [RouterLinkActiveDirective, RouterLinkDirective, RouterLinkWithHrefDirective]
})
export class DsRouterLinkModule {}

<p class="subheading">{{ subHeading }}</p>
<p class="pad-top-10">
  Current due date: <span>{{ paymentDueDate | dateFormat: 'MM/DD/YYYY' }}</span>
</p>
<div class="pad-top-20" [formGroup]="formGroup">
  <label for="paymentDate" class="subheading">Payment date</label>
  <p>
    <ds-form-control-container [control]="formGroup.controls['paymentDate']">
      <select
        id="paymentDate"
        class="icon fill"
        formControlName="paymentDate"
        data-cy="autoPayDateSelector"
      >
        <option *ngFor="let option of paymentDateOptions" [ngValue]="option">
          {{ option.text }}
        </option>
      </select>
      <span class="icon-chevron-down"></span>
    </ds-form-control-container>
  </p>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BillAccountCanRegister } from '../models/bill-account-can-register.models';

export const BILL_ACCOUNT_CAN_REGISTER_FEATURE_KEY = 'billAccountCanRegister';

export const getBillAccountCanRegisterState = createFeatureSelector<BillAccountCanRegister>(
  BILL_ACCOUNT_CAN_REGISTER_FEATURE_KEY
);

export const getLoading = createSelector(
  getBillAccountCanRegisterState,
  (state: BillAccountCanRegister) => state.loading
);

export const getLoaded = createSelector(
  getBillAccountCanRegisterState,
  (state: BillAccountCanRegister) => state.loaded
);

export const billAccountCanRegisterQuery = {
  getBillAccountCanRegisterState,
  getLoading,
  getLoaded
};

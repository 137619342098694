import { ButtonAnalytic, EventAnalytic, PageAnalytic } from '../analytics.model';

export class FirstPartyFnolAnalytics {
  public static readonly MagicThreeGuest: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:YourInformation',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly MagicPlusOneGuest: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:VerifyIdentity',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly SSNGuest: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:VerifyIdentity:SSN',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly PolicyGuest: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:VerifyIdentity:PolicyNumber',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly GuestRiskSelection: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:RiskSelection',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly pageAutoClaimIncidentInfo: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Auto:ClaimStart',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pagePropertyClaimIncidentInfo: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Property:ClaimStart',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Property',
    subCategory3: ''
  };

  public static readonly eventReportClaimStart: EventAnalytic = {
    eventName: 'Guest Report A Claim',
    eventStep: 'start'
  };
  public static readonly GuestRiskLocationInfo: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Auto:Location',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: '',
    claimNumber: ''
  };
  public static readonly pageAutoReportClaimDuplicate: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Auto:DuplicateClaim',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };
  public static readonly pagePropertyReportClaimDuplicate: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Property:DuplicateClaim',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Property',
    subCategory3: ''
  };

  // TODO
  public static readonly clickGuestDuplicateResumeDraftClaim: ButtonAnalytic = {
    link: 'Claims:ReportClaim:Guest:Auto:DuplicateClaim:ResumeClaim'
  };
  public static readonly clickGuestDuplicateReportDraftClaim: ButtonAnalytic = {
    link: 'Claims:ReportClaim:Guest:Auto:DuplicateClaim:ReportNewClaim'
  };

  public static readonly pageReportClaimDriverSelection: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Auto:VehicleDriver',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Claims',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pagePropertyReportClaimSummary: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Property:Summary',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };

  public static readonly GuestAutoClaimSubmitted: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Auto:ClaimSubmitted',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: '',
    claimNumber: ''
  };
  public static readonly eventGuestAutoClaimSubmitted: EventAnalytic = {
    eventName: 'Guest Report A Claim',
    eventStep: 'complete'
  };

  public static readonly GuestAutoClaimSubmittedTimeout: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Auto:ClaimSubmittedTimeout',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report A Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pagePropertyReportClaimConfirmation: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Property:ClaimSubmitted',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };

  public static readonly pagePropertyReportClaimTimeout: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:Property:ClaimSubmittedTimeout',
    experience: '',
    primaryCategory: 'Guest',
    subCategory1: 'Claims',
    subCategory2: 'Property',
    subCategory3: ''
  };

  public static readonly eventPropertyReportClaimConfirmation: EventAnalytic = {
    eventName: 'Guest Report A Claim',
    eventStep: 'complete'
  };

  public static readonly GuestEnrollMyAccountStart: PageAnalytic = {
    pageName: 'MyAccount:Enrollment:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Enroll',
    subCategory2: 'Guest Claim Flow',
    subCategory3: ''
  };
  public static readonly eventGuestEnrollMyAccountStart: EventAnalytic = {
    eventName: 'enrollment',
    eventStep: 'start'
  };
  public static readonly GuestEnrollmentSecurityQuestions: PageAnalytic = {
    pageName: 'MyAccount:Enrollment:SecurityQuestions',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Enroll',
    subCategory2: 'Guest Claim Flow',
    subCategory3: ''
  };
  public static readonly GuestEnrollmentComplete: PageAnalytic = {
    pageName: 'MyAccount:Enrollment:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Enroll',
    subCategory2: 'Guest Claim Flow',
    subCategory3: ''
  };
  public static readonly eventGuestEnrollementComplete: EventAnalytic = {
    eventName: 'enrollment',
    eventStep: 'complete'
  };
  public static readonly GuestMyAccountFound: PageAnalytic = {
    pageName: 'Claims:ReportClaim:Guest:MyAccountFound',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };
}

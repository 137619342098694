import { ClaimDetailServiceRequestModel } from './claim-detail-service-request';
export class ClaimDetails {
  'detailedLossCauseCode': any; // TODO: Get the correct model
  'atFaultPercentage': string;
  'stackedClaimIndicator': string;
  'underWritingCompanyCode': string;
  'securedClaim': boolean;
  'stormRelatedIndicator': string;
  'totalLossAmountPaid': string;
  'claimStatus': string;
  'catNumber': string;
  'interpreterNeededIndicator': string;
  'legalIndicator': string;
  'legalActionCode': string;
  'locationDescription': string;
  'primaryClaimNumber': string;
  'allegedClaimIndicator': string;
  'negligencePercent': string;
  'initiatedInFnolIndicator': string;
  'salvageIndicator': string;
  'policeReportIndicator': string;
  'typeOfSubrogationStatusCode': string;
  'claimResults': Array<{}>;
  'contacts': Array<{}>;
  'subrogationIndicator': string;
  'producerCode': string;
  'language': {
    description: string;
    otherDescription: string;
  };
  'typeOfClosedReason': string;
  'perils': Array<{}>;
  'claimRoles': Array<{}>;
  'commercialClaimDetail': {
    buildingSequenceNumber: string;
    classCode: string;
    commonSequenceNumber: string;
    coverageIds: string;
    fnolClaimNumber: string;
    lastUpdatedOn: string;
    lineOfBusinessCode: string;
    premisesSquenceNumber: string;
    stateSequenceNumber: string;
  };
  'exposures': Array<{}>;
  'financialDetails': Array<{}>;
  'monetaryTransactions': Array<MonetaryTransaction>;
  'serviceRequests': Array<ClaimDetailServiceRequestModel>;
  'initialPhoneCallCompletedIndicator': boolean;
  'initialPhoneCallCompletedon': string;
  'typeOfEstimationStatusCode': string;
  'estimateRecievedOn': string;
  'lossLocationAddress': {
    addr1: string;
    addr2: string;
    city: string;
    state: string;
    zip5: string;
    zip4: string;
  };
  'reportedBy': {
    reportedOn: string;
    typeOfNotificationCode: string;
    reportedBy: string;
    descriptionForOtherReportedByRole: string;
    typeOfReportedByRoleCode: string;
  };
  'autoClaimDetails': [
    {
      year: string;
      make: string;
      model: string;
      vin: string;
    }
  ];
}

export class MonetaryTransaction {
  typeOfTransactionCode: string;
  amount: number;
  transactedOn: string;
  coverageCode: {
    code: string;
    description: string;
  };
  typeOfTransactionStatusCode: string;
  payee: string;
  typeOfCostCode: string;
  paymentMailingAddress: {
    addr1: string;
    addr2: string;
    city: string;
    state: string;
    zip5: string;
    description: string;
  };
  typeOfPaymentPaidToCode: string;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { get as _get } from 'lodash';
import { ConfirmationModel, initialConfirmationModel } from './ds-confirmation.model';
@Component({
  selector: 'ds-confirmation',
  templateUrl: './ds-confirmation.component.html',
  styleUrls: ['./ds-confirmation.component.scss']
})
export class DsConfirmationComponent implements OnInit {
  @Input() confirmationData: ConfirmationModel;
  @Input() autopayerror: [];
  @Input() showDeletedPayment: boolean;
  @Input() paymentTerm: string;
  @Input() amfamTelNumber?: string;
  @Input() amfamPhone?: string;
  @Input() errorHeader?: string;
  @Input() policyNumber: string;
  @Input() vehicleName: string;
  @Output() retryClickedEvent = new EventEmitter();

  public confirmationHeading: ConfirmationModel['confirmation']['heading'];
  public errorConfirmationHeading: ConfirmationModel['confirmation']['errorHeading'];
  public confirmationSubHeading: ConfirmationModel['confirmation']['subHeading'];
  public confirmationStatus: ConfirmationModel['confirmation']['status'];
  public errorDetailsList: ConfirmationModel['details'];
  public successDetailsList: ConfirmationModel['details'];
  public hasErrorConfirmationTobeShown: boolean;
  public headerInfoText: ConfirmationModel['confirmation']['infoText'];
  public policyNumberText: string;
  public vehicleAddedText: string;
  public alertMessage: string;
  public title: string;
  public isPartner = false;
  constructor() {
    this.confirmationData = initialConfirmationModel;
  }

  ngOnInit() {
    this.confirmationHeading = this.confirmationData.confirmation.heading;
    this.errorConfirmationHeading = this.confirmationData.confirmation.errorHeading;
    this.confirmationSubHeading = this.confirmationData.confirmation.subHeading;
    this.confirmationStatus = this.confirmationData.confirmation.status;
    this.headerInfoText = _get(this.confirmationData, 'confirmation.infoText');
    this.isPartner = this.confirmationData.isPartner;
    this.policyNumberText = this.confirmationData.policyNumberText;
    this.vehicleAddedText = this.confirmationData.vehicleAddedText;
    this.alertMessage = this.confirmationData.alertMessageConfirmation;
    this.title = this.confirmationData.titleConfirmation;

    this.hasErrorConfirmationTobeShown = !!_get(this.confirmationData, 'details', []).find(
      detail => detail.heading === this.errorHeader && _get(detail, 'tableRows.length', 0) > 0
    );

    if (this.hasErrorConfirmationTobeShown) {
      this.errorDetailsList = _get(this.confirmationData, 'details', []).filter(
        detail => detail.heading === this.errorHeader
      );
    }
    this.successDetailsList = _get(this.confirmationData, 'details', []).filter(
      detail => detail.heading !== this.errorHeader
    );
  }
}

export interface KydSmartphonePhhCopy {
  abtToKnowYourDriveText: string;
  initialDiscount: string;
  savings: string;
  safeDriveText: string;
  coveragesList: string;
  coverageBullet1: string;
  coverageBullet2: string;
  coverageBullet3: string;
  coverageBullet4: string;
  coverageListEnd: string;
  eligibilityDisclaimer: string;
  eligibilityDisclaimerWA: string;
  programDiscounts: string;
  kydCoverages: string;
  successMessage: string;
  initiateProgramText: string;
  updatePhone: string;
  duplicatePhoneError: string;
  duplicatePhoneErrorEnrollAll: string;
  sendTextToNewNum: string;
  installAppText: string;
  removeDrenewalText: string;
  allDriversRemovedText: string;
  newPremium: string;
  unenrollAllTPM: string;
  importantText: string;
  unenrollAll: string;
  unenrollAllWA: string;
  unenrollAllConfirm: string;
  unenrollHeader: string;
  unenrollOne: string;
  unenrollOneLastOperator: string;
  unenrollOneLastOperatorWA: string;
  unenrollOneConfirm: string;
  unenrollOneLastOperatorConfirm: string;
}

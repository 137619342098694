import { LogoModel } from '@amfam/shared/models';
import { routerQuery } from '@amfam/shared/utility/navigation';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  @Input() chatEnabled? = false;
  @Input() feedbackEnabled = true;
  @Input() lastLogin? = '';
  @Input() partnerFooterLogo?: LogoModel;
  @Input() showContactInfo = true;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .select(routerQuery.getRouterCurrentStateUrl)
      .pipe()
      .subscribe(url => {
        if (url.includes('one-time-pay')) {
          document.getElementById('lastLogin')?.style.setProperty('display', 'none');
          document.getElementById('footer')?.style.setProperty('padding-top', '0px');
        } else {
          document.getElementById('lastLogin')?.style.setProperty('display', 'block');
          document.getElementById('footer')?.style.setProperty('padding-top', '60px');
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { DockingBarService, PageTitleService } from '@amfam/ui-kit';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  amfamChatUrl: string;
  amfamDotCom: string;
  amfamWebsiteIssue: string;
  amfamSitemap: string;

  constructor(
    private config: ConfigService,
    private dockingBarService: DockingBarService,
    private pageTitleService: PageTitleService
  ) {}

  ngOnInit() {
    this.amfamChatUrl = this.config.get('links.amfamChatUrl');
    this.amfamDotCom = this.config.get('links.amfamDotComUrl');
    this.amfamWebsiteIssue = this.amfamDotCom + 'customer/forms/website-issue';
    this.amfamSitemap = this.amfamDotCom + 'sitemap';
    this.dockingBarService.registerHeading('Page Not Found');
    this.pageTitleService.set('Page Not Found');
  }
}

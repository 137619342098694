import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { mockResponse } from '../testing/claim-dashboard.mock';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { map } from 'rxjs/operators';
import { get as _get } from 'lodash';
import { DashboardClaim } from '../+state/claim-dashboard.models';

@Injectable({
  providedIn: 'root'
})
export class ClaimDashboardService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getDashboardClaims(cdhId: string): Observable<DashboardClaim[]> {
    const endpoint = this.config.get('claimApi') + 'claimdash/' + cdhId;
    return this.http.get(endpoint).pipe(
      map(response => {
        return _get(response, 'dashboardClaims');
      })
    );
  }
}

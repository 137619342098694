export enum QuickQuoteEmailTemplates {
  REPLACE_VEHICLE_QUICKQUOTE = 'ReplaceVehicleQuickQuote',
  REPLACE_VEHICLE_SEND_TO_AGENT = 'ReplaceVehicleSentToAgent',
  REPLACE_VEHICLE_SEND_TO_AGENT_EXPECTATION_SETTINGS = 'MYACCT_RPL_VEH_AGENTSENT_ES'
}

export enum EmailSubject {
  SEND_TO_AGENT = 'Your customer has requested a new vehicle quote.',
  SEND_TO_AGENT_ONERROR = 'Your Customer Needs a Car Insurance Quote',
  REPLACE_VEHICLE_SEND_TO_AGENT = 'Action Needed: Your Customer Wants to Replace a Vehicle'
}

export enum EmailContent {
  SEND_TO_AGENT = 'policy.agentEmailContentQuickQuote',
  SEND_TO_AGENT_ONERROR = 'policy.quickQuoteAgentEmailOnError',
  REPLACE_VEHICLE_SEND_TO_AGENT = 'policy.replaceVehicleAgentEmailContent'
}

export enum FromAddresse {
  ADDRESS = 'policy_change_request@amfam.com',
  NAME = 'Policy Change Request'
}

export enum ANSNotificationCodes {
  SEND_TO_AGENT = 'ANSMYECAQQ',
  SEND_TO_AGENT_ONERROR = 'ANSMYECRQA',
  REPLACE_VEHICLE_SEND_TO_AGENT = 'ANSMYARPVQ'
}

export enum ANSNotificationContent {
  SEND_TO_AGENT = 'policy.ansNotificationContentQuickQuote',
  SEND_TO_AGENT_ONERROR = 'policy.quickQuoteANSNotificationOnError',
  REPLACE_VEHICLE_SEND_TO_AGENT = 'policy.replaceVehicleSendToAgentANSMessage'
}

export enum QuickQuoteReplaceVehicleContext {
  QUICK_QUOTE = 'QUICK_QUOTE',
  REPLACE_VEHICLE = 'REPLACE_VEHICLE',
  EXPECTATION_SETTINGS = 'EXPECTATION_SETTINGS'
}

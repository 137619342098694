<div role="navigation" [attr.data-cy]="sitemapTestId" [attr.aria-label]="sitemapAriaLabel">
  <ul role="group" *ngFor="let link of linkListObj">
    <li
      role="sitemap-item"
      authCheck
      [permissionName]="link?.permissionName"
      [checkAction]="link?.checkAction"
      class="site-map-link-wrapper"
      [ngClass]="{ 'has-children': !!link.subLinkArray }"
    >
      <a
        *ngIf="!link?.linkExternalUrl"
        [dsRouterLink]="link?.linkRoute"
        [queryParams]="link?.queryParams"
        [attr.data-cy]="link?.linkTestId"
        (click)="sitemapClicked.emit(link)"
        class="heading-sub-page"
      >
        <span *ngIf="!!link?.linkIcon" role="img" alt="" [ngClass]="link?.linkIcon"></span>
        <span>{{ link.linkText }}</span>
      </a>
      <a
        *ngIf="!!link?.linkExternalUrl"
        [href]="link?.linkExternalUrl"
        [attr.target]="link?.linkTarget"
        [attr.data-cy]="link?.linkTestId"
        class="heading-sub-page"
      >
        {{ link.linkText }}</a
      >
    </li>
    <ng-container *ngFor="let sublink of link?.subLinkArray">
      <li
        role="sitemap-item"
        authCheck
        [permissionName]="sublink?.permissionName"
        [checkAction]="sublink?.checkAction"
        [attr.data-cy]="sublink?.linkTestId"
      >
        <span *ngIf="!!sublink?.linkIcon" role="img" alt="" [ngClass]="sublink?.linkIcon"></span>
        <a
          *ngIf="!sublink?.linkExternalUrl"
          [dsRouterLink]="sublink?.linkRoute"
          [queryParams]="sublink?.queryParams"
          [attr.data-cy]="sublink?.linkTestId"
        >
          {{ sublink.linkText }}</a
        >
        <a
          *ngIf="!!sublink?.linkExternalUrl"
          [href]="sublink?.linkExternalUrl"
          [attr.target]="sublink?.linkTarget"
          [attr.data-cy]="sublink?.linkTestId"
        >
          {{ sublink.linkText }}</a
        >
      </li>

      <hr *ngIf="!!sublink?.dividerAfter" aria-hidden="true" />
    </ng-container>
    <hr *ngIf="!!link?.dividerAfter" aria-hidden="true" />
  </ul>
</div>

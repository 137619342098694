import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'tab',
  template: `
    <div [hidden]="!active" class="pane"><ng-content></ng-content></div>
  `
})
export class TabComponent {
  @Output() tabClick: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  @Input() active = false;
  constructor() {}

  @HostListener('click')
  tabClickActivity() {
    this.tabClick.emit(true);
  }
}

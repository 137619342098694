<div *ngIf="!showCodeEntry">
  <div class="row">
    <div class="column twelve text-left margin-bottom-10">
      <h1 content [contentPath]="'auth.resetOptionsTextHeader'"></h1>
      <p><span content [contentPath]="'enrollment.verifyTextContinueText'"></span></p>
    </div>
  </div>
  <ds-verify-phone
    [maskedPhoneNumbers]="phoneList"
    [usePhoneInput]="false"
    [buttonText]="'continue'"
    (submittedPhoneEvent)="sendCode($event)"
  >
  </ds-verify-phone>
  <small class="block caption error margin-top-5" *ngIf="gotError && !showCodeEntry">
    <span *ngIf="!phoneLocked" content [contentPath]="'enrollment.verifyEmailNotFound'"></span>
    <span *ngIf="phoneLocked" content [contentPath]="'auth.resetOptionsPhoneLocked'"></span>
  </small>
</div>

<div *ngIf="showCodeEntry">
  <h1 content [contentPath]="'auth.resetOptionsCodeHeader'"></h1>
  <p>
    <span content [contentPath]="'enrollment.verifyTextSentPart1'"></span>
    <strong>{{ phone }}</strong>
    <span content [contentPath]="'enrollment.verifyTextSentPart2'"></span>
  </p>
  <ds-reset-password-code-entry [pinConfirmModel]="pinConfirmData"> </ds-reset-password-code-entry>
  <p class="caption">
    <button
      ds-button
      class="link"
      (click)="sendCode(phone)"
      content
      [contentPath]="'enrollment.verifyTextResend'"
    ></button>
  </p>
  <small
    class="block caption error margin-top-5"
    *ngIf="gotError"
    content
    [contentPath]="'enrollment.verifyEmailNotFound'"
  ></small>
</div>

<a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
  ><em class="icon-chevron-left"></em> Back</a
>

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { Component, OnInit } from '@angular/core';
import { get as _get } from 'lodash';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  AlternateAuthService,
  SendPinRequest,
  VerifyPinRequest,
  profileQuery
} from '@amfam/profile/data-access';

import { BrandSelectors } from '@amfam/shared/utility/brand';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService, ToasterService } from '@amfam/ui-kit';
import { AuthService } from '../../../core/auth/auth.service';
import { LoginAnalytics } from '../../shared/login-constants';

import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/store';

@Component({
  selector: 'ds-email-validation-verify-phone',
  templateUrl: './email-validation-verify-phone.component.html',
  styleUrls: ['./email-validation-verify-phone.component.scss']
})
export class EmailValidationVerifyPhoneComponent implements OnInit {
  private emailValidationPath: string;
  private partnerId: string;
  private customerId: string;

  showCodeEntry = false;
  callSelected = false;
  textSelected = true;
  genericError = false;
  pinSendError = false;
  pinVerifyError = false;
  phoneLocked = false;
  maskedPhone: string;

  constructor(
    private store: Store<fromRoot.RootState>,
    private authService: AuthService,
    private toaster: ToasterService,
    private config: ConfigService,
    private analyticsFacade: AnalyticsFacade,
    private spinner: LoadingSpinnerService,
    private alternateAuthService: AlternateAuthService
  ) {}

  ngOnInit() {
    this.emailValidationPath = this.config.get('profileValidateEmailPath');
    combineLatest(
      this.store.select(BrandSelectors.getPartnerId),
      this.store.select(profileQuery.getAlternateAuthCustomerId),
      (partnerId, customerId) => {
        return {
          partnerId: partnerId,
          customerId: customerId
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        this.partnerId = state.partnerId;
        this.customerId = state.customerId;
      });
  }

  sendPin(maskedPhone: string) {
    this.spinner.start();
    this.pinSendError = false;
    this.genericError = false;
    this.maskedPhone = maskedPhone;

    const requestObj: SendPinRequest = {
      customerId: this.customerId,
      typeOfVerificationMethodCode: this.textSelected ? 'TEXT MESSAGE' : 'PHONE CALL',
      partnerId: this.partnerId,
      maskedPhoneNumber: maskedPhone
    };

    this.alternateAuthService
      .sendPin(requestObj)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.stop();
          this.handleSendSuccess();
          this.analyticsFacade.trackPage(LoginAnalytics.emailVerificationSendCode);
        },
        err => {
          this.spinner.stop();
          this.handleSendError(err);
        }
      );
  }

  private handleSendSuccess() {
    let toastMessage: string;
    if (this.callSelected) {
      toastMessage = 'We will be calling you shortly with your code';
    } else {
      toastMessage = 'We will be texting you shortly with your code';
    }
    this.toaster.pop('success', toastMessage);
    this.showCodeEntry = true;
  }

  private handleSendError(err: any) {
    const apiCode = _get(err, 'status.messages[0].code');
    if (apiCode === 400010) {
      // Phone number is locked
      this.phoneLocked = true;
    } else if (apiCode === 400002) {
      // Phone number is invalid
      this.pinSendError = true;
    } else {
      this.genericError = true;
    }
  }

  verifyPin(pin: string) {
    this.spinner.start();
    this.pinVerifyError = false;
    this.genericError = false;

    const requestObj: VerifyPinRequest = {
      pinCode: pin,
      partnerId: this.partnerId
    };

    this.alternateAuthService
      .verifyPin(requestObj)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.stop();
          this.handleVerifySuccess();
          this.analyticsFacade.trackPage(LoginAnalytics.emailVerificationConfirmCode);
        },
        err => {
          this.spinner.stop();
          this.handleVerifyError(err);
        }
      );
  }

  private handleVerifySuccess() {
    this.authService.setAltAuth(true);
    if (!this.authService.redirectUrl.includes(this.emailValidationPath)) {
      this.authService.redirectUrl = this.emailValidationPath + '/error';
    }
    this.authService.loginRedirect();
  }

  private handleVerifyError(err: any) {
    const apiCode = _get(err, 'status.messages[0].code');
    if (apiCode === 400010) {
      // Phone number is locked
      this.phoneLocked = true;
    } else if (apiCode === 400009 || apiCode === 400008) {
      // Code is expired or invalid
      this.pinVerifyError = true;
    } else {
      this.genericError = true;
    }
  }

  selectCall() {
    this.callSelected = true;
    this.textSelected = false;
  }

  selectText() {
    this.callSelected = false;
    this.textSelected = true;
  }

  goBackOneStep() {
    this.store.dispatch(
      new fromRouterActions.Go({
        path: ['/email-validation']
      })
    );
  }
}

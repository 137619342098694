<div *ngIf="!showCodeEntry">
  <div class="row">
    <div class="column twelve text-left">
      <h3 content [contentPath]="'enrollment.verifyPhoneConfirm'"></h3>
      <p><span content [contentPath]="'enrollment.verifyCallContinueText'"></span></p>
    </div>
  </div>
  <ds-verify-phone
    [maskedPhoneNumbers]="phoneList"
    [usePhoneInput]="false"
    [buttonText]="'continue'"
    (submittedPhoneEvent)="sendCode($event)"
  >
  </ds-verify-phone>
  <small
    class="block caption error margin-top-5"
    *ngIf="gotError"
    content
    [contentPath]="'enrollment.verifyEmailNotFound'"
  ></small>
</div>
<div *ngIf="showCodeEntry">
  <h3 content [contentPath]="'enrollment.verifyPhoneConfirm'"></h3>
  <p>
    <span content [contentPath]="'enrollment.verifyCallSentPart1'"></span>{{ phone
    }}<span content [contentPath]="'enrollment.verifyCallSentPart2'"></span>
  </p>
  <p content [contentPath]="'enrollment.verifyPhoneCodeConfirm'"></p>
  <ds-verify-code-entry [confirmMethod]="'PHONE CALL'" (submittedCodeEvent)="verifyCode($event)">
  </ds-verify-code-entry>
  <small
    data-cy="confirmationCodeNotFoundText"
    class="error-600-text margin-bottom-5"
    *ngIf="codeNotFound"
    content
    [contentPath]="'enrollment.verifyPhoneInvalidText'"
  >
  </small>
  <p class="caption">
    <button
      ds-button
      class="link"
      (click)="sendCode(phone)"
      content
      [contentPath]="'enrollment.verifyCallAgain'"
    ></button>
  </p>
  <small
    class="block caption error margin-top-5"
    *ngIf="gotError"
    content
    [contentPath]="'enrollment.verifyEmailNotFound'"
  ></small>
</div>

<a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
  ><span class="icon-chevron-left"></span> Back</a
>

import { Observable, of } from 'rxjs';

import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, switchMap } from 'rxjs/operators';

import {
  FeatureFlagService,
  featureFlagQuery
} from '@amfam/shared/utility/feature-flag/data-access';
import { Store } from '@ngrx/store';
import { ConfigService } from '../config';
import { Content } from './content.model';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private httpBackend: HttpClient;
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private featureFlagService: FeatureFlagService,
    private store: Store,
    private backend: HttpBackend
  ) {
    this.httpBackend = new HttpClient(backend);
  }

  public getContent(path: string, program: string = ''): Observable<Content> {
    return this.store.select(featureFlagQuery.hasFeatureFlag).pipe(
      filter(loaded => !!loaded),
      switchMap(() => {
        const sitecoreLambdaEnabled = this.featureFlagService.isEnabled('sitecore_lambda');
        const sitecoreDirect = this.featureFlagService.isEnabled('sitecore_direct');
        const endpointConfig = sitecoreLambdaEnabled
          ? 'sitecoreLambdaApi'
          : sitecoreDirect
          ? 'sitecoreApi'
          : 'contentApi';
        const context = program ? program : 'MyAccount';
        const domain = this.config.get(endpointConfig);
        const url: string = `${domain}${context}/${path}`;
        const httpOptions = {
          headers: new HttpHeaders({
            authId: 'cdhtp1',
            userId: 'cdhtp1'
          })
        };
        const http = endpointConfig === 'contentApi' ? this.http : this.httpBackend;
        return http.get<Content>(url, endpointConfig === 'contentApi' ? httpOptions : {}).pipe(
          catchError(error => {
            const endPointConfigOnError = sitecoreDirect ? 'sitecoreApi' : 'contentApi';
            const domainOnError = this.config.get(endPointConfigOnError);
            const urlOnError = `${domainOnError}${context}/${path}`;
            const errorHttp = endPointConfigOnError === 'contentApi' ? this.http : this.httpBackend;
            return errorHttp
              .get<Content>(urlOnError, endPointConfigOnError === 'contentApi' ? httpOptions : {})
              .pipe(catchError(error => of(new Content())));
          })
        );
      })
    );
  }

  public getMedia(path: string, fileOptions: any): Observable<ArrayBuffer> {
    // TODO: Use site core instead of local files
    // URLs will look like https://www-dev-93.amfam.com/-/media/myaccount/path-to-file
    if (!path.startsWith('/')) {
      path = '/assets/images/documents/' + path;
    }
    return this.http.get(path, fileOptions);
  }
}

export type ContentPaths =
  | 'companyinformation'
  | 'privacyandsecuritypolicy'
  | 'legalnotice'
  | 'TermsandConditions'
  | 'smartphone-termsandconditions'
  | 'OnlineTermsandConditions'
  | 'termsandconditions'
  | 'OutageBanner';

export type PartialContentPaths =
  | '-edelivery-paperlesspolicytermsandcondition'
  | '-edelivery-paperlesstermsandcondition-admin'
  | '-billing-termsandconditions'
  | '-payments-singlepaydcc'
  | '-payments-singlepayach'
  | '-payments-autopaydcc'
  | '-payments-autopayach';

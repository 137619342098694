import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  tooltips: Map<string, OverlayRef>;

  constructor() {
    this.tooltips = new Map();
  }

  addTooltip(id, overlayRef: OverlayRef) {
    this.tooltips.set(id, overlayRef);
  }

  removeTooltip(id) {
    this.tooltips.delete(id);
  }

  closeAllExcept(tooltipId: string) {
    this.tooltips.forEach((overlayRef, id) => {
      if (id !== tooltipId) {
        const selectedOverlayRef = this.tooltips.get(id);
        if (selectedOverlayRef.hasAttached()) selectedOverlayRef.detach();
      }
    });
  }
}

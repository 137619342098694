<div class="key-value" [ngClass]="classes">
  <div class="value">{{ value }}</div>
  <div class="key-wrapper">
    <div class="key">{{ key }}</div>
    <button
      class="info__button"
      data-cy="infoButton"
      triggerOn="click"
      [tooltip]="tooltipTemplateRef"
      [showCloseButton]="true"
      [closeOnClickingOutside]="true"
      [closeOnEscape]="true"
    >
      <i class="icon-info"></i>
    </button>
  </div>
</div>

import { FnolRiskModel, RiskModel } from '@amfam/policy/models';

export class CustomerPreFnolModel {
  cdhId: string;
  firstName: string;
  lastName: string;
  risk: FnolRiskModel;
  emailAddress: string;
  phoneNumber: string;
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AutoPayActions } from '@amfam/billing/auto-pay/data-access';
import { PaymentAccountActions } from '@amfam/billing/paymentaccount/data-access';
import { ScheduledPaymentActions } from '@amfam/billing/schedulepayment/data-access';
import * as PaymentConfirmationActions from './paymentConfirmation.actions';
@Injectable()
export class PaymentConfirmationEffects {
  constructor(private action$: Actions) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering

  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentConfirmationActions.PaymentConfirmationActionTypes.PaymentConfirmationLoad),
      switchMap(payLoad =>
        of(payLoad).pipe(
          map(
            (confirmationPayload: any) =>
              new PaymentConfirmationActions.PaymentConfirmationLoadSuccess(
                confirmationPayload.payload
              )
          ),
          catchError(error => of(new PaymentConfirmationActions.PaymentConfirmationLoadFail(error)))
        )
      )
    )
  );

  multiple_autopay_submit_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(AutoPayActions.addMultipleAutoPaySuccess),
      map(action => action),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );

  autopay_edit_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(AutoPayActions.editAutoPaySuccess),
      map(action => action),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );

  autopay_delete_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(AutoPayActions.deleteAutoPaySuccess),
      map(action => action),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );

  payment_submit_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitSuccess),
      map((action: ScheduledPaymentActions.ScheduledPaymentsSubmitSuccess) => action.payload),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );

  payment_edit_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsEditSuccess),
      map((action: ScheduledPaymentActions.ScheduledPaymentsEditSuccess) => action.payload),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );

  payment_delete_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteSuccess),
      map((action: ScheduledPaymentActions.ScheduledPaymentsDeleteSuccess) => action.payload),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );

  paymentAccount_add_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsAddSuccess),
      map((action: PaymentAccountActions.PaymentAccountsAddSuccess) => action.payload),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );

  paymentAccount_edit_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsEditSuccess),
      map((action: PaymentAccountActions.PaymentAccountsEditSuccess) => action.payload),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );

  paymentAccount_delete_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsDeleteSuccess),
      map((action: PaymentAccountActions.PaymentAccountsDeleteSuccess) => action.payload),
      map(payload => new PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    )
  );
}

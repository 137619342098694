import { switchMap, catchError, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';

import { BrandService } from '../services/brand.service';
import { BrandModel } from '../models/brand.model';
import * as BrandSelectors from '../+state/brand.selectors';
import { ConfigService } from '@amfam/shared/utility/shared-services/src/lib/config';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { NavigationService, fromRouterActions } from '@amfam/shared/utility/navigation';

@Injectable()
export class BrandGuard  {
  public dataLoading: Observable<boolean>;
  constructor(
    private store: Store<BrandModel>,
    private brandService: BrandService,
    private config: ConfigService,
    private navigationService: NavigationService,
    private feature: FeatureFlagService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let experienceId = route.root.firstChild.firstChild.paramMap.get('expid');

    if (!experienceId) {
      experienceId = this.config.get('afiExperienceId');
    }
    const currentExperienceId = this.navigationService.getExperienceId();
    if (currentExperienceId !== '' && currentExperienceId === experienceId) {
      return true;
    }
    return this.getBrand().pipe(
      switchMap(brand => {
        if (brand.loaded) {
          return of(true);
        }
        return this.brandService.loadBrandForUrl(experienceId).pipe(
          take(1),
          switchMap(() => {
            return of(true);
          }),
          catchError(error => {
            this.store.dispatch(
              new fromRouterActions.Go({
                path: ['/not-found']
              })
            );
            return of(false);
          })
        );
      })
    );
  }

  private getBrand(): Observable<BrandModel> {
    return this.store.select(BrandSelectors.getBrandState).pipe(take(1));
  }
}

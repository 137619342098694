import { AddressPipe } from '@amfam/policy/pipes';
import { TitleCasePipe } from '@amfam/shared/ui/pipes';
import { Address } from './address';
import { AutoPolicy } from './auto-policy';
import { Coverage } from './coverage';
import { Discount } from './discount';
import { Fee } from './fee';
import { GenericProductType } from './generic-product-type';
import { NamedInsured } from './named-insured';
import { Policy } from './policy';
import { PolicyTypeDisplayNameConstants } from './policy-type-display-name-constants';
import { Risk } from './risk';
import { Vehicle } from './vehicle';
import { VehicleType } from './vehicle-type';

export class RiskModel {
  vehicleTypeName: string;
  generalizedProductType: GenericProductType;
  periodStartDate: Date;
  periodEndDate: Date;
  id: string;
  poiLink: string;
  policyNumber: string;
  policyType: string;
  address?: Address;
  description?: string;
  vehicleId?: string;
  vehicleType?: VehicleType;
  vehicleTypeDisplayName?: string;
  vehicleRoles: NamedInsured[] = new Array<NamedInsured>();
  vehicleDiscounts: Discount[] = new Array<Discount>();
  vehicleCoverages: Coverage[] = new Array<Coverage>();
  vehicleFees: Fee[] = new Array<Fee>();
  vin: string;
  iconType: string;
  policyTypeDisplayName: string;
  previouslyEnrolled: boolean;
  waitingPeriodEndDate?: Date;
  hasExistingDevice: boolean;
  year: string;
  make: string;
  model: string;
  contractState?: string;
  vehicleUseDescription?: string;
  insuredName?: string;
  producerId?: string;
  fromSummary?: boolean;
  accountNumber?: string;
  amfamCompanyCode?: string;
  amfamCompanyDescription?: string;
  sortType?: string;
  operator?: boolean;

  constructor(public risk: Risk, public policy: Policy) {
    this.generalizedProductType = policy.generalizedProductType;
    this.periodStartDate = policy.periodStartDate;
    this.periodEndDate = policy.periodEndDate;
    this.policyNumber = policy.policyNumber;
    this.policyType = policy.policyType;
    this.iconType = policy.iconType;
    this.id = policy.policyNumber;
    this.producerId = policy?.assignedProducer?.producerIdNum;
    if (policy.generalizedProductType === GenericProductType.Auto) {
      const autoPolicy = policy as AutoPolicy;
      const vehRisk = risk as Vehicle;
      this.id = vehRisk.vehicleId;
      this.vehicleId = vehRisk.vehicleId;
      this.vehicleType = vehRisk.vehicleType;
      this.vehicleTypeDisplayName = Vehicle.getVehicleTypeDisplayName(vehRisk.vehicleType);
      this.address = vehRisk.garageLocationAddress;
      this.vin = vehRisk.vinSerialNumber;
      this.vehicleTypeName = vehRisk.vehicleTypeName;
      this.description = `${vehRisk.year} ${vehRisk.make} ${vehRisk.model}`;
      this.make = vehRisk.make;
      this.model = vehRisk.model;
      this.year = vehRisk.year;
      this.poiLink = autoPolicy.poiLink;
      this.vehicleUseDescription = vehRisk.vehicleUseDescription;
      this.iconType = vehRisk.iconType;
      this.accountNumber = autoPolicy.accountNumber;
      this.amfamCompanyCode = autoPolicy.amfamCompanyCode;
      this.amfamCompanyDescription = autoPolicy.amfamCompanyDescription;
      this.policyTypeDisplayName = PolicyTypeDisplayNameConstants.AUTO;
      for (const vehicleRole of vehRisk.vehicleRoles) {
        this.vehicleRoles.push(NamedInsured.fromJson(vehicleRole));
      }
      for (const vehicleDiscount of vehRisk.vehicleDiscounts) {
        this.vehicleDiscounts.push(Discount.fromJson(vehicleDiscount));
      }
      for (const vehicleFee of vehRisk.vehicleFees) {
        this.vehicleFees.push(Discount.fromJson(vehicleFee));
      }
      for (const vehicleCoverage of vehRisk.vehicleCoverages) {
        const coverage = Coverage.fromJson(vehicleCoverage);
        if (Coverage.isValid(coverage)) {
          this.vehicleCoverages.push(coverage);
        }
      }
      this.sortType = VehicleType[this.vehicleType].toLowerCase();
    } else if (policy.generalizedProductType === GenericProductType.Home) {
      this.address = risk as Address;
      this.description = new AddressPipe().transform(this.address, 'short');
      this.policyTypeDisplayName = PolicyTypeDisplayNameConstants.HOME;
      this.sortType = 'property';
    } else if (policy.generalizedProductType === GenericProductType.Life) {
      this.description = new TitleCasePipe().transform(risk.toString());
      this.policyTypeDisplayName = PolicyTypeDisplayNameConstants.LIFE;
      this.sortType = 'life';
    } else if (policy.generalizedProductType === GenericProductType.Umbrella) {
      this.policyTypeDisplayName = PolicyTypeDisplayNameConstants.UMBRELLA;
      this.description = policy.policyDescription;
      this.sortType = 'umbrella';
    } else {
      this.policyTypeDisplayName = PolicyTypeDisplayNameConstants.OTHER;
      this.iconType = 'icon-dreams';
      this.sortType = 'other';
    }
  }

  get policyTypeName(): string {
    return GenericProductType[this.generalizedProductType] || '';
  }
}

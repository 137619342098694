import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  CreateRepairRequest,
  CreateRepairRequestSuccess,
  CreateRepairRequestFail,
  RepairServiceActionTypes
} from './repair-service.actions';

import { CreateRepairRequestPayload } from './repair-service.models';

import { ClaimServicesService } from '../../services/claim-services.service';

@Injectable()
export class RepairServiceEffects {
  
  createRepairRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<CreateRepairRequest>(RepairServiceActionTypes.CreateRepairRequest),
    map(action => action.payload),
    switchMap((payload: CreateRepairRequestPayload) =>
      this.claimServicesService
        .createRepairRequest(payload.claimNumber, payload.vin, payload.selectedShop.id)
        .pipe(
          map(() => new CreateRepairRequestSuccess()),
          catchError(() => of(new CreateRepairRequestFail()))
        )
    )
  ));

  constructor(private actions$: Actions, private claimServicesService: ClaimServicesService) {}
}

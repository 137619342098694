import { AnalyticsFacade } from '@amfam/shared/analytics';
import { userQuery, UserState } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import {
  ConfigService,
  CookiesService,
  PageAnalytic
} from '@amfam/shared/utility/shared-services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  of as observableOf,
  throwError as observableThrowError,
  Subject,
  Subscription
} from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';
import * as fromRoot from '../store/';
import * as resetPasswordActions from '../store/reset-password/reset-password.actions';
import * as sessionActions from '../store/session/session.actions';
import { Credentials } from '../store/session/session.actions';
import { CoreService } from '@amfam/opportunities';

@Injectable()
export class AuthService {
  // track unsuccessful attempts here
  emailEntryAttemptsStore$: Subject<any> = new BehaviorSubject(null);

  // redirect for after logging in
  redirectUrl = '/';

  altAuthVerified = false;
  loggedIn = false;

  private digitalAccountApiUrl: string;
  private authenticateUrl: string;
  private logoutUrl: string;
  private refreshUrl: string;
  private retrieveUserIdUrl: string;
  private user$: Observable<UserState>;
  private loggedIn$: Observable<boolean>;
  private loggedInSub: Subscription;
  private partnerIdSub: Subscription;
  private partnerId: string;
  private rememberUser = false;
  private maskedUsername: string;
  private validateEmailPath: string;

  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Logout',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Logout',
    subCategory2: '',
    subCategory3: ''
  };
  // **** End of Analytics data for this component

  constructor(
    private store: Store<fromRoot.RootState>,
    private config: ConfigService,
    private cookies: CookiesService,
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
    private analyticsFacade: AnalyticsFacade,
    private coreService: CoreService
  ) {
    this.digitalAccountApiUrl = this.config.get('digitalAccountApi');
    this.authenticateUrl = this.digitalAccountApiUrl + '/digitalaccounts/authenticate';
    this.logoutUrl = this.digitalAccountApiUrl + '/digitalaccounts/logout';
    this.refreshUrl = this.digitalAccountApiUrl + '/digitalaccounts/refresh';
    this.retrieveUserIdUrl = this.digitalAccountApiUrl + '/digitalaccounts/forgotuserid';
    this.user$ = this.store.select(userQuery.getUserState);
    this.validateEmailPath = this.config.get('profileValidateEmailPath');
    this.loggedInSub = this.store.select(fromRoot.loggedIn).subscribe(loggdIn => {
      this.loggedIn = loggdIn;
    });
    this.partnerIdSub = this.store
      .select(BrandSelectors.getPartnerId)
      .subscribe(partnerId => (this.partnerId = partnerId));

    // Check if rememberMe cookie exists and does not have value 'ForgetMe'
    const rmuid = this.cookies.getItem('RMUID');
    this.rememberUser = rmuid && rmuid !== 'ForgetMe' ? true : false;
    if (this.rememberUser) {
      this.maskedUsername = this.cookies.getItem('MKUID');
    } else {
      // Remove MKUID and RMUID cookies if rememberUser = false
      this.cookies.removeItem('RMUID', '/', '.amfam.com');
      this.cookies.removeItem('MKUID', '/', '.amfam.com');
    }
  }

  get isAltAuthVerified() {
    return this.altAuthVerified;
  }
  get currentUser$() {
    return this.user$;
  }

  get userIdRetrievalAttempts$() {
    return this.emailEntryAttemptsStore$.asObservable();
  }

  get rememberMe() {
    return this.rememberUser;
  }

  get maskedUserId() {
    return this.maskedUsername;
  }

  setAltAuth(value: boolean) {
    this.altAuthVerified = value;
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  setRememberMe(value: boolean) {
    this.rememberUser = value;
    // If rememberMe = false, delete RMUID cookie
    if (!value) {
      this.cookies.removeItem('RMUID', '/', '.amfam.com');
      this.cookies.removeItem('MKUID', '/', '.amfam.com');
    }
  }

  login(username: string, password: string): Observable<any> {
    if (this.isLoggedIn()) {
      this.logout();
    }
    if (!this.cookies.cookiesEnabled()) {
      return this._handleError({ status: { code: 409 } });
    }
    const body = {
      rememberMe: this.featureFlagService.isEnabled('save_user_id') ? this.rememberUser : undefined,
      partnerId: this.partnerId
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));

    return this.http.post(this.authenticateUrl, body, { headers: headers }).pipe(
      map((data: any) => {
        if (data && data.trackId) {
          if (data.duplicateEmail === true) {
            this.cookies.setItem('isDuplicateEmail', '1');
          } else {
            this.cookies.removeItem('isDuplicateEmail');
          }
          this.store.dispatch(new sessionActions.LoginUserSuccessAction(data));
        }
        this.setMKUID(username);
        return data;
      })
    );
  }

  loginWithPayload(payload: Credentials): Observable<any> {
    return this.login(payload.username, payload.password);
  }

  // Creates MKUID cookie for masked username
  setMKUID(username: string) {
    if (this.rememberUser) {
      this.maskedUsername = username.slice(0, 3) + '****';
      const expirationDate = new Date();
      expirationDate.setUTCFullYear(expirationDate.getUTCFullYear() + 1);
      expirationDate.setUTCMonth(expirationDate.getUTCMonth() + 1);
      this.cookies.setItem('MKUID', this.maskedUsername, expirationDate, '/', '.amfam.com');
    }
  }

  refresh(): Observable<boolean> {
    return this.http.post(this.refreshUrl, '').pipe(
      map((data: any) => {
        if (data && data.trackId) {
          this.store.dispatch(new sessionActions.LoginUserSuccessAction(data));
          return true;
        }
      }),
      catchError(err => this._handleRefreshError(err))
    );
  }

  logout(): Observable<boolean> {
    return this.http.post(this.logoutUrl, '').pipe(
      map((res: any) => this._logout()),
      catchError((error: any) => observableOf(this._logout()))
    );
  }

  /*
    Get the current time from the gateway
  */
  getGatewayTime(): Observable<any> {
    const gatewayTimeUrl = this.config.get('gatewayTimeApi');
    return this.http.get(gatewayTimeUrl);
  }

  public loginRedirect(): boolean {
    this.store.dispatch(
      new fromRouterActions.Go({
        path: [this.redirectUrl]
      })
    );
    return true;
  }

  public checkUsernameAvailability(username: string): Observable<any> {
    const checkUsernameAvailabilityUrl =
      this.digitalAccountApiUrl + '/digitalaccounts?userId=' + username;

    return this.http.get(checkUsernameAvailabilityUrl).pipe(
      switchMap((data: any) => observableOf(data)),
      distinctUntilChanged(),
      catchError((error: any) => this._handleError(error))
    );
  }

  public checkEmailAddressAvailability(email: string): Observable<any> {
    const checkEmailAddressAvailabilityUrl =
      this.digitalAccountApiUrl +
      '/digitalaccounts?emailAddress=' +
      encodeURIComponent(email) +
      (this.partnerId ? '&partnerId=' + encodeURIComponent(this.partnerId) : '');

    return this.http.get(checkEmailAddressAvailabilityUrl).pipe(
      switchMap((data: any) => {
        if (data && data.status && data.status.code === 200) {
          return observableOf(data);
        } else {
          return this._handleError(data);
        }
      }),
      distinctUntilChanged(),
      catchError((error: any) => this._handleError(error))
    );
  }

  incrementEmailEntryAttempts() {
    let attempts = sessionStorage.getItem('email-entry-attempts');
    if (attempts) {
      const attemptsNum = parseInt(attempts, 10) + 1;
      sessionStorage.removeItem('email-entry-attempts');
      sessionStorage.setItem('email-entry-attempts', attemptsNum.toString());
      attempts = sessionStorage.getItem('email-entry-attempts');
    } else if (!attempts) {
      sessionStorage.setItem('email-entry-attempts', '1');
      attempts = sessionStorage.getItem('email-entry-attempts');
    }
    this.emailEntryAttemptsStore$.next(attempts);
  }

  retrieveUserId(requestObj: any): Observable<any> {
    return this.http.post(this.retrieveUserIdUrl, JSON.stringify(requestObj)).pipe(
      mergeMap((data: any) => {
        if (data.status.code === 200) {
          data.emailForResend = requestObj.emailAddress;
          return observableOf(data);
        }
        return this._handleError(data);
      }),
      catchError((error: any) => this._handleError(error))
    );
  }

  refreshSessionStorageAttempts() {
    const attempts = sessionStorage.getItem('attempts');
    if (attempts) {
      this.emailEntryAttemptsStore$.next(attempts);
    } else {
      return;
    }
  }

  setCurrentlyLockedUserId(userId: string) {
    this.store.dispatch(new resetPasswordActions.UserLoginLockedAction(userId));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public _handleRefreshError(res) {
    if (this.isLoggedIn()) {
      this.expire().subscribe(r =>
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/session-expired']
          })
        )
      );
    } else {
      if (this.redirectUrl.includes(this.validateEmailPath)) {
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/email-validation']
          })
        );
      } else {
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/login']
          })
        );
      }
    }
    return this._handleError(res);
  }

  private trackLogout() {
    this.analyticsFacade.trackPage(this.pageAnalytic);
  }

  private expire(): Observable<boolean> {
    return observableOf(this._logout());
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _handleError(res) {
    return observableThrowError(res);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _logout(): boolean {
    this.coreService.stopListening();
    this.store.dispatch(new sessionActions.LogoutUserAction());
    this.trackLogout();
    return true;
  }
}

import { PartyEmail, PartyPhone } from '../party';
import { SourceSystemType } from '@amfam/policy/models';

export class ProfileOverviewModel {
  address: any;
  firstName: string;
  primaryEmail: PartyEmail;
  primaryPhone: PartyPhone;
  userId: string;
}

export interface PreferenceItem {
  billAccountName: string;
  billAccountNumber: string;
  billAccountType: SourceSystemType;
  canSetPreferences: boolean;
  deliveryPreference: string; // TODO - Make enum as PAPER || EMAIL || BOTH
  email: string;
  preferenceId: {
    email: string;
    paper: string;
  };
  riskNames: string[];
}

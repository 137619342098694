import { AddressPipe } from '@amfam/policy/pipes';
import { Address } from './address';
import { Coverage } from './coverage';
import { NamedInsured } from './named-insured';
import { Policy } from './policy';
import { PolicyTypeIconConstants } from './policy-type-icon-constants';

export class PropertyPolicy extends Policy {
  public riskLocationAddress: Address;
  public propertyCoverages: Coverage[] = new Array<Coverage>();
  public dwellingRoles: NamedInsured[] = new Array<NamedInsured>();

  static fromJson(json: any, coveragesToFilter?, coveragesToReplace?): PropertyPolicy {
    const policy = new PropertyPolicy();
    policy.assignProperties(json, coveragesToFilter, coveragesToReplace);

    return policy;
  }

  protected assignProperties(json: any, coveragesToFilter?, coveragesToReplace?) {
    super.assignProperties(json);
    this.iconType = PolicyTypeIconConstants.HOME;
    this.policyType = 'property';

    if (json.dwelling.riskLocationAddress) {
      this.riskLocationAddress = Address.fromJson(json.dwelling.riskLocationAddress);
      this.policyRisks.push(this.riskLocationAddress);
    }

    if (json.dwelling.dwellingRoles) {
      for (const insured of json.dwelling.dwellingRoles) {
        this.dwellingRoles.push(NamedInsured.fromJson(insured));
      }
    }

    if (json.dwelling.dwellingCoverages) {
      for (const coverage of json.dwelling.dwellingCoverages) {
        const coverageEntity = Coverage.fromJson(
          this.replaceCoverage(
            this.removeSubLimits(coverage, coveragesToFilter),
            coveragesToReplace
          )
        );
        if (Coverage.isValid(coverageEntity)) {
          this.propertyCoverages.push(coverageEntity);
        }
      }
    }

    this.riskDescriptionList = [new AddressPipe().transform(this.policyAddress, 'short')];
  }

  removeSubLimits(coverage, coveragesToFilter) {
    if (!coveragesToFilter || coveragesToFilter.length === 0) return coverage;

    // Removing the sublimits from the coverage based on the filters in the policy-copy.json
    for (const coverageToFilter of coveragesToFilter) {
      const { coverageCode, limitTerm, packageTerm } = coverageToFilter;
      if (coverage.code === coverageCode) {
        return {
          ...coverage,
          limitTerms: limitTerm
            ? [
                ...coverage.limitTerms
                  .filter(lt => lt.code === limitTerm)
                  .map(l => ({
                    ...l,
                    packageTerms: packageTerm
                      ? [...l.packageTerms.filter(pt => pt.termType === packageTerm)]
                      : [...l.packageTerms]
                  }))
              ]
            : [...coverage.limitTerms]
        };
      }
    }

    return coverage;
  }

  replaceCoverage(coverage, coveragesToReplace) {
    if (!coveragesToReplace || coveragesToReplace.length === 0) return coverage;

    // Replace the coverage text from the coverage based on the config in  policy-copy.json
    for (const coverageToReplace of coveragesToReplace) {
      const { coverageR, limitR, packageR } = coverageToReplace;
      const coverageCode = coverageR.code;
      const limitTerm = limitR.code;
      const packageTerm = packageR.code;
      const coverageCodeToReplace = coverageR.replace;
      const limitTermToReplace = limitR.replace;
      const packageTermToReplace = packageR.replace;

      if (coverage.code === coverageCode) {
        return {
          ...coverage,
          description: coverageCodeToReplace
            ? coverage?.description?.replace(coverageCodeToReplace.from, coverageCodeToReplace.to)
            : coverage?.description,
          limitTerms: this.replaceLimitTerm(
            coverage,
            limitTerm,
            limitTermToReplace,
            packageTerm,
            packageTermToReplace
          )
        };
      }
    }

    return coverage;
  }

  private replacePackageTerm(l, limitTerm, packageTerm, packageTermToReplace) {
    return packageTermToReplace
      ? [
          ...l.packageTerms.map(pt =>
            packageTerm === pt.termType && l.code === limitTerm
              ? {
                  ...pt,
                  description: pt.description?.replace(
                    packageTermToReplace.from,
                    packageTermToReplace.to
                  )
                }
              : pt
          )
        ]
      : [...l.packageTerms];
  }

  private replaceLimitTerm(
    coverage,
    limitTerm,
    limitTermToReplace,
    packageTerm,
    packageTermToReplace
  ) {
    return limitTermToReplace
      ? [
          ...coverage.limitTerms
            .map(lt =>
              limitTerm === lt.code
                ? {
                    ...lt,
                    description: lt.description?.replace(
                      limitTermToReplace.from,
                      limitTermToReplace.to
                    )
                  }
                : lt
            )
            .map(l => ({
              ...l,
              packageTerms: this.replacePackageTerm(l, limitTerm, packageTerm, packageTermToReplace)
            }))
        ]
      : [
          ...coverage.limitTerms.map(l => ({
            ...l,
            packageTerms: this.replacePackageTerm(l, limitTerm, packageTerm, packageTermToReplace)
          }))
        ];
  }
}

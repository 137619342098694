export enum ErrorLevels {
  'UNKNOWN',
  'HIGH',
  'MEDIUM',
  'LOW'
}

export class ErrorObj {
  errorMessage: string;
  errorLevel: ErrorLevels;
}

export class ErrorMsgObj {
  code: string;
  description: string;
  details: [];
}

import { get as _get } from 'lodash';
import { Injectable } from '@angular/core';
import { subDays, endOfDay, isAfter, parse, subYears } from 'date-fns';
import { Claim } from '../models/claim';

@Injectable()
export class ClaimAdapter {
  constructor() {}

  adapt(getClaimsReponse: any) {
    const thirtyDaysAgo = endOfDay(subDays(new Date(), 31));

    const fnols = _get(getClaimsReponse, 'fnols', []).filter(fnol => {
      const openDate = parse(_get(fnol, 'openDate', new Date()));
      return isAfter(openDate, thirtyDaysAgo);
    });

    // Filter out all ICS claims and those older than 7 years
    const minDate = subYears(new Date(), 7);

    let hasHiddenClaims = false;
    const claims = _get(getClaimsReponse, 'claims', []).filter((claim: Claim) => {
      if (
        _get(claim, 'claimSourceSystem', '').toLowerCase() !== 'ics' &&
        isAfter(claim.openDate, minDate) &&
        _get(claim, 'closedReason', '').toLowerCase() !== 'void'
      ) {
        return true;
      } else {
        hasHiddenClaims =
          hasHiddenClaims || _get(claim, 'closedReason', '').toLowerCase() !== 'void';
        return false;
      }
    });

    return { fnols, claims, hasHiddenClaims };
  }
}

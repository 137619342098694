import { Address, Policy } from '@amfam/policy/models';
import { UBI_PROGRAM } from '../../../../../policy/know-your-drive-partial-household/models/kyd-program-category.model';

export class KydEnrollmentRequest {
  customerIdentifier: string;
  externalCustomerIdentifier: string;
  vin: string;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  policyNumber: string;
  termsAcceptedIndicator: string;
  acknowledgementDate: string;
  requestDate: string;
  optInReasonCode: string;
  optInType: string;
  emailAddress: string;
  phoneNumber: string;
  shippingAddress: Address;
  shipDevice: string;
  policyState: string;
  policyEffectiveDate: string;
  policyExpireDate: string;
  ubiProgramCategoryCode: string;

  constructor() {
    this.ubiProgramCategoryCode = UBI_PROGRAM.AUTOMATIC;
  }
}

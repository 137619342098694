import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-table-body-row',
  templateUrl: './table-body-row.component.html',
  styleUrls: ['../../../containers/ds-table/ds-table.component.scss']
})
export class DsTableBodyRowComponent implements OnInit {
  @Input() row: any;
  @Input() columns: any;
  @Input() tableClasses: any;
  @Input() rowDetail: any;

  constructor() {}

  ngOnInit() {}
}

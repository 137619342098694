<h1 class="heading-main">
  <span *dsPartnerAFI>Complete Your Sign Up</span>
  <span *dsPartnerConnect>Vehicle Program Enrollment</span>
</h1>
<form [formGroup]="termsForm" (ngSubmit)="submitForm()">
  <div class="row">
    <div class="column twelve">
      <span *dsPartnerAFI>
        Please read and agree to the Terms &amp; Conditions below. You can change your preferences
        any time.</span
      >
      <span *dsPartnerConnect
        >These programs apply only to vehicle policies. Your property bills, claims and policies are
        managed by our affiliate, Homesite Group. Please
        <a [href]="propertyServiceUrl" target="_blank">visit their website</a> for more
        information.</span
      >
    </div>
  </div>
  <div class="row pad-top-20">
    <div class="column twelve program" *ngFor="let program of model.programs">
      <div class="pad-bottom-20">
        <div class="program-name">{{ program.title }}</div>
        <payment-terms-and-conditions
          *ngIf="program.isPaymentTerms"
          paymentMethodType="Unknown"
        ></payment-terms-and-conditions>
        <a href="{{ program.termsUrl }}" target="amfam" class="accent" *ngIf="program.termsUrl"
          >View Terms &amp; Conditions for {{ program.title }}</a
        >
      </div>
    </div>
  </div>
  <div class="row pad-top-20" *ngIf="model.hasTerms">
    <div class="column twelve">
      <div class="terms pad" tabindex="0">
        <ng-container *ngFor="let program of model.programs">
          <div class="program-terms" *ngIf="program.terms" [innerHtml]="program.terms"></div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row pad-top-20">
    <div class="column twelve">
      <fieldset>
        <input
          authCheck
          class="margin-right-5"
          permissionName="maetnc_submit"
          checkAction="disable"
          type="radio"
          id="agreeToTermsRadio"
          formControlName="agreeToTerms"
          [value]="true"
          [(ngModel)]="agreeToTerms"
        />
        <label
          for="agreeToTermsRadio"
          data-cy="enrollTermsAgreeToTermsRadio"
          *ngIf="model.showPaymentTerms"
          >I agree to the Payment Authorization and Terms &amp; Conditions above.</label
        >
        <label
          for="agreeToTermsRadio"
          data-cy="enrollTermsAgreeToTermsRadio"
          *ngIf="!model.showPaymentTerms"
          >I agree to the Terms &amp; Conditions above.</label
        >
      </fieldset>
    </div>
    <div class="column twelve">
      <fieldset>
        <input
          authCheck
          class="margin-right-5"
          permissionName="maetnc_submit"
          checkAction="disable"
          type="radio"
          id="declineTermsRadio"
          formControlName="agreeToTerms"
          [value]="false"
          [(ngModel)]="agreeToTerms"
        />
        <label
          for="declineTermsRadio"
          data-cy="enrollTermsDeclineTermsRadio"
          *ngIf="model.showPaymentTerms"
          >I decline the Payment Authorization and Terms &amp; Conditions above.</label
        >
        <label
          for="declineTermsRadio"
          data-cy="enrollTermsDeclineTermsRadio"
          *ngIf="!model.showPaymentTerms"
          >I decline the Terms &amp; Conditions above.</label
        >
      </fieldset>
      <div class="warning pad margin-top-10" *ngIf="!agreeToTerms && hasSelectedAgreeToTermsOption">
        NOTE: By declining the Terms &amp; Conditions, you will not be signed up for any of the
        programs above, and you may lose any related discounts.
      </div>
    </div>
  </div>
  <div class="row callToAction">
    <div class="column twelve">
      <span class="md-float-right">
        <button
          ds-button
          type="submit"
          [disabled]="!hasSelectedAgreeToTermsOption"
          content
          [contentPath]="'shared.submitBtn'"
          data-cy="enrollTermsButton"
        ></button>
      </span>
    </div>
  </div>
</form>

import { takeUntil, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ForgotService } from '../../shared/forgot.service';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/store';
import * as resetPasswordActions from '../../../core/store/reset-password/reset-password.actions';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { VerifyPinRequest } from '@amfam/shared/models';

@Component({
  selector: 'ds-verify-token',
  templateUrl: './verify-token.component.html'
})
export class VerifyTokenComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  private token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromRoot.RootState>,
    private forgotService: ForgotService,
    private spinner: LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(take(1)).subscribe(this.handleRouteState.bind(this));
  }

  private handleRouteState(params: any) {
    this.token = params['token'];
    this.verify(this.token);
  }

  private verify(token: string) {
    this.spinner.start();

    const requestObj: VerifyPinRequest = {
      correlationId: token
    };

    this.forgotService
      .verifyToken(requestObj)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        res => {
          // Token is valid: Route to Reset Password
          this.store.dispatch(new resetPasswordActions.UserVerifiedEmailAction(token));
          this.store.dispatch(
            new fromRouterActions.Go({
              path: ['/forgot-password/reset-password']
            })
          );
        },
        err => {
          // Token is invalid: Route to Invalid Token component
          this.store.dispatch(
            new fromRouterActions.Go({
              path: ['/forgot-password/invalid-token']
            })
          );
        }
      );
  }

  ngOnDestroy() {
    this.spinner.stop();
    this.stop$.next();
    this.stop$.complete();
  }
}

/* eslint-disable ngrx/prefer-action-creator */
/* eslint-disable @typescript-eslint/no-shadow */
import { Policy, RiskModel } from '@amfam/policy/models';
import {
  ApiStatus,
  BillAccount,
  DecoratedPreference,
  DeletePreferencesActionPayload,
  DeletePreferencesStoreModel,
  OldUpdatePreferencesRequest,
  OldUpdatePreferencesResponse,
  PopulatePreferenceModel,
  RetrievePreferencePayload,
  SilentRegistrationPayload,
  UnassociatedRiskDetailFail,
  UnassociatedRiskDetailModel,
  UpdateAmdModel,
  UpdatePreferencesRequest,
  UpdatePreferencesStoreModel,
  UpdateRegistrationRequest,
  UpdateRegistrationStoreModel
} from '@amfam/shared/models';
import { Action } from '@ngrx/store';
import { Dictionary } from 'lodash';

export enum BillingActionTypes {
  BillAccountsLoad = '[Billing] BillAccounts Load',
  BillAccountsLoadSuccess = '[Billing] BillAccounts Load Success',
  BillAccountsLoadFail = '[Billing] BillAccounts Load Failure',
  BillAccountsLoadComplete = '[Billing] BillAccounts Load Complete',

  BillAccountLoadDetail = '[Billing] BillAccount Load Detail',
  BillAccountLoadDetailSuccess = '[Billing] BillAccount Load Detail Success',
  BillAccountLoadDetailFail = '[Billing] BillAccount Load Detail Fail',

  BillAccountLoadPreference = '[Billing] BillAccount Load Preference',
  BillAccountLoadPreferenceSuccess = '[Billing] BillAccount Load Preference Success',
  BillAccountLoadPreferenceFail = '[Billing] BillAccount Load Preference Fail',

  BillAccountUpdatePreference = '[Billing] BillAccount Update Preference',
  BillAccountUpdatePreferenceSuccess = '[Billing] BillAccount Update Preference Success',
  BillAccountUpdatePreferenceFail = '[Billing] BillAccount Update Preference Fail',
  BillAccountUpdatePreferenceInit = '[Billing] BillAccount Update Preference Init',

  BillAccountUpdateBillingPreference = '[Billing] BillAccount Update Billing Preference',
  BillAccountUpdateBillingPreferenceSuccess = '[Billing] BillAccount Update Billing Preference Success',
  BillAccountUpdateBillingPreferenceFail = '[Billing] BillAccount Update Billing Preference Fail',
  BillAccountUpdateBillingPreferenceInit = '[Billing] BillAccount Update Billing Preference Init',

  BillAccountUpdateDeliveryPreference = '[Billing] BillAccount Update Delivery Preference',
  BillAccountUpdateDeliveryPreferenceSuccess = '[Billing] BillAccount Update Delivery Preference Success',
  BillAccountUpdateDeliveryPreferenceFail = '[Billing] BillAccount Update Delivery Preference Fail',

  BillAccountDeletePreference = '[Billing] BillAccount Delete Preference',
  BillAccountDeletePreferenceRetry = '[Billing] BillAccount Delete Preference Retry',
  BillAccountDeletePreferenceResetRetry = '[Billing] BillAccount Delete Preference Reset Retry',
  BillAccountDeletePreferenceSuccess = '[Billing] BillAccount Delete Preference Success',
  BillAccountDeletePreferenceFail = '[Billing] BillAccount Delete Preference Fail',

  BillAccountPopulatePreference = '[Billing] BillAccount Populate Preference',

  BillAccountLoadDocuments = '[Billing] BillAccount Load Documents',
  BillAccountLoadDocumentsSuccess = '[Billing] BillAccount Load Documents Success',
  BillAccountLoadDocumentsFail = '[Billing] BillAccount Load Documents Fail',

  BillAccountUpdateRegistrationSubmit = '[Billing] BillAccount Update Registration Submit',
  BillAccountUpdateRegistrationSuccess = '[Billing] BillAccount Update Registration Success',
  BillAccountUpdateRegistrationPartialFail = '[Billing] BillAccount Update Registration Partial Fail',
  BillAccountUpdateRegistrationFail = '[Billing] BillAccount Update Registration Fail',
  BillAccountUpdateRegistrationInit = '[Billing] BillAccount Update Registration Init',

  BillAccountUnregisterAssociated = '[Billing] BillAccount Unregister Associated',
  BillAccountUnregisterUnAssociated = '[Billing] BillAccount Unregister UnAssociated',
  BillAccountSilentRegistration = '[Billing] BillAccount Silent Registration',

  BillAccountTransformPolicyList = '[Billing] BillAccount Transform PolicyList',
  BillAccountTransformPolicyListSuccess = '[Billing] BillAccount Transform PolicyList Success',
  BillAccountTransformPolicyListFail = '[Billing] BillAccount Transform PolicyList Fail',

  BillAccountUpdateReadOnlyStatus = '[Billing] BillAccount Update Read Only Status',

  BillAccountLoadUnAssociatedRiskDetail = '[Billing] BillAccount Load Unassociated Detail',
  BillAccountLoadUnAssociatedRiskDetailSuccess = '[Billing] BillAccount Load Unassociated Detail Success',
  BillAccountLoadUnAssociatedRiskDetailFail = '[Billing] BillAccount Load Unassociated Detail Fail',

  BillAccountReloadMinimumDue = '[Billing] BillAccount Reload Minimum Due(s)',
  BillAccountReloadMinimumDueSuccess = '[Billing] BillAccount Reload Minimum Due(s) Success',

  BillAccountLoadFuturePayments = '[Billing] BillAccount Load Future Payments',
  BillAccountLoadFuturePaymentsSuccess = '[Billing] BillAccount Load Future Payments Success',
  BillAccountLoadFuturePaymentsFail = '[Billing] BillAccount Load Future Payments Fail',

  BillAccountSetCancelPath = '[Billing] BillAccount Set Cancel Path',

  BillAccountSetSelected = '[Billing] BillAccount Set Selected',
  BillAccountSetSelectedSuccess = '[Billing] BillAccount Set Selected Success',

  BillAccountFound = '[Billing Deeplink] BillAccount not found',
  DoesBillAccountHaveAutopay = '[Billing Deeplink] BillAccount does not have Autopay',
  DismissBanner = '[Billing Deeplink] Bill account does not have autopay dismiss banner'
}

export class BillAccountsLoad implements Action {
  readonly type = BillingActionTypes.BillAccountsLoad;
  constructor(public payload?: any) {}
}

export class BillAccountsLoadSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountsLoadSuccess;
  constructor(public payload: any) {}
}

export class BillAccountLoadFuturePayments implements Action {
  readonly type = BillingActionTypes.BillAccountLoadFuturePayments;
  constructor(public payload: BillAccount) {}
}

export class BillAccountLoadFuturePaymentsSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountLoadFuturePaymentsSuccess;
  constructor(public payload: BillAccount) {}
}
export class BillAccountLoadFuturePaymentsFail implements Action {
  readonly type = BillingActionTypes.BillAccountLoadFuturePaymentsFail;
  constructor(public payload?: { status: ApiStatus; billAccountNumber: string }) {}
}

export class BillAccountsLoadFail implements Action {
  readonly type = BillingActionTypes.BillAccountsLoadFail;
  constructor(public payload?: any) {}
}

export class BillAccountsLoadComplete implements Action {
  readonly type = BillingActionTypes.BillAccountsLoadComplete;
  constructor(public payload?: any) {}
}

export class BillAccountLoadDetail implements Action {
  readonly type = BillingActionTypes.BillAccountLoadDetail;
  constructor(public payload: any) {}
}

export class BillAccountLoadDetailSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountLoadDetailSuccess;
  constructor(public payload: BillAccount) {}
}

export class BillAccountLoadDetailFail implements Action {
  readonly type = BillingActionTypes.BillAccountLoadDetailFail;
  constructor(public payload: { status: ApiStatus; billAccountNumber: string }) {}
}

export class BillAccountLoadUnAssociatedRiskDetail implements Action {
  readonly type = BillingActionTypes.BillAccountLoadUnAssociatedRiskDetail;
  constructor(public payload: UnassociatedRiskDetailModel) {}
}

export class BillAccountLoadUnAssociatedRiskDetailSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailSuccess;
  constructor(public payload: UnassociatedRiskDetailModel) {}
}

export class BillAccountLoadUnAssociatedRiskDetailFail implements Action {
  readonly type = BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailFail;
  constructor(public payload: UnassociatedRiskDetailFail) {}
}

export class BillAccountLoadPreference implements Action {
  readonly type = BillingActionTypes.BillAccountLoadPreference;
  constructor(public payload: RetrievePreferencePayload) {}
}

export class BillAccountLoadPreferenceSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountLoadPreferenceSuccess;
  constructor(public payload: DecoratedPreference) {}
}

export class BillAccountLoadPreferenceFail implements Action {
  readonly type = BillingActionTypes.BillAccountLoadPreferenceFail;
  constructor(public payload?: any) {}
}

export class BillAccountLoadDocuments implements Action {
  readonly type = BillingActionTypes.BillAccountLoadDocuments;
  constructor(public payload: any) {}
}

export class BillAccountLoadDocumentsSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountLoadDocumentsSuccess;
  constructor(public payload: any) {}
}

export class BillAccountLoadDocumentsFail implements Action {
  readonly type = BillingActionTypes.BillAccountLoadDocumentsFail;
  constructor(public payload?: any) {}
}

export class BillAccountUnregisterAssociated implements Action {
  readonly type = BillingActionTypes.BillAccountUnregisterAssociated;
  constructor(public payload?: any) {}
}

export class BillAccountUnregisterUnAssociated implements Action {
  readonly type = BillingActionTypes.BillAccountUnregisterUnAssociated;
  constructor(public payload?: { bilLAccountNumber: string }) {}
}

export class BillAccountUpdatePreference implements Action {
  readonly type = BillingActionTypes.BillAccountUpdatePreference;
  constructor(public payload: OldUpdatePreferencesRequest) {}
}

export class BillAccountUpdatePreferenceSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountUpdatePreferenceSuccess;
  constructor(public payload: OldUpdatePreferencesResponse) {}
}

export class BillAccountUpdatePreferenceFail implements Action {
  readonly type = BillingActionTypes.BillAccountUpdatePreferenceFail;
  constructor(public payload: OldUpdatePreferencesResponse) {}
}

export class BillAccountUpdatePreferenceInit implements Action {
  readonly type = BillingActionTypes.BillAccountUpdatePreferenceInit;
  constructor(public payload: string) {}
}

export class BillAccountUpdateBillingPreference implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateBillingPreference;
  constructor(public payload: UpdatePreferencesRequest) {}
}

export class BillAccountUpdateBillingPreferenceSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateBillingPreferenceSuccess;
  constructor(public payload: UpdatePreferencesStoreModel) {}
}

export class BillAccountUpdateBillingPreferenceFail implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateBillingPreferenceFail;
  constructor(public payload: UpdatePreferencesStoreModel) {}
}

export class BillAccountUpdateBillingPreferenceInit implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateBillingPreferenceInit;
  constructor(public payload: string) {}
}

export class BillAccountUpdateDeliveryPreference implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateDeliveryPreference;
  constructor(public payload: UpdatePreferencesRequest) {}
}

export class BillAccountUpdateDeliveryPreferenceSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateDeliveryPreferenceSuccess;
  constructor(public payload: UpdatePreferencesStoreModel) {}
}

export class BillAccountUpdateDeliveryPreferenceFail implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateDeliveryPreferenceFail;
  constructor(public payload: UpdatePreferencesStoreModel) {}
}
export class BillAccountDeletePreference implements Action {
  readonly type = BillingActionTypes.BillAccountDeletePreference;
  constructor(public payload: DeletePreferencesActionPayload) {}
}

export class BillAccountDeletePreferenceRetry implements Action {
  readonly type = BillingActionTypes.BillAccountDeletePreferenceRetry;
  constructor(public payload: { billAccountNumber: string }) {}
}

export class BillAccountDeletePreferenceResetRetry implements Action {
  readonly type = BillingActionTypes.BillAccountDeletePreferenceResetRetry;
  constructor(public payload: object) {}
}

export class BillAccountDeletePreferenceSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountDeletePreferenceSuccess;
  constructor(public payload: DeletePreferencesStoreModel) {}
}

export class BillAccountDeletePreferenceFail implements Action {
  readonly type = BillingActionTypes.BillAccountDeletePreferenceFail;
  constructor(public payload: DeletePreferencesStoreModel) {}
}

export class BillAccountPopulatePreference implements Action {
  readonly type = BillingActionTypes.BillAccountPopulatePreference;
  constructor(public payload: PopulatePreferenceModel) {}
}

export class BillAccountUpdateRegistrationInit implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateRegistrationInit;
  constructor(public payload: string) {} // bill account number
}
export class BillAccountUpdateRegistrationSubmit implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateRegistrationSubmit;
  constructor(public payload: UpdateRegistrationRequest) {}
}
export class BillAccountUpdateRegistrationSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateRegistrationSuccess;
  constructor(public payload: UpdateRegistrationStoreModel) {}
}

export class BillAccountUpdateRegistrationPartialFail implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateRegistrationPartialFail;
  constructor(public payload: UpdateRegistrationStoreModel) {}
}

export class BillAccountUpdateRegistrationFail implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateRegistrationFail;
  constructor(public payload: UpdateRegistrationStoreModel) {}
}

export class BillAccountSilentRegistration implements Action {
  readonly type = BillingActionTypes.BillAccountSilentRegistration;
  constructor(public payload: SilentRegistrationPayload[]) {}
}

export class BillAccountTransformPolicyList implements Action {
  readonly type = BillingActionTypes.BillAccountTransformPolicyList;
  constructor(
    public payload: {
      policyList: RiskModel[];
      billAccount: BillAccount;
      policyEntities: Dictionary<Policy>;
    }
  ) {}
}

export class BillAccountTransformPolicyListSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountTransformPolicyListSuccess;
  constructor(public payload: { billAccount: BillAccount }) {}
}
export class BillAccountTransformPolicyListFail implements Action {
  readonly type = BillingActionTypes.BillAccountTransformPolicyListFail;
  constructor(public payload: any) {}
}
export class BillAccountUpdateReadOnlyStatus implements Action {
  readonly type = BillingActionTypes.BillAccountUpdateReadOnlyStatus;
  constructor(public payload: DecoratedPreference) {}
}

export class BillAccountReloadMinimumDue implements Action {
  readonly type = BillingActionTypes.BillAccountReloadMinimumDue;
  constructor(public payload: string[]) {}
}
export class BillAccountReloadMinimumDueSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountReloadMinimumDueSuccess;
  constructor(public payload: UpdateAmdModel) {}
}

export class BillAccountSetCancelPath implements Action {
  readonly type = BillingActionTypes.BillAccountSetCancelPath;
  constructor(public payload: string) {}
}

export class BillAccountSetSelected implements Action {
  readonly type = BillingActionTypes.BillAccountSetSelected;
  constructor(public payload: string) {}
}

export class BillAccountSetSelectedSuccess implements Action {
  readonly type = BillingActionTypes.BillAccountSetSelectedSuccess;
  constructor(public payload?: any) {}
}

export class BillAccountFound implements Action {
  readonly type = BillingActionTypes.BillAccountFound;
  constructor(public payload?: boolean) {}
}

export class DoesBillAccountHaveAutopay implements Action {
  readonly type = BillingActionTypes.DoesBillAccountHaveAutopay;
  constructor(public payload?: boolean) {}
}

export class DismissBanner implements Action {
  readonly type = BillingActionTypes.DismissBanner;
  constructor(public payload?: boolean) {}
}

export type BillingActions =
  | BillAccountLoadFuturePayments
  | BillAccountLoadFuturePaymentsSuccess
  | BillAccountLoadFuturePaymentsFail
  | BillAccountsLoad
  | BillAccountsLoadSuccess
  | BillAccountsLoadFail
  | BillAccountsLoadComplete
  | BillAccountLoadDetail
  | BillAccountLoadDetailSuccess
  | BillAccountLoadDetailFail
  | BillAccountLoadUnAssociatedRiskDetail
  | BillAccountLoadUnAssociatedRiskDetailSuccess
  | BillAccountLoadUnAssociatedRiskDetailFail
  | BillAccountLoadPreference
  | BillAccountLoadPreferenceSuccess
  | BillAccountLoadPreferenceFail
  | BillAccountLoadDocuments
  | BillAccountLoadDocumentsSuccess
  | BillAccountLoadDocumentsFail
  | BillAccountUnregisterAssociated
  | BillAccountUnregisterUnAssociated
  | BillAccountUpdatePreference
  | BillAccountUpdatePreferenceSuccess
  | BillAccountUpdatePreferenceFail
  | BillAccountUpdatePreferenceInit
  | BillAccountUpdateBillingPreference
  | BillAccountUpdateBillingPreferenceSuccess
  | BillAccountUpdateBillingPreferenceFail
  | BillAccountUpdateBillingPreferenceInit
  | BillAccountUpdateDeliveryPreference
  | BillAccountUpdateDeliveryPreferenceSuccess
  | BillAccountUpdateDeliveryPreferenceFail
  | BillAccountPopulatePreference
  | BillAccountUpdateRegistrationInit
  | BillAccountUpdateRegistrationSubmit
  | BillAccountUpdateRegistrationSuccess
  | BillAccountUpdateRegistrationPartialFail
  | BillAccountUpdateRegistrationFail
  | BillAccountTransformPolicyList
  | BillAccountTransformPolicyListSuccess
  | BillAccountTransformPolicyListFail
  | BillAccountDeletePreference
  | BillAccountDeletePreferenceResetRetry
  | BillAccountDeletePreferenceRetry
  | BillAccountDeletePreferenceSuccess
  | BillAccountDeletePreferenceFail
  | BillAccountSilentRegistration
  | BillAccountUpdateReadOnlyStatus
  | BillAccountReloadMinimumDue
  | BillAccountReloadMinimumDueSuccess
  | BillAccountSetCancelPath
  | BillAccountSetSelected
  | BillAccountSetSelectedSuccess
  | BillAccountFound
  | DoesBillAccountHaveAutopay
  | DismissBanner;

export const fromBillingActions = {
  BillAccountLoadFuturePayments,
  BillAccountLoadFuturePaymentsSuccess,
  BillAccountLoadFuturePaymentsFail,
  BillAccountsLoad,
  BillAccountsLoadSuccess,
  BillAccountsLoadFail,
  BillAccountsLoadComplete,
  BillAccountLoadDetail,
  BillAccountLoadDetailSuccess,
  BillAccountLoadDetailFail,
  BillAccountLoadUnAssociatedRiskDetail,
  BillAccountLoadUnAssociatedRiskDetailSuccess,
  BillAccountLoadUnAssociatedRiskDetailFail,
  BillAccountLoadPreference,
  BillAccountLoadPreferenceSuccess,
  BillAccountLoadPreferenceFail,
  BillAccountLoadDocuments,
  BillAccountLoadDocumentsSuccess,
  BillAccountLoadDocumentsFail,
  BillAccountUnregisterAssociated,
  BillAccountUnregisterUnAssociated,
  BillAccountUpdatePreference,
  BillAccountUpdatePreferenceSuccess,
  BillAccountUpdatePreferenceFail,
  BillAccountUpdatePreferenceInit,
  BillAccountUpdateBillingPreference,
  BillAccountUpdateBillingPreferenceSuccess,
  BillAccountUpdateBillingPreferenceFail,
  BillAccountUpdateBillingPreferenceInit,
  BillAccountUpdateDeliveryPreference,
  BillAccountUpdateDeliveryPreferenceSuccess,
  BillAccountUpdateDeliveryPreferenceFail,
  BillAccountPopulatePreference,
  BillAccountUpdateRegistrationInit,
  BillAccountUpdateRegistrationSubmit,
  BillAccountUpdateRegistrationSuccess,
  BillAccountUpdateRegistrationPartialFail,
  BillAccountUpdateRegistrationFail,
  BillAccountTransformPolicyList,
  BillAccountTransformPolicyListSuccess,
  BillAccountTransformPolicyListFail,
  BillAccountDeletePreference,
  BillAccountDeletePreferenceResetRetry,
  BillAccountDeletePreferenceRetry,
  BillAccountDeletePreferenceSuccess,
  BillAccountDeletePreferenceFail,
  BillAccountSilentRegistration,
  BillAccountUpdateReadOnlyStatus,
  BillAccountReloadMinimumDue,
  BillAccountReloadMinimumDueSuccess,
  BillAccountSetCancelPath,
  BillAccountSetSelected,
  BillAccountSetSelectedSuccess,
  BillAccountFound,
  DoesBillAccountHaveAutopay,
  DismissBanner
};

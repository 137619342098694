import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAutopayPredict from './+state/autopay-predict.reducer';
import { autopayPredictReducers } from './+state/autopay-predict.selectors';
import { AutopayPredictEffects } from './+state/autopay-predict.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAutopayPredict.AUTOPAY_FEATURE_KEY, autopayPredictReducers),
    EffectsModule.forFeature([AutopayPredictEffects])
  ]
})
export class BillingAutopayPredictDataAccessModule {}

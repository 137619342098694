import { Address, Policy } from '@amfam/policy/models';

export class KydConfirmationRisk {
  constructor(
    public description: string,
    public policy: Policy,
    public vin: string,
    public shippingAddress: Address
  ) {}
}

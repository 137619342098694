import { CreditCardExpirationStatusType, PaymentMethod } from '@amfam/billing/payment-method/util';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { get as _get } from 'lodash';
import * as PaymentMethodActions from './payment-method.actions';

export interface State extends EntityState<PaymentMethod> {
  loading: boolean;
  hasError: boolean;
  newPaymentAccountId: string;
  correlationId: string;
}

export const adapter: EntityAdapter<PaymentMethod> = createEntityAdapter<PaymentMethod>({
  selectId: (paymentMethod: PaymentMethod) => paymentMethod.paymentAccountId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  hasError: false,
  correlationId: '',
  newPaymentAccountId: ''
});

export const reducer = createReducer(
  initialState,

  // GET PAYMENT METHODS
  on(PaymentMethodActions.getPaymentMethods, (state, action) => ({
    ...state,
    loading: true,
    hasError: false
  })),
  on(PaymentMethodActions.getPaymentMethodsSuccess, (state, action) => {
    const paymentMethods: PaymentMethod[] = action.paymentMethods.map(paymentMethod =>
      Object.assign({}, paymentMethod, {
        expired:
          _get(paymentMethod, 'creditCard.expirationStatus') ===
          CreditCardExpirationStatusType.EXPIRED,
        expiring:
          _get(paymentMethod, 'creditCard.expirationStatus') ===
          CreditCardExpirationStatusType.EXPIRING_SOON
      })
    );
    return adapter.setAll(paymentMethods, {
      ...state,
      loading: false,
      correlationId: action.correlationId
    });
  }),
  on(PaymentMethodActions.getPaymentMethodsFailure, (state, action) => ({
    ...state,
    loading: false,
    hasError: true,
    correlationId: action.correlationId
  })),

  // ADD PAYMENT METHOD
  on(PaymentMethodActions.addPaymentMethod, (state, action) => ({
    ...state,
    loading: true,
    newPaymentAccountId: '',
    hasError: false
  })),
  on(PaymentMethodActions.addPaymentMethodSuccess, (state, action) => ({
    ...state,
    loading: false,
    newPaymentAccountId: action.response.paymentAccountId,
    correlationId: action.correlationId
  })),
  on(PaymentMethodActions.addPaymentMethodFailure, (state, action) => ({
    ...state,
    loading: false,
    hasError: true,
    correlationId: action.correlationId
  })),

  // EDIT PAYMENT METHOD
  on(PaymentMethodActions.editPaymentMethod, (state, action) => ({
    ...state,
    loading: true,
    newPaymentAccountId: '',
    hasError: false
  })),
  on(PaymentMethodActions.editPaymentMethodSuccess, (state, action) =>
    adapter.removeOne(action.oldPaymentAccountId, {
      ...state,
      loading: false,
      newPaymentAccountId: action.response.paymentAccountId,
      correlationId: action.correlationId
    })
  ),
  on(PaymentMethodActions.editPaymentMethodFailure, (state, action) => ({
    ...state,
    loading: false,
    hasError: true,
    correlationId: action.correlationId
  })),

  // UPDATE MODE OF AUTHORIZATION
  on(PaymentMethodActions.updateModeOfAuthorization, (state, action) => ({
    ...state,
    loading: true,
    hasError: false
  })),
  on(PaymentMethodActions.updateModeOfAuthorizationSuccess, (state, action) => ({
    ...state,
    loading: false,
    correlationId: action.correlationId
  })),
  on(PaymentMethodActions.updateModeOfAuthorizationFailure, (state, action) => ({
    ...state,
    loading: false,
    hasError: true,
    correlationId: action.correlationId
  })),

  // DELETE PAYMENT METHOD
  on(PaymentMethodActions.storePaymentAccountId, (state, action) => ({
    ...state,
    newPaymentAccountId: action.paymentAccountId
  })),
  on(PaymentMethodActions.deletePaymentMethod, (state, action) => ({
    ...state,
    loading: true,
    newPaymentAccountId: '',
    hasError: false
  })),
  on(PaymentMethodActions.deletePaymentMethodSuccess, (state, action) =>
    adapter.removeOne(action.paymentAccountId, {
      ...state,
      loading: false,
      correlationId: action.correlationId
    })
  ),
  on(PaymentMethodActions.deletePaymentMethodFailure, (state, action) => ({
    ...state,
    loading: false,
    hasError: true,
    correlationId: action.correlationId
  })),

  // RESET ERROR STATE
  on(PaymentMethodActions.resetErrorState, (state, action) => ({
    ...state,
    hasError: false
  })),

  on(PaymentMethodActions.resetpaymentAccountId, (state, action) => ({
    ...state,
    newPaymentAccountId: ''
  }))
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectPaymentMethodIds = selectIds;
export const selectPaymentMethodEntities = selectEntities;
export const selectAllPaymentMethods = selectAll;
export const selectPaymentMethodsTotal = selectTotal;

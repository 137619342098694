<div class="clicktip-container" [ngClass]="{ 'display-clicktip': display }" aria-live="polite">
  <div *ngIf="display" role="tooltip" [id]="label | titleCaseDashesNoSpaces">
    <div class="row margin-bottom-20">
      <div class="pad">
        <div *ngIf="title" class="flex row">
          <span class="clicktip-title">{{ title }}</span>
        </div>
        <div class="row">
          <span class="clicktip-text" [innerHTML]="text"></span>
        </div>
      </div>
    </div>
  </div>
</div>
export interface SharedCopy {
  amfamCompanyName: string;
  amfamTelNumber: string;
  amfamPhoneNumber: string;
  amfamPhoneVanityNumber: string;
  amfamPhoneNumericNumber: string;
  accountNumber: string;
  appHeading: string;
  mobileAppBannerHeading: string;
  mobileAppBannerText: string;
  mobileAppBannerLink: string;
  submitBtn: string;
  nextBtn: string;
  backBtn: string;
  primary: string;
  enrolledEmail: string;
  deleteBtn: string;
  saveBtn: string;
  saveAndContinueBtn: string;
  confirmBtn: string;
  editBtn: string;
  addBtn: string;
  removeBtn: string;
  makePrimaryText: string;
  resendEmail: string;
  changeEmail: string;
  resendEmailBanner: string;
  makePrimaryEmailText: string;
  makePrimaryPhoneText: string;
  changeBtn: string;
  confirmAnsBtn: string;
  forgotAccountText: string;
  logInText: string;
  logInTextForLinks: string;
  forgotLogInText: string;
  createAccountText: string;
  needAccountText: string;
  createAccountNeedInfoText: string;
  signUpText: string;
  findPoliciesBtn: string;
  needHelpText: string;
  contactUsText: string;
  sendEmailText: string;
  remove: string;
  removeSelection: string;
  removeThisBill: string;
  startLiveChatText: string;
  find: string;
  retry: string;
  cancel: string;
  cancelSignUp: string;
  goHere: string;
  printScreen: string;
  toasterSuccessTitle: string;
  toasterErrorTitle: string;
  myAccountTxt: string;
  noOpportunities: string;
  usStates: UsStateCopy[];
  months: MonthCopy[];
  inputFieldLabelText: InputFieldLabelTextCopy;
  yes: string;
  no: string;
  contactNumber: string;
  callUs: string;
  notifyAgent: string;
  opportunityTitle: string;
  removeThisAccount: string;
  accept: string;
}

interface InputFieldLabelTextCopy {
  firstName: string;
  lastName: string;
  businessName: string;
  dateOfBirth: string;
  zipCode: string;
  email: string;
  emailAddress: string;
  phoneNumber: string;
  areaCode: string;
  otherTypeDescription: string;
  userId: string;
  password: string;
  securityQuestion1: string;
  securityQuestion2: string;
  securityAnswer1: string;
  securityAnswer2: string;
  yourSSN: string;
  middleInitial: string;
  middleInitialShort: string;
  streetAddress: string;
  apartmentSuiteOptional: string;
  city: string;
  state: string;
}

interface MonthCopy {
  name: string;
  abbr: string;
  value: string;
}

interface UsStateCopy {
  name: string;
  value: string;
  territory?: boolean;
}

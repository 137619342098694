// eslint-disable-next-line
import { ApiStatus, UNAVAILABLE_PAYMENT_ID } from '@amfam/shared/models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get as _get } from 'lodash';
import { ScheduledPaymentsActionTypes, ScheduledPaymentsActions } from './schedulepayment.actions';

export interface ScheduledPaymentNotificationState {
  paymentId: string;
  error?: ApiStatus | null;
  success?: ApiStatus | null;
  loading: boolean;
  correlationId?: string | null;
}

export interface NotificationState extends EntityState<ScheduledPaymentNotificationState> {
  loading: boolean;
  error: ApiStatus | null;
}

export const adapter: EntityAdapter<ScheduledPaymentNotificationState> =
  createEntityAdapter<ScheduledPaymentNotificationState>({
    selectId: (scheduledPaymentNotificationState: ScheduledPaymentNotificationState) =>
      scheduledPaymentNotificationState.paymentId,
    sortComparer: false
  });

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: ScheduledPaymentsActions
): NotificationState {
  switch (action.type) {
    case ScheduledPaymentsActionTypes.ScheduledPaymentsLoad: {
      return { ...state, loading: true };
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsLoadFail:
    case ScheduledPaymentsActionTypes.ScheduledPaymentsLoadSuccess: {
      return { ...state, loading: false };
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsSubmit:
    case ScheduledPaymentsActionTypes.ScheduledPaymentsEdit:
    case ScheduledPaymentsActionTypes.ScheduledPaymentsDelete: {
      return adapter.upsertOne(
        {
          paymentId: _get(action, 'payload.paymentId', UNAVAILABLE_PAYMENT_ID),
          error: null,
          correlationId: null,
          loading: true
        },
        state
      );
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitFail:
    case ScheduledPaymentsActionTypes.ScheduledPaymentsEditFail:
    case ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteFail: {
      return adapter.upsertOne(
        {
          paymentId: _get(action, 'payload.request.paymentId', UNAVAILABLE_PAYMENT_ID),
          error: action.payload.status,
          loading: false,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitSuccess: {
      adapter.removeOne(UNAVAILABLE_PAYMENT_ID, state);
      return adapter.upsertOne(
        {
          paymentId: _get(action, 'payload.response.paymentId'),
          success: action.payload.status,
          error: null,
          loading: false,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsEditSuccess:
    case ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteSuccess: {
      return adapter.upsertOne(
        {
          paymentId: _get(action, 'payload.request.paymentId'),
          error: null,
          loading: false,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsCleanup: {
      return adapter.upsertOne(
        {
          paymentId: _get(action, 'payload'),
          success: null,
          error: null,
          loading: false,
          correlationId: null
        },
        state
      );
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsTruncate: {
      return adapter.removeOne(action.payload, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { FeatureFlag } from '../models/feature-flag.model';
import * as FeatureFlagActions from './feature-flag.actions';

/**
 * @ngrx/entity provides a predefined interface for handling
 * a structured dictionary of records. This interface
 * includes an array of ids, and a dictionary of the provided
 * model type by id. This interface can be extended to include
 * any additional interface properties.
 */
export type FeatureFlagsState = EntityState<FeatureFlag>;

/**
 * createEntityAdapter creates an object of many helper
 * functions for single or multiple operations
 * against the dictionary of records. The configuration
 * object takes a record id selector function and
 * a sortComparer option which is set to a compare
 * function if the records are to be sorted.
 */
export const adapter: EntityAdapter<FeatureFlag> = createEntityAdapter<FeatureFlag>({
  selectId: (featureFlag: FeatureFlag) => featureFlag.id,
  sortComparer: false
});

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: FeatureFlagsState = adapter.getInitialState();

const featureFlagReducer = createReducer(
  initialState,

  on(FeatureFlagActions.loadAllFeatureFlags, (state, request) =>
    adapter.addMany(request.payload, state)
  )
);

export function reducer(state: FeatureFlagsState, action: Action) {
  return featureFlagReducer(state, action);
}

import { from as observableFrom, of as observableOf, Observable } from 'rxjs';
import { tap, first, map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PolicyActions } from '@amfam/policy/data-access';
import {
  ESignatureActions,
  SignESignature,
  SignESignatureSuccess,
  SignESignatureComplete
} from './e-signature.actions';

import {
  GetESignature,
  GetESignatureSuccess,
  GetESignatureFail,
  GetESignatureComplete,
  ESignatureActionTypes
} from './e-signature.actions';
import { ESignatureService } from '../services/e-signature.service';


@Injectable()
export class ESignatureEffects {
  
  loading$ = createEffect(() => this.actions$.pipe(
    ofType(PolicyActions.PoliciesActionTypes.PoliciesLoadSuccess),
    map((action: PolicyActions.PoliciesLoadSuccess) => action.payload),
    switchMap(payload => observableOf(new GetESignature(payload)))
  ));

  
  getESignature$: Observable<ESignatureActions> = createEffect(() => this.actions$.pipe(
    ofType<GetESignature>(ESignatureActionTypes.GetESignature),
    map(action => action.payload),
    switchMap(() =>   this.eSignatureService.requiresSignature()),
    mergeMap((response: any) => {
      return observableFrom([new GetESignatureComplete(), new GetESignatureSuccess(response)]);
    }),
    catchError(error => observableOf(new GetESignatureFail(error)))
  ));

  
  sign$ = createEffect(() => this.actions$.pipe(
    ofType<SignESignature>(ESignatureActionTypes.SignESignature),
    map(action => action.payload),
    switchMap(payload => this.eSignatureService.requestSignature(payload)),
    map((response: string) => new SignESignatureSuccess(response))
  ));

  
  signed$ = createEffect(() => this.actions$.pipe(
    ofType<SignESignatureSuccess>(ESignatureActionTypes.SignESignatureSuccess),
    map(action => action.payload),
    first(),
    tap(payload => (location.href = payload)),
    map(payload => new SignESignatureComplete(payload))
  ));

  constructor(private eSignatureService: ESignatureService, private actions$: Actions) {}
}

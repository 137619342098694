export interface ThirdPartyFnolCopy {
  enterPolicyHeading: string;
  enterPolicySubtext: string;
  enterVehicleInfoHeading: string;
  enterVehicleInfoSubtext: string;
  cantFindPolicyHeading: string;
  cantFindPolicySubtext: string;
  unknownRiskCheckbox: string;
  existingClaimHeading: string;
  existingClaimNextSteps: string;
  autoClaimConfirmationNextSteps: string;
  autoClaimConfirmationTimeoutNextSteps: string;
  cancelModalHeading: string;
  cancelModalText: string;
}

import { Directive, AfterViewInit, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[dsTabFocus]'
})
export class TabFocusDirective implements AfterViewInit {
  private el: HTMLElement;

  @HostListener('keyup.tab')
  @HostListener('keyup.shift.tab')
  onTab() {
    this.el.classList.remove('focus-off');
    this.el.classList.add('focus-on');
  }

  @HostListener('blur')
  onBlur() {
    this.el.classList.remove('focus-on');
    this.el.classList.add('focus-off');
  }

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngAfterViewInit() {
    if (this.el) {
      this.el.setAttribute('tabindex', '0');
      this.el.classList.add('focus-off');
    }
  }
}

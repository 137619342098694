import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// application modules
import { SharedModule } from '../shared/shared.module';

// components/directives/pipes
import { AddSecurityQuestionsComponent } from './add-security-questions/add-security-questions.component';
import { CookieCheckComponent } from './cookie-check/cookie-check.component';
import { AcceptUseridComponent } from './forgot-password/accept-userid';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { InvalidTokenComponent } from './forgot-password/invalid-token';
import { ResetOptionsComponent } from './forgot-password/reset-options';
import { VerifyTokenComponent } from './forgot-password/verify-token';
import { ForgotUseridComponent } from './forgot-userid';
import { LoginComponent } from './login';
import { LoginFormComponent } from './login-form';
import { ResetPasswordComponent } from './reset-password';
import { SessionExpiredComponent } from './session-expired/session-expired.component';

// providers
import { ProfileFeatureModule } from '@amfam/profile/feature';
import { ProfileModule } from '../profile/profile.module';
import { AppBannerComponent } from './app-banner/app-banner.component';
import { EmailValidationLoginComponent } from './email-validation/email-validation-login/email-validation-login.component';
import { EmailValidationVerifyPhoneComponent } from './email-validation/email-validation-verify-phone/email-validation-verify-phone.component';
import { EmailValidationComponent } from './email-validation/email-validation.component';
import { ResetPasswordCallComponent } from './forgot-password/reset-options/reset-password-call/reset-password-call.component';
import { ResetPasswordCodeEntryComponent } from './forgot-password/reset-options/reset-password-code-entry/reset-password-code-entry.component';
import { ResetPasswordEmailComponent } from './forgot-password/reset-options/reset-password-email/reset-password-email.component';
import { ResetPasswordOptionsComponent } from './forgot-password/reset-options/reset-password-options/reset-password-options.component';
import { ResetPasswordSecurityQuestionsComponent } from './forgot-password/reset-options/reset-password-security-questions/reset-password-security-questions.component';
import { ResetPasswordTextComponent } from './forgot-password/reset-options/reset-password-text/reset-password-text.component';
import { LoginBaseComponent } from './login-base/login-base.component';
import { authProviders } from './login-routing.module';
import { SessionActivityService } from './session-activity/session-activity.service';
import { ForgotService } from './shared/forgot.service';

@NgModule({
  imports: [RouterModule, SharedModule, ProfileModule, ProfileFeatureModule],
  declarations: [
    AcceptUseridComponent,
    CookieCheckComponent,
    ForgotPasswordComponent,
    ForgotUseridComponent,
    InvalidTokenComponent,
    LoginComponent,
    LoginBaseComponent,
    LoginFormComponent,
    ResetOptionsComponent,
    ResetPasswordComponent,
    SessionExpiredComponent,
    VerifyTokenComponent,
    AddSecurityQuestionsComponent,
    ResetPasswordEmailComponent,
    ResetPasswordTextComponent,
    ResetPasswordOptionsComponent,
    ResetPasswordCallComponent,
    ResetPasswordSecurityQuestionsComponent,
    ResetPasswordCodeEntryComponent,
    EmailValidationLoginComponent,
    EmailValidationVerifyPhoneComponent,
    EmailValidationComponent,
    AppBannerComponent
  ],
  exports: [
    AcceptUseridComponent,
    ForgotPasswordComponent,
    ForgotUseridComponent,
    InvalidTokenComponent,
    LoginComponent,
    LoginBaseComponent,
    LoginFormComponent,
    ResetOptionsComponent,
    ResetPasswordComponent,
    SessionExpiredComponent,
    VerifyTokenComponent
  ],
  providers: [authProviders, SessionActivityService, ForgotService]
})
export class LoginModule {}

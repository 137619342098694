//TODO: This will move to claims folder once Post-FNOL is merged
import { PageAnalytic, EventAnalytic, ButtonAnalytic } from '../analytics.model';

export class EnrollmentAnalytics {
  public static readonly pageSecurityQuestionsMyAccount: PageAnalytic = {
    pageName: `MyAccount:Enroll:SecurityQuestions`,
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Enroll',
    subCategory2: 'Personal',
    subCategory3: 'Security Questions'
  };

  public static readonly pageEnrollmentCompleteMyAccount: PageAnalytic = {
    pageName: 'MyAccount:Enroll:Customer:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Enroll',
    subCategory2: 'Personal',
    subCategory3: ''
  };

  public static readonly eventEnrollmentCompleteMyAccount: EventAnalytic = {
    eventName: 'enrollment',
    eventStep: 'complete'
  };

  public static readonly pageEnrollmentCompleteFirstPartyFnol: PageAnalytic = {
    pageName: 'FirstPartyFnol:Enroll:Customer:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Enroll',
    subCategory2: 'Personal',
    subCategory3: ''
  };

  public static readonly eventEnrollmentCompleteFirstPartyFnol: EventAnalytic = {
    eventName: 'enrollment',
    eventStep: 'complete'
  };

}

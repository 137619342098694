import { ApiStatus } from '@amfam/shared/models';

export class ANSRequest {
  notificationsRequest: {
    notificationText: string;
    notificationType: string;
    customerName: string;
    referenceNumber: string;
    formattedReferenceNumber: string;
    policyNumber: string;
    from: string;
    dueDate: string;
    createdBy: string;
    uniqueId: string;
    sourceSystem: string;
  };
  producerId: string;
}

export class ANSRequestActionPayload {
  agentId: string;
  correlationId: string;
  recommendationId: string;
  type?:string;
}

export class ANSFalure {
  status: ApiStatus;
  correlationId?: string;
  type?:string;
}

export class ANSSuccess {
  status: ApiStatus;
  deliveryStatus: {
    statusMessage: string;
  };
  correlationId?: string;
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-reset-options',
  templateUrl: './reset-options.component.html',
  styleUrls: ['../forgot-password.component.scss']
})
export class ResetOptionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

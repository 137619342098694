import { createAction, props } from '@ngrx/store';
import { PaymentMethodSetup } from './payment-method-feature.models';

export const storeDeletePaymentMethodInfo = createAction(
  '[PaymentMethodSetup] Store Delete Info',
  props<{
    paymentMethodInfo: PaymentMethodSetup;
  }>()
);

export const setPaymentMethodOperation = createAction(
  '[PaymentMethodSetup] Set Operation',
  props<{
    operation: 'add' | 'edit' | 'delete-success' | 'delete-error';
  }>()
);

export const resetPaymentMethodState = createAction(
  '[PaymentMethodSetup] Reset Payment Method State'
);

import { Policy } from '@amfam/policy/models';
import { Agent } from '@amfam/shared/utility/agent';

export class ReportClaimInternalEmail {
  constructor(
    public policy: Policy,
    public producer: Agent,
    public email: string,
    public phone: string,
    public dateOfIncident: string,
    public timeOfIncident: string,
    public locationOfIncident: string,
    public descriptionOfIncident: string
  ) {}
}

import { Vehicle } from './vehicle';

export class ATV extends Vehicle {
  public engineDisplacement: string;

  static fromJson(json: any): ATV {
    const atv = new ATV();
    atv.assignProperties(json);

    return atv;
  }

  protected assignProperties(json: any) {
    super.assignProperties(json);

    this.engineDisplacement = json.engineDisplacement;
  }
}

import { Action } from '@ngrx/store';
import { ReplaceVehiclePolicy } from './replace-vehicle-policy.model';

export const REPLACE_VEHICLE_POLICY_SELECTION = '[ReplaceVehiclePolicy] Selection';

export const REPLACE_VEHICLE_POLICY_SELECTION_CLEAR = '[ReplaceVehiclePolicy] Selection Clear';

export class ReplaceVehiclePolicySelectAction implements Action {
  readonly type = REPLACE_VEHICLE_POLICY_SELECTION;
  constructor(public payload?: ReplaceVehiclePolicy) {}
}

export class ReplaceVehiclePolicySelectClearAction implements Action {
  readonly type = REPLACE_VEHICLE_POLICY_SELECTION_CLEAR;
  constructor() {}
}

export type Actions = ReplaceVehiclePolicySelectAction | ReplaceVehiclePolicySelectClearAction;

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { get as _get } from 'lodash';

import { ConfigService } from '@amfam/shared/utility/shared-services';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { UtilService } from '@amfam/shared/utility/shared-services';
import { ValidateTokenRequest } from '../models/email-validation.model';
import { EmailValidationResponse } from '../models/profile.model';
import { userQuery } from '@amfam/shared/user';

@Injectable()
export class DigitalServiceProgramService {
  private cdhid: string;
  private endpoint: string;
  private waid: string;
  private partnerId: string;
  private experienceId: string;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private store: Store<any>,
    private util: UtilService
  ) {
    this.store.select(userQuery.getUserState).subscribe(user => {
      this.cdhid = _get(user, 'customerId', '');
      this.waid = _get(user, 'waid', '');
    });
    this.store.select(BrandSelectors.getBrandState).subscribe(brand => {
      this.partnerId = _get(brand, 'partnerId', 'AFI');
      this.experienceId = _get(brand, 'experienceId', '');
    });
  }

  /**
   * Will set an email as having been validated in the db.
   * @param token - Retrieved from the confirmation email link when the person clicks on the link.
   */
  confirm(requestObj: ValidateTokenRequest) {
    const url = this.config.get('digitalServiceProgramApi') + '/emails/confirm';

    return this.http.post(url, JSON.stringify(requestObj));
  }

  // check if an email address has already been validated
  getValidationStatus(emailAddress: string): Observable<EmailValidationResponse> {
    const url = this.config.get('digitalServiceProgramApi') + '/emails/status';
    const data = {
      emailAddress,
      waid: this.waid
    };
    return this.http.post<EmailValidationResponse>(url, JSON.stringify(data));
  }

  /**
   * Sends an email to the user asking them to confirm the new email address
   * @param email - Email address whose validation status is to be retrieved.
   */
  validate(emailAddress: string): Observable<EmailValidationResponse> {
    const url = this.config.get('digitalServiceProgramApi') + '/emails/validate';
    const data = this.util.pruneEmpty({
      emailAddress,
      customerId: Number(this.cdhid),
      waid: this.waid,
      partnerId: this.partnerId,
      experienceId: this.experienceId
    });
    return this.http.post<EmailValidationResponse>(url, data);
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRouter from './router-reducer';
import { NAVIGATION_FEATURE_KEY } from './router-reducer';
import { RouterState } from './router-model';
import { get as _get } from 'lodash';

export const getRouterState = createFeatureSelector<RouterState>(NAVIGATION_FEATURE_KEY);
export const getRouterCurrentState = createSelector(
  getRouterState,
  fromRouter.getCurrentRouterState
);
export const getRouterCurrentStateUrl = createSelector(getRouterCurrentState, state =>
  _get(state, 'url')
);
export const getRouterPreviousState = createSelector(getRouterState, fromRouter.getPreviousState);
export const getRouterPreviousStateUrl = createSelector(getRouterPreviousState, state =>
  _get(state, 'url')
);
export const getRouterNavigationId = createSelector(getRouterState, fromRouter.getNavigationId);

export const routerQuery = {
  getRouterState,
  getRouterCurrentState,
  getRouterPreviousState,
  getRouterPreviousStateUrl,
  getRouterNavigationId,
  getRouterCurrentStateUrl
};

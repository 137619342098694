import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-confirmation-link-area',
  templateUrl: './ds-confirmation-link-area.component.html',
  styleUrls: ['./ds-confirmation-link-area.component.scss']
})
export class DsConfirmationLinkAreaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

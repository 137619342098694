import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  contactNumberLink: string;

  constructor(private config: ConfigService) {
    this.contactNumberLink = this.config.get('numberLink');
  }

  ngOnInit() {}

  openContactUs() {
    window.open(this.contactNumberLink);
  }
}

import { createFeatureSelector, createSelector, ActionReducerMap } from '@ngrx/store';
import * as fromPaymentConfirmationState from './paymentConfirmation.reducer';
import * as fromPaymentConfirmationNotifications from './notifications.reducer';
import { get as _get, flatten as _flatten, some as _some } from 'lodash';
import { PaymentConfirmationModel } from '@amfam/shared/models';

export const PAYMENT_CONFIRMATION_FEATURE_KEY = 'payment confirmation';

export interface PaymentConfirmationState {
  paymentConfirmationState: fromPaymentConfirmationState.PaymentConfirmtionEntityState;
  paymentConfirmationNotifications: fromPaymentConfirmationNotifications.NotificationState;
}

export const paymentConfirmationReducers: ActionReducerMap<PaymentConfirmationState> = {
  paymentConfirmationState: fromPaymentConfirmationState.reducer,
  paymentConfirmationNotifications: fromPaymentConfirmationNotifications.reducer
};

export const getPaymentConfirmationState = createFeatureSelector<PaymentConfirmationState>(
  PAYMENT_CONFIRMATION_FEATURE_KEY
);
export const getPaymentConfirmationLoading = createSelector(
  getPaymentConfirmationState,
  (state: PaymentConfirmationState) => _get(state, 'paymentConfirmationNotifications.loading', true)
);

export const getPaymentConfirmationLoaded = createSelector(
  getPaymentConfirmationState,
  (state: PaymentConfirmationState) =>
    !_get(state, 'paymentConfirmationNotifications.loading', true)
);

export const getPaymentConfirmationDataState = createSelector(
  getPaymentConfirmationState,
  getPaymentConfirmationLoaded,
  (state, isLoaded) => {
    const paymentConfirmationStateArr = isLoaded
      ? fromPaymentConfirmationState.selectAll(state.paymentConfirmationState)
      : [];
    return <PaymentConfirmationModel>(
      (_get(paymentConfirmationStateArr, 'length', 0) > 0 ? paymentConfirmationStateArr[0] : [])
    );
  }
);

export interface KydEligibilityResponse {
  stateEligible?: boolean;
  customerEligible?: boolean;
  policyEligible?: boolean;
  vehicleEligible?: boolean;
  reasonCode?: string;
  reason?: string;
  programCategory: string;
  vinNumber?: string;
  policyNumber?: string;
  phhEligible?: boolean;
}

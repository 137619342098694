<div *ngIf="hasRegistrationError$ | async" class="margin-bottom-20">
  <ds-warning-box
    [headingText]="registrationErrorHeadingText"
    [messageText]="registrationErrorBodyText"
  >
  </ds-warning-box>
</div>

<h1 data-cy="autoPayTitleContext">{{ title$ | async }}</h1>
<div class="margin-bottom-30">
  <ds-auto-pay-setup-bill-account-tile
    [billAccountTile]="billAccountTile$ | async"
  ></ds-auto-pay-setup-bill-account-tile>
</div>

<form *ngIf="autoPayForm && paymentMethodOptions" [formGroup]="autoPayForm">
  <ds-column-layout [numberOfColumns]="'three'">
    <div column-left>
      <ds-setup-payment-method
        [formGroup]="autoPayForm"
        [paymentMethodOptions]="paymentMethodOptions"
        [displayDiscountWarning]="displayDiscountWarning$ | async"
        [displayPaymentExpiredError]="displayPaymentExpiredError$ | async"
        [displayAutoPayDiscountMessage]="(hasClassicPolicy$ | async) === false"
        [authorizedToAddPaymentMethod]="authorizedToAddPaymentMethod$ | async"
        [authorizedToEditPaymentMethod]="authorizedToEditPaymentMethod$ | async"
        (editPaymentMethodEvent)="editPaymentMethod()"
        (addCreditDebitEvent)="addCreditDebit()"
        (addCheckingAccountEvent)="addCheckingAccount()"
        (addSavingsAccountEvent)="addSavingsAccount()"
      ></ds-setup-payment-method>
    </div>
    <div column-center>
      <ds-auto-pay-setup-payment-frequency
        [formGroup]="autoPayForm"
        [paymentFrequencyOptions]="paymentFrequencyOptions"
        [displayAllAtOnceWarning]="isFullPay$ | async"
      ></ds-auto-pay-setup-payment-frequency>
    </div>
    <div column-right>
      <ds-auto-pay-setup-payment-date
        [formGroup]="autoPayForm"
        [paymentDateOptions]="paymentDateOptions"
        [paymentDueDate]="(selectedBillAccount$ | async)?.billingStatement?.currentAmtDueDate"
      ></ds-auto-pay-setup-payment-date>
      <ds-auto-pay-setup-payment-preview
        *ngIf="!!(paymentPreview$ | async) && (paymentPreviewLoading$ | async) === false"
        [paymentPreview]="paymentPreview$ | async"
        [isFullPay]="isFullPay$ | async"
      ></ds-auto-pay-setup-payment-preview>
      <ds-localized-spinner
        *ngIf="paymentPreviewLoading$ | async"
        class="block pad-top-10"
        [dataLoading]="paymentPreviewLoading$ | async"
        [loadingMessage]="'Loading'"
      >
      </ds-localized-spinner>
    </div>
  </ds-column-layout>
</form>
<div *ngIf="adminEnrollmentFlow === false">
  <call-to-action
    [displayBackButton]="(isEditingSetup$ | async) === false && (isAddMultiple$ | async)"
    [tertiaryButtonName]="tertiaryButtonName"
    [disabled]="
      (displayPaymentExpiredError$ | async) ||
      autoPayForm?.invalid ||
      (paymentPreviewLoading$ | async) ||
      (isEditingAndNotChanged$ | async)
    "
    [primaryButtonName]="primaryButtonText$ | async"
    (primaryEvent)="saveAutoPaySettings()"
    (tertiaryEvent)="cancel()"
    (backEvent)="back()"
  ></call-to-action>
</div>
<div *ngIf="adminEnrollmentFlow">
  <call-to-action
    [displayBackButton]="(isEditingSetup$ | async) === false && (isAddMultiple$ | async)"
    [tertiaryButtonName]="'Skip AutoPay Setup'"
    [disabled]="
      (displayPaymentExpiredError$ | async) ||
      autoPayForm?.invalid ||
      (paymentPreviewLoading$ | async) ||
      (isEditingAndNotChanged$ | async)
    "
    [primaryButtonName]="primaryButtonText$ | async"
    (primaryEvent)="saveAutoPaySettings()"
    (tertiaryEvent)="skipAutopay()"
    (backEvent)="back()"
  ></call-to-action>
</div>
<!--Payment Method Modal-->
<ds-payment-method-modal
  [paymentMethodAddEditConfig]="paymentMethodAddEditConfig$ | async"
  [displayDiscountWarning]="hasAutoPayDiscount$ | async"
  (paymentMethodAddEditCompleteEvent)="paymentMethodAddEditComplete($event)"
>
</ds-payment-method-modal>

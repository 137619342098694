import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { userQuery } from '@amfam/shared/user';


@Component({
  selector: 'profile-card-business',
  templateUrl: './profile-card-business.component.html',
  styleUrls: ['./profile-card-business.component.scss']
})
export class ProfileCardBusinessComponent implements OnInit, OnDestroy {
  public emailAddress: string;
  public businessName: string;
  public userID: string;
  public firstName: string;
  private storeSub: Subscription;

  @Input() isShellAccount: boolean;
  @Input() canShowStatus: boolean;

  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.storeSub = combineLatest(
      this.store.select(userQuery.getEmailAddress),
      this.store.select(userQuery.getBusinessName),
      this.store.select(userQuery.getLoginName),
      this.store.select(userQuery.getFirstName),
      (emailAddress, businessName, userID, firstName) => {
        return {
          emailAddress: emailAddress,
          businessName: businessName,
          userID: userID,
          firstName: firstName
        };
      }
    ).subscribe(state => {
      this.emailAddress = state.emailAddress;
      this.businessName = state.businessName;
      this.userID = state.userID;
      this.firstName = state.firstName;
    });
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}

<div id="verify-options" *ngIf="party && party.match >= 1">
  <p class="margin-bottom-20">
    <span content [contentPath]="'enrollment.verificationUserFoundText'"></span>
  </p>
  <div class="row verification-list">
    <div class="column twelve">
      <ul data-cy="verificationMethodList" class="margin-bottom-10">
        <li *ngIf="showSSN()" (click)="completeStep('ssn')">
          <a data-cy="enterSSNEnrollVerifyLink" href="javascript:void(0)"
            >Enter Last 4 Digits of SSN<span
              class="margin-top-5 float-right icon-chevron-right"
            ></span
          ></a>
        </li>
        <hr *ngIf="showEmail() && showSSN()" aria-hidden="true" class="dash" />
        <li *ngIf="showEmail()" (click)="completeStep('email')">
          <a data-cy="emailMeVerifyLink" href="javascript:void(0)"
            >Email Me a Link<span class="margin-top-5 float-right icon-chevron-right"></span
          ></a>
        </li>

        <hr *ngIf="showText() && (showEmail() || showSSN())" aria-hidden="true" class="dash" />
        <li *ngIf="showText()" (click)="completeStep('text')">
          <a data-cy="textMeCodeEnrollVerifyLink" href="javascript:void(0)"
            >Text Me a Code<span class="margin-top-5 float-right icon-chevron-right"></span
          ></a>
        </li>
        <hr
          *ngIf="showPhone() && (showText() || showEmail() || showSSN())"
          aria-hidden="true"
          class="dash"
        />
        <li *ngIf="showPhone()" (click)="completeStep('phone')">
          <a data-cy="callMeWithCodeEnrollVerifyLink" href="javascript:void(0)"
            >Call Me with a Code<span class="margin-top-5 float-right icon-chevron-right"></span
          ></a>
        </li>
        <hr
          *ngIf="showPolicy() && (showPhone() || showText() || showEmail() || showSSN())"
          aria-hidden="true"
          class="dash"
        />
        <li *ngIf="showPolicy()" (click)="completeStep('policy-number')">
          <a data-cy="enterPolicyNumberEnrollVerifyLink" href="javascript:void(0)"
            >Enter My Policy Number<span class="margin-top-5 float-right icon-chevron-right"></span
          ></a>
        </li>
      </ul>
    </div>
  </div>
  <hr aria-hidden="true" />
  <div class="row">
    <div id="back" class="caption margin-top-10">
      <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block"
        ><span class="icon-chevron-left"></span> Back</a
      >
    </div>
  </div>
</div>

<div *ngIf="party && party.match < 1">
  <p
    content
    data-cy="enrollmentVerificationApologyText"
    [contentPath]="'enrollment.verificationApologyText'"
  ></p>
  <button
    ds-button
    type="button"
    (click)="goBackOneStep()"
    content
    [contentPath]="'shared.retry'"
  ></button>
</div>

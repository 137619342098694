import { ValidatorFn } from '@angular/forms';

// Referenced from: https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325
// This works by passing in the condition you want to evaluate as the first parameter, and the validations you want to
// apply if the condition is met
export class ConditionalValidator {
  public static applyIfValid(condition: Function, validations: ValidatorFn) {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (condition()) {
        return validations(formControl);
      }
      return null;
    };
  }
}

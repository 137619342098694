<div class="flip-card">
  <div class="content" #card (click)="flip(card, $event)" role="button">
    <div class="front">
      <ng-content select="[front]"></ng-content>
      <a href="#" class="flip-button">Tap for the answer</a>
    </div>
    <div class="back">
      <ng-content select="[back]"></ng-content>
    </div>
  </div>
</div>

import {
  TPIIndicator,
  UnderwritingQuestion,
  VehicleSelectCriteria
} from '@amfam/policy/vehicle/data-access';
import { ApiStatus, QuoteSummaryModel } from '@amfam/shared/models';
import { Agent } from '@amfam/shared/utility/agent';
import { ANSNotification, InternalEmail } from '@amfam/shared/utility/shared-services';
import { get as _get } from 'lodash';
import { RiskModel } from './risk.model';

// Base Store model object
export interface ReplaceVehicle {
  // Status
  status: ApiStatus;
  hasError: boolean;
  loading: boolean;

  // Notify Agent status
  notifyAgentStatus: NotifyAgentStatus;
  producerId: string;

  // Quote request payload objects
  policyNumber: string;
  effectiveDate: Date;
  replaceVin: string;
  newVehicle: VehicleSelectCriteria;

  // Response data
  paymentRequestId: string;
  premium: PremiumEstimate;

  // confirmation type
  confirmationType: 'Online Bind Success' | 'Send Quote to Agent' | 'No Quote Send to Agent';

  // risk selection type
  riskSelectionContext: RiskSelectionContext;
}

export interface NotifyAgentStatus {
  notifyingAgent: boolean;
  hasError: boolean;
  status: ApiStatus;
}

export const initialNotifyAgentStatus: NotifyAgentStatus = {
  notifyingAgent: false,
  hasError: false,
  status: null
};

export interface PremiumEstimate {
  autoPaySavings?: number;
  estimatedFullPaySavings?: number;
  monthlyPremium: number;
  premiumDetails?: PremiumDetails;
  termMonths: number;
  totalCostWithFullPay?: number;
  totalDiscount: number;
  totalPremium: number;
  totalSurcharge?: number;
  workOrderNumber: string;
}

export interface PremiumDetails {
  PolicyAdminConstant?: number;
  RiskID?: number;
  discountAmount: number;
  premiumAmount: number;
}

export const initialPremiumDetails: PremiumDetails = {
  discountAmount: null,
  premiumAmount: null
};

export const initialPremiumEstimate: PremiumEstimate = {
  autoPaySavings: null,
  estimatedFullPaySavings: null,
  monthlyPremium: null,
  premiumDetails: initialPremiumDetails,
  termMonths: null,
  totalCostWithFullPay: null,
  totalDiscount: null,
  totalPremium: null,
  totalSurcharge: null,
  workOrderNumber: null
};

export const initialReplaceVehicle: ReplaceVehicle = {
  status: null,
  hasError: false,
  loading: false,

  notifyAgentStatus: initialNotifyAgentStatus,
  producerId: null,

  policyNumber: null,
  effectiveDate: null,
  replaceVin: null,
  newVehicle: null,

  paymentRequestId: null,
  premium: null,
  confirmationType: null,

  riskSelectionContext: null
};

// Remove when removing 'bindReplaceVehicle' feature flag
// Old request Payload for POST /autopolicies/{ID}/quotes
export interface OldReplaceVehicleQuoteApiRequest {
  policyNumber: string;
  effectiveDate: Date;
  replaceVIN: string;
  newVehicle: VehicleSelectCriteria;
}

// Request Payload for POST /autopolicies/{ID}/quotes
export interface ReplaceVehicleQuoteApiRequest {
  policyNumber: string;
  effectiveDate: Date;
  replaceVIN: string;
  copyTPIIndicator: boolean;
  underwritingQuestions: UnderwritingQuestion[];
  newVehicle: VehicleSelectCriteria;
}

// Response payload for POST /autopolicies/{ID}/quote
export interface ReplaceVehicleQuoteApiResponse {
  status: ApiStatus;
  premium?: PremiumEstimate;
}

// Request payload for /autopolicies/{ID}/submit
export interface ReplaceVehicleNotifyApiRequest {
  policyNumber: string;
  payload: {
    producerId: string;
    quoteId: string;
  };
}

// Response payload for /autopolicies/{ID}/submit
export interface ReplaceVehicleNotifyApiResponse {
  status: ApiStatus;
}

export class NotificationRequestInternalEmail {
  public producer: Agent;
  public orderNumber?: string;
  public vehicleId: string;
  public customerName: string;
  public policyNumber: string;
  public newVehicle: string;
  public oldVehicle: string;
  public email: string;
  public type?: 'No Quote' | 'Quote' | 'Quote to agent';
  public isUsedForBusiness?: 'Yes' | 'No';
  public isOwner?: 'Yes' | 'No';
  public hasTPI?: 'Yes' | 'No';
  public copyTPIIndicator?: 'Yes' | 'No';
  public existingTPI?: string;

  constructor(notificationRequest: NotificationRequest) {
    this.producer = notificationRequest.producer;
    this.orderNumber = notificationRequest.orderNumber || '';
    this.vehicleId = notificationRequest.vehicleId;
    this.customerName = notificationRequest.customerName;
    this.policyNumber = notificationRequest.policyNumber;
    this.newVehicle = notificationRequest.newVehicle;
    this.oldVehicle = notificationRequest.oldVehicle;
    this.email = notificationRequest.email;
    this.type = notificationRequest.type;
    this.isUsedForBusiness = notificationRequest.isUsedForBusiness;
    this.isOwner = notificationRequest.isOwner;
    this.hasTPI = notificationRequest.hasTPI;
    this.existingTPI = notificationRequest.existingTPI;
    this.copyTPIIndicator = notificationRequest.copyTPIIndicator;
  }
}

export class NotificationRequestCustomerEmail {
  public email: ReplaceVehicleRequestCustomerEmail;
  public message: MessageModel;
  public templateId: string;

  constructor(notificationRequest: NotificationCustomerRequest) {
    this.email = notificationRequest.email;
    this.message = notificationRequest.message;
    this.templateId = notificationRequest.templateId;
  }
}
export class NotificationCustomerRequest {
  public email: ReplaceVehicleRequestCustomerEmail;
  public message: MessageModel;
  public templateId: string;
}

export class NotificationRequest {
  public producer: Agent;
  public orderNumber?: string;
  public vehicleId: string;
  public customerName: string;
  public policyNumber: string;
  public newVehicle: string;
  public oldVehicle: string;
  public email: string;
  public type?: 'No Quote' | 'Quote' | 'Quote to agent';
  public isUsedForBusiness?: 'Yes' | 'No';
  public isOwner?: 'Yes' | 'No';
  public hasTPI?: 'Yes' | 'No';
  public copyTPIIndicator?: 'Yes' | 'No';
  public existingTPI?: string;
}

export class ANSRequest {
  public producer: Agent;
  public orderNumber: string;
  public policyNumber: string;
  public from: string;
  public customerName: string;
  public notificationText: string;
  public vehicleId: string;
  public notificationType?: string;
}

export class ANSPayloadRequest {
  notificationsRequest: {
    notificationText: string;
    notificationType: string;
    customerName: string;
    referenceNumber: string;
    formattedReferenceNumber: string;
    policyNumber: string;
    from: string;
    dueDate: string;
    createdBy: string;
    uniqueId: string;
    sourceSystem: string;
  };
  producerId: string;
}

export class ReplaceVehicleRequestCustomerEmail {
  public customerId: string;
  public customerEmailId: string;
  public partnerId: string;
  public accountId?: string;

  constructor(request: CustomerEmailRequest) {
    this.customerEmailId = request.customerEmailId;
    this.customerId = request.customerId;
    this.partnerId = request.partnerId;
    this.accountId = request.accountId;
  }
}

export class CustomerEmailRequest {
  public customerId: string;
  public customerEmailId: string;
  public partnerId: string;
  public accountId?: string;
}

export class MessageDetail {
  constructor(public key: string, public value: string | number) {}
}

export class TemplatedEmail {
  constructor(
    public templateCode: string,
    public partnerId: string,
    public brandId: string,
    public language: string,
    public customerIdentifier: string,
    public recipientAddress: string,
    public messageDetails: MessageDetail[] = new Array<MessageDetail>()
  ) {}
}

export class MessageModelNoQuote {
  public firstName: string;
  public policyNumber: string;
  public riskInformation: string;
  public totalPremium: number; // expected to be a number by Salesforce
  public monthlyPremium: number; // expected to be a number by Salesforce
  public termDescription: string;
  public newRiskInformation: string;
  public newTotalPremium: number; // expected to be a number by Salesforce
  public newMonthlyPremium: number; // expected to be a number by Salesforce
  public newTermDescription: string;
  public quoteExpirationDate: string;
  public drivers: string;
  public garagedAddress: string;
  public coverages: string;
  public discounts: string;
  public fees: string;
  public orderNumber: string;
  public producerId: string;

  constructor(request: MessageRequestNoQuote) {
    this.firstName = request.firstName;
    this.policyNumber = request.policyNumber;
    this.riskInformation = request.riskInformation;
    this.totalPremium = request.totalPremium;
    this.monthlyPremium = request.monthlyPremium;
    this.termDescription = request.termDescription;
    this.newRiskInformation = request.newRiskInformation;
    this.drivers = request.drivers;
    this.garagedAddress = request.garagedAddress;
    this.coverages = request.coverages;
    this.discounts = request.discounts;
    this.fees = request.fees;
    this.producerId = request.producerId;
  }
}

export class MessageModel {
  public firstName: string;
  public policyNumber: string;
  public riskInformation: string;
  public totalPremium: number; // expected to be a number by Salesforce
  public monthlyPremium: number; // expected to be a number by Salesforce
  public termDescription: string;
  public newRiskInformation: string;
  public newTotalPremium: number; // expected to be a number by Salesforce
  public newMonthlyPremium: number; // expected to be a number by Salesforce
  public newTermDescription: string;
  public quoteExpirationDate: string;
  public drivers: string;
  public garagedAddress: string;
  public coverages: string;
  public discounts: string;
  public fees: string;
  public orderNumber: string;
  public producerId: string;

  constructor(request: MessageRequest) {
    this.firstName = request.firstName;
    this.policyNumber = request.policyNumber;
    this.riskInformation = request.riskInformation;
    this.totalPremium = request.totalPremium;
    this.monthlyPremium = request.monthlyPremium;
    this.termDescription = request.termDescription;
    this.newRiskInformation = request.newRiskInformation;
    this.newTotalPremium = request.newTotalPremium;
    this.newMonthlyPremium = request.newMonthlyPremium;
    this.newTermDescription = request.newTermDescription;
    this.quoteExpirationDate = request.quoteExpirationDate;
    this.drivers = request.drivers;
    this.garagedAddress = request.garagedAddress;
    this.coverages = request.coverages;
    this.discounts = request.discounts;
    this.fees = request.fees;
    this.orderNumber = request.orderNumber;
    this.producerId = request.producerId;
  }
}

export class MessageRequest {
  public firstName: string;
  public policyNumber: string;
  public riskInformation: string;
  public totalPremium: number; // expected to be a number by Salesforce
  public monthlyPremium: number; // expected to be a number by Salesforce
  public termDescription: string;
  public newRiskInformation: string;
  public newTotalPremium: number; // expected to be a number by Salesforce
  public newMonthlyPremium: number; // expected to be a number by Salesforce
  public newTermDescription: string;
  public quoteExpirationDate: string;
  public drivers: string;
  public garagedAddress: string;
  public coverages: string;
  public discounts: string;
  public fees: string;
  public orderNumber: string;
  public producerId: string;
}

export class MessageRequestNoQuote {
  public firstName: string;
  public policyNumber: string;
  public riskInformation: string;
  public totalPremium: number; // expected to be a number by Salesforce
  public monthlyPremium: number; // expected to be a number by Salesforce
  public termDescription: string;
  public newRiskInformation: string;
  public drivers: string;
  public garagedAddress: string;
  public coverages: string;
  public discounts: string;
  public fees: string;
  public producerId: string;
}

export class CustomerNotificationSuccessModel {
  confirmation: {
    emailUniqueId: string;
  };
  status: ApiStatus;
}

export class CustomerNotificationFailureModel {
  status: ApiStatus;
}

export class AgentNotificationSuccessModel {
  confirmation: {
    emailUniqueId: string;
  };
  status: ApiStatus;
}

export class AgentNotificationFailureModel {
  status: ApiStatus;
}

export class EligibilityEnum {
  public static state = 'AZ';
  public static vehicleTypeName = 'Car/Light Truck';
  public static vehicleUseDescription = 'Antique/Classic/Replica';
}

export class ANSRequestModel {
  public producerId: string;
  public orderNumber: string;
  public from: string;
  public notificationText: string;
  public customerName: string;
  public policyNumber: string;
  public vehicleId: string;
  public notificationType?: string;

  constructor(notificationRequest: ANSRequest) {
    this.producerId = _get(notificationRequest, 'producer.id', '').toString();
    this.orderNumber = notificationRequest.orderNumber;
    this.from = notificationRequest.from;
    this.notificationText = notificationRequest.notificationText;
    this.customerName = notificationRequest.customerName;
    this.policyNumber = notificationRequest.policyNumber;
    this.vehicleId = notificationRequest.vehicleId;
    this.notificationType = notificationRequest.notificationType;
  }
}

export class ANSFailureModel {
  status: ApiStatus;
}

export interface SubmitOnlineBindRequestModel {
  policyNumber: string;
  state: string;
  notificationSubject: string;
  notificationText: string;
  notificationType: string;
  emailSubject: string;
  emailBody: string;
  workOrderNumber: string;
  vehicleId: string;
  sourceSystem: string;
  partnerId?: string;
  customer: {
    id?: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    emailTemplateId: string;
  };
  producer: {
    firstName: string;
    lastName: string;
    producerId: string;
    phone: string;
    email: string;
    fromAddress: string;
    fromAddressLabel: string;
  };
  emailTemplateData: {
    RiskInformation: string;
    TotalPremium: number;
    MonthlyPremium: number;
    TermDescription: string;
    NewRiskInformation: string;
    NewTotalPremium: number;
    NewMonthlyPremium: number;
    NewTermDescription: string;
    Drivers: string;
    GaragedAddress: string;
    Coverages: string;
    Fees: string;
    Discounts: string;
    FirstName?: string;
    PolicyNumber?: string;
    Vehicle?: string;
    CurrentCoverage?: string;
    CoverageChanges?: string;
    EmailAddress?: string;
    SubscriberKey?: string;
    VehicleCount?: number;
  };
  policyCoverages?: Map<string, string>;
  vehicleCoverages?: Map<string, string>;
  discounts?: Map<string, string>;
  futurePayments?: Map<string, string>;
}

export interface ReplaceVehicleAnalyticsModel {
  pageAnalyticName?: string;
  buttonAnalyticName?: string;
  eventName?: string;
  referenceNumber?: string;
  agentName?: string;
}

// Quick quote / Quote

export interface GetReplaceVehicleResponse {
  status: ApiStatus;
  premium: PremiumEstimate;
}
export interface NewVehicle {
  year: string;
  make: string;
  model: string;
  series: string;
  vehicleType: string;
}

export interface QuickQuoteApiPayload {
  replaceVIN: string;
  effectiveDate: Date;
  newVehicle: VehicleSelectCriteria;
  copyTPIIndicator?: boolean;
  underwritingQuestions?: UnderwritingQuestion[];
}
export interface QuickQuoteRequest extends QuickQuoteApiPayload {
  policyNumber: string;
  operation?: 'quickquotes' | 'quotes';
}

export interface QuickQuoteParams {
  replaceVIN: string;
  newVehicle: NewVehicle;
  policyNumber: string;
  effectiveDate: Date;
}

export interface CustomerInfo {
  customerId: string;
  firstname: string;
  lastname: any;
  primaryEmail: string;
  accountEmail: string;
}

export interface VehicleQuote {
  id?: string;
  year: string;
  vin?: string;
  make: string;
  model: string;
  series?: string;
  totalPremium?: number;
  term?: string | number;
  monthlyPremium?: number;
}

export interface QuickQuoteBase {
  partnerId: string;
  brandId: string;
  producer: Agent;
  policyNumber: string;
  customer: CustomerInfo;
  existingVehicle: VehicleQuote;
  newVehicle: VehicleQuote;
  vehicle?: RiskModel;
}

export interface QuickQuoteEmail extends QuickQuoteBase {
  workOrderNumber?: string;
}

export enum GracePeriodDays {
  REGULAR = '14',
  EXTENDED = '30'
}

export enum RiskSelectionContext {
  ADD_VEHICLE = 'Add Vehicle',
  REPLACE_VEHICLE = 'Replace Vehicle'
}

export interface ICancelProgressEmail extends QuickQuoteBase {
  copyTpiIndicator?: TPIIndicator;
  tpiName?: string;
  hasTpi?: 'Yes' | 'No';
  orderNumber?: string;
  isCancelProcess?: boolean;
}

export interface ReplaceVehiclePLPCPayload {
  replaceVehicleVin: string; //'JTDKAMFU2M3135606';
  policyNumber: string; //'410017889997';
  vehicle: {
    vin: string; //'5N1AL0MM4DC301508';
    year: string; //'2013';

    annualMileage: number; //6000;
    primaryUsage: string; //'pleasure';

    garageAddress: {
      addressLine1: string; //'4145 Cherokee Dr';
      addressLine2?: string;
      city: string; //'Brookfield';
      state: string; //'WI';
      postalCode: string; //'53045';
      country: string; //'USA';
    };

    airBagsIndicator: string; //'Both';
    antiLockBrakes: boolean; //true;
    antiTheftDeviceIndicator: string; //'none';
    dayTimeRunningLights: boolean; //false;
    electronicStability: boolean; //true;

    hasOtherOrCoowner: boolean; //false;
    tpis: string[];
  };
}

export interface INotify {
  ans: ANSNotification;
  email: InternalEmail;
  customerEmail: TemplatedEmail
}

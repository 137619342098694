import { Action } from '@ngrx/store';
import { GoPaperLessAnalyticsModel, GoPaperlessSuccessModel } from '../models/go-paperless.model';

export enum GoPaperlessActionTypes {
  LoadGoPaperless = '[GoPaperless] Load GoPaperless',
  LoadGoPaperlessSuccess = '[GoPaperless] Load GoPaperless Success',
  LoadGoPaperlessFailure = '[GoPaperless] Load GoPaperless Failure',

  EnrollGoPaperless = '[GoPaperless] Enroll GoPaperless',
  EnrollGoPaperlessSuccess = '[GoPaperless] Enroll GoPaperless Success',
  EnrollGoPaperlessFailure = '[GoPaperless] Enroll GoPaperless Failure',

  UnEnrollGoPaperless = '[GoPaperless] Unenroll GoPaperless',
  UnEnrollGoPaperlessSuccess = '[GoPaperless] Unenroll GoPaperless Success',
  UnEnrollGoPaperlessFailure = '[GoPaperless] Unenroll GoPaperless Failure',

  GenerateGoPaperlessEmail = '[GoPaperless] Email GoPaperless',
  GenerateGoPaperlessEmailSuccess = '[GoPaperless] Email GoPaperless Success',
  GenerateGoPaperlessEmailFailure = '[GoPaperless] Email GoPaperless Failure',

  SendGoPaperlessAnalytics = '[GoPaperless] Analytics GoPaperless'
}

export class LoadGoPaperless implements Action {
  readonly type = GoPaperlessActionTypes.LoadGoPaperless;
  constructor(public payload?: any) {}
}

export class LoadGoPaperlessSuccess implements Action {
  readonly type = GoPaperlessActionTypes.LoadGoPaperlessSuccess;
  constructor(public payload: { data: any }) {}
}

export class LoadGoPaperlessFailure implements Action {
  readonly type = GoPaperlessActionTypes.LoadGoPaperlessFailure;
  constructor(public payload: { error: any }) {}
}

export class EnrollGoPaperless implements Action {
  readonly type = GoPaperlessActionTypes.EnrollGoPaperless;
  constructor(public payload?: any) {}
}

export class EnrollGoPaperlessSuccess implements Action {
  readonly type = GoPaperlessActionTypes.EnrollGoPaperlessSuccess;
  constructor(public payload?: GoPaperlessSuccessModel) {}
}

export class EnrollGoPaperlessFailure implements Action {
  readonly type = GoPaperlessActionTypes.EnrollGoPaperlessFailure;
  constructor(public payload?: any) {}
}

export class UnEnrollGoPaperless implements Action {
  readonly type = GoPaperlessActionTypes.UnEnrollGoPaperless;
  constructor(public payload?: any) {}
}

export class UnEnrollGoPaperlessSuccess implements Action {
  readonly type = GoPaperlessActionTypes.UnEnrollGoPaperlessSuccess;
  constructor(public payload?: GoPaperlessSuccessModel) {}
}

export class UnEnrollGoPaperlessFailure implements Action {
  readonly type = GoPaperlessActionTypes.UnEnrollGoPaperlessFailure;
  constructor(public payload?: any) {}
}

export class GenerateGoPaperlessEmail implements Action {
  readonly type = GoPaperlessActionTypes.GenerateGoPaperlessEmail;
  constructor(public payload?: any) {}
}

export class GenerateGoPaperlessEmailSuccess implements Action {
  readonly type = GoPaperlessActionTypes.GenerateGoPaperlessEmailSuccess;
  constructor(public payload?: any) {}
}

export class GenerateGoPaperlessEmailFailure implements Action {
  readonly type = GoPaperlessActionTypes.GenerateGoPaperlessEmailFailure;
  constructor(public payload?: any) {}
}

export class SendGoPaperlessAnalytics implements Action {
  readonly type = GoPaperlessActionTypes.SendGoPaperlessAnalytics;
  constructor(public payload: GoPaperLessAnalyticsModel) {}
}

export type GoPaperlessActions =
  | LoadGoPaperless
  | LoadGoPaperlessSuccess
  | LoadGoPaperlessFailure
  | EnrollGoPaperless
  | EnrollGoPaperlessSuccess
  | EnrollGoPaperlessFailure
  | UnEnrollGoPaperless
  | UnEnrollGoPaperlessSuccess
  | UnEnrollGoPaperlessFailure
  | GenerateGoPaperlessEmail
  | GenerateGoPaperlessEmailSuccess
  | GenerateGoPaperlessEmailFailure
  | SendGoPaperlessAnalytics;

export const fromGoPaperlessActions = {
  LoadGoPaperless,
  LoadGoPaperlessSuccess,
  LoadGoPaperlessFailure,
  EnrollGoPaperless,
  EnrollGoPaperlessSuccess,
  EnrollGoPaperlessFailure,
  UnEnrollGoPaperless,
  UnEnrollGoPaperlessSuccess,
  UnEnrollGoPaperlessFailure,
  GenerateGoPaperlessEmail,
  GenerateGoPaperlessEmailSuccess,
  GenerateGoPaperlessEmailFailure,
  SendGoPaperlessAnalytics
};

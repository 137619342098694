import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import {
  FinancialAccountResponseModel,
  FetchPciTokenModel,
  PciAuthResponseModel
} from '../models/financial-institution.models';

import {
  GetFinancialInstitution,
  GetFinancialInstitutionSuccess,
  GetFinancialInstitutionFail,
  FinancialInstitutionActionTypes,
  FetchPciTokenAction
} from './financial-institution.actions';

import { PaymentAccountActions } from '@amfam/billing/paymentaccount/data-access';
import { FinancialAccountService } from '@amfam/billing/shared/util';

@Injectable()
export class FinancialInstitutionEffects {
  
  getFinancialInstitution$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GetFinancialInstitution>(FinancialInstitutionActionTypes.GetFinancialInstitution),
    map(action => action.payload),
    switchMap((routingNumber: string) =>
      this.financialInstitutionService.getFinancialInstitution(routingNumber).pipe(
        map((res: FinancialAccountResponseModel) => {
          return new GetFinancialInstitutionSuccess(res);
        }),
        catchError(error => of(new GetFinancialInstitutionFail(error)))
      )
    )
  ));

  
  getAuth$ = createEffect(() => this.actions$.pipe(
    ofType<FetchPciTokenAction>(FinancialInstitutionActionTypes.FetchAuthToken),
    map(action => action.payload),
    switchMap((payload: FetchPciTokenModel) =>
      this.financialInstitutionService.getAuthorizationToken().pipe(
        map((res: PciAuthResponseModel) => {
          this.financialInstitutionService.setAuthorizationToken(res.pciToken);
          if (payload.routingNumber) {
            return new GetFinancialInstitution(payload.routingNumber);
          } else {
            return new PaymentAccountActions.PaymentAccountsSave(payload.paymentAccountDTO);
          }
        }),
        catchError(err => of(new GetFinancialInstitutionFail(err)))
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private financialInstitutionService: FinancialAccountService
  ) {}
}

import { BrandModel, BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ClaimConfirmation } from '../models/claim-confirmation';
import { ClaimEmailService } from '../services/claim-email.service';
import { ClaimSubmitService } from '../services/claim-submit.service';
import {
  ClaimSubmitActionTypes,
  fromClaimSubmitActions,
  ReportClaim,
  SubmitClaim,
  SubmitClaimSuccess
} from './claim-submit.actions';

@Injectable()
export class ClaimSubmitEffects {
  submitClaim$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimSubmitActionTypes.SubmitClaim),
      map((action: SubmitClaim) => action.payload),
      switchMap(claimRequest =>
        this.claimSubmitService.reportClaimOld(claimRequest).pipe(
          map(
            (confirmation: ClaimConfirmation) =>
              new fromClaimSubmitActions.SubmitClaimSuccess(confirmation)
          ),
          catchError(() => of(new fromClaimSubmitActions.SubmitClaimFailed()))
        )
      )
    )
  );

  submitClaimSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimSubmitActionTypes.SubmitClaimSuccess),
      map((action: SubmitClaimSuccess) => action.payload),
      filter(confirmation => !!confirmation), // is this supposed to be some sort of error scenario?? yeeeesh
      withLatestFrom(this.brandStore.select(BrandSelectors.getIsPartner)),
      mergeMap(([confirmation, isPartner]) =>
        this.claimEmailService
          .generateInternalEmails(confirmation, isPartner)
          .pipe(map(email => confirmation))
      ),
      withLatestFrom(this.brandStore.select(BrandSelectors.getPartnerId)),
      mergeMap(([confirmation, partnerId]) =>
        this.claimEmailService
          .generateCustomerEmail(confirmation, partnerId)
          .pipe(map(email => confirmation))
      ),
      map(
        () =>
          new fromRouterActions.Go({
            path: ['/claims/claim-confirmation']
          })
      )
    )
  );

  reportClaimConfirm$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimSubmitActionTypes.ReportClaimSuccess, ClaimSubmitActionTypes.ReportClaimFailed),
      map(() => new fromRouterActions.Go({ path: ['/claims/report-claim/confirm'] }))
    )
  );

  reportClaim$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimSubmitActionTypes.ReportClaim),
      map((action: ReportClaim) => action.payload),
      switchMap(claimRequest =>
        this.claimSubmitService.reportClaim(claimRequest).pipe(
          map(
            (confirmation: ClaimConfirmation) =>
              new fromClaimSubmitActions.ReportClaimSuccess(confirmation)
          ),
          catchError(() => of(new fromClaimSubmitActions.ReportClaimFailed()))
        )
      )
    )
  );

  constructor(
    private brandStore: Store<BrandModel>,
    private claimSubmitService: ClaimSubmitService,
    private claimEmailService: ClaimEmailService,
    private action$: Actions
  ) {}
}

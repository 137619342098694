import { PaymentMethodFacade } from '@amfam/billing/payment-method/data-access';
import { PaymentMethod, PaymentMethodUtilService } from '@amfam/billing/payment-method/util';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { DockingBarService, LoadingSpinnerService } from '@amfam/ui-kit';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import * as PaymentMethodFeatureActions from '../+state/payment-method-feature.actions';
import {
  AutomaticPayments,
  PaymentMethodSetup,
  ScheduledPayments
} from '../+state/payment-method-feature.models';
import * as PaymentMethodFeatureSelectors from '../+state/payment-method-feature.selectors';

@Component({
  selector: 'ds-payment-method-delete-container',
  templateUrl: './payment-method-delete-container.component.html',
  styleUrls: ['./payment-method-delete-container.component.scss']
})
export class PaymentMethodDeleteContainerComponent implements OnInit {
  paymentMethod: PaymentMethod;
  scheduledPayments: ScheduledPayments[] = [];
  automaticPayments: AutomaticPayments[] = [];
  autoPayDiscountWarningHeading: string;
  autoPayDiscountWarningBody: string;

  constructor(
    private store: Store,
    private dockingBar: DockingBarService,
    private copyService: CopyService,
    private spinner: LoadingSpinnerService,
    private paymentMethodFacade: PaymentMethodFacade,
    private paymentMethodUtil: PaymentMethodUtilService
  ) {}

  ngOnInit(): void {
    this.spinner.start({ blockActions: true });
    this.dockingBar.registerHeading(
      this.copyService.getCopy('billing.paymentMethodStandAlone.dockingBarHeading')
    );

    this.store
      .select(PaymentMethodFeatureSelectors.getScheduledPaymentsByPaymentMethod)
      .pipe(take(1))
      .subscribe(paymentMethodInfo => {
        this.paymentMethod = paymentMethodInfo.paymentMethod;
        this.scheduledPayments = paymentMethodInfo.scheduledPayments;
        this.automaticPayments = paymentMethodInfo.automaticPayments;

        // get autopay discount warning
        if (this.paymentMethod.achWithdrawal && this.automaticPayments.length > 0) {
          this.autoPayDiscountWarningHeading = this.copyService.getCopy(
            'billing.paymentMethodStandAlone.autoPayDiscountWarningHeading'
          );
          this.autoPayDiscountWarningBody = this.copyService.getCopy(
            'billing.paymentMethodStandAlone.autoPayDiscountWarningBody'
          );
        }

        // send analytics
        this.paymentMethodFacade.sendPaymentMethodAnalytics({
          pageAnalyticName: 'DeletePaymentMethod'
        });

        this.spinner.stop();
      });
  }

  delete() {
    // send analytics
    this.paymentMethodFacade.sendPaymentMethodAnalytics({
      buttonAnalyticName: 'DeletePaymentMethod:Delete'
    });
    const paymentMethodInfo: PaymentMethodSetup = {
      paymentMethod: this.paymentMethod,
      scheduledPayments: this.scheduledPayments,
      automaticPayments: this.automaticPayments
    };
    this.store.dispatch(
      PaymentMethodFeatureActions.storeDeletePaymentMethodInfo({ paymentMethodInfo })
    );
    this.paymentMethodFacade.deletePaymentMethod(this.paymentMethod);
  }

  cancel() {
    // send analytics
    this.paymentMethodFacade.sendPaymentMethodAnalytics({
      buttonAnalyticName: 'DeletePaymentMethod:Cancel'
    });
    this.store.dispatch(new fromRouterActions.Back());
  }
}

export interface FirstPartyFnolCopy {
  magicThreeHeading: string;
  plusOneHeading: string;
  plusOnePolicyHeading: string;
  plusOnePolicySubheading: string;
  plusOneSsnHeading: string;
  myAccountFoundHeading: string;
  myAccountFoundNextSteps: string;
  plusOneErrorHeading: string;
  plusOneErrorNextSteps: string;
  enrollmentConfirmationHeading: string;
  enrollmentConfirmationText: string;
  myAccountBtn: string;
  cancelModalHeading: string;
  cancelModalText: string;
  nextStepsInstructions: string;
  confirmationNextStepsInstructions: string;
  autoConfirmationMyAccountBenefits: string;
  propertyConfirmationMyAccountBenefits: string;
  securityQuestionsTitle: string;
  accountSetupTitle: string;
  goToAmFamDotCom: string;
}

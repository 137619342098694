import {
  Applications,
  ApplicationService,
  CopyService
} from '@amfam/shared/utility/shared-services';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AutoPayPaymentPreview } from './auto-pay-payment-preview.model';

@Component({
  selector: 'ds-auto-pay-setup-payment-preview',
  templateUrl: './auto-pay-setup-payment-preview.component.html',
  styleUrls: ['./auto-pay-setup-payment-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoPaySetupPaymentPreviewComponent implements OnInit {
  @Input() paymentPreview: AutoPayPaymentPreview;
  @Input() isFullPay: boolean;

  paymentPreviewFeesDisclaimer: string;
  title: string;

  constructor(private copyService: CopyService, private appService: ApplicationService) {}

  ngOnInit(): void {
    this.paymentPreviewFeesDisclaimer = this.appService.isApp(Applications.MYACCOUNT_ADMIN)
      ? this.copyService.getCopy('billing.autoPayRefactor.adminToolPaymentPreviewFeesDisclaimer')
      : this.copyService.getCopy('billing.autoPayRefactor.paymentPreviewFeesDisclaimer');

    this.title = this.isFullPay ? 'Preview payments' : 'Preview first payment';
  }
}

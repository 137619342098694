import { BillingSharedUiModule } from '@amfam/billing/shared/ui';
import { PipesModule } from '@amfam/shared/ui/pipes';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoPayReviewItemComponent } from './auto-pay-review-item/auto-pay-review-item.component';
import { AutoPaySetupBillAccountTileComponent } from './auto-pay-setup-bill-account-tile/auto-pay-setup-bill-account-tile.component';
import { AutoPaySetupPaymentDateComponent } from './auto-pay-setup-payment-date/auto-pay-setup-payment-date.component';
import { AutoPaySetupPaymentFrequencyComponent } from './auto-pay-setup-payment-frequency/auto-pay-setup-payment-frequency.component';
import { AutoPaySetupPaymentPreviewComponent } from './auto-pay-setup-payment-preview/auto-pay-setup-payment-preview.component';

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    SharedServicesModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    BillingSharedUiModule
  ],
  declarations: [
    AutoPaySetupPaymentFrequencyComponent,
    AutoPaySetupPaymentDateComponent,
    AutoPaySetupPaymentPreviewComponent,
    AutoPayReviewItemComponent,
    AutoPaySetupBillAccountTileComponent
  ],
  exports: [
    AutoPaySetupPaymentFrequencyComponent,
    AutoPaySetupPaymentDateComponent,
    AutoPaySetupPaymentPreviewComponent,
    AutoPayReviewItemComponent,
    AutoPaySetupBillAccountTileComponent
  ]
})
export class BillingAutoPayUiModule {}

import { Action } from '@ngrx/store';
import { EnrollUserModel } from './enroll-user.model';

export const PARTY_DETAILS = '[PARTY] Anonymous Parties Details';
export const RESET_PARTY = '[PARTY] Reset Party Details';
export const SUBMIT_IDENTIFICATION = '[Identification] Submit';
export const RESET_IDENTIFICATION = '[Identification] Reset Identification';
export const VERIFY_DONE = '[Verification] Verify Success';
export const RESET_VERIFICATION = '[Verification] Reset Verification';
export const SUBMIT_SECURITYQUESTIONS = '[SecurityQuestions] Submit';
export const RESET_SECURITYQUESTIONS = '[SecurityQuestions] Reset';
export const SUBMIT_SIGNUP = '[SignUp] Submit';
export const RESET_SIGNUP = '[SignUp] Reset SignUp';
export const SUBMIT_BUSINESS = '[BusinessEnroll] Submit';
export const FORGOT_USERID_SHELL_ACCOUNT = '[CruiseEnroll] Found Shell Account Forgot UserId';
export const SHELL_ACCOUNT_FOUND = '[CruiseEnroll] Found Shell Account';
export const RESET_USER = '[EnrollUser] Reset';

export class PartyDetailsAction implements Action {
  type = PARTY_DETAILS;
  constructor(public payload: any) {}
}

export class ResetPartyDetailsAction implements Action {
  type = RESET_PARTY;

  constructor(public payload?: any) {}
}

export class SubmitIdentificationAction implements Action {
  type = SUBMIT_IDENTIFICATION;
  constructor(public payload: any) {}
}

export class ResetIdentificationAction implements Action {
  type = RESET_IDENTIFICATION;

  constructor(public payload?: any) {}
}

export class SubmitVerificationAction implements Action {
  type = VERIFY_DONE;
  constructor(public payload: any) {}
}

export class ResetVerificationAction implements Action {
  type = RESET_VERIFICATION;

  constructor(public payload?: any) {}
}

export class SubmitSecurityQuestionsAction implements Action {
  type = SUBMIT_SECURITYQUESTIONS;

  constructor(public payload: any) {}
}

export class ResetSecurityQuestionsAction implements Action {
  type = RESET_SECURITYQUESTIONS;

  constructor(public payload?: any) {}
}

export class SubmitSignUpAction implements Action {
  type = SUBMIT_SIGNUP;

  constructor(public payload: any) {}
}

export class ResetSignUpAction implements Action {
  type = RESET_SIGNUP;

  constructor(public payload?: any) {}
}

export class SubmitEnrollBusinessAction implements Action {
  type = SUBMIT_BUSINESS;

  constructor(public payload: any) {}
}

export class ForgotUserIdShellAccountAction implements Action {
  type = FORGOT_USERID_SHELL_ACCOUNT;

  constructor(public payload?: any) {}
}
export class ShellAccountFoundAction implements Action {
  type = SHELL_ACCOUNT_FOUND;

  constructor(public payload?: any) {}
}

export class ResetEnrollmentAction implements Action {
  type = RESET_USER;

  constructor(public payload?: any) {}
}

export type Actions =
  | PartyDetailsAction
  | ResetPartyDetailsAction
  | SubmitIdentificationAction
  | ResetIdentificationAction
  | SubmitVerificationAction
  | ResetVerificationAction
  | SubmitSecurityQuestionsAction
  | ResetSecurityQuestionsAction
  | SubmitSignUpAction
  | ResetSignUpAction
  | SubmitEnrollBusinessAction
  | ForgotUserIdShellAccountAction
  | ShellAccountFoundAction
  | ResetEnrollmentAction;

import { map, finalize, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { has as _has, get as _get } from 'lodash';

import { GetPaymentHistoryPayload, GetRegisteredPaymentsResponse } from '@amfam/shared/models';

import {
  PaymentHistoryActionTypes,
  GetPayments,
  fromPaymentHistoryActions
} from './payment-history.actions';
import { PaymentService } from '@amfam/billing/shared/util';
import { subMonths, addMonths, format } from 'date-fns';

@Injectable()
export class PaymentHistoryEffects {
  
  getPaymentHistory$ = createEffect(() => this.action$.pipe(
    ofType(PaymentHistoryActionTypes.GetPayments),
    map((action: GetPayments) => action.payload),
    mergeMap((payload: GetPaymentHistoryPayload) => {
      const startDate = format(subMonths(new Date(), payload.monthRange.startMonth), 'YYYY-MM-DD');
      const endDate = format(subMonths(new Date(), payload.monthRange.endMonth), 'YYYY-MM-DD');
      return this.paymentService.getRegisteredPayments(startDate, endDate, false, true).pipe(
        map((response: GetRegisteredPaymentsResponse) => {
          const payments = _get(response, 'payments', []);
          return new fromPaymentHistoryActions.GetPaymentsSuccess(payments);
        }),
        catchError(error => {
          if (_get(error, 'status.code') === '404') {
            return of(new fromPaymentHistoryActions.GetPaymentsSuccess([]));
          }
          return of(new fromPaymentHistoryActions.GetPaymentsFail());
        })
      );
    })
  ));

  constructor(private action$: Actions, private paymentService: PaymentService) {}
}

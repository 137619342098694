// Store
import { createSelector } from '@ngrx/store';
import * as workflowActions from './workflow.actions';

// Models
import { WorkflowModel, StepModel, initialWorkflow } from './workflow.model';

// Misc
import { keyBy as _keyBy, cloneDeep as _cloneDeep } from 'lodash';

export const getLoaded = (state: WorkflowModel) => state.loaded;

export const getWorkflowType = (state: WorkflowModel) => state.workflowType;

export const getSteps = (state: WorkflowModel) => state.steps;

export const getIds = (state: WorkflowModel) => state.ids;

export const getStep = (steps: StepModel[], name: string) => steps.find(step => step.name === name);

export const getActiveId = (state: WorkflowModel) => state.activeStep;

export const getActiveTitle = (state: WorkflowModel) => state.steps[state.activeStep].title;

export const getActiveStep = createSelector(
  getSteps,
  getActiveId,
  (steps, activeStep) => {
    return steps[activeStep];
  }
);

export const getNextStep = createSelector(
  getSteps,
  getActiveId,
  (steps, activeStep) => {
    return steps[steps[activeStep].nextStep];
  }
);

export const getBackStep = createSelector(
  getSteps,
  getActiveId,
  (steps, activeStep) => {
    return steps[steps[activeStep].backStep];
  }
);

export function reducer(state = initialWorkflow(), action: workflowActions.Actions): WorkflowModel {
  let steps = {};
  let ids = [];
  let activeStep: StepModel;
  let nextStep: StepModel;
  switch (action.type) {
    case workflowActions.WORKFLOW_LOAD:
      steps = _keyBy(action.payload.steps, step => step['name']);
      ids = Object.keys(steps);
      return Object.assign({}, state, {
        loaded: true,
        workflowType: action.payload.workflowType,
        ids: ids,
        steps: steps,
        activeStep: action.payload.activeStep || steps[ids[0]].name
      });

    case workflowActions.WORKFLOW_STEP_COMPLETE:
      let nextStepName = '';
      steps = _cloneDeep(state.steps);
      // find current step
      activeStep = steps[state.activeStep];
      // only complete if payload step is the active step
      if (activeStep.name !== action.payload.step) {
        return state;
      }
      // set current step to complete
      steps[activeStep.name].complete = true;
      if (activeStep.backStep) {
        // set backstep to complete
        steps[activeStep.backStep].complete = true;
      }
      // manage nextStep if changing
      if (activeStep.nextStep) {
        // find the name of the next step
        nextStepName = activeStep.nextStep;
        // set nextStep if provided
        if (action.payload && action.payload.nextStep && action.payload.nextStep !== '') {
          nextStepName = action.payload.nextStep;
          // change the step shown in the progress bar
          steps[activeStep.nextStep].show = false;
          steps[nextStepName].show = true;
          // set the new next step name
          steps[activeStep.name].nextStep = nextStepName;
        }
        // set route for nextStep if provided
        if (action.payload && action.payload.nextRoute && action.payload.nextRoute.length > 0) {
          steps[nextStepName].route = action.payload.nextRoute;
          steps[activeStep.name].complete = false;
        }
        // set the backStep for the nextStep
        steps[nextStepName].backStep = activeStep.name;
      }

      return Object.assign({}, state, {
        steps: steps,
        activeStep: nextStepName
      });

    case workflowActions.WORKFLOW_STEP_BACK:
      steps = _cloneDeep(state.steps);
      // find the previous step
      nextStep = steps[steps[state.activeStep].backStep];
      let active;
      if (action.payload && action.payload.alternateRoute) {
        active = action.payload.alternateRoute;
      } else {
        active = nextStep.name;
      }
      // set route for nextStep if provided
      return Object.assign({}, state, {
        steps: steps,
        activeStep: active
      });

    case workflowActions.WORKFLOW_STEP_NEXT:
      steps = _cloneDeep(state.steps);
      // find the previous step
      nextStep = steps[steps[state.activeStep].nextStep];
      // set route for nextStep if provided
      return Object.assign({}, state, {
        steps: steps,
        activeStep: nextStep.name
      });

    case workflowActions.WORKFLOW_STEP_SET_ACTIVE:
      steps = _cloneDeep(state.steps);
      if (action.payload && action.payload.activeStep && action.payload.activeStep !== '') {
        activeStep = steps[action.payload.activeStep];
        if (activeStep) {
          return Object.assign({}, state, {
            steps: steps,
            activeStep: activeStep.name
          });
        }
      }
      return state;

    case workflowActions.WORKFLOW_RESET:
      steps = _cloneDeep(state.steps);
      for (const id in steps) {
        if (steps[id]) {
          steps[id] = Object.assign(steps[id], { complete: false });
        }
      }
      ids = Object.keys(steps);
      return Object.assign({}, state, {
        steps: steps,
        activeStep: steps[ids[0]].name
      });

    case workflowActions.WORKFLOW_DELETE:
      return initialWorkflow();

    case workflowActions.WORKFLOW_SKIP:
      steps = _cloneDeep(state.steps);
      // mark current step as skipped
      steps[state.activeStep].skipped = true;
      // find the previous step
      nextStep = steps[steps[state.activeStep].nextStep];
      // set route for nextStep if provided
      return Object.assign({}, state, {
        steps: steps,
        activeStep: nextStep.name
      });

    default:
      return state;
  }
}

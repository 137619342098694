import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ScheduledPaymentEffects } from './+state/schedulepayment.effects';
import * as fromScheduledPayment from './+state/schedulepayment.reducer';
import { schedulePaymentReducers } from './+state/schedulepayment.selectors';
@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ScheduledPaymentEffects]),
    StoreModule.forFeature(
      fromScheduledPayment.SCHEDULE_PAYMENT_FEATURE_KEY,
      schedulePaymentReducers
    )
  ]
})
export class BillingSchedulepaymentDataAccessModule {}

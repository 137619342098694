import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { get as _get } from 'lodash';
import { filter, take } from 'rxjs/operators';

import { AnalyticsService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';
import { Subject } from 'rxjs';
import { ForgotPasswordAnalytics } from '../shared/forgot-password-analytic-constants';

import { AnalyticsActions } from '@amfam/shared/analytics';
import { ACTIONTYPE } from '@amfam/shared/analytics/src/lib/models/analytics.model';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/store';
import * as resetPasswordActions from '../../../core/store/reset-password/reset-password.actions';

@Component({
  selector: 'ds-accept-userid',
  templateUrl: './accept-userid.component.html',
  styleUrls: ['./accept-userid.component.scss']
})
export class AcceptUseridComponent implements OnInit, OnDestroy {
  private lockedUserIdToPrefill: string;
  private stop$: Subject<void> = new Subject<void>();

  responseReceived = true;
  username: AbstractControl;
  acceptUseridForm: UntypedFormGroup;
  userIdError: any;
  userIdSystemError: boolean;
  isDuplicateEmailError: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<fromRoot.RootState>,
    private spinner: LoadingSpinnerService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    // Check if locked userid already exists in store and prefill if so
    this.store
      .select(fromRoot.getResetPasswordLockedUserId)
      .pipe(take(1))
      .subscribe(userId => {
        if (userId) {
          this.lockedUserIdToPrefill = userId;
        }
      });

    this.acceptUseridForm = this.formBuilder.group({
      username: [this.lockedUserIdToPrefill || '', Validators.compose([Validators.required])]
    });
    this.username = this.acceptUseridForm.controls['username'];
    this.analyticsService.trackPageAndEvent(
      ForgotPasswordAnalytics.pageForgotPasswordStart,
      ForgotPasswordAnalytics.eventForgotPasswordStart
    );
  }

  checkIfUserIdExists() {
    this.spinner.start();
    this.resetErrorStrings();
    const trimmedUserIdentifier = this.username.value.trim();
    this.store.dispatch(
      AnalyticsActions.sendDynatraceAction({
        payload: {
          actionName: trimmedUserIdentifier,
          actionType: ACTIONTYPE.FORGOTPASSWORD
        }
      })
    );
    this.store.dispatch(new resetPasswordActions.LoadUserDataAction(trimmedUserIdentifier));

    this.store
      .select(fromRoot.getResetPasswordState)
      .pipe(
        filter(state => !state.loading),
        take(1)
      )
      .subscribe(state => {
        this.spinner.stop();
        // If userid is valid, go to reset methods
        // A 423 code indicates that the userid is locked, which is considered valid
        const statusCode = _get(state, 'userDetails.status.code');
        if (statusCode === 200 || statusCode === 423) {
          this.store.dispatch(
            new fromRouterActions.Go({
              path: ['forgot-password/reset-options']
            })
          );
        } else if (statusCode === 404) {
          this.userIdError = true;
        } else if (statusCode === 409) {
          this.isDuplicateEmailError = true;
        } else {
          this.userIdSystemError = true;
        }
        this.responseReceived = true;
      });
  }

  resetErrorStrings() {
    this.isDuplicateEmailError = false;
    this.userIdError = false;
    this.userIdSystemError = false;
  }

  ngOnDestroy() {
    this.spinner.stop();
    this.stop$.next();
    this.stop$.complete();
  }
}

import * as kydSmartphoneEnrollSummaryActions from './kyd-smartphone-enrollment-summary.actions';
import { Entities, initialEntities } from '../../entity/entity.model';

import { keyBy as _keyBy } from 'lodash';
import { KydSmartphoneSummary } from './models/kyd-smartphone-summary.model';

export function reducer(
  state = initialEntities<KydSmartphoneSummary>(),
  action: kydSmartphoneEnrollSummaryActions.Actions
): Entities<KydSmartphoneSummary> {
  let entities: {};

  switch (action.type) {
    case kydSmartphoneEnrollSummaryActions.LOAD:
      return Object.assign({}, state, {
        loading: true
      });

    case kydSmartphoneEnrollSummaryActions.LOAD_COMPLETE:
      return Object.assign({}, state, {
        loading: false
      });

    case kydSmartphoneEnrollSummaryActions.LOAD_SUCCESS:
      entities = _keyBy(action.payload || [], enrollmentSummary => enrollmentSummary.policyNumber);

      entities = Object.assign({}, state.entities || {}, entities);

      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities,
        loaded: true,
        loading: false
      });

    case kydSmartphoneEnrollSummaryActions.LOAD_FAIL:
      return Object.assign({}, state, {
        loaded: false,
        loading: false
      });

    default:
      return state;
  }
}

export const getEntities = (state: Entities<KydSmartphoneSummary>) => state.entities;

export const getIds = (state: Entities<KydSmartphoneSummary>) => state.ids;

export const getError = (state: Entities<KydSmartphoneSummary>) => state.error;

export const getLoaded = (state: Entities<KydSmartphoneSummary>) => state.loaded;

export const getLoading = (state: Entities<KydSmartphoneSummary>) => state.loading;

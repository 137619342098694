<div [formGroup]="formGroup">
  <!-- Card Number -->
  <label for="routingNumber" class="subheading">Card number</label>
  <div class="margin-bottom-30">
    <div class="row flex items-center">
      <ds-form-control-container [control]="formGroup.get('cardNumber')">
        <input
          type="text"
          id="cardNumber"
          class="fill"
          formControlName="cardNumber"
          maxlength="23"
          ccMask
          disableCopyPaste
          autocomplete="off"
          data-cy="cardNum"
          tabindex="1"
        />
      </ds-form-control-container>
      <div class="margin-top-10">
        <ds-credit-card-icon
          [ccIconHeight]="40"
          [ccNumberCtrl]="formGroup.get('cardNumber')"
        ></ds-credit-card-icon>
      </div>
    </div>
    <ds-form-control-message [control]="formGroup.get('cardNumber')"></ds-form-control-message>
  </div>

  <!--Expiration Date-->
  <label for="routingNumber" class="subheading">Expiration date</label>
  <div class="margin-bottom-30">
    <ds-form-control-container [control]="formGroup.get('expirationDate')">
      <input
        type="text"
        id="expirationDate"
        class="fill"
        formControlName="expirationDate"
        maxlength="5"
        expirationDateMask
        autocomplete="off"
        data-cy="expDate"
        tabindex="2"
      />
    </ds-form-control-container>
    <ds-form-control-message [control]="formGroup.get('expirationDate')"></ds-form-control-message>
  </div>

  <!-- Nickname -->
  <label for="routingNumber" class="subheading">Payment method nickname</label>
  <div class="margin-bottom-30">
    <ds-form-control-container [control]="formGroup.get('nickName')">
      <input
        type="text"
        id="nickName"
        data-cy="nickName"
        class="fill"
        formControlName="nickName"
        tabindex="3"
      />
    </ds-form-control-container>
    <ds-form-control-message [control]="formGroup.get('nickName')"></ds-form-control-message>
  </div>

  <!--Criteria List-->
  <div class="margin-bottom-30">
    <ds-criteria-list [criteriaItems]="criteria | async"> </ds-criteria-list>
  </div>
</div>

export interface ReplaceVehiclePolicy {
  policyNumber: string;
  effectiveDate: Date;
  vehicleId: string;
}

export const initialReplaceVehiclePolicy = {
  policyNumber: null,
  effectiveDate: null,
  vehicleId: null
};

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ValidationService } from '@amfam/ui-kit';
import { PartyPhone, PartyPhoneUsageCodes, PartyPhoneUsageCode } from '@amfam/shared/models';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { get as _get } from 'lodash';

@Component({
  selector: 'phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.scss']
})
export class PhoneListComponent implements OnInit {
  phoneListForm: UntypedFormGroup;
  usageCodes: PartyPhoneUsageCode[] = new PartyPhoneUsageCodes().usageCodes;
  phone: PartyPhone;
  isAddingPhone = false;
  makePrimaryOnSave = false;

  private defaultCode: PartyPhoneUsageCode = this.usageCodes[0];

  @Input() phones: PartyPhone[];
  @Input() allPrimaryCheck: boolean;
  // emit a new phone to be saved
  @Output() createEvent: EventEmitter<PartyPhone> = new EventEmitter();
  // passes up value from phone-list-item
  @Output() deleteEvent: EventEmitter<PartyPhone> = new EventEmitter();
  // Make phone number primary
  @Output() makePrimaryEvent: EventEmitter<PartyPhone> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder, public roleService: ImpersonateRolesService) {}

  ngOnInit() {
    this.buildForm();
  }

  // pass up id of entry to delete from child
  onDelete(phone: PartyPhone) {
    this.deleteEvent.emit(phone);
  }

  onMakePrimary(phone: PartyPhone) {
    this.makePrimaryEvent.emit(phone);
  }

  open() {
    this.makePrimaryOnSave = false;
    this.toggleForm();
  }

  // create new entry from form values and emit to parent
  addPhoneNumber() {
    const phone = PartyPhone.fromForm(this.phoneListForm);
    this.createEvent.emit(phone);
    this.toggleForm();
    this.resetForm();
  }

  cancel() {
    this.toggleForm();
    this.resetForm();
  }

  private buildForm() {
    this.phoneListForm = this.formBuilder.group(
      {
        phoneNumber: ['', [ValidationService.phoneNumberValidator, Validators.required]],
        extension: ['', [ValidationService.phoneNumberExtensionValidator]],
        typeOfUsageCode: [this.defaultCode, [Validators.required]],
        descriptionForOtherType: [''],
        primaryChecked: [this.makePrimaryOnSave]
      },
      {
        validator: Validators.compose([
          ValidationService.descIsRequired('typeOfUsageCode', 'descriptionForOtherType')
        ])
      }
    );
  }

  private resetForm() {
    this.phoneListForm.reset({
      phoneNumber: '',
      extension: '',
      typeOfUsageCode: this.defaultCode,
      descriptionForOtherType: '',
      primaryChecked: false
    });
  }

  // hide/show the form
  private toggleForm() {
    this.isAddingPhone = !this.isAddingPhone;
  }
}

import { Claim, claimQuery } from '@amfam/claim/data-access';
import { PolicySelectors } from '@amfam/policy/data-access';
import { userQuery, UserState } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import {
  CALLING_CONTEXT,
  CALLING_SUB_CONTEXT,
  ClaimEnterprise
} from '../models/claim-enterprise.model';

@Injectable({ providedIn: 'root' })
export class ClaimEnterpriseService {
  errorPageRedirectPath: string;
  errorPageRedirectBtnText: string;

  constructor(private config: ConfigService, private http: HttpClient, private store: Store) {}

  public createClaim(
    requestObj: ClaimEnterprise,
    isProperty: boolean
  ): Observable<ClaimEnterprise> {
    const configApiKey = isProperty ? 'claimEnterpriseApiv2' : 'claimEnterpriseApi';
    return this.http.post<ClaimEnterprise>(this.config.get(configApiKey) + 'create', requestObj);
  }

  public buildPayload(
    type: CALLING_CONTEXT,
    subContext: CALLING_SUB_CONTEXT,
    claimNumber?: string
  ) {
    return combineLatest([
      this.store.select(BrandSelectors.getCompany),
      this.store.select(BrandSelectors.getPartnerId),
      this.store.select(BrandSelectors.getAccountId),
      this.store.select(userQuery.getUserState),
      this.store.select(PolicySelectors.getAdvanceAutoPolicies),
      this.store.select(PolicySelectors.getAdvanceAutoPolicyRisks),
      this.store.select(claimQuery.getSelectedClaim),
      this.store.select(userQuery.getPrimaryPhone),
      this.store.select(PolicySelectors.getClaimablePolicyRisks)
    ]).pipe(
      map(
        ([
          company,
          partnerId,
          accountId,
          userState,
          policies,
          risks,
          selectedClaim,
          phone,
          policy
        ]) => ({
          company,
          partnerId,
          accountId,
          userState,
          policies,
          risks,
          selectedClaim,
          phone,
          policy
        })
      ),
      take(1),
      mergeMap(response => {
        const allPolicyList = new Set([]);

        response.policy.forEach(element => {
          if (element.policyNumber) {
            allPolicyList.add(element.policyNumber);
          }
        });

        const listPolicy = Array.from(allPolicyList).map(policyNumber => ({
          policyNumber,
          isE1P: false
        }));

        const userState = response.userState as UserState;
        let phoneNumber = 'None';
        if (
          _get(response.phone, 'areaCode', '') !== '' &&
          _get(response.phone, 'phoneNumber', '') !== ''
        ) {
          phoneNumber = _get(response.phone, 'areaCode') + _get(response.phone, 'phoneNumber');
        }

        const payloadObj = {
          callingContext: type,
          distributionChannel: {
            partnerId: response.partnerId,
            opco: 'amfam',
            partnerName: response.company
          },
          callingSubContext: subContext,
          claimsInfo: {
            fullClaimNumber: claimNumber
              ? claimNumber
              : response.selectedClaim === undefined ||
                (response.selectedClaim as Claim).claimNumber === ''
              ? null
              : (response.selectedClaim as Claim).claimNumber,
            policies: listPolicy
          },
          customerInfo: {
            firstName: userState.firstName,
            lastName: userState.lastName,
            phoneNumber: phoneNumber,
            emailAddress: userState.emailAddress,
            userId: userState.customerId,
            roleType: 'customer'
          }
        };

        return of(payloadObj);
      })
    );
  }

  public buildFNOLPayload(type: CALLING_CONTEXT, subContext: CALLING_SUB_CONTEXT) {
    return combineLatest([
      this.store.select(BrandSelectors.getCompany),

      this.store.select(BrandSelectors.getPartnerId),
      this.store.select(BrandSelectors.getAccountId),
      this.store.select(userQuery.getUserState),
      this.store.select(PolicySelectors.getAdvanceAutoPolicies),
      this.store.select(PolicySelectors.getAdvanceAutoPolicyRisks),
      this.store.select(claimQuery.getSelectedClaim),
      this.store.select(userQuery.getPrimaryPhone),
      this.store.select(PolicySelectors.getPolicies)
    ]).pipe(
      map(
        ([
          company,
          partnerId,
          accountId,
          userState,
          policies,
          risks,
          selectedClaim,
          phone,
          policy
        ]) => ({
          company,
          partnerId,
          accountId,
          userState,
          policies,
          risks,
          selectedClaim,
          phone,
          policy
        })
      ),
      take(1),
      mergeMap(response => {
        const listPolicy = [];

        response.policy.forEach(element => {
          if (element.policyNumber) {
            listPolicy.push({
              policyNumber: element.policyNumber,
              isE1P: false
            });
          }
        });

        const userState = response.userState as UserState;
        let phoneNumber = 'None';
        if (
          _get(response.phone, 'areaCode', '') !== '' &&
          _get(response.phone, 'phoneNumber', '') !== ''
        ) {
          phoneNumber = _get(response.phone, 'areaCode') + _get(response.phone, 'phoneNumber');
        }

        const payloadObj = {
          callingContext: type,
          distributionChannel: {
            partnerId: response.partnerId,
            opco: 'amfam',
            partnerName: response.company
          },
          callingSubContext: subContext,
          claimsInfo: {
            policies: listPolicy
          },
          customerInfo: {
            firstName: userState.firstName,
            lastName: userState.firstName,
            phoneNumber: phoneNumber,
            emailAddress: userState.emailAddress,
            userId: userState.customerId,
            roleType: 'customer'
          }
        };

        return of(payloadObj);
      })
    );
  }
}

import { SummariesAction, SummariesActionTypes } from './summaries.actions';
import { ApiStatus, initialApiStatus } from '@amfam/shared/models';
import { get as _get } from 'lodash';

export interface NotificationState {
  error: ApiStatus | null;
  success: ApiStatus | null;
  loading: boolean;
}

export const initialState: NotificationState = {
  loading: null,
  error: null,
  success: null
};

export function reducer(
  state: NotificationState = initialState,
  action: SummariesAction
): NotificationState {
  switch (action.type) {
    case SummariesActionTypes.LoadSummaries: {
      return { ...state, loading: true };
    }

    case SummariesActionTypes.SummariesLoadStatusNotFound:
    case SummariesActionTypes.SummariesLoadError: {
      return { ...state, loading: false, error: action.payload };
    }

    case SummariesActionTypes.SummariesLoadSuccess:
    case SummariesActionTypes.SummariesLoaded: {
      return { ...state, loading: false, error: null, success: action.payload };
    }

    default: {
      return state;
    }
  }
}

import { DigitalAccountActions, DigitalAccountActionTypes } from './digital-account.actions';
import { DigitalAccount } from './digital-account.model';
import { get as _get } from 'lodash';

export interface DigitalAccountState {
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
  error: any;
  digitalAccount: DigitalAccount;
  correlationId: string;
  emailAssingedToOthers: boolean;
}

export const initialState: DigitalAccountState = {
  loaded: false,
  loading: false,
  hasError: false,
  error: null,
  digitalAccount: null,
  correlationId: '',
  emailAssingedToOthers: false
};

export function reducer(state = initialState, action: DigitalAccountActions): DigitalAccountState {
  switch (action.type) {
    case DigitalAccountActionTypes.CreateDigitalAccount:
    case DigitalAccountActionTypes.GetDigitalAccountByCdhId:
    case DigitalAccountActionTypes.GetDigitalAccountByWaid:
    case DigitalAccountActionTypes.UpdateDigitalAccount:
    case DigitalAccountActionTypes.Refresh:
      return Object.assign({}, state, {
        hasError: false,
        loaded: false,
        loading: true
      });
    case DigitalAccountActionTypes.CheckEmailAddress:
      return Object.assign({}, state, {
        hasError: false,
        loaded: false,
        loading: true,
        emailAssignedToOthers: false
      });

    case DigitalAccountActionTypes.GetDigitalAccountByCdhIdSuccess:
    case DigitalAccountActionTypes.GetDigitalAccountByWaidSuccess:
      return Object.assign({}, state, {
        hasError: false,
        loaded: true,
        loading: false,
        digitalAccount: action.payload
      });

    case DigitalAccountActionTypes.CreateDigitalAccountSuccess:
    case DigitalAccountActionTypes.UpdateDigitalAccountSuccess:
    case DigitalAccountActionTypes.RefreshSuccess:
      return Object.assign({}, state, {
        hasError: false,
        loaded: true,
        loading: false
      });

    case DigitalAccountActionTypes.CreateDigitalAccountFailure:
    case DigitalAccountActionTypes.UpdateDigitalAccountFailure:
    case DigitalAccountActionTypes.GetDigitalAccountByCdhIdFailure:
    case DigitalAccountActionTypes.GetDigitalAccountByWaidFailure:
    case DigitalAccountActionTypes.RefreshFailure:
      return Object.assign({}, state, {
        hasError: true,
        error: action.payload,
        loaded: true,
        loading: false
      });
    case DigitalAccountActionTypes.CheckEmailAddressFailure:
      return Object.assign({}, state, {
        hasError: true,
        error: action.payload,
        loaded: true,
        loading: false,
        emailAssignedToOthers: false
      });

    case DigitalAccountActionTypes.Authenticate:
      return Object.assign({}, state, {
        hasError: false,
        loaded: false,
        loading: true
      });

    case DigitalAccountActionTypes.AuthenticateSuccess:
      return Object.assign({}, state, {
        hasError: false,
        loaded: true,
        loading: false,
        correlationId: _get(action, 'payload.correlationId', '')
      });

    case DigitalAccountActionTypes.AuthenticateFailure:
      return Object.assign({}, state, {
        hasError: true,
        error: action.payload.error,
        loaded: true,
        loading: false,
        correlationId: _get(action, 'payload.correlationId', '')
      });
    case DigitalAccountActionTypes.CheckEmailAddressSuccess:
      return Object.assign({}, state, {
        hasError: false,
        loaded: true,
        loading: false,
        correlationId: _get(action, 'payload.correlationId', ''),
        emailAssingedToOthers: _get(action, 'payload.emailDetail.assignedToAccountIndicator', false)
      });

    default:
      return state;
  }
}

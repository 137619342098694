import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { DockingBarService, DsModalService } from '@amfam/ui-kit';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// Store
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AutoPaySetup, AutoPaySteps } from '../+state/auto-pay-setup.models';
import * as AutoPaySetupSelectors from '../+state/auto-pay-setup.selectors';

@Injectable({
  providedIn: 'root'
})
export class AutoPayGuard  {
  constructor(
    private store: Store,
    private feature: FeatureFlagService,
    private modalService: DsModalService,
    private dockingBar: DockingBarService
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return this.store.select(AutoPaySetupSelectors.getAllAutoPaySetups).pipe(
      take(1),
      map((setups: AutoPaySetup[]) => {
        // feature must be enabled and must have initialized state
        if (setups.length > 0) {
          return true;
        }

        // go back to overview
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/overview']
          })
        );
        return false;
      })
    );
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return combineLatest([
      this.store.select(AutoPaySetupSelectors.getActiveSetup),
      this.store.select(AutoPaySetupSelectors.getActiveStep)
    ]).pipe(
      take(1),
      map(([activeSetup, activeStep]) => {
        // "activeStep" is actually the current step since the store will
        // update before routing starts

        // Allow user to navigate without warning modal if:
        if (
          (activeStep === AutoPaySteps.SKIP && !nextState.url.includes('auto-pay')) ||
          (this.modalService.isOpen('autoPayCancelModal') && !nextState.url.includes('auto-pay')) ||
          // they are on account selection or confimation screen and
          // they want to leave the autopayflow
          ((currentState.url.includes('/billing/auto-pay/select-accounts') ||
            currentState.url.includes('/billing/auto-pay/confirmation')) &&
            !nextState.url.includes('auto-pay')) ||
          // they are routing to the correct next step in the flow
          (activeStep === AutoPaySteps.SELECT_ACCOUNTS &&
            nextState.url.includes('select-accounts')) ||
          (activeStep === AutoPaySteps.SETUP &&
            nextState.url.includes(activeSetup.billAccount.billAccountNumber)) ||
          (activeStep === AutoPaySteps.REVIEW && nextState.url.includes('review')) ||
          (activeStep === AutoPaySteps.CONFIRMATION && nextState.url.includes('confirmation'))
        ) {
          this.modalService.close('autoPayCancelModal');
          return true;
        }
        // DM: yes, this dockingbar is a hack. The dumb service keeps trying to
        // remove it because of "Navigation Start"
        this.dockingBar.registerHeading('Set Up AutoPay');
        this.modalService.open('autoPayCancelModal');
        return false;
      })
    );
  }
}

<overview-section
  *ngIf="agents?.length"
  class="overview-section--programs"
  [sectionHeading]="title"
>
  <overview-section-content>
    <div class=" column twelve margin-bottom-10 " *ngIf="opportunitiesContent?.length === 0">
      <p content [contentPath]="'shared.noOpportunities'"></p>
    </div>
    <ng-container *ngIf="opportunitiesContent?.length > 0">
      <ds-opportunities-cards
        class="flex wrap"
        [opportunitiesContentList]="opportunitiesContent"
        [displayedOpportunitiesLength]="displayedOpportunitiesLengthSlice"
        [isAuthorized]="isAuthorized$ | async"
        (learnMoreEvent)="learnMore($event)"
      ></ds-opportunities-cards>
    </ng-container>
  </overview-section-content>
</overview-section>

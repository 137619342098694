<section class="item">
  <edit-business-name
    [firstName]="firstName"
    [lastName]="lastName"
    [businessName]="businessName"
    [headerCopy]="'businessNameHeaderText'"
  >
  </edit-business-name>

  <active-business-email-manager
    [emailAddress]="businessEmailAddress"
    [headerCopy]="'primaryMyAccountEmail'"
    [mainCaptionCopy]="'businessEmailDefaultText'"
  >
  </active-business-email-manager>
</section>

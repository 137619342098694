import { Injectable, InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken('WindowToken');

export function WindowFactory(): any {
  return window;
}

export abstract class WindowRef {
  get nativeWindow(): any {
    throw new Error('Not implemented');
  }
}
export function openWindowBlob(win, url, res, windowRef, fileName) {
  if (windowRef) {
    windowRef.location.href = url;
  } else {
    win.nativeWindow.open(url, '_blank');
  }
}

export function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }
  get nativeWindow(): any {
    return WindowFactory();
  }
}

export const WINDOW_PROVIDERS = [
  { provide: WindowRef, useClass: BrowserWindowRef },
  { provide: WINDOW, useFactory: WindowFactory, deps: [] }
];

<!-- Card Tile Start -->
<ds-tile class="program-tile" role="card" aria-labelledby="dsTileTitle">
  <!-- Card Header Start -->
  <div class="tile-heading" tile-heading>
    <div class="heading-text wrap">
      <!-- Icon & Card Title Start -->
      <div class="flex items-center">
        <!-- Card Title Start -->
        <!-- Icon Start -->
        <em
          class="heading-icon"
          [class.icon-money]="isBilling"
          [class.icon-mail]="!isBilling"
          [class.success-400-text]="isBilling"
        ></em>
        <!-- Icon End -->
        <!-- Title Start -->
        <span
          class="heading-section"
          id="dsTileTitle"
          content
          [contentPath]="
            isBilling ? 'goPaperless.billingSavingTitle' : 'goPaperless.policySavingTitle'
          "
          data-cy="setPrefsEasyButtonTitle"
        ></span>
        <!-- Title End -->
        <!-- Card Title End -->
      </div>
      <!-- Icon & Card Title End -->
    </div>
  </div>
  <!-- Card Header End -->

  <!-- Card Body Start -->
  <div class="tile-content" tile-content>
    <!-- Card Description Start -->
    <div>
      <p class="margin-bottom-0" data-cy="setPrefsEasyButtonText">
        <span content [contentPath]="'goPaperless.paperlessContentTitle'"></span>
      </p>
    </div>
    <!-- Card Description End -->
    <!-- Card List Items & Policy Terms and Conditions Checkbox Start -->
    <div class="pad-bottom-12 margin-top-10">
      <!-- Card List Items Start -->
      <ul class="complete-list" data-cy="setPrefsEasyButtonList">
        <li
          content
          [contentPath]="
            isBilling ? 'goPaperless.billingSavingList1' : 'goPaperless.policySavingList1'
          "
          [attr.data-cy]="
            isBilling
              ? 'setPrefsEasyButtonListItemBills'
              : 'setPrefsEasyButtonListItemInsuranceCards'
          "
        ></li>
        <li
          content
          [contentPath]="
            isBilling ? 'goPaperless.billingSavingList2' : 'goPaperless.policySavingList2'
          "
          [attr.data-cy]="isBilling ? 'billingSavingList2' : 'setPrefsEasyButtonListCoverageDocs'"
        ></li>
      </ul>
      <!-- Card List Items End -->
      <!-- Policy Terms and Conditions Checkbox Start -->
      <div class="checkbox-wrapper">
        <form [formGroup]="termsAndConditionsForm" class="flex items-center">
          <input
            type="checkbox"
            class="checkbox"
            id="termAgree"
            authCheck
            [permissionName]="'paperlesspolicydocsTandC_accept'"
            [checkAction]="'disable'"
            formControlName="termAgree"
            data-cy="setPrefsEasyButtonTermsCheckbox"
          />
          <label
            for="termAgree"
            class="checkbox-label"
            data-cy="setPrefsEasyButtonTermsCheckboxLabel"
          >
            I agree to the
          </label>
          <a href="javascript:void(0)" (click)="openModal()" data-cy="setPrefsEasyButtonModalLink"
            >Terms and Conditions</a
          >
        </form>
      </div>
      <!-- Policy Terms and Conditions Checkbox End -->
    </div>
    <!-- Card List Items & Policy Terms and Conditions Checkbox End -->
  </div>
  <!-- Card Body End -->
  <!-- Card CTA Start -->
  <div tile-cta class="tile-cta">
    <button
      ds-button
      class="-outline-primary"
      [disabled]="
        termsAndConditionsForm.invalid ||
        termsAndConditionsForm.controls['termAgree'].value === false
      "
      [dsAnalyticsClick]="clickGoPaperlessAnalytics.link"
      (click)="goPaperLess()"
      authCheck
      [permissionName]="'select_cta_edelivery_overviewcard'"
      [checkAction]="'disable'"
      data-cy="setPrefsEasyButtonButton"
    >
      Go Paperless
    </button>
  </div>
  <!-- Card CTA End -->
</ds-tile>
<!-- Card Tile End -->
<!-- Policy Terms and Conditions BoxModal Start -->
<ds-policy-terms-and-conditions [showPaperless]="showPaperless" [(visible)]="showModal">
</ds-policy-terms-and-conditions>
<!-- Policy Terms and Conditions BoxModal End -->

import { OverviewUiModule } from '@amfam/overview/ui';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './+state';
import { OpportunityEffects } from './+state/opportunity.effects';
import { DsOpportunitiesCtaComponent } from './components';
import { OpportunitiesCardsComponent } from './components/opportunities-cards/opportunities-cards.component';
import { OpportunityCardComponent } from './components/opportunity-card/opportunity-card.component';
import { OpportunitiesAgentComponent } from './containers/opportunities-agent/opportunities-agent.component';
import { OpportunitiesConfirmationComponent } from './containers/opportunities-confirmation/opportunities-confirmation.component';
import { OpportunitiesDetailComponent } from './containers/opportunities-detail-page/opportunities-detail-page.component';
import { OpportunitiesDetailSelectionPageComponent } from './containers/opportunities-detail-selection-page/opportunities-detail-selection-page.component';
import { OpportunitiesOverviewComponent } from './containers/opportunities-overview/opportunities-overview.component';
import { OpportunitiesQualifiersComponent } from './containers/opportunities-qualifiers/opportunities-qualifiers.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverviewUiModule,
    UiKitModule,
    SharedServicesModule,
    StoreModule.forFeature('opportunity', reducers),
    EffectsModule.forFeature([OpportunityEffects])
  ],
  declarations: [
    OpportunitiesOverviewComponent,
    OpportunitiesDetailComponent,
    DsOpportunitiesCtaComponent,
    OpportunityCardComponent,
    OpportunitiesCardsComponent,
    OpportunitiesDetailSelectionPageComponent,
    OpportunitiesQualifiersComponent,
    OpportunitiesAgentComponent,
    OpportunitiesConfirmationComponent
  ],
  exports: [
    OpportunitiesOverviewComponent,
    OpportunitiesDetailComponent,
    OpportunitiesDetailSelectionPageComponent
  ]
})
export class OpportunitiesModule {}

import { Injectable, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService implements OnInit {
  private _sidebarPresent$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  ngOnInit() {}

  get sidebarPresent$() {
    // If there isn't a sidebar on the subscribing component's template, set present to false
    // Otherwise use whatever we've been told to use from setSidebarLocation()
    if (!document.body.querySelector('.aside-wrapper')) {
      this._sidebarPresent$.next(false);
    }
    return this._sidebarPresent$.asObservable();
  }

  public setSidebarLocation(present: boolean) {
    this._sidebarPresent$.next(present);
  }
}

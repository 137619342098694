import { Action } from '@ngrx/store';
import { GetPaymentHistoryPayload, Payment } from '@amfam/shared/models';

export enum PaymentHistoryActionTypes {
  GetPayments = '[Payment History] Get Payments',
  GetPaymentsSuccess = '[PaymentHistory] Get Payments Success',
  GetPaymentsFail = '[PaymentHistory] Get Payments Fail'
}

export class GetPayments implements Action {
  readonly type = PaymentHistoryActionTypes.GetPayments;
  constructor(public payload: GetPaymentHistoryPayload) {}
}

export class GetPaymentsSuccess implements Action {
  readonly type = PaymentHistoryActionTypes.GetPaymentsSuccess;
  constructor(public payload: Payment[]) {}
}

export class GetPaymentsFail implements Action {
  readonly type = PaymentHistoryActionTypes.GetPaymentsFail;
  constructor(public payload?: any) {}
}

export type PaymentHistoryActions = GetPayments | GetPaymentsSuccess | GetPaymentsFail;

export const fromPaymentHistoryActions = {
  GetPayments,
  GetPaymentsSuccess,
  GetPaymentsFail
};

import { PaymentMethod } from '@amfam/billing/payment-method/util';
import { Component, Input, OnChanges } from '@angular/core';
import { has as _has } from 'lodash';

@Component({
  selector: 'ds-payment-method-card',
  templateUrl: './payment-method-card.component.html',
  styleUrls: ['./payment-method-card.component.scss']
})
export class PaymentMethodCardComponent implements OnChanges {
  @Input() paymentAccount: PaymentMethod;
  @Input() showIcon: boolean = true;

  public icon: string;
  public title: string;
  public accountNumber: string;
  public accountType: string;
  public creditCardIcon: boolean;

  constructor() {}

  ngOnChanges() {
    this.title = this.paymentAccount?.nickName;
    // Derive the icon and account number
    if (_has(this.paymentAccount, 'creditCard.cardType')) {
      this.icon = this.paymentAccount.creditCard.cardType;
      this.accountType = this.paymentAccount.creditCard.cardType;
      this.accountNumber = this.paymentAccount.creditCard.cardNumber;
      this.creditCardIcon = true;
    } else if (_has(this.paymentAccount, 'achWithdrawal')) {
      this.accountType = this.paymentAccount.achWithdrawal.accountType;
      this.accountNumber = this.paymentAccount.achWithdrawal.accountNumber;
      this.creditCardIcon = false;
    }
  }
}

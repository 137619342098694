<form [formGroup]="form" (ngSubmit)="submitSSN(form.controls['ssn'].value)">
  <div class="row">
    <div class="column twelve md-eight">
      <ds-form-control-container [labelText]="'Last Four of SSN'" [control]="form.controls['ssn']">
        <input
          data-cy="lastFourOfSSNEnrollmentConfirmationInput"
          formControlName="ssn"
          digitMask
          type="tel"
          maxlength="4"
          class="fill"
        />
        <ds-form-control-message [control]="form.controls['ssn']"></ds-form-control-message>
      </ds-form-control-container>
    </div>
    <div class="column twelve md-four">
      <div class="md-pad-left-10 pad-top-10 pad-bottom-10">
        <button
          data-cy="lastFourOfSSNEnrollmentContinueButton"
          ds-button
          content
          [contentPath]="'shared.nextBtn'"
          type="submit"
          [disabled]="!form.valid"
        ></button>
      </div>
    </div>
  </div>
</form>

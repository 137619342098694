import { Action, createReducer, on } from '@ngrx/store';
import {
  completeOverviewPaperlessEnrollment,
  initiateOverviewPaperlessEnrollment
} from './overview-paperless-enrollment-feature-actions';
export const paperlessEnrollmentFeatureKey = 'paperlessEnrollmentFeature';

export interface PaperlessOverviewEnrollmentFeatureState {
  overviewFlow: boolean;
  correlationId: string;
}

export const initialState: PaperlessOverviewEnrollmentFeatureState = {
  overviewFlow: false,
  correlationId: ''
};

export function reducer(
  state: PaperlessOverviewEnrollmentFeatureState | undefined,
  action: Action
): any {
  return mapReducer(state, action);
}

const mapReducer = createReducer(
  initialState,

  on(initiateOverviewPaperlessEnrollment, (state, {correlationId }):PaperlessOverviewEnrollmentFeatureState => ({
    ...state,
    overviewFlow: true,
    correlationId: correlationId

  })),

  on(completeOverviewPaperlessEnrollment, (state):PaperlessOverviewEnrollmentFeatureState => ({
    ...state,
    overviewFlow: false,
    correlationId: ''
  }))
);

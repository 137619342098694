import { CustomerEnrollment } from './customer-enrollment.model';

export class Program {
  icon: string;
  isPaymentTerms: boolean;

  constructor(
    public title: string,
    public enrollment: CustomerEnrollment,
    public terms: string = '',
    public termsUrl: string = ''
  ) {
    this.isPaymentTerms = title === 'Automatic Payments';
  }
}

<div class="row overview-section__header">
  <div class="column twelve sm-eight">
    <h2 class="overview-section__heading font-weight-bold">{{ sectionHeading }}</h2>
  </div>
  <div class="column twelve sm-four">
    <div class="overview-section__link">
      <ng-content select="overview-section-header-link"></ng-content>
    </div>
  </div>
</div>

<ds-localized-spinner
  class="block pad-bottom-20"
  [dataLoading]="loading"
  [loadingMessage]="spinnerText"
>
</ds-localized-spinner>

<ng-content select="overview-section-content"></ng-content>

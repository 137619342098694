import { createFeatureSelector, createSelector, ActionReducerMap } from '@ngrx/store';
import { DOCUMENTS_FEATURE_KEY } from './documents.reducer';
import * as fromDocumentsState from './documents.reducer';
import * as fromDocumentsNotifications from './notifications.reducer';
import { get as _get, isEmpty as _isEmpty, map as _map } from 'lodash';
import { Dictionary } from '@ngrx/entity';
import { Policy, PolicyDocument } from '@amfam/policy/models';
import { POLICIES_FEATURE_KEY } from '../policies/policies.reducer';
import { PoliciesState, getPolicies } from '../policies/policies.selectors';
export interface DocumentsState {
  state: fromDocumentsState.PolicyDocumentsEntityState;
  notifications: fromDocumentsNotifications.NotificationState;
}

export const documentsReducers: ActionReducerMap<DocumentsState> = {
  state: fromDocumentsState.reducer,
  notifications: fromDocumentsNotifications.reducer
};

// Lookup the 'Documents' feature state managed by NgRx
const getDocumentsState = createFeatureSelector<PoliciesState>(POLICIES_FEATURE_KEY);

const getLoaded = createSelector(
  getDocumentsState,
  (state: PoliciesState) => !_get(state, 'documentsNotifications.loading')
);

const getError = createSelector(getDocumentsState, (state: PoliciesState) =>
  _get(state, 'documentsNotifications.error')
);

const getAllDocuments = createSelector(
  getDocumentsState,
  getLoaded,
  (state: PoliciesState, isLoaded) => {
    return isLoaded ? fromDocumentsState.selectAll(state.documentsState) : [];
  }
);

export const getPolicyDocumentEntities = createSelector(
  getDocumentsState,
  getLoaded,
  (state: PoliciesState, isLoaded): Dictionary<PolicyDocument> => {
    if (isLoaded) {
      return fromDocumentsState.selectEntities(state.documentsState);
    }
  }
);

export const getPolicyDocumentIds = createSelector(
  getDocumentsState,
  getLoaded,
  (state: PoliciesState, isLoaded): number[] => {
    if (isLoaded) {
      return <number[]>fromDocumentsState.selectIds(state.documentsState);
    }
    return [];
  }
);

export const getPolicyDocumentNotifications = createSelector(
  getPolicyDocumentEntities,
  getPolicies,
  (entities, policies: Policy[]) => {
    if (_isEmpty(entities)) {
      return false;
    }
    const notificationsObj = {};
    let notifications: object[] | boolean;
    // Build list of notifications
    for (const doc of Object.values(entities)) {
      const docPolicyNumber = (doc.policyNumber || '').replace(/[^a-zA-Z0-9]/g, '');
      if (doc.isNew && policies.length) {
        if (notificationsObj[docPolicyNumber]) {
          notificationsObj[docPolicyNumber].number += 1;
        } else {
          const policyType = policies.filter(policy => policy.policyNumber === docPolicyNumber)[0]
            .policyType;
          notificationsObj[docPolicyNumber] = { number: 1, policyType: policyType, ids: [] };
        }
        notificationsObj[docPolicyNumber].ids.push(doc.id);
      }
    }
    // Return false if no notifications, else create array from object
    if (!Object.keys(notificationsObj).length) {
      notifications = false;
    } else {
      notifications = _map(notificationsObj, (notification, policyNum) => {
        return Object.assign({}, notification, { policyNumber: policyNum });
      });
    }
    return notifications;
  }
);

export const getPolicyDocumentsLoading = createSelector(getDocumentsState, (state: PoliciesState) =>
  _get(state, 'documentsNotifications.loading')
);

export const getPolicyDocumentsLoaded = createSelector(
  getDocumentsState,
  (state: PoliciesState) => !_get(state, 'documentsNotifications.loading')
);

export const getPolicyDocuments = createSelector(
  getPolicyDocumentEntities,
  getPolicyDocumentIds,
  (entities, ids) => {
    return ids.map(id => entities[id]);
  }
);

export const getPolicyDocumentsWithState = createSelector(
  getPolicyDocuments,
  getPolicyDocumentsLoading,
  (policyDocuments, policyDocumentsLoading) => {
    return {
      policyDocuments: policyDocuments,
      policyDocumentsLoading: policyDocumentsLoading
    };
  }
);

export const documentsQuery = {
  getLoaded,
  getError,
  getAllDocuments,
  getPolicyDocumentsWithState,
  getPolicyDocuments,
  getPolicyDocumentsLoaded,
  getPolicyDocumentsLoading,
  getPolicyDocumentIds,
  getPolicyDocumentEntities,
  getPolicyDocumentNotifications
};

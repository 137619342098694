import { PolicyStatus } from '@amfam/policy/models';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap as _flatMap, get as _get } from 'lodash';
import { POLICIES_FEATURE_KEY } from '../policies/policies.reducer';
import { PoliciesState } from '../policies/policies.selectors';
import * as fromSummariesNotifications from './notifications.reducer';
import * as fromSummariesState from './summaries.reducer';
export interface SummariesState {
  state: fromSummariesState.PolicySummariesEntityState;
  notifications: fromSummariesNotifications.NotificationState;
}

export const summaryReducers: ActionReducerMap<SummariesState> = {
  state: fromSummariesState.reducer,
  notifications: fromSummariesNotifications.reducer
};

// Lookup the 'Summaries' feature state managed by NgRx
export const getPolicySummariesState = createFeatureSelector<PoliciesState>(POLICIES_FEATURE_KEY);

export const getLoaded = createSelector(
  getPolicySummariesState,
  (state: PoliciesState) =>
    !_get(state, 'summariesNotification.loading') &&
    _get(state, 'summariesNotification.loading') !== null
);

export const getPolicySummariesHasError = createSelector(
  getPolicySummariesState,
  (state: PoliciesState) => !!_get(state, 'summariesNotification.error')
);

export const getAllPolicySummaries = createSelector(
  getPolicySummariesState,
  getLoaded,
  (summariesState: PoliciesState, isLoaded) => {
    const selectedSummaries = fromSummariesState.selectAll(summariesState.summariesState);
    // return [];
    return isLoaded ? fromSummariesState.selectAll(summariesState.summariesState) : [];
  }
);

export const getPolicySummaryLoading = createSelector(
  getPolicySummariesState,
  (state: PoliciesState) => !!_get(state, 'summariesNotification.loading')
);

export const getPolicySummaryEntities = createSelector(
  getPolicySummariesState,
  (state: PoliciesState) => _get(state, 'summariesState.entities')
);

export const getPolicySummaryIds = createSelector(getPolicySummariesState, (state: PoliciesState) =>
  _get(state, 'summariesState.ids')
);

export const getPolicySummaries = createSelector(getAllPolicySummaries, policies => {
  return policies.filter(
    policy =>
      policy.policyStatus !== PolicyStatus.Cancelled && policy.policyStatus !== PolicyStatus.Other
  );
});

export const getPolicyList = createSelector(getPolicySummaries, policies => {
  return _flatMap(policies, policy => policy.policyNumber);
});

export const getNonOperatorPolicyList = createSelector(getPolicySummaries, policies => {
  return policies.filter(policy => !policy.operator).map(policy => policy.policyNumber)
});

export const getOperatorPolicies = createSelector(getPolicySummaries, policies => {
  return policies.filter(policy => policy.operator);
});

export const getHomesitePolicies = createSelector(getAllPolicySummaries, policies => {
  return policies.filter(policy => policy.sourcePath === 'HOMESITE');
});

export const hasHomesitePolicy = createSelector(getHomesitePolicies, policies => {
  return policies && policies.length && policies.length > 0;
});

export const getProducerId = createSelector(getPolicySummaries, (summaries = []) => {
  return summaries && summaries.length > 0 ? summaries[0].servicingProducerId : '10007';
});

export const getNonOperatorPolicySummaries = createSelector(getPolicySummaries, policies => {
  return policies.filter(policy => !policy.operator)
});

export const summariesQuery = {
  getLoaded,
  hasHomesitePolicy,
  getHomesitePolicies,
  getPolicySummaries,
  getPolicySummaryIds,
  getPolicySummaryEntities,
  getPolicySummaryLoading,
  getAllPolicySummaries,
  getPolicySummariesHasError,
  getPolicySummariesState,
  getProducerId,
  getPolicyList,
  getNonOperatorPolicyList,
  getNonOperatorPolicySummaries,
  getOperatorPolicies
};

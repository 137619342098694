// The file contents for the defualt environment that may be overwritten during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
export const environment = {
  production: false,
  devMode: true,
  suppressErrorPage: true,
  billingCareCentreEmail: 'QA-DIGITALMAILBOXWB@amfam.com', // Where the email ultimately goes
  billingPersonalLinesEmail: 'QA-DIGITALMAILBOXWB@amfam.com', // Where the email ultimately goes
  billingCommercialLinesEmail: 'QA-DIGITALMAILBOXWB@amfam.com', // Where the email ultimately goes
  billingInternalFromEmail: 'OnlineBillingInquiry@amfam.com', // Who the email appears to be sent from
  policyChangeRequestInternal: 'QA-DIGITAL@amfam.com',
  policyGracePeriodNotificatonInternal: 'QA-DIGITAL@amfam.com',
  policyGracePeriodNotificatonInternalAgencyMailbox: 'QA-DIGITALMAILBOXWB@amfam.com',
  claimContantAdjusterInternal: 'DMSIMAILADJUSTER-L@amfam.com',
  claimReportClaimInternal: 'DMSICLAIMSMAIL-L@amfam.com',
  partnerBillingEmail: 'PARTNERBILLINGMAIL@amfam.com',
  partnerClaimEmail: 'PARTNERCLAIMMAIL@amfam.com',
  partnerAdjusterEmail: 'PARTNERADJUSTMAIL@amfam.com',
  partnerLicensedAdvisorEmail: 'amfamqa@homesite.com', // amfamqa@homesite.com - override to test locally
  gaProperty: 'UA-64816202-9',
  currentDate: new Date()
};

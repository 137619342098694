import {
  AddEditPaymentMethodPayload,
  FinancialAccountActions,
  PaymentMethodActions,
  PaymentMethodFacade,
  PaymentMethodSelectors,
  SavePaymentMethodPayload
} from '@amfam/billing/payment-method/data-access';
import {
  PaymentMethodAddEditConfig,
  PaymentMethodAddEditOperationType,
  PaymentMethodFinancialType,
  PaymentMethodUtilService
} from '@amfam/billing/payment-method/util';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import {
  ApplicationService,
  Applications,
  ConfigService,
  CopyService,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { DsModalService, LoadingSpinnerService, ValidationService } from '@amfam/ui-kit';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'ds-credit-debit-add-edit-wrapper',
  templateUrl: './credit-debit-add-edit-wrapper.component.html',
  styleUrls: ['./credit-debit-add-edit-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditDebitAddEditWrapperComponent implements OnDestroy, OnChanges, OnInit {
  @Input() paymentMethodAddEditConfig: PaymentMethodAddEditConfig;
  @Input() displayDiscountWarning: boolean = false;
  @Input() showTryAgainButton: boolean = false;
  @Output() paymentMethodAddEditCompleteEvent: EventEmitter<string> = new EventEmitter<string>();

  cardForm: UntypedFormGroup;
  heading: string;
  isEditFlow: boolean;
  autoPayWarningHeading: string;
  autoPayWarningBody: string;
  paymentMethodError: Observable<boolean>;
  authorizedToSubmit$: Observable<boolean>;
  primaryButtonName: string;
  tertiaryButtonName: string;
  failureMessage: string;
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private paymentMethodFacade: PaymentMethodFacade,
    private store: Store,
    private config: ConfigService,
    private paymentMethodUtil: PaymentMethodUtilService,
    private utilService: UtilService,
    private spinner: LoadingSpinnerService,
    private copyService: CopyService,
    private roleService: ImpersonateRolesService,
    private appService: ApplicationService,
    private modalService: DsModalService
  ) {}

  // need OnChanges to reset the form because closing
  // the modal does not destroy the dom element
  ngOnChanges() {
    // heading
    this.heading = this.appService.isApp(Applications.MYACCOUNT_ADMIN)
      ? this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? 'Edit card info'
        : 'Enter their card info'
      : this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
      ? 'Edit card info'
      : 'Enter your card info';

    this.isEditFlow =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? true
        : false;

    this.failureMessage = this.copyService.getCopy(
      `billing.paymentMethodStandAlone.${this.paymentMethodAddEditConfig.operation}FailureMessage`
    );

    // Default CTA buttons
    this.primaryButtonName = 'Save';
    this.tertiaryButtonName = null;
    // error handling
    this.store.dispatch(PaymentMethodActions.resetErrorState());
    this.store.dispatch(FinancialAccountActions.resetErrorState());
    this.paymentMethodError = this.store.select(PaymentMethodSelectors.hasAnyError);

    // create form
    this.cardForm = this.formBuilder.group({
      cardNumber: [
        '',
        Validators.compose([Validators.required, ValidationService.creditCardValidator])
      ],
      expirationDate: [
        '',
        Validators.compose([Validators.required, ValidationService.expirationDateInputValidator])
      ],
      nickName: [
        _get(this.paymentMethodAddEditConfig, 'paymentMethod.nickName', ''),
        Validators.compose([
          Validators.required,
          ValidationService.nickNameNumberLengthValidator,
          ValidationService.nickNameValidator
        ])
      ]
    });

    // add duplicate nickname validator
    this.store
      .select(PaymentMethodSelectors.getPaymentMethods)
      .pipe(take(1))
      .subscribe(paymentMethods => {
        // filter out current nickname if editing
        const existingNicknames = paymentMethods
          .map(paymentMethod => paymentMethod.nickName)
          .filter(
            nickname =>
              nickname !== _get(this.paymentMethodAddEditConfig, 'paymentMethod.nickName', '')
          );
        this.cardForm.setValidators(
          ValidationService.nickNameDuplicationValidator('nickName', existingNicknames)
        );
      });

    this.authorizedToSubmit$ =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.roleService.isAuthorized('editpaymentacct_submit_disable')
        : this.roleService.isAuthorized('makepaymentacct_submit_disable');
  }

  savePaymentMethod() {
    // Send analytics for payment method stand alone experience- showTryAgainButton var is true only for stand alone flow
    if (this.showTryAgainButton) {
      let buttonAnalyticName;
      if (this.primaryButtonName === 'Save') {
        // analytics for save button click
        buttonAnalyticName =
          this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.ADD
            ? 'AddPaymentMethod:SetupCreditDebitCard:Save'
            : 'EditPaymentMethod:EditCard:Save';
      } else {
        // analytics for try again button click
        buttonAnalyticName =
          this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.ADD
            ? 'AddPaymentMethod:SetupDebitCreditCard:Error:TryAgain'
            : 'EditPaymentMethod:SetupDebitCreditCard:Error:TryAgain';
      }
      this.paymentMethodFacade.sendPaymentMethodAnalytics({ buttonAnalyticName });
    }
    // Get payload values
    const lastUpdateTimestamp: string =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.paymentMethodAddEditConfig.paymentMethod.lastUpdateTimestamp
        : '';
    const paymentAccountId: string =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.paymentMethodAddEditConfig.paymentMethod.paymentAccountId
        : '';
    const oldNickName: string =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.paymentMethodAddEditConfig.paymentMethod.nickName
        : null;

    const cardNumber: string = this.cardForm.get('cardNumber').value.replace(/-/g, '');

    // create payload for save account (finAccount API)
    const savePayload: SavePaymentMethodPayload = {
      financialType: PaymentMethodFinancialType.CARD,
      nickName: this.cardForm.get('nickName').value,
      consumerKey: this.config.get('finAcctConsumerKey'),
      creditCard: {
        cardNumber,
        cardType: this.paymentMethodUtil.getCreditCardType(cardNumber),
        expirationDate: this.cardForm.get('expirationDate').value
      }
    };

    // create payload for add/edit account (customerpayment API)
    const addEditPayload: AddEditPaymentMethodPayload = {
      lastUpdateTimestamp,
      paymentAccount: {
        nickName: this.cardForm.get('nickName').value,
        oldNickName,
        token: '', // gets set in effect - comes back from finAccount call
        consumerKey: this.config.get('finAcctConsumerKey')
      }
    };

    // correlationId for tracking when request is complete
    const correlationId: string = this.utilService.generateId();

    // start spinner
    this.spinner.start();

    // start request
    this.paymentMethodFacade.savePaymentMethod(
      this.paymentMethodAddEditConfig.operation,
      savePayload,
      addEditPayload,
      paymentAccountId,
      correlationId
    );

    // on request completion
    this.store
      .select(PaymentMethodSelectors.getPaymentMethodState)
      .pipe(
        filter(state => state.correlationId === correlationId),
        take(1)
      )
      .subscribe(state => {
        this.spinner.stop();
        if (!state.hasError) {
          // emit event to parent with new paymentAccountId
          this.paymentMethodAddEditCompleteEvent.emit(state.newPaymentAccountId);
        } else {
          if (this.showTryAgainButton) {
            // analytics for payment method stand alone experience- showTryAgainButton var is true only for stand alone flow
            let pageAnalyticName =
              this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.ADD
                ? 'AddPaymentMethod:SetupDebitCreditCard:Error'
                : 'EditPaymentMethod:SetupDebitCreditCard:Error';
            this.paymentMethodFacade.sendPaymentMethodAnalytics({ pageAnalyticName });
            this.primaryButtonName = 'Try Again';
            this.tertiaryButtonName = 'Done';
          }
        }
      });
  }

  done() {
    this.modalService.closeAll();
    let buttonAnalyticName =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.ADD
        ? 'AddPaymentMethod:SetupDebitCreditCard:Error:Done'
        : 'EditPaymentMethod:SetupDebitCreditCard:Error:Done';
    this.paymentMethodFacade.sendPaymentMethodAnalytics({ buttonAnalyticName });
  }

  ngOnInit(): void {
    this.autoPayWarningHeading = this.copyService.getCopy(
      'billing.autoPayRefactor.aboutAutoPayDiscountHeader'
    );
    this.autoPayWarningBody = this.appService.isApp(Applications.MYACCOUNT_ADMIN)
      ? this.copyService.getCopy('billing.autoPayRefactor.adminToolLoseAutoPayDiscountMessage')
      : this.copyService.getCopy('billing.autoPayRefactor.loseAutoPayDiscountMessage');
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

import { AutoPaySelectors } from '@amfam/billing/auto-pay/data-access';
import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import {
  BillAccountsSelectors,
  BillAccountsSelectors as fromBillAccounts
} from '@amfam/billing/billaccount/data-access';
import {
  billaccountsQuery,
  BillingState
} from '@amfam/billing/billaccount/data-access/src/lib/+state/billaccount.selectors';
import { BillingNotificationsState } from '@amfam/billing/billaccount/data-access/src/lib/+state/notifications.reducer';
import { PaymentMethodSelectors } from '@amfam/billing/payment-method/data-access';
import { PaymentMethod } from '@amfam/billing/payment-method/util';
import { PaymentAccountSelectors } from '@amfam/billing/paymentaccount/data-access';
import {
  ScheduledPaymentSelectors as fromScheduledPayments,
  ScheduledPaymentSelectors
} from '@amfam/billing/schedulepayment/data-access';
//import * as fromPendingPayment from './pending-payment/pending-payment.reducer';
import {
  PolicySelectors as fromPolicies,
  PolicyDocumentsSelectors as fromPolicyDocuments,
  PolicySummarySelectors as fromPolicySummaries
} from '@amfam/policy/data-access';
import { EdeliverySelectors as fromEdeliverySelectors } from '@amfam/policy/e-delivery/data-access';
import { GoPaperLessSelectors as fromGoPaperless } from '@amfam/policy/go-paperless/data-access';
import {
  AutoPolicy,
  GenericProductType,
  Policy,
  PolicyStatus,
  RiskModel,
  SourceSystemType,
  VehicleTypeConstants
} from '@amfam/policy/models';
import { profileQuery } from '@amfam/profile/data-access';
import {
  Alert,
  AutomaticPayment,
  BillAccount,
  DeliveryPreferences,
  Party,
  ResetPasswordModel,
  ScheduledPayment
} from '@amfam/shared/models';
import { KydOperatorOptionStatusValuePipe } from '@amfam/shared/ui/pipes';
import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { agentQuery } from '@amfam/shared/utility/agent';
// Import brand selectors from brand lib
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { featureFlagQuery } from '@amfam/shared/utility/feature-flag/data-access';
// models
import { AnalyticsUserType } from '@amfam/shared/utility/shared-services';
import { Dictionary } from '@ngrx/entity';
import { ActionReducer, ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import dateFormat from 'date-fns/format';
import isWeekend from 'date-fns/is_weekend';
import isWithinRange from 'date-fns/is_within_range';
import setHours from 'date-fns/set_hours';
import startOfHour from 'date-fns/start_of_hour';
import subMilliseconds from 'date-fns/sub_milliseconds';
import { EligibilityEnum, ReplaceVehicle } from 'libs/policy/models/src/lib/models/replace-vehicle';
import {
  find as _find,
  flatMap as _flatMap,
  flatten as _flatten,
  get as _get,
  groupBy as _groupBy,
  isEmpty as _isEmpty,
  map as _map,
  some as _some
} from 'lodash';
import { ENROLLMENT_TYPE } from '../../enrollment/enrollment-type.model';
import { UBI_PROGRAM } from '../../policy/know-your-drive-partial-household/models/kyd-program-category.model';
/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromAlerts from './alert/alert.selectors';
import { AppBootstrap } from './app-bootstrap/app-bootstrap.model';
import * as fromAppBootstrap from './app-bootstrap/app-bootstrap.reducer';
import * as fromAssociatedParties from './associated-party/associated-party.reducer';
import { AssociatedParty } from './associated-party/models/associated-party.model';
import { EnrollUserModel } from './enrollment';
import * as fromEnrollUser from './enrollment/enroll-user.reducer';
import { EnrollStepsModel } from './enrollment/steps';
import * as fromEnrollSteps from './enrollment/steps/enroll-steps.reducer';
import { Entities } from './entity/entity.model';
import { ErrorModel } from './error/error.model';
import * as fromError from './error/error.reducer';
import * as fromEnrollment from './mae/mae-enrollment.reducer';
import * as fromPrograms from './mae/mae-program.reducer';
import * as fromMaeStatus from './mae/mae-status.reducer';
import { Enrollment, MaeStatus, Program } from './mae/models';
import * as fromKydSmartphoneEnrollPolicy from './programs/kyd-smartphone/kyd-smartphone-enroll-policy.reducer';
import * as fromKydSmartphoneEnrollSummary from './programs/kyd-smartphone/kyd-smartphone-enrollment-summary.reducer';
import {
  KydSmartphoneEnrolledOperatorModel,
  KydSmartphonePolicy,
  KydSmartphoneSummary
} from './programs/kyd-smartphone/models';
import { KydSmartphoneEnrollPolicyState } from './programs/kyd-smartphone/models/kyd-smartphone-enroll-policy-state.model';
import { KydCustomerDataModel } from './programs/kyd-smartphone/models/kyd-smartphone-policy.model';
import * as fromKydEligibility from './programs/kyd/kyd-eligibility.reducer';
import * as fromKydEnroll from './programs/kyd/kyd-enroll.reducer';
import * as fromKyd from './programs/kyd/kyd.reducer';
import {
  KydConfirmation,
  KydPolicyEligibility,
  KydVehicleEligibility,
  PolicyEnrollment
} from './programs/kyd/models';
import { ReplaceVehiclePolicy } from './replace-vehicle-policy/replace-vehicle-policy.model';
import * as fromReplaceVehiclePolicy from './replace-vehicle-policy/replace-vehicle-policy.reducer';
import * as fromReplaceVehicle from './replace-vehicle/replace-vehicle.reducer';
import * as fromResetPassword from './reset-password/reset-password.reducer';
import { Session } from './session/session.model';
import * as fromSession from './session/session.reducer';
import { WorkflowModel } from './workflow/workflow.model';
import * as fromWorkflow from './workflow/workflow.reducer';

/**
 * RootState:
 *   This is the initial state of all the reducers defined below
 *
 *   Please keep sorted
 */
export interface RootState {
  appBootstrap: AppBootstrap;
  associatedParties: Entities<AssociatedParty>;
  enrollments: Entities<Enrollment>;
  enrollSteps: EnrollStepsModel;
  enrollUser: EnrollUserModel;
  error: ErrorModel;
  kydEligibility: Entities<KydPolicyEligibility>;
  kydEnrollmentConfirmation: Entities<KydConfirmation>;
  kydEnrollments: Entities<PolicyEnrollment>;
  kydSmartphoneEnrollments: Entities<KydSmartphoneSummary>;
  kydSmartphoneEnrollPolicies: Entities<KydSmartphoneEnrollPolicyState>;
  maeStatus: Entities<MaeStatus>;
  programs: Entities<Program>;
  replaceVehicle: ReplaceVehicle;
  replaceVehiclePolicy: ReplaceVehiclePolicy;
  session: Session;
  workflow: WorkflowModel;
  resetPassword: ResetPasswordModel;
}

/**
 * reducers:
 *   This is all the reducers except for the rootReducer defined below
 *
 *   Please keep sorted
 */
export const reducers: ActionReducerMap<RootState> = {
  appBootstrap: fromAppBootstrap.reducer,
  associatedParties: fromAssociatedParties.reducer,
  enrollments: fromEnrollment.reducer,
  enrollSteps: fromEnrollSteps.reducer,
  enrollUser: fromEnrollUser.reducer,
  error: fromError.reducer,
  kydEligibility: fromKydEligibility.reducer,
  kydEnrollmentConfirmation: fromKydEnroll.reducer,
  kydEnrollments: fromKyd.reducer,
  kydSmartphoneEnrollments: fromKydSmartphoneEnrollSummary.reducer,
  kydSmartphoneEnrollPolicies: fromKydSmartphoneEnrollPolicy.reducer,
  maeStatus: fromMaeStatus.reducer,
  programs: fromPrograms.reducer,
  replaceVehicle: fromReplaceVehicle.reducer,
  replaceVehiclePolicy: fromReplaceVehiclePolicy.reducer,
  resetPassword: fromResetPassword.reducer,
  session: fromSession.reducer,
  workflow: fromWorkflow.reducer
};

/**
 * Root Reducer
 *   adds a reducer to clear the state after logout
 */
export function rootReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<RootState>[] = [rootReducer];

/**
 * Session Reducers
 */
export const getSessionState = (state: RootState) => state.session;
export const getSessionStatus = createSelector(getSessionState, fromSession.status);
export const getSessionHasError = createSelector(getSessionState, fromSession.hasError);
export const getSessionIsLoading = createSelector(getSessionState, fromSession.isLoading);
export const getDevice = createSelector(getSessionState, fromSession.getDevice);
export const loggedIn = createSelector(getSessionState, fromSession.loggedIn);
export const loggedOut = createSelector(getSessionState, fromSession.loggedOut);
export const sessionValidUntil = createSelector(getSessionState, fromSession.getSessionValidUntil);
export const timeDifferenceInMilliseconds = createSelector(
  getSessionState,
  fromSession.getTimeDifferenceInMilliseconds
);
export const gatewayTime = createSelector(timeDifferenceInMilliseconds, difference =>
  subMilliseconds(new Date(), difference)
);

/**
 * Enroll User Reducers
 */
export const getEnrollUserState = (state: RootState) => state.enrollUser;
// Todo: these variable names are not scoped correctly. They do not give enough context.
export const lastName = createSelector(getEnrollUserState, fromEnrollUser.lastName);
export const dob = createSelector(getEnrollUserState, fromEnrollUser.dob);
export const zipcode = createSelector(getEnrollUserState, fromEnrollUser.zipcode);
export const businessName = createSelector(getEnrollUserState, fromEnrollUser.businessName);
export const partyId = createSelector(getEnrollUserState, fromEnrollUser.partyId);
export const partnerId = createSelector(getEnrollUserState, fromEnrollUser.partnerId);
export const typeOfPartyCode = createSelector(getEnrollUserState, fromEnrollUser.typeOfPartyCode);
export const firstName = createSelector(getEnrollUserState, fromEnrollUser.firstName);
export const middleName = createSelector(getEnrollUserState, fromEnrollUser.middleName);
export const socialSecurityNumberOnRecordIndicator = createSelector(
  getEnrollUserState,
  fromEnrollUser.socialSecurityNumberOnRecordIndicator
);
export const maskedPhones = createSelector(getEnrollUserState, fromEnrollUser.maskedPhones);
export const maskedEmails = createSelector(getEnrollUserState, fromEnrollUser.maskedEmails);
export const match = createSelector(getEnrollUserState, fromEnrollUser.match);
export const verificationType = createSelector(getEnrollUserState, fromEnrollUser.verificationType);
export const policyNumber = createSelector(getEnrollUserState, fromEnrollUser.policyNumber);
export const emailAddressInput = createSelector(
  getEnrollUserState,
  fromEnrollUser.emailAddressInput
);
export const emailAddressSelect = createSelector(
  getEnrollUserState,
  fromEnrollUser.emailAddressSelect
);
export const userName = createSelector(getEnrollUserState, fromEnrollUser.userName);
export const password = createSelector(getEnrollUserState, fromEnrollUser.password);
export const enrollmentType = createSelector(getEnrollUserState, fromEnrollUser.enrollmentType);
export const questionOne = createSelector(getEnrollUserState, fromEnrollUser.questionOne);
export const answerOne = createSelector(getEnrollUserState, fromEnrollUser.answerOne);
export const questionTwo = createSelector(getEnrollUserState, fromEnrollUser.questionTwo);
export const answerTwo = createSelector(getEnrollUserState, fromEnrollUser.answerTwo);
export const shellAccountFoundForgotUserId = createSelector(
  getEnrollUserState,
  fromEnrollUser.shellAccountFoundForgotUserId
);
export const shellAccountFound = createSelector(
  getEnrollUserState,
  fromEnrollUser.shellAccountFound
);

/**
 * Enroll Steps Reducer
 */
export const getEnrollStepsState = (state: RootState) => state.enrollSteps;
export const identificationStep = createSelector(
  getEnrollStepsState,
  fromEnrollSteps.identificationStep
);
export const verificationStep = createSelector(
  getEnrollStepsState,
  fromEnrollSteps.verificationStep
);
export const personalSignUpStep = createSelector(
  getEnrollStepsState,
  fromEnrollSteps.personalSignUpStep
);
export const personalSecurityQuestionsStep = createSelector(
  getEnrollStepsState,
  fromEnrollSteps.personalSecurityQuestionsStep
);
export const businessEnrollStep = createSelector(
  getEnrollStepsState,
  fromEnrollSteps.businessEnrollStep
);
export const businessSignUpStep = createSelector(
  getEnrollStepsState,
  fromEnrollSteps.businessSignUpStep
);
export const businessSecurityQuestionsStep = createSelector(
  getEnrollStepsState,
  fromEnrollSteps.businessSecurityQuestionsStep
);

/**
 * Workflow Reducers
 */
export const getWorkflowState = (state: RootState) => state.workflow;
export const getWorkflowLoaded = createSelector(getWorkflowState, fromWorkflow.getLoaded);
export const getWorkflowActiveStep = createSelector(getWorkflowState, fromWorkflow.getActiveStep);
export const getWorkflowNextStep = createSelector(getWorkflowState, fromWorkflow.getNextStep);
export const getWorkflowBackStep = createSelector(getWorkflowState, fromWorkflow.getBackStep);
export const getWorkflowIds = createSelector(getWorkflowState, fromWorkflow.getIds);
export const getWorkflowEntities = createSelector(getWorkflowState, fromWorkflow.getSteps);
export const getWorkflowType = createSelector(getWorkflowState, fromWorkflow.getWorkflowType);
export const getWorkflowSteps = createSelector(getWorkflowEntities, getWorkflowIds, (steps, ids) =>
  ids.map(id => steps[id])
);

/**
 * App Bootstrap Reducers
 */
export const getAppBootstrapState = (state: RootState) => state.appBootstrap;
export const getBannerContent = createSelector(
  getAppBootstrapState,
  fromAppBootstrap.bannerContent
);
export const getShowBanner = createSelector(getAppBootstrapState, fromAppBootstrap.showBanner);
export const getAppStarted = createSelector(getAppBootstrapState, fromAppBootstrap.appStarted);

export const getUpdatedMyAccountEmailPayload = createSelector(
  profileQuery.getUpdatedMyAccountEmailAddress,
  userQuery.getEmails,
  (updatedEmailAddress, emails) => emails.find(email => email.emailAddress === updatedEmailAddress)
);

export const getProfileCardData = createSelector(
  userQuery.getAddress,
  userQuery.getFirstName,
  userQuery.getMyAccountEmail,
  getUpdatedMyAccountEmailPayload,
  userQuery.getPrimaryPhone,
  userQuery.getLoginName,
  (address, first, email, updatedEmail, phone, loginName) => ({
    address: address,
    firstName: first,
    primaryEmail: updatedEmail ? updatedEmail : email,
    primaryPhone: phone,
    userId: loginName
  })
);

export const isEnrolledInEDelivery = createSelector(userQuery.getPartyDetails, party => {
  if (_get(party, 'typeOfEdeliveryStatusCode') === 'OPTED IN') {
    return true;
  }
  return false;
});

export const getAnalyticUserType = createSelector(
  getWorkflowType,
  BrandSelectors.getIsPartner,
  userQuery.getTypeOfAccountCode,
  shellAccountFound,
  (workflowType, partner, typeOfAccountCode, cruise) => {
    // authenticated
    if (typeOfAccountCode) {
      switch (typeOfAccountCode) {
        case AccountTypeEnum.Customer:
          return partner ? AnalyticsUserType.customerPartner : AnalyticsUserType.customerAfi;
        case AccountTypeEnum.CFR:
          return AnalyticsUserType.cfr;
        case AccountTypeEnum.nonCustomer:
          return AnalyticsUserType.nonCustomer;
      }
    }

    // personal enrollment
    switch (workflowType) {
      case ENROLLMENT_TYPE.PERSONAL:
        if (cruise) {
          return partner ? AnalyticsUserType.cruisePartner : AnalyticsUserType.cruise;
        }
        return partner ? AnalyticsUserType.customerPartner : AnalyticsUserType.customerAfi;
      case ENROLLMENT_TYPE.MAE:
        if (cruise) {
          return partner ? AnalyticsUserType.cruisePartner : AnalyticsUserType.cruise;
        }
        return partner ? AnalyticsUserType.maePartner : AnalyticsUserType.mae;
      case ENROLLMENT_TYPE.CONVERSION:
        return AnalyticsUserType.conversion;
    }
    // unauthenticated
    return 'Customer';
  }
);

/**
 * CHAT
 */
export const chatEnabled = createSelector(
  gatewayTime,
  BrandSelectors.getIsPartner,
  featureFlagQuery.getFeatureFlagEntitiesState,
  (serverTime, isPartner, features) => {
    if (_get(features, 'chat.enabled')) {
      const chatStart = dateFormat(startOfHour(setHours(serverTime, 8)), 'YYYY-MM-DD');
      const chatEnd = dateFormat(startOfHour(setHours(serverTime, 20)), 'YYYY-MM-DD');
      const serverCheck = dateFormat(serverTime, 'YYYY-MM-DD');
      return !isPartner && !isWeekend(serverTime) && isWithinRange(serverCheck, chatStart, chatEnd);
    }
    return false;
  }
);

/**
 * Reset Password Reducers
 */
export const getResetPasswordState = (state: RootState) => state.resetPassword;
export const getResetPasswordLoading = createSelector(
  getResetPasswordState,
  fromResetPassword.getLoading
);
export const getResetPasswordMaskedEmail = createSelector(
  getResetPasswordState,
  fromResetPassword.getMaskedEmail
);
export const getResetPasswordMaskedNumbers = createSelector(
  getResetPasswordState,
  fromResetPassword.getMaskedPhoneNumbers
);
export const getResetPasswordSecurityQuestions = createSelector(
  getResetPasswordState,
  fromResetPassword.getSecurityQuestions
);
export const isResetPasswordUserVerified = createSelector(
  getResetPasswordState,
  fromResetPassword.isUserVerified
);
export const isVerifyMethodLocked = createSelector(
  getResetPasswordState,
  fromResetPassword.isVerifyMethodLocked
);
export const getResetPasswordUserIdentifier = createSelector(
  getResetPasswordState,
  fromResetPassword.getUserIdentifier
);
export const getResetPasswordLockedUserId = createSelector(
  getResetPasswordState,
  fromResetPassword.getLockedUserId
);
export const isResetOptionsUnlocked = createSelector(
  getResetPasswordState,
  resetPasswordState => resetPasswordState.userIdentifier && !resetPasswordState.userVerified
);
export const getResetPasswordShellAccountStatus = createSelector(
  getResetPasswordState,
  fromResetPassword.getShellAccountStatus
);

/**
 * AS: This reducer will return the list of billaccounts eligible for setting up
 * autopay
 */
export const getBillaccountsListEligible = createSelector(
  fromBillAccounts.getModifiableBillAccounts,
  AutoPaySelectors.getAutomaticPayments,
  AutoPaySelectors.getAutoPayNotificationEntities,
  (billaccounts, automaticPayments, autopayNotificationEntities) =>
    billaccounts.filter(
      billaccount =>
        !automaticPayments.find(
          automaticPayment =>
            automaticPayment?.billAccountNumber === billaccount.billAccountNumber &&
            !autopayNotificationEntities[automaticPayment.billAccountNumber].hasError
        )
    )
);

export const getActiveVehiclePolicies = createSelector(fromPolicies.getPolicies, policies =>
  policies
    .filter(
      policy =>
        policy.generalizedProductType === GenericProductType.Auto &&
        policy.policyStatus === PolicyStatus.Inforce
    )
    .map(policy => <AutoPolicy>policy)
);

export const getActiveScheduledVehiclePolicies = createSelector(
  fromPolicies.getPolicies,
  policies =>
    policies
      .filter(
        policy =>
          policy.generalizedProductType === GenericProductType.Auto &&
          (policy.policyStatus === PolicyStatus.Inforce ||
            policy.policyStatus === PolicyStatus.Scheduled)
      )
      .map(policy => <AutoPolicy>policy)
);

export const getActiveScheduledAutoPolicies = createSelector(
  getActiveScheduledVehiclePolicies,
  policies =>
    policies
      .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.AUTO)
      .map(policy => <AutoPolicy>policy)
);

export const getActiveAutoPolicies = createSelector(getActiveVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.AUTO)
    .map(policy => <AutoPolicy>policy)
);

export const getPolicyDocumentNotifications = createSelector(
  fromPolicyDocuments.getPolicyDocumentEntities,
  fromPolicies.getPolicies,
  (entities, policies: Policy[]) => {
    if (_isEmpty(entities)) {
      return false;
    }
    const notificationsObj = {};
    // eslint-disable-next-line @typescript-eslint/ban-types
    let notifications: object[] | boolean;
    // Build list of notifications
    for (const doc of Object.values(entities)) {
      if (doc.isNew && policies.length) {
        if (notificationsObj[doc.policyNumber]) {
          notificationsObj[doc.policyNumber].number += 1;
        } else {
          const policyType = policies.filter(policy => policy.policyNumber === doc.policyNumber)[0]
            .policyType;
          notificationsObj[doc.policyNumber] = { number: 1, policyType: policyType, ids: [] };
        }
        notificationsObj[doc.policyNumber].ids.push(doc.id);
      }
    }
    // Return false if no notifications, else create array from object
    if (!Object.keys(notificationsObj).length) {
      notifications = false;
    } else {
      notifications = _map(notificationsObj, (notification, policyNum) =>
        Object.assign({}, notification, { policyNumber: policyNum })
      );
    }
    return notifications;
  }
);

export const getAdvancedAutoPolicyRisks = createSelector(
  fromPolicies.getAdvanceAutoPolicies,
  autoPolicies =>
    _flatten(
      autoPolicies.map(policy => policy.vehicles.map(vehicle => new RiskModel(vehicle, policy)))
    )
);

export const isAdvanceAutoPolicy = createSelector(
  fromPolicies.getAdvanceAutoPolicies,
  (policies: AutoPolicy[], policynumber: string) =>
    !!policies.find(policy => policy.policyNumber === policynumber)
);

export const isMCEligibleStateAdvanceAutoPolicy = createSelector(
  fromPolicies.getAdvanceAutoPolicies,
  (policies: AutoPolicy[], data: { policynumber: string; inEligibleStates: string[] }) =>
    !!policies.find(
      policy =>
        policy.policyNumber === data.policynumber &&
        !data.inEligibleStates.find(stateCode => stateCode === _get(policy, 'policyAddress.state'))
    )
);

export const isAnyAdvancedPoliciyInGivenPolicies = createSelector(
  fromPolicies.getPolicies,
  (policies: Policy[], data: { dataPolicies: Policy[] }) => {
    let isAdvancedPolicy = false;
    data.dataPolicies.forEach(policy => {
      if (!isAdvancedPolicy) {
        const foundPolicy = policies.find(p => p.policyNumber === policy.policyNumber);

        if (foundPolicy && foundPolicy.sourceSystem === SourceSystemType.Advance) {
          isAdvancedPolicy = true;
        }
      }
    });
    return isAdvancedPolicy;
  }
);

export const getActiveAutoPolicyRisks = createSelector(getActiveVehiclePolicies, autoPolicies =>
  _flatten(
    autoPolicies.map(policy => policy.vehicles.map(vehicle => new RiskModel(vehicle, policy)))
  )
);

export const hasAnyPolicyCompAndCollisonCoverage = createSelector(
  getActiveAutoPolicyRisks,
  policyRisks => {
    let vehicleCoverages = [];
    vehicleCoverages = _flatMap(policyRisks, policyRisk => policyRisk.vehicleCoverages);
    return (
      !!vehicleCoverages.find(coverage => coverage.code === 'PAComprehensiveCov') &&
      !!vehicleCoverages.find(coverage => coverage.code === 'PACollisionCov')
    );
  }
);

export const hasAnyPolicyBASCompAndCollisonCoverage = createSelector(
  getActiveAutoPolicyRisks,
  policyRisks => {
    let vehicleCoverages = [];
    vehicleCoverages = _flatMap(policyRisks, policyRisk => policyRisk.vehicleCoverages);
    return (
      !!vehicleCoverages.find(coverage => coverage.code === 'BASCOMPREHENSIVE') &&
      !!vehicleCoverages.find(coverage => coverage.code === 'BASCOLLISION')
    );
  }
);

export const hasAnyPolicyComprehensiveCoverage = createSelector(
  getActiveAutoPolicyRisks,
  policyRisks => {
    let vehicleCoverages = [];
    vehicleCoverages = _flatMap(policyRisks, policyRisk => policyRisk.vehicleCoverages);
    return (
      !!vehicleCoverages.find(coverage => coverage.code === 'PAComprehensiveCov') &&
      !vehicleCoverages.find(coverage => coverage.code === 'PACollisionCov')
    );
  }
);

export const hasAnyPolicyLiabilityCoverage = createSelector(
  getActiveAutoPolicyRisks,
  policyRisks => {
    let vehicleCoverages = [];
    vehicleCoverages = _flatMap(policyRisks, policyRisk => policyRisk.vehicleCoverages);
    return (
      !!vehicleCoverages.find(coverage => coverage.code === 'PAPropDamageCov_af')
    );
  }
);

export const hasAnyPolicyCompAndCollisonCoverageAndCarLightTruck = createSelector(
  fromPolicies.getAdvanceAutoPolicyRisks,
  policyRisks =>
    _some(
      policyRisks,
      (policyRisk: RiskModel) =>
        !!policyRisk.vehicleCoverages.find(coverage => coverage.code === 'PAComprehensiveCov') &&
        !!policyRisk.vehicleCoverages.find(coverage => coverage.code === 'PACollisionCov') &&
        policyRisk.vehicleTypeName === 'Car/Light Truck'
    )
);

export const hasAdvPolicyAndNewCarReplaceCoverage = createSelector(
  fromPolicies.getAdvanceAutoPolicyRisks,
  policyRisks =>
    _some(
      policyRisks,
      (policyRisk: RiskModel) =>
        !!policyRisk.vehicleCoverages.find(coverage => coverage.code === 'PANewCarReplaceCov_af')
    )
);

export const isValidVehicleToBeReplaced = (selectedVehicleId: string) =>
  createSelector(fromPolicies.getAdvanceAutoPolicyRisks, policyRisks =>
    _some(
      policyRisks,
      (policyRisk: RiskModel) =>
        policyRisk.vehicleId === selectedVehicleId &&
        policyRisk.vehicleTypeName === EligibilityEnum.vehicleTypeName &&
        !EligibilityEnum.vehicleUseDescription
          .split('/')
          .find(el => policyRisk?.vehicleUseDescription.toLowerCase().includes(el.toLowerCase())) &&
        !policyRisk.vehicleCoverages.find(coverage => coverage.code === 'PANewCarReplaceCov_af')
    )
  );

export const hasAdvPolicyNotNewCarReplaceCoverageAndCarLightTruck = (selectedVechicleId: string) =>
  createSelector(fromPolicies.getAdvanceAutoPolicyRisks, policyRisks =>
    _some(
      policyRisks,
      (policyRisk: RiskModel) =>
        policyRisk.vehicleId === selectedVechicleId &&
        policyRisk.vehicleTypeName === 'Car/Light Truck' &&
        !!policyRisk.vehicleCoverages.find(coverage => coverage.code !== 'PANewCarReplaceCov_af')
    )
  );

export const hasSinglePolicyCompAndCollisonCoverage = createSelector(
  getActiveAutoPolicyRisks,
  (policyRisks: RiskModel[], vehicleId) => {
    let vehicleCoverages = [];
    vehicleCoverages = _get(
      policyRisks.find(policyRisk => policyRisk.vehicleId === vehicleId),
      'vehicleCoverages',
      []
    );

    return (
      !!vehicleCoverages.find(coverage => coverage.code === 'PAComprehensiveCov') &&
      !!vehicleCoverages.find(coverage => coverage.code === 'PACollisionCov')
    );
  }
);

export const hasSinglePolicyCompAndCollisonCoverageAndCarLightTruck = createSelector(
  fromPolicies.getAdvanceAutoPolicyRisks,
  (policyRisks: RiskModel[], vehicleId) => {
    let vehicleCoverages = [];
    vehicleCoverages = _get(
      policyRisks.find(policyRisk => policyRisk.vehicleId === vehicleId),
      'vehicleCoverages',
      []
    );

    const risk = policyRisks.find(policyRisk => policyRisk.vehicleId === vehicleId);
    return (
      !!vehicleCoverages.find(coverage => coverage.code === 'PAComprehensiveCov') &&
      !!vehicleCoverages.find(coverage => coverage.code === 'PACollisionCov') &&
      risk.vehicleTypeName === 'Car/Light Truck'
    );
  }
);

// filter eligible billaccounts and check if the property policies has TPI.
export const getBillaccountsListEligibleForAutopay = createSelector(
  fromPolicies.getPropertyPoliciesWithTPI,
  getBillaccountsListEligible,
  (policies, billaccounts) =>
    billaccounts.filter(
      billaccount =>
        !policies.find(
          homePolicy => homePolicy.billingAccountNumber === billaccount.billAccountNumber
        )
    )
);

// filter  billaccounts and return billaccounts with property policies has TPI.
export const getBillaccountsListNotEligibleForAutopay = createSelector(
  fromPolicies.getPropertyPoliciesWithTPI,
  getBillaccountsListEligible,
  (policies, billaccounts) =>
    billaccounts.filter(billaccount =>
      policies.find(homePolicy => homePolicy.billingAccountNumber === billaccount.billAccountNumber)
    )
);

export const getChangeCoveragePolicySummaries = createSelector(
  fromPolicySummaries.getPolicySummaries,
  policies =>
    policies.filter(
      policy =>
        policy.policyStatus !== PolicyStatus.Cancelled &&
        policy.policyStatus !== PolicyStatus.Other &&
        policy.policyStatus !== PolicyStatus.Scheduled
    )
);

/*
 * Associated Party Selectors
 */

export const getAssociatedPartiesState = (state: RootState) => state.associatedParties;
export const getAssociatedPartiesEntities = createSelector(
  getAssociatedPartiesState,
  fromAssociatedParties.getEntities
);
export const getAssociatedPartiesLoading = createSelector(
  getAssociatedPartiesState,
  fromAssociatedParties.getLoading
);
export const getAssociatedPartiesLoaded = createSelector(
  getAssociatedPartiesState,
  fromAssociatedParties.getLoaded
);
export const getAssociatedParties = createSelector(
  getAssociatedPartiesLoaded,
  getAssociatedPartiesEntities,
  (loaded, parties) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let _parties: AssociatedParty[] = [];
    if (loaded) {
      Object.keys(parties).map(key => {
        _parties = _parties.concat(parties[key]);
      });
    }
    return _parties;
  }
);

/**
 * KYD Eligibility Selectors
 */
export const getKydEligibilityState = (state: RootState) => state.kydEligibility;
export const getKydEligibilityEntities = createSelector(
  getKydEligibilityState,
  fromKydEligibility.getEntities
);
export const getKydEligibilityIds = createSelector(
  getKydEligibilityState,
  fromKydEligibility.getIds
);
export const getKydEligibilityLoading = createSelector(
  getKydEligibilityState,
  fromKydEligibility.getLoading
);
export const getKydEligibilityLoaded = createSelector(
  getKydEligibilityState,
  fromKydEligibility.getLoaded
);
export const getKydPolicyEligibility = createSelector(
  getKydEligibilityEntities,
  getKydEligibilityIds,
  (entities, ids = []) => ids.map(id => entities[id])
);

export const getKydSmartphonePolicyData = createSelector(getKydPolicyEligibility, entities =>
  _flatMap(entities, entity => entity.kydSmartPhoneData)
);

export const getKydSmartphoneEligiblePolicies = createSelector(
  getKydSmartphonePolicyData,
  smartPhonePolicies => <KydSmartphonePolicy[]>smartPhonePolicies
      .filter(policy => _get(policy, 'eligibleForKYD') === 'Y')
      .map(policy =>
        Object.assign({}, policy, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _allOperatorsEnrolled: false,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _edit: false,
          enrollStatus: 'N'
        })
      )
);

export const getKydEligiblePolicies = createSelector(getKydSmartphonePolicyData, policyData =>
  policyData
    .filter(policy => _get(policy, 'eligibleForKYD') === 'Y')
    .map(policy =>
      Object.assign({}, policy, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _allOperatorsEnrolled: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _edit: false,
        enrollStatus: 'N'
      })
    )
);

export const getPartialHouseHoldOperatorModelData = createSelector(
  getKydSmartphoneEligiblePolicies,
  policies => {
    const modelData: Dictionary<KydCustomerDataModel> = {};
    const policyRoles = _get(policies[0], 'policyRoles', []);
    const enrolledOperators = _get(policies[0], 'enrollmentSummary.enrolledOperators', []);

    /*
     * If the policy has never been enrolled in KYD, there will be no operatorId.
     * In this case, we use the operator's customerId as the unique key and gather operator data
     * from the policy roles data.
     */
    if (!enrolledOperators.length) {
      policyRoles.forEach(role => {
        modelData[role.customerId] = {
          id: role.customerId,
          customerId: role.customerId,
          fullName: role.fullName,
          phoneNumber: '',
          status: 'Not-Enrolled'
        };
      });
    } else {
      enrolledOperators.forEach((operator: KydSmartphoneEnrolledOperatorModel) => {
        const operatorId = _get(operator, 'operatorEnrollmentSummary.operatorId');
        modelData[operatorId] = {
          id: operatorId,
          customerId: operator.cdhId,
          fullName: _get(
            policyRoles.find(policyRole => policyRole.customerId === operator.cdhId),
            'fullName'
          ),
          phoneNumber:
            _get(operator, 'operatorEnrollmentSummary.operatorEnrollmentStatus') === 'Enrolled'
              ? _get(operator, 'contactDetail.phone')
              : '',
          status: new KydOperatorOptionStatusValuePipe().transform(
            _get(operator, 'operatorEnrollmentSummary.operatorEnrollmentStatus')
          )
        };
      });
    }
    return modelData;
  }
);

export const hasMultipleKydOperatorsEnrolled = createSelector(
  getKydSmartphoneEligiblePolicies,
  policies => {
    const operators = _get(policies[0], 'enrollmentSummary.enrolledOperators', []);
    const enrolledOperators = operators.filter(
      (operator: KydSmartphoneEnrolledOperatorModel) =>
        operator.operatorEnrollmentSummary.operatorEnrollmentStatus === 'Enrolled'
    );
    return enrolledOperators.length > 1;
  }
);

export const hasMultipleEligibleKydOperators = createSelector(
  getKydSmartphoneEligiblePolicies,
  policies => _get(policies[0], 'policyRoles', []).length > 1
);

export const getKydPolicyState = createSelector(getKydSmartphoneEligiblePolicies, policies =>
  _get(policies[0], 'policyState')
);

export const isKydSmartphonePolicyEnrolled = createSelector(
  getKydSmartphoneEligiblePolicies,
  policies => _get(policies[0], 'enrollmentSummary.enrollmentStatus') === 'Enrolled'
);

export const getKydSmartphonePolicyEnrollmentId = createSelector(
  getKydSmartphoneEligiblePolicies,
  policies => <number>_get(policies[0], 'enrollmentSummary.enrollmentId')
);

export const getEnrollementEmailId = createSelector(getKydEligiblePolicies, policies =>
  _get(policies[0], 'enrollmentSummary.emailId')
);

export const getKydPolicyEnrollmentId = createSelector(
  getKydEligiblePolicies,
  policies => <number>_get(policies[0], 'enrollmentSummary.enrollmentId')
);

export const isKydManageMode = createSelector(
  getKydSmartphonePolicyData,
  getKydEligiblePolicies,
  (policyData, kydEkigiblePolicies) =>
    kydEkigiblePolicies.length &&
    policyData.some(
      policy =>
        policy.enrollStatusFlag.enrolled ||
        policy.enrollStatusFlag.waitingPeriod ||
        policy.enrollStatusFlag.optedOut
    )
);

export const getKydEligibleEntities = createSelector(getKydEligibilityEntities, entity =>
  Object.keys(entity)
    .map(key => entity[key])
    .filter(elem => elem.isPhhEligible)
);

export const getKydPartialHouseholdEligibleEntities = createSelector(
  getKydEligibleEntities,
  eligibilities => eligibilities.filter(eligibleEntity => !!eligibleEntity.isPhhEligible)
);

export const isKydPartialHouseholdEligible = createSelector(
  getKydEligibleEntities,
  eligibilities =>
    _get(
      eligibilities.filter(eligibleEntity => !!eligibleEntity.isPhhEligible),
      'length',
      0
    ) > 0
);

export const getKydVehicleEligibility = createSelector(getKydPolicyEligibility, eligibilities =>
  _flatten(eligibilities.map(eligibility => eligibility.vehicleEligibilities))
);

export const getKydEligibleRisks = createSelector(
  getKydVehicleEligibility,
  fromPolicies.getAdvanceAutoPolicyRisks,
  (eligibilities, risks) =>
    risks.filter(risk =>
      eligibilities.some(eligibility => eligibility.canEnroll && risk.vin === eligibility.vin)
    )
);

/**
 * KYD Selectors
 */
export const getKydEnrollmentsState = (state: RootState) => state.kydEnrollments;
export const getKydEnrollmentEntities = createSelector(getKydEnrollmentsState, fromKyd.getEntities);
export const getKydEnrollmentIds = createSelector(getKydEnrollmentsState, fromKyd.getIds);
export const getLatestKydEnrollmentError = createSelector(
  getKydEnrollmentsState,
  state => state.error
);
export const getKydEnrollmentsLoading = createSelector(getKydEnrollmentsState, fromKyd.getLoading);
export const getKydEnrollmentsLoaded = createSelector(getKydEnrollmentsState, fromKyd.getLoaded);
export const getKydEnrollmentsHistory = createSelector(
  getKydEnrollmentEntities,
  getKydEnrollmentIds,
  (entities, ids) => ids.map(id => entities[id])
);
export const getKydEnrollments = createSelector(getKydEnrollmentsHistory, enrollments =>
  enrollments.map(enrollment => {
    const vehiclesByVin = _groupBy(enrollment.enrolledVehicles, vehicle => vehicle.vin);
    const currentVehicleEnrollments = Object.keys(vehiclesByVin).map(key =>
      vehiclesByVin[key].reduce((vehicleA, vehicleB) =>
        // enrollmentId is coming as string from backend, casting to a number with +
        +vehicleA.enrollmentSummary.enrollmentId > +vehicleB.enrollmentSummary.enrollmentId
          ? vehicleA
          : vehicleB
      )
    );

    return <PolicyEnrollment>(
      Object.assign({}, enrollment, { enrolledVehicles: currentVehicleEnrollments })
    );
  })
);

export const hasKydEnrollments = createSelector(
  getKydEnrollments,
  enrollments => enrollments.length > 0
);

export const getKydVehicleEnrollmentSummaries = createSelector(
  getKydEligibleRisks,
  getKydEnrollments,
  (eligibleRisks, enrollments) => {
    const summary = [];
    eligibleRisks.forEach(eligibleRisk => {
      const enrollment = _find(
        enrollments,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _enrollment => _enrollment.policyNumber === eligibleRisk.policyNumber
      );
      const vehicleEnrollment = _find(
        _get(enrollment, 'enrolledVehicles', []),
        enrolledVehicle => enrolledVehicle.vin === eligibleRisk.vin
      );

      const vehicleSummary = _get(vehicleEnrollment, 'enrollmentSummary', {});
      summary.push({
        risk: eligibleRisk,
        enrollmentSummary: vehicleSummary
      });
    });

    return summary;
  }
);

/**
 * KYD Enrollment Confirmation Selectors
 */
export const getKydEnrollmentConfirmationState = (state: RootState) =>
  state.kydEnrollmentConfirmation;
export const getKydEnrollmentConfirmationEntities = createSelector(
  getKydEnrollmentConfirmationState,
  fromKydEnroll.getEntities
);
export const getKydEnrollmentConfirmationIds = createSelector(
  getKydEnrollmentConfirmationState,
  fromKydEnroll.getIds
);
export const getKydEnrollmentConfirmationLoading = createSelector(
  getKydEnrollmentConfirmationState,
  fromKydEnroll.getLoading
);
export const getKydEnrollmentConfirmationLoaded = createSelector(
  getKydEnrollmentConfirmationState,
  fromKydEnroll.getLoaded
);
export const getKydEnrollmentConfirmation = createSelector(
  getKydEnrollmentConfirmationEntities,
  getKydEnrollmentConfirmationIds,
  (entities, ids) => ids.map(id => entities[id]).sort(KydConfirmation.compare)
);
export const getKydEnrollmentConfirmationRefreshComplete = createSelector(
  getKydEnrollmentConfirmationLoaded,
  getKydEligibilityLoading,
  getKydEnrollmentsLoading,
  (confirmationLoaded, eligibilityLoading, enrollmentsLoading) =>
    confirmationLoaded && !eligibilityLoading && !enrollmentsLoading
);

/**
 * KYD Smartphone Enrollment Summary Selectors
 */
export const getKydSmartphoneEnrollmentsState = (state: RootState) =>
  state.kydSmartphoneEnrollments;
export const getKydSmartphoneEnrollmentEntities = createSelector(
  getKydSmartphoneEnrollmentsState,
  fromKydSmartphoneEnrollSummary.getEntities
);
export const getKydSmartphoneEnrollmentIds = createSelector(
  getKydSmartphoneEnrollmentsState,
  fromKydSmartphoneEnrollSummary.getIds
);
export const getKydSmartphoneEnrollmentsLoading = createSelector(
  getKydSmartphoneEnrollmentsState,
  fromKydSmartphoneEnrollSummary.getLoading
);
export const getKydSmartphoneEnrollmentsLoaded = createSelector(
  getKydSmartphoneEnrollmentsState,
  fromKydSmartphoneEnrollSummary.getLoaded
);

export const getKydSmartphonePolicies = createSelector(
  getKydPolicyEligibility,
  fromPolicies.getAdvanceAutoPolicies,
  (policyEligibility, advanceAutoPolicies) =>
    advanceAutoPolicies.filter(autoPolicy =>
      policyEligibility.some(eligibility => eligibility.policyNumber === autoPolicy.policyNumber)
    )
);

export const getEligibleKydSmartphonePolicies = createSelector(
  getKydPolicyEligibility,
  fromPolicies.getAdvanceAutoPolicies,
  (policyEligibility, advanceAutoPolicies) =>
    advanceAutoPolicies.filter(autoPolicy =>
      policyEligibility.some(
        eligibility =>
          eligibility.policyNumber === autoPolicy.policyNumber &&
          eligibility.programCategory === UBI_PROGRAM.TRUE_MOTION &&
          (eligibility.isPolicyEligible || eligibility.reasonCode === '400007') &&
          eligibility.isStateEligible
      )
    )
);

export const getKydSmartphoneEnrollments = createSelector(
  getKydSmartphoneEnrollmentEntities,
  entity => Object.keys(entity).map(key => entity[key])
);

// Check eligibility of KYD Smartphone. Customer is not eligible for KYD Smartphone if they have a current KYD Automatic enrollment.
export const getKydSmartphoneEligibility = createSelector(
  getKydSmartphoneEnrollments,
  hasKydEnrollments,
  (enrollments, isKydEnrolled) => enrollments.length > 0 && !isKydEnrolled
);

// Return true if any KYD parts are loading
export const getAnyKydLoading = createSelector(
  getKydEligibilityLoading,
  getKydEnrollmentsLoading,
  getKydSmartphoneEnrollmentsLoading,
  (eligibilityLoading, kydEnrollmentsLoading, smartphoneEnrollmentsLoading) =>
    eligibilityLoading || kydEnrollmentsLoading || smartphoneEnrollmentsLoading
);

/*
 * Kyd Enroll Policy Selectors
 */

export const getKydSmartphoneEnrollPoliciesState = (state: RootState) =>
  state.kydSmartphoneEnrollPolicies;
export const getKydSmartponeEnrollPoliciesEntities = createSelector(
  getKydSmartphoneEnrollPoliciesState,
  fromKydSmartphoneEnrollPolicy.getEntities
);
export const getKydSmartponeEnrollPoliciesIds = createSelector(
  getKydSmartphoneEnrollPoliciesState,
  fromKydSmartphoneEnrollPolicy.getIds
);

/**
 * Mae Selectors
 */
export const getMaeStatusState = (state: RootState) => state.maeStatus;
export const getMaeStatusEntities = createSelector(getMaeStatusState, fromMaeStatus.getEntities);
export const getMaeStatusLoaded = createSelector(getMaeStatusState, fromMaeStatus.getLoaded);
export const getMaeStatusError = createSelector(getMaeStatusState, fromMaeStatus.getError);
export const getMaeStatusId = createSelector(getMaeStatusState, fromMaeStatus.getIds);
export const getMaeStatus = createSelector(getMaeStatusEntities, getMaeStatusId, (entities, ids) =>
  ids.map(id => entities[id])
);
export const getProgramsState = (state: RootState) => state.programs;
export const getProgramEntities = createSelector(getProgramsState, fromPrograms.getEntities);
export const getProgramsLoaded = createSelector(getProgramsState, fromPrograms.getLoaded);

export const getProgramsLoading = createSelector(getProgramsState, fromPrograms.getLoading);

export const getProgramsIds = createSelector(getProgramsState, fromPrograms.getIds);
export const getPrograms = createSelector(getProgramEntities, getProgramsIds, (entities, ids) =>
  ids.map(id => entities[id])
);
export const getEnrollmentState = (state: RootState) => state.enrollments;
export const getEnrollmentEntities = createSelector(getEnrollmentState, fromEnrollment.getEntities);
export const getEnrollmentLoaded = createSelector(getEnrollmentState, fromEnrollment.getLoaded);
export const getEnrollmentIds = createSelector(getEnrollmentState, fromEnrollment.getIds);
export const getEnrollments = createSelector(
  getEnrollmentEntities,
  getEnrollmentIds,
  (entities, ids) => ids.map(id => entities[id])
);

/**
 * Active Agent selectors
 */
export const getAllActiveAgents = createSelector(
  agentQuery.getRealAgents,
  fromPolicies.getPolicies,
  (agents, policies) =>
    agents.filter(
      agent => agent && _find(policies, p => p.assignedProducer.producerIdNum === agent.id)
    )
);
export const selectedAgentFromStoreFactory = selectAgentFromComponent =>
  createSelector(getAllActiveAgents, agents => {
    if (agents.length > 1) {
      return agents.find(agent => selectAgentFromComponent === agent.id);
    } else {
      return agents[0];
    }
  });

export const getAgentInformationByProducerId = producerId =>
  createSelector(getAllActiveAgents, agents => agents.find(agent => producerId === agent.id));

export const getAgentInformationByAgentId = agentId =>
  createSelector(getAllActiveAgents, agents => agents.find(agent => agentId === agent.agentId));

export const getAgentInformationByVehicleId = createSelector(
  getAllActiveAgents,
  fromPolicies.getAdvanceAutoPolicyRisks,
  fromPolicies.getAdvanceAutoPolicies,
  (agents, risks, policies, vehicleId) => {
    const matchingVehicle: RiskModel = risks.find(
      (vehicle: RiskModel) => _get(vehicle, 'id') === vehicleId
    );
    const matchingPolicy = policies.find(
      policy => _get(policy, 'policyNumber') === matchingVehicle.policyNumber
    );
    return agents.find(
      agent => agent.id === _get(matchingPolicy, 'assignedProducer.producerIdNum')
    );
  }
);

export const getBillOverdueAlertsLoading = createSelector(
  fromScheduledPayments.getScheduledPaymentsLoading,
  fromBillAccounts.getBillAccountsAnyPrefsLoading,
  AutoPaySelectors.getAnyLoading,
  (scheduledPaymentsLoading, billAccountsLoading, autoPayRulesLoading) =>
    scheduledPaymentsLoading || billAccountsLoading || autoPayRulesLoading
);

/**
 * Alert Reducers
 */
export const getBillOverdueAlerts = createSelector(
  fromBillAccounts.getModifiableBillAccounts,
  fromScheduledPayments.getScheduledPayments,
  AutoPaySelectors.getAutomaticPayments,
  getBillOverdueAlertsLoading,
  (
    billAccounts: BillAccount[],
    payments: ScheduledPayment[],
    automaticPayments: AutomaticPayment[],
    billOverdueAlertsLoading
  ): Alert[] =>
    fromAlerts.getBillOverdueAlerts(
      billAccounts,
      payments,
      automaticPayments,
      billOverdueAlertsLoading
    )
);

export const getConsolidatedBillOverdueAlerts = createSelector(
  getBillOverdueAlerts,
  (alerts: Alert[]): Alert[] => fromAlerts.getConsolidatedAlert(alerts)
);

export const getSystemStableAlert = createSelector(getShowBanner, (showBanner): Alert[] =>
  fromAlerts.getSystemStableAlert(showBanner)
);

// show banner if there are Homesite policies
export const getHomesitePoliciesAlert = createSelector(
  fromPolicySummaries.hasHomesitePolicy,
  (showBanner): Alert[] => fromAlerts.homesitePoliciesAlert(showBanner)
);

export const getBillingLoading = createSelector(
  fromBillAccounts.getBillAccountsAnyLoading,
  fromScheduledPayments.getScheduledPaymentsAnyLoading,
  AutoPaySelectors.getAnyLoading,
  fromScheduledPayments.getFailedPaymentsAllMethods,
  fromScheduledPayments.getSuccessfulPaymentsAllMethods,
  (
    billAccountsLoading,
    scheduledPaymentsLoading,
    autoPayLoading,
    failedPayments: ScheduledPayment[],
    successfulPayments: ScheduledPayment[]
  ): boolean =>
    billAccountsLoading ||
    scheduledPaymentsLoading ||
    autoPayLoading ||
    _some(failedPayments, { loading: true }) ||
    _some(successfulPayments, { loading: true })
);

export const getUnsuccessfulPaymentAlerts = createSelector(
  fromBillAccounts.getBillAccounts,
  fromScheduledPayments.getScheduledPayments,
  AutoPaySelectors.getAutomaticPayments,
  fromScheduledPayments.getFailedPaymentsAllMethods,
  fromScheduledPayments.getSuccessfulPaymentsAllMethods,
  getBillingLoading,
  (
    billAccounts: BillAccount[],
    scheduledPayments: ScheduledPayment[],
    automaticPayments: AutomaticPayment[],
    failedPayments: ScheduledPayment[],
    successfulPayments: ScheduledPayment[],
    loading: boolean
  ): Alert[] =>
    fromAlerts.getUnsuccessfulPaymentAlerts(
      billAccounts,
      [...scheduledPayments, ...successfulPayments],
      automaticPayments,
      failedPayments,
      loading
    )
);

export const getConsolidatedUnsuccessfulPaymentAlerts = createSelector(
  getUnsuccessfulPaymentAlerts,
  (alerts: Alert[]): Alert[] => fromAlerts.getConsolidatedAlert(alerts)
);

export const getPaperlessBillingAlerts = createSelector(
  fromBillAccounts.getModifiableBillAccounts,
  fromPolicies.getPolicies,
  (billAccounts: BillAccount[], policies: Policy[]): Alert[] =>
    fromAlerts.getPaperlessBillingAlerts(billAccounts, policies)
);

export const getConsolidatedPaperlessBillingAlerts = createSelector(
  getPaperlessBillingAlerts,
  (alerts: Alert[]): Alert[] => fromAlerts.getConsolidatedAlert(alerts)
);

export const getKydAlerts = createSelector(
  fromPolicies.getPolicies,
  getKydEnrollments,
  fromPolicies.getPoliciesLoaded,
  getKydEnrollmentsLoaded,
  getKydVehicleEligibility,
  (
    policies: Policy[],
    kydEnrollments: PolicyEnrollment[],
    policiesLoaded: boolean,
    kydEnrollmentsLoaded: boolean,
    kydEligibility: KydVehicleEligibility[]
  ): Alert[] =>
    fromAlerts.getKydAlerts(
      policies,
      kydEnrollments,
      policiesLoaded && kydEnrollmentsLoaded,
      kydEligibility
    )
);

export const getConsolidatedKydAlerts = createSelector(getKydAlerts, (alerts: Alert[]): Alert[] =>
  fromAlerts.getConsolidatedAlert(alerts)
);

export const getKydSmartphoneAlerts = createSelector(
  getEligibleKydSmartphonePolicies,
  getKydSmartphoneEnrollments,
  fromPolicies.getPoliciesLoaded,
  getKydSmartphoneEnrollmentsLoaded,
  (
    kydSmartphonePolicies: AutoPolicy[],
    kydSmartphoneEnrollments: KydSmartphoneSummary[],
    policiesLoaded: boolean,
    kydSmartphoneEnrollmentsLoaded: boolean
  ): Alert[] =>
    fromAlerts.getKydSmartphoneAlerts(
      kydSmartphonePolicies,
      kydSmartphoneEnrollments,
      policiesLoaded && kydSmartphoneEnrollmentsLoaded
    )
);

export const getConsolidatedKydSmartphoneAlerts = createSelector(
  getKydSmartphoneAlerts,
  (alerts: Alert[]): Alert[] => fromAlerts.getConsolidatedAlert(alerts)
);

export const getAutoPayAlertsLoading = createSelector(
  AutoPaySelectors.getAnyLoading,
  fromPolicies.getPoliciesLoading,
  fromBillAccounts.getBillAccountsAnyLoading,
  PaymentAccountSelectors.getPaymentAccountsLoading,
  PaymentMethodSelectors.getLoading,
  (
    autoPayLoading,
    policiesLoading,
    billAccountLoading,
    paymentAccountLoading,
    paymentMethodsLoading
  ) =>
    autoPayLoading ||
    policiesLoading ||
    billAccountLoading ||
    paymentAccountLoading ||
    paymentMethodsLoading
);

export const getAutoPayAlerts = createSelector(
  fromBillAccounts.getModifiableBillAccounts,
  fromPolicies.getPolicies,
  AutoPaySelectors.getAutomaticPayments,
  PaymentMethodSelectors.getPaymentMethods,
  getAutoPayAlertsLoading,
  (
    billAccounts: BillAccount[],
    policies: Policy[],
    automaticPayments: AutomaticPayment[],
    paymentMethods: PaymentMethod[],
    autoPayAlertsLoading
  ): Alert[] =>
    fromAlerts.getAutoPayAlerts(
      billAccounts,
      policies,
      automaticPayments,
      paymentMethods,
      autoPayAlertsLoading
    )
);

export const getConsolidatedAutoPayAlerts = createSelector(
  getAutoPayAlerts,
  (alerts: Alert[]): Alert[] => fromAlerts.getConsolidatedAlert(alerts)
);

// AS: Creating a new alert to show the alert banner on the overview if the gopaperless fails.
export const getConsolidatedGoPaperLessAlerts = createSelector(
  fromBillAccounts.getBillAccountsNotifications,
  fromGoPaperless.getGoPaperlessError,
  (billAccounts: BillingNotificationsState[], goPaperlessError: boolean): Alert[] =>
    fromAlerts.getPaperLessEnrollmentErrorAlerts(billAccounts, goPaperlessError)
);

export const getPaperlessPolicyAlerts = createSelector(
  fromPolicies.getPolicies,
  userQuery.getPartyDetails,
  (policies: Policy[], party: Party): Alert[] =>
    fromAlerts.getPaperlessPolicyAlerts(policies, party)
);

export const getConsolidatedPaperlessPolicyAlerts = createSelector(
  getPaperlessPolicyAlerts,
  (alerts: Alert[]): Alert[] => fromAlerts.getConsolidatedAlert(alerts)
);
// created new error banner on overview page if email deep link for bill account fails.
export const getConsolidatedEmailDeepLinkFailureAlerts = createSelector(
  billaccountsQuery.getBillAccountsState,
  (state: BillingState) => fromAlerts.getEmailDeepLinkFailureAlerts(state.billingState)
);

export const getConsolidatedAlerts1 = createSelector(
  getHomesitePoliciesAlert,
  getSystemStableAlert,
  getConsolidatedBillOverdueAlerts,
  getConsolidatedUnsuccessfulPaymentAlerts,
  getConsolidatedPaperlessBillingAlerts,
  getConsolidatedPaperlessPolicyAlerts,
  (
    homesitePoliciesAlerts: Alert[],
    systemStableAlerts: Alert[],
    billOverdueAlerts: Alert[],
    unsuccessfulPaymentAlerts: Alert[],
    paperlessBillingAlerts: Alert[],
    paperlessPolicyAlerts: Alert[]
  ): Alert[] => [
    ...homesitePoliciesAlerts,
    ...systemStableAlerts,
    ...billOverdueAlerts,
    ...unsuccessfulPaymentAlerts,
    ...paperlessBillingAlerts,
    ...paperlessPolicyAlerts
  ]
);

export const getConsolidatedAlerts2 = createSelector(
  getConsolidatedAutoPayAlerts,
  getConsolidatedKydAlerts,
  getConsolidatedKydSmartphoneAlerts,
  getConsolidatedGoPaperLessAlerts,
  getConsolidatedEmailDeepLinkFailureAlerts,
  (
    autopayAlerts: Alert[],
    kydAlerts: Alert[],
    kydSmartphoneAlerts: Alert[],
    goPaperLessAlerts: Alert[],
    emailDeepLinkfailureAlerts: Alert[]
  ): Alert[] => [
    ...autopayAlerts,
    ...kydAlerts,
    ...kydSmartphoneAlerts,
    ...goPaperLessAlerts,
    ...emailDeepLinkfailureAlerts
  ]
);

export const getConsolidatedAlerts = createSelector(
  getConsolidatedAlerts1,
  getConsolidatedAlerts2,
  getConsolidatedKydSmartphoneAlerts,
  (consolidatedAlerts1, consolidatedAlerts2): Alert[] => [
    ...consolidatedAlerts1,
    ...consolidatedAlerts2
  ]
);

export const getError = (state: RootState) => state.error;

// Replace a vehicle
export const getReplaceVehicleState = (state: RootState) => state.replaceVehicle;
export const getReplaceVehicleStatus = createSelector(
  getReplaceVehicleState,
  fromReplaceVehicle.getStatus
);
export const getReplaceVehicleHasError = createSelector(
  getReplaceVehicleState,
  fromReplaceVehicle.hasError
);
export const getReplaceVehicleLoading = createSelector(
  getReplaceVehicleState,
  fromReplaceVehicle.getLoading
);
export const getReplaceVehicleNotifyAgentStatus = createSelector(
  getReplaceVehicleState,
  fromReplaceVehicle.getStatus
);
export const getReplaceVehiclePremium = createSelector(
  getReplaceVehicleState,
  fromReplaceVehicle.getPremium
);
export const getReplaceVehicleNewVehicle = createSelector(
  getReplaceVehicleState,
  fromReplaceVehicle.getNewVehicle
);

export const getReplaceVehicleConfirmationType = createSelector(
  getReplaceVehicleState,
  fromReplaceVehicle.getConfirmationType
);

export const getVehicleRiskSelectionContextType = createSelector(
  getReplaceVehicleState,
  fromReplaceVehicle.getVehicleRiskSelectionContext
);

export const getReplaceVehicleRisks = createSelector(
  fromPolicies.getAutoPolicyRisks,
  fromPolicySummaries.getPolicySummaries,
  (risks, policySummaries) => {
    const rpvRisks: Array<RiskModel> = new Array<RiskModel>();
    risks.forEach(risk => {
      policySummaries.forEach(policy => {
        if (_get(risk, 'policyNumber', '') === _get(policy, 'policyNumber', '')) {
          risk = Object.assign({}, risk, { contractState: policy.contractState });
          rpvRisks.push(risk);
        }
      });
    });
    return rpvRisks;
  }
);

export const getChangeCoverageRisks = createSelector(
  fromPolicies.getAutoPolicyRisks,
  getChangeCoveragePolicySummaries,
  (risks, policySummaries) => {
    const rpvRisks: Array<RiskModel> = new Array<RiskModel>();
    risks.forEach(risk => {
      policySummaries.forEach(policy => {
        if (_get(risk, 'policyNumber', '') === _get(policy, 'policyNumber', '')) {
          risk = Object.assign({}, risk, { contractState: policy.contractState });
          rpvRisks.push(risk);
        }
      });
    });
    return rpvRisks;
  }
);
// Replace a vehicle
export const getReplaceVehiclePolicyState = (state: RootState) => state.replaceVehiclePolicy;

// Change my coverage
export const getCoverageChangeRisks = createSelector(
  getChangeCoverageRisks,
  (vehicles, inEligibleStates: string[]) =>
    vehicles.filter(
      vehicle =>
        !!_get(
          vehicle.vehicleCoverages.find(coverage => coverage.code === 'PAComprehensiveCov'),
          'deductibleTerms[0].value',
          ''
        ) &&
        !!_get(
          vehicle.vehicleCoverages.find(coverage => coverage.code === 'PACollisionCov'),
          'deductibleTerms[0].value',
          ''
        ) &&
        !inEligibleStates.find(stateCode => stateCode === vehicle.contractState)
    )
);

// Add Vehicle
export const getAddVehicleEnabled = createSelector(
  featureFlagQuery.getFeatureFlagEntitiesState,
  BrandSelectors.getIsPartner,
  (entities, isPartner) => !_isEmpty(entities) && entities['add_vehicle'].enabled && !isPartner
);

// Partner Add Vehicle
export const partnerAddVehicleEnabled = createSelector(
  featureFlagQuery.getFeatureFlagEntitiesState,
  BrandSelectors.getIsPartner,
  (entities, isPartner) =>
    !_isEmpty(entities) && entities['partner_add_vehicle'].enabled && isPartner
);

// Edelivery reducers

// Opportunity

export const opportunitiesEnabled = createSelector(
  featureFlagQuery.getFeatureFlagEntitiesState,
  BrandSelectors.getIsPartner,
  (entities, isPartner) => !_isEmpty(entities) && entities['opportunities'].enabled && !isPartner
);

/**
 * @returns boolean.
 * Conditions to be satisfied to display ez button.
 * 1. Risks are available
 * 2. No terms and conditions error
 * 3. Atleast one billaccount/policy preference is not email
 * 4. No only life policies
 * 5. No retrieve preferences error
 */
export const isEligibleToGoPaperless = createSelector(
  fromBillAccounts.getBillAccounts,
  fromGoPaperless.isEnrolledGoPaperless,
  fromPolicies.getPolicyRisks,
  fromEdeliverySelectors.getEdeliveryTermsAndConditionsHasError,
  fromPolicies.isEligibleForPaperless,
  fromBillAccounts.isEligibleToViewPreferences,
  (
    billAccounts,
    isPolicyPaperless,
    risks,
    eDeliveryTermsAndConditionsError,
    nonLifePolicies,
    noPreferencesError
  ) => {
    const isPolicyPaper = !isPolicyPaperless;
    const isBillingPaper = !!billAccounts.find(billAccount =>
      _get(billAccount, 'billingPreferences.preferences', []).some(
        preference =>
          preference.isModifiable &&
          preference.preferenceDeliveryCode.toLowerCase() !== DeliveryPreferences.EMAIL
      )
    );
    return (
      risks.length > 0 &&
      !eDeliveryTermsAndConditionsError &&
      (isBillingPaper || isPolicyPaper) &&
      nonLifePolicies &&
      noPreferencesError
    );
  }
);

export const billingEligibleToGoPaperless = createSelector(
  fromBillAccounts.getBillAccounts,
  billAccounts => {
    const isBillingPaper =
      !!billAccounts &&
      !!billAccounts.find(billAccount =>
        _get(billAccount, 'billingPreferences.preferences', []).some(
          preference =>
            preference.isModifiable &&
            preference.preferenceDeliveryCode.toLowerCase() !== DeliveryPreferences.EMAIL
        )
      );
    return isBillingPaper;
  }
);

// selectors for credit relief
export const isKydSmartphoneEligible = createSelector(
  getKydPolicyEligibility,
  getKydSmartphoneEligibility,
  getKydSmartphoneEnrollments,
  (policyEligibilities, smartphoneEligible, enrollments) =>
    policyEligibilities.some(
      policyEligibility =>
        policyEligibility.isPolicyEligible &&
        policyEligibility.isStateEligible &&
        policyEligibility.programCategory === UBI_PROGRAM.TRUE_MOTION &&
        smartphoneEligible &&
        enrollments[0].ubiProgramCategoryCode !== UBI_PROGRAM.AUTOMATIC
    )
);

export const isWithinKydMigrationPeriod = createSelector(
  getKydPolicyEligibility,
  policyEligibilities =>
    policyEligibilities.some(
      policyEligibility =>
        policyEligibility.reasonCode ==='400007'
        )
    
);

export const showKydSmartphoneBanner = createSelector(
  isKydSmartphoneEligible,
  getKydSmartphoneEnrollments,
  (isKydSmartphoneEligibleVar, smartphoneEnrollments) =>
    isKydSmartphoneEligibleVar &&
    !smartphoneEnrollments.some(
      summary =>
        summary.enrollmentStatus === 'Enrolled' || summary.enrollmentStatus === 'Waiting Period'
    )
);

export const isAutopayEligible = createSelector(
  getBillaccountsListEligibleForAutopay,
  AutoPayUtilSelectors.getBillAccountsEligibleForAutoPay,
  (eligibleAccounts, billAccountsEligibleForAutoPay) => {
    const accounts: BillAccount[] = billAccountsEligibleForAutoPay;
    return accounts?.length > 0 && !accounts.some(account => account.pastDue);
  }
);

export const showAutopayBanner = createSelector(
  isAutopayEligible,
  getBillaccountsListEligibleForAutopay,
  AutoPayUtilSelectors.getBillAccountsEligibleForAutoPay,
  fromPolicies.getVehiclePolicies,
  (isAutopayEligibleVar, billAccounts, billAccountsEligibleForAutoPay, policies) => {
    const accounts: BillAccount[] = billAccountsEligibleForAutoPay;
    const autoPolicyNumbers: string[] = policies.map(policy => policy.policyNumber);
    return (
      isAutopayEligibleVar &&
      accounts.some(billAccount =>
        _get(billAccount, 'policyList', []).some(policy =>
          autoPolicyNumbers.includes(policy.policyNumber)
        )
      )
    );
  }
);

export const isPaperlessEligible = createSelector(isEligibleToGoPaperless, eligible => eligible);

export const showPaperlessBanner = createSelector(
  isPaperlessEligible,
  fromBillAccounts.getBillAccounts,
  fromPolicies.getVehiclePolicies,
  fromGoPaperless.isEnrolledGoPaperless,
  (isPaperlessEligibleVar, billAccounts, policies, isEnrolled) => {
    const autoPolicyNumbers: string[] = policies.map(policy => policy.policyNumber);
    const billAccountsWithAutoPolicy = billAccounts.filter(billAccount =>
      _get(billAccount, 'policyList', []).some(policy =>
        autoPolicyNumbers.includes(policy.policyNumber)
      )
    );
    return (
      isPaperlessEligibleVar &&
      ((policies.length > 0 && !isEnrolled) ||
        billAccountsWithAutoPolicy.some(billAccount =>
          _get(billAccount, 'billingPreferences.preferences', []).some(
            preference =>
              preference.isModifiable &&
              preference.preferenceDeliveryCode.toLowerCase() !== DeliveryPreferences.EMAIL
          )
        ))
    );
  }
);

export const hasAutoDiscountsToEnroll = createSelector(
  showKydSmartphoneBanner,
  showAutopayBanner,
  showPaperlessBanner,
  (showKydBanner, showAutopayBannerVar, showPaperlessBannerVar) =>
    showKydBanner || showAutopayBannerVar || showPaperlessBannerVar
);

export const programsLoading = createSelector(
  // kyd
  getAnyKydLoading,
  // autopay
  BillAccountsSelectors.getBillAccountsAnyLoading,
  AutoPaySelectors.getAnyLoading,
  ScheduledPaymentSelectors.getScheduledPaymentsLoading,
  BillAccountsSelectors.getAllBillingPreferencesLoaded,
  fromPolicies.getPoliciesLoading,
  (
    kydLoading,
    billAccountsLoading,
    automaticPaymentsNewLoading,
    scheduledPaymentsLoading,
    prefsLoaded,
    policiesLoading
  ) =>
    kydLoading ||
    billAccountsLoading ||
    automaticPaymentsNewLoading ||
    scheduledPaymentsLoading ||
    policiesLoading ||
    !prefsLoaded
);

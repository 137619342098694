export interface OpportunityProduct {
  policyId: string;
  contractStateCode: string;
  primaryNamedInsuredPartyId: string;
  secondaryNamedInsuredPartyId: string;
  servicingProducerId: number;
  productType: string;
  riskId: string;
  riskDescription: string;
}

import { createAction, props } from '@ngrx/store';
import {
  AnalyticOptions,
  DynatraceActionPayload,
  UserAnalyticParams
} from '../models/analytics.model';

export const loadAdobeAnalytics = createAction('[Analytics] Load Adobe Analytics');
export const loadAdobeAnalyticsSuccess = createAction('[Analytics] Load Adobe Analytics Success');
export const loadAdobeAnalyticsFailure = createAction('[Analytics] Load Adobe Analytics Failure');

export const loadGoogleAnalytics = createAction('[Analytics] Load Google Analytics');
export const loadGoogleAnalyticsSuccess = createAction('[Analytics] Load Google Analytics Success');
export const loadGoogleAnalyticsFailure = createAction('[Analytics] Load Google Analytics Failure');

export const loadDynatrace = createAction('[Analytics] Load Dynatrace');
export const loadDynatraceSuccess = createAction('[Analytics] Load Dynatrace Success');
export const loadDynatraceFailure = createAction('[Analytics] Load Dynatrace Failure');

export const setUserInfo = createAction(
  '[Analytics] Set User Info',
  props<{ user: UserAnalyticParams }>()
);

export const sendAnalytic = createAction(
  '[Analytics] Send Adobe Analytic',
  props<{ options: AnalyticOptions }>()
);

export const storeGtmDetails = createAction(
  '[Analytics] Store Google Tag Manager Details',
  props<{ payload: any }>()
);

export const sendDynatraceAction = createAction(
  '[Analytics] Send Dynatrace Action',
  props<{ payload: DynatraceActionPayload }>()
);

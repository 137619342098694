import { BillingSharedUiModule } from '@amfam/billing/shared/ui';
import { MasksModule } from '@amfam/shared/ui/masks';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BankAccountAddEditComponent } from './bank-account-add-edit/bank-account-add-edit.component';
import { BankAccountCheckImageComponent } from './bank-account-check-image/bank-account-check-image.component';
import { CreditCardAddEditComponent } from './credit-card-add-edit/credit-card-add-edit.component';
import { ScheduledPaymentsDetailComponent } from './scheduled-payments-detail/scheduled-payments-detail.component';

@NgModule({
  imports: [
    CommonModule,
    SharedServicesModule,
    UiKitModule,
    FormsModule,
    ReactiveFormsModule,
    MasksModule,
    BillingSharedUiModule
  ],
  declarations: [
    BankAccountAddEditComponent,
    CreditCardAddEditComponent,
    BankAccountCheckImageComponent,
    ScheduledPaymentsDetailComponent
  ],
  exports: [
    BankAccountAddEditComponent,
    CreditCardAddEditComponent,
    BankAccountCheckImageComponent,
    BillingSharedUiModule,
    ScheduledPaymentsDetailComponent
  ]
})
export class BillingPaymentMethodUiModule {}

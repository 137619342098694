import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { DsModalService } from '@amfam/ui-kit';

@Component({
  selector: 'amfam-early-exit-modal',
  templateUrl: './early-exit-modal.component.html',
  styleUrls: ['./early-exit-modal.component.scss']
})
export class EarlyExitModalComponent implements OnInit {
  @Input() exitFlowConfimationText: string;
  @Input() earlyExitFlowModalId: string;
  @Input() exitPrimaryButtonText: string;
  @Output() stay = new EventEmitter();
  @Output() leavePage = new EventEmitter();
  public modalWidth = '596';

  constructor(private modalService: DsModalService) {}

  @Input() openModal = (isOpen: boolean) => () => isOpen ?? this.open();

  ngOnInit(): void {}

  open(): void {
    this.modalService.open(this.earlyExitFlowModalId);
  }

  close(): void {
    this.modalService.close(this.earlyExitFlowModalId);
  }
}

export const ANALYTICS_ENROLLMENT_TYPE: any = {
  CONVERSION: 'AddPersonalLine',
  MAE: 'mae',
  PERSONAL: 'personal'
};

export interface ReplaceVehicleAnalyticsInfoModel {
  agentName?: string;
  referenceNumber?: string;
}

export interface CoverageChangeAnalyticsInfoModel {
  selection?: string[];
  RRAmount?: string;
}

export interface ReportAClaimCompleteAnalyticsModel {
  claimNumber?: string;
}

export interface PayNowModel {
  paymentAmount?: string;
  paymentDate?: string;
}

export class AnalyticsUserType {
  public static cfr = 'Business';
  public static conversion = 'Business Converting to Personal';
  public static customerAfi = 'Personal';
  public static customerPartner = 'Connect';
  public static mae = 'MyAccount Agent Enrollment';
  public static maePartner = 'MyAccount Agent Enrollment – Connect';
  public static cruise = 'Agent POS';
  public static cruisePartner = 'Connect POS';
  public static nonCustomer = 'Non-Customer';
}

export class DataLayer {
  page?: PageInfo;
  event?: EventInfo[];
  user?: UserInfo;
  replaceVehicle?: ReplaceVehicleAnalyticsInfoModel;
  policyCoverageChange?: CoverageChangeAnalyticsInfoModel;
  linkClicked?: ClickInfo;
  claims?: ReportAClaimCompleteAnalyticsModel;
  payNow?: PayNowModel;
}

export class PageAnalytic {
  pageName: string;
  experience: string;
  primaryCategory: string;
  subCategory1: string;
  subCategory2: string;
  subCategory3: string;
  timeframe?: string;
  claimNumber?: string;
  paymentAmount?: string;
  paymentDate?: string;
  type?:string;
}

export class EventAnalytic {
  eventName: string;
  eventStep: string;
  eventAmount?: string | Array<string>;
  eventTerm?: string | Array<string>;
  replaceVehicle?: {
    agentName?: string;
    referenceNumber?: string;
  };
  changeCoverageInfo?: {
    selection?: string[];
    RRAmount?: string;
  };
  claimConfirmationInfo?: {
    claimNumber?: string;
  };
}
export class UserAnalytic {
  userType?: any;
  trackId?: string;
  partnerId?: string;
  experienceId?: string;
}

export class ButtonAnalytic {
  link: string;
}
export class PageInfo {
  pageInfo: {
    pageName: string;
    experience: string;
  };
  category: {
    primaryCategory: string;
    subCategory1: string;
    subCategory2: string;
    subCategory3: string;
  };
  attributes: {
    language: string;
  };
  paymnthistory: {
    timeframe: string;
  };
}
export class EventInfo {
  eventInfo: EventAnalytic;
}
export class UserInfo {
  profileInfo: {
    userType: string;
    trackID: string;
    experienceID: string;
    partnerID: string;
  };
}
export class ClickInfo {
  link: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CLAIMSUBMIT_FEATURE_KEY,
  initialState as claimSubmitInitialState,
  claimSubmitReducer
} from './+state/claim-submit.reducer';
import { ClaimSubmitEffects } from './+state/claim-submit.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIMSUBMIT_FEATURE_KEY, claimSubmitReducer, {
      initialState: claimSubmitInitialState
    }),
    EffectsModule.forFeature([ClaimSubmitEffects])
  ]
})
export class ClaimSubmitDataAccessModule {}

import { EntityNotification } from '@amfam/shared/models';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCommunicationPreferencesNotifications from './communication-preferences-notifications.reducer';
import * as fromCommunicationPreferences from './communication-preferences.reducer';

export const communicationPreferencesFeatureKey = 'communicationPreferences';

// Reducer Map
export interface State {
  communicationPreferencesState: fromCommunicationPreferences.State;
  communicationPreferencesNotificationsState: fromCommunicationPreferencesNotifications.State;
}

export const reducers: ActionReducerMap<State> = {
  communicationPreferencesState: fromCommunicationPreferences.reducer,
  communicationPreferencesNotificationsState: fromCommunicationPreferencesNotifications.reducer
};

// State Selectors
export const getState = createFeatureSelector<State>(communicationPreferencesFeatureKey);

export const getCommunicationPreferencesState = createSelector(
  getState,
  (state: State) => state.communicationPreferencesState
);

export const getCommunicationPreferencesNotificationsState = createSelector(
  getState,
  (state: State) => state.communicationPreferencesNotificationsState
);

// communicationPreferences Notifications Selectors
export const getCommunicationPreferencesNotifications = createSelector(
  getCommunicationPreferencesNotificationsState,
  fromCommunicationPreferencesNotifications.selectAllCommunicationPreferencesNotifications
);

export const getCommunicationPreferencesNotification = (billAccountNumber: string) =>
  createSelector(
    getCommunicationPreferencesNotificationsState,
    (state: fromCommunicationPreferencesNotifications.State) => state.entities[billAccountNumber]
  );

export const getAnyLoading = createSelector(
  getCommunicationPreferencesNotifications,
  getCommunicationPreferencesState,
  (notifications: EntityNotification[]) =>
    notifications.map(notification => notification.loading).includes(true)
);

export const hasAnyError = createSelector(
  getCommunicationPreferencesNotifications,
  getCommunicationPreferencesState,
  (notifications: EntityNotification[]) =>
    notifications.map(notification => notification.hasError).includes(true)
);

export const getCorrelationIds = createSelector(
  getCommunicationPreferencesNotifications,
  (notifications: EntityNotification[]) =>
    notifications.map(notification => notification.correlationId)
);

export const getLoading = (billAccountNumber: string) =>
  createSelector(
    getCommunicationPreferencesNotification(billAccountNumber),
    (notification: EntityNotification) => notification.loading
  );

export const hasError = (billAccountNumber: string) =>
  createSelector(
    getCommunicationPreferencesNotification(billAccountNumber),
    (notification: EntityNotification) => notification.hasError
  );

export const getCorrelationId = (billAccountNumber: string) =>
  createSelector(
    getCommunicationPreferencesNotification(billAccountNumber),
    (notification: EntityNotification) => notification.correlationId
  );

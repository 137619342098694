<div>
  <amfam-enrollment-header
    [firstLine]="confirmationFirstLineText"
    [secondLine]="confirmationSecondLineText"
  ></amfam-enrollment-header>
  <ds-enrollment-next></ds-enrollment-next>
  <div class="column twelve lg-push-two md-eight md-push-two">
    <call-to-action
      [primaryButtonName]="'CONTINUE'"
      (primaryEvent)="continue()"
      [secondaryButtonName]="'Exit Setup'"
      (secondaryEvent)="exit()"
    ></call-to-action>
  </div>
</div>

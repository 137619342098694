import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PartyService } from './services/party.service';
import { DigitalAccountService } from './services/digital-account.service';
import { DigitalServiceProgramService } from './services/digital-service-program.service';
import { ProfileUtilService } from './services/profile-util.service';
import { AlternateAuthService } from './services/alternate-auth.service';
import { ProfileEffects } from './+state/profile.effects';

import * as fromProfile from './+state/profile.reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ProfileEffects]),
    StoreModule.forFeature(fromProfile.PROFILE_FEATURE_KEY, fromProfile.reducer)
  ],
  providers: [
    PartyService,
    DigitalAccountService,
    DigitalServiceProgramService,
    ProfileUtilService,
    AlternateAuthService
  ]
})
export class ProfileDataAccessModule {}

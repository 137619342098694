import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ProfileOverviewModel } from '@amfam/shared/models';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileCardComponent {
  @Input() profileCard: ProfileOverviewModel;
  @Input() isCustomer: boolean;
  @Input() isShellAccount: boolean;
  @Input() canShowStatus: boolean;
  @Input() shellEmail: string;
}

<div>
  <h1 *ngIf="!partnerData.isPartner" data-cy="goPaperlessPolicyServiceTermsConditions">Go Paperless Policy Service Terms & Conditions</h1>
  <h2>Electronic Communication Disclosure Form</h2>
  <p>
    This Electronic Communication Disclosure Form ("Disclosure") applies to those communications,
    forms, disclosures and notices ("communications") that are required to be given to you, and in
    some cases signed and returned to us, in connection with the insurance product(s) you have
    purchased from American Family Mutual Insurance Company and/or its subsidiaries. By agreeing to
    the terms and conditions of this Disclosure, you are agreeing to receive these communications
    electronically. We may modify these terms and conditions at any time at our discretion and your
    continued election to receive electronic delivery of communications means you accept those
    changes as well. A current version will be viewable in My Account. If you don&rsquo;t accept the
    changes, you may withdraw your consent at any time.
  </p>
  <p>
    "I", "you" and "your" means the person or persons listed as named insureds on a policy. "We",
    "us" and "Company" means the specific underwriting company identified in your quote, application
    and the documents we provide to you, including your insurance policy. In order to select
    electronic delivery of communications, you understand that you must sign up for and maintain an
    account through My Account on the
    <a [attr.href]="partnerData.landingPageUrl" target="_blank">{{ partnerData.landingPage }}</a>
    website. All electronic communications will be accessed through My Account on the
    <a [attr.href]="partnerData.landingPageUrl" target="_blank">{{ partnerData.landingPage }}</a>
    website.
  </p>
  <h2>The following information will apply if you choose electronic delivery:</h2>
  <ol>
    <li>
      <strong>Your legal rights.</strong> We are required by law to provide specific communications
      to you in connection with the insurance product(s) you have purchased from us. You may choose
      to receive these communications from us electronically if we first provide you with this
      disclosure and obtain your consent to receive these communications electronically. Your
      consent will apply to all named insureds on your insurance policy (ies) that you request for
      electronic delivery. While we may occasionally provide some communications to you in paper
      form, you must agree to receive communications from us electronically and you must have the
      appropriate hardware and software (see #5) available to you to receive this information from
      us electronically.
      <br />
      <br />
      You acknowledge and agree that your consent to electronic communications is being provided in
      connection with a transaction affecting interstate commerce that is subject to the federal
      Electronic Signatures and National Commerce Act, and that you and we both intend that the Act
      apply to the fullest extent possible to validate our ability to conduct business with you by
      electronic means.
    </li>
    <br />
    <li>
      <strong>Scope of communications to be provided in electronic form</strong>. The types of
      electronic communications that you are consenting to receive, acknowledge, respond to and/or
      sign includes, but is not limited to, the following:<br />
      <br />
      <ul>
        <li>
          All legal and regulatory disclosure forms, notices and communications associated with the
          purchase, renewal, changes and/or maintenance of your insurance policy(ies) as determined
          by the company and allowed by the regulatory authority.
        </li>
        <br />
        <li>
          All company related forms, notices and communications associated with the purchase,
          renewal, changes and/or maintenance of your insurance policy as determined by the company
          and allowed by the regulatory authority.
        </li>
        <br />
        <li>
          All required forms, notices and/or disclosures relating to policy limits, coverage
          choices, selections and rejections, including but not limited to disclosures, notices or
          forms related to the acknowledgement, selection or rejection of coverage.
        </li>
      </ul>
    </li>
    <br />
    <li>
      <strong
        >Setting your electronic communications preferences. This applies to all policies under your
        account that are eligible for electronic delivery.</strong
      >
      Only you will be able to set or change your preferences for electronic delivery. This
      preference must be made through My Account. The Company or agent will not have the ability to
      set or change your preferences for you. If you select electronic delivery, all policies that
      are eligible will be enrolled in electronic delivery. You will not be able to set your
      electronic delivery preferences by each individual policy. Not all policy types may be
      eligible for electronic delivery.
    </li>
    <br />
    <li>
      <strong>Types of communications you will receive in paper.</strong> Anything required to be
      sent to you in paper form as defined by your regulatory authority will be sent to you in paper
      copy form and may also be available electronically. In the event our electronic delivery
      system is unavailable, we reserve the right to provide a paper (instead of electronic) copy of
      any communication you have authorized us to provide electronically.
    </li>
    <br />
    <li>
      <strong>Hardware and software requirements.</strong> In order to access, view, sign and retain
      electronic communications that we make available to you, you must have access to a personal
      computer with internet connectivity and at least one of the following:<br />
      <br />
      <ol class="list-style-lower-alpha">
        <li>
          For Windows Operating System:
          <ol class="list-style-upper-roman">
            <li>
              <strong>Browsers:</strong> Use current version of Internet Explorer, Firefox or Google
              Chrome
            </li>
            <br />
            <li>
              <strong>Portable Document Reader:</strong> Use current version of Adobe Acrobat Reader
            </li>
          </ol>
        </li>
        <br />
        <li>
          For Macintosh Operating System:
          <ol class="list-style-upper-roman">
            <li><strong>Browsers:</strong> Use current version of Safari</li>
            <br />
            <li>
              <strong>Portable Document Reader:</strong> Use current version of Adobe Acrobat Reader
            </li>
          </ol>
        </li>
        <br />
        <li>
          You must also have:
          <ol class="list-style-upper-roman">
            <li>
              A valid e-mail account with an e-mail service provider in order to participate in our
              electronic Communications programs. It is your responsibility to provide us with a
              true, accurate and complete e-mail address.
            </li>
            <br />
            <li>
              Sufficient electronic storage capacity on your computer&rsquo;s hard drive or other
              data storage unit, if you want to save the documents.
            </li>
            <br />
            <li>
              A printer capable of printing web content or PDF documents to print the disclosure or
              policy related documents, if you want to locally print from your computer.
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <br />
    <li>
      <strong>How to withdraw your consent to this e-communications disclosure.</strong> You may
      withdraw your consent to receive communications electronically or change your electronic
      delivery preferences for your policy(ies) at any time by updating your electronic delivery
      preferences in My Account. If you choose to withdraw your consent to receive communications
      electronically, all documents related to your insurance policy(ies) will be sent to you by
      U.S. mail beginning with the next transaction processed on your policy after you withdraw
      consent. Any discounts applied to your policy(ies) that are based entirely or in part on your
      consent to receive communications electronically, will be removed at the next renewal of your
      policy after you withdraw consent. All named insured on the policy must withdraw consent from
      electronic delivery of document to receive communications via U.S. mail.
    </li>
    <br />
    <li>
      <strong>Requesting paper copies of communications.</strong> We will not send you a paper copy
      of these electronic communications, unless you request it or we otherwise deem it appropriate
      to do so. You can obtain a paper copy of any communication we provide to you electronically by
      printing it yourself or by requesting that we mail you a paper copy. Requests for paper copies
      must be made within a reasonable time after we first provided the electronic communication to
      you. To request a paper copy, contact us by telephone at {{ partnerData.cusCareNumber }}.
      There is no charge associated with requesting a paper copy of a communication we sent you
      electronically. We reserve the rights to provide a paper (instead of electronic) copy of any
      communication that you have authorized us to provide electronically.
    </li>
    <br />
    <li>
      <strong>Communications in writing.</strong> All communications in either electronic or paper
      format from us to you will be considered "in writing." You should print or download for your
      records a copy of all electronic communications, this disclosure and any other document that
      is important to you. A copy of this disclosure will be available in My Account.
    </li>
    <br />
    <li>
      <strong>Updating your contact information.</strong> We will provide notice of activity on your
      account through your e-mail. It is your responsibility to maintain current e-mail contact
      information in My Account. You will need to make any updates to your e-mail address where
      notices are sent through My Account. The Company or the agent will not be able to make e-mail
      contact information changes for you.
      <br />
      <br />
      You understand any electronic communications will be deemed as provided to you, even if you do
      not maintain current e-mail or contact information with us. In the event we experience
      multiple email attempts returned as undeliverable by your service provider, we reserve the
      right to interpret these events as a withdrawal of your consent for electronic communications.
    </li>
    <br />
    <li>
      <strong>My Account.</strong> It is your responsibility to maintain an active My Account on the
      <a [attr.href]="partnerData.landingPageUrl" target="_blank">{{ partnerData.landingPage }}</a>
      website to access policy related documents. If you disable your account, we will discontinue
      electronic delivery of documents and change your delivery method to paper.
    </li>
    <br />
    <li>
      <strong>Retain copies for your records.</strong> It is your responsibility to make any hard
      copies of information you want to retain for your own use. Only current documents for active
      policies will be available for view or print in My Account. Older documents for active and
      inactive policies may be archived according to reasonable document retention policies and
      available on request.
    </li>
  </ol>
  <br />
</div>

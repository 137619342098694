<div
  *ngIf="allowToggleAccordion && allowMultipleOpenCategories"
  class="expand-collapse float-clear-all"
>
  <span class="float-right margin-bottom-20">
    <button ds-button class="link" [disabled]="!showExpandButton" (click)="expandAll()" data-cy="expandAll">
      <span class="accent">Expand All</span>
    </button>
    <span class="separator">/</span>
    <button ds-button class="link" [disabled]="!showCollapseButton" (click)="collapseAll()">
      <span class="accent">Collapse All</span>
    </button>
  </span>
</div>
<ol class="accordion" [class.open]="totalCategoriesOpen > 0">
  <ng-content></ng-content>
</ol>

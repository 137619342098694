import {
  Observable,
  Subscription,
  forkJoin,
  from,
  of as observableOf,
  throwError as observableThrowError,
  of
} from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, take, toArray } from 'rxjs/operators';

import { Policy } from '@amfam/policy/models';
import { PartyService, ProfileActions } from '@amfam/profile/data-access';
import { Party } from '@amfam/shared/models';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import * as fromRoot from '../../../core/store';
import {
  KydSmartphonePolicy,
  KydSmartphoneRole,
  KydSmartphoneSummary
} from '../../../core/store/programs/kyd-smartphone';

import * as KydSmartphoneSummaryAction from '../../../core/store/programs/kyd-smartphone/kyd-smartphone-enrollment-summary.actions';

import { flatten as _flatten, get as _get, uniqBy as _uniqBy } from 'lodash';

// date-fns
import { format } from 'date-fns';

import { AutoPolicy, Risk } from '@amfam/policy/models';
import { PartyPhone } from '@amfam/shared/models';
import { UserState, userQuery } from '@amfam/shared/user';
import {
  KydSmartphoneEnrollmentContactDetail,
  KydSmartphoneEnrollmentOperator,
  KydSmartphoneEnrollmentRequest
} from '../../know-your-drive-partial-household/models';

@Injectable({
  providedIn: 'root'
})
export class KydSmartphoneService {
  private endpoint: string;

  private currentUserSub: Subscription;

  private currentUser: UserState;

  public policyList: Array<KydSmartphonePolicy>;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private partyService: PartyService,
    private store: Store<fromRoot.RootState>
  ) {
    this.endpoint = this.config.get('ubiApi');

    this.policyList = [];

    this.currentUserSub = this.store.select(userQuery.getUserState).subscribe(user => {
      this.currentUser = user;
    });
  }

  public getSmartphonePolicies(): KydSmartphonePolicy[] {
    return this.policyList;
  }

  public prepareSmartphonePolicies(policies: AutoPolicy[]): Observable<KydSmartphonePolicy[]> {
    return from(policies).pipe(
      mergeMap(
        policy => of(this.createPolicy(policy)),
        (policy, kydSmartphonePolicy) => ({ policy, kydSmartphonePolicy })
      ),
      mergeMap(
        policyInfo => {
          return forkJoin(
            this.getRoles(policyInfo.policy).map(role =>
              this.partyService.getOtherPartyDetails(role.customerId, false)
            )
          );
        },
        (policyInfo, parties) => {
          policyInfo.kydSmartphonePolicy.policyRoles = [];
          // business contact doesn't have party information
          parties
            .filter(party => party.firstName)
            .forEach(party => {
              const operator = this.createPolicyRole(party, policyInfo.policy);
              if (
                !policyInfo.kydSmartphonePolicy.policyRoles.some(
                  _role => _role.fullName === operator.fullName
                )
              ) {
                policyInfo.kydSmartphonePolicy.policyRoles.push(operator);
                this.updateOperatorAlreadyEnrollStatus();
              }
            });
          return policyInfo.kydSmartphonePolicy;
        }
      ),
      toArray(),
      map((policyList: KydSmartphonePolicy[]) => {
        this.policyList = policyList;
        return this.policyList;
      })
    );
  }

  private createPolicy(policy: Policy): KydSmartphonePolicy {
    const kydSmartphonePolicy: KydSmartphonePolicy = new KydSmartphonePolicy();

    kydSmartphonePolicy.assignProperties(policy);

    kydSmartphonePolicy.enrollmentSummary = {} as KydSmartphoneSummary;

    return kydSmartphonePolicy;
  }

  private createPolicyRole(party: Party, policy?: Policy): KydSmartphoneRole {
    const kydRole: KydSmartphoneRole = new KydSmartphoneRole();
    kydRole.customerId = party.customerIdentifier;
    kydRole.fullName = [party.firstName, party.lastName].join(' ');
    kydRole.firstName = party.firstName;
    kydRole.lastName = party.lastName;
    kydRole.email = party.primaryEmail;

    if (party.phones) {
      party.phones.forEach(phone => {
        if (kydRole.phones.indexOf(phone.tenDigitPhoneNumber) < 0) {
          kydRole.phones.push(phone.tenDigitPhoneNumber);
        }
      });
    }

    if (policy) {
      kydRole.isNamedInsured = policy.policyRoles.some(_role => {
        return (
          [_role.firstName, _role.lastName].join(' ') === kydRole.fullName &&
          _role.roleType === 'PrimaryNamedInsured'
        );
      });
    }

    return kydRole;
  }

  public getRoles(policy: AutoPolicy) {
    const vehicleRoles = _flatten(
      policy.vehicles.map(vehicle => {
        return vehicle.vehicleRoles;
      })
    ).filter(role => role.roleType !== 'ThirdPartyInterest'); // Filter out TPI
    return _uniqBy(vehicleRoles, 'name');
  }

  public setEnrollmentSummary(
    kydPolicy: KydSmartphonePolicy,
    enrollmentSummary: KydSmartphoneSummary
  ): Observable<KydSmartphonePolicy> {
    // status from backend
    kydPolicy = Object.assign({}, kydPolicy, {
      enrolled: enrollmentSummary.enrollmentStatus === 'Enrolled',
      waitingPeriod: enrollmentSummary.enrollmentStatus === 'Waiting Period',
      optedOut: enrollmentSummary.enrollmentStatus === 'Opted-Out',
      _allOperatorsEnrolled: false,
      // this flag will be edited from front end
      enrollStatus:
        kydPolicy.enrollStatusFlag.enrolled || kydPolicy.enrollStatusFlag.waitingPeriod ? 'Y' : 'N',
      enrollmentSummary: enrollmentSummary
    });

    kydPolicy.policyRoles.forEach(operator => {
      operator = Object.assign({}, operator, { alreadyEnrolled: false });
    });

    const enrolledOperators = _get(kydPolicy, 'enrollmentSummary.enrolledOperators', []);
    if (enrolledOperators.length) {
      return this.createPolicyRoleFromSummary(kydPolicy).pipe(
        map(operators => {
          kydPolicy = Object.assign({}, kydPolicy, { policyRoles: operators || [] });
          this.updateOperatorAlreadyEnrollStatus();
          operators.forEach(operator =>
            this.updatePhoneForMatchingOperator(operator, operator.selectedPhoneNumber)
          );
          return kydPolicy;
        })
      );
    }

    return observableOf(kydPolicy);
  }

  private createPolicyRoleFromSummary(
    kydPolicy: KydSmartphonePolicy
  ): Observable<KydSmartphoneRole[]> {
    return from(kydPolicy.enrollmentSummary.enrolledOperators).pipe(
      mergeMap(
        enrolledOperator => this.partyService.getOtherPartyDetails(enrolledOperator.cdhId, false),
        (enrolledOperator, party) => ({ enrolledOperator, party })
      ),
      filter(data => !!data.party.firstName),
      map(data => {
        const kydRole: KydSmartphoneRole = this.createPolicyRole(data.party);

        kydRole.isNamedInsured =
          kydPolicy.enrollmentSummary.customerId === data.enrolledOperator.cdhId;
        kydRole.operatorId = data.enrolledOperator.operatorEnrollmentSummary.operatorId;
        kydRole.enrollStatus = 'Y';
        kydRole.originalPhoneNumber = _get(data.enrolledOperator, 'contactDetail.phone', '');
        kydRole.selectedPhoneNumber = kydRole.selectedPhoneNumber || kydRole.originalPhoneNumber;
        // add contact phone to list if not there
        if (
          kydRole.selectedPhoneNumber &&
          kydRole.phones.indexOf(kydRole.selectedPhoneNumber) < 0
        ) {
          kydRole.phones.push(kydRole.selectedPhoneNumber);
        }

        return kydRole;
      }),
      toArray()
    );
  }

  public updatePhoneForMatchingOperator(operator: KydSmartphoneRole, phone: string) {
    this.policyList.forEach(policy => {
      policy.policyRoles.forEach(_operator => {
        if (operator.fullName === _operator.fullName) {
          _operator = Object.assign({}, _operator, {
            originalPhoneNumber: phone,
            selectedPhoneNumber: phone
          });
          if (_operator.phones.indexOf(phone) < 0) {
            _operator = Object.assign({}, _operator, { phones: [..._operator.phones, phone] });
          }
          policy = Object.assign({}, policy, { _edit: false });
        }
      });
    });
  }

  private updateOperatorAlreadyEnrollStatus() {
    const enrolledPolices = this.policyList.filter(policy => policy.enrollStatusFlag.enrolled);
    const unEnrolledPolices = this.policyList.filter(policy => !policy.enrollStatusFlag.enrolled);

    unEnrolledPolices.forEach(policy => {
      policy = Object.assign({}, policy, { _allOperatorsEnrolled: false });
      policy.policyRoles.forEach(operator => {
        if (enrolledPolices.length) {
          enrolledPolices.forEach(enrolledPolicy => {
            operator = Object.assign({}, operator, {
              alreadyEnrolled: enrolledPolicy.policyRoles.some(
                enrolledOperator => enrolledOperator.fullName === operator.fullName
              )
            });
          });
        } else {
          operator = Object.assign({}, operator, { alreadyEnrolled: false });
        }
      });
    });
  }

  public getEnrollmentSummary(enrollmentPolicyNumber: string): Observable<KydSmartphoneSummary> {
    const url = `${this.endpoint}enrollments`;
    const params: HttpParams = new HttpParams().set('policyNumber', enrollmentPolicyNumber);
    const options = { params: params };
    return this.http.get(url, options).pipe(
      map((data: any) => {
        /*
         * MR: If the kyd policy is not enrolled, set the data to null so that
         * we don't use the enrollment data which is considered stale from the UBI API
         */
        /**
         *  Srikanth:  The above conditon is stopping us not to check enrollmentStatus for other than Enrolled
         */
        // if (
        //   _get(data, 'namedInsured.enrollmentSummary.enrollmentStatus') !== 'Enrolled' &&
        //   data.ubiProgramCategoryCode === 'UBI_DEVICE'
        // ) {
        //   data = null;
        // }
        let summary: KydSmartphoneSummary = new KydSmartphoneSummary();
        summary = Object.assign(
          {},
          {
            enrolledOperators: _get(data, 'namedInsured.enrolledOperators', []).filter(
              operator =>
                _get(operator, 'operatorEnrollmentSummary.operatorEnrollmentStatus') !== 'Removed'
            ),
            enrollmentAckDate: _get(data, 'namedInsured.enrollmentSummary.enrollmentAckDate', ''),
            emailId: _get(data, 'namedInsured.contactDetail.email', ''),
            enrollmentId: _get(data, 'namedInsured.enrollmentSummary.enrollmentId', 0),
            enrollmentStatus: _get(data, 'namedInsured.enrollmentSummary.enrollmentStatus', ''),
            customerId: _get(data, 'namedInsured.cdhId', ''),
            policyNumber: enrollmentPolicyNumber,
            ubiProgramCategoryCode: _get(data, 'ubiProgramCategoryCode', ''),
            optOutDetail: _get(data, 'namedInsured.enrollmentSummary.optOutDetail', {}),
            waitingPeriodEndDate: _get(
              data,
              'namedInsured.enrollmentSummary.waitingPeriodEndDate',
              ''
            ),
            discountAppliedDate: _get(
              data,
              'namedInsured.enrollmentSummary.discountAppliedDate',
              ''
            )
          }
        );

        return summary;
      })
    );
  }

  public enrollPolicy(policy: KydSmartphonePolicy, operatorCustomerIds?: any[]): Observable<any> {
    const url = this.endpoint + 'enrollments';

    const errHandler = error => {
      error._policy = policy;
      return observableThrowError(error);
    };

    return this.store.select(userQuery.getPartyDetails).pipe(
      take(1),
      mergeMap(party => {
        const payload: any = {
          EnrollmentRequest: this.prepareSubmitRequest(policy, party, operatorCustomerIds)
        };
        return this.http.post(url, payload).pipe(
          map((data: any) => {
            data._policy = policy;
            if (operatorCustomerIds === undefined) {
              this.store.dispatch(
                new KydSmartphoneSummaryAction.RefreshAction([policy.policyNumber])
              );
            }

            return data;
          }),
          catchError(errHandler)
        );
      }),
      catchError(errHandler)
    );
  }

  private getContractState(kydPolicy: KydSmartphonePolicy): string {
    const vehcicles: Risk[] = _get(kydPolicy, 'policyRisks', []);
    let state = '';
    if (vehcicles.length) {
      vehcicles.forEach(vehicle => {
        state = state || _get(vehicle, 'garageLocationAddress.state', '');
      });
    }
    return state;
  }

  private prepareSubmitRequest(
    kydPolicy: KydSmartphonePolicy,
    currentParty: Party,
    operatorCustomerIds?: any[]
  ): KydSmartphoneEnrollmentRequest {
    const enrollmentRequest: KydSmartphoneEnrollmentRequest = new KydSmartphoneEnrollmentRequest();

    const address: any = _get(currentParty, 'addresses[0]', {});

    enrollmentRequest.customerIdentifier = this.currentUser.customerId;
    enrollmentRequest.policyNumber = kydPolicy.policyNumber;
    enrollmentRequest.termsAcceptedIndicator = 'Y';
    enrollmentRequest.acknowledgementDate = format(new Date(), 'YYYY-MM-DD');
    enrollmentRequest.requestDate = format(new Date(), 'YYYY-MM-DD');
    enrollmentRequest.emailAddress = currentParty.primaryEmail.emailAddress;
    enrollmentRequest.phoneNumber = currentParty.primaryPhone.tenDigitPhoneNumber;
    enrollmentRequest.policyEffectiveDate = format(kydPolicy.effectiveDate, 'YYYY-MM-DD');
    enrollmentRequest.policyExpireDate = format(kydPolicy.endEffectiveDate, 'YYYY-MM-DD');
    enrollmentRequest.policyState = this.getContractState(kydPolicy);

    enrollmentRequest.shippingAddress = {
      addressLine1: address.line1 || '',
      addressLine2: address.line2 || '',
      city: address.city || '',
      state: enrollmentRequest.policyState,
      zipCode: address.zip5 || ''
    };

    enrollmentRequest.operators = new Array<KydSmartphoneEnrollmentOperator>();

    for (const operator of kydPolicy.policyRoles) {
      const enrollOperator: KydSmartphoneEnrollmentOperator = {} as KydSmartphoneEnrollmentOperator;
      enrollOperator.customerIdentifier = operator.customerId;
      enrollOperator.contactDetail = {} as KydSmartphoneEnrollmentContactDetail;

      if (operatorCustomerIds === undefined) {
        enrollOperator.contactDetail.phoneNumber = operator.selectedPhoneNumber;
      } else {
        const op = operatorCustomerIds.find(elem => elem.id === operator.customerId);
        if (op) {
          enrollOperator.contactDetail.phoneNumber = op.phone;
        }
      }
      enrollmentRequest.operators.push(enrollOperator);
    }

    return enrollmentRequest;
  }

  public optOut(policy: KydSmartphonePolicy, enrollmentId): Observable<any> {
    const url = `${this.endpoint}enrollments/${enrollmentId}?OptOutReasonCode=2002&optOutType=NamedInsuredVoluntarilyOptedOut`;

    return this.http.delete(url).pipe(
      map((result: any) => {
        if (+result.code === 200) {
          this.store.dispatch(new KydSmartphoneSummaryAction.RefreshAction([policy.policyNumber]));
        }
        return observableThrowError(false);
      }),
      catchError(error => {
        return observableThrowError(false);
      })
    );
  }

  public updatePhone(operatorId: string, customerId: string, phone: string): Observable<any> {
    const url = `${this.endpoint}operator/${operatorId}`;

    const payload = {
      TMRequest: {
        phoneNumber: phone,
        cdhId: customerId
      }
    };

    return this.http.put(url, payload).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  public enrollOperator(
    enrollOperatorPolicyNumber: string,
    cdhId: string,
    phone: string
  ): Observable<any> {
    const url = `${this.endpoint}enroll/operator/${enrollOperatorPolicyNumber}`;

    const payload = {
      TMRequest: {
        phoneNumber: phone,
        cdhId: cdhId,
        originSystem: 'MYACCOUNT'
      }
    };

    return this.http.post(url, payload).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  public unEnrollOperator(
    operatorId: string,
    unEnrollPolicyNumber: string,
    phone: string
  ): Observable<any> {
    const url = `${this.endpoint}unenroll/operator/${unEnrollPolicyNumber}`;

    const payload = {
      TMRequest: {
        phoneNumber: phone,
        cdhId: operatorId,
        originSystem: 'MYACCOUNT',
        reason: 'text'
      }
    };

    return this.http.post(url, payload).pipe(
      catchError(error => {
        throw error;
      })
    );
  }

  public addPhoneToCDH(phoneNumber) {
    const regEx = /([0-9]{3})([0-9]{7})/;
    const regExMatch = phoneNumber.match(regEx);

    const payLoad: any = PartyPhone.fromJson({
      areaCode: regExMatch[1],
      phoneNumber: regExMatch[2],
      contactMethodUsages: [{ typeOfUsageCode: 'MOBILE' }]
    });

    this.store.dispatch(
      new ProfileActions.AddContactEntryAction({
        data: payLoad,
        methodType: 'phones'
      })
    );
  }

  public validatePhoneNotInUse(phone: string) {
    const url = `${this.endpoint}enrollments/searchbyphone/${phone}`;

    return this.http.get(url).pipe(
      catchError(error => {
        throw error;
      })
    );
  }
}

<form [formGroup]="form" (ngSubmit)="submitNumber()">
  <div class="row">
    <div *ngIf="!usePhoneInput" class="column twelve md-eight">
      <ds-form-control-container
        [labelText]="'Select Phone Number'"
        [control]="form.controls['selectPhone']"
      >
        <select class="icon fill" data-cy="selectConfirmationPhone" formControlName="selectPhone">
          <option value=""></option>
          <option
            data-cy="selectConfirmationPhoneOptions"
            *ngFor="let phone of maskedPhoneNumbers"
            [value]="phone"
            >{{ phone | phoneNumberFormatter }}
          </option>
        </select>
        <span class="icon-chevron-down"></span>
        <ds-form-control-message
          data-cy="selectConfirmationPhoneErrorText"
          [control]="form.controls['selectPhone']"
        ></ds-form-control-message>
      </ds-form-control-container>
    </div>
    <div *ngIf="usePhoneInput" class="column twelve md-six">
      <ds-form-control-container
        [labelText]="'Phone Number'"
        [control]="form.controls['inputPhone']"
      >
        <input
          [identifier]="'inputPhone'"
          id="inputPhone"
          [preValue]="form.value.inputPhone"
          [phoneControl]="form.controls['inputPhone']"
          formControlName="inputPhone"
          maxlength="12"
          phoneMask
        />
        <ds-form-control-message [control]="form.controls['inputPhone']"></ds-form-control-message>
      </ds-form-control-container>
    </div>
    <div class="column twelve md-four">
      <div class="md-pad-left-10 pad-top-10 pad-bottom-10">
        <button
          data-cy="verifyPhoneContinueButton"
          ds-button
          class="pad-right-20 pad-left-20"
          type="submit"
          [disabled]="!form.valid"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</form>

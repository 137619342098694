import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { FutureStateService } from './future-state.service';
import { FutureState } from './future-state';

@Directive({
  selector: '[decorateFutureState]'
})
export class DecorateFutureStateDirective implements OnInit, OnDestroy {
  private el: HTMLElement;
  private futureState: FutureState = null;

  private sub: any;

  constructor(private elRef: ElementRef, private futureStateSvc: FutureStateService) {
    this.el = elRef.nativeElement;
    this.sub = this.futureStateSvc.futureState$.subscribe(
      futureState => {
        this.futureState = futureState;
        this.update();
      },
      error => console.error(error)
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private update() {
    if (this.futureState.decorateFutureState) {
      this.el.classList.add('future');
    } else {
      this.el.classList.remove('future');
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  constructor() {}

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any, controlValue?: any) {
    const config = {
      required: 'Required',
      requiredWithTrim: 'Your answer cannot be only spaces',
      noErrorMessage: ' ',
      invalidEmail: 'Invalid email address',
      invalidPhoneNumber: 'Phone number must have 10 digits',
      invalidAreaCode: 'Area Code is invalid',
      invalidExtension: 'Extension is invalid',
      invalidPolicyNumber: 'Policy number is invalid',
      invalidPolicyNumberMinLength: 'Minimum 10 characters required.',
      invalidPolicyNumberMaxLength: 'Minimum 12 characters required.',
      invalidAccountNumber: 'Account number is invalid',
      invalidSSN: 'Invalid SSN',
      invalidUserId: 'Invalid User ID',
      invalidUsername: 'User ID is invalid',
      invalidPassword: 'Invalid password',
      isDupEmail: 'This email address already exists on file.',
      divisibleByTen: 'The number should be divisible by 10',
      answersMatch: `Your answers must be different for each question`,
      answerInQuestion: `Your answer must not be part of the question`,
      miscAnswerError: `Please try a different answer`,
      minlength: `Minimum length ${validatorValue?.requiredLength}`,
      maxlength: `Maximum length ${validatorValue?.requiredLength}`,
      invalidDateFormat: 'Please enter the date as mm/dd/yyyy',
      invalidDateRange: 'Please enter a valid date',
      invalidDate: 'Please enter a valid date',
      invalidTimeFormat: 'Please enter the time as hh:mm',
      atLeastOneLetterErrorMsg: 'Must have at least 1 letter',
      atLeastOneLetterOrNumErrorMsg: 'Must have at least 1 letter or number',
      specialCharError: "Can have only letters and the characters ' and -",
      specialCharValidError: 'Can have only letters and the characters . , /, #, & and -',
      invalidFirstNameCharacters: "Can have only letters and the characters ' and -",
      invalidAndOr: controlValue + ' is not a valid entry.',
      invalidFirstNameEstate: 'Trusts and Estates must be added as organizations.',
      lastnameAdditionalValidation: controlValue + ' ' + 'is not a valid entry',
      invalidEmailSpecialCharacters:
        'Has at least 1 special character that is not allowed in this field',
      invalidNickName: "Punctuation and special symbols aren't allowed.",
      noMoreThanFourNumbers: 'No more than 4 numbers are allowed.',
      selectOneOrMore: 'Must select at least 1 option',
      invalidZipcode: 'Invalid zipcode',
      invalidExpirationYear: 'Invalid expiration year',
      zipCodeNotFive: 'Minimum Length 5',
      onlyNumbers: 'Can have only numbers',
      duplicateNickName:
        "You've already used this payment method name. Please try a different one.",
      invalidCharacter: '? is not a valid character for an answer',
      otherTypeDescription: 'Other Type description should be four characters or longer',
      invalidPaymentAmnt:
        'You’ve entered an invalid payment amount. Please enter a different amount and try again.',
      noMoreThanPersonalLimit:
        'Your payment amount cannot be greater than $30,000 for this account.',
      noMoreThanCommercialLimit:
        'Your payment amount cannot be greater than $85,000 for this account.',
      noMoreThanTenOverBalance: 'Your payment cannot exceed your account balance by more than $10.',
      inValidBankAccountNumber:
        "You've entered an invalid bank account number. Please verify the number and try again.",
      ccMinLength: 'Please enter a minimum credit card length of 14 characters.',
      ccInvalid:
        "You've entered an invalid debit or credit card number. Please verify the number and try again",
      expDateInvalid:
        "You've entered an invalid debit or credit card expiration date. Please verify the date and try again.",
      invalidRoutingNumber:
        "You've entered an invalid routing number. Please verify the number and try again.",
      noSpaceNickName: 'Account name can’t include only spaces.',
      invalidTime: "You've entered future time.",
      emptyOrLettersOnly: 'Please use letters or leave blank',
      phoneNumberInUse: 'This phone number is already in use.',
      kydSmartphonePhoneNumberInUse:
        'This phone number is assigned to another driver. Please choose a different number.',
      invalidFutureTime: `The entered time hasn't occurred yet`,
      invalidFutureDay: `The entered day hasn't occurred yet`,
      invalidCity: `Invalid city.`,
      invalidUuid: 'Please enter a valid UUID in the format: XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX',
      nonAsciiCharacters: `Only letters, numbers, spaces, and punctuation are allowed.`,
      invalidVIN: `Invalid VIN`,
      specialCharVIN: `VIN cannot contain special characters`,
      shortVIN: `VIN must have at least 17 characters`,
      longVIN: `VIN cannot exceed 17 characters`,
      missingVIN: `VIN is required`,
      invalidPaymentMethod: `Please select a valid payment method`,
      showSpecialCharWarningMsg: `You may use numbers, letters, spaces or any of the following characters: . , ! ? '`,
      invalidCharacters: `You’ve entered text which has at least 1 special character that is not allowed in this field`,
      minShopNameLength: `Shop Name minimum length is 1`,
      maxShopNameLength: `Shop Name maximum length is 50`,
      minShopCityLength: `Shop City minimum length is 1`,
      maxShopCityLength: `Shop City maximum length is 50`,
      nonExistentZipcode: 'Zipcode does not exist',
      zipcodeNotRecognized: 'Zipcode is not recognized',
      invalidOdometer: 'Please enter a number between 1 and 99,999',
      missingOdometer: 'Odometer mileage is required',
      missingAnnualMileage: 'Annual mileage is required',
      payingLessThanMinDuePastDue:
        'The past due amount is the minimum due. Please pay at least this amount to continue.',
      payingLessThanMinDuePastDueCancellationLetterSent:
        'The past due amount + current due amount is the minimum due. Please pay at least this amount to continue.',
      paymentGreaterThanZero: 'Please enter an amount to continue'
    };
    return config[validatorName];
  }
}

import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PoliciesPartialState } from './policies.reducer';
import { policiesQuery } from './policies.selectors';
import { LoadPolicies } from './policies.actions';
import { PolicySummary } from '@amfam/policy/models';

@Injectable()
export class PoliciesFacade {
  loaded$ = this.store.pipe(select(policiesQuery.getLoaded));
  allPolicies$ = this.store.pipe(select(policiesQuery.getAllPolicies));
  selectedPolicies$ = this.store.pipe(select(policiesQuery.getSelectedPolicies));

  constructor(private store: Store<PoliciesPartialState>) {}

  loadAll(policySummaryList: PolicySummary[]) {
    this.store.dispatch(new LoadPolicies(policySummaryList));
  }
}

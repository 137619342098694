import { Action } from '@ngrx/store';
import {
  StepModel,
  StepCompleteModel,
  WorkflowModel,
  LoadWorkflowModel,
  ActiveStepModel
} from './workflow.model';

/**
 * ACTIONS:
 * complete(nextStep?): complete the step and navigate to nextStep
 * back : navigate to backStep
 * reset: reset workflow to the initial state
 */
export const WORKFLOW_LOAD = '[WORKFLOW] Load';
export const WORKFLOW_STEP_COMPLETE = '[WORKFLOW STEP] Complete';
export const WORKFLOW_STEP_NEXT = '[WORKFLOW STEP] Next';
export const WORKFLOW_STEP_BACK = '[WORKFLOW STEP] Back';
export const WORKFLOW_STEP_SET_ACTIVE = '[WORKFLOW STEP] Set Active';
export const WORKFLOW_RESET = '[WORKFLOW] Reset';
export const WORKFLOW_DELETE = '[WORKFLOW] Delete';
export const WORKFLOW_SKIP = '[WORKFLOW] Skip';

export class WorkflowLoadAction implements Action {
  type = WORKFLOW_LOAD;
  constructor(public payload: LoadWorkflowModel) {}
}

export class WorkflowStepCompleteAction implements Action {
  type = WORKFLOW_STEP_COMPLETE;
  constructor(public payload?: StepCompleteModel) {}
}

export class WorkflowStepNextAction implements Action {
  type = WORKFLOW_STEP_NEXT;

  constructor(public payload?: any) {}
}
export class WorkflowStepBackAction implements Action {
  type = WORKFLOW_STEP_BACK;

  constructor(public payload?: any) {}
}

export class WorkflowStepSetActiveAction implements Action {
  type = WORKFLOW_STEP_SET_ACTIVE;

  constructor(public payload?: ActiveStepModel) {}
}

export class WorkflowResetAction implements Action {
  type = WORKFLOW_RESET;

  constructor(public payload?: any) {}
}

export class WorkflowDeleteAction implements Action {
  type = WORKFLOW_DELETE;

  constructor(public payload?: any) {}
}

export class WorkflowSkipAction implements Action {
  type = WORKFLOW_SKIP;

  constructor(public payload?: any) {}
}

export type Actions =
  | WorkflowLoadAction
  | WorkflowStepCompleteAction
  | WorkflowStepNextAction
  | WorkflowStepBackAction
  | WorkflowResetAction
  | WorkflowSkipAction;

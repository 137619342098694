import { ButtonProps } from '@amfam/shared/models';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-simple-card',
  templateUrl: './simple-card.component.html',
  styleUrls: ['./simple-card.component.scss']
})
export class SimpleCardComponent {
  @Input() headerIcon: string;
  @Input() heading: string;
  @Input() buttons: ButtonProps[];
  @Input() id: string;
}

export interface KydSmartphoneCopy {
  termsTitle: string;
  enrollTitleSuccess: string;
  enrollTitleError: string;
  enrollTitleWarning: string;
  nextStepsSuccess: string;
  nextStepsWarning: string;
  nextStepsError: string;
  disclaimer: string;
}

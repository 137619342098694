import { ResetPasswordModel, initialState } from '@amfam/shared/models';
import * as resetPassword from './reset-password.actions';
import { get as _get } from 'lodash';

export function reducer(state = initialState, action: resetPassword.Actions): ResetPasswordModel {
  switch (action.type) {
    case resetPassword.LOAD_USER_DATA:
      return Object.assign({}, initialState, {
        loading: true,
        hasError: false,
        userIdentifier: action.payload
      });

    case resetPassword.LOAD_USER_DATA_SUCCESS:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        userDetails: action.payload.apiData,
        userVerified: false
      });

    case resetPassword.LOAD_USER_DATA_ERROR:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        userDetails: action.payload,
        userVerified: false
      });

    case resetPassword.LOAD_SECURITY_QUESTIONS:
      return Object.assign({}, state, {
        loading: true,
        hasError: false
      });

    case resetPassword.LOAD_SECURITY_QUESTIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        hasError: false,
        securityQuestions: action.payload
      });

    case resetPassword.LOAD_SECURITY_QUESTIONS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        hasError: true
      });

    case resetPassword.USER_VERIFIED_PHONE:
      return Object.assign({}, state, {
        userVerified: true,
        pinCode: action.payload
      });

    case resetPassword.USER_VERIFIED_EMAIL:
      return Object.assign({}, state, {
        userVerified: true,
        correlationId: action.payload
      });

    case resetPassword.USER_VERIFIED_SECURITY_QUESTIONS:
      return Object.assign({}, state, {
        userVerified: true,
        answeredSecurityQuestions: action.payload
      });

    case resetPassword.VERIFY_METHOD_LOCKED:
      return Object.assign({}, state, {
        verifyMethodLocked: true
      });

    case resetPassword.RESET_PASSWORD:
      return Object.assign({}, state, {
        loading: true,
        hasError: false
      });

    case resetPassword.RESET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload
      });

    case resetPassword.RESET_PASSWORD_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        hasError: false
      });

    case resetPassword.RESET_PASSWORD_LOGIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload
      });

    case resetPassword.USER_LOGIN_LOCKED:
      return Object.assign({}, state, {
        lockedUserId: action.payload
      });

    case resetPassword.SHELL_ACCOUNT_FOUND:
      return Object.assign({}, state, {
        shellAccountStatus: true
      });

    default:
      return state;
  }
}

export const getLoading = (state: ResetPasswordModel) => state.loading;

export const hasError = (state: ResetPasswordModel) => state.hasError;

export const getStatus = (state: ResetPasswordModel) => state.userDetails.status;

export const getUserIdentifier = (state: ResetPasswordModel) => state.userIdentifier;

export const getLockedUserId = (state: ResetPasswordModel) => state.lockedUserId;

export const isUserVerified = (state: ResetPasswordModel) => state.userVerified;

export const isVerifyMethodLocked = (state: ResetPasswordModel) => state.verifyMethodLocked;

export const getAnsweredSecurityQuestions = (state: ResetPasswordModel) =>
  state.answeredSecurityQuestions;

export const getPinCode = (state: ResetPasswordModel) => state.pinCode;

export const getCorrelationId = (state: ResetPasswordModel) => state.correlationId;

export const getMaskedEmail = (state: ResetPasswordModel) => state.userDetails.maskedEmailAddress;

export const getMaskedPhoneNumbers = (state: ResetPasswordModel) =>
  state.userDetails.maskedPhoneNumbers;

export const getSecurityQuestions = (state: ResetPasswordModel) => state.securityQuestions;

export const getUserDetails = (state: ResetPasswordModel) => state.userDetails;

export const getShellAccountStatus = (state: ResetPasswordModel) => state.shellAccountStatus;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsRouterLinkModule } from '@amfam/shared/ui/ds-router-link';

import { FooterComponent } from './containers/footer/footer.component';
import { FooterPartnerComponent } from './components/footer-partner/footer-partner.component';
import { FooterAfiComponent } from './components/footer-afi/footer-afi.component';
import { LastLoginComponent } from './components/last-login/last-login.component';
import { BrandDirectivesModule } from '@amfam/shared/utility/brand';

@NgModule({
  imports: [CommonModule, DsRouterLinkModule, BrandDirectivesModule],
  declarations: [FooterComponent, FooterPartnerComponent, FooterAfiComponent, LastLoginComponent],
  exports: [FooterComponent]
})
export class FooterModule {}

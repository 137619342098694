import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  HostListener,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: '[ds-button]',
  templateUrl: './ds-button.component.html',
  styleUrls: ['./ds-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DsButtonComponent {
  /** Whether the button has focus from the keyboard (not the mouse). Used for class binding. */
  isKeyboardFocused: boolean = false;

  /** Whether a mousedown has occurred on this element in the last 100ms. */
  isMouseDown: boolean = false;

  constructor(private elementRef: ElementRef) {}

  /** @internal */
  @HostListener('mousedown')
  setMousedown() {
    // We only *show* the focus style when focus has come to the button via the keyboard.
    // The Material Design spec is silent on this topic, and without doing this, the
    // button continues to look :active after clicking.
    // @see http://marcysutton.com/button-focus-hell/
    this.isMouseDown = true;
    window.setTimeout(() => {
      this.isMouseDown = false;
    }, 100);
  }

  /** @internal */
  @HostListener('focus')
  setKeyboardFocus() {
    this.isKeyboardFocused = !this.isMouseDown;
  }

  /** @internal */
  @HostListener('blur')
  removeKeyboardFocus() {
    this.isKeyboardFocused = false;
  }

  /** TODO(hansl): e2e test this function. */
  focus() {
    this.elementRef.nativeElement.focus();
  }
}

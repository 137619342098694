import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { chunk as _chunk } from 'lodash';
import { ErrorMessageService } from '../../services/error-message.service';

@Component({
  selector: 'ds-form-control-message',
  templateUrl: './ds-form-control-message.component.html',
  styleUrls: ['./ds-form-control-message.component.scss']
})
export class DsFormControlMessageComponent {
  @Input() control: UntypedFormControl;
  @Input() showAll = false;
  @Input() validateOnSubmit = false;

  private columnDivider: number;
  groupMessages = false; // group error messages into 2 columns?
  groupedAllErrorMessages: string[][]; // to hold new array of arrays if grouped

  constructor() { }

  get errorMessages(): string[] {
    const messages: string[] = [];
    if ((this.control && !this.control.pristine && this.control.touched) || this.validateOnSubmit) {
      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName)) {
          messages.push(
            ErrorMessageService.getValidatorErrorMessage(
              propertyName,
              this.control.errors[propertyName],
              this.control.value
            )
          );
        }
      }
    }
    if (messages.length > 2) {
      // if there are more than two errors split them into 2 columns
      this.groupMessages = true;

      // TODO - Break into a utility function - used in the policy list view as well
      this.columnDivider = messages.length / 2; // divide total errors 2
      this.columnDivider = Math.round(messages.length / 2) / 1; // no remainders
      this.groupedAllErrorMessages = _chunk(messages, this.columnDivider); // _chunk into an array of arrays
    }
    return messages;
  }
}

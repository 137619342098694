import { Injectable } from '@angular/core';

import { ToastrService, ActiveToast } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(private toastr: ToastrService) {}

  pop(level: string, body: string, title = ''): ActiveToast<any> {
    return this.toastr[level](body, title);
  }
}

import { takeUntil, filter, take } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { get as _get } from 'lodash';

import { DsModalService } from '@amfam/ui-kit';
import { LoadingSpinnerService } from '@amfam/ui-kit';
import { KydService } from '../../policy/know-your-drive/shared/kyd.service';

import { UtilService } from '@amfam/shared/utility/shared-services';
import { AnalyticsService, EventAnalytic } from '@amfam/shared/utility/shared-services';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import {
  EnrollNotificationModel,
  CustomerEnrollment,
  EnrollmentRequest
} from '../../core/store/mae';
import { Party } from '@amfam/shared/models';
import { EnrollAnalytics } from './shared/enroll-constants';

import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import { EsignatureActions, EsignatureSelectors } from '@amfam/policy/e-signature/data-access';
import * as maeEnrollmentActions from '../../core/store/mae/mae-enrollment.actions';
import { userQuery, UserState } from '@amfam/shared/user';
import { GoPaperlessService } from '@amfam/policy/go-paperless/data-access';
import { SignatureRequest } from '@amfam/policy/models';

@Component({
  selector: 'enroll-notification',
  templateUrl: './enroll-notification.component.html',
  styleUrls: ['./enroll-notification.component.scss']
})
export class EnrollNotificationComponent implements OnInit, OnDestroy {
  hasPrograms: boolean;
  enrolled: boolean;
  readonly modalId = 'mae-programs-modal';
  model: EnrollNotificationModel = new EnrollNotificationModel();
  propertyServiceUrl: string;

  private docuSignVisited: boolean;
  private isSignatureRequired: boolean;
  private party: Party;
  private user: UserState;
  private agreeToTerms: boolean;
  private stop$ = new Subject<void>();

  constructor(
    private modalService: DsModalService,
    private store: Store<fromRoot.RootState>,
    private route: ActivatedRoute,
    private spinner: LoadingSpinnerService,
    private utilService: UtilService,
    private goPaperlessService: GoPaperlessService,
    private kydService: KydService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    combineLatest(
      this.route.queryParams,
      this.store.select(fromRoot.getPrograms),
      this.store.select(BrandSelectors.getIsPartner),
      this.store.select(EsignatureSelectors.getESignatureRequired),
      this.store.select(BrandSelectors.getPropertyServiceUrl),
      this.store.select(userQuery.getPartyDetails),
      this.store.select(userQuery.getUserState),
      (params, programs, isPartner, isSignatureRequired, propertyServiceUrl, party, user) => {
        return {
          params: params,
          model: new EnrollNotificationModel(programs),
          isPartner: isPartner,
          isSignatureRequired: isSignatureRequired,
          propertyServiceUrl: propertyServiceUrl,
          party: party,
          user: user
        };
      }
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(result => {
        this.docuSignVisited = result.params['event'] === 'OnSigningComplete';
        this.model = result.model;
        const requiresSignature = result.isSignatureRequired;
        const isPartner = result.isPartner;
        this.propertyServiceUrl = result.propertyServiceUrl;
        this.party = result.party;
        this.user = result.user;

        const enrollment = this.getEnrollment();
        this.isSignatureRequired =
          requiresSignature && (enrollment.eDeliveryOptInIndicator || enrollment.ubiOptInIndicator);

        if (isPartner) {
          for (let program of this.model.programs) {
            if (program.title === 'Go Paperless') {
              program = Object.assign({}, program, { title: 'Vehicle Paperless Policy Documents' });
            } else if (program.title === 'Online Billing') {
              program = Object.assign({}, program, { title: 'Vehicle Electronic Billing' });
            } else {
              program = Object.assign({}, program, { title: 'Vehicle ' + program.title });
            }
          }
        }
      });

    /**@description- combine latest to enroll programs after done with docusign and programs loaded.
     * This could be part of above combine latest, but, selector/s(getPartyDetails, getUserState) from user.reducer
     * is not giving loading status.
     * TODO: Figure out how to know loading status of selectors from user.reducer and move this to top level combined subscription.
     */
    combineLatest(
      this.route.queryParams,
      this.store.select(fromRoot.getProgramsLoaded),
      (params, prograsmLoaded) => {
        return {
          params: params,
          programsLoaded: prograsmLoaded
        };
      }
    )
      .pipe(
        takeUntil(this.stop$),
        filter(result => result.programsLoaded)
      )
      .subscribe(result => {
        if (result.params['event'] === 'OnSigningComplete') {
          this.agreeToTerms = true;
          this.enrollOrDecline();
        }
      });
  }

  /**
   * @description - Dispatch enroll or decline action.
   */
  private enrollOrDecline() {
    const enrollment = this.getEnrollment();
    const request: EnrollmentRequest = {
      customerId: this.user.customerId,
      email: this.user.emailAddress,
      enrollment: enrollment,
      party: this.party
    };
    if (this.agreeToTerms) {
      this.store.dispatch(new maeEnrollmentActions.EnrollAction(request));
    } else {
      this.store.dispatch(new maeEnrollmentActions.UnenrollAction(request));
    }

    /**
     * @description- MAE enrollment store subscription
     */
    this.store
      .select(fromRoot.getEnrollmentLoaded)
      .pipe(
        takeUntil(this.stop$),
        filter(loaded => loaded)
      )
      .subscribe(() => {
        const eventAnalytic: EventAnalytic = {
          eventName: this.agreeToTerms ? 'Accept' : 'Decline',
          eventStep: ''
        };
        this.analyticsService.trackPageAndEvent(
          EnrollAnalytics.enrollTermsPageAnalytic,
          eventAnalytic
        );
        this.enrolled = this.agreeToTerms;
        this.modalService.open(this.modalId);

        if (!this.enrolled) {
          this.close();
        }

        this.modalService.scrollTop(this.modalId);

        this.spinner.stop();
      });
  }

  /**
   *@description - Build payload
   */
  private getEnrollment(): CustomerEnrollment {
    if (this.model && this.model.programs && this.model.programs.length) {
      return this.model.programs[0].enrollment;
    }

    return {
      autoPayOptInIndicator: false,
      eDeliveryOptInIndicator: false,
      onineBillingOptInIndicator: false,
      ubiOptInIndicator: false,
      ubiDeviceOptInIndicator: false,
      managedPreferenceChangeIndicator: false
    };
  }

  /**
   * @author: Abhishek SIngh
   * @returns:  void
   * @description: Actions are dispathced in mae-enrollment.effects to know status of enrolled/unenrolled programs,
   * instead of reloading whole app.
   */

  close(): void {
    this.modalService.close(this.modalId);
  }

  onSubmitTerms(agreeToTerms: boolean): void {
    this.spinner.start();
    this.agreeToTerms = agreeToTerms;
    if (this.agreeToTerms) {
      if (this.isSignatureRequired) {
        const signatureRequest = this.createSignatureRequest();
        this.store.dispatch(new EsignatureActions.SignESignature(signatureRequest));
      } else {
        this.enrollOrDecline();
      }
    } else {
      this.enrollOrDecline();
    }
  }

  /**@description - E-signature and Kyd signature payload.
   *
   */
  private createSignatureRequest(): SignatureRequest {
    const enrollment = this.getEnrollment();
    this.store.pipe(select(BrandSelectors.getExperienceId), take(1)).subscribe(experienceId => {
      const callbackUrl = this.utilService.getAbsoluteUrl(location.pathname, experienceId);
      const goPaperlessSignatureRequest =
        enrollment.eDeliveryOptInIndicator &&
        this.goPaperlessService.createSignatureRequest(callbackUrl);
      const kydSignatureRequest =
        enrollment.ubiOptInIndicator && this.kydService.createSignatureRequest(callbackUrl);

      if (goPaperlessSignatureRequest && kydSignatureRequest) {
        return goPaperlessSignatureRequest.merge(kydSignatureRequest);
      }
      if (goPaperlessSignatureRequest) {
        return goPaperlessSignatureRequest;
      }
      if (kydSignatureRequest) {
        return kydSignatureRequest;
      }
    });

    throw new Error('Unable to create signature request for enrollment');
  }

  modalInitialized(initialized) {
    if (initialized) {
      this.store
        .select(fromRoot.getPrograms)
        .pipe(takeUntil(this.stop$))
        .subscribe(programs => {
          if (!!programs.length && !this.docuSignVisited) {
            // T&C modal is not required once docusigned.
            this.hasPrograms = true;
            this.modalService.open(this.modalId);
          }
        });
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

<alert-item
  *ngFor="let alert of (consolidatedAlerts | async)"
  [fromOverview]="fromOverview"
  [alert]="alert"
  [actionFromErrorModal]="buttonClickedFromErrorModal"
></alert-item>
<ds-billing-registration-failure-modal
  [visible]="hasRegistrationError$ | async"
  (tryAgainClicked)="buttonClickedFromModal('tryAgainClicked')"
></ds-billing-registration-failure-modal>

<div class="ds-confirmation">
  <div class="ds-confirmation__header">
    <img class="icon" [src]="data.icon.url" />
    <div class="heading">{{ data.heading }}</div>
  </div>
  <div class="ds-confirmation__description">
    {{ data.description }}
  </div>

  <div class="ds-confirmation__action">
    <call-to-action
      [displayBackButton]="true"
      backButtonName="Back to Overview"
      (backEvent)="backToOverview()"
    ></call-to-action>
  </div>
</div>

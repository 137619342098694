import { EligibleServicesAction, EligibleServicesActionTypes } from './eligible-services.actions';
import { ServiceType } from './eligible-services.models';

export interface EligibleServicesState {
  claimNumber: string;
  eligibleServices: ServiceType[];
  numberOfCustomerPhotos: number;
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
}

export const initialState: EligibleServicesState = {
  claimNumber: null,
  eligibleServices: [],
  numberOfCustomerPhotos: 0,
  loaded: false,
  loading: false,
  hasError: false
};

export function eligibleServicesReducer(
  state: EligibleServicesState = initialState,
  action: EligibleServicesAction
): EligibleServicesState {
  switch (action.type) {
    case EligibleServicesActionTypes.GetEligibleServices:
      return Object.assign({}, state, {
        claimNumber: action.payload.claimNumber,
        loaded: false,
        loading: true
      });

    case EligibleServicesActionTypes.GetEligibleServicesSuccess:
      return Object.assign({}, state, {
        hasError: false,
        loaded: true,
        loading: false,
        eligibleServices: action.payload.eligibleServices,
        numberOfCustomerPhotos: action.payload.numberOfCustomerPhotos
      });

    case EligibleServicesActionTypes.GetEligibleServicesFail:
      return Object.assign({}, state, {
        hasError: true,
        loaded: true,
        loading: false,
        eligibleServices: []
      });

    case EligibleServicesActionTypes.ResetEligibleServices:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { userQuery } from '@amfam/shared/user';

@Component({
  selector: 'manage-business-email-address',
  templateUrl: './manage-business-email-address.component.html',
  styleUrls: ['./manage-business-email-address.component.scss']
})
export class ManageBusinessEmailAddressComponent implements OnInit, OnDestroy {
  public businessEmailAddress: string;
  public businessName: string;
  public firstName: string;
  public lastName: string;
  private storeSub: Subscription;

  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.storeSub = combineLatest(
      this.store.select(userQuery.getEmailAddress),
      this.store.select(userQuery.getBusinessName),
      this.store.select(userQuery.getFirstName),
      this.store.select(userQuery.getLastName),
      (emailAddress, businessName, firstName, lastName) => {
        return {
          emailAddress: emailAddress,
          businessName: businessName,
          firstName: firstName,
          lastName: lastName
        };
      }
    ).subscribe(state => {
      this.businessEmailAddress = state.emailAddress;
      this.businessName = state.businessName;
      this.firstName = state.firstName;
      this.lastName = state.lastName;
    });
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}

import { SummariesAction, SummariesActionTypes } from './summaries.actions';
import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { PolicyDocument, PolicySummary } from '@amfam/policy/models';
import { get as _get } from 'lodash';

export const SUMMARIES_FEATURE_KEY = 'summaries';

export interface PolicySummariesPartialState {
  readonly [SUMMARIES_FEATURE_KEY]: PolicySummariesEntityState;
}

export interface PolicySummariesEntityState extends EntityState<PolicySummary> {
  selectedPolicyNumber: string;
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  error: any;
}

export const adapter: EntityAdapter<PolicySummary> = createEntityAdapter<PolicySummary>({
  selectId: (policyObjModel: PolicySummary) => policyObjModel.policyNumber,
  sortComparer: false
});

export const initialState: PolicySummariesEntityState = adapter.getInitialState({
  selectedPolicyNumber: '',
  loading: false,
  loaded: false,
  hasError: false,
  error: null
});

export function reducer(
  state: PolicySummariesEntityState = initialState,
  action: SummariesAction
): PolicySummariesEntityState {
  switch (action.type) {
    case SummariesActionTypes.SummariesLoadSuccess:
    case SummariesActionTypes.UpdatePoiLink: {
      return adapter.upsertMany(_get(action, 'payload', []), state);
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

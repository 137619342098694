<div class="row">
  <div class="column relative twelve">
    <ng-content></ng-content>
    <button
      ds-button
      [attr.aria-label]="inverseValue + ' input value'"
      class="show-hide link"
      (click)="toggleShow()"
      type="button"
      data-cy="showHideButton"
    >
      {{ showValue }}
    </button>
  </div>
</div>

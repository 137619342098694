import { isUserComingFromEnrollment } from '@amfam/billing/auto-pay/feature/src/lib/+state/auto-pay-setup.actions';
import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { PolicySelectors, PolicySummarySelectors } from '@amfam/policy/data-access';
import { GoPaperLessSelectors } from '@amfam/policy/go-paperless/data-access';
import { profileQuery } from '@amfam/profile/data-access';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions, routerQuery } from '@amfam/shared/utility/navigation';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
// Store
import { Store } from '@ngrx/store';
import { every as _every, get as _get } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { billingEligibleToGoPaperless, isEligibleToGoPaperless } from '../+state/common.selectors';

@Injectable({
  providedIn: 'root'
})
export class PaperlessGuard  {
  constructor(private store: Store, private feature: FeatureFlagService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.store.select(PolicySelectors.getPoliciesLoading),
      this.store.select(PolicySummarySelectors.getPolicySummaryLoading),
      this.store.select(BillAccountsSelectors.getBillAccountsAnyLoading),
      this.store.select(GoPaperLessSelectors.getGoPaperlessLoading),
      this.store.select(profileQuery.getProfileLoading),
      this.store.select(BillAccountsSelectors.getAllBillAccountsNotifications),
      this.store.select(billingEligibleToGoPaperless),
      this.store.select(isEligibleToGoPaperless),
      this.store.select(AutoPayUtilSelectors.getBillAccountsEligibleForAutoPay),
      this.store.select(routerQuery.getRouterPreviousState),
      of(route.queryParams)
    ]).pipe(
      map(
        ([
          policiesLoading,
          policySummaryLoading,
          billAccountsLoading,
          goPaperLessLoading,
          profileLoading,
          billAccounts,
          isBillingEligibleForPaperLess,
          isPolicyEligibleForPaperLess,
          billAccountsEligibleForAutoPay,
          previousStateRoute,
          queryParams
        ]) => ({
          loading:
            billAccountsLoading ||
            policiesLoading ||
            goPaperLessLoading ||
            policySummaryLoading ||
            profileLoading ||
            (billAccounts.length > 0
              ? _every(
                  billAccounts,
                  billAccount => _get(billAccount, 'prefsFinishedLoading', false) === false
                )
              : false),
          eligibleForPaperLess: isBillingEligibleForPaperLess || isPolicyEligibleForPaperLess,
          billAccountsEligibleForAutoPay,
          previousStateRoute,
          queryParams
        })
      ),
      filter(state => !state.loading),
      map(state => {
        if (
          _get(state, 'previousStateRoute.url') === '/enroll/personal/security-questions' ||
          _get(state, 'previousStateRoute.url') === '/enroll/confirmation' ||
          state.queryParams.event === 'OnSigningComplete'
        ) {
          this.store.dispatch(isUserComingFromEnrollment({ isUserComingFromEnrollment: true }));
          if (!state.eligibleForPaperLess) {
            if (state.billAccountsEligibleForAutoPay.length === 0) {
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: ['/overview']
                })
              );
            } else {
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: ['/billing/auto-pay/select-accounts']
                })
              );
            }

            return false;
          } else {
            return true;
          }
        } else {
          this.store.dispatch(
            new fromRouterActions.Go({
              path: ['/overview']
            })
          );
          return false;
        }
      })
    );
  }
}

<div
  class="row button-container"
  [ngClass]="{
    'full-width-buttons': fullWidthButtons,
    'action-panel-container': actionPanel,
    'error-action-btn': dsErrorAction
  }"
>
  <!--Back Button-->
  <div *ngIf="displayBackButton" class="flex items-center link-button">
    <span class="back-icon icon-chevron-left"></span>
    <a class="caption no-decoration" (click)="backEvent.emit()" data-cy="backButton">{{
      backButtonName
    }}</a>
  </div>

  <span *ngIf="!equalWidthButtons" class="grow"></span>
  <!--Tertiary Button-->
  <a
    *ngIf="!!tertiaryButtonName"
    class="pad-horizontal-10 link-button no-decoration caption"
    [ngClass]="{
      'error-600-text': tertiaryButtonDestructive,
      'flex-1': equalWidthButtons,
      'text-center': equalWidthButtons
    }"
    (click)="tertiaryEvent.emit()"
    data-cy="tertiaryButton"
    >{{ tertiaryButtonName }}</a
  >
  <!--Secondary Button-->
  <button
    ds-button
    *ngIf="!!secondaryButtonName"
    class="secondary-button margin-left-0"
    [ngClass]="{
      '-error': secondaryButtonDestructive,
      '-outline-white': !secondaryButtonDestructive,
      'flex-1': equalWidthButtons
    }"
    (click)="secondaryEvent.emit()"
    [disabled]="disabled || disableSecondaryButton"
    data-cy="secondaryButton"
    [class.capitalize]="capitalize"
  >
    {{ secondaryButtonName }}
  </button>
  <!--Primary Button-->
  <button
    ds-button
    *ngIf="!!primaryButtonName"
    class="margin-right-0 margin-left-0 primary-button"
    [ngClass]="{ '-error': primaryButtonDestructive, 'flex-1': equalWidthButtons }"
    (click)="primaryEvent.emit()"
    [disabled]="disabled || disablePrimaryButton"
    [class.capitalize]="capitalize"
    data-cy="primaryButton"
  >
    {{ primaryButtonName }}
  </button>
</div>

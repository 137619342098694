import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { ImpersonateRolesService } from '../services/impersonate-roles.service';
import { elementTypes } from '../models/element-types.model';

@Directive({
  selector: '[authCheck]'
})
export class AuthCheckDirective implements OnInit {
  @Input() private permissionName: string;
  @Input() private checkAction: string;
  @Input() private applyAuthCheck = true;

  constructor(private element: ElementRef, private roleService: ImpersonateRolesService) {}

  ngOnInit() {
    if (this.applyAuthCheck) {
      this.roleService.isAuthorized(this.permissionName).subscribe(
        authorized => {
          if (!authorized && this.element.nativeElement.localName !== elementTypes.anchorTag) {
            switch (this.checkAction) {
              case 'hide':
                this.element.nativeElement.style.visibility = 'hidden';
                break;
              case 'remove':
                this.element.nativeElement.style.display = 'none';
                break;
              case 'disable':
                try {
                  this.element.nativeElement.disabled = true;
                } catch (error) {
                  console.log('Unable to set disabled on non-button or input element: ', error);
                }
                break;
            }
          } else if (
            !authorized &&
            this.element.nativeElement.localName === elementTypes.anchorTag
          ) {
            switch (this.checkAction) {
              case 'remove':
                this.element.nativeElement.style.display = 'none';
                break;
              case 'disable':
                this.element.nativeElement.style.color = '#717073';
                this.element.nativeElement.style.pointerEvents = 'none';
                this.element.nativeElement.tabIndex = -1;
                break;
            }
          }
        },
        err => console.log(err)
      );
    }
  }
}

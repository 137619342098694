import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IMPERSONATION_FEATURE_KEY, fromImpersonation } from './impersonation.reducer';
import { ImpersonationState } from './impersonation.model';

export const getImpersonationState = createFeatureSelector<ImpersonationState>(
  IMPERSONATION_FEATURE_KEY
);

export const isImpersonating = createSelector(
  getImpersonationState,
  fromImpersonation.isImpersonating
);
export const permissions = createSelector(
  getImpersonationState,
  fromImpersonation.permissions
);

export const impersonationQuery = {
  isImpersonating,
  permissions
};

import { Directive, Input, HostListener } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { ButtonAnalytic } from './analytics.model';

@Directive({
  selector: '[dsAnalyticsClick]'
})
export class AnalyticsClickDirective {
  private buttonAnalytic: ButtonAnalytic;

  constructor(private analytics: AnalyticsService) {}

  @Input()
  set dsAnalyticsClick(data: string) {
    this.buttonAnalytic = {
      link: data
    };
  }

  @HostListener('click')
  onClick(): boolean {
    if (this.buttonAnalytic) {
      this.analytics.trackButtonClick(this.buttonAnalytic);
      return true;
    }
    return false;
  }
}

import { Directive, HostBinding, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

@Directive({
  selector: '[refreshMobileMenu]'
})
export class MobileMenuRefreshDirective implements OnDestroy {
  private stop$ = new Subject<void>();

  constructor(private router: Router, private element: ElementRef) {
    this.router.events.pipe(takeUntil(this.stop$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.element.nativeElement.checked = false;
      }
    });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

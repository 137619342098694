import { CopyService } from '@amfam/shared/utility/shared-services';
import { AlertModalConfig, DsModalService } from '@amfam/ui-kit';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-silent-registration-error-modal',
  templateUrl: './silent-registration-error-modal.component.html',
  styleUrls: ['./silent-registration-error-modal.component.scss']
})
export class SilentRegistrationErrorModalComponent implements OnInit {
  modalConfig: AlertModalConfig = {
    headerText: this.copyService.getCopy(
      'billing.autoPayRefactor.registrationErrorModalHeadingText'
    ),
    bodyText: this.copyService.getCopy('billing.autoPayRefactor.registrationErrorModalBodyText'),
    modalId: 'silentRegistrationErrorModal',
    ctaConfig: {
      primaryButtonName: 'OK',
      fullWidthButtons: true
    }
  };

  constructor(private modalService: DsModalService, private copyService: CopyService) {}

  ngOnInit(): void {}

  closeModal() {
    this.modalService.close('silentRegistrationErrorModal');
  }
}

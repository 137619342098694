import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { ErrorPageComponent } from './error-page/error-page.component';

@Injectable()
export class ErrorGuard  {
  canDeactivate(
    component: ErrorPageComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean {
    window.location.href = nextState.url;
    return true;
  }
}

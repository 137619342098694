<div
  authCheck
  *ngIf="!(fromOverview && alert.level === 5)"
  [permissionName]="'view_sso_banner'"
  [checkAction]="'remove'"
  data-cy="alertItem"
>
  <div
    *ngIf="content && !alert.data?.registeredElseWhere"
    [ngClass]="alertClasses"
    class="alert items-center flex"
  >
    <span [ngClass]="alertIcon"></span>
      <div *ngIf="showPastDueAlert" class="alert-message" data-cy="alertMessage">
        <bills-overdue-alert (routeToBillAccount)="routeToBillAccount($event)" [billAccounts]="alert.data.billAccounts"></bills-overdue-alert>
      </div>
      <div *ngIf="!showPastDueAlert" class="alert-message" data-cy="alertMessage" [innerHtml]="content.message"></div>
  <div class="alert-action">
      <button
        ds-button
        authCheck
        [permissionName]="'select_sso_banner'"
        [checkAction]="'disable'"
        [applyAuthCheck]="alert.type === alertTypes.POLICIES_MANAGED_EXTERNALLY"
        *ngIf="content.action"
        [ngClass]="buttonClasses"
        (click)="takeAction()"
        data-cy="alertActionButton"
      >
        {{ content.action }}
      </button>
    </div>
  </div>
</div>

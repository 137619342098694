import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { UserAnalytic, WindowRef } from '@amfam/shared/utility/shared-services';
import { DynatraceService } from './dynatrace.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  profileInfo: UserAnalytic = new UserAnalytic();

  constructor(private win: WindowRef, private dynatraceService: DynatraceService) {}

  loadGoogleTagManagerScript() {
    return from(
      new Promise<void>((resolve, reject) => {
        // Set up the GTM script
        let script, scriptRef;
        const dataLayerName = 'dataLayer';
        const gtmId = 'GTM-K52ZTSS'; // GTM container ID
        this.win.nativeWindow[dataLayerName] = this.win.nativeWindow[dataLayerName] || [];

        // Create the GTM script tag
        script = document.createElement('script');
        scriptRef = document.getElementsByTagName('script')[0];
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

        script.onload = () => {
          resolve();
        };
        script.onerror = (error: any) => {
          reject(error);
        };

        // Finally, append the GTM script tag in the DOM
        scriptRef.parentNode.insertBefore(script, scriptRef);
      }).then(
        () => {
          // GTM script loaded successfully
          this.dynatraceService.sendDynatraceAction('ga', 'true');
        },
        reason => {
          // Handle script loading failure
          this.dynatraceService.sendDynatraceAction('ga', 'false');
          throw new Error(reason);
        }
      )
    );
  }

  private pushToGTMDataLayer(obj: any) {
    if (obj) this.win.nativeWindow.dataLayer.push(obj);
  }

  sendAnalytic(options: any) {
    this.pushToGTMDataLayer(options);
  }
}

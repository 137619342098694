import { Phone } from './phone';
import { Address } from '@amfam/policy/models';
import { Email } from './email';

export class Contact {
  name: string;
  firstName: string;
  lastName: string;
  middleName: string;
  emails: Email[];
  phones: Phone[];
  addresses: Address[];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { billingRegistrationReducers } from './+state/registration.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('billing registrations', billingRegistrationReducers)
  ]
})
export class BillingRegistrationDataAccessModule {}

import { Action } from '@ngrx/store';
import { DraftClaim } from '../models';
import { StorePolicyInfo } from '../models';

export enum ClaimFnolActionTypes {
  // Claims Search
  LoadDraftClaims = '[ClaimFnol] Load Draft Claims',
  LoadDraftClaimsSuccess = '[ClaimFnol] Load Draft Claims Success',
  LoadDraftClaimsFail = '[ClaimFnol] Load Draft Claims Fail',

  // Detail calls
  LoadDraftClaimDetail = '[ClaimFnol] Load Draft Claim Detail',
  LoadDraftClaimDetailSuccess = '[ClaimFnol] Load Draft Claim Detail Success',
  LoadDraftClaimDetailFail = '[ClaimFnol] Load Draft Claim Detail Fail',

  // Set the selected draft claim number in the store
  ResumeDraftClaim = '[ClaimFnol] Resume Draft Claim',

  // Store the selected risk for a new, uncreated draft claim (no api interaction)
  SaveDraftClaim = '[ClaimFnol] Save Draft Claim',

  // Call to initally create the draft claim
  CreateDraftClaim = '[ClaimFnol] Create Draft Claim',
  CreateDraftClaimSuccess = '[ClaimFnol] Create Draft Claim Success',
  CreateDraftClaimFail = '[ClaimFnol] Create Draft Claim Fail',

  // Upate draft claim
  UpdateDraftClaim = '[ClaimFnol] Update Draft Claim',
  UpdateDraftClaimSuccess = '[ClaimFnol] Update Draft Claim Success',
  UpdateDraftClaimFail = '[ClaimFnol] Update Draft Claim Fail',

  // Delete
  DeleteDraftClaim = '[ClaimFnol] Delete Draft Claim',
  DeleteDraftClaimSuccess = '[ClaimFnol] Delete Draft Claim Success',
  DeleteDraftClaimFail = '[ClaimFnol] Delete Draft Claim Fail',

  // Final submission
  SubmitDraftClaim = '[ClaimFnol] Submit Draft Claim',
  SubmitDraftClaimSuccess = '[ClaimFnol] Submit Draft Claim Success',
  SubmitDraftClaimFail = '[ClaimFnol] Submit Draft Claim Fail',

  // Clear selected risk from non-auto flow.
  ClearSelectedEntity = '[ClaimFnol] Clear Selected Risk'
}

export class LoadDraftClaims implements Action {
  readonly type = ClaimFnolActionTypes.LoadDraftClaims;
  constructor(public payload?: any) {}
}

export class LoadDraftClaimsSuccess implements Action {
  readonly type = ClaimFnolActionTypes.LoadDraftClaimsSuccess;
  constructor(public payload?: any) {}
}

export class LoadDraftClaimsFail implements Action {
  readonly type = ClaimFnolActionTypes.LoadDraftClaimsFail;
  constructor(public payload?: any) {}
}

export class LoadDraftClaimDetail implements Action {
  readonly type = ClaimFnolActionTypes.LoadDraftClaimDetail;
  constructor(public payload?: any) {}
}

export class LoadDraftClaimDetailSuccess implements Action {
  readonly type = ClaimFnolActionTypes.LoadDraftClaimDetailSuccess;
  constructor(public payload?: any) {}
}

export class LoadDraftClaimDetailFail implements Action {
  readonly type = ClaimFnolActionTypes.LoadDraftClaimDetailFail;
  constructor(public payload?: any) {}
}
export class ResumeDraftClaim implements Action {
  readonly type = ClaimFnolActionTypes.ResumeDraftClaim;
  constructor(public payload?: string) {}
}

// Action to save draft claim data before submitting.
export class SaveDraftClaim implements Action {
  readonly type = ClaimFnolActionTypes.SaveDraftClaim;
  constructor(public payload?: StorePolicyInfo | DraftClaim) {}
}

export class CreateDraftClaim implements Action {
  readonly type = ClaimFnolActionTypes.CreateDraftClaim;
  constructor(public payload?: DraftClaim) {}
}

// TODO: Make CreateDraftClaimResponse model
export class CreateDraftClaimSuccess implements Action {
  readonly type = ClaimFnolActionTypes.CreateDraftClaimSuccess;
  constructor(public payload?: any) {}
}

export class CreateDraftClaimFail implements Action {
  readonly type = ClaimFnolActionTypes.CreateDraftClaimFail;
  constructor(public payload?: any) {}
}

export class UpdateDraftClaim implements Action {
  readonly type = ClaimFnolActionTypes.UpdateDraftClaim;
  constructor(public payload?: any) {}
}

export class UpdateDraftClaimSuccess implements Action {
  readonly type = ClaimFnolActionTypes.UpdateDraftClaimSuccess;
  constructor(public payload?: any) {}
}

export class UpdateDraftClaimFail implements Action {
  readonly type = ClaimFnolActionTypes.UpdateDraftClaimFail;
  constructor(public payload?: any) {}
}

export class DeleteDraftClaim implements Action {
  readonly type = ClaimFnolActionTypes.DeleteDraftClaim;
  constructor(public payload?: any) {}
}

export class DeleteDraftClaimSuccess implements Action {
  readonly type = ClaimFnolActionTypes.DeleteDraftClaimSuccess;
  constructor(public payload?: any) {}
}

export class DeleteDraftClaimFail implements Action {
  readonly type = ClaimFnolActionTypes.DeleteDraftClaimFail;
  constructor(public payload?: any) {}
}

export class SubmitDraftClaim implements Action {
  readonly type = ClaimFnolActionTypes.SubmitDraftClaim;
  constructor(public payload?: DraftClaim) {}
}

export class SubmitDraftClaimSuccess implements Action {
  readonly type = ClaimFnolActionTypes.SubmitDraftClaimSuccess;
  constructor(public payload?: any) {}
}

export class SubmitDraftClaimFail implements Action {
  readonly type = ClaimFnolActionTypes.SubmitDraftClaimFail;
  constructor(public payload?: any) {}
}

export class ClearSelectedEntity implements Action {
  readonly type = ClaimFnolActionTypes.ClearSelectedEntity;
  constructor() {}
}

export type ClaimFnolAction =
  | LoadDraftClaims
  | LoadDraftClaimsSuccess
  | LoadDraftClaimsFail
  | LoadDraftClaimDetail
  | LoadDraftClaimDetailSuccess
  | LoadDraftClaimDetailFail
  | ResumeDraftClaim
  | SaveDraftClaim
  | CreateDraftClaim
  | CreateDraftClaimSuccess
  | CreateDraftClaimFail
  | UpdateDraftClaim
  | UpdateDraftClaimSuccess
  | UpdateDraftClaimFail
  | DeleteDraftClaim
  | DeleteDraftClaimSuccess
  | DeleteDraftClaimFail
  | SubmitDraftClaim
  | SubmitDraftClaimSuccess
  | SubmitDraftClaimFail
  | ClearSelectedEntity;

export const fromClaimFnolActions = {
  LoadDraftClaims,
  LoadDraftClaimsSuccess,
  LoadDraftClaimsFail,
  LoadDraftClaimDetail,
  LoadDraftClaimDetailSuccess,
  LoadDraftClaimDetailFail,
  ResumeDraftClaim,
  SaveDraftClaim,
  CreateDraftClaim,
  CreateDraftClaimSuccess,
  CreateDraftClaimFail,
  UpdateDraftClaim,
  UpdateDraftClaimSuccess,
  UpdateDraftClaimFail,
  DeleteDraftClaim,
  DeleteDraftClaimSuccess,
  DeleteDraftClaimFail,
  SubmitDraftClaim,
  SubmitDraftClaimSuccess,
  SubmitDraftClaimFail,
  ClearSelectedEntity
};

export interface ClaimDashboardRequest {
  cdhId: string;
}

export interface DashboardClaim {
  claimNumber: string;
  risk: DashboardAutoClaimRisk[] | DashboardPropertyClaimRisk;
  risktype: RiskType;
  activities: DashboardClaimActivity[];
  claimProgress: string;
}

export interface DashboardAutoClaimRisk {
  year: string;
  make: string;
  model: string;
  vin: string;
  typeOfVehicleCode: string;
}

export interface DashboardPropertyClaimRisk {
  addr1: string;
  addr2: string;
  city: string;
  state: string;
  zip5: string;
  zip4: string;
  addressType: string;
}

export interface DashboardClaimActivity {
  date: string;
  message: string;
}

export interface DashboardClaimCardData {
  claimNumber: string;
  fullRiskName: string;
  latestActivity: DashboardClaimActivity;
  formattedClaimNumber?: string;
}

export enum RiskType {
  VEHICLE = 'Vehicle',
  PROPERTY = 'Property'
}

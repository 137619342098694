import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'enroll-contact-us-link',
  templateUrl: './contact-us-link.component.html',
  styleUrls: ['../../enrollment.component.scss']
})
export class ContactUsLinkComponent implements OnInit {
  public amfamDotCom: string;

  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.amfamDotCom = this.config.get('links.amfamDotComUrl');
  }
}

import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { get as _get } from 'lodash';

// JP: Custom validator to ensure that at least one checkbox is checked else form is disabled
export function minimumCheckedCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: UntypedFormGroup) {
    let checked = 0;

    _get(formGroup, "controls['option']['controls']", []).forEach(option => {
      if (option.value) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: true
      };
    }

    return null;
  };
}

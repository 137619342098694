import { PolicyTypeDisplayNameConstants } from './policy-type-display-name-constants';

export class VehicleTypeDisplayNameConstants {
  static readonly ATV = 'All Terrain Vehicle';
  static readonly AUTO = PolicyTypeDisplayNameConstants.AUTO; // Let's reference the policy display name
  static readonly CAMPER = 'Recreational Vehicle';
  static readonly DIRTBIKE = 'Dirt Bike';
  static readonly FARMMACHINERY = 'Farm Machinery';
  static readonly GOLFCART = 'Golf Cart';
  static readonly MOBILITYDEVICE = 'Mobility Device';
  static readonly MOPED = 'Moped';
  static readonly MOTORCYCLE = 'Motorcycle';
  static readonly MOTORHOME = 'Recreational Vehicle';
  static readonly RV = 'Recreational Vehicle';
  static readonly SCOOTER = 'Scooter';
  static readonly SNOWMOBILE = 'Snowmobile';
  static readonly TRAILER = 'Recreational Vehicle';
  static readonly UNKNOWN = 'Unknown';
  static readonly UTV = 'Utility Vehicle';
  static readonly WATERCRAFT = 'Watercraft';
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { IMPERSONATION_FEATURE_KEY, reducer } from './+state/impersonation.reducer';
import { initialImpersonationState } from './+state/impersonation.model';
import { ImpersonationEffects } from './+state/impersonation.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(IMPERSONATION_FEATURE_KEY, reducer, {
      initialState: initialImpersonationState
    }),
    EffectsModule.forFeature([ImpersonationEffects])
  ]
})
export class ImpersonationStoreModule {}

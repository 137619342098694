<p *ngIf="showCookieWarning" class="error-600-text error-600-border pad-20">
  <span content [contentPath]="'auth.cookieText'"></span>
  <a
    dsPartnerContent
    [brandContent]="'getPrettyCusCareNumber'"
    [brandHref]="'getCusCareNumberFullHref'"
    target="_blank"
  ></a>
  <span>.</span>
</p>

import { createSelector } from '@ngrx/store';

import { get as _get, has as _has } from 'lodash';
import { PendingEmailVerification } from '../models/profile.model';
import * as profile from './profile.actions';
import { ProfileActionTypes } from './profile.actions';

export const PROFILE_FEATURE_KEY = 'profile';

export interface ProfileState {
  loading: boolean;
  customerId: string;
  emailInUseError: boolean;
  updatedMyAccountEmailAddress: string;
  emailVerificationPending: PendingEmailVerification;
  toast?: {
    type: string;
    title: string;
    body: string;
    hide?: boolean;
  };
  editSecurityInfoError?: boolean;
  editSecurityInfoResponse?: any;
  methodType?: string;
  // from digital account
  securityQuestions?: any[];
}

const initialState: ProfileState = {
  loading: false,
  customerId: null,
  emailInUseError: false,
  updatedMyAccountEmailAddress: '',
  emailVerificationPending: {
    pending: false,
    oldEmail: null,
    newEmail: null
  },
  toast: {
    type: '',
    title: '',
    body: '',
    hide: false
  },
  securityQuestions: []
};

export function reducer(state: ProfileState = initialState, action: profile.Actions): ProfileState {
  switch (action.type) {
    case ProfileActionTypes.MAKE_REQUEST:
    case ProfileActionTypes.FETCH_DATA:
      return Object.assign({}, state, {
        loading: true,
        methodType: ''
      });

    case ProfileActionTypes.ADD_CONTACT_ENTRY_SUCCESS:
      // Don't pop toaster message if making email primary
      const hideToasterMessage = action.payload.setPrimaryEmail;
      const methodType_a = action.payload.methodType;
      const toastMsgForAdd: string =
        methodType_a === 'phones' ? 'Phone number has been saved' : 'Email address has been saved';

      const successObj = {
        loading: false,
        toast: {
          type: 'success',
          title: '',
          body: toastMsgForAdd,
          hide: hideToasterMessage
        }
      };
      // Edge case: there might be a weird situation where the the GET fails after the POST is successful
      // we still want to toast that the POST was good even if the UI can't update
      if (!action.payload[methodType_a]) {
        return Object.assign({}, state, successObj);
      }
      return Object.assign({}, state, successObj, {
        [methodType_a]: action.payload[methodType_a]
      });

    case ProfileActionTypes.CHANGE_SECURITY_INFO_SUCCESS:
      const methodType_success = action.payload.methodType;
      const newStateObjectSecurityInfoSuccess = {
        methodType: methodType_success,
        loading: false,
        editSecurityInfoError: false,
        toast: {
          type: 'success',
          title: 'Success',
          body: '',
          hide: action.payload.hideToaster
        }
      };
      if (methodType_success === 'password') {
        newStateObjectSecurityInfoSuccess.toast.body = 'Password Changed';
      } else if (methodType_success === 'userId') {
        newStateObjectSecurityInfoSuccess.toast.body = 'User ID Changed';
      } else {
        newStateObjectSecurityInfoSuccess.toast.body = 'Your security questions have been updated';
      }
      return Object.assign({}, state, newStateObjectSecurityInfoSuccess);

    case ProfileActionTypes.CHANGE_SECURITY_INFO_FAIL:
      const methodType_failure = action.payload.methodType;
      const newStateObjectSecurityInfoFail = {
        methodType: methodType_failure,
        loading: false,
        editSecurityInfoError: true,
        editSecurityInfoResponse: {},
        toast: {
          type: 'error',
          title: 'Error',
          body: '',
          hide: action.payload.hideToaster
        }
      };
      if (methodType_failure === 'password') {
        newStateObjectSecurityInfoFail.toast.body =
          'There was an error updating your password. Please try again later.';
      } else if (methodType_failure === 'userId') {
        newStateObjectSecurityInfoFail.toast.body =
          'There was an error updating your user id. Please try again later.';
      } else {
        newStateObjectSecurityInfoFail.editSecurityInfoResponse = action.payload.status;
        newStateObjectSecurityInfoFail.toast.body =
          'There was an error updating your security questions. Please try again.';
      }
      return Object.assign({}, state, newStateObjectSecurityInfoFail);

    case ProfileActionTypes.DELETE_CONTACT_ENTRY_SUCCESS:
      const methodType_d = action.payload.methodType;
      const toasterRemoveCopy =
        methodType_d === 'phones'
          ? 'Phone number has been removed'
          : 'Email address has been removed';
      return Object.assign({}, state, {
        loading: false,
        toast: {
          type: 'success',
          title: '',
          body: toasterRemoveCopy
        }
      });

    case ProfileActionTypes.CHANGE_CONTACT_ENTRY_SUCCESS:
      const methodType_c = action.payload.methodType;
      const toasterChangeCopy =
        methodType_c === 'phones'
          ? 'Phone number successfully updated'
          : 'Email successfully updated';
      return Object.assign({}, state, {
        loading: false,
        toast: {
          type: 'success',
          title: '',
          body: toasterChangeCopy
        }
      });

    // If the email change is not succesful and the email is already in use
    case ProfileActionTypes.UPDATE_DA_FAIL:
      if (action.payload.status.messages[0].code === 400003) {
        return Object.assign({}, state, {
          loading: false,
          emailInUseError: true
        });
      }
      return Object.assign({}, state, {
        loading: false,
        emailInUseError: false,
        toast: {
          type: 'error',
          title: 'Error',
          body: 'This email address is already in use. Please select a different email address.'
        }
      });

    case ProfileActionTypes.UPDATE_DA_SUCCESS:
      const newStateObjectUpdateDaSuccess = {
        loading: false,
        emailInUseError: false,
        updatedMyAccountEmailAddress: action.payload.emailAddress,
        emailVerificationPending: {
          pending: false,
          oldEmail: null,
          newEmail: null
        }
      };
      // only show toast if updating email that's already validated
      if (
        action.payload.hasOwnProperty('emailAddress') &&
        !action.payload.hasOwnProperty('customerId')
      ) {
        newStateObjectUpdateDaSuccess['toast'] = {
          type: 'success',
          title: '',
          body: 'Email successfully updated'
        };
      }
      return Object.assign({}, state, newStateObjectUpdateDaSuccess);

    // If add/edit/remove for phone or email fails OR email validation fails
    case ProfileActionTypes.CONTACT_ENTRY_FAIL:
    case ProfileActionTypes.VALIDATE_EMAIL_FAIL:
      const detailedErrorCode = _get(action, 'payload.status.messages[0].code');
      const contactMethodType = _get(action, 'payload.methodType');
      const operationType = _get(action, 'type');
      const updatedStateObject = {
        loading: false,
        phoneInUseError: false,
        emailInUseError: false,
        toast: {
          type: 'error',
          title: 'Error',
          body: ''
        }
      };
      if (contactMethodType === 'phones') {
        delete updatedStateObject['emailInUseError'];
        if (detailedErrorCode === '400001') {
          updatedStateObject.phoneInUseError = true;
          updatedStateObject.toast.body = 'Phone number is already in use';
        }
        // else if (operationType === ProfileActionTypes.ADD_CONTACT_ENTRY) {
        //   updatedStateObject.toast.body =
        //     'Your phone number could not be saved at this time. Please try again later.';
        // } else if (operationType === ProfileActionTypes.DELETE_CONTACT_ENTRY) {
        //   updatedStateObject.toast.body =
        //     'Your phone number could not be deleted at this time. Please try again later.';
        // }
        else {
          updatedStateObject.toast.body =
            'Your phone number could not be updated at this time. Please try again later.';
        }
      } else {
        delete updatedStateObject['phoneInUseError'];
        if (detailedErrorCode === '400001') {
          updatedStateObject.emailInUseError = true;
          updatedStateObject.toast.body = 'Email is already in use';
        }
        //  else if (operationType === ProfileActionTypes.ADD_CONTACT_ENTRY) {
        //   updatedStateObject.toast.body =
        //     'Your email address could not be saved at this time. Please try again later.';
        // } else if (operationType === ProfileActionTypes.DELETE_CONTACT_ENTRY) {
        //   updatedStateObject.toast.body =
        //     'Your email address could not be removed at this time. Please try again later.';
        // }
        else {
          updatedStateObject.toast.body =
            'This email address is already in use. Please select a different email address.';
        }
      }
      return Object.assign({}, state, updatedStateObject);

    case ProfileActionTypes.FETCH_PARTY_DATA_SUCCESS:
      return Object.assign({}, state, {
        loading: false
      });

    case ProfileActionTypes.FETCH_PARTY_DATA_FAIL:
      return Object.assign({}, state, {
        loading: false
      });

    case ProfileActionTypes.FETCH_DA_DATA_SUCCESS:
      const payload = {
        securityQuestions: action.payload.securityQuestions
      };
      return Object.assign(
        {},
        state,
        {
          loading: false
        },
        payload
      );

    case ProfileActionTypes.FETCH_DA_DATA_FAIL:
      return Object.assign({}, state, {
        loading: false
      });

    case ProfileActionTypes.UPDATE_SECURITY_QUESTIONS:
      return Object.assign({}, state, {
        securityQuestions: action.payload.securityQuestions
      });

    case ProfileActionTypes.VALIDATE_EMAIL:
      return Object.assign({}, state, {
        emailVerificationPending: {
          pending: false,
          oldEmail: action.payload.oldEmail,
          newEmail: action.payload.newEmail
        }
      });

    case ProfileActionTypes.VALIDATE_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        emailInUseError: false,
        emailVerificationPending: {
          pending: true,
          oldEmail: state.emailVerificationPending.oldEmail,
          newEmail: state.emailVerificationPending.newEmail
        },
        toast: {
          type: 'success',
          title: '',
          body: 'Email sent for verification.'
        }
      });

    case ProfileActionTypes.GET_VALIDATION_STATUS_FOR_PENDING_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        emailInUseError: false,
        emailVerificationPending: {
          pending: true,
          newEmail: action.payload.newEmail,
          tokenExpiry: action.payload.tokenExpiry
        }
      });

    case ProfileActionTypes.MAGIC3_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        customerId: action.payload
      });

    case ProfileActionTypes.UPDATE_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        updatedMyAccountEmailAddress: _has(action.payload, 'digitalAccount.emailAddress')
          ? action.payload.digitalAccount.emailAddress
          : ''
      });

    case ProfileActionTypes.CLEAR_TOAST:
      return { ...state, toast: initialState.toast };

    default: {
      return state;
    }
  }
}

export const getLoading = (state: ProfileState) => state.loading;

export const getCustomerId = (state: ProfileState) => state.customerId;

export const getSecurityQuestions = (state: ProfileState) => state.securityQuestions;

export const getEmailInUseError = (state: ProfileState) => state.emailInUseError;

export const getEmailVerificationPending = (state: ProfileState) => state.emailVerificationPending;

export const getUpdatedMyAccountEmailAddress = (state: ProfileState) =>
  state.updatedMyAccountEmailAddress;

export const getToastMessage = (state: ProfileState) => state.toast;

export const getEditSecurityInfoError = (state: ProfileState) => state.editSecurityInfoError;

export const getEditSecurityInfoResponse = (state: ProfileState) => state.editSecurityInfoResponse;

export const getEditSecurityInfoMethodType = (state: ProfileState) => state.methodType;

export const getSecurityInfoStatus = createSelector(
  getLoading,
  getEditSecurityInfoError,
  getEditSecurityInfoResponse,
  getEditSecurityInfoMethodType,
  (loading, editSecurityInfoError, editSecurityInfoResponse, editSecurityInfoMethodType) => {
    return {
      loading,
      editSecurityInfoError,
      editSecurityInfoResponse,
      editSecurityInfoMethodType
    };
  }
);
export const getTokenExpred = (state: ProfileState) => state.emailVerificationPending.tokenExpiry;

<div>
  <amfam-enrollment-header
    [firstLine]="somethingWentWrongText"
    [secondLine]="enrollmentErrorMessage"
  ></amfam-enrollment-header>

  <div class="column twelve lg-push-two md-eight md-push-two">
    <call-to-action
      [primaryButtonName]="'Try Again'"
      (primaryEvent)="tryAgain()"
      [disablePrimaryButton]="retryCount <= 0"
      [secondaryButtonName]="'Exit Setup'"
      (secondaryEvent)="exitSetup()"
    ></call-to-action>
  </div>
</div>

import { Component, ContentChildren, QueryList } from '@angular/core';
import { ShowHideInputMyAccountDirective } from './show-hide-input.directive';

@Component({
  selector: 'show-hide',
  templateUrl: './show-hide-input.component.html',
  styleUrls: ['./show-hide-input.component.scss']
})
export class ShowHideInputComponent {
  private show = false;
  showValue = 'Show';
  inverseValue = 'Hide';

  @ContentChildren(ShowHideInputMyAccountDirective, { descendants: true }) input: QueryList<
    ShowHideInputMyAccountDirective
  >;

  constructor() {}

  toggleShow() {
    this.show = !this.show;
    if (this.showValue === 'Hide') {
      this.showValue = 'Show';
      this.inverseValue = 'Hide';
    } else {
      this.showValue = 'Hide';
      this.inverseValue = 'Show';
    }

    if (this.show) {
      this.input.forEach(item => {
        item.changeType('text');
      });
    } else {
      this.input.forEach(item => {
        item.changeType('password');
      });
    }
  }
}

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService, PageAnalytic } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';
import * as fromRoot from '../../../../core/store/';
import * as enrollUserActions from '../../../../core/store/enrollment';
import { IdentificationModel } from '../../../../core/store/enrollment/personal/identification';
import * as enrollStepsActions from '../../../../core/store/enrollment/steps';
import * as workflowActions from '../../../../core/store/workflow';
import { stepNames } from '../../../../core/store/workflow/workflow.model';
import { VerificationResponse } from '../../../verification-response';
import { VerificationService } from '../verification.service';

@Component({
  selector: 'ds-enroll-verify-ssn',
  templateUrl: './enroll-verify-ssn.component.html',
  styleUrls: ['../../../enrollment.component.scss']
})
export class EnrollVerifySsnComponent implements OnInit, OnDestroy {
  responseReceived = true;
  ssnNotFoundMessage: string;
  verifyResponse: VerificationResponse;

  // private model: VerifyOptionsModel;
  private identificationModel: IdentificationModel;
  private componentName: any = {};
  private componentData: any;
  private typeOfAccountCode: string;
  private partyId: string;
  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic;
  private conversionPageAnalytic: PageAnalytic;
  // **** End of Analytics data for this component

  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<fromRoot.RootState>,
    private verifyBy: VerificationService,
    private spinner: LoadingSpinnerService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    this.pageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:SSN',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity:SSN'
    };
    this.conversionPageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:SSN',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Add Personal Line',
      subCategory2: '',
      subCategory3: ''
    };
    this.componentName.enrollment = 'enrollment';
    this.componentData = this.copyService.getComponentData(this.componentName.enrollment);

    combineLatest(
      this.store.select(fromRoot.getEnrollUserState),
      this.store.select(userQuery.getTypeOfAccountCode),
      this.store.select(BrandSelectors.getPartnerId),
      (enrollUserState, typeOfAccountCode, partnerId) => {
        return {
          enrollUserState: enrollUserState,
          typeOfAccountCode: typeOfAccountCode,
          partnerId: partnerId
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        this.identificationModel = {
          lastName: state.enrollUserState.lastName,
          zipcode: state.enrollUserState.zipcode,
          dob: state.enrollUserState.dob,
          partnerId: state.partnerId
        };
        this.partyId = state.enrollUserState.partyId;

        this.typeOfAccountCode = state.typeOfAccountCode;
        // Analytics for conversion
        if (this.typeOfAccountCode && this.typeOfAccountCode !== AccountTypeEnum.Customer) {
          this.analyticsFacade.trackPage(this.conversionPageAnalytic);
        } else {
          this.analyticsFacade.trackPage(this.pageAnalytic);
        }
      });
  }

  goBackOneStep() {
    this.store.dispatch(new workflowActions.WorkflowStepBackAction());
  }

  resetErrorMessages() {
    this.ssnNotFoundMessage = null;
  }

  public verifySsn(ssn: string) {
    this.resetErrorMessages();
    this.responseReceived = false;
    this.spinner.start();

    this.verifyBy
      .ssn(ssn, this.identificationModel)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        response => {
          this.handleSuccess(response);
        },
        err => {
          this.handleError(err);
        }
      );
  }

  handleSuccess(response: any) {
    if (!response) {
      return;
    }
    this.spinner.stop();
    const statusCode = response.status && response.status.code ? response.status.code : 500;
    if (statusCode === 200) {
      this.verifyBy.resetVerifyAttempts();
      this.store.dispatch(
        new enrollUserActions.SubmitVerificationAction({
          verificationType: 'ssn',
          partyId: this.partyId
        })
      );
      this.store.dispatch(
        new enrollStepsActions.VerifyStepAction({
          verificationStep: true
        })
      );
      // complete the step
      this.store.dispatch(
        new workflowActions.WorkflowStepCompleteAction({ step: stepNames.verification })
      );
    } else if (statusCode === 404) {
      this.verifyBy.incrementAttempts();
      this.ssnNotFoundMessage = this.componentData['verifySSNMismatchText'];
      this.verifyResponse = new VerificationResponse(false, this.ssnNotFoundMessage, 'ssn');
    } else {
      this.store.dispatch(
        new fromRouterActions.Go({
          path: ['/enroll/contact-us']
        })
      );
    }
    this.responseReceived = true;
  }

  handleError(err: any) {
    this.spinner.stop();
    if (!err) {
      return;
    }
    this.responseReceived = true;
    this.store.dispatch(
      new fromRouterActions.Go({
        path: ['/enroll/contact-us']
      })
    );
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

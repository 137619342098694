import { VechiclePolicyChangeEntryPoint, VehicleSelectCriteria } from '../models/vehicle.models';
import { VehicleAction, VehicleActionTypes } from './vehicle.actions';

import { getYear } from 'date-fns';
import { get as _get } from 'lodash';
import { VehicleFeaturesModel } from '../models/vehicle-features.model';

export interface VehicleState {
  status?: any;
  hasError?: boolean;
  loading: boolean;
  criteria: VehicleSelectCriteria;
  newVehicle: any;
  years: Array<number>;
  makes: Array<string>;
  models: Array<string>;
  styles: Array<string>;
  vehiclePolicyChangeEntryPoint: VechiclePolicyChangeEntryPoint;
  features: VehicleFeaturesModel[];
}

export const initialState: VehicleState = {
  status: null,
  hasError: false,
  loading: false,
  criteria: {
    make: null,
    series: null,
    model: null,
    odometer: null,
    vin: null,
    year: null,
    vehicleType: null
  },
  newVehicle: null,
  years: getValidVehicleYears(),
  makes: [],
  models: [],
  styles: [],
  vehiclePolicyChangeEntryPoint: null,
  features: []
};

function getValidVehicleYears(): number[] {
  const start = 1982;
  const end = getYear(new Date()) + 2;
  const validYears = [];
  for (let i = start; i <= end; i++) {
    validYears.push(i);
  }
  return validYears.reverse();
}

export function vehicleReducer(
  state: VehicleState = initialState,
  action: VehicleAction
): VehicleState {
  switch (action.type) {
    case VehicleActionTypes.Reset:
      return Object.assign({}, initialState);

    case VehicleActionTypes.LookupVinYear:
      return Object.assign({}, state, {
        loading: true,
        status: null,
        hasError: false,
        criteria: {
          year: action.payload.year,
          vin: action.payload.vin
        },
        makes: [],
        models: [],
        styles: []
      });

    // TODO - add response data when shape is know
    case VehicleActionTypes.LookupVinYearSuccess:
      return Object.assign({}, state, {
        loading: false,
        hasError: false,
        newVehicle: action.payload.newVehicle,
        features: action.payload.features
      });

    case VehicleActionTypes.LookupVinYearFail:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: _get(action, 'payload.status', action.payload)
      });

    case VehicleActionTypes.GetMakes:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        criteria: {
          ...state.criteria,
          year: action.payload.year
        },
        makes: [],
        models: [],
        styles: []
      });

    case VehicleActionTypes.GetMakesSuccess:
      return Object.assign({}, state, {
        loading: false,
        makes: action.payload.makes
      });

    case VehicleActionTypes.GetMakesFail:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    case VehicleActionTypes.GetModels:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        criteria: {
          ...state.criteria,
          make: action.payload.make
        },
        models: [],
        styles: []
      });

    case VehicleActionTypes.GetModelsSuccess:
      return Object.assign({}, state, {
        loading: false,
        models: action.payload.models
      });

    case VehicleActionTypes.GetModelsFail:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    // This is going to be called Series or Styles - need spec.
    case VehicleActionTypes.GetStyles:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        criteria: {
          ...state.criteria,
          model: action.payload.model
        },
        styles: []
      });

    case VehicleActionTypes.GetStylesSuccess:
      return Object.assign({}, state, {
        loading: false,
        styles: action.payload.series
      });

    case VehicleActionTypes.GetStylesFail:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    case VehicleActionTypes.SetCriteria:
      return Object.assign({}, state, {
        criteria: Object.assign({}, state.criteria, {
          year: _get(action, 'payload.year', state.criteria.year),
          vin: _get(action, 'payload.vin', state.criteria.vin),
          odometer: _get(action, 'payload.odometer', state.criteria.odometer),
          make: _get(action, 'payload.make', state.criteria.make),
          series: _get(action, 'payload.series', state.criteria.series),
          model: _get(action, 'payload.model', state.criteria.model),
          underWritingQuestions: _get(
            action,
            'payload.underWritingQuestions',
            state.criteria.underWritingQuestions
          ),
          copyTPIIndicator: _get(
            action,
            'payload.copyTPIIndicator',
            state.criteria.copyTPIIndicator
          ),
          TPIName: _get(action, 'payload.TPIName', state.criteria.TPIName)
        }),
        newVehicle: Object.assign({}, state.newVehicle, {
          year: _get(action, 'payload.year', state.criteria.year),
          vin: _get(action, 'payload.vin', state.criteria.vin),
          odometer: _get(action, 'payload.odometer', state.criteria.odometer),
          make: _get(action, 'payload.make', _get(state, 'criteria.make', state?.newVehicle?.make)),
          series: _get(
            action,
            'payload.series',
            _get(state, 'criteria.series', state?.newVehicle?.series)
          ),
          model: _get(
            action,
            'payload.model',
            _get(state, 'criteria.model', state?.newVehicle?.model)
          )
        })
      });

    case VehicleActionTypes.SetTpi:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          TPIName: action.payload?.tpiName,
          copyTPIIndicator: action.payload?.copyTpiIndicator
        }
      };

    case VehicleActionTypes.SetVehiclePolicyChangeEntryPoint:
      return Object.assign({}, state, {
        loading: false,
        vehiclePolicyChangeEntryPoint: action.payload.entryPoint
      });

    default:
      return state;
  }
}

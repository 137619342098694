import { fromRouterActions } from '@amfam/shared/utility/navigation/src';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ds-underlying-policy-button',
  templateUrl: './underlying-policy-button.component.html',
  styleUrls: ['./underlying-policy-button.component.scss']
})
export class UnderlyingPolicyButtonComponent {
  @Input() productType: string;
  @Input() productName: string;
  @Input() iconType: string;
  @Input() policyNumber: string;

  constructor(private store: Store<any>) {}

  navigateToPolicy(): void {
    let linkProductType = this.productType;
    if (this.productType.toLowerCase() === 'homeowners') {
      linkProductType = 'property';
    } else if (this.productType.toLowerCase() === 'personalauto') {
      linkProductType = 'auto';
    }

    const path = `/policies/${linkProductType}/${this.policyNumber}`;
    this.store.dispatch(
      new fromRouterActions.Go({
        path: [path]
      })
    );
  }
}

<div *dsPartnerAFI class="agents-list-item">
  <div class="agent-headings">
    <h5>{{ agent.fullName }}</h5>
    <h6 *ngIf="agent.isAmericanStarCertified">
      <img src="assets/images/jd-power.png" alt="American Star Certified Agency" />American Star
      Certified Agency
    </h6>
  </div>
  <ul>
    <li
      class="agent-photo"
      [style.background-image]="agent.photoUrl ? 'url(' + agent.photoUrl + ')' : ''"
    >
      <span *ngIf="!agent.photoUrl" class="initials">{{ initials }}</span>
    </li>
    <li *ngIf="mainPhoneNumber || defaultContactNumber">
      <span tabindex="0" aria-label="Phone Number" class="pseudo-icon icon-call"></span>
      <a [href]="'tel:+' + mainPhoneNumber || defaultContactNumber">{{
        mainPhoneNumber ? (mainPhoneNumber | phoneNumberSingleStringFormatter) : defaultContactNumber
      }}</a>
    </li>
    <li *ngIf="mapUrl">
      <span class="pseudo-icon icon-find"></span>
      <a
        [href]="mapUrl"
        target="_blank"
        [attr.aria-label]="'Open Google Maps link to ' + mainOfficeAddress + ' in a new window.'"
        >Location</a
      >
    </li>
    <li *ngIf="agent.websiteUrl">
      <span class="pseudo-icon icon-agent"></span>
      <a [href]="agent.websiteUrl" target="_blank">Website</a>
    </li>
    <li *ngIf="emailUrl">
      <span class="pseudo-icon icon-email"></span> <a [href]="emailUrl" target="_blank">Email</a>
    </li>
  </ul>
</div>
<div *dsPartnerConnect class="agents-list-item partner-width">
  <div class="partner-headings"></div>
  <ul class="partner-height">
    <li>
      <span class="contact-us-placeholder"> <i class="icon-contact-us"></i></span>
    </li>
    <li>
      <span class="pseudo-icon icon-call"></span>
      <a
        dsPartnerContent
        [brandContent]="'getPrettyCusCareNumber'"
        [brandHref]="'getCusCareNumberFullHref'"
      ></a>
    </li>
    <li>
      <span class="pseudo-icon icon-email"></span>
      <a dsPartnerContent [brandHref]="'getCusCareEmailHref'">Email</a>
    </li>
  </ul>
</div>

import { AnalyticsActions } from '@amfam/shared/analytics';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { launchOverview, setPreferences } from '../+state/mae-feature.actions';
import { MaeAnalytics } from '../mae-constants';

@Component({
  selector: 'ds-create-account',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Output() createAccountEvent: EventEmitter<any> = new EventEmitter();

  confirmationFirstLineText;
  confirmationSecondLineText;

  constructor(
    private copyService: CopyService,
    private store: Store,
    private spinnerService: LoadingSpinnerService
  ) {}
  ngOnInit(): void {
    this.confirmationFirstLineText = this.copyService.getCopy(
      'enrollment.confirmationFirstLineText'
    );

    this.confirmationSecondLineText = this.copyService.getCopy(
      'enrollment.confirmationSecondLineText'
    );

    this.store.dispatch(
      AnalyticsActions.sendAnalytic({
        options: {
          page: MaeAnalytics.pageAgentProgramEnrollmentComplete
        }
      })
    );
    this.spinnerService.stop();
  }

  continue() {
    this.spinnerService.start();
    this.store.dispatch(setPreferences());
  }
  exit() {
    this.store.dispatch(launchOverview());
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMae from './mae.reducer';

export const selectMaeState = createFeatureSelector<fromMae.MaeState>(fromMae.maeFeatureKey);

export const getResendEmailSuccess = createSelector(selectMaeState, (state: fromMae.MaeState) => {
  return state.resendEmailSuccess;
});

export const getChangeEmailSuccess = createSelector(selectMaeState, (state: fromMae.MaeState) => {
  return state.changeEmailSuccess;
});

export const enrollmentHasError = createSelector(selectMaeState, fromMae.hasError);
export const enrollmentLoaded = createSelector(selectMaeState, fromMae.loaded);
export const enrollmentLoading = createSelector(selectMaeState, fromMae.loading);

export const maeQuery = {
  selectMaeState,
  getResendEmailSuccess,
  getChangeEmailSuccess,
  enrollmentHasError,
  enrollmentLoaded,
  enrollmentLoading
};

import { Injectable } from '@angular/core';

@Injectable()
export class FeatureDetectionService {
  detect() {
    // detect touch capability
    if (window && document && document.documentElement) {
      const documentHelper = new ElementHelper(document.documentElement);

      let wasTouch = false;
      window.addEventListener('touchstart', () => {
        wasTouch = true;
        documentHelper.addClass('touched');
      });
      window.addEventListener('mousedown', () => {
        if (wasTouch) {
          wasTouch = false;
        } else {
          documentHelper.removeClass('touched');
        }
      });
    }
  }
}

class ElementHelper {
  constructor(private element: HTMLElement) {}

  addClass(className: string) {
    // exclude empty strings
    if (!className || !/\S/.test(className)) {
      return;
    }
    className = className.trim();

    // test for existence
    const regex = new RegExp('(?:^|\\s)' + className + '(?:\\s|$)');
    if (regex.test(this.element.className)) {
      return;
    }

    // add a space if the element already has class
    if (this.element.className) {
      className = ' ' + className;
    }

    this.element.className += className;
  }

  removeClass(className: string) {
    // exclude empty strings
    if (!className || !/\S/.test(className)) {
      return;
    }
    className = className.trim();

    // regex must work with class names that include a dash
    const regex = new RegExp('(?:^|\\s)' + className + '(?:\\s|$)');
    this.element.className = this.element.className.replace(regex, '');
  }
}

/* eslint-disable max-len */

import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { DraftClaim, Incident, SelectOptions } from '@amfam/claim/fnol/data-access';

import { TitleCaseDashPipe } from '@amfam/shared/ui/pipes';
import { PrettyPolicyNum } from '@amfam/policy/pipes';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { PageTitleService, DockingBarService } from '@amfam/ui-kit';

import { Time24to12Pipe } from '@amfam/shared/ui/pipes';
import { FormData } from '../models';
import { ClaimsNavigationPaths } from '@amfam/shared/models';
import { RiskModel, Policy } from '@amfam/policy/models';

import {
  find as _find,
  isEmpty as _isEmpty,
  get as _get,
  has as _has,
  cloneDeep as _cloneDeep
} from 'lodash';
// Store
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { RouterState } from '@amfam/shared/utility/navigation';
import { impersonationQuery, ImpersonationState } from '@amfam/shared/utility/impersonation';

@Injectable({ providedIn: 'root' })
export class ReportClaimFnolService {
  private formData: FormData = new FormData();
  private isRiskSelectionFormValid = false;
  private isIncidentFormValid = false;
  private isLossCauseFormValid = false;
  public pageTitleName = 'File a Claim';
  private lossCauses: Array<SelectOptions> = this.copyService.get('claims', 'lossCauses');
  private collisionDetailCauses: Array<SelectOptions> = this.copyService.get(
    'claims',
    'collisionDetailCauses'
  );
  private weatherDetailCauses: Array<SelectOptions> = this.copyService.get(
    'claims',
    'weatherDetailCauses'
  );
  private theftDetailCauses: Array<SelectOptions> = this.copyService.get(
    'claims',
    'theftDetailCauses'
  );
  constructor(
    private routerStore: Store<RouterState>,
    private impersonationStore: Store<ImpersonationState>,
    private dockingBarService: DockingBarService,
    private pageTitle: PageTitleService,
    private copyService: CopyService
  ) {}

  getRiskSelection(): RiskModel {
    return this.formData.riskSelection;
  }

  setRiskSelection(data: RiskModel) {
    this.isRiskSelectionFormValid = true;
    this.formData.riskSelection = data;
  }

  getFormData(): FormData {
    // Return the entire Form Data
    return this.formData;
  }

  resetFormData(): FormData {
    // Reset the workflow
    // this.workflowService.resetSteps();
    // Return the form data after all this.* members had been reset
    this.formData.clear();
    this.isRiskSelectionFormValid = this.isIncidentFormValid = this.isLossCauseFormValid = false;
    return this.formData;
  }

  isFormValid() {
    // Return true if all forms had been validated successfully; otherwise, return false
    return this.isRiskSelectionFormValid && this.isIncidentFormValid && this.isLossCauseFormValid;
  }

  createUpdateDraftClaimLocationFactory(lossLocationDetails, draftClaim: DraftClaim) {
    return {
      fnol: {
        claimNumber: draftClaim.claimNumber,
        lossDate: draftClaim.lossDate,
        policyNumber: draftClaim.policyNumber,
        insured: draftClaim.insured,
        autoClaimDetail: draftClaim.autoClaimDetail,
        lossLocationAddress: {
          addr1: '',
          addr2: '',
          city: lossLocationDetails.incidentCity,
          state: lossLocationDetails.incidentState,
          zip5: ''
        },
        fnolDetail: {
          multipleMovingVehiclesIndicator: _get(
            draftClaim,
            'fnolDetail.multipleMovingVehiclesIndicator',
            {}
          ),
          typeOfLossCauseCode: _get(draftClaim, 'fnolDetail.typeOfLossCauseCode', {}),
          typeOfLossLocationCode: lossLocationDetails.lossLocationType,
          injuryIndicator: lossLocationDetails.lossInjuries === 'yes' ? true : false,
          typeOfFnolStatusCode: 'DRAFT',
          typeOfHowReportedCode: 'MyAccount',
          whatHappened: _get(draftClaim, 'fnolDetail.whatHappened', ''),
          typeOfStateJurisdictionCode: _get(
            draftClaim,
            'fnolDetail.typeOfStateJurisdictionCode',
            {}
          )
        }
      }
    };
  }

  createUpdateDraftClaimLossCauseFactory(
    lossCauseDetails,
    unMappedLossCause: string,
    draftClaim: DraftClaim
  ) {
    return {
      fnol: {
        claimNumber: draftClaim.claimNumber,
        lossDate: draftClaim.lossDate,
        policyNumber: draftClaim.policyNumber,
        insured: draftClaim.insured,
        autoclaimDetail: draftClaim.autoClaimDetail,
        lossLocationAddress: _get(draftClaim, 'lossLocationAddress', null),
        fnolDetail: {
          multipleMovingVehiclesIndicator:
            lossCauseDetails.collisionStationary === 'yes' ? true : false,
          typeOfLossCauseCode:
            !unMappedLossCause || 0 === unMappedLossCause.length
              ? this.transmuteLossCause(lossCauseDetails)
              : unMappedLossCause.toUpperCase(),
          typeOfLossLocationCode: draftClaim.fnolDetail.typeOfLossLocationCode,
          injuryIndicator: draftClaim.fnolDetail.injuryIndicator,
          typeOfFnolStatusCode: 'DRAFT',
          typeOfHowReportedCode: 'MyAccount',
          whatHappened: _get(draftClaim, 'fnolDetail.whatHappened', ''),
          typeOfStateJurisdictionCode: _get(
            draftClaim,
            'fnolDetail.typeOfStateJurisdictionCode',
            {}
          )
        }
      }
    };
  }

  createUpdateDraftClaimInfoFactory(lossInfoDetails, draftClaim: DraftClaim) {
    draftClaim.insured = Object.assign({}, draftClaim.insured, {
      phones: new Array(this.getInsuredPhoneNumber(lossInfoDetails.phoneNumber)),
      emails: new Array(this.getInsuredEmailAddress(lossInfoDetails.emailAddress))
    });

    return {
      fnol: {
        claimNumber: draftClaim.claimNumber,
        lossDate: draftClaim.lossDate,
        policyNumber: draftClaim.policyNumber,
        insured: draftClaim.insured,
        autoclaimDetail: draftClaim.autoClaimDetail,
        fnolDetail: {
          typeOfFnolStatusCode: 'DRAFT',
          typeOfHowReportedCode: 'MyAccount',
          whatHappened: lossInfoDetails.whatHappened,
          multipleMovingVehiclesIndicator: draftClaim.fnolDetail.multipleMovingVehiclesIndicator,
          typeOfLossCauseCode: draftClaim.fnolDetail.typeOfLossCauseCode,
          typeOfLossLocationCode: draftClaim.fnolDetail.typeOfLossLocationCode,
          typeOfStateJurisdictionCode: draftClaim.lossLocationAddress.state,
          injuryIndicator: draftClaim.fnolDetail.injuryIndicator
        },
        reportedBy: {
          typeOfReportedByRoleCode: 'Self',
          reportedBy: draftClaim.insured
        }
      }
    };
  }
  createSubmitDraftClaimFactory(draftClaim: DraftClaim): DraftClaim {
    draftClaim.insured = Object.assign({}, draftClaim.insured, {
      phones: draftClaim.reportedBy.reportedBy.phones,
      emails: draftClaim.reportedBy.reportedBy.emails
    });
    return {
      fnol: {
        claimNumber: draftClaim.claimNumber,
        policyNumber: draftClaim.policyNumber,
        lossDate: draftClaim.lossDate,
        claimProgress: 'Submission',
        insured: draftClaim.insured,
        reportedBy: {
          typeOfReportedByRoleCode: 'Self',
          reportedBy: draftClaim.insured
        },
        lossLocationAddress: draftClaim.lossLocationAddress,
        autoClaimDetail: draftClaim.autoClaimDetail,
        fnolDetail: draftClaim.fnolDetail
      }
    };
  }

  private transmuteLossCause(lossCauseDetails): string {
    switch (lossCauseDetails.lossCause) {
      case 'COLLISION':
        switch (lossCauseDetails.collisionLoss) {
          case 'HIT AN ANIMAL':
          case 'BACKED':
          case 'HIT WHILE TURNING':
          case 'OTHER':
          case 'REAR END':
            return lossCauseDetails.lossCause + ' ' + lossCauseDetails.collisionLoss;
        }
        break;
      case 'WEATHER':
        switch (lossCauseDetails.weatherLoss) {
          case 'HAIL':
          case 'OTHER':
            return lossCauseDetails.lossCause + ' ' + lossCauseDetails.weatherLoss;
        }
        break;
      case 'THEFT':
        switch (lossCauseDetails.theftLoss) {
          case 'VEHICLE BOAT STOLEN':
          case 'BOAT PARTS':
          case 'CONTENTS':
          case 'VANDALISM':
            return lossCauseDetails.lossCause + ' ' + lossCauseDetails.theftLoss;
        }
        break;
      default:
        return lossCauseDetails.lossCause;
    }
  }

  /**
   * @param input : will be in API format , deriving it into user-friendly terms.
   */
  public getFormattedLossCause(input: string): Incident {
    const incident: any = {};
    // Split the string.
    const lossCauseCode = input.split(' ');
    // Extracting first element helps in understanding there will be detail loss cause too.
    const lossCause = lossCauseCode[0];
    // Join rest of the elements in the string to derive detail loss cause value(Please go through switch cases).
    const detailLossCause = lossCauseCode.splice(1).join(' ');
    // Some loss causes will be added by CC, default to those if we don't have in our list.
    const isExistingLossCause = _find(this.lossCauses, { value: lossCause });
    incident.cause = _isEmpty(isExistingLossCause)
      ? new TitleCaseDashPipe().transform(String(input))
      : isExistingLossCause.name;
    switch (lossCause) {
      case 'COLLISION':
        const collisionCause = _find(this.collisionDetailCauses, {
          value: detailLossCause
        });
        const collisionDetail = collisionCause ? collisionCause.name : null;
        incident.detail =
          !collisionDetail || 0 === collisionDetail.length ? detailLossCause : collisionDetail;
        break;
      case 'THEFT':
        const theftCause = _find(this.theftDetailCauses, {
          value: detailLossCause
        });
        const theftDetail = theftCause ? theftCause.name : null;
        incident.detail = !theftDetail || 0 === theftDetail.length ? detailLossCause : theftDetail;
        break;
      case 'WEATHER':
        const weatherCause = _find(this.weatherDetailCauses, {
          value: detailLossCause
        });
        const weatherDetail = weatherCause ? weatherCause.name : null;
        incident.detail =
          !weatherDetail || 0 === weatherDetail.length ? detailLossCause : weatherDetail;
        break;
      default:
        break;
    }
    return incident;
  }

  setClaimsPageTitle(policy: Policy) {
    const fullPageTitle = 'File a Claim for Policy #: ' + new PrettyPolicyNum().transform(policy);

    if (policy && policy.policyNumber && this.pageTitle.get() !== fullPageTitle) {
      this.pageTitleName = fullPageTitle;
    }

    this.pageTitle.set(this.pageTitleName);
    this.dockingBarService.registerHeading(this.pageTitleName);
  }

  /**
   * Use Case: Walkthrough fnol form without making API calls.
   * Derive user is impersonatot and  has specified(fnolform_walkthrough) UI element.
   */
  public isImpersonating(): boolean {
    let isAuthorizedImpersonator = false;
    combineLatest(
      this.impersonationStore.select(impersonationQuery.isImpersonating),
      this.impersonationStore.select(impersonationQuery.permissions),
      (impersonating, impPermissions) => {
        return {
          impersonating: impersonating,
          permissions: impPermissions
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        isAuthorizedImpersonator =
          state.impersonating &&
          state.permissions.find(permission => permission === 'fnolform_walkthrough') != null;
      });
    return isAuthorizedImpersonator;
  }

  private getInsuredPhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(/-/g, '');
      if (phoneNumber.length === 10) {
        return {
          phoneType: 'Mobile',
          areaCode: phoneNumber.slice(0, 3),
          number: phoneNumber.slice(3, 10)
        };
      }
    }
  }

  private getInsuredEmailAddress(emailAddress: string) {
    if (emailAddress) {
      return {
        emailType: 'Other',
        emailAddress: emailAddress
      };
    }
  }

  public getRoutingPath(matchingFnol: DraftClaim): void {
    if (_has(matchingFnol, 'lossDate') && _get(matchingFnol, 'lossDate')) {
      const lossDate = new Time24to12Pipe().transform(matchingFnol.lossDate);
      if (!lossDate) {
        this.routerStore.dispatch(
          new fromRouterActions.Go({
            path: [ClaimsNavigationPaths.CLAIM_INCIDENT_TIME_PATH]
          })
        );
      } else if (!_get(matchingFnol, 'fnolDetail.typeOfLossLocationCode')) {
        this.routerStore.dispatch(
          new fromRouterActions.Go({
            path: [ClaimsNavigationPaths.CLAIM_INCIDENT_LOCATION_PATH]
          })
        );
      } else if (
        _get(matchingFnol, 'fnolDetail.typeOfLossLocationCode') &&
        !_get(matchingFnol, 'fnolDetail.typeOfLossCauseCode')
      ) {
        this.routerStore.dispatch(
          new fromRouterActions.Go({
            path: [ClaimsNavigationPaths.CLAIM_CAUSE_PATH]
          })
        );
      } else if (
        _get(matchingFnol, 'fnolDetail.typeOfLossCauseCode') &&
        !_get(matchingFnol, 'fnolDetail.whatHappened')
      ) {
        this.routerStore.dispatch(
          new fromRouterActions.Go({
            path: [ClaimsNavigationPaths.CLAIM_CONTACT]
          })
        );
      } else if (_get(matchingFnol, 'fnolDetail.whatHappened')) {
        this.routerStore.dispatch(
          new fromRouterActions.Go({
            path: [ClaimsNavigationPaths.CLAIM_SUMMARY]
          })
        );
      }
    }
  }
}

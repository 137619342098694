import { AnalyticsFacade } from '@amfam/shared/analytics';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { LoadingSpinnerService } from '@amfam/ui-kit';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ForgotPasswordAnalytics } from '../../shared/forgot-password-analytic-constants';

import { BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import * as fromRoot from '../../../../core/store';

@Component({
  selector: 'ds-reset-password-options',
  templateUrl: './reset-password-options.component.html',
  styleUrls: ['../../forgot-password.component.scss']
})
export class ResetPasswordOptionsComponent implements OnInit, OnDestroy {
  currentRoute = '/forgot-password/reset-options';
  userIdentifier: string;
  emailEnabled = false;
  textEnabled = false;
  callEnabled = false;
  securityQuestionsEnabled = false;
  maskedEmail: string;
  maskedPhoneNumbers: string[];
  securityQuestions: string[];
  verifyMethodLocked = false;
  private stop$ = new Subject<void>();
  isDuplicateEmailError = false;
  constructor(
    private store: Store<fromRoot.RootState>,
    private featureFlag: FeatureFlagService,
    private analyticsFacade: AnalyticsFacade,
    private spinner: LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.start();

    combineLatest(
      this.store.select(fromRoot.getResetPasswordState),
      this.store.select(BrandSelectors.getIsPartner),
      (resetPasswordState, isPartner) => {
        return { resetPasswordState, isPartner };
      }
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.spinner.stop();
        if (!state.isPartner) {
          this.isDuplicateEmailError = !!_get(state, 'resetPasswordState.hasError');
        }
        this.userIdentifier = state.resetPasswordState.userIdentifier;
        this.securityQuestions = state.resetPasswordState.securityQuestions;
        this.securityQuestionsEnabled = !!this.securityQuestions;
        this.maskedEmail = state.resetPasswordState.userDetails.maskedEmailAddress;
        this.emailEnabled = !!this.maskedEmail;
        this.maskedPhoneNumbers = state.resetPasswordState.userDetails.maskedPhoneNumbers;
        this.callEnabled = this.maskedPhoneNumbers.length && !state.isPartner;
        this.textEnabled = this.maskedPhoneNumbers.length && !state.isPartner;
        this.verifyMethodLocked = state.resetPasswordState.verifyMethodLocked;
        this.analyticsFacade.trackPage(ForgotPasswordAnalytics.forgotPasswordMultipleEmailError);
        if (this.verifyMethodLocked) {
          this.analyticsFacade.trackPage(
            ForgotPasswordAnalytics.pageForgotPasswordMultipleResetAttempts
          );
        } else {
          this.analyticsFacade.trackPage(ForgotPasswordAnalytics.pageForgotPasswordResetOptions);
        }
      });
  }

  goBackOneStep() {
    this.store.dispatch(
      new fromRouterActions.Go({
        path: ['/forgot-password']
      })
    );
  }

  ngOnDestroy() {
    this.spinner.stop();
  }
}

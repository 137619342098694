import { Injectable } from '@angular/core';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { PaymentRecordRequest, PaymentRecordResponse } from '../models/payment-capture.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentaccountService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  public updateScreenRecording(payload: PaymentRecordRequest): Observable<PaymentRecordResponse> {
    const recordUri = this.config.get('i3Api') + 'RecordingProxy';
    return this.http.post<PaymentRecordResponse>(recordUri, payload);
  }
}

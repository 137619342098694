import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'amfam-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent {
  @Input() text: string;
  @Input() heading: string;
  @Input() showCloseButton: boolean;
  @Output() close = new EventEmitter<any>();
}

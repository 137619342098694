import { NgModule } from '@angular/core';

import { PartnerContentDirective } from './directives/partner-content.directive';
import { PartnerConnectDirective } from './directives/partner-connect.directive';
import { PartnerGenericHideDirective } from './directives/partner-generic-hide.directive';
import { PartnerShowDirective } from './directives/partner-show.directive';
import { PartnerAFIDirective } from './directives/partner-afi.directive';

@NgModule({
  imports: [],
  declarations: [
    PartnerContentDirective,
    PartnerConnectDirective,
    PartnerGenericHideDirective,
    PartnerShowDirective,
    PartnerAFIDirective
  ],
  exports: [
    PartnerContentDirective,
    PartnerConnectDirective,
    PartnerGenericHideDirective,
    PartnerShowDirective,
    PartnerAFIDirective
  ]
})
export class BrandDirectivesModule {}

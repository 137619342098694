<div>
  <button (click)="learnMoreEvent.emit(recommendationId)" class="rmv-decorate" data-cy="opportunityCard"
    [disabled]="!isAuthorized">
    <img alt="{{ opportunityCardInformation?.title }} Image" [src]="opportunityCardInformation?.bodyImage"
      class="opportunity-card" />
    <div>
      <div class="opportunity-title" [innerHTML]="opportunityCardInformation?.title" data-cy="opportunityCardTitle">
      </div>
    </div>
  </button>
</div>

import { Injectable } from '@angular/core';
import { get as _get } from 'lodash';
import {
  CheckEmailAddressResponse,
  DigitalAccount,
  GetDigitalAccountByCdhIdResponse,
  GetDigitalAccountByWaidResponse
} from '../../+state/digital-account/digital-account.model';
import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root'
})
export class DigitalAccountAdapter implements Adapter<DigitalAccount> {
  constructor() {}

  adaptGetDigitalAccountByCdhId(
    response: GetDigitalAccountByCdhIdResponse,
    cdhId: string
  ): DigitalAccount {
    // could not find account, return inital State
    if (_get(response, 'status.code') === 404) {
      return null;
    }

    return {
      shellAccount: _get(response, 'shellAccountIndicator', false),
      enrollmentRequest: _get(response, 'enrollmentRequestIndicator', false),
      verificationInProgress: _get(response, 'verificationInProgressIndicator', false),
      maskedEmailAddress: _get(response, 'maskedEmailAddress', ''),
      maskedPhoneNumbers: _get(response, 'maskedPhoneNumbers', []),
      waid: _get(response, 'webAccessId', ''),
      emailAddress: _get(response, 'emailAddress', ''),
      customerId: cdhId
    };
  }

  adaptGetDigitalAccountByWaid(response: GetDigitalAccountByWaidResponse): DigitalAccount {
    return {
      waid: _get(response, 'digitalAccount.waid', _get(response, 'shellAccount.waid', '')),
      customerId: _get(
        response,
        'digitalAccount.customerId',
        _get(response, 'shellAccount.customerId', '')
      ),
      userId: _get(response, 'digitalAccount.userId', ''),
      userIdLastUpdatedOn: _get(response, 'digitalAccount.userIdLastUpdatedOn', ''),
      partnerId: _get(
        response,
        'digitalAccount.partnerId',
        _get(response, 'shellAccount.partnerId', '')
      ),
      typeOfAccountCode: _get(
        response,
        'digitalAccount.typeOfAccountCode',
        _get(response, 'shellAccount.typeOfAccountCode', '')
      ),
      emailAddress: _get(
        response,
        'digitalAccount.emailAddress',
        _get(response, 'shellAccount.emailAddress', '')
      ),
      securityQuestions: _get(response, 'digitalAccount.securityQuestions', []),
      securityQuestionsLastUpdatedOn: _get(
        response,
        'digitalAccount.securityQuestionsLastUpdatedOn',
        ''
      ),
      nameDetail: _get(
        response,
        'digitalAccount.nameDetail',
        _get(response, 'shellAccount.nameDetail', '')
      ),
      shellAccount: _get(response, 'shellAccount', false)
    };
  }
  adaptCheckEmailAddress(
    response: CheckEmailAddressResponse,
    correlationId: string
  ): CheckEmailAddressResponse {
    return {
      emailDetail: _get(response, 'emailDetail', null),
      correlationId: correlationId,
      status: _get(response, 'status', null)
    };
  }
}

<div class="column twelve" [formGroup]="parentFormGroup" *ngIf="options.length > 0">
  <!--This naming convention is used to grab each FormControl from the lower level FormArray-->
  <div *ngFor="let option of parentFormGroup.controls[controlName]['controls']; let i = index">
    <div
      class="question items-center"
      [ngStyle]="{ display: displayClass }"
      [ngClass]="{
        selected: option.value,
        disabled: option.disabled
      }"
      (click)="toggleDynamicOption(option, options[i].id, options[i])"
    >
      <input
        [formControl]="option"
        [value]="option.value"
        [disabled]="option.disabled"
        [attr.id]="options[i].id"
        [attr.aria-labelledby]="'label-' + options[i].id"
        class="checkbox"
        type="checkbox"
        [checked]="option.value"
      />
      <label
        data-cy="checkBoxGroupLabel"
        class="checkbox-label"
        [attr.id]="'label-' + options[i].id"
        [attr.aria-label]="options[i].text"
        [attr.for]="'options[i].id'"
        >{{ options[i].text }}</label
      >
      <ng-container *ngIf="options[i]?.helpText && options[i]?.helpText.length > 0">
        <div
          *ngFor="let helpText of options[i]?.helpText"
          class="flex row caption"
          [ngClass]="{ warning: helpText.warning }"
        >
          {{ helpText.text }}
        </div>
      </ng-container>
    </div>
  </div>
</div>

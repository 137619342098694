import { Injectable } from '@angular/core';
import { some as _some, find as _find } from 'lodash';
import { ScheduledPayment } from '@amfam/shared/models';

@Injectable({
  providedIn: 'root'
})
export class PaymentAccountTransmuteService {
  constructor() {}

  // SK: This method returns whether payment is scheduled or not for BA# while add/edit autopay.
  public getScheduledPaymentStatus(
    scheduledPayments: ScheduledPayment[],
    billAccountNumberList: string[]
  ): boolean {
    let hasScheduledPayment = false;
    for (const scheduledPayment of scheduledPayments) {
      for (const ba of scheduledPayment.billAccounts) {
        hasScheduledPayment = !!_find(
          billAccountNumberList,
          billaccountNumberItem => billaccountNumberItem === ba.billAccountNumber
        );
        if (hasScheduledPayment) {
          return hasScheduledPayment;
        }
      }
    }
    return hasScheduledPayment;
  }
}

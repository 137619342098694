import { createSelector } from '@ngrx/store';
import { DigitalAccountFeatureState, getDigitalAccountFeatureState } from '../index';
import { DigitalAccountState } from './digital-account.reducer';
import { ErrorObj, ErrorLevels } from '@amfam/ui-kit';
import { get as _get } from 'lodash';

export const getDigitalAccountState = createSelector(
  getDigitalAccountFeatureState,
  (state: DigitalAccountFeatureState) => state.digitalAccount
);

export const getLoading = createSelector(
  getDigitalAccountState,
  (state: DigitalAccountState) => state.loading
);

export const getLoaded = createSelector(
  getDigitalAccountState,
  (state: DigitalAccountState) => state.loaded
);

export const hasError = createSelector(
  getDigitalAccountState,
  (state: DigitalAccountState) => state.hasError
);

const getErrorMessages = createSelector(getDigitalAccountState, (state: DigitalAccountState) => {
  const errorObject: Array<ErrorObj> = [
    {
      errorMessage: state.error,
      errorLevel: ErrorLevels.HIGH
    }
  ];
  return errorObject;
});

export const getDigitalAccount = createSelector(
  getDigitalAccountState,
  (state: DigitalAccountState) => state.digitalAccount
);

export const getWaid = createSelector(getDigitalAccountState, (state: DigitalAccountState) =>
  _get(state, 'digitalAccount.waid', '')
);

export const isShellAccount = createSelector(getDigitalAccountState, (state: DigitalAccountState) =>
  _get(state, 'digitalAccount.shellAccount', '')
);

export const digitalAccountQuery = {
  getDigitalAccountState,
  getLoading,
  getLoaded,
  hasError,
  getDigitalAccount,
  getWaid,
  isShellAccount,
  getErrorMessages
};

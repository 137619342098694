import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AutoPaySetup, AutoPaySteps } from './auto-pay-setup.models';
import * as fromAutoPaySetup from './auto-pay-setup.reducer';

export const getAutoPaySetupState = createFeatureSelector<fromAutoPaySetup.State>(
  fromAutoPaySetup.autoPaySetupFeatureKey
);

export const getActiveStep = createSelector(
  getAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.activeStep
);

export const getEntryPoint = createSelector(
  getAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.entryPoint
);

export const getContext = createSelector(
  getAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.context
);

export const getPreselectedAccounts = createSelector(
  getAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.preselectedAccounts
);

export const getAllAutoPaySetups = createSelector(
  getAutoPaySetupState,
  fromAutoPaySetup.selectAllAutoPaySetups
);

export const isUserComingFromEnrollment = createSelector(
  getAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.isUserComingFromEnrollment
);

export const hasRegistrationError = createSelector(
  getAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.registrationError
);

export const getActiveSetup = createSelector(getAllAutoPaySetups, (setups: AutoPaySetup[]) => {
  // find bill account that is not set up yet
  const activeSetup: AutoPaySetup = setups.find(setup => setup.active);
  return activeSetup;
});

export const getPreviousSetup = createSelector(
  getActiveStep,
  getAllAutoPaySetups,
  (activeStep: AutoPaySteps, setups: AutoPaySetup[]) => {
    if (activeStep === AutoPaySteps.REVIEW) {
      return setups[setups.length - 1];
    } else {
      const activeSetupIndex: number = setups.findIndex(setup => setup.active);
      return activeSetupIndex > 0 ? setups[activeSetupIndex - 1] : null;
    }
  }
);

export const getNextSetup = createSelector(
  getActiveStep,
  getAllAutoPaySetups,
  (activeStep: AutoPaySteps, setups: AutoPaySetup[]) => {
    if (activeStep === AutoPaySteps.SELECT_ACCOUNTS) {
      return setups[0];
    } else {
      const activeSetupIndex: number = setups.findIndex(setup => setup.active);
      return setups.length > 0 && activeSetupIndex < setups.length - 1
        ? setups[activeSetupIndex + 1]
        : null;
    }
  }
);

<li [class.open]="isOpen" #scrollTarget [class.thinner-border]="htmlTitle">
  <button
    type="button"
    [class.pseudo-icon]="showIcon"
    [class.psudo-icon.new]="showIcon"
    class="category-toggle flex pad-left-0"
    [class.closed]="!isOpen"
    [class.outline]="showOutline"
    [ngClass]="{ 'button-gradient-text': htmlTitle, 'button-padding': htmlTitle, pad: !htmlTitle }"
    (click)="handleToggleClick()"
    (keyup.tab)="enableOutline()"
    (mousedown)="disableOutline()"
    (mouseup)="disableOutline()"
    (blur)="disableOutline()"
    [attr.aria-expanded]="isOpen"
    [attr.aria-controls]="uniqueContentId"
    [attr.id]="uniqueLabelId"
    [attr.aria-label]="title"
  >
    <div
      class="heading-wrapper"
      role="region"
      [attr.id]="uniqueContentId"
      [attr.aria-labelledby]="uniqueLabelId"
      [class.new-template-padding]="htmlTitle"
    >
      <span *ngIf="icon" class="icon-{{ icon }}"></span>
      <span [innerHTML]="title" class="heading-sub-page"></span>
      <div *ngIf="htmlTitle">
        <ng-content select="[title]"></ng-content>
      </div>
    </div>

    <amfam-badge class="badge-accordion" *ngIf="showNewBadge" [labelName]="'New'"></amfam-badge>
  </button>
  <div class="content" [class.new-template-padding]="htmlTitle" [@openClose]="isOpen">
    <ng-content></ng-content>
  </div>
</li>

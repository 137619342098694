import { Policy, PolicySummary } from '@amfam/policy/models';
import { Action } from '@ngrx/store';

export enum PoliciesActionTypes {
  LoadPolicies = '[Policies] Policies Load',
  PoliciesLoadSuccess = '[Policies] Policies Load Success',
  PoliciesRawLoadSuccess = '[Policies] Policies Raw Load Success',
  PoliciesLoadComplete = '[Policies] Policies Load Complete',
  PoliciesLoadError = '[Policies] Policies Load Error',

  LoadOnePolicy = '[Policies] Load One Policy',
  PolicyLoadSuccess = '[Policies] Policy Load Success',
  PolicyLoadError = '[Policies] Policy Load Error',

  PoliciesSetSelected = '[Policies] Policies Set Selected',
  PoliciesSetSelectedSuccess = '[Policies] Policies Set Selected Success',
  PoliciesSetSelectedError = '[Policies] Policies Set Selected Error'
}

export class LoadPolicies implements Action {
  readonly type = PoliciesActionTypes.LoadPolicies;
  constructor(public payload: PolicySummary[]) {}
}

export class PoliciesLoadError implements Action {
  readonly type = PoliciesActionTypes.PoliciesLoadError;
  constructor(public payload?: Policy[]) {}
}

export class PoliciesLoadSuccess implements Action {
  readonly type = PoliciesActionTypes.PoliciesLoadSuccess;
  constructor(public payload: Policy[]) {}
}

export class PoliciesRawLoadSuccess implements Action {
  readonly type = PoliciesActionTypes.PoliciesRawLoadSuccess;
  constructor(public payload?: Policy[]) {}
}

export class PoliciesLoadComplete implements Action {
  readonly type = PoliciesActionTypes.PoliciesLoadComplete;
  constructor(public payload?: any) {}
}

export class LoadOnePolicy implements Action {
  readonly type = PoliciesActionTypes.LoadOnePolicy;
  constructor(public payload: any) {}
}

export class PolicyLoadError implements Action {
  readonly type = PoliciesActionTypes.PolicyLoadError;
  constructor(public payload?: any) {}
}

export class PolicyLoadSuccess implements Action {
  readonly type = PoliciesActionTypes.PolicyLoadSuccess;
  constructor(public payload: any) {}
}

export class PoliciesSetSelected implements Action {
  readonly type = PoliciesActionTypes.PoliciesSetSelected;
  constructor(public payload: string) {}
}

export class PoliciesSetSelectedSuccess implements Action {
  readonly type = PoliciesActionTypes.PoliciesSetSelectedSuccess;
  constructor(public payload?: any) {}
}

export class PoliciesSetSelectedError implements Action {
  readonly type = PoliciesActionTypes.PoliciesSetSelectedError;
  constructor(public payload: any) {}
}

export type PoliciesAction =
  | LoadPolicies
  | PoliciesLoadSuccess
  | PoliciesRawLoadSuccess
  | PoliciesLoadError
  | PoliciesLoadComplete
  | LoadOnePolicy
  | PolicyLoadSuccess
  | PolicyLoadError
  | PoliciesSetSelected
  | PoliciesSetSelectedSuccess
  | PoliciesSetSelectedError;

export const fromPoliciesActions = {
  LoadPolicies,
  PoliciesLoadSuccess,
  PoliciesRawLoadSuccess,
  PoliciesLoadError,
  PoliciesLoadComplete,
  PoliciesSetSelected,
  PoliciesSetSelectedSuccess,
  PoliciesSetSelectedError,
  LoadOnePolicy,
  PolicyLoadSuccess,
  PolicyLoadError
};

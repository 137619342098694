export class Surcharge {
  public code: string;
  public name: string;
  public description: string;
  public amount: string;
  public value: string;
  public valueType: string;

  static fromJson(json: any): Surcharge {
    const surcharge = new Surcharge();
    surcharge.code = json.code;
    surcharge.name = json.name;
    surcharge.description = json.description;
    surcharge.amount = json.amount;
    surcharge.value = json.value;
    surcharge.valueType = json.valueType;

    return surcharge;
  }
}

<div
  [attr.data-header]="column.name"
  [ngClass]="{ 'no-label': column.isHeaderOnMobile }"
  class="ds-table-body-cell-label"
>
  <span *ngIf="!column.cellTemplate" [title]="rowName" [innerHTML]="rowName"> </span>
  <ng-template
    *ngIf="column?.cellTemplate"
    [ngTemplateOutlet]="column.cellTemplate"
    [ngTemplateOutletContext]="{ value: rowName, row: row, column: column }"
  >
  </ng-template>
</div>

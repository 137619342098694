import { LoadingSpinnerService } from '@amfam/ui-kit/src';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AgentInfo, OpportunitiesAgentService } from './opportunities-agent.service';

@Component({
  selector: 'amfam-opportunities-agent',
  templateUrl: './opportunities-agent.component.html',
  styleUrls: ['./opportunities-agent.component.scss']
})
export class OpportunitiesAgentComponent implements OnInit, OnDestroy {
  agentId: string;
  opportunityType: string;
  agents$: Observable<AgentInfo[]> = this.opportunitiesAgentService.getAgents().pipe(
    tap(agents => {
      if (agents.length === 1) this.agentId = agents[0].agentId;
    })
  );

  constructor(
    private opportunitiesAgentService: OpportunitiesAgentService,
    private activatedRoute: ActivatedRoute,
    private spinnerService: LoadingSpinnerService
  ) {}

  ngOnInit(): void {
    this.opportunityType = this.activatedRoute.snapshot.paramMap.get('id');
    this.opportunitiesAgentService.setDockingbarHeading();
    this.opportunitiesAgentService.sendPageAnalytics(this.opportunityType);
  }

  onAgentSelected(agentId: string) {
    this.agentId = agentId;
  }

  sendToAgent() {
    this.spinnerService.start({ blockActions: true });
    this.opportunitiesAgentService.sendToAgent(this.agentId, this.opportunityType);
  }

  backToMyAccount() {
    this.opportunitiesAgentService.backToMyaccount(this.opportunityType);
  }

  ngOnDestroy(): void {
    this.spinnerService.stop();
  }
}

import {
  DeletePaymentAccountSuccessModel,
  DeleteScheduledPaymentFailureModel,
  DeleteScheduledPaymentSuccessModel,
  EditScheduledPaymentSuccessModel,
  ScheduledPayment,
  SchedulePaymentPayloadModel,
  SubmitScheduledPaymentFailureModel,
  SubmitScheduledPaymentPayloadModel
} from '@amfam/shared/models';
import { Action } from '@ngrx/store';

export enum ScheduledPaymentsActionTypes {
  ScheduledPaymentsLoad = '[ScheduledPayments] Load',
  ScheduledPaymentsLoadSuccess = '[ScheduledPayments] Load Success',
  ScheduledPaymentsLoadFail = '[ScheduledPayments] Load Fail',

  ScheduledPaymentsSubmit = '[ScheduledPayments] Submit',
  ScheduledPaymentsSubmitSuccess = '[ScheduledPayments] Submit Success',
  ScheduledPaymentsSubmitFail = '[ScheduledPayments] Submit Fail',

  ScheduledPaymentsEdit = '[ScheduledPayments] Edit',
  ScheduledPaymentsEditSuccess = '[ScheduledPayments] Edit Success',
  ScheduledPaymentsEditFail = '[ScheduledPayments] Edit Fail',

  ScheduledPaymentsDelete = '[ScheduledPayments] Delete',
  ScheduledPaymentsDeleteSuccess = '[ScheduledPayments] Delete Success',
  ScheduledPaymentsDeleteFail = '[ScheduledPayments] Delete Fail',

  ScheduledPaymentsRemove = '[ScheduledPayments] Remove',
  ScheduledPaymentsUpdate = '[ScheduledPayments] Update',
  ScheduledPaymentsCleanup = '[ScheduledPayments] Cleanup',
  ScheduledPaymentsTruncate = '[ScheduledPayments] Truncate'
}

export class ScheduledPaymentsLoad implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsLoad;
  constructor(public payload?: any) {}
}

export class ScheduledPaymentsLoadSuccess implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsLoadSuccess;
  constructor(public payload: ScheduledPayment[]) {}
}

export class ScheduledPaymentsLoadFail implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsLoadFail;
  constructor(public payload?: any) {}
}

export class ScheduledPaymentsSubmit implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsSubmit;
  constructor(public payload: SubmitScheduledPaymentPayloadModel) {}
}

export class ScheduledPaymentsSubmitSuccess implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitSuccess;
  constructor(public payload: any) {}
}

export class ScheduledPaymentsSubmitFail implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitFail;
  constructor(public payload: SubmitScheduledPaymentFailureModel) {}
}

export class ScheduledPaymentsEdit implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsEdit;
  constructor(public payload: SchedulePaymentPayloadModel) {}
}

export class ScheduledPaymentsEditSuccess implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsEditSuccess;
  constructor(public payload: EditScheduledPaymentSuccessModel) {}
}

export class ScheduledPaymentsEditFail implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsEditFail;
  constructor(public payload: DeleteScheduledPaymentFailureModel) {}
}

export class ScheduledPaymentsDelete implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsDelete;
  constructor(public payload: SchedulePaymentPayloadModel) {}
}

export class ScheduledPaymentsDeleteSuccess implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteSuccess;
  constructor(public payload: DeleteScheduledPaymentSuccessModel) {}
}

export class ScheduledPaymentsDeleteFail implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteFail;
  constructor(public payload: DeleteScheduledPaymentFailureModel) {}
}

export class ScheduledPaymentsRemove implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsRemove;
  constructor(public payload: DeletePaymentAccountSuccessModel) {}
}

export class ScheduledPaymentsUpdate implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsUpdate;
  constructor(public payload: { newNickName: string; oldNickName: string }) {}
}

export class ScheduledPaymentsCleanup implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsCleanup;
  constructor(public payload: string) {} // paymentId
}

export class ScheduledPaymentsTruncate implements Action {
  readonly type = ScheduledPaymentsActionTypes.ScheduledPaymentsTruncate;
  constructor(public payload: string) {} // paymentId
}

export type ScheduledPaymentsActions =
  | ScheduledPaymentsLoad
  | ScheduledPaymentsLoadSuccess
  | ScheduledPaymentsLoadFail
  | ScheduledPaymentsCleanup
  | ScheduledPaymentsSubmit
  | ScheduledPaymentsSubmitSuccess
  | ScheduledPaymentsSubmitFail
  | ScheduledPaymentsEdit
  | ScheduledPaymentsEditSuccess
  | ScheduledPaymentsEditFail
  | ScheduledPaymentsDelete
  | ScheduledPaymentsDeleteSuccess
  | ScheduledPaymentsDeleteFail
  | ScheduledPaymentsUpdate
  | ScheduledPaymentsRemove
  | ScheduledPaymentsTruncate;

export const fromScheduledPaymentsActions = {
  ScheduledPaymentsLoad,
  ScheduledPaymentsLoadSuccess,
  ScheduledPaymentsLoadFail,
  ScheduledPaymentsCleanup,
  ScheduledPaymentsSubmit,
  ScheduledPaymentsSubmitSuccess,
  ScheduledPaymentsSubmitFail,
  ScheduledPaymentsEdit,
  ScheduledPaymentsEditSuccess,
  ScheduledPaymentsEditFail,
  ScheduledPaymentsDelete,
  ScheduledPaymentsDeleteSuccess,
  ScheduledPaymentsDeleteFail,
  ScheduledPaymentsUpdate,
  ScheduledPaymentsRemove,
  ScheduledPaymentsTruncate
};

import { createSelector } from '@ngrx/store';

import { MaeStatus } from './models';
import * as maeStatus from './mae-status.actions';
import { Entities, initialEntities } from '../entity/entity.model';

import { keyBy as _keyBy, values as _values } from 'lodash';

export function reducer(
  state = initialEntities<MaeStatus>(),
  action: maeStatus.Actions
): Entities<MaeStatus> {
  let entities = {};

  switch (action.type) {
    case maeStatus.LOAD_SUCCESS:
      entities = _keyBy([action.payload], (status: MaeStatus) => status.customerId);
      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities,
        loaded: true,
        loading: false
      });
    case maeStatus.LOAD_FAIL:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload
      });
    default:
      return state;
  }
}

export const getEntities = (state: Entities<MaeStatus>) => state.entities;

export const getIds = (state: Entities<MaeStatus>) => state.ids;

export const getError = (state: Entities<MaeStatus>) => state.error;

export const getLoaded = (state: Entities<MaeStatus>) => state.loaded;

export const getLoading = (state: Entities<MaeStatus>) => state.loading;

export interface ApiMessage {
  level: string;
  description: string;
}

export interface ApiStatus {
  customerFeedbackCode?: string;
  maxMessageLevel?: string;
  code: number;
  reason: string;
  messages?: Array<ApiMessage>;
  transactionId: string;
  partialStatuses?: PartialStatus[];
  errors?: string[];
}

export const initialApiStatus: ApiStatus = {
  maxMessageLevel: '',
  code: 0,
  reason: '',
  messages: [],
  transactionId: ''
};

export interface PartialStatus {
  action: string;
  payloadEntityReference: string;
  status: ApiStatus;
}

export interface FeedbackStatus {
  recommendationId: string;
  error: ApiStatus | null;
  success: ApiStatus | null;
  loading: boolean;
  isMultiAgent: false;
  type?: string;
  customerFeedbackCode?: string;
}

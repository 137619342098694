import { KydSmartphoneEnrollmentOperator } from './kyd-smartphone-enrollment-operator.model';
import { UBI_PROGRAM } from './kyd-program-category.model';

export class KydSmartphoneEnrollmentRequest {
  customerIdentifier: string;
  externalCustomerIdentifier: string;
  policyNumber: string;
  termsAcceptedIndicator: string;
  ubiProgramCategoryCode: string;
  callingApp: string;
  acknowledgementDate: string;
  requestDate: string;
  optInReasonCode: string;
  optInType: string;
  emailAddress: string;
  phoneNumber: string;
  policyEffectiveDate: string;
  policyExpireDate: string;
  policyState: string;
  shippingAddress: any;
  operators: KydSmartphoneEnrollmentOperator[];

  constructor() {
    this.ubiProgramCategoryCode = UBI_PROGRAM.TRUE_MOTION;
    this.callingApp = 'KYDENROLLAPI';
    this.optInReasonCode = '1001';
    this.optInType = '1';
  }
}

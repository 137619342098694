<div>
  <h1
    class="margin-bottom-10"
    content
    [contentPath]="'billing.autoPayRefactor.chooseAutoPayAccountsHeader'"
  ></h1>
  <p
    class="margin-bottom-30"
    content
    [contentPath]="'billing.autoPayRefactor.eligibleAccountsInfoSubHeader'"
  ></p>
  <form [formGroup]="billAccountSelectionFormGroup">
    <ds-checkbox-group
      [controlName]="'option'"
      [parentFormGroup]="billAccountSelectionFormGroup"
      [options]="billAccountOptions$ | async"
      [displayClass]="'grid'"
    ></ds-checkbox-group>
  </form>
  <call-to-action
    [tertiaryButtonName]="tertiaryButtonName"
    [disabled]="billAccountSelectionFormGroup.invalid"
    [primaryButtonName]="'Continue'"
    (primaryEvent)="submit()"
    (tertiaryEvent)="cancel()"
  ></call-to-action>
</div>

<ds-silent-registration-error-modal> </ds-silent-registration-error-modal>

import { PaymentStatus, ScheduledPayment, UNAVAILABLE_PAYMENT_ID } from '@amfam/shared/models';
import { Dictionary } from '@ngrx/entity';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap as _flatMap, get as _get, some as _some } from 'lodash';
import * as fromSchedulePaymentNotifications from './notifications.reducer';
import * as fromSchedulePaymentState from './schedulepayment.reducer';
import { SCHEDULE_PAYMENT_FEATURE_KEY } from './schedulepayment.reducer';

export interface SchedulePaymentState {
  schedulePaymentState: fromSchedulePaymentState.SchedulePaymentEntityState;
  schedulePaymentNotifications: fromSchedulePaymentNotifications.NotificationState;
}

export const schedulePaymentReducers: ActionReducerMap<SchedulePaymentState> = {
  schedulePaymentState: fromSchedulePaymentState.reducer,
  schedulePaymentNotifications: fromSchedulePaymentNotifications.reducer
};

export const getScheduledPaymentsState = createFeatureSelector<SchedulePaymentState>(
  SCHEDULE_PAYMENT_FEATURE_KEY
);

export const getScheduledPaymentsLoading = createSelector(
  getScheduledPaymentsState,
  (state: SchedulePaymentState) => _get(state, 'schedulePaymentNotifications.loading', true)
);

export const getScheduledPaymentsLoaded = createSelector(
  getScheduledPaymentsState,
  (state: SchedulePaymentState) => !_get(state, 'schedulePaymentNotifications.loading', true)
);

export const getScheduledPaymentEntities = createSelector(
  getScheduledPaymentsState,
  getScheduledPaymentsLoaded,
  (state: SchedulePaymentState, isLoaded) =>
    isLoaded
      ? <Dictionary<ScheduledPayment>>(
          fromSchedulePaymentState.selectEntities(state.schedulePaymentState)
        )
      : []
);

export const getScheduledPaymentIds = createSelector(
  getScheduledPaymentsState,
  getScheduledPaymentsLoaded,
  (state: SchedulePaymentState, isLoaded) =>
    isLoaded ? <string[]>fromSchedulePaymentState.selectIds(state.schedulePaymentState) : []
);

export const getScheduledPaymentsError = createSelector(
  getScheduledPaymentsState,
  (state: SchedulePaymentState) => !!_get(state, 'schedulePaymentNotifications.error')
);

// this returns scheduled and authorized Online Billing payments only. The http call returns non-Online Billing payments that are either:
// SUCCESSUL, UNSUCCESSFUL, DELETED, SCHEDULED or AUTHORIZED
// For the most part, the treatment of pending payments in the app are only built-out to mean Online Billing payments, with the current
// exception of the unsuccessful payment alert. Payment History uses a different mechanism in the store.
export const getScheduledPayments = createSelector(
  getScheduledPaymentEntities,
  getScheduledPaymentIds,
  (entities, ids): ScheduledPayment[] =>
    ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(
        payment =>
          (_get(payment, 'paymentStatus') === PaymentStatus.SCHEDULED ||
            _get(payment, 'paymentStatus') === PaymentStatus.AUTHORIZED) &&
          _get(payment, 'paymentMethod') === 'Online Billing'
      )
);

// This selector will return all scheduled payments except with DELETED status and will be used for bill account status pill hierarchy
export const selectScheduledPaymentsWithMostPaymentStatus = createSelector(
  getScheduledPaymentEntities,
  getScheduledPaymentIds,
  (entities, ids): ScheduledPayment[] =>
    ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(
        payment =>
          payment.paymentStatus !== PaymentStatus.DELETED &&
          payment.paymentMethod === 'Online Billing'
      )
);

export const getInflightScheduledPayment = createSelector(
  getScheduledPaymentEntities,
  getScheduledPaymentIds,
  (entities, ids) => ids.filter(id => id === UNAVAILABLE_PAYMENT_ID).map(id => entities[id])
);

// getAllScheduledPayments returns all payments along with inflight payment with an ID value of UNAVAILABLE_PAYMENT_ID
// and should only be used within add / edit payment flows
export const getAllScheduledPayments = createSelector(
  getScheduledPaymentEntities,
  getScheduledPaymentIds,
  getInflightScheduledPayment,
  (entities, ids, inflightPayment) => [
    ...ids
      .map(id => entities[id])
      .filter(
        payment =>
          (_get(payment, 'paymentStatus') === PaymentStatus.SCHEDULED ||
            _get(payment, 'paymentStatus') === PaymentStatus.AUTHORIZED) &&
          _get(payment, 'paymentMethod') === 'Online Billing'
      ),
    ...inflightPayment
  ]
);

export const getFailedPaymentsAllMethods = createSelector(
  getScheduledPaymentEntities,
  getScheduledPaymentIds,
  (entities, ids) =>
    ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(payment => _get(payment, 'paymentStatus') === 'UNSUCCESSFUL')
);

export const getSuccessfulPaymentsAllMethods = createSelector(
  getScheduledPaymentEntities,
  getScheduledPaymentIds,
  (entities, ids) =>
    ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(payment => _get(payment, 'paymentStatus') === 'SUCCESSFUL')
);

export const hasScheduledPaymentForBillaccount = (id: string) =>
  createSelector(getScheduledPayments, scheduledPayments => {
    for (const scheduledPayment of scheduledPayments) {
      const billAccountsArr =
        _flatMap(scheduledPayment.billAccounts, billaccount => billaccount) || [];
      if (billAccountsArr.find(billAccounts => billAccounts.billAccountNumber === id)) {
        return true;
      }
    }
  });

export const getSuccessfullScheduledPaymentForBillingAccount = createSelector(
  getAllScheduledPayments,
  (payments: ScheduledPayment[], billAccountNumber: string) => {
    const defaultDate = '9999-01-01T00:00:00.000-0600';
    const initialDate = '0001-01-01';
    // Order pending payments by most recent first
    payments
      .sort((a, b) => {
        // if the payment is SUCCESSFUL, we need to sort on the receivedDate field. For pending payments we sort on lastUpdateTimestamp
        // If a lastUpdateTimestamp starts with 0001-01-01 treat it as the most recent payment, so cast it to the year 9999
        const dateA =
          a.paymentStatus === 'SUCCESSFUL'
            ? _get(a, 'receivedDate', '')
            : _get(a, 'lastUpdateTimestamp', '').startsWith(initialDate)
            ? defaultDate
            : a.lastUpdateTimestamp;

        const dateB =
          b.paymentStatus === 'SUCCESSFUL'
            ? _get(b, 'receivedDate', '')
            : _get(b, 'lastUpdateTimestamp', '').startsWith(initialDate)
            ? defaultDate
            : b.lastUpdateTimestamp;
        return String(dateA).localeCompare(String(dateB));
      })
      .reverse();

    return payments.find(payment => {
      if (payment.billAccounts.length === 1) {
        return payment.billAccounts[0].billAccountNumber === billAccountNumber;
      }
      return false;
    });
  }
);

export const getScheduledPaymentsNotificationsEntities = createSelector(
  getScheduledPaymentsState,
  getScheduledPaymentsLoaded,
  (state: SchedulePaymentState, isLoaded) =>
    isLoaded
      ? fromSchedulePaymentNotifications.selectEntities(state.schedulePaymentNotifications)
      : []
);

export const getScheduledPaymentsNotificationsIds = createSelector(
  getScheduledPaymentsState,
  getScheduledPaymentsLoaded,
  (state: SchedulePaymentState, isLoaded) =>
    isLoaded
      ? <string[]>fromSchedulePaymentNotifications.selectIds(state.schedulePaymentNotifications)
      : []
);

export const getInflightScheduledPaymentNotifications = createSelector(
  getScheduledPaymentsNotificationsEntities,
  getScheduledPaymentsNotificationsIds,
  (entities, ids) => ids.filter(id => id === UNAVAILABLE_PAYMENT_ID).map(id => entities[id])
);

export const getScheduledPaymentsNotifications = createSelector(
  getScheduledPaymentEntities,
  getScheduledPaymentsNotificationsEntities,
  getScheduledPaymentIds,
  (entities: Dictionary<ScheduledPayment>, notificationsEntities, ids) =>
    <fromSchedulePaymentNotifications.ScheduledPaymentNotificationState[]>ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(
        payment =>
          (_get(payment, 'paymentStatus') === PaymentStatus.SCHEDULED ||
            _get(payment, 'paymentStatus') === PaymentStatus.AUTHORIZED) &&
          _get(payment, 'paymentMethod') === 'Online Billing'
      )
      .map(payment => notificationsEntities[payment.paymentId])
);

export const getAllScheduledPaymentsNotifications = createSelector(
  getScheduledPaymentEntities,
  getScheduledPaymentsNotificationsEntities,
  getScheduledPaymentIds,
  getInflightScheduledPaymentNotifications,
  (entities: Dictionary<ScheduledPayment>, notificationsEntities, ids, inflightPayment) =>
    <fromSchedulePaymentNotifications.ScheduledPaymentNotificationState[]>[
      ...ids
        .map(id => entities[id])
        .filter(
          payment =>
            (_get(payment, 'paymentStatus') === PaymentStatus.SCHEDULED ||
              _get(payment, 'paymentStatus') === PaymentStatus.AUTHORIZED) &&
            _get(payment, 'paymentMethod') === 'Online Billing'
        )
        .map(payment => notificationsEntities[payment.paymentId]),
      ...inflightPayment
    ]
);

export const getScheduledPaymentsAnyLoading = createSelector(
  getScheduledPaymentsNotifications,
  getScheduledPaymentsLoading,
  (schedulePayments, schedulePaymentsLoading) =>
    schedulePaymentsLoading || _some(schedulePayments, { loading: true })
);

export const schedulePaymentQuery = {
  getScheduledPaymentsAnyLoading,
  getScheduledPaymentsNotifications,
  getScheduledPaymentsNotificationsIds,
  getScheduledPaymentsNotificationsEntities,
  getSuccessfullScheduledPaymentForBillingAccount,
  getSuccessfulPaymentsAllMethods,
  getFailedPaymentsAllMethods,
  getAllScheduledPayments,
  getInflightScheduledPayment,
  getScheduledPayments,
  getScheduledPaymentsError,
  getScheduledPaymentIds,
  getScheduledPaymentEntities,
  getScheduledPaymentsLoaded,
  getScheduledPaymentsLoading,
  getScheduledPaymentsState,
  hasScheduledPaymentForBillaccount
};

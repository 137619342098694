import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from as observableFrom, of } from 'rxjs';
import {
  catchError,
  distinct,
  filter,
  map,
  mergeMap,
  toArray,
  withLatestFrom
} from 'rxjs/operators';

import { Agent, fromAgentActions, initialAgent } from '@amfam/shared/utility/agent';
import { get as _get } from 'lodash';
import { PolicyService } from '../../services/policy.service';
import { SummariesAction, SummariesActionTypes } from '../summaries/summaries.actions';
import {
  LoadPolicies,
  PoliciesActionTypes,
  PoliciesLoadSuccess,
  PoliciesSetSelected,
  fromPoliciesActions
} from './policies.actions';
import { policiesQuery } from './policies.selectors';

@Injectable()
export class PoliciesEffects {
  loading$ = createEffect(() =>
    this.action$.pipe(
      ofType(SummariesActionTypes.SummariesLoadSuccess),
      map((action: SummariesAction) => action.payload),
      // Do not call the policy details load if we do not have policy summaries
      filter(policySummaries => !!_get(policySummaries, 'length', 0)),
      mergeMap(payload => {
        return of(new fromPoliciesActions.LoadPolicies(payload));
      })
    )
  );

  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(PoliciesActionTypes.LoadPolicies),
      map((action: LoadPolicies) => action.payload),
      mergeMap(policySummaries => {
        // TODO emit an action that an individual policy load failed so we can show that on the UI.
        return observableFrom(policySummaries).pipe(
          filter(summary => summary.sourcePath !== 'HOMESITE'),
          mergeMap(policySummary =>
            this.policyService.getPolicyDetails(policySummary).pipe(
              /**
               * AS: This catch is important to ensure other call in the mergemap succeed.
               * as of now we do not know what to do with the information so we retrun the response as
               * undefined.
               */
              catchError(err => {
                return of(undefined);
              })
            )
          ),
          toArray()
        );
      }),
      mergeMap(policies => {
        const returnErrArray = [];
        /**
         * If we do not have any policies loaded via the inner subscription of mergeMap then
         * thow the error otherwise treat this as success and load the policy store.
         */
        if (policies.some(policy => policy !== undefined)) {
          const filtered = policies.filter(policy => policy && policy.policyNumber);
          returnErrArray.push(
            new fromPoliciesActions.PoliciesLoadSuccess(filtered),
            new fromPoliciesActions.PoliciesRawLoadSuccess(filtered),
            new fromPoliciesActions.PoliciesLoadComplete()
          );
        } else {
          returnErrArray.push(new fromPoliciesActions.PolicyLoadError());
        }
        return returnErrArray;
      }),
      catchError(error => {
        return of(new fromPoliciesActions.PolicyLoadError(_get(error, 'message', '')));
      })
    )
  );

  loadPolicy$ = createEffect(() =>
    this.action$.pipe(
      ofType(PoliciesActionTypes.PoliciesLoadSuccess),
      map((action: PoliciesLoadSuccess) => action.payload),
      mergeMap(payload => {
        return observableFrom(
          payload.map(policy => _get(policy, 'assignedProducer.producerIdNum'))
        );
      }),
      // prevent multiple loads of the same producer
      distinct(),
      map(producerId =>
        Object.assign({}, initialAgent, {
          id: producerId
        })
      ),
      map((agent: Agent) => new fromAgentActions.LoadAgentAction(agent))
    )
  );

  setselected$ = createEffect(() =>
    this.action$.pipe(
      ofType(PoliciesActionTypes.PoliciesSetSelected),
      map((action: PoliciesSetSelected) => action.payload),
      filter(policyNumber => !!policyNumber),
      withLatestFrom(this.store.select(policiesQuery.getPolicyEntities)),
      mergeMap(([policyNumber, policies]) => {
        const policyNumbers = Object.keys(policies);
        const matchedPolicyNumber = policyNumbers.find(polNumber =>
          polNumber.includes(policyNumber)
        );
        const policyFound = !!policies[matchedPolicyNumber];
        if (policyFound) {
          return of(
            new fromPoliciesActions.PoliciesSetSelectedSuccess({
              policyNumber: matchedPolicyNumber
            })
          );
        } else {
          of(new fromPoliciesActions.PoliciesSetSelectedError({ policyNumber }));
        }
      })
    )
  );

  constructor(
    private store: Store<any>,
    private policyService: PolicyService,
    private action$: Actions
  ) {}

  // TODO: AS - This pattern with datapersistence is not working and i do not have time currently to fix it
  // @Effect() loadPolicies$ = this.dataPersistence.fetch(PoliciesActionTypes.LoadPolicies, {
  //   run: (action: LoadPolicies, state: PoliciesPartialState) => {
  //     return observableFrom(action.payload).pipe(
  //       // TODO emit an action that an individual policy load failed so we can show that on the UI.
  //       filter(summary => summary.sourcePath !== 'HOMESITE'),
  //       mergeMap(policySummary =>
  //         this.policyService.getPolicyDetails(policySummary).pipe(
  //           /**
  //            * AS: This catch is important to ensure other call in the mergemap succeed.
  //            * as of now we do not know what to do with the information so we retrun the response as
  //            * undefined.
  //            */
  //           catchError(() => of(undefined))
  //         )
  //       ),
  //       toArray(),
  //       mergeMap(policies => {
  //         const returnErrArray = [];
  //         /**
  //          * If we do not have any policies loaded via the inner subscription of mergeMap then
  //          * thow the error otherwise treat this as success and load the policy store.
  //          */
  //         if (policies.some(policy => policy !== undefined)) {
  //           const filtered = policies.filter(policy => policy && policy.policyNumber);
  //           returnErrArray.push(
  //             new fromPoliciesActions.PoliciesLoadSuccess(filtered),
  //             new fromPoliciesActions.PoliciesLoadComplete()
  //           );
  //         } else {
  //           returnErrArray.push(new fromPoliciesActions.PoliciesLoadError());
  //         }
  //         return returnErrArray;
  //       })
  //     );
  //   },

  //   onError: (action: LoadPolicies, error) => {
  //     return new fromPoliciesActions.PoliciesLoadError(error);
  //   }
  // });

  // @Effect() loadAgents$ = this.dataPersistence.fetch(PoliciesActionTypes.PoliciesLoadSuccess, {
  //   run: (action: PoliciesLoadSuccess, state: PoliciesPartialState) => {
  //     return observableFrom(
  //       action.payload.map(policy => _get(policy, 'assignedProducer.producerIdNum'))
  //     ).pipe(
  //       distinct(),
  //       map(producerId =>
  //         Object.assign({}, initialAgent, {
  //           id: producerId
  //         })
  //       ),
  //       map((agent: Agent) => new fromAgentActions.LoadAgentAction(agent))
  //     );
  //   },

  //   onError: (action: PoliciesLoadSuccess, error) => {
  //     return new fromPoliciesActions.PoliciesLoadError(error);
  //   }
  // });

  // @Effect() setSelectedPolicy$ = this.dataPersistence.fetch(
  //   PoliciesActionTypes.PoliciesSetSelected,
  //   {
  //     run: (action: PoliciesSetSelected, state: PoliciesPartialState) => {
  //       return observableFrom(action.payload).pipe(
  //         filter(policyNumber => !!policyNumber),
  //         mergeMap(policyNumber => {
  //           const policiesSub = this.dataPersistence.store.select(policiesQuery.getPolicies);
  //           const policiesLoadedSub = this.dataPersistence.store.select(
  //             policiesQuery.getPoliciesLoaded
  //           );
  //           return combineLatest(policiesSub, policiesLoadedSub).pipe(
  //             map(results => {
  //               const policies = results[0];
  //               const isLoaded = results[1];
  //               if (!isLoaded) {
  //                 return null;
  //               }
  //               if (policies.length !== 0) {
  //                 const policy = policies.find(p => p.policyNumber === policyNumber);
  //                 if (results) {
  //                   return results;
  //                 } else {
  //                   throw new Error('Policy was not found');
  //                 }
  //               } else {
  //                 throw new Error('No policies found for user');
  //               }
  //             }),
  //             filter(value => !!value),
  //             map(value => {
  //               if (value) {
  //                 return new fromPoliciesActions.PoliciesSetSelectedSuccess(value);
  //               }
  //             })
  //           );
  //         })
  //       );
  //     },

  //     onError: (action: PoliciesSetSelected, error) => {
  //       return new fromPoliciesActions.PoliciesSetSelectedError(error);
  //     }
  //   }
  // );

  // constructor(
  //   private actions$: Actions,
  //   private dataPersistence: DataPersistence<PoliciesPartialState>,
  //   private policyService: PolicyService
  // ) {}
}

<ds-table-body-cell
  *ngFor="let column of columns"
  [ngClass]="{ 'hide-on-mobile': column.hideOnMobile, 'cell-header': column.isHeaderOnMobile }"
  class="{{tableClasses}}"
  [column]="column"
  [row]="row"
>
</ds-table-body-cell>
<div class="datatable-row-detail">
  <ng-template
    *ngIf="rowDetail && row.accountReference.displayName === 'Bundled Payment'"
    [ngTemplateOutlet]="rowDetail"
    [ngTemplateOutletContext]="{ row: row }"
  >
  </ng-template>
</div>

export class RegisterBillingAccountModel {
  'actionIndicator': string;
  'policyNumber': string;
  'billAccount': {
    billingMethod: string;
    billingPreference: {
      deliveryMethod: string;
      accountNickName: string;
      dueDateReminder: string;
    };
  };
}

export class RegisterBillAccModel {
  'billAccountNumber': string;
  'correlationId': string;
  'isUnregister': boolean;
  'registrationData': {
    policyNumber: string;
    accountName: string;
    reminder: string;
    associated: boolean;
  };
}
export class AddBillAccountFormModel {
  accountNumber: string;
  policyNumber: string;
}

export class PaymentAmountModel {
  balance: number;
  minDue: number;
  selectedAmount?: number;
  name: string;
  accNum: string;
  accountType?: string;
  fullPayAvailable: boolean;
  fullPayApplied?: boolean;
  fullPayDiscountText?: string;
  editAutoPayAmount?: string;
  pastDuePaymentInfo?: PastDuePaymentInfo;
}

export class PastDuePaymentInfo {
  pastDueAmount: number;
  pastDueDate: string;
  currentDueAmount: number;
  currentDueDate: string;
  cancelationLetterSent: boolean;
}

export class PaymentAmountRadioState {
  public static readonly MINIMUM_DUE = 'minAmountDue';
  public static readonly PAST_DUE = 'pastDue';
  public static readonly PAST_PLUS_CURRENT = 'pastPlusCurrent';
  public static readonly FULLPAY_BALANCE = 'FullPay';
  public static readonly UNPAID_BALANCE = 'unpaidBalance';
  public static readonly OTHER_AMOUNT = 'otherAmount';
}

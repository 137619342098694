export enum VehicleType {
  Unknown,
  ATV,
  Auto,
  Boat,
  Camper,
  DirtBike,
  FarmMachinery,
  GolfCart,
  MobilityDevice,
  Moped,
  Motorcycle,
  Motorhome,
  Scooter,
  Snowmobile,
  Trailer,
  UTV
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ValidationService, ToasterService } from '@amfam/ui-kit';
import { CopyService } from '@amfam/shared/utility/shared-services';

import { UntypedFormBuilder, UntypedFormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'add-ssn',
  templateUrl: './add-ssn.component.html',
  styleUrls: ['./add-ssn.component.scss']
})
export class AddSsnComponent implements OnInit {
  ssn: AbstractControl;

  dataFormModel: UntypedFormGroup;
  private copyServiceProfileSSN = this.copyService.get('profile', 'addSSN');
  yourSSN: String;

  @Output() saveSSN = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toasterService: ToasterService,
    private copyService: CopyService
  ) {}

  // if the user clears the field we reset validation
  onKeyup(e: any): void {
    if ((e.keyCode === 8 || e.keyCode === 37 || e.keyCode === 38) && !e.target.value) {
      this.dataFormModel.reset();
    }
  }

  onSubmit(): void {
    // TODO: get rid of this if statement
    // most likely added to test the toast messaging without a connected endpoint on the backend
    if (this.ssn.value === '010101010') {
      this.toasterService.pop('error', this.copyServiceProfileSSN.toasterServiceSSNSaveError);
    } else {
      this.toasterService.pop('success', this.copyServiceProfileSSN.toasterServiceSSNSaveSuccess);
      this.saveSSN.emit(this.ssn.value);
    }
  }

  ngOnInit() {
    this.dataFormModel = this.formBuilder.group({
      ssn: ['', ValidationService.ssnValidator]
    });

    this.ssn = this.dataFormModel.controls['ssn'];
    this.yourSSN = this.copyService.get('shared', 'inputFieldLabelText')['yourSSN'];
  }
}

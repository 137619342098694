<h1 class="heading-main" data-cy="enrollConfirmationStatus">
  {{ model?.anyErrors ? 'Something Went Wrong' : 'You Are Signed Up' }}
</h1>
<div class="row">
  <div class="column twelve">
    <div class="programs pad-vertical-20" [ngClass]="{ error: model?.anyErrors }">
      <div class="flex items-center pad-horizontal-20 pad-bottom-20">
        <i
          class="icon"
          [ngClass]="{ 'icon-complete-circle': !model?.anyErrors, 'icon-alert': model?.anyErrors }"
        ></i>
        <span *dsPartnerAFI data-cy="enrollConfirmationProgramStatus" class="message">{{
          model?.anyErrors
            ? 'There was an error with one or more programs.'
            : "You've
          successfully signed up for the following programs."
        }}</span>
        <span *dsPartnerConnect data-cy="enrollConfirmationProgramStatus" class="message">{{
          model?.anyErrors
            ? 'There was an error with one or more programs.'
            : "Success!
          You've enrolled in the following vehicle programs."
        }}</span>
      </div>
      <div *ngFor="let enrollment of model?.enrollments">
        <div class="column twelve list confirmation-status" data-cy="enrollConfirmationStatusFor">
          <i
            class="icon"
            [ngClass]="
              !enrollment?.success ? 'icon-alert cy-fail' : 'icon-complete-circle cy-success'
            "
          ></i>
          <p [ngClass]="{ 'program-error': !enrollment?.success }">
            {{ enrollment.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<h3 class="heading-section pad-top-20 pad-bottom-10">
  <span *dsPartnerAFI><strong>What's Next</strong></span>
  <span *dsPartnerConnect><strong>Next Steps</strong></span>
</h3>
<div class="row">
  <div class="column twelve">
    <div class="enrollments">
      <div class="message">
        <p class="pad-bottom-10" *ngIf="model?.anyErrors">
          We apologize for the inconvenience. Due to an error, you weren't successfully signed up
          for one or more programs. Please try again later.
        </p>
        <div *dsPartnerAFI>
          <p class="pad-bottom-10">
            We'll send documents and notifications to {{ model?.user?.emailAddress }}. You can
            change your email address in the <a href="/profile">Profile</a> section.
          </p>
          <p class="pad-bottom-10">
            Visit the <a href="/policies">Policies</a> section to manage your program preferences
            and other details.
          </p>
          <p>
            Visit the <a href="/billing">Billing &amp; Payments</a> section to view and manage your
            billing and payments settings.
          </p>
        </div>
        <div *dsPartnerConnect>
          <p class="pad-bottom-10">
            These programs apply only to your auto policies. We'll email you an enrollment
            confirmation at {{ model?.user?.emailAddress }}.
          </p>
          <p>
            We're proud to partner with Homesite Group for your property coverage. More information
            on programs available for your property coverage is available on the
            <a [href]="propertyServiceUrl" target="_blank">Homesite website</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row callToAction">
  <div class="column twelve">
    <span class="md-float-left">
      <button
        ds-button
        type="button"
        aria-label="Done"
        data-cy="enrollConfirmationButton"
        (click)="close()"
      >
        Done
      </button>
    </span>
  </div>
</div>

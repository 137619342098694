import { map, withLatestFrom, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';

import * as systemStatus from '../app-bootstrap/app-bootstrap.actions';
import { ContentService } from '../../../core';
import { BrandSelectors, BrandActions } from '@amfam/shared/utility/brand';
import * as fromRoot from '../../../core/store';
import { fromFeatureFlagActions } from '@amfam/shared/utility/feature-flag/data-access';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { environment } from '../../../../environments/environment';
@Injectable()
export class AppBootstrapEffects {
  constructor(
    private actions$: Actions,
    private contentService: ContentService,
    private featureFlagService: FeatureFlagService,
    private store: Store<fromRoot.RootState>
  ) {}

  /**
   * Once Brand Data is available, get the Outage Banner content while sending PartnerId as a parameter. The Outage Banner
   * content is dynamic based on partnerId sent.
   */

  // eslint-disable-next-line @typescript-eslint/member-ordering
  start$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandActions.LOAD_BRAND_DATA_SUCCESS),
      withLatestFrom(this.store.select(BrandSelectors.getPartnerId)),
      mergeMap(([action, partnerId]) => {
        const contentPath = 'OutageBanner?partnerId=' + partnerId;
        return this.contentService.getContent(contentPath).pipe(
          map((contentResponse: any) => {
            if (contentResponse.richContent !== '') {
              return new systemStatus.LoadSystemBannerSuccessAction(contentResponse);
            } else {
              return new systemStatus.LoadSystemBannerFailAction(contentResponse);
            }
          })
        );
      })
    )
  );
}

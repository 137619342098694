import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileMenuRefreshDirective } from './mobile-menu-refresh.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MobileMenuRefreshDirective],
  exports: [MobileMenuRefreshDirective]
})
export class MobileMenuRefreshModule {}

<ds-modal [id]="'paymentMethodModal'" [maxWidth]="800" [closeOthers]="true">
  <div class="modal-container">
    <ds-bank-account-add-edit-wrapper
      *ngIf="
        paymentMethodAddEditConfig?.accountType === paymentMethodTypes.CHECKING ||
        paymentMethodAddEditConfig?.accountType === paymentMethodTypes.SAVINGS
      "
      [paymentMethodAddEditConfig]="paymentMethodAddEditConfig"
      [showTryAgainButton]="showTryAgainButton"
      (paymentMethodAddEditCompleteEvent)="paymentMethodAddEditCompleteEvent.emit($event)"
    >
    </ds-bank-account-add-edit-wrapper>

    <ds-credit-debit-add-edit-wrapper
      *ngIf="paymentMethodAddEditConfig?.accountType === paymentMethodTypes.CREDIT_DEBIT"
      [paymentMethodAddEditConfig]="paymentMethodAddEditConfig"
      [displayDiscountWarning]="displayDiscountWarning"
      [showTryAgainButton]="showTryAgainButton"
      (paymentMethodAddEditCompleteEvent)="paymentMethodAddEditCompleteEvent.emit($event)"
    >
    </ds-credit-debit-add-edit-wrapper>
  </div>
</ds-modal>

import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as AutoPaySetupActions from './auto-pay-setup.actions';
import { AutoPaySteps } from './auto-pay-setup.models';
import * as AutoPaySetupSelectors from './auto-pay-setup.selectors';

@Injectable()
export class AutoPaySetupEffects {
  // Go to next step after updating autopay preferences and setting completed to true
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPaySetupActions.updateAutoPaySetup),
      map(() => AutoPaySetupActions.nextStep())
    )
  );

  edit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPaySetupActions.editAutoPaySetup),
      switchMap(action => [
        AutoPaySetupActions.setStep({
          step: AutoPaySteps.SETUP,
          activeBillAccount: action.billAccountNumber,
          isEditing: true
        }),
        new fromRouterActions.Go({
          path: ['billing', 'auto-pay', 'setup'],
          query: { billAccount: action.billAccountNumber }
        })
      ])
    )
  );

  routeToPreviousStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPaySetupActions.previousStep),
      withLatestFrom(this.store.select(AutoPaySetupSelectors.getPreviousSetup)),
      switchMap(([action, previousSetup]) => {
        if (previousSetup) {
          return [
            AutoPaySetupActions.setStep({
              step: AutoPaySteps.SETUP,
              activeBillAccount: previousSetup.billAccount.billAccountNumber,
              isEditing: false
            }),
            new fromRouterActions.Go({
              path: ['billing', 'auto-pay', 'setup'],
              query: { billAccount: previousSetup.billAccount.billAccountNumber }
            })
          ];
        } else {
          return [
            AutoPaySetupActions.setStep({ step: AutoPaySteps.SELECT_ACCOUNTS }),
            new fromRouterActions.Go({
              path: ['billing', 'auto-pay', 'select-accounts']
            })
          ];
        }
      })
    )
  );

  routeToNextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutoPaySetupActions.nextStep),
      withLatestFrom(
        this.store.select(AutoPaySetupSelectors.getNextSetup),
        this.store.select(AutoPaySetupSelectors.getActiveSetup)
      ),
      switchMap(([action, nextSetup, activeSetup]) => {
        if (nextSetup && !activeSetup.editing) {
          return [
            AutoPaySetupActions.setStep({
              step: AutoPaySteps.SETUP,
              activeBillAccount: nextSetup.billAccount.billAccountNumber,
              isEditing: false
            }),
            new fromRouterActions.Go({
              path: ['billing', 'auto-pay', 'setup'],
              query: { billAccount: nextSetup.billAccount.billAccountNumber }
            })
          ];
        } else {
          return [
            AutoPaySetupActions.setStep({ step: AutoPaySteps.REVIEW }),
            new fromRouterActions.Go({
              path: ['billing', 'auto-pay', 'review']
            })
          ];
        }
      })
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}

export class VerifyUserModel {
  constructor(
    public customerId: string,
    public typeOfVerificationMethodCode: string,
    public maskedEmailAddress: string,
    public programPathContext: string,
    public experienceId: string,
    public partnerId: string,
    public programEnrollmentIndicator: boolean
  ) {}
}

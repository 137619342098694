import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  CreateRentalRequest,
  CreateRentalRequestSuccess,
  CreateRentalRequestFail,
  RentalServiceActionTypes
} from './rental-service.actions';

import { CreateRentalRequestPayload } from './rental-service.models';

import { ClaimServicesService } from '../../services/claim-services.service';

@Injectable()
export class RentalServiceEffects {
  
  createRentalRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<CreateRentalRequest>(RentalServiceActionTypes.CreateRentalRequest),
    map(action => action.payload),
    switchMap((payload: CreateRentalRequestPayload) => {
      return this.claimServicesService
        .createRentalRequest(
          payload.claimNumber,
          payload.vin,
          payload.selectedShop.id,
          payload.customerId
        )
        .pipe(
          map(() => new CreateRentalRequestSuccess()),
          catchError(() => of(new CreateRentalRequestFail()))
        );
    })
  ));

  constructor(private actions$: Actions, private claimServicesService: ClaimServicesService) {}
}

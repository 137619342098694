<div class="main row">
<card [cardClasses]="'profile-card'" data-cy="profileCard">
  <div card-header>
    <div class="row">
      <div class="column twelve md-six">
        <h3 data-cy="accountUserName">Hi, {{ profileCard.firstName }}</h3>
        <div *ngIf="canShowStatus">
          Status:
          <span class="status">
            {{ !isShellAccount ? 'Active' : 'Shell Account Pending' }}
          </span>
        </div>
      </div>
      <div *ngIf="!isShellAccount" class="column twelve md-six">
        <h4 data-cy="accountUserID">
          User ID: <span>{{ profileCard.userId }}</span>
        </h4>
      </div>
    </div>
  </div>
  <div card-content>
    <div class="row">
      <div class="column twelve md-six">
        <h4
          content
          [contentPath]="
            isShellAccount ? 'shared.enrolledEmail' : 'profile.manageEmails.primaryEmail'
          "
          data-cy="accountEmailAddress"
        ></h4>
        <p class="primary-email">
          {{
            isShellAccount
              ? shellEmail
              : profileCard.primaryEmail
              ? profileCard.primaryEmail.emailAddress
              : ''
          }}
        </p>
        <div *ngIf="profileCard.primaryPhone">
          <h4>Phone Number</h4>
          <p>
            {{
              profileCard.primaryPhone.areaCode + profileCard.primaryPhone.phoneNumber
                | phoneNumberSingleStringFormatter: profileCard.primaryPhone.extension
            }}
          </p>
        </div>
      </div>
      <div class="column twelve md-six">
        <div *ngIf="isCustomer">
          <h4 data-cy="accountAddress">Address</h4>
          <address *ngIf="profileCard.address">
            <span>{{ profileCard.address.line1 }}</span
            ><br />
            <span *ngIf="profileCard.address.line2">{{ profileCard.address.line2 }}<br /></span>
            <span class="pad-right-6">{{ profileCard.address.city }},</span>
            <span class="pad-right-6">{{ profileCard.address.state }}</span>
            <span>{{ profileCard.address.zip5 }}</span>
          </address>
          <p *ngIf="!profileCard.address">N/A</p>
        </div>
      </div>
    </div>
  </div>
</card>

export class BillingPaymentConfirmationModel {
  amount?: number;
  date?: string;
  transactionId?: string;
  moduleName?: string;
  category?: string;
  subCategory?: string;
  confirmationMsgHeading?: string;
  paymentAccount?: string;
  billAccounts?: Array<{
    billAccountNumber: string;
    billAccountNickName: string;
    billAcctPaymentAmount: string;
    nickName: string;
    pastDueInfo?: {
      currentAmountDue?: number;
      currentAmountDueDate?: string;
    };
  }>;
  columnNamePropMap?: Array<{}>;
  rowsPropValueMap?: Array<{}>;
  autoPay?: boolean;
  paymentTerm?: Array<string>;
  accountName?: string;
  paymentConfirmationId?: string;
  typeOfPayment?: string;
  paymentId?: string;
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { flatMap } from 'lodash';
import { AutomaticPayment } from '../models/auto-pay.models';
import * as AutoPayActions from './auto-pay.actions';
export interface State extends EntityState<AutomaticPayment> {}

export const adapter: EntityAdapter<AutomaticPayment> = createEntityAdapter<AutomaticPayment>({
  selectId: (automaticPayment: AutomaticPayment) => automaticPayment?.billAccountNumber,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,

  // Get Automatic Payments
  on(AutoPayActions.getAutomaticPaymentSuccess, (state, action) =>
    adapter.upsertOne(action.response.autoPayRules[0], state)
  ),

  // Get All Automatic Payments
  on(AutoPayActions.getAllAutomaticPaymentsSuccess, (state, action) => {
    const automaticPayments: AutomaticPayment[] = action.response
      .filter(response => response.autoPayRules.length > 0)
      .map(response => response.autoPayRules[0]);
    return adapter.setAll(automaticPayments, state);
  }),

  // Edit AutoPay
  on(AutoPayActions.editAutoPaySuccess, (state, action) =>
    adapter.updateOne(
      {
        id: action.response?.autoPayRules[0]?.billAccountNumber,
        changes: action.response.autoPayRules[0]
      },
      state
    )
  ),

  on(AutoPayActions.updateAutoPay, (state, action) =>
    adapter.upsertMany(
      flatMap(state.entities)
        .filter(entity => entity.paymentAccount.nickName === action.payload.oldNickName)
        .map(entity =>
          Object.assign({}, entity, {
            paymentAccount: {
              nickName: action.payload.newNickName
            }
          })
        ),
      state
    )
  ),

  // Add Multiple AutoPay
  on(AutoPayActions.addMultipleAutoPaySuccess, (state, action) =>
    adapter.upsertMany(action.response.autoPayRules, state)
  ),
  on(AutoPayActions.addMultipleAutoPayPartialSuccess, (state, action) =>
    adapter.upsertMany(action.response.autoPayRules, state)
  ),

  // Delete AutoPay
  on(AutoPayActions.deleteAutoPaySuccess, (state, action) =>
    adapter.removeOne(action.billAccountNumber, state)
  )
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAutomaticPaymentIds = selectIds;
export const selectAutomaticPaymentEntities = selectEntities;
export const selectAllAutomaticPayments = selectAll;
export const selectAutomaticPaymentsTotal = selectTotal;

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderWrapperService {
  private _skipContentFocus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _focusMainContent$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  get skipContentFocus$() {
    return this._skipContentFocus$.asObservable();
  }

  setSkipContentFocus(skipContentFocus: boolean) {
    this._skipContentFocus$.next(skipContentFocus);
  }

  get focusMainContent$() {
    return this._focusMainContent$.asObservable();
  }

  setFocusMainContent(focusMainContent: boolean) {
    this._focusMainContent$.next(focusMainContent);
  }
}

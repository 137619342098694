<form [formGroup]="freeFormTextGroup">
  <ds-form-control-container
    [labelText]="placeHolder"
    [control]="freeFormTextGroup.get('description')"
  >
    <ds-text-area [control]="'description'" [parentFormGroup]="freeFormTextGroup"> </ds-text-area>
    <div class="character-count">
      <ds-form-control-message [control]="freeFormTextGroup.get('description')">
      </ds-form-control-message>
      <ds-form-character-counter
        [maxLength]="maxLength"
        [control]="freeFormTextGroup.get('description')"
      >
      </ds-form-character-counter>
    </div>
  </ds-form-control-container>
  <call-to-action
    [disabled]="freeFormTextGroup.invalid"
    [primaryButtonName]="'Continue'"
    [displayBackButton]="displayBackButton"
    [tertiaryButtonName]="'Cancel'"
    (primaryEvent)="onFreeTextSubmit(freeFormTextGroup.controls['description'].value)"
    (tertiaryEvent)="cancel()"
    (backEvent)="goBack()"
  >
  </call-to-action>
</form>

export interface Phone {
  type: string;
  number: string;
  ext: string;
}

export interface IReportClaim {
  policyNumber: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipcode: string;
  email: string;
  phone1: Phone;
  phone2?: Phone;
  typeOfLoss: string;
  dateOfLoss: string;
  timeOfLoss: string;
  locationOfLoss: string;
  descriptionOfLoss: string;
}

export interface EntityNotification {
  id: string;
  loading: boolean;
  hasError: boolean;
  error: any;
  correlationId: string;
}

export class LoadNotification implements EntityNotification {
  id: string;
  loading: boolean = true;
  hasError: boolean = false;
  error: any = null;
  correlationId: string = '';

  constructor(id: string) {
    this.id = id;
  }
}

export class SuccessNotification implements EntityNotification {
  id: string;
  loading: boolean = false;
  hasError: boolean = false;
  error: any = null;
  correlationId: string;

  constructor(id: string, correlationId: string) {
    this.id = id;
    this.correlationId = correlationId;
  }
}

export class FailureNotification implements EntityNotification {
  id: string;
  loading: boolean = false;
  hasError: boolean = true;
  error: any;
  correlationId: string;

  constructor(id: string, correlationId: string, error: any) {
    this.id = id;
    this.correlationId = correlationId;
    this.error = error;
  }
}

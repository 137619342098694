export class Phone {
  areaCode: string;
  number: string;
  extension: string;
  countryCode: string;
  phoneType: string;

  get fullNumber(): string {
    return [this.countryCode, this.areaCode, this.number].join('');
  }

  public static clearFormat(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.replace(/\-/g, '') : '';
  }

  constructor(phoneNumber?: string, extension?: string) {
    if (phoneNumber) {
      const numbers = phoneNumber.replace(/[^0-9]+/g, '');
      if (numbers.length > 10) {
        this.countryCode = numbers.slice(0, numbers.length - 10);
        this.areaCode = numbers.slice(numbers.length - 10, numbers.length - 7);
        this.number = numbers.slice(numbers.length - 7);
      } else if (numbers.length > 7) {
        this.areaCode = numbers.slice(0, numbers.length - 7);
        this.number = numbers.slice(numbers.length - 7);
      } else {
        this.number = numbers;
      }
    }
    if (extension) {
      this.extension = extension;
    }
  }
}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { has as _has } from 'lodash';

import { profileQuery, ProfileActions } from '@amfam/profile/data-access';
import { get as _get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { userQuery } from '@amfam/shared/user';
import { SecurityQuestionsService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'edit-security-questions',
  templateUrl: './edit-security-questions.component.html',
  styleUrls: ['./edit-security-questions.component.scss']
})
export class EditSecurityQuestionsComponent implements OnInit, OnDestroy {
  @Input() securityQuestions: any[];

  showForm = false;
  private username: string;
  private customerId: string;
  errorLoadingQuestions = false;
  private stop$: Subject<void> = new Subject<void>();
  private updatedSecurityQuestions: any;

  constructor(
    private securityQuestionsService: SecurityQuestionsService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.loadAccountInformation();
  }

  loadAccountInformation() {
    // Get the username from the auth service, since this is an edit operation on the security questions
    // this can only be performed if the user is loggeg in.
    combineLatest(
      this.store.select(userQuery.getUserState),
      this.store.select(profileQuery.getProfileSecurityInfoStatus),
      (user, profileSecurityInfoStatus) => {
        return {
          user: user,
          profileSecurityInfoStatus: profileSecurityInfoStatus
        };
      }
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.username = _get(state, 'user.loginName', '');
        this.customerId = _get(state, 'user.customerId', '');
        if (
          _get(state, 'profileSecurityInfoStatus.editSecurityInfoMethodType') !==
          'securityQuestions'
        ) {
          return;
        }
        const editSecurityInfoLoading = _get(state, 'profileSecurityInfoStatus.loading');
        const editSecurityInfoError = _get(
          state,
          'profileSecurityInfoStatus.editSecurityInfoError'
        );
        if (!editSecurityInfoLoading && !editSecurityInfoError) {
          this.handleSecurityQuestionSuccess(this.updatedSecurityQuestions);
        } else if (!editSecurityInfoLoading && editSecurityInfoError) {
          this.handleSecurityQuestionFailure(
            _get(state, 'profileSecurityInfoStatus.editSecurityInfoResponse')
          );
        } else {
          return;
        }
      });
  }

  // post updates to security questions
  onComplete({ status, securityQuestions }) {
    if (status) {
      // start the spinner
      this.store.dispatch(new ProfileActions.MakeRequestAction());

      // dispatch the request to change password
      const data = {
        securityQuestions: securityQuestions,
        customerId: this.customerId
      };
      this.updatedSecurityQuestions = securityQuestions;
      this.store.dispatch(
        new ProfileActions.ChangeSecurityInfoAction({
          data,
          methodType: 'securityQuestions'
        })
      );
    }
  }

  handleSecurityQuestionSuccess(securityQuestions): void {
    if (!securityQuestions) {
      return;
    }
    // pass updates to the store
    this.store.dispatch(
      new ProfileActions.UpdateSecurityQuestionsAction({
        securityQuestions: this.getNewQuestionsArray(securityQuestions)
      })
    );
    this.showForm = false;
  }

  handleSecurityQuestionFailure(error) {
    const apiMessage = _get(error, 'messages[0]', null);
    if (apiMessage && apiMessage.code) {
      this.securityQuestionsService.setUserQuestionsError$(apiMessage.code);
    }
  }

  // triggers if theres an error loading the list of security questions
  onGetAllQuestionsServiceError(err) {
    // toggle flag to show error messaging
    this.errorLoadingQuestions = true;
  }

  openSecurityQuestionsForm() {
    // reset error value so if opened again request for questions will be attempted again
    this.errorLoadingQuestions = false;
    this.securityQuestionsService.setUserQuestionsError$(null);
    this.showForm = !this.showForm;
  }

  private getNewQuestionsArray(securityQuestions): any[] {
    let oldQuestion: any[] = [];
    // if not all the questions are being updated, we need to get the question that wasn't updated
    if (securityQuestions.length !== this.securityQuestions) {
      oldQuestion = this.securityQuestions.filter(
        question => question.question !== securityQuestions[0].oldQuestion
      );
    }
    // clear answers and oldQuestion prop on question objects
    return [...securityQuestions, ...oldQuestion].map(({ question }) => {
      return {
        question,
        answer: ''
      };
    });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

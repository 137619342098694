import { GenericProductType } from '@amfam/policy/models';
import { userQuery } from '@amfam/shared/user';
import {
  ApplicationService,
  Applications,
  ConfigService,
  CookiesService,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FnolModel } from '../+state/fnol.model';
import { CreateDraftClaimModel } from '../models/create-draft-claim.model';
import { DeleteDraftClaimApiResponse } from '../models/delete-draft-claim-api-response.model';
import { InitiateDynamicQuestionAdapterModel } from '../models/initiate-dynamic-question-adapter.model';
import { InitiateDynamicQuestionApiResponseModel } from '../models/initiate-dynamic-question-api-response.model';
import { InitiateDynamicQuestionModel } from '../models/initiate-dynamic-question.model';
import { SubmitDraftClaimApiResponseModel } from '../models/submit-draft-claim-api-response.model';
import { SubmitQuestionApiModel } from '../models/submit-question-api.model';
import { FnolAdapter } from './fnol-adapter';

@Injectable({
  providedIn: 'root'
})
export class FnolService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private fnolAdapter: FnolAdapter,
    private cookieService: CookiesService,
    private utilService: UtilService,
    private appService: ApplicationService,
    private store: Store
  ) {}

  resumeDraftClaim(
    draftClaimNumber: string,
    lineOfBusiness: GenericProductType
  ): Observable<InitiateDynamicQuestionAdapterModel> {
    return this.http
      .post(
        this.config.get('converseApi') + 'next/' + draftClaimNumber,
        {},
        { headers: this.getHeaders(lineOfBusiness) }
      )
      .pipe(
        map((response: InitiateDynamicQuestionApiResponseModel) => this.fnolAdapter.adapt(response))
      );
  }

  createDraftClaim(
    createDraftClaimPayload: CreateDraftClaimModel
  ): Observable<{ claimNumber: string }> {
    let headers = new HttpHeaders();
    let endpoint = this.config.get('claimFnolApi');
    if (this.appService.isApp(Applications.THIRD_PARTY_FNOL)) {
      endpoint = this.config.get('thirdPartyFnolApi');
      headers = headers.set('Authorization', 'Bearer ' + this.cookieService.getItem('TPFKey'));
    }
    return this.http.post(endpoint + 'draftclaim', createDraftClaimPayload, { headers }).pipe(
      map((response: FnolModel) => ({
        claimNumber: _get(response, 'claimNumber', '')
      }))
    );
  }

  initiateDynamicQuestionFlow(
    initiateDynamicQuestionPayload: InitiateDynamicQuestionModel,
    lineOfBusiness: GenericProductType
  ): Observable<InitiateDynamicQuestionAdapterModel> {
    const endpoint = this.appService.isApp(Applications.THIRD_PARTY_FNOL)
      ? this.config.get('thirdPartyFnolConverseApi')
      : this.config.get('converseApi');
    return this.http
      .post(
        endpoint + 'next/' + initiateDynamicQuestionPayload.draftClaimNumber,
        initiateDynamicQuestionPayload.payload,
        { headers: this.getHeaders(lineOfBusiness) }
      )
      .pipe(
        map((response: InitiateDynamicQuestionApiResponseModel) => this.fnolAdapter.adapt(response))
      );
  }

  submitQuestion(
    payload: SubmitQuestionApiModel,
    draftClaimNumber: string,
    lineOfBusiness: GenericProductType
  ): Observable<InitiateDynamicQuestionAdapterModel> {
    const endpoint = this.appService.isApp(Applications.THIRD_PARTY_FNOL)
      ? this.config.get('thirdPartyFnolConverseApi')
      : this.config.get('converseApi');
    return this.http
      .post(endpoint + 'next/' + draftClaimNumber, payload, {
        headers: this.getHeaders(lineOfBusiness)
      })
      .pipe(
        map((response: InitiateDynamicQuestionApiResponseModel) => this.fnolAdapter.adapt(response))
      );
  }

  goBack(
    draftClaimNumber: string,
    questionId: string,
    lineOfBusiness: GenericProductType
  ): Observable<InitiateDynamicQuestionAdapterModel> {
    const endpoint = this.appService.isApp(Applications.THIRD_PARTY_FNOL)
      ? this.config.get('thirdPartyFnolConverseApi')
      : this.config.get('converseApi');
    return this.http
      .post(
        endpoint + 'previous/' + draftClaimNumber + '/' + questionId,
        {},
        { headers: this.getHeaders(lineOfBusiness) }
      )
      .pipe(
        map((response: InitiateDynamicQuestionApiResponseModel) => this.fnolAdapter.adapt(response))
      );
  }

  editQuestion(
    draftClaimNumber: string,
    questionId: string,
    lineOfBusiness: GenericProductType
  ): Observable<InitiateDynamicQuestionAdapterModel> {
    const endpoint = this.appService.isApp(Applications.THIRD_PARTY_FNOL)
      ? this.config.get('thirdPartyFnolConverseApi')
      : this.config.get('converseApi');
    return this.http
      .get(endpoint + draftClaimNumber + '/' + questionId, {
        headers: this.getHeaders(lineOfBusiness)
      })
      .pipe(
        map((response: InitiateDynamicQuestionApiResponseModel) => this.fnolAdapter.adapt(response))
      );
  }

  submitDraftClaim(
    draftClaimNumber: string,
    lineOfBusiness: GenericProductType
  ): Observable<string> {
    const endpoint = this.appService.isApp(Applications.THIRD_PARTY_FNOL)
      ? this.config.get('thirdPartyFnolConverseApi')
      : this.config.get('converseApi');
    return this.http
      .post(
        endpoint + 'submit/' + draftClaimNumber,
        {},
        {
          headers: this.getHeaders(lineOfBusiness)
        }
      )
      .pipe(
        map((response: SubmitDraftClaimApiResponseModel) => _get(response, 'response.claimNumber'))
      );
  }

  deleteDraftClaim(claimNumber: string): Observable<DeleteDraftClaimApiResponse> {
    return this.http.delete<DeleteDraftClaimApiResponse>(
      this.config.get('claimFnolApi') + claimNumber
    );
  }

  private getHeaders(lineOfBusiness: GenericProductType): HttpHeaders {
    let CustkeyToken = '';
    this.store
      .select(userQuery.selectCUSTKEY)
      .pipe(take(1))
      .subscribe(token => (CustkeyToken = token));
    let headers = new HttpHeaders();
    if (this.appService.isApp(Applications.FIRST_PARTY_FNOL)) {
      headers = headers.set('partner-id', 'amfam');
      headers = headers.set('cust-key', this.cookieService.getItem('ALTAUTHNKEY'));
    } else if (this.appService.isApp(Applications.THIRD_PARTY_FNOL)) {
      headers = headers.set('partner-id', 'amfamThirdParty');
      headers = headers.set('cust-key', '');
      headers = headers.set('Authorization', 'Bearer ' + this.cookieService.getItem('TPFKey'));
    } else {
      headers = headers.set('partner-id', 'amfam');
      headers = headers.set('cust-key', 'Bearer ' + CustkeyToken);
    }
    headers = headers.set(
      'line-of-business',
      lineOfBusiness === GenericProductType.Home ? 'property' : 'auto'
    );
    headers = headers.set('transactionid', this.utilService.generateId());
    return headers;
  }
}

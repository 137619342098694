import { DigitalAccount, DigitalAccountService } from '@amfam/shared/digital-account/data-access';
import { Party } from '@amfam/shared/models';
import { fromUserActions, userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CommunicationService, CookiesService } from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { UpdateEmailData } from '../models/email-validation.model';
import {
  EmailPayloadToGetValidationStatus,
  EmailValidationResponse,
  UpdateContactMethodPayload
} from '../models/profile.model';
import { AlternateAuthService } from '../services/alternate-auth.service';
import { DigitalServiceProgramService } from '../services/digital-service-program.service';
import { PartyService } from '../services/party.service';
import { ProfileUtilService } from '../services/profile-util.service';
import * as profile from './profile.actions';

@Injectable()
export class ProfileEffects {
  /**
   * Dispatches a FETCH_DATA action when the account is loaded
   *
   * @memberof ProfileEffects
   */

  loadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.PROFILE_LOAD),
      map((action: profile.ProfileLoad) => action.payload),
      map(payload => new profile.FetchDataAction(payload))
    )
  );

  /**
   * gets Party data from the store
   * if the authenticatin payload contains a customerId
   *
   * @memberof ProfileEffects
   */

  loadParty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.FETCH_DATA),
      map((action: profile.FetchDataAction) => action.payload),
      filter(payload => {
        // Make sure we have a customerId before calling getPartyDetails()
        return payload && payload.customerId && payload.customerId !== '';
      }),
      switchMap(() =>
        this.store.select(userQuery.getPartyDetails).pipe(
          map(res => new profile.FetchPartyDataSuccessAction(res)),
          catchError(err => of(new profile.FetchPartyDataFailAction(err)))
        )
      )
    )
  );

  /**
   * Add an email or phone to a Party
   *
   * @memberof ProfileEffects
   */

  addContactEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.ADD_CONTACT_ENTRY),
      map((action: profile.AddContactEntryAction) => action.payload),
      switchMap(
        // POST to party with new email
        (data: profile.ContactEntryActionPayload): Observable<Action> =>
          this.partyService.addContactEntry(data).pipe(
            map(res => {
              res.methodType = _get(data, 'methodType');
              return res.status.code === 200
                ? new profile.UpdateContactMethodValuesAction(data)
                : new profile.ContactEntryFailAction(res);
            }),
            catchError(err => {
              err.methodType = _get(data, 'methodType');
              return of(new profile.ContactEntryFailAction(err));
            })
          )
      )
    )
  );

  /**
   * This happens after you ADD a new email of phone.
   * We need to GET all emails or phones again so we
   * have the ID of the entry we just added.
   *
   * @memberof ProfileEffects
   */

  getUpdatedValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.UPDATE_CONTACT_METHOD_VALUES),
      map((action: profile.UpdateContactMethodValuesAction) => action.payload),
      switchMap((payload: UpdateContactMethodPayload) =>
        this.partyService.getPartyDetails().pipe(
          mergeMap((res: Party) => {
            if (payload.methodType === 'emails' && _get(payload.data, 'setPrimaryEmail')) {
              const validationPayload: EmailPayloadToGetValidationStatus =
                this.profileUtilService.getOldAndNewPrimaryEmail(payload.data, res);
              return [
                new profile.AddContactEntrySuccessAction({
                  setPrimaryEmail: true,
                  methodType: payload.methodType,
                  [payload.methodType]: res[payload.methodType]
                }),
                new profile.GetValidationStatusForEmailAction(validationPayload)
              ];
            }
            return [
              new profile.AddContactEntrySuccessAction({
                setPrimaryEmail: false,
                methodType: payload.methodType,
                [payload.methodType]: res[payload.methodType]
              })
            ];
          }),
          catchError(err => {
            err.methodType = _get(payload, 'methodType');
            return of(new profile.ContactEntryFailAction(err));
          })
        )
      )
    )
  );

  validation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.GET_VALIDATION_STATUS_FOR_EMAIL),
      map((action: profile.GetValidationStatusForEmailAction) => action.payload),
      switchMap((payload: EmailPayloadToGetValidationStatus) => {
        return this.dspService.getValidationStatus(payload.newEntry.emailAddress).pipe(
          mergeMap((response: EmailValidationResponse) => {
            if (_get(response, 'emailProfile.confirmed')) {
              const emailAddress = payload.newEntry.emailAddress;
              return [
                new profile.MakeRequestAction(), // start the spinner
                new profile.UpdateDaAction({ emailAddress })
              ];
            } else {
              return [
                new profile.MakeRequestAction(), // start the spinner
                new profile.ValidateEmailAction({
                  // email has not been validated
                  newEmail: payload.newEntry,
                  oldEmail: payload.oldEntry
                })
              ];
            }
          }),
          catchError(error => of(new profile.GetValidationStatusForEmailFailAction(error)))
        );
      })
    )
  );

  /**
   * Delete an email or phone from a party
   *
   * @memberof ProfileEffects
   */

  deleteContactEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.DELETE_CONTACT_ENTRY),
      map((action: profile.DeleteContactEntryAction) => action.payload),
      switchMap(
        (data: profile.ContactEntryActionPayload): Observable<Action> =>
          this.partyService.deleteContactEntry(data).pipe(
            map(res => {
              res.methodType = _get(data, 'methodType');
              return res.status.code === 200
                ? new profile.DeleteContactEntrySuccessAction(data)
                : new profile.ContactEntryFailAction(res);
            }),
            catchError(err => {
              err.methodType = _get(data, 'methodType');
              return of(new profile.ContactEntryFailAction(err));
            })
          )
      )
    )
  );

  /**
   * Update an email or phone in a party
   *
   * @memberof ProfileEffects
   */

  changeContactEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.CHANGE_CONTACT_ENTRY),
      map((action: profile.ChangeContactEntryAction) => action.payload),
      switchMap((data: profile.ContactEntryActionPayload): Observable<Action> => {
        // oldEntry will exist if the user is changing a preferred contact setting that has a pre-existing value
        // in this situation the old and new values have to be PUT separately
        // first update should be the old entry if there is one
        const entry = data.data.oldEntry ? data.data.oldEntry : data.data.newEntry;
        // if there is an old entry, second update will be the new entry
        const entry2 = data.data.oldEntry ? data.data.newEntry : undefined;
        return this.partyService.editContactEntry(entry, data.methodType, entry2).pipe(
          map((res: any) => {
            if (res.status.code === 200) {
              if (entry2) {
                // kick of edit for second entry
                return new profile.ChangeContactEntryAction({
                  data: { newEntry: entry2 },
                  methodType: data.methodType
                });
              } else {
                return new profile.ChangeContactEntrySuccessAction({
                  methodType: data.methodType,
                  data: res.otherEntry ? [entry, res.otherEntry] : [entry]
                });
              }
            }
            res.methodType = _get(data, 'methodType');
            return new profile.ContactEntryFailAction(res);
          }),
          catchError(err => {
            err.methodType = _get(data, 'methodType');
            return of(new profile.ContactEntryFailAction(err));
          })
        );
      })
    )
  );

  /**
   * Refresh party details from the party API after all CRUD operations on a party
   *
   * @memberof ProfileEffects
   */

  refreshParty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        profile.ProfileActionTypes.REFRESH_PARTY_DATA,
        profile.ProfileActionTypes.CHANGE_CONTACT_ENTRY_SUCCESS,
        profile.ProfileActionTypes.DELETE_CONTACT_ENTRY_SUCCESS
      ),
      mergeMap(() =>
        this.partyService.getPartyDetails().pipe(
          map(res => new profile.FetchPartyDataSuccessAction(res)),
          catchError(err => of(new profile.FetchPartyDataFailAction(err)))
        )
      )
    )
  );

  loadDigitalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.FETCH_DATA),
      withLatestFrom(this.store.select(userQuery.getWaid)),
      switchMap(([action, waid]) =>
        this.daService.getDigitalAccountByWaid(waid).pipe(
          withLatestFrom(this.store.select(userQuery.getSmImpersonatorUserId)),
          map(([res, impersonatorUserId]) => {
            if (
              !_get(res, 'securityQuestions[0].question') &&
              this.featureFlagService.isEnabled('type_zero_security_questions') &&
              !impersonatorUserId
            ) {
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: ['/add-security-questions']
                })
              );
            }
            return new profile.FetchDaDataSuccessAction(res);
          }),
          catchError(err => of(new profile.FetchDaDataFailAction(err)))
        )
      )
    )
  );

  updateDigitalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.UPDATE_DA),
      map((action: profile.UpdateDaAction) => action.payload),
      withLatestFrom(
        this.store.select(userQuery.getWaid),
        this.store.select(BrandSelectors.getPartnerId),
        this.store.select(BrandSelectors.getExperienceId)
      ),

      switchMap(([data, waid, partnerId, expId]) =>
        this.daService.updateDigitalAccount(data, waid, partnerId, expId).pipe(
          mergeMap(res => {
            // we now need to dispatch two actions
            // 1. to update the UI by calling getPartyDetails -> called by RefreshPartyDataAction
            // 2. to update the state with UpdateDaSuccessAction
            return [new profile.RefreshPartyDataAction(), new profile.UpdateDaSuccessAction(data)];
          }),
          catchError(err => of(new profile.UpdateDaFailAction(err)))
        )
      )
    )
  );

  /**
   * This effect is used to update MyAccount email when using AltAuth
   *
   * @memberof ProfileEffects
   */

  updateMyAccountEmail = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.UPDATE_EMAIL),
      map((action: profile.UpdateEmailAction) => action.payload),
      switchMap((data: UpdateEmailData) =>
        this.alternateAuthService.updateEmail(data).pipe(
          map(res => new profile.UpdateEmailSuccessAction(data)),
          catchError(err => of(new profile.UpdateEmailFailAction(err)))
        )
      )
    )
  );

  /**
   * this effect is used for editing security questions or password
   *
   * @memberof ProfileEffects
   */

  changeSecurityInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.CHANGE_SECURITY_INFO),
      map((action: profile.ChangeSecurityInfoAction) => action.payload),
      withLatestFrom(
        this.store.select(userQuery.getWaid),
        this.store.select(BrandSelectors.getPartnerId),
        this.store.select(BrandSelectors.getExperienceId)
      ),
      switchMap(([data, waid, partnerId, expId]) => {
        const methodType: string = _get(data, 'methodType', '');
        const methodTypeValue = data['data'][methodType];
        let digitalAccount: DigitalAccount;
        if (methodType === 'securityQuestions') {
          digitalAccount = {
            customerId: data.data.customerId,
            securityQuestions: methodTypeValue
          };
        } else if (methodType === 'password') {
          digitalAccount = {
            customerId: data.data.customerId,
            password: methodTypeValue
          };
        } else if (methodType === 'userId') {
          digitalAccount = {
            userId: methodTypeValue
          };
        } else {
          // TODO: ChangeSecurityInfoFailAction does not do anything
          return of(
            new profile.ChangeSecurityInfoFailAction({
              error: 'invalid payload'
            })
          );
        }
        return this.daService.updateDigitalAccount(digitalAccount, waid, partnerId, expId).pipe(
          mergeMap(res => {
            res.methodType = methodType;
            res.hideToaster = data.hideToaster;

            if (methodType === 'userId') {
              return [
                new profile.ChangeSecurityInfoSuccessAction(data),
                new fromUserActions.EditUserId(methodTypeValue)
              ];
            }
            return res.status.code === 200
              ? [new profile.ChangeSecurityInfoSuccessAction(data)]
              : [new profile.ChangeSecurityInfoFailAction(res)];
          }),
          catchError(err => {
            err.methodType = methodType;
            err.hideToaster = data.hideToaster;
            return of(new profile.ChangeSecurityInfoFailAction(err));
          })
        );
      })
    )
  );
  /**
   * Email the user when something changes
   *
   * @memberof ProfileEffects
   */

  sendNotificationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.CHANGE_CONTACT_ENTRY_SUCCESS),
      map((action: profile.ChangeContactEntrySuccessAction) => action.payload),
      withLatestFrom(
        this.store.select(userQuery.getUserState),
        this.store.select(BrandSelectors.getPartnerId)
      ),
      switchMap(([data, userState, partnerId]) =>
        this.communicationService
          .sendEmailNotificationForProfile(
            data.methodType,
            partnerId,
            userState.customerId,
            userState.emailAddress,
            userState.firstName
          )
          .pipe(
            map(res => new profile.EmailNotificationSuccessAction()),
            catchError(err => of(new profile.EmailNotificationFailAction(err)))
          )
      )
    )
  );

  /**
   * Send an email to the new email address
   * with a link to confirm the new address
   *
   * @memberof ProfileEffects
   */

  validateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.VALIDATE_EMAIL),
      map((action: profile.ValidateEmailAction) => action.payload),
      switchMap((data: profile.ValidateEmailActionPayload) =>
        this.dspService.validate(data.newEmail.emailAddress).pipe(
          map((res: EmailValidationResponse) => {
            if (res.status && res.status.code === 200) {
              this.cookiesService.removeItem('isDuplicateEmail');
            }
            return res.status && res.status.code === 200
              ? new profile.ValidateEmailSuccessAction()
              : new profile.ValidateEmailFailAction();
          }),
          catchError(err => of(new profile.ValidateEmailFailAction(err)))
        )
      )
    )
  );

  /**
   *  New email status for Make Primary
   */
  emailStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profile.ProfileActionTypes.GET_VALIDATION_STATUS_FOR_PENDING_EMAIL),
      switchMap(() => {
        return this.dspService.getValidationStatus('').pipe(
          mergeMap((response: EmailValidationResponse) => {
            if (_get(response, 'emailProfile.confirmed')) {
              const emailAddress = _get(response, 'emailProfile.emailAddress', '');
              return [
                new profile.MakeRequestAction(), // start the spinner
                new profile.UpdateDaAction({ emailAddress })
              ];
            } else {
              return [
                new profile.MakeRequestAction(),
                new profile.GetValidationStatusForPendingEmailSuccessAction({
                  // email has not been validated
                  newEmail: _get(response, 'emailProfile.emailAddress'),
                  tokenExpiry: _get(response, 'emailProfile.tokenExpired')
                })
              ];
            }
          }),
          catchError(error => of(new profile.GetValidationStatusForEmailFailAction(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private communicationService: CommunicationService,
    private daService: DigitalAccountService,
    private featureFlagService: FeatureFlagService,
    private dspService: DigitalServiceProgramService,
    private alternateAuthService: AlternateAuthService,
    private partyService: PartyService,
    private store: Store<any>,
    private profileUtilService: ProfileUtilService,
    private cookiesService: CookiesService
  ) {}
}

import { Address } from '@amfam/policy/models';
import { Phone, Email } from '@amfam/shared/models';

export class Customer {
  firstName: string;
  lastName: string;
  middleName: string;
  phones: Array<Phone>;
  addresses: Array<Address>;
  emails: Array<Email>;
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// rxjs
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ClaimDashboardService } from '../services/claim-dashboard.service';
// Store
import {
  ClaimDashboardActionTypes,
  fromClaimDashboardActions,
  LoadClaimDashboard
} from './claim-dashboard.actions';
import { DashboardClaim } from './claim-dashboard.models';

@Injectable()
export class ClaimDashboardEffects {
  loadDashboardClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimDashboardActionTypes.LoadClaimDashboard),
      map((action: LoadClaimDashboard) => action.payload),
      filter(payload => !!payload.cdhId),
      switchMap(payload =>
        this.claimDashboardService.getDashboardClaims(payload.cdhId).pipe(
          map((response: DashboardClaim[]) => {
            return new fromClaimDashboardActions.LoadClaimDashboardSuccess(response);
          }),
          catchError(error => of(new fromClaimDashboardActions.LoadClaimDashboardFail(error)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private claimDashboardService: ClaimDashboardService) {}
}

import {
  AuthCopy,
  BillingCopy,
  ClaimsCopy,
  ContactAdjusterCopy,
  DefaultQuickLinksCopy,
  EnrollmentCopy,
  FaqCopy,
  GoPaperlessCopy,
  KydCopy,
  KydSmartphoneCopy,
  KydSmartphonePhhCopy,
  OpportunityContent,
  OverviewCopy,
  PolicyCopy,
  PolicyDetailCopy,
  ProfileCopy,
  SharedCopy,
  FirstPartyFnolCopy,
  ThirdPartyFnolCopy,
  OpportunityContentCopy
} from '../models';
export interface AllCopy {
  auth: AuthCopy;
  billing: BillingCopy;
  claims: ClaimsCopy;
  contactAdjuster: ContactAdjusterCopy;
  defaultQuickLinks: DefaultQuickLinksCopy;
  enrollment: EnrollmentCopy;
  faq: FaqCopy;
  goPaperless: GoPaperlessCopy;
  kyd: KydCopy;
  kydSmartphone: KydSmartphoneCopy;
  kydSmartphonePhh: KydSmartphonePhhCopy;
  overview: OverviewCopy;
  opportunity: OpportunityContent[];
  policy: PolicyCopy;
  policyDetail: PolicyDetailCopy;
  profile: ProfileCopy;
  shared: SharedCopy;
  firstPartyFnol: FirstPartyFnolCopy;
  thirdPartyFnol: ThirdPartyFnolCopy;
  opportunityContent: OpportunityContentCopy
}

import { GoPaperlessCopy } from './go-paperless-copy';

export interface FaqCopy {
  heading: string;
  goPaperless: FaqQuestion[];
  policy: FaqQuestion[];
  propertyClaims: FaqQuestion[];
  autoClaims: FaqQuestion[];
  parnterPolicy: FaqQuestion[];
  parnterBilling: FaqQuestion[];
}

export interface FaqQuestion {
  title: string;
  body: string;
}

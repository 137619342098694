export interface AuthCopy {
  addSecurityQuestionsError: string;
  addSecurityQuestionsHeader: string;
  addSecurityQuestionsSuccess: string;
  addSecurityQuestionsText: string;
  conversionLogin: string;
  cookieText: string;
  emailValidationHeader: string;
  emailValidationSubHeader: string;
  emailValidationLoginText: string;
  emailValidationLoginButton: string;
  emailValidationSearchButton: string;
  emailValidationSearchText: string;
  emailValidationSearchError: string;
  emailValidationPhoneHeader: string;
  emailValidationPhoneTextButton: string;
  emailValidationPhoneCallButton: string;
  emailValidationPhoneText: string;
  emailValidationPhoneError: string;
  emailValidationPhoneGenericError: string;
  emailValidationConfirmHeaderText: string;
  emailValidationConfirmHeaderCall: string;
  emailValidationConfirmTextPart1: string;
  emailValidationConfirmTextPart2: string;
  emailValidationConfirmCallPart1: string;
  emailValidationConfirmCallPart2: string;
  emailValidationConfirmResendCode: string;
  emailValidationConfirmError: string;
  emailValidationConfirmationHeader: string;
  emailValidationConfirmationNextStep: string;
  emailValidationInvalidAttemptsHeader: string;
  emailValidationInvalidAttemptsText: string;
  enterUserId: string;
  forgotPasswordHelpText: string;
  forgotPasswordResetText: string;
  forgotPasswordResetTextAcceptId: string;
  forgotPasswordSecurityErrorText: string;
  forgotPasswordUserIdErrorText: string;
  forgotPasswordUserIdHeadingText: string;
  forgotUseridCheckSpamText: string;
  forgotUseridGetButtonText: string;
  forgotUseridMismatchText: string;
  forgotUseridMismatchText2: string;
  forgotUseridResetText1: string;
  forgotUseridResetText2: string;
  forgotUseridTroubleText: string;
  genericErrorTryAgain: string;
  invalidTokenChatText: string;
  invalidTokenCopyPasteText: string;
  invalidTokenExpired: string;
  invalidTokenHelpText: string;
  invalidTokenSuggestionText: string;
  loginAccountLocked1: string;
  loginAccountLockedAlt: string;
  loginAccountText: string;
  loginForgotPasswordText: string;
  loginForgotUserText: string;
  loginIncorrectText: string;
  loginWithEmailIncorrectText: string;
  loginWithUserIdIncorrectText: string;
  loginIncorrectGenericText: string;
  loginWithEmailLockWarning: string;
  loginLockWarning: string;
  loginTrouble: string;
  loginTroubleNumber: string;
  loginUnlock: string;
  logoutToasterMessage: string;
  resetOptionsHeader: string;
  resetOptionsHeaderFailedAttempts: string;
  resetOptionsGenericError: string;
  resetOptionsEmailLink: string;
  resetOptionsSecurityQuestionsLink: string;
  resetOptionsCallLink: string;
  resetOptionsTextLink: string;
  resetOptionsAnswerText: string;
  resetOptionsCheckSpam: string;
  resetOptionsResendText: string;
  resetOptionsEmailHeader: string;
  resetOptionsEmailText: string;
  resetOptionsPhoneLocked: string;
  resetOptionsCodeHeader: string;
  resetOptionsCodeExpired: string;
  resetOptionsCallHeader: string;
  resetOptionsTextHeader: string;
  resetPasswordCreate: string;
  resetPasswordHeader: string;
  resetPasswordLogInText: string;
  resetPasswordGenericError: string;
  resetPasswordInvalidPassword: string;
  securityQtnAnswerMismatch: string;
  securityQtnNumberAFI: string;
  securityQtnOtherOptions1: string;
  securityQtnOtherOptions2: string;
  securityQtnResetHelpText: string;
  securityQtnTrouble: string;
  sessionExpiredFYIText: string;
  sessionExpiredLogInText: string;
  sessionExpiredLogOutText: string;
  sessionTimeoutDoneText: string;
  sessionTimeoutHereText: string;
  sessionTimeoutIdleText: string;
  duplicateEmailForgotUserIdError: string;
  duplicateEmailForgotPasswordError: string;
  duplicateEmailloginTrouble: string;
}

<p class="subheading">{{ subHeading }}</p>
<p></p>
<div class="pad-top-20">
  <ds-radio-group
    data-cy="paymentFrequencyOptionsRadioButtons"
    class="radio-group-container"
    [control]="'paymentFrequency'"
    [selectedAnswer]=""
    [parentFormGroup]="formGroup"
    [options]="paymentFrequencyOptions"
  >
  </ds-radio-group>
</div>
<ds-warning-box
  *ngIf="displayAllAtOnceWarning"
  [headingText]="displayAllAtOnceWarningHeading"
  [messageText]="displayAllAtOnceWarningBody"
>
</ds-warning-box>

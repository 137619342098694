export interface AddressLookupModel {
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateCode: string;
  zip5Code: string;
  zip4Code: string;
}

export interface AddressLookupResponse {
  address: AddressLookupModel;
  valid: boolean;
}

import { Action } from '@ngrx/store';
import { GetEligibleServicesPayload, EligibleServicesResponse } from './eligible-services.models';

export enum EligibleServicesActionTypes {
  GetEligibleServices = '[Eligible Services] Get Eligible Services',
  GetEligibleServicesSuccess = '[Eligible Services] Get Eligible Services Success',
  GetEligibleServicesFail = '[Eligible Services] Get Eligible Services Fail',
  ResetEligibleServices = '[Eligible Services] Reset Eligible Services'
}

export class GetEligibleServices implements Action {
  readonly type = EligibleServicesActionTypes.GetEligibleServices;
  constructor(public payload: GetEligibleServicesPayload) {}
}

export class GetEligibleServicesSuccess implements Action {
  readonly type = EligibleServicesActionTypes.GetEligibleServicesSuccess;
  constructor(public payload: EligibleServicesResponse) {}
}

export class GetEligibleServicesFail implements Action {
  readonly type = EligibleServicesActionTypes.GetEligibleServicesFail;
  constructor(public payload?: string) {}
}

export class ResetEligibleServices implements Action {
  readonly type = EligibleServicesActionTypes.ResetEligibleServices;
  constructor() {}
}

export type EligibleServicesAction =
  | GetEligibleServices
  | GetEligibleServicesSuccess
  | GetEligibleServicesFail
  | ResetEligibleServices;

export const fromEligibleServicesActions = {
  GetEligibleServices,
  GetEligibleServicesSuccess,
  GetEligibleServicesFail,
  ResetEligibleServices
};

import { get as _get } from 'lodash';
import { BrandModel, initialBrandModel } from '../models/brand.model';
import * as brandActions from './brand.actions';

export const BRAND_FEATURE_KEY = 'brand';

export function brandReducer(
  state = initialBrandModel,
  action: brandActions.BrandActions
): BrandModel {
  switch (action.type) {
    case brandActions.LOAD_BRAND_DATA:
      return Object.assign({}, initialBrandModel, {
        experienceId: action.payload.expid,
        loading: true
      });

    case brandActions.LOAD_BRAND_DATA_SUCCESS:
      return Object.assign({}, action.payload, {
        loading: false,
        loaded: true,
        hasError: false
      });

    case brandActions.LOAD_BRAND_DATA_FAILURE:
      return Object.assign({}, initialBrandModel, {
        loading: false,
        loaded: true,
        hasError: true
      });

    case brandActions.LOAD_USER_BRAND_DATA_SUCCESS:
      return Object.assign({}, state, {
        userBrandLoaded: true,
        hasError: false
      });

    case brandActions.LOAD_USER_BRAND_DATA_FAILURE:
      return Object.assign({}, state, {
        userBrandLoaded: false,
        hasError: true
      });

    case brandActions.UPDATE_PROPERTY_SERVICE_URL:
      const brand: BrandModel = Object.assign({}, state);
      brand.data.propertyServiceUrl = action.payload;
      return brand;

    default:
      return state;
  }
}

export const assetPath = (state: BrandModel) => state.data.assetPath;
export const billingContactEmailFaq = (state: BrandModel) => state.data.billingContactEmailFaq;
export const brandId = (state: BrandModel) => state.brandId;
export const claimPhoneNum = (state: BrandModel) =>
  _get(state, 'data.claimPhoneNum', '1-800-692-6326');
export const claimPhoneNumAuto = (state: BrandModel) => state.data.claimPhoneNumAuto;
export const claimPhoneNumProperty = (state: BrandModel) => state.data.claimPhoneNumProperty;
export const claimErsPhoneNum = (state: BrandModel) => state?.data?.claimErsPhoneNum;
export const claimGlassPhoneNum = (state: BrandModel) => state?.data?.claimGlassPhoneNum;
export const company = (state: BrandModel) => state.data.company;
export const cusCare247 = (state: BrandModel) => state.data.cusCare247;
export const cusCareEmail = (state: BrandModel) => state.data.cusCareEmail;
export const cusCareHoursWeekday = (state: BrandModel) => state.data.cusCareHoursWeekday;
export const cusCareHoursFriday = (state: BrandModel) => state.data.cusCareHoursFriday;
export const cusCareHoursWeekend = (state: BrandModel) => state.data.cusCareHoursWeekend;
export const cusCareNumber = (state: BrandModel) => state.data.cusCareNumber;
export const customerCareTimezone = (state: BrandModel) => state.data.customerCareTimezone;
export const customerCareWeekdayEnd = (state: BrandModel) => state.data.customerCareWeekdayEnd;
export const customerCareWeekdayStart = (state: BrandModel) => state.data.customerCareWeekdayStart;
export const experienceClass = (state: BrandModel) => state.data.experienceClass;
export const experienceId = (state: BrandModel) => state.experienceId;
export const hasError = (state: BrandModel) => !state.loading && state.hasError;
export const isGenericPartner = (state: BrandModel) => state.isGenericPartner;
export const isPartner = (state: BrandModel) => _get(state, 'isPartner');
export const landingPageDomain = (state: BrandModel) => state.data.landingPageDomain;
export const landingPagePath = (state: BrandModel) => state.data.landingPagePath;
export const landingPageUrl = (state: BrandModel) => state.data.landingPageUrl;
export const landingPage = (state: BrandModel) => state.data.landingPage;
export const loaded = (state: BrandModel) => !state.loading && state.loaded;
export const loading = (state: BrandModel) => !state.loading && state.loading;
export const myaccountSubdomain = (state: BrandModel) => state.data.myaccountSubdomain;
export const partnerId = (state: BrandModel) => state.partnerId;
export const accountId = (state: BrandModel) => state.accountId;
export const partnerNameInUrl = (state: BrandModel) => state.data.partnerNameInUrl;
export const propertyServiceSSO = (state: BrandModel) => state.data.propertyServiceSSO;
export const propertyServiceUrl = (state: BrandModel) => state.data.propertyServiceUrl;
export const userBrandLoaded = (state: BrandModel) => state.userBrandLoaded;
export const brandHeaderLogo = (state: BrandModel) => state.data.brandHeaderLogo;
export const brandFooterLogo = (state: BrandModel) => state.data.brandFooterLogo;

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'call-to-action',
  styleUrls: ['./call-to-action.component.scss'],
  templateUrl: './call-to-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallToActionComponent {
  @Input() primaryButtonName: string;
  @Input() secondaryButtonName: string;
  @Input() tertiaryButtonName: string;
  @Input() backButtonName = 'Back';
  @Input() fullWidthButtons = false;
  @Input() actionPanel = false;

  @Input() primaryButtonDestructive = false;
  @Input() secondaryButtonDestructive = false;
  @Input() tertiaryButtonDestructive = false;

  @Input() displayBackButton = false;

  @Input() disablePrimaryButton = false;
  @Input() disableSecondaryButton = false;
  @Input() disabled = false;
  @Input() capitalize = false;
  @Input() dsErrorAction = false;
  @Input() equalWidthButtons = false;

  @Output() primaryEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() tertiaryEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}

<div class="main">
  <div class="row">
    <div class="column twelve sm-ten sm-push-one md-eight md-push-two lg-six lg-push-three">
      <div class="content">
        <h1>
          <span
            data-cy="needHelpSigningInHeader"
            content
            [contentPath]="'auth.forgotPasswordHelpText'"
          ></span>
        </h1>
        <p>
          <span content [contentPath]="'auth.forgotUseridResetText1'"></span>
          <!-- <span dsPartnerContent [brandContent]="'getCompany'"></span> -->
          <span *dsPartnerAFI>American Family Insurance</span>
          <span *dsPartnerConnect>Midvale Insurance</span>
          <span content [contentPath]="'auth.forgotUseridResetText2'"></span>
        </p>
        <div class="row"><hr aria-hidden="true" /></div>
        <form
          [formGroup]="acceptEmailForm"
          (ngSubmit)="acceptEmailForm.valid && retrieveUserIdSetup()"
        >
          <ds-form-control-container [labelText]="'Email Address'" [control]="email">
            <input
              [focus]="emailInputFocus"
              data-cy="forgotUserIdEmailInput"
              type="text"
              id="email"
              class="fill"
              formControlName="email"
            />
            <ds-form-control-message [control]="email"></ds-form-control-message>
            <small class="block caption error margin-top-5" *ngIf="emailNotFoundError">
              <span
                data-cy="forgotUserIdMismatchText"
                content
                [contentPath]="'auth.forgotUseridMismatchText'"
              ></span>
              <span dsPartnerContent [brandContent]="'getPrettyCusCareNumber'"></span>
              <span content [contentPath]="'auth.forgotUseridMismatchText2'"></span>
            </small>
            <small
              class="block caption error margin-top-5"
              *ngIf="unexpectedError"
              content
              [contentPath]="'auth.genericErrorTryAgain'"
            ></small>
            <p *ngIf="duplicateEmailError && partnerId === 'AFI'" class="duplicateEmailError">
              <span>{{ dupEmailError }}</span>
            </p>
          </ds-form-control-container>
          <div class="row">
            <div class="column twelve">
              <a
                data-cy="returnToLogInLink"
                href="javascript:void(0);"
                [dsRouterLink]="['/login']"
                class="inline-block margin-top-20 caption"
                ><i class="icon-chevron-left"></i>Return to log in</a
              >
              <button
                ds-button
                type="submit"
                id="continueBtn"
                data-cy="sendUserIdButton"
                class="-outline float-right"
                [disabled]="!acceptEmailForm.valid || !responseReceived"
                content
                [contentPath]="'auth.forgotUseridGetButtonText'"
              ></button>
            </div>
          </div>
        </form>
        <div class="row"><hr aria-hidden="true" /></div>
        <div *ngIf="emailFound" class="row">
          <small content [contentPath]="'auth.forgotUseridCheckSpamText'"></small>
        </div>
        <div class="row">
          <div class="column twelve pad-top-5">
            <small content [contentPath]="'auth.loginTrouble'"></small>
            <a
              *dsPartnerAFI
              href="javascript:void(0)"
              (click)="openContactUs()"
              class="caption"
              content
              [contentPath]="'auth.loginTroubleNumber'"
            ></a>
            <a
              *dsPartnerConnect
              class="caption"
              dsPartnerContent
              [brandContent]="'getPrettyCusCareNumber'"
              [brandHref]="'getCusCareNumberFullHref'"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

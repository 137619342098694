import { Injectable } from '@angular/core';
import { get as _get } from 'lodash';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  ConfigService,
  CommunicationService,
  InternalEmail,
  TemplatedEmail,
  Addressee
} from '@amfam/shared/utility/shared-services';
import { ContactBillingInternalEmail } from './contact-billing-internalEmail.model';
import { ContactBillingCustomerEmail } from './contact-billing-customer-email.model';
import * as fromRoot from './../../core/store';
import { BrandSelectors } from '@amfam/shared/utility/brand';

@Injectable({
  providedIn: 'root'
})
export class BillAccountContactService {
  private partnerId: string;
  private isConnectPartner: boolean;
  private partnerSub: Subscription;

  constructor(
    private communicationService: CommunicationService,
    private store: Store<fromRoot.RootState>,
    private config: ConfigService
  ) {
    this.partnerSub = this.getPartnerData().subscribe(res => {
      this.partnerId = res[0];
      this.isConnectPartner = res[1];
    });
  }

  public generateInternalEmails(model: ContactBillingInternalEmail): Promise<void> {
    const email: InternalEmail = new InternalEmail();
    const emailAddress: string = this.getEmailAddress(model);
    email.messageFrom = new Addressee(
      this.config.get('billingInternalFromEmail'),
      'Mailbox, amfam.com/My Account'
    );
    email.messageToList.push(new Addressee(emailAddress, 'Billing Team'));
    email.messageSubject = 'American Family Insurance Contact Billing';
    email.messageContentType = 'text/html';
    email.messageContent = this.generateInternalEmailContent(model);
    return this.communicationService.sendInternalEmail(email).toPromise();
  }

  private generateInternalEmailContent(model: ContactBillingInternalEmail): string {
    let content = `Dear Billing Team,<br><br>`;
    content += `Your customer below has submitted a billing request to his/her billing account number from My Account.<br><br>`;
    content += `Please contact the customer within two business days to discuss their request.<br><br>`;
    content += `Thank you,<br>The amfam.com Team`;
    content += `<br><br><hr/><br><br>Request Change<br>`;
    content += `The following is the detail from customer.<br><br>`;
    content += `<table>`;
    content += `<table><tr><td>Last Name</td><td>${model.lastName}</td></tr>`;
    content += `<tr><td>First Name</td><td>${model.firstName}</td></tr>`;
    content += `<tr><td></td>Reason to Contact<td>${model.prefferedContactReason}</td></tr>`;
    content += `<tr><td>Method of Contact</td><td>${model.prefferedContactMode}</td></tr>`;
    content += `<tr><td>Email</td><td>${model.email}</td></tr>`;
    content += `<tr><td>Phone</td><td>${model.phone}</td></tr>`;
    content += `<tr><td>Billing Account Number</td><td>${model.billAccountNumber}</td></tr>`;
    content += `<tr><td>Comments</td><td>${model.comment}</td></tr>`;
    content += `</table>`;
    content += `<br><br><hr/>`;

    return content;
  }

  public disposeSub() {
    if (this.partnerSub) {
      this.partnerSub.unsubscribe();
    }
  }

  private getPartnerData(): Observable<any> {
    return combineLatest(
      this.store.select(BrandSelectors.getPartnerId),
      this.store.select(BrandSelectors.getIsPartner)
    );
  }

  public generateCustomerEmail(model: ContactBillingCustomerEmail) {
    // AS: TODO get the correct codes, these are copied from claims.
    const email = new TemplatedEmail(
      'MCRC',
      this.partnerId,
      'MyAccount',
      'EN',
      model.billAccNum,
      model.email
    );

    return this.communicationService.sendTemplatedEmail(email).toPromise();
  }

  private getEmailAddress(model: ContactBillingInternalEmail) {
    // If Connect Partner, send to Connect mailbox
    if (this.isConnectPartner) {
      return this.config.get('partnerBillingEmail');
    }
    // if its not partner send anything to care centre team
    else {
      return this.config.get('billingCareCentreEmail');
    }
  }
}

import { Coverage } from '@amfam/policy/models';
import { CoverageTerm } from '@amfam/policy/models';

export class CoverageDeductibleLimit {
  public coverage: Coverage;
  public deductible: CoverageTerm;
  public limit: CoverageTerm;

  constructor(coverage: Coverage, deductible: CoverageTerm, limit: CoverageTerm) {
    this.coverage = coverage;
    this.deductible = deductible;
    this.limit = limit;
  }
}

import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '@amfam/shared/utility/shared-services';
import {
  ResetOptionRequest,
  ResetOptionModel,
  VerifyPinRequest,
  ResetPasswordSubmitRequest,
  ResetPasswordSubmitModel,
  VerifySecurityQuestionsModel,
  VerifySecurityQuestionsRequest
} from '@amfam/shared/models';

@Injectable()
export class ForgotService {
  private digitalAccountApi: string;
  private partnerId: string;
  private userIdentifierEmail = false;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.digitalAccountApi = this.config.get('digitalAccountApi');
  }

  /**
   * MR: Takes in userIdentifier + partnerId and searches for user info
   * @param userIdentifier - Will be either email or userId
   * @param partnerId - Partner ID
   */
  findUser(userIdentifier: string, partnerId: string): Observable<any> {
    // Reset variables
    this.partnerId = partnerId;
    this.userIdentifierEmail = false;

    const searchType = this.checkIfInputIsEmail(userIdentifier);
    const endpoint =
      this.digitalAccountApi + '/digitalaccounts?' + searchType + '&partnerId=' + partnerId;

    return this.http.get(endpoint);
  }

  /**
   * MR: Checks userIdentifier to see if it as an email and then returns appropriate search type string
   * @param userIdentifier - Will be either email or userId
   */
  private checkIfInputIsEmail(userIdentifier: string): string {
    if (userIdentifier.includes('@')) {
      this.userIdentifierEmail = true;
      return 'emailAddress=' + encodeURIComponent(userIdentifier);
    } else {
      this.userIdentifierEmail = false;
      return 'userId=' + userIdentifier;
    }
  }

  /**
   * MR: Sends verification request via email, call, or text.
   * @param requestModel - Contains specific request data for email, call, or text
   */
  sendVerificationRequest(requestModel: ResetOptionModel): Observable<any> {
    const payload: ResetOptionRequest = this.buildResetOptionRequestPayload(requestModel);

    return this.http.post(this.digitalAccountApi + '/digitalaccounts/forgotpassword', payload);
  }

  /**
   * MR: Builds verification payload specific to method used and whether email/userId is used as identifier
   * @param requestModel - Contains specific request data for email, call, or text
   */
  private buildResetOptionRequestPayload(requestModel: ResetOptionModel): ResetOptionRequest {
    return {
      userId: !this.userIdentifierEmail ? requestModel.userIdentifier : undefined,
      email: this.userIdentifierEmail ? requestModel.userIdentifier : undefined,
      partnerId: this.partnerId,
      typeOfVerificationMethodCode: requestModel.typeOfVerificationMethodCode,
      maskedPhoneNumber: requestModel.maskedPhoneNumber ? requestModel.maskedPhoneNumber : undefined
    };
  }

  /**
   * MR: Verifies the pincode or correlationid received via email or phone options
   * @param requestObj - Contains pincode or correlationId
   */
  verifyToken(requestObj: VerifyPinRequest): Observable<any> {
    const url = this.digitalAccountApi + '/digitalaccounts/validatetoken';

    return this.http.post(url, requestObj);
  }

  /**
   * MR: Verifies that the security question answers are correct
   * @param requestModel - Contains security question answers + user identifier
   */
  verifySecurityQuestions(requestModel: VerifySecurityQuestionsModel): Observable<any> {
    const endpoint = this.digitalAccountApi + '/digitalaccounts/forgotpassword';
    const payload: VerifySecurityQuestionsRequest = {
      userId: !this.userIdentifierEmail ? requestModel.userIdentifier : undefined,
      email: this.userIdentifierEmail ? requestModel.userIdentifier : undefined,
      partnerId: this.partnerId,
      securityQuestions: requestModel.securityQuestions
    };

    return this.http.post(endpoint, payload);
  }

  /**
   * MR: Submits a request to reset user password
   * @param requestModel - Contains verification data + newPassword for password reset
   */
  resetPassword(requestModel: ResetPasswordSubmitModel): Observable<any> {
    const payload: ResetPasswordSubmitRequest = this.buildResetPasswordRequestPayload(requestModel);

    let urlToAppend: string;
    if (payload.correlationId || payload.pinCode) {
      urlToAppend = '/digitalaccounts/resetpassword';
    } else if (payload.securityQuestions) {
      urlToAppend = '/digitalaccounts/forgotpassword';
    } else {
      return observableThrowError(null);
    }
    return this.http.post(this.digitalAccountApi + urlToAppend, payload);
  }

  /**
   * MR: Builds the reset password payload based on verification type
   * @param requestModel - Contains verification data + newPassword for password reset
   */
  private buildResetPasswordRequestPayload(
    requestModel: ResetPasswordSubmitModel
  ): ResetPasswordSubmitRequest {
    return {
      userId: !this.userIdentifierEmail ? requestModel.userIdentifier : undefined,
      email: this.userIdentifierEmail ? requestModel.userIdentifier : undefined,
      partnerId: this.partnerId,
      pinCode: !!requestModel.pinCode ? requestModel.pinCode : undefined,
      correlationId: !!requestModel.correlationId ? requestModel.correlationId : undefined,
      securityQuestions: !!requestModel.securityQuestions
        ? requestModel.securityQuestions
        : undefined,
      newPassword: requestModel.newPassword
    };
  }
}

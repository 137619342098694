<confirmation
  [config]="confirmationConfig$ | async"
  (primaryEvent)="primaryButtonClick()"
  (tertiaryEvent)="done()"
>
  <div content>
    <p *ngIf="(submitStatus$ | async) === submitStatuses.SUCCESS" class="margin-bottom-20">
      {{ successBodyText | async }}
    </p>
    <p *ngIf="(submitStatus$ | async) === submitStatuses.FAILURE" class="margin-bottom-20">
      {{ failureBodyText | async }}
    </p>
    <p *ngIf="(submitStatus$ | async) === submitStatuses.PARTIAL_FAILURE" class="margin-bottom-20">
      {{ partialFailureBodyTextOne }}
    </p>

    <p
      *ngIf="
        (submitStatus$ | async) === submitStatuses.FAILURE ||
        (submitStatus$ | async) === submitStatuses.PARTIAL_FAILURE
      "
      class="subheading margin-bottom-5"
    >
      Failed
    </p>
    <ds-auto-pay-review-item
      *ngFor="let item of failedReviewItems$ | async"
      [config]="item"
    ></ds-auto-pay-review-item>
    <p
      *ngIf="(submitStatus$ | async) === submitStatuses.PARTIAL_FAILURE"
      class="subheading margin-bottom-5"
    >
      Successful
    </p>
    <p *ngIf="(submitStatus$ | async) === submitStatuses.SUCCESS" class="subtitle">
      AutoPay details
    </p>
    <ds-auto-pay-review-item
      data-cy="autoPaySuccessfulConfirmationReviewItem"
      *ngFor="let item of successfulReviewItems$ | async"
      [config]="item"
    ></ds-auto-pay-review-item>
  </div>
  <p *ngIf="(submitStatus$ | async) === submitStatuses.PARTIAL_FAILURE" class="margin-top-20">
    {{ partialFailureBodyTextTwo }}
  </p>
</confirmation>

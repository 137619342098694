import { Directive, Input, ElementRef, Renderer2, OnInit, OnChanges } from '@angular/core';
import { CopyService } from './copy.service';
import { Applications } from '../application/application.enum';

@Directive({
  selector: '[content]'
})
export class ContentDirective implements OnInit, OnChanges {
  @Input()
  contentPath: string;

  @Input()
  options: any; // Object containing keys for interpolation

  @Input()
  applicationDependentCopy?: boolean;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private copyService: CopyService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.contentPath) {
      if (!!this.applicationDependentCopy) {
        this.renderer.setProperty(
          this.element.nativeElement,
          'innerHTML',
          this.copyService.getCopy(this.contentPath, this.options, !!this.applicationDependentCopy)
        );
      } else {
        this.renderer.setProperty(
          this.element.nativeElement,
          'innerHTML',
          this.copyService.getCopy(this.contentPath, this.options)
        );
      }
    } else {
      this.renderer.setProperty(this.element.nativeElement, 'innerHTML', '');
    }
  }
}

<!-- Email -->
<div *ngIf="!showEmailSent">
  <div class="row">
    <div class="column twelve text-left">
      <h3 content [contentPath]="'enrollment.verifyEmailConfirm'"></h3>
      <p><span content [contentPath]="'enrollment.verifyEmailContinueText'"></span></p>
    </div>
  </div>
  <ds-verify-email [maskedEmails]="emailList" (submittedEmailEvent)="sendEmail($event)">
  </ds-verify-email>
  <small
    class="block caption error margin-top-5"
    *ngIf="gotError && !showEmailSent"
    content
    [contentPath]="'enrollment.verifyEmailNotFound'"
  ></small>
</div>

<div class="row">
  <div class="column twelve">
    <div *ngIf="showEmailSent">
      <p>
        <span content [contentPath]="'enrollment.verifyEmailSentPart1'"></span>{{ email
        }}<span content [contentPath]="'enrollment.verifyEmailSentPart2'"></span>
      </p>
      <button
        ds-button
        class="link"
        [disabled]="!isButtonActive"
        (click)="sendEmail(email)"
        content
        [contentPath]="'enrollment.verifyEmailResend'"
      ></button>
      <small
        class="block caption error margin-top-5"
        *ngIf="gotError"
        content
        [contentPath]="'enrollment.verifyEmailNotFound'"
      ></small>
    </div>
    <a
      href="javascript:void(0)"
      (click)="goBackOneStep()"
      class="inline-block margin-top-20 caption"
      ><span class="icon-chevron-left"></span> Back</a
    >
  </div>
</div>

export class PendingRegistration {
  associated: boolean;
  billAccountNumber: string;
  policyNumber: string;
}

export const initialPendingRegistration: PendingRegistration = {
  associated: null,
  billAccountNumber: null,
  policyNumber: null
};

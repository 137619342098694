import { Pipe, PipeTransform } from '@angular/core';
import { PartyEmail } from '@amfam/shared/models';
import { get as _get } from 'lodash';

@Pipe({
  name: 'emailDescription'
})
export class EmailDescriptionPipe implements PipeTransform {
  transform(value: PartyEmail): string {
    if (_get(value, 'emailAddress')) {
      return value.emailAddress;
    }
  }
}

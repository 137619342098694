import { PageAnalytic } from '@amfam/shared/utility/shared-services';

export class EnrollAnalytics {
  public static readonly enrollTermsPageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:AgentProgramEnrollment',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Agent Enrollment',
    subCategory2: 'Programs',
    subCategory3: ''
  };
}

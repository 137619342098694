import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, empty, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GenericProductType, Policy, PolicySummary } from '@amfam/policy/models';

import { BrandModel } from '@amfam/shared/utility/brand';
import { ConfigService } from '@amfam/shared/utility/shared-services/src/lib/config';

import { PlautoService } from './plauto.service';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { UtilService } from '@amfam/shared/utility/shared-services';
import { LifeService } from './life.service';
import { PlpropertyService } from './plproperty.service';
import { PlumbrellaService } from './plumbrella.service';
import { TypedWorker } from './typed-worker';
@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  private productType = 'products';
  private supportedPolicyTypes = [
    GenericProductType.Auto,
    GenericProductType.Home,
    GenericProductType.Umbrella,
    GenericProductType.Life
  ];

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private plProperty: PlpropertyService,
    private plAutoService: PlautoService,
    private plUmbrellaService: PlumbrellaService,
    private lifeService: LifeService,
    private brandStore: Store<BrandModel>,
    private featureFlagService: FeatureFlagService,
    private utilService: UtilService
  ) {}

  public isPolicyTypeSupported(policyType: GenericProductType): boolean {
    return this.supportedPolicyTypes.includes(policyType);
  }

  public getPolicySummaries(cdhId: string, isPartner: boolean): Observable<PolicySummary[]> {
    const url = this.config.get('partyApi') + 'parties/' + cdhId + '/products';
    const operatorUrl = this.config.get('partyApi') + 'parties/' + cdhId + '/operatoronlyproducts';

    return forkJoin([
      this.http.get(url).pipe(catchError(() => of({ products: [] }))),
      this.featureFlagService.isEnabled('operator_user_insurance_card')
        ? this.http.get(operatorUrl).pipe(catchError(() => of({ products: [] })))
        : of({ products: [] })
    ]).pipe(
      map(([products, operatorProducts]) => {
        const operatorPrds =
          operatorProducts && operatorProducts[this.productType]
            ? operatorProducts[this.productType]
            : [];

        const prds = products && products[this.productType] ? products[this.productType] : [];
        if (operatorPrds.length > 0)
          return [...prds, ...operatorPrds.map(op => ({ ...op, operator: true }))];
        else return [...prds];
      }),
      map(summaries => {
        let filteredSummaries = summaries.filter(
          summary => summary['typeOfProductCode'] === 'POLICY'
        );
        if (!isPartner) {
          filteredSummaries = filteredSummaries.filter(
            summary => summary['sourceSystem'] !== 'HOMESITE'
          );
        }
        return filteredSummaries.map(summary => PolicySummary.fromJson(summary));
      })
    );
  }

  public getPolicyDetails(policySummary: PolicySummary): Observable<Policy> {
    const policyWorker: TypedWorker = this.getPolicyWorker(policySummary.generalizedProductType);
    if (policyWorker) {
      const endpoint = policyWorker.getEndpoint();
      let workerUrl: string;
      if (policySummary.generalizedProductType === GenericProductType.Life) {
        workerUrl = `${endpoint}${policySummary.policyNumber}`;
      } else {
        workerUrl = `${endpoint}${policySummary.policyNumber}?sourceSystem=${policySummary.sourcePath}`;
      }
      return policyWorker.getPolicy(workerUrl, policySummary).pipe(
        map(p => {
          if (p.policyNumber) {
            p.productDescription = policySummary.productDescription;
            p.sourceSystem = policySummary.sourceSystem;
            p.sourcePath = policySummary.sourcePath;
            p.policyStatus = Policy.getPolicyStatusType(p.status ? p.status : policySummary.status);
            p.billingAccountNumber = this.utilService.onlyAlphanumeric(p.billingAccountNumber);
            p.billingAccountNumberDisplay = this.utilService.onlyAlphanumeric(
              p.billingAccountNumberDisplay
            );
            return p;
          } else {
            return p;
          }
        })
      );
    } else {
      return empty();
    }
  }
  /*
    public getPolicy(product: Product): Observable<Policy> {
      const options = this.getOptions({});
      const policyWorker: TypedWorker = this.getPolicyWorker(product.generalizedProductType);
      if (policyWorker) {
        const endpoint = policyWorker.getEndpoint();
        const workerUrl = `${endpoint}${product.policyNumber}?sourceSystem=${product.sourcePath}`;
        return policyWorker.getPolicy(workerUrl, options).map(p => {
          p.status = product.status;
          p.sourceSystem = product.sourceSystemName;
          p.sourcePath = product.sourcePath;
          p.policyStatus = Policy.getPolicyStatusType(p.status);
          return p;
        });
      } else {
        return empty<Policy>();
      }
    }
  */

  public getPolicyWorker(type: GenericProductType): TypedWorker {
    let serviceInstance = null;
    switch (type) {
      case GenericProductType.Auto:
        serviceInstance = this.plAutoService;
        break;
      case GenericProductType.Home:
        serviceInstance = this.plProperty;
        break;
      case GenericProductType.Umbrella:
        serviceInstance = this.plUmbrellaService;
        break;
      case GenericProductType.Life:
        serviceInstance = this.lifeService;
        break;
      default:
      // Return null for now to filter out other product types
      // throw new Error('Could not create policy worker of type: ' + type);
    }
    return serviceInstance;
  }
}

import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PolicySummariesPartialState } from './summaries.reducer';
import { summariesQuery } from './summaries.selectors';
import { LoadSummaries } from './summaries.actions';

@Injectable()
export class SummariesFacade {
  loaded$ = this.store.pipe(select(summariesQuery.getLoaded));
  allSummaries$ = this.store.pipe(select(summariesQuery.getAllPolicySummaries));
  selectedSummaries$ = this.store.pipe(select(summariesQuery.getPolicySummaries));

  constructor(private store: Store<PolicySummariesPartialState>) {}

  loadAll(user: any) {
    this.store.dispatch(new LoadSummaries(user));
  }
}

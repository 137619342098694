export interface ProfileCopy {
  profileHeader: string;
  requestChangeLinkText: string;
  profileText1: string;
  profileText2: string;
  profileText3: string;
  profileText4: string;
  profileText5: string;
  securityInformationTitle: string;
  securityInformationText: string;
  changeUserIdText: string;
  noSecurityQuestionsSecurityInformationText: string;
  contactInformationTitle: string;
  contactInformationText: string;
  communicationPreferencesTitle: string;
  communicationPreferencesHeading: string;
  communicationPreferencesAdminHeading: string;
  communicationPreferencesSubheading: string;
  communicationPreferencesAdminSubheading: string;
  communicationPreferencesClassicMessage: string;
  communicationPreferencesAdvanceMessage: string;
  communicationPreferencesClassicAndAdvanceMessage: string;
  communicationPreferencesAllPaperlessMessage: string;
  communicationPreferencesBillingPaperlessMessage: string;
  communicationPreferencesNoChangeExplanation: string;
  communicationPreferencesGenericWarning: string;
  communicationPreferencesPolicyWarning: string;
  contactBusinessInformationText: string;
  shellAccountEmailAddressText: string;
  yourSSNTitle: string;
  yourSSNText: string;
  addSSNTitle: string;
  addSSNText: string;
  businessNameHeaderText: string;
  enterNewBusinessNames: string;
  securityQuestionsTitle: string;
  securityQuestionsText: string;
  cancelSecurityQuestionEdit: string;
  editUserIdTitle: string;
  editUserIdText: string;
  editPasswordTitle: string;
  editPasswordText: string;
  activeAccountcontactInformationText: string;
  promoteDiscountModalText: string;
  paperlessTitle: string;
  switchToPaperlessButton: string;
  switchSelectedAccountsButton: string;
  loseDiscountModalTitle: string;
  loseDiscountModalText: string;
  loseDiscountModalTitleAdmin: string;
  loseDiscountModalTextAdmin: string;
  switchToPaperButton: string;
  cancelBtn: string;
  manageEmails: ManageEmailsCopy;
  managePhoneNumbers: ManagePhoneNumbersCopy;
  editPassword: EditPasswordCopy;
  editSecurityQuestions: EditSecurityQuestionsCopy;
  addSSN: AddSSNCopy;
  cantChangeSettingsModal: CantChangeSettingsModalCopy;
  mandateTitle: string;
  mandateMainText: string;
  mandateSubText: string;
  mandateEmail: string;
  mandatePhone: string;
  mandateConfirm: string;
  mandateMakeChanges: string;
  mandateLooksGood: string;
  makePrimaryKYDAlertTitle: string;
  makePrimaryKYDAlertText: string;
}

interface AddSSNCopy {
  toasterServiceSSNSaveError: string;
  toasterServiceSSNSaveSuccess: string;
}

interface EditSecurityQuestionsCopy {
  editSecurityQuestionsSuccessToastBody: string;
  editSecurityQuestionsErrorToastBody: string;
}

interface EditPasswordCopy {
  editPasswordSuccessToastBody: string;
  editPasswordFailToastBody: string;
}

interface ManagePhoneNumbersCopy {
  phoneNumbersTitle: string;
  phoneNumbersTitlePartners: string;
  deletePhoneNumbersText: string;
  emptyPhoneNumberState: string;
  addPhoneNumberBtn: string;
  primaryPhoneNumberChange: string;
  primaryPhoneNumberUsageDescription: string;
  toasterServicePhoneSaveError: string;
  toasterServicePhoneSaveSuccess: string;
  toasterServicePhoneRemoveSuccess: string;
  toasterServicePhoneAlreadyInUse: string;
  toasterServicePhoneDeleteError: string;
  toasterServicePhoneUpdateError: string;
}

interface ManageEmailsCopy {
  setAsPrimary: string;
  primaryEmail: string;
  addEmailsButton: string;
  cancelEmailEdit: string;
  cancelEmailAddition: string;
  mailLinkText: string;
  mailLinkAltText: string;
  primaryEmailUsageDescription: string;
  primaryEmailChangeText: string;
  primaryMyAccountEmail: string;
  primaryEmailDefaultText: string;
  primaryEmailVerificationText1: string;
  primaryEmailVerificationText2: string;
  businessEmailDefaultText: string;
  primaryEmailText: string;
  emailSentText: string;
  pendingChangeText: string;
  pendingVerificationText: string;
  representativeEmail: string;
  representativeEmailPartners: string;
  representativeEmailText: string;
  representativeEmailTextPartners: string;
  sendAnotherVerificationEmailText: string;
  additionalEmailAddressesTitle: string;
  additionalEmailAddressesTitlePartners: string;
  confirmDeletePrompt: string;
  emailAddrSelectLabel: string;
  additionalEmailAddressesMyAccountAndRepresentativeCaption: string;
  additionalEmailAddressesMyAccountCaption: string;
  additionalEmailAddressesRepresentativeCaption: string;
  emailInUseError: string;
  toasterServiceEmailupdated: string;
  toasterServiceEmailVerification: string;
  toasterServiceEmailSaveError: string;
  toasterServiceEmailSaveSuccess: string;
  toasterServiceEmailRemoveSuccess: string;
  toasterServiceEmailAlreadyInUse: string;
  toasterServiceEmailRemoveError: string;
  toasterServiceEmailUpdateError: string;
  validateEmailTokenExpiredButton: string;
  validateEmailTokenSuccessButton: string;
  validateEmailExpiredMessage1: string;
  validateEmailExpiredMessage2: string;
  validateEmailSuccessMessage1: string;
  validateEmailSuccessMessage2: string;
  validateEmailSuccessHeader: string;
  validateEmailLoginHeader: string;
}

interface CantChangeSettingsModalCopy {
  title: string;
  listHeader: string;
  bulletOne: string;
  bulletTwo: string;
  callUs: string;
}

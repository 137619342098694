import { MasksModule } from '@amfam/shared/ui/masks';
import { PipesModule } from '@amfam/shared/ui/pipes';
import { SharedUiShowHideInputModule } from '@amfam/shared/ui/show-hide-input';
import { BrandDirectivesModule, BrandModule } from '@amfam/shared/utility/brand';
import { ImpersonationModule } from '@amfam/shared/utility/impersonation';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AcceptPasswordInputComponent } from './components/accept-password-input';
import { ActiveBusinessEmailManagerComponent } from './components/active-business-email-manager/active-business-email-manager.component';
import { AddSsnComponent } from './components/add-ssn/add-ssn.component';
import { BillingCommunicationPreferencesComponent } from './components/billing-communication-preferences/billing-communication-preferences.component';
import { CommunicationPreferencesComponent } from './components/communication-preferences/communication-preferences.component';
import { EditBusinessNameComponent } from './components/edit-business-name/edit-business-name.component';
import { EditPasswordComponent } from './components/edit-password/edit-password.component';
import { EditSecurityQuestionsComponent } from './components/edit-security-questions/edit-security-questions.component';
import { EmailDescriptionPipe } from './components/email-description.pipe';
import { EmailListItemComponent } from './components/email-list-item/email-list-item.component';
import { EmailListComponent } from './components/email-list/email-list.component';
import { ManageBusinessEmailAddressComponent } from './components/manage-business-email-address/manage-business-email-address.component';
import { ManageEmailAddressComponent } from './components/manage-email-address/manage-email-address.component';
import { ManagePhoneNumbersComponent } from './components/manage-phone-numbers/manage-phone-numbers.component';
import { PhoneDescriptionPipe } from './components/manage-phone-numbers/phone-description.pipe';
import { ManageSecurityQuestionsComponent } from './components/manage-security-questions/manage-security-questions.component';
import { PhoneListItemComponent } from './components/phone-list-item/phone-list-item.component';
import { PhoneListComponent } from './components/phone-list/phone-list.component';
import { PoliciesCommunicationPreferencesComponent } from './components/policies-communication-preferences/policies-communication-preferences.component';
import { PolicyTermsAndConditionsComponent } from './components/policy-terms-and-conditions';
import { PreferencesMessageComponent } from './components/preferences-message/preferences-message.component';
import { PreferencesWarningComponent } from './components/preferences-warning/preferences-warning.component';
import { ProfileCardBusinessComponent } from './components/profile-card-business/profile-card-business.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';

@NgModule({
  imports: [
    BrandModule,
    BrandDirectivesModule,
    CommonModule,
    UiKitModule,
    SharedServicesModule,
    RouterModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ImpersonationModule,
    SharedUiShowHideInputModule,
    MasksModule
  ],
  declarations: [
    ActiveBusinessEmailManagerComponent,
    AddSsnComponent,
    BillingCommunicationPreferencesComponent,
    CommunicationPreferencesComponent,
    EditBusinessNameComponent,
    EditPasswordComponent,
    EditSecurityQuestionsComponent,
    EmailListComponent,
    EmailListItemComponent,
    ManageBusinessEmailAddressComponent,
    ManageEmailAddressComponent,
    ManagePhoneNumbersComponent,
    ManageSecurityQuestionsComponent,
    PhoneListComponent,
    PhoneListItemComponent,
    PoliciesCommunicationPreferencesComponent,
    PreferencesMessageComponent,
    PreferencesWarningComponent,
    ProfileCardComponent,
    ProfileCardBusinessComponent,
    PolicyTermsAndConditionsComponent,
    AcceptPasswordInputComponent,
    EmailDescriptionPipe,
    PhoneDescriptionPipe
  ],
  exports: [
    ActiveBusinessEmailManagerComponent,
    AddSsnComponent,
    BillingCommunicationPreferencesComponent,
    CommunicationPreferencesComponent,
    EditBusinessNameComponent,
    EditPasswordComponent,
    EditSecurityQuestionsComponent,
    EmailListComponent,
    EmailListItemComponent,
    ManageBusinessEmailAddressComponent,
    ManageEmailAddressComponent,
    ManagePhoneNumbersComponent,
    ManageSecurityQuestionsComponent,
    PhoneListComponent,
    PhoneListItemComponent,
    PoliciesCommunicationPreferencesComponent,
    PreferencesMessageComponent,
    PreferencesWarningComponent,
    ProfileCardComponent,
    ProfileCardBusinessComponent,
    PolicyTermsAndConditionsComponent
  ]
})
export class ProfileFeatureModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { DockingBarService, PageTitleService, LoadingSpinnerService } from '@amfam/ui-kit';
import { NavLinkTreeObj, LinkFormat } from '@amfam/shared/ui/ds-header';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { RiskModel } from '@amfam/policy/models';
import { PolicySelectors } from '@amfam/policy/data-access';
import { map, takeUntil } from 'rxjs/operators';
import { claimQuery, Claim } from '@amfam/claim/data-access';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { BillAccount } from '@amfam/shared/models';
import { BillingPaymentPaths } from '@amfam/shared/models';
import { get as _get } from 'lodash';
import { PrettyBillingAcctNum } from '@amfam/shared/ui/pipes';
import { PrettyClaimNum } from '@amfam/claim/shared/utility';
import { userQuery, AccountTypeEnum } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

@Component({
  selector: 'ds-sitemap',
  templateUrl: './sitemap.component.html'
})
export class SitemapComponent implements OnInit, OnDestroy {
  linkList: Array<NavLinkTreeObj>;
  risks: RiskModel[] = new Array<RiskModel>();
  billAccounts: BillAccount[] = new Array<BillAccount>();
  riskLinks: NavLinkTreeObj[];
  billAccountLinks: NavLinkTreeObj[];
  claimLinks: NavLinkTreeObj[];
  displayFileClaim = false;
  claimsFNOLEnabled: boolean;
  isPartner = false;

  // Make the route constants available on the template
  billingPaths = BillingPaymentPaths;

  // Aggregate Loading Observable
  dataLoading$: Observable<boolean>;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<fromRoot.RootState>,
    public spinner: LoadingSpinnerService,
    private dockingBarService: DockingBarService,
    private pageTitleService: PageTitleService,
    private feature: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.dockingBarService.registerHeading('Site Map');
    this.pageTitleService.set('Site Map');

    // Setting this here opposed to below with everything else so that I can use the async pipe.
    // Setting it below will throw an error about using 'true' with the async pipe.
    this.dataLoading$ = combineLatest([
      this.store.select(PolicySelectors.getPoliciesLoading),
      this.store.select(claimQuery.getClaimsLoading),
      this.store.select(BillAccountsSelectors.getBillAccountsLoading)
    ]).pipe(
      map(
        ([policiesLoading, claimsLoading, billAccountsLoading]) =>
          policiesLoading || claimsLoading || billAccountsLoading
      )
    );

    // Get all of the store data needed to construct the site
    combineLatest([
      this.store.select(claimQuery.getAllClaims),
      this.store.select(PolicySelectors.getPolicyRisks),
      this.store.select(BillAccountsSelectors.getBillAccounts),
      this.store.select(userQuery.getUserState),
      this.store.select(BrandSelectors.getBrandState),
      this.dataLoading$
    ])
      .pipe(
        map(([claims, risks, billAccounts, user, brand, loading]) => ({
          claims,
          risks,
          billAccounts,
          user,
          brand,
          loading
        })),
        takeUntil(this.stop$)
      )
      .subscribe(state => {
        if (_get(state, 'brand.data')) {
          this.isPartner = _get(state, 'brand.isPartner');
        }

        if (!state.loading) {
          this.buildAllLinkArrays(state.risks, state.billAccounts, state.claims);
          this.spinner.stop();
        } else if (state.loading) {
          this.spinner.start({
            blockActions: true
          });
        }

        if (state.user !== null) {
          // User is logged in, we could set that flag here if needed
          this.displayFileClaim =
            _get(state.user, 'typeOfAccountCode') === AccountTypeEnum.Customer;
        }

        // Check FNOL feature is enabled.
        this.claimsFNOLEnabled = this.feature.isEnabled('fnol') && !this.isPartner;
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  buildOverviewLinkArray(): NavLinkTreeObj[] {
    const overviewLinkArray: NavLinkTreeObj[] = [
      {
        linkText: 'Overview',
        linkRoute: '/overview',
        subLinkArray: [
          {
            linkText: 'FAQs',
            linkRoute: '/faq'
          }
        ]
      }
    ];

    return overviewLinkArray;
  }

  // @todo: jjc - Move all of this to a service for reuse by the main navigation
  private buildRiskLinkArray(risks: RiskModel[] = []): NavLinkTreeObj[] {
    const riskLinkArray: NavLinkTreeObj[] = [
      {
        linkText: 'Policies',
        linkRoute: '/policies',
        linkFormat: LinkFormat.POLICY,
        linkTestId: 'sitemap-item-policy-overview',
        subLinkArray: []
      }
    ];
    return this.buildNavLinkTreeObjArray(riskLinkArray, risks);
  }

  private buildBillAccountLinkArray(billAccounts: BillAccount[] = []): NavLinkTreeObj[] {
    const billAccountLinkArray: NavLinkTreeObj[] = [
      {
        linkText: 'Billing & Payments',
        linkRoute: '/billing',
        linkFormat: LinkFormat.BILLING,
        linkTestId: 'sitemap-item-billing-overview',
        subLinkArray: []
      }
    ];
    return this.buildNavLinkTreeObjArray(billAccountLinkArray, billAccounts);
  }

  private buildClaimLinkArray(claims: Claim[]): NavLinkTreeObj[] {
    const claimLinkArray: NavLinkTreeObj[] = [
      {
        linkText: 'Claims',
        linkRoute: '/claims',
        linkFormat: LinkFormat.CLAIM,
        permissionName: 'claimsummary_veiw',
        checkAction: 'remove',
        linkTestId: 'sitemap-item-claim-overview',
        subLinkArray: []
      }
    ];

    const claimLinkPostArray: NavLinkTreeObj[] = [
      {
        linkText: 'Accident Checklist',
        linkRoute: '/faq/claims',
        queryParams: { openAccordion: 'checklist' },
        linkTestId: 'sitemap-item-claim-checklist'
      },
      {
        linkText: 'Claims FAQ',
        linkRoute: '/faq/claims',
        linkTestId: 'sitemap-item-claim-faq'
      }
    ];

    // Should we display the file a claim link at all?
    if (this.displayFileClaim) {
      // If so, assume fnol is not enabled
      const reportClaimArray: NavLinkTreeObj = {
        linkText: 'File a Claim',
        linkRoute: '/claims/report-claim',
        linkTestId: 'sitemap-item-claim-report',
        permissionName: 'fnolform_view',
        checkAction: 'disable'
      };

      // Now check for fnol and update the link in the array if needed
      if (this.claimsFNOLEnabled) {
        reportClaimArray.linkRoute = '/claims/report-claim-fnol';
      }

      // Push the file a claim array into the claims link array
      claimLinkArray[0].subLinkArray.push(reportClaimArray);
    }
    return this.buildNavLinkTreeObjArray(claimLinkArray, claims, claimLinkPostArray);
  }

  private buildNavLinkTreeObjArray(
    itemArray: NavLinkTreeObj[],
    items: RiskModel[] | BillAccount[] | Claim[],
    postArray?: NavLinkTreeObj[]
  ): NavLinkTreeObj[] {
    items.forEach(item => {
      itemArray[0].subLinkArray.push(this.determineLinkFormatType(item, itemArray[0].linkFormat));
    });

    // Some link objects have links after the formatted links, lets add those
    if (!!postArray) {
      postArray.forEach(postItem => {
        itemArray[0].subLinkArray.push(postItem);
      });
    }

    return itemArray;
  }

  // Determine the type of link and format it accordingly
  private determineLinkFormatType(item, linkFormat: LinkFormat) {
    switch (linkFormat) {
      case LinkFormat.POLICY:
        return this.formatPolicyRiskLink(item);
      case LinkFormat.BILLING:
        return this.formatBillAccountLink(item);
      case LinkFormat.CLAIM:
        return this.formatClaimLink(item);
    }
  }

  private formatPolicyRiskLink(risk: RiskModel): NavLinkTreeObj {
    return {
      linkText: risk.description,
      linkRoute: '/policies/' + risk.policyType + '/' + risk.policyNumber,
      queryParams: risk.vehicleId ? { vehicleId: risk.vehicleId } : '',
      linkTestId: 'sitemap-item-policy-' + risk.vehicleId || risk.policyNumber
    };
  }

  private formatBillAccountLink(billAccount: BillAccount): NavLinkTreeObj {
    return {
      linkText:
        _get(billAccount, 'billingPreferences.accountNickName') ||
        new PrettyBillingAcctNum().transform(billAccount.billAccountNumber),
      linkRoute: [BillingPaymentPaths.BILLING_DETAIL_PAGE, billAccount.billAccountNumber],
      linkTestId: 'sitemap-item-billaccount-' + billAccount.billAccountNumber
    };
  }

  private formatClaimLink(claim: Claim): NavLinkTreeObj {
    return {
      linkText: 'Claim ' + new PrettyClaimNum().transform(claim.claimNumber),
      linkRoute: '/claims/' + claim.claimNumber,
      linkTestId: 'sitemap-item-claim-' + claim.claimNumber,
      permissionName: 'clmdetails_view',
      checkAction: 'remove'
    };
  }

  private buildAllLinkArrays(risks: RiskModel[], billAccounts: BillAccount[], claims: Claim[]) {
    this.linkList = [
      ...this.buildOverviewLinkArray(),
      ...this.buildRiskLinkArray(risks),
      ...this.buildBillAccountLinkArray(billAccounts),
      ...this.buildClaimLinkArray(claims),
      {
        linkText: 'Profile',
        linkRoute: '/profile'
      }
    ];
  }
}

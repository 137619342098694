// Angular
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Models
import {
  GetMakesPayload,
  GetModelsPayload,
  GetStylesPayload,
  GetVehicleMakesApiResponse,
  LookupVinYearPayload
} from '../models/vehicle.models';

// Services
import { ConfigService } from '@amfam/shared/utility/shared-services';

// Misc
import { get as _get } from 'lodash';
import { VehicleFeaturesAdapterService } from './vehicle-features-adapter.service';

@Injectable({ providedIn: 'root' })
export class VehicleService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private vehicleFeaturesAdapterService: VehicleFeaturesAdapterService
  ) {}

  getVehicleByVinAndYear(payload: LookupVinYearPayload) {
    const uri = this.config.get('vehicleApi') + 'vehicles/' + payload.vin;
    const params: HttpParams = new HttpParams()
      .set('year', payload.year)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get(uri, { params }).pipe(
      map((json: any) => {
        const vehicle: any = _get(json, 'matchedVehicles.automobiles[0].vehicle');
        const features = this.vehicleFeaturesAdapterService.adapt(json)?.features;

        if (vehicle) {
          return {
            newVehicle: {
              year: vehicle.year,
              make: vehicle.make,
              model: vehicle.model,
              series: vehicle.series
            },
            features: features
          };
        } else {
          throw new Error('Error locating vehicle by year and VIN');
        }
      })
    );
  }

  getVehicleMakes(payload: GetMakesPayload): Observable<GetVehicleMakesApiResponse> {
    const uri = this.config.get('vehicleApi') + 'makes';
    const params: HttpParams = new HttpParams()
      .set('year', payload.year)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get<GetVehicleMakesApiResponse>(uri, { params });
  }

  getVehicleModels(payload: GetModelsPayload) {
    const uri = this.config.get('vehicleApi') + 'models';
    const params: HttpParams = new HttpParams()
      .set('year', payload.year)
      .set('make', payload.make)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get(uri, { params });
  }

  getVehicleStyles(payload: GetStylesPayload) {
    const uri = this.config.get('vehicleApi') + 'series';
    const params: HttpParams = new HttpParams()
      .set('year', payload.year)
      .set('make', payload.make)
      .set('model', payload.model)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get(uri, { params }).pipe(
      catchError(error => {
        if (_get(error, 'status.code') === 404) {
          return of([]);
        }
        return throwError(error);
      })
    );
  }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Injectable } from '@angular/core';

export const scrollAnimation = trigger('scrollAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-20px)' }),
    animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
  ])
]);

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  scrollToElement(elementId: string, offset: number = 0): void {
    const element = document.getElementById(elementId);
    if (element) {
      const scrollOffset = element.getBoundingClientRect().top + window.scrollY - offset;

      window.scrollTo({
        top: scrollOffset,
        behavior: 'smooth'
      });
    }
  }
}

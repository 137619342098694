import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPaperlessEnrollmentFeature from './overview-paperless-enrollment-feature-reducer';

export const selectPaperlessOverviewEnrollmentFeatureState =
  createFeatureSelector<fromPaperlessEnrollmentFeature.PaperlessOverviewEnrollmentFeatureState>(
    fromPaperlessEnrollmentFeature.paperlessEnrollmentFeatureKey
  );
export const selectIsOverviewFlow = createSelector(
  selectPaperlessOverviewEnrollmentFeatureState,
  (state: fromPaperlessEnrollmentFeature.PaperlessOverviewEnrollmentFeatureState) =>
    state.overviewFlow
);

export const selectCorrelationId = createSelector(
  selectPaperlessOverviewEnrollmentFeatureState,
  (state: fromPaperlessEnrollmentFeature.PaperlessOverviewEnrollmentFeatureState)=>
  state.correlationId
);
export const paperlessOverviewEnrollmentFeatureQuery = {
  selectPaperlessOverviewEnrollmentFeatureState,
  selectIsOverviewFlow,
  selectCorrelationId
};

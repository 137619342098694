import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-confirmation-card-area',
  templateUrl: './ds-confirmation-card-area.component.html',
  styleUrls: ['./ds-confirmation-card-area.component.scss']
})
export class DsConfirmationCardAreaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

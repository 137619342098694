import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EDeliveryTermsAndConditionsEffects } from './+state/e-delivery-terms-and-conditions.effects';
import * as fromEdeliveries from './+state/e-delivery-terms-and-conditions.reducer';

import { eDeliveryReducers } from './+state/e-delivery-terms-and-conditions.selectors';
@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([EDeliveryTermsAndConditionsEffects]),
    StoreModule.forFeature(fromEdeliveries.POLICIES_EDELIVERY_FEATURE_KEY, eDeliveryReducers)
  ]
})
export class PolicyEDeliveryDataAccessModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimEnterpriseState, CLAIM_ENTERPRISE_FEATURE_KEY } from './claim-enterprise.reducer';

// Lookup the 'ClaimEnterprise' feature state managed by NgRx
const getClaimEnterpriseState = createFeatureSelector<ClaimEnterpriseState>(
  CLAIM_ENTERPRISE_FEATURE_KEY
);

export const getClaimsEnterpriseLoading = createSelector(
  getClaimEnterpriseState,
  (state: ClaimEnterpriseState) => state.loading
);

export const getClaimsEnterpriseContent = createSelector(
  getClaimEnterpriseState,
  (state: ClaimEnterpriseState) => state.url
);

export const claimEnterpriseQuery = {
  getClaimEnterpriseState,
  getClaimsEnterpriseLoading,
  getClaimsEnterpriseContent
};

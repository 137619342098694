export class SecurityQuestionsAnswerModel {
  private question: string;
  private answer: string;

  set setQuestion(question: string) {
    this.question = question;
  }

  set setAnswer(answer: string) {
    this.answer = answer;
  }
}

import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Applications, ApplicationService, CopyService } from '@amfam/shared/utility/shared-services';
import { SourceSystemType } from '@amfam/policy/models';
import { DeliveryPreferences } from '@amfam/shared/models';

@Component({
  selector: 'ds-preferences-warning',
  templateUrl: './preferences-warning.component.html',
  styleUrls: ['./preferences-warning.component.scss']
})
export class PreferencesWarningComponent implements OnInit, OnChanges {
  @Input() deliveryPreference: string;
  @Input() billAccountType: SourceSystemType;

  warningMessage = '';
  public isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);

  constructor(
    private copyService: CopyService,
    private appService: ApplicationService
    ) {}

  ngOnInit() {}

  ngOnChanges() {
    // Determine warning warning message
    if (this.deliveryPreference !== DeliveryPreferences.EMAIL) {
      if (
        this.billAccountType === SourceSystemType.Advance ||
        this.billAccountType === SourceSystemType.Legacy
      ) {
        this.warningMessage = this.copyService.getCopy(
          'profile.communicationPreferencesGenericWarning'
        );
      } else {
        this.warningMessage = this.copyService.getCopy(
          'profile.communicationPreferencesPolicyWarning'
        );
      }
    }
  }
}

<div class="ds-tooltip">
    <div class="ds-tooltip__header">
        <div class="title" *ngIf="heading">{{heading}}</div> 
        <button
        title="Close"
        class="close" 
        data-cy="iconExit"
        *ngIf="showCloseButton" 
        (click)="$event.preventDefault(); $event.stopPropagation(); $event.stopImmediatePropagation(); close.emit($event);">
        <i class="icon-exit"></i>
    </button>
    </div>
    <div class="ds-tooltip__body" [innerHTML]="text"></div>
</div>

<!-- Additional email address of the user -->
<h3 class="pad-top-30 margin-bottom-10">
  <span
    *dsPartnerAFI
    content
    [contentPath]="'profile.manageEmails.additionalEmailAddressesTitle'"
  ></span>
  <span
    *dsPartnerConnect
    content
    [contentPath]="'profile.manageEmails.additionalEmailAddressesTitlePartners'"
  ></span>
</h3>

<email-list-item
  *ngFor="let email of emailAddresses"
  [email]="email"
  [primaryEmail]="primaryEmail"
  [isPrimaryEmail]="primaryEmail && primaryEmail.emailId === email.emailId"
  [isPendingEmail]="email.emailId === pendingEmailId"
  [primaryEmailContactMethodUsage]="primaryEmailContactMethodUsage"
  (deleteEmailEvent)="onDelete($event)"
  (makePrimaryEvent)="onMakePrimary($event)"
  (sendAnotherVerificationEmailEvent)="sendAnotherVerificationEmail($event)"
  (resendEmailEmitEvent)="resendEmail()"
  (changeEmailEmitEvent)="changeEmail($event)"
  [makePrimaryEmailAddDeleteBtnToggleDisplay]="makePrimaryEmailAddDeleteBtnToggleDisplay"
  [isShellAccount]="isShellAccount"
  [shellEmail]="shellEmail"
  [isDuplicateEmail]="isDuplicateEmail"
>
</email-list-item>
<p
  class="pad-top-20"
  *ngIf="emailAddresses.length === 1"
  content
  [contentPath]="'profile.manageEmails.primaryEmailChangeText'"
></p>

<!-- Markup for adding a new email address -->
<form *ngIf="isAddingEmail" [formGroup]="addEmailAddrForm" (ngSubmit)="saveNewEmail()">
  <div class="row flex wrap">
    <div class="column twelve sm-five sm-pad-right-10 pad-bottom-10 sm-pad-bottom-0 float-left">
      <ds-form-control-container [control]="emailAddrControl" [labelText]="'Email Address'">
        <input
          class="fill"
          data-cy="addEmailAddressInput"
          formControlName="emailAddr"
          id="emailAddr"
          type="email"
          required
        />
        <ds-form-control-message [control]="emailAddrControl"></ds-form-control-message>
        <ds-form-control-message
          *ngIf="addEmailAddrForm.hasError('isDupEmail')"
          [control]="addEmailAddrForm"
        ></ds-form-control-message>
      </ds-form-control-container>
    </div>
    <div class="column twelve sm-three sm-pad-right-10 pad-bottom-10 sm-pad-bottom-0">
      <ds-form-control-container [labelText]="'Email Type'" [control]="emailTypeControl">
        <select
          class="icon fill"
          formControlName="emailType"
          data-cy="addEmailAddressType"
          id="emailType"
        >
          <option *ngFor="let type of codeTypes" value="{{ type }}">
            {{ type | slice: 0:1 }}{{ type | slice: 1 | lowercase }}
          </option>
        </select>
        <span class="icon-chevron-down"></span>
      </ds-form-control-container>
    </div>
    <div class="column twelve sm-four pad-bottom-10 sm-pad-bottom-0">
      <ds-form-control-container
        *ngIf="emailTypeControl.value === OTHER"
        [control]="typeOtherDescControl"
        [labelText]="'Description'"
      >
        <input
          class="fill"
          formControlName="typeOtherDesc"
          id="typeOtherDesc"
          type="text"
          maxlength="35"
          data-cy="emailListTypeOtherInput"
        />
        <ds-form-control-message
          *ngIf="addEmailAddrForm.hasError('required')"
          [control]="addEmailAddrForm"
        ></ds-form-control-message>
      </ds-form-control-container>
    </div>
  </div>
  <div class="row flex wrap">
    <div class="column twelve pad-top-10">
      <input
        type="checkbox"
        class="checkbox"
        [disabled]="!(roleService.isAuthorized('make_primary_email') | async)"
        id="makePrimaryEmailOnSave"
        [checked]="this.makePrimaryOnSave"
        data-cy="makePrimaryEmail"
        formControlName="primaryChecked"
      />
      <label
        for="makePrimaryEmailOnSave"
        class="checkbox-label"
        content
        [contentPath]="'shared.makePrimaryEmailText'"
      ></label>
      <p
        class="subText"
        content
        [contentPath]="'profile.manageEmails.primaryEmailUsageDescription'"
      ></p>
    </div>
  </div>
  <div class="row flex wrap">
    <div class="column twelve dashed-border float-left pad-top-10">
      <button
        ds-button
        type="submit"
        content
        [contentPath]="'shared.saveBtn'"
        data-cy="saveEmailAddressButton"
        [disabled]="
          !addEmailAddrForm.valid || !(roleService.isAuthorized('addemailaddr_submit') | async)
        "
      ></button>
      <button
        ds-button
        class="-white bg-transparent cancel a margin-left-10"
        content
        [contentPath]="'shared.cancel'"
        (click)="cancel()"
      ></button>
    </div>
  </div>
</form>

<button
  *ngIf="!makePrimaryEmailAddDeleteBtnToggleDisplay"
  ds-button
  class="-outline margin-top-20"
  [disabled]="otherEditActive || !(roleService.isAuthorized('addemailaddr_view') | async)"
  (click)="addNewEmail()"
  data-cy="addContactEmailButton"
>
  <span content [contentPath]="'shared.addBtn'"></span>
</button>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnalyticsClickDirective } from './analytics/analytics-click.directive';
import { ConfigComponent } from './config/config.component';
import { AdminTermsComponent } from './content/admin-terms/admin-terms.component';
import { BillingTermsComponent } from './content/billing-terms/billing-terms.component';
import { PolicyTermsComponent } from './content/policy-terms/policy-terms.component';
import { SafePipe } from './content/safe-pipe';
import { ContentDirective } from './copy/content.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ConfigComponent,
    ContentDirective,
    AnalyticsClickDirective,
    BillingTermsComponent,
    PolicyTermsComponent,
    AdminTermsComponent,
    SafePipe
  ],
  exports: [
    ContentDirective,
    AnalyticsClickDirective,
    BillingTermsComponent,
    PolicyTermsComponent,
    AdminTermsComponent,
    SafePipe
  ]
})
export class SharedServicesModule {}

import { Action } from '@ngrx/store';
import { AutopayPredict } from '../models/autopay-predict.models';

export enum AutopayPredictActionTypes {
  GetAutopayPredict = '[AutopayPredict] Get AutopayPredict',
  GetAutopayPredictSuccess = '[AutopayPredict] Get AutopayPredict Success',
  GetAutopayPredictFail = '[AutopayPredict] Get AutopayPredict Fail',
  EditAutopayPredict = '[AutopayPredict] Get AutopayPredict Edit',
  ClearAutopayPredict = '[AutopayPredict] Get AutopayPredict Clear'
}

export class GetAutopayPredict implements Action {
  readonly type = AutopayPredictActionTypes.GetAutopayPredict;
  constructor(public payload?: any) {}
}

export class GetAutopayPredictSuccess implements Action {
  readonly type = AutopayPredictActionTypes.GetAutopayPredictSuccess;
  constructor(public payload: AutopayPredict) {}
}

export class GetAutopayPredictFail implements Action {
  readonly type = AutopayPredictActionTypes.GetAutopayPredictFail;
  constructor(public payload?: any) {}
}

export class EditAutopayPredict implements Action {
  readonly type = AutopayPredictActionTypes.EditAutopayPredict;
  constructor(public payload?: any) {}
}

export class ClearAutopayPredict implements Action {
  readonly type = AutopayPredictActionTypes.ClearAutopayPredict;
  constructor(public payload?: any) {}
}

export type AutopayPredictActions =
  | GetAutopayPredict
  | GetAutopayPredictSuccess
  | GetAutopayPredictFail
  | EditAutopayPredict
  | ClearAutopayPredict;

export const fromAutopayPredictActions = {
  GetAutopayPredict,
  GetAutopayPredictSuccess,
  GetAutopayPredictFail,
  EditAutopayPredict,
  ClearAutopayPredict
};

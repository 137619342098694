import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { GoPaperLessSelectors } from '@amfam/policy/go-paperless/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { BillAccount, ONLINE_BILLING, OverviewAnalytics } from '@amfam/shared/models';
import { ConfigService, CopyService } from '@amfam/shared/utility/shared-services';
import { DsModalService, LoadingSpinnerService } from '@amfam/ui-kit';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../core/store';

@Component({
  selector: 'ds-overview-registration-modal',
  templateUrl: './overview-registration-modal.component.html',
  styleUrls: ['./overview-registration-modal.component.scss']
})
export class OverviewRegistrationModalComponent implements OnInit, OnChanges, OnDestroy {
  public static readonly registrationModalID = 'RegistrationModal';
  public static readonly registrationTryAgainModalID = 'RegistrationTryAgainModal';
  public onlineBillingText = ONLINE_BILLING;

  public registrationModal: string;
  public registrationTryAgainModal: string;
  public modalWidth = '496';
  private _visible = false;
  public unregisteredBillAccounts: Observable<BillAccount[]>;
  public userRegisteredBillAccounts: Observable<BillAccount[]>;
  public isEnrolled: Observable<boolean>;
  public contactNumberLink = this.config.get('numberLink');
  public amfamPhone = this.copyService.get('shared', 'amfamPhoneNumber');

  // Shared subject for completing observables
  private stop$: Subject<void> = new Subject<void>();

  public counter: number;
  public disableTryAgain = false;

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(this._visible);
  }

  @Output()
  public visibleChange = new EventEmitter<boolean>();

  @Output()
  public tryAgainEvent = new EventEmitter<boolean>();

  constructor(
    private modalService: DsModalService,
    private store: Store<fromRoot.RootState>,
    private spinner: LoadingSpinnerService,
    private config: ConfigService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade
  ) {
    this.counter = 5;
  }

  ngOnInit() {
    this.registrationModal = this.modalService.createModalId(
      OverviewRegistrationModalComponent.registrationModalID
    );
    this.registrationTryAgainModal = this.modalService.createModalId(
      OverviewRegistrationModalComponent.registrationTryAgainModalID
    );
  }

  ngOnChanges(): void {
    if (this.visible) {
      this.openRegistrationModal();
    } else {
      this.modalService.close(this.registrationModal);
      this.modalService.close(this.registrationTryAgainModal);
    }
  }

  openRegistrationModal(): void {
    this.modalService.open(this.registrationModal);
    this.unregisteredBillAccounts = this.store
      .select(BillAccountsSelectors.getUnregisteredBillAccounts)
      .pipe(takeUntil(this.stop$));
    this.userRegisteredBillAccounts = this.store
      .select(BillAccountsSelectors.getUserRegisteredBillAccounts)
      .pipe(takeUntil(this.stop$));
    this.isEnrolled = this.store.select(GoPaperLessSelectors.isEnrolledGoPaperless);
    this.analyticsFacade.trackPage(OverviewAnalytics.pageGoPaperlessError);
  }

  registrationTryAgain(): void {
    this.counter -= 1;
    this.tryAgainEvent.emit(true);
    this.disableTryAgain = true;
    if (this.counter === 0) {
      this.modalService.close(this.registrationModal);
      this.modalService.open(this.registrationTryAgainModal);
    }
    combineLatest(
      this.store.select(BillAccountsSelectors.getBillAccountsAnyLoading),
      this.store.select(GoPaperLessSelectors.getGoPaperlessLoading),
      (billAccountsLoading, goPaperlessLoading) => {
        return {
          loading: billAccountsLoading || goPaperlessLoading
        };
      }
    )
      .pipe(
        takeUntil(this.stop$),
        filter(state => !state.loading)
      )
      .subscribe(() => {
        this.disableTryAgain = false;
      });
  }

  close(id: string): void {
    if (id === 'registrationTryAgainModal') {
      this.counter = 5;
    }
    this.modalService.close(id);
    this.visible = false;
  }
  /**
   * AS:
   * @param initialized : Boolean telling if the modal is up and running.
   * @function modalInitialized : Gets called once the modal has been initialized.
   */
  modalInitialized(initialized) {
    this.spinner.stop();
  }

  openContactUs() {
    window.open(this.contactNumberLink);
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}

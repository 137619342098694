<section id="add-ssn-component" class="item">
  <div class="row">
    <form [formGroup]="dataFormModel" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="column twelve sm-nine">
        <show-hide>
          <ds-form-control-container [labelText]="yourSSN" [control]="ssn">
            <input
              class="fill pad-right-60"
              formControlName="ssn"
              id="ssn"
              maxlength="11"
              type="password"
              (keyup)="onKeyup($event)"
              autocomplete="off"
              show-hide-input
              ssnMask
            />
            <ds-form-control-message [control]="ssn"></ds-form-control-message>
          </ds-form-control-container>
        </show-hide>
      </div>
      <div class="column twelve sm-three">
        <div class="sm-pad-left-10 sm-pad-top-10">
          <button ds-button type="submit" [disabled]="!dataFormModel.valid">
            <span content [contentPath]="'shared.addBtn'"></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

export interface OpportunitiesCtaInterface {
  title: string;
  body: string[];
  buttons: OpportunitiesCtaButton[];
  enabled?: boolean;
  ctaIconPath?: string;
}

export interface OpportunitiesCtaButton {
  buttonText: string;
  buttonType?: string;
}

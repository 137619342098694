import { get as _get } from 'lodash';
import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { DecoratedPreference, RetrievePreferenceResponse } from '@amfam/shared/models';
@Injectable({
  providedIn: 'root'
})
export class RetrievePreferenceAdapter implements Adapter<DecoratedPreference | any> {
  constructor() {}

  /**
   * Return DecoratedPreference object if pcm is enabled.
   * TODO: Remove else condition, return type ANY once pcm is live
   */

  adapt(item: RetrievePreferenceResponse): DecoratedPreference | any {
    return {
      billAccountNumber: _get(item, 'billAccount.billAccountNumber', '').replace(/-/g, ''),
      billingPreferences: {
        accountNickName: _get(item, 'billAccount.billingPreference.accountNickname'),
        dueDateReminder: _get(item, 'billAccount.billingPreference.dueDateReminder'),
        preferences: _get(item, 'billAccount.billingPreference.preferences')
      }
    };
  }
}

import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnrollmentHeaderComponent } from './enrollment-header/enrollment-header.component';
import { EnrollmentNextComponent } from './enrollment-next/enrollment-next.component';

@NgModule({
  imports: [CommonModule, UiKitModule, SharedServicesModule],
  declarations: [EnrollmentHeaderComponent, EnrollmentNextComponent],
  exports: [EnrollmentHeaderComponent, EnrollmentNextComponent]
})
export class MaeUiModule {}

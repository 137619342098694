import { Injectable } from '@angular/core';
import { has as _has } from 'lodash';

// date-fns
import { format, isBefore, isAfter } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class DsTableUtilService {
  public nameComparator(oldVal, newVal) {
    if (oldVal.toLowerCase() < newVal.toLowerCase()) {
      return -1;
    } else if (oldVal.toLowerCase() > newVal.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  /*
    Compare two dates with the input format MM/DD/YYYY
  */
  public dateComparator(a, b) {
    const dateA = format(a, 'MM/DD/YYYY');
    const dateB = format(b, 'MM/DD/YYYY');

    // Comparator function
    if (isBefore(dateA, dateB)) {
      return -1;
    } else if (isAfter(dateA, dateB)) {
      return 1;
    }
    return 0;
  }

  constructor() {}

  // Traverse the table node to get to the element that contains the body-row-wrapper elements
  private getDatatableScroller(table) {
    if (_has(table, 'element') && table.element.hasChildNodes()) {
      const tableVisibleDiv = table.element.children[0];
      if (tableVisibleDiv.hasChildNodes() && tableVisibleDiv.children.length > 1) {
        const datatableBody = tableVisibleDiv.children[1];
        if (datatableBody.hasChildNodes()) {
          const datatableSelection = datatableBody.children[0];
          if (datatableSelection.hasChildNodes()) {
            return datatableSelection.children[0];
          }
        }
      }
    }
  }

  // Remove all of the 'expanded' classes from the body-row-wrapper elements
  public removeExpandedClass(table) {
    if (table) {
      const datatableScroller = this.getDatatableScroller(table);
      if (datatableScroller && datatableScroller.hasChildNodes()) {
        const datatableScrollerChildren = datatableScroller.children;
        if (datatableScrollerChildren.length && datatableScrollerChildren.length > 0) {
          for (let i = 0; i < datatableScrollerChildren.length; i++) {
            datatableScrollerChildren[i].classList.remove('expanded');
          }
        }
      }
    }
  }

  public detailToggleClass(table: any, expanded: boolean, rowIndex: any) {
    if (table) {
      // Get the body-row-wrapper parent
      const datatableScroller = this.getDatatableScroller(table);
      // event.type fires as 'all' and 'row' to tell us if it's just a row that was toggled or all of them
      // we only care about the row, so let's skip 'all'
      if (datatableScroller && datatableScroller.hasChildNodes()) {
        const datatableScrollerChildren = datatableScroller.children;
        if (datatableScrollerChildren.length && datatableScrollerChildren.length > 0) {
          this.removeExpandedClass(table);
          if (expanded && rowIndex >= 0) {
            datatableScrollerChildren[rowIndex].classList.add('expanded');
          }
        }
      }
    }
  }
}

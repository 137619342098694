import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
  HostListener
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { SidebarService } from './sidebar.service';

@Component({
  selector: 'layout-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() sidebarVisible = true;
  @Input() sidebarWidth = 340;
  public sidebarWidthString = '';
  public actualSidebarWidth = this.sidebarWidth;
  private resizeListenerFunc: Function;

  @ViewChild('sidebar')
  sidebar: ElementRef;

  constructor(private sidebarService: SidebarService, private renderer: Renderer2) {}

  ngOnInit() {
    this.sidebarWidthString = this.actualSidebarWidth.toString() + 'px';
  }

  ngAfterViewInit() {
    this.resizedSidebar();
    this.resizeListenerFunc = this.renderer.listen(window, 'resize', event => {
      this.resizedSidebar();
    });
  }

  ngOnDestroy() {
    if (this.resizeListenerFunc) {
      this.resizeListenerFunc();
    }
  }

  resizedSidebar() {
    if (this.sidebar) {
      this.actualSidebarWidth = Math.round(
        this.sidebar.nativeElement.getBoundingClientRect().width
      );
      this.sidebarService.setSidebarLocation(this.actualSidebarWidth === this.sidebarWidth);
      if (this.actualSidebarWidth !== this.sidebarWidth) {
        this.sidebarWidthString = '';
      }
    } else {
      this.sidebarService.setSidebarLocation(false);
    }
  }
}

<div
  class="banner flex"
  [class.info]="messageType==='info'"
>
  <div class="message"><ng-content></ng-content></div>
  <a class="close" (click)="closeBanner.emit()">
    <i class="icon-exit"></i>
    <span class="sr-only">close banner</span>
  </a>
</div>

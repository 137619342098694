import { RiskModel } from '@amfam/policy/models';

export class FormData {
  riskSelection: RiskModel;
  dateOfIncident = '';
  timeOfIncident = '';
  amPmSelection = '';
  incidentCity = '';
  incidentState = '';
  locationType = '';
  injuries = '';
  lossCause = '';
  collisionStationary = '';
  collisionLoss = '';
  theftLoss = '';
  weatherLoss = '';

  clear() {
    this.riskSelection = null;
    this.dateOfIncident = '';
    this.timeOfIncident = '';
    this.amPmSelection = '';
    this.incidentCity = '';
    this.incidentState = '';
    this.locationType = '';
    this.injuries = '';
    this.lossCause = '';
    this.collisionStationary = '';
    this.collisionLoss = '';
    this.theftLoss = '';
    this.weatherLoss = '';
  }
}

export class RiskSelection {
  riskSelection: RiskModel;
}

export class LossCause {
  lossCause = '';
  collisionStationary = '';
  collisionLoss = '';
  theftLoss = '';
  weatherLoss = '';
}

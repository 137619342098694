/* eslint-disable @typescript-eslint/naming-convention */
import { SourceSystemType } from '@amfam/policy/models/src';

export const coverageCodeConst = {
  BAS1BI: 'BI',
  BAS1PD: 'PD',
  BAS2ME: 'ME',
  BAS3UIM: 'UIM',
  BAS3UM: 'UM',
  BAS3UMPD: 'UMPD',
  BAS4COLLISION: 'COLL',
  BAS4COMPREHENSIVE: 'COMP',
  BAS5ERS: 'ERS',
  BASBI: 'BI',
  BASCOLLISION: 'COLL',
  BASCOMPREHENSIVE: 'COMP',
  BASPD: 'PD',
  BASUM: 'UM',
  'End 110-3': 'XXX',
  'END106(WA)': 'PIP',
  'END15-106': 'PIP',
  'END15-107': 'PIP',
  'END15-108': 'PIP',
  'END22-106': 'PIP',
  'END33-106': 'PIP',
  'END33-107': 'PIP',
  'END36-106': 'PIP',
  'END43-106': 'PIP',
  END53: 'UM',
  'END53(AZ)': 'UM',
  'END53(CO)': 'UIM UM',
  'END53(GA)': 'UM',
  'END53(IA)': 'UM',
  'END53(IL)': 'UM',
  'END53(MO)': 'UM',
  'END53(NE)': 'UM',
  'END53(NV)': 'UM',
  'END53(OH)': 'UM',
  'END53(OR)': 'UM',
  'END53(UT)': 'UM',
  'END54-1(CO)': 'UMPD',
  'END54-1(IL)': 'UMPD',
  'END54-1(OR)': 'UMPD',
  'END54(CO)': 'UMPD',
  'END54(IL)': 'UMPD',
  'END54(OH)': 'UMPD',
  'END54(UT)': 'UMPD',
  'END55-2': 'UIM',
  'END55-2(IA)': 'UIM',
  'END55-2(IL)': 'UIM',
  'END55-2(IN)': 'UIM',
  'END55-2(MN)': 'UIM',
  'END55-2(MO)': 'UIM',
  'END55-2(NE)': 'UIM',
  'END55-2(OH)': 'UIM',
  'END55-2(OR)': 'UIM',
  'END55(AZ)': 'UIM',
  'END55(IA)': 'UIM',
  'END55(ID)': 'UIM',
  'END55(IL)': 'UIM',
  'END55(MO)': 'UIM',
  'END55(NE)': 'UIM',
  'END55(NV)': 'UIM',
  'END55(OH)': 'UIM',
  'END55(UT)': 'UIM',
  'END55(WA)': 'UIM',
  END83: 'ME',
  'END83(AZ)': 'ME',
  'END83(CO)': 'ME',
  'END83(GA)': 'ME',
  'END83(IL)': 'ME',
  'END83(MO)': 'ME',
  'END83(NE)': 'ME',
  'END83(NV)': 'ME',
  'END83(OH)': 'ME',
  PAAccDeathDismemberCov_af: 'DD',
  PABoatEmergencyServiceCov_af: 'ERS',
  PABoatLiabCov_af: 'LIAB',
  PABoatMeCov_af: 'ME',
  PABoatPhysicalDamageCov_af: 'PHYS DMGE',
  PABoatUmUimCov_af: 'UM UIM',
  PABodilyInjuryCov_af: 'BI',
  PABodilyInjuryCycleCov_af: 'BI',
  PACollisionCov: 'COLL',
  PAComprehensiveCov: 'COMP',
  'PAEmergencyRoad ServiceCov_af': 'ERS',
  PAEmergencyRoadServiceCov_af: 'ERS',
  BASERS: 'ERS',
  END90: 'ERS',
  'END90(CO)': 'ERS',
  PAFpbAccDeathBenfCov_af: 'DD',
  PAFpbMedCov_af: 'ME',
  PALeaseLoanCov_af: 'LL',
  PAMedExpCov_af: 'ME',
  PAPersInjuryProtectCov: 'PIP',
  PAPipCovKS_af: 'PIP',
  PAPipCovMN_af: 'PIP',
  PAPipCovOR_af: 'PIP',
  PAPipCovUT_af: 'PIP',
  PAPipCovWA_af: 'PIP',
  PAPipCovTX_af: 'PIP',
  PAPipCovNJ_af: 'PIP',
  PAPipCovMD_af: 'PIP',
  PAPipCovND_af: 'PIP',
  PAPipCovKSVeh_af: 'PIP',
  PAPipCovMNVeh_af: 'PIP',
  PAPropDamageCov_af: 'PD',
  PARentalCov: 'RR',
  PAUIMCov_af: 'UIM',
  PAUIMPDCov_af: 'UIMPD',
  PAUIMPropertyDamageCov: 'UIMPD',
  PAUMCov_af: 'UM',
  PAUmpdCov_af: 'UMPD',
  PAUMPDLineCov_af: 'UMPD',
  PAUmUimpdCov_af: 'UM/UIMPD',
  PAUMUIMPDLineCov_af: 'UM/UIMPD',
  PAFpbCombinationCov_af: 'FPB CMB',
  PATotalDisabilityCov_af: 'Total Disability',
  PAUMUIMCov_af: 'UM/UIM'
};

export const stateMapping: { [key: string]: string } = {
  AZ: 'policy.stateLaw.AZ',
  GA: 'policy.stateLaw.GA',
  ID: 'policy.stateLaw.ID',
  IL: 'policy.stateLaw.IL',
  MN: 'policy.stateLaw.MN',
  OH: 'policy.stateLaw.OH',
  NV: 'policy.stateLaw.NV',
  default: 'policy.stateLaw.default'
};

export const sourceMapping: { [key in SourceSystemType]: string } = {
  [SourceSystemType.Advance]: 'Advance',
  [SourceSystemType.Legacy]: 'Classic'
};
export const setNAIC = {
  AFIC: '10386',
  AFMIC: '19275',
  ASIC: '19283',
  ASICO: '10387',
  default: ''
};
export const arizonaDOTNumber = {
  10386: '1503',
  19275: '0097',
  19283: '0094',
  default: ''
};

export const POIName = {
  OH: 'Insurance Identification Card',
  ID: 'Liability Insurance Identification Card',
  GA: 'Insurance Policy Information Card',
  default: 'Insurance Card'
};

import { ButtonAnalytic, PageAnalytic } from '@amfam/shared/utility/shared-services';

export class OpportunityAnalytics {
  // click analytic for learn more from the overview card
  public static readonly clickOpportunityLearnMore: ButtonAnalytic = {
    link: 'Opportunity'
  };

  // Click analytic for Im Interested/ Contact Agent from overview card
  public static readonly clickOpportunityContactAgent: ButtonAnalytic = {
    link: 'ContactMe'
  };

  // Click analytic for No Thanks from overview card
  public static readonly clickOpportunityDismiss: ButtonAnalytic = {
    link: 'Dismiss'
  };

  // click analytic for learn more from the overview card
  public static readonly clickOpportunityLearnMoreWithType: ButtonAnalytic = {
    link: '${type}:Opportunity'
  };

  // Click analytic for Im Interested/ Contact Agent from overview card
  public static readonly clickOpportunityContactAgentWithType: ButtonAnalytic = {
    link: '${type}:ContactMe'
  };

  // Click analytic for No Thanks from overview card
  public static readonly clickOpportunityDismissWithType: ButtonAnalytic = {
    link: '${type}:Dismiss'
  };

  // Click analytic for Get a Quote button from overview detail page
  public static readonly clickOpportunityGetAQuote: ButtonAnalytic = {
    link: 'GetQuoteDSP'
  };

  // Click analytic for cancelling out of quote from the qualifier page
  public static readonly clickOpportunityCancelQuote: ButtonAnalytic = {
    link: 'CancelQuote'
  };

  public static readonly clickOpportunitySendToAgent: ButtonAnalytic = {
    link: 'SendToAgent'
  };

  public static readonly clickOpportunityBackToMyAccount: ButtonAnalytic = {
    link: 'BackToMyAccount'
  };

  public static readonly clickOpportunitySendToDSP: ButtonAnalytic = {
    link: 'CrossSellAutoDSPWeb'
  };

  // page analytic for opportunity detail page
  public static readonly pageOpportunityDetail: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:Opportunity',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  // page analytic for opportunity detail page
  public static readonly pageOpportunityDetailWithType: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:Opportunity:${type}',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  // page analytic for contact agent communication success
  public static readonly pageOpportunityContactAgentSuccess: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:ContactAgent:Success',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  // page analytic for contact agent communication success
  public static readonly pageOpportunityContactAgentSuccessWithType: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:${type}:ContactAgent:Success',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  // page analytic for contact agent communication error
  public static readonly pageOpportunityContactAgentError: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:ContactAgent:Error',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  // page analytic for contact agent communication error
  public static readonly pageOpportunityContactAgentErrorWithType: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:${type}:ContactAgent:Error',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  public static readonly overviewPageOpporutunity: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:Overview',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  public static readonly clickOpportunityWhichAgentSendToAgent: ButtonAnalytic = {
    link: 'WhichAgent:SendToAgent'
  };

  public static readonly clickOpportunityWhichAgentSendToAgentWithType: ButtonAnalytic = {
    link: '${type}:WhichAgent:SendToAgent'
  };

  public static readonly whichAgentSuccessPageOpporutunity: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:WhichAgent:ContactAgent:Success',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  public static readonly whichAgentSuccessPageOpporutunityWithType: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:${type}:WhichAgent:ContactAgent:Success',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  public static readonly whichAgentErrorPageOpporutunity: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:WhichAgent:ContactAgent:Error',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  public static readonly whichAgentErrorPageOpporutunityWithType: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:${type}:WhichAgent:ContactAgent:Error',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  public static readonly whichAgentPageOpporutunity: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:WhichAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  public static readonly whichAgentPageOpporutunityWithType: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:${type}:WhichAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '${subProductType}',
    subCategory3: ''
  };

  public static readonly pageOpportunitiesQualifiers: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:QuoteQualifiers',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageOpportunitiesNeedAgentForm: PageAnalytic = {
    pageName: 'MyAccount:${endorsement}:NeedAgentForm',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: '${productType}',
    subCategory2: '',
    subCategory3: ''
  };
}

import { ProfileActions } from '@amfam/profile/data-access';
import { PaperlessDiscountPayload } from '@amfam/shared/models';
import { ApplicationService, Applications } from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GenerateEmailConstants, GoPaperlessSuccessModel } from '../models/go-paperless.model';
import { GoPaperlessService } from '../services/go-paperless.service';
import {
  EnrollGoPaperless,
  EnrollGoPaperlessFailure,
  EnrollGoPaperlessSuccess,
  GenerateGoPaperlessEmail,
  GenerateGoPaperlessEmailFailure,
  GenerateGoPaperlessEmailSuccess,
  GoPaperlessActionTypes,
  LoadGoPaperless,
  SendGoPaperlessAnalytics,
  UnEnrollGoPaperless,
  UnEnrollGoPaperlessFailure,
  UnEnrollGoPaperlessSuccess
} from './go-paperless.actions';
@Injectable()
export class GoPaperlessEffects {
  
  load$ = createEffect(() => this.action$.pipe(
    ofType(ProfileActions.ProfileActionTypes.FETCH_PARTY_DATA_SUCCESS),
    map(
      (action: ProfileActions.FetchPartyDataSuccessAction) =>
        action.payload.typeOfEdeliveryStatusCode
    ),
    map(enrollStatus => new LoadGoPaperless(enrollStatus))
  ));

  
  enroll$ = createEffect(() => this.action$.pipe(
    ofType(GoPaperlessActionTypes.EnrollGoPaperless),
    map((action: EnrollGoPaperless) => action.payload),
    switchMap(payload => {
      return this.goPaperlesservice.changeEDeliveryStatus('Y').pipe(
        mergeMap((res: any) => {
          const successModel: GoPaperlessSuccessModel = {
            status: res.status,
            isEnrolled: true,
            correlationId: payload?.correlationId
          };
          const isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
          if (isAdminApp) {
            return [
              new ProfileActions.RefreshPartyDataAction(),
              new EnrollGoPaperlessSuccess(successModel)
            ];
          } else {
            return [
              new ProfileActions.RefreshPartyDataAction(),
              new EnrollGoPaperlessSuccess(successModel),
              new GenerateGoPaperlessEmail({
                paperlessOperation: GenerateEmailConstants.GoPaperlessOptIn,
                correlationId: payload?.correlationId
              })
            ];
          }
        }),
        catchError(err => of(new EnrollGoPaperlessFailure(err)))
      );
    })
  ));

  
  unenroll$ = createEffect(() => this.action$.pipe(
    ofType(GoPaperlessActionTypes.UnEnrollGoPaperless),
    map((action: UnEnrollGoPaperless) => action.payload),
    switchMap(payload => {
      return this.goPaperlesservice.changeEDeliveryStatus('N').pipe(
        mergeMap((res: any) => {
          const successModel: GoPaperlessSuccessModel = {
            status: res.status,
            isEnrolled: false,
            correlationId: payload?.correlationId
          };
          const isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
          if (isAdminApp) {
            return [
              new ProfileActions.RefreshPartyDataAction(),
              new UnEnrollGoPaperlessSuccess(successModel)
            ];
          } else {
            return [
              new ProfileActions.RefreshPartyDataAction(),
              new UnEnrollGoPaperlessSuccess(successModel),
              new GenerateGoPaperlessEmail({
                paperlessOperation: GenerateEmailConstants.GoPaperlessOptOut
              })
            ];
          }
        }),
        catchError(err => of(new UnEnrollGoPaperlessFailure(err)))
      );
    })
  ));

  
  generateEmail$ = createEffect(() => this.action$.pipe(
    ofType(GoPaperlessActionTypes.GenerateGoPaperlessEmail),
    map((action: GenerateGoPaperlessEmail) => action.payload),
    mergeMap(payload => {
      return this.goPaperlesservice.generateCustomerEmail(payload).pipe(
        map(res => new GenerateGoPaperlessEmailSuccess(res)),
        catchError(err => of(new GenerateGoPaperlessEmailFailure(err)))
      );
    })
  ));

  /**
   * MR: Call RPA service to handle discounts for enroll/unenroll paperless success
   */
  
  applyPaperlessDiscount$ = createEffect(() => this.action$.pipe(
    ofType<EnrollGoPaperlessSuccess | UnEnrollGoPaperlessSuccess>(
      GoPaperlessActionTypes.EnrollGoPaperlessSuccess,
      GoPaperlessActionTypes.UnEnrollGoPaperlessSuccess
    ),
    map(action => action.payload),
    switchMap((payload: GoPaperlessSuccessModel) => {
      return this.goPaperlesservice
        .buildPaperlessPayload(payload.isEnrolled)
        .pipe(
          switchMap((rpaPayload: PaperlessDiscountPayload) =>
            this.goPaperlesservice.applyPaperlessDiscount(rpaPayload)
          )
        );
    })
  ), { dispatch: false });

  /**
   * @author: Abhishek Singh
   * @returns:
   * @description: This effect triggers a analytics notification.
   */
  
  analyticsNotification$ = createEffect(() => this.action$.pipe(
    ofType<SendGoPaperlessAnalytics>(GoPaperlessActionTypes.SendGoPaperlessAnalytics),
    map(action => action.payload),
    map(payload => this.goPaperlesservice.sendAnalytics(payload))
  ), { dispatch: false });

  constructor(
    private goPaperlesservice: GoPaperlessService,
    private action$: Actions,
    private appService: ApplicationService
  ) {}
}

<div class="ds-profile-card margin-bottom-50" [class.selected]="selected">
  <div class="ds-profile-card__header">
    <div class="action" *ngIf="showRadio">
      <input type="radio" name="profileRadio" id="profileRadio + {{ index }}" [value]="id" (change)="profileSelected.emit(id)" />
    </div>
    <div class="thumbnail">
      <img *ngIf="url" [src]="url" (error)="imageLoadError()" />
    </div>
    <div class="name">
      {{ name }}
    </div>
    <div *ngIf="recommended" class="recommended">
      <div>Recommended</div>
      <i class="icon-info"></i>
    </div>
  </div>
  <div class="ds-profile-card__description" [class.pad-left-40]="showRadio" >
    <div class="office">
      <img class="office__icon" src="/assets/images/collar.png" alt="" srcset="" />
      <div class="office__name">{{ office }}</div>
    </div>
    <div class="address">
      <img class="address__icon" src="/assets/images/find.png" alt="" srcset="" />
      <div class="address__content">
        {{ address }}
      </div>
    </div>
  </div>
</div>

import { createAction, props } from '@ngrx/store';
import { CustomerEnrollmentRequest, CustomerEnrollment } from '../models/mae.models';

export const resendEmail = createAction('[Mae] ResendEmail');

export const resendEmailSuccess = createAction('[Mae] ResendEmail Success');

export const resendEmailFail = createAction('[Mae] ResendEmail Fail');

export const changeEmail = createAction('[Mae] ChangeEmail', props<{ email: string }>());

export const changeEmailSuccess = createAction('[Mae] ChangeEmail Success');

export const changeEmailFail = createAction('[Mae] ChangeEmail Fail');
export const enrollment = createAction(
  '[Mae] Initiate Enrollment',
  props<{ payload: { customerEnrollmentRequest: CustomerEnrollmentRequest; customerId: string } }>()
);
export const enrollmentSuccess = createAction(
  '[Mae] Initiate Enrolment Success',
  props<{ payload: CustomerEnrollment }>()
);
export const enrollmentFailure = createAction(
  '[Mae] Initiate Enrollment Failure',
  props<{ message: string }>()
);

export const fromMaeActions = {
  resendEmail,
  resendEmailSuccess,
  resendEmailFail,
  changeEmail,
  changeEmailSuccess,
  changeEmailFail,
  enrollment,
  enrollmentSuccess,
  enrollmentFailure
};

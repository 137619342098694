import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ConfirmationConfig } from './confirmation.model';

@Component({
  selector: 'confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationComponent implements OnInit {
  @Input() config: ConfirmationConfig;
  @Output() primaryEvent = new EventEmitter();
  @Output() secondaryEvent = new EventEmitter();
  @Output() tertiaryEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}

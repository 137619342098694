import { TestBed } from '@angular/core/testing';

export class ConfigServiceMock {
  amfamFakeUrl = 'www.amfam.com/testURL/';

  get = () => this.amfamFakeUrl;

  getConfigJson = () => {
    return 'foo';
  };
}

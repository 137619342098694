import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimSubmitState, CLAIMSUBMIT_FEATURE_KEY } from './claim-submit.reducer';

// Lookup the 'ClaimSubmit' feature state managed by NgRx
const getClaimSubmitState = createFeatureSelector<ClaimSubmitState>(CLAIMSUBMIT_FEATURE_KEY);

const getClaimConfirmation = createSelector(
  getClaimSubmitState,
  (state: ClaimSubmitState) => state.confirmation
);

const getReferenceNumber = createSelector(
  getClaimConfirmation,
  confirmation => confirmation?.referenceNumber
);

const claimSubmitFailed = createSelector(getClaimSubmitState, state => state.error);

const isClaimReset = createSelector(
  getClaimSubmitState,
  state => !state.confirmation && !state.error
);

export const claimSubmitQuery = {
  getClaimConfirmation,
  getReferenceNumber,
  claimSubmitFailed,
  isClaimReset
};

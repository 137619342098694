import { Action } from '@ngrx/store';

import { ErrorModel } from './error.model';

export const LOAD_SUCCESS = '[Error] Load Success';
export const LOAD_FAIL = '[Error] Load Fail';

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: ErrorModel) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload?: any) {}
}

export type Actions = LoadSuccessAction | LoadFailAction;

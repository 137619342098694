import {
  EntityNotification,
  FailureNotification,
  LoadNotification,
  SuccessNotification
} from '@amfam/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as AutoPayActions from './auto-pay.actions';

export interface State extends EntityState<EntityNotification> {}

export const adapter: EntityAdapter<EntityNotification> = createEntityAdapter<EntityNotification>({
  selectId: (notification: EntityNotification) => notification.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,

  // Get Automatic Payment
  on(AutoPayActions.getAutomaticPayment, (state, action) =>
    adapter.upsertOne(new LoadNotification(action.billAccountNumber), state)
  ),

  on(AutoPayActions.getAutomaticPaymentSuccess, (state, action) =>
    adapter.upsertOne(
      new SuccessNotification(
        action.response?.autoPayRules[0]?.billAccountNumber,
        action.correlationId
      ),
      state
    )
  ),

  on(AutoPayActions.getAutomaticPaymentFailure, (state, action) =>
    adapter.upsertOne(
      new FailureNotification(action.billAccountNumber, action.correlationId, action.error),
      state
    )
  ),

  // Get All Automatic Payments
  on(AutoPayActions.getAllAutomaticPayments, (state, action) => {
    const notifications: EntityNotification[] = action.billAccountNumbers.map(
      billAccountNumber => new LoadNotification(billAccountNumber)
    );
    return adapter.upsertMany(notifications, state);
  }),
  on(AutoPayActions.getAllAutomaticPaymentsSuccess, (state, action) => {
    const notifications: EntityNotification[] = (state.ids as string[]).map(
      billAccountNumber => new SuccessNotification(billAccountNumber, action.correlationId)
    );
    return adapter.upsertMany(notifications, state);
  }),
  on(AutoPayActions.getAllAutomaticPaymentsFailure, (state, action) => {
    const notifications: EntityNotification[] = action.billAccountNumbers.map(
      billAccountNumber =>
        new FailureNotification(billAccountNumber, action.correlationId, action.error)
    );
    return adapter.upsertMany(notifications, state);
  }),

  // Edit AutoPay
  on(AutoPayActions.editAutoPay, (state, action) =>
    adapter.upsertOne(new LoadNotification(action.billAccountNumber), state)
  ),
  on(AutoPayActions.editAutoPaySuccess, (state, action) =>
    adapter.upsertOne(
      new SuccessNotification(
        action.response?.autoPayRules[0]?.billAccountNumber,
        action.correlationId
      ),
      state
    )
  ),
  on(AutoPayActions.editAutoPayFailure, (state, action) =>
    adapter.upsertOne(
      new FailureNotification(action.billAccountNumber, action.correlationId, action.error),
      state
    )
  ),

  // Add Multiple AutoPay
  on(AutoPayActions.addMultipleAutoPay, (state, action) => {
    const notifications: EntityNotification[] = action.payload.accounts.map(
      account => new LoadNotification(account.billingNumber)
    );
    return adapter.upsertMany(notifications, state);
  }),
  on(AutoPayActions.addMultipleAutoPaySuccess, (state, action) => {
    const notifications: EntityNotification[] = action.response.autoPayRules.map(
      autoPayRule => new SuccessNotification(autoPayRule.billAccountNumber, action.correlationId)
    );
    return adapter.upsertMany(notifications, state);
  }),
  on(AutoPayActions.addMultipleAutoPayPartialSuccess, (state, action) => {
    const successNotifications: EntityNotification[] = action.response.autoPayRules.map(
      autoPayRule => new SuccessNotification(autoPayRule.billAccountNumber, action.correlationId)
    );
    const failureNotifications: EntityNotification[] = action.response.partialStatuses.map(
      partialStatus =>
        new FailureNotification(
          partialStatus.payloadEntityId,
          action.correlationId,
          partialStatus.status
        )
    );
    const notifications: EntityNotification[] = successNotifications.concat(failureNotifications);
    return adapter.upsertMany(notifications, state);
  }),
  on(AutoPayActions.addMultipleAutoPayFailure, (state, action) => {
    const notifications: EntityNotification[] = action.billAccountNumbers.map(
      billAccountNumber =>
        new FailureNotification(billAccountNumber, action.correlationId, action.error)
    );
    return adapter.upsertMany(notifications, state);
  }),

  // Delete AutoPay
  on(AutoPayActions.deleteAutoPay, (state, action) =>
    adapter.upsertOne(new LoadNotification(action.payload.billAccountNumber), state)
  ),

  on(AutoPayActions.deleteAutoPaySuccess, (state, action) =>
    adapter.upsertOne(
      new SuccessNotification(action.billAccountNumber, action.correlationId),
      state
    )
  ),

  on(AutoPayActions.deleteAutoPayFailure, (state, action) =>
    adapter.upsertOne(
      new FailureNotification(action.billAccountNumber, action.correlationId, action.error),
      state
    )
  )
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAutomaticPaymentNotificationIds = selectIds;
export const selectAutomaticPaymentNotificationEntities = selectEntities;
export const selectAllAutomaticPaymentNotifications = selectAll;
export const selectAutomaticPaymentNotificationsTotal = selectTotal;

import {
  LetterToDigitPhoneNumberPipe,
  PrettyLetterAndDigitPhoneNumberPipe
} from '@amfam/shared/ui/pipes';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BrandModel as BrandState } from '../models/brand.model';
import * as fromBrand from './brand.reducer';
import { BRAND_FEATURE_KEY } from './brand.reducer';

/**
 * Brand Reducers
 */
export const getBrandState = createFeatureSelector<BrandState>(BRAND_FEATURE_KEY);

export const getBrandId = createSelector(getBrandState, state => state.brandId);
export const getExperienceId = createSelector(getBrandState, fromBrand.experienceId);
export const getPartnerId = createSelector(getBrandState, fromBrand.partnerId);
export const getAccountId = createSelector(getBrandState, fromBrand.accountId);
export const getAssetPath = createSelector(getBrandState, fromBrand.assetPath);
export const getClaimPhoneNum = createSelector(getBrandState, fromBrand.claimPhoneNum);
export const getPrettyClaimPhoneNum = createSelector(getClaimPhoneNum, number => {
  const formattedNumber = new PrettyLetterAndDigitPhoneNumberPipe().transform(number);
  return formattedNumber;
});
export const getClaimPhoneNumFullHref = createSelector(getClaimPhoneNum, number => {
  // If we have a number add prefix, else return empty string
  const formattedNumber = new LetterToDigitPhoneNumberPipe().transform(number);
  return formattedNumber ? 'tel:+' + formattedNumber.replace(/\D/g, '') : '';
});

export const getClaimPhoneNumAuto = createSelector(getBrandState, fromBrand.claimPhoneNumAuto);
export const getClaimPhoneNumProperty = createSelector(
  getBrandState,
  fromBrand.claimPhoneNumProperty
);
export const getErsClaimPhoneNum = createSelector(getBrandState, fromBrand.claimErsPhoneNum);
export const getGlassClaimPhoneNum = createSelector(getBrandState, fromBrand.claimGlassPhoneNum);

export const getCompany = createSelector(getBrandState, fromBrand.company);
export const getCusCareNumber = createSelector(getBrandState, fromBrand.cusCareNumber);
export const getPrettyCusCareNumber = createSelector(getCusCareNumber, number => {
  const formattedNumber = new PrettyLetterAndDigitPhoneNumberPipe().transform(number);
  return formattedNumber;
});
export const getCusCareNumberFullHref = createSelector(getCusCareNumber, number => {
  // If we have a number add prefix, else return empty string
  const formattedNumber = new LetterToDigitPhoneNumberPipe().transform(number);
  return formattedNumber ? 'tel:+' + formattedNumber.replace(/\D/g, '') : '';
});
export const getCusCareEmail = createSelector(getBrandState, fromBrand.cusCareEmail);
export const getCusCareEmailHref = createSelector(getCusCareEmail, email =>
  // If we have a number add prefix, else return empty string
  email ? 'mailto:' + email : ''
);
export const getCusCare247 = createSelector(getBrandState, fromBrand.cusCare247);
export const getCusCareHoursWeekday = createSelector(getBrandState, fromBrand.cusCareHoursWeekday);
export const getCusCareHoursFriday = createSelector(getBrandState, fromBrand.cusCareHoursFriday);
export const getCusCareHoursWeekend = createSelector(getBrandState, fromBrand.cusCareHoursWeekend);
export const getCustomerCareTimezone = createSelector(
  getBrandState,
  fromBrand.customerCareTimezone
);
export const getCustomerCareWeekdayEnd = createSelector(
  getBrandState,
  fromBrand.customerCareWeekdayEnd
);
export const getCustomerCareWeekdayStart = createSelector(
  getBrandState,
  fromBrand.customerCareWeekdayStart
);
export const getExperienceClass = createSelector(getBrandState, fromBrand.experienceClass);
export const getPartnerNameInUrl = createSelector(getBrandState, fromBrand.partnerNameInUrl);
export const getBillingContactEmailFaq = createSelector(
  getBrandState,
  fromBrand.billingContactEmailFaq
);
export const getMyaccountSubdomain = createSelector(getBrandState, fromBrand.myaccountSubdomain);
export const getLandingPageDomain = createSelector(getBrandState, fromBrand.landingPageDomain);
export const getLandingPagePath = createSelector(getBrandState, fromBrand.landingPagePath);
export const getLandingPageUrl = createSelector(getBrandState, fromBrand.landingPageUrl);
export const getLandingPage = createSelector(getBrandState, fromBrand.landingPage);
export const getPropertyServiceUrl = createSelector(getBrandState, fromBrand.propertyServiceUrl);
export const getPropertyServiceSSO = createSelector(getBrandState, fromBrand.propertyServiceSSO);
export const getIsPartner = createSelector(getBrandState, fromBrand.isPartner);
export const getIsGenericPartner = createSelector(getBrandState, fromBrand.isGenericPartner);
export const getHeaderLogo = createSelector(getBrandState, fromBrand.brandHeaderLogo);
export const getFooterLogo = createSelector(getBrandState, fromBrand.brandFooterLogo);
export const getBrandLoading = createSelector(getBrandState, fromBrand.loading);
export const getBrandHasError = createSelector(getBrandState, fromBrand.hasError);
export const getUserBrandLoaded = createSelector(getBrandState, fromBrand.userBrandLoaded);
export const getBrandLoaded = createSelector(
  getBrandState,
  getExperienceId,
  (brand, userExperienceId) => {
    if (userExperienceId === '') {
      return brand.loaded;
    }
    return brand.userBrandLoaded;
  }
);
export const getPartnerTermsData = createSelector(
  getLandingPage,
  getLandingPageUrl,
  getPrettyCusCareNumber,
  getIsPartner,
  (landingPage, landingPageUrl, cusCareNumber, isPartner) => ({
    landingPage,
    landingPageUrl,
    cusCareNumber,
    isPartner
  })
);

import { Injectable } from '@angular/core';

import { select, Store, /* Action */ } from '@ngrx/store';

// import * as BrandActions from './brand.actions';
// import * as BrandFeature from './brand.reducer';
import * as BrandSelectors from './brand.selectors';

@Injectable({
  providedIn: 'root'
})
export class Facade {

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  isPartner$ = this.store.pipe(select(BrandSelectors.getIsPartner))
  constructor(private store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
  }
}

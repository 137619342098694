<div class="main">
  <div class="row">
    <div class="column twelve lg-six lg-push-three md-six md-push-three">
      <div class="flex items-center">
        <div class="pad-right-20"><span class="icon icon-info"></span></div>
        <div>
          <h2 content [contentPath]="'auth.sessionExpiredLogOutText'"></h2>
          <p content [contentPath]="'auth.sessionExpiredLogInText'"></p>
        </div>
      </div>
    </div>
  </div>

  <ds-login-base></ds-login-base>
</div>

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { BrandSelectors } from '@amfam/shared/utility/brand';
import { UtilService } from '@amfam/shared/utility/shared-services';
import {
  ConfirmationModel,
  DockingBarService,
  LoadingSpinnerService,
  PageTitleService
} from '@amfam/ui-kit';

import { PolicySummarySelectors } from '@amfam/policy/data-access';
import { BillAccount, BillAccountData, NewBillingPreference } from '@amfam/shared/models';
import { CopyService } from '@amfam/shared/utility/shared-services';

import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import {
  BillAccountActions,
  BillAccountsSelectors,
  BillingUtilService
} from '@amfam/billing/billaccount/data-access';
import { pendingPaymentActions } from '@amfam/billing/pending-payment/data-access';
import { paperlessOverviewEnrollmentFeatureQuery } from '@amfam/overview/paperless-enrollment/feature';
import {
  completeOverviewPaperlessEnrollment,
  initiateOverviewPaperlessEnrollment
} from '@amfam/overview/paperless-enrollment/feature/src/lib/+state/overview-paperless-enrollment-feature-actions';
import { EsignatureActions, EsignatureSelectors } from '@amfam/policy/e-signature/data-access';
import {
  GoPaperLessActions,
  GoPaperLessSelectors,
  GoPaperlessService
} from '@amfam/policy/go-paperless/data-access';
import { CommunicationPreferencesSelectors } from '@amfam/profile/communication-preferences/data-access';
import { paperlessOnboardingEnrollmentFeatureQuery } from '@amfam/profile/communication-preferences/feature';
import { AnalyticsFacade, GoPaperlessAnalytics } from '@amfam/shared/analytics';
import { PageAnalyticParams } from '@amfam/shared/analytics/src/lib/models/analytics.model';
import { UserState, userQuery } from '@amfam/shared/user';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ActivatedRoute } from '@angular/router';
import {
  completeOnboardingPaperlessEnrollment,
  initiateOnboardingPaperlessEnrollment
} from '../../+state/onboarding-paperless-enrollment-feature-actions';
import { Styles } from '../../util/styles-enum';

@Component({
  selector: 'amfam-go-paperless-confirmation',
  templateUrl: './go-paperless-confirmation.component.html',
  styleUrls: ['./go-paperless-confirmation.component.scss']
})
export class GoPaperlessConfirmationComponent implements OnInit, AfterViewInit, OnDestroy {
  isPolicyEnrolledNow: boolean;
  isPolicyHasErrors: boolean;
  user: UserState;
  isOnboardingFlow = false;
  isOveriviewFlow = false;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  tertiaryButtonLabel: string;
  primaryButtonEvent: () => void;
  secondaryButtonEvent: () => void;
  tertiaryButtonEvent: () => void;
  pageHeadingText: string;
  pageInfoText: string;
  pageActionText: string;
  status: string;
  homeSitePolicies = [];
  propertyServiceUrl: string;
  confirmationData: ConfirmationModel;
  updatedPreferenceBillAccounts: BillAccount[] = [];
  failedPreferenceBillAccounts: BillAccount[] = [];
  totalBillAccounts: BillAccount[] = [];
  classStyle: string;
  billingLineItemClassStyle: string;
  policyLineItemClassStyle: string;
  billAccounts: BillAccount[];
  eSignatureStatus: string;
  private isESignatureRequired: boolean;
  private policyEnrolledGoPaperless: boolean;

  // Shared subject for completing observables
  private stop$: Subject<void> = new Subject<void>();
  private newBillingPreferences: NewBillingPreference[] = [];
  private analyticsSend = false;
  private billAccountsEligibleForAutopay: BillAccount[];
  // **** Start of Analytics data for this component

  constructor(
    private pageTitle: PageTitleService,
    private spinner: LoadingSpinnerService,
    private store: Store,
    private dockingbarService: DockingBarService,
    private copyService: CopyService,
    private billingUtilService: BillingUtilService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private goPaperlessService: GoPaperlessService,
    private analyticsFacade: AnalyticsFacade,
    private elementRef: ElementRef
  ) {
    this.spinner.start();
  }

  homeSiteLink() {
    window.open(this.propertyServiceUrl);
  }

  ngOnInit() {
    combineLatest(
      this.store.select(PolicySummarySelectors.getHomesitePolicies),
      this.store.select(BrandSelectors.getPropertyServiceUrl),
      (policies, url) => ({
        policies: policies,
        url: url
      })
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.propertyServiceUrl = state.url;
        if (
          state.url.indexOf('?') > 0 &&
          state.policies &&
          state.policies.length &&
          state.policies.length > 0
        ) {
          this.propertyServiceUrl =
            this.propertyServiceUrl + '&policyNumber=' + state.policies[0].policyNumber;
        }
      });

    /* Clear if there are any pending payments(will be none though) to be on safe side
     which helps in navigate to confirmation instead payments(BillingPaymentPaths.ADD_SCHEDULED_PAYMENT).*/
    this.store.dispatch(new pendingPaymentActions.ClearPendingPaymentsAction());

    this.pageTitle.set('Confirmation');
    this.dockingbarService.registerHeading('Confirmation');

    /*
     * Get to know from which way the user is navigating? (Onboarding or Overview)
     */

    // Onboarding store response
    this.store
      .select(paperlessOnboardingEnrollmentFeatureQuery.selectIsOnboardingFlow)
      .pipe(take(1))
      .subscribe(data => (this.isOnboardingFlow = data));

    // Overview store response
    this.store
      .select(paperlessOverviewEnrollmentFeatureQuery.selectIsOverviewFlow)
      .pipe(take(1))
      .subscribe(data => (this.isOveriviewFlow = data));
    /*
     Get bill accounts that are

     1. not registered elsewhere (you cannot register an account that is registered to someone else)
     2. not enrollend in AFT (you cannot register AFT enrolled accounts)
     3. not already registered (you cannot register an account that is already registered)

     */

    combineLatest(
      this.store.select(BillAccountsSelectors.getBillAccountsNotifications),
      this.store.select(GoPaperLessSelectors.isEnrolledGoPaperless),
      this.store.select(GoPaperLessSelectors.getCorrelationId),
      this.store.select(GoPaperLessSelectors.getGoPaperlessError),
      this.store.select(PolicySummarySelectors.getHomesitePolicies),
      this.store.select(BillAccountsSelectors.getBillAccounts),
      this.store.select(paperlessOverviewEnrollmentFeatureQuery.selectCorrelationId),
      this.store.select(paperlessOnboardingEnrollmentFeatureQuery.selectCorrelationId),
      (
        billAccountsNotifications,
        isPolicyEnrolled,
        policyCorrelationId,
        policyHasErrors,
        homeSitePolicies,
        billaccounts: BillAccount[],
        overviewCorrelationId,
        onboardingCorrelationId
      ) => ({
        billAccountsNotifications: billAccountsNotifications,
        isPolicyEnrolled: isPolicyEnrolled,
        policyCorrelationId: policyCorrelationId,
        policyHasErrors: policyHasErrors,
        homeSitePolicies: homeSitePolicies,
        billaccounts: billaccounts,
        correlationId: this.isOnboardingFlow ? onboardingCorrelationId : overviewCorrelationId
      })
    )
      .pipe(
        filter(
          state =>
            !!state.correlationId &&
            state.billAccountsNotifications.length > 0 &&
            state.billaccounts.length > 0 &&
            state.billAccountsNotifications.length === state.billaccounts.length &&
            state.billAccountsNotifications.every(bn => bn.prefsFinishedLoading === true) &&
            state.billaccounts.every(bills => bills.updatingPreferenceAction === false)
        ),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        map(state => {
          const filtered = state.billAccountsNotifications.filter(
            ban => ban.updatePreferenceCorrelationId === state.correlationId
          );
          return Object.assign({}, state, { billAccountsNotifications: filtered });
        }),
        takeUntil(this.stop$),
        tap(state => {
          const successBillAccountNotificaitons = state.billAccountsNotifications.filter(
            billAccount => billAccount?.updatedDeliveryPreferences === true
          );
          this.updatedPreferenceBillAccounts = state.billaccounts.filter(ba =>
            successBillAccountNotificaitons.find(
              sban => sban.billAccountNumber === ba.billAccountNumber
            )
          );

          const failedBillAccountNotifications = state.billAccountsNotifications.filter(
            billaccount => billaccount?.updatedDeliveryPreferences === false
          );

          this.failedPreferenceBillAccounts = state.billaccounts.filter(ba =>
            failedBillAccountNotifications.find(
              fban => fban.billAccountNumber === ba.billAccountNumber
            )
          );

          this.totalBillAccounts = state.billaccounts;
          this.isPolicyEnrolledNow =
            state.isPolicyEnrolled && state.policyCorrelationId === state.correlationId;
          this.isPolicyHasErrors = state.policyHasErrors;
          this.homeSitePolicies = state.homeSitePolicies;

          this.formatConfirmationData();
          this.spinner.stop();
        })
      )
      .subscribe();

    // Keeping the analytics call out of the combined latest to avoid triggering it
    // multiple times.
  }

  formatConfirmationData() {
    // create object
    this.confirmationData = {
      confirmation: {}
    };
    this.setCssStylesAndStatus();
    this.setPageInfoTextAndPageActionText();
    this.store
      .select(AutoPayUtilSelectors.getBillAccountsEligibleForAutoPay)
      .pipe(take(1))
      .subscribe(billAccounts => (this.billAccountsEligibleForAutopay = billAccounts));

    if (this.isOnboardingFlow) {
      this.executeOnboardingEvents();
    } else if (this.isOveriviewFlow) {
      this.executeOverviewEvents();
    }

    // call to action
    if (this.homeSitePolicies.length > 0) {
      this.confirmationData.ctaArea = {
        heading: this.copyService.getCopy('goPaperless.goPaperlessHomesiteCtaTitle'),
        text: this.copyService.getCopy('goPaperless.goPaperlessHomesiteCtaText'),
        button: {
          title: 'Go To Homesite',
          callback: this.homeSiteLink.bind(this)
        }
      };
    }
    this.spinner.stop();
  }

  // Overview - Success - Profile - Link
  ngAfterViewInit() {
    const profileLink = this.elementRef.nativeElement.querySelector("a[href='/profile']");

    if (profileLink) {
      profileLink.addEventListener('click', () => {
        this.analyticsFacade.trackButtonClick(
          GoPaperlessAnalytics.linkOverviewGoPapelressSuccessProfile
        );
      });
    }
  }

  /*
   * Method: CTA - Onboarding & Overview CTA Button Navigation
   */
  navigateToPath(url: string) {
    this.store.dispatch(
      // eslint-disable-next-line ngrx/prefer-action-creator-in-dispatch
      new fromRouterActions.Go({
        path: [url]
      })
    );
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
    if (this.billAccountsEligibleForAutopay.length === 0) {
      if (this.isOnboardingFlow) {
        this.store.dispatch(completeOnboardingPaperlessEnrollment());
      } else {
        this.store.dispatch(completeOverviewPaperlessEnrollment());
      }
    }
  }

  private tryAgain() {
    const correlationId = this.utilService.generateId();

    if (this.isOnboardingFlow) {
      this.store.dispatch(completeOnboardingPaperlessEnrollment());
      this.store.dispatch(initiateOnboardingPaperlessEnrollment({ correlationId: correlationId }));
    } else if (this.isOveriviewFlow) {
      this.store.dispatch(completeOverviewPaperlessEnrollment());
      this.store.dispatch(initiateOverviewPaperlessEnrollment({ correlationId: correlationId }));
    }

    this.loadESignature();
    this.onSigningComplete(correlationId);
    if (!this.isESignatureRequired && !this.policyEnrolledGoPaperless) {
      this.spinner.start({
        blockActions: true
      });
      this.store.dispatch(
        new GoPaperLessActions.EnrollGoPaperless({ correlationId: correlationId })
      );
    } else if (this.isESignatureRequired && !this.policyEnrolledGoPaperless) {
      this.store.pipe(select(BrandSelectors.getExperienceId), take(1)).subscribe(experienceId => {
        const callbackUrl = this.utilService.getAbsoluteUrl('paperless/sign-up', experienceId);
        const signatureRequest = this.goPaperlessService.createSignatureRequest(callbackUrl);
        this.store.dispatch(new EsignatureActions.SignESignature(signatureRequest));
      });
      this.spinner.start({
        blockActions: true
      });
    }
    this.setBillingPreferences(correlationId);
    this.routeToConfirmation();
  }

  private setBillingPreferences(correlationId: string) {
    combineLatest([
      this.store.select(BillAccountsSelectors.getAllBillingPreferencesLoaded),
      this.store.select(BillAccountsSelectors.getBillAccounts)
    ])
      .pipe(
        filter(
          ([loaded, billAccounts]) =>
            !!loaded &&
            (billAccounts.length > 0
              ? billAccounts.every(
                  billAccount => _get(billAccount, 'billingPreferences.preferences') !== undefined
                )
              : false)
        ),
        take(1)
      )
      .subscribe(([, billAccounts]) => {
        this.billAccounts = billAccounts;
      });

    this.newBillingPreferences = this.billingUtilService.setBillAccountsToPaperless(
      this.billAccounts
    );

    if (this.newBillingPreferences && this.newBillingPreferences.length > 0) {
      this.newBillingPreferences.forEach(newPreference => {
        this.store
          .select(
            BillAccountsSelectors.getBillAccountDataToUpdatePreferences(
              newPreference.billAccountNumber
            )
          )
          .pipe(take(1))
          .subscribe((billAccountData: BillAccountData) => {
            const preferencesObj = this.billingUtilService.buildPreferencePayload(
              newPreference,
              billAccountData,
              correlationId
            );
            this.store.dispatch(
              new BillAccountActions.BillAccountUpdateDeliveryPreference(preferencesObj)
            );
          });
      });
    }
  }

  private routeToConfirmation() {
    combineLatest([
      this.store.select(GoPaperLessSelectors.getGoPaperlessLoaded),
      this.store.select(CommunicationPreferencesSelectors.getAnyLoading),
      this.store.select(BillAccountsSelectors.getBillAccounts)
    ])
      .pipe(
        filter(
          ([policyLoaded, billingLoading, billAccounts]) =>
            !!policyLoaded &&
            !billingLoading &&
            (billAccounts.length > 0
              ? billAccounts.every(
                  billAccount => _get(billAccount, 'billingPreferences.preferences') !== undefined
                )
              : false)
        ),
        take(1)
      )
      .subscribe(([policyLoaded, billingLoading]) => {
        if (policyLoaded && !billingLoading) {
          this.spinner.stop();
          this.store.dispatch(
            new fromRouterActions.Go({ path: ['/paperless/gopaperless/confirmation'] })
          );
        }
      });
  }

  private loadESignature() {
    combineLatest([
      this.store.select(GoPaperLessSelectors.isEnrolledGoPaperless),
      this.store.select(EsignatureSelectors.getESignatureRequired)
    ])
      .pipe(takeUntil(this.stop$))
      .subscribe(([eDelivery, eSignatureRequired]) => {
        this.policyEnrolledGoPaperless = eDelivery;
        this.isESignatureRequired = eSignatureRequired;
      });
  }

  private onSigningComplete(correlationId: string) {
    this.route.queryParams.pipe(takeUntil(this.stop$)).subscribe(queryParams => {
      // Query param from docusign
      if (queryParams['event']) {
        this.eSignatureStatus = queryParams['event'];
      }
    });
    if (this.eSignatureStatus === 'OnSigningComplete') {
      this.spinner.start({
        blockActions: true
      });
      this.store.dispatch(
        new GoPaperLessActions.EnrollGoPaperless({ correlationId: correlationId })
      );
      this.setBillingPreferences(correlationId);
      this.routeToConfirmation();
    }
  }

  private sendAnalytics(analyticPage: PageAnalyticParams) {
    this.analyticsFacade.trackPage(analyticPage);
  }

  /**
   * Text upate of pageInfoText and pageActionText for (Onboarding or Overview) enrollment.
   */
  private setPageInfoTextAndPageActionText() {
    // Set pageInfoText content
    this.store
      .select(userQuery.getUserState)
      .pipe(
        filter(user => !!user),
        take(1),
        tap(user => {
          this.user = user;
          const userEmailAddress = this.user?.emailAddress ? this.user?.emailAddress : 'your email';
          const goPaperLessSuccessText = this.copyService.getCopy(
            'goPaperless.goPaperLessSuccessText',
            {
              userEmailAddress
            }
          );
          const goPaperLessPartialSuccessAndFailureText = this.copyService.getCopy(
            'goPaperless.goPaperLessPartialSuccessAndFailureText'
          );
          const goPaperLessPartialSuccessText = this.copyService.getCopy(
            'goPaperless.goPaperLessPartialSuccessText'
          );
          const goPaperLessFailureText = this.copyService.getCopy(
            'goPaperless.goPaperLessFailureText'
          );

          // If Overview & Onboarding :: Success
          if (this.status === 'success') {
            // head text
            this.pageInfoText = goPaperLessSuccessText;
          }
          // If Overview & Onboarding :: Partial Success show the text
          else if (this.status === 'partialSuccess') {
            // head text
            this.pageInfoText = goPaperLessPartialSuccessAndFailureText;
            // foot text
            this.pageActionText = goPaperLessPartialSuccessText;
          }
          // If Overview & Onboarding :: Failure show the text
          else if (this.status === 'failure') {
            // head text
            this.pageInfoText = goPaperLessPartialSuccessAndFailureText;
            // foot text
            this.pageActionText = goPaperLessFailureText;
          }
        })
      )
      .subscribe();
  }

  /* *
   * Check if Policy has errors with how many BillAccounts get failed and how many total BillAccounts we have.
   * isPolicyHasErrors, failedPreferenceBillAccounts and totalBillAccounts.
   * Based on the above case, we should get three status 1) success, 2) partial success, 3) failure
   */
  private setCssStylesAndStatus() {
    // Set page headline
    const goPaperLessSuccessTitle = this.copyService.getCopy('goPaperless.goPaperLessSuccessTitle');
    const goPaperLessPartialFailureTitle = this.copyService.getCopy(
      'goPaperless.goPaperLessPartialSuccessTitle'
    );
    const goPaperLessFailureTitle = this.copyService.getCopy('goPaperless.goPaperLessFailure');
    if (
      this.isPolicyHasErrors &&
      this.failedPreferenceBillAccounts.length === this.totalBillAccounts.length
    ) {
      this.pageHeadingText = goPaperLessFailureTitle;
      this.classStyle = Styles.FAILURE;
      this.policyLineItemClassStyle = Styles.FAILURE;
      this.billingLineItemClassStyle = Styles.FAILURE;
      this.status = 'failure';
    }
    // If Success, we should
    else if (!this.isPolicyHasErrors && !(this.failedPreferenceBillAccounts.length > 0)) {
      this.pageHeadingText = goPaperLessSuccessTitle;
      this.classStyle = Styles.SUCCESS;
      this.policyLineItemClassStyle = Styles.SUCCESS;
      this.billingLineItemClassStyle = Styles.SUCCESS;
      this.status = 'success';
    }
    // If Partial Success, we should
    else if (this.isPolicyHasErrors || this.failedPreferenceBillAccounts.length > 0) {
      this.pageHeadingText = goPaperLessPartialFailureTitle;
      this.classStyle = Styles.PARTIALSUCCESS;

      if (this.isPolicyHasErrors) {
        this.policyLineItemClassStyle = Styles.PARTIALSUCCESS;
      }
      if (this.failedPreferenceBillAccounts.length > 0) {
        this.billingLineItemClassStyle = Styles.PARTIALSUCCESS;
      }
      this.status = 'partialSuccess';
    }
  }

  /**
   * execute onboarding actions/cta button events based on success, failure, partial success
   */
  private executeOnboardingEvents() {
    // Onboarding :: Success
    if (this.status === 'success') {
      // take the user to autopay setup if they have eligible bill accounts
      if (this.billAccountsEligibleForAutopay.length === 0) {
        this.sendAnalytics(GoPaperlessAnalytics.pageOnboardingGoPaperlessAlreadyOnAutoPaySuccess);
        this.primaryButtonLabel = 'Done';
        this.secondaryButtonLabel = null;
        this.primaryButtonEvent = () => {
          this.navigateToPath('/overview');
          this.analyticsFacade.trackButtonClick(
            GoPaperlessAnalytics.buttonOnboardingGoPaperlessAlreadyOnAutoPaySuccessDone
          );
        };
      } else {
        this.primaryButtonLabel = 'Set Up AutoPay';
        this.secondaryButtonLabel = null;
        this.tertiaryButtonLabel = 'Skip AutoPay Setup';
        this.primaryButtonEvent = () => {
          this.navigateToPath('/billing/auto-pay/select-accounts');
          // Analytic - Success - SetupAutoPay - Button
          this.analyticsFacade.trackButtonClick(
            GoPaperlessAnalytics.buttonOnboardingGoPaperlessSuccessSetUpAutoPay
          );
        };
        this.tertiaryButtonEvent = () => {
          this.navigateToPath('/overview');
          // Analytic - Success - SkipAutoPaySetup - Button
          this.analyticsFacade.trackButtonClick(
            GoPaperlessAnalytics.buttonOnboardingGoPaperlessSuccessSkipAutoPaySetup
          );
        };
        // Analytic - Success - Page
        this.sendAnalytics(GoPaperlessAnalytics.pageOnboardingGoPaperlessSuccess);
      }
    }
    // Onboarding :: Partial Success & Failure
    else if (this.status === 'partialSuccess' || this.status === 'failure') {
      if (this.billAccountsEligibleForAutopay.length === 0) {
        this.primaryButtonLabel = 'Try Again';
        this.secondaryButtonLabel = null;
        this.tertiaryButtonLabel = 'Cancel';

        if (this.status === 'partialSuccess') {
          this.sendAnalytics(
            GoPaperlessAnalytics.pageOnboardingGoPaperlessAlreadyOnAutoPayPartialSuccess
          );
          this.primaryButtonEvent = () => {
            this.tryAgain();
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessAlreadyOnAutoPayPartialSuccessTryAgain
            );
          };
          this.tertiaryButtonEvent = () => {
            this.navigateToPath('/overview');
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessAlreadyOnAutoPayPartialSuccessCancel
            );
          };
        } else if (this.status === 'failure') {
          this.sendAnalytics(GoPaperlessAnalytics.pageOnboardingGoPaperlessAlreadyOnAutoPayFailure);
          this.primaryButtonEvent = () => {
            this.tryAgain();
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessAlreadyOnAutoPayFailureTryAgain
            );
          };
          this.tertiaryButtonEvent = () => {
            this.navigateToPath('/overview');
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessAlreadyOnAutoPayFailureCancel
            );
          };
        }
      } else {
        // Button Labels
        this.primaryButtonLabel = 'Try Again';
        this.secondaryButtonLabel = 'Continue to AutoPay Setup';
        this.tertiaryButtonLabel = 'Skip AutoPay Setup';

        // Button Events
        // Onboarding - partialSuccess
        if (this.status === 'partialSuccess') {
          // Analytic - PartialSuccess - Page
          this.sendAnalytics(GoPaperlessAnalytics.pageOnboardingGoPaperlessPartialSuccess);

          // Analytic - PartialSuccess - TryAgain - Button
          this.primaryButtonEvent = () => {
            this.tryAgain();
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessPartialSuccessTryAgain
            );
          };
          // Analytic - PartialSuccess - Continue to AutoPay Setup - Button
          this.secondaryButtonEvent = () => {
            this.navigateToPath('/billing/auto-pay/select-accounts');
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessPartialSuccessContinueAutoPaySetup
            );
          };
          // Analytic - PartialSuccess - Skip AutoPay Setup - Button
          this.tertiaryButtonEvent = () => {
            this.navigateToPath('/overview');
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessPartialSuccessSkipAutoPaySetup
            );
          };
        }

        // Onboarding - error
        else if (this.status === 'failure') {
          // Analytic - Failure - Page
          this.sendAnalytics(GoPaperlessAnalytics.pageOnboardingGoPaperlessFailure);

          // Analytic - Failure - TryAgain - Button
          this.primaryButtonEvent = () => {
            this.tryAgain();
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessFailureTryAgain
            );
          };
          // Analytic - Failure - Continue to AutoPay Setup - Button
          this.secondaryButtonEvent = () => {
            this.navigateToPath('/billing/auto-pay/select-accounts');
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessFailureContinueAutoPaySetup
            );
          };
          // Analytic - Failure - Skip AutoPay Setup - Button
          this.tertiaryButtonEvent = () => {
            this.navigateToPath('/overview');
            this.analyticsFacade.trackButtonClick(
              GoPaperlessAnalytics.buttonOnboardingGoPaperlessFailureSkipAutoPaySetup
            );
          };
        }
      }
    }
  }

  /**
   * execute overview actions/cta button events based on success, failure, partial success
   */
  private executeOverviewEvents() {
    // Set pageActionText content
    const overviewSuccessPageActionText = this.copyService.getCopy(
      'goPaperless.overviewSuccessPageActionText'
    );
    // Overview :: Success
    if (this.isOveriviewFlow && this.status === 'success') {
      this.pageActionText = overviewSuccessPageActionText;
      this.primaryButtonLabel = 'Done';
      this.secondaryButtonLabel = null;
      this.tertiaryButtonLabel = null;
      this.primaryButtonEvent = () => {
        this.navigateToPath('/overview');
        // Analytic - Success - Done - Button
        this.analyticsFacade.trackButtonClick(
          GoPaperlessAnalytics.buttonOverviewGoPaperlessSuccessDone
        );
      };
      // foot text
      this.pageActionText = overviewSuccessPageActionText;
      // Analytic - Success - Enroll_Both - Page
      this.sendAnalytics(GoPaperlessAnalytics.pageOverviewGoPaperlessSuccess);
    }
    // Overview :: Partial Success & Failure
    else if (
      this.isOveriviewFlow &&
      (this.status === 'partialSuccess' || this.status === 'failure')
    ) {
      // Button Labels
      this.primaryButtonLabel = 'Try Again';
      this.secondaryButtonLabel = null;
      this.tertiaryButtonLabel = 'Cancel';

      // Button Events
      // Overview - partialSuccess
      if (this.status === 'partialSuccess') {
        // Analytic - PartialSuccess - Page
        this.sendAnalytics(GoPaperlessAnalytics.pageOverviewGoPaperlessPartialSuccess);

        // Analytic - PartialSuccess - TryAgain - Button
        this.primaryButtonEvent = () => {
          this.tryAgain();
          this.analyticsFacade.trackButtonClick(
            GoPaperlessAnalytics.buttonOverviewGoPaperlessPartialSuccessTryAgain
          );
        };

        // Analytic - PartialSuccess - Cancel - Button
        this.tertiaryButtonEvent = () => {
          this.navigateToPath('/overview');
          this.analyticsFacade.trackButtonClick(
            GoPaperlessAnalytics.buttonOverviewGoPaperlessPartialSuccessCancel
          );
        };
      }
      // Overview - failure
      else if (this.status === 'failure') {
        // Analytic - Failure - Page
        this.sendAnalytics(GoPaperlessAnalytics.pageOverviewGoPaperlessFailure);

        // Analytic - PartialSuccess - TryAgain - Button
        this.primaryButtonEvent = () => {
          this.tryAgain();
          this.analyticsFacade.trackButtonClick(
            GoPaperlessAnalytics.buttonOverviewGoPaperlessFailureTryAgain
          );
        };

        // Analytic - PartialSuccess - Cancel - Button
        this.tertiaryButtonEvent = () => {
          this.navigateToPath('/overview');
          this.analyticsFacade.trackButtonClick(
            GoPaperlessAnalytics.buttonOverviewGoPaperlessFailureCancel
          );
        };
      }
    }
  }
}

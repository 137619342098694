import { ApiStatus } from '@amfam/shared/models';
import { VehicleFeaturesModel } from './vehicle-features.model';

export interface VehicleSelectCriteria {
  make?: string;
  series?: string;
  model?: string;
  odometer?: string;
  vin?: string;
  year?: string;
  vehicleType?: string;
  underWritingQuestions?: UnderwritingQuestion[];
  copyTPIIndicator?: TPIIndicator;
  TPIName?: string;
}

export interface VehicleSelect {
  status?: any;
  hasError?: boolean;
  loading: boolean;
  criteria: VehicleSelectCriteria;
  newVehicle: any;
  years: Array<number>;
  makes: Array<string>;
  models: Array<string>;
  styles: Array<string>;
}

export interface LookupVinYearPayload {
  year: string;
  vin: string;
}

export interface GetMakesPayload {
  year: string;
}

export interface GetModelsPayload {
  year: string;
  make: string;
}

export interface GetStylesPayload {
  year: string;
  make: string;
  model: string;
}

export interface GetVehicleMakesApiResponse {
  status: ApiStatus;
  makes?: Array<String>;
}

export interface GetVehicleModelsApiResponse {
  status: ApiStatus;
  models?: Array<String>;
}

export interface GetVehicleSeriesApiResponse {
  status: ApiStatus;
  series?: Array<String>;
}

export interface GetVehicleVinYearApiResponse {
  newVehicle: VehicleSelectCriteria;
  features: VehicleFeaturesModel[];
}

export interface UnderwritingQuestion {
  question: string;
  answer: string;
}

export enum TPIIndicator {
  YES = 'yes',
  NO = 'no'
}

export enum VechiclePolicyChangeEntryPoint {
  OVERVIEW = 'overview',
  POLICY = 'policies'
}

import {
  PaymentMethod,
  PaymentMethodModeOfAuthorizationType
} from '@amfam/billing/payment-method/util';
import { ApiStatus } from '@amfam/shared/models';

export interface GetPaymentMethodsResponse {
  paymentAccounts: PaymentMethod[];
  status: ApiStatus;
}

export interface GetPaymentMethodDetailPayload {
  paymentAccountId: string;
  correlationId: string;
}

export interface GetPaymentMethodDetailResponse {
  autoPayRules: AutoPayRule[];
  paymentAccounts: PaymentMethod[];
  payments: Payment[];
  status: ApiStatus;
}

export interface AddEditPaymentMethodPayload {
  lastUpdateTimestamp?: string;
  paymentAccount: {
    nickName: string;
    oldNickName: string;
    modeOfAuthorization?: PaymentMethodModeOfAuthorizationType;
    token: string;
    consumerKey: string;
  };
}

export interface AddEditPaymentMethodResponse {
  paymentAccountId: string;
  status: ApiStatus;
}
export interface DeletePaymentMethodPayload {
  paymentAccountId: string;
}

export interface DeletePaymentMethodResponse {
  status: ApiStatus;
}

export interface Payment {
  autoPayIndicator: string;
  billAccounts: BillAccount[];
  paymentId: string;
  paymentStatus: string;
  receivedDate: string;
  totalPaymentAmount: string;
}

export interface BillAccount {
  billAccountNumber: string;
  billAcctPaymentAmount: string;
}

export interface AutoPayRule {
  billAccountNumber: string;
  daysBeforeDueDate: string;
  paymentAmount: string;
  nextPaymentDate: string;
  predictedDollarAmount: string;
}

export interface UpdateModeOfAuthorizationPayload {
  paymentAccountId: string;
  requestBody: UpdateModeOfAuthorizationRequest;
}

export interface UpdateModeOfAuthorizationRequest {
  paymentAccount: {
    nickName: string;
    modeOfAuthorization: string;
  };
  lastUpdateTimestamp: string;
}

export interface UpdateModeOfAuthorizationResponse {
  paymentAccountId: string;
  status: ApiStatus;
}

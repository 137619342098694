import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[bound-content]'
})
export class BoundContentDirective implements OnInit {
  @Input()
  public bindTo: string;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setProperty(this.element.nativeElement, 'innerHTML', this.bindTo);
  }
}

import { createFeatureSelector, createSelector, ActionReducerMap } from '@ngrx/store';
import { PAYMENT_ACCOUNT_FEATURE_KEY } from './paymentaccount.reducer';
import * as fromPaymentAccountState from './paymentaccount.reducer';
import * as fromPaymentAccountNotifications from './notifications.reducer';
import { get as _get, flatten as _flatten, some as _some } from 'lodash';
import { PaymentAccount, UNAVAILABLE_PAYMENTACCOUNT_ID } from '@amfam/shared/models';
import { Dictionary } from '@ngrx/entity';

export interface PaymentAccountState {
  paymentAccountState: fromPaymentAccountState.PaymentAccountEntityState;
  paymentAccountNotifications: fromPaymentAccountNotifications.NotificationState;
}

export const paymentAccountReducers: ActionReducerMap<PaymentAccountState> = {
  paymentAccountState: fromPaymentAccountState.reducer,
  paymentAccountNotifications: fromPaymentAccountNotifications.reducer
};

export const getPaymentAccountsState = createFeatureSelector<PaymentAccountState>(
  PAYMENT_ACCOUNT_FEATURE_KEY
);

export const getPaymentAccountsLoading = createSelector(
  getPaymentAccountsState,
  (state: PaymentAccountState) => _get(state, 'paymentAccountNotifications.loading', true)
);

export const getPaymentAccountsLoaded = createSelector(
  getPaymentAccountsState,
  (state: PaymentAccountState) => !_get(state, 'paymentAccountNotifications.loading', true)
);

export const getPaymentAccountEntities = createSelector(
  getPaymentAccountsState,
  getPaymentAccountsLoaded,
  (state: PaymentAccountState, isLoaded) =>
    isLoaded
      ? <Dictionary<PaymentAccount>>(
          fromPaymentAccountState.selectEntities(state.paymentAccountState)
        )
      : []
);

export const getPaymentAccountIds = createSelector(
  getPaymentAccountsState,
  getPaymentAccountsLoaded,
  (state: PaymentAccountState, isLoaded) => {
    return isLoaded ? <string[]>fromPaymentAccountState.selectIds(state.paymentAccountState) : [];
  }
);

export const getPaymentAccountsError = createSelector(
  getPaymentAccountsState,
  (state: PaymentAccountState) => !!_get(state, 'paymentAccountNotifications.error')
);

export const getAllPaymentAccounts = createSelector(
  getPaymentAccountEntities,
  getPaymentAccountIds,
  (entities, ids) => {
    return <PaymentAccount[]>ids.map(id => entities[id]);
  }
);

export const getPaymentAccountsWithoutUnavailable = createSelector(
  getPaymentAccountEntities,
  getPaymentAccountIds,
  (entities, ids) => {
    return ids
      .filter(id => {
        return id !== UNAVAILABLE_PAYMENTACCOUNT_ID;
      })
      .map(id => entities[id]);
  }
);

export const getMatchedPaymentAccount = createSelector(
  getPaymentAccountEntities,
  (paymentAccounts, paymentId: string) => {
    return paymentAccounts[paymentId];
  }
);

export const getPaymentAccountsExpiredFirst = createSelector(
  getPaymentAccountsWithoutUnavailable,
  (paymentAccounts = []) => {
    return paymentAccounts.slice().sort((a: PaymentAccount, b: PaymentAccount) => {
      // Assign a weight of 0 for expired payment accounts, 1 for expiring payment accounts, 2 for not expired/expiring payment accounts
      const aValue = a.expired ? 0 : a.expiring ? 1 : 2;
      const bValue = b.expired ? 0 : b.expiring ? 1 : 2;

      if (aValue > bValue) {
        return 1;
      } else if (aValue < bValue) {
        return -1;
      }
      return 0;
    });
  }
);

export const getPaymentAccountsExpiredLast = createSelector(
  getPaymentAccountsExpiredFirst,
  (paymentAccounts = []) => {
    return paymentAccounts.slice().reverse();
  }
);

export const getPaymentAccountsNotificationsEntities = createSelector(
  getPaymentAccountsState,
  getPaymentAccountsLoaded,
  (state: PaymentAccountState, isLoaded) => {
    return isLoaded
      ? fromPaymentAccountNotifications.selectEntities(state.paymentAccountNotifications)
      : [];
  }
);

export const getPaymentAccountsNotificationsIds = createSelector(
  getPaymentAccountsState,
  getPaymentAccountsLoaded,
  (state: PaymentAccountState, isLoaded) => {
    return isLoaded
      ? <string[]>fromPaymentAccountNotifications.selectIds(state.paymentAccountNotifications)
      : [];
  }
);

export const getPaymentAccountsNotifications = createSelector(
  getPaymentAccountsNotificationsEntities,
  getPaymentAccountsNotificationsIds,
  (entities, ids) => {
    return <fromPaymentAccountNotifications.PaymentAccountNotificationState[]>(
      ids.map(id => entities[id])
    );
  }
);

export const getPaymentAccountsNotificationsWithoutUnavailable = createSelector(
  getPaymentAccountsNotificationsEntities,
  getPaymentAccountsNotificationsIds,
  (entities, ids) => {
    return <fromPaymentAccountNotifications.PaymentAccountNotificationState[]>ids
      .filter(id => {
        return id !== UNAVAILABLE_PAYMENTACCOUNT_ID;
      })
      .map(id => entities[id]);
  }
);

export const getPaymentAccountsAnyLoading = createSelector(
  getPaymentAccountsNotifications,
  getPaymentAccountsLoading,
  (paymentAccounts, paymentAccountsLoading) => {
    return paymentAccountsLoading || _some(paymentAccounts, { loading: true });
  }
);

export const paymentAccountQuery = {
  getPaymentAccountsLoading,
  getPaymentAccountsLoaded,
  getPaymentAccountEntities,
  getPaymentAccountIds,
  getPaymentAccountsError,
  getAllPaymentAccounts,
  getPaymentAccountsWithoutUnavailable,
  getMatchedPaymentAccount,
  getPaymentAccountsExpiredFirst,
  getPaymentAccountsExpiredLast,
  getPaymentAccountsNotificationsEntities,
  getPaymentAccountsNotificationsIds,
  getPaymentAccountsNotifications,
  getPaymentAccountsNotificationsWithoutUnavailable,
  getPaymentAccountsAnyLoading
};

<div class="container pad-20">
  <div class="header">
    <img src="/assets/images/benefits.png" />
    <h2>Let's make sure we can help you online</h2>
  </div>
  <p class="pad-top-5">
    If any of the following are true, please check the box and an agent will help you get a quote.
  </p>
  <div class="form-container pad-top-20">
    <form [formGroup]="qualifersFormGroup">
      <div
        formArrayName="qualifiers"
        role="checkbox"
        class="checkbox-container"
        *ngFor="let qualifier of qualifiersFormArray.controls; let i = index"
      >
        <input [formControlName]="i" type="checkbox" id="{{ i }}" name="{{ i }}" (keydown.enter)="onEnterKeyPressed(i)" />
        <label for="{{ i }}">{{ qualifierOptions[i].text }}</label>
        <button
          class="info__button"
          data-cy="infoButton"
          triggerOn="click"
          [heading]="qualifierOptions[i].tooltipContent.heading"
          [tooltip]="qualifierOptions[i].tooltipContent.body"
          [showCloseButton]="true"
          [closeOnClickingOutside]="false"
          [closeOnEscape]="true"
        >
          <i class="icon-info"></i>
        </button>
      </div>
    </form>
  </div>
  <div class="cta-buttons">
    <call-to-action
      primaryButtonName="Continue"
      secondaryButtonName="Cancel"
      [fullWidthButtons]="false"
      [capitalize]="true"
      (primaryEvent)="continue()"
      (secondaryEvent)="cancel()"
      [equalWidthButtons]="true"
      [disablePrimaryButton]="isOpportunityQuoted$ | async"
    >
    </call-to-action>
  </div>
</div>



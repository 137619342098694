<ul class="progress-bar">
  <li
    *ngFor="let step of steps; let i = index"
    [ngClass]="{ 'complete icon': step.complete, active: step.active }"
  >
    <span>
      <span class="circle">{{ i + 1 }}</span>
      <span *ngIf="!step.active" class="none md-inline-block pad-left-10">{{ step.title }}</span>
      <span *ngIf="step.active" class="md-inline-block pad-left-10">{{ step.title }}</span>
    </span>
  </li>
</ul>

import {
  BillAccountCanRegister,
  initialBillAccountCanRegister
} from '../models/bill-account-can-register.models';
import * as billAccountCanRegister from './bill-account-can-register.actions';
import { get as _get } from 'lodash';

// This reduces a set of registrations
export function billAccountCanRegisterReducer(
  state = initialBillAccountCanRegister,
  action: billAccountCanRegister.BillAccountCanRegisterAction
): BillAccountCanRegister {
  switch (action.type) {
    case billAccountCanRegister.CAN_REGISTER:
      return Object.assign({}, state, initialBillAccountCanRegister, action.payload, {
        loading: true
      });

    case billAccountCanRegister.CAN_REGISTER_CLEAR:
      return Object.assign({}, state, initialBillAccountCanRegister);

    case billAccountCanRegister.CAN_REGISTER_SUCCESS:
      // AS: Get the policy number from the response payload and override the policy number of state(request payload)
      // with the policy number in response payload which might include the needed "check digits"
      const policyObj = _get(action, 'payload.billingAccount.policyList', []).find(
        policyObjFromResponse =>
          _get(policyObjFromResponse, 'policyNumber', '').substring(0, 10) ===
          state.policyNumber.substring(0, 10)
      );
      return Object.assign({}, state, action.payload, {
        policyNumber: policyObj ? policyObj.policyNumber : state.policyNumber,
        loading: false,
        loaded: true
      });

    case billAccountCanRegister.CAN_REGISTER_FAIL:
      return Object.assign({}, state, action.payload, {
        loading: false,
        loaded: true
      });

    default:
      return state;
  }
}

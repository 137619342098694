import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '@amfam/shared/utility/shared-services';

@Injectable()
export class BusinessConversionService {
  constructor(private configService: ConfigService, private http: HttpClient) {}

  public convertAccount(waid: string, partyId: string): Observable<any> {
    const digitalAPI = this.configService.get('digitalAccountApi');
    const postURL = this.buildURL(digitalAPI);
    const data = {
      waid: waid,
      customerId: partyId
    };
    return this.http.post(postURL, JSON.stringify(data));
  }

  private extractData(body: any) {
    return body.data || {};
  }

  private buildURL(api: string): string {
    return api + '/digitalaccounts/convertuser';
  }
}

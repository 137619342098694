import { combineLatest, from as observableFrom, Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, first, map, mergeMap, take, toArray } from 'rxjs/operators';

import { File, SignatureRequest, Vehicle } from '@amfam/policy/models';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import {
  EnrollmentsResponse,
  KydConfirmation,
  KydEnrollmentRequest,
  KydEnrollmentResponse,
  KydSignUp
} from '../../../core/store/programs/kyd';

import { get as _get } from 'lodash';
import { KydEligibilityResponse } from '../../../core/store/programs/kyd/models/kyd-eligibility-response.model';

import { userQuery } from '@amfam/shared/user';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/store';
import { CustomerInfoModel } from '../../../core/store/programs/kyd/models';

// date-fns
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class KydService {
  private endpoint: string;
  private enrollmentId: number;
  private customerInfo: CustomerInfoModel;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private store: Store<fromRoot.RootState>
  ) {
    this.endpoint = this.config.get('ubiApi');

    this.getCustomerDetail();
  }

  private getCustomerDetail() {
    combineLatest(
      this.store.select(userQuery.getCustomerId),
      this.store.select(fromRoot.getProfileCardData)
    ).subscribe(result => {
      const customerId = result[0] || '';
      const profileData = result[1] || {};

      this.customerInfo = {
        customerId: customerId,
        emailAddress: _get(profileData, 'primaryEmail.emailAddress', ''),
        phoneNumber: _get(profileData, 'primaryPhone.tenDigitPhoneNumber', '')
      };
    });
  }

  private getEnrollments(policyNumber?: string, vin?: string): Observable<EnrollmentsResponse> {
    return this.store.select(userQuery.getCustomerId).pipe(
      first(),
      mergeMap(customerId => {
        let url = `${this.endpoint}enrollments?customerIdentifier=${customerId}`;
        if (policyNumber) {
          url += `&policyNumber=${policyNumber}`;
        }
        if (vin) {
          url += `&vin=${vin}`;
        }

        return this.http.get(url).pipe(
          map((data: any) => {
            let enrollment: EnrollmentsResponse = null;
            if (data.namedInsured !== null && typeof data.namedInsured !== 'undefined') {
              enrollment = <EnrollmentsResponse>data.namedInsured;
            }
            return enrollment;
          })
        );
      })
    );
  }

  public getEnrollment(enrollmentId): Observable<any> {
    const url = `${this.endpoint}enrollments/${enrollmentId}`;
    return this.http.get(url);
  }

  public getPolicyEnrollments(policyNumber: string): Observable<EnrollmentsResponse> {
    return this.getEnrollments(policyNumber);
  }

  public verifyEligibility(
    policyNumber?: string,
    vin?: string,
    state?: string
  ): Observable<KydEligibilityResponse> {
    return this.store.select(userQuery.getCustomerId).pipe(
      first(),
      mergeMap(customerId => {
        const requestObj = {
          customerIdentifier: customerId,
          policyNum: policyNumber,
          vin: vin,
          stateCd: state
        };

        return this.http.post(this.endpoint + 'verifyeligibility', requestObj).pipe(
          map((data: any) => {
            const categoryCode: any = _get(data, 'eligibleProgramCategories[0].code');
            const eligibility: any = _get(data, 'programCategories.' + categoryCode, {});
            const policyEligible: string = _get(
              eligibility,
              'policyEligibilityType.isPolicyEligibleForUBI',
              'Y'
            );
            const vehicleEligible: any = _get(
              eligibility,
              'vehicleEligibilityType.isVehicleEligibleForUBI',
              'N'
            );
            const customerEligible: any = _get(
              eligibility,
              'customerEligibilityType.isCustomerEligibleForUBI',
              'N'
            );
            const stateEligible: any = _get(
              eligibility,
              'stateEligibilityType.isUBIProgramEligibileInState',
              'N'
            );

            const phhEligible: any = _get(
              eligibility,
              'policyEligibilityType.isPartialHouseHoldApplicable',
              'N'
            );

            const eligibilityResponse: KydEligibilityResponse = {
              stateEligible: stateEligible === 'Y',
              customerEligible: customerEligible === 'Y',
              policyEligible: policyEligible === 'Y',
              vehicleEligible: vehicleEligible === 'Y',
              reasonCode: _get(eligibility, 'policyEligibilityType.reasonType[0].code', ''),
              reason: _get(eligibility, 'policyEligibilityType.reasonType[0].message', ''),
              programCategory: categoryCode,
              vinNumber: vin,
              policyNumber: policyNumber,
              phhEligible: phhEligible === 'Y'
            };

            if (eligibilityResponse.reasonCode === '400002') {
              eligibilityResponse.vehicleEligible = true;
            }

            return eligibilityResponse;
          })
        );
      })
    );
  }

  public saveEnrollments(signUps: KydSignUp[]): Observable<KydConfirmation[]> {
    return observableFrom(signUps).pipe(
      mergeMap(signUp => {
        return combineLatest(
          this.store.select(userQuery.getCustomerId),
          this.store.select(fromRoot.getProfileCardData),
          (customerId, profileData) => {
            return {
              customerId: customerId,
              profileData: profileData
            };
          }
        ).pipe(
          filter(state => _get(state, 'profileData.primaryEmail.emailAddress')),
          take(1),
          mergeMap(result => {
            return of({
              customerId: result.customerId,
              emailAddress: _get(result, 'profileData.primaryEmail.emailAddress', ''),
              phoneNumber: _get(result, 'profileData.primaryPhone.tenDigitPhoneNumber', '')
            });
          }),
          mergeMap((res: CustomerInfoModel) => {
            const enrollmentRequest = this.buildKydEnrollmentRequest(signUp, res);
            const data = JSON.stringify({ EnrollmentRequest: enrollmentRequest });
            return this.http
              .post(this.endpoint + 'enrollments', data)
              .pipe(
                map((enrollmentResponse: KydEnrollmentResponse) =>
                  this.buildKydConfirmation(
                    enrollmentResponse,
                    enrollmentRequest,
                    signUp.riskModel.policy
                  )
                )
              );
          }, 1), // TODO: remove concurrent request limit after UBI API race condition is fixed
          toArray()
        );
      })
    );
  }

  public deleteEnrollment(enrollmentId: number): Observable<any> {
    const url = `${this.endpoint}enrollments/${enrollmentId}?OptOutReasonCode=2002&optOutType=NamedInsuredVoluntarilyOptedOut`;
    return this.http.delete(url);
  }

  public createSignatureRequest(callbackUrl: string): SignatureRequest {
    const eSignatureConsentFile = new File(
      '/assets/documents/ga-kyd-electronic-signature-consent.pdf',
      'application/pdf',
      'ELECTRONIC SIGNATURE CONSENT AND DISCLOSURE'
    );
    const goPaperlessTermsFile = new File(
      '/assets/documents/ga-kyd-electronic-communication-disclosure.pdf',
      'application/pdf',
      'Electronic Communication Disclosure Form'
    );
    const files = [eSignatureConsentFile, goPaperlessTermsFile];
    return new SignatureRequest(files, callbackUrl);
  }

  public storeSelectedRisks(signUps: KydSignUp[]) {
    const json = JSON.stringify(signUps);
    sessionStorage.setItem('kyd-sign-ups', json);
  }

  public retrieveSelectedRisks(): KydSignUp[] {
    const json = sessionStorage.getItem('kyd-sign-ups');
    const signUps = JSON.parse(json);
    return signUps;
  }

  private buildKydEnrollmentRequest(
    optIn: KydSignUp,
    customerInfo: CustomerInfoModel
  ): KydEnrollmentRequest {
    const risk = <Vehicle>optIn.riskModel.risk;
    const kydEnrollment: KydEnrollmentRequest = new KydEnrollmentRequest();
    kydEnrollment.customerIdentifier = customerInfo.customerId;
    kydEnrollment.externalCustomerIdentifier = '';
    kydEnrollment.vin = optIn.riskModel.vin;
    kydEnrollment.vehicleYear = risk.year;
    kydEnrollment.vehicleMake = risk.make;
    kydEnrollment.vehicleModel = risk.model;
    kydEnrollment.policyNumber = optIn.riskModel.policy.policyNumber;
    kydEnrollment.termsAcceptedIndicator = 'Y';
    kydEnrollment.acknowledgementDate = format(new Date(), 'YYYY-MM-DD');
    kydEnrollment.requestDate = format(new Date(), 'YYYY-MM-DD');
    kydEnrollment.optInReasonCode = '1001';
    kydEnrollment.optInType = '1';
    kydEnrollment.emailAddress = customerInfo.emailAddress || '';
    kydEnrollment.phoneNumber = customerInfo.phoneNumber || '';
    kydEnrollment.shippingAddress = optIn.address;
    kydEnrollment.policyState = optIn.riskModel.address.state;
    kydEnrollment.shipDevice = optIn.riskModel.hasExistingDevice ? 'N' : 'Y';
    kydEnrollment.policyEffectiveDate = format(
      optIn.riskModel.policy.periodStartDate,
      'YYYY-MM-DD'
    );
    kydEnrollment.policyExpireDate = format(optIn.riskModel.policy.periodEndDate, 'YYYY-MM-DD');

    return kydEnrollment;
  }

  private buildKydConfirmation(response: KydEnrollmentResponse, request, policy): KydConfirmation {
    return {
      success: +response.code === 200,
      risk: {
        description: `${request.vehicleYear} ${request.vehicleMake} ${request.vehicleModel}`,
        policy: policy,
        vin: request.vin,
        shippingAddress: request.shippingAddress
      }
    };
  }
}

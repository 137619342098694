<div
  *ngIf="notifications?.messagesArray?.length"
  class="notification-container"
  (mouseover)="subOpen()"
  (mouseout)="subClose()"
>
  <div
    #notificationElem
    class="notification-link"
    [class.has-children]="notifications?.messagesArray?.length"
  >
    <a
      tabindex="0"
      role="menuitem"
      [href]=""
      [attr.aria-label]="notificationMessage"
      [attr.aria-expanded]="submenuExpanded"
      [attr.aria-haspopup]="submenuHasPopup ? 'menu' : 'false'"
      (focus)="subOpen()"
      (blur)="subClose()"
    >
      <span class="notification-icon icon-bell"></span>
      <div data-cy="headerNotificationNumber" class="notification-number">
        {{ notifications?.messagesArray?.length }}
      </div>
    </a>
  </div>
  <ul
    [@toggleSubmenu]="showNotificationMenuSub"
    class="notification-message"
    #subNotificationElem
    role="menu"
  >
    <li *ngFor="let message of notifications?.messagesArray" role="menuitem" class="message-item">
      <div class="flex items-center">
        <a
          class="item-link"
          role="menuitem"
          [style.width.px]="notifications?.width"
          [attr.aria-label]="message.linkText"
          [attr.data-cy]="message?.linkTestId"
          (click)="handleNotificationClick(message)"
          (focus)="subOpen()"
          (blur)="subClose()"
        >
          <span class="link-icon icon-documents"></span>
          <span class="link-text">{{ message.linkText }}</span>
        </a>
        <a
          tabindex="0"
          role="button"
          class="dismiss-link"
          data-cy="notificationDismissItems"
          [href]=""
          (focus)="subOpen()"
          (blur)="subClose()"
          (click)="onDismissClick(message)"
        >
          <span class="dismiss-icon icon-exit"><span class="sr-only">Dismiss Message</span></span>
        </a>
      </div>
    </li>
  </ul>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { UiKitModule } from '@amfam/ui-kit';

@NgModule({
  imports: [CommonModule, UiKitModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent]
})
export class SharedUiBreadcrumbModule {}

import { ApiStatus } from '@amfam/shared/models';
import { OpportunityContent } from '@amfam/shared/utility/shared-services';
import { FeedBack } from './feedback';
import { Link } from './link';
import { OpportunityProduct } from './product';

export class OpportunityConstants {
  public static readonly umbrellaOpportunityProductCode: string = 'umbr';
  public static readonly ersOpportunityProductCode: string = 'ers';
  public static readonly rentersOpportunityProductCode: string = 'renters';
  public static readonly lifeOpportunityProductCode: string = 'life';
  public static readonly ebcOpportunityProductCode: string = 'ebci';
  public static readonly slcOpportunityProductCode: string = 'slc';
  public static readonly umbrellaOpportunityProductName: string = 'umbrella';
  public static readonly ersOpportunityProductName: string = 'ers';
  public static readonly rentersOpportunityProductName: string = 'renters';
  public static readonly lifeOpportunityProductName: string = 'life';
  public static readonly ebcOpportunityProductName: string = 'ebci';
  public static readonly slcOpportunityProductName: string = 'slc';
  public static readonly autoOpportunityProductName: string = 'auto';
  public static readonly propOpportunityProductName: string = 'prop';
  public static readonly ersOpportunitySubProductCode: string = 'ers_cov_ind';
  public static readonly rentersOpportunitySubProductCode: string = 'rr_lmt';
  public static readonly lifeOpportunitySubProductCode: string = 'hh_my_life_ind';
  public static readonly ebcOpportunitySubProductCode: string = 'equip_brkdwn_cov_ind';
  public static readonly slcOpportunitySubProductCode: string = 'serv_line_cov_ind';
  public static readonly accidentOpportunitySubProductCode: string = 'accident_forgiveness';
  public static readonly accidentOpportunityProductName: string = 'accident';
  public static readonly autoDiminishingDeductableProductName = 'autodimded';
  public static readonly autoDiminishingDeductableSubProductCode = 'dim_deductible';
  public static readonly propertyDiminishingDeductableProductName = 'propdimded';
  public static readonly propertyDiminishingDeductableSubProductCode = 'dim_deductible';
  public static readonly propertyIdentityTheftProductName = 'idtheft';
  public static readonly propertyIdentityTheftSubProductCode = 'identity_theft';
  public static readonly propertyMatchingSidingProductName = 'siding';
  public static readonly propertyMatchingSidingSubProductCode = 'match_siding';
  public static readonly propertySumpPumpProductName = 'sump';
  public static readonly propertySumpPumpSubProductCode = 'sump_pump';
  public static readonly autoLeaseLoanGapOpportunitySubProductCode = 'lease_loan_gap';
  public static readonly autoLeaseLoanGapOpportunityProductName = 'leaseloan';
  public static readonly propertyHiddenLoanOpportunitySubProductCode = 'hidden_water';
  public static readonly propertyHiddenLoanGapOpportunityProductName = 'hiddenwater';
  public static readonly automotorcycle = 'motorcycle';
  public static readonly automotorhome = 'rvcamper';
  public static readonly autoatv = 'atv';
  public static readonly autosnowmobile = 'snowmobile';
  public static readonly autowatercraft = 'boat';
}
export interface ResponseOpportunityModel {
  householdId: string;
  recommendationId: string;
  recommendationType: string;
  recommendationDesc: string;
  recommendationDetails: string;
  productType: string;
  subProductType: string;
  coverageLimit: string;
  recommendationStatus: string;
  recommendationDate: string;
  rank: number;
  rankFactor: number;
  primaryNamedInsuredPartyId: string;
  createdBy: string;
  createdDate: string;
  createdBySystemName: string;
  updatedBy: string;
  updatedDate: string;
  updatedBySystemName: string;
  links: Link[];
  products: OpportunityProduct[];
  feedbacks: FeedBack[];
  interpretedCode: string;
}

export interface Opportunity {
  recommendationId: string;
  productType: string;
  subProductType: string;
  revisedProductType: string;
  content: OpportunityContent | null;
}

export interface OpportunitiesResponse {
  policyOpportunities: ResponseOpportunityModel[];
  offerKeys: string [];
  status: ApiStatus;
}

export interface Recommendation{
  policyOpportunities: Opportunity[];
  offerKeys: string [];
}


export interface OpportunitiesFailureResponse {
  policyOpportunities?: Array<any>;
  status: ApiStatus;
}

export enum OpportunityOptimizelyEvent {
  LEARN_MORE = 'learn_more',
  NOTIFY_AGENT = 'im_interested',
  DISMISS = 'dismiss_event'
}

export interface ABExperience {
  text: string;
  isEnabled: boolean;
}

export interface StampsApiResponse {
  bindActive: boolean;
  quoteActive: boolean;
  status: ApiStatus;
  url: string;
}

export const DSP_DOMAIN = 'https://getquote-bundle';

import { map, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { ClaimsFaqResponse } from '../models/claim-faq.model';
import { ClaimFaqActionTypes, fromClaimFaqActions } from './claim-faq.actions';
import { ClaimFaqService } from '../services/claim-faq.service';

@Injectable()
export class ClaimFaqEffects {
  
  claimsFaqLoad$ = createEffect(() => this.action$.pipe(
    ofType(ClaimFaqActionTypes.LoadClaimFaq),
    switchMap(() =>
      this.claimsFaqService.getClaimsFaq().pipe(
        map((res: ClaimsFaqResponse) => new fromClaimFaqActions.LoadClaimFaqSuccess(res)),
        catchError(error => of(new fromClaimFaqActions.LoadClaimFaqFail(error)))
      )
    )
  ));

  constructor(private action$: Actions, private claimsFaqService: ClaimFaqService) {}
}

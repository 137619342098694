import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { BannerConfig } from './banner-config.model';

@Component({
  selector: 'amfam-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBannerComponent {
  @Input() bannerConfig: BannerConfig;
  @Input() phaseTwoEnabled?: boolean;
  @Input() isAdminApp = false;
  @Input() isDuplicateEmail?: boolean;
  @Input() showIcon?: boolean;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter();
  @Output() cancelClick: EventEmitter<void> = new EventEmitter();
}

import { PolicyStatus } from '@amfam/policy/models';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PolicyDocumentService } from '../../services/policy-documents.service';
import { PolicyService } from '../../services/policy.service';
import { DocumentsActionTypes } from '../documents/documents.actions';
import {
  LoadSummaries,
  SummariesAction,
  SummariesActionTypes,
  fromSummariesActions
} from './summaries.actions';
import { getAllPolicySummaries, getOperatorPolicies } from './summaries.selectors';
import { forkJoin, noop } from 'rxjs';

@Injectable()
export class SummariesEffects {
  // TODO: AS - This pattern with datapersistence is not working and i do not have time currently to fix it
  // @Effect() loadSummaries$ = this.dataPersistence.fetch(SummariesActionTypes.LoadSummaries, {
  //   run: (action: LoadSummaries, state: PolicySummariesPartialState) => {
  //     return from(action.payload).pipe(
  //       switchMap(user => this.policyService.getPolicySummaries(_get(user, 'customerId'))),
  //       map(policies => {
  //         const policySummaries = policies.filter(
  //           policy =>
  //             this.policyService.isPolicyTypeSupported(policy.generalizedProductType) &&
  //             (policy.policyStatus === PolicyStatus.Inforce ||
  //               policy.policyStatus === PolicyStatus.Scheduled)
  //         );
  //         return new fromSummariesActions.SummariesLoadSuccess(policySummaries);
  //       })
  //     );
  //   },

  //   onError: (action: SummariesActionTypes.SummariesLoadError, error) => {
  //     // action perform for 404 status code when there are no policies.
  //     if (_get(error, 'status.code') === 404) {
  //       return new fromSummariesActions.SummariesStatusNotFoundAction(error);
  //     } else {
  //       return new fromSummariesActions.SummariesLoadError(error);
  //     }
  //   }
  // });

  loadSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SummariesActionTypes.LoadSummaries),
      map((action: LoadSummaries) => action.payload),
      withLatestFrom(
        this.store.select(userQuery.getCustomerId),
        this.store.select(BrandSelectors.getIsPartner)
      ),
      switchMap(([user, customerId, isPartner]) =>
        this.policyService.getPolicySummaries(_get(user, 'customerId', customerId), isPartner).pipe(
          map(policies => {
            const policySummaries = policies.filter(
              policy =>
                this.policyService.isPolicyTypeSupported(policy.generalizedProductType) &&
                (policy.policyStatus === PolicyStatus.Inforce ||
                  policy.policyStatus === PolicyStatus.Scheduled)
            );
            return new fromSummariesActions.SummariesLoadSuccess(policySummaries);
          }),
          catchError(error => {
            const returnErrArray: SummariesAction[] = [];
            // action perform for 404 status code when there are no policies.
            if (_get(error, 'status.code') === 404) {
              returnErrArray.push(new fromSummariesActions.SummariesStatusNotFoundAction(error));
            } else {
              returnErrArray.push(new fromSummariesActions.SummariesLoadError(error));
            }

            return returnErrArray;
          })
        )
      )
    )
  );

  updateOpertorPoiLinks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentsActionTypes.LoadDocumentsComplete),
        concatLatestFrom(() => this.store.select(getOperatorPolicies)),
        switchMap(([action, policies]) =>
          forkJoin(policies.map(policy => this.policyDocumentService.getPOILinks(policy)))
        ),
        map(policies => policies?.length > 0 ? new fromSummariesActions.UpdatePoiLink(policies) : new noop())
      ),
  );

  constructor(
    private actions$: Actions,
    private policyService: PolicyService,
    private store: Store,
    private policyDocumentService: PolicyDocumentService
  ) {}
}

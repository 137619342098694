import { CoverageTerm } from './coverage-term';

// @dynamic
export class Coverage {
  public name: string;
  public description: string;
  public coverageType: string;
  public coverageLevel: string;
  public code: string;
  public rejectedCoverage: boolean;
  public showRejected?: boolean;
  public appliesToMultipleRisks: boolean;
  public deductibleTerms: CoverageTerm[] = new Array<CoverageTerm>();
  public limitTerms: CoverageTerm[] = new Array<CoverageTerm>();
  public otherTerms: CoverageTerm[] = new Array<CoverageTerm>();

  static fromJson(json: any): Coverage {
    const coverage = new Coverage();
    coverage.name = json.name;
    coverage.description = json.description;
    coverage.coverageType = json.coverageType;
    coverage.code = json.code;
    coverage.rejectedCoverage = json.rejected;
    coverage.showRejected = false;
    coverage.coverageLevel = '';
    coverage.appliesToMultipleRisks = json.appliesToMultipleRisks === 'true';
    for (const deductibleTerm of json.deductibleTerms) {
      coverage.deductibleTerms.push(CoverageTerm.fromJson(deductibleTerm));
    }
    for (const limitTerm of json.limitTerms) {
      if (limitTerm.packageTerms) {
        for (const packageTerm of limitTerm.packageTerms) {
          coverage.limitTerms.push(CoverageTerm.fromJson(packageTerm));
        }
      } else {
        coverage.limitTerms.push(CoverageTerm.fromJson(limitTerm));
      }
    }
    for (const otherTerm of json.otherTerms) {
      coverage.otherTerms.push(CoverageTerm.fromJson(otherTerm));
    }

    return coverage;
  }

  public static compare(a: Coverage, b: Coverage): number {
    if (a.description < b.description) {
      return -1;
    }

    if (a.description > b.description) {
      return 1;
    }

    return 0;
  }

  public static isValid(coverage: Coverage): boolean {
    return !coverage.deductibleTerms
      .concat(coverage.limitTerms)
      .some(term => term.value === 'Rejected' || term.valueDescription === 'No Coverage');
  }
}

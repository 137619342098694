import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';

import { profileQuery, ProfileActions } from '@amfam/profile/data-access';

import { get as _get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { userQuery } from '@amfam/shared/user';

@Component({
  selector: 'edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit, OnDestroy {
  username: string;
  private customerId: string;
  showForm = false;
  requestInProgress = false;
  private stop$: Subject<void> = new Subject<void>();

  constructor(private store: Store<any>) {}

  ngOnInit() {
    combineLatest(
      this.store.select(userQuery.getCustomerIdAndLoginName),
      this.store.select(profileQuery.getProfileSecurityInfoStatus),
      (customerIdAndLoginName, profileSecurityInfoStatus) => {
        return {
          customerIdAndLoginName: customerIdAndLoginName,
          profileSecurityInfoStatus: profileSecurityInfoStatus
        };
      }
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.customerId = _get(state, 'customerIdAndLoginName.customerId', '');
        this.username = _get(state, 'customerIdAndLoginName.loginName', '');
        if (_get(state, 'profileSecurityInfoStatus.editSecurityInfoMethodType') !== 'password') {
          return;
        }
        if (
          !_get(state, 'profileSecurityInfoStatus.loading') &&
          !_get(state, 'profileSecurityInfoStatus.editSecurityInfoError')
        ) {
          this.showForm = false;
        }
      });
  }

  openEditPasswordForm() {
    this.showForm = !this.showForm;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  onComplete(password: string) {
    // start the spinner
    this.requestInProgress = true;
    this.store.dispatch(new ProfileActions.MakeRequestAction());

    // dispatch the request to change password
    const data = {
      password: password,
      customerId: this.customerId
    };
    this.store.dispatch(
      new ProfileActions.ChangeSecurityInfoAction({
        data,
        methodType: 'password'
      })
    );
  }
}

<layout-sidebar *ngIf="(opportunityContent$ | async) && !showNewTemplate; else newCard">
  <div class="main">
    <div class="faq">
      <div class="row">
        <div class="column twelve">
          <h2 class="heading-main">
            {{ (opportunityContent$ | async)?.detailComponentHeader }}
          </h2>
          <p [innerHTML]="(opportunityContent$ | async)?.detailComponentIntro"></p>
          <div *ngIf="(opportunityContent$ | async)?.detailComponentIntro2">
            <p [innerHTML]="(opportunityContent$ | async)?.detailComponentIntro2"></p>
          </div>
          <div
            *ngIf="(opportunityContent$ | async)?.opportunityProductCode === 'property'"
            class="column twelve"
          >
            <div class="column twelve">
              <h2 class="heading-sub prop-sub">
                {{ (opportunityContent$ | async).propertyHeaderQuestion }}
              </h2>
            </div>
            <div
              *ngFor="let icon of propertyIcons; let i = index"
              class="icon-div column two"
              [ngClass]="{ active: icon.active }"
            >
              <div (click)="switchPropertyTile(i)">
                <i [ngClass]="icon.icon"></i>
                <p class="icon-name">{{ icon.name }}</p>
              </div>
            </div>
          </div>
          <ds-accordion
            *ngIf="(opportunityContent$ | async)?.opportunityProductCode !== 'property'"
            [allowMultipleOpenCategories]="true"
            [allowToggleAccordion]="true"
          >
            <ng-container *ngFor="let item of (opportunityContent$ | async)?.detailComponentFAQ">
              <ds-accordion-category [title]="item.title">
                <p [innerHTML]="item.body"></p>
              </ds-accordion-category>
            </ng-container>
          </ds-accordion>
          <ds-accordion
            *ngIf="(opportunityContent$ | async)?.opportunityProductCode === 'property'"
            [allowMultipleOpenCategories]="true"
            [allowToggleAccordion]="true"
            [index]="propItemIndex"
          >
            <ng-container
              *ngFor="
                let item of ((opportunityContent$ | async)?.detailComponentFAQ)[propItemIndex]
              "
            >
              <ds-accordion-category [title]="item.title">
                <p [innerHTML]="item.body"></p>
              </ds-accordion-category>
            </ng-container>
          </ds-accordion>
          <ng-container *ngTemplateOutlet="cta"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <aside class="main-aside">
    <links-agents-sidebar
      [isQuickLinkDisable]="
        isContactMeDisabled(notificationState$ | async, recommendationId$ | async, 'links')
      "
      [quickLinksList]="quickLinksList"
      *ngIf="(authorized$ | async) && !!(recommendationId$ | async)"
    >
    </links-agents-sidebar>
  </aside>
</layout-sidebar>

<ng-template #newCard>
  <div class="row margin-top-10 new-card">
    <div class="column twelve">
      <p [innerHTML]="(opportunityContent$ | async)?.heroCard"></p>
    </div>
    <div class="column twelve md-pad-horizontal-40">
      <div class="column twelve pad-10">
        <h3 class="pad-bottom-10 font-weight-bold">{{ (opportunityContent$ | async)?.detailComponentHeader }}</h3>
        <h5 class="pad-bottom-10 " [innerHTML]="(opportunityContent$ | async)?.detailComponentIntro">{{ (opportunityContent$ | async)?.detailComponentIntro }}</h5>
      </div>
      <div
        class="column twelve md-four pad-10"
        *ngFor="let item of (opportunityContent$ | async)?.detailComponentFAQ"
      >
        <ds-flip-card>
          <h3 class="center font-weight-bold" front >{{item.title}}</h3>
          <div back>
            <h3 class="center font-weight-bold">{{item.title}}</h3>
            <p class="overflow-auto" [innerHTML]="item.body"></p>
          </div>
         
        </ds-flip-card>
      </div>
      <div class="column twelve md-eight pad-10 justify-center items-center cta-section">
        <ng-container *ngTemplateOutlet="cta"></ng-container>
      </div>
    </div>
  </div>
  <div class="row md-pad-horizontal-40 caption">
    <p class="column pad-20" [innerHTML]="(opportunityContent$ | async)?.disclaimer"></p>
  </div>
</ng-template>

<ng-template #cta>
  <div class="margin-top-20 opportunities-cta-background">
    <ds-opportunities-cta
      [showCTA]="!((notificationSuccess$ | async) || !(recommendationId$ | async))"
      [ctaData]="opportunitiesCTAData | async"
      [showContactMe]="
        notificationSuccess$ && notificationSuccess$?.customerFeedbackCode === 'CUSTOMER_DISMISSED'
      "
      (ctaClickEvent)="contactAgent($event)"
    ></ds-opportunities-cta>

    <call-to-action 
    class="pad-bottom-10"
    *ngIf="!((notificationSuccess$ | async) || !(recommendationId$ | async))"
    [primaryButtonName]="primaryButton" 
    [secondaryButtonName]="secondaryButton"
    [tertiaryButtonName]="tertiaryButton"    
    [capitalize]="true"
    [equalWidthButtons]="true"
    (primaryEvent)="contactAgent(primaryButton)"
    (secondaryEvent)="contactAgent(secondaryButton)"
    (tertiaryEvent)="contactAgent(tertiaryButton)"
    
    ></call-to-action>

    <div *ngIf="!(pending$ | async)" class="margin-top-20">
      <ng-container *ngIf="(notificationStatus$ | async) as feedback">
      <div *ngIf="feedback?.error" >
        <span class="error-600-text icon-alert margin-right-10"></span>
        <ng-container *ngIf="feedback.customerFeedbackCode === 'CUSTOMER_DISMISSED' else error">
          {{ (opportunityContent$ | async)?.generalFailureMessage || (opportunityContent$ | async)?.failureNotification }}
        </ng-container>
        <ng-template #error>
        {{  (opportunityContent$ | async)?.failureNotification }}
        </ng-template>
      </div>
      </ng-container>
      <div
        *ngIf="
          (notificationSuccess$ | async) &&
          (notificationSuccess$ | async)?.customerFeedbackCode !== 'CUSTOMER_DISMISSED'
        "
      >
        <span
          data-cy="successNotification"
          class="success-400-text icon-complete-circle margin-right-10"
        ></span
        >{{ (opportunityContent$ | async)?.successNotification }}
      </div>
      <div
        *ngIf="
          (notificationSuccess$ | async) &&
          (notificationSuccess$ | async)?.customerFeedbackCode === 'CUSTOMER_DISMISSED'
        "
      >
        <span class="success-400-text icon-complete-circle margin-right-10"></span
        >{{ (opportunityContent$ | async)?.nothanksSuccessMessage }}
      </div>
    </div>
  </div>
</ng-template>

<ds-modal
  [id]="agentSelectionModal"
  [closeOthers]="true"
  [maxWidth]="modalWidth"
  (onCloseModalEvent)="closeModal()"
>
  <p>{{ (opportunityContent$ | async)?.agentSelectHeader }}</p>
  <div>
    <form [formGroup]="agentNotificationForm" *ngIf="!(pending$ | async)">
      <ds-form-control-container
        [labelText]="'Select which agent'"
        [control]="agentNotificationForm.get('selectAgent')"
      >
        <select id="selectAgent" class="icon fill" formControlName="selectAgent">
          <option value=""></option>
          <option *ngFor="let agent of agents$ | async" [value]="agent.agentId">
            {{ agent.fullName }}
          </option>
        </select>
        <span class="icon-chevron-down"></span>
        <ds-form-control-message
          [control]="agentNotificationForm.get('selectAgent')"
        ></ds-form-control-message>
      </ds-form-control-container>
    </form>
  </div>
  <div class="pad-top-10">
    <button
      ds-button
      content
      (click)="confirmAgentSelection()"
      class="fill"
      type="submit"
      [disabled]="!agentNotificationForm.valid || !(authorized$ | async)"
      [contentPath]="'shared.notifyAgent'"
    ></button>
  </div>
</ds-modal>

import { CreditCardExpirationStatusType, PaymentMethod } from '@amfam/billing/payment-method/util';
import { createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';
import * as FinancialAccountSelectors from '../financial-account/financial-account.selectors';
import { getState, State } from '../index';
import * as fromPaymentMethod from './payment-method.reducer';

export const getPaymentMethodState = createSelector(
  getState,
  (state: State) => state.paymentMethodState
);

export const getPaymentMethodEntities = createSelector(
  getPaymentMethodState,
  (state: fromPaymentMethod.State) => state.entities
);

export const getLoading = createSelector(
  getPaymentMethodState,
  (state: fromPaymentMethod.State) => state.loading
);

export const hasError = createSelector(
  getPaymentMethodState,
  (state: fromPaymentMethod.State) => state.hasError
);

export const getCorrelationId = createSelector(
  getPaymentMethodState,
  (state: fromPaymentMethod.State) => state.correlationId
);

export const getPaymentMethods = createSelector(
  getPaymentMethodState,
  (state: fromPaymentMethod.State) => {
    const paymentMethods = fromPaymentMethod.selectAllPaymentMethods(state);
    paymentMethods.sort((a, b) => new Intl.Collator().compare(a.nickName, b.nickName));
    return paymentMethods;
  }
);

export const getPaymentMethod = (paymentAccountId: string) =>
  createSelector(getPaymentMethodEntities, entities => {
    return entities[paymentAccountId];
  });

export const getMatchedPaymentAccount = createSelector(
  getPaymentMethodEntities,
  (paymentAccounts, paymentId: string) => {
    return paymentAccounts[paymentId];
  }
);

export const getPaymentMethodsSortedByExpirationStatus = createSelector(
  getPaymentMethods,
  paymentMethods => {
    return paymentMethods.sort((a: PaymentMethod, b: PaymentMethod) => {
      // Assign a weight of 0 for expired payment accounts, 1 for expiring payment accounts, 2 for not expired/expiring payment accounts
      const aExpired: boolean =
        _get(a, 'creditCard.expirationStatus', null) === CreditCardExpirationStatusType.EXPIRED;
      const aExpiringSoon: boolean =
        _get(a, 'creditCard.expirationStatus', null) ===
        CreditCardExpirationStatusType.EXPIRING_SOON;
      const bExpired: boolean =
        _get(b, 'creditCard.expirationStatus', null) === CreditCardExpirationStatusType.EXPIRED;
      const bExpiringSoon: boolean =
        _get(b, 'creditCard.expirationStatus', null) ===
        CreditCardExpirationStatusType.EXPIRING_SOON;
      const aValue = aExpired ? 0 : aExpiringSoon ? 1 : 2;
      const bValue = bExpired ? 0 : bExpiringSoon ? 1 : 2;

      if (aValue > bValue) {
        return 1;
      } else if (aValue < bValue) {
        return -1;
      }
      return 0;
    });
  }
);

export const hasAnyError = createSelector(
  FinancialAccountSelectors.hasPCITokenError,
  FinancialAccountSelectors.hasFinancialInstitutionError,
  hasError,
  (pciError, financialInstitutionError, paymentMethodError) =>
    pciError || financialInstitutionError || paymentMethodError
);

export const getNewPaymentAccountId = createSelector(
  getPaymentMethodState,
  (state: fromPaymentMethod.State) => state.newPaymentAccountId
);

import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Entities } from '../models/entity/entity.model';
import { PendingPayment } from '../models/pending-payment.models';
import * as fromPendingPayment from './pending-payment.reducer';

export const PENDING_PAYMENT_FEATURE_KEY = 'pendingPayment';

const getPendingPaymentState = createFeatureSelector<Entities<PendingPayment>>(
  PENDING_PAYMENT_FEATURE_KEY
);

export const getPendingPaymentIds = createSelector(
  getPendingPaymentState,
  fromPendingPayment.getIds
);
export const getPendingPaymentEntities = createSelector(
  getPendingPaymentState,
  fromPendingPayment.getEntities
);
export const getPendingPayments = createSelector(
  getPendingPaymentEntities,
  getPendingPaymentIds,
  (entities, ids) => ids.map(id => entities[id])
);
export const getPendingPaymentsLoading = createSelector(
  getPendingPaymentState,
  fromPendingPayment.getLoading
);

export const getBillAccountsEligibleForOneTimePay = createSelector(
  BillAccountsSelectors.getModifiableBillAccounts,
  billAccounts => billAccounts.filter(billAccount => Number(billAccount.accountBalance) > 0)
);

export const getBillAccountsWithPastDue = createSelector(
  getBillAccountsEligibleForOneTimePay,
  billAccounts => billAccounts.filter(billAccount => billAccount.pastDue)
);

export const pendingPaymentQuery = {
  getPendingPaymentState,
  getPendingPaymentIds,
  getPendingPaymentEntities,
  getPendingPaymentsLoading,
  getPendingPayments,
  getBillAccountsEligibleForOneTimePay,
  getBillAccountsWithPastDue
};

<div>
  <p class="pad-top-20">Add a new payment method</p>
  <div class="row">
    <a (click)="addCheckingAccount()" [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      >Checking Account</a
    >
  </div>
  <p *ngIf="displayAutoPayDiscountMessage" class="caption margin-bottom-10">AutoPay Discount</p>
  <div class="row">
    <a (click)="addSavingsAccount()" [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      >Savings Account</a
    >
  </div>
  <p *ngIf="displayAutoPayDiscountMessage" class="caption margin-bottom-10">AutoPay Discount</p>
  <div class="row">
    <a (click)="addCreditDebit()" [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      >Debit/Credit Card</a
    >
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { DigitalAccountService, DigitalAccount } from '@amfam/shared/digital-account/data-access';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditBusinessService {
  constructor(private digitalAccountService: DigitalAccountService, private store: Store) {}

  updateDigitalAccount(digitalAccount: DigitalAccount): Observable<any> {
    let expId = null,
      partnerId = null,
      waId = null;
    combineLatest(
      this.store.select(userQuery.getWaid),
      this.store.select(BrandSelectors.getPartnerId),
      this.store.select(BrandSelectors.getExperienceId),
      (waID, partnerID, expID) => {
        return {
          waid: waID,
          partnerId: partnerID,
          expId: expID
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        expId = state.expId;
        partnerId = state.partnerId;
        waId = state.waid;
      });
    return this.digitalAccountService.updateDigitalAccount(digitalAccount, waId, partnerId, expId);
  }
}

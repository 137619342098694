import {
  AddEditPaymentMethodPayload,
  FinancialAccountActions,
  FinancialAccountSelectors,
  FinancialInstitution,
  PaymentMethodActions,
  PaymentMethodFacade,
  PaymentMethodSelectors,
  SavePaymentMethodPayload
} from '@amfam/billing/payment-method/data-access';
import {
  PaymentMethodAccountType,
  PaymentMethodAccountUseType,
  PaymentMethodAddEditConfig,
  PaymentMethodAddEditOperationType,
  PaymentMethodFinancialType,
  PaymentMethodModeOfAuthorizationType,
  PaymentMethodUtilService
} from '@amfam/billing/payment-method/util';
import { AnalyticsFacade, AutomaticPaymentsAnalytics } from '@amfam/shared/analytics';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import {
  ApplicationService,
  Applications,
  ConfigService,
  CopyService,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { DsModalService, LoadingSpinnerService, ValidationService } from '@amfam/ui-kit';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ds-bank-account-add-edit-wrapper',
  templateUrl: './bank-account-add-edit-wrapper.component.html',
  styleUrls: ['./bank-account-add-edit-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankAccountAddEditWrapperComponent implements OnDestroy, OnChanges {
  @Input() paymentMethodAddEditConfig: PaymentMethodAddEditConfig;
  @Input() showTryAgainButton: boolean = false;
  @Output() paymentMethodAddEditCompleteEvent: EventEmitter<string> = new EventEmitter<string>();

  heading: string;
  isEditFlow: boolean;
  accountForm: UntypedFormGroup;
  isBusiness$: Observable<boolean>;
  financialInstitution$: Observable<FinancialInstitution>;
  paymentMethodError: Observable<boolean>;
  authorizedToSubmit$: Observable<boolean>;
  primaryButtonName: string;
  tertiaryButtonName: string;
  failureMessage: string;
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private paymentMethodFacade: PaymentMethodFacade,
    private store: Store,
    private config: ConfigService,
    private paymentMethodUtil: PaymentMethodUtilService,
    private utilService: UtilService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade,
    private roleService: ImpersonateRolesService,
    private appService: ApplicationService,
    private modalService: DsModalService,
    private copyService: CopyService
  ) {}

  // need OnChanges to reset the form because closing
  // the modal does not destroy the dom element
  ngOnChanges() {
    // heading
    this.heading = this.appService.isApp(Applications.MYACCOUNT_ADMIN)
      ? this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? 'Edit bank account info'
        : 'Enter their bank account info'
      : this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
      ? 'Edit bank account info'
      : 'Enter your bank account info';

    this.isEditFlow =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? true
        : false;

    this.failureMessage = this.copyService.getCopy(
      `billing.paymentMethodStandAlone.${this.paymentMethodAddEditConfig.operation}FailureMessage`
    );

    // Default CTA buttons
    this.primaryButtonName = 'Save';
    this.tertiaryButtonName = null;
    // error handling
    this.store.dispatch(PaymentMethodActions.resetErrorState());
    this.store.dispatch(FinancialAccountActions.resetErrorState());

    // create form
    this.accountForm = this.formBuilder.group({
      routingNumber: [
        '',
        Validators.compose([
          Validators.required,
          ValidationService.routingNumberValidator,
          Validators.minLength(9)
        ])
      ],
      accountNumber: [
        '',
        Validators.compose([
          Validators.required,
          ValidationService.bankAccountNumberValidator,
          Validators.minLength(4),
          Validators.maxLength(17)
        ])
      ],
      businessAccount: [false, Validators.compose([Validators.required])],
      nickName: [
        _get(this.paymentMethodAddEditConfig, 'paymentMethod.nickName', ''),
        Validators.compose([
          Validators.required,
          ValidationService.nickNameNumberLengthValidator,
          ValidationService.nickNameValidator
        ])
      ]
    });

    // add duplicate nickname validator
    this.store
      .select(PaymentMethodSelectors.getPaymentMethods)
      .pipe(take(1))
      .subscribe(paymentMethods => {
        // filter out current nickname if editing
        const existingNicknames = paymentMethods
          .map(paymentMethod => paymentMethod.nickName)
          .filter(
            nickname =>
              nickname !== _get(this.paymentMethodAddEditConfig, 'paymentMethod.nickName', '')
          );
        this.accountForm.setValidators(
          ValidationService.nickNameDuplicationValidator('nickName', existingNicknames)
        );
      });

    // changes check image
    this.isBusiness$ = this.accountForm.get('businessAccount').valueChanges;

    // get financial institution for this routing number
    this.financialInstitution$ = this.paymentMethodFacade.financialInstitution$.pipe(
      map((financialInstitution: FinancialInstitution) =>
        _get(financialInstitution, 'routingNumber', '') ===
        this.accountForm.get('routingNumber').value
          ? financialInstitution
          : null
      )
    );

    // gets financial institution
    this.accountForm
      .get('routingNumber')
      .valueChanges.pipe(
        filter(() => this.accountForm.get('routingNumber').valid),
        takeUntil(this.stop$)
      )
      .subscribe(() => {
        this.paymentMethodFacade.getFinancialInstitution(
          this.accountForm.get('routingNumber').value
        );
      });

    // registers routing number error
    this.store
      .select(FinancialAccountSelectors.hasFinancialInstitutionError)
      .pipe(takeUntil(this.stop$))
      .subscribe(error => {
        this.spinner.stop();
        if (error) {
          this.accountForm.get('routingNumber').setErrors({ invalidRoutingNumber: true });
        } else {
          this.accountForm.get('routingNumber').setErrors(null);
        }
      });

    this.authorizedToSubmit$ =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.roleService.isAuthorized('editpaymentacct_submit_disable')
        : this.roleService.isAuthorized('makepaymentacct_submit_disable');
  }

  savePaymentMethod() {
    this.paymentMethodError = this.store.select(PaymentMethodSelectors.hasAnyError);

    // analytics for payment method stand alone experience- showTryAgainButton var is true only for stand alone flow
    if (this.showTryAgainButton) {
      let buttonAnalyticName;
      if (this.primaryButtonName === 'Save') {
        // analytics for save button click
        buttonAnalyticName =
          this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.ADD
            ? 'AddPaymentMethod:SetupSavingsChecking:Save'
            : 'EditPaymentMethod:EditCheckingSavings:Save';
      } else {
        // analytics for try again button click
        buttonAnalyticName =
          this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.ADD
            ? 'AddPaymentMethod:SetupSavingsChecking:Error:TryAgain'
            : 'EditPaymentMethod:SetupSavingsChecking:Error:TryAgain';
      }
      this.paymentMethodFacade.sendPaymentMethodAnalytics({ buttonAnalyticName });
    } else {
      if (this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT)
        this.analyticsFacade.trackButtonClick(
          AutomaticPaymentsAnalytics.buttonMultipleAutomaticPaymentsPaymentMethodEdited
        );

      if (this.paymentMethodAddEditConfig.accountType === PaymentMethodAccountType.CHECKING) {
        this.analyticsFacade.trackButtonClick(
          AutomaticPaymentsAnalytics.buttonMultipleAutomaticPaymentsNewPaymentMethodChecking
        );
      }

      if (this.paymentMethodAddEditConfig.accountType === PaymentMethodAccountType.SAVINGS) {
        this.analyticsFacade.trackButtonClick(
          AutomaticPaymentsAnalytics.buttonMultipleAutomaticPaymentsNewPaymentMethodSaving
        );
      }

      if (this.paymentMethodAddEditConfig.accountType === PaymentMethodAccountType.CREDIT_DEBIT) {
        this.analyticsFacade.trackButtonClick(
          AutomaticPaymentsAnalytics.buttonMultipleAutomaticPaymentsNewPaymentMethodDebitCredit
        );
      }
    }

    // Get payload values
    const accountUse: PaymentMethodAccountUseType = this.accountForm.get('businessAccount').value
      ? PaymentMethodAccountUseType.BUSINESS
      : PaymentMethodAccountUseType.PERSONAL;
    const modeOfAuthorization: PaymentMethodModeOfAuthorizationType =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.paymentMethodAddEditConfig.paymentMethod.modeOfAuthorization
        : this.paymentMethodUtil.getPaymentMethodModeOfAuthorization(
            this.paymentMethodAddEditConfig.accountType
          );
    const lastUpdateTimestamp: string =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.paymentMethodAddEditConfig.paymentMethod.lastUpdateTimestamp
        : '';
    const paymentAccountId: string =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.paymentMethodAddEditConfig.paymentMethod.paymentAccountId
        : '';
    const oldNickName: string =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.EDIT
        ? this.paymentMethodAddEditConfig.paymentMethod.nickName
        : null;

    // create payload for save account (finAccount API)
    const savePayload: SavePaymentMethodPayload = {
      financialType: PaymentMethodFinancialType.BANK,
      nickName: this.accountForm.get('nickName').value,
      consumerKey: this.config.get('finAcctConsumerKey'),
      bankAccount: {
        routingNumber: this.accountForm.get('routingNumber').value,
        accountNumber: this.accountForm.get('accountNumber').value,
        accountType: this.paymentMethodAddEditConfig.accountType,
        accountUse
      }
    };

    // create payload for add/edit account (customerpayment API)
    const addEditPayload: AddEditPaymentMethodPayload = {
      lastUpdateTimestamp,
      paymentAccount: {
        nickName: this.accountForm.get('nickName').value,
        oldNickName,
        modeOfAuthorization,
        token: '', // gets set in effect - comes back from finAccount call
        consumerKey: this.config.get('finAcctConsumerKey')
      }
    };

    // correlationId for tracking when request is complete
    const correlationId: string = this.utilService.generateId();

    // start spinner
    this.spinner.start();

    // start request
    this.paymentMethodFacade.savePaymentMethod(
      this.paymentMethodAddEditConfig.operation,
      savePayload,
      addEditPayload,
      paymentAccountId,
      correlationId
    );

    // on request completion
    this.store
      .select(PaymentMethodSelectors.getPaymentMethodState)
      .pipe(
        filter(state => state.correlationId === correlationId),
        take(1)
      )
      .subscribe(state => {
        this.spinner.stop();
        if (!state.hasError) {
          // emit event to parent with new paymentAccountId
          this.paymentMethodAddEditCompleteEvent.emit(state.newPaymentAccountId);
        } else {
          if (this.showTryAgainButton) {
            // analytics for payment method stand alone experience
            let pageAnalyticName =
              this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.ADD
                ? 'AddPaymentMethod:SetupSavingsChecking:Error'
                : 'EditPaymentMethod:SetupSavingsChecking:Error';
            this.paymentMethodFacade.sendPaymentMethodAnalytics({ pageAnalyticName });
            this.primaryButtonName = 'Try Again';
            this.tertiaryButtonName = 'Done';
          }
        }
      });
  }

  done() {
    this.modalService.closeAll();
    let buttonAnalyticName =
      this.paymentMethodAddEditConfig.operation === PaymentMethodAddEditOperationType.ADD
        ? 'AddPaymentMethod:SetupSavingsChecking:Error:Done'
        : 'EditPaymentMethod:SetupSavingsChecking:Error:Done';
    this.paymentMethodFacade.sendPaymentMethodAnalytics({ buttonAnalyticName });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html'
})
export class BannerComponent {
  @Input()
  messageType = 'message';

  @Output()
  closeBanner = new EventEmitter()
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BillingEffects } from './+state/billaccount.effects';
import * as fromBilling from './+state/billaccount.reducer';
import { billingReducers } from './+state/billaccount.selectors';
import { BillingUtilService } from './services/billing-util.service';
import { BillAccountsService } from './services/bill-accounts.service';
@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([BillingEffects]),
    StoreModule.forFeature(fromBilling.BILLING_FEATURE_KEY, billingReducers)
  ],
  providers: [BillAccountsService, BillingUtilService]
})
export class BillingBillAccountDataAccessModule {}

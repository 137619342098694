import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';

import { FnolRiskModel, PolicyTypeIconConstants, RiskModel } from '@amfam/policy/models';

import { DriverDefaults } from '@amfam/claim/dynamic-fnol/data-access';

@Component({
  selector: 'ds-risk-list',
  templateUrl: './risk-list.component.html',
  styleUrls: ['./risk-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskListComponent implements OnInit {
  @Input() risks: Array<RiskModel | FnolRiskModel> = new Array<RiskModel | FnolRiskModel>();
  @Input() preSelectedRisk: RiskModel | FnolRiskModel;
  @Input() otherLabel = '';
  @Input() otherContent = '';
  @Input() showPolicyNumber = true;
  @Input() vehicleLabel;
  @Input() showIcon = true;
  @Output() onRiskChange = new EventEmitter();

  currentRisk: RiskModel | FnolRiskModel;
  currentRiskId = '';
  riskSelectionForm: UntypedFormGroup;
  riskSelectionCtrl: AbstractControl;
  driverDefaults = DriverDefaults;
  riskIcons = PolicyTypeIconConstants;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.riskSelectionForm = this.formBuilder.group({
      riskSelection: ['', Validators.compose([Validators.required])]
    });
    this.riskSelectionCtrl = this.riskSelectionForm.controls['riskSelection'];
    /**
     * If there is single risk, preselect it.
     */
    if (this.risks.length === 1) {
      this.toggleRisk(this.risks[0].id);
    }

    /**
     * If a risk needs to be preselected.
     */
    if (this.preSelectedRisk) {
      this.toggleRisk(this.preSelectedRisk.id);
    }
  }

  toggleRisk(riskId: string) {
    this.riskSelectionCtrl.setValue(riskId);
    this.currentRisk = this.risks.find(risk => risk.id === riskId);
    if (this.currentRisk && this.currentRisk.id) {
      this.currentRiskId = this.currentRisk.id;
    } else {
      this.currentRiskId = riskId;
    }
    this.onRiskChange.emit(riskId);
  }
}

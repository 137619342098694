import { digitalAccountQuery } from '@amfam/shared/digital-account/data-access';
import { Party } from '@amfam/shared/models';
import { fromUserActions, userQuery } from '@amfam/shared/user';
import {
  Applications,
  ApplicationService,
  ConfigService
} from '@amfam/shared/utility/shared-services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get, has as _has } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class PartyService {
  private customerId: string;
  private endpoint: string;
  private otherEntry: any;
  private partyVersion = '';

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private store: Store<any>,
    private applicationService: ApplicationService
  ) {
    this.store.select(userQuery.getCustomerId).subscribe(customerId => {
      if (customerId) {
        this.customerId = customerId;
        this.endpoint = this.config.get('partyApi') + 'parties/' + customerId;

        this.getPartyDetails().subscribe(
          party => {},
          error => {}
        );
      }
    });

    // Single source of truth to get the party version
    this.store.select(userQuery.getPartyVersion).subscribe(partyVersion => {
      this.partyVersion = partyVersion;
    });
  }

  addContactEntry({ data, methodType }): Observable<any> {
    if (methodType === 'emails') {
      data = Object.assign(
        {},
        {
          emailAddress: data.emailAddress,
          contactMethodUsages: data.contactMethodUsages
        }
      );
    }
    const url = this.endpoint + '/' + methodType;
    const body = {
      authId: this.config.get('processId'),
      party: {
        partyVersion: this.partyVersion,
        sourceSystemInformation: {
          sourceSystemName: 'MYACCOUNT',
          sourceSystemIdentifier: this.customerId
        },
        contactMethodDetail: {
          [methodType]: [data]
        }
      }
    };
    return this.http.post(url, body);
  }

  editContactEntry(entry, methodType, entry2): Observable<any> {
    // if we need to change a second entry as well, we store the first one so we can pass it to the store later
    this.otherEntry = entry2 ? entry : this.otherEntry;
    // extract id via this check because their id property names are different
    const id = methodType === 'phones' ? 'phoneId' : 'emailId';
    const url = `${this.endpoint}/${methodType}/${entry[id]}`;
    const body = {
      authId: this.config.get('processId'),
      party: {
        partyVersion: this.partyVersion,
        sourceSystemInformation: {
          sourceSystemName: 'MYACCOUNT',
          sourceSystemIdentifier: this.customerId
        },
        contactMethodDetail: {
          [methodType]: [entry]
        }
      }
    };
    return this.http.put(url, body).pipe(
      map((data: any) => {
        // update the local copy of the partyVersion
        if (_has(data, 'partyResourceIdentifier.partyVersion')) {
          this.partyVersion = _get(data, 'partyResourceIdentifier.partyVersion');
        }
        // add our first entry to the response so the store can update it
        data.otherEntry = this.otherEntry ? this.otherEntry : undefined;
        // reset value
        this.otherEntry = entry2 ? this.otherEntry : false;
        return data;
      })
    );
  }

  deleteContactEntry({ data, methodType }): Observable<any> {
    const url = `${this.endpoint}/${methodType}/${data}`;
    const options = this.getOptions({
      'AFI-PartyVersion': this.partyVersion,
      'AFI-AuthId': this.config.get('processId'),
      'AFI-SourceSystemName': 'MYACCOUNT',
      'AFI-SourceSystemIdentifier': this.customerId
    });
    return this.http.delete(url, options);
  }

  getPartyDetails(): Observable<Party> {
    const url = this.endpoint + '?authId=' + this.config.get('processId');
    const options = this.getOptions({
      authId: this.config.get('processId'),
      userId: this.config.get('processId')
    });
    return this.http.get(url, options).pipe(
      map((data: any) => {
        let updatedPartyStoreObj: Party;
        if (data.party) {
          updatedPartyStoreObj = Party.fromJson(data.party);
          if (this.applicationService.isApp(Applications.MYACCOUNT_ADMIN)) {
            this.store
              .select(digitalAccountQuery.isShellAccount)
              .pipe(take(1))
              .subscribe(sa => {
                // updating primary indicator
                if (sa) {
                  updatedPartyStoreObj.emails.forEach(email => {
                    email.primaryIndicator = email.emailAddress === sa.emailAddress;
                  });
                }
                // dispatch action to update party bucket in the user object
                this.store.dispatch(new fromUserActions.UpdateParty(updatedPartyStoreObj));
              });
          } else {
            // dispatch action to update party bucket in the user object
            this.store.dispatch(new fromUserActions.UpdateParty(updatedPartyStoreObj));
          }
          return updatedPartyStoreObj;
        }
        // if party isn't in the response something went wrong
        return new Party();
      })
    );
  }

  // Update party boolean was added as a patch to trap bug 723323
  // We will be refactoring kyd-smartphone and we will fix the overall issues at that time
  getOtherPartyDetails(cdhId: string, updateParty: boolean): Observable<Party> {
    const url =
      this.config.get('partyApi') +
      'associatedparties/' +
      cdhId +
      '?authId=' +
      this.config.get('processId');
    const options = this.getOptions({
      authId: this.config.get('processId'),
      userId: this.config.get('processId')
    });
    return this.http.get(url, options).pipe(
      map((data: any) => {
        let updatedPartyStoreObj: Party = <Party>{};
        if (data.party) {
          updatedPartyStoreObj = Party.fromJson(data.party);
          updatedPartyStoreObj.customerIdentifier =
            updatedPartyStoreObj.customerIdentifier || cdhId;

          // dispatch action to update party bucket in the user object
          if (updateParty) {
            this.store.dispatch(new fromUserActions.UpdateParty(updatedPartyStoreObj));
          }
        }
        // if party isn't in the response something went wrong
        return updatedPartyStoreObj;
      })
    );
  }

  private getOptions(headerObj: {}) {
    const headers = new HttpHeaders(headerObj);
    return { headers: headers };
  }
}

import { PolicySelectors } from '@amfam/policy/data-access';
import { InsuredTypes, RiskModel, SourceSystemType } from '@amfam/policy/models';
import { AnalyticsFacade } from '@amfam/shared/analytics/src';
import { DocumentPipeService, StateAbbreviationPipe } from '@amfam/shared/ui/pipes';
import { Agent, agentQuery } from '@amfam/shared/utility/agent';
import { CopyService, WindowRef, openWindowBlob } from '@amfam/shared/utility/shared-services';
import { DockingBarService, LoadingSpinnerService } from '@amfam/ui-kit';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, combineLatest, filter, map, switchMap, take } from 'rxjs';
import {
  POIName,
  arizonaDOTNumber,
  coverageCodeConst,
  setNAIC,
  sourceMapping,
  stateMapping
} from '../../coverage-code-consts';

@Component({
  selector: 'ds-insurance-card',
  templateUrl: './insurance-card.component.html',
  styleUrls: ['./insurance-card.component.scss']
})
export class InsuranceCardComponent implements OnInit {
  isHandsetPortrait$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.HandsetPortrait])
    .pipe(map(obs => obs.matches));

  coverages$: Observable<string>;
  risk$: Observable<RiskModel>;
  vehicleId: string;
  stateDisclaimer$: Observable<string>;
  agents$ = this.store.select(agentQuery.getRealAgents);
  agent$: Observable<Agent>;
  defaultContactNumber: string;
  footer: string;
  dataLoading$: Observable<boolean>;
  disclaimer = this.copyService.getCopy('policy.insuranceCardDislaimer');
  insureds$: Observable<string[]>;
  naicCode$: Observable<string>;
  POIName$: Observable<string>;
  azDOTNumber$: Observable<string>;

  constructor(
    private dockingBarService: DockingBarService,
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private copyService: CopyService,
    private sanitizer: DomSanitizer,
    private documentPipeService: DocumentPipeService,
    private win: WindowRef,
    private deviceService: DeviceDetectorService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade
  ) {
    this.dockingBarService.registerHeading('Insurance Card');

    this.vehicleId = this.activatedRoute.snapshot.paramMap.get('id');

    this.risk$ = this.store
      .select(PolicySelectors.getVehicleRisks)
      .pipe(map(risk => risk.find(val => val.id === this.vehicleId)));

    this.insureds$ = this.risk$.pipe(
      map(risk => {
        const roles = risk.policy.policyRoles;
        const pni = roles.find(
          role => role.roleType.toLowerCase() === InsuredTypes.PRIMARYNAMEDINSURED
        );
        const sni = roles.find(
          role => role.roleType.toLowerCase() === InsuredTypes.SECONDARYNAMEDINSURED
        );
        const names = [];
        if (pni?.name) names.push(pni.name);
        if (sni?.name) names.push(sni.name);
        return names;
      })
    );

    this.agent$ = combineLatest([this.agents$, this.risk$]).pipe(
      filter(([agents, risk]) => agents?.length > 0 && !!risk),
      map(([agents, risk]) => agents.find(agent => agent.id === risk.producerId))
    );

    this.dataLoading$ = combineLatest([
      this.store.select(PolicySelectors.getPoliciesLoading),
      this.risk$,
      this.agent$
    ]).pipe(
      map(([detailLoading, risk, agent]) => detailLoading && risk !== undefined && agent.loading)
    );

    this.coverages$ = this.risk$.pipe(
      map(risk => {
        const policyCoverages = risk.policy.policyCoverages
          .filter(
            coverage =>
              coverageCodeConst[coverage.code] !== undefined && coverage.limitTerms.length > 0
          )
          .map(coverage => coverageCodeConst[coverage.code])
          .join(' ');

        const vehicleCoverages = risk.vehicleCoverages
          .filter(coverage => coverageCodeConst[coverage.code] !== undefined)
          .map(coverage => coverageCodeConst[coverage.code])
          .join(' ');

        return policyCoverages + ' ' + vehicleCoverages;
      })
    );
  }

  ngOnInit(): void {
    this.spinner.start({ blockActions: true });
    this.dataLoading$.subscribe(loading => {
      if (loading !== undefined) {
        this.spinner.stop();
      }
    });

    this.defaultContactNumber = this.copyService.getCopy('shared.contactNumber');
    this.footer = this.copyService.getCopy('policy.footerInfo');
    this.naicCode$ = this.risk$.pipe(map(risk => this.getNAIC(risk.amfamCompanyCode)));
    this.POIName$ = this.risk$.pipe(map(risk => this.getPOIName(risk.address.state)));

    this.stateDisclaimer$ = this.risk$.pipe(
      map(risk => this.getDisclaimer(risk.policy.policyAddress.state, risk.policy.sourceSystem))
    );

    this.azDOTNumber$ = this.risk$.pipe(
      filter(risk => risk.address.state === 'AZ'),
      switchMap(risk => this.getAZDOTNumber(this.naicCode$))
    );
  }

  getPOIName(state) {
    const poiName = POIName[state] || POIName['default'];
    const fullStateName = new StateAbbreviationPipe().transform(state);
    return `${fullStateName} ${poiName}`.toUpperCase();
  }

  getNAIC(companyCode: string) {
    return setNAIC[companyCode];
  }
  getAZDOTNumber(naicCode$: Observable<string>): Observable<string> {
    return naicCode$.pipe(map(code => arizonaDOTNumber[code]));
  }

  getDisclaimer(stateAbbrv: string, sourceSystem: SourceSystemType): string {
    const stateKey = stateMapping[stateAbbrv] || stateMapping['default'];
    const sourceKey = sourceMapping[sourceSystem] || sourceMapping[SourceSystemType.Legacy];
    return this.copyService.getCopy(`${stateKey}.${sourceKey}`);
  }

  public openStatementOnAndroid(link: string) {
    this.spinner.start();
    let windowRef;
    if (!window.navigator) {
      windowRef = this.win.nativeWindow.open();
    }
    this.documentPipeService
      .retrieveDocument(link)
      .pipe(take(1))
      .subscribe(
        res => {
          if (!res) {
            if (windowRef) {
              windowRef.close();
            }
            //  this.analyticsFacade.trackPage(OverviewAnalytics.overviewGetPOIError);
          }
          const url = this.sanitizer.sanitize(SecurityContext.URL, res);
          this.spinner.stop();
          openWindowBlob(this.win, url, res, windowRef, 'document.pdf');
        },
        err => {
          if (windowRef) {
            windowRef.close();
          }
        }
      );
  }
  public openStatement(link: string) {
    if (this.deviceService.device.toLowerCase() === 'android') this.openStatementOnAndroid(link);
    else {
      let windowRef;
      windowRef = this.win.nativeWindow.open();
      this.documentPipeService
        .retrieveDocument(link)
        .pipe(take(1))
        .subscribe(
          res => {
            if (!res) {
              if (windowRef) {
                windowRef.close();
              }
            }
            //this.analyticsFacade.trackPage(OverviewAnalytics.overviewGetPOIError); // CHANGE ANALYTICS
            const url = this.sanitizer.sanitize(SecurityContext.URL, res);
            this.spinner.stop();
            windowRef.location.href = url;
          },
          err => {
            if (windowRef) {
              windowRef.close();
            }
          }
        );
    }
  }
}

<div role="main" id="app-wrapper">
  <div class="main row">
    <div class="column twelve">
      <div class="">
        <h1>My Account is taking a break.</h1>
        <p><strong>But don’t worry, we’ll be back soon!</strong></p>
        <p>
          We know how hard our customers work to achieve their dreams, so we’re doing a bit of
          upkeep to ensure our systems meet all your needs. This won’t take long. Please check back
          in 2 to 4 hours.
        </p>
      </div>
    </div>
  </div>
</div>

<footer
  [chatEnabled]="chatEnabled$ | async"
  [partnerFooterLogo]="partnerFooterLogo$ | async"
></footer>

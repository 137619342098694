import {
  PaymentConfirmationActionTypes,
  PaymentConfirmationActions
} from './paymentConfirmation.actions';
import { ApiStatus } from '@amfam/shared/models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get as _get } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PaymentConfirmationNotificationsState {}

export interface NotificationState extends EntityState<PaymentConfirmationNotificationsState> {
  loading: boolean;
  error: ApiStatus | null;
}

export const adapter: EntityAdapter<PaymentConfirmationNotificationsState> =
  createEntityAdapter<PaymentConfirmationNotificationsState>();

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: PaymentConfirmationActions
): NotificationState {
  switch (action.type) {
    case PaymentConfirmationActionTypes.PaymentConfirmationLoad: {
      return { ...state, loading: true };
    }

    case PaymentConfirmationActionTypes.PaymentConfirmationLoadSuccess: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

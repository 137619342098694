import { ApiStatus, initialApiStatus } from '@amfam/shared/models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { OpportunityActionTypes, OpportunityActionsUnion } from './opportunity.action';

import { get as _get } from 'lodash';
import { CustomerFeedbackEnum } from '../models/feedback';

export interface State {
  recommendationId: string;
  error: ApiStatus | null;
  success: ApiStatus | null;
  loading: boolean;
  isMultiAgent: false;
  type?: string;
  customerFeedbackCode?: string;
}
export interface NotificationState extends EntityState<State> {}

export const adapter: EntityAdapter<State> = createEntityAdapter<State>({
  selectId: (state: State) => state.recommendationId,
  sortComparer: false
});

export const initialState: NotificationState = adapter.getInitialState();

export function reducer(state = initialState, action: OpportunityActionsUnion): NotificationState {
  switch (action.type) {
    case OpportunityActionTypes.OPPORTUNITIES_FEEDBACK: {
      return adapter.upsertOne(
        {
          recommendationId: _get(action, 'payload.recommendationId'),
          loading: true,
          error: state.entities[_get(action, 'payload.recommendationId')]
            ? state.entities[_get(action, 'payload.recommendationId')].error
            : null,
          success: state.entities[_get(action, 'payload.recommendationId')]
            ? state.entities[_get(action, 'payload.recommendationId')].success
            : null,
          isMultiAgent: _get(action, 'payload.isMultiAgent:', false)
        },
        state
      );
    }

    case OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION: {
      return adapter.upsertOne(
        {
          recommendationId: _get(action, 'payload.recommendationId'),
          loading: true,
          error: null,
          success: null,
          isMultiAgent: _get(action, 'payload.isMultiAgent', false)
        },
        state
      );
    }

    case OpportunityActionTypes.OPPORTUNITIES_NOTIFY_CUSTOMER_SUCCESS: {
      return adapter.upsertOne(
        {
          recommendationId: _get(action, 'payload.recommendationId'),
          success: _get(action, 'payload.status', initialApiStatus),
          error: null,
          loading: false,
          isMultiAgent: _get(action, 'payload.isMultiAgent', false)
        },
        state
      );
    }

    case OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION_FAIL: {
      return adapter.upsertOne(
        {
          recommendationId: _get(action, 'payload.recommendationId'),
          success: null,
          error: {
            maxMessageLevel: '',
            code: 500,
            reason:
              (action && action.payload && action.payload.error && action.payload.error.reason) ||
              '',
            messages: [
              (action && action.payload && action.payload.error && action.payload.error.message) ||
                ''
            ],
            transactionId: ''
          },
          loading: false,
          isMultiAgent: _get(action, 'payload.isMultiAgent', false),
          type: action.payload && action.payload.type,
          customerFeedbackCode: action.payload && action.payload.customerFeedbackCode
        },
        state
      );
    }

    case OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_SUCCESS: {
      const success = state.entities[_get(action, 'payload.recommendationId')]
        ? state.entities[_get(action, 'payload.recommendationId')].success
        : null;
      return adapter.upsertOne(
        {
          recommendationId: _get(action, 'payload.recommendationId'),
          success:
            _get(action, 'payload.status.customerFeedbackCode', '') ===
            CustomerFeedbackEnum.CustomerDismissed
              ? _get(action, 'payload.status', initialApiStatus)
              : success,
          error: null,
          loading: false,
          isMultiAgent: _get(action, 'payload.isMultiAgent', false)
        },
        state
      );
    }

    case OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_ONLY_SUCCESS: {
      return adapter.upsertOne(
        {
          recommendationId: action.payload?.recommendationId,
          success: action.payload?.status || initialApiStatus,             
          error: null,
          loading: false,
          isMultiAgent: _get(action, 'payload.isMultiAgent', false)
        },
        state
      );
    }

    case OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_FAIL:
    case OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_ONLY_FAIL: {
      return adapter.upsertOne(
        {
          recommendationId: _get(action, 'payload.recommendationId'),
          error: {
            maxMessageLevel: '',
            code: 500,
            reason: action?.payload?.error?.reason || '',
            messages: [action?.payload?.error?.message || ''],
            transactionId: ''
          },
          success: null,
          loading: false,
          isMultiAgent: _get(action, 'payload.isMultiAgent', false),
          type: action.payload?.type,
          customerFeedbackCode: action.payload?.customerFeedbackCode
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}

import { RiskModel } from '@amfam/policy/models/src';
import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'amfam-policy-card',
  templateUrl: './policy-card.component.html',
  styleUrls: ['./policy-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyCardComponent {
  @Input() risk: RiskModel;
  @Input() coverages: string;
  @Input() insureds: string[];
  copied = false;

  constructor(private clipboard: Clipboard, private cd: ChangeDetectorRef) {}

  copyPolicyNumberToClipboard() {
    const policyNumber = this.risk.policyNumber;
    if (this.clipboard.copy(policyNumber)) {
      this.copied = true;
      this.cd.detectChanges();
      setTimeout(() => {
        this.copied = false;
        this.cd.detectChanges();
      }, 3000);
    }
  }
}

export interface NameDetail {
  firstName?: string;
  lastName?: string;
  businessName?: string;
}
export interface DigitalAccount {
  customerId?: string;
  emailAddress?: string;
  password?: string;
  phoneNumber?: string;
  securityQuestions?: any[];
  userId?: string;
  nameDetail?: NameDetail;
}

import { createSelector } from '@ngrx/store';
import { ReplaceVehiclePolicy, initialReplaceVehiclePolicy } from './replace-vehicle-policy.model';
import * as replaceVehiclePolicyActions from './replace-vehicle-policy.actions';

// This reduces a set of registrations
export function reducer(
  state = initialReplaceVehiclePolicy,
  action: replaceVehiclePolicyActions.Actions
): ReplaceVehiclePolicy {
  // eslint-disable-next-line prefer-const
  switch (action.type) {
    case replaceVehiclePolicyActions.REPLACE_VEHICLE_POLICY_SELECTION:
      return Object.assign({}, state, initialReplaceVehiclePolicy, action.payload);

    case replaceVehiclePolicyActions.REPLACE_VEHICLE_POLICY_SELECTION_CLEAR:
      return Object.assign({}, state, initialReplaceVehiclePolicy);

    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { FutureState } from './future-state';

@Injectable()
export class FutureStateService {
  private futureStateStore: FutureState = new FutureState(true, false);
  private _futureState$: BehaviorSubject<FutureState> = new BehaviorSubject(this.futureStateStore);

  constructor() {}

  public toggleFutureState() {
    this.futureStateStore.showFutureState = !this.futureStateStore.showFutureState;
    this._futureState$.next(this.futureStateStore);
  }

  public toggleDecoration() {
    this.futureStateStore.decorateFutureState = !this.futureStateStore.decorateFutureState;
    this._futureState$.next(this.futureStateStore);
  }

  get futureState$() {
    return this._futureState$.asObservable();
  }
}

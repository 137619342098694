import { PartyEmail } from '@amfam/shared/models';
import { UserLogin } from '@amfam/shared/user';
import { Action } from '@ngrx/store';
import { UpdateEmailData } from '../models/email-validation.model';
import { EmailPayloadToGetValidationStatus } from '../models/profile.model';

export interface ContactEntryActionPayload {
  data: any;
  methodType: 'phones' | 'emails';
}

export interface SecurityInfoActionPayload {
  data: any;
  methodType: 'securityQuestions' | 'password';
  hideToaster: boolean;
}

export interface ValidateEmailActionPayload {
  newEmail: PartyEmail;
  oldEmail: PartyEmail;
}

// The generic term 'Contact Entry' is used here in place of 'Email Address' or 'Phone Number'
// so actions and functions can be reused due to the fact that both
// email and phone can be altered in much the same way

export enum ProfileActionTypes {
  PROFILE_LOAD = '[Profile] Load Profile',
  ADD_CONTACT_ENTRY = '[Profile] Add New Contact Entry',
  ADD_CONTACT_ENTRY_SUCCESS = '[Profile] Add Contact Entry Success',
  DELETE_CONTACT_ENTRY = '[Profile] Delete Contact Entry',
  DELETE_CONTACT_ENTRY_SUCCESS = '[Profile] Delete Contact Entry Success',
  CHANGE_CONTACT_ENTRY = '[Profile] Change Contact Entry',
  CHANGE_CONTACT_ENTRY_SUCCESS = '[Profile] Change Contact Entry Success',
  CONTACT_ENTRY_FAIL = '[Profile] Add Contact Entry Fail',
  CHANGE_SECURITY_INFO = '[Profile] Change Security Information',
  CHANGE_SECURITY_INFO_SUCCESS = '[Profile] Change Security Information Success',
  CHANGE_SECURITY_INFO_FAIL = '[Profile] Change Security Information Fail',
  FETCH_DATA = '[Profile] Fetch Party Data',
  FETCH_PARTY_DATA_SUCCESS = '[Profile] Fetch Party Data Success',
  FETCH_PARTY_DATA_FAIL = '[Profile] Fetch Party Data Fail',
  FETCH_DA_DATA_SUCCESS = '[Profile] Fetch Digital Account Data Success',
  FETCH_DA_DATA_FAIL = '[Profile] Fetch Digital Account Data Fail',
  UPDATE_DA = '[Profile] Update Digital Account',
  UPDATE_DA_SUCCESS = '[Profile] Update Digital Account Success',
  UPDATE_DA_FAIL = '[Profile] Update Digital Account Fail',
  UPDATE_EMAIL = '[Profile] Update MyAccount Email',
  UPDATE_EMAIL_SUCCESS = '[Profile] Update MyAccount Email Success',
  UPDATE_EMAIL_FAIL = '[Profile] Update MyAccount Email Fail',
  UPDATE_SECURITY_QUESTIONS = '[Profile] Update Security Questions',
  MAKE_REQUEST = '[Profile] Make request from Profile',
  UPDATE_CONTACT_METHOD_VALUES = '[Profile] Update Contact Method Values',
  NOTIFICATION_SUCCESS = '[Profile] Send Email Notificiation Success',
  NOTIFICATION_FAIL = '[Profile] Send Email Notification Fail',
  VALIDATE_EMAIL = '[Profile] Send Email for Validation',
  VALIDATE_EMAIL_SUCCESS = '[Profile] Send Email for Validation Success',
  VALIDATE_EMAIL_FAIL = '[Profile] Send Email for Validation Fail',
  MAGIC3_SEARCH_SUCCESS = '[Profile] Email Validation Magic 3 Search Success',
  REFRESH_PARTY_DATA = '[Profile] Refresh Party Data',
  GET_VALIDATION_STATUS_FOR_EMAIL = '[Profile] Get Validation Status For Email',
  GET_VALIDATION_STATUS_FOR_EMAIL_FAIL = '[Profile] Get Validation Status For Email Fail',
  GET_VALIDATION_STATUS_FOR_PENDING_EMAIL = '[Profile] Get Validation Status For Pending Email',
  GET_VALIDATION_STATUS_FOR_PENDING_EMAIL_SUCCESS = '[Profile] Get Validation Status For Pending Email Success',
  CLEAR_TOAST = '[Profile] Clear Toast'
}

export class ProfileLoad implements Action {
  readonly type = ProfileActionTypes.PROFILE_LOAD;
  constructor(public payload: UserLogin) {}
}

// The action for adding a new email or phone contact
export class AddContactEntryAction implements Action {
  readonly type = ProfileActionTypes.ADD_CONTACT_ENTRY;

  constructor(public payload: ContactEntryActionPayload) {}
}

export class ChangeContactEntryAction implements Action {
  readonly type = ProfileActionTypes.CHANGE_CONTACT_ENTRY;

  constructor(public payload: ContactEntryActionPayload) {}
}

export class ChangeContactEntrySuccessAction implements Action {
  readonly type = ProfileActionTypes.CHANGE_CONTACT_ENTRY_SUCCESS;

  constructor(public payload) {}
}

export class DeleteContactEntryAction implements Action {
  readonly type = ProfileActionTypes.DELETE_CONTACT_ENTRY;

  constructor(public payload) {}
}

export class AddContactEntrySuccessAction implements Action {
  readonly type = ProfileActionTypes.ADD_CONTACT_ENTRY_SUCCESS;

  constructor(public payload) {}
}

export class ContactEntryFailAction implements Action {
  readonly type = ProfileActionTypes.CONTACT_ENTRY_FAIL;

  constructor(public payload?) {}
}

export class ChangeSecurityInfoAction implements Action {
  readonly type = ProfileActionTypes.CHANGE_SECURITY_INFO;

  constructor(public payload?) {}
}

export class ChangeSecurityInfoSuccessAction implements Action {
  readonly type = ProfileActionTypes.CHANGE_SECURITY_INFO_SUCCESS;

  constructor(public payload?) {}
}

export class ChangeSecurityInfoFailAction implements Action {
  readonly type = ProfileActionTypes.CHANGE_SECURITY_INFO_FAIL;

  constructor(public payload?) {}
}

export class DeleteContactEntrySuccessAction implements Action {
  readonly type = ProfileActionTypes.DELETE_CONTACT_ENTRY_SUCCESS;

  constructor(public payload) {}
}

export class FetchDataAction implements Action {
  readonly type = ProfileActionTypes.FETCH_DATA;

  constructor(
    public payload: {
      customerId?: string;
    }
  ) {}
}

export class FetchPartyDataSuccessAction implements Action {
  readonly type = ProfileActionTypes.FETCH_PARTY_DATA_SUCCESS;

  constructor(public payload) {}
}

export class FetchPartyDataFailAction implements Action {
  readonly type = ProfileActionTypes.FETCH_PARTY_DATA_FAIL;

  constructor(public payload) {}
}

export class FetchDaDataSuccessAction implements Action {
  readonly type = ProfileActionTypes.FETCH_DA_DATA_SUCCESS;

  constructor(public payload) {}
}

export class FetchDaDataFailAction implements Action {
  readonly type = ProfileActionTypes.FETCH_DA_DATA_FAIL;

  constructor(public payload) {}
}

export class UpdateDaAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_DA;

  constructor(public payload) {}
}

export class UpdateDaSuccessAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_DA_SUCCESS;

  constructor(public payload) {}
}

export class UpdateDaFailAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_DA_FAIL;

  constructor(public payload) {}
}

export class UpdateEmailAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_EMAIL;

  constructor(public payload: UpdateEmailData) {}
}

export class UpdateEmailSuccessAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_EMAIL_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateEmailFailAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_EMAIL_FAIL;

  constructor(public payload: any) {}
}

// This used to start the loading spinner
export class MakeRequestAction implements Action {
  readonly type = ProfileActionTypes.MAKE_REQUEST;

  constructor(public payload?) {}
}

// This is a generic action that will merge the payload into the store
export class UpdateSecurityQuestionsAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_SECURITY_QUESTIONS;

  constructor(public payload) {}
}

export class UpdateContactMethodValuesAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_CONTACT_METHOD_VALUES;

  constructor(public payload?) {}
}

export class EmailNotificationSuccessAction implements Action {
  readonly type = ProfileActionTypes.NOTIFICATION_SUCCESS;

  constructor(public payload?) {}
}

export class EmailNotificationFailAction implements Action {
  readonly type = ProfileActionTypes.NOTIFICATION_FAIL;

  constructor(public payload?) {}
}

export class ValidateEmailAction implements Action {
  readonly type = ProfileActionTypes.VALIDATE_EMAIL;

  constructor(public payload: ValidateEmailActionPayload) {}
}

export class ValidateEmailSuccessAction implements Action {
  readonly type = ProfileActionTypes.VALIDATE_EMAIL_SUCCESS;

  constructor(public payload?) {}
}

export class ValidateEmailFailAction implements Action {
  readonly type = ProfileActionTypes.VALIDATE_EMAIL_FAIL;

  constructor(public payload?) {}
}

export class Magic3SearchSuccessAction implements Action {
  readonly type = ProfileActionTypes.MAGIC3_SEARCH_SUCCESS;

  constructor(public payload: string) {}
}
export class RefreshPartyDataAction implements Action {
  readonly type = ProfileActionTypes.REFRESH_PARTY_DATA;

  constructor(public payload?) {}
}

export class GetValidationStatusForEmailAction implements Action {
  readonly type = ProfileActionTypes.GET_VALIDATION_STATUS_FOR_EMAIL;
  constructor(public payload: EmailPayloadToGetValidationStatus) {}
}

export class GetValidationStatusForEmailFailAction implements Action {
  readonly type = ProfileActionTypes.GET_VALIDATION_STATUS_FOR_EMAIL_FAIL;
  constructor(public payload: any) {}
}

export class GetValidationStatusForPendingEmailAction implements Action {
  readonly type = ProfileActionTypes.GET_VALIDATION_STATUS_FOR_PENDING_EMAIL;
}
export class GetValidationStatusForPendingEmailSuccessAction implements Action {
  readonly type = ProfileActionTypes.GET_VALIDATION_STATUS_FOR_PENDING_EMAIL_SUCCESS;
  constructor(public payload: any) {}
}
export class ClearToast implements Action {
  readonly type = ProfileActionTypes.CLEAR_TOAST;
}

export type Actions =
  | ProfileLoad
  | FetchDataAction
  | FetchPartyDataSuccessAction
  | FetchPartyDataFailAction
  | FetchDaDataSuccessAction
  | FetchDaDataFailAction
  | AddContactEntryAction
  | AddContactEntrySuccessAction
  | ChangeContactEntryAction
  | ChangeSecurityInfoAction
  | ChangeSecurityInfoSuccessAction
  | ChangeSecurityInfoFailAction
  | DeleteContactEntryAction
  | DeleteContactEntrySuccessAction
  | ContactEntryFailAction
  | ChangeContactEntrySuccessAction
  | UpdateDaAction
  | UpdateDaSuccessAction
  | UpdateDaFailAction
  | UpdateEmailAction
  | UpdateEmailSuccessAction
  | UpdateEmailFailAction
  | UpdateSecurityQuestionsAction
  | MakeRequestAction
  | UpdateContactMethodValuesAction
  | EmailNotificationSuccessAction
  | EmailNotificationFailAction
  | ValidateEmailSuccessAction
  | ValidateEmailFailAction
  | ValidateEmailAction
  | Magic3SearchSuccessAction
  | RefreshPartyDataAction
  | GetValidationStatusForEmailAction
  | GetValidationStatusForEmailFailAction
  | GetValidationStatusForPendingEmailAction
  | GetValidationStatusForPendingEmailSuccessAction
  | ClearToast;

import { PolicyTypeIconConstants } from '@amfam/policy/models';
import { ApiStatus } from '@amfam/shared/models/src';

export interface PaperlessSetup {
  policyTypeIcon: PolicyTypeIconConstants;
  riskDescriptions: string[];
  accountName: string;
}

export enum PaperlessSubmitStatus {
  SUCCESS = 'SUCCESS',
  PARTIAL_FAILURE = 'PARTIAL_FAILURE',
  FAILURE = 'FAILURE'
}

// Set Bill Account Delivery Preference
export interface SetBillAccountDeliveryPreferencePayload {
  billingPreference: BillAccountDeliveryPreference;
}

export interface BillAccountDeliveryPreference {
  billAccountNumber: string;
  accountNickname: string;
  dueDateReminder: string;
  preferences: DeliveryPreference[];
}

export interface BillAccountDeliveryPreferenceNotification {
  billAccountNumber: string;
  billingPreferences: BillAccountDeliveryPreference;
  correlationId: string;
}
export interface DeliveryPreference {
  payloadEntityId: DeliveryPreferenceCodeTypes;
  preferenceCode: 'DOCUMENTS';
  preferenceDeliveryCode: DeliveryPreferenceTypes;
  businessUnitCode: 'BILLING';
  preferenceTypeCode: 'DOCUMENT-NOTIF';
  lastUpdateTimestamp: string;
  phoneNumber: string;
  preferenceId: string;
  emailAddress: string;
  isModifiable: boolean;
  reasonNotModifiable?: string;
}

export interface SetBillAccountDeliveryPreferenceResponse {
  status: ApiStatus;
}

// Set Policy Delivery Preference
export interface SetPolicyDeliveryPreferencePayload {
  authId: string;
  party: Party;
}

export interface Party {
  edeliveryIndicator: string;
  partyVersion: string;
  updatedOn: string;
  typeOfPartyCode: string;
  sourceSystemInformation: SourceSystemInformation;
  contactMethodDetail: ContactMethodDetail;
}

export interface ContactMethodDetail {
  emails: Email[];
}

export interface Email {
  emailId: string;
  emailAddress: string;
  primaryIndicator: boolean;
  contactMethodUsages: ContactMethodUsage[];
}

export interface ContactMethodUsage {
  typeOfUsageCode: string;
}

export interface SourceSystemInformation {
  sourceSystemName: string;
  sourceSystemKey: string;
}

export interface SetPolicyDeliveryPreferenceResponse {
  status: ApiStatus;
  partyResourceIdentifier: PartyResourceIdentifier;
}

export interface PartyResourceIdentifier {
  typeOfResourceCode: string;
  partyId: string;
  emailId: string;
  partyVersion: string;
  updatedOn: string;
}

// Apply Paperless Discount
export interface ApplyPaperlessDiscountPayload {
  FormInstanceTypePaperless: FormInstanceTypePaperless;
}

export interface FormInstanceTypePaperless {
  policies: {
    policyNumber: string;
    producerId: string;
  }[];
  paperlessIndicator: boolean;
  effectiveDate: string;
  customerId: string;
  partnerId: string;
  templateCode: string;
}

export interface ApplyPaperlessDiscountResponse {
  status: ApiStatus;
}

export enum DeliveryPreferenceTypes {
  PAPER = 'PAPER',
  EMAIL = 'EMAIL'
}

export enum DeliveryPreferenceCodeTypes {
  PAPER = 'DOCUMENTS.PAPER',
  EMAIL = 'DOCUMENTS.EMAIL'
}

import { catchError, map, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get as _get } from 'lodash';
import { of } from 'rxjs';

import { AuthService } from '../../../core/auth/auth.service';
import { ForgotService } from '../../../login/shared/forgot.service';
import {
  GetSecurityQuestionsModel,
  LoadUserDataSuccessModel,
  UserDetailsModel,
  ResetPasswordSubmitModel,
  ResetPasswordSuccessModel
} from '@amfam/shared/models';
import * as fromRoot from '../../store';
import * as resetPassword from './reset-password.actions';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { SecurityQuestionsService } from '@amfam/shared/utility/shared-services';

@Injectable()
export class ResetPasswordEffects {
  
  loadUserData$ = createEffect(() => this.action$.pipe(
    ofType(resetPassword.LOAD_USER_DATA),
    map((action: resetPassword.LoadUserDataAction) => action.payload),
    withLatestFrom(this.store.select(BrandSelectors.getPartnerId)),
    mergeMap(([payload, partnerId]) => {
      return this.forgotService.findUser(payload, partnerId).pipe(
        mergeMap((res: UserDetailsModel) => {
          // Route to personal enrollment if user has shell account
          if (res.shellAccountIndicator || res.enrollmentRequestIndicator) {
            return [
              new fromRouterActions.Go({
                path: ['/enroll/personal']
              }),
              new resetPassword.ShellAccountFoundAction()
            ];
          } else {
            const statusCode = _get(res, 'status.code');
            if (statusCode === 200 || statusCode === 423) {
              const securityQuestionsData: GetSecurityQuestionsModel = {
                userIdentifier: payload,
                partnerId: partnerId
              };
              const successObj: LoadUserDataSuccessModel = {
                securityQuestionsData: securityQuestionsData,
                apiData: res
              };
              return [new resetPassword.LoadUserDataSuccessAction(successObj)];
            } else {
              return [new resetPassword.LoadUserDataErrorAction(res)];
            }
          }
        }),
        catchError(err => of(new resetPassword.LoadUserDataErrorAction(err)))
      );
    })
  ));

  
  loadSecurityQuestions$ = createEffect(() => this.action$.pipe(
    ofType(resetPassword.LOAD_USER_DATA_SUCCESS),
    map((action: resetPassword.LoadUserDataSuccessAction) => action.payload),
    switchMap((payload: LoadUserDataSuccessModel) => {
      return this.securityQuestionsService
        .getUserSecurityQuestions(payload.securityQuestionsData)
        .pipe(
          map(res => new resetPassword.LoadSecurityQuestionsSuccessAction(res)),
          catchError(err => of(new resetPassword.LoadSecurityQuestionsErrorAction(err)))
        );
    })
  ));

  
  resetPassword$ = createEffect(() => this.action$.pipe(
    ofType(resetPassword.RESET_PASSWORD),
    map((action: resetPassword.ResetPasswordAction) => action.payload),
    switchMap((payload: ResetPasswordSubmitModel) => {
      return this.forgotService.resetPassword(payload).pipe(
        map(res => {
          if ((payload.userIdentifier || res.userId) && payload.newPassword) {
            const successObj: ResetPasswordSuccessModel = {
              status: res.status,
              userIdentifier: res.userId ? res.userId : payload.userIdentifier,
              password: payload.newPassword
            };
            return new resetPassword.ResetPasswordSuccessAction(successObj);
          } else {
            return new resetPassword.ResetPasswordErrorAction(res.status);
          }
        }),
        catchError(err => of(new resetPassword.ResetPasswordErrorAction(err.status)))
      );
    })
  ));



  
  login$ = createEffect(() => this.action$.pipe(
    ofType(resetPassword.RESET_PASSWORD_SUCCESS),
    map((action: resetPassword.ResetPasswordSuccessAction) => action.payload),
    switchMap((payload: ResetPasswordSuccessModel) => {
      this.authService.setRememberMe(false);
      return this.authService
        .login(payload.userIdentifier, payload.password)
        .pipe(
          map(res => new resetPassword.ResetPasswordLoginSuccessAction(res.status)),
          catchError(err => of(new resetPassword.ResetPasswordLoginErrorAction(err.status)))
        );
    })
  ));

  constructor(
    private store: Store<fromRoot.RootState>,
    private authService: AuthService,
    private forgotService: ForgotService,
    private securityQuestionsService: SecurityQuestionsService,
    private action$: Actions
  ) {}
}

import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { PartyPhone, PartyPhoneUsageCode, PartyEmail, Party } from '@amfam/shared/models';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { get as _get } from 'lodash';
import { EmailPayloadToGetValidationStatus } from '@amfam/profile/data-access';
import { userQuery, UserState } from '@amfam/shared/user';

@Injectable({
  providedIn: 'root'
})
export class ProfileUtilService {
  private stop$ = new Subject<void>();

  constructor(private store: Store<UserState>) {}

  public flattenPhones(phones: PartyPhone[]) {
    const flatPhones: PartyPhone[] = [];
    for (const phone of phones) {
      for (const contactMethodUsage of phone.contactMethodUsages) {
        const contactMethodUsages = new Array<{
          typeOfUsageCode: PartyPhoneUsageCode;
          descriptionForOtherType?: string;
        }>();
        contactMethodUsages.push(contactMethodUsage);
        const flatPhone = Object.assign({}, phone, {
          tenDigitPhoneNumber: phone.tenDigitPhoneNumber,
          contactMethodUsages: contactMethodUsages
        });
        flatPhones.push(flatPhone);
      }
    }
    return flatPhones;
  }

  public getOldAndNewPrimaryEmail(
    selectedEmail: PartyEmail,
    payload: Party
  ): EmailPayloadToGetValidationStatus {
    let data: EmailPayloadToGetValidationStatus;
    this.store
      .select(userQuery.getMyAccountEmail)
      .pipe(
        filter(email => !!email),
        take(1)
      )
      .subscribe(currentMyAccountEmail => {
        let futureMyAccountEmail: PartyEmail;
        // This is to know the emailId as selectedEmail don't have.
        if (_get(payload, 'emails')) {
          futureMyAccountEmail = payload.emails.find(email => {
            return selectedEmail.emailAddress === email.emailAddress;
          });
        }
        data = this.getOldAndNewPrimaries(currentMyAccountEmail, futureMyAccountEmail);
      });
    return data;
  }

  public getOldAndNewPrimaries(
    currentMyAccountEmail: PartyEmail,
    futureMyAccountEmail: PartyEmail
  ): EmailPayloadToGetValidationStatus {
    const oldPrimary: PartyEmail = !currentMyAccountEmail
      ? undefined
      : Object.assign(
          {},
          {
            emailAddress: currentMyAccountEmail.emailAddress,
            emailId: currentMyAccountEmail.emailId,
            contactMethodUsages: currentMyAccountEmail.contactMethodUsages
          }
        );

    const newPrimary: PartyEmail = Object.assign(
      {},
      {
        emailAddress: futureMyAccountEmail.emailAddress,
        emailId: futureMyAccountEmail.emailId,
        contactMethodUsages: futureMyAccountEmail.contactMethodUsages
      }
    );
    return {
      newEntry: newPrimary,
      oldEntry: oldPrimary,
      type: 'MYACCOUNT'
    };
  }
}

import { ApiStatus } from '@amfam/shared/models';
import { Agent } from '@amfam/shared/utility/agent';

export interface AutoCoveragesModel {
  Comprehensive: {
    limits: string;
    code: string;
  };
  Collision: {
    limits: string;
    code: string;
  };
  'Rental Remimbursement': {
    limits: string;
    code: string;
  };
  ERS: {
    values: string;
    code: string;
  };
}

export interface CoverageChangeModel {
  effectiveDate: string;
  riskCoverages: RiskCoverageModel[];
  vehicleIdentifier?: string;
  Vin: string;
  policyNumber?: string;
}

export interface RiskCoverageModel {
  code: string;
  selected: boolean;
  terms?: CoverageTermModel[];
}

export interface CoverageTermModel {
  code: string;
  selectedValue: string;
}

export interface CoverageChangeQuoteResponseModel {
  status: ApiStatus;
  premium: {
    autoPaySavings: Number;
    estimatedFullPaySavings: Number;
    monthlyPremium: Number;
    premiumDetails: [
      {
        discountAmount: Number;
        PolicyAdminConstant: Number;
        premiumAmount: Number;
        RiskID: string;
      }
    ];
    termMonths: Number;
    totalCostWithFullPay: Number;
    totalDiscount: Number;
    totalPremium: Number;
    totalSurcharge: Number;
    workOrderNumber: string;
  };
}

export interface CoverageChangeCustomerTemplateDataModel {
  firstName: string;
  policyNumber: string;
  Vehicle: string;
  CurrentCoverage: string;
  CoverageChanges: string;
  orderNumber: string;
  ProdID: string;
  producer?: Agent;
}

export interface CoverageChangeNotificationRequestCustomerEmail {
  email: CoverageChangeRequestCustomerEmail;
  message: CoverageChangeCustomerTemplateDataModel;
  templateId: string;
}

export interface CoverageChangeRequestCustomerEmail {
  customerId: string;
  customerEmailId: string;
  partnerId: string;
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionEventsService {
  private userActivitySubject$ = new Subject<void>();

  userActivity$ = this.userActivitySubject$.asObservable();

  triggerUserActivity() {
    this.userActivitySubject$.next();
  }

  stopTrackingUserActivity() {
    this.userActivitySubject$.complete();
  }
}

import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { has as _has } from 'lodash';

@Component({
  selector: 'ds-checkbox-toggle',
  templateUrl: './ds-checkbox-toggle.component.html',
  styleUrls: ['./ds-checkbox-toggle.component.scss']
})
export class DsCheckboxToggleComponent implements AfterViewInit {
  @Input() labelText = '';
  @Input() inputValue = [];
  @Input() inputRow: any;
  @Input() inputDisabled = false;

  @ViewChild('labelEl')
  labelEl: ElementRef;

  ngAfterViewInit(): void {
    if (_has(this.labelEl, 'nativeElement')) {
      this.labelEl.nativeElement.classList.add('toggle-focus-off');
    }
  }

  onFocus() {
    if (_has(this.labelEl, 'nativeElement')) {
      this.labelEl.nativeElement.classList.add('toggle-focus-on');
      this.labelEl.nativeElement.classList.remove('toggle-focus-off');
    }
  }

  onBlur() {
    if (_has(this.labelEl, 'nativeElement')) {
      this.labelEl.nativeElement.classList.remove('toggle-focus-on');
      this.labelEl.nativeElement.classList.add('toggle-focus-off');
    }
  }
}

import { Injectable } from '@angular/core';
import { Opportunity, OpportunityConstants, ResponseOpportunityModel } from '../models';
import { Adapter } from '../models/adapter';

@Injectable({
  providedIn: 'root'
})
export class OpportunityAdapter implements Adapter<Opportunity> {
  adapt(item: ResponseOpportunityModel): Opportunity {
    return {
      recommendationId: item.recommendationId,
      productType: item.productType,

      subProductType: this.computeRevisedSubProductType(item.productType, item.subProductType),
      revisedProductType: this.computeRevisedProductType(item.productType, item.subProductType),
      content: null
    };
  }

  /**
   *
   * @param productType : A string variable which tells the product type of the opportunity
   * @param subProductType : A sting variable which tells the sub product type of the opportunity, we need this as some of
   * the opportunity have same product type. This could be because of the limitation of 4 characters on the productType.
   * @description : This function would consume product type and the sub product type and spit out a more understandable product type.
   * This revised product type would used through out the application to decide the product type of opportunity.
   */
  computeRevisedProductType(productType: string, subProductType: string): string {
    switch (productType) {
      case OpportunityConstants.lifeOpportunityProductCode:
        return OpportunityConstants.lifeOpportunityProductName;

      case OpportunityConstants.propOpportunityProductName:
        if (subProductType === OpportunityConstants.ebcOpportunitySubProductCode) {
          return OpportunityConstants.ebcOpportunityProductName;
        } else if (subProductType === OpportunityConstants.slcOpportunitySubProductCode) {
          return OpportunityConstants.slcOpportunityProductName;
        } else if (
          subProductType === OpportunityConstants.propertyDiminishingDeductableSubProductCode
        ) {
          return OpportunityConstants.propertyDiminishingDeductableProductName;
        } else if (subProductType === OpportunityConstants.propertyIdentityTheftSubProductCode) {
          return OpportunityConstants.propertyIdentityTheftProductName;
        } else if (subProductType === OpportunityConstants.propertyMatchingSidingSubProductCode) {
          return OpportunityConstants.propertyMatchingSidingProductName;
        } else if (subProductType === OpportunityConstants.propertySumpPumpSubProductCode) {
          return OpportunityConstants.propertySumpPumpProductName;
        } else if (
          subProductType === OpportunityConstants.propertyHiddenLoanOpportunitySubProductCode
        ) {
          return OpportunityConstants.propertyHiddenLoanGapOpportunityProductName;
        } else {
          return OpportunityConstants.propOpportunityProductName;
        }

      case OpportunityConstants.umbrellaOpportunityProductCode:
        return OpportunityConstants.umbrellaOpportunityProductName;

      case OpportunityConstants.autoOpportunityProductName:
        if (subProductType === OpportunityConstants.ersOpportunitySubProductCode) {
          return OpportunityConstants.ersOpportunityProductName;
        } else if (subProductType === OpportunityConstants.rentersOpportunitySubProductCode) {
          return OpportunityConstants.rentersOpportunityProductName;
        } else if (subProductType === OpportunityConstants.accidentOpportunitySubProductCode) {
          return OpportunityConstants.accidentOpportunityProductName;
        } else if (
          subProductType === OpportunityConstants.autoDiminishingDeductableSubProductCode
        ) {
          return OpportunityConstants.autoDiminishingDeductableProductName;
        } else if (
          subProductType === OpportunityConstants.autoLeaseLoanGapOpportunitySubProductCode
        ) {
          return OpportunityConstants.autoLeaseLoanGapOpportunityProductName;
        } else if (subProductType === OpportunityConstants.automotorcycle) {
          return OpportunityConstants.automotorcycle;
        } else if (subProductType === OpportunityConstants.autoatv) {
          return OpportunityConstants.autoatv;
        } else if (subProductType === OpportunityConstants.automotorhome) {
          return OpportunityConstants.automotorhome;
        } else if (subProductType === OpportunityConstants.autosnowmobile) {
          return OpportunityConstants.autosnowmobile;
        } else if (subProductType === OpportunityConstants.autowatercraft) {
          return OpportunityConstants.autowatercraft;
        } else {
          return OpportunityConstants.autoOpportunityProductName;
        }

      default:
        return productType;
    }
  }

  computeRevisedSubProductType(productType: string, subProductType: string): string {
    // Service currently sends back null string id there is no value, resetting it to avoid any false positive down the line
    if (subProductType === 'null' || !subProductType) {
      return '';
    }

    switch (productType) {
      case OpportunityConstants.lifeOpportunityProductCode:
        return '';

      default:
        return subProductType;
    }
  }
}

import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'ds-key-value-card',
  templateUrl: './key-value-card.component.html',
  styleUrls: ['./key-value-card.component.scss']
})
export class KeyValueCardComponent {
  @Input() key: string;
  @Input() value: string;
  @Input() classes: string;
  @Input() tooltipTemplateRef?: TemplateRef<any> 
}

import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderNavRect } from '../models/header-data.model';
import { NavLinkTreeObj } from '../models/links-model';
import { NotificationObj } from '../models/notification-model';

import { get as _get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HeaderWrapperService {
  private _skipContentFocus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _focusMainContent$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _linkFocus$: BehaviorSubject<ElementRef | null> = new BehaviorSubject(null);
  private _linkActive$: BehaviorSubject<ElementRef | null> = new BehaviorSubject(null);
  private _sublinkActive$: BehaviorSubject<ElementRef | null> = new BehaviorSubject(null);

  private _mobileSublinkActive$: BehaviorSubject<ElementRef | null> = new BehaviorSubject(null);
  private _mobileMenuActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _mobileMenuOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _mobileNotificationMenuOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _mobileMenuReset$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _mobileMenuOpenItem$: BehaviorSubject<HTMLElement | null> = new BehaviorSubject(null);

  private _notificationToDismiss$: BehaviorSubject<object> = new BehaviorSubject({});

  private _loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _chatEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _headerNavRect$: BehaviorSubject<HeaderNavRect> = new BehaviorSubject({});

  private _routerStateUrl$: BehaviorSubject<string> = new BehaviorSubject('');

  // Used to reset the roofline mover
  private linkActive: ElementRef | null = null;

  constructor() {}

  get routerStateUrl$() {
    return this._routerStateUrl$.asObservable();
  }

  setRouterStateUrl(routerStateUrl: string) {
    this._routerStateUrl$.next(routerStateUrl);
  }

  get loaded$() {
    return this._loaded$.asObservable();
  }

  setLoaded(loaded: boolean) {
    this._loaded$.next(loaded);
  }

  get headerNavRect$() {
    return this._headerNavRect$.asObservable();
  }

  setHeaderNavRect(rect: HeaderNavRect) {
    this._headerNavRect$.next(rect);
  }

  get chatEnabled$() {
    return this._chatEnabled$.asObservable();
  }

  setChatEnabled(chatEnabled: boolean) {
    this._chatEnabled$.next(chatEnabled);
  }

  get skipContentFocus$() {
    return this._skipContentFocus$.asObservable();
  }

  setSkipContentFocus(skipContentFocus: boolean) {
    this._skipContentFocus$.next(skipContentFocus);
  }

  get linkFocus$() {
    return this._linkFocus$.asObservable();
  }

  setLinkFocus(linkFocus: ElementRef) {
    this._linkFocus$.next(linkFocus);
  }

  get linkActive$() {
    return this._linkActive$.asObservable();
  }

  setLinkActive(linkActive: ElementRef) {
    this.linkActive = linkActive;
    this._linkActive$.next(linkActive);
  }

  // This makes the sub recalc the position of the active link
  resetRooflineMover() {
    this._linkActive$.next(this.linkActive);
  }

  get sublinkActive$() {
    return this._sublinkActive$.asObservable();
  }

  setSublinkActive(sublinkActive: ElementRef) {
    this._sublinkActive$.next(sublinkActive);
  }

  get mobileSublinkActive$() {
    return this._mobileSublinkActive$.asObservable();
  }

  setMobileSublinkActive(mobileSublinkActive: ElementRef) {
    this._mobileSublinkActive$.next(mobileSublinkActive);
  }

  get mobileMenuActive$() {
    return this._mobileMenuActive$.asObservable();
  }

  setMobileMenuActive(mobileMenuActive: boolean) {
    this._mobileMenuActive$.next(mobileMenuActive);
  }

  get mobileMenuOpen$() {
    return this._mobileMenuOpen$.asObservable();
  }

  setMobileNotificationMenuOpen(mobileNotificationMenuOpen: boolean) {
    this._mobileNotificationMenuOpen$.next(mobileNotificationMenuOpen);
  }

  get mobileNotificationMenuOpen$() {
    return this._mobileNotificationMenuOpen$.asObservable();
  }

  setMobileMenuOpen(mobileMenuOpen: boolean) {
    this._mobileMenuOpen$.next(mobileMenuOpen);
  }

  get mobileMenuReset$() {
    return this._mobileMenuReset$.asObservable();
  }

  setMobileMenuReset(mobileMenuReset: boolean) {
    this._mobileMenuReset$.next(mobileMenuReset);
  }

  get mobileMenuOpenItem$() {
    return this._mobileMenuOpenItem$.asObservable();
  }

  setMobileMenuOpenItem(mobileMenuOpenItem: HTMLElement) {
    this._mobileMenuOpenItem$.next(mobileMenuOpenItem);
  }

  get notificationToDismiss$() {
    return this._notificationToDismiss$.asObservable();
  }

  setNotificationToDismiss(notificationToDismiss: any) {
    this._notificationToDismiss$.next(notificationToDismiss);
  }

  get focusMainContent$() {
    return this._focusMainContent$.asObservable();
  }

  setFocusMainContent(focusMainContent: boolean) {
    this._focusMainContent$.next(focusMainContent);
  }

  buildNotificationMessageArray(notifications): NotificationObj {
    const itemArray: NotificationObj = {
      width: 320,
      messagesArray: []
    };

    if (_get(notifications, 'length')) {
      notifications.forEach(notification => {
        itemArray.messagesArray.push({
          linkText: this.buildNotificationLinkText(
            notification?.number,
            notification?.policyNumber
          ),
          policyNumber: notification?.policyNumber,
          ids: notification?.ids,
          linkRoute: '/policies/' + notification?.policyType + '/' + notification?.policyNumber,
          queryParams: { openAccordion: 'documents', tid: 'newdocnotice' },
          linkTestId: 'notification-' + notification?.policyNumber + '-Link',
          mobileLinkTestId: 'mobileNotification-' + notification?.policyNumber + '-Link'
        });
      });
    }
    return itemArray;
  }

  buildNotificationLinkText(notificationNumber, policyNumber) {
    if (notificationNumber > 1) {
      return (
        'You have ' + notificationNumber + ' new documents ready for Policy Number ' + policyNumber
      );
    } else {
      return 'Your new document is ready for Policy Number ' + policyNumber;
    }
  }

  centerSubNav(linkElem, subLinkElem, headerNavRect) {
    // This centers the sub menu under the nav item.
    // Needed to get accurate height, maybe switch to use route change complete
    let subNavItemOverflowsRight = false;
    if (linkElem && subLinkElem) {
      let subLinkElemOffset = 0;
      // Get the width of this element
      const linkElemWidth = linkElem.nativeElement.getBoundingClientRect().width;
      const subNavItemRightPosition = subLinkElem.nativeElement.getBoundingClientRect().right;

      // Get the width of the sub menu
      const subLinkElemWidth = subLinkElem.nativeElement.getBoundingClientRect().width;
      // Don't allow the sub menu to be narrower than the nav item
      subLinkElem.nativeElement.style.minWidth = linkElemWidth + 'px';

      subNavItemOverflowsRight = subNavItemRightPosition >= headerNavRect.right;

      // If the sub menu is wider than the nav item
      if (subLinkElemWidth > linkElemWidth) {
        // Add the two together, half that and flip it to a negative number
        subLinkElemOffset = ((subLinkElemWidth - linkElemWidth) * -1) / 2;
      }

      if (!subNavItemOverflowsRight) {
        // Use this value to negatively offset the sub menu to the left, centering it.
        subLinkElem.nativeElement.style.left = subLinkElemOffset + 'px';
        subLinkElem.nativeElement.style.right = 'auto';
      } else {
        // Or reset things
        subLinkElem.nativeElement.style.left = 'auto';
        subLinkElem.nativeElement.style.right = '0px';
      }
    }
  }
}

import { userQuery } from '@amfam/shared/user';
import {
  ApplicationService,
  Applications,
  ConfigService,
  CopyService
} from '@amfam/shared/utility/shared-services';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { get as _get, includes as _includes } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'footer-afi',
  templateUrl: './footer-afi.component.html',
  styleUrls: ['./footer-afi.component.scss']
})
export class FooterAfiComponent implements OnInit, OnDestroy {
  @Input() chatEnabled: boolean;
  @Input() feedbackEnabled: boolean = true;
  @Input() showContactInfo = true;

  amfamChatUrl: string = this.config.get('links.amfamChatUrl');
  amfamHeaderFooterPhone: string = this.copyService.getCopy('shared.amfamHeaderFooterPhoneNumber');
  amfamDotCom: string = this.config.get('links.amfamDotComUrl');
  nortonSecurity: string = this.config.get('links.nortonSecurity');
  currentYear: number;
  showSiteMap = false;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private config: ConfigService,
    private copyService: CopyService,
    private appService: ApplicationService,
    private store: Store
  ) {}

  ngOnInit() {
    this.currentYear = new Date().getFullYear();

    const excludedAppNames = [Applications.MYACCOUNT_ADMIN, Applications.FIRST_PARTY_FNOL];

    // Check if the current app is in the excluded apps list
    // Then sub to the userState.loginName to determine login state
    if (!_includes(excludedAppNames, this.appService.getAppName())) {
      this.store
        .select(userQuery.getUserState)
        .pipe(distinctUntilKeyChanged('loginName'), takeUntil(this.stop$))
        .subscribe(state => {
          this.showSiteMap = !!_get(state, 'loginName');
        });
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { GlobalHeadersInterceptor } from './global-headers-inteceptor';
import { GlobalResponseInterceptor } from './global-response-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: GlobalHeadersInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: GlobalResponseInterceptor, multi: true }
];

export interface EnrollmentCopy {
  acceptUserIdError: string;
  acceptUserIdHeading: string;
  securityQtnWelcome: string;
  securityQtnWelcomeText: string;
  signUpBusinessSetUpText: string;
  signUpUserIdSuggText: string;
  signUpUserIdLengthText: string;
  signUpUserIdMismatchText: string;
  signUpUserIdLetterText: string;
  signUpUserIdNumberText: string;
  signUpUserIdSpecialText: string;
  signUpUserIdAvailableText: string;
  signUpUserIdUnavailableText: string;
  signUpUserIdCheckSystemErrorText: string;
  signUpEmailUnavailableText: string;
  signUpEmailCheckSystemErrorText: string;
  signUpEmailShellAccount: string;
  cruiseModalHeader: string;
  cruiseModalText: string;
  cruiseModalBtn: string;
  findPoliciesInfoText: string;
  personalAccountFindPoliciesInfoText: string;
  personalAccountFindPoliciesHelpText: string;
  businessCreateAccText: string;
  businessCreateAccManageText: string;
  chooseAccTypeHeader: string;
  chooseAccTypeInfoText: string;
  chooseAccTypePersonalInfoText: string;
  chooseAccTypePartnerInfoText: string;
  chooseAccTypePersonalShellAccount: string;
  chooseAccTypeBusinessInfoText: string;
  chooseAccTypeBusinessBtn: string;
  chooseAccTypePersonalBtn: string;
  contactUsHelpText: string;
  contactUsAgentText: string;
  contactUsCallbackText: string;
  identificationBusinessAccText: string;
  setSecurityQtnSelectText: string;
  setSecurityQtnSuggestText: string;
  signUpThanksText: string;
  signUpSafeText: string;
  signUpThanksTextPersonal: string;
  signUpEmail: string;
  signUpEmailWrong: string;
  verifyEmailLocatedText: string;
  verifyEmailRequiredText: string;
  verifyEmailSentText: string;
  verifyEmailSpamText: string;
  verifyEmailContinueText: string;
  verifyEmailSentPart1: string;
  verifyEmailSentPart2: string;
  verifyEmailResend: string;
  verifyEmailConfirm: string;
  verifyEmailNotFound: string;
  verifyEmailTokenExpiredText: string;
  verifyEmailTokenExpiredSorry: string;
  maeVerifyEmailTokenExpiredSorry: string;
  verifyEmailTokenExpiredRestart: string;
  myAgentEnrollmentVerifyEmailTokenExpiredRestart: string;
  verifyEmailTokenExpiredButton: string;
  myAgentEnrollmentVerifyEmailTokenExpiredButton: string;
  verifyEmailTokenMaeSorry: string;
  verifyEmailTokenMae: string;
  verifyPhoneConfirmText: string;
  verifyPhoneRequiredText: string;
  verifyPhoneInvalidText: string;
  verifyPhoneCodeConfirm: string;
  verifyPhoneActivateText: string;
  verifyPhoneResendText: string;
  verifyPhoneCodeText: string;
  verifyPhoneConfirm: string;
  verifyCallContinueText: string;
  verifyCallSentPart1: string;
  verifyCallSentPart2: string;
  verifyCallAgain: string;
  verifyTextContinueText: string;
  verifyTextSentPart1: string;
  verifyTextSentPart2: string;
  verifyTextResend: string;
  verifyTextConfirm: string;
  verifyPolicyNumMismatchText: string;
  verifyPolicyNumConfirmText: string;
  verifyPolicyNumContinueText: string;
  verifyPolicyNumSystemError: string;
  verifyPolicyConfirm: string;
  verifySSNMismatchText: string;
  verifySSNConfirmText: string;
  verifySSNContinueText: string;
  verifySSNConfirm: string;
  verificationApologyText: string;
  verificationUserFoundText: string;
  verifyOptionsSSN: string;
  verifyOptionsEmail: string;
  verifyOptionsText: string;
  verifyOptionsCall: string;
  verifyOptionsPolicy: string;
  contactUsHeader: string;
  contactUsBack: string;
  contactUsSchedule: string;
  contactUsCall: string;
  contactUsChat: string;
  exitWarningFirst: string;
  exitWarningMessage: string;
  exitWarningChoose: string;
  exitWarningFinish: string;
  exitWarningExit: string;
  personalInfoText: string;
  programInEligibleMessage: string;
  termsAndConditionsTitle: string;
  addressInvalidMessage: string;
  acceptTermsMsg: string;
  contentLoadError: string;
  emailSelectionHeader: string;
  emailSelectionConditionHeader: string;
  emptyEmailErrorMessage: string;
  confirmationFirstLineText: string;
  confirmationSecondLineText: string;
  nextActionText: string;
  somethingWentWrongText: string;
  enrollmentErrorMessage: string;
  enrollmentErrorMessageRetry: string;
  nextActionBodyText1: string;
  nextActionBodyText2: string;
  nextActionBodyText3: string;
}

import { Action } from '@ngrx/store';
import { MaeStatus } from './models';

export const LOAD = '[MaeStatus] Load';
export const LOAD_SUCCESS = '[MaeStatus] Load Success';
export const LOAD_FAIL = '[MaeStatus] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor(public payload: string) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: MaeStatus) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
  constructor(public payload?: any) {}
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;

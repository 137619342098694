import { Contact, Office } from '@amfam/shared/models';
import { Address } from '@amfam/policy/models';
import { ClaimDetails } from './claim-details';
import { Vehicle } from '@amfam/policy/models';

// date-fns
import { isAfter, isBefore } from 'date-fns';

export class Claim {
  claimNumber: string;
  claimDescription: string;
  claimSourceSystem?: string;
  exposureId: string;
  // DM: we need to know if we attempted to fetch the exposureId
  // because sometimes it will return an empty string
  exposureIdLoaded: boolean;
  exposureIdError: boolean;
  policyNumber: string;
  policyType: string;
  lossDate: Date;
  openDate: Date;
  closedDate: Date;
  closedReason: string;
  claimStatus: string;
  lossLocationAddress: Address;
  primaryFileHandler: Contact;
  claimProgress?: string;
  claimDetail?: ClaimDetails;
  lossCause?: any; // TODO: Get the correct model.
  autoClaimDetails?: Vehicle[];
  office: Office;
  hasError: boolean;

  public static compare(a: Claim, b: Claim): number {
    if (a == null || b == null) {
      return -1;
    }

    if (
      (a.claimStatus === 'OPEN' && b.claimStatus === 'OPEN') ||
      (a.claimStatus === 'CLOSED' && b.claimStatus === 'CLOSED')
    ) {
      if (isBefore(a.openDate, b.openDate)) {
        return 1;
      }
      if (isAfter(a.openDate, b.openDate)) {
        return -1;
      }
      return 0;
    }

    if (a.claimStatus === 'OPEN' && b.claimStatus === 'CLOSED') {
      return -1;
    }

    if (a.claimStatus === 'CLOSED' && b.claimStatus === 'OPEN') {
      return 1;
    }

    return 0;
  }
}

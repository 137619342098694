import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavLinkTreeObj } from '@amfam/shared/ui/ds-header';

@Component({
  selector: 'ds-shared-sitemap',
  templateUrl: './shared-sitemap.component.html',
  styleUrls: ['./shared-sitemap.component.scss']
})
export class SharedSitemapComponent implements OnInit {
  @Input() linkListObj: NavLinkTreeObj[];
  @Input() sitemapTestId = 'sitemapNav';
  @Input() sitemapAriaLabel = 'Site Map Navigation';
  @Output() sitemapClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}

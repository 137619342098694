import { get as _get } from 'lodash';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfileState from './profile.reducer';

import { PROFILE_FEATURE_KEY, ProfileState } from './profile.reducer';

export const getProfileState = createFeatureSelector<ProfileState>(PROFILE_FEATURE_KEY);

export const getProfileLoading = createSelector(getProfileState, fromProfileState.getLoading);
export const getAlternateAuthCustomerId = createSelector(
  getProfileState,
  fromProfileState.getCustomerId
);
export const getEmailInUseError = createSelector(
  getProfileState,
  fromProfileState.getEmailInUseError
);
export const getEmailVerificationPending = createSelector(
  getProfileState,
  fromProfileState.getEmailVerificationPending
);
export const getUpdatedMyAccountEmailAddress = createSelector(
  getProfileState,
  fromProfileState.getUpdatedMyAccountEmailAddress
);

export const getToastMessage = createSelector(getProfileState, fromProfileState.getToastMessage);
export const getProfileSecurityInfoStatus = createSelector(
  getProfileState,
  fromProfileState.getSecurityInfoStatus
);
export const getSecurityQuestions = createSelector(
  getProfileState,
  fromProfileState.getSecurityQuestions
);
export const hasSecurityQuestions = createSelector(getSecurityQuestions, securityQuestions => {
  if (securityQuestions && securityQuestions.length && securityQuestions.length > 0) {
    return (
      _get(securityQuestions[0], 'question', '') !== '' &&
      _get(securityQuestions[1], 'question', '') !== ''
    );
  }
  return false;
});
export const getTokenExpired = createSelector(getProfileState, fromProfileState.getTokenExpred);

export const profileQuery = {
  getProfileState,
  getProfileLoading,
  getAlternateAuthCustomerId,
  getEmailInUseError,
  getEmailVerificationPending,
  getUpdatedMyAccountEmailAddress,
  getToastMessage,
  getProfileSecurityInfoStatus,
  getSecurityQuestions,
  hasSecurityQuestions,
  getTokenExpired
};

import {
  SecurityQuestionsActions,
  SecurityQuestionsActionTypes
} from './security-questions.actions';
import { SecurityQuestion } from './security-questions.models';

export interface SecurityQuestionsState {
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
  allSecurityQuestions: SecurityQuestion[];
  userSecurityQuestions: SecurityQuestion[];
}

export const initialState: SecurityQuestionsState = {
  loaded: false,
  loading: false,
  hasError: false,
  allSecurityQuestions: [],
  userSecurityQuestions: []
};

export function reducer(
  state: SecurityQuestionsState = initialState,
  action: SecurityQuestionsActions
): SecurityQuestionsState {
  switch (action.type) {
    case SecurityQuestionsActionTypes.GetAllSecurityQuestions:
    case SecurityQuestionsActionTypes.GetUserSecurityQuestions:
      return Object.assign({}, state, {
        hasError: false,
        loaded: false,
        loading: true
      });

    case SecurityQuestionsActionTypes.GetAllSecurityQuestionsSuccess:
      return Object.assign({}, state, {
        hasError: false,
        loaded: true,
        loading: false,
        allSecurityQuestions: action.payload.questions
      });

    case SecurityQuestionsActionTypes.GetUserSecurityQuestionsSuccess:
      return Object.assign({}, state, {
        hasError: false,
        loaded: true,
        loading: false,
        userSecurityQuestions: action.payload.response
      });

    case SecurityQuestionsActionTypes.GetAllSecurityQuestionsFailure:
    case SecurityQuestionsActionTypes.GetUserSecurityQuestionsFailure:
      return Object.assign({}, state, {
        hasError: true,
        loaded: true,
        loading: false
      });

    default:
      return state;
  }
}

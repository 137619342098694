<div class="agents-list" *ngIf="adjuster && !claimsStatusEnabled">
  <h4>Claim Contact</h4>
  <ul>
    <li class="agents-list-item">
      <div class="agent-headings">
        <h5>{{ adjuster.name }}</h5>
      </div>
      <ul>
        <li
          class="agent-photo"
          [style.background-image]="adjuster.photo ? 'url(' + adjuster.photo + ')' : ''"
        >
          <span class="initials">{{ adjuster.initials }}</span>
        </li>
        <li>
          <span class="pseudo-icon icon-email"></span>
          <a [dsRouterLink]="['/claims/contact-adjuster', claim.claimNumber]">Contact</a>
        </li>
      </ul>
    </li>
  </ul>
</div>
<div class="agents-list" *ngIf="adjuster && claimsStatusEnabled">
  <h4>Claim Contact</h4>
  <ul>
    <li class="agents-list-item">
      <div class="agent-headings">
        <h5>{{ adjuster.name }}</h5>
      </div>
      <ul>
        <li
          class="agent-photo"
          [style.background-image]="adjuster.photo ? 'url(' + adjuster.photo + ')' : ''"
        >
          <span class="initials">{{ adjuster.initials }}</span>
        </li>
        <li>
          <span class="pseudo-icon icon-email"></span>
          <a [dsRouterLink]="['/claims/contact-adjuster', claim.claimNumber]">Email</a>
        </li>
        <li>
          <span tabindex="0" aria-label="Phone Number" class="pseudo-icon icon-call"></span>
          <a *ngIf="!!adjuster.phone" [href]="adjuster.phone | phoneNumberTelFormatter">{{
            adjuster.phone | adjusterPhoneNumberFormatter
          }}</a>
          <a
            *ngIf="!adjuster.phone"
            dsPartnerContent
            [brandContent]="'getPrettyClaimPhoneNum'"
            [brandHref]="'getClaimPhoneNumFullHref'"
          ></a>
        </li>
      </ul>
    </li>
  </ul>
</div>

<!-- If there is no adjuster yet we need to show the AMFAM contact number -->
<div class="agents-list" *ngIf="!adjuster">
  <h4>Claims Contact</h4>
  <a href="{{ amfamTel }}" target="_blank">{{ amfamPhone }}</a>
</div>

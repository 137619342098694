import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowHideInputComponent } from './show-hide-input/show-hide-input.component';
import { ShowHideInputDirective } from './directives/show-hide-input.directive';
@NgModule({
  imports: [CommonModule],
  declarations: [ShowHideInputComponent, ShowHideInputDirective],
  exports: [ShowHideInputComponent, ShowHideInputDirective]
})
export class SharedUiShowHideInputModule {}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingSpinnerOptions } from '../models/loading-spinner-options';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
  public status: Subject<boolean> = new Subject<boolean>();
  public loadingMessageSub: Subject<string> = new Subject<string>();
  public secondaryMessageSub: Subject<string> = new Subject<string>();
  public maxWidthSub: Subject<string> = new Subject<string>();
  public blockActionsStatus: Subject<boolean> = new Subject<boolean>();
  public isNewLoaderSub: Subject<boolean> = new Subject<boolean>();
  public isProgressBarSub: Subject<boolean> = new Subject<boolean>();
  public isRecalculateSub: Subject<boolean> = new Subject<boolean>();

  private spinnerDefaults = {
    loadingMessage: 'Loading',
    secondaryMessage: '',
    maxWidth: '686',
    isNewLoader: false,
    isProgressBar: false,
    isRecalculate: false
  };

  private _active = false;
  private _blockActions = false;
  private _loadingMessage = this.spinnerDefaults.loadingMessage;
  private _secondaryMessage = this.spinnerDefaults.secondaryMessage;
  private _maxWidth = this.spinnerDefaults.maxWidth;
  private _isNewLoader = this.spinnerDefaults.isNewLoader;
  private _isProgressBar = this.spinnerDefaults.isProgressBar;
  private _isRecalculate = this.spinnerDefaults.isRecalculate;

  public get active(): boolean {
    return this._active;
  }

  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  public set loadingMessage(s: string) {
    this._loadingMessage = s;
    this.loadingMessageSub.next(s);
  }

  public set secondaryMessage(s: string) {
    this._secondaryMessage = s;
    this.secondaryMessageSub.next(s);
  }

  public set maxWidth(s: string) {
    this._maxWidth = s;
    this.maxWidthSub.next(s);
  }

  public set isNewLoader(v: boolean) {
    this._active = v;
    this.isNewLoaderSub.next(v);
  }

  public set isProgressBar(v: boolean) {
    this._active = v;
    this.isProgressBarSub.next(v);
  }

  public set isRecalculate(v: boolean) {
    this._active = v;
    this.isRecalculateSub.next(v);
  }

  public set blockActions(v: boolean) {
    this._blockActions = v;
    this.blockActionsStatus.next(v);
  }

  public start(opts?: LoadingSpinnerOptions): void {
    this.active = true;

    if (opts && opts.loadingMessage) {
      this.loadingMessage = opts.loadingMessage;
    } else {
      this.loadingMessage = this.spinnerDefaults.loadingMessage;
    }
    if (opts && opts.secondaryMessage) {
      this.secondaryMessage = opts.secondaryMessage;
    } else {
      this.secondaryMessage = this.spinnerDefaults.secondaryMessage;
    }
    if (opts && opts.maxWidth) {
      this.maxWidth = opts.maxWidth;
    } else {
      this.maxWidth = this.spinnerDefaults.maxWidth;
    }
    if (opts && opts.blockActions) {
      this.blockActions = opts.blockActions;
    }
    if (opts && opts.isNewLoader) {
      this.isNewLoader = opts.isNewLoader;
    }
    if (opts && opts.isProgressBar) {
      this.isProgressBar = opts.isProgressBar;
    }
    if (opts && opts.isRecalculate) {
      this.isRecalculate = opts.isRecalculate;
    }
  }

  public stop(): void {
    this.active = false;
    this.loadingMessage = this.spinnerDefaults.loadingMessage;
    this.secondaryMessage = this.spinnerDefaults.secondaryMessage;
    this.maxWidth = this.spinnerDefaults.maxWidth;
    this.blockActions = false;
    this.isNewLoader = false;
    this.isProgressBar = false;
    this.isRecalculate = false;
  }
}

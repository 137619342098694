import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ConfigService } from '@amfam/shared/utility/shared-services';
import {
  AddEditAutoPayPayload,
  AddEditAutoPayResponse,
  DeleteAutoPayPayload,
  DeleteAutoPayResponse,
  ApplyAutoPayDiscountPayload,
  ApplyAutoPayDiscountResponse,
  AddMultipleAutoPayPayload,
  AddMultipleAutoPayResponse,
  GetAutomaticPaymentsResponse,
  GetAutoPayPredictionPayload,
  GetAutoPayPredictionResponse
} from '../models/auto-pay.models';
import { catchError } from 'rxjs/operators';
import { ApiStatus } from '@amfam/shared/models';
import { get as _get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AutoPayService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  getAutomaticPayments(billAccountNumber: string): Observable<GetAutomaticPaymentsResponse> {
    const endpoint: string =
      this.config.get('paymentApi') + 'billaccounts/' + billAccountNumber + '/autopay';
    return this.http.get<GetAutomaticPaymentsResponse>(endpoint).pipe(
      catchError((err: { status: ApiStatus }) => {
        // 404 is not error, it means there are no autopays set up for that account
        if (
          _get(err, 'status.code', '').toString() === '404' ||
          _get(err, 'error.status.code', '').toString() === '404'
        ) {
          return of({
            autoPayRules: [],
            status: err.status
          });
        }
        // otherwise, throw error like normal
        else {
          throw err;
        }
      })
    );
  }

  getAutoPayPrediction(
    payload: GetAutoPayPredictionPayload,
    context: 'add' | 'edit'
  ): Observable<AddEditAutoPayResponse> {
    const endpoint =
      this.config.get('paymentApi') + 'billaccounts/' + payload.billAccountNumber + '/autopay';
    if (context === 'add') {
      return this.http.post<GetAutoPayPredictionResponse>(endpoint, payload);
    } else {
      return this.http.put<GetAutoPayPredictionResponse>(endpoint, payload);
    }
  }

  addMultipleAutoPay(payload: AddMultipleAutoPayPayload): Observable<AddMultipleAutoPayResponse> {
    const endpoint = this.config.get('paymentApi') + 'automaticpayments';
    return this.http.post<AddMultipleAutoPayResponse>(endpoint, payload);
  }

  addAutoPay(
    payload: AddEditAutoPayPayload,
    billAccountNumber: string
  ): Observable<AddEditAutoPayResponse> {
    const endpoint =
      this.config.get('paymentApi') + 'billaccounts/' + billAccountNumber + '/autopay';
    return this.http.post<AddEditAutoPayResponse>(endpoint, payload);
  }

  editAutoPay(
    payload: AddEditAutoPayPayload,
    billAccountNumber: string
  ): Observable<AddEditAutoPayResponse> {
    const endpoint =
      this.config.get('paymentApi') + 'billaccounts/' + billAccountNumber + '/autopay';
    return this.http.put<AddEditAutoPayResponse>(endpoint, payload);
  }

  deleteAutoPay(payload: DeleteAutoPayPayload): Observable<DeleteAutoPayResponse> {
    const endpoint =
      this.config.get('paymentApi') + 'billaccounts/' + payload.billAccountNumber + '/autopay';
    return this.http.delete<DeleteAutoPayResponse>(endpoint);
  }

  applyAutoPayDiscount(
    payload: ApplyAutoPayDiscountPayload
  ): Observable<ApplyAutoPayDiscountResponse> {
    const headers: HttpHeaders = new HttpHeaders().set('AFI-UserId', 'MyAF_Web');
    const endpoint = this.config.get('discountApi');
    return this.http.post<ApplyAutoPayDiscountResponse>(
      endpoint + 'applyautopaydiscount',
      payload,
      { headers }
    );
  }
}

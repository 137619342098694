import { Component, OnInit } from '@angular/core';
import { get as _get } from 'lodash';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ConfigService } from '@amfam/shared/utility/shared-services';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ButtonAnalyticParams } from '@amfam/shared/analytics/src/lib/models/analytics.model';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';

@Component({
  selector: 'ds-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit {
  displayBanner = false;
  appLink: string;
  static readonly buttonDownloadMyAmfam: ButtonAnalyticParams = {
    link: 'MyAccount:MyAmFam:SmartBanner:Open'
  };

  constructor(
    private store: Store,
    private config: ConfigService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    combineLatest([
      this.store.select(fromRoot.getDevice),
      this.store.select(BrandSelectors.getBrandState)
    ])
      .pipe(
        map(([device, brandState]) => {
          return {
            device,
            brandState
          };
        }),
        filter(state => state.brandState.loaded),
        take(1)
      )
      .subscribe(state => {
        const deviceType = _get(state, 'device.device').toLowerCase();

        if (deviceType === 'android' && !state.brandState.isPartner) {
          this.displayBanner = true;
          this.appLink = this.config.get('links.amfamGooglePlayLink');
        } else if (
          (deviceType === 'ipad' || deviceType === 'iphone') &&
          !state.brandState.isPartner
        ) {
          this.displayBanner = true;
          this.appLink = this.config.get('links.amfamAppStoreLink');
        }
      });
  }

  dismiss() {
    this.displayBanner = false;
  }
  sendAnalytics() {
    this.analyticsFacade.trackButtonClick(AppBannerComponent.buttonDownloadMyAmfam);
  }
}

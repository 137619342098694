import {
  EntityNotification,
  FailureNotification,
  LoadNotification,
  SuccessNotification
} from '@amfam/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as CommunicationPreferencesActions from './communication-preferences.actions';

export interface State extends EntityState<EntityNotification> {}

export const adapter: EntityAdapter<EntityNotification> = createEntityAdapter<EntityNotification>({
  selectId: (notification: EntityNotification) => notification.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,

  // Set Bill Account Delivery Preference
  // on(CommunicationPreferencesActions.setBillAccountDeliveryPreference, (state, action) =>
  //   adapter.upsertOne(new LoadNotification(action.billAccountNumber), state)
  // ),

  on(CommunicationPreferencesActions.setBillAccountDeliveryPreferenceSuccess, (state, action) =>
    adapter.upsertOne(
      new SuccessNotification(action.billAccountNumber, action.correlationId),
      state
    )
  ),

  on(CommunicationPreferencesActions.setBillAccountDeliveryPreferenceFailure, (state, action) =>
    adapter.upsertOne(
      new FailureNotification(action.billAccountNumber, action.correlationId, action.error),
      state
    )
  ),

  on(CommunicationPreferencesActions.setBillAccountDeliveryPreference, (state, action) =>
    adapter.upsertOne(new LoadNotification(action.billAccountNumber), state)
  )
  // on(CommunicationPreferencesActions.setBillAccountDeliveryPreferenceFailure, (state, action) =>
  //   adapter.upsertOne(
  //     new FailureNotification(action.billAccountNumber, action.correlationId, action.error),
  //     state
  //   )
  // ),

  // on(CommunicationPreferencesActions.setPolicyDeliveryPreference, (state, action) =>
  //   adapter.upsertOne(new LoadNotification('policy'), state)
  // ),

  // on(CommunicationPreferencesActions.setPolicyDeliveryPreferenceSuccess, (state, action) =>
  //   adapter.upsertOne(new SuccessNotification('policy', action.correlationId), state)
  // ),

  // on(CommunicationPreferencesActions.setPolicyDeliveryPreferenceFailure, (state, action) =>
  //   adapter.upsertOne(new FailureNotification('policy', action.correlationId, action.error), state)
  // )
  // on(CommunicationPreferencesActions.applyPaperlessDiscount, state => ({
  //   loading: false,
  //   error: false,
  //   correlationId: ''
  // })),
  // on(CommunicationPreferencesActions.applyPaperlessDiscountSuccess, (state, action) => ({
  //   loading: false,
  //   error: false,
  //   correlationId: ''
  // })),
  // on(CommunicationPreferencesActions.applyPaperlessDiscountFailure, (state, action) => ({
  //   loading: false,
  //   error: false,
  //   correlationId: ''
  // }))
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectCommunicationPreferencesNotificationIds = selectIds;
export const selectCommunicationPreferencesNotificationEntities = selectEntities;
export const selectAllCommunicationPreferencesNotifications = selectAll;
export const selectCommunicationPreferencesNotificationsTotal = selectTotal;

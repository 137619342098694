import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PolicyDocumentsPartialState } from './documents.reducer';
import { documentsQuery } from './documents.selectors';
import { LoadDocuments } from './documents.actions';
import { PolicySummary } from '@amfam/policy/models';

@Injectable()
export class DocumentsFacade {
  loaded$ = this.store.pipe(select(documentsQuery.getLoaded));
  allDocuments$ = this.store.pipe(select(documentsQuery.getAllDocuments));

  constructor(private store: Store<PolicyDocumentsPartialState>) {}

  loadAll(policySummaryList: PolicySummary[]) {
    this.store.dispatch(new LoadDocuments(policySummaryList));
  }
}

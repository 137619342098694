import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromPaymentMethodFeatureState from './payment-method-feature.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromPaymentMethodFeatureState.paymentMethodFeatureKey,
      fromPaymentMethodFeatureState.reducer
    )
  ]
})
export class PaymentMethodFeatureStateModule {}

import { createSelector } from '@ngrx/store';
import * as fromFinancialAccount from './financial-account.reducer';

import { getState, State } from '../index';

export const getFinancialAccountState = createSelector(
  getState,
  (state: State) => state.financialAccountState
);

export const getPCIAuthorizationToken = createSelector(
  getFinancialAccountState,
  (state: fromFinancialAccount.State) => state.pciToken
);

export const getFinancialInstitution = createSelector(
  getFinancialAccountState,
  (state: fromFinancialAccount.State) => state.financialInstitution
);

export const getLoading = createSelector(
  getFinancialAccountState,
  (state: fromFinancialAccount.State) => state.loading
);

export const hasFinancialInstitutionError = createSelector(
  getFinancialAccountState,
  (state: fromFinancialAccount.State) => {
    return state.financialInstitutionError || state.paymentMethodError;
  }
);

export const hasPCITokenError = createSelector(
  getFinancialAccountState,
  (state: fromFinancialAccount.State) => state.pciTokenError
);

export const getCorrelationId = createSelector(
  getFinancialAccountState,
  (state: fromFinancialAccount.State) => state.correlationId
);

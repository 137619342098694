<div
  *ngIf="dataLoading"
  [ngClass]="{ 'neutral-600-border has-border': bordered, 'pad-20 margin-vertical-20': padded }"
  class="flex items-center"
  data-cy="localizedSpinnerContent"
>
  <div class="spinner">
    <div class="spin-accent"></div>
    <div class="center"></div>
  </div>
  <h3 class="neutral-600-text margin-0 pad-left-10">{{ loadingMessage }}</h3>
</div>

import { ButtonAnalytic, EventAnalytic, PageAnalytic } from '@amfam/shared/utility/shared-services';

export class PolicyAnalytics {
  public static readonly pagePolicyGraceNotice: PageAnalytic = {
    pageName: 'MyAccount:NewVehicleGraceNotice',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'New Vehicle Grace Period',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyGraceNoticeSent: PageAnalytic = {
    pageName: 'MyAccount:NewVehicleGraceNotice:AgentNotified',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'New Vehicle Grace Period',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyReplaceVehicle: PageAnalytic = {
    pageName: 'MyAccount:ReplaceVehicle',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly eventPolicyReplaceVehicleStart: EventAnalytic = {
    eventName: 'Replace Vehicle',
    eventStep: 'start'
  };

  public static readonly eventPolicyReplaceVehicleComplete: EventAnalytic = {
    eventName: 'Replace Vehicle',
    eventStep: 'complete',
    replaceVehicle: {
      referenceNumber: ''
    }
  };

  public static readonly eventPolicyReplaceVehicleSent: EventAnalytic = {
    eventName: 'Replace Vehicle',
    eventStep: 'sent',
    replaceVehicle: {
      agentName: ''
    }
  };

  public static readonly pagePolicyReplaceVehicleVIN: PageAnalytic = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:VIN:Start',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyReplaceVehicleYMM: PageAnalytic = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:YearMakeModel',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyReplaceVehicleNewPremium: PageAnalytic = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:NewPremium',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyReplaceVehicleConfirmation: PageAnalytic = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:Confirmation',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyReplaceVehicleError: PageAnalytic = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:Error',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  // page analytic for policy and billing terms and conditions
  public static readonly pageTermsAndConditionsGoPaperless: PageAnalytic = {
    pageName: 'MyAccount:GoPaperlessTC_Both',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: ' ',
    subCategory2: 'Go Paperless',
    subCategory3: ''
  };

  // page analytic for go paperless confirmation
  public static readonly pageGoPaperlessConfirmation: PageAnalytic = {
    pageName: 'MyAccount:Enroll:Paperless:Complete',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: ' ',
    subCategory2: 'Go Paperless',
    subCategory3: ''
  };

  // page analytic for go paperless confirmation for new customer
  /**
   *  @deprecated
   * use the paperless-anlytics-constants for this
   */
  public static readonly pageGoPaperlessNewCustomerConfirmation: PageAnalytic = {
    pageName: 'MyAccount:Enroll:Paperless:Complete',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Policies',
    subCategory2: 'Go Paperless',
    subCategory3: ''
  };
  // page analytic for go paperless start page for new customer
  public static readonly pageGoPaperlessNewCustomerStart: PageAnalytic = {
    pageName: 'MyAccount:GoPaperless:NewCustomer:Start',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Policies',
    subCategory2: 'Go Paperless',
    subCategory3: ''
  };

  // page analytic for go paperless existing preference click for new customer
  public static readonly buttonGoPaperlessNewCustomerExistingPreferenceClick: ButtonAnalytic = {
    link: 'GoPaperlessKeepMyExistingPreferences'
  };

  // page analytic for go paperless error screen for new customer
  public static readonly buttonGoPaperlessNewCustomerErrorClick: ButtonAnalytic = {
    link: 'GoPaperlessSignUpError'
  };

  // Page Analytics for Life Policy Detail page
  public static readonly pageLifePolicyDetailAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Policies:PolicyDetails',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Policies',
    subCategory2: 'Life',
    subCategory3: ''
  };

  public static readonly policyChangeRequestPageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Policies:RequestChange${changeTypeSelected}',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Policies',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly policyChangeRequestEventAnalytic: EventAnalytic = {
    eventName: 'Request a Policy Change',
    eventStep: 'start'
  };

  public static readonly pagePolicyAddVehicleNewOrReplaceRiskSelection: PageAnalytic = {
    pageName: 'MyAccount:Policies:AddVehicle:NewOrReplace',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Add Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyAddVehicleGraceNotification: PageAnalytic = {
    pageName: 'MyAccount:Policies:NewVehicleGraceNotice',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Add Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyAddVehicleGraceNotificationSentToAgent: PageAnalytic = {
    pageName: 'MyAccount:Policies:NewVehicleGraceNotice:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Add Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyAddVehicleNewOrReplaceVehicleGraceNotification: PageAnalytic = {
    pageName: 'MyAccount:Policies:AddVehicle:NewOrReplace:NewVehicleGraceNotice',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Add Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly hardStopForClassicPolicy: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:HardStop:ClassicPolicy',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'HardStops',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyAddVehicleNewOrReplaceMultiAdvGraceNotification: PageAnalytic = {
    pageName: 'MyAccount:Policies:AddVehicle:NewOrReplace:MultiAdvGracePeriod',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Add Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageAddVehicleMultiAdvPolicies: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:HardStop:MultipleAdvancePolicies',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Hardstops',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyHardStopLiabilityOnly: PageAnalytic = {
    pageName: 'MyAccount:HardStop:LiabilityOnly',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Hardstops',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyAddVehicleNewOrReplaceMultiAdvGraceNotificationSendToAgent: PageAnalytic =
    {
      pageName: 'MyAccount:Policies:AddVehicle:NewOrReplace:MultiAdvGracePeriod:SentToAgent',
      experience: '',
      primaryCategory: 'MyAccount',
      subCategory1: 'Add Vehicle',
      subCategory2: '',
      subCategory3: ''
    };

  public static readonly eventPolicyNewOrReplaceMultiAdvGraceNotificationSendToAgent: EventAnalytic =
    {
      eventName: 'Add Vehicle',
      eventStep: 'Sent to Agent'
    };

  public static readonly pagePolicyReplaceVehiclePolicyGracePeriod: PageAnalytic = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:PolicyGracePeriod',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyNewVehiclePolicyGracePeriod: PageAnalytic = {
    pageName: 'MyAccount:Policies:NewVehicleGraceNotice:SentToAgent',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyNewCarReplaceGraceNotice: PageAnalytic = {
    pageName: 'MyAccount:Policies:NewCarReplaceGraceNotice',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyNewCarReplaceGraceNoticeSentToAgent: PageAnalytic = {
    pageName: 'MyAccount:Policies:NewCarReplaceGraceNotice:SentToAgent',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyAddVehicleFuturePaymentSchedule: PageAnalytic = {
    pageName: 'MyAccount:Policies:AddVehicle:FuturePaymentSchedule',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Add Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly buttonPoliciesEditLenderInfo: ButtonAnalytic = {
    link: 'MyAccount:Policies:EditLenderInfo'
  };

  public static readonly buttonPoliciesRequestChangeTPI: ButtonAnalytic = {
    link: 'MyAccount:Policies:RequestChange:ChangeTPI'
  };

  public static readonly buttonPoliciesRequestRemoveTPI: ButtonAnalytic = {
    link: 'MyAccount:Policies:RequestChange:RemoveTPI'
  };

  public static readonly buttonPoliciesRequestAddTPI: ButtonAnalytic = {
    link: 'MyAccount:Policies:RequestChange:AddTPI'
  };

  public static readonly buttonPoliciesTPIChangesDone: ButtonAnalytic = {
    link: 'MyAccount:Policies:RequestChange:Done'
  };

  public static readonly pagePoliciesRequestAChangeComplete: PageAnalytic = {
    pageName: 'MyAccount:Policies:RequestChange:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Policy',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly buttonPoliciesGetPOI: ButtonAnalytic = {
    link: 'MyAccount:Policies:GetPOI'
  };

  public static readonly buttonOverviewGetPOI: ButtonAnalytic = {
    link: 'MyAccount:Overview:GetPOI'
  };

  public static readonly buttonPoliciesDocumentsGetPOI: ButtonAnalytic = {
    link: 'MyAccount:Policies:Documents:GetPOI'
  };

  public static readonly pagePoliciesGetPOIError: PageAnalytic = {
    pageName: 'MyAccount:Overview:GetPOI:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Policies',
    subCategory2: '',
    subCategory3: ''
  };
}

export class PolicyDetails {
  public static readonly buttonRequestPolicyChange: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:CoverageDetails:RequestPolicyChange'
  };
  public static readonly buttonFileAClaim: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:FileCliam'
  };
  public static readonly buttonGetInsuranceCard: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:GetPOI'
  };
  public static readonly buttonAddReplaceVehicle: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:AddReplaceVehicle'
  };
  public static readonly buttonManageBillingAccount: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:CoverageDetails:ManageBillingAccounts'
  };
  public static readonly buttonCoverageDetailsRequestPolicyChange: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:CoverageDetails:RequestPolicyChange'
  };
  public static readonly buttonCoverageDetailsUpdateMyCoverage: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:CoverageDetails:UpdateMyCoverage'
  };
  public static readonly jumpLinkCoverageDetials: ButtonAnalytic = {
    link: 'MyAccount:Policies:JumpLink:CoverageDetails'
  };

  public static readonly jumpLinkDocuments: ButtonAnalytic = {
    link: 'MyAccount:Policies:JumpLink:Documents'
  };
  public static readonly jumpLinkPolicyDetailsDocuments: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:JumpLink:Documents'
  };
  public static readonly jumpLinkCoverage: ButtonAnalytic = {
    link: 'MyAccount:Policies:JumpLink:ViewCoverageDetails'
  };
  public static readonly jumpLinkPremium: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:JumpLink:Premium'
  };
  public static readonly getPOIPDF: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:Documetns:GetPOIPDF'
  };
  public static readonly getDECPagePDF: ButtonAnalytic = {
    link: 'MyAccount:Policies:PolicyDetails:Documetns:GetDecPagePDF'
  };

  public static readonly jumpLinkCoverageDocuments: ButtonAnalytic = {
    link: 'MyAccount:Policies:JumpLink:CoverageDocuments'
  };
}

export class PolicyTypeName {
  public static readonly life: string = 'Life';
}

export const ReplaceVehicleANSNotificationType = 'ANSMYARPVQ';

export const ReplaceVehicleCustomerTemplateIdCode = 'MYACCT_RPL_VEH_AGENTSENT_ES';

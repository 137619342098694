<div class="row">
  <div class="column twelve lg-push-two lg-eight">
    <!-- AS: As part of multiple autopay setup , we are now going to display success and failure
    headers in the same confirmation. Seperating it out based on the type here -->

    <!--AS: If there is an error to be displayed in the confirmation page-->
    <div
      *ngIf="
        confirmationStatus === 'error' ||
        (errorDetailsList?.length > 0 &&
          hasErrorConfirmationTobeShown &&
          !!errorConfirmationHeading)
      "
    >
      <div class="confirmation-title">
        <span class="icon pseudo-icon error"></span>
        <div>
          <h2
            class="heading-main confirmation-title-text"
            [innerHtml]="errorDetailsList ? errorConfirmationHeading : confirmationHeading"
          ></h2>
          <span
            *ngIf="confirmationSubHeading"
            class="confirmation-title-subtext"
            [innerHTML]="confirmationSubHeading"
          ></span>
        </div>
      </div>

      <!--AS: Use the details object to build the rows-->
      <ng-content></ng-content>

      <ds-confirmation-details
        *ngIf="errorDetailsList"
        [details]="errorDetailsList"
      ></ds-confirmation-details>
      <div *ngIf="errorDetailsList" class="confirmation-cta">
        <button ds-button class="margin-right-20" (click)="retryClickedEvent.emit()">
          TRY AGAIN
        </button>
        <a class="caption pad-horizontal-20" href="{{ amfamTelNumber }}" target="_blank">{{
          amfamPhone
        }}</a>
      </div>
    </div>

    <!--AS: If there is no error to be displayed in the confirmation page-->
    <div *ngIf="confirmationStatus !== 'error'">
      <div class="confirmation-title">
        <span
          class="icon pseudo-icon"
          [ngClass]="{
            warning: confirmationStatus === 'warning',
            alert: confirmationStatus === 'alert',
            claims: confirmationStatus === 'claims'
          }"
        ></span>

        <div>
          <h2
            data-cy="confirmationScreenHeader"
            class="heading-main confirmation-title-text"
            [innerHTML]="confirmationHeading"
          ></h2>
          <span
            data-cy="confirmationScreenSubHeader"
            *ngIf="confirmationSubHeading"
            class="confirmation-title-subtext"
            [innerHTML]="confirmationSubHeading"
          ></span>
        </div>
      </div>

      <!--AS: New subheading to be displayed incase of multiautopay success-->
      <div *ngIf="!!headerInfoText" class="info-text-message">
        <p [innerHTML]="headerInfoText"></p>
      </div>

      <!--AS: Use the details object to build the rows-->
      <ng-content></ng-content>
      <ds-confirmation-details
        *ngIf="successDetailsList"
        [details]="successDetailsList"
      ></ds-confirmation-details>
    </div>

    <ds-confirmation-next-steps
      *ngIf="
        confirmationData?.nextSteps &&
        (confirmationData?.nextSteps?.heading || confirmationData?.nextSteps?.text)
      "
      [nextSteps]="confirmationData.nextSteps"
    ></ds-confirmation-next-steps>
    <div *ngIf="vehicleName && !isPartner">
      <div class="display-policy">
        <div class="mrg-100 mrg-rgt">{{ vehicleAddedText }}</div>
        <div class="print-data">{{ vehicleName }}</div>
      </div>
      <div class="display-policy mrg-bottom42">
        <div class="mrg-100">{{ policyNumberText }}</div>
        <div class="print-data">{{ policyNumber | prettyPolicyNum }}</div>
      </div>

      <ds-amfam-dsn-alert
        [icon]="true"
        [type]="'info'"
        [message]="alertMessage"
        [title]="title"
      ></ds-amfam-dsn-alert>
    </div>
    <ng-content select="ds-confirmation-card-area"></ng-content>

    <ds-confirmation-disclaimer
      *ngIf="confirmationData?.disclaimer"
      [disclaimer]="confirmationData.disclaimer"
    ></ds-confirmation-disclaimer>

    <ds-confirmation-cta-area
      *ngIf="confirmationData?.ctaArea"
      [ctaArea]="confirmationData.ctaArea"
    ></ds-confirmation-cta-area>
    <ng-content select="ds-confirmation-link-area" class="font-weight-semibold"></ng-content>
  </div>
</div>

import * as AssociatedPartyAction from './associated-party.actions';
import { Entities, initialEntities } from '../entity/entity.model';

import { groupBy as _groupBy } from 'lodash';
import { AssociatedParty } from './models/associated-party.model';

export function reducer(
  state = initialEntities<AssociatedParty>(),
  action: AssociatedPartyAction.Actions
): Entities<AssociatedParty> {
  let entities: {};

  switch (action.type) {
    case AssociatedPartyAction.LOAD:
      return Object.assign({}, state, {
        loading: true
      });

    case AssociatedPartyAction.LOAD_COMPLETE:
      return Object.assign({}, state, {
        loading: false
      });

    case AssociatedPartyAction.LOAD_SUCCESS:
      entities = _groupBy(action.payload || [], associatedParty => associatedParty.policyNumber);

      entities = Object.assign({}, state.entities || {}, entities || {});

      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities,
        loaded: true,
        loading: false
      });

    default:
      return state;
  }
}

export const getEntities = (state: Entities<AssociatedParty>) => state.entities;

export const getIds = (state: Entities<AssociatedParty>) => state.ids;

export const getError = (state: Entities<AssociatedParty>) => state.error;

export const getLoaded = (state: Entities<AssociatedParty>) => state.loaded;

export const getLoading = (state: Entities<AssociatedParty>) => state.loading;

import { createFeatureSelector, createSelector, ActionReducerMap } from '@ngrx/store';
import { AUTOPAY_FEATURE_KEY } from './autopay-predict.reducer';
import * as fromAutopayPredictState from './autopay-predict.reducer';
import * as fromAutopayPredictNotifications from './notifications.reducer';
import { get as _get } from 'lodash';
import { AutopayPredict } from '../models/autopay-predict.models';
export interface AutopayPredictState {
  autopayPredictState: fromAutopayPredictState.AutopayEntityState;
  autopayPredictNotifications: fromAutopayPredictNotifications.NotificationState;
}

export const autopayPredictReducers: ActionReducerMap<AutopayPredictState> = {
  autopayPredictState: fromAutopayPredictState.reducer,
  autopayPredictNotifications: fromAutopayPredictNotifications.reducer
};

export const getAutopayPredictState = createFeatureSelector<AutopayPredictState>(
  AUTOPAY_FEATURE_KEY
);

export const getAutopayPredictLoading = createSelector(
  getAutopayPredictState,
  (state: AutopayPredictState) => _get(state, 'autopayPredictNotifications.loading', true)
);

export const getAutopayPredictLoaded = createSelector(
  getAutopayPredictState,
  (state: AutopayPredictState) => !_get(state, 'autopayPredictNotifications.loading', true)
);

export const getAutopayPredictEntities = createSelector(
  getAutopayPredictState,
  getAutopayPredictLoaded,
  (state: AutopayPredictState, isLoaded) => {
    return isLoaded ? fromAutopayPredictState.selectEntities(state.autopayPredictState) : [];
  }
);

export const getAutopayPredictIds = createSelector(
  getAutopayPredictState,
  getAutopayPredictLoaded,
  (state: AutopayPredictState, isLoaded) => {
    return isLoaded ? <string[]>fromAutopayPredictState.selectIds(state.autopayPredictState) : [];
  }
);
export const getAutopayPredicts = createSelector(
  getAutopayPredictEntities,
  getAutopayPredictIds,
  (entities, ids) => {
    return ids.map(id => entities[id]);
  }
);
export const getAutopayPredictById = createSelector(
  getAutopayPredicts,
  (entities: AutopayPredict[], billaccountNumber: string) => {
    return entities[billaccountNumber];
  }
);

export const autopayPredictQuery = {
  getAutopayPredictState,
  getAutopayPredictById,
  getAutopayPredicts,
  getAutopayPredictIds,
  getAutopayPredictEntities,
  getAutopayPredictLoading,
  getAutopayPredictLoaded
};

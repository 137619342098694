import { createFeatureSelector, createSelector } from '@ngrx/store';
import { has as _has, forEach as _forEach, cloneDeep as _cloneDeep, get as _get } from 'lodash';
import { Step } from '../models/step';

import {
  PROGRESSINDICATOR_FEATURE_KEY,
  ProgressIndicatorState
} from './progress-indicator.reducer';

// Lookup the 'ProgressIndicator' feature state managed by NgRx
const getProgressIndicatorState = createFeatureSelector<ProgressIndicatorState>(
  PROGRESSINDICATOR_FEATURE_KEY
);

const getLoaded = createSelector(
  getProgressIndicatorState,
  (state: ProgressIndicatorState) => state.loaded
);

const getWorkflowType = createSelector(
  getProgressIndicatorState,
  (state: ProgressIndicatorState) => state.workflowType
);

const getSteps = createSelector(
  getProgressIndicatorState,
  (state: ProgressIndicatorState) => state.steps
);

const getIds = createSelector(
  getProgressIndicatorState,
  (state: ProgressIndicatorState) => state.ids
);

const getActiveId = createSelector(
  getProgressIndicatorState,
  (state: ProgressIndicatorState) => state.activeStep
);

const getActiveTitle = createSelector(
  getProgressIndicatorState,
  (state: ProgressIndicatorState) => state.steps[state.activeStep].title
);

const getActiveStep = createSelector(
  getSteps,
  getActiveId,
  (steps, activeStep) => {
    return steps[activeStep];
  }
);

const getNextStep = createSelector(
  getSteps,
  getActiveId,
  (steps, activeStep) => {
    return steps[steps[activeStep].nextStep];
  }
);

const getBackStep = createSelector(
  getSteps,
  getActiveId,
  (steps, activeStep) => {
    return steps[steps[activeStep].backStep];
  }
);

const getProgressIndicatorData = createSelector(
  getProgressIndicatorState,
  state => {
    const progressBarData: Step[] = [];
    const steps = _cloneDeep(_get(state, 'steps'));
    _forEach(steps, step => {
      if (_has(step, 'show') && step.show === true) {
        progressBarData.push({
          name: step.name,
          title: step.title,
          complete: step.complete,
          skipped: step.skipped,
          nextStep: step.nextStep,
          active: step.title === _get(state.steps[state.activeStep], 'title', '')
        });
      }
    });
    return progressBarData;
  }
);

export const progressIndicatorQuery = {
  getProgressIndicatorState,
  getLoaded,
  getWorkflowType,
  getSteps,
  getIds,
  getActiveId,
  getActiveTitle,
  getActiveStep,
  getNextStep,
  getBackStep,
  getProgressIndicatorData
};

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ds-select-card',
  templateUrl: './select-card.component.html',
  styleUrls: ['./select-card.component.scss']
})
export class SelectCardComponent {
  @Input() name: string;
  @Input() imageUrl: string;
  @Input() imageAltText: string;

  @Output() cardSelected = new EventEmitter();

  onClick() {
    this.cardSelected.emit();
  }
}

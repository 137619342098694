import { RiskModel } from '@amfam/policy/models/src';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'amfam-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.scss']
})
export class VehicleCardComponent {
  @Input() risk: RiskModel;
}

import { ClaimGlassOnly } from '@amfam/claim/glass-only/data-access';
import { FnolModel } from '../+state/fnol.model';
import { get as _get } from 'lodash';

export class GlassDamagePayload {
  static create(payload: FnolModel): ClaimGlassOnly {
    let successUrl = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port) {
      successUrl += ':' + window.location.port;
    }
    successUrl += '/claims';

    return {
      policyNumber: _get(payload, 'risk.policyNumber'),
      lossDate: _get(payload, 'lossDate'),
      returnURL: successUrl,
      returnURL2: successUrl,
      firstName: _get(payload, 'firstName'),
      lastName: _get(payload, 'lastName'),
      address1: _get(payload, 'risk.address.addressLine1'),
      address2: _get(payload, 'risk.address.addressLine2'),
      city: _get(payload, 'risk.address.city'),
      state: _get(payload, 'risk.address.state'),
      zip: _get(payload, 'risk.address.zipCode'),
      email: _get(payload, 'emailAddress'),
      phoneNumber: _get(payload, 'phoneNumber')
    };
  }
}

import {
  AutoPolicy,
  GenericProductType,
  LifePolicy,
  Policy,
  PolicyStatus,
  PropertyPolicy,
  RiskCompare,
  RiskModel,
  SourceSystemType,
  UmbrellaPolicy,
  Vehicle
} from '@amfam/policy/models';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap as _flatMap, flatten as _flatten, get as _get } from 'lodash';
import { VehicleTypeConstants } from '../../models/vehicle-type-constants';
import * as fromDocumentsState from '../documents/documents.reducer';
import * as fromDocumentsNotifications from '../documents/notifications.reducer';
import * as fromSummariesNotifications from '../summaries/notifications.reducer';
import * as fromSummariesState from '../summaries/summaries.reducer';
import { getPolicySummaries } from '../summaries/summaries.selectors';
import * as fromPoliciesNotifications from './notifications.reducer';
import * as fromPoliciesState from './policies.reducer';
import { POLICIES_FEATURE_KEY } from './policies.reducer';

export interface PoliciesState {
  policyState: fromPoliciesState.PoliciesEntityState;
  policyNotifications: fromPoliciesNotifications.NotificationState;
  documentsState: fromDocumentsState.PolicyDocumentsEntityState;
  documentsNotifications: fromDocumentsNotifications.NotificationState;
  summariesState: fromSummariesState.PolicySummariesEntityState;
  summariesNotification: fromSummariesNotifications.NotificationState;
}

export const policiesReducers: ActionReducerMap<PoliciesState> = {
  policyState: fromPoliciesState.reducer,
  policyNotifications: fromPoliciesNotifications.reducer,
  documentsState: fromDocumentsState.reducer,
  documentsNotifications: fromDocumentsNotifications.reducer,
  summariesState: fromSummariesState.reducer,
  summariesNotification: fromSummariesNotifications.reducer
};

// Lookup the 'Policies' feature state managed by NgRx
const getPoliciesState = createFeatureSelector<PoliciesState>(POLICIES_FEATURE_KEY);

const getLoaded = createSelector(
  getPoliciesState,
  (state: PoliciesState) => !_get(state, 'policyNotifications.loading', true)
);

const getError = createSelector(getPoliciesState, (state: PoliciesState) =>
  _get(state, 'policyNotifications.error')
);

export const getPolicyHasError = createSelector(
  getPoliciesState,
  (state: PoliciesState) => !!_get(state, 'policyNotifications.error')
);

const getAllPolicies = createSelector(
  getPoliciesState,
  getLoaded,
  (state: PoliciesState, isLoaded) =>
    // return [];
    isLoaded ? fromPoliciesState.selectAll(state.policyState) : []
);

const getAllRawPolicies = createSelector(
  getPoliciesState,
  getLoaded,
  (state: PoliciesState, isLoaded) => (isLoaded ? state.policyState.rawPolicies : [])
);

const getSelectedId = createSelector(
  getPoliciesState,
  (state: PoliciesState) => state.policyState.selectedPolicyNumber
);

const getSelectedPolicies = createSelector(getAllPolicies, getSelectedId, (policies = [], id) => {
  const result = policies.find(it => it['id'] === id);
  return result ? Object.assign({}, result) : [];
});

export const getSelectedPolicy = createSelector(
  getAllPolicies,
  getSelectedId,
  (policies, id): Policy => policies.find(it => it['policyNumber'] === id)
  // return result ? Object.assign({}, result) : [];
);

export const getSelectedPolicyNumber = createSelector(
  getSelectedPolicy,
  policy => policy?.policyNumber
);

export const getPni = createSelector(getSelectedPolicy, selectedPolicy => {
  const roles = selectedPolicy?.policyRoles;
  const pni = roles?.find(role => role.roleType.toLowerCase() === 'primarynamedinsured');
  return pni?.firstName + ' ' + pni?.lastName;
});

export const getVehicleDescription = vehicleId =>
  createSelector(getSelectedPolicy, selectedPolicy => {
    const vehicle: Vehicle = selectedPolicy?.policyRisks?.find(
      risk => (<Vehicle>risk).vehicleId === vehicleId
    ) as Vehicle;
    return vehicle?.description;
  });

export const getSelectedPolicyById = createSelector(
  getAllPolicies,
  (policies, id: string) => policies.find(it => it['policyNumber'] === id)
  // return result ? Object.assign({}, result) : [];
);

export const getPolicyEntities = createSelector(
  getPoliciesState,
  (state: PoliciesState) => state.policyState.entities
);

const getPolicyIds = createSelector(getPoliciesState, getLoaded, (state: PoliciesState, isLoaded) =>
  isLoaded ? fromPoliciesState.selectIds(state.policyState) : []
);

export const getLatestPolicyError = createSelector(getPoliciesState, state =>
  _get(state, 'policyNotifications.error')
);

export const getPoliciesLoading = createSelector(
  getPoliciesState,
  (state: PoliciesState) => <boolean>!!_get(state, 'policyNotifications.loading')
);

export const getPoliciesLoaded = createSelector(
  getPoliciesState,
  (state: PoliciesState) => <boolean>!_get(state, 'policyNotifications.loading')
);

export const getPolicies = createSelector(getAllPolicies, (policies = []) =>
  policies.filter(
    policy =>
      policy.policyStatus !== PolicyStatus.Cancelled && policy.policyStatus !== PolicyStatus.Other
  )
);

export const getInactivePolicies = createSelector(getAllPolicies, (policies = []) =>
  policies.filter(
    policy =>
      policy.policyStatus === PolicyStatus.Cancelled || policy.policyStatus === PolicyStatus.Other
  )
);

export const getNumberOfPolicies = createSelector(getPolicies, policies => {
  if (!policies || !policies.length) {
    return 0;
  } else {
    return policies.length;
  }
});
export const getActivePolicies = createSelector(getPolicies, policies =>
  policies.filter(policy => policy.policyStatus === PolicyStatus.Inforce)
);
export const getAdvancePolicies = createSelector(getPolicies, policies =>
  policies.filter(policy => policy.sourceSystem === SourceSystemType.Advance)
);

export const getClassicPolicies = createSelector(getPolicies, policies =>
  policies.filter(policy => policy.sourceSystem === SourceSystemType.Legacy)
);

export const hasAdvancePolicies = createSelector(
  getAdvancePolicies,
  advancePolicies => advancePolicies && advancePolicies.length > 0
);

export const hasClassicPolicies = createSelector(
  getPolicies,
  policies => !!policies.filter(policy => policy.sourceSystem === SourceSystemType.Legacy)
);

export const getVehiclePolicies = createSelector(getPolicies, policies =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Auto)
    .map(policy => <AutoPolicy>policy)
);

export const getAutoPolicies = createSelector(getVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.AUTO)
    .map(policy => <AutoPolicy>policy)
);

export const getCyclePolicies = createSelector(getVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.CYCLE)
    .map(policy => <AutoPolicy>policy)
);

export const getRvPolicies = createSelector(getVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.RV)
    .map(policy => <AutoPolicy>policy)
);

export const getWatercraftPolicies = createSelector(getVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.WATERCRAFT)
    .map(policy => <AutoPolicy>policy)
);

export const getAdvanceAutoPolicies = createSelector(getVehiclePolicies, policies =>
  policies.filter(policy => policy.sourceSystem === SourceSystemType.Advance)
);

export const getPropertyPolicies = createSelector(getPolicies, policies =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Home)
    .map(policy => <PropertyPolicy>policy)
);

export const getUmbrellaPolicies = createSelector(getPolicies, policies =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Umbrella)
    .map(policy => <UmbrellaPolicy>policy)
);

export const getLifePolicies = createSelector(getPolicies, policies =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Life)
    .map(policy => <LifePolicy>policy)
);

export const getAutoPolicyRisks = createSelector(getVehiclePolicies, autoPolicies =>
  _flatten(
    autoPolicies.map(policy => policy.vehicles.map(vehicle => new RiskModel(vehicle, policy)))
  )
);

// Filter any vehicles that don't have a year/make/model - this can happen for non-owned vehicle policy holders
export const getFilteredAutoPolicyRisks = createSelector(getAutoPolicyRisks, autoPolicyRisks =>
  autoPolicyRisks.filter(
    risk =>
      _get(risk, 'year', '') !== '' &&
      _get(risk, 'make', '') !== '' &&
      _get(risk, 'model', '') !== ''
  )
);

export const getAdvanceAutoPolicyRisks = createSelector(
  getAdvanceAutoPolicies,
  advanceAutoPolicies =>
    _flatten(
      advanceAutoPolicies.map(policy =>
        policy.vehicles.map(vehicle => new RiskModel(vehicle, policy))
      )
    )
);

export const getAdvanceAutoPolicyRisksById = createSelector(
  getAdvanceAutoPolicies,
  (advanceAutoPolicies, vehicleId) => {
    const riskArr: RiskModel[] = _flatten(
      advanceAutoPolicies.map(policy =>
        policy.vehicles.map(vehicle => new RiskModel(vehicle, policy))
      )
    );
    return riskArr.find(risk => risk.vehicleId === vehicleId);
  }
);
export const getAutoPolicyById = vehicleId =>
  createSelector(getAutoPolicies, autoPolicies => {
    const riskArr: RiskModel[] = _flatten(
      autoPolicies.map(policy => policy.vehicles.map(vehicle => new RiskModel(vehicle, policy)))
    );
    return riskArr.find(risk => risk.vehicleId === vehicleId);
  });

export const getPropertyPolicyRisks = createSelector(getPropertyPolicies, propertyPolicies =>
  propertyPolicies.map(policy => new RiskModel(policy.riskLocationAddress, policy))
);

export const getUmbrellaPolicyRisks = createSelector(getUmbrellaPolicies, umbrellaPolicies =>
  umbrellaPolicies.map(umbrella => new RiskModel(null, umbrella))
);

export const getLifePolicyRisks = createSelector(getLifePolicies, lifePolicies =>
  lifePolicies.map(life => new RiskModel(life.policyType, life))
);

export const getPolicyRisks = createSelector(
  getFilteredAutoPolicyRisks,
  getPropertyPolicyRisks,
  getUmbrellaPolicyRisks,
  getLifePolicyRisks,
  (autoRisks, propRisks, umbRisks, lifeRisks) =>
    new Array<RiskModel>()
      .concat(autoRisks, propRisks, umbRisks, lifeRisks)
      .sort(RiskCompare.compare)
);

export const getfilteredBillingPolicyRiskList = createSelector(
  getPolicyRisks,
  (policyRisks, billaccount) =>
    policyRisks.filter(
      (policyRisk: RiskModel) =>
        policyRisk.policy.billingAccountNumber === billaccount.billAccountNumber
    )
);

export const getClaimablePolicyRisks = createSelector(getPolicyRisks, risks =>
  risks
    .filter(
      risk =>
        risk.policy.policyStatus === PolicyStatus.Inforce &&
        (risk.generalizedProductType === GenericProductType.Auto ||
          risk.generalizedProductType === GenericProductType.Home)
    )
    .sort(RiskCompare.compare)
);

export const getPhotoUploadRisks = createSelector(getPolicyRisks, risks =>
  risks
    .filter(
      risk =>
        (risk.policy.policyStatus === PolicyStatus.Inforce || PolicyStatus.Scheduled) &&
        (risk.generalizedProductType === GenericProductType.Auto ||
          risk.generalizedProductType === GenericProductType.Home)
    )
    .sort(RiskCompare.compare)
);

// Advance auto policies and risks are required to check kyd status, eligibility
export const getAdvanceAutoPoliciesAndRisks = createSelector(
  getAdvanceAutoPolicies,
  getAdvanceAutoPolicyRisks,
  (advanceAutpolicies, advanceAutoPolicyrisks) => ({
    advanceAutpolicies: advanceAutpolicies,
    advanceAutoPolicyrisks: advanceAutoPolicyrisks
  })
);

export const isEligibleForPaperless = createSelector(getAllPolicies, (allPolicies = []) => {
  // not eligible for paperless if the customer only has a life policy
  const nonLifePolicies = allPolicies.filter(policy => !(policy instanceof LifePolicy));
  return nonLifePolicies.length > 0;
});

// filter the property policies based on TPI
export const getPropertyPoliciesWithTPI = createSelector(getPropertyPolicies, policies =>
  policies
    .filter(policy => policy.policyRoles.some(policyRole => policyRole.roleType === 'BillTo'))
    .map(policy => policy)
);

export const getRolesforAdvanceAutoPolicy = createSelector(getAdvanceAutoPolicies, policies =>
  policies.map(p => ({
    policyNumber: p.policyNumber,
    roles: p.policyRoles.map(role => ({
      type: role.roleType,
      firstName: role.firstName,
      lastName: role.lastName,
      customerId: role.customerId
    }))
  }))
);

// Generate slimmed down risk models using summary data supplemented with full policy risk data if available
export const getClaimableRiskModels = createSelector(getClaimablePolicyRisks, claimableRisks => {
  claimableRisks.map(risk => {
    if (risk.generalizedProductType === GenericProductType.Auto) {
      return {
        id: risk.id,
        policyNumber: risk.policyNumber,
        periodStartDate: risk.periodStartDate,
        generalizedProductType: risk.generalizedProductType,
        iconType: risk.iconType,
        description: risk.description,
        vin: risk.vin,
        year: risk.year,
        make: risk.make,
        model: risk.model,
        vehicleTypeName: risk.vehicleTypeName,
        address: risk.address
      };
    } else {
      return {
        id: risk.id,
        policyNumber: risk.policyNumber,
        periodStartDate: risk.periodStartDate,
        generalizedProductType: risk.generalizedProductType,
        iconType: risk.iconType,
        description: risk.description,
        address: risk.address
      };
    }
  });
  return claimableRisks.sort(RiskCompare.compare);
});

export const hasMultipleAdvanceAutoPolicies = createSelector(
  getAutoPolicies,
  (policies = []) =>
    policies.filter(policy => policy.sourceSystem === SourceSystemType.Advance).length > 1
);

export const hasAdvanceAutoPolicy = createSelector(getAutoPolicies, (policies = []) =>
  policies.some(policy => policy.sourceSystem === SourceSystemType.Advance)
);

export const getVehicleRisks = createSelector(
  getAutoPolicyRisks,
  getPolicySummaries,
  (risks, policySummaries) => {
    const rpvRisks: Array<RiskModel> = new Array<RiskModel>();
    risks.forEach(risk => {
      policySummaries.forEach(policy => {
        if (_get(risk, 'policyNumber', '') === _get(policy, 'policyNumber', '')) {
          risk = Object.assign({}, risk, { contractState: policy.contractState });
          rpvRisks.push(risk);
        }
      });
    });
    return rpvRisks;
  }
);

export const getActiveVehiclePolicies = createSelector(getPolicies, policies =>
  policies
    .filter(
      policy =>
        policy.generalizedProductType === GenericProductType.Auto &&
        policy.policyStatus === PolicyStatus.Inforce
    )
    .map(policy => <AutoPolicy>policy)
);

export const getActiveAutoPolicyRisks = createSelector(getActiveVehiclePolicies, autoPolicies =>
  _flatten(
    autoPolicies.map(policy => policy.vehicles.map(vehicle => new RiskModel(vehicle, policy)))
  )
);

export const hasAnyPolicyComprehensiveCoverage = createSelector(
  getActiveAutoPolicyRisks,
  policyRisks => {
    let vehicleCoverages = [];
    vehicleCoverages = _flatMap(policyRisks, policyRisk => policyRisk.vehicleCoverages);
    return (
      !!vehicleCoverages.find(coverage => coverage.code === 'PAComprehensiveCov') &&
      !vehicleCoverages.find(coverage => coverage.code === 'PACollisionCov')
    );
  }
);

export const hasAnyPolicyLiabilityCoverage = createSelector(
  getActiveAutoPolicyRisks,
  policyRisks => {
    let vehicleCoverages = [];
    vehicleCoverages = _flatMap(policyRisks, policyRisk => policyRisk.vehicleCoverages);
    return !!vehicleCoverages.find(coverage => coverage.code === 'PAPropDamageCov_af');
  }
);

export const policiesQuery = {
  getPropertyPoliciesWithTPI,
  getLifePolicyRisks,
  getUmbrellaPolicyRisks,
  getPropertyPolicyRisks,
  getAdvanceAutoPolicyRisks,
  getFilteredAutoPolicyRisks,
  getAutoPolicyRisks,
  getLifePolicies,
  getUmbrellaPolicies,
  getPropertyPolicies,
  getAdvanceAutoPolicies,
  getWatercraftPolicies,
  getRvPolicies,
  getCyclePolicies,
  getAutoPolicies,
  getVehiclePolicies,
  hasClassicPolicies,
  hasAdvancePolicies,
  hasAnyPolicyComprehensiveCoverage,
  getClassicPolicies,
  getAdvancePolicies,
  getActivePolicies,
  getNumberOfPolicies,
  getPolicies,
  getPoliciesLoaded,
  getPoliciesLoading,
  getLatestPolicyError,
  getPolicyIds,
  getPolicyEntities,
  getSelectedPolicy,
  getSelectedPolicies,
  getSelectedId,
  getAllPolicies,
  getAllRawPolicies,
  getPolicyHasError,
  getError,
  getLoaded,
  getPoliciesState,
  getClaimableRiskModels,
  getRolesforAdvanceAutoPolicy,
  hasAdvanceAutoPolicy,
  hasMultipleAdvanceAutoPolicies,
  getVehicleRisks,
  getInactivePolicies
};

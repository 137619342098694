export interface AppBootstrap {
  bannerContent: string;
  showBanner: boolean;
  appStarted: boolean;
}

export const initialAppBootstrap = {
  bannerContent: '',
  showBanner: false,
  appStarted: false
};

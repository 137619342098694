import { Action } from '@ngrx/store';

export enum SummariesActionTypes {
  LoadSummaries = '[Summaries] Load Summaries',
  SummariesLoaded = '[Summaries] Summaries Loaded',
  SummariesLoadSuccess = '[Summaries] Summaries Load Success',
  SummariesLoadError = '[Summaries] Summaries Load Error',
  SummariesLoadStatusNotFound = '[Summaries] Load Status Not Found',
  UpdatePoiLink = '[Summaries Load CompleteEffect] Update Poi Link in Opertor Policy'
}

export class LoadSummaries implements Action {
  readonly type = SummariesActionTypes.LoadSummaries;
  constructor(public payload?: any) {}
}

export class SummariesLoadSuccess implements Action {
  readonly type = SummariesActionTypes.SummariesLoadSuccess;
  constructor(public payload: any) {}
}

export class SummariesLoadError implements Action {
  readonly type = SummariesActionTypes.SummariesLoadError;
  constructor(public payload: any) {}
}

export class SummariesLoaded implements Action {
  readonly type = SummariesActionTypes.SummariesLoaded;
  // TODO: AS: Type this later
  constructor(public payload: any) {}
}

export class SummariesStatusNotFoundAction implements Action {
  readonly type = SummariesActionTypes.SummariesLoadStatusNotFound;
  constructor(public payload?: { status: any }) {}
}

export class UpdatePoiLink implements Action {
  readonly type = SummariesActionTypes.UpdatePoiLink;
  constructor(public payload: any) {}
}

export type SummariesAction =
  | LoadSummaries
  | SummariesLoaded
  | SummariesLoadSuccess
  | SummariesLoadError
  | SummariesStatusNotFoundAction
  | UpdatePoiLink;

export const fromSummariesActions = {
  LoadSummaries,
  SummariesLoaded,
  SummariesLoadSuccess,
  SummariesLoadError,
  SummariesStatusNotFoundAction,
  UpdatePoiLink
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PartnerTermsData } from '../terms.model';

@Component({
  selector: 'ds-policy-terms',
  templateUrl: './policy-terms.component.html',
  styleUrls: ['./policy-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyTermsComponent {
  @Input() partnerData: PartnerTermsData;

  constructor() {}
}

import * as vehicleActions from './vehicle.actions';

import { VechiclePolicyChangeEntryPoint, VehicleSelectCriteria } from '../models/vehicle.models';

import { TPIIndicator } from '@amfam/policy/vehicle/data-access';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VehicleState } from './vehicle.reducer';
import { vehicleQuery } from './vehicle.selectors';

@Injectable({ providedIn: 'root' })
export class VehicleFacade {
  constructor(private store: Store<VehicleState>) {}

  makes$ = this.store.select(vehicleQuery.getMakes);
  models$ = this.store.select(vehicleQuery.getModels);
  series$ = this.store.select(vehicleQuery.getStyles);
  years$ = this.store.select(vehicleQuery.getYears);
  isLoading$ = this.store.select(vehicleQuery.getLoading);
  criteria$ = this.store.select(vehicleQuery.getCriteria);
  newVehicle$ = this.store.select(vehicleQuery.getNewVehicle);
  newRiskDescription$ = this.store.select(vehicleQuery.getNewRiskDescription);
  error$ = this.store.select(vehicleQuery.hasError);
  status$ = this.store.select(vehicleQuery.getStatus);
  vehiclePolicyChangeEntryPoint$ = this.store.select(vehicleQuery.getVehiclePolicyChangeEntryPoint);
  vehicleFeatures$ = this.store.select(vehicleQuery.getVehicleFeatures);

  loadMakes(year: string) {
    this.store.dispatch(new vehicleActions.GetMakes({ year }));
  }

  loadModels(year: string, make: string) {
    this.store.dispatch(new vehicleActions.GetModels({ year, make }));
  }

  loadSeries(year: string, make: string, model: string) {
    this.store.dispatch(new vehicleActions.GetStyles({ year, make, model }));
  }

  lookupVinYear(year: string, vin: string) {
    this.store.dispatch(new vehicleActions.LookupVinYear({ year, vin }));
  }

  reset() {
    this.store.dispatch(new vehicleActions.Reset());
  }

  setCriteria(criteriaOptions: VehicleSelectCriteria) {
    this.store.dispatch(new vehicleActions.SetCriteria(criteriaOptions));
  }

  setTpi(tpiName: string, copyTpiIndicator: TPIIndicator) {
    this.store.dispatch(new vehicleActions.SetTpi({ tpiName, copyTpiIndicator }));
  }

  SetVehiclePolicyChangeEntryPoint(entryPoint: VechiclePolicyChangeEntryPoint) {
    this.store.dispatch(new vehicleActions.SetVehiclePolicyChangeEntryPoint({ entryPoint }));
  }

  addNewVehicle(year: string, make?: string, model?: string, series?: string, vin?: string) {
    let newVehicle: VehicleSelectCriteria = {
      year
    };
    if (make) newVehicle = { ...newVehicle, make };
    if (model) newVehicle = { ...newVehicle, model };
    if (series) newVehicle = { ...newVehicle, series };

    if (vin) newVehicle = { ...newVehicle, vin };

    this.store.dispatch(new vehicleActions.SetCriteria(newVehicle));
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DocumentPipeService } from '../services/document-pipe.service';

@Pipe({
  name: 'document'
})
export class DocumentPipe implements PipeTransform {
  constructor(private documentPipeService: DocumentPipeService) {}

  transform(url): Observable<SafeUrl> {
    return this.documentPipeService.retrieveDocument(url);
  }
}

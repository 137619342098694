<div class="main row" *ngIf="dataLoading$ | async; else loaded">
  Loading..
</div>

<ng-template #loaded>
  <div class="main row" data-cy="sitemap">
    <div class="column twelve">
      <ds-shared-sitemap [linkListObj]="linkList"></ds-shared-sitemap>
    </div>
  </div>
</ng-template>

export interface PaymentRecordRequest {
  userID: string;
  operation: PaymentRecordActionType;
}

export interface PaymentRecordResponse {
  interactions: string[];
}

export enum PaymentRecordActionType {
  PAUSE = 0,
  RESUME = 1
}

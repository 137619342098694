import { map, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as billAccountCanRegister from './bill-account-can-register.actions';
import { BillAccountsService } from '@amfam/billing/billaccount/data-access';
@Injectable()
export class BillAccountCanRegisterEffects {
  
  canRegister$ = createEffect(() => this.action$.pipe(
    ofType(billAccountCanRegister.CAN_REGISTER),
    map((action: billAccountCanRegister.CanRegisterAction) => action.payload),
    switchMap(payload =>
      this.billingService.canRegister(payload.billAccountNumber, payload.policyNumber).pipe(
        map((canRegisterReponse: any) => {
          return new billAccountCanRegister.CanRegisterSuccessAction(canRegisterReponse);
        }),
        catchError(error => of(new billAccountCanRegister.CanRegisterFailAction(error)))
      )
    )
  ));

  constructor(private action$: Actions, private billingService: BillAccountsService) {}
}

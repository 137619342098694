export class Discount {
  public code: string;
  public name: string;
  public description: string;
  public amount: string;
  public value: string;
  public valueType: string;
  public vehicleId?: string;

  static fromJson(json: any): Discount {
    const discount = new Discount();
    discount.code = json.code;
    discount.name = json.name;
    discount.description = json.description;
    discount.amount = json.amount;
    discount.value = json.value;
    discount.valueType = json.valueType;

    return discount;
  }
}

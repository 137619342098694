import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { CircleProgressOptionsInterface } from '../../models/ds-progress-indicator-circle.model';

import { merge as _merge } from 'lodash';

@Component({
  selector: 'ds-progress-indicator-circle',
  templateUrl: './ds-progress-indicator-circle.component.html',
  styleUrls: ['./ds-progress-indicator-circle.component.scss']
})
export class DsProgressIndicatorCircleComponent implements OnInit, OnChanges {
  private initialTemplateOptions: CircleProgressOptionsInterface = {};

  @Input() percent: number;
  @Input() title = 'complete'; // Displays after the percentage when hovering over the svg (e.g., 90% complete)
  @Input() templateOptions: CircleProgressOptionsInterface = {};

  svg: any;
  _lastPercent = 0;

  private _timerSubscription: Subscription;

  private applyOptions = () => {
    this.templateOptions.percent =
      +this.templateOptions.percent > 0 ? +this.templateOptions.percent : 0;
    this.templateOptions = _merge({}, this.initialTemplateOptions, this.templateOptions);
  };

  private min = (a, b) => {
    return a < b ? a : b;
  };

  constructor() {}

  ngOnInit() {
    // Set defaults
    const defaults = {
      percent: 0,
      radius: 15,
      strokeWidth: 5
    };

    // _merge only replaces undefined this.templateOptions attributes with defaults
    this.templateOptions = _merge({}, defaults, this.templateOptions);

    // Preserving readonly config for reference
    this.initialTemplateOptions = Object.assign({}, this.templateOptions);

    this.render();
  }

  ngOnChanges() {
    this.render();
  }

  render = () => {
    this.applyOptions();
    let percent = this.templateOptions.percent;
    if (this.percent > 0) {
      percent = this.percent;
    }
    this.animate(this._lastPercent, percent);
    this._lastPercent = percent;
  };

  polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadius = (angleInDegrees * Math.PI) / 180;
    const x = centerX + Math.sin(angleInRadius) * radius;
    const y = centerY - Math.cos(angleInRadius) * radius;
    return { x: x, y: y };
  };

  draw = (percent: number) => {
    const radius = this.templateOptions.radius;
    const strokeWidth = this.templateOptions.strokeWidth;

    // make percent reasonable
    percent = percent === undefined ? this.templateOptions.percent : Math.abs(percent);
    // circle percent shouldn't be greater than 100%.
    const circlePercent = percent > 100 ? 100 : percent;
    // determine box size
    const boxSize = radius * 2 + strokeWidth * 2;
    // the center of the circle
    const center = { x: boxSize / 2, y: boxSize / 2 };
    // the start point of the arc
    const startPoint = { x: center.x, y: center.y - radius };
    // get the end point of the arc
    const endPoint = this.polarToCartesian(center.x, center.y, radius, (360 * circlePercent) / 100);
    // We'll get an end point with the same [x, y] as the start point when percent is 100%, so move x a little bit.
    if (circlePercent === 100) {
      endPoint.x = endPoint.x + -0.5;
    }
    // largeArcFlag and sweepFlag
    let largeArcFlag, sweepFlag;
    if (circlePercent > 50) {
      [largeArcFlag, sweepFlag] = [1, 1];
    } else {
      [largeArcFlag, sweepFlag] = [0, 1];
    }

    // Build out svg properties
    this.svg = {
      viewBox: `0 0 ${boxSize} ${boxSize}`,
      width: boxSize,
      height: boxSize,
      path: {
        // A rx ry x-axis-rotation large-arc-flag sweep-flag x y (https://developer.mozilla.org/en/docs/Web/SVG/Tutorial/Paths#Arcs)
        d: `M ${startPoint.x} ${startPoint.y}
            A ${radius} ${radius}
            0 ${largeArcFlag} ${sweepFlag} ${endPoint.x} ${endPoint.y}`,
        strokeWidth: strokeWidth
      },
      circle: {
        cx: center.x,
        cy: center.y,
        r: radius,
        strokeWidth: strokeWidth
      }
    };
  };

  getAnimationParameters = (previousPercent: number, currentPercent: number) => {
    const MIN_INTERVAL = 10;
    let times, step, interval;
    const fromPercent = previousPercent < 0 ? 0 : previousPercent;
    const toPercent = currentPercent < 0 ? 0 : this.min(currentPercent, 100);
    const delta = Math.abs(Math.round(toPercent - fromPercent));

    if (delta >= 100) {
      // we will finish animation in 100 times
      times = 100;
      step = 1;
    } else {
      // we will finish in as many times as the number of percent.
      times = delta;
      step = 1;
    }
    // Get the interval of timer
    interval = Math.round(1000 / times);
    // Readjust all values if the interval of timer is extremely small.
    if (interval < MIN_INTERVAL) {
      interval = MIN_INTERVAL;
      times = 1000 / interval;
      step = Math.round(100 / times);
    }
    // step must be greater than 0.
    if (step < 1) {
      step = 1;
    }
    return { times: times, step: step, interval: interval };
  };

  animate = (previousPercent: number, currentPercent: number) => {
    if (this._timerSubscription && !this._timerSubscription.closed) {
      this._timerSubscription.unsubscribe();
    }
    const fromPercent = previousPercent;
    const toPercent = currentPercent;
    const { step: step, interval: interval } = this.getAnimationParameters(fromPercent, toPercent);
    let count = fromPercent;
    if (fromPercent < toPercent) {
      this._timerSubscription = timer(0, interval).subscribe(() => {
        count += step;
        if (count <= toPercent) {
          this.draw(toPercent);
          this._timerSubscription.unsubscribe();
        } else {
          this.draw(toPercent);
          this._timerSubscription.unsubscribe();
        }
      });
    } else {
      this._timerSubscription = timer(0, interval).subscribe(() => {
        count -= step;
        if (count >= toPercent) {
          this.draw(toPercent);
          this._timerSubscription.unsubscribe();
        } else {
          this.draw(toPercent);
          this._timerSubscription.unsubscribe();
        }
      });
    }
  };
}

import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { format } from 'date-fns';

@Component({
  selector: 'last-login',
  templateUrl: './last-login.component.html',
  styleUrls: ['./last-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastLoginComponent implements OnInit {
  public lastLoginFormatted = '';
  @Input() lastLogin: string;

  constructor() {}

  ngOnInit() {
    // Check if the last login feature is enabled
    this.lastLoginFormatted = format(this.lastLogin, 'MMMM D, YYYY [at] h:mm aa');
  }
}

// TODO: @jjc - move this to a shared space, update docs and replace with shared model in sitemap
export interface NotificationObj {
  linkText?: string;
  policyNumber?: string;
  linkRoute?: string | [string, string];
  queryParams?: string | {};
  ids?: [];
  iconClasses?: string;
  linkTestId?: string;
  mobileLinkTestId?: string;
  ariaLabel?: string;
  clickFunction?: any;
  dismissClickFunction?: any;
  width?: number; // Added to restrict width of link container
  messagesArray?: NotificationObj[];
}

export interface NotificationMessagesObj {
  closeClickFunction?: string;
  closeTestId?: string;
}

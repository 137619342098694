<div class="banner-border neutral-600-border pad-20">
  <button
    *ngIf="isAdminApp"
    ds-button
    class="icon-exit -white float-right"
    (click)="cancelClick.emit()"
    aria-label="Close"
  ></button>
  <div class="row flex items-center">
    <img
      *ngIf="bannerConfig?.iconPath"
      src="{{ bannerConfig.iconPath }}"
      class="relief-icon margin-right-20"
    />
    <div>
      <div
        *ngIf="bannerConfig?.headingTextPath"
        [ngClass]="[isDuplicateEmail ? '' : 'font-weight-bold']"
      >
        <span *ngIf="showIcon" class="icon-alert duplicateEmail margin-right-30"></span>
        <span
          [ngClass]="{ 'header-content': phaseTwoEnabled }"
          [ngClass]="[
            isDuplicateEmail ? 'header-duplicateEmailContent' : '',
            phaseTwoEnabled ? 'header-content' : ''
          ]"
          content
          [contentPath]="bannerConfig.headingTextPath"
        ></span>
      </div>
      <div *ngIf="bannerConfig?.subText">
        {{ bannerConfig.subText }}
      </div>
    </div>
    <span class="grow"></span>
    <button
      ds-button
      *ngIf="bannerConfig?.buttonText"
      class="button -outline-white margin-left-10"
      [ngClass]="{
        buttonv2: phaseTwoEnabled
      }"
      [attr.aria-label]="bannerConfig.buttonText"
      (click)="this.buttonClick.emit()"
    >
      {{ bannerConfig.buttonText }}
    </button>
    <button
      *ngIf="phaseTwoEnabled"
      ds-button
      class="icon-exit -white"
      (click)="cancelClick.emit()"
      aria-label="Close"
    ></button>
  </div>
</div>

<div class="opportunities-cta">
  <div class="row flex">
    <div *ngIf="ctaData?.ctaIconPath">
      <img alt="Insurance Policy Image" [src]="ctaData?.ctaIconPath" />
    </div>

    <div class="row margin-left-30">
      <div class="column twelve">
        <div class="opportunities-cta-title margin-bottom-0">{{ ctaData?.title }}</div>
        <div *ngFor="let item of ctaData?.body" class="margin-bottom-10" [innerHTML]="item"></div>
      </div>
      <div *ngIf="showCTA" class="row margin-top-20">
        <div
          class="column twelve md-six md-pad-right-10 md-pad-top-20"
          *ngFor="let button of ctaData?.buttons"
        >
          <button
            ds-button
            class="{{ button.buttonType }}"
            [disabled]="
              button.buttonText.toLowerCase() === opportunityButtonAction.contactMe
                ? showContactMe
                : !ctaData?.enabled
            "
            (click)="ctaClicked(button.buttonText)"
            data-cy="detailctaBtn"
          >
            {{ button.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService, PageAnalytic, UtilService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../../core/store/';
import * as enrollUserActions from '../../../../core/store/enrollment';
import { IdentificationModel } from '../../../../core/store/enrollment/personal/identification';
import { PartyModel } from '../../../../core/store/enrollment/personal/party';
import * as enrollStepsActions from '../../../../core/store/enrollment/steps';
import * as workflowActions from '../../../../core/store/workflow';
import { stepNames } from '../../../../core/store/workflow/workflow.model';
import { VerificationResponse } from '../../../verification-response';
import { IdentificationService } from '../../identification/identification.service';
import { VerificationService } from '../verification.service';

@Component({
  selector: 'ds-enroll-verify-policy-num',
  templateUrl: './enroll-verify-policy-num.component.html',
  styleUrls: ['../../../enrollment.component.scss']
})
export class EnrollVerifyPolicyNumComponent implements OnInit, OnDestroy {
  verifyResponse: VerificationResponse;
  policyNumNotFoundMessage: string;
  multipleMatch = false;
  responseReceived = true;
  partyMatch: number;
  partyId: string;
  policyNum: string;

  private componentName: any = {};
  private componentData: any;
  private identificationModel: IdentificationModel;
  private typeOfAccountCode: string;

  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic;
  private conversionPageAnalytic: PageAnalytic;
  // **** End of Analytics data for this component
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private copyService: CopyService,
    private store: Store<fromRoot.RootState>,
    private verifyBy: VerificationService,
    private idSvc: IdentificationService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.pageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:PolicyNbr',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity:PolicyNbr'
    };
    this.conversionPageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:PolicyNbr',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Add Personal Line',
      subCategory2: '',
      subCategory3: ''
    };
    this.componentName.enrollment = 'enrollment';
    this.componentData = this.copyService.getComponentData(this.componentName.enrollment);

    combineLatest(
      this.store.select(fromRoot.getEnrollUserState),
      this.store.select(userQuery.getTypeOfAccountCode),
      this.store.select(BrandSelectors.getPartnerId),
      (enrollUserState, typeOfAccountCode, partnerId) => ({
        enrollUserState: enrollUserState,
        typeOfAccountCode: typeOfAccountCode,
        partnerId: partnerId
      })
    )
      .pipe(take(1))
      .subscribe(state => {
        this.identificationModel = {
          lastName: state.enrollUserState.lastName,
          zipcode: state.enrollUserState.zipcode,
          dob: state.enrollUserState.dob,
          partnerId: state.partnerId
        };

        this.partyMatch = state.enrollUserState.match;
        this.partyId = state.enrollUserState.partyId;
        this.typeOfAccountCode = state.typeOfAccountCode;
        // Analytics for conversion
        if (this.typeOfAccountCode && this.typeOfAccountCode !== AccountTypeEnum.Customer) {
          this.analyticsFacade.trackPage(this.conversionPageAnalytic);
        } else {
          this.analyticsFacade.trackPage(this.pageAnalytic);
        }
        this.checkMultipleMatch();
      });
  }

  checkMultipleMatch() {
    if (this.partyMatch > 1) {
      this.multipleMatch = true;
    }
  }

  goBackOneStep() {
    if (this.multipleMatch) {
      this.store.dispatch(
        new workflowActions.WorkflowStepBackAction({
          alternateRoute: 'identification'
        })
      );
    } else {
      this.store.dispatch(new workflowActions.WorkflowStepBackAction());
    }
  }

  resetErrorMessages() {
    this.policyNumNotFoundMessage = null;
  }

  preparePolicyNum(policyNum: string) {
    this.resetErrorMessages();
    this.responseReceived = false;
    this.spinner.start({ blockActions: true });

    // Strip all non-alphanumeric digits
    let preparedPolicyNum: string = policyNum.replace(/[^a-zA-Z0-9]/g, '');
    preparedPolicyNum = this.utilService.extractPolicyNumber(preparedPolicyNum);
    this.verifyPolicyNum(preparedPolicyNum);
  }

  verifyPolicyNum(policyNumber: string) {
    this.verifyBy
      .policyNumber(policyNumber.toUpperCase(), this.identificationModel)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        response => {
          this.handleResponse(policyNumber, response);
        },
        err => {
          this.handleError(err);
        }
      );
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private handleResponse(policyNumber: string, response: any) {
    this.spinner.stop();
    const statusCode = response.status && response.status.code ? response.status.code : 500;
    if (statusCode === 200) {
      // Success - policy number match
      this.handleSuccess(response, policyNumber);
    } else if (statusCode === 404) {
      // Policy Number not found
      if (policyNumber.length === 12) {
        // If failed policy number is 12 digits, attempt again with first 10 digits
        this.verifyPolicyNum(policyNumber.substr(0, 10));
      } else {
        this.policyNumNotFoundMessage = this.componentData['verifyPolicyNumMismatchText'];
        this.verifyResponse = new VerificationResponse(
          false,
          this.policyNumNotFoundMessage,
          'policyNum'
        );
        this.verifyBy.incrementAttempts();
      }
    } else {
      // Unexpected error response. Route to oops page
      this.store.dispatch(
        new fromRouterActions.Go({
          path: ['/enroll/contact-us']
        })
      );
    }
    this.responseReceived = true;
  }

  private handleSuccess(response: any, policyNumber: string) {
    this.verifyBy.resetVerifyAttempts();
    let partyId = response.resultingParty.partyId;
    if (!partyId || this.partyMatch <= 1) {
      partyId = this.partyId;
    }
    // add search results to the store
    const partyModel: PartyModel = this.idSvc.getPartyModel(response);
    this.store.dispatch(new enrollUserActions.PartyDetailsAction(partyModel));
    this.store.dispatch(
      new enrollUserActions.SubmitVerificationAction({
        verificationType: 'policyNum',
        partyId: partyId,
        policyNumber: policyNumber
      })
    );
    this.store.dispatch(
      new enrollStepsActions.VerifyStepAction({
        verificationStep: true
      })
    );
    // complete the step
    this.store.dispatch(
      new workflowActions.WorkflowStepCompleteAction({ step: stepNames.verification })
    );
  }

  private handleError(err: any) {
    this.spinner.stop();
    this.responseReceived = true;
    this.store.dispatch(
      new fromRouterActions.Go({
        path: ['/enroll/contact-us']
      })
    );
  }
}

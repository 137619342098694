<div class="simple-card" [attr.id]="id">
  <div class="simple-card__header">
    <img [src]="'/assets/images/' + headerIcon" alt="{{ heading }}" />
    <span class="title">{{ heading }}</span>
  </div>
  <div class="simple-card__body">
    <ng-content></ng-content>
  </div>
  <div class="simple-card__footer">
    <a
      (click)="button.clickEvent()"
      *ngFor="let button of buttons"
      class="capitalize action__button"
      [class.icon-gradient]="button.iconClass"
      [ngClass]="button.iconClass || ''"
    >
      <img *ngIf="button.iconUrl" [src]="'/assets/images/' + button.iconUrl" alt="{{ heading }}" />
      {{ button.name }}
    </a>
  </div>
</div>

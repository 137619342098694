import { Injectable } from '@angular/core';
import { Claim } from '@amfam/claim/data-access';
import { Adjuster } from '../models/adjuster.model';

import { get as _get } from 'lodash';

import { CopyService } from '@amfam/shared/utility/shared-services';
import { PhoneNumberSingleStringFormatterPipe } from '@amfam/shared/ui/pipes';
@Injectable({
  providedIn: 'root'
})
export class AdjusterListService {
  constructor(private copyService: CopyService) {}

  public getAdjuster(claim: Claim) {
    let adjuster: Adjuster;
    const c = claim.primaryFileHandler;
    let areaCode = _get(c, 'phones[0].areaCode', '').replace(/\D/g, '');
    let number = _get(c, 'phones[0].number', '').replace(/\D/g, '');
    let extension = _get(c, 'phones[0].extension', '').replace(/\D/g, '');
    if (number.length === 5) {
      // Extension was passed in the number
      extension = number;
      number = new PhoneNumberSingleStringFormatterPipe()
        .transform(this.copyService.get('shared', 'amfamTelNumber'))
        .replace(/\D/g, '');
      areaCode = '';
    }
    if (c) {
      adjuster = {
        name: c.name,
        initials: c.firstName.charAt(0) + c.lastName.charAt(0),
        emailUrl: _get(c, 'emails[0].emailAddress', ''),
        phone: extension ? areaCode + number + 'x' + extension : areaCode + number
      };
    }
    return adjuster;
  }
}

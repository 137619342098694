import { Directive, ElementRef, Renderer2, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[disableCopyPaste]'
})
export class DisableCopyPasteDirective implements OnInit, OnDestroy {
  private pasteListenerFunc: Function;
  private copyListenerFunc: Function;

  constructor(public renderer: Renderer2, public element: ElementRef) {}

  ngOnInit() {
    this.pasteListenerFunc = this.renderer.listen(this.element.nativeElement, 'paste', event => {
      event.preventDefault();
    });
    this.copyListenerFunc = this.renderer.listen(this.element.nativeElement, 'copy', event => {
      event.preventDefault();
    });
  }
  ngOnDestroy() {
    if (this.pasteListenerFunc) {
      this.pasteListenerFunc();
    }
    if (this.copyListenerFunc) {
      this.copyListenerFunc();
    }
  }
}

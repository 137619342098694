export interface BillingCopy {
  billing: string;
  billingAndPayments: string;
  viewStatementHistoryHeading: string;
  manageOtherPolicyHeading: string;
  paymentMethodSelectTxt: string;
  paymentMethodNameTxt: string;
  managePaymentsAdvancedCustomerTxt: string;
  statementHistoryHeading: string;
  billingTermsAndCondition: string;
  noBillingAccountsAvailable: string;
  noBillingAccountsAvailablePartners: string;
  accountBalancePayoff: string;
  addBillAccHeading: string;
  addBillAccDescription: string;
  autoPay: string;
  automaticFundsTransferErrorMsg: string;
  billAccountSearchError: string;
  onlineBilledErrorMsg: string;
  policyDescription: string;
  registerAccount: string;
  registerAccountAfter: string;
  registerAccountText: string;
  onDueDateText: string;
  dueDateText: string;
  dueDateAdditionalText: string;
  noReminder: string;
  paymentHeading: string;
  verifyAmount: string;
  enterPaymentAmount: string;
  scheduledPaymentUpdated: string;
  scheduledPaymentDeleted: string;
  needPrintConfirmation: string;
  paymentRemovalInstructions: string;
  payMinDueInstruction: string;
  oldPayMinDueInstruction: string;
  submitPayment: string;
  minDue: string;
  pastDue: string;
  currentDue: string;
  pastPlusCurrent: string;
  unpaidBalance: string;
  remainingBalance: string;
  fullPayBalance: string;
  selectPaymentAmount: string;
  selectPaymentDate: string;
  pastDueNotCanceledBanner1: string;
  pastDueNotCanceledBanner2: string;
  pastDueNotCanceledBanner3: string;
  pastDueCanceledBanner1: string;
  pastDueCanceledBanner2: string;
  selectPaymentDateDisabled: string;
  datepickerDisabledMultiplePastDue: string;
  datepickerDisabledSinglePastDue: string;
  datepickerDisabledMultipleDueToday: string;
  datepickerDisabledSingleDueToday: string;
  datepickerDisabledMultipleFunkyDate: string;
  datepickerDisabledSingleFunkyDate: string;
  datepickerDisabledMultipleNoMinDue: string;
  datepickerDisabledSingleNoMinDue: string;
  selectPaymentDateAndMethod: string;
  paymentConfirmation: string;
  paymentConfirmationDetails: string;
  confirmation: string;
  paymentAmount: string;
  paymentDate: string;
  paymentDateAndMethod: string;
  totalPayment: string;
  accountName: string;
  paymentConfirmationNumber: string;
  deletePaymentMethodScheduleWarn: string;
  cannotDeletePaymentMethodScheduleWarn: string;
  deletePaymentMethodWarn: string;
  paymentDetailsHeading: string[];
  setUpAutoPaymentBtn: string;
  noThanks: string;
  paymentConfirmationMsg: string;
  returnToBilling: string;
  backToBillingAndPayments: string;
  viewFuturePayments: string;
  startPayingNowBtn: string;
  contactReasonsBillingStatements: string;
  contactReasonsPaymentHistory: string;
  contactReasons: string[];
  contactSubReasons: string[];
  contactMode: string[];
  paymentMethods: PaymentMethodCopy[];
  expiredExpiringWarning: string;
  contactBillingPageHeader: string;
  contactBillingHelpTxtPart1Updated: string;
  contactBillingHelpTxtPart2Updated: string;
  contactBillingHelpTxtPart1UpdatedPartner: string;
  contactBillingHelpTxtPart2UpdatedPartner: string;
  contactBillingConfirmationSucess: string;
  backToBilling: string;
  contactPageTitle: string;
  contactBillinErrorMessage: string;
  alreadyPaidModal: string;
  paymentMethodsCondensed: string[];
  contactBillingHelpTxtPart1: string;
  contactBillingHelpTxtPart1FAQLink: string;
  contactBillingHelpTxtPart1Punctuation: string;
  contactBillingHelpTxtPart2: string;
  contactBillingHelpTxtPart2Punctuation: string;
  contactBillingHelpTxtPart3: string;
  contactBillingHelpTxtPart4: string;
  contactBillingHelpTxtPart5: string;
  contactBillingHelpTxtPart4PrivacyLink: string;
  contactBillingNameHelpTxt: string;
  contactBillingContactModeHelpTxt: string;
  contactBillingContactSubReasonHelpTxt: string;
  contactBillingEmailHelpTxt: string;
  contactBillingPhoneHelpTxt: string;
  contactBillingPhoneScheduleHelpTxt: string;
  contactBillingPhoneScheduleDateHelpTxt: string;
  contactBillingReasonHelpTxt: string;
  contactBillingCommentsHelpTxt: string;
  contactBillingFormNoteHelpTxt: string;
  contactBillingFormNoteHelpTxtPartners: string;
  contactBillingFormSubmitHelpTxt: string;
  contactBillingAccountHelpTxt: string;
  noStatementHistory: string;
  noStatementHistorySingleAccount: string;
  noPaymentHistory: string;
  noPaymentHistoryApiError: string;
  paymentHistoryRequestMorePre: string;
  paymentHistoryRequestMoreLink: string;
  paymentHistoryRequestMorePost: string;
  billingStatementRequestMorePre: string;
  billingStatementRequestMoreLink: string;
  billingStatementRequestMorePost: string;
  unregisterBillAccountHeading: string;
  unregisterAssocBillAccountHeading: string;
  unregisterAssocBillAccountMsg: string;
  updateBillAccountPreferencesTitle: string;
  updateBillAccountPreferences: string;
  updateBillAccountPreferencesAutopayPre: string;
  updateBillAccountPreferencesAutopayLink: string;
  updateBillAccountPreferencesAutopayPost: string;
  makePaymentAutopayRedirectPre: string;
  makePaymentAutopayRedirectLink: string;
  makePaymentAutopayRedirectPost: string;
  makePaymentAutopayAMDRedirectPost: string;
  scheduledPaymentsConfirmationHeading: string;
  pastDuePaymentsConfirmationHeading: string;
  pastAndCurrentDuePaymentsConfirmationHeading: string;
  paymentWithAutoPayPaymentsConfirmationHeading: string;
  autoPayWithoutPaymentPaymentsConfirmationHeading: string;
  paymentWithoutAutoPayPaymentsConfirmationHeading: string;
  multipleScheduledPaymentsConfirmationHeading: string;
  yourNextSteps: string;
  nextSteps: string;
  yourNextStepsInstructions: string;
  confirmationDetails: string;
  confirmationDetailsMessage: string;
  addPaymentAcctSuccessfullHeading: string;
  editPaymentAcctSuccessfullHeading: string;
  deletePaymentAcctSuccessfullHeading: string;
  deletePaymentWarning: string;
  addBillAccountHeader: string;
  removeBillAccountHeader: string;
  stopManagingBillAccountHeader: string;
  removeBillAccountMsgOne: string;
  stopManagingBillAccountNextSteps: string;
  nextStepsConfirmationMsg: string;
  nextStepsConfirmationMsgTwo: string;
  cardNumber: string;
  cardType: string;
  schedulePaymentAutoPayLookupMessage: string;
  makeASchedulePaymentBtn: string;
  editScheduledPaymentsConfirmationHeading: string;
  deleteScheduledPaymentsConfirmationHeading: string;
  addPaymentMethodPaymentsConfirmationHeading: string;
  editPaymentMethodPaymentsConfirmationHeading: string;
  deletePaymentMethodPaymentsConfirmationHeading: string;
  noAutopayRulesP1: string;
  noAutopayRulesP2Advance: string;
  noAutopayRulesP2Classic: string;
  allAccountsSetUp: string;
  noScheduledPaymentsP1: string;
  noPaymentMethods: string;
  findBillAcctHeading: string;
  findBillAcctMsg: string;
  inBillingCycleErrMsg: string;
  payElectronically: string;
  stopPayingElectronically: string;
  stopAutoPaySeriesText: string;
  stopAllAutoPaysText: string;
  setAuoPayMsg: string;
  getStatments: string;
  verifyForDiscounts: string;
  unregisterUnassocBillAccountHeading: string;
  unregisterUnassocBillAccountMsg: string;
  policyDescriptionText: string;
  removeAccount: string;
  stopManagingHeading: string;
  stopManagingContent: string;
  stopManagingConfirm: string;
  stopManagingRemoveScheduled: string;
  stopManagingCancelAutomatic: string;
  stopManagingDeleteMethods: string;
  stopManagingUpdatePreferences: string;
  stopManagingSetPaper: string;
  stopManagingButton: string;
  riskDescription: string;
  nameYourBillingAccount: string;
  nameYourBillingAccountText: string;
  updateYourEmail: string;
  updateYourEmailText: string;
  updateYourEmailTextAfter: string;
  umbrella: string;
  billAcctAddConfHeading: string;
  billAcctDelConfHeading: string;
  billAcctAddConfText: string;
  registerInvalidBillAcctErrMsg: string;
  registerAftBillAcctErrMsg: string;
  registerOnlineBillAcctErrMsg: string;
  registerMortgageBillAcctErrMsg: string;
  registersomeOneElseManagingPrefErrMsg: string;
  registercustomerNotIdentifiedErrMsg: string;
  noAutoPayImpersonatingMessage: string;
  detailPageAutoPaySetNameAndReminderMsg: string;
  detailPageAutoPayWarnPre: string;
  detailPageAutoPayWarnLink: string;
  detailPageAutoPayWarnPost: string;
  selectedAccount: string;
  allDone: string;
  returnToBillingAndPaymentsNow: string;
  editAutoPayHelpText: string;
  registeredElsewhere: string;
  enrolledInAft: string;
  stopManagingModalHeader: string;
  stopManagingModalDeleted: string;
  stopManagingModalFailed: string;
  addAutoPayHelpText: string;
  submitAutoPayBtn: string;
  hasSchedulePaymentWarningMsg: string;
  oldHasSchedulePaymentWarningMsg: string;
  getStartedRegistrationDescription: string;
  getStartedRegistrationButton: string;
  getStartedPaymentDescription: string;
  getStartedPaymentButton: string;
  sameDayCreditCardNoModifyWarning: string;
  paymentMayVaryWarning: string;
  sameDayCreditCardNoModify: string;
  datePickerDateNotInRangeError: string;
  validations: PaymentValidationsCopy[];
  amd: AmdCopy;
  errorMsgs: ErrorMsg[];
  defaultBillingErrorMessage: string;
  ineligibleReasonsList: string[];
  fullPayDiscountPrompt: string;
  fullPayDiscountDatePromptPre: string;
  fullPayDiscountDatePromptPost: string;
  fullPayDiscountConfirmation: string;
  makePayment: string;
  setUpAutoPay: string;
  registrationErrorMessage: string;
  cannotSchedule: string;
  setPreferencesError: string;
  requiredPayloadEntityReferences: string[];
  preferencesNotLoaded: string;
  expiredExpiringCCDC: ExpiredExpiringCCDC;
  automaticPaymentPastDueWarning: string;
  advancedCustomerSavingsDiscountText: string;
  advancedAndClassicCustomerSavingsDiscountText: string;
  savingsSelectedCongratulatoryText: string;
  multipartPaymentMessage: string;
  maxBillAccountsSelectedWarning: string;
  maxBillAccountsSelectedWarningSectionPage: string;
  setupMultiAutomaticPaymentSelectionPageHeader: string;
  chooseAutoPayAccountsHeader: string;
  eligibleAccountsInfoSubHeader: string;
  billAccountsSelectionMsgForMultiAutopay: string;
  payMonthlyMessage: string;
  payFullTermMessage: string;
  existingPaymentMessage: string;
  reviewAutoPayHeader: string;
  predictedAmountWarnMessageForAutopay: string;
  addMultiAutoPaySuccessfullNextStepsPre: string;
  multiAutopayFailureMessage: string;
  multiAutopayResubmitMessage: string;
  multiAutopayReviewScreenErrorMessage: string;
  incompleteRequestMsg: string;
  pleaseCallMessage: string;
  tryLaterMessage: string;
  manageAccountsElectronicallyText: string;
  addAccountButton: string;
  somethingWentWrongMessage: string;
  noBillingAccountsMessage: string;
  viewAccountButton: string;
  viewAndPayMultipleLink: string;
  pastDueSeparately: string;
  autoPayRefactor: AutoPayRefactor;
  paymentScheduleTxt: string;
  paymentScheduleTxt2: string;
  noSchedulePayments: string;
  reasonsForFuturePaymentError: string;
  paymentScheduleErrorTxt: string;
  noSchedulePaymentsReasons: string;
  reviewFuturePayments: string;
  termsAndConditions: TermsAndConditions;
  oneTimePayRefactor: OneTimePayRefactor;
  paymentMethodStandAlone: PaymentMethodStandAlone;
}

export interface AutoPayRefactor {
  howOftenDoYouWantToPay: string;
  howOftenDoTheyWantToPay: string;
  whenDoYouWantToPay: string;
  whenDoTheyWantToPay: string;
  chooseAutoPayAccountsHeader: string;
  eligibleAccountsInfoSubHeader: string;
  paymentPreviewFeesDisclaimer: string;
  adminToolPaymentPreviewFeesDisclaimer: string;
  aboutAutoPayDiscountHeader: string;
  loseAutoPayDiscountMessage: string;
  adminToolLoseAutoPayDiscountMessage: string;
  allAtOnceWarningHeader: string;
  adminToolAllAtOnceWarningHeader: string;
  allAtOnceWarningMessage: string;
  adminToolallAtOnceWarningMessage: string;
  cancelModalHeading: string;
  setUpCancelModalBody: string;
  editCancelModalBody: string;
  paymentExpiredMessage: string;
  registrationErrorHeadingText: string;
  registrationErrorBodyText: string;
  registrationErrorModalHeadingText: string;
  registrationErrorModalBodyText: string;
  deleteModalHeadingText: string;
  deleteModalBodyText: string;
  deleteModalBodyTextAutoPayDiscount: string;
  adminToolDeleteModalBodyText: string;
  adminToolDeleteModalBodyTextAutoPayDiscount: string;
  deleteSuccessConfirmationHeadingText: string;
  adminToolDeleteSuccessConfirmationHeadingText: string;
  deleteSuccessConfirmationBodyText: string;
  adminToolDeleteSuccessConfirmationBodyText: string;
  deleteFailureConfirmationHeadingText: string;
  deleteEditFailureConfirmationBodyText: string;
  reviewSettings: string;
  confirmationSuccessHeader: string;
  confirmationFailureHeader: string;
  confirmationSuccessBody: string;
  editConfirmationSuccessHeader: string;
  adminToolEditConfirmationSuccessHeader: string;
  editConfirmationSuccessBody: string;
  adminToolEditConfirmationSuccessBody: string;
  confirmationPartialFailureBodyOne: string;
  confirmationPartialFailureBodyTwo: string;
  confirmationFailureBody: string;
}

export interface TermsAndConditions {
  autoPayBank: string;
  autoPayCard: string;
  autoPayBoth: string;
  scheduledPaymentBank: string;
  scheduledPaymentCard: string;
  scheduledPaymentBoth: string;
}

export interface ExpiredExpiringCCDC {
  expiredExpiringWarning: string;
  expiredExpiringLinkText: string;
  expiredExpiringLinkPost: string;
}

export interface ErrorMsg {
  key: string;
  value: string;
}

export interface OneTimePayRefactor {
  dockingBarHeading: string;
  chooseOneTimePayAccountsHeader: string;
  setUpAutoPaySubHeader: string;
  chooseOneTimePayAccountsSubHeader2: string;
  choosePaymentMethod: string;
  authorizationDisclaimer: string;
  confirmationSuccessHeader1: string;
  confirmationSuccessHeader2: string;
  confirmationSuccessBody: string;
  confirmationFailureHeader: string;
  confirmationFailureBody: string;
  deleteSuccessHeader: string;
  deleteErrorBody: string;
}

export interface PaymentMethodStandAlone {
  dockingBarHeading: string;
  deleteHeading: string;
  autoPayDiscountWarningHeading: string;
  autoPayDiscountWarningBody: string;
}

interface PaymentValidationsCopy {
  key: string;
  value: string;
}

interface AmdCopy {
  noPaymentDue: string;
  autoPayPredictPastDuePaymentMsg: string;
  minToFullAutoPayWarnMsg: string;
  autoPayPredictPaymentErrorMsg: string;
  verifyFirstPayment: string;
  verifyFuturePayments: string;
  autoPayProcessingEffectMsg: string;
  autoPayProcessingAmtMsg: string;
  hasSchedulePaymentWarningMsg: string;
  editOneTimePayment: string;
  accountBalancePaymentCycleMsg: string;
}

interface PaymentMethodCopy {
  name: string;
  value: string;
}

<card [cardClasses]="'profile-card'">
  <div card-header>
    <div class="row">
      <div class="column twelve md-six">
        <h3>Hi, {{ firstName }}</h3>
        <div *ngIf="canShowStatus">
          Status:
          <span class="status">
            {{ !isShellAccount ? 'Active' : 'Shell Account Pending' }}
          </span>
        </div>
      </div>
      <div class="column twelve md-six">
        <h4>
          User ID: <span> {{ isShellAccount ? '' : userID }} </span>
        </h4>
      </div>
    </div>
  </div>
  <div card-content>
    <div class="row">
      <div class="column twelve md-six">
        <h4>My Account Email</h4>
        <p class="primary-email">{{ emailAddress }}</p>
      </div>
      <div class="column twelve md-six">
        <div>
          <h4>Business Name</h4>
          <p class="business-name">{{ businessName }}</p>
        </div>
      </div>
    </div>
  </div>
</card>

import { Action } from '@ngrx/store';
import { PendingRegistration } from '../models/pending-registration.model';

export enum PendingRegistrationsActionTypes {
  PendingRegistrationsAddMany = '[PendingRegistrations] Add Many',
  PendingRegistrationsAddOne = '[PendingRegistrations] Add One',
  PendingRegistrationsRemoveAll = '[PendingRegistrations] Remove All',
  PendingRegistrationsRemoveOne = '[PendingRegistrations] Remove One',

  PendingRegistrationsFail = '[PendingRegistrations] Fail'
}

export class PendingRegistrationsAddMany implements Action {
  readonly type = PendingRegistrationsActionTypes.PendingRegistrationsAddMany;
  constructor(public payload: PendingRegistration[]) {}
}

export class PendingRegistrationsAddOne implements Action {
  readonly type = PendingRegistrationsActionTypes.PendingRegistrationsAddOne;
  constructor(public payload: PendingRegistration) {}
}

export class PendingRegistrationsRemoveAll implements Action {
  readonly type = PendingRegistrationsActionTypes.PendingRegistrationsRemoveAll;
  constructor(public payload?: any) {}
}

export class PendingRegistrationsRemoveOne implements Action {
  readonly type = PendingRegistrationsActionTypes.PendingRegistrationsRemoveOne;
  constructor(public payload: PendingRegistration) {}
}

export class PendingRegistrationsFail implements Action {
  readonly type = PendingRegistrationsActionTypes.PendingRegistrationsFail;
  constructor(public payload: string) {}
}

export type PendingRegistrationsActions =
  | PendingRegistrationsAddMany
  | PendingRegistrationsAddOne
  | PendingRegistrationsRemoveAll
  | PendingRegistrationsRemoveOne
  | PendingRegistrationsFail;

export const fromPendingRegistrationsActions = {
  PendingRegistrationsAddMany,
  PendingRegistrationsAddOne,
  PendingRegistrationsRemoveAll,
  PendingRegistrationsRemoveOne,
  PendingRegistrationsFail
};

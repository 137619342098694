import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ds-text-area',
  templateUrl: './ds-text-area.component.html',
  styleUrls: ['./ds-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DsTextAreaComponent),
      multi: true
    }
  ]
})
export class DsTextAreaComponent implements ControlValueAccessor {

  @Input() parentFormGroup: UntypedFormGroup;
  @Input() control: string;

  onChange: any = () => { };
  onTouched: any = () => { };

  set value(val) {
    this.onChange(val);
    this.onTouched(val);
  }

  writeValue(value: any) {
    this.value = value;
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  constructor() { }


}

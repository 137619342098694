import { Address } from './address';
import { GenericProductType } from './generic-product-type';
import { RiskTypeIconConstants } from './risk-type-icon-constants';

export interface FnolRiskModel {
  generalizedProductType: GenericProductType;
  policyNumber: string;
  id: string;
  iconType: RiskTypeIconConstants;
  periodStartDate: Date;
  description?: string;
  vin?: string;
  year?: string;
  make?: string;
  model?: string;
  address?: Address;
  vehicleTypeName?: string;
}

<div class="card-content" *ngIf="risk$ | async as risk" data-cy="insurance-card">
  <h1>Your Insurance Card</h1>
  <div class="buttons">
    <div class="button">
      <a
        [routerLink]="'/policies/auto/' + risk.policyNumber"
        [queryParams]="{ vehicleId: risk.vehicleId }"
        data-cy="view-policy-link"
        ><img src="/assets/images/poi-auto.svg" /><span>View Policy</span>
      </a>
    </div>
    <div class="button" *ngIf="risk.poiLink">
      <a (click)="openStatement(risk.poiLink)" data-cy="download-pdf-link"
        ><img src="/assets/images/download.svg" /><span>Download as PDF</span>
      </a>
    </div>
  </div>
  <div class="section">
    <div class="sub-section">
      <h3>Your Policy</h3>
      <amfam-policy-card
        [coverages]="coverages$ | async"
        [risk]="risk"
        [insureds]="insureds$ | async"
        data-cy="policy-card"
      ></amfam-policy-card>
    </div>
    <div class="sub-section">
      <h3>Your Vehicle</h3>
      <amfam-vehicle-card [risk]="risk" data-cy="vehicle-card"></amfam-vehicle-card>
    </div>
  </div>
  <div class="agent-section" [class.stretch-column]="isHandsetPortrait$ | async">
    <h3>Your Agent</h3>
    <agent-item
      class="agent-item"
      [agent]="agent$ | async"
      [defaultContactNumber]="defaultContactNumber"
      data-cy="agent-item"
    ></agent-item>
  </div>
  <div class="disclaimer">
    <div class="agent-id">
      <p>Producer ID: {{ risk.producerId }}</p>
      <p *ngIf="risk.address.state === 'AZ'" data-cy="az-dot-number">
        ADOT: {{ azDOTNumber$ | async }}
      </p>
    </div>
    <b>{{ POIName$ | async }}</b>
    <p [innerHTML]="stateDisclaimer$ | async" data-cy="state-disclaimer"></p>
    <p [innerHTML]="footer" data-cy="footer"></p>
    <div class="company-and-naic">
      <p>{{ risk.amfamCompanyDescription }}</p>
      <p>NAIC Number: {{ naicCode$ | async }}</p>
    </div>
    <i>{{ disclaimer }}</i>
  </div>
</div>
<div class="action sticky" *ngIf="isHandsetPortrait$ | async">
  <a href="tel:1-866-987-0206" class="flex-column icon-button" data-cy="roadside-assistance-link">
    <img src="/assets/images/TowTruck2x.png" height="50" width="50" alt="File a Claim" />
    <span>Roadside Assistance</span>
  </a>

  <a
    class="flex-column icon-button"
    [routerLink]="['/claims/report-claim-fnol']"
    data-cy="file-claim-link"
  >
    <img src="/assets/images/Document2x.png" height="50" width="50" alt="File a Claim" />
    <span>File a Claim</span>
  </a>
</div>

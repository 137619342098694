import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CLAIMDASHBOARD_FEATURE_KEY,
  claimDashboardReducer
} from './+state/claim-dashboard.reducer';
import { ClaimDashboardEffects } from './+state/claim-dashboard.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIMDASHBOARD_FEATURE_KEY, claimDashboardReducer),
    EffectsModule.forFeature([ClaimDashboardEffects])
  ]
})
export class ClaimDashboardDataAccessModule {}

<div #linkContainer class="nav-container" (mouseover)="subOpen()" (mouseout)="subClose()">
  <div
    #linkElem
    class="nav-item-link"
    data-cy="navigationMenuItem"
    role="none"
    [style.maxWidth.px]="link?.maxWidth"
    [class.has-children]="!!link?.subLinkArray?.length"
    [class.overflow-ellipsis]="link?.overflowEllipsis"
    [ngClass]="link?.listClasses"
  >
    <a
      *ngIf="!link?.linkExternalUrl && !link.mobileOnly && link.showOnNavigationMenu"
      #linkElemItem
      role="menuitem"
      [queryParams]="link?.queryParams"
      [ngClass]="link?.linkClasses + '-text'"
      [attr.aria-label]="link.linkText"
      [attr.aria-expanded]="submenuExpanded"
      [attr.aria-haspopup]="submenuHasPopup ? 'menu' : 'false'"
      [attr.data-cy]="link?.linkTestId"
      (focus)="subOpen($event.currentTarget)"
      (blur)="subClose($event.currentTarget)"
      (touchstart)="subOpen($event.currentTarget)"
      (click)="linkClick(link, $event)"
    >
      <div class="link-content">
        <span
          *ngIf="!!link?.linkIcon"
          role="presentation"
          aria-hidden="true"
          [ngClass]="link?.linkIcon"
        ></span>
        <span [class.overflow-ellipsis]="link?.overflowEllipsis" role="none">{{
          link.linkText
        }}</span>
      </div>
    </a>
    <a
      *ngIf="!!link?.linkExternalUrl && !link.mobileOnly && link.showOnNavigationMenu"
      role="menuitem"
      [ngClass]="link?.linkClasses + '-text'"
      [href]="link?.linkExternalUrl"
      [attr.aria-label]="link.linkText"
      [attr.target]="link?.linkTarget"
      [attr.data-cy]="link?.linkTestId"
      (click)="linkClick(link, $event)"
      (focus)="subOpen($event.currentTarget)"
      (blur)="subClose($event.currentTarget)"
    >
      <div class="link-content">
        <span
          *ngIf="!!link?.linkIcon"
          role="presentation"
          aria-hidden="true"
          [ngClass]="link?.linkIcon"
        ></span>
        <span [class.overflow-ellipsis]="link?.overflowEllipsis" role="none">{{
          link.linkText
        }}</span>
      </div>
    </a>
    <ul
      *ngIf="!!link?.subLinkArray?.length"
      [@showSubmenu]="showSub"
      #sublinkElem
      class="nav-item-sublink"
      role="menu"
      [attr.data-cy]="link?.submenuTestId"
    >
      <li
        *ngFor="let sublink of link?.subLinkArray; trackBy: trackByFn"
        role="none"
        #subLinkElemList
        authCheck
        [ngClass]="sublink?.listClasses"
        [style.maxWidth.px]="sublink?.maxWidth"
        [permissionName]="sublink?.permissionName"
        [checkAction]="sublink?.checkAction"
      >
        <a
          *ngIf="!sublink?.linkExternalUrl && !link.mobileOnly"
          role="menuitem"
          [ngClass]="sublink?.linkClasses"
          [queryParams]="sublink?.queryParams"
          [attr.aria-label]="sublink?.linkText"
          [attr.data-cy]="sublink?.linkTestId"
          (click)="sublinkClick(sublink, $event)"
          (focus)="subOpen($event.currentTarget)"
          (blur)="subClose($event.currentTarget)"
        >
          <span
            *ngIf="!!sublink?.linkIcon"
            role="presentation"
            aria-hidden="true"
            alt="Link Icon"
            [ngClass]="sublink?.linkIcon"
          ></span>
          <span [class.overflow-ellipsis]="sublink?.overflowEllipsis" role="none">{{
            sublink.linkText
          }}</span>
        </a>
        <a
          *ngIf="!!sublink?.linkExternalUrl && !link.mobileOnly"
          role="menuitem"
          [ngClass]="sublink?.linkClasses"
          [href]="sublink?.linkExternalUrl"
          [attr.aria-label]="sublink?.linkText"
          [attr.target]="sublink?.linkTarget"
          [attr.data-cy]="sublink?.linkTestId"
          (click)="sublinkClick(sublink, $event)"
          (focus)="subOpen($event.currentTarget)"
          (blur)="subClose($event.currentTarget)"
        >
          <span
            *ngIf="!!sublink?.linkIcon"
            role="presentation"
            aria-hidden="true"
            alt="Link Icon"
            [ngClass]="sublink?.linkIcon"
          ></span>
          <span [class.overflow-ellipsis]="sublink?.overflowEllipsis" role="none">{{
            sublink.linkText
          }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>

import { KydSmartphoneEnrolledOperatorModel } from './kyd-smartphone-enrolled-operator.model';

export class KydSmartphoneSummary {
  policyNumber: string;
  emailId?: string;
  ubiProgramCategoryCode: string;
  customerId: string;
  enrollmentId: number;
  enrollmentStatus: string;
  enrollmentAckDate: string;
  enrolledOperators: Array<KydSmartphoneEnrolledOperatorModel>;
  optOutDetail: any;
  waitingPeriodEndDate: string;
  discountAppliedDate: string;

  constructor(
    policyNumber = '',
    ubiProgramCategoryCode = '',
    enrollmentId = null,
    enrollmentStatus = '',
    enrollmentAckDate = '',
    enrolledOperators = null
  ) {
    this.policyNumber = policyNumber;
    this.ubiProgramCategoryCode = ubiProgramCategoryCode;
    this.customerId = '';
    this.enrollmentId = enrollmentId;
    this.enrollmentStatus = enrollmentStatus;
    this.enrollmentAckDate = enrollmentAckDate;
    this.enrolledOperators = enrolledOperators;
    this.optOutDetail = {};
    this.waitingPeriodEndDate = '';
    this.discountAppliedDate = '';
  }
}

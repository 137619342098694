import {
  FinancialInstitutionAction,
  FinancialInstitutionActionTypes
} from './financial-institution.actions';

import { get as _get } from 'lodash';
import {
  FinancialInstitutionModel,
  initialFinancialInstitutionModel
} from '../models/financial-institution.models';

export function financialInstitutionReducer(
  state: FinancialInstitutionModel = initialFinancialInstitutionModel,
  action: FinancialInstitutionAction
): FinancialInstitutionModel {
  switch (action.type) {
    case FinancialInstitutionActionTypes.GetFinancialInstitution:
      return Object.assign({}, state, {
        financialInstitutionName: '',
        status: null,
        messages: null,
        loading: true,
        foundFinancialInstitution: false,
        loaded: false
      });

    case FinancialInstitutionActionTypes.GetFinancialInstitutionSuccess:
      return Object.assign({}, state, {
        financialInstitutionName: _get(
          action,
          'payload.finAcctServiceResponse.financialInstitute.FinanacialInstitutionName',
          ''
        ),
        status: _get(action, 'payload.finAcctServiceResponse.apiStatus.code'),
        messages: null,
        loading: false,
        foundFinancialInstitution: true,
        loaded: true
      });

    case FinancialInstitutionActionTypes.GetFinancialInstitutionFail:
      return Object.assign({}, state, {
        financialInstitutionName: '',
        status: _get(action, 'payload.status.code'),
        messages: _get(action, 'payload.status.messages'),
        loading: false,
        foundFinancialInstitution: false,
        loaded: true
      });

    case FinancialInstitutionActionTypes.ResetFinancialInstitution:
      return Object.assign({}, initialFinancialInstitutionModel);

    default:
      return state;
  }
}

export const getLoading = (state: FinancialInstitutionModel) => state.loading;

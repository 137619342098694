import {
  Component, ElementRef,
  HostListener, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isEmpty as _isEmpty } from 'lodash';
import { ErrorMessageService } from '../../services/error-message.service';

@Component({
  selector: 'ds-form-control-container',
  templateUrl: './ds-form-control-container.component.html',
  styleUrls: ['./ds-form-control-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DsFormControlContainerComponent implements OnInit, OnDestroy {
  // Input FormControl
  @Input() control: UntypedFormControl;

  // Input Label Text
  @Input() labelText = '';

  // Input Wrapper Class
  @Input() inputWrapperClass = 'input';

  // Input Label Class
  @Input() labelClass = '';

  // Input show all error messages
  @Input() allMessages = false;

  // Input to validate pristine submission
  @Input() validateOnSubmit = false;

  // Whether the label should be floating
  isFloating = false;
  private controlElement: any;
  private sub: any;

  inputIdAttr = '';

  @ViewChild('inputWrapper', { static: true }) private inputWrapper: ElementRef;

  /** Set focus on input */
  @HostListener('click')
  focus() {
    this.controlElement.focus();
  }

  constructor() { }

  ngOnInit() {
    this.controlElement = this.inputWrapper.nativeElement.querySelector('input');
    if (!this.controlElement) {
      this.controlElement = this.inputWrapper.nativeElement.querySelector('select');
    }
    if (!this.controlElement) {
      this.controlElement = this.inputWrapper.nativeElement.querySelector('textarea');
    }

    // Get input element id and set it for use in the [attr.for] of the label
    if (this.controlElement.getAttribute('id')) {
      this.inputIdAttr = this.controlElement.getAttribute('id');
    }

    //  Float if the control value is a number and is greater than or equal to 0
    //  Float if the control value has a length and that length is greater than 0
    if (this.control) {
      this.isFloating =
        this.control.value &&
        (typeof this.control.value === 'number' ||
          (this.control.value.length && this.control.value.length > 0) ||
          !_isEmpty(this.control.value));

      this.sub = this.control.valueChanges.subscribe(data => {
        if (data) {
          this.isFloating = !data.length || data.length > 0;
        } else {
          /**
           * This condition is called if the control had value and a
           * reset is called on control with empty value.
           */
          this.isFloating = false;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  get errorMessage() {
    if ((this.control && !this.control.pristine && this.control.touched) || this.validateOnSubmit) {
      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName)) {
          return ErrorMessageService.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName]
          );
        }
      }
    }
    return null;
  }

  get allErrorMessages() {
    const messages = [];
    if (!this.control.pristine) {
      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
          messages.push(
            ErrorMessageService.getValidatorErrorMessage(
              propertyName,
              this.control.errors[propertyName]
            )
          );
        }
      }
    }
    return messages;
  }
}

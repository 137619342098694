import {
  Component,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from '@angular/core';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  private _isOpen: boolean;

  get isOpen(): boolean {
    return this._isOpen;
  }
  @Input()
  set isOpen(value: boolean) {
    this._isOpen = value;
    this.isOpenChange.emit(this._isOpen);
    this.setBodyClass();
  }

  @Input() fullScreen = false;

  @Output() isOpenChange = new EventEmitter<boolean>();

  @ViewChild('content', { static: true })
  content: ElementRef;

  @ViewChild('modal', { static: true })
  modal: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    if (document && document.body && this.modal.nativeElement) {
      document.body.appendChild(this.modal.nativeElement);
    }
  }

  ngOnDestroy() {
    if (document && document.body && this.modal.nativeElement) {
      document.body.removeChild(this.modal.nativeElement);
    }
  }

  @HostListener('click', ['$event.target'])
  onClick(targetElement) {
    if (this.content.nativeElement) {
      if (!this.content.nativeElement.contains(targetElement)) {
        this.close();
      }
    }
  }

  @HostListener('touchend', ['$event.target'])
  onTouchEnd(targetElement) {
    if (this.content.nativeElement) {
      if (!this.content.nativeElement.contains(targetElement)) {
        this.close();
      }
    }
  }

  @HostListener('focus', ['$event.target'])
  onFocus(targetElement) {
    if (this.content.nativeElement) {
      if (!this.content.nativeElement.contains(targetElement)) {
        this.close();
      }
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event) {
    const keyCode = event.keyCode || event.charCode;

    if (keyCode === 27) {
      this.close();
    }
  }

  public close() {
    this.isOpen = false;
  }

  private setBodyClass() {
    if (document && document.body) {
      if (this.isOpen) {
        document.body.classList.add('modal-open');
        if (this.content.nativeElement) {
          this.content.nativeElement.focus();
        }
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  }
}

import { Action } from '@ngrx/store';
import { ClaimRequestWrapper } from '../models/claim-request-wrapper';
import { ClaimConfirmation } from '../models/claim-confirmation';
import { DraftClaim } from '@amfam/claim/fnol/data-access';
import { IReportClaim } from '@amfam/shared/models';

export enum ClaimSubmitActionTypes {
  SubmitClaim = '[ClaimSubmit] Submit',
  SubmitClaimSuccess = '[ClaimSubmit] SubmitClaimSuccess',
  SubmitClaimFailed = '[ClaimSubmit] SubmitClaimFailed',
  FnolConfirmation = '[ClaimSubmit] Fnol Confirmation',
  ResetConfirmation = '[ClaimSubmit] Clear Confirmation',
  ReportClaim = '[Report Claim] Report Claim',
  ReportClaimSuccess = '[Report Claim] Report Claim Success',
  ReportClaimFailed = '[Report Claim] Report Claim Failed'
}

export class SubmitClaim implements Action {
  readonly type = ClaimSubmitActionTypes.SubmitClaim;
  constructor(public payload: ClaimRequestWrapper) {}
}

export class SubmitClaimSuccess implements Action {
  readonly type = ClaimSubmitActionTypes.SubmitClaimSuccess;
  constructor(public payload: ClaimConfirmation) {}
}

export class SubmitClaimFailed implements Action {
  readonly type = ClaimSubmitActionTypes.SubmitClaimFailed;
  constructor() {}
}

export class FnolConfirmation implements Action {
  readonly type = ClaimSubmitActionTypes.FnolConfirmation;
  constructor(public payload: DraftClaim) {}
}

export class ResetConfirmation implements Action {
  readonly type = ClaimSubmitActionTypes.ResetConfirmation;
  constructor() {}
}

export class ReportClaim implements Action {
  readonly type = ClaimSubmitActionTypes.ReportClaim;
  constructor(public payload: IReportClaim) {}
}

export class ReportClaimSuccess implements Action {
  readonly type = ClaimSubmitActionTypes.ReportClaimSuccess;
  constructor(public payload: ClaimConfirmation) {}
}


export class ReportClaimFailed implements Action {
  readonly type = ClaimSubmitActionTypes.ReportClaimFailed
}


export type ClaimSubmitAction =
  | SubmitClaim
  | SubmitClaimSuccess
  | SubmitClaimFailed
  | FnolConfirmation
  | ResetConfirmation
  | ReportClaim
  | ReportClaimSuccess
  | ReportClaimFailed;

export const fromClaimSubmitActions = {
  SubmitClaim,
  SubmitClaimSuccess,
  SubmitClaimFailed,
  FnolConfirmation,
  ResetConfirmation,
  ReportClaim,
  ReportClaimSuccess,
  ReportClaimFailed
};

import {
  PaymentConfirmationActionTypes,
  PaymentConfirmationActions
} from './paymentConfirmation.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get as _get, flatMap as _flatMap } from 'lodash';
import { PaymentConfirmationModel } from '@amfam/shared/models';

export const PAYMENT_CONFIRMATION_FEATURE_KEY = 'payment confirmation';

export interface PaymentConfirmtionPartialState {
  readonly [PAYMENT_CONFIRMATION_FEATURE_KEY]: PaymentConfirmtionEntityState;
}

export interface PaymentConfirmtionEntityState extends EntityState<PaymentConfirmationModel> {
  category: string;
}

export const adapter: EntityAdapter<PaymentConfirmationModel> = createEntityAdapter<
  PaymentConfirmationModel
>({
  selectId: (paymentConfirmationObjModel: PaymentConfirmationModel) =>
    paymentConfirmationObjModel.category,
  sortComparer: false
});

export const initialState: PaymentConfirmtionEntityState = adapter.getInitialState({
  category: ''
});

export function reducer(
  state: PaymentConfirmtionEntityState = initialState,
  action: PaymentConfirmationActions
): PaymentConfirmtionEntityState {
  switch (action.type) {
    case PaymentConfirmationActionTypes.PaymentConfirmationLoadSuccess: {
      return adapter.upsertOne(action.payload, adapter.removeAll(state));
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

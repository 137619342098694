import { of as observableOf, Observable, of, throwError } from 'rxjs';

import { catchError, switchMap, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as maeProgramActions from './mae-program.actions';
import * as sessionActions from '../session/session.actions';
import { EnrollNotificationService } from '../../../shared/enroll-notification/shared/enroll-notification.service';
import { get as _get } from 'lodash';

@Injectable()
export class MaeProgramEffects {
  /* eslint-disable @typescript-eslint/member-ordering */

  loading$ = createEffect(() =>
    this.action$.pipe(
      ofType(sessionActions.LOAD_ACCOUNT),
      map((action: sessionActions.LoadAccountAction) => action.payload),
      map(payload => new maeProgramActions.LoadAction(payload))
    )
  );

  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(maeProgramActions.LOAD),
      map((action: maeProgramActions.LoadAction) => action.payload),
      switchMap(user => {
        if (_get(user, 'customerId', '') !== '') {
          return this.enrollNotificationService.getMaePrograms(user.customerId);
        }
        return throwError({});
      }),
      map(programs => new maeProgramActions.LoadSuccessAction(programs)),
      catchError(error => observableOf(new maeProgramActions.LoadFailAction(error)))
    )
  );

  constructor(
    private enrollNotificationService: EnrollNotificationService,
    private action$: Actions
  ) {}
}

import { Party } from '@amfam/shared/models';
import { Action } from '@ngrx/store';
import { UserLogin, UserUpdate } from './user.model';

export enum UserActionTypes {
  LoginSuccess = '[User] Login Success',
  UpdateParty = '[User] Update Party',
  UpdateUser = '[User] Update User',
  EditUserId = '[User] Edit User Id',
  UserInfoSuccess = '[User] UserInfo Success',
  UpdateUserEmail = '[User] Update User Email',
  EditSmImpersonatrUserId = '[User] Edit SM Impersonator User ID',
  EditCustomerId = '[User] Edit Customer ID',
  EditWAID = '[User] Edit WAID'
}

export class LoginSuccess implements Action {
  readonly type = UserActionTypes.LoginSuccess;
  constructor(public payload: UserLogin) {}
}

export class UpdateParty implements Action {
  readonly type = UserActionTypes.UpdateParty;
  constructor(public payload: Party) {}
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;
  constructor(public payload: UserUpdate) {}
}

export class EditUserId implements Action {
  readonly type = UserActionTypes.EditUserId;
  constructor(public payload: string) {}
}

export class UserInfoSuccess implements Action {
  readonly type = UserActionTypes.UserInfoSuccess;
  constructor(public payload: UserLogin) {}
}

export class UpdateUserEmail implements Action {
  readonly type = UserActionTypes.UpdateUserEmail;
  constructor(public payload: string) {}
}

export class EditSmImpersonatrUserId implements Action {
  readonly type = UserActionTypes.EditSmImpersonatrUserId;
  constructor(public payload: string) {}
}

export class EditCustomerId implements Action {
  readonly type = UserActionTypes.EditCustomerId;
  constructor(public payload: string) {}
}

export class EditWAID implements Action {
  readonly type = UserActionTypes.EditWAID;
  constructor(public payload: string) {}
}

export type UserAction =
  | LoginSuccess
  | UpdateParty
  | UpdateUser
  | EditUserId
  | UserInfoSuccess
  | UpdateUserEmail
  | EditSmImpersonatrUserId
  | EditCustomerId
  | EditWAID;

export const fromUserActions = {
  LoginSuccess,
  UpdateParty,
  UpdateUser,
  EditUserId,
  UserInfoSuccess,
  UpdateUserEmail,
  EditSmImpersonatrUserId,
  EditCustomerId,
  EditWAID
};

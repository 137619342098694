import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { get as _get } from 'lodash';

import { ValidationService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-verify-code-entry',
  templateUrl: './verify-code-entry.component.html',
  styleUrls: ['../../../enrollment/enrollment.component.scss']
})
export class VerifyCodeEntryComponent implements OnInit {
  @Input() confirmMethod: string;
  @Output() submittedCodeEvent = new EventEmitter();

  private codeInput: AbstractControl;
  codeForm: UntypedFormGroup;
  codeNotFound: boolean;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.loadCodeForm();
  }

  private loadCodeForm() {
    this.codeForm = this.formBuilder.group({
      codeInput: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          ValidationService.numbersOnly
        ]
      ]
    });
    this.codeInput = this.codeForm.controls['codeInput'];
  }

  verifyCode() {
    this.submittedCodeEvent.emit(this.codeInput.value);
  }
}

import { PageAnalytic } from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagAnalyticConstants {
  public static readonly pageDefaultDatafileLoadedMyaccount: PageAnalytic = {
    pageName: 'MyAccount:DefaultOptimizely',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageDefaultDatafileLoadedMyaccountAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:DefaultOptimizely',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: '',
    subCategory3: ''
  };
}

import { ApiStatus } from '@amfam/shared/models';

export class EligibleServicesConstants {
  public static readonly DRP = 'drp';
  public static readonly RENTAL = 'RENTAL';
}

export interface EligibleServicesResponse {
  status: ApiStatus;
  claimNumber: string;
  eligibleServices: ServiceType[];
  vin: string;
  numberOfCustomerPhotos: number;
}

export interface ServiceType {
  serviceCode: string;
  serviceDescription: string;
  coverageCode?: string;
  coverageDescription?: string;
  serviceDependenciesSatisfied?: boolean;
  serviceDependentOnSRCode?: string;
  serviceCustomerCallInMsgCode?: string;
  serviceRequestDisclaimer?: ServiceRequestDisclaimer[];
  serviceOpen?: boolean;
}

export interface ServiceRequestDisclaimer {
  serviceDisclaimerCode: string;
  serviceDisclaimerDescription: string;
  serviceDisclaimerStateCode: string;
}

export interface ClaimServicesStatus {
  repairEligible: boolean;
  repairSelected: boolean;
  rentalCoverage: boolean;
  rentalEligible: boolean;
  rentalSelected: boolean;
  rentalDependencyUnmet: boolean;
  photosUploaded: boolean;
}

// action payload models
export interface GetEligibleServicesPayload {
  vin: string;
  claimNumber: string;
}

import {
  EDeliveryTermsAndConditionsActions,
  EDeliveryTermsAndConditionsActionTypes
} from './e-delivery-terms-and-conditions.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get as _get } from 'lodash';
import {
  EdeliveryTermsAndConditionsModel,
  EdeliveryTermsAndConditionsResponse
} from '../models/e-delivery-terms-and-conditions.model';

export const POLICIES_EDELIVERY_FEATURE_KEY = 'e-delivery';

export interface EdeliveryPartialState {
  readonly [POLICIES_EDELIVERY_FEATURE_KEY]: EdeliveryEntityState;
}

export interface EdeliveryEntityState extends EntityState<EdeliveryTermsAndConditionsModel> {}

export const adapter: EntityAdapter<EdeliveryTermsAndConditionsModel> = createEntityAdapter<
  EdeliveryTermsAndConditionsModel
>({
  selectId: (eDeliveryObjModel: EdeliveryTermsAndConditionsModel) => eDeliveryObjModel.brandId,
  sortComparer: false
});

export const initialState: EdeliveryEntityState = adapter.getInitialState({});

export function reducer(
  state: EdeliveryEntityState = initialState,
  action: EDeliveryTermsAndConditionsActions
): EdeliveryEntityState {
  switch (action.type) {
    case EDeliveryTermsAndConditionsActionTypes.LoadEDeliveryTermsAndConditionssSuccess: {
      return adapter.addOne(_get(action, 'payload'), initialState);
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

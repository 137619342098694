<hr />
<div class="row flex margin-bottom-10" data-cy="reviewItem">
  <div class="row flex grow bill-account-tile items-center">
    <div class="column margin-right-20 margin-top-5">
      <span
        class="policy-icon"
        [ngClass]="preference.policyTypeIcon"
        role="img"
        data-cy="reviewItemIcon"
      ></span>
    </div>
    <div class="column grow">
      <div class="row" data-cy="reviewItemRiskDescription">{{ preference.riskDescriptions }}</div>
      <div class="row caption" data-cy="reviewItemAccountName">{{ preference.accountName }}</div>
    </div>
  </div>
</div>

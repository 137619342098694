import { Inject, Injectable, InjectionToken, Renderer2, RendererFactory2 } from '@angular/core';
import { IconsConfig, FaviconsConfig } from '../models/favicon.model';

export const BROWSER_FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>('Favicons Configuration');

export abstract class FaviconService {
  abstract activate(name: string): void;
  abstract reset(): void;
}

@Injectable()
export class BrowserFavicons implements FaviconService {
  private elementId: string;
  private icons: IconsConfig;
  private useCacheBusting: boolean;
  private renderer: Renderer2;

  constructor(
    @Inject(BROWSER_FAVICONS_CONFIG) config: FaviconsConfig,
    private rendererFactory: RendererFactory2
  ) {
    this.elementId = 'favicons-service-injected-node';
    this.icons = Object.assign(Object.create(null), config.icons);
    this.useCacheBusting = config.cacheBusting || false;
    this.renderer = this.rendererFactory.createRenderer(null, null);

    // Since the document may have a static favicon definition, we want to strip out
    // any exisitng elements that are attempting to define a favicon. This way, there
    // is only one favicon element on the page at a time.
    this.removeExternalLinkElements();
  }

  // Activate the favicon with the given name / identifier.
  activate(name: string): void {
    if (!this.icons[name]) {
      throw new Error(`Favicon for [ ${name} ] not found.`);
    }
    this.setNode(this.icons[name].type, this.icons[name].href);
  }

  // Activate the default favicon (with isDefault set to True).
  reset(): void {
    for (const name of Object.keys(this.icons)) {
      const icon = this.icons[name];

      if (icon.isDefault) {
        this.setNode(icon.type, icon.href);
        return;
      }
    }
  }

  // Inject the favicon element into the document header.
  private addNode(type: string, href: string): void {
    const linkElement = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElement, 'id', this.elementId);
    this.renderer.setAttribute(linkElement, 'rel', 'icon');
    this.renderer.setAttribute(linkElement, 'type', type);
    this.renderer.setAttribute(linkElement, 'href', href);
    this.renderer.appendChild(document.head, linkElement);
  }

  // Return an augmented HREF value with a cache-busting query-string parameter.
  private cacheBustHref(href: string): string {
    const augmentedHref =
      href.indexOf('?') === -1
        ? `${href}?faviconCacheBust=${Date.now()}`
        : `${href}&faviconCacheBust=${Date.now()}`;

    return augmentedHref;
  }

  // Remove any favicon nodes that are not controlled by this service.
  private removeExternalLinkElements(): void {
    const linkElements = document.querySelectorAll("link[ rel ~= 'icon']");

    for (const linkElement of Array.from(linkElements)) {
      this.renderer.removeChild(linkElement.parentNode, linkElement);
    }
  }

  // Remove the favicon node from the document header.
  private removeNode(): void {
    const linkElement = document.head.querySelector('#' + this.elementId);

    if (linkElement) {
      this.renderer.removeChild(document.head, linkElement);
    }
  }

  // Remove the existing favicon node and inject a new favicon node with the given
  // element settings.
  private setNode(type: string, href: string): void {
    const augmentedHref = this.useCacheBusting ? this.cacheBustHref(href) : href;

    this.removeNode();
    this.addNode(type, augmentedHref);
  }
}

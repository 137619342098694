import { Directive, Input, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[phoneMask]'
})
export class PhoneMaskDirective implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  // Form control for field
  @Input() phoneControl: AbstractControl;

  // Identifier to identify the element in DOM
  @Input() identifier: string;

  // PreValue used to identify adding or removing number
  @Input()
  preValue: string;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.phoneValidate();
  }

  phoneValidate() {
    this.phoneControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe(data => {
      const preInputValue: string = this.preValue;
      const lastChar: string = preInputValue.substr(preInputValue.length - 1);

      // Remove all mask characters (keep only numeric)
      let newVal = data.replace(/\D/g, '');

      // Getting the cursor position using identifier from DOM
      let start = this.renderer.selectRootElement('#' + this.identifier).selectionStart;
      let end = this.renderer.selectRootElement('#' + this.identifier).selectionEnd;

      if (data.length < preInputValue.length) {
        // Removing context of number
        if (preInputValue.length < start) {
          // While removing if we encounter '-' character, then remove last digit too
          if (lastChar === '-') {
            newVal = newVal.substr(0, newVal.length - 1);
          }
        }

        // If there's no number don't show '-' character
        if (newVal.length === 0) {
          newVal = '';
        }
        // While removing we change pattern match, otherwise deleting of non-numeric characters is not recognized
        else if (newVal.length > 3 && newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})/, '$1-');
        } else if (newVal.length > 6 && newVal.length <= 9) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2-');
        } else if (newVal.length > 9) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }

        this.phoneControl.setValue(newVal, { emitEvent: false });

        // Keep cursor in the normal position after setting the value above
        this.renderer.selectRootElement('#' + this.identifier).setSelectionRange(start, end);
      } else {
        // When typed value in the input
        const removedD = data.charAt(start);

        // Don't show '-' for empty value
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length > 3 && newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})/, '$1-');
        } else if (newVal.length > 6 && newVal.length <= 9) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2-');
        } else if (newVal.length > 9) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }

        // Check typing whether in the middle or not in the follwing case
        if (preInputValue.length >= start) {
          // Typing in the middle
          if (removedD === '-') {
            // Change cursor position due to '-' character
            start = start + 1;
            end = end + 1;
          }

          this.phoneControl.setValue(newVal, { emitEvent: false });

          // Setting the cursor position back
          this.renderer.selectRootElement('#' + this.identifier).setSelectionRange(start, end);
        } else {
          this.phoneControl.setValue(newVal, { emitEvent: false });

          // Setting the cursor position back
          this.renderer
            .selectRootElement('#' + this.identifier)
            .setSelectionRange(start + 2, end + 2); // +2 because of wanting standard typing
        }
      }
    });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

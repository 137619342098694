<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="column twelve">
      <ds-form-control-container
        [labelText]="formLabeltext.inputFieldLabelText.lastName"
        [control]="form.get('lastName')"
      >
        <input
          data-cy="magic3LastNameInput"
          type="text"
          id="lastName"
          class="fill"
          formControlName="lastName"
        />
        <ds-form-control-message
          data-cy="magic3LastNameErrorText"
          [control]="form.get('lastName')"
        ></ds-form-control-message>
      </ds-form-control-container>
    </div>
  </div>
  <div class="row">
    <div class="column twelve md-six">
      <div class="md-pad-right-10">
        <ds-form-control-container
          [labelText]="formLabeltext.inputFieldLabelText.dateOfBirth"
          [control]="form.get('dob')"
        >
          <input
            data-cy="magic3DoBInput"
            type="tel"
            id="dob"
            class="fill"
            formControlName="dob"
            dobMask
            maxlength="10"
          />
          <ds-form-control-message
            data-cy="magic3DobErrorText"
            [control]="form.get('dob')"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
    </div>
    <div class="column twelve md-six">
      <div class="md-pad-left-10">
        <ds-form-control-container
          [labelText]="formLabeltext.inputFieldLabelText.zipCode"
          [control]="form.get('zipcode')"
        >
          <input
            data-cy="magic3ZipcodeInput"
            type="tel"
            id="zipcode"
            class="fill"
            formControlName="zipcode"
            maxlength="5"
          />
          <ds-form-control-message
            data-cy="magic3ZipcodeErrorText"
            [control]="form.get('zipcode')"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="column twelve">
      <p *ngIf="searchErrorMessage" class="error-600-text caption">{{ searchErrorMessage }}</p>
      <a
        *ngIf="showBackButton"
        href="javascript:void(0)"
        (click)="goBackOneStep()"
        class="inline-block margin-top-20 caption"
        ><i class="icon-chevron-left"></i> Back</a
      >
      <button
        ds-button
        data-cy="magic3SearchContinueButton"
        type="submit"
        class="float-right -offset-top"
        [disabled]="!form.valid"
        content
        [contentPath]="'shared.nextBtn'"
      ></button>
    </div>
  </div>
</form>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ds-flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.scss']
})
export class FlipCardComponent implements OnInit {
  flipStatus = FlipSide.FRONT;
  @Output() cardFlipped = new EventEmitter<FlipSide>();

  constructor() {}

  ngOnInit(): void {}

  flip(card, e) {
    card.style.transform = this.flipStatus === FlipSide.FRONT ? 'rotateY(180deg)' : 'rotateY(0deg)';
    if (e && e.preventDefault) e.preventDefault();
    this.flipStatus = this.flipStatus === FlipSide.FRONT ? FlipSide.BACK : FlipSide.FRONT;
    this.cardFlipped.emit(this.flipStatus);
  }
}

export enum FlipSide {
  FRONT = 'FRONT',
  BACK = 'BACK'
}

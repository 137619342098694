import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  SecurityQuestionsActionTypes,
  SecurityQuestionsActions,
  GetAllSecurityQuestionsSuccess,
  GetAllSecurityQuestionsFailure,
  GetUserSecurityQuestionsSuccess,
  GetUserSecurityQuestionsFailure,
  GetUserSecurityQuestions,
  SetSecurityQuestionsAnswers,
  SetSecurityQuestionsAnswersSuccess,
  SetSecurityQuestionsAnswersFailure
} from './security-questions.actions';
import { SecurityQuestionsService } from '../../services/security-questions.service';
import {
  GetUserSecurityQuestionsPayload,
  SetSecurityQuestionsAnswersPayload
} from './security-questions.models';
import { DigitalAccount } from '../digital-account/digital-account.model';
import { DigitalAccountService } from '../../services/digital-account.service';
import { Store } from '@ngrx/store';
import { BrandSelectors } from '@amfam/shared/utility/brand';

@Injectable()
export class SecurityQuestionsEffects {
  
  getAllSecurityQuestions$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityQuestionsActionTypes.GetAllSecurityQuestions),
    switchMap(() =>
      this.securityQuestionsService.getAllSecurityQuestions().pipe(
        map(response => new GetAllSecurityQuestionsSuccess({ questions: response.questions })),
        catchError(error => of(new GetAllSecurityQuestionsFailure()))
      )
    )
  ));

  getUserSecurityQuestions$ = this.actions$.pipe(
    ofType(SecurityQuestionsActionTypes.GetUserSecurityQuestions),
    map((action: GetUserSecurityQuestions) => action.payload),
    switchMap((payload: GetUserSecurityQuestionsPayload) =>
      this.securityQuestionsService.getUserSecurityQuestions(payload).pipe(
        map(response => new GetUserSecurityQuestionsSuccess({ response })),
        catchError(error => of(new GetUserSecurityQuestionsFailure()))
      )
    )
  );

  setSecurityQuestionsAnswers$ = this.actions$.pipe(
    ofType(SecurityQuestionsActionTypes.SetSecurityQuestionsAnswers),
    map((action: SetSecurityQuestionsAnswers) => action.payload),
    switchMap(payload => {
      const digitalAccount: DigitalAccount = {
        customerId: payload.customerId,
        securityQuestions: payload.securityQuestions
      };
      return this.digitalAccountService
        .updateDigitalAccount(digitalAccount, payload.waid, payload.partnerId, payload.experienceId)
        .pipe(
          map(response => new SetSecurityQuestionsAnswersSuccess({ response })),
          catchError(error => of(new SetSecurityQuestionsAnswersFailure()))
        );
    })
  );

  constructor(
    private actions$: Actions<SecurityQuestionsActions>,
    private securityQuestionsService: SecurityQuestionsService,
    private digitalAccountService: DigitalAccountService,
    private store: Store<any>
  ) {}
}

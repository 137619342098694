import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CLAIMFNOL_FEATURE_KEY,
  ClaimFnolState,
  selectClaimFnolEntities,
  selectEntityIds,
  selectAllClaimFnols
} from './claim-fnol.reducer';

import { some as _some, get as _get } from 'lodash';

// Lookup the 'ClaimFnol' feature state managed by NgRx
const getClaimFnolState = createFeatureSelector<ClaimFnolState>(CLAIMFNOL_FEATURE_KEY);

export const fnolHasError = createSelector(
  getClaimFnolState,
  (state: ClaimFnolState) => state.hasError
);

export const getClaimFnolEntities = createSelector(
  getClaimFnolState,
  (state: ClaimFnolState) => state.entities
);

export const getSelectedEntityId = createSelector(
  getClaimFnolState,
  (state: ClaimFnolState) => state.selectedEntityId
);

export const getClaimFnolIds = createSelector(
  getClaimFnolState,
  selectEntityIds
);

export const getClaimFnols = createSelector(
  getClaimFnolState,
  selectAllClaimFnols
);

export const getClaimFnolLoading = createSelector(
  getClaimFnolState,
  (state: ClaimFnolState) => state.loading
);

export const getClaimFnolAnyLoading = createSelector(
  getClaimFnolLoading,
  getClaimFnols,
  (loading, fnols) => {
    return loading || _some(fnols, { loading: true });
  }
);

export const getClaimFnolSelectedEntity = createSelector(
  getClaimFnolEntities,
  getSelectedEntityId,
  (fnols, id) => {
    return fnols[id];
  }
);

export const getSelectedEntityLoading = createSelector(
  getClaimFnolState,
  (state: ClaimFnolState) =>
    state.selectedEntityId ? _get(state, 'entities[state.selectedEntityId].loading') : false
);

export const claimFnolQuery = {
  fnolHasError,
  getClaimFnolEntities,
  getClaimFnolIds,
  getClaimFnols,
  getClaimFnolLoading,
  getClaimFnolAnyLoading,
  getClaimFnolSelectedEntity,
  getSelectedEntityLoading
};

import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { ToasterService } from '@amfam/ui-kit';
import {
  CustomerEnrollentResponse,
  CustomerEnrollment,
  CustomerEnrollmentRequest
} from '../models/mae.models';

@Injectable({ providedIn: 'root' })
export class MaeService {
  private maev2BaseUrl: string;
  private maev3BaseUrl: string;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private toasterService: ToasterService
  ) {}

  resendEmail(cdhId: string, userId: string): Observable<any> {
    this.maev3BaseUrl = this.configService.get('maev3Api');
    const url = `${this.maev3BaseUrl}enrollments/${cdhId}/notifications`;
    const data = {
      handlerUserId: userId
    };
    return this.http.post(url, data);
  }

  changeEnrolledEmail(cdhId: string, emailId: string, userId: string): Observable<any> {
    this.maev2BaseUrl = this.configService.get('maev2Api');
    const url = `${this.maev2BaseUrl}postsalefulfillments/emails/${cdhId}`;
    const body = {
      newEmailAddress: emailId
    };
    const headers = {
      'AFI-EndUserId': userId
    };

    return this.http.put(url, body, { headers: headers });
  }

  agentenrollment(
    customerEnrollmentRequest: CustomerEnrollmentRequest,
    cdhId: string
  ): Observable<CustomerEnrollentResponse> {
    this.maev2BaseUrl = this.configService.get('maev2Api');

    const url = `${this.maev2BaseUrl}postsalefulfillments/${cdhId}`;
    const body = {
      CustomerEnrollmentRequest: customerEnrollmentRequest
    };
    return this.http.post<CustomerEnrollentResponse>(url, body);
  }
}

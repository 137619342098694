import { HeaderWrapperService } from '@amfam/shared/ui/ds-header';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { has as _has } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ds-roofline',
  templateUrl: './ds-roofline.component.html',
  styleUrls: ['./ds-roofline.component.scss']
})
export class DsRooflineComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private headerWrapperService: HeaderWrapperService, private renderer: Renderer2) {}
  @Input() loggedIn$: Observable<boolean>;

  leftPosition: number;
  leftOffsetPosition: number;
  focusLeftPosition: number;
  innerWidth: number;
  linkActive: ElementRef;
  linkFocus: ElementRef;
  rooflineImgWidth = 46;
  // TODO: Get this blue hex value from the design tokens
  // We could also modify the SVG color and only have a single image.
  transparentBackground: string;
  filledBackground: string;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  @ViewChild('roofpeak', { static: true }) roofpeak: ElementRef<HTMLInputElement>;

  @ViewChild('mover') mover: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = document.documentElement.clientWidth;
  }

  ngOnInit() {
    this.loggedIn$.pipe(takeUntil(this.stop$)).subscribe(loggedIn => {
      if (loggedIn) {
        // TODO: Get this hex values from the design tokens
        // We could also modify the SVG color and only have a single image.
        //this.transparentBackground = 'url("/assets/images/ds-roofline-blue.svg"), white';
        //this.filledBackground = 'url("/assets/images/ds-roofline-blue.svg"), #003da5';
        this.rooflineImgWidth = 20;
        this.renderer.addClass(this.roofpeak.nativeElement, 'roofpeak');
      } else {
        // If the user is not logged in, there isn't a dockbar therefor no need for a filled background
        // this.transparentBackground = 'url("/assets/images/ds-roofline.svg"), white';
        // this.filledBackground = this.transparentBackground;
        this.renderer.removeClass(this.roofpeak.nativeElement, 'roofpeak');
      }
    });
  }

  ngAfterViewInit() {
    this.innerWidth = document.documentElement.clientWidth;

    this.headerWrapperService.loaded$.pipe(takeUntil(this.stop$)).subscribe(() => {
      // Recheck active link position after loaded
      this.moveRoofline();
    });

    this.headerWrapperService.headerNavRect$
      .pipe(takeUntil(this.stop$))
      .subscribe(headerNavRect => {
        this.leftOffsetPosition = 0;
        if (this.innerWidth > headerNavRect.width) {
          this.leftOffsetPosition =
            (this.innerWidth - headerNavRect.width) / 2 + this.rooflineImgWidth / 2;
        } else {
          this.leftOffsetPosition = this.rooflineImgWidth / 2;
        }
        this.moveRoofline();
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private moveRoofline() {
    this.headerWrapperService.linkActive$
      .pipe(
        takeUntil(this.stop$),
        filter(linkActive => _has(linkActive, 'nativeElement'))
      )
      .subscribe(linkActive => {
        this.linkActive = linkActive;
        this.leftPosition = linkActive.nativeElement.getBoundingClientRect().left;
        this.roofpeak.nativeElement.style.width = this.rooflineImgWidth + 'px';
        this.roofpeak.nativeElement.style.background = this.filledBackground;
        const moverLeft =
          this.leftPosition -
          this.leftOffsetPosition +
          linkActive.nativeElement.getBoundingClientRect().width / 2;
        this.mover.nativeElement.style.width = moverLeft + 'px';
      });

    this.headerWrapperService.linkFocus$.pipe(takeUntil(this.stop$)).subscribe(linkFocus => {
      this.linkFocus = linkFocus;
      if (_has(linkFocus, 'nativeElement')) {
        this.focusLeftPosition = linkFocus.nativeElement.getBoundingClientRect().left;
        this.mover.nativeElement.style.width =
          this.focusLeftPosition -
          this.leftOffsetPosition +
          linkFocus.nativeElement.getBoundingClientRect().width / 2 +
          'px';
        if (linkFocus.nativeElement.classList.contains('has-children')) {
          this.roofpeak.nativeElement.style.background = this.transparentBackground;
        } else {
          this.roofpeak.nativeElement.style.background = this.filledBackground;
        }
      } else if (_has(this.linkActive, 'nativeElement')) {
        this.mover.nativeElement.style.width =
          this.leftPosition -
          this.leftOffsetPosition +
          this.linkActive.nativeElement.getBoundingClientRect().width / 2 +
          'px';
        this.roofpeak.nativeElement.style.background = this.filledBackground;
      }
    });
  }
}

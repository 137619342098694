import {
  PaymentMethodAccountType,
  PaymentMethodAddEditConfig
} from '@amfam/billing/payment-method/util';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ds-payment-method-modal',
  templateUrl: './payment-method-modal.component.html',
  styleUrls: ['./payment-method-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentMethodModalComponent {
  @Input() paymentMethodAddEditConfig: PaymentMethodAddEditConfig;
  @Input() displayDiscountWarning = false;
  @Input() showTryAgainButton = false;
  @Output() paymentMethodAddEditCompleteEvent: EventEmitter<string> = new EventEmitter<string>();

  paymentMethodTypes = PaymentMethodAccountType;

  constructor() {}
}

import { Action } from '@ngrx/store';
import { DashboardClaim, ClaimDashboardRequest } from './claim-dashboard.models';

export enum ClaimDashboardActionTypes {
  LoadClaimDashboard = '[ClaimDashboard] Load Dashboard Claims',
  LoadClaimDashboardSuccess = '[ClaimDashboard] Load Dashboard Claims Success',
  LoadClaimDashboardFail = '[ClaimDashboard] Load Dashboard Claims Error'
}

export class LoadClaimDashboard implements Action {
  readonly type = ClaimDashboardActionTypes.LoadClaimDashboard;
  constructor(public payload: ClaimDashboardRequest) {}
}

export class LoadClaimDashboardSuccess implements Action {
  readonly type = ClaimDashboardActionTypes.LoadClaimDashboardSuccess;
  constructor(public payload: DashboardClaim[]) {}
}

export class LoadClaimDashboardFail implements Action {
  readonly type = ClaimDashboardActionTypes.LoadClaimDashboardFail;
  constructor(public payload: any) {} // error
}

export type ClaimDashboardAction =
  | LoadClaimDashboard
  | LoadClaimDashboardSuccess
  | LoadClaimDashboardFail;

export const fromClaimDashboardActions = {
  LoadClaimDashboard,
  LoadClaimDashboardSuccess,
  LoadClaimDashboardFail
};

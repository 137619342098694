import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// application modules
import { ClaimSharedUtilityModule } from '@amfam/claim/shared/utility';
import { SharedHeaderModule } from '@amfam/shared/ui/ds-header';
import { SharedRooflineModule } from '@amfam/shared/ui/ds-roofline';
import { FooterModule } from '@amfam/shared/ui/footer';
import { QuickLinksModule } from '@amfam/shared/ui/quick-links';
import { SharedSitemapModule } from '@amfam/shared/ui/sitemap';
import { UiKitModule } from '@amfam/ui-kit';
import { UiModule } from '../shared/ui/ui.module';
import { HeaderWrapperComponent } from './header-wrapper/header-wrapper.component';
import { SitemapComponent } from './sitemap/sitemap.component';

// component/directive/pipe
import { RiskListModule } from '@amfam/shared/ui/risk-list';
import { ImpersonationModule } from '@amfam/shared/utility/impersonation';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { AgentsListComponent } from './agents-list/agents-list.component';
import { AlertComponent } from './alerts-bar/alert/alert.component';
import { AlertsBarComponent } from './alerts-bar/alerts-bar.component';
import { BillsOverdueAlertComponent } from './alerts-bar/bills-overdue-alert/bills-overdue-alert.component';
import { BoundContentDirective } from './bound-content';
import {
  EnrollConfirmationComponent,
  EnrollNotificationComponent,
  EnrollTermsComponent
} from './enroll-notification';
import { FeatureFlagWrapperComponent } from './feature-flag-wrapper/feature-flag-wrapper.component';
import { DecorateFutureStateDirective, ToggleFutureStateDirective } from './feature-toggle';
import { LinksAgentsSidebarComponent } from './links-agents-sidebar/links-agents-sidebar.component';
import { NotFoundComponent } from './not-found';
import { SessionTimeoutComponent } from './session-timeout';

import { ContactUsComponent } from '../core/contact-us/contact-us.component';
import { ExitWarningComponent } from '../enrollment/exit-warning/exit-warning.component';
import { FeatureDisableDirective } from './feature-flag-directive/feature-disable.directive';
import { FeatureEnableDirective } from './feature-flag-directive/feature-enable.directive';
import { CompanyInformationComponent } from './footer-links/company-information/company-information.component';
import { LegalNoticeComponent } from './footer-links/legal-notice/legal-notice.component';
import { PrivacySecurityPolicyComponent } from './footer-links/privacy-security-policy/privacy-security-policy.component';
import { SuggestAddressComponent } from './suggest-address/suggest-address.component';
import { VerifyCodeEntryComponent } from './verification/verify-code-entry/verify-code-entry.component';
import { VerifyEmailComponent } from './verification/verify-email/verify-email.component';
import { VerifyPhoneComponent } from './verification/verify-phone/verify-phone.component';
import { VerifyPolicyNumComponent } from './verification/verify-policy-num/verify-policy-num.component';
import { VerifySsnComponent } from './verification/verify-ssn/verify-ssn.component';

import { BillingSharedUiModule } from '@amfam/billing/shared/ui';
import { AdjusterListModule } from '@amfam/claim/shared/adjuster-list';
import { OverviewPaperlessEnrollmentFeatureModule } from '@amfam/overview/paperless-enrollment/feature';
import { OverviewUiModule } from '@amfam/overview/ui';
import { PolicyPipesModule } from '@amfam/policy/pipes';
import { DsRouterLinkModule } from '@amfam/shared/ui/ds-router-link';
import { MobileMenuRefreshModule } from '@amfam/shared/ui/mobile-menu-refresh';
import { PipesModule } from '@amfam/shared/ui/pipes';
import { BrandDirectivesModule } from '@amfam/shared/utility/brand';
import { Magic3SearchComponent } from './magic3-search/magic3-search.component';
import { OverviewRegistrationModalComponent } from './overview-registration-modal/overview-registration-modal.component';
import { PaymentTermsComponent } from './payment-terms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    UiKitModule,
    RouterModule,
    SharedServicesModule,
    AdjusterListModule,
    ImpersonationModule,
    DsRouterLinkModule,
    PolicyPipesModule,
    PipesModule,
    RiskListModule,
    FooterModule,
    SharedRooflineModule,
    SharedSitemapModule,
    SharedHeaderModule,
    BrandDirectivesModule,
    MobileMenuRefreshModule,
    QuickLinksModule,
    ClaimSharedUtilityModule,
    OverviewUiModule,
    BillingSharedUiModule,
    OverviewPaperlessEnrollmentFeatureModule
  ],
  declarations: [
    AgentsListComponent,
    AgentsListComponent,
    AlertComponent,
    AlertsBarComponent,
    BillsOverdueAlertComponent,
    BoundContentDirective,
    CompanyInformationComponent,
    ContactUsComponent,
    DecorateFutureStateDirective,
    EnrollConfirmationComponent,
    EnrollNotificationComponent,
    EnrollTermsComponent,
    ExitWarningComponent,
    FeatureDisableDirective,
    FeatureEnableDirective,
    FeatureFlagWrapperComponent,
    SitemapComponent,
    HeaderWrapperComponent,
    LegalNoticeComponent,
    LinksAgentsSidebarComponent,
    Magic3SearchComponent,
    NotFoundComponent,
    OverviewRegistrationModalComponent,
    PaymentTermsComponent,
    PrivacySecurityPolicyComponent,
    SessionTimeoutComponent,
    SuggestAddressComponent,
    ToggleFutureStateDirective,
    VerifyCodeEntryComponent,
    VerifyEmailComponent,
    VerifyPhoneComponent,
    VerifyPolicyNumComponent,
    VerifySsnComponent
  ],
  exports: [
    AdjusterListModule,
    AgentsListComponent,
    AlertsBarComponent,
    BillsOverdueAlertComponent,
    BoundContentDirective,
    BrandDirectivesModule,
    CommonModule,
    ContactUsComponent,
    DecorateFutureStateDirective,
    DsRouterLinkModule,
    EnrollNotificationComponent,
    ExitWarningComponent,
    FeatureDisableDirective,
    FeatureEnableDirective,
    FeatureFlagWrapperComponent,
    FooterModule,
    SitemapComponent,
    HeaderWrapperComponent,
    ImpersonationModule,
    LinksAgentsSidebarComponent,
    Magic3SearchComponent,
    MobileMenuRefreshModule,
    NotFoundComponent,
    OverviewRegistrationModalComponent,
    PaymentTermsComponent,
    PipesModule,
    PolicyPipesModule,
    QuickLinksModule,
    ReactiveFormsModule,
    RiskListModule,
    SharedRooflineModule,
    RouterModule,
    SessionTimeoutComponent,
    SharedServicesModule,
    SuggestAddressComponent,
    ToggleFutureStateDirective,
    UiKitModule,
    UiModule,
    VerifyCodeEntryComponent,
    VerifyEmailComponent,
    VerifyPhoneComponent,
    VerifyPolicyNumComponent,
    VerifyCodeEntryComponent,
    FooterModule,
    RiskListModule,
    PolicyPipesModule,
    PipesModule,
    AdjusterListModule,
    SharedSitemapModule,
    SharedHeaderModule,
    MobileMenuRefreshModule,
    QuickLinksModule,
    VerifySsnComponent,
    OverviewPaperlessEnrollmentFeatureModule
  ]
})
export class SharedModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GoPaperlessConfirmationComponent } from '../containers/go-paperless-confirmation/go-paperless-confirmation.component';
import { GoPaperlessWrapperComponent } from '../containers/go-paperless-wrapper/go-paperless-wrapper.component';
import { PaperlessConfirmationWrapperComponent } from '../containers/paperless-confirmation-wrapper/paperless-confirmation-wrapper.component';
import { PaperlessGuard } from './paperless.guard';

const routes: Routes = [
  {
    path: 'sign-up',
    data: { breadcrumb: false },
    component: GoPaperlessWrapperComponent,
    canActivate: [PaperlessGuard]
  },
  {
    path: 'gopaperless/confirmation',
    data: { breadcrumb: 'Paperless Enrollment' },
    component: GoPaperlessConfirmationComponent
  },
  {
    path: 'confirmation',
    data: { breadcrumb: false },
    component: PaperlessConfirmationWrapperComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [PaperlessGuard],
  exports: [RouterModule]
})
export class PaperlessRoutingModule {}

import { BrandGuard } from '@amfam/shared/utility/brand';
import { OptimizelyGuard } from '@amfam/shared/utility/feature-flag/data-access';
import { ConfigComponent } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerResolverService } from '@amfam/ui-kit';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, FeatureFlagGuard } from './core';
import { MaintenanceComponent } from './core/maintenance/maintenance.component';
import { MaintenanceGuard } from './core/maintenance/maintenance.guard';
import { NavigationResolver } from './core/navigation/navigation-resolver.service';
import { ErrorGuards } from './error/error-routing.module';
// core
import { LoginRoutes } from './login/login-routing.module';
import { PreloadSelectedModules } from './selective-preload-strategy';
import { OutageComponent } from './core/outage/outage.component';

const appRoutes: Routes = [
  // redirect the empty route
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  },
  ...LoginRoutes,
  {
    path: 'enroll',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./enrollment/enrollment.module').then(m => m.EnrollmentModule),
    data: {
      preload: true
    }
  },
  {
    path: 'outage',
    component: OutageComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: '',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard, MaintenanceGuard],
    resolve: {
      partner: NavigationResolver
    },
    data: {
      redirectRoute: '/login',
      preload: true
    }
  }
];

const allRoutes: Routes = [
  {
    path: 'config-json',
    component: ConfigComponent,
    resolve: {
      spinner: LoadingSpinnerResolverService
    }
  },
  {
    path: '',
    canActivate: [BrandGuard, OptimizelyGuard],
    resolve: {
      spinner: LoadingSpinnerResolverService
    },
    children: [
      ...appRoutes,
      {
        path: ':expid',
        children: [...appRoutes],
        canActivate: [FeatureFlagGuard],
        data: {
          feature: 'partner',
          redirectRoute: '/not-found',
          preload: true
        }
      },
      // this one always last
      { path: '**', redirectTo: 'not-found', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(allRoutes, {
    preloadingStrategy: PreloadSelectedModules,
    enableTracing: false,
    paramsInheritanceStrategy: 'always'
})
  ],
  providers: [BrandGuard, FeatureFlagGuard, MaintenanceGuard, ErrorGuards, NavigationResolver]
})
export class AppRoutingModule {}

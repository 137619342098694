import { Action } from '@ngrx/store';
import { PaymentConfirmationModel } from '@amfam/shared/models';

export enum PaymentConfirmationActionTypes {
  PaymentConfirmationLoad = '[PaymentConfirmation] Load',
  PaymentConfirmationLoadSuccess = '[PaymentConfirmation] Load Success',
  PaymentConfirmationLoadFail = '[PaymentConfirmation] Load Fail'
}

export class PaymentConfirmationLoad implements Action {
  readonly type = PaymentConfirmationActionTypes.PaymentConfirmationLoad;
  constructor(public payload: PaymentConfirmationModel) {}
}

export class PaymentConfirmationLoadSuccess implements Action {
  readonly type = PaymentConfirmationActionTypes.PaymentConfirmationLoadSuccess;
  constructor(public payload: PaymentConfirmationModel) {}
}

export class PaymentConfirmationLoadFail implements Action {
  readonly type = PaymentConfirmationActionTypes.PaymentConfirmationLoadFail;
  constructor(public payload?: any) {}
}

export type PaymentConfirmationActions =
  | PaymentConfirmationLoad
  | PaymentConfirmationLoadSuccess
  | PaymentConfirmationLoadFail;

export const fromPaymentConfirmationActions = {
  PaymentConfirmationLoad,
  PaymentConfirmationLoadSuccess,
  PaymentConfirmationLoadFail
};

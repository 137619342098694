import { LocationStrategy } from '@angular/common';
import { Attribute, Directive, HostBinding, Input, OnDestroy, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, UrlTree } from '@angular/router';
import { NavigationService } from '@amfam/shared/utility/navigation';

@Directive({
  selector: '[dsRouterLinkActive]'
})
export class RouterLinkActiveDirective implements OnDestroy, OnChanges {
  private subscription: Subscription;
  private href: string;
  private pathToMatch: any;

  @HostBinding('class.active') activeClass: boolean;
  @Input() dsRouterLinkActive: any;
  @Input() fuzzyMatchRoute?: boolean;

  constructor(private router: Router, private navigationService: NavigationService) {
    this.subscription = router.events.subscribe(routeInstance => {
      if (routeInstance instanceof NavigationEnd) {
        this.updateActiveClass();
      }
    });
  }

  ngOnChanges(changes: {}): any {
    this.updateActiveClass();
  }
  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  private updateActiveClass(): void {
    this.pathToMatch = this.navigationService.getCommands(this.dsRouterLinkActive);

    if (this.fuzzyMatchRoute) {
      this.activeClass = this.fuzzyMatchUrl();
    } else {
      this.activeClass = this.pathToMatch === this.router.url ? true : false;
    }
  }

  private fuzzyMatchUrl(): boolean {
    const fuzzyUrl = this.router.url.substring(0, this.pathToMatch.length);
    return fuzzyUrl === this.pathToMatch ? true : false;
  }
}

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { DockingBarService, LoadingSpinnerService, PageTitleService } from '@amfam/ui-kit';
import { Location } from '@angular/common';
import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ContentPaths, ContentService } from '../../../core';
import { FooterLinksComponentDirective } from '../footer-links.component';

@Component({
  selector: 'app-privacy-security-policy',
  templateUrl: './privacy-security-policy.component.html',
  styleUrls: ['./privacy-security-policy.component.scss']
})
export class PrivacySecurityPolicyComponent
  extends FooterLinksComponentDirective
  implements OnInit, OnDestroy
{
  content = '';

  constructor(
    dockingService: DockingBarService,
    route: ActivatedRoute,
    application: ApplicationRef,
    private contentService: ContentService,
    private pageTitle: PageTitleService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade,
    private location: Location
  ) {
    super(dockingService, route, application);
    this.spinner.start();
  }

  ngOnInit() {
    const contentPath: ContentPaths = 'privacyandsecuritypolicy';
    super.ngOnInit();
    this.contentService
      .getContent(contentPath, '')
      .pipe(takeUntil(this.stop$))
      .subscribe(
        content => {
          this.content = content.richContent;
          this.spinner.stop();
          this.onContentLoad();
        },
        error => this.spinner.stop()
      );
    const title = 'Privacy & Security Policy';
    this.pageTitle.set(title);
    this.dockingService.clear();
    // A PageAnalytic needs to be set up, contact Heather Mills with screenshots
    // this.analyticsFacade.trackPage(BillingAnalytics.pageTermsAndConditions);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public back() {
    this.location.back();
  }
}

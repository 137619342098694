import { ClaimAction, ClaimActionTypes } from './claim.actions';

import { ApiStatus } from '@amfam/shared/models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { keyBy as _keyBy, values as _values, get as _get, has as _has } from 'lodash';

import { Claim } from '../models';
import { Update } from '@ngrx/entity/src/models';

export const CLAIM_FEATURE_KEY = 'claim';

export interface ClaimState extends EntityState<Claim> {
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
  error: string;
  selectedEntityId: string;
  status: ApiStatus;
  needRefresh: boolean;
}

export const adapter: EntityAdapter<Claim> = createEntityAdapter<Claim>({
  selectId: (claimPayload: Claim) => claimPayload.claimNumber,
  sortComparer: false
});

export const initialState: ClaimState = adapter.getInitialState({
  loaded: false,
  loading: false,
  hasError: false,
  error: null,
  selectedEntityId: null,
  status: null,
  needRefresh: false
});

export function claimReducer(state = initialState, action: ClaimAction): ClaimState {
  let newState;
  let oldClaim: Claim = null; // claim with old data
  let updatedClaim: Claim = null; // claim with new data attached
  let claimUpdate: Update<Claim> = null; // ngrx type Update<Claim> used with entity adapter

  switch (action.type) {
    // The claim list load initial state
    case ClaimActionTypes.LoadClaims:
      return Object.assign({}, state, {
        loading: true,
        loaded: false,
        hasError: false,
        status: {},
        needRefresh: false
      });

    // Claim load summary list successful
    case ClaimActionTypes.LoadClaimsSuccess:
      newState = Object.assign({}, state, {
        hasHiddenClaims: _get(action, 'payload.hasHiddenClaims', false),
        loaded: true,
        loading: false
      });
      const claims = _get(action.payload, 'claims', []);
      return adapter.setAll(claims, newState);

    // Claim load summary list failed
    case ClaimActionTypes.LoadClaimsFail:
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    // Individual Claim Detail initial state
    case ClaimActionTypes.LoadClaimDetail:
      oldClaim = state.entities[action.payload.claimNumber];
      updatedClaim = Object.assign({}, oldClaim, {
        claimNumber: action.payload.claimNumber,
        detailLoaded: false,
        loading: true,
        hasError: false,
        exposureId: '',
        exposureIdLoaded: false,
        status: {}
      });

      newState = Object.assign({}, state, {
        loading: true
      });
      return adapter.upsertOne(updatedClaim, newState);

    // Individual Claim Detail Success
    case ClaimActionTypes.LoadClaimDetailSuccess:
      const claimNum = _get(action, 'payload.response.claim.claimNumber');
      oldClaim = state.entities[claimNum];
      updatedClaim = Object.assign({}, oldClaim, action.payload.response.claim, {
        detailLoaded: true,
        detailUnavailable: false,
        loading: false,
        exposureId: _get(action, 'payload.response.claim.claimDetail.exposures[0].exposureId'),
        exposureIdLoaded: true
      });
      claimUpdate = createClaimUpdate(oldClaim.claimNumber, updatedClaim);

      newState = Object.assign({}, state, {
        loading: false
      });
      return adapter.updateOne(claimUpdate, newState);

    // Individual Claim Detail Fail
    case ClaimActionTypes.LoadClaimDetailFail:
      oldClaim = state.entities[action.payload.claimNumber];
      updatedClaim = Object.assign({}, oldClaim, {
        claimNumber: action.payload.claimNumber,
        detailLoaded: false,
        detailUnavailable: true,
        loading: false,
        hasError: true,
        status: action.payload.status
      });
      claimUpdate = createClaimUpdate(oldClaim.claimNumber, updatedClaim);

      newState = Object.assign({}, state, {
        loading: false
      });
      return adapter.updateOne(claimUpdate, newState);

    /* After 5 retries if the claim details are not fetched then this action is executed where all
    errors are set to false at claims level and claim detail level.
    */
    case ClaimActionTypes.DetailUnavailibleIgnoreErrors:
      oldClaim = state.entities[action.payload];
      updatedClaim = Object.assign({}, oldClaim, {
        claimNumber: action.payload,
        detailLoaded: false,
        detailUnavailable: true,
        loading: false,
        hasError: false,
        claimStatus: 'OPEN',
        claimProgress: 'SUBMISSION',
        error: '',
        useProgressTrackingIndicator: true
      });
      claimUpdate = createClaimUpdate(oldClaim.claimNumber, updatedClaim);

      newState = Object.assign({}, state, {
        hasError: false,
        error: false,
        loading: false
      });
      return adapter.updateOne(claimUpdate, newState);

    case ClaimActionTypes.SetSelectedClaim:
      return Object.assign({}, state, {
        selectedEntityId: _has(state.entities, action.payload) ? action.payload : null
      });

    case ClaimActionTypes.UpdateClaimField:
      oldClaim = state.entities[action.payload.claimNumber];
      updatedClaim = Object.assign({}, oldClaim, action.payload.claimFields);
      return adapter.upsertOne(updatedClaim, state);

    case ClaimActionTypes.GetExposureIdSuccess:
      oldClaim = state.entities[action.payload.claimNumber];
      updatedClaim = Object.assign({}, oldClaim, {
        exposureId: action.payload.exposureId,
        exposureIdError: false,
        exposureIdLoaded: true
      });
      claimUpdate = createClaimUpdate(oldClaim.claimNumber, updatedClaim);
      return adapter.updateOne(claimUpdate, state);

    case ClaimActionTypes.GetExposureIdFail:
      oldClaim = state.entities[action.payload.claimNumber];
      updatedClaim = Object.assign({}, state.entities[action.payload.claimNumber], {
        exposureIdError: true,
        exposureIdLoaded: true
      });
      claimUpdate = createClaimUpdate(oldClaim.claimNumber, updatedClaim);
      return adapter.updateOne(claimUpdate, state);

    case ClaimActionTypes.ClearSelectedClaim:
      return Object.assign({}, state, {
        selectedEntityId: null
      });

    case ClaimActionTypes.RequireClaimsRefresh:
      return Object.assign({}, state, {
        needRefresh: true
      });

    default:
      return state;
  }
}

function createClaimUpdate(claimNumber: string, updatedEntity: Claim): Update<Claim> {
  return {
    id: claimNumber,
    changes: updatedEntity
  };
}

export const getSelectedEntityId = (state: ClaimState) => state.selectedEntityId;

// get the selectors
const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

// select the array of claim ids (claim number)
export const selectEntityIds = selectIds;

// select the dictionary of claim entities
export const selectClaimEntities = selectEntities;

// select the array of claims
export const selectAllClaims = selectAll;

<nav role="navigation" class="mobile-navigation">
  <div class="mobile-navigation-container">
    <div class="mobile-navigation-menu">
      <button
        #menuLink
        aria-controls="navigationMobileMenu"
        class="menu-icon"
        data-cy="navigationMobileMenuButton"
        [ngClass]="mobileMenuOpen ? 'icon-exit' : 'icon-search-menu'"
        [attr.aria-expanded]="mobileMenuOpen"
        (click)="toggleNav()"
      >
        <span>Menu</span>
      </button>
    </div>
    <div class="mobile-navigation-logo">
      <a
        target="_self"
        [href]="homeUrl"
        [attr.tabIndex]="mobileMenuOpen ? '-1' : '0'"
        [attr.title]="headerLogo?.altText ? headerLogo?.altText : 'logo'"
      >
        <div
          class="logo-container column text-center"
          [style.backgroundImage]="'url(' + headerLogo?.logoURL + ')'"
          [style.height.px]="headerLogo?.height"
        ></div>
      </a>
    </div>
    <ds-header-mobile-notification
      *ngIf="(loggedIn$ | async) && (notifications$ | async)"
      [notifications$]="notifications$"
    ></ds-header-mobile-notification>
  </div>

  <div role="menu" id="navigationMobileMenuItems" data-cy="navigationMobileMenuItems">
    <ul
      data-cy="navigationMobileMenu"
      id="navigationMobileMenu"
      role="navigation"
      [@toggleMenu]="mobileMenuOpen"
      [class.loggedIn]="loggedIn$ | async"
    >
      <ds-header-mobile-navigation-item
        *ngFor="let link of linkArray; trackBy: trackByFn"
        class="link-item"
        #navItem
        [link]="link"
        [style.display]="mobileMenuOpen ? 'block' : 'none'"
        (linkClickEvent)="linkClicked($event)"
        (linkBlurEvent)="resetOnBlur($event)"
        (sublinkClickEvent)="sublinkClicked($event)"
        (subOpenEvent)="hideOtherSubMenus($event)"
        (sublinkTitleClickEvent)="sublinkTitleClicked()"
      >
      </ds-header-mobile-navigation-item>
    </ul>
  </div>
</nav>

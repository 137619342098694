import { ApiStatus } from '@amfam/shared/models';

export interface DeviceInformation {
  browser: string;
  browserVersion: string;
  device: string;
  os: string;
  osVersion: string;
  userAgent: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export interface Session {
  status: ApiStatus;
  loggedIn: boolean;
  hasError: boolean;
  isLoading: boolean;
  sessionValidUntil: string;
  differenceInMilliseconds: number;
  device: DeviceInformation;
}

export const initialSession: Session = {
  status: null,
  loggedIn: false,
  hasError: false,
  isLoading: true,
  sessionValidUntil: null,
  differenceInMilliseconds: null,
  device: null
};

export interface GatewayTime {
  utcFormat: string;
  epochMilliseconds: string;
  epochSeconds: string;
  iso8601Format: string;
  rfc1123Format: string;
  rfc850Format: string;
  asciiFormat: string;
  userFormat: string;
  status: any;
}

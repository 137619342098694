import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { DeliveryPreferences, PreferenceItem } from '@amfam/shared/models';
import { Subject } from 'rxjs';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { takeUntil, take } from 'rxjs/operators';
import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';
import { get as _get } from 'lodash';
@Component({
  selector: 'ds-billing-communication-preferences',
  templateUrl: './billing-communication-preferences.component.html',
  styleUrls: ['../communication-preferences/communication-preferences.component.scss']
})
export class BillingCommunicationPreferencesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() preferenceData: PreferenceItem[];
  @Input() clearWarning: number;
  @Input() adminPartnerDisabled: boolean | undefined;
  @Output() billingPreferencesChangeEvent = new EventEmitter();
  billingPreferencesForm: UntypedFormGroup;
  billingPreferenceCtrl: AbstractControl;
  canWarn: boolean[];

  // Shared subject for completing observables
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public roleService: ImpersonateRolesService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit() {}

  /**
   * To have access to this.billingPreferencesForm form before and after updates.
   */
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.clearWarning) {
      this.canWarn = this.preferenceData.map(() => false);
    }
    this.preferenceData = <PreferenceItem[]>_get(simpleChanges, 'preferenceData.currentValue', []);
    const formControlArray = this.preferenceData.map(preference => {
      return [preference.deliveryPreference];
    });

    this.billingPreferencesForm = this.formBuilder.group({
      billingPreferences: this.formBuilder.array(formControlArray)
    });
    // Map preferenceData value false to not show warnings initially.
    this.canWarn = this.preferenceData.map(() => false);

    // When user makes changes, items that started as email can have warnings
    this.billingPreferencesForm.valueChanges.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.canWarn = this.preferenceData.map(preference => {
        return preference.deliveryPreference === DeliveryPreferences.EMAIL;
      });
    });

    this.billingPreferencesForm.valueChanges.pipe(takeUntil(this.stop$)).subscribe(formData => {
      this.billingPreferencesChangeEvent.emit(formData.billingPreferences);
    });
  }

  get billingPreferences(): UntypedFormArray {
    return this.billingPreferencesForm.get('billingPreferences') as UntypedFormArray;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

export interface KydSmartphoneRole {
  customerId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phones: string[];
  enrollStatus: string;
  enrolledPhone: string;
  operatorId: string;
  operatorEnrollmentStatus: string;
}

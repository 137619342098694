import { AutoPaySelectors } from '@amfam/billing/auto-pay/data-access';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { PolicySelectors } from '@amfam/policy/data-access';
import { Discount, Policy, PolicyDiscounts, Vehicle } from '@amfam/policy/models';
import { EntityNotification } from '@amfam/shared/models';
import { createSelector } from '@ngrx/store';
import { flatMap as _flatMap, get as _get } from 'lodash';

export const getBillAccountsEnrolledInAutoPay = createSelector(
  BillAccountsSelectors.getAllBillAccounts,
  AutoPaySelectors.getAutomaticPayments,
  (billAccounts, automaticPayments) =>
    billAccounts.filter(billAccount => {
      const billAccountHasAutoPay: boolean = automaticPayments
        .map(autoPay => autoPay?.billAccountNumber)
        .includes(billAccount.billAccountNumber);
      return billAccountHasAutoPay;
    })
);

export const getBillAccountsWithTPIPolicy = createSelector(
  BillAccountsSelectors.getAllBillAccounts,
  PolicySelectors.getPropertyPoliciesWithTPI,
  (billAccounts, policiesWithTPI) =>
    billAccounts.filter(billAccount => {
      const billAccountHasTPIPolicy: boolean = policiesWithTPI
        .map(policy => policy.billingAccountNumber)
        .includes(billAccount.billAccountNumber);
      return billAccountHasTPIPolicy;
    })
);

export const billAccountHasClassicPolicy = (billAccountNumber: string) =>
  createSelector(PolicySelectors.getClassicPolicies, classicPolicies =>
    classicPolicies.map(policy => policy.billingAccountNumber).includes(billAccountNumber)
  );

/*
  A bill account is eligible for autopay under the following conditions:
    1) It is modifiable
    2) It does not have a policy with a TPI
    3) It is not already enrolled in AutoPay
    4) No error loading autopay
*/
export const getBillAccountsEligibleForAutoPay = createSelector(
  BillAccountsSelectors.getModifiableBillAccounts,
  getBillAccountsWithTPIPolicy,
  getBillAccountsEnrolledInAutoPay,
  AutoPaySelectors.getAutoPayNotifications,
  (billAccounts, billAccountsWithTPI, billAccountsWithAutoPay, notifications) =>
    billAccounts.filter(billAccount => {
      const billAccountHasTPI: boolean = billAccountsWithTPI
        .map(ba => ba.billAccountNumber)
        .includes(billAccount.billAccountNumber);
      const billAccountHasAutoPay: boolean = billAccountsWithAutoPay
        .map(ba => ba.billAccountNumber)
        .includes(billAccount.billAccountNumber);

      const billAccountNotification: EntityNotification = notifications.find(
        notification => notification.id === billAccount.billAccountNumber
      );
      const autoPayLoadError: boolean = _get(billAccountNotification, 'hasError', false);

      return !billAccountHasTPI && !billAccountHasAutoPay && !autoPayLoadError;
    })
);

export const getBillAccountsIneligibleForAutoPay = createSelector(
  BillAccountsSelectors.getAllBillAccounts,
  getBillAccountsEligibleForAutoPay,
  (billAccounts, billAccountsEligibleForAutoPay) =>
    billAccounts.filter(
      billAccount =>
        !billAccountsEligibleForAutoPay
          .map(eligibleAccount => eligibleAccount.billAccountNumber)
          .includes(billAccount.billAccountNumber)
    )
);

export const billAccountHasAutoPayDiscount = (billAccountNumber: string) =>
  createSelector(PolicySelectors.getPolicies, policies => {
    const billAccountPolicies: Policy[] = policies.filter(
      policy => policy.billingAccountNumber === billAccountNumber
    );

    const policyVehicles: Vehicle[] = _flatMap(billAccountPolicies, (policy: Policy) =>
      _get(policy, 'vehicles', [])
    );

    const policyDiscounts: Discount[] = _flatMap(billAccountPolicies, (policy: Policy) =>
      _get(policy, 'policyDiscounts', [])
    );

    const vehicleDiscounts: Discount[] = _flatMap(policyVehicles, (vehicle: Vehicle) =>
      _get(vehicle, 'vehicleDiscounts', [])
    );

    const vehiclesDiscountOnPolicy: boolean = vehicleDiscounts
      .map(vehicle => vehicle.code)
      .includes(PolicyDiscounts.AUTO_AUTOPAY);

    const automobileAutoPayDiscount: boolean = policyDiscounts
      .map(discount => discount.code)
      .includes(PolicyDiscounts.AUTO_AUTOPAY);

    const propertyAutoPayDiscount: boolean = policyDiscounts
      .map(discount => discount.code)
      .includes(PolicyDiscounts.PROPERTY_AUTOPAY);

    return automobileAutoPayDiscount || vehiclesDiscountOnPolicy || propertyAutoPayDiscount;
  });

export const isEligibleForFullPayDiscount = (billAccountNumber: string) =>
  createSelector(
    BillAccountsSelectors.getBillAccount(billAccountNumber),
    billAccount => _get(billAccount, 'fullPayBalance', 0) > 0
  );

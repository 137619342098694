<ds-modal
  [id]="findVinFlowModalId"
  [closeOthers]="true"
  [maxWidth]="modalWidth"
  (onCloseModalEvent)="close()"
>
  <div>
    <h2 class="text-center"><b>{{vinContent.headerText}}</b></h2>

    <div class="row pad-10">
      <div class="column twelve">
        <div class="text-container">
          <div class="text-block">
            <p class="margin-0">
              {{vinContent.definition}}
            </p>
          </div>

          <div class="text-block">
            <h2 class="semi-bold wrap-icon">
              <span class="icon-license-plate"></span> {{vinContent.documentsSubHeader}}
            </h2>
            <p class="margin-0">
              {{vinContent.vinOnDocument}}
            </p>
          </div>
          <div class="text-block">
            <h2 class="semi-bold wrap-icon"><span class="icon-auto"></span>{{vinContent.yourVehicleSubHeaderText}}</h2>
            <p class="margin-0">
              {{vinContent.vinOnVehicle}}
            </p>
          </div>

          <div class="image-container">
            <img src="/assets/images/where-to-find-vin.svg" alt="where to find the VIN" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ds-modal>

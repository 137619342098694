import { omit as _omit } from 'lodash';

import { PendingPayment, initialPendingPayment } from '../models/pending-payment.models';
import * as pendingPaymentActions from './pending-payment.actions';
import { Entities, initialEntities } from '../models/entity/entity.model';

// This reduces a set of payment history
export function pendingPaymentReducer(
  state = initialEntities<PendingPayment>(),
  action: pendingPaymentActions.PendingPaymentAction
): Entities<PendingPayment> {
  const entities = Object.assign({}, state.entities);

  switch (action.type) {
    case pendingPaymentActions.ADD_PENDING_PAYMENT:
      entities[action.payload.billAccountNumber] = Object.assign(
        {},
        initialPendingPayment,
        action.payload
      );
      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities
      });

    case pendingPaymentActions.ADD_PENDING_PAYMENTS:
      action.payload.forEach((pending: PendingPayment) => {
        entities[pending.billAccountNumber] = Object.assign({}, initialPendingPayment, pending);
      });
      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities
      });

    case pendingPaymentActions.CLEAR_PENDING_PAYMENTS:
      return Object.assign({}, state, {
        ids: [],
        entities: {}
      });

    case pendingPaymentActions.REMOVE_PENDING_PAYMENT:
      const newIdsArray: any[] = state.ids.filter(id => {
        return id !== action.payload.billAccountNumber;
      });
      const newObj = Object.assign({}, state, {
        ids: newIdsArray,
        entities: _omit(state.entities, action.payload.billAccountNumber),
        loaded: false
      });
      return newObj;

    default:
      return state;
  }
}

export const getLoading = (state: Entities<PendingPayment>) => state.loading;

export const getEntities = (state: Entities<PendingPayment>) => state.entities;

export const getIds = (state: Entities<PendingPayment>) => state.ids;

import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import { MaeFeatureActions } from '@amfam/mae/feature';
import {
  AnalyticsFacade,
  AutomaticPaymentsAnalytics,
  AutomaticPaymentsAnalyticsAdmin
} from '@amfam/shared/analytics';
import { BillAccount } from '@amfam/shared/models';
import { ArrayToList, PrettyBillingAcctNum } from '@amfam/shared/ui/pipes';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';
import { DockingBarService, Option, ValidationService } from '@amfam/ui-kit';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { differenceInMilliseconds } from 'date-fns';
import { flatMap as _flatmap } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as AutoPaySetupActions from '../../+state/auto-pay-setup.actions';
import { AutoPaySteps } from '../../+state/auto-pay-setup.models';
import * as AutoPaySetupSelectors from '../../+state/auto-pay-setup.selectors';
import { AutoPayUtilService } from '../../util/auto-pay-util.service';

@Component({
  selector: 'ds-bill-account-selection-wrapper',
  templateUrl: './bill-account-selection-wrapper.component.html',
  styleUrls: ['./bill-account-selection-wrapper.component.scss']
})
export class BillAccountSelectionWrapperComponent implements OnInit {
  billAccountOptions$: Observable<Option[]>;
  billAccountSelectionFormGroup: UntypedFormGroup;
  tertiaryButtonName: string;
  isMyAccountAdmin: boolean;
  isUserComingFromEnrollment: boolean;

  constructor(
    private store: Store,
    private dockingBar: DockingBarService,
    private autoPayUtil: AutoPayUtilService,
    private formBuilder: UntypedFormBuilder,
    private analyticsFacade: AnalyticsFacade,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.isMyAccountAdmin = this.applicationService.isApp(Applications.MYACCOUNT_ADMIN);

    this.dockingBar.registerHeading('Set Up AutoPay');
    this.billAccountOptions$ = this.store
      .select(AutoPayUtilSelectors.getBillAccountsEligibleForAutoPay)
      .pipe(
        take(1),
        map((billAccounts: BillAccount[]) =>
          billAccounts.sort((d1, d2) =>
            differenceInMilliseconds(d2.paymentDueDate, d1.paymentDueDate)
          )
        ),
        map((billAccounts: BillAccount[]) =>
          billAccounts.map((billAccount: BillAccount) => ({
            text: new ArrayToList().transform(
              _flatmap(billAccount.policyList, policy => policy.riskDescriptionList)
            ),
            helpText: [
              {
                text: `Acct ${new PrettyBillingAcctNum().transform(billAccount.billAccountNumber)}`
              }
            ],
            id: billAccount.billAccountNumber
          }))
        )
      );
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackPage(AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPayments);
    } else {
      this.analyticsFacade.trackPage(AutomaticPaymentsAnalytics.pageMultipleAutomaticPayments);
    }

    this.store
      .select(AutoPaySetupSelectors.getPreselectedAccounts)
      .pipe(take(1))
      .subscribe(preselectedAccounts => {
        this.getForm(preselectedAccounts);
      });

    this.store
      .select(AutoPaySetupSelectors.isUserComingFromEnrollment)
      .pipe(take(1))
      .subscribe(isUserComingFromEnrollment => {
        this.tertiaryButtonName = isUserComingFromEnrollment ? 'Skip AutoPay Setup' : 'Cancel';
        this.isUserComingFromEnrollment = isUserComingFromEnrollment;
      });
  }

  getForm(preselectedBillAccountNumbers: string[]): void {
    this.billAccountOptions$.pipe(take(1)).subscribe((billAccountOptions: Option[]) => {
      this.billAccountSelectionFormGroup = this.formBuilder.group(
        {
          option: new UntypedFormArray(
            billAccountOptions.map(option => {
              const initialValue: boolean = preselectedBillAccountNumbers.includes(
                <string>option.id
              );
              return new UntypedFormControl(initialValue);
            })
          )
        },
        { validators: ValidationService.minimumCheckedCheckboxCheckedValidator() }
      );
    });
  }

  submit() {
    this.billAccountOptions$.pipe(take(1)).subscribe(billAccountOptions => {
      // Get IDs of selected accounts
      const selectedBillAccountNumbers: string[] = [];
      this.billAccountSelectionFormGroup.value.option.forEach((option, i) => {
        if (option) {
          selectedBillAccountNumbers.push(<string>billAccountOptions[i].id);
        }
      });
      this.store.dispatch(
        AutoPaySetupActions.setPreselectedAccounts({
          billAccountNumbers: selectedBillAccountNumbers
        })
      );
      this.autoPayUtil.addMultipleAutoPay(selectedBillAccountNumbers);
    });
  }

  cancel() {
    let entryPoint: string;
    this.store
      .select(AutoPaySetupSelectors.getEntryPoint)
      .pipe(take(1))
      .subscribe(ep => {
        entryPoint = ep;
      });
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackButtonClick(AutomaticPaymentsAnalyticsAdmin.buttonSkipAutoPaySetup);
      this.store.dispatch(AutoPaySetupActions.setStep({ step: AutoPaySteps.SKIP }));
      if (this.isUserComingFromEnrollment) {
        this.store.dispatch(MaeFeatureActions.skipAutoPay());
      } else {
        this.store.dispatch(
          new fromRouterActions.Go({
            path: [entryPoint]
          })
        );
      }
    } else {
      this.analyticsFacade.trackButtonClick(AutomaticPaymentsAnalytics.buttonSkipAutoPaySetup);
      this.store.dispatch(
        new fromRouterActions.Go({
          path: [entryPoint]
        })
      );
    }
  }
}

export interface OpportunityContentCopy {
  agent: Agent;
}

export interface Agent {
  error: ConfirmationPage;
  success: ConfirmationPage;
}

export interface ConfirmationPage {
  heading: string;
  description: string;
  icon: Icon;
}

export interface Icon {
  url?: string;
  class?: string;
}

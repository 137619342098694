import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaymentConfirmationEffects } from './+state/paymentConfirmation.effects';
import {
  paymentConfirmationReducers,
  PAYMENT_CONFIRMATION_FEATURE_KEY
} from './+state/paymentConfirmation.selectors';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([PaymentConfirmationEffects]),
    StoreModule.forFeature(PAYMENT_CONFIRMATION_FEATURE_KEY, paymentConfirmationReducers)
  ]
})
export class BillingPaymentconfirmationDataAccessModule {}

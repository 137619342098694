import { ApiStatus, AddEditPaymentAccountDTO } from '@amfam/shared/models';

export class FinancialInstitutionModel {
  financialInstitutionName: string;
  loading: boolean;
  status: any;
  messages: any;
  foundFinancialInstitution: boolean;
  loaded: boolean;
}

export const initialFinancialInstitutionModel: FinancialInstitutionModel = {
  financialInstitutionName: '',
  loading: false,
  status: null,
  messages: null,
  foundFinancialInstitution: false,
  loaded: false
};

export interface FetchPciTokenModel {
  paymentAccountDTO?: AddEditPaymentAccountDTO;
  routingNumber?: string;
}
export interface PciAuthResponseModel {
  pciToken: string;
  status: ApiStatus;
}

export interface FinancialAccountModel {
  apiStatus: ApiStatus;
  consumerKey: string;
  tokenId?: string;
  financialInstitute?: FinancialInstituteModel;
}

export interface FinancialInstituteModel {
  FinanacialInstitutionName: string;
  routingNumber: string;
  addressLines: string[];
  isActive: string;
}
export interface FinancialAccountResponseModel {
  finAcctServiceResponse: FinancialAccountModel;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { get as _get, forEach as _forEach } from 'lodash';

import { ConfigService } from '@amfam/shared/utility/shared-services';
import { IdentificationModel } from '../../../core/store/enrollment/personal/identification';
import { PartyModel } from '../../../core/store/enrollment/personal/party';

// date-fns
import { format } from 'date-fns';

@Injectable()
export class IdentificationService {
  public showPersonalInfo = false;
  private partyApi: string = this.config.get('partyApi');

  constructor(private config: ConfigService, private http: HttpClient) {}

  hasProgram(routeData, programName: string): boolean {
    return _get(routeData, 'programs', []).some(program => {
      return _get(program, 'program') === programName;
    });
  }

  getPartyModel(response: any): PartyModel {
    const party: PartyModel = {
      partyId: '',
      partnerId: '',
      typeOfPartyCode: '',
      firstName: '',
      middleName: '',
      lastName: '',
      socialSecurityNumberOnRecordIndicator: false,
      maskedPhones: [],
      maskedEmails: [],
      match: 0
    };
    if (response && response.status.code === 403) {
      // 403 is a forbidden status code which means it is a multiple match scenario
      // and the user is not authorized to view multiple customer's data
      party.match = 3;
    } else if (response && response.status.code === 200) {
      // exact party match found
      party.typeOfPartyCode = response.resultingParty.typeOfPartyCode;
      party.partnerId = response.resultingParty.partnerId;
      party.firstName = response.resultingParty.person.name.firstName;
      party.middleName = response.resultingParty.person.name.middleName;
      party.lastName = response.resultingParty.person.name.lastName;
      party.socialSecurityNumberOnRecordIndicator =
        ['true', true].indexOf(
          response.resultingParty.person.socialSecurityNumberOnRecordIndicator
        ) > -1;
      party.maskedPhones = response.resultingParty.maskedPhones;
      party.maskedEmails = response.resultingParty.maskedEmails;
      party.partyId = response.resultingParty.partyId;
      party.match = 1;
    }
    return party;
  }

  search(model: IdentificationModel): Observable<any> {
    const url = this.partyApi + 'anonymousparties';
    let headers = new HttpHeaders({
      'AFI-UserId': this.config.get('processId')
    });
    const params = this.getParams(model);
    return this.http.get(url, { headers: headers, params: params });
  }

  private getParams(paramsObjModel: IdentificationModel): HttpParams {
    const params: HttpParams = new HttpParams()
      .set('partnerId', paramsObjModel.partnerId)
      .set('lastName', paramsObjModel.lastName)
      .set('zip5', paramsObjModel.zipcode)
      .set('dateOfBirth', format(new Date(paramsObjModel.dob), 'MMDDYYYY'))
      .set('authId', this.config.get('processId'));
    return params;
  }

  private replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
}

import { of as observableOf, Observable, Subscription, BehaviorSubject } from 'rxjs';

import { take, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { VerifyUserModel } from '../../shared/verify-user.model';
import { VerifyUserPhoneModel } from '../../shared/verify-user-phone.model';
import { VerifyTokenModel } from '../../shared/verify-token.model';
import { ResetRoutesService } from '../../personal/shared/personal-enrollment-guards/reset-routes.service';
import { IdentificationModel } from '../../../core/store/enrollment/personal/identification';

import { stepNames } from '../../../core/store/workflow/workflow.model';
import * as fromRoot from '../../../core/store/';
import * as workflowActions from '../../../core/store/workflow';
import { fromRouterActions } from '@amfam/shared/utility/navigation';

// date-fns
import { format } from 'date-fns';

@Injectable()
export class VerificationService {
  public static myAccountExists: boolean;

  private verifyAttempts = 0;

  private basePartyUrl: string;
  private baseDigitalAcctUrl: string;
  private partnerIdSub: Subscription;
  private partnerId: string;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private resetEnrollment: ResetRoutesService,
    private store: Store<fromRoot.RootState>,
    private featureFlagService: FeatureFlagService
  ) {
    this.basePartyUrl = this.config.get('partyApi');
    this.baseDigitalAcctUrl = this.config.get('digitalAccountApi') + '/digitalaccounts';
    this.partnerIdSub = this.store
      .select(BrandSelectors.getPartnerId)
      .subscribe(partnerId => (this.partnerId = partnerId));
  }

  selectOption(route) {
    // complete the step
    this.store.dispatch(
      new workflowActions.WorkflowStepCompleteAction({
        step: stepNames.verificationOptions,
        nextRoute: ['verify', route]
      })
    );
  }

  createUrl(baseUrl: string, paramsObjModel: IdentificationModel, num: string, method: string) {
    let currentFormatDob: any = paramsObjModel.dob;
    currentFormatDob = new Date(currentFormatDob);
    const operation = 'anonymousparties';
    const lastNameParam = '?lastName=' + paramsObjModel.lastName;
    const dobParam = '&dateOfBirth=' + format(currentFormatDob, 'MMDDYYYY');
    const zipParam = '&zip5=' + paramsObjModel.zipcode;
    const partnerParam = '&partnerId=' + paramsObjModel.partnerId;
    const authId = '&authId=' + this.config.get('processId');
    const ssnParam = '&last4OfSocialSecurityNumber=';
    const policyNumberParam = '&policyIdentifier=';
    let paramsQueryString = operation + lastNameParam + zipParam + dobParam + partnerParam + authId;
    if (method === 'policyNum') {
      paramsQueryString += policyNumberParam + num;
    } else if (method === 'ssn') {
      paramsQueryString += ssnParam + num;
    }
    return baseUrl + paramsQueryString;
  }

  resetVerifyAttempts() {
    this.verifyAttempts = 0;
  }

  ssn(
    ssn: string,
    identificationModel: IdentificationModel
  ): Observable<any> {
    const url = this.createUrl(this.config.get('partyApi'), identificationModel, ssn, 'ssn');

    return this.http.get(url, { headers: this.getHeaders() }).pipe(
      mergeMap((data: any) => {
        return observableOf(data);
      })
    );
  }

  policyNumber(
    policyNum: string,
    identificationModel: IdentificationModel
  ): Observable<any> {
    const url = this.createUrl(
      this.config.get('partyApi'),
      identificationModel,
      policyNum,
      'policyNum'
    );

    return this.http.get(url, { headers: this.getHeaders() }).pipe(
      mergeMap((data: any) => {
        return observableOf(data);
      })
    );
  }

  email(requestObj: VerifyUserModel): Observable<any> {
    // Actual Service Implementation
    const operation = '/verifyUser';

    return this.http.post(this.baseDigitalAcctUrl + operation, JSON.stringify(requestObj)).pipe(
      mergeMap((data: any) => {
        if (data && data.status.code === 200) {
          return observableOf(true);
        } else {
          return observableOf(false);
        }
      })
    );
  }

  pin(requestObj: VerifyTokenModel) {
    const url = this.baseDigitalAcctUrl;
    const operation = '/confirmUser';

    // TODO update this service call when API is finished
    return this.http.post(url + operation, JSON.stringify(requestObj));
  }

  phone(requestObj: VerifyUserPhoneModel): Observable<any> {
    // Actual Service Implementation
    const operation = '/verifyUser';

    return this.http.post(this.baseDigitalAcctUrl + operation, JSON.stringify(requestObj)).pipe(
      mergeMap((data: any) => {
        if (data && data.status.code === 200) {
          return observableOf(true);
        } else {
          return observableOf(false);
        }
      })
    );
  }

  text(requestObj: VerifyUserPhoneModel): Observable<any> {
    // Actual Service Implementation
    const operation = '/verifyUser';

    return this.http.post(this.baseDigitalAcctUrl + operation, JSON.stringify(requestObj)).pipe(
      mergeMap((data: any) => {
        if (data && data.status.code === 200) {
          return observableOf(true);
        } else {
          return observableOf(false);
        }
      })
    );
  }

  checkIfMyAccountExists(partyId: string): Observable<any> {
    const url: string = this.baseDigitalAcctUrl + '?customerId=' + encodeURIComponent(partyId);
    return this.http.get(url).pipe(take(1));
  }

  checkIfMyAccountExistsEmail(email: string): Observable<any> {
    const url: string =
      this.baseDigitalAcctUrl +
      '?emailAddress=' +
      encodeURIComponent(email) +
      (this.partnerId ? '&partnerId=' + encodeURIComponent(this.partnerId) : '');
    return this.http.get(url).pipe(take(1));
  }

  incrementAttempts() {
    this.verifyAttempts += 1;

    if (this.verifyAttempts >= 5) {
      this.verifyAttempts = 0;
      this.resetEnrollment.resetEnrollmentSteps();
      this.store.dispatch(
        new fromRouterActions.Go({
          path: ['/enroll/contact-us']
        })
      );
      return;
    }

    return this.verifyAttempts;
  }

  private getHeaders() {
    let headers = new HttpHeaders({ 'AFI-UserId': this.config.get('processId') });
    return headers;
  }
}

export interface IPremium {
  amount: string;
  term?: string;
}

export interface IQuote {
  premiumPerMonth: IPremium;
  premiumOther?: IPremium;
  header?: string;
  subText?: string;
}

export interface QuoteSummaryModel {
  option: string;
  value: string;
  isCheckbox?: boolean;
  coverageType?: string;
  coverageHelpTextList?: string[];
  highlight?: boolean;
}

export interface QuoteModel {
  quoteMonthlyDollars: string;
  quoteMonthlyCents: string;
  quoteTerm: number;
  quoteTotalAmount: number;
}

export interface PaymentScheduleItem {
  invoiceDate: Date;
  changeAmount: number;
  estPaymentAmount: number;
  amount: number;
  dueDate: Date;
  status: PaymentScheduleStatusType;
}

export enum PaymentScheduleStatusType {
  PLANNED = 'planned',
  BILLED = 'billed',
  DUE = 'due'
}

export interface IQuotes {
  current: IQuote;
  new: IQuote;
}

export interface IArticle {
  headerContentRef: string;
  content: string;
  contentIconPath?: string;
}

export interface ISectionContentRef {
  path: string;
  options?: { [key: string]: string };
}

export interface ISection {
  headerContentRef: ISectionContentRef;
  iconClass: string;
  items: QuoteSummaryModel[];
}

// Angular
import { IReportClaim } from '@amfam/shared/models/src';
// Services
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Models
import { ClaimConfirmation, ClaimRequestWrapper } from '../models';

@Injectable({ providedIn: 'root' })
export class ClaimSubmitService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  /**
   * @deprecated Use `reportClaimNew` instead
   */
  public reportClaimOld(claim: ClaimRequestWrapper): Observable<ClaimConfirmation> {
    return this.http
      .post(this.config.get('claimApiv1') + 'fnolreportclaim', claim.claimRequest)
      .pipe(
        map((data: any) => {
          const confirmation: ClaimConfirmation = data;
          confirmation.request = claim;
          return confirmation;
        })
      );
  }

  public reportClaim(claim: IReportClaim): Observable<ClaimConfirmation> {
    return (
      this.http
        .post(this.config.get('reportClaimApi') + 'submit', claim)
        .pipe(
          map(
            (response: any) => ({ referenceNumber: response.referenceNumber } as ClaimConfirmation)
          )
        )
    );
  }
}

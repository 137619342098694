import { Action } from '@ngrx/store';
import { Policy, SignatureRequest } from '@amfam/policy/models';

export enum ESignatureActionTypes {
  GetESignature = '[ESignature] Get ESignature',
  GetESignatureSuccess = '[ESignature] Get ESignature Success',
  GetESignatureComplete = '[ESignature] Get ESignature Complete',
  GetESignatureFail = '[ESignature] Get ESignature Fail',

  SignESignature = '[ESignature] Sign ESignature',
  SignESignatureSuccess = '[ESignature] Sign ESignature Success',
  SignESignatureComplete = '[ESignature] Sign ESignature Complete'
}

export class GetESignature implements Action {
  readonly type = ESignatureActionTypes.GetESignature;
  constructor(public payload: Policy[]) {}
}

export class GetESignatureSuccess implements Action {
  readonly type = ESignatureActionTypes.GetESignatureSuccess;
  constructor(public payload: Policy[]) {}
}

export class GetESignatureFail implements Action {
  readonly type = ESignatureActionTypes.GetESignatureFail;
  constructor(public payload?: any) {}
}

export class GetESignatureComplete implements Action {
  readonly type = ESignatureActionTypes.GetESignatureComplete;
  constructor(public payload?: any) {}
}

export class SignESignature implements Action {
  readonly type = ESignatureActionTypes.SignESignature;
  constructor(public payload: SignatureRequest) {}
}

export class SignESignatureSuccess implements Action {
  readonly type = ESignatureActionTypes.SignESignatureSuccess;
  constructor(public payload: string) {}
}

export class SignESignatureComplete implements Action {
  readonly type = ESignatureActionTypes.SignESignatureComplete;
  constructor(public payload?: string) {}
}

export type ESignatureActions =
  | GetESignature
  | GetESignatureSuccess
  | GetESignatureFail
  | GetESignatureComplete
  | SignESignature
  | SignESignatureSuccess
  | SignESignatureComplete;

export const fromESignatureActions = {
  GetESignature,
  GetESignatureSuccess,
  GetESignatureFail,
  GetESignatureComplete,
  SignESignature,
  SignESignatureSuccess,
  SignESignatureComplete
};

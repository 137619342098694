import { ProducerContactDetails } from './producerContactDetails';
import { ApiStatus } from '../api/api-status.model';

export class Producer {
  public producerId: string;
  public producerPartyId: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public nickName: string;
  public effectiveDate: string;
  public endEffectiveDate: string;
  public contactDetail: ProducerContactDetails;
}

export interface ProducerApiResponse {
  producer: Producer;
  status: ApiStatus;
}

import { Component, OnInit, Input } from '@angular/core';
import { StepProgressBarList } from './step-progress-bar-list';

@Component({
  selector: 'step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss']
})
export class StepProgressBarComponent implements OnInit {
  @Input() steps: StepProgressBarList;

  constructor() {}

  ngOnInit() {}
}

export class RiskSummary {
  public riskId: string;
  public riskDescription: string;
  public riskTypeCode2: string;
  public riskTypeCodeDesc: string;

  static fromJson(json: any): RiskSummary {
    const riskSummary = new RiskSummary();
    riskSummary.riskId = json.riskId;
    riskSummary.riskDescription = json.riskDescription;
    riskSummary.riskTypeCode2 = json.riskTypeCode2;
    riskSummary.riskTypeCodeDesc = json.riskTypeCodeDesc;

    return riskSummary;
  }
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import { Observable } from 'rxjs';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { LogoModel } from '@amfam/shared/models';

@Component({
  selector: 'ds-maintenance',
  templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent implements OnInit {
  chatEnabled$: Observable<boolean>;
  partnerFooterLogo$: Observable<LogoModel>;

  constructor(private store: Store<fromRoot.RootState>) {}

  ngOnInit() {
    this.chatEnabled$ = this.store.select(fromRoot.chatEnabled);
    this.partnerFooterLogo$ = this.store.select(BrandSelectors.getFooterLogo);
  }
}

import { Program } from './program.model';

export class EnrollNotificationModel {
  public hasTerms: boolean;
  public showPaymentTerms: boolean;

  constructor(public programs = new Array<Program>()) {
    this.hasTerms = programs.some(program => !!program.terms);
    this.showPaymentTerms = programs.some(program => program.isPaymentTerms);
  }
}

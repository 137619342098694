import { PageAnalytic, EventAnalytic } from '@amfam/shared/utility/shared-services';

export class ForgotPasswordAnalytics {
  public static readonly pageForgotPasswordStart: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly eventForgotPasswordStart: EventAnalytic = {
    eventName: 'Forgot Password',
    eventStep: 'start'
  };

  public static readonly pageForgotPasswordResetOptions: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:ResetMethod',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly pageForgotPasswordMultipleResetAttempts: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:MultipleAttempts',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly pageForgotPasswordEmail: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:SendRecoveryEmail',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly pageForgotPasswordText: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:ResetMethod:Text',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly pageForgotPasswordCall: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:ResetMethod:Phone',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly pageForgotPasswordConfirmPin: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:ResetMethod:EnterCode',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly pageForgotPasswordSecurityQuestions: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:AnswerSecurityQuestions',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly pageResetPassword: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:ResetPassword',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot Password',
    subCategory3: ''
  };

  public static readonly eventResetPasswordComplete: EventAnalytic = {
    eventName: 'Forgot Password',
    eventStep: 'complete'
  };
  public static readonly forgotPasswordMultipleEmailError: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotPassword:MultipleEmailError',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: '',
    subCategory3: ''
  };
}

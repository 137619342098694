import { FnolActions, FnolActionTypes } from './fnol.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { get as _get } from 'lodash';
import { Update } from '@ngrx/entity/src/models';
import { FnolModel, initialFnolModel } from './fnol.model';
const UNAVAILABLE_CLAIM = 'unavailable';

export const FNOL_FEATURE_KEY = 'fnol';

export interface FnolState extends EntityState<FnolModel> {
  selectedEntityId: string;
  hasError: boolean;
}

export const adapter: EntityAdapter<FnolModel> = createEntityAdapter<FnolModel>({
  selectId: (fnolPayload: FnolModel) =>
    _get(fnolPayload, 'claimNumber', '') === '' ? UNAVAILABLE_CLAIM : fnolPayload.claimNumber,
  sortComparer: false
});

export const initialFnolState: FnolState = adapter.getInitialState({
  selectedEntityId: null,
  hasError: false
});

export function reducer(state: FnolState = initialFnolState, action: FnolActions): FnolState {
  let newState = null;
  let draftClaimNumber = null;
  let submittedDraftClaimNumber = null;
  let newDraft: FnolModel;
  let oldDraft: FnolModel = null; // claim with old data
  let updatedDraft: FnolModel = null;
  let draftUpdate: Update<FnolModel> = null; // ngrx type Update<Claim> used with entity adapter

  switch (action.type) {
    case FnolActionTypes.LOAD_DRAFT_CLAIMS:
      return adapter.setAll(_get(action, 'payload', []), state);

    case FnolActionTypes.RESUME_DRAFT_CLAIM:
      updatedDraft = Object.assign({}, state.entities[action.payload.claimNumber], action.payload, {
        loading: true,
        hasError: false
      });
      newState = Object.assign({}, state, {
        selectedEntityId: action.payload.claimNumber,
        hasError: false
      });
      draftUpdate = createDraftUpdate(action.payload.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, newState);

    case FnolActionTypes.RESUME_DRAFT_CLAIM_SUCCESS:
      updatedDraft = Object.assign({}, state.entities[state.selectedEntityId], action.payload, {
        loading: false
      });
      draftUpdate = createDraftUpdate(state.selectedEntityId, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.RESUME_DRAFT_CLAIM_FAIL:
      updatedDraft = Object.assign({}, state.entities[state.selectedEntityId], {
        loading: false,
        hasError: true,
        claimErrorMessage: action.payload
      });
      newState = Object.assign({}, state, {
        selectedEntityId: updatedDraft.claimNumber,
        hasError: true
      });
      draftUpdate = createDraftUpdate(state.selectedEntityId, updatedDraft);
      return adapter.updateOne(draftUpdate, newState);

    // Store customer data in store before creating draft claim
    case FnolActionTypes.SAVE_INFO_FOR_DRAFT_CLAIM:
      newDraft = Object.assign({}, initialFnolModel, action.payload, {
        claimNumber: ''
      });
      newState = Object.assign({}, state, {
        selectedEntityId: UNAVAILABLE_CLAIM
      });
      return adapter.upsertOne(newDraft, newState);

    case FnolActionTypes.SAVE_LOSS_DATE_INFO:
    case FnolActionTypes.SAVE_DRIVER_INFO:
      updatedDraft = Object.assign({}, state.entities[state.selectedEntityId], action.payload, {
        claimNumber: ''
      });
      draftUpdate = createDraftUpdate(UNAVAILABLE_CLAIM, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.INITIATE_DRAFT_CLAIM:
      newDraft = Object.assign({}, initialFnolModel, action.payload.draftInfo, {
        claimNumber: ''
      });
      newState = Object.assign({}, state, {
        selectedEntityId: UNAVAILABLE_CLAIM
      });
      return adapter.upsertOne(newDraft, newState);

    // Create draft claim
    case FnolActionTypes.CREATE_DRAFT_CLAIM:
      updatedDraft = Object.assign({}, action.payload, {
        loading: true,
        hasError: false
      });
      newState = Object.assign({}, state, {
        selectedEntityId: UNAVAILABLE_CLAIM
      });
      draftUpdate = createDraftUpdate(UNAVAILABLE_CLAIM, updatedDraft);
      return adapter.updateOne(draftUpdate, newState);

    /**
     * @param isNewDigitalClaim - API sends this field to identify if this is new claim or not.
     * We don't have this field when creating new draft claim, this indicator tells draft claim is created
     * here, which is required to dispatch action to delete draft claim.
     */
    case FnolActionTypes.CREATE_DRAFT_CLAIM_SUCCESS:
      draftClaimNumber = action.payload.replace(/-/g, '');
      oldDraft = state.entities[UNAVAILABLE_CLAIM];
      updatedDraft = Object.assign({}, oldDraft, {
        claimNumber: draftClaimNumber,
        useProgressTrackingIndicator: true, // variable to display progress indicator
        isNewDigitalClaim: 'true'
      });
      newState = Object.assign({}, state, {
        selectedEntityId: draftClaimNumber
      });
      newState = adapter.removeOne(UNAVAILABLE_CLAIM, newState);
      return adapter.addOne(updatedDraft, newState);

    case FnolActionTypes.CREATE_DRAFT_CLAIM_FAIL:
      oldDraft = state.entities[UNAVAILABLE_CLAIM];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: false,
        hasError: true
      });
      draftUpdate = createDraftUpdate(UNAVAILABLE_CLAIM, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.INITIATE_DYNAMIC_QUESTIONS_FLOW:
      oldDraft = state.entities[state.selectedEntityId];
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, oldDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.INITIATE_DYNAMIC_QUESTIONS_FLOW_SUCCESS:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: false,
        hasError: false
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.INITIATE_DYNAMIC_QUESTIONS_FLOW_FAIL:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: false,
        hasError: true,
        claimErrorMessage: action.payload
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    // DM: need a separate reducer entry for this so we can store the third party contacts
    // in the state for later access when we add a claimant contact if third party fnol
    case FnolActionTypes.SUBMIT_THIRD_PARTY_CONTACT_FORM_QUESTION:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: true,
        hasError: false,
        thirdPartyContacts: action.payload
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    // Submit question and Go back
    case FnolActionTypes.SUBMIT_QUESTION:
    case FnolActionTypes.GO_BACK:
    case FnolActionTypes.EDIT_QUESTION:
    case FnolActionTypes.SUBMIT_VEHICLE_DAMAGE_QUESTION:
    case FnolActionTypes.SUBMIT_CLAIMANT_CONTACT_FORM_QUESTION:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: true,
        hasError: false
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.SUBMIT_QUESTION_SUCCESS:
    case FnolActionTypes.GO_BACK_SUCCESS:
    case FnolActionTypes.EDIT_QUESTION_SUCCESS:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: false,
        hasError: false
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.SUBMIT_QUESTION_FAIL:
    case FnolActionTypes.GO_BACK_FAIL:
    case FnolActionTypes.EDIT_QUESTION_FAIL:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: false,
        hasError: true,
        claimErrorMessage: action.payload
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    // Update store with contact info(phone number and email address)
    case FnolActionTypes.SUBMIT_INSURED_CONTACT_QUESTION:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: true,
        hasError: false
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    // Submit draft
    case FnolActionTypes.SUBMIT_DRAFT_CLAIM:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, {
        submitting: true,
        hasError: false
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.SUBMIT_DRAFT_CLAIM_SUCCESS:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, {
        submittedDraftClaimNumber: action.payload,
        submitting: false,
        hasError: false
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.SUBMIT_DRAFT_CLAIM_FAIL:
      oldDraft = state.entities[state.selectedEntityId];
      updatedDraft = Object.assign({}, oldDraft, {
        submitting: false,
        hasError: true,
        claimErrorMessage: action.payload
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, state);

    case FnolActionTypes.CLEAR_DRAFT_CLAIM:
      submittedDraftClaimNumber = state.selectedEntityId ? state.selectedEntityId : action.payload;
      newState = Object.assign({}, state, {
        selectedEntityId: '',
        hasError: false
      });
      return adapter.removeOne(submittedDraftClaimNumber, newState);

    case FnolActionTypes.CLEAR_ERROR_STATE:
      draftClaimNumber = state.selectedEntityId;
      updatedDraft = Object.assign({}, state.entities[draftClaimNumber], {
        hasError: false
      });
      newState = Object.assign({}, state, {
        selectedEntityId: draftClaimNumber,
        hasError: false
      });
      draftUpdate = createDraftUpdate(draftClaimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, newState);

    case FnolActionTypes.RESET_SELECTED_ENTITY:
      draftClaimNumber = state.selectedEntityId;
      updatedDraft = Object.assign({}, state.entities[draftClaimNumber]);
      newState = Object.assign({}, state, {
        selectedEntityId: '',
        hasError: false
      });
      draftUpdate = createDraftUpdate(draftClaimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, newState);

    // Delete draft claim
    case FnolActionTypes.DELETE_DRAFT_CLAIM:
      oldDraft = state.entities[action.payload];
      updatedDraft = Object.assign({}, oldDraft, {
        loading: true,
        hasError: false
      });
      newState = Object.assign({}, state, {
        selectedEntityId: action.payload,
        hasError: false
      });
      draftUpdate = createDraftUpdate(_get(oldDraft, 'claimNumber'), updatedDraft);
      return adapter.updateOne(draftUpdate, newState);

    case FnolActionTypes.DELETE_DRAFT_CLAIM_SUCCESS:
      draftClaimNumber = action.payload;
      newState = Object.assign({}, state, {
        selectedEntityId: action.payload === state.selectedEntityId ? '' : state.selectedEntityId
      });
      return adapter.removeOne(draftClaimNumber, newState);

    case FnolActionTypes.DELETE_DRAFT_CLAIM_FAIL:
      draftClaimNumber = action.payload;
      oldDraft = state.entities[draftClaimNumber];
      updatedDraft = Object.assign({}, oldDraft, action.payload, {
        loading: false,
        hasError: true
      });
      newState = Object.assign({}, state, {
        selectedEntityId: draftClaimNumber,
        hasError: true
      });
      draftUpdate = createDraftUpdate(oldDraft.claimNumber, updatedDraft);
      return adapter.updateOne(draftUpdate, newState);

    default:
      return state;
  }
}

function createDraftUpdate(claimNumber: string, updatedEntity: FnolModel): Update<FnolModel> {
  return {
    id: claimNumber,
    changes: updatedEntity
  };
}

// get the selectors
const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

// select the array of claim ids (claim number)
export const selectEntityIds = selectIds;

// select the dictionary of claim entities
export const selectfnolEntities = selectEntities;

// select the array of draft claims
export const selectFnols = selectAll;

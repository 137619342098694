export interface OpportunityContent {
  opportunityProductType: string;
  opportunityProductCode: string;
  coverageType?: string;
  agentSelectHeader: string;
  agentServicedPolicies: string[];
  overviewComponentIntroNoThanksBtn: string;
  detailComponentHeader: string;
  detailComponentIntro: string;
  detailComponentIntro2?: string;
  detailComponentFAQ: OpportunityInfoQuestionAnswer[];
  detailComponentFooterInfo: string;
  detailComponentFooterInfoTitle: string;
  detailComponentFooterInfoBody: string;
  detailContactBtnText: string;
  agentEmailFromEmailAddress: string;
  agentEmailFromTxt: string;
  agentEmailSubject: string;
  agentEmail: string;
  ansNotification: string;
  ansNotificationType: string;
  successNotification: string;
  nothanksSuccessMessage: string;
  failureNotification: string;
  dismissFailureNotification: string;
  generalFailureMessage: string;
  templateID: string;
  testTemplateID?: string;
  slides: OpportunitySlide[];
  propertyHeaderQuestion?: string;
  ctaIconPath?: string;
  analyticsInfo?: OpportunityAnalytics;
  tags?: string[];
  types?: SelectionType[];
  selectionTypeHeading?: string;
  newTemplate?: boolean;
  heroCard?: string;
  selectionHeader?: string;
  qualifiers?: OpportunityQualifierOptions[];
  quote?: Quote;
}
export interface OpportunityInfoQuestionAnswer {
  title: string;
  body: string;
}
export interface OpportunityQualifierOptions {
  text: string;
  tooltipContent: {
    heading: string;
    body: string;
  };
}

export interface OpportunityContentWithRecommendationId {
  recommendationId: string;
}

export interface OpportunitySlide {
  title: string;
  bodyText: string;
  ctaLinkText1: string;
  ctaLinkText2: string;
  ctaLinkRoute?: string;
  slideType: string;
  bodyImage?: string;
  bodyImageHeight?: string;
  themeClasses?: string[];
  iconClasses?: string[];
  disclaimers?: string[];
  theme?: string;
}

export interface SelectionType {
  name: string;
  code: string;
  thumbnail: string;
  heroCard: string;
  faq?: OpportunityInfoQuestionAnswer[];
  analytics?: OpportunityAnalytics;
  detailComponentHeader?: string;
  detailComponentIntro?: string;
}

export interface OpportunityAnalytics {
  pageInfo: string;
  subCategory1: string;
  subCategory2: string;
  subCategory3?: string;
  type?: string;
}

export interface Quote {
  buttonText: string;
  askAgentText: string;
  featureFlag: string;
  abTestingKey: string;
}



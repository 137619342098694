import { BillAccount } from '@amfam/shared/models';
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bills-overdue-alert',
  templateUrl: './bills-overdue-alert.component.html'
})
export class BillsOverdueAlertComponent {
  @Input() billAccounts: BillAccount[];

  @Output() routeToBillAccount = new EventEmitter();

  constructor() {}
}

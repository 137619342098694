import { LogoModel } from '@amfam/shared/models';

export interface BrandModel {
  brandId: string;
  experienceId: string;
  partnerId: string;
  accountId: string;
  segmentId: string;
  accessPointCd: string;
  affiliateCd: string;
  uiPlatformCd: string;
  externalExperienceId: string;
  data: BrandDataModel;
  isPartner: boolean; // Is this experience a non-AFI partner
  isGenericPartner: boolean; // Is this experience the generic partner
  loading: boolean;
  loaded: boolean;
  userBrandLoaded: boolean;
  hasError: boolean;
}

export interface BrandDataModel {
  assetPath: string;
  claimPhoneNum: string;
  claimPhoneNumAuto: string;
  claimPhoneNumProperty: string;
  claimErsPhoneNum: string;
  claimGlassPhoneNum: string;
  company: string;
  cusCareNumber: string;
  cusCareEmail: string;
  cusCare247: string;
  cusCareHoursWeekday: string;
  cusCareHoursFriday: string;
  cusCareHoursWeekend: string;
  customerCareTimezone: string;
  customerCareWeekdayEnd: string;
  customerCareWeekdayStart: string;
  experienceClass: string;
  partnerNameInUrl: string;
  billingContactEmailFaq: string;
  myaccountSubdomain: string;
  landingPageDomain: string;
  landingPagePath: string;
  landingPageUrl: string;
  landingPage: string;
  propertyServiceUrl: string;
  propertyServiceSSO: string;
  brandHeaderLogo: LogoModel;
  brandFooterLogo: LogoModel;
}

export interface BrandLinkDataModel {
  brandState: BrandModel;
  cusCareNumberFullHref: string;
  cusCareEmailHref: string;
}

export const initialLogoModel = {
  logoURL: '',
  height: '',
  width: '',
  altText: ''
};

export const initialBrandDataModel = {
  assetPath: '',
  claimPhoneNum: '',
  claimPhoneNumAuto: '',
  claimPhoneNumProperty: '',
  claimErsPhoneNum: '',
  claimGlassPhoneNum: '',
  company: '',
  cusCareNumber: '',
  cusCareEmail: '',
  cusCare247: '',
  cusCareHoursWeekday: '',
  cusCareHoursFriday: '',
  cusCareHoursWeekend: '',
  customerCareTimezone: '',
  customerCareWeekdayEnd: '',
  customerCareWeekdayStart: '',
  experienceClass: '',
  partnerNameInUrl: '',
  billingContactEmailFaq: '',
  myaccountSubdomain: '',
  landingPageDomain: '',
  landingPagePath: '',
  landingPageUrl: '',
  landingPage: '',
  propertyServiceUrl: '',
  propertyServiceSSO: '',
  brandHeaderLogo: null,
  brandFooterLogo: null
};

export const initialBrandModel = {
  brandId: '',
  experienceId: '',
  partnerId: '',
  accountId: '',
  segmentId: '',
  accessPointCd: '',
  affiliateCd: '',
  uiPlatformCd: '',
  externalExperienceId: '',
  data: initialBrandDataModel,
  isPartner: false,
  isGenericPartner: false,
  loading: false,
  loaded: false,
  userBrandLoaded: false,
  hasError: false
};

export const BrandServiceDataMap = {
  assetPath: 'AssetPath',
  claimPhoneNum: 'ClaimPhoneNum',
  claimPhoneNumAuto: 'ClaimPhoneNum_Auto',
  claimPhoneNumProperty: 'ClaimPhoneNum_Property',
  claimErsPhoneNum: 'ClaimErsPhoneNum',
  claimGlassPhoneNum: 'ClaimGlassPhoneNum',
  company: 'Company',
  cusCareNumber: 'CustomerCareNumber',
  cusCareEmail: 'CustomerCareEmail',
  cusCare247: 'CustomerCare247',
  cusCareHoursWeekday: 'CustomerCareHours_Weekday',
  cusCareHoursFriday: 'CustomerCareHours_Friday',
  cusCareHoursWeekend: 'CustomerCareHours_Weekend',
  customerCareTimezone: 'CustomerCareTimezone',
  customerCareWeekdayEnd: 'CustomerCareWeekdayEnd',
  customerCareWeekdayStart: 'CustomerCareWeekdayStart',
  experienceClass: 'ExperienceClass',
  partnerNameInUrl: 'PartnerName_In_URL',
  billingContactEmailFaq: 'BillingContactEmailFaq',
  myaccountSubdomain: 'MyaccountSubdomain',
  landingPageDomain: 'LandingPageDomain',
  landingPagePath: 'LandingPagePath',
  propertyServiceUrl: 'PropertyServiceURL',
  propertyServiceSSO: 'PropertyServiceSSO'
};

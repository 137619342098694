import { Claim } from './claim';

export interface LoadClaimDetailPayload {
  claimNumber: string;
}

export interface LoadClaimDetailSuccessPayload {
  response: any;
}

export interface LoadClaimsSuccessPayload {
  claims: Claim[];
  fnols: any[];
  hasHiddenClaims: boolean;
}

<li
  [@showNavItem]="showNavItem"
  (@showNavItem.done)="focusPreviousElem()"
  class="mobile-nav-item"
  data-cy="mobileNavigationMenuItem"
>
  <a
    *ngIf="!link?.linkExternalUrl"
    href="javascript:void(0)"
    [ngClass]="link?.linkClasses"
    [attr.aria-label]="link.linkText"
    [attr.data-cy]="link?.mobileLinkTestId"
    (click)="linkClick($event)"
    (blur)="onLinkBlur()"
  >
    <div class="mobile-nav-item-link">
      <div class="mobile-link-content">
        <span
          *ngIf="!!link?.linkIcon"
          class="link-content-icon"
          role="img"
          alt=""
          [attr.title]="link.linkText"
          [ngClass]="link?.linkIcon"
        ></span>
        <span [class.overflow-ellipsis]="link?.overflowEllipsis" class="link-text">{{
          link.linkText
        }}</span>
        <span
          *ngIf="!!link?.subLinkArray?.length"
          class="link-content-children-icon icon-chevron-right"
        ></span>
      </div>
    </div>
  </a>
  <a
    *ngIf="!!link?.linkExternalUrl"
    [href]="link?.linkExternalUrl"
    [ngClass]="link?.linkClasses"
    [href]="link?.linkExternalUrl"
    [attr.aria-label]="link.linkText"
    [attr.target]="link?.linkTarget"
    [attr.data-cy]="link?.mobileLinkTestId"
    (click)="linkClick($event)"
    (blur)="onLinkBlur()"
  >
    <div class="mobile-link-content">
      <span
        *ngIf="!!link?.linkIcon"
        class="link-content-icon"
        role="img"
        alt=""
        [attr.title]="link.linkText"
        [ngClass]="link?.linkIcon"
      ></span>
      <span [class.overflow-ellipsis]="link?.overflowEllipsis" class="link-text">{{
        link.linkText
      }}</span>
    </div>
  </a>
</li>
<ul
  *ngIf="!!link?.subLinkArray?.length"
  class="mobile-subnav"
  [@showSubmenu]="showSub"
  (@showSubmenu.done)="focusSublinkTitle()"
  [attr.data-cy]="link?.mobileSubmenuTestId"
>
  <li class="mobile-subnav-title">
    <a #sublinkTitle href="javascript:void(0)" (click)="sublinkTitleClick()">
      <span class="icon-chevron-left pad-right-10"></span>
      <span>{{ link.mobileAltSubMenuTitle || link.linkText }}</span>
    </a>
  </li>

  <li
    *ngFor="let sublink of link?.subLinkArray; trackBy: trackByFn"
    #subLinkElemItem
    authCheck
    class="mobile-subnav-item"
    [ngClass]="sublink?.listClasses"
    [style.maxWidth.px]="sublink?.maxWidth"
    [permissionName]="sublink?.permissionName"
    [checkAction]="sublink?.checkAction"
    (blur)="resetOnBlur($event.currentTarget.parent)"
  >
    <a
      *ngIf="!sublink?.linkExternalUrl"
      href="javascript:void(0)"
      [ngClass]="sublink?.linkClasses"
      [dsRouterLink]="sublink?.linkRoute"
      [queryParams]="sublink?.queryParams"
      [attr.aria-label]="sublink?.linkText"
      [attr.data-cy]="sublink?.mobileLinkTestId"
      (click)="sublinkClick(sublink, $event)"
      (blur)="resetOnBlur($event.currentTarget.parentNode)"
    >
      <div class="mobile-link-content">
        <span
          *ngIf="!!sublink?.linkIcon"
          class="link-content-icon"
          role="img"
          alt="Link Icon"
          [attr.title]="sublink?.linkText"
          [ngClass]="sublink?.linkIcon"
        ></span>
        <span [class.overflow-ellipsis]="sublink?.overflowEllipsis" class="link-text" role="none">{{
          sublink.linkText
        }}</span>
      </div>
    </a>
    <a
      *ngIf="!!sublink?.linkExternalUrl"
      [ngClass]="sublink?.linkClasses"
      [href]="sublink?.linkExternalUrl"
      [attr.aria-label]="sublink?.linkText"
      [attr.target]="sublink?.linkTarget"
      [attr.data-cy]="sublink?.mobileLinkTestId"
      (click)="sublinkClick(sublink, $event)"
      (blur)="resetOnBlur($event.currentTarget.parentNode)"
    >
      <div class="mobile-link-content">
        <span
          *ngIf="!!sublink?.linkIcon"
          class="link-content-icon"
          role="img"
          alt="Link Icon"
          [attr.title]="sublink?.linkText"
          [ngClass]="sublink?.linkIcon"
        ></span>
        <span [class.overflow-ellipsis]="sublink?.overflowEllipsis" class="link-text" role="none">{{
          sublink.linkText
        }}</span>
      </div>
    </a>
  </li>
</ul>

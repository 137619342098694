export interface PaymentConfirmationModel {
  confirmationId?: string;
  paymentMethod?: {
    nickName?: string;
    date?: string;
    creditCard?: any;
    achWithdrawal?: any;
  };
  paymentAccount?: string;
  automaticPayment?: any;
  schedulePayment?: any;
  category: string;
  subCategory?: string;
  paymentId?: string;
  amount?: number;
  paymentConfirmationId?: string;
  [propName: string]: any;
}

export const initialPaymentConfirmation: PaymentConfirmationModel = {
  confirmationId: null,
  paymentMethod: null,
  automaticPayment: null,
  schedulePayment: null,
  category: null,
  subCategory: null
};

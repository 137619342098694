import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PAYMENT_HISTORY_FEATURE_KEY,
  PaymentHistoryState,
  selectAllPayments
} from './payment-history.reducer';

const getPaymentHistoryState = createFeatureSelector<PaymentHistoryState>(
  PAYMENT_HISTORY_FEATURE_KEY
);

const getMonthRange = createSelector(getPaymentHistoryState, state => state.monthRange);

const getPayments = createSelector(getPaymentHistoryState, selectAllPayments);

const getLoading = createSelector(getPaymentHistoryState, state => state.loading);

const hasError = createSelector(getPaymentHistoryState, state => state.hasError);

export const paymentHistoryQuery = {
  getPaymentHistoryState,
  getMonthRange,
  getPayments,
  getLoading,
  hasError
};

<div>
  <h3 *ngIf="noEnrolledUserStateInfo; else enrolledUserStateInfoPresent" data-cy="enrollmentMyAccountExistsHeader">
    We're sorry. We found a My Account that already exists.
  </h3>
  <ng-template #enrolledUserStateInfoPresent>
    <h3 data-cy="enrollmentMyAccountExistsHeader">
      We're sorry. We found a My Account that already exists with the following information.
    </h3>
  </ng-template>
  <hr aria-hidden="true" class="dash" *ngIf="lastName" />
  <div class="margin-top-20">
    <p *ngIf="lastName">
      Last Name: <b>{{ lastName }}</b>
    </p>
    <p *ngIf="zipcode">
      ZIP Code: <b>{{ zipcode }}</b>
    </p>
    <p *ngIf="dob">
      Date of Birth: <b>{{ dob }}</b>
    </p>
    <p *ngIf="policyNum">
      Policy Number: <b>{{ policyNum }}</b>
    </p>
  </div>
  <hr aria-hidden="true" class="dash" *ngIf="lastName" />
  <div class="row">
    <a
      *ngIf="!isMaeWorkflow"
      href="javascript:void(0)"
      (click)="goBackOneStep()"
      class="inline-block margin-top-20 caption"
      ><i class="icon-chevron-left"></i> Back</a
    >
    <button ds-button type="button" (click)="goToLogin()" class="float-right -offset-top">
      Go to the Login Page
    </button>
  </div>
  <hr aria-hidden="true" />
</div>

import { takeUntil } from 'rxjs/operators';
import { Directive, TemplateRef, ViewContainerRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BrandModel as BrandState } from '../models/brand.model';
import * as BrandSelectors from '../+state/brand.selectors';

@Directive({
  selector: '[dsPartnerAFI]'
})
export class PartnerAFIDirective implements OnDestroy, OnInit {
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<BrandState>
  ) {}

  /**
   *  PartnerAFIDirective checks if the partnerID in Store is 'AFI'.
   *  If so, the content in the template is displayed. This is to be used for toggling AFI content.
   */

  ngOnInit() {
    this.store
      .select(BrandSelectors.getIsPartner)
      .pipe(takeUntil(this.stop$))
      .subscribe(isPartner => {
        if (!isPartner) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

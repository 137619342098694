import { AnalyticsFacade } from '@amfam/shared/analytics';
import { Agent } from '@amfam/shared/utility/agent';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ButtonAnalytic, UtilService } from '@amfam/shared/utility/shared-services';
import { DockingBarService } from '@amfam/ui-kit';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, first, map, switchMap, tap } from 'rxjs';
import { getAllActiveAgents } from '../../+state';
import { OpportunitiesNotificationAction } from '../../+state/opportunity.action';
import { OpportunityAnalytics } from '../../models/opportunity-analytic-contants';
import { OpportunityService } from '../../services/opportunity.service';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesAgentService {
  constructor(
    private store: Store,
    private opportunityService: OpportunityService,
    private utilService: UtilService,
    private dockingBarService: DockingBarService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  setDockingbarHeading() {
    this.dockingBarService.registerHeading('My Opportunities');
  }

  getAgents() {
    return this.store.select(getAllActiveAgents).pipe(
      filter(agents => agents && agents.length > 0 && !!agents[0].agentId),
      map(agents => this.mapAgent(agents))
    );
  }

  mapAgent(agents: Agent[]): AgentInfo[] {
    if (!agents || agents.length === 0) return null;
    return agents.map(agent => ({
      agentId: agent.agentId,
      name: `${agent.firstName} ${agent.lastName}`,
      url: agent.photoUrl,
      office: agent.fullName,
      address: this.getOfficeAddress(agent.offices),
      recommended: agent.isAmericanStarCertified
    }));
  }

  private getOfficeAddress(offices: AgentOffice[]) {
    if (!offices || offices.length === 0 || !offices[0].address) return '';
    const { line1, line2, city, state, zip4, zip5 } = offices[0].address;
    return `${line1}${line2 && line2.length > 0 ? `, ${line2}` : ''}, ${city}, ${state} ${zip5}`;
  }

  getRecommendationId(type: string): Observable<string> {
    return this.opportunityService.getRecommendationIdByOpportunityType(type);
  }

  backToMyaccount(type: string) {
    this.sendBackToMyAccountAnalytics(type);
    this.store.dispatch(new fromRouterActions.Go({ path: ['/overview'] }));
  }

  sendToAgent(agentId: string, type: string) {
    this.sendToAgentAnalytics(type);
    this.getRecommendationId(type)
      .pipe(
        filter(r => !!r),
        first(),
        tap(recommendationId =>
          this.store.dispatch(
            new OpportunitiesNotificationAction({
              correlationId: this.utilService.generateId(),
              agentId: agentId,
              recommendationId: recommendationId,
              isMultiAgent: false
            })
          )
        )
      )
      .subscribe();
  }

  sendPageAnalytics(type: string) {
    this.getRecommendationId(type)
      .pipe(
        filter(recommendationId => !!recommendationId),
        first(),
        switchMap(recommendationId =>
          this.opportunityService.buildPageAnalyticData(
            OpportunityAnalytics.pageOpportunitiesNeedAgentForm,
            recommendationId
          )
        ),
        tap(analytics => this.analyticsFacade.trackPage(analytics))
      )
      .subscribe();
  }

  sendToAgentAnalytics(type: string) {
    this.sendButtonAnalytics(type, OpportunityAnalytics.clickOpportunitySendToAgent);
  }

  sendBackToMyAccountAnalytics(type: string) {
    this.sendButtonAnalytics(type, OpportunityAnalytics.clickOpportunityBackToMyAccount);
  }

  private sendButtonAnalytics(type: string, analyticRef: ButtonAnalytic) {
    this.getRecommendationId(type)
      .pipe(
        filter(recommendationId => !!recommendationId),
        first(),
        switchMap(recommendationId =>
          this.opportunityService.buildButtonAnalyticData(analyticRef, recommendationId)
        ),
        tap(analytics => this.analyticsFacade.trackButtonClick(analytics))
      )
      .subscribe();
  }
}

export interface AgentInfo {
  agentId: string;
  name: string;
  url: string;
  office: string;
  address: string;
  recommended: boolean;
}

export interface AgentOffice {
  address: AgentAddress;
  phones: AgentPhone[];
  typeOfOfficeCode: string;
}

export interface AgentAddress {
  city: string;
  line1: string;
  line2: string;
  state: string;
  zip4: string;
  zip5: string;
}

export interface AgentPhone {
  areaCode: string;
  phoneNumber: string;
  typeOfPhoneCode: string;
  typeOfUsageCode: string;
}

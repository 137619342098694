import { ErrorMsg } from './billing-copy';
export interface PolicyCopy {
  autoSummaryText: string;
  autoSummaryTextPartner: string;
  propertySummaryText: string;
  umbrellaSummaryText: string;
  policyListTextPartner: string;
  lifeSummaryText: string;
  policyDetails: string;
  lifeContactText: string;
  lifeAdditionalContactText: string;
  lifeCoverageInfo: string;
  lifeCoverageInfoNew: string;
  lifePremiumInfo: string;
  lifeCardInfo: string;
  lifeCardInfoContact: string;
  lifePremiumInfoNew: string;
  policyChangeSuccess: string;
  backToPolicies: string;
  policyName: string;
  insured: string;
  defaultErrorMessage: string;
  defaultReplaceVehicleErrorMessage: string;
  sameVINReplaceVehicleErrorMessage: string;
  changeTypeConfirmationText: string;
  changeTypeConfirmationMessage: string;
  agentInformationANS: string;
  agentInformation: string;
  emailSubjectOnlineBindNoAction: string;
  emailSubjectOnlineBindActionNeeded: string;
  agentEmailContentNoQuote: string;
  agentEmailContentQuoteToAgent: string;
  ANSContentQuoteTPIChangeNeeded: string;
  ANSContentQuoteNoTPIChangeNeeded: string;
  ANSContentQuoteToAgent: string;
  agentEmailContentQuoteNoTPIChangeNeeded: string;
  agentEmailContentQuoteTPIChangeNeeded: string;
  ansNotificationFailError: string;
  PGPDidYouKnow: string;
  PGPNeverFear: string;
  PGPOOPs: string;
  errorMsgs: ErrorMsg[];
  replaceVehicleConfirmation: ReplaceVehicleConfirmationCopy;
  policy101: Policy101ClicktipModel[];
  addVehicleSendToAgentANSMessage: string;
  replaceVehicleSendToAgentANSMessage: string;
  replaceVehicleQuoteInfoText: string;
  replaceVehicleQuoteInfoTextOnlineBind: string;
  replaceVehicletermsText: string;
  replaceVehicletermsInfoText: string;
  eSignatureErrorQueryParams: string[];
  policyNameChangeAgentEmail: string;
  policyAddressChangeAgentEmail: string;
  policyCoverageChangeAgentEmail: string;
  policyNameChangeANSMessage: string;
  policyAddressChangeANSMessage: string;
  policyCoverageChangeANSMessage: string;
  policyChangeHeading: string;
  policyChangeTypeSelectionHeading: string;
  policyChangeDisclaimer: string;
  policyChangeTitle: string;
  policyChangePageHeader: string;
  policyCommentsAdditionalInfoText: string;
  fullNameInfoText: string;
  addressInfoText: string;
  unavailablePoliciesNote: string;
  onlineBindFailureInfoText: string;
  underWritingQuestionIsOwner: KeyValuePairModel;
  underWritingQuestionIsBusinessUse: KeyValuePairModel;
  underWritingQuestionHasLoan: string;
  onlineBindFailureMessage: string;
  comprehensiveOnlyVehiclesAreUnavailable: string;
  policyChangeAgentEmailLabel: string;
  policyChangeAgentFollowUpText: string;
  policyLandingTitle: string;
  policyLandingViewPolicies: string;
  personalLinesPolicyText: string;
  personalLinesPolicyNote: string;
  addAutoBtn: string;
  requestQuoteBtn: string;
  policyOverviewMessage: string;
  note: string;
  policyHelpMessage: string;
  paperlessPolicyTitle: string;
  madeGreatChoiceText: string;
  faqPaperLess: string;
  viewText: string;
  TAndC: string;
  getAccessText: string;
  discountText: string;
  returnAnyTimeMessage: string;
  agentEmailForCoverageChange: string;
  agentEmailForCoverageChangeOnlineBind: string;
  ansEmailForCoverageChange: string;
  ansEmailForCoverageChangeOnlineBind: string;
  changeCoveragePCError: string;
  deductibleLimits: AutoCoveragesModel;
  addVehicle: string;
  agentSelectHeader: string;
  newVehicleCaption: string;
  newVehicle14DayDiscountQuestion: string;
  newVehicle30DayDiscountQuestion: string;
  newVehicleGarageAddress: string;
  newVehicleUnderwritingQuestions: string;
  newVehicleLoanInstitutions: string;
  newVehicleLoanLeaseInstitutions: string;
  newVehicleDriverInfo: string;
  newVehiclePrimaryDriverSelection: string;
  newVehicleSecondaryDriverSelection: string;
  newVehicleDriverSelectionMultipleStepHeader: string;
  newVehicleDriverSelectionSingleStepHeader: string;

  newVehicleNextStep: string;
  newVehicleAgentsHelpHeader: string;
  newVehicleAgentsHelpBody: string;
  newVehicleSendToAgent: string;
  sendToLicensedAdvisor: string;
  newVehicleEmailSuccessStatusText: string;
  newVehicleAgentEmailNotificationBody: string;
  newVehicleAgentEmailNotificationHeaderText: string;
  newVehicleFeaturesAndEquipmentsHeader: string;
  newVehicleFeaturesAndEquipmentsSubHeader: string;
  newVehicleFeaturesAndEquipmentsDiscountInfo: string;
  newVehicleFeaturesAndEquipmentsCodesWithDescription: VehicleEquipmentCodeDescription[];
  newVehicleAgentEmailNotificationText: string;
  preferencesError: string;
  partialBillAccountsAndPolicyFailureMessage: string;
  billingSuccessButPolicyFailureMessage: string;
  policySuccessButBillingFailureMessage: string;
  policySuccessButPartialBillingFailureMessage: string;
  policyOrAllBillingFailureMessage: string;
  fewBillingFailureMessage: string;
  quoteOrBindHeader: string;
  quoteOrBindMessage: string;
  quoteVehicleDetailsHeader: string;
  bindVehicleDetailsHeader: string;
  quickQuoteCoverageOptions: CoverageOptions[];
  QQDidYouKnow: string;
  replaceVehicleQuoteInfoTextOnlineQuote: string;
  quoteVehicleReviewHeader: string;
  vehicleHasKitBusinessCoOwnerFlagPartnerEmailSubjectNoTPI: string;
  vehicleMileageUnder500PartnerEmailSubjectNoTPI: string;
  newVehicleUsageMileageHeaderWithoutPrimaryUseOptions: string;
  addVehicleLabel: string;
  replaceVehicleLabel: string;
  gracePeriodContent: string;
  newVehicleUnderwritingQuestionsHeader: string;
  filterCoverages: FilterCoverageOptions[];
  replaceCoverages: ReplaceCoverageOptions[];
  replaceVehicle: string;
  addVehicleExitFlowConfimationText: string;
  replaceVehicleExitFlowConfimationText: string;
  addVehicleExitFlowPrimaryButtonText: string;
  replaceVehicleExitFlowPrimaryButtonText: string;
}

interface ReplaceVehicleConfirmationCopy {
  advanceVehicleHeading: string;
  nonAdvanceVehicleHeading: string;
  advanceVehicleNextSteps: string;
  nonAdvanceVehicleNextSteps: string;
  advanceVehicleOnlineBindSuccessHeading: string;
  advanceVehicleOnlineBindNextSteps: string;
  advanceVehicleOnlineBindAgentNotificationSuccessHeading: string;
  advanceVehicleOnlineBindAgentNotificationNextSteps: string;
}

export interface Policy101ClicktipModel {
  codes: string[];
  title: string;
  text: string;
}

interface KeyValuePairModel {
  key: string;
  value: string;
}
interface AutoCoveragesModel {
  Comprehensive: {
    limits: string;
    code: string;
  };
  Collision: {
    limits: string;
    code: string;
  };
  'Rental Remimbursement': {
    limits: string;
    code: string;
  };
  ERS: {
    values: string;
    code: string;
  };
}

interface VehicleEquipmentCodeDescription {
  [id: string]: string;
}

interface CoverageOptions {
  text: string;
  id: string;
}

interface FilterCoverageOptions {
  coverageCode: string;
  limitTerm: string;
  packageTerm: string;
}

interface ReplaceInfo {
  code: string;
  replace?: {
    from: string;
    to: string;
  };
}
interface ReplaceCoverageOptions {
  coverageR: ReplaceInfo;
  limitR: ReplaceInfo;
  packageR: ReplaceInfo;
}

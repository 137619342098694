import { Coverage, PropertyPolicy } from '@amfam/policy/models';
import { ConfigService, CopyService } from '@amfam/shared/utility/shared-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { unionBy as _unionBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TypedWorker } from './typed-worker';

@Injectable({
  providedIn: 'root'
})
export class PlpropertyService implements TypedWorker {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private copyService: CopyService
  ) {}

  public getEndpoint() {
    return this.config.get('productApi') + 'propertypolicies/';
  }

  /*
    Expectation is that this will take the shape of the plauto api and have a status object
    and a policy object
  */
  public getPolicy(url: string): Observable<PropertyPolicy> {
    return this.http.get(url).pipe(map((data: any) => this.loadModel(data)));
  }

  public loadModel(json: any): PropertyPolicy {
    let policy: any = json;
    if (json.policy) {
      policy = json.policy;
    }
    policy.namedInsuredList = [];

    if (policy.policyRoles) {
      for (let i = 0; i < policy.policyRoles.length; i++) {
        const name = policy.policyRoles[i].name;
        policy.namedInsuredList.push(name);
      }
    }

    return PropertyPolicy.fromJson(policy, this.getCoveragesToFilter(), this.getCoveragesToReplace());
  }

  getCoveragesToFilter() {
    return this.copyService.getCopy('policy.filterCoverages');
  }

  getCoveragesToReplace() {
    return this.copyService.getCopy('policy.replaceCoverages');
  }

  public getPolicyLevelCoverages(policy: PropertyPolicy): Array<Coverage> {
    return _unionBy(policy.policyCoverages, policy.propertyCoverages, x => x.code);
  }
}

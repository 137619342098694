import {
  PaymentMethodFacade,
  PaymentMethodSelectors
} from '@amfam/billing/payment-method/data-access';
import {
  PaymentMethod,
  PaymentMethodAccountType,
  PaymentMethodUtilService
} from '@amfam/billing/payment-method/util';
import { userQuery } from '@amfam/shared/user';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService } from '@amfam/shared/utility/shared-services';
import {
  ConfirmationConfig,
  ConfirmationStatus,
  DockingBarService,
  LoadingSpinnerService
} from '@amfam/ui-kit';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  AutomaticPayments,
  PaymentMethodSetup,
  ScheduledPayments
} from '../+state/payment-method-feature.models';
import * as PaymentMethodFeatureSelectors from '../+state/payment-method-feature.selectors';
@Component({
  selector: 'ds-payment-method-confirmation',
  templateUrl: './payment-method-confirmation.component.html',
  styleUrls: ['./payment-method-confirmation.component.scss']
})
export class PaymentMethodConfirmationComponent implements OnInit {
  confirmationData$: Observable<ConfirmationConfig>;
  paymentMethod: PaymentMethod;
  messageBodyText: string;
  subHeading: string;
  operation: 'add' | 'edit' | 'delete-success' | 'delete-error';
  deletePaymentMethodInfo: PaymentMethodSetup;
  scheduledPayments: ScheduledPayments[] = [];
  automaticPayments: AutomaticPayments[] = [];
  autoPayDiscountWarningHeading: string;
  autoPayDiscountWarningBody: string;
  paymentMethodAccountType: PaymentMethodAccountType;
  private stop$ = new Subject<void>();

  constructor(
    private store: Store,
    private copyService: CopyService,
    private dockingService: DockingBarService,
    private spinnerService: LoadingSpinnerService,
    private paymentMethodFacade: PaymentMethodFacade,
    private paymentMethodUtil: PaymentMethodUtilService
  ) {}

  ngOnInit(): void {
    this.spinnerService.start({ blockActions: true });
    combineLatest([
      this.store.select(PaymentMethodSelectors.getPaymentMethodEntities),
      this.store.select(PaymentMethodSelectors.getNewPaymentAccountId),
      this.store.select(PaymentMethodFeatureSelectors.getPaymentMethodOperation),
      this.store.select(userQuery.getEmailAddress),
      this.store.select(PaymentMethodFeatureSelectors.getDeletePaymentMethodInfo)
    ])
      .pipe(
        filter(([, , operation]) => operation !== null),
        takeUntil(this.stop$)
      )
      .subscribe(
        ([entities, paymentAccountId, operation, emailAddress, deletePaymentMethodInfo]) => {
          this.dockingService.registerHeading('CONFIRMATION');
          let primaryButtonName: string = 'Done';
          let tertiaryButtonName: string;
          this.paymentMethod = entities[paymentAccountId];
          this.subHeading = 'Payment method details';
          const messageHeader = this.copyService.getCopy(
            `billing.paymentMethodStandAlone.${operation}ConfirmationHeading`
          );
          this.messageBodyText = this.copyService.getCopy(
            `billing.paymentMethodStandAlone.${operation}ConfirmationBody`,
            { emailAddress: emailAddress }
          );
          this.operation = operation;

          if (operation === 'delete-success') {
            this.paymentMethod = deletePaymentMethodInfo.paymentMethod;
            this.deletePaymentMethodInfo = deletePaymentMethodInfo;
            this.scheduledPayments = deletePaymentMethodInfo.scheduledPayments;
            this.automaticPayments = deletePaymentMethodInfo.automaticPayments;
          }

          if (operation === 'delete-error') {
            this.dockingService.registerHeading('delete payment method');
            tertiaryButtonName = 'Done';
            primaryButtonName = 'Try Again';
            this.subHeading = null;
          }

          this.confirmationData$ = this.getConfirmationConfig(
            messageHeader,
            operation,
            primaryButtonName,
            tertiaryButtonName
          );

          // send analytics
          this.paymentMethodAccountType = this.paymentMethodUtil.getPaymentMethodAccountType(
            this.paymentMethod
          );
          let pageAnalyticName: string;
          switch (operation) {
            case 'add':
              pageAnalyticName =
                this.paymentMethodAccountType === PaymentMethodAccountType.CREDIT_DEBIT
                  ? 'AddPaymentMethod:SetupDebitCreditCard:Complete'
                  : 'AddPaymentMethod:SetupSavingsChecking:Complete';
              break;
            case 'edit':
              pageAnalyticName =
                this.paymentMethodAccountType === PaymentMethodAccountType.CREDIT_DEBIT
                  ? 'EditPaymentMethod:EditCard:Complete'
                  : 'EditPaymentMethod:EditCheckingSavings:Complete';
              break;
            case 'delete-success':
              pageAnalyticName =
                this.paymentMethodAccountType === PaymentMethodAccountType.CREDIT_DEBIT
                  ? 'DeletePaymentMethod:DebitCreditCard:Complete'
                  : 'DeletePaymentMethod:CheckingSavings:Complete';
              break;
            case 'delete-error':
              pageAnalyticName = 'DeletePaymentMethod:Error';
              break;
          }
          this.paymentMethodFacade.sendPaymentMethodAnalytics({ pageAnalyticName });

          this.spinnerService.stop();
        }
      );
  }

  private getConfirmationConfig(
    messageHeader: string,
    operation: string,
    primaryButtonName: string,
    tertiaryButtonName: string
  ): Observable<ConfirmationConfig> {
    return of({
      status: operation === 'delete-error' ? ConfirmationStatus.ERROR : ConfirmationStatus.SUCCESS,
      heading: messageHeader,
      ctaConfig: {
        primaryButtonName: primaryButtonName,
        tertiaryButtonName: tertiaryButtonName
      }
    });
  }

  submit() {
    switch (this.operation) {
      case 'delete-error':
        this.paymentMethodFacade.deletePaymentMethod(this.paymentMethod);
        // send analytics
        this.paymentMethodFacade.sendPaymentMethodAnalytics({
          buttonAnalyticName: 'DeletePaymentMethod:Error:TryAgain'
        });
        break;

      default:
        this.done();
        break;
    }
  }

  done() {
    // send analytics
    let buttonAnalyticName: string;
    switch (this.operation) {
      case 'add':
        buttonAnalyticName =
          this.paymentMethodAccountType === PaymentMethodAccountType.CREDIT_DEBIT
            ? 'AddPaymentMethod:SetupDebitCreditCard:Complete:Done'
            : 'AddPaymentMethod:SetupSavingsChecking:Complete:Done';
        break;
      case 'edit':
        buttonAnalyticName =
          this.paymentMethodAccountType === PaymentMethodAccountType.CREDIT_DEBIT
            ? 'EditPaymentMethod:EditCard:Done'
            : 'EditPaymentMethod:EditCheckingSavings:Done';
        break;
      case 'delete-success':
        buttonAnalyticName =
          this.paymentMethodAccountType === PaymentMethodAccountType.CREDIT_DEBIT
            ? 'DeletePaymentMethod:DebitCreditCard:Done'
            : 'DeletePaymentMethod:CheckingSavings:Done';
        break;
      case 'delete-error':
        buttonAnalyticName = 'DeletePaymentMethod:Error:Done';
        break;
    }
    this.paymentMethodFacade.sendPaymentMethodAnalytics({ buttonAnalyticName });

    this.store.dispatch(
      new fromRouterActions.Go({
        path: ['billing']
      })
    );
  }
}

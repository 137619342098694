import { switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import { fromRouterActions } from '@amfam/shared/utility/navigation'
import { isUserVerified } from '../../core/store/reset-password/reset-password.reducer';

@Injectable()
export class ResetPasswordGuard  {
  constructor(private store: Store<fromRoot.RootState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromRoot.isResetPasswordUserVerified).pipe(
      take(1),
      switchMap(isVerified => {
        // Returns true if user has successfully been verified to Reset Password
        if (isVerified) {
          return of(true);
        }
        // Returns false and routes to first step of Forgot Password flow
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/forgot-password']
          })
        );
        return of(false);
      })
    );
  }
}

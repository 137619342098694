import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'prettyClaimNum' })
export class PrettyClaimNum implements PipeTransform {
  transform(value: any): any {
    if (value && value.length && value.length === 11) {
      return value.slice(0, 2) + '-' + value.slice(2, 5) + '-' + value.slice(5);
    }

    return value;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import { PaymentService } from '@amfam/billing/shared/util';

import { AutopayPredictPayloadModel } from '@amfam/shared/models';
import { EditAutopayPredict } from './autopay-predict.actions';

import {
  GetAutopayPredict,
  GetAutopayPredictSuccess,
  GetAutopayPredictFail,
  AutopayPredictActionTypes
} from './autopay-predict.actions';

@Injectable()
export class AutopayPredictEffects {
  
  getAutopayPredict$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<GetAutopayPredict>(AutopayPredictActionTypes.GetAutopayPredict),
    map(action => action.payload),
    switchMap((payload: AutopayPredictPayloadModel) => {
      return this.paymentService.postAutopayPredict(payload).pipe(
        map((predictAutopayRes: any) => new GetAutopayPredictSuccess(predictAutopayRes)),
        catchError(error =>
          of(
            new GetAutopayPredictFail({
              billAccountNumber: payload.billAccountNumber,
              error: error
            })
          )
        )
      );
    })
  ));

  
  autoPayPredictEdit$ = createEffect(() => this.actions$.pipe(
    ofType<EditAutopayPredict>(AutopayPredictActionTypes.EditAutopayPredict),
    map(action => action.payload),
    switchMap(payload =>
      this.paymentService.putAutopayPredict(payload).pipe(
        map((predictAutopayRes: any) => new GetAutopayPredictSuccess(predictAutopayRes)),
        catchError(error =>
          of(
            new GetAutopayPredictFail({
              billAccountNumber: payload.billAccountNumber,
              error: error
            })
          )
        )
      )
    )
  ));

  constructor(private actions$: Actions, private paymentService: PaymentService) {}
}

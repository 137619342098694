import { createFeatureSelector, createSelector, ActionReducerMap, select } from '@ngrx/store';
import {
  PENDING_REGISTRATION_FEATURE_KEY,
  PendingRegistrationEntityState
} from './pending-registration.reducer';
import * as fromPendingRegistrationState from './pending-registration.reducer';
import * as fromPendingRegistrationNotifications from './pending-registration-notifications.reducer';
import { get as _get, flatten as _flatten } from 'lodash';

export interface RegistrationState {
  pendingRegistrationState: fromPendingRegistrationState.PendingRegistrationEntityState;
  pendingRegistrationNotifications: fromPendingRegistrationNotifications.NotificationState;
}

export const billingRegistrationReducers: ActionReducerMap<RegistrationState> = {
  pendingRegistrationState: fromPendingRegistrationState.reducer,
  pendingRegistrationNotifications: fromPendingRegistrationNotifications.reducer
};

// TODO: Change this when the bill account registration is moved here.
const getRegistrationState = createFeatureSelector<RegistrationState>('billing registrations');

const getPendingRegistrationsLoaded = createSelector(
  getRegistrationState,
  (state: RegistrationState) => !_get(state, 'pendingRegistrationNotifications.loading', true)
);

const getError = createSelector(getRegistrationState, (state: RegistrationState) =>
  _get(state, 'pendingRegistrationNotifications.error')
);

/**
 * Pending Registration Reducers
 */

export const getPendingRegistrationIds = createSelector(
  getRegistrationState,
  getPendingRegistrationsLoaded,
  (state, isLoaded) => {
    return isLoaded
      ? <string[]>fromPendingRegistrationState.selectIds(state.pendingRegistrationState)
      : [];
  }
);

export const getPendingRegistrationEntities = createSelector(
  getRegistrationState,
  getPendingRegistrationsLoaded,
  (state, isLoaded) => {
    return isLoaded
      ? fromPendingRegistrationState.selectEntities(state.pendingRegistrationState)
      : [];
  }
);

export const getPendingRegistrationError = createSelector(
  getRegistrationState,
  (state: RegistrationState) => !!_get(state, 'pendingRegistrationNotifications.error')
);

export const getPendingRegistrations = createSelector(
  getPendingRegistrationEntities,
  getPendingRegistrationIds,
  (entities, ids) => {
    return ids.map(id => entities[id]);
  }
);

export const registrationsQuery = {
  getPendingRegistrationIds,
  getPendingRegistrationEntities,
  getPendingRegistrationError,
  getPendingRegistrations,
  getRegistrationState
};

<div [ngClass]="{ 'note-info': nextSteps.isNote }">
  <div class="confirmation-nextSteps">
    <h2
      *ngIf="nextSteps?.heading"
      class="confirmation-nextSteps-title margin-bottom-0"
      [innerHTML]="nextSteps.heading"
    ></h2>
    <p *ngIf="nextSteps?.text" [innerHTML]="nextSteps.text"></p>
  </div>
</div>

import { BrandSelectors } from '@amfam/shared/utility/brand';
import {
  Applications,
  ApplicationService,
  ConfigService,
  CopyService,
  PartnerTermsData
} from '@amfam/shared/utility/shared-services';
import { DsModalService } from '@amfam/ui-kit';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  PaymentTermsAndConditionsPartnerContent,
  PaymentTermsAndConditionsType
} from './payment-terms-and-conditions.models';
@Component({
  selector: 'ds-payment-terms-and-conditions',
  templateUrl: './payment-terms-and-conditions.component.html',
  styleUrls: ['./payment-terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentTermsAndConditionsComponent implements OnChanges {
  @Input() paymentType: PaymentTermsAndConditionsType;
  @Input() isPartner: boolean;
  @Input() partnerContent: PaymentTermsAndConditionsPartnerContent;
  @Input()
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(this._visible);
  }
  public termsPartnerData$: Observable<PartnerTermsData> = this.store.select(
    BrandSelectors.getPartnerTermsData
  );

  public paymentTermsConditionModal = 'PaymentTermsConditionModal';
  public modalWidth = '596';
  private _visible = false;
  public paymentTermsConditionContent: Observable<string>;
  public isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);

  termsAndConditionsHtml: string;
  @Output()
  public visibleChange = new EventEmitter<boolean>();
  @Output()
  public agreeTermsAndConditionsEvent = new EventEmitter<boolean>();

  get visible(): boolean {
    return this._visible;
  }

  constructor(
    private copyService: CopyService,
    private config: ConfigService,
    private modalService: DsModalService,
    private store: Store<any>,
    private appService: ApplicationService
  ) {}

  ngOnChanges(): void {
    if (this.isAdminApp) {
      this.termsAndConditionsHtml = this.copyService.getCopy(
        'billing.termsAndConditions.myAccountAdmin.' + this.paymentType,
        {
          companyName: this.isPartner
            ? this.partnerContent.companyName
            : this.copyService.getCopy('shared.amfamCompanyName'),
          phoneNumber: this.isPartner
            ? this.partnerContent.phoneNumber
            : this.copyService.getCopy('shared.amfamPhoneNumber'),
          landingPage: this.isPartner
            ? this.partnerContent.landingPage
            : this.config.get('links.myAccountURL'),
          companyInfoPage: this.config.get('links.companyInfoPage')
        }
      );
    } else {
      this.termsAndConditionsHtml = this.copyService.getCopy(
        'billing.termsAndConditions.' + this.paymentType,
        {
          companyName: this.isPartner
            ? this.partnerContent.companyName
            : this.copyService.getCopy('shared.amfamCompanyName'),
          phoneNumber: this.isPartner
            ? this.partnerContent.phoneNumber
            : this.copyService.getCopy('shared.amfamPhoneNumber'),
          landingPage: this.isPartner
            ? this.partnerContent.landingPage
            : this.config.get('links.myAccountURL'),
          companyInfoPage: this.config.get('links.companyInfoPage')
        }
      );
    }
    if (this.visible) {
      this.openModal();
    }
  }

  openModal(): void {
    this.modalService.open(this.paymentTermsConditionModal);
  }

  closeModal(event?: boolean): void {
    this.modalService.close(this.paymentTermsConditionModal);
    this.visible = false;
  }
  agreeTermsAndConditions(isAgreeing: boolean): void {
    this.modalService.close(this.paymentTermsConditionModal);
    this.visible = false;

    this.agreeTermsAndConditionsEvent.emit(isAgreeing);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep as _cloneDeep, has as _has } from 'lodash';

@Pipe({ name: 'prettyBillingAcctNum' })
export class PrettyBillingAcctNum implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }
    if (value !== undefined && value.length === 11) {
      let prettyString = '';
      for (let i = 0; i < value.length; i++) {
        prettyString += value[i];
        if (i === 2 || i === 5 || i === 8) {
          prettyString += '-';
        }
      }
      return prettyString;
    } else if (value !== undefined && value.length === 6) {
      let prettyString = '000-';
      for (let i = 0; i < value.length; i++) {
        prettyString += value[i];
        if (i === 2) {
          prettyString += '-';
        }
      }
      return prettyString;
    }
    return value;
  }
}

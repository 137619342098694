import { AutoPayActions } from '@amfam/billing/auto-pay/data-access';
import { billaccountsQuery as BillAccountsSelectors } from '@amfam/billing/billaccount/data-access/src/lib/+state/billaccount.selectors';
import { PaymentMethodActions } from '@amfam/billing/payment-method/data-access';
import { PaymentService } from '@amfam/billing/shared/util';
import {
  INVALID_IMPERSONATION_STATUS_CODE,
  ONLINE_BILLING,
  PaymentAccount,
  UNAVAILABLE_PAYMENTACCOUNT_ID
} from '@amfam/shared/models';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { UtilService } from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { from, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { PaymentaccountService } from '../services/paymentaccount.service';
import * as PaymentAccountActions from './paymentaccount.actions';

@Injectable()
export class PaymentAccountEffects {
  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsLoad),
      switchMap(() =>
        this.paymentService.getPaymentMethods().pipe(
          finalize(() => {
            this.store.dispatch(new PaymentAccountActions.PaymentAccountsLoadComplete());
          }),
          mergeMap((paymentMethodsResponse: any) => from(paymentMethodsResponse.paymentAccounts)),
          map((fetchedPaymentAccount: PaymentAccount) => {
            return new PaymentAccountActions.PaymentAccountsLoadSuccess(fetchedPaymentAccount);
          }),
          catchError(error => of(new PaymentAccountActions.PaymentAccountsLoadFail(error)))
        )
      )
    )
  );

  loadAll$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsLoadSuccess),
      map((action: PaymentAccountActions.PaymentAccountsLoadSuccess) => action.payload),
      mergeMap((pa: PaymentAccount) => [
        new PaymentAccountActions.PaymentAccountsLoadDetail(pa.paymentAccountId)
      ]),
      catchError(error => of(new PaymentAccountActions.PaymentAccountsLoadFail(error)))
    )
  );

  loadDetail$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsLoadDetail),
      map((action: PaymentAccountActions.PaymentAccountsLoadDetail) => action.payload),
      mergeMap(payload =>
        this.paymentService.getPaymentMethodDetail(payload).pipe(
          finalize(() => {
            this.store.dispatch(new PaymentAccountActions.PaymentAccountsLoadComplete());
          }),
          mergeMap(paymentMethodsResponse => from([paymentMethodsResponse])),
          map(
            detailedPaymentAccount =>
              new PaymentAccountActions.PaymentAccountsLoadDetailSuccess({
                creditDetails: _get(detailedPaymentAccount, 'paymentAccounts[0].creditCard'),
                nickName: _get(detailedPaymentAccount, 'paymentAccounts[0].nickName'),
                autopayRules: _get(detailedPaymentAccount, 'autoPayRules'),
                scheduledPayments: _get(detailedPaymentAccount, 'payments')
              })
          ),
          catchError(error => of(new PaymentAccountActions.PaymentAccountsDeleteFail(error)))
        )
      ),
      catchError(error => of(new PaymentAccountActions.PaymentAccountsDeleteFail(error)))
    )
  );

  reloadPaymentAccount$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsRefresh),
        withLatestFrom(this.store.select(BillAccountsSelectors.getUserRegisteredBillAccounts)),
        map(([action, userRegisteredBillAccounts]) => {
          let registeredPresent: boolean;
          userRegisteredBillAccounts.forEach(billAccount => {
            if (_get(billAccount, 'billingMethod') === ONLINE_BILLING) {
              registeredPresent = true;
            }
          });
          if (!registeredPresent) {
            this.store.dispatch(new PaymentAccountActions.PaymentAccountsLoad());
          }
        })
      ),
    {
      dispatch: false
    }
  );

  save$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsSave),
      map((action: PaymentAccountActions.PaymentAccountsSave) => action.payload),
      switchMap(payload => {
        return this.paymentService.savePaymentMethod(payload.finAcctPayload).pipe(
          map(fasResponse => {
            if (String(_get(fasResponse, 'finAcctServiceResponse.apiStatus.code')) === '200') {
              const nextActionPayload = this.paymentService.attachFinAcctServiceResponse(
                payload,
                fasResponse
              );
              if (payload.action === 'add') {
                return new PaymentAccountActions.PaymentAccountsAdd(nextActionPayload);
              } else if (payload.action === 'edit') {
                return new PaymentAccountActions.PaymentAccountsEdit(nextActionPayload);
              }
            } else {
              return new PaymentAccountActions.PaymentAccountsSaveFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: fasResponse.apiStatus
                  }
                )
              );
            }
          }),
          catchError(error => {
            return of(
              new PaymentAccountActions.PaymentAccountsSaveFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: error
                  }
                )
              )
            );
          })
        );
      })
    )
  );

  add$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsAdd),
      map((action: PaymentAccountActions.PaymentAccountsAdd) => action.payload),
      switchMap(payload => {
        return this.paymentService.addPaymentMethod(payload.customerPaymentPayload).pipe(
          mergeMap(res => {
            const returnArray = [];
            /**
             * Status code of '202304' will be returned if the user is impersonating and is
             * trying to add a payment account without sufficient permission to do so.
             * In this case treat the response code of '202304' as a failure code and stop them from
             * moving forward.
             */
            if (_get(res, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
              returnArray.push(
                new PaymentAccountActions.PaymentAccountsAddFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: res.status
                    }
                  )
                )
              );
            } else {
              const successObject = Object.assign(
                {},
                {
                  request: payload,
                  response: res,
                  confirmation: this.paymentService.buildAddEditPaymentMethodConfirmation(
                    payload,
                    res
                  )
                }
              );
              returnArray.push(
                new PaymentAccountActions.PaymentAccountsAddSuccess(successObject),
                new PaymentAccountActions.PaymentAccountsTruncate(UNAVAILABLE_PAYMENTACCOUNT_ID),
                new PaymentAccountActions.PaymentAccountsLoadDetail(res.paymentAccountId)
              );

              returnArray.push(
                PaymentMethodActions.getPaymentMethods({
                  correlationId: this.utilService.generateId()
                })
              );
            }
            return returnArray;
          }),
          catchError(error => {
            return of(
              new PaymentAccountActions.PaymentAccountsAddFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: error
                  }
                )
              )
            );
          })
        );
      })
    )
  );

  addFailCleanup$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsAddFail),
      map((action: PaymentAccountActions.PaymentAccountsAddFail) => action.payload),
      map(payload => {
        return new PaymentAccountActions.PaymentAccountsTruncate(UNAVAILABLE_PAYMENTACCOUNT_ID);
      })
    )
  );

  editFailCleanup$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsEditFail),
      map((action: any) => {
        return new PaymentAccountActions.PaymentAccountsCleanup(
          _get(action, 'payload.request.currentPaymentAccountNickName')
        );
      })
    )
  );

  updateFailCleanup$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthFail),
      map((action: any) => {
        return new PaymentAccountActions.PaymentAccountsCleanup(
          _get(action, 'payload.request.paymentAccount.nickName')
        );
      })
    )
  );

  deleteFailCleanup$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsDeleteFail),
      map((action: any) => {
        return new PaymentAccountActions.PaymentAccountsCleanup(
          _get(action, 'payload.request.nickName')
        );
      })
    )
  );

  edit$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsEdit),
      map((action: PaymentAccountActions.PaymentAccountsEdit) => action.payload),
      switchMap(payload => {
        return this.paymentService
          .editPaymentMethod(payload.customerPaymentPayload, payload.paymentAccountId)
          .pipe(
            mergeMap(res => {
              const returnArray = [];
              /**
               * Status code of '202304' will be returned if the user is impersonating and is
               * trying to edit a payment account without sufficient permission to do so.
               * In this case treat the response code of '202304' as a failure code and stop them from
               * moving forward.
               */
              if (_get(res, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
                returnArray.push(
                  new PaymentAccountActions.PaymentAccountsEditFail(
                    Object.assign(
                      {},
                      {
                        request: payload,
                        status: res.status
                      }
                    )
                  )
                );
              } else {
                const paymentConfirmationObj =
                  this.paymentService.buildAddEditPaymentMethodConfirmation(payload, res);
                const successObject = Object.assign(
                  {},
                  {
                    request: payload,
                    response: res,
                    confirmation: paymentConfirmationObj
                  }
                );
                returnArray.push(
                  new PaymentAccountActions.PaymentAccountsEditSuccess(successObject),
                  new PaymentAccountActions.PaymentAccountsLoadDetail(res.paymentAccountId),
                  new PaymentAccountActions.PaymentAccountsCleanup(payload.finAcctPayload.nickName)
                );
                returnArray.push(
                  PaymentMethodActions.getPaymentMethods({
                    correlationId: this.utilService.generateId()
                  })
                );
              }
              return returnArray;
            }),
            catchError(error => {
              return of(
                new PaymentAccountActions.PaymentAccountsEditFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: error
                    }
                  )
                )
              );
            })
          );
      })
    )
  );

  update$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsUpdateModeAuth),
      map((action: PaymentAccountActions.PaymentAccountsUpdateModeAuth) => action.payload),
      switchMap(payload => {
        return this.paymentService.updateModeOfAuth(payload.request, payload.paymentAccountId).pipe(
          mergeMap(res => {
            const returnArray = [];
            const successObject = Object.assign(
              {},
              {
                request: payload.request,
                response: res
              }
            );
            returnArray.push(
              new PaymentAccountActions.PaymentAccountsUpdateModeAuthSuccess(successObject),
              new PaymentAccountActions.PaymentAccountsLoadDetail(res.paymentAccountId),
              new PaymentAccountActions.PaymentAccountsCleanup(
                payload.request.paymentAccount.nickName
              )
            );
            returnArray.push(
              PaymentMethodActions.getPaymentMethods({
                correlationId: this.utilService.generateId()
              })
            );

            return [...returnArray, ...payload.paymentAction];
          }),
          catchError(error => {
            return of(
              new PaymentAccountActions.PaymentAccountsUpdateModeAuthFail(
                Object.assign(
                  {},
                  {
                    request: payload.request,
                    correlationId: payload.correlationId,
                    status: error
                  }
                )
              )
            );
          })
        );
      })
    )
  );

  delete$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsDelete),
      map((action: PaymentAccountActions.PaymentAccountsDelete) => action.payload),
      withLatestFrom(this.store.select(BillAccountsSelectors.getAllBillAccounts)),
      switchMap(([payload, billAccounts]) => {
        return this.paymentService.deletePaymentMethod(payload).pipe(
          mergeMap(res => {
            const returnArray = [];
            /**
             * Status code of '202304' will be returned if the user is impersonating and is
             * trying to delete a payment account without sufficient permission to do so.
             * In this case treat the response code of '202304' as a failure code and stop them from
             * moving forward.
             */
            if (_get(res, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
              returnArray.push(
                new PaymentAccountActions.PaymentAccountsDeleteFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: res.status
                    }
                  )
                )
              );
            } else {
              const paymentConfirmationObj =
                this.paymentService.buildDeletePaymentMethodConfirmationData(payload, res);
              returnArray.push(
                new PaymentAccountActions.PaymentAccountsDeleteSuccess(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      response: res,
                      confirmation: paymentConfirmationObj
                    }
                  )
                ),
                new PaymentAccountActions.PaymentAccountsTruncate(payload.nickName)
              );

              returnArray.push(
                PaymentMethodActions.getPaymentMethods({
                  correlationId: this.utilService.generateId()
                })
              );
              returnArray.push(
                AutoPayActions.getAllAutomaticPayments({
                  correlationId: this.utilService.generateId(),
                  billAccountNumbers: billAccounts.map(billAccount => billAccount.billAccountNumber)
                })
              );
            }
            return returnArray;
          }),
          catchError(error => {
            return of(
              new PaymentAccountActions.PaymentAccountsDeleteFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: error
                  }
                )
              )
            );
          })
        );
      })
    )
  );

  updateScreenRecording$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountScreenRecordingUpdate),
        map((action: PaymentAccountActions.PaymentAccountScreenRecordingUpdate) => action.payload),
        switchMap(payload => this.paymentAccountService.updateScreenRecording(payload))
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private action$: Actions,
    private paymentService: PaymentService,
    private paymentAccountService: PaymentaccountService,
    private feature: FeatureFlagService,
    private utilService: UtilService
  ) {}
}

<div class="quote">
    <div class="quote-header pad-top-10">{{quote.header}}</div>
    <div class="cards">
        <ds-premium-card *ngIf="quote?.premiumPerMonth" [amount]="quote?.premiumPerMonth?.amount"
            [term]="quote?.premiumPerMonth?.term"></ds-premium-card>
        <div *ngIf="quote?.premiumOther" class="separator"></div>
        <ds-premium-card *ngIf="quote?.premiumOther?.amount" [amount]="quote?.premiumOther?.amount"
            [term]="quote?.premiumOther?.term"></ds-premium-card>
    </div>
    <div *ngIf="quote?.subText" class="pad-16 color-white sub-text">
        {{quote?.subText}}
    </div>
</div>
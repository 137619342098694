import { ApiStatus } from '../api/api-status.model';
import { PaymentConfirmationModel } from './payment-confirmation.model';

export interface AutoPayPayloadModel {
  payload: {
    autopayRule: {
      paymentAccountNickName: string;
      daysBeforeDueDate: string;
      paymentAmount: 'MINDUE' | 'FULLPAY';
    };
    lastUpdateTimestamp?: string;
  };
  action?: string;
  billAccountNumber: string;
  billAccountNickName: string;
  billAccountDueDate: string;
  hasScheduledPayment?: boolean;
  pendingScheduledPayment?: boolean;
  status?: ApiStatus;
  hasError?: boolean;
  correlationId: string;
}

export interface MultipleAutoPayPayloadModel {
  accounts: {
    billingNumber: string;
    paymentAccountNickName: string;
    daysBeforeDueDate: string;
    paymentAmount: string;
  }[];
}
export interface SubmitAutoPayResponseModel {
  autoPayRules: Array<AutomaticPayment>;
  status: ApiStatus;
}

export interface AutomaticPayment {
  billAccountNumber: string;
  daysBeforeDueDate: string;
  lastUpdateTimestamp: string;
  paymentAccount: {
    creditCard?: {
      cardNumber: string;
      cardType: string;
    };
    achWithdrawal?: {
      routingNumber: string;
      accountNumber: string;
      accountType: string;
      accountUse: string;
      financialInstitution: string;
    };
    lastUpdateTimestamp?: string;
    nickName: string;
    paymentAccountId?: string;
    modeOfAuthorization?: string;
  };
  paymentAmount: string;
  nextPaymentDate: string;
  predictedDollarAmount: string;
}

export interface AutoPaySuccessModel {
  request: AutoPayPayloadModel;
  response: SubmitAutoPayResponseModel;
  confirmation: PaymentConfirmationModel;
}

export interface MultipleAutoPaySuccessModel {
  request: MultipleAutoPayPayloadModel;
  response: MultipleAutoPayResponse;
  confirmation: PaymentConfirmationModel;
}

export interface MultipleAutoPayResponse {
  autoPayRules?: AutomaticPayment[];
  partialStatuses?: {
    payloadEntityId: string;
    status: ApiStatus;
  }[];
  status?: ApiStatus;
}
export interface SubmitAutoPayFailModel {
  request: AutoPayPayloadModel;
  status: ApiStatus;
}

export interface EditAutoPayFailModel {
  request: AutoPayPayloadModel;
  status: ApiStatus;
}

export interface DeleteAutoPayFailModel {
  request: AutoPayPayloadModel;
  status: ApiStatus;
}

export interface AutopayAnalyticModel {
  pageAnalytic: { pageName: string };
  eventAnalytic?: { eventStep: string };
}

export class AutopayPredictPayloadModel {
  transactionId?: string;
  lastUpdateTimestamp?: string;
  autopayRule: {
    paymentAccountNickName: string;
    daysBeforeDueDate: number | string;
    paymentAmount: 'MINDUE' | 'FULLPAY';
  };
  predictionOnly: string | boolean;
  billAccountNumber?: string;
}

export class AutoPayAccountModel {
  accountNickName: string;
  billAccountNumber: string;
  isRegistered: boolean;
}

export class AutoPayFormModel {
  account: AutoPayAccountModel;
  paymentMethod: string;
  reminder: string;
}

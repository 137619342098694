<div class="input-group column twelve">
  <div class="form-group" [ngClass]="{ 'has-error': errorMessage }">
    <div #inputWrapper [class]="inputWrapperClass">
      <ng-content></ng-content>
      <label
        *ngIf="labelText"
        [attr.for]="inputIdAttr"
        [class]="labelClass"
        [ngClass]="{ floating: isFloating }"
        class="control-label"
        >{{ labelText }}</label
      >
    </div>
  </div>
</div>

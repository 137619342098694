import { QuickLink } from '@amfam/shared/ui/quick-links';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'links-agents-sidebar',
  templateUrl: './links-agents-sidebar.component.html',
  styleUrls: ['./links-agents-sidebar.component.scss']
})
export class LinksAgentsSidebarComponent implements OnInit {
  @Input() quickLinksTitle: string;
  @Input() quickLinksList: QuickLink[];
  @Input() isQuickLinkDisable: boolean;

  constructor() {}

  ngOnInit() {}
}

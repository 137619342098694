export class File {
  get fileName(): string {
    if (!this.absolutePath) {
      return '';
    }
    const filePathParts = this.absolutePath.split(/\\|\//);
    if (!filePathParts.length) {
      return '';
    }

    return filePathParts[filePathParts.length - 1];
  }

  constructor(
    public absolutePath: string,
    public contentType: string,
    public friendlyName: string
  ) {}
}

import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { omit as _omit } from 'lodash';

import {
  GetPaymentMethodsResponse,
  GetPaymentMethodDetailPayload,
  GetPaymentMethodDetailResponse,
  AddEditPaymentMethodPayload,
  DeletePaymentMethodPayload,
  DeletePaymentMethodResponse,
  AddEditPaymentMethodResponse,
  UpdateModeOfAuthorizationPayload,
  UpdateModeOfAuthorizationResponse
} from '../models/payment-method.model';
import { catchError } from 'rxjs/operators';
import { ApiStatus } from '@amfam/shared/models/src';
import { get as _get } from 'lodash';

@Injectable({ providedIn: 'root' })
export class PaymentMethodService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getPaymentMethods(): Observable<GetPaymentMethodsResponse> {
    const endpoint = this.config.get('paymentApi') + 'paymentaccounts';
    return this.http.get<GetPaymentMethodsResponse>(endpoint).pipe(
      catchError((err: { status: ApiStatus }) => {
        // 404 is not error, it means there are no payment methods
        if (
          _get(err, 'status.code', '').toString() === '404' ||
          _get(err, 'error.status.code', '').toString() === '404'
        ) {
          return of({
            paymentAccounts: [],
            status: err.status
          });
        }
        // otherwise, throw error like normal
        else {
          throw err;
        }
      })
    );
  }

  getPaymentMethodDetail(
    payloadObj: GetPaymentMethodDetailPayload
  ): Observable<GetPaymentMethodDetailResponse> {
    const endpoint = this.config.get('paymentApi') + 'paymentaccounts/' + payloadObj;
    return this.http.get<GetPaymentMethodDetailResponse>(endpoint);
  }

  addPaymentMethod(payload: AddEditPaymentMethodPayload): Observable<AddEditPaymentMethodResponse> {
    const endpoint: string = this.config.get('paymentApi') + 'paymentaccounts';
    return this.http.post<AddEditPaymentMethodResponse>(endpoint, payload);
  }

  editPaymentMethod(
    payload: AddEditPaymentMethodPayload,
    paymentAccountId?: string
  ): Observable<AddEditPaymentMethodResponse> {
    const endpoint: string = this.config.get('paymentApi') + 'paymentaccounts/' + paymentAccountId;
    return this.http.put<AddEditPaymentMethodResponse>(endpoint, payload);
  }

  updateModeOfAuthorization(
    payload: UpdateModeOfAuthorizationPayload
  ): Observable<UpdateModeOfAuthorizationResponse> {
    const endpoint: string =
      this.config.get('paymentApi') + 'paymentaccounts/' + payload.paymentAccountId;
    return this.http.put<UpdateModeOfAuthorizationResponse>(endpoint, payload.requestBody);
  }

  deletePaymentMethod(paymentAccountId: string): Observable<DeletePaymentMethodResponse> {
    const uri = this.config.get('paymentApi') + 'paymentaccounts/' + paymentAccountId;
    return this.http.delete<DeletePaymentMethodResponse>(uri);
  }
}

import { Action } from '@ngrx/store';
import { PendingPayment } from '../models/pending-payment.models';

export const ADD_PENDING_PAYMENTS = '[PendingPayments] Add Many';
export const ADD_PENDING_PAYMENT = '[PendingPayment] Add One';
export const CLEAR_PENDING_PAYMENTS = '[PendingPayments] Clear All';
export const REMOVE_PENDING_PAYMENT = '[PendingPayment] Remove One';

export class AddPendingPaymentsAction implements Action {
  readonly type = ADD_PENDING_PAYMENTS;
  constructor(public payload: PendingPayment[]) {}
}

export class AddPendingPaymentAction implements Action {
  readonly type = ADD_PENDING_PAYMENT;
  constructor(public payload: PendingPayment) {}
}

export class ClearPendingPaymentsAction implements Action {
  readonly type = CLEAR_PENDING_PAYMENTS;
  constructor(public payload?: any) {}
}

export class RemovePendingPaymentAction implements Action {
  readonly type = REMOVE_PENDING_PAYMENT;
  constructor(public payload: PendingPayment) {}
}

export type PendingPaymentAction =
  | AddPendingPaymentsAction
  | AddPendingPaymentAction
  | ClearPendingPaymentsAction
  | RemovePendingPaymentAction;

export const pendingPaymentActions = {
  AddPendingPaymentsAction,
  AddPendingPaymentAction,
  ClearPendingPaymentsAction,
  RemovePendingPaymentAction
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromPendingPayment from './+state/pending-payment.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('pendingPayment', fromPendingPayment.pendingPaymentReducer)
  ]
})
export class BillingPendingPaymentDataAccessModule {}

<table class="vehicle-table" data-cy="vehicle-table">
  <thead>
    <tr>
      <th>Year</th>
      <th>Make</th>
      <th>Model</th>
      <th>VIN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ risk.year }}</td>
      <td>{{ risk.make }}</td>
      <td>{{ risk.model }}</td>
      <td class="break-all" data-cy="vehicle-vin">{{ risk.vin }}</td>
    </tr>
  </tbody>
</table>

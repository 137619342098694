import { BrandModel, BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { has as _has } from 'lodash';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { QuickLink } from '../../models/quick-link.model';

@Component({
  selector: 'quick-links-list',
  templateUrl: './quick-links-list.component.html',
  styleUrls: ['./quick-links-list.component.scss']
})
export class QuickLinksListComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() links: QuickLink[];
  @Input() isCustomerDismissed: boolean;
  @Input() isQuickLinkDisable: boolean;

  private connectLandingPageUrl: string;
  isContactBillingToggleEnabled: boolean;

  constructor(
    private copyService: CopyService,
    private brandStore: Store<BrandModel>,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.isContactBillingToggleEnabled = this.featureFlagService.isEnabled('contact_billing');
    combineLatest(this.brandStore.select(BrandSelectors.getIsPartner), isConnectPartner => ({
      isConnectPartner: isConnectPartner
    }))
      .pipe(take(1))
      .subscribe(state => {
        // Build connect landing page url if partner
        if (this.links === undefined) {
          this.links = new Array<QuickLink>();
          this.links.push(new QuickLink('Make a Payment', '/billing'));
          // Hide Request a Change if Connect partner
          if (!state.isConnectPartner) {
            this.links.push(new QuickLink('Request a Change', '/policies/changerequest'));
          }
          if (this.isContactBillingToggleEnabled) {
            this.links.push(new QuickLink('Contact a Billing Specialist', '/billing/contact'));
          }
          this.links.push(new QuickLink('FAQs', '/faq'));
        }
        if (this.title === undefined) {
          this.title = this.copyService.get('defaultQuickLinks', 'defaultQuickLinkTitle');
        }
      });
  }

  ngOnChanges(changes: any) {
    if (_has(changes, 'links')) {
      this.links = changes.links.currentValue;
    }

    if (_has(changes, 'title')) {
      this.title = changes.title.currentValue;
    }
  }
}

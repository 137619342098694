<div class="main">
  <h1 *ngIf="emailRedirect" class="text-center">
    <span content [contentPath]="'profile.manageEmails.validateEmailLoginHeader'"></span>
  </h1>
  <div class="row pad-top-20">
    <div class="pad-bottom-20" *ngIf="responseCode === 409">
      <amfam-info-banner
        *dsPartnerAFI
        [bannerConfig]="duplicateEmailBannerConfig"
        [showIcon]="true"
        [isDuplicateEmail]="true"
      ></amfam-info-banner>
    </div>
    <div class="column twelve lg-six lg-push-three md-six md-push-three">
      <ds-cookie-check
        [showCookieWarning]="!cookiesEnabled && (cookieCheckFeatureEnabled$ | async)"
      ></ds-cookie-check>
      <div *ngIf="cookiesEnabled" class="card pad-20">
        <p *ngIf="emailRedirect" class="text-center">
          <span content [contentPath]="'profile.manageEmails.validateEmailLoginSubHeader'"></span>
        </p>
        <h1 data-cy="loginBaseHeading" class="heading-sub-page" *ngIf="!emailRedirect">
          <span content [contentPath]="'auth.loginAccountText'"> </span>
        </h1>
        <div class="content">
          <ds-login-form
            (responseCodeEvent)="responseReceived($event)"
            [isEmailValidation]="false"
            [cookieError]="!cookiesEnabled && (cookieCheckFeatureEnabled$ | async)"
          >
          </ds-login-form>

          <div *ngIf="responseCode !== 423" class="column twelve caption">
            <hr aria-hidden="true" />
            <ng-container *dsPartnerGenericHide>
              <span class="pad-right-6" content [contentPath]="'shared.createAccountText'"></span>
              <a
                [dsRouterLink]="['/enroll']"
                content
                [contentPath]="'shared.signUpText'"
                data-cy="loginBaseSignUpLink"
              ></a>
            </ng-container>
          </div>

          <ng-container *dsPartnerGenericHide>
            <div *ngIf="responseCode === 423" class="row pad-top-20">
              <div class="column twelve caption">
                <hr aria-hidden="true" />
                <span content [contentPath]="'shared.needAccountText'"></span>
                <a
                  [dsRouterLink]="['/enroll']"
                  content
                  [contentPath]="'shared.signUpText'"
                  data-cy="loginBaseSignUpLink"
                ></a>
              </div>
            </div>
          </ng-container>

          <div class="column twelve caption pad-vertical-4">
            <span content [contentPath]="'auth.loginTrouble'"></span>
            <a
              dsPartnerContent
              [brandContent]="'getPrettyCusCareNumber'"
              [brandHref]="'getCusCareNumberFullHref'"
              data-cy="loginBaseCusCareNumberLink"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

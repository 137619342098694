<div class="policy-card" data-cy="policy-card">
  <div class="grid-container">
    <div class="column">
      <div class="name">
        <p><strong>Insured Name</strong></p>
        <p class="break-all" *ngFor="let name of insureds" data-cy="insured-name">{{ name }}</p>
      </div>
      <div class="policy-number">
        <div class="risk-policy">
          <p><strong>Policy Number</strong></p>
          <p class="break-all" data-cy="policy-number">{{ risk.policyNumber }}</p>
        </div>
        <div class="copy-button">
          <a [ariaDisabled]="copied" (click)="copyPolicyNumberToClipboard()"
            ><img src="/assets/images/clipboard.svg" /><span *ngIf="!copied" data-cy="copy-button"
              >Copy</span
            ><span *ngIf="copied" data-cy="copied-message">Copied!</span>
          </a>
        </div>
      </div>
      <div class="effective-date">
        <p><strong>Effective Date</strong></p>
        <p class="break-all" data-cy="effective-date">
          {{ risk.periodStartDate | date: 'MM/d/yyyy' }}
        </p>
      </div>
    </div>
    <div class="column">
      <div class="address">
        <p><strong>Address</strong></p>
        <p class="break-all">
          {{ risk.policy.policyAddress.addressLine1 }}<br />{{
            risk.policy.policyAddress?.addressLine2
          }}{{ risk.policy.policyAddress.city }}, {{ risk.policy.policyAddress.state }}
          {{ risk.policy.policyAddress.zipCode }}
        </p>
      </div>
      <div class="coverages">
        <p><strong>Coverages</strong></p>
        <p class="break-all" data-cy="coverages">{{ coverages }}</p>
      </div>
      <div class="expiration-date">
        <p><strong>Expiration Date</strong></p>
        <p class="break-all" data-cy="expiration-date">
          {{ risk.periodEndDate | date: 'MM/d/yyyy' }}
        </p>
      </div>
    </div>
  </div>
</div>

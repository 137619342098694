import { DraftClaim } from './claim-fnol.model';

import { Address } from '@amfam/policy/models';
import format from 'date-fns/format';
import { get as _get } from 'lodash';

export class SelectOptions {
  name?: string;
  value: string;
}
export class Incident {
  cause: string;
  detail: string;
}
export interface StorePolicyInfo {
  policyNumber: string;
  riskId: string;
  autoClaimDetail: {
    vehicles: [
      {
        year: Number;
        make: string;
        model: string;
        vin: string;
      }
    ];
  };
  insured: {
    partyIdentifier: string;
    firstName: string;
    lastName: string;
    middleName: string;
    addresses: [
      {
        addr1: string;
        addr2: string;
        city: string;
        state: string;
        zip5: string;
      }
    ];
    phones: Array<string>;
    emails: Array<string>;
  };
}

export class StorePolicyInfoClass {
  static create(
    policyNumber: string,
    riskId: string,
    year: string,
    make: string,
    model: string,
    vin: string,
    firstName: string,
    lastName: string,
    partyId: string,
    address: Address
  ): StorePolicyInfo {
    return {
      policyNumber: policyNumber,
      riskId: riskId,
      autoClaimDetail: {
        vehicles: [
          {
            year: Number(year),
            make: make,
            model: model,
            vin: vin
          }
        ]
      },
      insured: {
        partyIdentifier: partyId,
        firstName: firstName,
        lastName: lastName,
        middleName: '', // TODO: Not available from user store, but fnol -> insured object needs from spec.
        addresses: [
          {
            addr1: address.addressLine1,
            addr2: address.addressLine2,
            city: address.city,
            state: address.state,
            zip5: _get(address, 'zipCode', '').slice(0, 5)
          }
        ],
        phones: [],
        emails: []
      }
    };
  }
}

export interface CreateDraftClaimData {
  lossDate: string;
  openDate?: string;
  partyId: string;
  policyNumber: string;
  riskId?: string;
  claimNumber?: string;
  formData?: any;
  draftClaim?: DraftClaim;
  component?: string;
  useProgressTrackingIndicator?: boolean;
}

export class CreateDraftClaimClass {
  static create(
    timeOfLoss: string,
    partyId: string,
    policyNumber: string,
    riskId: string
  ): CreateDraftClaimData {
    return {
      lossDate: timeOfLoss,
      openDate: format(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
      partyId: partyId,
      policyNumber: policyNumber,
      riskId: riskId,
      useProgressTrackingIndicator: true
    };
  }
}

<div class="suggest-address" *ngIf="isValid() && !!addressList.length">
  <h4>USPS Suggested Address:</h4>
  <ul *ngIf="!!addressList.length">
    <li *ngFor="let address of addressList; let i = index">
      <div class="input-group">
        <fieldset class="radio">
          <input
            type="radio"
            id="address{{i}}"
            name="address"
            (click)="onSelect(address)"
            [attr.checked]="address === selectedAddress"
          />
          <label
            class="pad-left-10"
            for="address{{i}}"
            [innerHTML]="getDisplayAddress(address)"
          ></label>
        </fieldset>
      </div>
    </li>
  </ul>
</div>

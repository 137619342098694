import { LoadingSpinnerResolverService } from '@amfam/ui-kit';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutopayComponent } from '../autopay/autopay.component';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { InitiationComponent } from '../initiation/initiation.component';
import { MaeErrorComponent } from '../mae-error/mae-error.component';
import { SkipautopayComponent } from '../skipautopay/skipautopay.component';

export const meaRoutes: Routes = [
  {
    path: 'initiation',
    component: InitiationComponent
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent
  },
  {
    path: 'error',
    component: MaeErrorComponent
  },
  {
    path: 'paperless',
    loadChildren: () =>
      import('@amfam/profile/communication-preferences/feature').then(
        m => m.ProfileCommunicationPreferencesFeatureModule
      ),
    data: {
      breadcrumb: false
    },
    resolve: {
      spinner: LoadingSpinnerResolverService
    }
  },
  {
    path: 'autopay',
    component: AutopayComponent,
    data: {
      breadcrumb: 'lazy_loaded_module'
    }
  },
  {
    path: 'skip-autopay',
    component: SkipautopayComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(meaRoutes)],
  exports: [RouterModule]
})
export class MaeFeatureRoutingModule {}

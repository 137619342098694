import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { PartnerTermsData } from '@amfam/shared/utility/shared-services';
import { AlertModalConfig, DsModalService } from '@amfam/ui-kit';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'ds-paperless-modal-wrapper',
  templateUrl: './paperless-modal-wrapper.component.html',
  styleUrls: ['./paperless-modal-wrapper.component.scss']
})
export class PaperlessModalWrapperComponent implements OnInit {
  @Output() agreeTermsAndConditionsEvent = new EventEmitter<void>();

  public termsPartnerData$: Observable<PartnerTermsData> = this.store.select(
    BrandSelectors.getPartnerTermsData
  );
  modalConfig: AlertModalConfig;
  constructor(
    private store: Store,
    private modalService: DsModalService,
    private feature: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.getModelConfig();
  }
  getModelConfig(): void {
    if (this.feature.isEnabled('go_paperless_new_enrollment_page')) {
      this.modalConfig = {
        headerText: 'Terms & Conditions',
        bodyText: '',
        modalId: 'termAndConditionsModal',
        maxWidth: 700,

        ctaConfig: {
          tertiaryButtonName: 'Cancel'
        }
      };
    } else {
      this.modalConfig = {
        headerText: 'Terms & Conditions',
        bodyText: '',
        modalId: 'termAndConditionsModal',
        maxWidth: 700,

        ctaConfig: {
          primaryButtonName: 'I Agree',
          tertiaryButtonName: 'Cancel'
        }
      };
    }
  }

  agree(): void {
    this.agreeTermsAndConditionsEvent.emit();
    this.modalService.close('termAndConditionsModal');
  }

  close(): void {
    this.modalService.close('termAndConditionsModal');
  }
}

import { PageAnalytic } from '@amfam/shared/utility/shared-services';

export class MaeAnalytics {
  public static readonly pageAgentProgramEnrollmentStart: PageAnalytic = {
    pageName: 'MyAccount:Admin:AgentProgramEnrollment:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Profile',
    subCategory3: 'Setup'
  };

  public static readonly pageAgentProgramEnrollmentComplete: PageAnalytic = {
    pageName: 'MyAccount:Admin:AgentProgramEnrollment:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Profile',
    subCategory3: 'Setup'
  };
  public static readonly pageAgentProgramEnrollmentError: PageAnalytic = {
    pageName: 'MyAccount:Admin:AgentProgramEnrollment:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Profile',
    subCategory3: 'Setup'
  };
  public static readonly pageAgentProgramEnrollmentPaperlessAgreement: PageAnalytic = {
    pageName: 'MyAccount:Admin:AgentProgramEnrollment:PaperlessAgreement',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Profile',
    subCategory3: 'Setup'
  };
  public static readonly pageAgentProgramEnrollmentMultipleAutomaticPaymentsAgreement: PageAnalytic =
    {
      pageName: 'MyAccount:Admin:BillingPayments:MultipleAutomaticPayments:Agreement',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Admin',
      subCategory2: 'Profile',
      subCategory3: 'Setup'
    };
}

export class CoverageTerm {
  public name: string;
  public description: string;
  public value: string;
  public code: string;
  public coverageTermType: string;
  public valueType: string;
  public valueDescription: string;
  public valueCode: string;

  static fromJson(json: any): CoverageTerm {
    const coverageTerm = new CoverageTerm();
    if (json) {
      coverageTerm.name = json.name;
      coverageTerm.description = json.description;
      coverageTerm.value = json.value;
      coverageTerm.code = json.code;
      coverageTerm.coverageTermType = json.coverageTermType;
      coverageTerm.valueType = json.valueType;
      coverageTerm.valueDescription = json.valueDescription;
      coverageTerm.valueCode = json.valueCode;
    }

    return coverageTerm;
  }
}

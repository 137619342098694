<h1>My Account Billing Terms and Conditions</h1>
<h2>
  <span class="underline">Disclosure Form for Electronic Billing Statements</span>
</h2>
<p>
  By agreeing to the terms and conditions of this Disclosure Form ("Disclosure"), you are agreeing
  to receive your billing statements electronically. "I", "you", and "your" means the person(s)
  listed as accountholder on your billing account. "We", "us", "our" and "Company" means the
  specific underwriting company identified in your policy documents. In order to consent to receive
  your billing statements electronically, you understand that you must maintain an account through
  My Account on the
  <a [attr.href]="partnerData.landingPageUrl" target="_blank">{{ partnerData.landingPage }}</a>
  website. All electronic billing statements will be accessed through My Account.
</p>
<p>
  <strong>The following information will apply if you choose</strong> to sign up for electronic
  billing statements
  <em>
    (electronic relating to technology having electrical, digital, magnetic, wireless, optical,
    electromagnetic, or similar capabilities):</em
  >
</p>
<ol class="number">
  <li>
    <strong> Your legal rights. </strong>You may choose to receive billing statements from us
    electronically if we first provide you with this Disclosure and obtain your consent to receive
    billing statements electronically. You must also have the appropriate hardware and software (see
    #2) available to you to receive billing statements from us electronically.
    <p>&nbsp;</p>
    You acknowledge and agree that your consent to receiving electronic billing statements is being
    provided in connection with a transaction affecting interstate commerce that is subject to the
    federal Electronic Signatures and National Commerce Act, and that you and we both intend that
    the Act apply to the fullest extent possible to validate our ability to conduct business with
    you by electronic means.
    <p>&nbsp;</p>
  </li>
  <li>
    <strong>Hardware and software requirements.</strong> In order to receive, access, and retain
    electronic billing statements that we make available to you, you must have access to a personal
    computer with internet connectivity and at least one of the following:
    <ol class="list-style-lower-alpha">
      <li>
        For Windows Operating System:
        <ol class="list-style-upper-roman">
          <li>
            <strong>Browsers:</strong> Use current version of Internet Explorer, Firefox or Google
            Chrome.
          </li>
          <li>
            <strong>Portable Document Reader (PDF):</strong> Use current version of Adobe Acrobat
            Reader.
          </li>
        </ol>
      </li>
      <li>
        For Macintosh Operating System:
        <ol class="list-style-upper-roman">
          <li><strong>Browsers:</strong> Use current version of Safari.</li>
          <li>
            <strong>Portable Document Reader (PDF):</strong> Use current version of Adobe Acrobat
            Reader.
          </li>
        </ol>
      </li>
      <li>
        You must also have:
        <ol class="list-style-upper-roman">
          <li>
            A valid email account with an email service provider in order to sign up to receive your
            billing statements electronically. It is your responsibility to provide us with a true,
            accurate and complete email address.
          </li>
          <li>
            Sufficient electronic storage capacity on your computer&rsquo;s hard drive or other data
            storage unit, if you want to save documents.
          </li>
          <li>
            A printer capable of printing web content or PDF documents to print this Disclosure or
            billing statements, if you want to locally print from your computer.
          </li>
        </ol>
      </li>
    </ol>
  </li>
  <li>
    <p>
      <strong>How to withdraw your consent to not receiving paper bills.</strong> You may elect to
      receive paper bills via USPS Mail at any time by following these steps:&nbsp;
    </p>
    <ol class="list-style-lower-alpha">
      <li>
        Log in to My Account on the
        <a [attr.href]="partnerData.landingPageUrl" target="_blank">{{
          partnerData.landingPage
        }}</a>
        website
      </li>
      <li>Hover over Hi "Name" and choose profile</li>
      <li>Choose the Paperless option and select the respective billing account.</li>
    </ol>
    <p>
      Once you have elected to receive paper billing statements, future billing statements will be
      sent to you by U.S. mail and you may be subject to fees. If you choose to receive paper
      copies, you may continue to access your billing statement in My Account. You may re-elect to
      not receive paper billing statements at any time by following the steps above. In the event
      you choose to receiving paper billing statements, any discounts applied to your policy(ies)
      that are based entirely or in part on your consent to receive your billing statements
      electronically only, will be removed at the next renewal.
    </p>
  </li>
  <li>
    <strong>Requesting paper copies of billing statements. </strong>We will not send you a paper
    copy of your billing statement unless you request it or we otherwise deem it appropriate to do
    so. You can obtain a paper copy by printing it yourself or by requesting that we mail you a
    paper copy. To request a paper copy, contact us by telephone at {{ partnerData.cusCareNumber }}.
    There is no charge associated with requesting a paper copy of a billing statement. We reserve
    the rights to provide a paper (instead of electronic) copy of any billing statement that you
    have authorized us to provide electronically.
  </li>
  <li>
    <p>
      <strong>Updating your email contact information.</strong> We will provide notice of billing
      activity through your email. It is your responsibility to maintain current email contact
      information in My Account. You will need to make any updates to your email address through My
      Account&rsquo;s "Profile" section on the
      <a [attr.href]="partnerData.landingPageUrl" target="_blank">{{ partnerData.landingPage }}</a>
      website. a. You understand any electronic billing statements will be deemed as provided to
      you, even if you do not maintain current email contact information with us. In the event we
      experience multiple email attempts returned as undeliverable by your service provider, we
      reserve the right to interpret these events as a withdrawal of your consent for electronic
      billing statements.&nbsp;
    </p>
  </li>

  <li>
    <strong>My Account.</strong> It is your responsibility to maintain an active My Account on the
    <a [attr.href]="partnerData.landingPageUrl" target="_blank">{{ partnerData.landingPage }}</a>
    website to receive billing statements electronically. If you disable your account, we will
    discontinue emailing billing statements electronically and change your delivery method to paper.
  </li>
  <br />
  <li>
    <strong>Retain copies for your records.</strong> It is your responsibility to make any hard
    copies of this Disclosure or billing statements you want to retain for your own use. Up to 24
    months of billing statements will be available for you to view or print from the "View Billing
    Statement History" section of Billing &amp; Payments in My Account. Older billing statements for
    active or inactive policies may be archived according to reasonable document retention policies
    and available on request. A copy of this Disclosure is available in My Account.
  </li>
</ol>

<strong>
  By agreeing to the terms in this document, I agree to receive all mailings, communications and
  billing statements electronically. Such electronic mailing or communications may even include
  cancellation or nonrenewal notices.
</strong>

<p>V 02-019</p>

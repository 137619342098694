import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { get as _get } from 'lodash';

import { ConfigService } from '@amfam/shared/utility/shared-services';
import { AddressLookupModel } from './address-model';

@Injectable()
export class AddressLookupService {
  private addressLookupApi: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.addressLookupApi = this.config.get('addressLookupApi');
  }

  lookup(addressModel: AddressLookupModel): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('line1', addressModel.addressLine1)
      .set('line2', addressModel.addressLine2)
      .set('city', addressModel.city)
      .set('state', addressModel.stateCode)
      .set('zip5', addressModel.zip5Code);

    const options = { params: params };

    //noinspection TypeScriptValidateTypes
    return this.http.get(this.addressLookupApi, options).pipe(
      map((data: any) => {
        const addressList = [];
        if (
          +_get(data, 'status.code') === 200 &&
          _get(data, 'addressMatchDetail.multipleAddressMatchIndicator') === true
        ) {
          const matchCandidates = _get(data, 'matchCandidates', []);
          matchCandidates.forEach(match => {
            if (match.addressMatchIndicator === true && match.addressDetail.dpvMatchCode === 'Y') {
              const addresses = _get(match, 'address', []);
              (addresses instanceof Array ? addresses : [addresses]).forEach(address => {
                addressList.push({
                  addressLine1: address.line1,
                  addressLine2: address.line2,
                  city: address.city,
                  stateCode: address.state,
                  zip5Code: address.zip5,
                  zip4Code: address.zip4
                });
              });
            }
          });
        } else if (
          +_get(data, 'status.code') === 200 &&
          _get(data, 'addressMatchDetail.exactAddressMatchIndicator') === true &&
          _get(data, 'addressMatchDetail.matchConfidence') === '100'
        ) {
          const matchCandidates = _get(data, 'matchCandidates', []);
          matchCandidates.forEach(match => {
            const addresses = _get(match, 'address', []);
            (addresses instanceof Array ? addresses : [addresses]).forEach(address => {
              addressList.push({
                addressLine1: address.line1,
                addressLine2: address.line2,
                city: address.city,
                stateCode: address.state,
                zip5Code: address.zip5,
                zip4Code: address.zip4
              });
            });
          });
        }
        return addressList;
      })
    );
  }
}

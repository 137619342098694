export const ENROLLMENT_TYPE = {
  CONVERSION: 'conversion',
  MAE: 'mae',
  PERSONAL: 'personal',
  TOKEN: 'token'
};

export const ANALYTICS_ENROLLMENT_TYPE = {
  CONVERSION: 'AddPersonalLine',
  MAE: 'mae',
  PERSONAL: 'personal',
  TOKEN: 'token'
};

import { Action } from '@ngrx/store';
import { ClaimsFaqResponse } from '../models/claim-faq.model';

export enum ClaimFaqActionTypes {
  LoadClaimFaq = '[ClaimFaq] Load Claim Faq',
  LoadClaimFaqSuccess = '[ClaimFaq] Load Claim Faq Success',
  LoadClaimFaqFail = '[ClaimFaq] Load Claim Faq Fail'
}

export class LoadClaimFaq implements Action {
  readonly type = ClaimFaqActionTypes.LoadClaimFaq;
}

export class LoadClaimFaqSuccess implements Action {
  readonly type = ClaimFaqActionTypes.LoadClaimFaqSuccess;
  constructor(public payload: ClaimsFaqResponse) {}
}

export class LoadClaimFaqFail implements Action {
  readonly type = ClaimFaqActionTypes.LoadClaimFaqFail;
  constructor(public payload?: any) {}
}

export type ClaimFaqAction = LoadClaimFaq | LoadClaimFaqSuccess | LoadClaimFaqFail;

export const fromClaimFaqActions = {
  LoadClaimFaq,
  LoadClaimFaqSuccess,
  LoadClaimFaqFail
};

<div>
  <!-- Saved Payment Methods -->
  <div class="column twelve" [formGroup]="formGroup" *ngIf="paymentMethodOptions?.length > 0">
    <div *ngFor="let option of paymentMethodOptions">
      <div
        class="payment-method-option"
        [ngClass]="{
          disabled: option.expireStatus === 'Expired',
          selected: option.id === formGroup.controls[control].value?.id
        }"
        (click)="radioButtonClick(option)"
        data-cy="paymentMethodRadioOption"
      >
        <div class="flex row items-center">
          <input
            [formControlName]="control"
            [value]="option"
            id="{{ option.id }}"
            class="margin-right-6"
            type="radio"
            [checked]="option.id === formGroup.controls[control].value?.id"
          />
          <label for="{{ option.id }}" class="flex row">
            <div>
              <ds-credit-card-icon
                *ngIf="!option.bankIcon"
                class="credit-card"
                [ccType]="option.icon"
                [ccIconHeight]="24"
              ></ds-credit-card-icon>
              <span *ngIf="option.bankIcon" class="icon-bank bank-icon" role="img"></span>
            </div>
            {{ option.nickName + ' ending in ' + option.lastFourOrThree }}
          </label>
          <div class="flex">
            <button ds-button class="-outline-white" (click)="edit(option.id)">
              <span class="icon icon-pen"> </span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="option.expireStatus">
          <div class="flex warning">
            {{ option.expireStatus }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Add Payment Method -->
  <div class="flex add-payment-method">
    <button
      ds-button
      class="-outline-white"
      [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      (click)="addCheckingAccount()"
    >
      Add Checking
    </button>
    <button
      ds-button
      class="-outline-white"
      [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      (click)="addSavingsAccount()"
    >
      Add Savings
    </button>
    <button
      ds-button
      class="-outline-white"
      [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      (click)="addCreditDebit()"
    >
      Add Card
    </button>
  </div>

  <div *ngIf="paymentMethodError" class="payment-method-error">
    <ds-error-box [messageText]="choosePaymentMethod"> </ds-error-box>
  </div>
</div>

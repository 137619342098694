<div class="row">
  <div class="column twelve">
    <div
      *ngIf="!showForm"
      class="pad-left-40 pad-top-10 float-left"
      content
      [contentPath]="'profile.editUserIdTitle'"
    ></div>
    <button
      ds-button
      *ngIf="!showForm"
      id="openEditUserIdForm"
      data-cy="openEditUserFormButton"
      class="-outline-white float-right margin-left-10"
      content
      [contentPath]="'shared.changeBtn'"
      authCheck
      [permissionName]="'changeuserid_view'"
      [checkAction]="'disable'"
      (click)="toggleEditUserIdForm()"
    ></button>
  </div>
</div>
<div class="row pad-left-40" *ngIf="showForm">
  <div class="column twelve">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="row">
        <fieldset class="column twelve">
          <legend class="heading-section" content [contentPath]="'profile.editUserIdText'"></legend>
          <ds-userid-availability
            *ngIf="showForm"
            [form]="form"
            (userIdAvailabilityEvent)="getUserIdAvailability($event)"
          ></ds-userid-availability>
        </fieldset>
      </div>
      <div class="row">
        <div class="column twelve">
          <button
            ds-button
            type="submit"
            aria-label="Save"
            data-cy="saveUserIDChanges"
            class="float-right margin-left-5"
            [disabled]="!userIdAvailable"
          >
            Save
          </button>
          <button
            ds-button
            class="-white bg-transparent cancel a float-right margin-right-5"
            aria-label="Cancel"
            type="button"
            (click)="resetForm()"
            content
            [contentPath]="'shared.cancel'"
          ></button>
        </div>
      </div>
    </form>
  </div>
</div>
<hr aria-hidden="true" class="dashed" />

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromVehicle from './+state/vehicle.reducer';
import { VehicleEffects } from './+state/vehicle.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('vehicle', fromVehicle.vehicleReducer),
    EffectsModule.forFeature([VehicleEffects])
  ]
})
export class PolicyVehicleDataAccessModule {}

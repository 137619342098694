import {
  AgentState,
  selectAgentIds,
  selectAgentEntities,
  selectAllAgents,
  AGENT_FEATURE_KEY
} from './agent.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getAgentsState = createFeatureSelector<AgentState>(AGENT_FEATURE_KEY);

const getAgentsLoading = createSelector(
  getAgentsState,
  (state: AgentState) => state.loading
);
const getAgentsLoaded = createSelector(
  getAgentsState,
  (state: AgentState) => state.loaded
);

const getAgentsError = createSelector(
  getAgentsState,
  (state: AgentState) => state.hasError
);

const getAgentEntities = createSelector(
  getAgentsState,
  selectAgentEntities
);
const getAgentIds = createSelector(
  getAgentsState,
  selectAgentIds
);
const getAgents = createSelector(
  getAgentsState,
  selectAllAgents
);
const getRealAgents = createSelector(
  getAgents,
  agents => {
    return agents.filter(agent => agent.id !== undefined && agent.id !== null);
  }
);
const getAreAllAgentsLoaded = createSelector(
  getAgents,
  agents => {
    return agents.every(agent => {
      // check loading instead of loaded; loads that succeed or fail will each be false if loading is complete
      return !agent.loading;
    });
  }
);

export const agentQuery = {
  getAgentsState,
  getAgentsLoading,
  getAgentsLoaded,
  getAgentsError,
  getAgentEntities,
  getAgentIds,
  getAgents,
  getRealAgents,
  getAreAllAgentsLoaded
};

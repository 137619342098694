import * as BillAccountActions from '@amfam/billing/billaccount/data-access/src/lib/+state/billaccount.actions';
import {
  PaymentMethodActions,
  PaymentMethodSelectors
} from '@amfam/billing/payment-method/data-access';
import {
  PaymentAccountActions,
  PaymentAccountSelectors
} from '@amfam/billing/paymentaccount/data-access';
import { PaymentService } from '@amfam/billing/shared/util';
import {
  ApiStatus,
  BillingPaymentPaths,
  GetRegisteredPaymentsResponse,
  INVALID_IMPERSONATION_STATUS_CODE,
  ScheduledPayment,
  SchedulePaymentPayloadModel,
  SubmitScheduledPaymentPayloadModel,
  UNAVAILABLE_PAYMENT_ID
} from '@amfam/shared/models';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addDays, format, subDays } from 'date-fns';
import { get as _get } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import * as ScheduledPaymentActions from './schedulepayment.actions';

@Injectable()
export class ScheduledPaymentEffects {
  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsLoad),
      switchMap(() => {
        const startDate = format(subDays(new Date(), 31), 'YYYY-MM-DD');
        const endDate = format(addDays(new Date(), 31), 'YYYY-MM-DD');
        return this.paymentService.getRegisteredPayments(startDate, endDate, false, true).pipe(
          map((response: GetRegisteredPaymentsResponse) => _get(response, 'payments', [])),
          map((payments: ScheduledPayment[]) =>
            // Normalize the billAccount number to be a string for future comparisons and consistancy across API calls

            payments.map(payment => {
              payment.billAccounts = payment.billAccounts.map(billAccount => {
                billAccount.billAccountNumber = String(billAccount.billAccountNumber);
                return billAccount;
              });
              return payment;
            })
          ),
          map(
            (payments: ScheduledPayment[]) =>
              new ScheduledPaymentActions.ScheduledPaymentsLoadSuccess(payments)
          ),
          catchError(error => of(new ScheduledPaymentActions.ScheduledPaymentsLoadFail(error)))
        );
      })
    )
  );

  add$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsSubmit),
      map((action: ScheduledPaymentActions.ScheduledPaymentsSubmit) => action.payload),
      switchMap((payload: SubmitScheduledPaymentPayloadModel) =>
        this.paymentService.addScheduledPayment(payload).pipe(
          mergeMap(res => {
            const returnArray: Array<ScheduledPaymentActions.ScheduledPaymentsActions> = [];
            /**
             * Status code of '202304' will be returned if the user is impersonating and is
             * trying to schedule a payment without sufficient permission to do so.
             * In this case treat the response code of '202304' as a failure code and stop them from
             * moving forward.
             */
            if (_get(res, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
              returnArray.push(
                new ScheduledPaymentActions.ScheduledPaymentsSubmitFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: res.status
                    }
                  )
                )
              );
            } else {
              const conf = this.paymentService.buildAddScheduledPaymentConfirmationData(
                payload,
                res
              );
              // TODO - instead of only passing the requestJSON from the payload, pass the whole thing.
              returnArray.push(
                new ScheduledPaymentActions.ScheduledPaymentsSubmitSuccess(
                  Object.assign(
                    {},
                    {
                      request: Object.assign({}, payload.requestJson, {
                        paymentMethodType: payload.paymentMethodType,
                        correlationId: payload.correlationId
                      }),
                      response: res,
                      confirmation: conf
                    }
                  )
                ),
                new ScheduledPaymentActions.ScheduledPaymentsTruncate(UNAVAILABLE_PAYMENT_ID)
              );
            }
            return returnArray;
          }),
          catchError(error =>
            of(
              new ScheduledPaymentActions.ScheduledPaymentsSubmitFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: error
                  }
                )
              )
            )
          )
        )
      )
    )
  );

  // When adding a scheduled payment fails we need to cleanup the state (error state is alread in the store)

  addFailCleanup$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitFail),
      map(() => new ScheduledPaymentActions.ScheduledPaymentsTruncate(UNAVAILABLE_PAYMENT_ID))
    )
  );

  edit$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsEdit),
      map((action: ScheduledPaymentActions.ScheduledPaymentsEdit) => action.payload),
      switchMap((payload: SchedulePaymentPayloadModel) =>
        this.paymentService.editScheduledPayment(payload).pipe(
          mergeMap(response => {
            const returnArray: Array<ScheduledPaymentActions.ScheduledPaymentsActions> = [];
            /**
             * Status code of '202304' will be returned if the user is impersonating and is
             * trying to edit a payment without sufficient permission to do so.
             * In this case treat the response code of '202304' as a failure code and stop them from
             * moving forward.
             */
            if (_get(response, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
              returnArray.push(
                new ScheduledPaymentActions.ScheduledPaymentsEditFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: response.status
                    }
                  )
                )
              );
            } else {
              const conf = this.paymentService.buildEditScheduledPaymentConfirmationData(
                payload,
                response
              );
              // TODO - this router action should be handled by the component
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: [BillingPaymentPaths.CONFIRMATION_PATH]
                })
              );
              returnArray.push(
                new ScheduledPaymentActions.ScheduledPaymentsEditSuccess(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      response: response,
                      confirmation: conf
                    }
                  )
                ),
                new ScheduledPaymentActions.ScheduledPaymentsCleanup(payload.paymentId),
                new ScheduledPaymentActions.ScheduledPaymentsLoad()
              );
            }
            return returnArray;
          }),
          catchError(error =>
            of(
              new ScheduledPaymentActions.ScheduledPaymentsEditFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: <ApiStatus>_get(error, 'status')
                  }
                )
              )
            )
          )
        )
      )
    )
  );

  // When editing / deleting a scheduled payment fails we need to cleanup the state (error state is alread in the store)

  editDeleteFailCleanup$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsEditFail,
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteFail
      ),
      map(
        (action: any) =>
          new ScheduledPaymentActions.ScheduledPaymentsCleanup(action.payload.request.paymentId)
      )
    )
  );

  delete$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsDelete),
      map((action: ScheduledPaymentActions.ScheduledPaymentsDelete) => action.payload),
      switchMap((payload: SchedulePaymentPayloadModel) =>
        this.paymentService.deleteScheduledPayment(payload).pipe(
          mergeMap(response => {
            const returnArray: Array<ScheduledPaymentActions.ScheduledPaymentsActions> = [];
            /**
             * Status code of '202304' will be returned if the user is impersonating and is
             * trying to edit a payment without sufficient permission to do so.
             * In this case treat the response code of '202304' as a failure code and stop them from
             * moving forward.
             */
            if (_get(response, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
              returnArray.push(
                new ScheduledPaymentActions.ScheduledPaymentsDeleteFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: response.status
                    }
                  )
                )
              );
            } else {
              const conf = this.paymentService.buildDeleteScheduledPaymentConfirmationData(
                payload,
                response
              );
              // TODO - this should be handled by the component
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: [BillingPaymentPaths.CONFIRMATION_PATH]
                })
              );
              returnArray.push(
                new ScheduledPaymentActions.ScheduledPaymentsDeleteSuccess(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      response: response,
                      confirmation: conf
                    }
                  )
                ),
                new ScheduledPaymentActions.ScheduledPaymentsTruncate(payload.paymentId)
              );
            }
            return returnArray;
          }),
          catchError(error =>
            of(
              new ScheduledPaymentActions.ScheduledPaymentsDeleteFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: error
                  }
                )
              )
            )
          )
        )
      )
    )
  );

  paymentEditReloadPaymentAccount$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitSuccess,
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteSuccess
      ),
      withLatestFrom(
        this.store.select(PaymentAccountSelectors.getPaymentAccountEntities),
        this.store.select(PaymentMethodSelectors.getPaymentMethodEntities)
      ),
      map(([action, paymentAccountEntities, paymentMethodEntities]) => {
        const paymentMethods = paymentMethodEntities;
        const nickName = String(_get(action, 'payload.request.payment.paymentAccount.nickName'));
        if (nickName && paymentMethods && paymentMethods[nickName]) {
          const paymentAccountId = paymentMethods[nickName].paymentAccountId;
          return new PaymentAccountActions.PaymentAccountsLoadDetail(paymentAccountId);
        } else {
          // Loading individual payment account didn't work, reload all of them
          return new PaymentAccountActions.PaymentAccountsLoad();
        }
      })
    )
  );

  // TODO - We should only reload the payment accounts affected (if they switched payment accounts)

  payment_edit_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsEditSuccess),
      map((action: ScheduledPaymentActions.ScheduledPaymentsEditSuccess) => action.payload),
      map(payload => new PaymentAccountActions.PaymentAccountsLoad())
    )
  );

  // When payment accounts are edited we need to update the reference in any scheduled payments that use it
  // When payment accounts are removed we need to remove any payments that are associated with it
  // eslint-disable-next-line @typescript-eslint/naming-convention
  paymentAccount_edit_delete_success$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsEditSuccess,
        PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsDeleteSuccess
      ),
      map(() => new ScheduledPaymentActions.fromScheduledPaymentsActions.ScheduledPaymentsLoad())
    )
  );

  // When payments are added, edited or deleted we need to query to reload the minimum due

  update_amd$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitSuccess,
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteSuccess,
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsEditSuccess
      ),
      map(
        (
          action:
            | ScheduledPaymentActions.ScheduledPaymentsSubmitSuccess
            | ScheduledPaymentActions.ScheduledPaymentsDeleteSuccess
            | ScheduledPaymentActions.ScheduledPaymentsEditSuccess
        ) => {
          const billAccounts = _get(action, 'payload.request.payment.billAccounts', []).map(
            ba => ba.billAccountNumber
          );
          return new BillAccountActions.BillAccountReloadMinimumDue(billAccounts);
        }
      )
    )
  );

  // When payment method is edited we need to make sure any payment references are updated

  editPaymentMethodSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(PaymentMethodActions.editPaymentMethodSuccess),
      map(action => {
        const payload = {
          newNickName: action.newNickName,
          oldNickName: action.oldNickName
        };
        return new ScheduledPaymentActions.ScheduledPaymentsUpdate(payload);
      })
    )
  );

  constructor(
    private store: Store,
    private feature: FeatureFlagService,
    private action$: Actions,
    private paymentService: PaymentService
  ) {}
}

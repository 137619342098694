<div>
  <h3 content [contentPath]="'enrollment.verifySSNConfirm'"></h3>
  <p><span content [contentPath]="'enrollment.verifySSNContinueText'"></span></p>
  <ds-verify-ssn (submittedSSNEvent)="verifySsn($event)"> </ds-verify-ssn>
  <small
    data-cy="enrollmentVerifySSNMismatchText"
    class="block caption error margin-top-5"
    *ngIf="verifyResponse && !verifyResponse.success && ssnNotFoundMessage"
    content
    [contentPath]="'enrollment.verifySSNMismatchText'"
  ></small>
  <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
    ><span class="icon-chevron-left"></span> Back</a
  >
</div>

/**
 * Based on MDN simple cookie framework:
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
 */

/*\
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  Revision #1 - September 4, 2014
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
|*|  https://developer.mozilla.org/User:fusionchess
|*|  https://github.com/madmurphy/cookies.js
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * docCookies.getItem(name)
|*|  * docCookies.removeItem(name[, path[, domain]])
|*|  * docCookies.hasItem(name)
|*|  * docCookies.keys()
|*|
\*/

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  constructor() {}

  getItem(key: string): string | null {
    if (!key) {
      return null;
    }

    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' +
              encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') +
              '\\s*\\=\\s*([^;]*).*$)|^.*$'
          ),
          '$1'
        )
      ) || null
    );
  }

  setItem(
    key: string,
    value: string,
    end?: Number | String | Date,
    path?: string,
    domain?: string,
    secure?: boolean
  ): boolean {
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
      return false;
    }
    let expires = '';
    if (end) {
      switch (end.constructor) {
        case Number:
          expires =
            end === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + end;
          break;
        case String:
          expires = '; expires=' + end;
          break;
        case Date:
          expires = '; expires=' + (<Date>end).toUTCString();
          break;
      }
    }
    document.cookie =
      encodeURIComponent(key) +
      '=' +
      encodeURIComponent(value) +
      expires +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '') +
      (secure ? '; secure' : '');

    return true;
  }

  removeItem(key: string, path?: string, domain?: string): boolean {
    if (!this.hasItem(key)) {
      return false;
    }
    document.cookie =
      encodeURIComponent(key) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '');

    return true;
  }

  hasItem(key: string): boolean {
    if (!key) {
      return false;
    }

    return new RegExp(
      '(?:^|;\\s*)' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\='
    ).test(document.cookie);
  }

  keys(): string[] {
    const keys: string[] = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:\=[^;]*)?;\s*/);
    const len: number = keys.length;

    for (let i = 0; i < len; i++) {
      keys[i] = decodeURIComponent(keys[i]);
    }
    return keys;
  }

  cookiesEnabled(): boolean {
    const testCookie = 'TEST_COOKIE';
    let cookieEnabled = navigator.cookieEnabled ? true : false;
    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      this.setItem(testCookie, '1');
      cookieEnabled = this.removeItem(testCookie);
    }
    return cookieEnabled;
  }
}

import {
  ANSFailureModel,
  ANSRequestModel,
  AgentNotificationFailureModel,
  CustomerNotificationFailureModel,
  CustomerNotificationSuccessModel,
  NotificationRequestCustomerEmail,
  NotificationRequestInternalEmail,
  OldReplaceVehicleQuoteApiRequest,
  ReplaceVehicleAnalyticsModel,
  ReplaceVehicleQuoteApiRequest,
  ReplaceVehicleQuoteApiResponse,
  RiskSelectionContext,
  SubmitOnlineBindRequestModel,
  TemplatedEmail
} from '@amfam/policy/models';

import { Action } from '@ngrx/store';
import { ApiStatus } from '@amfam/shared/models';

export const REPLACE_VEHICLE_INIT = '[ReplaceVehicle] Init';

export const REPLACE_VEHICLE_GET_PREMIUM = '[ReplaceVehicle] Get Premium';
export const REPLACE_VEHICLE_GET_PREMIUM_SUCCESS = '[ReplaceVehicle] Get Premium Success';
export const REPLACE_VEHICLE_GET_PREMIUM_FAIL = '[ReplaceVehicle] Get Premium Failure';

export const REPLACE_VEHICLE_SUBMIT_ONLINE_BIND = '[ReplaceVehicle] Submit Online Bind';
export const REPLACE_VEHICLE_SUBMIT_ONLINE_BIND_SUCCESS =
  '[ReplaceVehicle] Submit Online Bind Success';
export const REPLACE_VEHICLE_SUBMIT_ONLINE_BIND_FAIL =
  '[ReplaceVehicle] Submit Online Bind Failure';

export const REPLACE_VEHICLE_NOTIFY_AGENT = '[ReplaceVehicle] Notify Agent';
export const REPLACE_VEHICLE_NOTIFY_AGENT_SUCCESS = '[ReplaceVehicle] Notify Agent Success';
export const REPLACE_VEHICLE_NOTIFY_AGENT_FAIL = '[ReplaceVehicle] Notify Agent Failure';

export const REPLACE_VEHICLE_ANS_NOTIFY_AGENT = '[ReplaceVehicle] Notify Agent ANS';
export const REPLACE_VEHICLE_ANS_NOTIFY_AGENT_SUCCESS = '[ReplaceVehicle] Notify Agent ANS Success';
export const REPLACE_VEHICLE_ANS_NOTIFY_AGENT_FAIL = '[ReplaceVehicle] Notify Agent ANS Failure';

export const REPLACE_VEHICLE_NOTIFY_CUSTOMER = '[ReplaceVehicle] Notify Customer';
export const REPLACE_VEHICLE_NOTIFY_CUSTOMER_SUCCESS = '[ReplaceVehicle] Notify Customer Success';
export const REPLACE_VEHICLE_NOTIFY_CUSTOMER_FAIL = '[ReplaceVehicle] Notify Customer Failure';

export const REPLACE_VEHICLE_ANS_NOTIFICATION = '[ReplaceVehicle] ANS Notification';
export const REPLACE_VEHICLE_ANS_NOTIFICATION_SUCCESS = '[ReplaceVehicle] ANS Notification Success';
export const REPLACE_VEHICLE_ANS_NOTIFICATION_FAIL = '[ReplaceVehicle] ANS Notification Failure';

export const REPLACE_VEHICLE_GRACE_PERIOD_NOTIFY_CUSTOMER = '[ReplaceVehicle] Grace Period Notify Customer';
export const REPLACE_VEHICLE_GRACE_PERIOD_NOTIFY_CUSTOMER_SUCCESS = '[ReplaceVehicle] Grace Period Notify Customer Success';
export const REPLACE_VEHICLE_GRACE_PERIOD_NOTIFY_CUSTOMER_FAIL = '[ReplaceVehicle] Grace Period Notify Customer Failure';

export const SEND_ANALYTICS = '[ReplaceVehicle] Send Analytics';

export const REPLACE_VEHICLE_RISK_SELECTION_CONTEXT = '[ReplaceVehicle] Risk Selection Context';

export class ReplaceVehicleInitAction implements Action {
  readonly type = REPLACE_VEHICLE_INIT;
  constructor() {}
}

export class ReplaceVehicleGetPremiumAction implements Action {
  readonly type = REPLACE_VEHICLE_GET_PREMIUM;
  constructor(public payload: ReplaceVehicleQuoteApiRequest | OldReplaceVehicleQuoteApiRequest) {}
}

export class ReplaceVehicleGetPremiumSuccessAction implements Action {
  readonly type = REPLACE_VEHICLE_GET_PREMIUM_SUCCESS;
  constructor(public payload: ReplaceVehicleQuoteApiResponse) {} // Response will have status and premium
}

export class ReplaceVehicleGetPremiumFailAction implements Action {
  readonly type = REPLACE_VEHICLE_GET_PREMIUM_FAIL;
  constructor(public payload: ReplaceVehicleQuoteApiResponse) {}
}

export class ReplaceVehicleSubmitOnlineBindAction implements Action {
  readonly type = REPLACE_VEHICLE_SUBMIT_ONLINE_BIND;
  constructor(public payload: SubmitOnlineBindRequestModel) {}
}

export class ReplaceVehicleSubmitOnlineBindSuccessAction implements Action {
  readonly type = REPLACE_VEHICLE_SUBMIT_ONLINE_BIND_SUCCESS;
  constructor(public payload: ApiStatus) {} // Response will have status and premium
}

export class ReplaceVehicleSubmitOnlineBindFailAction implements Action {
  readonly type = REPLACE_VEHICLE_SUBMIT_ONLINE_BIND_FAIL;
  constructor(public payload: ApiStatus) {}
}

export class ReplaceVehicleNotifyAgentAction implements Action {
  readonly type = REPLACE_VEHICLE_NOTIFY_AGENT;
  constructor(public payload: NotificationRequestInternalEmail) {}
}

export class ReplaceVehicleNotifyAgentSuccessAction implements Action {
  readonly type = REPLACE_VEHICLE_NOTIFY_AGENT_SUCCESS;
  constructor(public payload: NotificationRequestInternalEmail) {}
}

export class ReplaceVehicleNotifyAgentFailAction implements Action {
  readonly type = REPLACE_VEHICLE_NOTIFY_AGENT_FAIL;
  constructor(public payload: AgentNotificationFailureModel) {}
}

export class ReplaceVehicleANSNotifyAgentAction implements Action {
  readonly type = REPLACE_VEHICLE_ANS_NOTIFY_AGENT;
  constructor(public payload: ANSRequestModel) {}
}

export class ReplaceVehicleANSNotifyAgentSuccessAction implements Action {
  readonly type = REPLACE_VEHICLE_ANS_NOTIFY_AGENT_SUCCESS;
  constructor() {}
}

export class ReplaceVehicleANSNotifyAgentFailAction implements Action {
  readonly type = REPLACE_VEHICLE_ANS_NOTIFY_AGENT_FAIL;
  constructor(public payload: ANSFailureModel) {}
}

export class ReplaceVehicleNotifyCustomerAction implements Action {
  readonly type = REPLACE_VEHICLE_NOTIFY_CUSTOMER;
  constructor(public payload: NotificationRequestCustomerEmail) {}
}

export class ReplaceVehicleNotifyCustomerSuccessAction implements Action {
  readonly type = REPLACE_VEHICLE_NOTIFY_CUSTOMER_SUCCESS;
  constructor(public payload: CustomerNotificationSuccessModel) {}
}

export class ReplaceVehicleNotifyCustomerFailAction implements Action {
  readonly type = REPLACE_VEHICLE_NOTIFY_CUSTOMER_FAIL;
  constructor(public payload: CustomerNotificationFailureModel) {}
}

export class ReplaceVehicleANSAction implements Action {
  readonly type = REPLACE_VEHICLE_ANS_NOTIFICATION;
  constructor(public payload: ANSRequestModel) {}
}

export class ReplaceVehicleANSSuccessAction implements Action {
  readonly type = REPLACE_VEHICLE_ANS_NOTIFICATION_SUCCESS;
  constructor() {}
}

export class ReplaceVehicleANSFailAction implements Action {
  readonly type = REPLACE_VEHICLE_ANS_NOTIFICATION_FAIL;
  constructor(public payload: ANSFailureModel) {}
}

export class SendAnalyticsAction implements Action {
  readonly type = SEND_ANALYTICS;
  constructor(public payload: ReplaceVehicleAnalyticsModel) {}
}

export class SendGracePeriodCustomerEmail implements Action {
  readonly type = REPLACE_VEHICLE_GRACE_PERIOD_NOTIFY_CUSTOMER;
  constructor(public payload: TemplatedEmail) {}
}
export class SendGracePeriodCustomerEmailSuccess implements Action {
  readonly type = REPLACE_VEHICLE_GRACE_PERIOD_NOTIFY_CUSTOMER_SUCCESS;
}

export class SendGracePeriodCustomerEmailFailed implements Action {
  readonly type = REPLACE_VEHICLE_GRACE_PERIOD_NOTIFY_CUSTOMER_FAIL;
}

export class SetVehicleRiskSelectionContext implements Action {
  readonly type = REPLACE_VEHICLE_RISK_SELECTION_CONTEXT;
  constructor(public payload: RiskSelectionContext) {}
}


export type Actions =
  | ReplaceVehicleInitAction
  | ReplaceVehicleGetPremiumAction
  | ReplaceVehicleGetPremiumSuccessAction
  | ReplaceVehicleGetPremiumFailAction
  | ReplaceVehicleNotifyAgentAction
  | ReplaceVehicleNotifyAgentSuccessAction
  | ReplaceVehicleNotifyAgentFailAction
  | ReplaceVehicleANSNotifyAgentAction
  | ReplaceVehicleANSNotifyAgentSuccessAction
  | ReplaceVehicleANSNotifyAgentFailAction
  | ReplaceVehicleNotifyCustomerAction
  | ReplaceVehicleNotifyCustomerSuccessAction
  | ReplaceVehicleNotifyCustomerFailAction
  | ReplaceVehicleANSAction
  | ReplaceVehicleANSSuccessAction
  | ReplaceVehicleANSFailAction
  | ReplaceVehicleSubmitOnlineBindAction
  | ReplaceVehicleSubmitOnlineBindSuccessAction
  | ReplaceVehicleSubmitOnlineBindFailAction
  | SendGracePeriodCustomerEmail
  | SendGracePeriodCustomerEmailSuccess
  | SendGracePeriodCustomerEmailFailed
  | SetVehicleRiskSelectionContext;

<h1 content [contentPath]="'auth.emailValidationHeader'"></h1>
<p class="pad-top-10" content [contentPath]="'auth.emailValidationSubHeader'"></p>
<div class="row pad-top-30">
  <div class="column twelve">
    <div class="button-width">
      <div class="block button-group pad-bottom-20">
        <button
          ds-button
          class="-stacked"
          tabindex="-1"
          [disabled]="accountLocked"
          (click)="loginSelected()"
          [class.active]="showLogin"
          content
          [contentPath]="'auth.emailValidationLoginButton'"
        ></button>
        <button
          ds-button
          class="-stacked"
          tabindex="-1"
          (click)="searchSelected()"
          [class.active]="showSearch"
          content
          [contentPath]="'auth.emailValidationSearchButton'"
        ></button>
      </div>
    </div>
  </div>

  <div class="column twelve">
    <div *ngIf="showLogin && !accountLocked">
      <p class="pad-top-10" content [contentPath]="'auth.emailValidationLoginText'"></p>
      <ds-login-form (responseCodeEvent)="loginResponseReceived($event)" [isEmailValidation]="true">
      </ds-login-form>
    </div>

    <div *ngIf="showSearch || accountLocked">
      <p
        class="pad-bottom-10 pad-top-10"
        content
        [contentPath]="'auth.emailValidationSearchText'"
      ></p>
      <ds-magic3-search
        (submittedFormEvent)="onMagic3Submit($event)"
        [showBackButton]="false"
        [searchErrorMessage]="searchErrorMessageInput"
      >
      </ds-magic3-search>
      <p *ngIf="accountLocked" class="error-600-text">
        [Your account is currently locked. Please verify with personal info.]
      </p>
    </div>
  </div>
</div>

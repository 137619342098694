import { AnalyticsFacade } from '@amfam/shared/analytics';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { PageAnalytic } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService, ToasterService } from '@amfam/ui-kit';
import { ActivatedRoute } from '@angular/router';
import { get as _get } from 'lodash';
import { ENROLLMENT_TYPE } from '../../../../../app/enrollment/enrollment-type.model';
import * as fromRoot from '../../../../core/store/';
import * as workflowActions from '../../../../core/store/workflow';
import { VerifyUserModel } from '../../../shared/verify-user.model';
import { VerificationService } from '../verification.service';

@Component({
  selector: 'ds-enroll-verify-email',
  templateUrl: './enroll-verify-email.component.html',
  styleUrls: ['../../../enrollment.component.scss']
})
export class EnrollVerifyEmailComponent implements OnInit, OnDestroy {
  isButtonActive = false;
  programPathContext: string;
  gotError = false;
  emailList: any = [];
  email: string;
  partyId: string;
  showEmailSent = false;
  private typeOfAccountCode: string;
  private experienceId: string;
  private partnerId: string;
  private enrollType: string;
  private programEnrollment = false;
  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic;
  private trackAnalytic: PageAnalytic;
  private conversionPageAnalytic: PageAnalytic;

  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<fromRoot.RootState>,
    private verifyBy: VerificationService,
    private toasterService: ToasterService,
    private spinner: LoadingSpinnerService,
    private activeRoute: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    this.enrollType = this.activeRoute.parent.parent.routeConfig.data.enrollType;
    this.pageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:Email',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity:Email'
    };
    this.trackAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:EmailSent',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity:Email'
    };
    this.conversionPageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:EmailSent',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Add Personal Line',
      subCategory2: '',
      subCategory3: ''
    };
    // **** End of Analytics data for this component
    if (
      this.featureFlagService.isEnabled('token_based_enrollment') &&
      this.enrollType === ENROLLMENT_TYPE.TOKEN
    ) {
      this.programEnrollment = true;
      const token = _get(this.activeRoute, 'parent.parent.snapshot.params.tokenId', '');

      // JP: Safe check on token. If token is missing, this will break email link that's sent, so route user to error page
      if (token === '') {
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/error']
          })
        );
      }
      // JP: Context is mapped to token/:tokenId for reference to link back into token enrollment
      this.programPathContext =
        'token/' + _get(this.activeRoute, 'parent.parent.snapshot.params.tokenId', '');
    }

    combineLatest(
      this.store.select(fromRoot.getEnrollUserState),
      this.store.select(userQuery.getTypeOfAccountCode),
      this.store.select(BrandSelectors.getExperienceId),
      this.store.select(BrandSelectors.getPartnerId),
      (enrollUserState, typeOfAccountCode, experienceId, partnerId) => {
        return {
          enrollUserState: enrollUserState,
          typeOfAccountCode: typeOfAccountCode,
          experienceId: experienceId,
          partnerId: partnerId
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        this.experienceId = state.experienceId;
        this.partnerId = state.partnerId;
        this.emailList = state.enrollUserState.maskedEmails;
        this.partyId = state.enrollUserState.partyId;
        this.typeOfAccountCode = state.typeOfAccountCode;
        // Analytics for conversion
        if (this.typeOfAccountCode && this.typeOfAccountCode !== AccountTypeEnum.Customer) {
          this.analyticsFacade.trackPage(this.conversionPageAnalytic);
        } else {
          this.analyticsFacade.trackPage(this.pageAnalytic);
        }
      });
  }

  public sendEmail(email: string) {
    this.spinner.start();
    this.email = email;
    this.isButtonActive = false;

    const requestObj: VerifyUserModel = new VerifyUserModel(
      this.partyId,
      'EMAIL',
      email,
      this.programPathContext,
      this.experienceId,
      this.partnerId,
      this.programEnrollment
    );

    this.verifyBy
      .email(requestObj)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        res => {
          // TODO - pull this from a message service
          this.spinner.stop();
          if (!res) {
            return;
          }
          const emailMsg = 'We will be sending you an email shortly';
          if (res) {
            this.toasterService.pop('success', emailMsg);
            this.showEmailSent = true;
            this.gotError = false;
          } else {
            this.store.dispatch(
              new fromRouterActions.Go({
                path: ['/enroll/contact-us']
              })
            );
          }
          this.isButtonActive = true;
          this.analyticsFacade.trackPage(this.trackAnalytic);
        },
        err => {
          this.spinner.stop();
          if (!err) {
            return;
          }
          this.gotError = true;
          const emailErr = 'We had a trouble sending you an email';
          this.toasterService.pop('error', emailErr);
          this.showEmailSent = false;
          this.isButtonActive = true;
        }
      );
  }

  goBackOneStep() {
    this.store.dispatch(new workflowActions.WorkflowStepBackAction());
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}

export class MatDatePickerOptions {
  isDisabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  highlightDate?: Date;
}

export const MY_NATIVE_DATE_FORMATS = {
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export interface GoPaperlessCopy {
  goPaperlessHeader: string;
  goPaperlessSummary: string;
  goPaperlessContent: string;
  goPaperlessEmailHeader: string;
  goPaperlessEmailNote: string;
  goPaperlessTermsAndConditionsHeader: string;
  unenrollGoPaperlessHeader: string;
  unenrollGoPaperlessSummary: string;
  unenrollGoPaperlessAccountsHeader: string;
  goPaperlessPromoSummary: string;
  goPaperlessPromoPolicyHeader: string;
  goPaperlessPromoEnrolledSummary: string;
  goPaperlessPromoEnrolledPolicyHeader: string;
  goPaperlessPromoEnrolledEmailHeader: string;
  goPaperlessPromoEnrolledEmailNote: string;
  ezButtonEnabledConfirmationTitle: string;
  ezButtonDisabledConfirmationTitle: string;
  goPaperLessSuccessTitle: string;
  goPaperLessPartialSuccessTitle: string;
  goPaperLessFailure: string;
  goPaperLessSuccessText1: string;
  goPaperLessSuccessText2: string;
  goPaperLessPartialSuccessAndFailureText: string;
  overviewSuccessPageActionText: string;
  goPaperLessPartialSuccessText: string;
  goPaperLessFailureText: string;
  overviewGoPaperLessFailure: string;
  billingStatementsCtaTitle: string;
  billingStatementsCtaText: string;
  goPaperlessHomesiteCtaTitle: string;
  goPaperlessHomesiteCtaText: string;
  paperlessBenefitTextPARTNER: string;
  paperlessBenefitsAFI: string;
  paperlessBenefitTitle: string;
  paperlessBenefitTextAFI: string;
  paperlessBenefitsPARTNER: string;
  paperlessBenefitTitle2: string;
  easyButtonTitle: string;
  billsList: string;
  insurancecardsList: string;
  paperlessContentTitle: string;
  coveragedocumentsList: string;
  goPaperLessPageDetails: string;
  goPaperLessPageDetailsFooter: string;
  goPaperLessSuccess: string;
  enrollPaperlessTitle: string;
  paperPolicyNote: string;
  optOutButton: string;
  signUpPaperlessPolicyMessage: string;
  goPaperlessQuestions: string;
  viewText: string;
  termsAndConditionsLink: string;
  visitGoPaperlessMessage: string;
  contactForMoreInfoText: string;
  accountsAvailableForPaperlessText: string;
  signUpButton: string;
  checkOutGoPaperlessQuestions: string;
  FAQs: string;
  visitGoPaperlessForQuestionsText: string;
  accountsWithPaperlessText: string;
  note: string;
  paperlessDocsNotAvailableMessage: string;
  policyDocumentNotifications: string;
  changeEmailQuestion: string;
  profileTab: string;
  enrollInPaperless: string;
  viewMoreInfoText: string;
  notificationsText: string;
  updateEmailText: string;
  sectionText: string;
  forMoreInfoText: string;
  paperlessFAQ: string;
  successPaperlessHeading: string;
  successPaperlessSubheading: string;
  failurePaperlessHeading: string;
  failurePaperlessSubheading: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFinancialInstitution from './+state/financial-institution.reducer';
import { FinancialInstitutionEffects } from './+state/financial-institution.effects';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('financialInstitution', fromFinancialInstitution.financialInstitutionReducer), EffectsModule.forFeature([FinancialInstitutionEffects])]
})
export class BillingFinancialInstitutionDataAccessModule {}

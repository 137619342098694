import { createReducer, on } from '@ngrx/store';
import * as AutoPayActions from './auto-pay.actions';
import { AutomaticPayment } from '../models/auto-pay.models';
import { keys as _keys } from 'lodash';

export interface State {
  prediction: AutomaticPayment;
  loading: boolean;
  hasError: boolean;
  correlationId: string;
}
export const initialState: State = {
  prediction: null,
  loading: false,
  hasError: false,
  correlationId: ''
};

export const reducer = createReducer(
  initialState,

  // Get AutoPay Prediction
  on(AutoPayActions.getAutoPayPrediction, (state, action) => ({
    ...state,
    loading: true,
    hasError: false,
    prediction: null
  })),
  on(AutoPayActions.getAutoPayPredictionSuccess, (state, action) => ({
    ...state,
    loading: false,
    hasError: false,
    correlationId: action.correlationId,
    prediction: action.response.autoPayRules[0]
  })),
  on(AutoPayActions.getAutoPayPredictionFailure, (state, action) => ({
    ...state,
    loading: false,
    hasError: true,
    correlationId: action.correlationId,
    prediction: null
  }))
);

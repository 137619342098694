import { ClaimGlassOnlyAction, ClaimGlassOnlyActionTypes } from './claim-glass-only.actions';

export const CLAIMGLASSONLY_FEATURE_KEY = 'claimGlassOnly';

import { ClaimGlassOnly, initialClaimGlassOnly } from '../models/claim-glass-only.model';

export function claimGlassOnlyReducer(
  state: ClaimGlassOnly = initialClaimGlassOnly,
  action: ClaimGlassOnlyAction
): ClaimGlassOnly {
  switch (action.type) {
    case ClaimGlassOnlyActionTypes.LoadGlassOnlyClaims:
      return Object.assign({}, state, initialClaimGlassOnly, action.payload, {
        loading: true
      });

    case ClaimGlassOnlyActionTypes.LoadGlassOnlyClaimsSuccess:
      return Object.assign({}, state, {
        htmlBody: action.payload,
        loading: false,
        loaded: true
      });

    case ClaimGlassOnlyActionTypes.LoadGlassOnlyClaimsFail:
      return Object.assign({}, state, action.payload, {
        loading: false,
        loaded: true
      });

    default:
      return state;
  }
}
